import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ITodo } from '@shared/models';

@Component({
	selector: 'app-comment-popup',
	templateUrl: './comment-popup.component.html',
	styleUrls: ['./comment-popup.component.scss']
})
export class CommentPopupComponent implements OnInit, OnChanges {
	@Output() public closeButtonClicked =  new EventEmitter();

	@Input() public task: ITodo;
	constructor() { }

	ngOnInit(): void {
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes.hasOwnProperty('task')){
			this.task.commentCount = this.task.comments?.length ? this.task.comments.length : 0;
		}
	}

	public onCloseButtonClick(): void{
		this.closeButtonClicked.emit();
	}

}
