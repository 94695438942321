import { distinctUntilChanged } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from './user.service';
import { NetworkService } from './network.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class CollectionsService {
	public col = { myCollections: [], followingCollections: [], otherCollections: [] };
	private collectionSubject = new BehaviorSubject<any>([]);
	public collection = this.collectionSubject.asObservable().pipe(distinctUntilChanged());
	public sortedCollectionSubject = new BehaviorSubject<any>(this.col);
	public nextUrl = '';
	public isCollectionLoaded = false;
	public sortedCollection = this.sortedCollectionSubject.asObservable().pipe(distinctUntilChanged());
	public scrollPosition = 0;
	public isCollectionPaginating = false;
	public emptyCollections = false;
	public postAddedToCollection = false;
	public newCollection = { name: '', uid: '' };

	constructor(private apiService: ApiService, private userService: UserService, private netWorkService: NetworkService) {
		this.netWorkService.currentNetwork.subscribe(() => {
			this.resetCollections();
		});
		this.userService.currentUser.subscribe(() => {
			this.resetCollections();
		});
	}

	/**
	 * Function to populate the collections subject
	 */
	public populateCollection(query = ''): void {
		this.emptyCollections = false;
		this.isCollectionLoaded = false;
		this.getCollections(query).subscribe((data) => {
			if (data.objects.length === 0) {
				this.emptyCollections = true;
			} else {
				this.emptyCollections = false;
			}

			if (data.next) {
				this.nextUrl = data.next.split('alpha')[1];
			}

			this.isCollectionLoaded = true;
			this.collectionSubject.next(data['objects']);
		});

		this.sortCollection();
	}

	public paginateCollection(): void {
		if (this.nextUrl !== '') {
			this.isCollectionPaginating = true;
			this.apiService.get(this.nextUrl).subscribe((data) => {
				this.isCollectionPaginating = false;
				if (data && this.nextUrl) {
					if (data.next) {
						this.nextUrl = data.next.split('alpha')[1];
					} else {
						this.nextUrl = '';
					}

					let prev = this.collectionSubject.value;
					prev = prev.concat(data['objects']);

					this.collectionSubject.next(prev);
				}
			});
		}
	}

	/**
	 * Function to sort the collection
	 */
	public sortCollection(): void {
		// const self = this;
		this.collection.subscribe((value) => {
			this.col['myCollections'] = [];
			this.col['followingCollections'] = [];
			this.col['otherCollections'] = [];

			value.forEach((collection) => {
				if (collection.user.username === localStorage.getItem('uid') || collection.collaboration === true) {
					this.col['myCollections'].push(collection);
				} else {
					if (collection.follow) {
						this.col['followingCollections'].push(collection);
					} else {
						this.col['otherCollections'].push(collection);
					}
				}
			});

			this.sortedCollectionSubject.next(this.col);
		});
	}

	/**
	 * Function to get the collections list of currently loaded network.
	 */
	public getCollections(q): Observable<any> {
		if (q) {
			return this.apiService.get('/folders/?network=' + localStorage.getItem('network_id') + '&query=' + q);
		} else {
			return this.apiService.get('/folders/?network=' + localStorage.getItem('network_id'));
		}
	}

	public getCollectionDetail(collectionUid, state): Observable<any> {
		if (state) {
			return this.apiService.get('/folders/' + collectionUid + '/?archived=true');
		} else {
			return this.apiService.get('/folders/' + collectionUid + '/');
		}
	}

	/**
	 * Function to get the contents inside a collection
	 * @param collectionUid
	 */
	public getCollectionContent(collectionUid): Observable<any> {
		return this.apiService.get('/folders/' + collectionUid + '/contents/');
	}

	/**
	 * Function to unfollow a collection
	 * @param collectionUid
	 */
	public unFollowCollection(collectionUid): Observable<any> {
		const url = '/users/' + localStorage.getItem('uid') + '/folders/';
		const params = { follow: false, folder: collectionUid };

		return this.apiService.post(url, params);
	}

	/**
	 * Function to follow a collection
	 * @param collectionUid F
	 */
	public followCollection(collectionUid): Observable<any> {
		const url = '/users/' + localStorage.getItem('uid') + '/folders/';
		const params = { follow: true, folder: collectionUid };

		return this.apiService.post(url, params);
	}

	/**
	 * Function to create collection
	 * @param collection
	 */
	public createCollection(collection): Observable<any> {
		const url = '/folders/';
		collection['username'] = localStorage.getItem('uid');
		collection['network'] = localStorage.getItem('network_id');
		this.resetCollections();

		return this.apiService.post(url, collection);
	}

	/**
	 * Function to add collection to a post
	 * @param collectionUid
	 * @param postUid
	 */
	public addToCollection(collectionUid, postUid): Observable<any> {
		const url = '/folders/' + collectionUid + '/contents/';
		const params = { username: localStorage.getItem('username'), contentUid: postUid, contentType: 'post' };
		this.resetCollections();

		return this.apiService.post(url, params);
	}

	/**
	 * Function to remove a post from collection.
	 */
	public removeFromCollection(collectionUid, postUid): Observable<any> {
		const url = '/folders/' + collectionUid + '/contents/?contentUid=' + postUid + '&contentType=post&username=' + localStorage.getItem('uid');
		return this.apiService.delete(url);
	}

	/**
	 * To update collection.
	 */
	public updateCollection(collectionUid, data): Observable<any> {
		const url = '/folders/' + collectionUid + '/';
		this.resetCollections();

		return this.apiService.put(url, data);
	}

	public deleteCollection(collectionUid): Observable<any> {
		const url = '/folders/' + collectionUid + '/';
		this.resetCollections();

		return this.apiService.delete(url);
	}

	/**
	 * Function to reset the collection lists.
	 */
	public resetCollections(): void {
		this.col = { myCollections: [], followingCollections: [], otherCollections: [] };
		this.isCollectionLoaded = false;
		this.emptyCollections = false;
		this.sortedCollectionSubject.next(this.col);
		this.scrollPosition = 0;
		this.collectionSubject.next([]);
		this.nextUrl = '';
	}

	public checkAccessibility(data): Observable<any> {
		const params = new HttpParams({
			fromObject: {
				format: 'json',
			},
		});

		const url = '/folders/' + data.collectionId + '/?username=' + localStorage.getItem('uid');

		return this.apiService.get(url, params);
	}

	public getArchivedCollections(): Observable<any> {
		return this.apiService.get('/folders/?archived=true&network=' + localStorage.getItem('network_id'));
	}

	public getArchivedCounts(): Observable<any> {
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/folders_counts/');
	}
}
