import { Component, Input, OnChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MixPanelService } from '@core/services/third-party/mix-panel.service';
import { SegmentAnalyticsService } from '@core/services/third-party/segment-analytics.service';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';
import { WorkspaceCreationService } from '@modules/workspace/services/workspace-creation.service';
import { TranslateService } from '@ngx-translate/core';
import { IWorkspace } from '@shared/models/workspace/workspace';
import { ToastService } from '@shared/services/common/toast.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ClipboardService } from 'ngx-clipboard';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-invite-teammates',
	templateUrl: './invite-teammates.component.html',
	styleUrls: ['./invite-teammates.component.scss'],
})
export class InviteTeammatesComponent implements OnChanges {
	@Input() public workspace: IWorkspace | any;

	// #region Form
	public inviteForm: FormGroup;
	public isSubmitted = false;
	public copyLinkSuccess = false;
	public multiEmailInputs = false;
	// #endregion

	public errorModal?: NzModalRef;

	// #region Modal handlers
	public modalOpen = false;
	public baseUrl: string = '';
	// #endregion

	constructor(
		private clipboardService: ClipboardService,
		private mixPanelService: MixPanelService,
		private segmentAnalyticsService: SegmentAnalyticsService,
		private toastService: ToastService,
		private translateService: TranslateService,
		private formBuilder: FormBuilder,
		private workspaceService: WorkspaceCreationService,
		private pageLoadingUiService: PageLoadingUiService,
		private nzModalService: NzModalService
	) {
		this.inviteForm = this.formBuilder.group({
			invites: this.formBuilder.array([
				this.formBuilder.group({
					email: ['', [Validators.required, Validators.email, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]],
				}),
			]),
		});
	}

	ngOnChanges(): void {
		this.baseUrl = this.workspace?.shareUrl ? `${environment.appURL}?share_url=${this.workspace?.shareUrl}&nid=${this.workspace?.uid}` : '';
	}

	// #region Modal Actions

	/**
	 * Show modal
	 */
	public showInviteModal(): void {
		this.modalOpen = true;
	}

	/**
	 * Close and reset modal
	 */
	public closeInviteModal(): void {
		this.isSubmitted = false;
		this.clearFormArray(this.getInvites);
		this.inviteForm.reset();
		this.modalOpen = false;
		this.multiEmailInputs = false;
		this.copyLinkSuccess = false;
	}

	// #endregion

	// #region Invite link copy to clipboard

	/**
	 * Copy the invite link to clipboard and show success toast
	 */
	public onClickCopy(): void {
		this.clipboardService.copyFromContent(this.baseUrl);
		this.copyLinkSuccess = true;

		this.mixPanelService.track('Invitation Sent', {
			network: this.workspace.name,
			appVersion: environment.version,
			step: 'Copied to clipboard',
		});
		this.segmentAnalyticsService.trackEvent('Invitation Sent', {
			network: this.workspace.name,
			appVersion: environment.version,
			step: 'Copied to clipboard',
		});

		this.toastService.success(this.translateService.instant('Invite link copied'));
	}

	// #endregion

	// #region Form

	/**
	 * Getter for FormArray
	 */
	get getInvites(): FormArray {
		return this.inviteForm.get('invites') as FormArray;
	}

	/**
	 * Getter for a particular FormGroup instance from the FormArray
	 * @param i index of the FormControl of FormArray
	 * @returns FormGroup for a single FormArray element
	 */
	public getInvite(i: number): FormGroup {
		return this.getInvites?.controls[i] as FormGroup;
	}

	/**
	 * Function to create a new Invite Input (FormGroup) and Add it to the FormArray
	 */
	public addInvites(): void {
		this.multiEmailInputs = true;
		this.getInvites.push(
			this.formBuilder.group({
				email: ['', [Validators.email, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]],
			})
		);
	}

	/**
	 * Resets the formArray by removing all formGroups within
	 * @param formArray FormArray
	 */
	private clearFormArray(formArray: FormArray): void {
		formArray.clear();
		formArray.push(
			this.formBuilder.group({
				email: ['', [Validators.required, Validators.email, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]],
			})
		);
	}

	/**
	 * Handle submit event and call function to request API for sending emails
	 */
	public onSubmit(): void {
		this.isSubmitted = true;
		if (this.inviteForm.valid) {
			const invites = this.inviteForm.value.invites,
				emails = [];

			invites.forEach((emailObj: any) => {
				if (emailObj.email?.trim()?.length) {
					emails.push(emailObj?.email?.trim());
				}
			});

			console.log(emails);
			this.inviteTeamMembersViaEmail(emails);
		}
	}

	// #endregion

	// #region Invite via Email Addresses
	/**
	 * Invite team-members via email
	 *
	 * To:- Entered EMAILS
	 * Subject:- USER is inviting you join his/her workspace.
	 * Body:- Hey, I am inviting you to WORKSPACE_NAME to collaborate in Peerbie.
	 * 	   Here is the invitation link INVITATION_LINK
	 *
	 * Handled by server
	 */
	private inviteTeamMembersViaEmail(emails: string[]): void {
		this.pageLoadingUiService.setPageLoading(true);
		this.workspaceService
			.inviteTeammatesToWorkspace(this.workspace?.uid, { email: emails.toString() })
			.subscribe(
				(response: any) => {
					if (response.success) {
						this.toastService.success(this.translateService.instant('Successfully created Invite Email'));
						this.mixPanelService.track('Invitation Sent', {
							network: this.workspace?.name,
							appVersion: environment?.version,
							step: 'Email Sent',
						});
						this.segmentAnalyticsService.trackEvent('Invitation Sent', {
							network: this.workspace?.name,
							appVersion: environment?.version,
							step: 'Email Sent',
						});

						this.closeInviteModal();
					} else {
						this.errorModal = this.nzModalService.error({
							nzTitle: this.translateService.instant('Error'),
							// eslint-disable-next-line spellcheck/spell-checker
							nzContent: this.translateService.instant('Aww Snap! Something went wrong'),
							nzOnOk: () => { },
						});
					}
				},
				(error: any) => {
					this.errorModal = this.nzModalService.error({
						nzTitle: this.translateService.instant('Error'),
						// eslint-disable-next-line spellcheck/spell-checker
						nzContent: error?.errors?.error?.detail ?? this.translateService.instant('Aww Snap! Something went wrong'),
						nzOnOk: () => { },
					});
				}
			)
			.add(() => {
				this.pageLoadingUiService.setPageLoading(false);
			});
	}

	// #endregion
}
