import { PeerCoreModule } from 'projects/peer-core/src/public-api';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NotificationComponent } from './notification.component';
import { SharedModule } from '../shared';
import { AgmCoreModule } from '@agm/core';

const networkRouting: ModuleWithProviders<any> = RouterModule.forChild([
	{
		path: 'notifications',
		component: NotificationComponent,
	},
]);

@NgModule({
	imports: [networkRouting, BrowserModule, AgmCoreModule, SharedModule, PeerCoreModule],
	declarations: [NotificationComponent],
	bootstrap: [NotificationComponent],
	providers: [],
})
export class NotificationModule {}
