import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MixPanelService } from '@core/services/third-party/mix-panel.service';
import { SegmentAnalyticsService } from '@core/services/third-party/segment-analytics.service';
import { WorkspaceUiService } from '@core/services/ui/workspace/workspace-ui.service';
import { WorkspaceService } from '@core/services/workspace/workspace.service';
import { AuthenticationService } from '@modules/new-auth/services/authentication.service';
import { WorkspaceCreationService } from '@modules/workspace/services/workspace-creation.service';
import { TranslateService } from '@ngx-translate/core';
import { PaginatedResponse } from '@shared/models/base/paginated-data-ui';
import { IBranchData } from '@shared/models/third-party/branch-data';
import { IWorkspace, IWorkspacePaginatedResponse } from '@shared/models/workspace/workspace';
import { SocketIoService, UserService } from '@shared/services';
import { WorkspaceCreationSessionManagementService } from '@shared/services/workspace-creation-session-management.service';
import CacheUtils from '@shared/utils/cache-utils';
import { CookieService } from 'ngx-cookie-service';
import { ScrollEvent, ScrollService } from 'projects/peer-core/src/lib/services/ui/scroll.service';
import { Subscription, timer } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-workspaces',
	templateUrl: './workspaces.component.html',
	styleUrls: ['./workspaces.component.scss'],
})
export class WorkspacesComponent implements OnInit, OnDestroy {
	public network: IWorkspace;
	public currentUser: any;

	public colors = [
		{ bg: 'var(--orange-50)', letter: 'var(--orange-500)' },
		{ bg: 'var(--pink-50)', letter: 'var(--pink-500)' },
		{ bg: 'var(--green-50', letter: 'var(--green-500)' },
		{ bg: 'var(--blue-50)', letter: 'var(--blue-500)' },
		{ bg: 'var(--indigo-50)', letter: 'var(--indigo-500)' },
	];

	// workspace delete modal
	public showWorkspaceDeletedModal = false;
	public deletedWorkspace: IWorkspace;

	public branchData: IBranchData;
	public workspacesResponse: IWorkspacePaginatedResponse = new PaginatedResponse();

	private windowScrollChangeSubscription: Subscription;

	public singleWorkspaceApp = environment.single_workspace;

	constructor(
		private userService: UserService,
		private workspaceService: WorkspaceService,
		private workspaceUiService: WorkspaceUiService,
		private socket: SocketIoService,
		private scrollService: ScrollService,
		private router: Router,
		private cookieService: CookieService,
		private segmentService: SegmentAnalyticsService,
		private mixPanelService: MixPanelService,
		private translateService: TranslateService,
		private authenticationService: AuthenticationService,
		private workspaceCreationService: WorkspaceCreationService,
		private wsSessionManagementService: WorkspaceCreationSessionManagementService
	) {
		// handle member deletion
		this.socket.getSocket().on('member-delete', (data: any) => {
			const socketData = JSON.parse(data);

			if (socketData.memberId) {
				this.handleDeleteMembership(socketData.memberId);
			}
		});

		// page scroll subscription
		this.windowScrollChangeSubscription = this.scrollService.windowScrollChanged.subscribe((event: ScrollEvent) => {
			if (event.scrollPercent >= 80 && !this.workspacesResponse.isLoading && this.workspacesResponse.isNextPageLoading) {
				this.loadUsersNetworks();
			}
		});
	}

	ngOnInit(): void {
		this.wsSessionManagementService.clearJoinFlowSessionData();
		/**
		 * Cache Clear:- Workspace creation & Current Workspace
		 */
		this.workspaceCreationService.resetWorkspaceCreation();
		CacheUtils.removeStoredData(['networkExpired', 'network_id']);

		this.userService.isAuthenticated.pipe(debounceTime(750)).subscribe((isAuthenticated: boolean) => {
			if (isAuthenticated) {
				this.userService.currentUser.subscribe((userData: any) => {
					this.currentUser = userData ?? this.userService.getCurrentUser();

					if (this.currentUser) {
						/**
						 * eslint-disable-next-line spellcheck/spell-checker
						 * Add Reditus Tracking Script and Segment if cookie for google auth exists
						 */
						if (this.cookieService.get('google-auth')) {
							if (typeof window.gr === 'function') {
								window.gr('track', 'conversion', { email: userData?.email });
								this.cookieService.delete('google-auth');
								this.cookieService.set('newUser', 'true', 0.02083);
							}

							this.mixPanelService.track('Signup Completed', {
								email: userData?.email,
								appVersion: environment.version,
								language: this.translateService.currentLang,
							});

							/**
							 * Segment Analytics Tracking
							 */
							this.segmentService.trackEvent('Signup Completed', {
								email: userData?.email,
								appVersion: environment.version,
								language: this.translateService.currentLang,
							});
						}

						// #region Product Fruits Implementation
						timer(500).subscribe(() => {
							const productFruitUserData = {
								username: userData.username, // REQUIRED, must be unique
								email: userData.email,
								// eslint-disable-next-line spellcheck/spell-checker
								firstname: userData.firstName,
								// eslint-disable-next-line spellcheck/spell-checker
								lastname: userData.lastName || '',
								signUpAt: userData.dateJoined, // Sign up date (yyyy-mm-dd hh:mm:ss)
							};
							if (userData && window['productFruitsUser']?.username !== productFruitUserData?.username && typeof window?.productFruits?.identifyUser === 'function') {
								window?.productFruits?.identifyUser(productFruitUserData);
							}
						});

						// #endregion

						this.workspaceUiService.clearWorkspaceFromStorage();

						this.loadUsersNetworks();
					}
				});
			} else {
				timer(1000).subscribe(() => {
					this.authenticationService.setAuthenticationStep('home');
					console.log("In workspace list", localStorage.getItem('branchData'));
					if (localStorage.getItem('branchData') || CacheUtils.getStoredData('branchData')) {
						this.router.navigateByUrl('/authentication/register');
					} else {
						timer(1000).subscribe(() => {
							console.log("In workspace list second attempt", localStorage.getItem('branchData'));
							if (localStorage.getItem('branchData') || CacheUtils.getStoredData('branchData')) {
								this.router.navigateByUrl('/authentication/register');
							} else {
								this.router.navigateByUrl('/authentication/login');
							}
						});
					}
				});
			}
		});
	}

	ngOnDestroy(): void {
		this.windowScrollChangeSubscription.unsubscribe();
	}

	// #region Loaders

	/**
	 * Load user's networks
	 */
	public loadUsersNetworks(): void {
		// console.log('loadUsersNetworks');
		this.workspacesResponse.isLoading = !this.workspacesResponse?.objects?.length && this.workspacesResponse?.next === null;
		this.workspacesResponse.isNextPageLoading = this.workspacesResponse?.objects?.length && this.workspacesResponse?.next !== null;

		if (!this.workspacesResponse.isLoading && !this.workspacesResponse?.isNextPageLoading) {
			return;
		}

		// we are removing the app id from the request to get the all workspaces
		this.workspaceService
			.getAllWorkspacesOfUser(this.currentUser?.username, this.workspacesResponse?.isNextPageLoading ? this.workspacesResponse?.offset : 0, 100)
			.subscribe((data: IWorkspacePaginatedResponse) => {
				if (this.workspacesResponse.next) {
					this.workspacesResponse.objects.push(...data.objects);
				} else {
					this.workspacesResponse = new PaginatedResponse(data);
				}

				this.workspacesResponse.next = data.next;
				this.workspacesResponse.offset = data.offset + 100;

				this.syncIsCurrentWorkspace();

				this.workspaceUiService.handleBranches(this.workspacesResponse.objects, (workspace: IWorkspace) => this.loadNetwork(workspace));

				/**
				 * If there is only one workspace,then enter the workspace by default, skipping the workspaces selection page
				 *
				 * Else if there are no workspaces and no branchData, then redirect to workspace creation page
				 */
				if (data?.count === 1 && data?.objects?.length > 0 && data?.objects?.[0] && !localStorage.getItem('branchData')) {
					this.loadNetwork(data?.objects?.[0]);
				} else if (data?.count === 0 && data?.objects?.length === 0 && !(localStorage.getItem('branchData') || CacheUtils.getStoredData('branchData') || localStorage.getItem("fromBranchLink"))) {
					this.router.navigate(['/workspace/welcome']);
				}
			})
			.add(() => {
				timer(500).subscribe(() => (this.workspacesResponse.isLoading = false));
			});

		// sort workspaces
		this.workspacesResponse.objects = this.workspaceUiService.reOrderWorkspaces(this.workspacesResponse.objects);
	}

	/**
	 * Switch to selected workspace
	 *
	 * @param workspace IWorkspace
	 */
	private loadNetwork(workspace: IWorkspace): void {
		if (workspace.manager === true) {
			if (!localStorage.getItem('manager')) {
				const userMembership = {
					manager: workspace.manager,
					uid: workspace.uid,
				};

				CacheUtils.storeData('manager', JSON.stringify(userMembership));
			}
		}

		if (workspace.activityNotificationCount) {
			CacheUtils.storeData('notificationCount', workspace.activityNotificationCount);
		}

		this.workspacesResponse.isLoading = true;

		this.workspaceService
			.getWorkspace(workspace.network.uid)
			.subscribe((network: any) => {
				// const _network = new Network(network);

				workspace.network = network;

				// redirect to selected workspace
				this.workspaceUiService.redirectToWorkspace(workspace, this.currentUser?.username);

				// reset network
				this.network = workspace;

				// sync current workspace
				this.syncIsCurrentWorkspace();

				// scroll to top
				window.scrollTo(0, 0);
			})
			.add(() => {
				this.workspacesResponse.isLoading = false;
			});
	}

	// #endregion

	// #region Helpers

	private syncIsCurrentWorkspace(): void {
		this.workspacesResponse?.objects?.forEach((workspace) => {
			workspace.isCurrentWorkspace = workspace.network.uid === this.network?.uid;
		});
	}

	/**
	 * Handle delete workspace
	 *
	 * @param uid user membership uid
	 */
	private handleDeleteMembership(uid: string): void {
		this.workspaceService.getAllWorkspacesOfUser(this.currentUser.username, 0, 1000).subscribe((data: IWorkspacePaginatedResponse) => {
			const workspace = data.objects?.find((ws: IWorkspace) => ws.uid === uid);

			if (workspace) {
				// TODO: implement deleted workspace
				this.deletedWorkspace = workspace;
				this.showWorkspaceDeletedModal = true;

				this.workspacesResponse.objects = this.workspacesResponse.objects.filter((ws: IWorkspace) => ws.uid !== uid);
			}
		});
	}

	// #endregion
}
