import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BuySellComponent } from './buy-sell.component';
import { SharedModule } from '../shared';
import { AgmCoreModule } from '@agm/core';
import { PostModule } from '../post/post.module';
import { BuySellDetailComponent } from './buy-sell-detail/buy-sell-detail.component';
import { SlideshowModule } from 'ng-simple-slideshow';

@NgModule({
	imports: [BrowserModule, AgmCoreModule, SharedModule, PostModule, SlideshowModule],
	declarations: [BuySellComponent, BuySellDetailComponent],
	bootstrap: [BuySellComponent],
	providers: [],
})
export class BuySellModule {}
