export class BeeError {
	public message: string = null;
	public type: string = null;
	public hint: string = null;
	public hiddenHint: string = null;
	public originalError: any;

	constructor(error: any) {
		Object.assign(this, error);
	}
}
