import { Component, OnInit } from '@angular/core';
import { UserService, LayoutService } from '../shared';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-zapier',
	templateUrl: './zapier.component.html',
	styleUrls: ['./zapier.component.css'],
})
export class ZapierComponent implements OnInit {
	public loadingGif = 'none';
	public currentSelectedMenu;
	public user: any;
	public isNetwork = false;
	public isExpired = false;
	public displayDialog = 'none';
	public membershipUid;
	public isDevMode = !environment.production;

	constructor(private userService: UserService, private router: Router, public layoutService: LayoutService, private location: Location) {}

	ngOnInit(): void {
		this.userService.currentUser.subscribe((userData) => {
			if (userData) {
				this.user = userData;
			} else {
				this.user = this.userService.getCurrentUser();
			}
		});

		this.layoutService.hideBd();
	}

	private clearCache(): void {
		if (localStorage.getItem('networkExpired')) {
			localStorage.removeItem('networkExpired');
		}
	}

	public onBackHandle(): void {
		this.location.back();
	}
}
