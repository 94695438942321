import { Component, OnInit } from '@angular/core';
import { UserService, NetworkService, Network, GeoLocationService, MemberService } from '../../shared';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-update-role',
	templateUrl: './update-role.component.html',
	styleUrls: ['./update-role.component.css'],
})
export class UpdateRoleComponent implements OnInit {
	public appLogo = environment.logo;
	public roleFieldForm: FormGroup;
	public currentLocation: any;
	public currentUser;
	public searchQuery;
	public role: any;
	public networkId: any;
	public currentYear: any;
	public loginDetails: any;
	public selectedRole: any;
	public membershipId: any;
	public nextUrl;
	public model = {
		department: '',
		location: '',
		role: '',
		start: '',
		title: '',
		manager: '',
		state: 'active',
	};

	public displayDepartmentsModal = 'none';
	public displayLocationsModal = 'none';
	public displayMembersModal = 'none';
	public displayError = 'none';

	public departments = [];
	public locations = [];
	public members = [];
	public roles = [];

	public selectedDepartment: any;
	public selectedLocation: any;
	public selectedMember: any;

	public location = { uid: '' };
	public department = { uid: '' };
	public member = { id: '' };

	public noDepartments = false;
	public noLocations = false;
	public noMembers = false;
	public showForm = true;

	constructor(
		private networkService: NetworkService,
		private geoLocationService: GeoLocationService,
		private memberService: MemberService,
		private fb: FormBuilder,
		private router: Router,
		private userService: UserService
	) {}

	/** 1. Set network uid from local storage
	 *  2. Set Roles from logged in details
	 *  3. Set membership uid from logged in details
	 *  4. Set role form
	 *  5. Set current year for role form
	 *  6. Set current location
	 */
	ngOnInit(): void {
		if (localStorage.getItem('network_id')) {
			// 1
			this.networkId = localStorage.getItem('network_id');
		}

		if (localStorage.getItem('userDetails')) {
			this.loginDetails = JSON.parse(localStorage.getItem('userDetails'));
			if (this.loginDetails.roles) {
				// 2
				this.roles = this.loginDetails.roles;
				this.selectedRole = this.roles[0];
			}
			if (this.loginDetails.membershipUid) {
				this.membershipId = this.loginDetails.membershipUid; // 3
			}
		}

		this.userService.currentUser.subscribe((userData) => {
			this.currentUser = userData;
		});

		this.roleFieldForm = this.fb.group({
			// 4
			department: ['', Validators.required],
			location: ['', Validators.required],
			year: ['', Validators.required],
			title: ['', Validators.required],
			manager: [''],
		});

		const date = new Date(); // 5
		this.currentYear = date.getFullYear();
		this.roleFieldForm.controls.year.setValue(this.currentYear);

		const accuracy = { enableHighAccuracy: true }; // 6
		this.geoLocationService.getLocation(accuracy).subscribe(
			(position) => {
				this.currentLocation = position;
			},
			function () {}
		);
	}

	/**
	 * Function which set default role
	 * 1. Check if selected role exists in roles array
	 * 2. If exists, set that role object as selected role
	 */
	public onRoleChange(): void {
		const index = this.roles.findIndex((role) => role.slug === this.selectedRole); // 1
		if (index > 0) {
			this.selectedRole = this.roles[index]; // 2
		}
	}

	/**
	 * Function which set roles on clicking next in role update form
	 * 1. Set role on model object
	 */
	public setRole(): void {
		if (this.selectedRole) {
			this.model.role = this.selectedRole.slug; // 1
		}
	}

	/**
	 * Function to get all locations and display it
	 * 1. Display location modal
	 * 2. Set latitude and longitude of current location
	 * 3. Call api for getting locations
	 * 4. Set location data on locations array
	 */
	public getLocations(): void {
		this.displayLocationsModal = 'block'; // 1
		this.noLocations = true;
		this.locations = [];
		this.searchQuery = '';
		const nearMeLocation = this.currentLocation.coords.latitude + ',' + this.currentLocation.coords.longitude; // 2
		this.networkService.getCitiesForRoleUpdate(nearMeLocation).subscribe((data) => {
			// 3
			this.noLocations = false;
			if (data.results && data.results.length > 0) {
				this.locations = data.results; // 4
			}
		});
	}

	public onSearchClick(): void {
		this.noLocations = true;
		this.locations = [];
		const nearMeLocation = this.currentLocation.coords.latitude + ',' + this.currentLocation.coords.longitude;
		this.networkService.searchCitiesForRoleUpdate(nearMeLocation, this.searchQuery).subscribe((data) => {
			this.noLocations = false;
			if (data.results && data.results.length > 0) {
				this.locations = data.results;
			}
		});
	}

	public onSearchEnter(event: any): void {
		if (event.keyCode === 13) {
			this.onSearchClick();
		}
	}

	/**
	 * Function to get all departments and display it
	 * 1. Display departments modal
	 * 2. Show spinner
	 * 3. Call api for getting departments
	 * 4. Hide spinner
	 * 5. Set departments array
	 */
	public getDepartments(): void {
		this.displayDepartmentsModal = 'block'; // 1
		this.noDepartments = true; // 2
		this.departments = [];
		this.networkService.getDepartments(this.networkId).subscribe((data) => {
			// 3
			this.noDepartments = false; // 4
			if (data.objects && data.objects.length > 0) {
				this.departments = data.objects; // 5
			}
		});
	}

	/**
	 * Function to get all members and display it
	 * 1. Display members modal
	 * 2. Show spinner
	 * 3. Call api for getting members
	 * 4. Check if next url exists,call pagination function
	 * 5. Hide spinner
	 * 6. Set members array
	 */
	public getMembers(): void {
		this.displayMembersModal = 'block'; // 1
		this.noMembers = true; // 2
		this.members = [];
		this.memberService.getMembers(this.networkId).subscribe((data) => {
			// 3
			this.noMembers = false; // 4
			if (data.next) {
				this.nextUrl = data.next.split('alpha')[1]; // 5
				this.paginateMembers();
			} else {
				this.nextUrl = '';
			}
			this.members = data.objects; // 6
		});
	}

	/**
	 * Function to get remaining members \
	 * 1. Check if next url exists
	 * 2. Call pagination api
	 * 3. Set next url if exists
	 * 4. Append new members objects to members array
	 */
	public paginateMembers(): void {
		if (this.nextUrl !== '') {
			// 1
			this.networkService.paginateTasks(this.nextUrl).subscribe((data) => {
				// 2
				if (data.next) {
					// 3
					this.nextUrl = data.next.split('alpha')[1];
				} else {
					this.nextUrl = '';
				}
				this.members = this.members.concat(data.objects); // 4
			});
		}
	}

	/**
	 * Function on selecting department
	 * 1.Set selected department object
	 * 2.Set its uid for radio btn on
	 */
	public onSelectDepartment(department): void {
		this.selectedDepartment = department; // 1
		this.department.uid = department.uid; // 2
	}

	/**
	 * Function to set department selected
	 * 1. Close departments modal
	 * 2. Set selected department name on role object
	 */
	public onSetDepartment(): void {
		this.displayDepartmentsModal = 'none'; // 1
		if (this.selectedDepartment) {
			this.roleFieldForm.controls.department.setValue(this.selectedDepartment.name); // 2
		}
	}

	/**
	 *  Function on selecting location
	 * 1.Set selected location object
	 * 2.Set its uid for radio btn on
	 */
	public onSelectLocation(loc): void {
		this.selectedLocation = loc; // 1
		this.location.uid = loc.uid; // 2
	}

	/**
	 * Function to set location selected
	 * 1. Close locations modal
	 * 2. Set selected location name on role object
	 */
	public onSetLocation(): void {
		this.displayLocationsModal = 'none'; // 1
		if (this.selectedLocation) {
			this.roleFieldForm.controls.location.setValue(this.selectedLocation.name); // 2
		}
	}

	/**
	 * Function on selecting manager
	 * 1. Set selected manger object
	 * 2. Set its uid for radio btn on
	 */
	public onSelectManager(member): void {
		this.selectedMember = member; // 1
		this.member.id = member.uid; // 2
	}

	/**
	 * Function to set manager selected
	 * 1. Close managers modal
	 * 2. Set selected manager name on role object
	 */
	public onSetManager(): void {
		this.displayMembersModal = 'none'; // 1
		if (this.selectedMember) {
			this.roleFieldForm.controls.manager.setValue(this.selectedMember['user'].name); // 2
		}
	}

	/**
	 * Function which executes on submitting role update form
	 * 1. Check form validation
	 * 2. Set role object for api
	 * 3. Set manager uid if it is selected
	 * 4. Call role update api
	 * 5. Call network details api on success and redirect inside network
	 * 6. Show error popup if exists
	 */
	public submitForm(): void {
		this.roleFieldForm.markAllAsTouched(); // 1
		if (this.roleFieldForm.valid) {
			this.model.department = this.selectedDepartment.uid; // 2
			this.model.location = this.selectedLocation.uid;
			this.model.start = this.roleFieldForm.value.year;
			this.model.title = this.roleFieldForm.value.title;
			if (this.roleFieldForm.value.manager) {
				// 3
				this.model.manager = this.selectedMember.uid;
			}

			// TODO: use loading spinner
			this.networkService
				.updateRoleFields(this.model, this.membershipId) // 4
				.subscribe(
					(data) => {
						this.networkService.get(data['network']['uid']).subscribe(
							// 5
							(networkData) => {
								this.networkService.setNetwork(new Network(networkData)); // 7
								this.router.navigateByUrl('/network');
							}
						);
					},
					() => {
						this.displayError = 'block';
					}
				);
		}
	}
}
