import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';
import { NetworkService, LayoutService, ProfilesService } from '../shared';

@Component({
	selector: 'app-location-detail',
	templateUrl: './location-detail.component.html',
	styleUrls: ['./location-detail.component.css'],
})
export class LocationDetailComponent implements OnInit {
	public locUid;
	public location;
	public showMenu = false;
	public postType = '';
	public showPostCreateForm = false;
	public locObject;
	public displayCheckIn = 'none';
	public range = -1;
	public review;
	public isError = false;
	// Reports
	public reportReasons: any[] = [];
	public reportModal = 'none';
	public flag = { value: '', text: '' };
	public reportText;
	public isReported = false;

	constructor(
		private profileService: ProfilesService,
		private activatedRoute: ActivatedRoute,
		public networkService: NetworkService,
		public layoutService: LayoutService,
		private pageLoadingUiService: PageLoadingUiService
	) {}

	ngOnInit(): void {
		this.activatedRoute.queryParams.subscribe((params) => {
			if (params['id']) {
				this.locUid = params['id'];
				this.getLocationDetail(params['id']);
			}
		});

		this.layoutService.showBackdrop.subscribe((data) => {
			if (data === 'event-post-form' || data === 'hide') {
				this.showPostCreateForm = false;
				this.postType = '';
				this.locObject = '';
			}
		});
	}

	private getLocationDetail(uid): void {
		this.networkService.getLocationDetail(uid).subscribe((data) => {
			this.location = data;
		});
	}

	public goToFourSquare(): void {
		window.open(`https://foursquare.com/v/Gülen Bebe/${this.location['venueId']}`, '_blank');
	}

	public goToGoogleMap(): void {
		window.open(`http://maps.google.com/maps?z=12&t=m&q=loc:${this.location.point.latitude}+${this.location.point.longitude}`, '_blank');
	}

	public activateButton(): void {
		this.showMenu = !this.showMenu;
		if (this.showMenu) {
			this.layoutService.showBd('post-menu');
		} else {
			this.layoutService.hideBd();
		}
	}

	public onRecommendClick(): void {
		this.showPostCreateForm = !this.showPostCreateForm;
		this.showMenu = !this.showMenu;
		if (this.showPostCreateForm) {
			this.postType = 'recommend';
			const x = {};
			if (this.location['point'] && this.location['point']['latitude']) {
				x['latitude'] = this.location['point']['latitude'];
				x['longitude'] = this.location['point']['longitude'];
				x['name'] = this.location['name'];
				x['uid'] = this.location.uid;
				this.locObject = x;
			}
		} else {
			this.postType = '';
			this.locObject = '';
			this.layoutService.hideBd('event-post-form');
		}
	}

	public onShareClick(): void {
		this.showPostCreateForm = !this.showPostCreateForm;
		this.showMenu = !this.showMenu;
		if (this.showPostCreateForm) {
			this.postType = 'post';
			const x = {};
			if (this.location['point'] && this.location['point']['latitude']) {
				x['latitude'] = this.location['point']['latitude'];
				x['longitude'] = this.location['point']['longitude'];
				x['name'] = this.location['name'];
				x['uid'] = this.location.uid;
				this.locObject = x;
			}
		} else {
			this.postType = '';
			this.locObject = '';
			this.layoutService.hideBd('event-post-form');
		}
	}

	public onCheckinClick(): void {
		this.showMenu = !this.showMenu;
		this.displayCheckIn = 'block';
	}

	public closeCheckIn(): void {
		this.displayCheckIn = 'none';
		this.review = '';
		this.range = -1;
		this.isError = false;
		this.layoutService.hideBd();
	}

	public handleLocationCheckin(): void {
		if (this.review) {
			this.pageLoadingUiService.setPageLoading(true);
			this.networkService.checkIn(this.location.uid, this.review, this.range).subscribe(() => {
				this.closeCheckIn();
				this.pageLoadingUiService.setPageLoading(false);
			});
		} else {
			this.pageLoadingUiService.setPageLoading(false);
			this.isError = true;
		}
	}

	public handlePostReport(): void {
		this.reportModal = 'block';
		this.flag['value'] = '';
		this.flag['text'] = '';
		this.reportReasons = [];
		this.profileService.getReasons().subscribe((data) => {
			if (data.post) {
				this.reportReasons = Object.entries(data.post);
			}
		});
	}

	public onPostReport(): void {
		if (this.flag['value']) {
			this.pageLoadingUiService.setPageLoading(true);
			const obj = {
				contentType: 'location',
				contentUid: this.location['uid'],
				reason: this.flag['value'],
				text: this.flag['text'],
			};

			this.networkService.reportAnAction(obj).subscribe(
				(data) => {
					this.location['flagged'] = data.uid;
					this.resetReports();
					this.reportText = 'Your feedback is reported successfully';
					this.isReported = true;
					this.pageLoadingUiService.setPageLoading(false);
					setTimeout(() => {
						this.isReported = false;
					}, 7000);
				},
				() => {
					this.resetReports();
					this.pageLoadingUiService.setPageLoading(false);
				}
			);
		}
	}

	public resetReports(): void {
		this.flag['value'] = '';
		this.flag['text'] = '';
		this.reportReasons = [];
		this.reportModal = 'none';
	}
}
