import { ImageAttachment } from 'projects/bee-attachment/src/lib/shared/models/image-attachment';
import { IEventAttachment } from './event-attachment';
import { IFileAttachment } from './file-attachment';
import { ILocationAttachment } from './location-attachment';
import { IPersonAttachment } from './person-attachment';
import { IURLAttachment } from './url-attachment';

export interface IBaseAttachment {
	images?: ImageAttachment[];
	url?: IURLAttachment;
	files?: IFileAttachment[];
	person?: IPersonAttachment;
	event?: IEventAttachment;
	location?: ILocationAttachment;
}

export class BaseAttachment implements IBaseAttachment {
	constructor(data?: IBaseAttachment) {
		Object.assign(this, data);
	}
}
