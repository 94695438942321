/* eslint-disable curly */
/* eslint-disable spellcheck/spell-checker */
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class UiAvatarsService {
	/**
	 * List of colors for each month
	 */
	public colors: any[] = [
		{ background: '#fcefe6' },
		{ background: '#ffe4e4' },
		{ background: '#eaffd0' },
		{ background: '#ffe0f7' },
		{ background: '#f4d6c4' },
		{ background: '#d9e8fb' },
		{ background: '#e0ffff' },
		{ background: '#fafafa' },
		{ background: '#f0f4c3' },
		{ background: '#f5deb3' },
		{ background: '#e6e6e6' },
		{ background: '#e0eee0' },
	];

	constructor() {}

	public getAvatarUrl(name: string, size: number, colorRef: string, type: string): string {
		let colorIndex = 0;

		if (type === 'date') {
			colorIndex = moment(colorRef).month();
		} else if (type === 'uid') {
			const firstNumberMatch = colorRef?.match(/[1-12]/);

			colorIndex = firstNumberMatch ? parseInt(firstNumberMatch[0], 10) - 1 : 0;
		}

		const bgColor = this.colors[colorIndex]?.background || '#e0eee0';

		return `https://ui-avatars.com/api/?bold=false&=${size}&length=2&background=${bgColor.replace('#', '')}&color=${this.darken(bgColor, 0.6).replace(
			'#',
			''
		)}80&name=${name}&index=${colorIndex}`;
	}

	// #region Helpers

	private darken(color, amount): string {
		const r = parseInt(color.substring(1, 3), 16);
		const g = parseInt(color.substring(3, 5), 16);
		const b = parseInt(color.substring(5, 7), 16);
		const hsl = this.rgbToHsl(r, g, b);
		const newL = Math.max(0, hsl[2] - amount);
		const newRgb = this.hslToRgb(hsl[0], hsl[1], newL);
		const newColor = `#${this.componentToHex(newRgb[0])}${this.componentToHex(newRgb[1])}${this.componentToHex(newRgb[2])}`;
		return newColor;
	}

	private rgbToHsl(r, g, b): any {
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		(r /= 255), (g /= 255), (b /= 255);
		const max = Math.max(r, g, b),
			min = Math.min(r, g, b);

		let h, s;

		const l = (max + min) / 2;

		if (max === min) {
			h = s = 0;
		} else {
			const d = max - min;
			s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
			switch (max) {
				case r:
					h = (g - b) / d + (g < b ? 6 : 0);
					break;
				case g:
					h = (b - r) / d + 2;
					break;
				case b:
					h = (r - g) / d + 4;
					break;
			}
			h /= 6;
		}
		return [h, s, l];
	}

	private hslToRgb(h, s, l): any {
		let r, g, b;

		if (s === 0) {
			r = g = b = l; // achromatic
		} else {
			const hue2rgb = (_p: number, _q: number, t: number): number => {
				if (t < 0) t += 1;
				if (t > 1) t -= 1;
				if (t < 1 / 6) return _p + (_q - _p) * 6 * t;
				if (t < 1 / 2) return _q;
				if (t < 2 / 3) return _p + (_q - _p) * (2 / 3 - t) * 6;

				return _p;
			};

			const q = l < 0.5 ? l * (1 + s) : l + s - l * s,
				p = 2 * l - q;

			r = hue2rgb(p, q, h + 1 / 3);
			g = hue2rgb(p, q, h);
			b = hue2rgb(p, q, h - 1 / 3);
		}

		return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
	}

	private componentToHex(c): string {
		const hex = c.toString(16);

		return hex.length === 1 ? '0' + hex : hex;
	}

	// #endregion
}
