import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentPopupComponent } from './comment-popup/comment-popup.component';
import { CommentInputComponent } from './components/comment-input/comment-input.component';
import { CommentListComponent } from './components/comment-list/comment-list.component';
import { RouterModule, Routes } from '@angular/router';
import { BeeAvatarModule } from 'projects/bee-ui/src/lib/modules/avatar/avatar.module';
import { SharedModule } from '..';
import { OvalLoaderModule } from '@shared/oval-loader/oval-loader.module';

const appRoutes: Routes = [{
	path: '',
	component: CommentPopupComponent
}];

@NgModule({
	declarations: [
		CommentPopupComponent,
		CommentInputComponent,
		CommentListComponent
	],
	imports: [
		CommonModule,
		BeeAvatarModule,
		RouterModule.forChild(appRoutes),
		SharedModule,
		OvalLoaderModule
	],
	exports:[
		CommentPopupComponent,
		CommentInputComponent,
		CommentListComponent
	]
})
export class CommentPopupModule { }
