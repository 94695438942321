import { Component, OnInit, ViewChild } from '@angular/core';
import { PrivacyComponent, UserService, NetworkService, PostService, LayoutService } from '../../shared';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { MixPanelService } from '@core/services/third-party/mix-panel.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { SegmentAnalyticsService } from '@core/services/third-party/segment-analytics.service';

@Component({
	selector: 'app-create-todolist',
	templateUrl: './create-todolist.component.html',
	styleUrls: ['./create-todolist.component.css'],
})
export class CreateTodolistComponent implements OnInit {
	@ViewChild(PrivacyComponent, { static: true }) privacy;
	public model = { subcircle: '' };
	public role;
	public roles;
	public network;
	public loadingGif = 'none';
	// Repeat Cycle
	public repeatcycle_options = 'none';
	public selectedRepeatOption = 'Daily';
	public repeatOptions = ['daily', 'weekly', 'monthly', 'one-off'];
	public repeatObj = { option: '' };
	public showSelectDateOption = false;
	public oneOff_date;
	//
	public defaultCp;
	public membershipFields;
	public circleId;
	public currentNetworkDefaultCp;
	//
	public privacy_setting = 'none';
	public custom_privacy = 'none';
	public members = null;
	public currentUser;
	public forNames;
	public totalMembersCount = null;
	public type = 'create-todolist';
	public departments = [];
	public personalFilters = [];
	public SubscriptionTemplates = [];
	public todolist = {
		name: '',
		description: '',
		collaboration: true,
		repeatCycle: '',
		state: 'active',
		statusPost: true,
	};
	public todolistId;
	error: string;
	public newDate = new Date();

	public errorDialog = 'none';

	public errorText = '';

	placement = 'bottom';
	currentDate = {
		year: this.newDate.getFullYear(),
		month: this.newDate.getMonth() + 1,
		day: this.newDate.getDate(),
	};

	constructor(
		private userService: UserService,
		private networkService: NetworkService,
		private postService: PostService,
		private router: Router,
		private _location: Location,
		private activatedRoute: ActivatedRoute,
		private parserFormatter: NgbDateParserFormatter,
		private layoutService: LayoutService,
		private translate: TranslateService,
		private mixPanelService: MixPanelService,
		private segmentService: SegmentAnalyticsService
	) {}

	ngOnInit(): void {
		if (localStorage.getItem('network_id')) {
			this.circleId = localStorage.getItem('network_id');
		}
		this.updateSelectionFields();
		this.currentUser = this.userService.getCurrentUser();
		this.network = this.networkService.getCurrentNetwork();
		this.role = this.network.membership.memberRole.role;
		const regexCreate = new RegExp(/^\/network\/create-todolist\/*$/);
		const regexEdit = new RegExp(/^\/network\/edit-todolist\/[a-zA-Z0-9_.-]*$/);
		const regexEvent = new RegExp(/^\/network\/generate-actions\/[a-zA-Z0-9_.-]*$/);
		this.networkService.currentNetwork.subscribe((data) => {
			if (data.membership && data.membership.memberRole) {
				data.membership.memberRole.fields.forEach((element) => {
					this.networkService.departmentsReady.subscribe((departmentsLoaded) => {
						if (departmentsLoaded) {
							this.networkService.currentDepartments.subscribe((departments) => {
								this.departments = departments.objects;
							});
						}
					});
				});
				data.membership.memberRole.fields.forEach((value) => {
					if (value.CP === true) {
						this.personalFilters.push(value);
					}
				});
				this.roles = data['roles'];
			}
		});

		if (this.network.SubscriptionTemplates) {
			this.SubscriptionTemplates = this.network.SubscriptionTemplates;
		}
		// CREATE TODO LIST----------------------
		if (regexCreate.test(this.router.url)) {
			if (this.network.membership.memberRole && this.network.membership.memberRole.defaultContentPrivacy) {
				this.defaultCp = this.network['membership']['memberRole']['defaultContentPrivacy'];
				this.membershipFields = this.network['membership']['memberRole']['fields'];
			} else {
				if (!localStorage.getItem(`${this.circleId}`)) {
					this.forNames = this.role['name'];
				}
			}
			// Default repeat option is daily
			this.todolist.repeatCycle = 'daily';
			this.privacy.isPrivate = false;
		} else if (regexEdit.test(this.router.url)) {
			this.type = 'edit-todolist';
			this.activatedRoute.params.subscribe((params: Params) => {
				this.todolistId = params['id'];
				this.loadingGif = 'block';
				this.networkService.getToDoListDetail(this.todolistId, false).subscribe((data) => {
					this.loadingGif = 'none';
					if (data) {
						this.todolist['name'] = data['name'];
						this.todolist['description'] = data['description'];
						this.todolist['collaboration'] = data['collaboration'];
						this.todolist['statusPost'] = data['statusPost'];
						this.role = '';
						if (data['contentPrivacy']) {
							this.privacy['content_privacy'] = data['contentPrivacy'];
							if (data['contentPrivacy'].department) {
								const index = this.departments.findIndex((department) => department.uid === data['contentPrivacy'].department);
								if (index > -1) {
									this.privacy['selectedDepartment'] = this.departments[index];
								}
							} else {
								this.privacy['selectedDepartment'] = null;
							}
							if (data['contentPrivacy'].role) {
								const index = this.roles.findIndex((rol) => rol.name === data['contentPrivacy'].role);
								this.privacy['role'] = this.roles[index];
							} else {
								this.privacy['role'] = null;
							}

							if (!data['contentPrivacy'].role && !data['contentPrivacy'].department) {
								const field = Object.keys(data['contentPrivacy']);
								this.personalFilters.forEach((element) => {
									if (element.key === field[0] && field[0] !== 'manager') {
										this.privacy['personalFilter'] = element;
									}
								});
								if (data['contentPrivacy'].status) {
									this.privacy['selectByStatusValue'] = data['contentPrivacy'];
								} else if (data['contentPrivacy'].renewalStatus) {
									this.privacy['selectByRenewalValue'] = data['contentPrivacy'];
								} else if (data['contentPrivacy'].membership_level) {
									this.privacy['selectByLevelValue'] = data['contentPrivacy'];
								} else if (data['contentPrivacy'].manager) {
									if (this.privacy.myTeam && this.privacy.myTeam.key) {
										this.privacy['personalFilter'] = this.privacy.myTeam;
									}
								}
							}
						} else {
							this.privacy['content_privacy'] = null;
							this.privacy['selectedDepartment'] = null;
							this.privacy['role'] = null;
						}
						if (data['customPrivacy']) {
							this.privacy['custom_privacy'] = data['customPrivacy'];
						} else {
							this.privacy['custom_privacy'] = [];
						}
						if (data['channel']) {
							this.model['channel'] = data['channel'].uid;
							this.privacy.selectedSubCircle = data['channel'].name;
							this.privacy.channel = data['channel'];
						} else {
							this.model['channel'] = null;
							this.privacy.selectedSubCircle = 'All';
						}
						this.updateRepeatCycleBasedOnToDoList(data);
						this.updatePrivacyStringBasedOnToDoList(data);
					}
				});
			});
		} else if (regexEvent.test(this.router.url)) {
			this.type = 'generate-actions';
			this.activatedRoute.params.subscribe((params: Params) => {
				this.todolistId = params['id'];
				this.loadingGif = 'block';
				this.postService.get(this.todolistId).subscribe((data) => {
					this.loadingGif = 'none';
					// Set default values
					this.todolist.name = `${data.title} Actions`;
					this.selectedRepeatOption = 'One-off';
					this.repeatObj.option = 'one-off';
					this.showSelectDateOption = true;
					this.todolist.collaboration = true;
					const today = new Date();
					const tomorrow = new Date(today);
					tomorrow.setDate(today.getDate() + 1);
					// this.oneOff_date = tomorrow;
					this.oneOff_date = {
						year: tomorrow.getFullYear(),
						month: tomorrow.getMonth() + 1,
						day: tomorrow.getDate(),
					};

					this.privacy['content_privacy'] = null;
					this.privacy['selectedDepartment'] = null;
					this.privacy['selectedRole'] = null;
					this.privacy['personalFilter'] = null;
					this.privacy['selectByStatusValue'] = null;
					this.privacy['selectByRenewalValue'] = null;
					this.privacy['selectByLevelValue'] = null;
					this.privacy.selectedSubCircle = null;
					this.model['subcircle'] = null;

					if (data) {
						if (data['contentPrivacy']) {
							this.privacy['content_privacy'] = data['contentPrivacy'];
							if (data['contentPrivacy'].department) {
								const index = this.departments.findIndex((department) => department.value === data['contentPrivacy'].department);
								if (index > -1) {
									this.privacy['selectedDepartment'] = this.departments[index];
								}
							} else {
								this.privacy['selectedDepartment'] = null;
							}
							if (data['contentPrivacy'].role) {
								const index = this.roles.findIndex((rol) => rol.name === data['contentPrivacy'].role);
								this.privacy['selectedRole'] = this.roles[index];
							} else {
								this.privacy['role'] = null;
							}
							if (!data['contentPrivacy'].role && !data['contentPrivacy'].department) {
								const field = Object.keys(data['contentPrivacy']);
								this.personalFilters.forEach((element) => {
									if (element.key === field[0]) {
										this.privacy['personalFilter'] = element;
									}
								});
								if (data['contentPrivacy'].status) {
									this.privacy['selectByStatusValue'] = data['contentPrivacy'];
								} else if (data['contentPrivacy'].renewalStatus) {
									this.privacy['selectByRenewalValue'] = data['contentPrivacy'];
								} else if (data['contentPrivacy'].membership_level) {
									this.privacy['selectByLevelValue'] = data['contentPrivacy'];
								}
							}
						} else {
							this.privacy['content_privacy'] = null;
							this.privacy['selectedDepartment'] = null;
							this.privacy['selectedRole'] = null;
						}
						if (data['customPrivacy']) {
							this.privacy['custom_privacy'] = data['customPrivacy'];
						} else {
							this.privacy['custom_privacy'] = [];
						}
						if (data['channel']) {
							this.model['channel'] = data['channel'].uid;
							this.privacy.selectedSubCircle = data['channel'].name;
							this.privacy.channel = data['channel'];
						} else {
							this.model['channel'] = null;
							this.privacy.selectedSubCircle = 'All';
						}
						this.updatePrivacyStringBasedOnToDoList(data);
					}
				});
			});
		}

		this.privacy.privacyModalStatus.subscribe((data) => {
			if (data === 'closed') {
				this.layoutService.hideBd();
				this.updatePrivacyValues();
			}
		});
	}

	public onPrivacyClick(): void {
		this.privacy.showPrivacy();
		this.privacy.privacy_settings = 'block';
	}

	/**
	 * Function for creating a todolist
	 * 1. Check and show error if name/oneoff date is not selected
	 * 2. Call privacy update functions
	 * 4. Set oneOFf is repeat cycle is one off
	 * 5. Call todo list create api
	 * 6. Navigate back todo todolists page
	 */
	onCreate() {
		if (!this.todolist['name']) {
			// 1
			this.error = this.translate.instant('Please enter a title');
		} else if (this.showSelectDateOption && !this.oneOff_date) {
			this.error = this.translate.instant('Please select a date');
		} else {
			this.loadingGif = 'block';
			this.updatePrivacyValues(); // 2
			this.updateSelectionFields();
			if (this.oneOff_date) {
				this.todolist.repeatCycle = 'one_off';
				this.todolist['oneOff'] = this.parserFormatter.format(this.oneOff_date);
			}

			// return;
			this.networkService.createToDoList(this.todolist, this.network.uid).subscribe(
				(data) => {
					// 4
					if (data) {
						this.loadingGif = 'none';
						this.router.navigate(['/network/todolist']); // 5
					}

					this.mixPanelService.track('Created Team', {
						workspace: this.network.name || 'NA',
						appVersion: environment.version,
						language: this.translate.currentLang,
						user: this.currentUser.username || 'NA',
						username: this.currentUser.name || 'NA',
						date: moment().format('YYYY-MM-DD'),
					});

					this.segmentService.trackEvent('Created Team', {
						workspace: this.network.name || 'NA',
						appVersion: environment.version,
						language: this.translate.currentLang,
						user: this.currentUser.username || 'NA',
						username: this.currentUser.name || 'NA',
						date: moment().format('YYYY-MM-DD'),
					});
				},
				(err) => {
					this.loadingGif = 'none';
					this.loadingGif = 'none';
					this.errorDialog = 'block';
					this.errorText = err.errors.error.detail ? err.errors.error.detail : 'Something went wrong';
				}
			);
		}
	}

	onUpdateToDoList() {
		if (this.todolistId) {
			if (!this.todolist['name']) {
				this.error = this.translate.instant('Please enter a title');
			} else {
				this.updatePrivacyValues();
				if (this.oneOff_date) {
					this.todolist.repeatCycle = 'one_off';
					this.todolist['oneOff'] = this.parserFormatter.format(this.oneOff_date);
				}

				// this.todolist['status'] = 'archived';
				this.loadingGif = 'block';
				this.networkService.updateToDoList(this.todolist, this.todolistId).subscribe(
					(data) => {
						this._location.back();
						this.loadingGif = 'none';
					},
					(err) => {
						this.loadingGif = 'none';
						this.errorDialog = 'block';
						this.errorText = err.errors.error.detail ? err.errors.error.detail : 'Something went wrong';
					}
				);
			}
		}
	}

	/** ** function to update previos and current selection values in privacy */
	updateSelectionFields() {
		if (localStorage.getItem(`${this.circleId}`)) {
			const retrivedCp = JSON.parse(localStorage.getItem(`${this.circleId}`));
			if (retrivedCp.privacyType === 'role') {
				this.privacy['selectedRole'] = retrivedCp;
				this.forNames = retrivedCp.name;
				this.privacy.previousSelection = retrivedCp.name;
				this.privacy.currentSelection = retrivedCp.name;
			}
			if (retrivedCp.privacyType === 'department') {
				this.privacy['selectedDepartment'] = retrivedCp;
				if (retrivedCp.valueLabel) {
					this.privacy.previousSelection = retrivedCp.valueLabel;
					this.privacy.currentSelection = retrivedCp.valueLabel;
					this.forNames = retrivedCp.valueLabel;
				}
				if (retrivedCp.name) {
					this.privacy.previousSelection = retrivedCp.name;
					this.privacy.currentSelection = retrivedCp.name;
					this.forNames = retrivedCp.name;
				}
			}
			if (retrivedCp.privacyType === 'subCircle' || retrivedCp.privacyType === 'channel') {
				this.model['subcircle'] = retrivedCp.uid;
				this.privacy.channel = retrivedCp;
				this.forNames = retrivedCp.name;
				this.privacy.previousSelection = retrivedCp.name;
				this.privacy.currentSelection = retrivedCp.name;
			}
			if (retrivedCp.privacyType === 'personalFilter') {
				this.privacy['personalFilter'] = retrivedCp;
				this.forNames = retrivedCp.valueLabel;
				this.privacy.previousSelection = retrivedCp.valueLabel;
				this.privacy.currentSelection = retrivedCp.valueLabel;
			}
			if (Array.isArray(retrivedCp) && retrivedCp.includes('status') === true) {
				this.privacy.selectByStatusValue = retrivedCp;
				this.privacy.previousSelection = retrivedCp[1];
				this.privacy.currentSelection = retrivedCp[1];
				this.forNames = retrivedCp[1];
			}
			if (Array.isArray(retrivedCp) && retrivedCp.includes('renewalFrequency') === true) {
				this.privacy.selectByRenewalValue = retrivedCp;
				this.privacy.previousSelection = retrivedCp[1];
				this.privacy.currentSelection = retrivedCp[1];
				this.forNames = retrivedCp[1];
			}
			if (retrivedCp.privacyType === 'selectByLevel') {
				this.privacy['selectByLevelValue'] = retrivedCp;
				this.privacy.previousSelection = retrivedCp.name;
				this.privacy.currentSelection = retrivedCp.name;
				this.forNames = retrivedCp.name;
			}
			if (Array.isArray(retrivedCp) && !retrivedCp.includes('status') && !retrivedCp.includes('renewalFrequency')) {
				this.privacy['custom_privacy'] = retrivedCp;
				retrivedCp.forEach((member) => {
					if (member.user) {
						this.forNames = member.user.name;
						this.privacy.previousSelection = member.user.name;
						this.privacy.currentSelection = member.user.name;
					} else if (member.name) {
						this.forNames = member.name;
						this.privacy.previousSelection = member.name;
						this.privacy.currentSelection = member.name;
					} else {
						this.forNames = member.username;
						this.privacy.previousSelection = member.username;
						this.privacy.currentSelection = member.username;
					}
				});
			}
			if (retrivedCp === 'Everyone') {
				this.forNames = this.translate.instant('Everyone');
				this.privacy.previousSelection = this.translate.instant('Everyone');
				this.privacy.currentSelection = this.translate.instant('Everyone');
			}
		} else {
			this.forNames = 'Everyone';
			this.privacy.previousSelection = 'Everyone';
			this.privacy.currentSelection = 'Everyone';
		}
	}
	/**
	 * Function to update privacy string based in collection.
	 */
	updatePrivacyStringBasedOnToDoList(todoList) {
		this.forNames = '';
		if (todoList['customPrivacy']) {
			todoList['customPrivacy'].forEach((cp, index) => {
				if (cp.username) {
					this.forNames = `${this.forNames}${index > 0 ? ', ' : ' '}${cp.username}`;
				} else if (cp.name) {
					this.forNames = `${this.forNames}${index > 0 ? ', ' : ' '}${cp.name}`;
				}
			});
		} else if (todoList['contentPrivacy']) {
			if (todoList['contentPrivacy'].role && todoList['contentPrivacy'].department) {
				const index = this.departments.findIndex((department) => department.value === todoList['contentPrivacy'].department);
				if (index > -1) {
					this.forNames = this.departments[index]['valueLabel'] + ' , ' + todoList['contentPrivacy'].role;
				}
			} else if (todoList['contentPrivacy'].role) {
				this.forNames = todoList['contentPrivacy'].role;
			} else if (todoList['contentPrivacy'].department) {
				const index = this.departments.findIndex((department) => department.uid === todoList['contentPrivacy'].department);
				if (index > -1) {
					this.forNames = this.departments[index]['name'];
				}
			} else if (!todoList['contentPrivacy'].role || !todoList['contentPrivacy'].department) {
				const field = Object.keys(todoList['contentPrivacy']);
				this.personalFilters.forEach((element) => {
					if (element.key === field[0] && field[0] !== 'manager') {
						this.forNames = element.valueLabel;
					}
				});

				if (todoList['contentPrivacy'].status) {
					this.forNames = todoList['contentPrivacy'].status;
				} else if (todoList['contentPrivacy'].renewalStatus) {
					this.forNames = todoList['contentPrivacy'].renewalStatus;
				} else if (todoList['contentPrivacy'].membership_level) {
					const index = this.SubscriptionTemplates.findIndex((val) => val.uid === todoList['contentPrivacy'].membership_level);
					if (index > -1) {
						this.forNames = this.SubscriptionTemplates[index]['name'];
					}
				} else if (todoList['contentPrivacy'].manager) {
					this.forNames = this.translate.instant('My Team');
				}
			}
		} else if (todoList['channel'] && todoList['channel']['uid']) {
			this.forNames = todoList['channel'].name;
		} else {
			this.forNames = 'Everyone';
		}
	}

	// Function to update repeat cycle options based on todo list
	updateRepeatCycleBasedOnToDoList(data) {
		if (data.repeatCycle !== 'one_off') {
			this.selectedRepeatOption = data.repeatCycle;
			this.todolist.repeatCycle = data.repeatCycle;
			this.repeatObj = { option: data.repeatCycle };
		} else {
			this.selectedRepeatOption = 'one-off';
			this.showSelectDateOption = true;
			this.todolist.repeatCycle = data.repeatCycle;
			this.repeatObj = { option: 'one-off' };

			const date = new Date(data.oneOff);
			const startMonth = date.getUTCMonth() + 1;
			const startDay = date.getUTCDate();
			const startYear = date.getUTCFullYear();
			const startHours = date.getUTCHours();
			const startMinutes = date.getUTCMinutes();
			const startSeconds = date.getUTCSeconds();
			// var utcDate = start_year + "-" + start_month + "-" + start_day + " " + start_hours + ":" + start_minutes + ":" + start_seconds;
			const utcDate = startYear + '-' + startMonth + '-' + startDay;
			this.todolist['oneOff'] = utcDate;
			this.oneOff_date = {
				year: startYear,
				month: startMonth,
				day: startDay,
			};
		}
	}

	public updatePrivacyStringBasedOnPrivacyComponent(): void {
		this.forNames = '';
		if (this.privacy.isPrivate) {
			this.forNames = this.currentUser.name;
		} else if (this.privacy.custom_privacy && this.privacy.custom_privacy.length > 0) {
			const names = [];
			this.privacy.custom_privacy.forEach((user) => {
				if (user['user']) {
					names.push(user['user'].name);
				} else if (user['name']) {
					names.push(user['name']);
				} else {
					names.push(user.username);
				}
			});
			this.forNames = names.join(',');
		} else {
			if (this.privacy.selectedRole && this.privacy.selectedDepartment) {
				this.forNames = this.privacy.selectedRole.name + ', ' + this.privacy.selectedDepartment.valueLabel;
			} else if (this.privacy.selectedRole) {
				let roleName;
				if (this.privacy.selectedRole.name) {
					roleName = this.privacy.selectedRole.name;
				} else {
					roleName = this.privacy.selectedRole;
				}
				this.forNames = roleName;
			} else if (this.privacy.selectedDepartment) {
				let departmentName;
				if (this.privacy.selectedDepartment.valueLabel) {
					departmentName = this.privacy.selectedDepartment.valueLabel;
					this.forNames = departmentName;
				}
				if (this.privacy.selectedDepartment.name && this.privacy.selectedDepartment.uid) {
					departmentName = this.privacy.selectedDepartment.name;
					this.forNames = departmentName;
				}
			} else if (this.privacy.personalFilter) {
				let personalFilter = '';
				personalFilter = this.privacy.personalFilter.valueLabel;
				this.forNames = personalFilter;
			} else if (this.privacy.channel) {
				this.todolist['channel'] = this.privacy.channel.uid;
				this.forNames = this.privacy.channel.name;
			} else if (this.privacy.subCircle) {
				this.todolist['channel'] = this.privacy.subCircle.uid;
				this.forNames = this.privacy.subCircle.name;
			} else {
				this.forNames = 'Everyone';
			}
		}
	}

	/**
	 * Update cp value according to the detail from the privacy modal.
	 */
	updatePrivacyValues(isEdit = false) {
		if (this.privacy.isPrivate) {
			delete this.todolist['content_privacy'];
			this.todolist['collaboration'] = false;
			this.todolist['custom_privacy'] = [];
			this.todolist['custom_privacy'].push(this.currentUser.userId);
			delete this.todolist['collaboration'];
		} else if (this.privacy.custom_privacy && this.privacy.custom_privacy.length > 0) {
			delete this.todolist['content_privacy'];
			this.todolist['custom_privacy'] = [];
			const names = [];
			// -
			const newObject = this.privacy.custom_privacy;
			newObject.forEach((member) => {
				member.privacyType = 'customPrivacy';
			});
			localStorage.setItem(`${this.circleId}`, JSON.stringify(newObject));
			this.privacy.custom_privacy.forEach((user) => {
				if (user['user']) {
					this.todolist['custom_privacy'].push(user['user'].userId);
					names.push(user['user'].name);
				} else if (user.userId) {
					this.todolist['custom_privacy'].push(user.userId);
					names.push(user.username);
				} else {
					this.todolist['custom_privacy'].push(user.id);
					names.push(user.username);
				}
			});
			if (isEdit) {
				this.privacy.showCustomPrivacy();
			}
			delete this.todolist['collaboration'];
		} else {
			let roleString = null;
			let departmentString = null;
			let personalFilterString = null;
			let selectByStatusString = null;
			let selectByRenewalString = null;
			let selectByLevelString = null;

			delete this.todolist['custom_privacy'];
			if (this.privacy.selectedRole && this.privacy.selectedDepartment) {
				roleString = '"role": "' + this.privacy.selectedRole.slug + '"';
				departmentString = '"department": "' + this.privacy.selectedDepartment.value + '"';
			} else if (this.privacy.selectedRole) {
				roleString = '"role": "' + this.privacy.selectedRole.slug + '"';
				// * ******************SPACE AFTER : ******************* */
				roleString = '"role": "' + this.privacy.selectedRole.slug + '"';
				// * ************************************** */
				const newObject = this.privacy.selectedRole;
				newObject.privacyType = 'role';
				localStorage.setItem(`${this.circleId}`, JSON.stringify(newObject));
			} else if (this.privacy.selectedDepartment) {
				if (this.privacy.selectedDepartment.value) {
					departmentString = '"department": "' + this.privacy.selectedDepartment.value + '"';
				}
				if (this.privacy.selectedDepartment.uid && this.privacy.selectedDepartment.name) {
					departmentString = '"department": "' + this.privacy.selectedDepartment.uid + '"';
				}
				const newObject = this.privacy.selectedDepartment;
				newObject.privacyType = 'department';
				localStorage.setItem(`${this.circleId}`, JSON.stringify(newObject));
			} else if (this.privacy.personalFilter) {
				let personalFilterName = '';
				personalFilterName = this.privacy.personalFilter.valueLabel;
				personalFilterString = '"' + this.privacy.personalFilter.key + '": "' + this.privacy.personalFilter.value + '"';
				// -
				const newObject = this.privacy.personalFilter;
				newObject.privacyType = 'personalFilter';
				localStorage.setItem(`${this.circleId}`, JSON.stringify(newObject));
			} else if (this.privacy.selectByStatusValue) {
				if (this.privacy.selectByStatusValue.status) {
					selectByStatusString = '"status": "' + this.privacy.selectByStatusValue.status + '"';
				} else {
					selectByStatusString = '"status": "' + this.privacy.selectByStatusValue[0] + '"';
					// -
					const newObject = this.privacy.selectByStatusValue;
					if (newObject.includes('status') === false) {
						newObject.push('status');
					}
					localStorage.setItem(`${this.circleId}`, JSON.stringify(newObject));
				}
			} else if (this.privacy.selectByRenewalValue) {
				if (this.privacy.selectByRenewalValue.renewalStatus) {
					selectByRenewalString = '"renewalStatus": "' + this.privacy.selectByRenewalValue.renewalStatus + '"';
				} else {
					selectByRenewalString = '"renewalStatus": "' + this.privacy.selectByRenewalValue[0] + '"';
					const newObject = this.privacy.selectByRenewalValue;
					if (newObject.includes('renewalFrequency') === false) {
						newObject.push('renewalFrequency');
					}
					localStorage.setItem(`${this.circleId}`, JSON.stringify(newObject));
				}
			} else if (this.privacy.selectByLevelValue) {
				if (this.privacy.selectByLevelValue.uid) {
					selectByLevelString = '"membership_level": "' + this.privacy.selectByLevelValue.uid + '"';
					const newObject = this.privacy.selectByLevelValue;
					newObject.privacyType = 'selectByLevel';
					localStorage.setItem(`${this.circleId}`, JSON.stringify(newObject));
				} else {
					selectByLevelString = '"membership_level": "' + this.privacy.selectByLevelValue.membership_level + '"';
				}
			} else if (this.model['channel']) {
				this.todolist['channel'] = this.model['channel'];
				const newObject = this.privacy.channel;
				newObject['privacyType'] = 'subCircle';
				localStorage.setItem(`${this.circleId}`, JSON.stringify(newObject));
			}
			if (
				this.privacy.custom_privacy.length === 0 &&
				!this.privacy.selectedRole &&
				!this.privacy.role &&
				!this.privacy.personalFilter &&
				!this.privacy.selectedDepartment &&
				!this.privacy.channel &&
				!this.model.subcircle &&
				!this.privacy.selectByStatusValue &&
				!this.privacy.selectByRenewalValue &&
				!this.privacy.selectByLevelValue
			) {
				localStorage.setItem(`${this.circleId}`, JSON.stringify('Everyone'));
			}

			if (roleString && departmentString) {
				this.todolist['content_privacy'] = '{' + roleString + ', ' + departmentString + '}';
			} else if (roleString && !departmentString) {
				this.todolist['content_privacy'] = '{' + roleString + '}';
			} else if (!roleString && departmentString) {
				this.todolist['content_privacy'] = '{' + departmentString + '}';
			} else if (personalFilterString) {
				this.todolist['content_privacy'] = '{' + personalFilterString + '}';
			} else if (selectByStatusString) {
				this.todolist['content_privacy'] = '{' + selectByStatusString + '}';
			} else if (selectByRenewalString) {
				this.todolist['content_privacy'] = '{' + selectByRenewalString + '}';
			} else if (selectByLevelString) {
				this.todolist['content_privacy'] = '{' + selectByLevelString + '}';
			} else {
				delete this.todolist['content_privacy'];
			}
		}
		if (!this.model['channel']) {
			delete this.todolist['channel'];
		}
		if (this.todolist['custom_privacy'] && this.todolist['custom_privacy'].length > 0) {
			this.todolist['custom_privacy'] = '[' + this.todolist['custom_privacy'].join(',') + ']';
		}
		if (!isEdit) {
			this.updatePrivacyStringBasedOnPrivacyComponent();
		}
	}

	onClickCancel() {
		if (this.type === 'edit-todolist' || this.type === 'generate-actions') {
			this._location.back();
		} else {
			this.router.navigateByUrl('network/todolist');
		}
	}

	// Repeat Cycle Modal----
	showRepeatCycleOptions() {
		this.repeatcycle_options = 'block';
		this.todolist.repeatCycle = this.repeatObj.option.toLowerCase();
		if (this.type !== 'edit-todolist' && this.type !== 'generate-actions') {
			this.repeatObj = { option: 'daily' };
		}
	}

	public closeModal(): void {
		this.repeatcycle_options = 'none';
	}

	onRepeatOptionClick(option) {
		this.repeatObj = { option: option };
	}

	onSelectRepeatOption() {
		this.selectedRepeatOption = this.repeatObj.option;
		this.todolist.repeatCycle = this.repeatObj.option;
		this.repeatcycle_options = 'none';
		// If one-off is selected,show date option
		if (this.selectedRepeatOption === 'one-off') {
			this.showSelectDateOption = true;
		} else {
			this.showSelectDateOption = false;
			this.oneOff_date = '';
			delete this.todolist['oneOff'];
		}
	}
}
