import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class UrlService {
	constructor() {}

	/**
	 * Get domain from url
	 *
	 * @param url target URL
	 * @returns string
	 */
	public getDomain(url: string): string {
		const domain = new URL(url);

		return domain.hostname;
	}

	/**
	 * Get favicon url
	 *
	 * @param url target URL
	 * @returns string
	 */
	public getFaviconUrl(url: string): string {
		const domain = this.getDomain(url);

		return `https://www.google.com/s2/favicons?domain=${domain}&sz=32`;
	}

	/**
	 * Get extension from url
	 *
	 * @param url target URL
	 * @returns string
	 */
	public getExtensionFromUrl(url: string): string | null {
		try {
			return url.match(/^https?:\/\/.*[\\\/][^\?#]*\.([a-zA-Z0-9]+)\??#?/)[1]?.toLowerCase();
		} catch (ignored) {
			return null;
		}
	}
}
