import { EventEmitter, Injectable } from '@angular/core';
import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { environment } from './../../../../environments/environment';
import CacheUtils from '@shared/utils/cache-utils';

@Injectable({
	providedIn: 'root',
})
export class ChatSocketBaseService extends Socket {
	constructor() {
		super(<SocketIoConfig>{
			url: `${environment.socketio_chat_url}&l=${CacheUtils.getStoredData('language', 'en')}`,
			options: {
				reconnection: true,
				reconnectionDelay: 1000,
				reconnectionDelayMax: 5000,
				reconnectionAttempts: 50,
				timeout: 10000,
				autoConnect: true,
				pingInterval: 10000,
				pingTimeout: 5000,
				forceNew: true,
			},
		});
	}
}

@Injectable({
	providedIn: 'root',
})
export class ChatSocketService {
	public socketController: EventEmitter<any> = new EventEmitter();

	constructor(private chatSocketBaseService: ChatSocketBaseService) {}

	public getSocket(): ChatSocketBaseService {
		return this.chatSocketBaseService;
	}

	public updateUnreadMessageCount(data: any): void {
		this.socketController.emit(data);
	}
}
