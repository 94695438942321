/* eslint-disable spellcheck/spell-checker */
import { catchError, distinctUntilChanged } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Observable, throwError } from 'rxjs';
import { ApiService } from './api.service';
import { Network } from '../models';
import { Department } from '../models';
import { Subscription } from '../models';
import { NotificationService } from './notification.service';
import { PostService } from './post.service';
import { MemberService } from './member.service';
import { ChannelService } from './channel.service';
import { ProjectService } from '@modules/project/services/project.service';
import { HttpParams } from '@angular/common/http';

export interface UserTodoCounts {
	closed: number;
	future: number;
	past: number;
	today: number;
}

@Injectable()
export class NetworkService {
	private dailyMessageObservable: Observable<any>;

	private currentNetworkSubject = new BehaviorSubject<Network>(new Network());
	public currentNetwork = this.currentNetworkSubject.asObservable();
	private isNetworkSetSubject = new ReplaySubject<boolean>(1);

	private currentSubscriptionSubject = new BehaviorSubject<Subscription>(new Subscription());
	public currentSubscription = this.currentSubscriptionSubject.asObservable().pipe(distinctUntilChanged());
	private isSubscriptionSetSubject = new ReplaySubject<boolean>(1);
	public subscriptionReady = this.isSubscriptionSetSubject.asObservable();

	private currentDepartmentsSubject = new BehaviorSubject<any>(null);
	public currentDepartments = this.currentDepartmentsSubject.asObservable().pipe(distinctUntilChanged());
	private isDepartmentsSetSubject = new ReplaySubject<boolean>(1);
	public departmentsReady = this.isDepartmentsSetSubject.asObservable();

	// For welcome tips----------
	private currentFormSubject = new BehaviorSubject<any>(null);
	public currentForm = this.currentFormSubject.asObservable().pipe(distinctUntilChanged());
	private isFormSetSubject = new ReplaySubject<boolean>(1);
	public formReady = this.isFormSetSubject.asObservable();
	// ----------------------------------

	public networkReady = this.isNetworkSetSubject.asObservable();
	private validationPendingNetworkSubject = new BehaviorSubject<any>(null);
	public validationPendingNetwork = this.validationPendingNetworkSubject.asObservable().pipe(distinctUntilChanged());
	public previousPaymentDetails: any;
	public displayWelcomeDialog = false;
	public reportedIssues: any[] = [];
	public departments: any[] = [];
	public personalFilters: any[] = [];
	public next_url = '';
	public paymentDetails: any;
	public purchasePlan;
	public previousIssueFilter;
	public previousTodoFilter;
	public onTodoDetail = false;
	public onIssueDetail = false;
	public settings = {};
	public archivedList;

	public todoAction: any = '';

	public networkId: string;

	constructor(
		private apiService: ApiService,
		private notificationService: NotificationService,
		private postService: PostService,
		private memberService: MemberService,
		private channelService: ChannelService,
		private projectService: ProjectService
	) {
		this.setNetworkId();
	}

	private setNetworkId(): void {
		this.networkId = localStorage.getItem('network_id');
	}

	public getNetwork(): Observable<any> {
		const networkId = this.getNetworkUid();

		return this.apiService.get(`/networks/${networkId}/`);
	}

	public populate(): void {
		this.getNetwork().subscribe(
			(data) => {
				this.setNetwork(new Network(data));
			},
			(error: any) => {
				console.error(error);
				// this.purgeAuth(); Hidden for network details lost issue
			}
		);
	}

	public populateNetworkBasedOnId(networkId): void {
		this.apiService.get('/networks/' + networkId + '/').subscribe(
			(data) => {
				this.setNetwork(new Network(data));
			},
			() => {
				// this.purgeAuth(); Hidden for network details lost issue
			}
		);
	}

	public purgeAuth(): void {
		this.destroyNetwork();
	}

	public destroyNetwork(): void {
		window.localStorage.removeItem('network_id');
		localStorage.removeItem('membership');
		localStorage.removeItem('MyTodolist');
		localStorage.removeItem('favoriteId');
		localStorage.removeItem('notificationCount');
		localStorage.removeItem('zoom_authentication');
		localStorage.removeItem('device-token');
		localStorage.removeItem('todo-prev-route');
		localStorage.removeItem('purchasePlans');
		localStorage.removeItem('seatsCount');
		localStorage.removeItem('manager');
		localStorage.removeItem('networkExpired');
		localStorage.removeItem('welcome-tips');
		localStorage.removeItem('member-delete');
		localStorage.removeItem('branchData');
		localStorage.removeItem('branch_session_first');
		localStorage.removeItem('app_network_id');
		localStorage.removeItem('tab');
		localStorage.removeItem('detail-tab');
		localStorage.removeItem('status');
		localStorage.removeItem('currentPurchasePlan');
		localStorage.removeItem('currentSubscriptionStatus');

		this.currentNetworkSubject.next(new Network());
		this.isNetworkSetSubject.next(false);
		this.notificationService.resetNotifications();
		this.postService.resetPosts();
		this.memberService.resetMembers();
		this.channelService.resetChannels();
		this.currentSubscriptionSubject.next(new Subscription());
		this.isSubscriptionSetSubject.next(false);
		this.currentDepartmentsSubject.next(new Department());
		this.isDepartmentsSetSubject.next(false);
	}

	/**
	 * Purpose: Set/Update subscription details with remove_cache flag
	 * Created By: Arjun Jayakumar
	 * Created On: 26-02-2024
	 */
	public getNetworkSubscriptionDetails(): void {
		this.apiService
			.get(`/payments/transactions/active/?network=${localStorage.getItem('network_id')}&remove_cache=true`)
			.pipe(catchError((error: any) => throwError({ message: 'An error occurred. Please try again later.', error: error })))
			.subscribe((data) => this.setSubscriptionDetail(data));
	}

	public getDepartmentDetails(): void {
		this.apiService.get('/networks/' + this.getNetworkUid() + '/departments/').subscribe(
			(data) => {
				this.setNetworkDepartments(data);
			},
			() => {}
		);
	}

	public updateNetworkLogo(uid, network): void {
		const params = { logo: uid };
		if (network['privacySettings']) {
			if (network['privacySettings']['customer']) {
				let departments = [];
				departments = network['privacySettings']['customer']['department'];
				const a = '{"department": ' + JSON.stringify(departments) + '}';
				const b = '{"customer":' + a + '}';
				params['privacy_settings'] = b;
			}
		}

		if (network['pointsTablePrivacy']) {
			params['pointsTablePrivacy'] = network['pointsTablePrivacy'];
		}

		const url = '/networks/' + this.getNetworkUid() + '/';
		this.apiService.put(url, params).subscribe(
			() => {
				this.populate();
			},
			() => {}
		);
	}

	public updateNetwork(value, network, type): Observable<any> {
		const params = {};
		params[type] = value;

		if (network['privacySettings']) {
			if (network['privacySettings']['customer']) {
				let departments = [];
				departments = network['privacySettings']['customer']['department'];
				const a = '{"department": ' + JSON.stringify(departments) + '}';
				const b = '{"customer":' + a + '}';
				params['privacy_settings'] = b;
			}
		}
		if (network['pointsTablePrivacy']) {
			params['pointsTablePrivacy'] = network['pointsTablePrivacy'];
		}
		const url = '/networks/' + this.getNetworkUid() + '/';
		return this.apiService.put(url, params);
	}

	public createDepartment(param): Observable<any> {
		const url = '/networks/' + localStorage.getItem('network_id') + '/departments/';
		return this.apiService.post(url, param);
	}

	public setNetworkDepartments(data): void {
		this.currentDepartmentsSubject.next(data);
		this.isDepartmentsSetSubject.next(true);
		this.departments = data.objects;
	}

	public setSubscriptionDetail(data): void {
		this.currentSubscriptionSubject.next(data);
		this.isSubscriptionSetSubject.next(true);
	}

	public setForm(data): void {
		this.currentFormSubject.next(data);
		this.isFormSetSubject.next(true);
	}

	public clearFormSubscriptions(): void {
		this.currentFormSubject.next(null);
		this.isFormSetSubject.next(false);
	}

	public getNetworkUid(): string {
		const networkId = window?.localStorage['network_id'];

		if (!networkId) {
			window.location.href = '/';
		}

		return networkId;
	}

	public get(uid): Observable<any> {
		return this.apiService.get('/networks/' + uid + '/');
	}

	public getNetworkTypes(): Observable<any> {
		return this.apiService.get('/_support/networkSettings/');
	}

	public getNetworksBasedOnFilters(type, name): Observable<any> {
		return this.apiService.get('/networks/search/?format=json&username=' + localStorage.getItem('uid') + '&type=' + type + '&query=' + name);
	}

	public getLocations(uid, coords: any): Observable<any> {
		if (coords) {
			return this.apiService.get('/networks/' + uid + '/locations/?near=' + coords.latitude + ',' + coords.longitude);
		} else {
			return this.apiService.get('/networks/' + uid + '/locations/');
		}
	}

	public filterLocations(coords: any, param): Observable<any> {
		if (coords) {
			return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/locations/?near=' + coords + '&' + param);
		} else {
			return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/locations/?' + param);
		}
	}

	public getRemainingLocations(url): Observable<any> {
		return this.apiService.get(url);
	}

	public setNetwork(network: any): void {
		localStorage.setItem('network_id', network?.uid);
		this.networkId = network.uid;

		window.localStorage['network_id'] = network?.uid;

		this.currentNetworkSubject.next(network);
		this.isNetworkSetSubject.next(true);
		this.setPersonalFilters(network);

		this.projectService.networkId = network?.uid;
	}

	public setPersonalFilters(network): void {
		if (network.membership && network.membership.memberRole) {
			network['membership']['memberRole']['fields'].forEach((field) => {
				if (field.CP === true) {
					this.personalFilters.push(field);
				}
			});
		}
	}

	public clearNetworkSubject(): void {
		this.currentNetworkSubject.next(new Network());
		this.isNetworkSetSubject.next(false);
		this.currentSubscriptionSubject.next(new Subscription());
		this.isSubscriptionSetSubject.next(false);
		this.currentDepartmentsSubject.next(new Department());
		this.isDepartmentsSetSubject.next(false);
	}

	public clearSubscriptions(): void {
		this.currentSubscriptionSubject.next(new Subscription());
		this.isSubscriptionSetSubject.next(false);
	}

	public clearNetwork(): void {
		this.currentNetworkSubject.next(new Network());
		this.isNetworkSetSubject.next(false);
	}

	public getCurrentNetwork(): Network {
		return this.currentNetworkSubject.value;
	}

	// Membership cp-----
	public getSelectByStatusRenewalFrequency(): Observable<any> {
		return this.apiService.get('/_support/SubscriptionTemplateStatus/');
	}

	public updateRoleFields(params: any, membershipId: string): Observable<any> {
		const uid = localStorage.getItem('uid');

		return this.apiService.put(`/users/${uid}/memberships/${membershipId}/`, params);
	}

	public getCitiesForRoleUpdate(nearMeLocation): Observable<any> {
		const url = '/geo/cities/?near=' + nearMeLocation;
		return this.apiService.get(url);
	}

	public searchCitiesForRoleUpdate(nearMeLocation, query): Observable<any> {
		const url = '/geo/cities/?near=' + nearMeLocation + '&query=' + query;
		return this.apiService.get(url);
	}

	/* -----------------------------------Issue management api starts---------------------------------------------------------------------- */
	public getReportedIssues(): Observable<any> {
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/issue_collections/');
	}

	public searchReportedIssues(q): Observable<any> {
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/issue_collections/?query=' + q);
	}

	public filterReportedIssues(filter = ''): Observable<any> {
		if (filter) {
			return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/issue_collections/' + '?' + filter);
		} else {
			return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/issue_collections/');
		}
	}

	public getReportedIssueStatus(): Observable<any> {
		return this.apiService.get('/_support/IssueStatus/');
	}

	public paginateIssues(url): Observable<any> {
		return this.apiService.get(url);
	}

	public getReportedIssueDetails(issueUid): Observable<any> {
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/issue_collections/' + issueUid + '/');
	}

	public getIssueDetail(id): Observable<any> {
		return this.apiService.get('/issues/' + id + '/');
	}

	public getAllProducts(network_id): Observable<any> {
		return this.apiService.get('/networks/' + network_id + '/products/');
	}

	public createNewProduct(product, network_id): Observable<any> {
		const url = '/networks/' + network_id + '/products/';
		return this.apiService.post(url, product);
	}

	public updateProduct(product, network_id, product_id): Observable<any> {
		const url = '/networks/' + network_id + '/products/' + product_id + '/';
		return this.apiService.put(url, product);
	}

	public deleteProduct(network_id, product_id): Observable<any> {
		return this.apiService.delete('/networks/' + network_id + '/products/' + product_id + '/');
	}

	// getAllProjects(network_id) {
	//   return this.apiService.get('/networks/' + network_id + '/projects/');
	// }

	// TODO: remove this after migration, moved to project service
	public getAllProjects(network_id, query): Observable<any> {
		if (query) {
			return this.apiService.get('/networks/' + network_id + '/projects/?query=' + query);
		} else {
			return this.apiService.get('/networks/' + network_id + '/projects/');
		}
	}

	public createNewProject(project, network_id): Observable<any> {
		const url = '/networks/' + network_id + '/projects/';
		return this.apiService.post(url, project);
	}

	public deleteProject(network_id, project_id): Observable<any> {
		return this.apiService.delete('/networks/' + network_id + '/projects/' + project_id + '/');
	}

	public updateProject(project, network_id, project_id): Observable<any> {
		const url = '/networks/' + network_id + '/projects/' + project_id + '/';
		return this.apiService.put(url, project);
	}

	/** ------------------------TODO LISTS STARTS------------------------------------------- */

	// TODO: remove this after migration, moved to project service
	public getTodoLists(network_uid, query): Observable<any> {
		if (query) {
			return this.apiService.get('/networks/' + network_uid + '/todolists/?query=' + query);
		} else {
			return this.apiService.get('/networks/' + network_uid + '/todolists/');
		}
	}

	public filterTodos(filter): Observable<any> {
		return this.apiService.get('/users/' + localStorage.getItem('uid') + '/todos/' + '?' + filter + '&network=' + localStorage.getItem('network_id'));
	}

	// TODO: remove this after migration, moved to project service
	public newFilterTodos(filter): Observable<any> {
		return this.apiService.get('/users/' + localStorage.getItem('uid') + '/todos/' + '?newFormat=True&network=' + localStorage.getItem('network_id') + '&' + filter);
	}

	// eslint-disable-next-line spellcheck/spell-checker
	public calfilterTodos(filter, username): Observable<any> {
		return this.apiService.get('/users/' + username + '/todos/' + '?newFormat=True&network=' + localStorage.getItem('network_id') + '&' + filter);
	}

	public getEndDayTodos(): Observable<any> {
		return this.apiService.get(
			'/networks/' + localStorage.getItem('network_id') + '/user_start_day_tasks/?assigned=true&network=' + localStorage.getItem('network_id') + '&today=true'
		);
	}

	public paginateTasks(url): Observable<any> {
		return this.apiService.get(url);
	}

	public filterAllTodos(): Observable<any> {
		return this.apiService.get('/users/' + localStorage.getItem('uid') + '/todos/');
	}

	public getTodosCount(uid, filter, state): Observable<any> {
		if (state) {
			return this.apiService.get('/todolists/' + uid + '/todo_count_dates/?archived=true&' + filter);
		} else {
			return this.apiService.get('/todolists/' + uid + '/todo_count_dates/?' + filter);
		}
	}

	public getUserItemsCounts(filter): Observable<any> {
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/' + localStorage.getItem('uid') + '/items_count/?' + filter);
	}

	public getCountsOfOthers(username): Observable<any> {
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/' + username + '/items_count/');
	}

	public getUserEvents(filter, date): Observable<any> {
		return this.apiService.get(
			'/networks/' +
				localStorage.getItem('network_id') +
				'/user_events/?type=' +
				filter +
				'&assigned=true&newFormat=true&network=' +
				localStorage.getItem('network_id') +
				'&date=' +
				date
		);
	}

	public getProjectTodosCount(uid, filter, state): Observable<any> {
		if (state) {
			return this.apiService.get('/projects/' + uid + '/todo_count_dates/?archived=true&' + filter);
		} else {
			return this.apiService.get('/projects/' + uid + '/todo_count_dates/?' + filter);
		}
	}

	public paginateToDoLists(url): Observable<any> {
		return this.apiService.get(url);
	}

	public paginateProjects(url): Observable<any> {
		return this.apiService.get(url);
	}

	public createToDoList(todoList, network_uid): Observable<any> {
		todoList['username'] = localStorage.getItem('uid');
		todoList['network'] = localStorage.getItem('network_id');
		const url = '/networks/' + network_uid + '/todolists/';
		return this.apiService.post(url, todoList);
	}

	public getToDoListDetail(id, state): Observable<any> {
		if (state) {
			return this.apiService.get('/todolists/' + id + '/?archived=true');
		} else {
			return this.apiService.get('/todolists/' + id + '/');
		}
	}

	// TODO: remove this after migration, moved to project service
	public getProjectDetail(id): Observable<any> {
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/projects/' + id + '/');
	}

	public getProjectTodos(slug, filter, state): Observable<any> {
		if (state) {
			return this.apiService.get(
				'/users/' + localStorage.getItem('uid') + '/todos/' + '?network=' + localStorage.getItem('network_id') + '&project=' + slug + '&archived=true&' + filter
			);
		} else {
			return this.apiService.get('/users/' + localStorage.getItem('uid') + '/todos/' + '?network=' + localStorage.getItem('network_id') + '&project=' + slug + '&' + filter);
		}
	}

	public getArchivedProducts(): Observable<any> {
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/products/?archived=true');
	}

	// TODO: remove this after migration, moved to project service
	public getTodosForProjects(projectUid, param, state): Observable<any> {
		if (param) {
			if (state) {
				return this.apiService.get('/projects/' + projectUid + '/todos/?archived=true&' + param + '&remove_section_tasks=true');
			} else {
				return this.apiService.get('/projects/' + projectUid + '/todos/?' + param + '&remove_section_tasks=true');
			}
		} else {
			if (state) {
				return this.apiService.get('/projects/' + projectUid + '/todos/?archived=true&remove_section_tasks=true');
			} else {
				return this.apiService.get('/projects/' + projectUid + '/todos/?remove_section_tasks=true');
			}
		}
	}

	// TODO: remove this after migration, moved to project service
	// eslint-disable-next-line spellcheck/spell-checker
	public getTodosForKanben(projectUid, param, state): Observable<any> {
		if (param) {
			if (state) {
				return this.apiService.get('/projects/' + projectUid + '/todos/?archived=true&' + param);
			} else {
				return this.apiService.get('/projects/' + projectUid + '/todos/?' + param);
			}
		} else {
			if (state) {
				return this.apiService.get('/projects/' + projectUid + '/todos/?archived=true');
			} else {
				return this.apiService.get('/projects/' + projectUid + '/todos/');
			}
		}
	}

	public getTodosForProjectsInCal(projectUid, param, state): Observable<any> {
		if (param) {
			if (state) {
				return this.apiService.get('/projects/' + projectUid + '/todos/?archived=true&' + param);
			} else {
				return this.apiService.get('/projects/' + projectUid + '/todos/?' + param);
			}
		} else {
			if (state) {
				return this.apiService.get('/projects/' + projectUid + '/todos/?archived=true');
			} else {
				return this.apiService.get('/projects/' + projectUid + '/todos/');
			}
		}
	}

	// TODO: remove this after migration, moved to project service
	public getTask(id): Observable<any> {
		return this.apiService.get('/todos/' + id + '/');
	}

	public getToDoItems(todolist_uid, filterString, state): Observable<any> {
		if (state) {
			if (filterString) {
				return this.apiService.get('/todolists/' + todolist_uid + '/todos/' + '?archived=true&' + filterString);
			} else {
				return this.apiService.get('/todolists/' + todolist_uid + '/todos/?archived=true');
			}
		} else {
			if (filterString) {
				return this.apiService.get('/todolists/' + todolist_uid + '/todos/' + '?' + filterString);
			} else {
				return this.apiService.get('/todolists/' + todolist_uid + '/todos/');
			}
		}
	}

	public getTodosByHash(uid): Observable<any> {
		return this.apiService.get('/hashtags/' + uid + '/todos/?app_id=' + localStorage.getItem('network_id'));
	}

	public deleteToDoList(todoListUid): Observable<any> {
		return this.apiService.delete('/todolists/' + todoListUid + '/');
	}

	public updateToDoList(todoList, todolist_uid): Observable<any> {
		todoList['username'] = localStorage.getItem('uid');
		todoList['network'] = localStorage.getItem('network_id');
		const url = '/todolists/' + todolist_uid + '/';
		return this.apiService.put(url, todoList);
	}

	public getToDoListForItem(): Observable<any> {
		const networkUid = localStorage.getItem('network_id');
		return this.apiService.get('/users/' + localStorage.getItem('uid') + '/todolists/?network=' + networkUid);
	}

	public createToDoItem(todoItem, todoList_uid, type): Observable<any> {
		const params = todoItem;

		console.log(params);

		if (type === 'Team not selected') {
			const url = '/users/' + localStorage.getItem('uid') + '/todos/' + '?network=' + localStorage.getItem('network_id');

			return this.apiService.post(url, params);
		} else {
			const url = '/todolists/' + todoList_uid + '/todos/';

			return this.apiService.post(url, params);
		}
	}

	// TODO: remove this after migration, moved to project service
	public createToDo(todoItem, todoList_uid): Observable<any> {
		const params = todoItem;

		if (!todoList_uid) {
			const url = '/users/' + localStorage.getItem('uid') + '/todos/' + '?&network=' + localStorage.getItem('network_id');

			return this.apiService.post(url, params);
		} else {
			const url = '/todolists/' + todoList_uid + '/todos/';

			return this.apiService.post(url, params);
		}
	}

	// TODO: remove this after migration, moved to project service
	public deleteToDoItem(todoItem_uid, param): Observable<any> {
		if (param === 'past') {
			const url = '/todos/' + todoItem_uid + '/?username=' + localStorage.getItem('uid') + '&delete_all=true';
			return this.apiService.delete(url);
		} else if (param === 'future') {
			const url = '/todos/' + todoItem_uid + '/?username=' + localStorage.getItem('uid') + '&delete_future=true';
			return this.apiService.delete(url);
		} else {
			const url = '/todos/' + todoItem_uid + '/?username=' + localStorage.getItem('uid');
			return this.apiService.delete(url);
		}
	}

	// TODO: remove this after migration, moved to project service
	public updateToDoItem(todoItem, todoItem_uid): Observable<any> {
		const url = '/todos/' + todoItem_uid + '/';
		return this.apiService.put(url, todoItem);
	}

	public postTodoListActiveMembers(params): Observable<any> {
		const networkUid = localStorage.getItem('network_id');
		const url = '/networks/' + networkUid + '/active_members/';
		return this.apiService.post(url, params);
	}

	public getTodoListActiveMembers(): Observable<any> {
		const networkUid = localStorage.getItem('network_id');
		const url = '/networks/' + networkUid + '/active_members/?active=true';
		return this.apiService.get(url);
	}

	public getTodoListTotalMembers(): any {
		const networkUid = localStorage.getItem('network_id'),
			url = '/networks/' + networkUid + '/active_members/?all=true';

		return this.apiService.get(url);
	}

	/** -----------TODO LISTS Ends--------------------------------*/

	// Do Not Disturb
	public notificationControl(data: any, networkUid: string): Observable<any> {
		const url = `/users/${localStorage.getItem('uid')}/mute/?username=${localStorage.getItem('uid')}&network_uid=${networkUid}`;

		return this.apiService.post(url, data);
	}

	/**
	 * On updating notification settings
	 * @param body To be updated notification settings
	 * @param muteID Mute ID of the user
	 * @param username Current user's username
	 * @returns Updated notification settings
	 */
	public updateNotificationSettings(body: any, username: string, muteID: string): Observable<any> {
		return this.apiService.put(`/users/${username}/mute/${muteID}/`, body);
	}

	public onMuteNetwork(data): Observable<any> {
		const url = '/networks/' + localStorage.getItem('network_id') + '/mutes/';
		return this.apiService.post(url, data);
	}

	// Un mute network
	public unMuteNetwork(id): Observable<any> {
		const url = '/networks/' + localStorage.getItem('network_id') + '/mutes/' + id + '/';
		return this.apiService.delete(url);
	}

	public getMuteSettings(mute_id, networkUid): Observable<any> {
		const url = `/users/${localStorage.getItem('uid')}/mute/${mute_id}/?network_uid=${networkUid}`;
		// const url = '/users/' + localStorage.getItem('uid') + '/mute/' + mute_id + '/';
		return this.apiService.get(url);
	}

	public muteContents(id): Observable<any> {
		const params = {
			contentType: 'channel',
			networkUid: localStorage.getItem('network_id'),
			contentUid: id,
		};
		const url = '/users/' + localStorage.getItem('uid') + '/mutecontents/?username=' + localStorage.getItem('uid');
		return this.apiService.post(url, params);
	}

	public unMuteContents(id): Observable<any> {
		const url = '/users/' + localStorage.getItem('uid') + '/mutecontents/' + id + '/?username=' + localStorage.getItem('uid');
		return this.apiService.delete(url);
	}

	public getMuteContents(): Observable<any> {
		return this.apiService.get('/users/' + localStorage.getItem('uid') + '/mutecontents/?username=' + localStorage.getItem('uid'));
	}

	// eslint-disable-next-line spellcheck/spell-checker
	// Function for check in in location details for adim adim
	public checkIn(uid, review, range): Observable<any> {
		const url = '/locations/' + uid + '/checkins/';
		const params = {
			network: localStorage.getItem('network_id'),
			location: uid,
			body: review,
			rating: range,
		};
		return this.apiService.post(url, params);
	}
	// Function for checkin if no location uid exists in loc object
	public checkinWithoutUid(modal): Observable<any> {
		const url = '/locations/';
		return this.apiService.post(url, modal);
	}

	// Functions for getting report actions for location
	public getReportActionsForLocation(): Observable<any> {
		return this.apiService.get('/_support/flagReasons/');
	}

	// Function for executing a report action for location
	public reportAnAction(obj): Observable<any> {
		const url = '/users/' + localStorage.getItem('uid') + '/flags/';
		return this.apiService.post(url, obj);
	}

	public getChannels(url, query = ''): Observable<any> {
		if (query) {
			return this.apiService.get(url + '?' + query);
		} else {
			return this.apiService.get(url);
		}
	}

	// -------------Reminder actions starts---------------------------------------------------//
	public getReminderOptions(): Observable<any> {
		return this.apiService.get('/_support/reminderOptions/');
	}

	public createReminder(reminder): Observable<any> {
		const url = '/users/' + localStorage.getItem('uid') + '/reminders/';
		return this.apiService.post(url, reminder);
	}

	public deleteReminder(uid): Observable<any> {
		const url = `/users/${localStorage.getItem('uid')}/reminders/${uid}/`;
		return this.apiService.delete(url);
	}

	public getRemainderDetails(id): Observable<any> {
		const url = '/users/' + localStorage.getItem('uid') + '/reminders/' + id + '/';
		return this.apiService.get(url);
	}

	public editRemainder(reminderObject, uid): Observable<any> {
		const url = '/users/' + localStorage.getItem('uid') + '/reminders/' + uid + '/';
		return this.apiService.put(url, reminderObject);
	}

	public getContentReminders(contentType, contentUid): Observable<any> {
		const url = '/' + contentType + '/' + contentUid + '/reminders/';
		return this.apiService.get(url);
	}

	// -------------Reminder actions ends---------------------------------------------------//

	public getRoleDetails(role, network): Observable<any> {
		return this.apiService.get('/roles/' + role + '/?format=json&username=' + localStorage.getItem('uid') + '&network=' + network);
	}

	public postRoleDetails(data): Observable<any> {
		return this.apiService.post('/users/' + localStorage.getItem('uid') + '/memberships/', data);
	}

	public postEmail(data, membershipId): Observable<any> {
		return this.apiService.post('/memberships/' + membershipId + '/', data);
	}

	public getDepartments(networkUid: string, offset: number = 0, limit: number = 50): Observable<any> {
		return this.apiService.get(`/networks/${networkUid}/departments/?offset=${offset}&limit=${limit}`);
	}

	public getCities(nearMeLocation): Observable<any> {
		return this.apiService.get('/geo/cities/?username=' + localStorage.getItem('uid') + '&near=' + nearMeLocation);
	}

	public updateNetworkMuteStatus(contactsMute, mutedNetworks): Observable<any> {
		const url = '/users/' + localStorage.getItem('uid') + '/';
		const params = {
			contactsMute: contactsMute,
			mute: mutedNetworks,
			username: localStorage.getItem('uid'),
		};
		return this.apiService.put(url, params);
	}

	// updateDailyDigest(networks) {
	//   const url = '/users/' + localStorage.getItem('uid') + '/';
	//   const params = { 'dailyDijest': networks, 'username': localStorage.getItem('uid') };
	//   return this.apiService.put(url, params);
	// }

	public getMembershipDetails(membershipUid: string, networkID: string = ''): Observable<any> {
		let httpParams = new HttpParams();
		if (networkID) {
			localStorage.setItem('network_id', networkID);
			httpParams = httpParams.set('__includeAppId', 'true');
		} else if (localStorage.getItem('network_id')) {
			httpParams = httpParams.set('__includeAppId', 'true');
		}

		const url = '/users/' + localStorage.getItem('uid') + '/memberships/' + membershipUid + '/';
		return this.apiService.get(url, httpParams);
	}

	// updateAvailabiltyStatus(availabiltyStatus, membershipId) {
	//   const url = '/users/' + localStorage.getItem('uid') + '/memberships/' + membershipId + '/';
	//   const params = { 'username': localStorage.getItem('uid'), 'availabiltyStatus': availabiltyStatus };
	//   return this.apiService.put(url, params);
	// }

	public updateAvailabiltyStatus(availabiltyStatus, membershipId, workPlace?: string): Observable<any> {
		const url = '/users/' + localStorage.getItem('uid') + '/memberships/' + membershipId + '/';
		const params: {availabilityStatus: string; workplace_today?: string} = { availabilityStatus: availabiltyStatus };
		if(workPlace){
			params.workplace_today = workPlace;
		}
		return this.apiService.put(url, params);
	}

	public updateDailyDigest(param): Observable<any> {
		const url = '/users/' + localStorage.getItem('uid') + '/';
		const params = { dailyDigest: param };
		return this.apiService.put(url, params);
	}

	public requestNetwork(name, contentUid = null): Observable<any> {
		const url = '/requests/';
		const params = {
			contentUid: contentUid,
			username: localStorage.getItem('uid'),
			text: name,
		};
		return this.apiService.post(url, params);
	}
	public getNextChannel(url): Observable<any> {
		return this.apiService.get(url);
	}

	public leavechannel(channelUid, membershipUid): Observable<any> {
		const url = `/channels/${channelUid}/members/${membershipUid}/`;
		return this.apiService.delete(url);
	}

	public createChannel(data): Observable<any> {
		const url = '/networks/' + localStorage.getItem('network_id') + '/channels/';
		return this.apiService.post(url, data);
	}

	public joinChannel(channelId): Observable<any> {
		const url = '/channels/' + channelId + '/members/';
		const data = {
			username: localStorage.getItem('uid'),
			user: localStorage.getItem('uid'),
		};
		return this.apiService.post(url, data);
	}

	/**
	 * Function to add members to channels.
	 */
	public addMembers(data, uid): Observable<any> {
		const url = '/channels/' + uid + '/members/';
		return this.apiService.post(url, data);
	}

	public getChannel(channelId, state): Observable<any> {
		if (channelId) {
			if (state) {
				const url = '/channels/' + channelId + '/?archived=true&username=' + localStorage.getItem('uid');
				return this.apiService.get(url);
			} else {
				const url = '/channels/' + channelId + '/?username=' + localStorage.getItem('uid');
				return this.apiService.get(url);
			}
		}
	}

	public getchannelMembers(channelId, state): Observable<any> {
		if (state) {
			const url = '/channels/' + channelId + '/members/?archived=true&state=active&username=' + localStorage.getItem('uid');
			return this.apiService.get(url);
		} else {
			const url = '/channels/' + channelId + '/members/?state=active&username=' + localStorage.getItem('uid');
			return this.apiService.get(url);
		}
	}

	public setValidationPendingNetwork(network): void {
		this.validationPendingNetworkSubject.next(network);
	}

	// Used to generate network types based on user country list and split it as 2 arrays
	// possibly deprecated
	public pupulateNetworkTypesArray(networkServices): any {
		let count = 0;
		const primaryNetworkTypes: any[] = [],
			secondaryCircleTypes: any[] = [];

		if (networkServices) {
			if (networkServices.enterprise === 1) {
				primaryNetworkTypes.push({
					name: 'Work',
					slug: 'enterprise',
					class: 'pb-breifcase',
					index: count,
				});
				count++;
			}
			if (networkServices.university === 1) {
				primaryNetworkTypes.push({
					name: 'University',
					slug: 'university',
					class: 'pb-graduate',
					index: count,
				});
				count++;
			}
			if (networkServices.school === 1) {
				primaryNetworkTypes.push({
					name: 'School',
					slug: 'school',
					class: 'pb-book',
					index: count,
				});
				count++;
			}
			if (networkServices.clubs === 1) {
				primaryNetworkTypes.push({
					name: 'Membership',
					slug: 'clubs',
					class: 'pb-user-check',
					index: count,
				});
				count++;
			}
			if (networkServices.contacts === 1) {
				if (count < 4) {
					primaryNetworkTypes.push({
						name: 'Contacts',
						slug: 'contacts',
						class: 'pb-share',
						index: count,
					});
				} else {
					secondaryCircleTypes.push({
						name: 'Contacts',
						slug: 'contacts',
						class: 'pb-share',
						index: count,
					});
				}
				count++;
			}

			if (networkServices.community === 1) {
				if (count < 4) {
					primaryNetworkTypes.push({
						name: 'Community',
						slug: 'community',
						class: 'pb-map-pin',
						index: count,
					});
				} else {
					secondaryCircleTypes.push({
						name: 'Community',
						slug: 'community',
						class: 'pb-map-pin',
						index: count,
					});
				}
				count++;
			}

			if (networkServices.neighborhood === 1) {
				if (count < 4) {
					primaryNetworkTypes.push({
						name: 'Neighborhood',
						slug: 'neighborhood',
						class: 'pb-muncipalty',
						index: count,
					});
				} else {
					secondaryCircleTypes.push({
						name: 'Neighborhood',
						slug: 'neighborhood',
						class: 'pb-muncipalty',
						index: count,
					});
				}
				count++;
			}
			if (networkServices.elections === 1) {
				if (count < 4) {
					primaryNetworkTypes.push({
						name: 'Elections',
						slug: 'elections',
						class: 'pb-map-pin',
						index: count,
					});
				} else {
					secondaryCircleTypes.push({
						name: 'Elections',
						slug: 'elections',
						class: 'pb-map-pin',
						index: count,
					});
				}
				count++;
			}
		}
		const networkTypes = {
			primaryNetworkTypes: primaryNetworkTypes,
			secondaryCircleTypes: secondaryCircleTypes,
		};
		return networkTypes;
	}

	public resetReportedIssues(): void {
		this.reportedIssues = [];
	}

	// Purchase Scenarios

	public getSubscriptions(network_id): Observable<any> {
		return this.apiService.get('/payments/subscriptions/?network=' + network_id);
	}

	public createSubscriptionPayment(object): Observable<any> {
		const url = '/payments/subscriptions/';
		return this.apiService.post(url, object);
	}

	public getActivePurchasePlan(): Observable<any> {
		return this.apiService.get('/payments/transactions/active/?network=' + localStorage.getItem('network_id'));
	}

	public getNetworkActiveSubscription(networkId): Observable<any> {
		return this.apiService.get('/payments/transactions/active/?network=' + networkId);
	}

	public cancelSubscriptionPlan(data): Observable<any> {
		const url = '/payments/transactions/active/?network=' + localStorage.getItem('network_id');
		return this.apiService.post(url, data);
	}

	public updateSubscription(data): Observable<any> {
		const url = '/subscriptions/active/?network=' + localStorage.getItem('network_id');
		return this.apiService.post(url, data);
	}

	public getBillingDetails(): Observable<any> {
		const url = '/payments/customer/';
		return this.apiService.get(url);
	}

	public updateBillingDetails(params): Observable<any> {
		const url = '/payments/customer/';
		return this.apiService.post(url, params);
	}

	public getTransactionDetails(id): Observable<any> {
		return this.apiService.get('/payments/transactions/?transaction_id=' + id);
	}

	public validateCoupon(coupon): Observable<any> {
		return this.apiService.get('/payments/coupons/?network=' + localStorage.getItem('network_id') + '&coupon=' + coupon);
	}

	public getNetworks(query, type): Observable<any> {
		return this.apiService.get('/networks/search/?query=' + query + '&type=' + type);
	}

	public getRoleFieldsUpdate(slug, networkId): Observable<any> {
		return this.apiService.get('/roles/' + slug + '/?network=' + networkId);
	}

	public deleteMembership(membershipUid): Observable<any> {
		return this.apiService.delete('/users/' + localStorage.getItem('uid') + '/memberships/' + membershipUid + '/');
	}

	private getPointsRewardsObservable: Observable<any>;

	public getPointsRewards(query: string | null = null): Observable<any> {
		return this.apiService.get(`/networks/${this.networkId}/points_table/?${query ? 'filter_by=' + query : ''}`);
	}

	public getCustomerStates(): Observable<any> {
		return this.apiService.get('/_support/CustomerStates/');
	}

	public createCustomer(params): Observable<any> {
		const url = '/networks/' + localStorage.getItem('network_id') + '/customers/';
		return this.apiService.post(url, params);
	}

	public getGroupedStatus(): Observable<any> {
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/grouped_status/');
	}

	public getGroupedByFilters(slug): Observable<any> {
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/grouped_by_filters/' + slug + '/');
	}

	public getCustomersList(state): Observable<any> {
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/customers/?status=' + state);
	}

	public filterCustomers(filter, sortParams): Observable<any> {
		if (!sortParams) {
			return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/customers/?' + filter);
		} else {
			return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/customers/?' + filter + '&' + sortParams);
		}
	}

	public searchCustomers(query): Observable<any> {
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/customers/?query=' + query);
	}

	public sortCustomersList(state, filter): Observable<any> {
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/customers/?status=' + state + '&' + filter);
	}

	public getCustomers(): Observable<any> {
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/customers/');
	}

	public getCustomersByHash(hashUid): Observable<any> {
		return this.apiService.get('/hashtags/' + hashUid + '/customers/?app_id=' + localStorage.getItem('network_id'));
	}

	public getCustomerDetails(id): Observable<any> {
		return this.apiService.get('/customers/' + id + '/');
	}

	public deleteCustomer(id): Observable<any> {
		return this.apiService.delete('/customers/' + id + '/');
	}

	public editCustomer(customerUid, params): Observable<any> {
		const url = '/customers/' + customerUid + '/';
		return this.apiService.put(url, params);
	}

	public getTodoListCounts(): Observable<any> {
		return this.apiService.get('/users/' + localStorage.getItem('uid') + '/todolists_counts/?network=' + localStorage.getItem('network_id'));
	}

	public getArchivedCounts(): Observable<any> {
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/todolists_counts/');
	}

	public getArchivedProjectCounts(): Observable<any> {
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/project_counts/');
	}

	public getArchivedProductCounts(): Observable<any> {
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/product_counts/');
	}

	public getLocationDetail(uid): Observable<any> {
		return this.apiService.get('/locations/' + uid + '/');
	}

	public memberPrivacySettings(memberId, params): Observable<any> {
		const url = '/users/' + localStorage.getItem('uid') + '/memberships/' + memberId + '/';
		return this.apiService.calPut(url, params);
	}

	// deprecated, moved to calendar service
	public getCalendarMembers(): Observable<any> {
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/members/calender/');
	}

	public getArchivedTodoLists(): Observable<any> {
		// return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/archived_todolists/');
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/todolists/?archived=true');
	}

	public getArchivedProjects(): Observable<any> {
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/projects/?archived=true');
	}

	public updateCustomerSettings(settings): Observable<any> {
		const url = '/networks/' + this.getNetworkUid() + '/';
		return this.apiService.calPut(url, settings);
	}

	public assignPoints(params): Observable<any> {
		const url = '/networks/' + localStorage.getItem('network_id') + '/points_table/';
		return this.apiService.post(url, params);
	}

	public enablePointsSettings(settings): Observable<any> {
		const url = '/networks/' + this.getNetworkUid() + '/';
		return this.apiService.put(url, settings);
	}

	public getTeamMembers(): Observable<any> {
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/manager/team-members/');
	}

	public createNoListTask(params): Observable<any> {
		const url = '/users/' + localStorage.getItem('uid') + '/todos/' + '?&network=' + localStorage.getItem('network_id');
		return this.apiService.post(url, params);
	}

	// TODO: remove this after migration, moved to project service
	public getSubtasks(uid, limit, status, section): Observable<any> {
		if (status === 'open') {
			return this.apiService.get('/todos/' + uid + '/sub_tasks/?limit=' + limit + '&status=open');
		} else if (status === 'closed') {
			return this.apiService.get(
				'/todos/' + uid + '/sub_tasks/?limit=' + limit + '&newFormat=true&today=true&assigned=true&network=' + localStorage.getItem('network_id') + ''
			);
		} else if (section) {
			return this.apiService.get('/todos/' + uid + '/sub_tasks/?limit=' + limit + '&remove_section_tasks=true');
		} else {
			return this.apiService.get('/todos/' + uid + '/sub_tasks/?limit=' + limit);
		}
	}

	// TODO: remove this after migration, moved to project service
	public createSection(params): Observable<any> {
		const url = '/sections/';
		return this.apiService.post(url, params);
	}

	// deleteSection(uid) {
	//   return this.apiService.delete('/sections/' + uid + '/');
	// }

	// TODO: remove this after migration, moved to project service
	public deleteSection(uid, param): Observable<any> {
		if (param) {
			return this.apiService.delete('/sections/' + uid + '/?' + param);
		} else {
			return this.apiService.delete('/sections/' + uid + '/');
		}
	}

	// TODO: remove this after migration, moved to project service
	public editSection(uid, params): Observable<any> {
		const url = '/sections/' + uid + '/';
		return this.apiService.put(url, params);
	}

	// addTaskInSections(uid, params) {
	//   const url = '/sections/' + uid + '/?update=true';
	//   return this.apiService.put(url, params);
	// }

	// TODO: remove this after migration, moved to project service
	public addTaskInSections(uid, params): Observable<any> {
		const url = '/sections/' + uid + '/';
		return this.apiService.put(url, params);
	}

	public reorderSubtasks(uid, params): Observable<any> {
		const url = '/todos/' + uid + '/sub_tasks_order/';
		return this.apiService.post(url, params);
	}

	public getTodoSections(uid, limit): Observable<any> {
		if (limit) {
			return this.apiService.get('/todos/' + uid + '/sections/?limit=' + limit);
		} else {
			return this.apiService.get('/todos/' + uid + '/sections/');
		}
	}

	// TODO: remove this after migration, moved to project service
	public getTodosInSection(uid, limit, filter): Observable<any> {
		if (filter) {
			if (limit) {
				return this.apiService.get('/sections/' + uid + '/todos/?limit=' + limit + '&' + filter);
			} else {
				return this.apiService.get('/sections/' + uid + '/todos/');
			}
		} else {
			if (limit) {
				return this.apiService.get('/sections/' + uid + '/todos/?limit=' + limit);
			} else {
				return this.apiService.get('/sections/' + uid + '/todos/');
			}
		}
	}

	// TODO: remove this after migration, moved to project service
	public deleteTaskInSection(section, subtask): Observable<any> {
		return this.apiService.delete('/sections/' + section['uid'] + '/todo/' + subtask['uid'] + '/');
	}

	// TODO: remove this after migration, moved to project service
	public movetaskBetweenSections(sourceSec, task, destinationSec): Observable<any> {
		const params = { uid: destinationSec['uid'] };
		const url = '/sections/' + sourceSec['uid'] + '/todo/' + task['uid'] + '/';
		return this.apiService.put(url, params);
	}

	public reorderSections(params): Observable<any> {
		const url = '/sections/order/';
		return this.apiService.post(url, params);
	}

	// TODO: remove this after migration, moved to project service
	public reorderTasks(uid, params): Observable<any> {
		const url = '/projects/' + uid + '/todo_order/';
		return this.apiService.post(url, params);
	}

	// TODO: remove this after migration, moved to project service
	public getProjectSections(uid, limit): Observable<any> {
		if (limit) {
			return this.apiService.get('/projects/' + uid + '/sections/?limit=' + limit);
		} else {
			return this.apiService.get('/projects/' + uid + '/sections/');
		}
	}

	// TODO: remove this after migration, moved to project service
	public getSectionDetail(uid): Observable<any> {
		return this.apiService.get('/sections/' + uid + '/');
	}

	public deleteDepartment(department_uid): Observable<any> {
		return this.apiService.delete('/networks/' + localStorage.getItem('network_id') + '/departments/' + department_uid + '/');
	}

	public getServices(): Observable<any> {
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/services/');
		// return this.apiService.get('/_support/Services/');
	}

	public updateService(slug): Observable<any> {
		const url = '/networks/' + localStorage.getItem('network_id') + '/services/' + slug + '/';
		return this.apiService.put(url);
	}

	public deleteService(slug): Observable<any> {
		const url = '/networks/' + localStorage.getItem('network_id') + '/services/' + slug + '/';
		return this.apiService.delete(url);
	}

	public getQuickMessages(): Observable<any> {
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/postcategories/');
	}

	public createNewMessage(params): Observable<any> {
		// const params = { 'name': name };
		const url = '/networks/' + localStorage.getItem('network_id') + '/postcategories/';
		return this.apiService.post(url, params);
	}

	public deleteQuickMessage(slug): Observable<any> {
		return this.apiService.delete('/networks/' + localStorage.getItem('network_id') + '/postcategories/' + slug + '/');
	}

	// eslint-disable-next-line spellcheck/spell-checker
	public queryDep(query): Observable<any> {
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/departments/?query=' + query);
	}
	// SSO
	public getIdentityProvider(): Observable<any> {
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/identity-provider/');
	}

	public updateIPConfig(id, data): Observable<any> {
		const url = '/networks/' + localStorage.getItem('network_id') + '/identity-provider/' + id + '/update_config/';
		return this.apiService.patch(url, data);
	}

	public importUsers(): Observable<any> {
		const url = '/networks/' + localStorage.getItem('network_id') + '/import-users/';
		return this.apiService.post(url, {});
	}

	public deleteIPConfig(id): Observable<any> {
		const url = '/networks/' + localStorage.getItem('network_id') + '/identity-provider/' + id + '/';
		return this.apiService.delete(url);
	}

	public createSSOConfigs(data): Observable<any> {
		const url = '/networks/' + localStorage.getItem('network_id') + '/sso-configs/';
		return this.apiService.post(url, data);
	}

	public getSSOConfigs(): Observable<any> {
		const url = '/networks/' + localStorage.getItem('network_id') + '/sso-configs/';
		return this.apiService.get(url);
	}

	public updateSSOConfigs(data, id): Observable<any> {
		const url = '/networks/' + localStorage.getItem('network_id') + '/sso-configs/' + id + '/';
		return this.apiService.put(url, data);
	}

	public goo(): Observable<any> {
		return this.apiService.get('/networks/9405571b-8df0-4ea6-80cf-5a4ff294874b/');
	}

	public getDepartmentss(): Observable<any> {
		return this.apiService.get('/networks/9405571b-8df0-4ea6-80cf-5a4ff294874b/departments/');
	}

	public deleteSSOConfigs(id): Observable<any> {
		const url = '/networks/' + localStorage.getItem('network_id') + '/sso-configs/' + id + '/';
		return this.apiService.delete(url);
	}

	public createDomain(data): Observable<any> {
		const url = '/networks/' + localStorage.getItem('network_id') + '/domains/';
		return this.apiService.post(url, data);
	}

	public getDomains(): Observable<any> {
		const url = '/networks/' + localStorage.getItem('network_id') + '/domains/';
		return this.apiService.get(url);
	}

	public updateDomian(data, id): Observable<any> {
		const url = '/networks/' + localStorage.getItem('network_id') + '/domains/' + id + '/';
		return this.apiService.put(url, data);
	}

	public deleteDomain(id): Observable<any> {
		const url = '/networks/' + localStorage.getItem('network_id') + '/domains/' + id + '/';
		return this.apiService.delete(url);
	}

	public trigger(): Observable<any> {
		const url = '/users/' + localStorage.getItem('uid') + '/' + localStorage.getItem('network_id') + '/create-todo-tasks/';
		return this.apiService.get(url);
	}

	/**
	 * Get daily message for home feed daily message widget
	 *
	 * @param time number
	 */
	public getDailyMessage(time: number, networkID: string = '', username: string = ''): Observable<any> {
		// if (this.dailyMessageObservable) {
		// 	return this.dailyMessageObservable;
		// }

		const networkId: string = networkID || localStorage.getItem('network_id'),
			uid: string = username || localStorage.getItem('uid'),
			apiUrl = `/networks/${networkId}/${uid}/daily_messages/?time=${time}`;

		this.dailyMessageObservable = this.apiService.get(apiUrl);

		return this.dailyMessageObservable;
	}

	// SCIM app endpoints
	public getToken(): Observable<any> {
		const url = '/networks/' + localStorage.getItem('network_id') + '/scim/generate-token/';
		return this.apiService.get(url);
	}

	public generateToken(): Observable<any> {
		const url = '/networks/' + localStorage.getItem('network_id') + '/scim/generate-token/';
		return this.apiService.post(url, {});
	}

	public refreshToken(): Observable<any> {
		const url = '/networks/' + localStorage.getItem('network_id') + '/scim/refresh-token/';
		return this.apiService.post(url, {});
	}

	public updateInvite(data): Observable<any> {
		const url = '/networks/' + localStorage.getItem('network_id') + '/scim/update-invite/';
		return this.apiService.put(url, data);
	}

	public setCurrentUtcDate(): any {
		const d = new Date().toISOString().slice(0, 10),
			date = new Date(),
			hours = date.getUTCHours(),
			minutes = date.getUTCMinutes(),
			seconds = date.getUTCSeconds(),
			utcDate = d + ' ' + hours + ':' + minutes + ':' + seconds;

		return utcDate;
	}

	/**
	 * Api function to re order customer list
	 */
	public reorderCustomerList(customers): Observable<any> {
		const url = '/customers/order/';
		return this.apiService.post(url, customers);
	}

	/**
	 * Api function to get api call
	 */
	public getPointDetails(id, date): Observable<any> {
		const url = `/networks/members/${id}/points/?date=${date}`;
		return this.apiService.get(url);
	}

	private getStartDayUserCountsObservable: Observable<any>;

	/**
	 * Function to get user counts in start day task modal etc
	 */
	public getStartDayUserCounts(): Observable<UserTodoCounts> {
		this.setNetworkId();

		return this.apiService.get(`/networks/${this.networkId}/user_todo_counts/`);
	}

	/**
	 * Create Department from Join workspace Flow
	 * @param body Department Name Object
	 * @param selectedNetworkUID Selected Network's uid
	 * @returns Created department
	 */
	public createDepartmentFromJoinWork(body: any, selectedNetworkUID: string): Observable<any> {
		return this.apiService.post(`/networks/${selectedNetworkUID}/departments/`, body);
	}

	// #region TimeZones

	public getTimezones(): Observable<any> {
		return this.apiService.get(`/geo/timezones/`);
	}

	public paginateTimeZone(url: string): Observable<any> {
		return this.apiService.get(url);
	}

	public searchTimeZone(query: string): Observable<any> {
		return this.apiService.get(`/geo/timezones/?query=${query}`);
	}

	// #endregion
}
