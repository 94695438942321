import { DailyMessageService } from './../shared/components/network/daily-message-widget/services/daily-message.service';
import {
	Component,
	OnInit,
	ViewChildren,
	ViewChild,
	QueryList,
	ElementRef,
	Input,
	Output,
	EventEmitter,
	ChangeDetectorRef,
	AfterViewInit,
	OnChanges,
	SimpleChanges,
	HostBinding,
	TemplateRef,
	ViewContainerRef,
	// eslint-disable-next-line spellcheck/spell-checker
	Renderer2,
	Injector,
} from '@angular/core';
import {
	UserService,
	User,
	Post,
	Todo,
	NetworkService,
	PostService,
	MemberService,
	CommentsService,
	LocationService,
	SocketIoService,
	GeoLocationService,
	LocationObj,
	CollectionsService,
	Reminder,
	Subtask,
	ITodo,
} from '../shared';
import { Router } from '@angular/router';
import { PostListComponent, PrivacyComponent, DueDateComponent } from '../shared';
import { LayoutService } from '../shared/services/layout.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { BehaviorSubject, Subject, Subscription, timer } from 'rxjs';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments/environment';
import * as moment from 'moment';
import { StartEndDayService } from '../shared/services/home/start-end-day.service';
import MathUtils from '../shared/utils/math-utils';
import { ToastService } from '../shared/services/common/toast.service';
import { ProjectService } from '@modules/project/services/project.service';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';
import { MixPanelService } from '@core/services/third-party/mix-panel.service';
import CacheUtils from '@shared/utils/cache-utils';
import { SegmentAnalyticsService } from '@core/services/third-party/segment-analytics.service';

// tui implementation
import Editor from '@toast-ui/editor';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight-all.js';
import Prism from 'prismjs';
import 'prismjs/components/prism-clojure.js';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { IWorkPlace, workplaceData } from '../shared/models/workspace/workplace';
import cloneDeep from 'lodash/cloneDeep';
import { format } from 'date-fns';

const CSS_CLASS_NAMES = {
	highLight: 'dd-highlight-item',
};

@Component({
	selector: 'app-post',
	templateUrl: './post.component.html',
	styleUrls: ['./post.component.scss'],
	providers: [NgbCarouselConfig],
})
export class PostComponent implements OnInit, AfterViewInit, OnChanges {
	@HostBinding('class.row') public row = true;

	@ViewChild('datePicker', { static: true }) public datePicker: ElementRef;
	@ViewChildren('textArea') public textArea: QueryList<ElementRef>;
	@ViewChild('modelBody', { static: false }) public modelBody: ElementRef;
	@ViewChild('modelTitle', { static: false }) public modelTitle: ElementRef;
	@ViewChild('postList', { static: false }) public child: PostListComponent;
	@ViewChild('memberList', { static: true }) public memberList: ElementRef;
	@ViewChild('choicesInput', { static: true }) public choicesInput: ElementRef;
	@ViewChild('startDatePicker', { static: false }) public startDatePicker: ElementRef;
	@ViewChild(PrivacyComponent, { static: true }) public privacy;
	@ViewChild(DueDateComponent, { static: true }) public dueDateComponent;
	@ViewChildren(PostListComponent) public postList: any;
	@ViewChild('overlayTemplate') public overlayTemplate: TemplateRef<any>;
	@ViewChild('commentPopupTemplate') public commentPopupTemplate: TemplateRef<any>;

	@Input() public postType = 'post';
	@Input() public currentPage = '';
	@Input() public todoDetail = '';
	@Input() public product = '';
	@Input() public customerListState = '';
	@Input() public projectDetail = '';
	@Input() public calendarDate = '';
	@Input() public showMainPostForm = false;
	@Input() public collectionUid = '';
	@Input() public postUrl;
	@Input() public locObject;
	@Input() public rating;
	@Input() public channel;
	@Output() public newPostEmitter = new EventEmitter<any>();
	@Output() public refreshProducts: EventEmitter<any> = new EventEmitter();
	@Output() public refreshLists: EventEmitter<any> = new EventEmitter();
	@Output() public modalClosedEmitter: EventEmitter<any> = new EventEmitter();
	@Output() public newCustomerDetailAddedEmitter: EventEmitter<any> = new EventEmitter();

	// #region itemLoading

	private _todoAction = new BehaviorSubject<any>(null);

	@Input() set todoAction(value: any) {
		this._todoAction.next(value);
	}

	get todoAction(): any {
		return this._todoAction.getValue();
	}

	// #endRegion
	public isNeaty = environment.appName === 'Neaty';

	public appName = environment.appName;

	public showMore = true;
	public showTodoMore = true;
	public selectedDate: any;
	public toggle = {};
	// eslint-disable-next-line spellcheck/spell-checker
	public toggler = {};

	public meridian = false;
	public spinners = false;
	public dueTimeDisabled = false;
	public showTaskTimeError = false;
	public taskDueTime: any;
	public taskReminders = [];
	public zoom_oauth_url = environment.zoom_oauth_url;

	private reminderObject = {
		username: '',
		contentUid: '',
		contentType: '',
		repeat: false,
	};
	public timeForReminder: any;
	public reminderMessage;
	public toast_message = 'none';
	public actionsGif = 'none';
	public isShimming = 0;
	public todos = [];
	public noLists = [];
	public startDaySelected = false;
	public noTasks = false;
	public displayStartEndModal = 'none';
	public next_url;

	public selectedTasks: any[] = [];
	public todoListsNames: any[] = [];
	public listIds: any[] = [];
	public noListUids: any[] = [];
	public selectedTodos: any = [];
	public language;
	public projectStartDate: any;
	public attachmentMember;
	public projectEndDate: any;
	public eventStarts: any;
	public displayDatePicker = false;
	public todosFetched: any = 0;
	public scrollStatus = false;
	public displayWelcomeDialog = 'none';
	public externalUserChannel: any;
	public isExternalUser = false;
	public multipleExternalUser = false;
	public show: boolean;
	public shiftKeyUsed = false;
	public currentUser: User;
	public network;
	public changingTitle;
	public currentSubscription: any;
	private currentNetSub;
	public value;
	public SubscriptionTemplates = [];
	public members: any[] = [];
	public selectedMembers: any[] = [];
	public showSpin;
	public buySellLocation = 'Click to select a location';
	public posts;
	public nextUrl;
	public filters;
	public photo_upload = false;
	public question_photo = false;
	public showAttachmentOption = false;
	public recommendationPost = false;
	public event_post = false;
	public classified = false;
	public showBg = false;
	public post_categories;
	public ordinary_post = true;
	public article = false;
	public member_display = 'none';
	public post_type = {};
	public selected_post: Post;
	public errors;
	public networkLoaded;
	public saved_post;
	public location_display = 'none';
	public displayBuySellLocations = 'none';
	public buySellCities = [];
	public searchCityQuery;
	public noCities = false;
	public selectedCity = {
		name: this.translate.instant('Location'),
		uid: '',
	};
	public locations;
	public currentLocation;
	public errorMessage: string;
	public currentRate = 8;
	public startDate: any;
	public endDate: any;
	public startDateClick = false;
	public endDateClick = false;
	public eventImageExists = false;
	public noProjects = false;
	public eventImages: any;
	public selectedEventType: any = null;
	public timeZone: string = null;
	public tempPrice: any = null;
	public nextMembersUrl = '';
	public memberModelType = null;
	public privacy_settings = 'none';
	public custom_privacy_display = false;
	public custom_privacy: any[] = [];
	public totalMembersCount = 0;
	public channels;
	public roles = JSON.parse(localStorage.getItem('roles'));
	public privacyMemberList: any[] = [];
	public membership = JSON.parse(localStorage.getItem('membership'));
	public userRole = this.membership.memberRole.role;
	public departments: any[] = [];
	public role: any;
	public selectedDepartment = '';
	public roleString: any = null;
	public departmentString: any = null;
	public personalFilterString: any = null;
	public publicPost = false;
	public zoomImage;

	public selectByStatusString: any = null;
	public selectByRenewalString: any = null;
	public selectByLevelString: any = null;

	public issuePriorities: any = ['P1', 'P2', 'P3'];
	public addIssue = false;
	public selectedPriority;
	public repeatOptions = [
		{
			option: 'Today',
			slug: 'daily',
		},
		{
			option: 'Tomorrow',
			slug: 'one_off',
		},
		{
			option: 'This week',
			slug: 'weekly',
		},
		{
			option: 'This month',
			slug: 'monthly',
		},
		{
			option: 'Specify Date',
			slug: 'one_off',
		},
	];
	public priorities = ['P1', 'P2', 'P3'];
	public repeatObj = {
		option: '',
	};
	public memberObj = {
		option: '',
	};
	public selectedOption = {
		option: '',
	};
	public showOptions = false;
	public selectedProject;
	public display_options = 'none';
	public display_todoLists = 'none';
	public display_priority = 'none';
	public display_members = 'none';
	public todoLists: any[] = [];

	public lists = {
		name: '',
		uid: '',
	};
	public project = {
		id: '',
		name: 'Project',
	};
	public todo_next_url;
	public attachmentMembersNextPageUrl;
	public selectedDueDate: any;
	public selectedRepeatOption = {
		option: 'Today',
		slug: 'daily',
	};
	public selectedPriorityOption;
	public listPrivacy: any;
	// eslint-disable-next-line spellcheck/spell-checker
	public assignees: any[] = [];
	public defaultTaskMember;
	public display_date = false;
	public confirm_members = false;
	public priority_p1 = false;
	public priority_p2 = false;
	public priority_p3 = false;
	public todoList_uid: any = '';
	public itemMember: any;

	public isListsLoading = false;

	public showSuggestionOnDescription = false;
	public showUserSuggestionOnTitle = false;
	public next_mention_url;
	public searchResults = [];
	public showSearchBoxOnTitle = false;
	public showSearchBoxOnDescription = false;
	public showHashDescription = false;
	public titleFocus = true;

	public showHashTagSuggestionOnTitle = false;
	public showHashTagSuggestionOnDesc = false;
	public showHashSearchBoxOnTitle = false;
	public showHashSearchBoxOnDesc = false;
	public nextHashUrl = '';
	public project_next_url;
	public choices: any[] = [];
	public choiceValue: any;
	public productCategories;
	public productCondition;
	public privacyModel: any;
	public showBackdrop: any;
	public enlargeForm: any;
	public showMenu = false;
	public hidePlusBtn = false;
	public isQuickPost = false;
	public showPostType = false;
	public selectedPostTypeLabel = 'Post';
	public isFree = false;
	public showMoreOptions = false;
	public defaultCp;
	public searchList: any[] = [];
	public hashTagList: any[] = [];
	public membershipFields;
	public showUserSuggestion = false;
	public showHashTagSuggestion = false;
	public locationErrorMessage = '';
	public currentNetworkDefaultCp;
	public doc_upload = false;
	public fileType = [];
	private documentList = ['XLSX', 'XLS', 'DOC', 'DOCX', 'PPT', 'PPTX', 'TXT', 'PDF', 'CSV'];
	private audioList = ['MP3', 'WAV', 'M4A'];
	private videoList = ['MP4', 'MOV'];
	public choiceIndexContainer: any[] = [];
	public networkId;
	public lastPrivacyExist = false;
	public lastPrivacyValue;
	public everyoneSelected;
	public personalFilters: any = [];
	public personalFilterArray: any = [];
	public personalFilterValue;
	public assignedMembers: any[] = [];
	public projects: any[] = [];
	public reviews: any[] = [];
	public mentionIndex;
	public meeting_duration;
	public showZoomLinkModal = 'none';
	public startDateString;
	public showStartPicker = false;
	public forNames;

	public suggestedTasks: any[] = [];
	public showTitleSuggestion = false;
	public isTitleLoading: boolean;
	public suggestionNextUrl;
	public selectedTask;
	public displayError = 'none';
	public personals = [];

	public displayCreateTodoList = 'none';
	public isTodolistCreateForm = false;
	public repeatCycleOptions = ['daily', 'weekly', 'monthly', 'one-off'];
	public TodoListRepeatCycle = 'daily';
	public displayDateField = false;
	public todoListDate = {
		year: new Date().getFullYear(),
		month: new Date().getMonth() + 1,
		day: new Date().getDate(),
	};
	public todolist = {
		name: '',
		description: '',
		collaboration: true,
		repeatCycle: 'daily',
		state: 'active',
		statusPost: true,
	};
	public todoListCreateError;

	public addCustomer = true;
	private locale = 'tr-TR';

	public subtaskValue: any;
	public subtasksIndexContainer: any[] = [];
	public subtasks: any[] = [];
	public currentSub;
	public subAssignee;
	public memberUid;
	public displaySubAssignee = false;
	// eslint-disable-next-line spellcheck/spell-checker
	public displaySubassignee = 'none';
	public displaySubDueDateOptions = 'none';
	public sub = 'daily';
	public subRepeats = ['daily', 'tomorrow', 'weekly', 'monthly', 'one_off'];
	public subtaskDate = {
		year: new Date().getFullYear(),
		month: new Date().getMonth() + 1,
		day: new Date().getDate(),
	};
	public subtask = new Subtask();
	public isSubtask = false;
	public native;
	public index;

	public taskForReminder;
	public reminderTime;
	public timeFor;
	public remindersSelected = [];
	public reminderModel = new Reminder();
	public onConfirm = false;

	public error = {
		detail: this.translate.instant('Something went wrong'),
		status: '',
	};
	public query;
	private customerSubject: Subject<string> = new Subject();

	public customer = {
		title: '',
		status: '',
		priority: '',
		category: 'customer',
		type: 'customer',
		state: 'active',
		shareable: true,
		commentable: true,
		contentType: 'network',
	};
	public noAssignee = true;
	public isCalling = 0;
	public displayModalForCustomer = 'none';
	public displayPriorities = 'none';
	public displayStates = 'none';
	public customerSelected;
	public currentCustomer;
	public prioritySelected;
	public currentPriority;
	public priority;
	public currentState;
	public stateSelected;
	public isCustomerSubmitted = false;
	public selectedCustomerUid;
	public customerStates = [];
	public priorityOptions = [
		{
			name: 'High',
			slug: 'p1',
		},
		{
			name: 'Med',
			slug: 'p2',
		},
		{
			name: 'Low',
			slug: 'p3',
		},
	];
	public incompleteTodos = [];
	public displayEndDayConfirm = 'none';
	public totalCounts: any;
	public endDayText;

	public selectedMember = {
		uid: '',
	};
	public memberForAttachment: any;
	public showPostForms = false;
	public displayPostShortCuts = false;
	public displayBackButton = true;
	public displayShortCuts = true;

	public showTaskForms = false;
	public displayTaskOptions = false;
	public displayIssueOptions = false;
	public displayDueTimeModal = 'none';
	public displayRepeats = 'none';
	public currentRepeat = '';
	public repeatSelected = 'never';
	public reminderSelected = ['none', 'None'];
	public displayTaskReminders = 'none';
	public currentDueTime: any = '';
	public displayTaskShortCuts = false;
	public displayCustomerAdditionalOptions = false;

	public displaySalesModal = 'none';
	public displayDealsModal = 'none';
	public displayTypesModal = 'none';
	public displayCurrencyModal = 'none';
	public displayContactDetails = false;
	public customerContactName;
	public salesSelected;
	public dealSelected;
	public typeSelected;
	public currencySelected = 'TRY';
	public customerPrice: any;
	public currentSaleChannel = '';
	public currentDealType = '';
	public currentCustomerType = '';
	public currentCurrencyType = 'TRY';

	public salesChannels = [
		{
			option: 'None',
			slug: 'none',
		},
		{
			option: 'Ads',
			slug: 'ads',
		},
		{
			option: 'Search',
			slug: 'search',
		},
		{
			option: 'Social Media',
			slug: 'social_media',
		},
		{
			option: 'Reference',
			slug: 'reference',
		},
		{
			option: 'Partner',
			slug: 'partner',
		},
	];
	public dealTypes = [
		{
			option: 'None',
			slug: 'none',
		},
		{
			option: 'Post Sales',
			slug: 'post_sales',
		},
		{
			option: 'Sales',
			slug: 'sales',
		},
		{
			option: 'Services',
			slug: 'services',
		},
	];
	public customerTypes = [
		{
			option: 'None',
			slug: 'none',
		},
		{
			option: 'Business',
			slug: 'business',
		},
		{
			option: 'Individual',
			slug: 'individual',
		},
		{
			option: 'Non Profit',
			slug: 'non_profit',
		},
		{
			option: 'Education',
			slug: 'education',
		},
		{
			option: 'Retail',
			slug: 'retail',
		},
		{
			option: 'Health',
			slug: 'health',
		},
		{
			option: 'Government',
			slug: 'government',
		},
		{
			option: 'Municipality',
			slug: 'municipality',
		},
	];
	public currencyTypes = ['USD', 'EUR', 'TRY', 'INR'];
	public showCountryModal = 'none';
	public field = {
		value: '',
	};
	public selectedCountry;
	public countries: any[] = [];
	public nextCUrl;
	public countryCode;
	public searchString;
	public isLoading = false;
	public displayCountryError = false;
	public additionalContactForm: FormGroup;
	// For new issue form
	// eslint-disable-next-line spellcheck/spell-checker
	public currentpriority = 'p1';
	public displayIssuePriorities = 'none';
	public displayCustomers = 'none';
	public customers = [];
	public isCustomersFetching = 0;
	public nextCustomerUrl: any;
	public customerObj = {
		uid: '',
	};
	public selectedCustomer;
	public currentIssueCustomer = '';
	public isCustomer = false;
	public lockCustomer = false;
	public isProduct = false;
	public isTitle = false;
	public newDate = new Date();
	public currentTime;
	// eslint-disable-next-line spellcheck/spell-checker
	public errorText = 'Aww Snap! Something went wrong';
	public currentType = 'issue';
	public selectedType = 'issue';
	public displayTypes = 'none';
	public types = ['issue', 'enhancement', 'feature'];
	public starts: any;
	public ends: any;
	public eventStartDate = {
		year: this.newDate.getFullYear(),
		month: this.newDate.getMonth() + 1,
		day: this.newDate.getDate(),
	};
	public eventEndDate: any;
	public duration: any = '00:30';

	public placement = 'right';
	public modelB = {
		year: this.newDate.getFullYear(),
		month: this.newDate.getMonth() + 1,
		day: this.newDate.getDate(),
	};
	public currentDate = {
		year: this.newDate.getFullYear(),
		month: this.newDate.getMonth() + 1,
		day: this.newDate.getDate(),
	};

	@ViewChild('panel', {
		static: false,
	})
	public panel: ElementRef<any>;
	private arrowKeyLocation = -1;
	private keyupSubscription: Subscription;
	public isMentions = false;
	public mentionCount = 0;
	public mentionType;
	public userMentions = [];
	public hashMentions = [];
	public nextMentionUrl;
	public mentionPrivacy: any;
	public CaretPosition: any;
	private subject: Subject<string> = new Subject();
	public mentionsFetched = 0;
	public userRegexFailed = false;
	public hashRegexFailed = false;
	public hashes = [];

	@Input() public collectionPrivacy = null;
	@Input() public channelPrivacy = null;

	public userSuggestionString = '';
	public hashSuggestionString = '';
	public model = new Post(
		{
			title: '',
			body: '',
			category: null,
			privacy: '',
			images: [],
			preview_images: [],
			shareable: true,
			commentable: true,
			channel: '',
		},
		true
	);
	public issueModel = {
		category: 'bugs',
		network: localStorage.getItem('network_id'),
		collection_post: true,
		type: 'issue',
		username: localStorage.getItem('uid'),
		commentable: true,
		contentType: 'network',
		state: 'active',
		shareable: true,
		description: '',
		priority: '',
		products: '',
		title: '',
	};
	public todoItem = new Todo();
	public submitted = false;
	public range = -1;
	public userSearch = '';
	public hashTagSearch = '';
	public nextUserUrl = '';
	public searchLocation = '';
	public projectPrivacyCp: any;
	public todoPrivacyCp: any;
	public errorDialogForCpSelection = 'none';
	public errorTextForCpSelection = '';
	public dailyMessageObj: any;
	public projectUpdatePost = false;
	public taskType: 'task' | 'subTask' = 'task';
	public displayElapsed = 'none';
	public elapsedForm: FormGroup;
	public reaction = '';
	public selectedTodo: any;
	public parentTask: any;
	public taskReactionDialog = 'none';
	public reactionToEdit: any;
	public showEditReactionDialog = 'none';
	public selectedItem: any;
	public startDayUserCounts: any;
	public userCountLoaded = false;
	public currentDayInfo = new Date();
	public tuiEditor: Editor | null = null;

	private overlayRef: OverlayRef;
	private commentOverlayRef: OverlayRef;
	public selectedTaskToClose: ITodo;

	public showButtonLoader = false;

	public workplaceData: IWorkPlace[] = workplaceData;
	private selectedWorkPlace: IWorkPlace = workplaceData[0];

	constructor(
		private postService: PostService,
		private fb: FormBuilder,
		private router: Router,
		private networkService: NetworkService,
		private userService: UserService,
		private memberService: MemberService,
		private locationService: LocationService,
		private geoLocationService: GeoLocationService,
		private ref: ChangeDetectorRef,
		private socket: SocketIoService,
		private translate: TranslateService,
		private parserFormatter: NgbDateParserFormatter,
		private commentService: CommentsService,
		private startEndDayService: StartEndDayService,
		private toastService: ToastService,
		public layoutService: LayoutService,
		public config: NgbCarouselConfig,
		public collectionService: CollectionsService,
		private dailyMessageService: DailyMessageService,
		private projectService: ProjectService,
		private pageLoadingUiService: PageLoadingUiService,
		private mixPanelService: MixPanelService,
		private segmentService: SegmentAnalyticsService,
		private overlay: Overlay,
		private viewContainerRef: ViewContainerRef,
		// eslint-disable-next-line spellcheck/spell-checker
		private renderer: Renderer2,
		// private replacePipe: ReplacePipe
		private injector: Injector
	) {
		if (localStorage.getItem('networkExpired')) {
			this.router.navigateByUrl('/expired');
		}
		config.interval = 10000;
		config.wrap = false;
		config.keyboard = false;

		this.additionalContactForm = this.fb.group({
			houseNo: [''],
			streetOne: [''],
			streetTwo: [''],
			city: [''],
			state: [''],
			country: ['Turkey'],
			code: [90],
			zip: [''],
			phone: ['', [Validators.pattern('^[0-9]*$')]],
			email: [''],
		});

		this.elapsedForm = this.fb.group({
			time: ['', Validators.required],
		});

		// Start/End day
		this.startEndDayService.showTodosEvent.subscribe((type: string) => {
			this.displayTodosModal(type);
		});

		// Daily message widget action event
		this.dailyMessageService.dailyActionClickedEvent.subscribe((dailyMessage: any) => {
			// route redirections and other cases handle by it self component

			if (dailyMessage.slug === 'forgot-start-day') {
				this.displayTodosModal(this.todoAction);
			} else if (dailyMessage.slug === 'create-todo-tasks') {
				this.selectPost({
					name: 'Add Task',
					slug: 'add-task',
				});
				this.layoutService.showBd('post-menu');
			} else if (dailyMessage.slug === 'forgot-end-day') {
				this.displayTodosModal(this.todoAction);
			}
		});

		this.startEndDayService.toggleStarDayEvent.subscribe((status: boolean) => {
			if (status) {
				this.displayTodosModal('Start Day');
			}
		});
	}

	ngOnInit(): void {
		this._todoAction.subscribe((status: string) => {
			this.startEndDayService.updateDayStatus(status === 'Start Day');
		});

		this.eventEndDate = this.eventStartDate;

		if (this.networkService.todoAction) {
			this.displayTodosModal(this.networkService.todoAction === 'start' ? 'Start Day' : 'End Day');
		}

		const d = new Date();
		this.starts = d.getHours() + ':' + d.getMinutes();

		if (localStorage.getItem('language')) {
			if (localStorage.getItem('language') === 'en') {
				this.language = 'en-IN';
			} else {
				this.language = 'tr-IN';
			}
		}

		if (localStorage.getItem('network_id')) {
			this.networkId = localStorage.getItem('network_id');
		}

		if(localStorage.getItem(`${this.networkId}`) !== 'Everyone'){
			this.everyoneSelected = JSON.parse(localStorage.getItem(`${this.networkId}`));
		}else{
			this.everyoneSelected = 'Everyone';
		}

		if (this.currentPage && this.currentPage !== 'collection' && this.currentPage !== 'channel-detail') {
			this.showMainPostForm = true;
		}

		this.role = this.userRole;

		if (this.router.url === '/network/saved-post') {
			this.saved_post = true;
		} else {
			this.saved_post = false;
		}

		this.privacyModel = this.model;
		this.membership.memberRole.fields.forEach((element) => {
			if (element.type === 'department') {
				this.departments.push(element);
			}
			if (element.CP === true) {
				this.personals.push(element);
			}
		});

		this.networkService.currentNetwork.subscribe((network) => {
			if (network.membership && network.membership.memberRole) {
				// if (!this.networkService.todoAction) {
				this.checkForStartDayEndDay(network['membership']);
				// }
				this.defaultCp = network['membership']['memberRole']['defaultContentPrivacy'];
				this.membershipFields = network['membership']['memberRole']['fields'];
				// this.membershipFields.forEach((field) => {
				//   if (this.currentPage !== 'collection' && this.currentPage !== 'channel-detail') {
				//     if (field.key === this.defaultCp) {
				//       if (!localStorage.getItem(`${this.networkId}`)) {
				//         this.currentNetworkDefaultCp = field.valueLabel;
				//         this.privacy['personalFilter'] = field;
				//         this.privacy.previousSelection = field.valueLabel;
				//         this.privacy.currentSelection = field.valueLabel;
				//         this.privacy.personalFilterValue = field.valueLabel;

				//       }
				//     }
				//   }
				// });

				if (network['membership']['memberRole'] && network['membership']['memberRole']['role']) {
					const memberRole = network['membership']['memberRole']['role'];
					if (memberRole.slug === 'external') {
						if (network.channels.length > 0) {
							network.channels.forEach((channel) => {
								if (channel.access === 4) {
									this.externalUserChannel = channel;
									this.mentionPrivacy = 'channel=' + this.externalUserChannel['uid'];
								}
							});
							this.isExternalUser = true;
							const externalChannels = [];
							network.channels.forEach((channel) => {
								if (channel['access'] === 4) {
									externalChannels.push(channel);
								}
							});
							if (externalChannels.length > 1) {
								this.multipleExternalUser = true;
								if (this.privacy.selectedChannel === 'All') {
									this.privacy.selectedChannel = this.externalUserChannel;
									this.privacy.previousSelection = this.externalUserChannel['name'];
									this.privacy.currentSelection = this.externalUserChannel['name'];
								}
							}
						}
					}
				}
			}

			this.network = network;
			this.membership = network['membership'];

			// Add customer restriction for free tier
			if (
				(network?.subscription?.planQuotas?.numberOfCustomers === -1 || network?.subscription?.network?.subscription?.subscriptionPlan?.name === 'Starter') &&
				this.network?.services?.length > 0
			) {
				const i = this.network.services.findIndex((service) => service.slug === 'add-customer');
				this.network.services.splice(i, 1);
			}

			if (this.network['services'] && this.network['services'].length > 0) {
				const i = this.network['services'].findIndex(
					(service) => service['slug'] === 'question' || service['slug'] === 'recommend' || service['slug'] === 'event' || service['slug'] === 'event'
				);
				if (i > -1) {
					this.showMore = true;
				} else {
					this.showMore = false;
				}

				if (!this.currentPage) {
					const index = this.network['services'].findIndex((service) => service['slug'] === 'add-customer' || service['slug'] === 'add-issue');
					if (index > -1) {
						this.displayShortCuts = true;
					} else {
						this.displayShortCuts = false;
					}
				}
			}

			/** 1. For checking if customer service exists in network
			 * 2. If exists,display customer selection in issue create form
			 */
			if (this.network.services && this.network.services.length > 0) {
				const index = this.network.services.findIndex((service) => service.slug === 'add-customer'); // 1
				if (index > -1) {
					this.isCustomer = true; // 2
				} else {
					this.isCustomer = false;
				}
			}
		});

		/** For handling customer selection in issue create form.
		 * 1. Check if current subscription is either premium or business.
		 * 2. If premium,check is subscription is purhcased or not
		 * 3. If purchased,lock customer selection,else unlock it.
		 * 4. If subscription is business,unlock customer selection
		 */
		this.networkService.subscriptionReady.subscribe((subscriptionLoaded) => {
			if (subscriptionLoaded) {
				this.currentNetSub = this.networkService.currentSubscription.subscribe((subscription) => {
					if (subscription['subscriptionPlan']) {
						this.currentSubscription = subscription['subscriptionPlan'];
						if (this.currentSubscription?.name === 'Premium' || this.currentSubscription?.name === 'Basic') {
							// 1
							if (subscription && !subscription['purchasedPlan']) {
								// 2
								this.lockCustomer = false; // 3
							} else {
								this.lockCustomer = true; // 3
							}
						} else {
							this.lockCustomer = false; // 4
						}
					}
				});
			}
		});

		if (this.network['privacySettings'] && this.network['membership']['memberType'] === 'normal') {
			this.checkForCustomerSettings(this.network);
		}

		/** For handling task/issue/customer create forms from welcome tips
		 * 1. Check if current welcome tip is task and open create task form
		 * 2. Check if current welcome tip is issue and open create issue form
		 * 3. Check if current welcome tip is customer and open create customer form
		 */
		this.networkService.formReady.subscribe((formLoaded) => {
			if (formLoaded) {
				this.networkService.currentForm.subscribe((data) => {
					this.layoutService.showBd('main-post');
					if (data === 'task') {
						// 1
						this.selectPost({
							name: 'Add Task',
							slug: 'add-task',
						});
					} else if (data === 'issue') {
						// 2
						this.displayBackButton = false;
						this.selectPost({
							name: 'Add an Issue',
							slug: 'add-issue',
						});
					} else if (data === 'customer') {
						// 3
						this.displayBackButton = false;
						this.selectPost({
							name: 'Add Customer',
							slug: 'add-customer',
						});
					}
				});
			}
		});

		/** For handling @/# mentions searches
		 * 1. Call api for getting search results
		 * 2. If mention type is user,fill userMentions array
		 * 3. Else fill hashMentions array
		 * 4. If nextUrl exists,set nextUrl and call paginate function
		 */
		this.subject.pipe(debounceTime(400)).subscribe((searchTextValue) => {
			// 1

			const i = searchTextValue.indexOf('@');
			if (i > -1) {
				searchTextValue = searchTextValue.substring(i + 1);
			}

			this.mentionsFetched = 1;
			this.postService.getMentions(searchTextValue, this.mentionType, this.mentionPrivacy).subscribe((data) => {
				this.mentionsFetched = 0;
				this.arrowKeyLocation = -1;
				this.mentionCount = data.count;
				if (
					(this.model.title && !this.isMentions) ||
					(!this.model.title && this.isMentions) ||
					(this.model.title && this.isMentions) ||
					(this.model.body && !this.isMentions) ||
					(!this.model.body && this.isMentions) ||
					(this.model.body && this.isMentions) ||
					this.isSubtask
				) {
					if (!this.userRegexFailed || !this.hashRegexFailed) {
						if (this.mentionType === 'user') {
							// 2
							this.hashMentions = [];
							this.userMentions = data.objects;
						} else if (this.mentionType === 'hash') {
							this.userMentions = []; // 3
							const filterData = [];
							data.objects.forEach((item: any) => {
								if (item.name !== '') {
									filterData.push(item);
								}
							});
							this.hashMentions = filterData;
						}
						if (data.next) {
							// 4
							this.nextMentionUrl = data.next.split('alpha')[1];
							this.paginateMentions();
						} else {
							this.nextMentionUrl = '';
						}
					}
				}
			});
		});

		this.customerSubject.pipe(debounceTime(400)).subscribe((query) => {
			this.customers = [];
			this.networkService.searchCustomers(query).subscribe((data) => {
				this.isCustomersFetching = 1;
				this.customers = data['objects'];
				if (data.next) {
					this.nextCustomerUrl = data.next.split('alpha')[1];
				} else {
					this.nextCustomerUrl = '';
				}
			});
		});

		/** For displaying welcome dialog popup for new user in network
		 * 1. Check if welcome dialog is true,then display welcome popup
		 * 2. Else hide popup
		 */
		if (this.networkService.displayWelcomeDialog) {
			this.displayWelcomeDialog = 'block'; // 1
		} else {
			this.displayWelcomeDialog = 'none'; // 2
		}

		if (this.currentPage !== 'collection') {
			this.loadCachedPrivacy();
		} else {
		}

		this.networkService.networkReady.subscribe((networkLoaded: boolean) => {
			this.networkLoaded = networkLoaded;

			if (networkLoaded) {
				this.loadNetworkData();
			}
		});

		this.userService.currentUser.subscribe((userData) => {
			this.currentUser = userData;
		});

		this.layoutService.showBackdrop.subscribe((data) => {
			if (data === 'main-post') {
				this.showMainPostForm = false;
				this.showPostForms = false;
				this.showTaskForms = false;
				this.showMenu = false;
			} else if (data === 'post_locations') {
				this.location_display = 'none';
			} else if (data === 'post-menu') {
				this.showMenu = false;
				this.hideMainForm();
			} else if (data === 'privacy') {
				this.layoutService.hideBd();
			}
		});

		this.layoutService.clickOutside.subscribe((data) => {
			if (
				data['srcElement'].className === 'dropdown pull-right user-suggestion' ||
				(data['srcElement'].attributes.id &&
					(data['srcElement'].attributes.id.nodeValue === 'hash-search' || data['srcElement'].attributes.id.nodeValue === 'hash-view-more'))
			) {
				if (this.showUserSuggestionOnTitle) {
					this.showUserSuggestionOnTitle = true;
				}
				// For title suggestion in add task
				if (this.showTitleSuggestion) {
					this.showTitleSuggestion = true;
				}
				// For new hashtag mention [29-07-20]
				if (this.showHashTagSuggestionOnTitle) {
					this.showHashTagSuggestionOnTitle = true;
				}
				//
			} else {
				// For title suggestion in add task
				this.showTitleSuggestion = false;

				this.showUserSuggestionOnTitle = false;
				this.showSearchBoxOnTitle = false;
				this.showSearchBoxOnDescription = false;
				this.showSuggestionOnDescription = false;

				// For new hashtag mention [29-07-20]
				this.showHashTagSuggestionOnTitle = false;
				this.showHashTagSuggestionOnDesc = false;
				this.showHashSearchBoxOnDesc = false;
				this.showHashSearchBoxOnTitle = false;
				// -
				this.userSearch = '';
				this.hashTagSearch = '';
				this.mentionsFetched = 0;
				this.userMentions = [];
				this.hashMentions = [];
			}
		});

		this.privacy.privacyModalStatus.subscribe((data) => {
			if (data === 'closed') {
				if (this.isTodolistCreateForm) {
					this.displayCreateTodoList = 'block';
					this.display_todoLists = 'block';
					this.updatePrivacyStringBasedOnPrivacyComponent();
				}
			}
			this.checkPrivacyForMentions();
		});

		this.dueDateComponent.modalStatus.subscribe((data) => {
			if (data === 'closed') {
				this.setTodoOptions();
			}
		});

		this.socket.getSocket().on('start-day', (message) => {
			const memberShipId = JSON.parse(localStorage.getItem('membership'));
			const networkId = localStorage.getItem('network_id');
			const data = JSON.parse(message);
			if (data.memberId === memberShipId.uid && data.networkId === networkId) {
				this.networkService.populate();
			}
		});

		this.socket.getSocket().on('end-day', (message) => {
			const memberShipId = JSON.parse(localStorage.getItem('membership'));
			const networkId = localStorage.getItem('network_id');
			const data = JSON.parse(message);
			if (data.memberId === memberShipId.uid && data.networkId === networkId) {
				this.networkService.populate();
			}
		});
	}

	/**
	 * Load network data
	 */
	private loadNetworkData(): void {
		if (this.network.SubscriptionTemplates) {
			this.SubscriptionTemplates = this.network.SubscriptionTemplates;
		}

		if (this.currentPage !== 'collection' && this.currentPage !== 'channel-detail') {
			this.postUrl = '/networks/' + this.network.uid + '/posts/';
		}

		this.channels = this.network['channels'];

		for (const res of this.network.services) {
			this.post_type[res.slug] = res.slug === 'post';
		}

		if (this.membership) {
			this.departments = [];
			this.membership.memberRole.fields.forEach((element) => {
				if (element.type === 'department') {
					this.departments.push(element);
				}
			});
		}

		this.geoLocationService
			.getLocation({
				enableHighAccuracy: true,
			})
			.subscribe(
				(position) => {
					this.currentLocation = position;

					this.locationService.getNearMeLocations(position.coords).subscribe((data: any) => {
						const locations = data.response.groups[0].items.map((loc: any) => new LocationObj(loc, '4sq'));

						this.locations = this.sortData(locations);
					});

					this.ref.detectChanges();
				},
				(error) => {
					this.locationErrorMessage = error;
					this.ref.detectChanges();
				}
			);
	}

	/** Retrieving cp on switching networks */
	private updateSelectionFields(): void {
		if (this.currentPage !== 'collection' && this.currentPage !== 'channel-detail') {
			if (localStorage.getItem(`${this.networkId}`)) {
				let retrievedCP;
				if(localStorage.getItem(`${this.networkId}`) !== 'Everyone'){
					retrievedCP = JSON.parse(localStorage.getItem(`${this.networkId}`));
				}else{
					retrievedCP = 'Everyone';
				}
				if (retrievedCP.privacyType === 'role') {
					this.privacy['selectedRole'] = retrievedCP;
					this.forNames = retrievedCP.name;
					this.privacy.previousSelection = retrievedCP.name;
					this.privacy.currentSelection = retrievedCP.name;
					if (retrievedCP.role) {
						this.privacy.previousSelection = retrievedCP.role;
						this.privacy.currentSelection = retrievedCP.role;
					}
				}
				if (retrievedCP.privacyType === 'department') {
					this.privacy['selectedDepartment'] = retrievedCP;
					if (retrievedCP.valueLabel) {
						this.privacy.previousSelection = retrievedCP.valueLabel;
						this.privacy.currentSelection = retrievedCP.valueLabel;
						this.forNames = retrievedCP.valueLabel;
					}
					if (retrievedCP.name && retrievedCP.uid) {
						this.privacy.previousSelection = retrievedCP.name;
						this.privacy.currentSelection = retrievedCP.name;
						this.forNames = retrievedCP.name;
					}
				}
				if (retrievedCP.privacyType === 'channel' || retrievedCP.privacyType === 'subCircle') {
					this.model.channel = retrievedCP.uid;
					this.privacy.channel = retrievedCP;
					this.privacy.selectedChannel = retrievedCP;
					this.privacy.previousSelection = retrievedCP.name;
					this.privacy.currentSelection = retrievedCP.name;
					this.forNames = retrievedCP.name;
				}
				if (retrievedCP.privacyType === 'personalFilter') {
					this.privacy['personalFilter'] = retrievedCP;
					this.privacy.previousSelection = retrievedCP.valueLabel;
					this.privacy.currentSelection = retrievedCP.valueLabel;
					this.forNames = retrievedCP.valueLabel;
				}
				if (retrievedCP.privacyType === 'selectByLevel') {
					this.privacy['selectByLevelValue'] = retrievedCP;
					this.privacy.previousSelection = retrievedCP.name;
					this.privacy.currentSelection = retrievedCP.name;
					this.forNames = retrievedCP.name;
				}
				if (retrievedCP === 'Everyone') {
					this.currentNetworkDefaultCp = '';
					this.privacy.previousSelection = 'Everyone';
					this.privacy.currentSelection = 'Everyone';
					this.forNames = 'Everyone';
				}
				if (Array.isArray(retrievedCP)) {
					retrievedCP.forEach((member) => {
						if (member.privacyType === 'customPrivacy') {
							this.privacy['custom_privacy'] = retrievedCP;
							const membersList = [];
							for (const u of this.privacy['custom_privacy']) {
								if (u.user && u.user.firstName) {
									membersList.push(u.user.firstName);
								} else if (u.firstName) {
									membersList.push(u.firstName);
								} else if (u.name) {
									membersList.push(u.name);
								} else if (u.username) {
									membersList.push(u.username);
								}
							}
							this.privacy.previousSelection = membersList.join(', ');
							this.privacy.currentSelection = membersList.join(', ');
							this.forNames = membersList.join(', ');
						}
					});
					if (retrievedCP.includes('status') === true) {
						this.privacy.selectByStatusValue = retrievedCP;
						this.privacy.previousSelection = retrievedCP[1];
						this.privacy.currentSelection = retrievedCP[1];
						this.forNames = retrievedCP[1];
					}
					if (retrievedCP.includes('renewalFrequency') === true) {
						this.privacy.selectByRenewalValue = retrievedCP;
						this.privacy.previousSelection = retrievedCP[1];
						this.privacy.currentSelection = retrievedCP[1];
						this.forNames = retrievedCP[1];
					}
				}
			} else {
				this.forNames = 'Everyone';
				this.privacy.previousSelection = 'Everyone';
				this.privacy.currentSelection = 'Everyone';
				// this.membershipFields.forEach((field) => {
				//   if (field.key === this.defaultCp) {
				//     if (!localStorage.getItem(`${this.networkId}`)) {
				//       this.currentNetworkDefaultCp = field.valueLabel;
				//       this.privacy['personalFilter'] = field;
				//       this.forNames = field.valueLabel;
				//       this.privacy.previousSelection = field.valueLabel;
				//       this.privacy.currentSelection = field.valueLabel;
				//       this.privacy.personalFilterValue = field.valueLabel;
				//     }
				//   }
				// });
			}
		}
	}

	ngAfterViewInit(): void {
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
		if (this.child) {
			this.child.showBackdrop.subscribe(() => {
				this.showBackdrop = true;
			});
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.hasOwnProperty('postType') && this.networkLoaded) {
			if (this.postType === 'event') {
				this.displayBackButton = false;
				this.selectPost({
					name: 'Setup an event',
					slug: 'event',
				});
			}
			if (this.postType === 'buy-sell') {
				this.displayBackButton = false;
				this.selectPost({
					name: 'Sell Something',
					slug: 'buy-sell',
				});
			}
			if (this.postType === 'meeting') {
				this.displayBackButton = false;
				this.selectPost({
					name: 'Video conference',
					slug: 'video-conference',
				});
			}
			if (this.postType === 'recommend') {
				this.displayBackButton = false;
				this.model.location = this.locObject;
				this.selectPost({
					name: 'Recommendation',
					slug: 'recommend',
				});
			}
			if (this.postType === 'post') {
				this.displayBackButton = false;
				this.model.location = this.locObject;
				this.selectPost({
					name: 'Post Something',
					slug: 'post',
				});
			}
			if (this.postType === 'add-issue') {
				this.displayShortCuts = false;
				this.displayBackButton = false;
				this.selectPost({
					name: 'Add an Issue',
					slug: 'add-issue',
				});
			}
			if (this.postType === 'add-task') {
				this.displayShortCuts = false;
				this.selectPost({
					name: 'Add Task',
					slug: 'add-task',
				});
			}
			if (this.postType === 'add-customer') {
				this.displayShortCuts = false;
				this.displayBackButton = false;
				this.selectPost({
					name: 'Add Customer',
					slug: 'add-customer',
				});
			}
		} else if (changes.hasOwnProperty('collectionPrivacy') && this.currentPage === 'collection') {
			this.updateCollectionPrivacy(this.collectionPrivacy);
		} else if (changes.hasOwnProperty('channelPrivacy') && this.currentPage === 'channel-detail') {
			this.updateChannelPrivacy();
		}
	}

	/**
	 * To update post privacy if loaded in collection detail page.
	 */
	private updateCollectionPrivacy(data): void {
		if (!this.isExternalUser) {
			if (data['content_privacy']) {
				if (data['content_privacy'].department) {
					const index = this.networkService.departments.findIndex((department) => department.uid === data['content_privacy'].department);
					if (index > -1) {
						this.privacy.previousSelection = this.networkService.departments[index].name;
						this.privacy.currentSelection = this.networkService.departments[index].name;
						this.privacy['selectedDepartment'] = this.networkService.departments[index];
					}
				} else {
					this.privacy['selectedDepartment'] = null;
				}

				if (data['content_privacy'].role) {
					const role = {
						name: data['content_privacy'].role,
						slug: data['content_privacy'].role,
					};

					this.privacy['selectedRole'] = role;
					this.privacy.previousSelection = role.name;
					this.privacy.currentSelection = role.name;
				} else {
					this.privacy['selectedRole'] = null;
				}

				if (!data['content_privacy'].role && !data['content_privacy'].department) {
					const field = Object.values(data['content_privacy']);
					const index = this.networkService.personalFilters.findIndex((p) => p.value === field[0]);
					if (index > -1) {
						this.privacy.previousSelection = this.networkService.personalFilters[index].valueLabel;
						this.privacy.currentSelection = this.networkService.personalFilters[index].valueLabel;
						this.privacy['personalFilter'] = this.networkService.personalFilters[index];
					}

					if (data['content_privacy'].status) {
						this.privacy.previousSelection = data['content_privacy'].status;
						this.privacy.currentSelection = data['content_privacy'].status;
						this.privacy['selectByStatusValue'] = data['content_privacy'];
					} else if (data['content_privacy'].renewalStatus) {
						this.privacy.previousSelection = data['content_privacy'].renewalStatus;
						this.privacy.currentSelection = data['content_privacy'].renewalStatus;
						this.privacy['selectByRenewalValue'] = data['content_privacy'];
					} else if (data['content_privacy'].membership_level) {
						this.privacy['selectByLevelValue'] = data['content_privacy'];
					} else if (data['content_privacy'].manager) {
						if (this.privacy.myTeam && this.privacy.myTeam.key) {
							this.privacy['personalFilter'] = this.privacy.myTeam;
							this.privacy.forNames = this.translate.instant('My Team');
						}
					}
				}
			} else {
				this.privacy['content_privacy'] = null;
				this.privacy['selectedDepartment'] = null;
				this.privacy['role'] = null;
			}

			if (data['custom_privacy'] && data['custom_privacy'].length > 0) {
				this.privacy['custom_privacy'] = data['custom_privacy'];
				const names = [];

				this.privacy.custom_privacy.forEach((user) => {
					if (user['user']) {
						names.push(user['user'].name);
					} else if (user['name']) {
						names.push(user['name']);
					} else {
						names.push(user.username);
					}
				});

				this.privacy.previousSelection = names.join(', ');
				this.privacy.currentSelection = names.join(', ');
			}

			if (data['channel']) {
				this.privacy['channel'] = data['channel'];
				this.model.channel = data['channel'].uid;
				this.privacy.selectedChannel = data['channel'];
				this.privacy.previousSelection = data['channel'].name;
				this.privacy.currentSelection = data['channel'].name;
			}

			if (!data['content_privacy'] && !data['custom_privacy'] && !data['channel']) {
				this.privacy['custom_privacy'] = [];
				this.privacy['role'] = null;
				this.privacy['selectedDepartment'] = '';
				this.privacy['selectedRole'] = '';
				this.privacy['channel'] = '';
				this.privacy.previousSelection = 'Everyone';
				this.privacy.currentSelection = 'Everyone';
				this.privacy.selectedChannel = '';
			}
		}
	}

	// Function to set channel as the privacy of posts in channel detail page
	private updateChannelPrivacy(): void {
		if (this.channelPrivacy['channel']) {
			if (this.channelPrivacy['channel'].uid) {
				this.privacy.selectedChannel = this.channelPrivacy['channel'].name;
				this.model['channel'] = this.channelPrivacy['channel'].uid;
				this.privacy.channel = this.channelPrivacy['channel'];
				this.privacy.currentSelection = this.channelPrivacy['channel'].name;
				this.privacy.previousSelection = this.channelPrivacy['channel'].name;
				this.privacy['custom_privacy'] = [];
				this.privacy['content_privacy'] = null;
				this.privacy['selectedDepartment'] = null;
				this.privacy['role'] = null;
			}
		}
	}

	public resetDefaultCp(): void {
		this.currentNetworkDefaultCp = '';
		this.lastPrivacyValue = '';
	}

	public printData(): void {}

	private sortData(data: any): any {
		return data.sort(function (m1, m2) {
			if (m1.distance < m2.distance) {
				return -1;
			} else if (m1.distance > m2.distance) {
				return 1;
			} else {
				return 0;
			}
		});
	}

	private handleError2(): void {
		alert('ffffffffff');
	}

	private onSubmit(): void {
		this.submitted = true;
	}

	// TODO: Remove this when we're done
	private get diagnostic(): any {
		return JSON.stringify(this.model);
	}

	private highlight(): void {
		this.showBg = true;
		// this.postType = 'post';
	}

	private disableIt(): void {
		delete this.model.member;
		this.hidePlusBtn = false;
		this.customer['title'] = '';
		this.selectedMember['uid'] = '';
		this.attachmentMember = '';
		this.selectedCity['uid'] = '';
		this.selectedCity['name'] = this.translate.instant('Location');
		this.showBg = false;
		this.postType = 'post';
		this.article = false;
		this.photo_upload = false;
		this.doc_upload = false;
		this.location_display = 'none';
		this.post_type = {
			'buy-sell': false,
			event: false,
			message: false,
			post: true,
			recommend: false,
		};
		this.showMainPostForm = false;
		this.showPostForms = false;
		this.showTaskForms = false;
		this.displayPostShortCuts = false;
		this.range = -1;
		this.choiceIndexContainer = [];
		this.model.images = [];
		this.model.url = '';
		this.choices = [];
		this.fileType = [];
		this.hashes = [];
		this.networkService.clearFormSubscriptions(); // For clearing active form subscriptions
		this.postService.hideBackDrop();
		delete this.model.location;

		delete this.todoItem['description'];
		delete this.model['venueName'];
		delete this.todoItem['doc'];
		delete this.todoItem['url'];
		delete this.todoItem['attachmentType'];
		delete this.todoItem['images'];
		delete this.todoItem['member'];
		delete this.todoItem['numberOfOpens'];
		this.projectUpdatePost = false;
	}

	private resetTodoForm(): void {
		this.privacy.projectUid = '';
		this.privacy.currentProject = '';
		this.privacy.projectSelected = '';
		this.mentionPrivacy = '';
		this.currentPriority = 'med';
		this.prioritySelected = 'p2';
		this.todoItem['priority'] = 'p2';
		this.selectedRepeatOption = {
			option: 'Today',
			slug: 'daily',
		};
		this.repeatObj.option = 'Today';
		this.todoItem['title'] = '';
		this.todoItem['description'] = '';
		this.model.title = '';
		this.model.body = '';
		this.model.images = [];
		this.model.url = '';
		this.choices = [];
		this.fileType = [];
		this.hashes = [];
		this.selectedMember['uid'] = '';
		this.attachmentMember = '';
		this.article = false;
		this.photo_upload = false;
		this.doc_upload = false;
		this.location_display = 'none';
		delete this.model.member;
		delete this.model.location;
		delete this.model['venueName'];
		delete this.todoItem['doc'];
		delete this.todoItem['url'];
		delete this.todoItem['attachmentType'];
		delete this.todoItem['images'];
		delete this.todoItem['member'];
		delete this.todoItem['numberOfOpens'];
		delete this.todoItem['hashtags'];
		this.networkService.clearFormSubscriptions();

		if (this.todoDetail) {
			this.setDefaultDueDate();
			this.lists = {
				name: this.todoDetail['name'],
				uid: this.todoDetail['uid'],
			};
			this.listPrivacy = this.todoDetail;
			this.selectToDoList();
		} else {
			this.selectToDoList();
			this.display_date = false;
			this.selectedDate = '';
		}

		if (!this.projectDetail) {
			delete this.todoItem['project'];
		}

		if (this.currentUser.name) {
			this.defaultTaskMember = this.currentUser.name;
			this.todoItem['assignedUser'] = this.currentUser.username;
		} else {
			this.defaultTaskMember = this.currentUser.username;
			this.todoItem['assignedUser'] = this.currentUser.username;
		}

		if (this.modelTitle) {
			this.modelTitle.nativeElement.style.height = 'auto';
		}

		if (this.modelBody) {
			this.modelBody.nativeElement.style.height = 'auto';
		}
	}

	private setDefaultDueDate(): void {
		if (this.todoDetail['repeatCycle']) {
			this.dueDateComponent['selectedDueDate'] = this.todoDetail['repeatCycle'];
		} else {
			this.dueDateComponent['selectedDueDate'] = 'daily';
		}
		if (this.todoDetail['oneOff']) {
			const d = new Date(this.todoDetail['oneOff']);
			this.dueDateComponent.setDate(d);
		}
		this.setTodoOptions();
	}

	private handleWelcomeTips(): void {
		if (this.network['membership'] && this.network['membership']['userItems']) {
			if (!localStorage.getItem('welcome-tips')) {
				const userItems = this.network['membership']['userItems'];
				if (!userItems['tasksExists'] || !userItems['issuesExists'] || !userItems['customerExists']) {
					this.networkService.populate();
				}
			}
		}
	}

	// For add an issue---->
	public onSelectProductClick(): void {
		if (!this.product) {
			this.privacy.showSelectProduct();
		}
	}

	public selectPost(type): void {
		this.hidePlusBtn = true;
		const slug = type['slug'];
		this.selectedPostTypeLabel = type['name'];
		this.isQuickPost = false;
		this.showMenu = false;
		this.showPostType = false;
		this.postType = slug;
		this.model.images = [];
		this.model.preview_images = [];
		this.choiceIndexContainer = [];
		this.choiceValue = [];
		this.post_type[slug] = true;
		this.model.title = '';
		this.model.body = '';
		this.errorDialogForCpSelection = 'none';
		this.projectPrivacyCp = null;
		this.errorTextForCpSelection = '';

		if (this.privacy.products) {
			this.privacy.products.forEach((value) => {
				value['checked'] = false;
			});
		}

		if (slug === 'recommend') {
			this.checkPrivacyForMentions();
			this.showMainPostForm = false;
			this.showPostForms = true;
			this.model.title = this.translate.instant('Recommendation');
			this.model.category = 'recommendations';
			this.addIssue = false;
			this.privacy.selectedProduct = [];
		} else if (slug === 'event') {
			const d = new Date();
			this.starts = d.getHours() + ':' + d.getMinutes();
			this.eventStartDate = {
				year: d.getFullYear(),
				month: d.getMonth() + 1,
				day: d.getDate(),
			};
			this.eventEndDate = this.eventStartDate;
			this.ends = '';
			// this.eventEndDate = '';
			this.checkPrivacyForMentions();
			delete this.model.member;
			this.showMainPostForm = false;
			this.showPostForms = true;
			this.timeZone = this.getTimeZone();
			this.model.category = 'event';
			this.addIssue = false;
			this.privacy.selectedProduct = [];

			if (this.calendarDate) {
				const date = new Date(this.calendarDate);
				const month = date.getMonth() + 1;
				const day = date.getDate();
				const year = date.getFullYear();
				this.eventStartDate = {
					year: year,
					month: month,
					day: day,
				};
				this.eventEndDate = this.eventStartDate;
			} else {
				this.eventStartDate = {
					year: this.newDate.getFullYear(),
					month: this.newDate.getMonth() + 1,
					day: this.newDate.getDate(),
				};
				this.eventEndDate = this.eventStartDate;
			}

			this.postService.getEventImages().subscribe((data) => {
				if (data.count > 0) {
					this.eventImageExists = true;
					this.eventImages = data.objects;
					this.model.images.push(this.eventImages[0].upload.uid);
				} else {
					this.eventImageExists = false;
				}
			});
		} else if (slug === 'buy-sell') {
			this.checkPrivacyForMentions();
			delete this.model.member;
			this.showMainPostForm = false;
			this.showPostForms = true;
			this.model.condition = '';
			this.model.category = 'other';
			this.addIssue = false;
			this.privacy.selectedProduct = [];
			this.postService.getFiltersAndCategories().subscribe((data) => {
				this.productCategories = data['offerCategories'];
				this.productCondition = data['conditions'];
			});
		} else if (slug === 'post') {
			this.networkService.populate(); // For refreshing privacy modal and channels list
			this.checkPrivacyForMentions();
			this.showMainPostForm = false;
			this.showPostForms = true;
			this.model.category = 'status';
			this.showMoreOptions = false;
			this.addIssue = false;
			this.privacy.selectedProduct = [];
		} else if (slug === 'add-issue') {
			if (this.product && this.product['slug']) {
				this.issueModel.products = this.product['slug'];
			}
			this.isTitle = false;
			this.isProduct = false;
			this.selectedType = 'issue';
			this.currentType = 'issue';
			this.showMainPostForm = false;
			this.showTaskForms = true;
			this.displayIssueOptions = false;
			this.mentionPrivacy = '';
			this.currentIssueCustomer = '';
			this.selectedCustomer = '';
			this.selectedPriority = 'P2';
			// eslint-disable-next-line spellcheck/spell-checker
			this.currentpriority = 'P2';
			this.customerObj['uid'] = '';
			delete this.issueModel['customer'];
			delete this.model.member;
			this.addIssue = true;
			this.showMoreOptions = true;
		} else if (slug === 'question') {
			this.checkPrivacyForMentions();
			this.showMainPostForm = false;
			this.showPostForms = true;
			this.addIssue = false;
			this.privacy.selectedProduct = [];
			this.photo_upload = false;
			this.doc_upload = false;
		} else if (slug === 'add-task') {
			this.privacy.projectUid = '';
			this.privacy.currentProject = '';
			this.privacy.projectSelected = '';
			this.mentionPrivacy = '';
			this.showMainPostForm = false;
			this.showTaskForms = true;
			this.displayTaskOptions = false;
			delete this.model.member;
			this.showMoreOptions = false;
			this.currentPriority = 'med';
			this.prioritySelected = 'p2';
			this.todoItem['priority'] = 'p2';

			if (this.currentUser.name) {
				this.defaultTaskMember = this.currentUser.name;
				this.todoItem['assignedUser'] = this.currentUser.username;

				console.log('post this.currentUser', this.currentUser);
			} else {
				this.defaultTaskMember = this.currentUser.username;
				this.todoItem['assignedUser'] = this.currentUser.username;
			}

			if (this.todoItem) {
				this.todoItem['repeatCycle'] = 'daily';
				this.todoItem['selectedDueDate'] = 'daily';
				this.todoItem['fromTime'] = null;
				this.todoItem['oneOff'] = null;
				this.todoItem['toTime'] = null;
				this.todoItem['remindersSelected'] = [];
				this.dueDateComponent.reset();
			}

			if (this.todoDetail && this.todoDetail['uid']) {
				this.lists = {
					name: this.todoDetail['name'],
					uid: this.todoDetail['uid'],
				};
				this.listPrivacy = this.todoDetail;
				this.selectToDoList();
				if (this.currentUser.name) {
					this.defaultTaskMember = this.currentUser.name;
					this.todoItem['assignedUser'] = this.currentUser.username;
				} else {
					this.defaultTaskMember = this.currentUser.username;
					this.todoItem['assignedUser'] = this.currentUser.username;
				}

				if (this.todoDetail && this.todoDetail['selectedCalendarDate']) {
					this.dueDateComponent['selectedDueDate'] = 'one_off';
					this.dueDateComponent.setDate(this.todoDetail['selectedCalendarDate']);
					this.setTodoOptions();
				} else {
					this.setDefaultDueDate();
				}
			} else if (this.projectDetail && this.projectDetail['uid']) {
				if (this.projectDetail && this.projectDetail['channel'] && this.projectDetail['channel'].name) {
					this.projectPrivacyCp = this.projectDetail['channel'].name;
				} else {
					this.projectPrivacyCp = 'Everyone';
				}
				this.lists = {
					name: '',
					uid: '',
				};
				this.assignedMembers = [];
				this.handlePreviousTodolist();
				this.selectedProject = this.projectDetail;
				this.project['name'] = this.projectDetail['name'];
				this.project['id'] = this.projectDetail['uid'];
				this.todoItem['project'] = this.projectDetail['uid'];
				if (this.projectDetail['selectedCalendarDate']) {
					this.dueDateComponent['selectedDueDate'] = 'one_off';
					this.dueDateComponent.setDate(this.projectDetail['selectedCalendarDate']);
					this.setTodoOptions();
				}
			} else {
				this.lists = {
					name: '',
					uid: '',
				};
				this.assignedMembers = [];
				this.handlePreviousTodolist();
			}

			if (this.calendarDate) {
				this.dueDateComponent['selectedDueDate'] = 'one_off';
				this.dueDateComponent.setDate(this.calendarDate);
				this.setTodoOptions();
			}
		} else if (slug === 'add-customer') {
			this.mentionPrivacy = '';
			this.showMainPostForm = false;
			this.showTaskForms = true;
			this.displayCustomerAdditionalOptions = false;
			this.isCustomerSubmitted = false;
			this.postService.productSlug = '';
			this.currentCustomer = this.currentUser['name'];
			this.customer['assignedUser'] = this.currentUser['username'];
			this.customer['priority'] = 'p1';
			this.prioritySelected = 'p1';
			this.currentPriority = 'high';
			if (this.customerListState) {
				this.customer['status'] = this.customerListState;
				this.stateSelected = this.customerListState;
				this.setCustomerState();
			} else {
				this.customer['status'] = 'open';
				this.currentState = 'new';
				this.stateSelected = 'open';
			}
		} else if (slug === 'video-conference') {
			this.checkPrivacyForMentions();
			this.showMoreOptions = false;
			const d = new Date();
			this.starts = d.getHours() + ':' + d.getMinutes();
			this.eventStartDate = {
				year: d.getFullYear(),
				month: d.getMonth() + 1,
				day: d.getDate(),
			};
			this.ends = '';
			this.eventEndDate = '';
			this.duration = '00:30';
			this.model.starts = '';

			if (this.currentUser && this.currentUser.zoomLinked) {
				if (this.currentUser.zoomExpires) {
					const currentDate = new Date();
					const zoomExpiryDate = this.currentUser.zoomExpires;
					const splicedZoomDate = zoomExpiryDate.slice(0, zoomExpiryDate.lastIndexOf('.'));
					const u = '+0000';
					const UTCZoomExpiryDate = splicedZoomDate.concat(u);
					const expiryDate = new Date(UTCZoomExpiryDate);

					if (currentDate > expiryDate) {
						const data = {};

						this.userService.zoomAuthRefresh(data).subscribe(() => {
							this.userService.updateUserDetails();
							this.currentUser = this.userService.getCurrentUser();
							localStorage.removeItem('userDetails');
							CacheUtils.removeStoredData('userDetails');
						});
					}
				}

				this.showMainPostForm = true;
			} else {
				this.showMainPostForm = false;
				this.showZoomLinkModal = 'block';
			}

			this.postService.getEventImages().subscribe((data) => {
				if (data.count > 0) {
					this.eventImages = data.objects;

					this.eventImages.forEach((image) => {
						if (image.description === 'Zoom') {
							this.zoomImage = image;
						}
					});
				}
			});

			delete this.model.member;
			this.timeZone = this.getTimeZone();
			this.addIssue = false;
			this.privacy.selectedProduct = [];
		}

		this.initializeTuiEditor();
	}

	public displayPostSomething(): void {
		const body = {
			name: 'Post something',
			slug: 'post',
		};
		this.selectPost(body);
	}

	public uploadPhoto(): void {
		this.removeSelectedMember();
		this.photo_upload = !this.photo_upload;
		this.article = false;
		this.location_display = 'none';
		this.doc_upload = false;
		if (this.model.preview_images && this.model.preview_images.length > 0) {
			this.model.preview_images = [];
			this.model.images = [];
		}
	}

	public uploadDocument(): void {
		this.removeSelectedMember();
		this.photo_upload = false;
		this.doc_upload = !this.doc_upload;
		this.article = false;
		this.location_display = 'none';
		if (this.model.preview_images && this.model.preview_images.length > 0) {
			this.model.preview_images = [];
			this.model.images = [];
		}
	}

	public postArticle(): void {
		this.removeSelectedMember();
		this.article = !this.article;
		this.photo_upload = false;
		this.doc_upload = false;
		this.location_display = 'none';
	}

	public postLocation(): void {
		this.removeSelectedMember();
		if (this.model.location) {
			delete this.model.location;
		} else {
			this.article = false;
			this.photo_upload = false;
			this.doc_upload = false;
			this.layoutService.showBd('post_locations');
			this.location_display = 'block';
		}
	}

	public addUrlBox(post): void {
		post.newComment.isLink = !post.newComment.isLink;
	}

	private postCreatePrecheck(): void {
		delete this.model.privacy;
		delete this.personalFilterString;
		delete this.selectByStatusString;
		delete this.selectByRenewalString;
		delete this.selectByLevelString;

		if (this.model['mentioned_users'] && this.model['mentioned_users'].length > 0) {
			const userList = [];
			for (const u of this.model['mentioned_users']) {
				if (this.model.body.indexOf('@' + u.name) > -1) {
					userList.push(u.username);
				}
				if (this.model.title.indexOf('@' + u.name) > -1) {
					userList.push(u.username);
				}
			}
			this.model['mentioned_users'] = [];
			this.model['mentioned_users'] = userList.join(',');
		}
		if (this.model['hashtags'] && this.model['hashtags'].length > 0) {
			const tagList = [];
			for (const h of this.model['hashtags']) {
				if (this.model.body.indexOf('#' + h['name']) > -1) {
					tagList.push(h['name']);
				}

				if (this.model.title.indexOf('#' + h['name']) > -1) {
					tagList.push(h['name']);
				}
			}
			const re = /(?:^|\W)#(\w+)(?!\w)/g;
			let match;
			while ((match = re.exec(this.model.body))) {
				if (tagList.indexOf(match[1]) < 0) {
					tagList.push(match[1]);
				}
			}
			this.model['hashtags'] = [];
			this.model['hashtags'] = tagList.join(',');
		}

		if (this.privacy.custom_privacy.length !== 0) {
			const customPrivacy: any[] = [];
			const newObject = this.privacy.custom_privacy;
			newObject.forEach((member) => {
				member.privacyType = 'customPrivacy';
			});
			localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			this.privacy.custom_privacy.forEach((element) => {
				if (element.userId) {
					customPrivacy.push(element.userId);
					this.privacy.custom_privacy_display = false;
				} else if (element.user) {
					customPrivacy.push(element.user.userId);
				} else {
					customPrivacy.push(element.id);
				}
			});
			this.model.custom_privacy = '[' + customPrivacy.join(',') + ']';

			delete this.model.content_privacy;
		} else {
			if (this.privacy.selectedRole) {
				if (this.privacy.selectedRole.slug) {
					this.roleString = {
						role: this.privacy.selectedRole.slug,
					};

					const newObject = this.privacy.selectedRole;
					newObject.privacyType = 'role';
					localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
				}
				if (this.privacy.selectedRole.role) {
					const role = this.privacy.selectedRole.role.toLowerCase();
					this.roleString = {
						role: role,
					};

					const newObject = this.privacy.selectedRole;
					newObject.privacyType = 'role';
					localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
				}
			}

			if (this.privacy.personalFilter) {
				this.personalFilterString = {};
				const key = this.privacy.personalFilter.key;
				this.personalFilterString[key] = this.privacy.personalFilter.value;

				const newObject = this.privacy.personalFilter;
				newObject.privacyType = 'personalFilter';
				localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			}
			if (this.privacy.selectByStatusValue) {
				this.selectByStatusString = {
					status: this.privacy.selectByStatusValue[0],
				};

				const newObject = this.privacy.selectByStatusValue;
				if (newObject.includes('status') === false) {
					newObject.push('status');
				}
				localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			}

			if (this.privacy.selectByRenewalValue) {
				this.selectByRenewalString = {
					renewalStatus: this.privacy.selectByRenewalValue[0],
				};

				const newObject = this.privacy.selectByRenewalValue;

				if (newObject.includes('renewalFrequency') === false) {
					newObject.push('renewalFrequency');
				}

				localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			}

			if (this.privacy.selectByLevelValue) {
				this.selectByLevelString = {
					membership_level: this.privacy.selectByLevelValue.uid,
				};

				const newObject = this.privacy.selectByLevelValue;

				newObject.privacyType = 'selectByLevel';
				localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			}

			if (this.privacy.selectedDepartment) {
				if (this.privacy.selectedDepartment.value) {
					this.departmentString = {
						department: this.privacy.selectedDepartment['value'],
					};
				}

				if (this.privacy.selectedDepartment.uid) {
					this.departmentString = {
						department: this.privacy.selectedDepartment['uid'],
					};
				}

				const newObject = this.privacy.selectedDepartment;

				newObject.privacyType = 'department';
				localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			}

			if (this.privacy.channel) {
				const newObject = this.privacy.channel;

				newObject.privacyType = 'channel';
				localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			}

			if (
				this.privacy.custom_privacy.length === 0 &&
				!this.privacy.selectedRole &&
				!this.privacy.personalFilter &&
				!this.privacy.selectedDepartment &&
				!this.privacy.channel &&
				!this.model.channel &&
				!this.privacy.selectByStatusValue &&
				!this.privacy.selectByRenewalValue &&
				!this.privacy.selectByLevelValue
			) {
				localStorage.setItem(`${this.networkId}`, JSON.stringify('Everyone'));
			}

			if (this.roleString && this.departmentString) {
				// this.model.content_privacy = '{' + this.roleString + ', ' + this.departmentString + '}';
			} else if (!this.roleString && this.departmentString) {
				this.model.content_privacy = JSON.stringify(this.departmentString);
			} else if (this.roleString && !this.departmentString) {
				this.model.content_privacy = JSON.stringify(this.roleString);
			} else if (this.personalFilterString) {
				this.model.content_privacy = JSON.stringify(this.personalFilterString);
			} else if (this.selectByStatusString) {
				this.model.content_privacy = JSON.stringify(this.selectByStatusString);
			} else if (this.selectByRenewalString) {
				this.model.content_privacy = JSON.stringify(this.selectByRenewalString);
			} else if (this.selectByLevelString) {
				this.model.content_privacy = JSON.stringify(this.selectByLevelString);
			} else {
				delete this.model.content_privacy;
			}

			this.roleString = null;
			this.departmentString = null;
			delete this.model.custom_privacy;
		}

		if (this.externalUserChannel && !this.privacy.channel) {
			if (this.isExternalUser) {
				this.model.channel = this.externalUserChannel['uid'];
			}
		}

		if (!this.model.channel || this.model.channel === '' || !Object.keys(this.model.channel).length) {
			delete this.model.channel;
		}

		if (this.model.shareable) {
			this.model.shareable = 1;
		} else {
			this.model.shareable = 0;
		}

		if (this.model.commentable) {
			this.model.commentable = 1;
		} else {
			this.model.commentable = 0;
		}
		this.model.state = 'active';

		if (this.currentPage === 'collection') {
			this.model['collection_post'] = true;
		}
		delete this.model.preview_images;
	}

	/*
	 * Function to create post based on type.
	 */
	public createPost(): void {
		this.shiftKeyUsed = false;

		switch (this.postType) {
			case 'event':
				// eslint-disable-next-line spellcheck/spell-checker
				this.model.link = 'www.andoneapp.com';
				this.model.cost = 'free';
				this.model.type = 'event';
				this.model.category = 'events';
				delete this.model.condition;
				if (!(this.model.title && this.model.title.trim())) {
					this.errorMessage = 'Please enter title for the event.';
					return;
				}
				// if (this.model.starts !== null) {
				//   var date = new Date(this.model.starts);
				//   var start_month = date.getUTCMonth() + 1;
				//   var start_day = date.getUTCDate();
				//   var start_year = date.getUTCFullYear();
				//   var start_hours = date.getUTCHours();
				//   var start_minutes = date.getUTCMinutes();
				//   var start_seconds = date.getUTCSeconds();
				//   var utcDate = start_year + "-" + start_month + "-" + start_day + " " + start_hours + ":" + start_minutes + ":" + start_seconds;
				//   this.model.starts = utcDate;
				// } else {
				//   this.errorMessage = 'Please enter a valid start date.';
				//   return;
				// }
				if (this.eventStartDate && this.starts) {
					const d = this.parserFormatter.format(this.eventStartDate) + ' ' + this.starts;
					const date = new Date(d);
					const startMonth = date.getUTCMonth() + 1;
					const startDay = date.getUTCDate();
					const startYear = date.getUTCFullYear();
					const startHours = date.getUTCHours();
					const startMinutes = date.getUTCMinutes();
					const startSeconds = date.getUTCSeconds();
					const utcDate = startYear + '-' + startMonth + '-' + startDay + ' ' + startHours + ':' + startMinutes + ':' + startSeconds;
					this.model.starts = utcDate;
				}

				if (!this.ends) {
					const d = this.parserFormatter.format(this.eventEndDate) + ' ' + this.starts;
					const date = new Date(d);
					const time = this.addHoursToDate(date, 3);
					this.ends = time.getHours() + ':' + time.getMinutes();
				}

				if (this.eventEndDate) {
					if (this.ends) {
						const d = this.parserFormatter.format(this.eventEndDate) + ' ' + this.ends;
						const date = new Date(d);
						const startMonth = date.getUTCMonth() + 1;
						const startDay = date.getUTCDate();
						const startYear = date.getUTCFullYear();
						const startHours = date.getUTCHours();
						const startMinutes = date.getUTCMinutes();
						const startSeconds = date.getUTCSeconds();
						const utcDate = startYear + '-' + startMonth + '-' + startDay + ' ' + startHours + ':' + startMinutes + ':' + startSeconds;
						this.model.ends = utcDate;
					} else {
						this.errorMessage = 'Please select end time for the event.';
						return;
					}
				}

				// if (this.eventEndDate) {
				//     let d:any
				//     if(this.ends){
				//       d =this.parserFormatter.format(this.eventEndDate) + ' ' + this.ends;
				//     }else{
				//       d=  this.parserFormatter.format(this.eventEndDate);
				//     }

				//     let date = new Date(d);
				//     var start_month = date.getUTCMonth() + 1;
				//     var start_day = date.getUTCDate();
				//     var start_year = date.getUTCFullYear();
				//     var start_hours = date.getUTCHours();
				//     var start_minutes = date.getUTCMinutes();
				//     var start_seconds = date.getUTCSeconds();
				//     let endUtcDate
				//     if(this.ends){
				//       endUtcDate = start_year + "-" + start_month + "-" + start_day + " " + start_hours + ":" + start_minutes + ":" + start_seconds;
				//     }else {
				//       endUtcDate = start_year + "-" + start_month + "-" + start_day ;
				//     }

				//     this.model.ends = endUtcDate;
				//     console.log("UTC Date",endUtcDate)
				//     console.log("End date",this.eventEndDate)
				//     console.log("End time",this.ends)

				// }
				// return

				// if (this.model.ends !== null) {
				//   // this.model.ends = this.model.ends.toString() + ':00' + this.getTimeZone();
				//   var date = new Date(this.model.starts);
				//   var ends_month = date.getUTCMonth() + 1;
				//   var ends_day = date.getUTCDate();
				//   var ends_year = date.getUTCFullYear();
				//   var ends_hours = date.getUTCHours();
				//   var ends_minutes = date.getUTCMinutes();
				//   var ends_seconds = date.getUTCSeconds();
				//   var utcDate = ends_year + "-" + ends_month + "-" + ends_day + " " + ends_hours + ":" + ends_minutes + ":" + ends_seconds;
				//   this.model.ends = utcDate;
				// }
				if (this.model.images && this.model.images.length > 0) {
					if (this.model.images.length > 1) {
						this.model.images.splice(0, 1);
					}
					if (this.doc_upload && this.documentList.indexOf(this.fileType[0]) > -1) {
						this.model.attachmentType = 'doc';
					} else if (this.doc_upload && this.audioList.indexOf(this.fileType[0]) > -1) {
						this.model.attachmentType = 'audio';
					} else if (this.doc_upload && this.videoList.indexOf(this.fileType[0]) > -1) {
						this.model.attachmentType = 'video';
					} else {
						this.model.attachmentType = 'photo';
					}
					this.model.images = this.model.images.join('|');
				}
				if (!this.article) {
					delete this.model.url;
				} else {
					this.model.attachmentType = 'url';
				}

				this.postCreatePrecheck();
				if (this.model.location) {
					this.pageLoadingUiService.setPageLoading(true);

					this.locationService
						.create({
							venueId: this.model.location.venueId,
							latitude: this.model.location.latitude,
							longitude: this.model.location.longitude,
							name: this.model.location.name,
							category: this.model.location.category,
							website: this.model.location.website,
							phone: this.model.location.phone,
						})
						.subscribe((data) => {
							this.model['venueName'] = data['name'];
							delete this.model.location;
							this.callCreateApi();
						});
				} else {
					this.callCreateApi();
				}
				break;
			case 'buy-sell':
				if (!this.model.images || this.model.images.length === 0) {
					this.errorMessage = this.translate.instant('Please select an image to create an offer');
					break;
				}

				this.postCreatePrecheck();
				this.model.action = 'public';
				this.model.type = 'offer';
				this.model.sellPrice = Number(this.model.sellPrice);
				delete this.model.preview_images;
				if (typeof this.model.images !== 'undefined' && this.model.images.length > 0) {
					this.model.attachmentType = 'photo';
					this.model.images = this.model.images.join('|');
				}

				if (this.model.fixedPrice) {
					this.model.fixedPrice = 1;
				} else {
					this.model.fixedPrice = 0;
				}

				if (this.selectedCity && this.selectedCity.uid) {
					this.model['city'] = this.selectedCity['uid'];
				}

				if (!this.model.condition) {
					delete this.model.condition;
				}

				if (this.model.location) {
					this.model.attachmentType = 'location';
					this.model.rating = 5;
					this.locationService
						.create({
							venueId: this.model.location.venueId,
							latitude: this.model.location.latitude,
							longitude: this.model.location.longitude,
							name: this.model.location.name,
							category: this.model.location.category,
							website: this.model.location.website,
							phone: this.model.location.phone,
						})
						.subscribe((data) => {
							this.model.location = data.uid;
							this.callCreateApi();
						});
				} else {
					this.callCreateApi();
				}
				break;
			case 'question':
				this.postCreatePrecheck();
				this.model.type = 'question';
				this.model.category = 'questions';
				let choiceString: string;
				this.choices.forEach(function (item, index) {
					if (index > 0) {
						choiceString += '|choice:' + item + '<#>order:' + index;
					} else {
						choiceString = 'choice:' + item + '<#>order:' + index;
					}
				});

				if (this.choiceValue) {
					choiceString += (choiceString ? '|' : '') + 'choice:' + this.choiceValue + '<#>order:' + this.choices.length;
				}

				/**
				 * Remove starts as it causes issues with API calls
				 */
				if (this.model.starts) {
					delete this.model.starts;
				}

				this.model.choices = choiceString;
				delete this.model.preview_images;
				if (typeof this.model.images !== 'undefined' && this.model.images.length > 0) {
					if (this.doc_upload && this.documentList.indexOf(this.fileType[0]) > -1) {
						this.model.attachmentType = 'doc';
					} else if (this.doc_upload && this.audioList.indexOf(this.fileType[0]) > -1) {
						this.model.attachmentType = 'audio';
					} else if (this.doc_upload && this.videoList.indexOf(this.fileType[0]) > -1) {
						this.model.attachmentType = 'video';
					} else {
						this.model.attachmentType = 'photo';
					}
					this.model.images = this.model.images.join('|');
				}

				if (typeof this.model?.member !== 'string' && this.model?.member?.uid) {
					this.model.member = this.model.member.uid;
				}

				this.callCreateApi();
				break;
			case 'recommend':
				if (this.model.location && this.range === -1) {
					this.errorMessage = 'Please rate the place';
					break;
				}

				this.postCreatePrecheck();
				this.model.type = 'recommendation';
				this.model.category = 'recommendations';

				if (!this.article) {
					delete this.model.url;
				} else {
					if (this.model.url) {
						this.model.attachmentType = 'url';
					}
				}

				if (typeof this.model.images !== 'undefined' && this.model.images.length > 0) {
					if (this.doc_upload && this.documentList.indexOf(this.fileType[0]) > -1) {
						this.model.attachmentType = 'doc';
					} else if (this.doc_upload && this.audioList.indexOf(this.fileType[0]) > -1) {
						this.model.attachmentType = 'audio';
					} else if (this.doc_upload && this.videoList.indexOf(this.fileType[0]) > -1) {
						this.model.attachmentType = 'video';
					} else {
						this.model.attachmentType = 'photo';
					}
					this.model.images = this.model.images.join('|');

					if (this.model.member) {
						this.model.attachmentType = 'member';
						this.model.member = this.model.member.uid;
					}

					this.callCreateApi();
				} else if (this.model.location) {
					this.model.attachmentType = 'location';
					this.model['rating'] = this.range;
					if (!this.model.location['uid']) {
						this.pageLoadingUiService.setPageLoading(true);
						this.locationService
							.create({
								venueId: this.model.location.venueId,
								latitude: this.model.location.latitude,
								longitude: this.model.location.longitude,
								name: this.model.location.name,
								category: this.model.location.category,
								website: this.model.location.website,
								phone: this.model.location.phone,
							})
							.subscribe((data) => {
								this.model.location = data.uid;
								this.callCreateApi();
							});
					} else {
						this.model.location = this.model.location['uid'];
						this.callCreateApi();
					}
				} else {
					this.callCreateApi();
				}
				this.model.preview_images = [];
				break;
			case 'add-issue':
				if (this.privacy.selectedProduct.length === 0 && !this.product && !this.model.title) {
					this.isProduct = true;
					this.isTitle = true;
					break;
				} else if (!this.model.title) {
					this.isTitle = true;
					break;
				} else if (this.privacy.selectedProduct.length === 0 && !this.product) {
					this.isProduct = true;
					break;
				} else {
					this.addAnIssue();
				}
				if (typeof this.model.images !== 'undefined' && this.model.images.length > 0) {
					if (this.doc_upload && this.documentList.indexOf(this.fileType[0]) > -1) {
						this.issueModel['attachmentType'] = 'doc';
					} else if (this.doc_upload && this.audioList.indexOf(this.fileType[0]) > -1) {
						this.issueModel['attachmentType'] = 'audio';
					} else if (this.doc_upload && this.videoList.indexOf(this.fileType[0]) > -1) {
						this.issueModel['attachmentType'] = 'video';
					} else {
						this.issueModel['attachmentType'] = 'photo';
					}
					this.issueModel['images'] = this.model.images.join('|');
					this.callCreateIssue();
				} else if (this.model.location) {
					this.pageLoadingUiService.setPageLoading(true);
					this.issueModel['attachmentType'] = 'location';
					this.locationService
						.create({
							venueId: this.model.location.venueId,
							latitude: this.model.location.latitude,
							longitude: this.model.location.longitude,
							name: this.model.location.name,
							category: this.model.location.category,
							website: this.model.location.website,
							phone: this.model.location.phone,
						})
						.subscribe((data) => {
							this.issueModel['location'] = data.uid;
							this.callCreateIssue();
						});
				} else {
					this.callCreateIssue();
				}
				this.model.preview_images = [];
				break;
			case 'video-conference':
				this.model.type = 'meeting';
				this.model.category = 'meeting';
				delete this.model.condition;
				// if (this.model.starts) {
				//   var date = new Date(this.model.starts);
				//   var ends_month = date.getUTCMonth() + 1;
				//   var ends_day = date.getUTCDate();
				//   var ends_year = date.getUTCFullYear();
				//   var ends_hours = date.getUTCHours();
				//   var ends_minutes = date.getUTCMinutes();
				//   var ends_seconds = date.getUTCSeconds();
				//   var utcDate = ends_year + "-" + ends_month + "-" + ends_day + " " + ends_hours + ":" + ends_minutes + ":" + ends_seconds;
				//   this.model.starts = utcDate;
				// } else {
				//   var date = new Date();
				//   var ends_month = date.getUTCMonth() + 1;
				//   var ends_day = date.getUTCDate();
				//   var ends_year = date.getUTCFullYear();
				//   var ends_hours = date.getUTCHours();
				//   var ends_minutes = date.getUTCMinutes();
				//   var ends_seconds = date.getUTCSeconds();
				//   var utcDate = ends_year + "-" + ends_month + "-" + ends_day + " " + ends_hours + ":" + ends_minutes + ":" + ends_seconds;
				//   this.model.starts = utcDate;
				// }

				// if (this.meeting_duration !== null) {
				//   this.model.duration = this.meeting_duration.minute;
				// } else {

				// }

				if (this.eventStartDate && this.starts) {
					const d = this.parserFormatter.format(this.eventStartDate) + ' ' + this.starts;
					const date = new Date(d);
					const startMonth = date.getUTCMonth() + 1;
					const startDay = date.getUTCDate();
					const startYear = date.getUTCFullYear();
					const startHours = date.getUTCHours();
					const startMinutes = date.getUTCMinutes();
					const startSeconds = date.getUTCSeconds();
					const utcDate = startYear + '-' + startMonth + '-' + startDay + ' ' + startHours + ':' + startMinutes + ':' + startSeconds;
					this.model.starts = utcDate;
				}

				if (this.duration) {
					const hour = this.duration.split(':')[0];
					const minute = this.duration.split(':')[1];
					this.model.duration = Math.floor(hour * 60) + Number(minute);
				}
				this.model.attachmentType = 'photo';
				this.model.images = [];
				this.model.images.push('13fbf9ac8bb4137b189f660cfcf43d5bbeb7db26');
				this.postCreatePrecheck();
				// return
				this.callCreateApi();
				break;
			case 'add-customer':
				if (!this.article) {
					delete this.customer['url'];
				} else {
					if (this.model.url) {
						this.customer['attachmentType'] = 'url';
						this.customer['url'] = this.model.url;
					}
				}

				if (this.model.title) {
					this.customer['title'] = this.model.title;
				}

				if (this.model.images !== 'undefined' && this.model.images.length > 0) {
					if (this.model.images.length > 1) {
						// console.log(this.model.images)
						// this.customer['images'].splice(0, 1);
					}
					if (this.doc_upload && this.documentList.indexOf(this.fileType[0]) > -1) {
						this.customer['attachmentType'] = 'doc';
					} else if (this.doc_upload && this.audioList.indexOf(this.fileType[0]) > -1) {
						this.customer['attachmentType'] = 'audio';
					} else if (this.doc_upload && this.videoList.indexOf(this.fileType[0]) > -1) {
						this.customer['attachmentType'] = 'video';
					} else {
						this.customer['attachmentType'] = 'photo';
					}
					this.customer['images'] = this.model.images.join('|');
					this.callCreateCustomer();
				} else if (this.model.location) {
					this.pageLoadingUiService.setPageLoading(true);
					this.customer['attachmentType'] = 'location';
					this.locationService
						.create({
							venueId: this.model.location.venueId,
							latitude: this.model.location.latitude,
							longitude: this.model.location.longitude,
							name: this.model.location.name,
							category: this.model.location.category,
							website: this.model.location.website,
							phone: this.model.location.phone,
						})
						.subscribe((data) => {
							this.customer['location'] = data.uid;
							this.callCreateCustomer();
						});
				} else {
					this.callCreateCustomer();
				}
				if (this.customer['attachmentType'] === 'doc') {
					this.customer['doc'] = this.customer['images'];
					delete this.customer['images'];
				}
				break;
			default:
				this.postCreatePrecheck();
				this.model.type = 'post';
				this.model.preview_images = [];
				if (typeof this.model.images !== 'undefined' && this.model.images.length > 0) {
					if (this.doc_upload && this.documentList.indexOf(this.fileType[0]) > -1) {
						this.model.attachmentType = 'doc';
					} else if (this.doc_upload && this.audioList.indexOf(this.fileType[0]) > -1) {
						this.model.attachmentType = 'audio';
					} else if (this.doc_upload && this.videoList.indexOf(this.fileType[0]) > -1) {
						this.model.attachmentType = 'video';
					} else {
						this.model.attachmentType = 'photo';
					}
					this.model.images = this.model.images.join('|');
				}

				if (!this.article) {
					delete this.model.url;
				} else {
					if (this.model.url) {
						this.model.attachmentType = 'url';
					}
				}
				if (this.model.member) {
					this.model.attachmentType = 'member';
					this.model.member = this.model.member.uid;
				}
				if (this.model.location) {
					this.model.attachmentType = 'location';
					if (!this.model.location['uid']) {
						// If share action from locDetail is used
						this.pageLoadingUiService.setPageLoading(true);
						this.locationService
							.create({
								venueId: this.model.location.venueId,
								latitude: this.model.location.latitude,
								longitude: this.model.location.longitude,
								name: this.model.location.name,
								category: this.model.location.category,
								website: this.model.location.website,
								phone: this.model.location.phone,
							})
							.subscribe((data) => {
								this.model.location = data.uid;
								this.callCreateApi();
							});
					} else {
						this.model.location = this.model.location['uid'];
						this.callCreateApi();
					}
				} else {
					this.callCreateApi();
				}
		}
	}

	private addAnIssue(): void {
		if (this.model['mentioned_users'] && this.model['mentioned_users'].length > 0) {
			const userList = [];

			for (const u of this.model['mentioned_users']) {
				if (this.model.body.indexOf('@' + u.name) > -1) {
					userList.push(u.username);
				}

				if (this.model.title.indexOf('@' + u.name) > -1) {
					userList.push(u.username);
				}
			}

			this.issueModel['mentioned_users'] = [];
			this.issueModel['mentioned_users'] = userList.join(',');
		}

		if (this.model['hashtags'] && this.model['hashtags'].length > 0) {
			const tagList = [];

			for (const h of this.model['hashtags']) {
				if (this.model.body.indexOf('#' + h['name']) > -1) {
					tagList.push(h['name']);
				}

				if (this.model.title.indexOf('#' + h['name']) > -1) {
					tagList.push(h['name']);
				}
			}

			const re = /(?:^|\W)#(\w+)(?!\w)/g;
			let match;

			while ((match = re.exec(this.model.body))) {
				if (tagList.indexOf(match[1]) < 0) {
					tagList.push(match[1]);
				}
			}

			this.issueModel['hashtags'] = [];
			this.issueModel['hashtags'] = tagList.join(',');
		}

		if (!this.article) {
			delete this.issueModel['url'];
		} else {
			if (this.model.url) {
				this.issueModel['attachmentType'] = 'url';
				this.issueModel['url'] = this.model.url;
			}
		}

		if (this.privacy.selectedProduct.length !== 0) {
			const productArray: any[] = [];

			this.privacy.selectedProduct.forEach((product) => {
				if (product) {
					productArray.push(product);
				}
			});

			this.issueModel.products = productArray.join(', ');
		}

		if (this.selectedPriority) {
			const priority = this.selectedPriority.toLowerCase();
			this.issueModel.priority = priority;
		}

		if (this.selectedType) {
			this.issueModel['type'] = this.selectedType;
		}

		this.issueModel.description = this.model.body;
		this.issueModel.title = this.model.title;
		this.showMoreOptions = false;
		this.addIssue = false;
		delete this.model.body;
	}

	private callCreateIssue(): void {
		if (this.issueModel['attachmentType'] === 'doc') {
			this.issueModel['doc'] = this.issueModel['images'];
			delete this.model.images;
			delete this.issueModel['images'];
		}

		if (this.issueModel['attachmentType'] === 'audio') {
			this.issueModel['audio'] = this.issueModel['images'];
			delete this.model.images;
			delete this.issueModel['images'];
		}

		if (this.issueModel['attachmentType'] === 'video') {
			this.issueModel['video'] = this.issueModel['images'];
			delete this.model.images;
			delete this.issueModel['images'];
		}

		if (this.selectedCustomer) {
			this.issueModel['customer'] = this.selectedCustomer['uid'];
		}

		this.handleIssueHashtags(this.issueModel.title);

		if (this.issueModel.description) {
			this.handleIssueHashtags(this.issueModel.description);
		}

		this.pageLoadingUiService.setPageLoading(true);

		this.postService.createIssue(this.issueModel).subscribe(
			(data) => {
				this.model.images = [];
				this.pageLoadingUiService.setPageLoading(false);
				this.refreshProducts.emit();
				this.handleWelcomeTips();
				this.showTaskForms = false;
				this.layoutService.hideBd();
				this.hidePlusBtn = false;
				this.selectedType = 'issue';
				this.currentType = 'issue';
				this.article = false;
				this.photo_upload = false;
				this.doc_upload = false;
				this.location_display = 'none';
				this.currentIssueCustomer = '';
				this.selectedCustomer = '';
				this.selectedPriority = 'P2';
				// eslint-disable-next-line spellcheck/spell-checker
				this.currentpriority = 'P2';
				this.customerObj['uid'] = '';
				delete this.issueModel['customer'];

				if (this.modelTitle) {
					this.modelTitle.nativeElement.style.height = 'auto';
				}

				if (this.modelBody) {
					this.modelBody.nativeElement.style.height = 'auto';
				}

				this.newPostEmitter.emit(new Post(data));
				this.model.category = 'status';
				delete this.issueModel['customer'];
				delete this.issueModel['collection_post'];
				delete this.issueModel['products'];
				delete this.issueModel['priority'];
				delete this.issueModel['attachmentType'];
				delete this.issueModel['url'];
				delete this.issueModel['images'];
				delete this.model.condition;
				this.issueModel['description'] = '';
				this.doc_upload = false;
				this.errorMessage = '';
				this.model.title = '';
				this.model.url = '';
				this.model.location = '';
				this.privacy.selectedProduct = [];
				this.privacy.products.forEach((value) => {
					value['checked'] = false;
				});

				if (this.product && this.product['slug']) {
					this.issueModel.products = this.product['slug'];
				}
			},
			() => {
				this.handleError();
			}
		);
	}

	private handleIssueHashtags(text): void {
		if (text.indexOf('#') > -1) {
			const words = text.split(' '),
				hashes = [];

			words.forEach((element) => {
				if (element.indexOf('#') > -1) {
					const index = element.indexOf('#');

					if (index === 0) {
						const string = element.replace(/(^#)/g, '');

						if (hashes.indexOf(string) === -1) {
							hashes.push(string);
						}
					}
				}
			});

			if (hashes.length > 0) {
				this.issueModel['hashtags'] = hashes.join(',');
			}
		}
	}

	/**
	 * Function to call the post create API.
	 */
	private callCreateApi(): void {
		if (this.currentPage === 'collection') {
			this.model['collection_post'] = true;
		}

		if (this.model.attachmentType === 'doc') {
			this.model['doc'] = this.model.images;
			delete this.model.images;
		}

		if (this.model.attachmentType === 'audio') {
			this.model['audio'] = this.model.images;
			delete this.model.images;
		}

		if (this.model.attachmentType === 'video') {
			this.model['video'] = this.model.images;
			delete this.model.images;
		}

		if (this.projectUpdatePost) {
			this.model['project'] = this.projectDetail['uid'];
			this.model['category'] = 'project';
		}

		this.handlePostHashtags(this.model.title);
		this.handlePostHashtags(this.model.body);

		// return;
		this.pageLoadingUiService.setPageLoading(true);
		this.postService.create(this.model).subscribe(
			(data) => {
				this.mixPanelService.track('Feed Post', {
					workspace: this.network.name || 'NA',
					appVersion: environment.version,
					language: this.translate.currentLang,
					name: this.currentUser?.name || 'NA',
					username: this.currentUser?.username || 'NA',
					date: moment().format('YYYY-MM-DD'),
				});

				this.segmentService.trackEvent('Feed Post', {
					workspace: this.network.name || 'NA',
					appVersion: environment.version,
					language: this.translate.currentLang,
					name: this.currentUser.name || 'NA',
					username: this.currentUser.username || 'NA',
					date: moment().format('YYYY-MM-DD'),
				});

				this.model = new Post(
					{
						title: null,
						body: '',
						category: null,
						privacy: '',
						images: [],
						preview_images: [],
						shareable: true,
						commentable: true,
						custom_privacy: undefined,
						channel: undefined,
						content_privacy: undefined,
						department: null,
					},
					true
				);

				this.disableIt();
				if (this.currentPage === 'collection' && this.collectionUid) {
					this.collectionService.addToCollection(this.collectionUid, data['uid']).subscribe(() => {
						this.mixPanelService.track('Created Collections', {
							workspace: this.network.name || 'NA',
							appVersion: environment.version,
							language: this.translate.currentLang,
							username: this.currentUser?.username || 'NA',
							name: this.currentUser?.name || 'NA',
							date: moment().format('YYYY-MM-DD'),
						});

						this.segmentService.trackEvent('Created Collections', {
							workspace: this.network.name || 'NA',
							appVersion: environment.version,
							language: this.translate.currentLang,
							username: this.currentUser?.username || 'NA',
							name: this.currentUser?.name || 'NA',
							date: moment().format('YYYY-MM-DD'),
						});
					});
				}

				if (this.currentPage === 'channel-detail') {
					this.updateChannelPrivacy();
				}

				this.currentNetworkDefaultCp = '';
				this.loadCachedPrivacy();
				this.postService.newPostEmitter.emit(data);
				this.model.images = [];
				this.choices = [];
				this.layoutService.hideBd();
				this.pageLoadingUiService.setPageLoading(false);
				this.model.category = 'status';
				this.postType = 'post';
				this.selectedDepartment = '';
				this.errorMessage = '';
				this.question_photo = false;
				this.showAttachmentOption = false;
				this.postService.alterPostBodyAccordingToUserMentionAndHashtag(data);
				this.newPostEmitter.emit(new Post(data));

				// ====Reset ngx mat picker
				const d = new Date();
				this.starts = d.getHours() + ':' + d.getMinutes();
				this.eventStartDate = {
					year: d.getFullYear(),
					month: d.getMonth() + 1,
					day: d.getDate(),
				};
				this.ends = '';
				this.eventEndDate = '';
				this.duration = '00:30';

				if (this.child) {
					this.child.appendNewPost(data);
				}
			},
			(err) => {
				this.pageLoadingUiService.setPageLoading(false);
				this.hidePlusBtn = false;
				this.errors = err;
				this.handleError();
			}
		);
	}

	private handlePostHashtags(text): void {
		if (text.indexOf('#') > -1) {
			const words = text.split(' ');

			words.forEach((element) => {
				if (element.indexOf('#') > -1) {
					const index = element.indexOf('#');

					if (index === 0) {
						const string = element.replace(/(^#)/g, '');

						if (this.hashes.indexOf(string) === -1) {
							this.hashes.push(string);
						}
					}
				}
			});

			if (this.hashes.length > 0) {
				this.model['hashtags'] = this.hashes.join(',');
			}
		}
	}

	/*
	 *Function to close location popup
	 */
	private popupOnCloseHandled(): void {
		this.location_display = 'none';
		// this.postType = 'post';
	}

	/**
	 *Function to get the members of a network
	 */
	public getMembers(): void {
		if (this.totalMembersCount === 0 || this.totalMembersCount > this.members.length) {
			this.networkService.networkReady.subscribe((networkLoaded) => {
				this.networkLoaded = networkLoaded;

				if (this.networkLoaded) {
					this.networkService.currentNetwork.subscribe((network) => {
						if (network) {
							this.network = network;

							this.memberService.getMembers(this.network.uid, this.nextMembersUrl).subscribe((data) => {
								this.members = [];

								data.objects.forEach((element) => {
									this.members.push(element);
								});

								this.totalMembersCount = data.count;

								if (data.next) {
									this.nextMembersUrl = data.next.split('alpha')[1];
								} else {
									this.nextMembersUrl = '';
									//
								}
							});
						}
					});
				}
			});
		}
	}

	public showMembers(): void {
		this.getMembers();
		this.openModal();
	}

	public loadMoreMembers(): void {
		this.showMembers();
	}

	public onMemberSelect(member): void {
		this.model.member = member;

		this.onCloseHandled();
	}

	public onLocationSelect(loc): void {
		this.model.location = loc;

		this.layoutService.hideBd();
	}

	private removeSelectedLocation(): void {
		delete this.model.location;
	}

	public removeSelectedMember(): void {
		this.selectedMember['uid'] = '';
		this.attachmentMember = '';

		delete this.model.member;
	}

	private openModal(): void {
		this.member_display = 'block';
		//  this.selected_post = null;
	}

	public onCloseHandled(): void {
		this.member_display = 'none';
		this.location_display = 'none';
		this.memberModelType = null;
		this.privacy_settings = 'none';
	}

	public onSelectEventType(event): void {
		this.model.images = [];
		this.model.preview_images = [];
		this.model.images.push(event.upload.uid);
		// this.model.preview_images.push(event.upload.thumbnails.400);
	}

	public onCloseCustomImage(): void {
		this.model.images = [];
		this.model.preview_images = [];
		this.photo_upload = false;
		this.model.images.push(this.eventImages[0].upload.uid);
	}

	public currentEventEmitter(): void {}

	private getTimeZone(): string {
		let offset = new Date().getTimezoneOffset();
		let isNegative = false;

		if (offset < 0) {
			offset = -1 * offset;
			isNegative = true;
		}

		let hoursString = Math.trunc(offset / 60).toString();
		let minutesString = (offset % 60).toString();

		switch (hoursString.length) {
			case 1:
				hoursString = '0' + hoursString;
				break;
			default:
				hoursString = hoursString;
		}

		switch (minutesString.length) {
			case 1:
				minutesString = '0' + minutesString;
				break;
			default:
				minutesString = minutesString;
		}

		if (isNegative) {
			return '+' + hoursString + minutesString;
		} else {
			return '-' + hoursString + minutesString;
		}
	}

	public freeChange(): void {
		this.isFree = !this.isFree;

		if (this.isFree) {
			this.tempPrice = this.model.sellPrice;
			this.model.sellPrice = 0;
		} else {
			this.model.sellPrice = this.tempPrice;
		}
	}

	/**
	 * Modified on 06-05-20
	 */
	public onPrivacyClick(): void {
		if (this.projectUpdatePost) {
			return;
		}

		if (this.currentPage === 'channel-detail' || (this.isExternalUser && !this.multipleExternalUser)) {
		} else {
			if (this.postType === 'add-task') {
				if (this.isTodolistCreateForm) {
					this.display_todoLists = 'none';
					this.displayCreateTodoList = 'none';
				}
			}

			this.privacy.showPrivacy();

			const subscribe = this.privacy.isPrivacyActive.subscribe((data) => {
				if (this.postType === 'video-conference') {
					if (data) {
						this.showMainPostForm = false;
					} else {
						this.showMainPostForm = true;
						subscribe.unsubscribe();
					}
				}
			});

			this.memberModelType = 'privacy';
		}
	}

	private showCustomPrivacy(): void {
		if (this.members.length === 0) {
			this.getMembers();
		}

		this.custom_privacy_display = !this.custom_privacy_display;
		this.custom_privacy = [];
	}

	private onCustomPrivacySelect(member): void {
		if (this.custom_privacy.indexOf(member) > -1) {
			this.custom_privacy.splice(this.custom_privacy.indexOf(member), 1);
		} else {
			this.custom_privacy.push(member);
		}
	}

	/**
	 * Get cities in Buy Sell post form
	 */
	public getCitiesForBuySell(): void {
		this.displayBuySellLocations = 'block';
		this.noCities = true;
		this.buySellCities = [];
		this.searchCityQuery = '';
		const nearMeLocation = this.currentLocation.coords.latitude + ',' + this.currentLocation.coords.longitude;

		this.networkService.getCitiesForRoleUpdate(nearMeLocation).subscribe((data) => {
			this.noCities = false;

			if (data.results && data.results.length > 0) {
				this.buySellCities = data.results;
			}
		});
	}

	public onSearchCity(): void {
		this.noCities = true;
		this.buySellCities = [];
		const nearMeLocation = this.currentLocation.coords.latitude + ',' + this.currentLocation.coords.longitude;

		this.networkService.searchCitiesForRoleUpdate(nearMeLocation, this.searchCityQuery).subscribe((data) => {
			this.noCities = false;

			if (data.results && data.results.length > 0) {
				this.buySellCities = data.results;
			}
		});
	}

	public onSearchCityEnter(event): void {
		if (event.keyCode === 13) {
			this.onSearchCity();
		}
	}

	public onSelectCity(city): void {
		this.selectedCity['name'] = city['name'];
		this.selectedCity['uid'] = city['uid'];
	}

	public onAddChoice(): void {
		if (this.choiceValue && this.choiceValue.length > 0) {
			this.choices.push(this.choiceValue);
			this.choiceIndexContainer.push(this.choices.length - 1);
		}

		this.choiceValue = '';
	}

	public onChoiceRemove(choice): void {
		this.choices.splice(this.choices.indexOf(choice), 1);
		this.choiceIndexContainer.pop();
	}

	public onChoiceKey(event): void {
		if (event.keyCode === 13) {
			this.onAddChoice();
		}
	}

	// Function to enlarge the normal post form
	public activateForm(isQuickPost = false, type = 'post'): void {
		this.rating = '';
		this.errorMessage = '';
		this.selectedPostTypeLabel = 'Post';
		this.postType = type;
		this.addIssue = false;
		this.selectedMember['uid'] = '';
		this.attachmentMember = '';
		delete this.model.member;
		this.privacy.selectedProduct = [];

		if (this.privacy.products) {
			this.privacy.products.forEach((value) => {
				value['checked'] = false;
			});
		}

		switch (type) {
			case 'event':
				this.selectedPostTypeLabel = 'Event';
				break;
			case 'buy-sell':
				this.selectedPostTypeLabel = 'Buy Sell';
				break;
			default:
				this.selectedPostTypeLabel = 'Post';
		}

		if (isQuickPost) {
			this.hidePlusBtn = false;
			this.resetReminders();
			this.handleStartDayEndDayModal();
			this.resetAdditionalOptions(); // Reset additionalOptions in task form
			this.display_date = false; // Reset date fields
			this.isQuickPost = true;
			this.showMainPostForm = false;
			this.showPostForms = false;
			this.showTaskForms = false;
			this.dueDateComponent.reset();
			this.displayTaskShortCuts = false;
			this.displayPostShortCuts = false;
			this.displayContactDetails = false;
			this.clearCustomerDetails();
			this.clearSubtasks();
			this.displayStartEndModal = 'none';
			this.networkService.clearFormSubscriptions(); // For clearing active form subscriptions
			this.layoutService.hideBd();

			if (this.todoDetail && this.todoDetail['selectedCalendarDate']) {
				this.todoDetail['selectedCalendarDate'] = '';
			}
		} else {
			this.hidePlusBtn = true;
			this.isQuickPost = false;
			this.layoutService.showBd('main-post');
			this.displayPostSomething();
		}

		// Empty these after closing post form
		this.model.title = '';
		this.model.body = '';
		this.model['mentioned_users'] = [];
		this.model['hashtags'] = [];
		this.modalClosedEmitter.emit();
	}

	private hideMainForm(): void {
		this.showMainPostForm = false;
		this.showPostForms = false;
		this.showTaskForms = false;
		this.selectedPostTypeLabel = 'Post';
		this.postType = 'post';
		this.isQuickPost = true;
	}

	// Function to show the categories modal
	public activateButton(): void {
		this.networkService.displayWelcomeDialog = false;

		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});

		if (this.currentPage !== 'collection' && this.currentPage !== 'channel-detail') {
			this.showMenu = !this.showMenu;
			this.showUserSuggestionOnTitle = false;

			if (this.showMenu) {
				this.layoutService.showBd('post-menu');
			} else {
				this.layoutService.hideBd();
			}
		} else if (this.currentPage === 'collection') {
			this.selectPost({
				name: 'Post something',
				slug: 'post',
			});

			this.layoutService.showBd('post-menu');
		} else {
			this.showMenu = !this.showMenu;
			this.showUserSuggestionOnTitle = false;

			if (this.showMenu) {
				this.layoutService.showBd('post-menu');
			} else {
				this.layoutService.hideBd();
			}
		}

		// If a network has single service,opens the service form directly on click
		if (this.network.services.length === 1) {
			this.network.services.forEach((service) => {
				this.selectPost({
					name: service.name,
					slug: service.slug,
				});
			});
		}
	}

	// Function to show/hide the post type selector inside the post create form
	public onPostTypeClick(): void {
		if (this.currentPage !== 'collection' && this.selectedPostTypeLabel !== 'Add Task' && this.selectedPostTypeLabel !== 'Add task') {
			this.showPostType = !this.showPostType;
		}
	}

	// Function to enable/disable fixed price in buy/sell post create.
	private onChangeFixedPrice(): void {
		this.model.fixedPrice = !this.model.fixedPrice;
	}

	public checkShiftKey(event, isSubtask = false): void | boolean {
		if (isSubtask && (event?.key === 'Enter' || event?.keyCode === 13)) {
			event.preventDefault();
			return false;
		}
		// -Check if shift key is pressed for user mention / hashtags
		if (event.shiftKey) {
			this.shiftKeyUsed = true;
		}
	}

	public onUserSelect(user): void {
		this.isMentions = false;
		this.userMentions = [];
		this.hashMentions = [];

		if (this.titleFocus) {
			const startPosition = this.modelTitle.nativeElement.selectionStart;
			const endPosition = this.modelTitle.nativeElement.selectionEnd;

			if (startPosition === endPosition) {
				const text = this.modelTitle.nativeElement.value.substring(0, startPosition);
				this.mentionIndex = text.lastIndexOf('@');

				if (this.mentionIndex > -1 && this.shiftKeyUsed) {
					const strippedText = this.modelTitle.nativeElement.value.substring(this.mentionIndex, startPosition);

					if (strippedText !== '@') {
						const reg = new RegExp(strippedText, 'g');
						const replacedTitle = this.model.title.replace(reg, '@' + user.name + ' ');

						this.model.title = replacedTitle;
					} else {
						const firstPart = this.model.title.substr(0, startPosition);
						const lastPart = this.model.title.substr(startPosition + 1);
						const newString = firstPart + user.name + ' ' + lastPart;

						this.model.title = newString;
					}
				} else {
					const newTitle = <''>(
						`${this.modelTitle.nativeElement.value.substring(0, startPosition)}@${user.name + ' '}${this.modelTitle.nativeElement.value.substring(
							endPosition
						)}`.toString()
					);

					this.model.title = newTitle;
					this.modelTitle.nativeElement.focus();
				}
			}
		} else {
			const startPosition = this.modelBody.nativeElement.selectionStart;
			const endPosition = this.modelBody.nativeElement.selectionEnd;

			if (startPosition === endPosition) {
				const body = this.modelBody.nativeElement.value.substring(0, startPosition);
				this.mentionIndex = body.lastIndexOf('@');

				if (this.mentionIndex > -1 && this.shiftKeyUsed) {
					const strippedText = this.modelBody.nativeElement.value.substring(this.mentionIndex, startPosition);

					if (strippedText !== '@') {
						const reg = new RegExp(strippedText, 'g');
						const replacedBody = this.model.body.replace(reg, '@' + user.name + ' ');
						this.model.body = <''>replacedBody;
					} else {
						const firstPart = this.model.body.substr(0, startPosition);
						const lastPart = this.model.body.substr(startPosition + 1);
						const newString = firstPart + user.name + ' ' + lastPart;
						this.model.body = <''>newString;
					}
				} else {
					const newTitle = <''>(
						`${this.modelBody.nativeElement.value.substring(0, startPosition)}@${user.name + ' '}${this.modelBody.nativeElement.value.substring(
							endPosition
						)}`.toString()
					);

					this.model.body = newTitle;
					this.modelBody.nativeElement.focus();
				}
			}
		}

		if (this.model['mentioned_users']) {
			this.model['mentioned_users'].push(user);
		} else {
			this.model['mentioned_users'] = [];
			this.model['mentioned_users'].push(user);
		}
	}

	/** Modal functions */
	public onLocationModalClose(): void {
		this.location_display = 'none';
		this.layoutService.hideBd();
	}

	/**
	 * Function to search locations.
	 */
	public onLocationSearch(): void {
		this.locationService.getNearMeLocations(this.currentLocation.coords, this.searchLocation).subscribe((data) => {
			const locations = data.response.groups[0].items.map((loc) => new LocationObj(loc, '4sq'));

			this.locations = this.sortData(locations);
		});
	}

	public onResetLocationSearch(): void {
		this.searchLocation = '';
		this.onLocationSearch();
	}

	/* ---------------Video Conference Functions-------------------------- */

	// Show and hide password for zoom meeting
	public showPassword(): void {
		this.show = !this.show;
	}

	// For closing zoom link modal
	public hideZoomLinkModal(): void {
		this.hidePlusBtn = false;
		this.showZoomLinkModal = 'none';
		this.layoutService.hideBd();
	}

	// For closing zoom link modal on clicking link zoom account option
	public onLinkAccountClick(): void {
		this.showZoomLinkModal = 'none';
		this.layoutService.hideBd();
		localStorage.setItem('zoom_authentication', 'true');
	}

	private showStartDatePicker(element): void {
		this.showStartPicker = true;

		setTimeout(() => {
			element.focus();
		}, 20);
	}

	// Select a todo list and pass todolist privacy to filter members
	public selectToDoList(): void {
		if (this.projectDetail || this.privacy.projectSelected) {
			const project = this.projectDetail ? this.projectDetail : this.privacy.projectSelected ? this.privacy.projectSelected : '';

			// eslint-disable-next-line spellcheck/spell-checker
			this.assignees = [];
			if (project && project.channel && project.channel.uid) {
				this.getFilteredMembers('channel', project.channel.uid);
			} else {
				this.getFilteredMembers('all', 'all');
			}
			return;
		}

		if (this.listPrivacy && this.listPrivacy !== 'Team not selected') {
			this.setDefaultRepeatCycle(this.listPrivacy);
			this.handleTodolistPrivacy(this.listPrivacy);
			this.todoList_uid = this.listPrivacy.uid;
			this.display_todoLists = 'none';
			this.assignedMembers = [];
			this.defaultTaskMember = this.currentUser.name;
			this.todoItem['assignedUser'] = this.currentUser.username;

			if (this.lists['name']) {
				if (this.listPrivacy && this.listPrivacy.channel && this.listPrivacy.channel.uid) {
					const uid = this.listPrivacy.channel.uid;
					if (this.listPrivacy.channel.access === 4) {
						this.getExternalChannelMembers(uid);
					} else {
						this.getFilteredMembers('channel', uid);
					}
				} else if (this.listPrivacy.contentPrivacy) {
					if (!this.listPrivacy.contentPrivacy.role) {
						if (this.listPrivacy.contentPrivacy.status) {
							const privacy = this.listPrivacy.contentPrivacy;
							const key = 'membership_status';
							const value = Object.values(privacy);
							this.getFilteredMembers(key, value[0]);
						} else if (this.listPrivacy.contentPrivacy.renewalStatus) {
							const privacy = this.listPrivacy.contentPrivacy;
							const key = 'renewal_status';
							const value = Object.values(privacy);
							this.getFilteredMembers(key, value[0]);
						} else {
							const privacy = this.listPrivacy.contentPrivacy;
							const key = Object.keys(privacy);
							const value = Object.values(privacy);
							this.getFilteredMembers(key[0], value[0]);
						}
					} else if (this.listPrivacy.contentPrivacy.role) {
						const privacy = this.listPrivacy.contentPrivacy;
						const key = Object.keys(privacy);
						const value = Object.values(privacy);
						const newVal = value[0].toString().toLowerCase();
						this.getFilteredMembers(key[0], newVal);
					}
				} else if (this.listPrivacy.customPrivacy && this.listPrivacy.customPrivacy.length > 0) {
					const customPrivacy = [];
					let filter;

					this.listPrivacy.customPrivacy.forEach((user) => {
						if (user.id) {
							customPrivacy.push(user.id);
						}
						filter = '[' + customPrivacy.join(',') + ']';
					});
					this.getFilteredMembers('custom_privacy', filter);
				} else {
					this.getFilteredMembers('all', 'all');
				}
			}
		} else if (this.listPrivacy === 'Team not selected') {
			this.todoList_uid = '';
			this.mentionPrivacy = '';
			this.getFilteredMembers('all', 'all');
			this.display_todoLists = 'none';
		} else {
			this.display_todoLists = 'none';
		}
	}

	/**
	 * Handle repeat cycle of todolist on task create form on
	 * selecting a todolist
	 * @param list
	 */
	private setDefaultRepeatCycle(list): void {
		if (list['repeatCycle'] === 'daily') {
			this.selectedRepeatOption = {
				option: 'Today',
				slug: 'daily',
			};
			this.repeatObj.option = 'Today';
		} else if (list['repeatCycle'] === 'monthly') {
			this.selectedRepeatOption = {
				option: 'This month',
				slug: 'monthly',
			};
			this.repeatObj.option = 'This month';
		} else if (list['repeatCycle'] === 'weekly') {
			this.selectedRepeatOption = {
				option: 'This week',
				slug: 'weekly',
			};
			this.repeatObj.option = 'This week';
		} else if (list['repeatCycle'] === 'one_off') {
			this.selectedRepeatOption = {
				option: 'Specify Date',
				slug: 'one_off',
			};
			this.repeatObj.option = 'Specify Date';
			this.display_date = true;
			const d = list['oneOff'];
			const x = new Date(d);
			const startMonth = x.getMonth() + 1;
			const startDay = x.getDate();
			const startYear = x.getFullYear();
			this.selectedDate = startYear + '-' + startMonth + '-' + startDay;
		}
	}

	/**
	 * Set privacy of todolist as default for project
	 * 1. Check if contentPrivacy exists within todolist.
	 * 2. Check if department exist as contentPrivacy and handle department.
	 * 3. Check if role exist as contentPrivacy and handle role.
	 * 4. Check if personalFilters(location) exists as contentPrivacy and handle it.
	 * 5. Check if membership cp exists as contentPrivacy and handle it.
	 * 6. Check if customPrivacy exists as cp and handle it.
	 * 7. Check if channel exists as cp and handle it.
	 */
	private setProjectPrivacy(data): void {
		if (data['name'] !== 'My Todo List') {
			if (data['contentPrivacy']) {
				// 1
				if (data['contentPrivacy'].department) {
					// 2
					const index = this.departments.findIndex((department) => department.value === data['contentPrivacy'].department);
					if (index > -1) {
						this.privacy.forNames = this.departments[index].valueLabel;
						this.privacy['selectedDepartment'] = this.departments[index];
					}
				} else {
					this.privacy['selectedDepartment'] = null;
				}

				if (data['contentPrivacy'].role) {
					// 3
					const role = {
						name: data['contentPrivacy'].role,
						slug: data['contentPrivacy'].role,
					};
					this.privacy['selectedRole'] = role;
					this.privacy.forNames = data['contentPrivacy'].role;
				} else {
					this.privacy['selectedRole'] = null;
				}

				if (!data['contentPrivacy'].role && !data['contentPrivacy'].department) {
					// 4
					const field = Object.keys(data['contentPrivacy']);
					this.personals.forEach((element) => {
						if (element.key === field[0] && field[0] !== 'manager') {
							this.forNames = element.valueLabel;
							this.privacy.forNames = element.valueLabel;
							this.privacy['personalFilter'] = element;
						}
					});
					if (data['contentPrivacy'].status) {
						// 5
						this.privacy.forNames = data['contentPrivacy'].status;
						this.privacy['selectByStatusValue'] = data['contentPrivacy'];
					} else if (data['contentPrivacy'].renewalStatus) {
						this.privacy.forNames = data['contentPrivacy'].renewalStatus;
						this.privacy['selectByRenewalValue'] = data['contentPrivacy'];
					} else if (data['contentPrivacy'].membership_level) {
						this.privacy['selectByLevelValue'] = data['contentPrivacy'];
					} else if (data['contentPrivacy'].manager) {
						if (this.privacy.myTeam && this.privacy.myTeam.key) {
							this.privacy['personalFilter'] = this.privacy.myTeam;
							this.privacy.forNames = this.translate.instant('My Team');
						}
					}
				}
			} else {
				this.privacy['content_privacy'] = null;
				this.privacy['selectedDepartment'] = null;
				this.privacy['role'] = null;
			}

			if (data['customPrivacy'] && data['customPrivacy'].length > 0) {
				// 6
				this.privacy['custom_privacy'] = data['customPrivacy'];
				const names = [];
				this.privacy.custom_privacy.forEach((user) => {
					if (user['user']) {
						names.push(user['user'].name);
					} else if (user['name']) {
						names.push(user['name']);
					} else {
						names.push(user.username);
					}
				});
				this.privacy.forNames = names.join(',');
			} else {
				this.privacy['custom_privacy'] = [];
			}

			if (data['channel']) {
				// 7
				this.privacy.forNames = data['channel']['name'];
				this.privacy['channel'] = data['channel'];
			}

			if (!data['contentPrivacy'] && !data['customPrivacy'] && !data['channel']) {
				this.privacy.forNames = 'Everyone';
				this.privacy['custom_privacy'] = [];
				this.privacy['selectedDepartment'] = '';
				this.privacy['selectedRole'] = '';
				this.privacy['channel'] = '';
			}
		} else {
			this.privacy.custom_privacy = [];
			const names = [];
			const a = {
				id: this.currentUser['userId'],
				username: this.currentUser['name'],
			};
			this.privacy.custom_privacy.push(a);
			this.privacy.custom_privacy.forEach((user) => {
				if (user['user']) {
					names.push(user['user'].name);
				} else if (user['name']) {
					names.push(user['name']);
				} else {
					names.push(user.username);
				}
			});
			this.privacy.forNames = names.join(',');
		}

		this.privacy.previousSelection = this.privacy.forNames;
		this.privacy.currentSelection = this.privacy.forNames;
	}

	// Display repeat cycle options
	private showRepeatOptions(): void {
		this.display_options = 'block';
	}

	/**
	 * Function to get all todolists for add task form
	 * 1. Show loader
	 * 2. Get all todolists
	 * 3. Set next url if exists
	 * 4. Call pagination if next url exists
	 * 5. If current page is not todolist detail and if todo detail doesn't exists(if in calendar From Todolist detail),display all lists
	 */
	public displayTodoListsModal(): void {
		if (this.projectPrivacyCp) {
			this.errorDialogForCpSelection = 'block';
			this.errorTextForCpSelection = this.translate.instant('You need to remove the project in order to change the team');
			return;
		}

		if (this.currentPage !== 'todolistDetail' && !this.todoDetail) {
			// 5
			this.scrollStatus = false;
			this.todoLists = [];
			this.isListsLoading = true; // 1
			this.display_todoLists = 'block';

			this.networkService.getToDoListForItem().subscribe((list) => {
				// 2
				this.isListsLoading = false;
				this.todoLists = list.objects;
				this.updatePrivacyString();
				this.showOneOffDate();
				this.todo_next_url = list?.next ? list.next.split('alpha')[1] : null;

				// if (this.todo_next_url) {
				// 	this.paginateLists();
				// }
			});
		}
	}

	/**
	 * Function to set project privacy cp
	 */
	public emitCurrentSelectedProjectCpFn($event): void {
		if ($event) {
			this.projectPrivacyCp = $event.name;
		} else {
			this.projectPrivacyCp = 'Everyone';
		}
		this.confirm_members = false;
		this.assignedMembers = [];
		this.defaultTaskMember = this.currentUser.name;
	}

	public projectDeselectedEmitterFn(): void {
		this.projectPrivacyCp = null;
		this.confirm_members = false;
		this.assignedMembers = [];
		this.defaultTaskMember = this.currentUser.name;
	}

	// Update privacy values of a list
	private updatePrivacyString(): void {
		this.todoLists.forEach((list) => {
			if (localStorage.getItem('MyTodolist') && list.uid === localStorage.getItem('MyTodolist')) {
				list['privacy_name'] = this.currentUser.name;
			} else {
				if (list['customPrivacy']) {
					list['customPrivacy'].forEach(() => {
						list['privacy_name'] = this.translate.instant('Custom Selection');
					});
				} else if (list['contentPrivacy']) {
					if (list['contentPrivacy'].role && list['contentPrivacy'].department) {
						const index = this.departments.findIndex((department) => department.value === list['contentPrivacy'].department);
						if (index > -1) {
							list['privacy_name'] = this.departments[index]['valueLabel'] + ' , ' + list['contentPrivacy'].role;
						}
					} else if (list['contentPrivacy'].role) {
						list['privacy_name'] = list['contentPrivacy'].role;
						//
					} else if (list['contentPrivacy'].department) {
						const index = this.departments.findIndex((department) => department.key === Object.keys(list['contentPrivacy']));
						if (index > -1) {
							list['privacy_name'] = this.departments[index]['valueLabel'];
						}
					} else if (!list['contentPrivacy'].role || !list['contentPrivacy'].department) {
						this.value = Object.keys(list['contentPrivacy']);
						this.personals.forEach((element) => {
							if (element.key === this.value[0]) {
								list['privacy_name'] = element.valueLabel;
							}
						});
						if (list['contentPrivacy'].status) {
							list['privacy_name'] = list['contentPrivacy'].status;
						} else if (list['contentPrivacy'].renewalStatus) {
							list['privacy_name'] = list['contentPrivacy'].renewalStatus;
						} else if (list['contentPrivacy'].membership_level) {
							const index = this.SubscriptionTemplates.findIndex((val) => val.uid === list['contentPrivacy'].membership_level);
							if (index > -1) {
								list['privacy_name'] = this.SubscriptionTemplates[index]['name'];
							}
						} else if (list['contentPrivacy'].trialPeriod) {
							list['privacy_name'] = list['contentPrivacy'].trialPeriod;
						}
					}
				} else if (list['channel'] && Object.keys(list['channel']).length) {
					list['privacy_name'] = list['channel'].name;
				} else {
					list['privacy_name'] = this.translate.instant('Everyone');
				}
			}
		});
	}

	/**
	 * Function to show one off date of  todolists
	 */
	private showOneOffDate(): void {
		this.todoLists.forEach((list) => {
			if (list.repeatCycle === 'one_off' && list.oneOff) {
				const newDate = new Date(list.oneOff).toLocaleString();
				list['newDate'] = newDate.substring(0, newDate.indexOf(','));
			}
		});
	}

	/**
	 * Function to display modal for members in a todolist
	 * 1. Check if current todolist is MyTodolist and if a todolist is selected
	 * 2. Display members selection modal if 1st condition passes
	 * 3. Else dont display anything
	 */
	public displayListMembersModal(): void {
		if (this.lists['name'] && this.lists['uid'] !== localStorage.getItem('MyTodolist')) {
			// 1
			this.display_members = 'block'; // 2
			this.selectToDoList();
		} else {
			this.display_members = 'none'; // 3
		}
	}

	/**
	 * Function to add members to array for task form
	 * 1. Check if array contains selected member already
	 * 2. If selected member doesn't exists,then push member
	 * 3. Else remove member
	 */
	public selectMembersForTask(member): void {
		const index = this.assignedMembers.findIndex((x) => x.uid === member.uid); // 1
		if (index === -1) {
			// 2
			this.assignedMembers.push(member);
		} else {
			this.assignedMembers.splice(index, 1); // 3
		}
	}

	/**
	 * Function to get todolist members based on cp of todolist
	 * 1. Get all members
	 * 2. Check if members are already selected for task
	 * 3. If members are selected,turn radio button on
	 * 4. Else turn all radio button off
	 * 5. Set next url
	 */
	private getFilteredMembers(key, value): void {
		this.isCalling = 0;
		let filterString;
		if (key === 'all') {
			filterString = 'external_members=false';
		} else {
			filterString = key + '=' + value;
		}
		this.memberService.getMembers(this.network.uid, '', filterString).subscribe((data) => {
			// 1
			// eslint-disable-next-line spellcheck/spell-checker
			this.assignees = data.objects;
			this.isCalling = 1;
			if (this.assignedMembers.length > 0) {
				// 2
				// eslint-disable-next-line spellcheck/spell-checker
				this.assignees.forEach((member) => {
					this.assignedMembers.forEach((m) => {
						if (m.uid === member.uid) {
							member['checked'] = true; // 3
						}
					});
				});
			} else {
				// eslint-disable-next-line spellcheck/spell-checker
				this.assignees.forEach((member) => {
					// 4
					member['checked'] = false;
				});
			}

			if (data.next) {
				// 5
				this.attachmentMembersNextPageUrl = data.next.split('alpha')[1];
			} else {
				this.attachmentMembersNextPageUrl = '';
			}
		});
	}

	private getExternalChannelMembers(value): void {
		this.isCalling = 0;
		this.memberService.getMembersForExternalChannel(value).subscribe((data) => {
			// eslint-disable-next-line spellcheck/spell-checker
			this.assignees = data.objects;
			this.isCalling = 1;
			if (this.assignedMembers.length > 0) {
				// eslint-disable-next-line spellcheck/spell-checker
				this.assignees.forEach((member) => {
					this.assignedMembers.forEach((m) => {
						if (m.uid === member.uid) {
							member['checked'] = true;
						}
					});
				});
			} else {
				// eslint-disable-next-line spellcheck/spell-checker
				this.assignees.forEach((member) => {
					member['checked'] = false;
				});
			}
			if (data.next) {
				this.attachmentMembersNextPageUrl = data.next.split('alpha')[1];
			} else {
				this.attachmentMembersNextPageUrl = '';
			}
		});
	}

	/*
	 * Function which puts selected members for a task in a todo item
	 */
	public onConfirmMembersForTask(): void {
		const taskMembers: any[] = [];
		if (this.assignedMembers.length > 0) {
			this.assignedMembers.forEach((member) => {
				if (member && member.user) {
					taskMembers.push(member.user.username);
				}
			});
			this.todoItem['assignedUser'] = taskMembers.join(', ');
			this.confirm_members = true;
			this.defaultTaskMember = '';
			this.display_members = 'none';
		} else {
			this.defaultTaskMember = this.currentUser.name;
			this.display_members = 'none';
		}
	}

	/*
	 *
	 * Function to close all modals in add task form
	 */
	public closeModal(): void {
		this.display_todoLists = 'none';
		this.display_options = 'none';
		this.display_members = 'none';
		this.display_priority = 'none';
		if (this.assignedMembers.length === 0) {
			this.defaultTaskMember = this.currentUser.name;
			this.todoItem['assignedUser'] = this.currentUser.username;
		}
	}

	// _________________________________________________________________________________//

	public setTaskRepeat(): void {
		this.currentRepeat = this.repeatSelected;
		if (this.repeatSelected !== 'never') {
			this.todoItem['repeatFreequency'] = this.repeatSelected;
		} else {
			this.currentRepeat = '';
			delete this.todoItem['repeatFreequency'];
		}
	}

	/**
	 *  Function on selecting a task reminder option
	 * 1. Check if repeat cycle is not daily or specify date
	 * 2. Show error message and disable time picker if true
	 * 3. Else enable time picker and hide error message
	 */
	public onEstimatedTimeSelect(): void {
		if (this.repeatObj['option'] !== 'Today' && this.repeatObj['option'] !== 'Specify Date' && this.repeatObj['option'] !== 'Tomorrow') {
			// 1
			this.showTaskTimeError = true; // 2
			this.dueTimeDisabled = true;
		} else {
			this.showTaskTimeError = false; // 3
			this.dueTimeDisabled = false;
		}
	}

	// [14-06-21, reminder options check]
	public onDueDateOptionClick(val): void {
		this.selectedDueDate = val;
		this.repeatObj = {
			option: val.option,
		};
		// this.taskReminders = this.postReminders;
		this.reminderSelected = ['none', 'None'];
		this.currentDueTime = '';
		this.taskDueTime = '';
		if (this.selectedDueDate && this.selectedDueDate.option === 'Specify Date') {
			this.display_date = true;
		} else {
			this.display_date = false;
		}
	}

	private clicked(): void {}

	public selectRepeatOption(): void {
		if (this.selectedDueDate) {
			this.selectedRepeatOption = this.selectedDueDate;
			this.display_options = 'none';
			if (this.selectedDueDate.option === 'Specify Date' && this.modelB) {
				this.selectedDate = this.parserFormatter.format(this.modelB);
			} else {
				this.selectedDate = '';
			}
		} else {
			this.display_options = 'none';
		}
	}

	/**
	 * Function to display Reminder options based on due time
	 * 1. IF due is selected,show event reminder options
	 * 2. Else show post reminder options
	 */
	// displayTaskReminderOptions() {
	//   if (this.taskDueTime) {  // 1
	//     this.taskReminders = this.eventReminders;
	//   } else {                // 2
	//     this.taskReminders = this.postReminders;
	//   }
	// }

	/**
	 * Function on selecting unassigned option in todos
	 * 1. Close members modal
	 * 2. Set assignedUser empty
	 * 3. Set Unassigned on todo form
	 * 4. Set assigned members array empty
	 * 5. Turn off all radio btn on members modal
	 */
	public onUnassignedSelected(): void {
		this.display_members = 'none'; // 1
		delete this.todoItem['assignedUser']; // 2
		this.defaultTaskMember = this.translate.instant('Unassigned'); // 3
		this.assignedMembers = []; // 4
		this.confirm_members = false;
		// eslint-disable-next-line spellcheck/spell-checker
		this.assignees.forEach((member) => {
			member['checked'] = false; // 5
		});
	}

	private minutesUntilMidnight(): number {
		const midnight = new Date();
		midnight.setHours(24);
		midnight.setMinutes(0);
		midnight.setSeconds(0);
		midnight.setMilliseconds(0);
		return (midnight.getTime() - new Date().getTime()) / 1000 / 60;
	}

	/**
	 * Function to close reminder message automatically
	 */
	private closeAfterSixSeconds(): void {
		setTimeout(() => {
			this.toast_message = 'none';
		}, 8000);
	}

	/**
	 * Function for setting estimated Time and repeats for tasks
	 * 1. Check if due time is selected for task
	 * 2. Set task due time in hour:min format
	 * 3. Check if repeat cycle is one off
	 * 4. Set Estimated time
	 * 5. If repeat cycle is not one off set estimated time with current date
	 * 6. If repeat option is selected for tasks set repeat Frequency
	 */
	private setAdditionalOptions(): void {
		if (this.taskDueTime) {
			// 1
			const newDueTime = `${this.taskDueTime.hour}:${this.taskDueTime.minute}`; // 2

			if (this.repeatObj['option'] === 'Specify Date') {
				// 3
				const d = this.selectedDate + ' ' + newDueTime;
				const date = new Date(d);
				const startMonth = date.getUTCMonth() + 1;
				const startDay = date.getUTCDate();
				const startYear = date.getUTCFullYear();
				const startHours = date.getUTCHours();
				const startMinutes = date.getUTCMinutes();
				const startSeconds = date.getUTCSeconds();
				const utcDate = startYear + '-' + startMonth + '-' + startDay + ' ' + startHours + ':' + startMinutes + ':' + startSeconds;
				this.todoItem['estimatedTime'] = utcDate;
			} else if (this.repeatObj['option'] === 'Tomorrow') {
				const today = new Date();
				const tomorrow = new Date(today);
				tomorrow.setDate(today.getDate() + 1);
				const tomorrowDate = new Date(tomorrow).toISOString().slice(0, 10) + ''; // 5
				const d = tomorrowDate + ' ' + newDueTime;
				const date = new Date(d);
				const startMonth = date.getUTCMonth() + 1;
				const startDay = date.getUTCDate();
				const startYear = date.getUTCFullYear();
				const startHours = date.getUTCHours();
				const startMinutes = date.getUTCMinutes();
				const startSeconds = date.getUTCSeconds();
				const utcDate = startYear + '-' + startMonth + '-' + startDay + ' ' + startHours + ':' + startMinutes + ':' + startSeconds;
				this.todoItem['estimatedTime'] = utcDate;
			} else {
				const todayDate = new Date().toISOString().slice(0, 10) + ''; // 5
				const d = todayDate + ' ' + newDueTime;
				const date = new Date(d);
				const startMonth = date.getUTCMonth() + 1;
				const startDay = date.getUTCDate();
				const startYear = date.getUTCFullYear();
				const startHours = date.getUTCHours();
				const startMinutes = date.getUTCMinutes();
				const startSeconds = date.getUTCSeconds();
				const utcDate = startYear + '-' + startMonth + '-' + startDay + ' ' + startHours + ':' + startMinutes + ':' + startSeconds;
				this.todoItem['estimatedTime'] = utcDate;
			}
		}
	}

	/**
	 * Function to reset additional options fields in task form
	 * 1. Reset due time field
	 * 2. Reset repeat and reminder fields
	 * 3. Reset Additional options ui fields
	 * 4. Remove estimated time and repeat Frequency from todo Item object
	 */
	private resetAdditionalOptions(): void {
		if (!this.todoDetail) {
			this.selectedDate = '';
		}
		this.dueDateComponent.reset();
		this.taskDueTime = ''; // 1
		this.currentRepeat = '';
		this.repeatSelected = 'never';
		this.reminderSelected = ['none', 'None'];
		this.currentDueTime = '';
		delete this.todoItem['estimatedTime']; // 4
		delete this.todoItem['startTime'];
		delete this.todoItem['endTime'];
		delete this.todoItem['repeatFreequency'];
	}

	// _________________________________________________________________________________//

	/*
	 *
	 * Function to creating a todo task
	 */
	public createToDoItem(): void {
		// return
		if (this.model['mentioned_users'] && this.model['mentioned_users'].length > 0) {
			const userList = [];

			for (const u of this.model['mentioned_users']) {
				if (this.model.body.indexOf('@' + u.name) > -1) {
					userList.push(u.username);
				}

				if (this.model.title.indexOf('@' + u.name) > -1) {
					userList.push(u.username);
				}
			}

			this.todoItem['mentioned_users'] = [];
			this.todoItem['mentioned_users'] = userList.join(',');
		}

		if (this.model['hashtags'] && this.model['hashtags'].length > 0) {
			const tagList = [];

			for (const h of this.model['hashtags']) {
				if (this.model.body.indexOf('#' + h['name']) > -1) {
					tagList.push(h['name']);
				}

				if (this.model.title.indexOf('#' + h['name']) > -1) {
					tagList.push(h['name']);
				}
			}

			const re = /(?:^|\W)#(\w+)(?!\w)/g;
			let match;

			while ((match = re.exec(this.model.body))) {
				if (tagList.indexOf(match[1]) < 0) {
					tagList.push(match[1]);
				}
			}

			this.todoItem['hashtags'] = [];
			this.todoItem['hashtags'] = tagList.join(',');
		}

		if (!this.article) {
			delete this.model.url;
		} else {
			if (this.model.url) {
				this.todoItem['attachmentType'] = 'url';
				this.todoItem['url'] = this.model.url;
			}
		}

		if (this.model.images !== 'undefined' && this.model.images.length > 0) {
			if (this.model.images.length > 1) {
				if (this.todoItem['images'] && this.todoItem['images'].length) {
					// this.todoItem['images'].splice(0, 1);
				}
			}

			if (this.doc_upload && this.documentList.indexOf(this.fileType[0]) > -1) {
				this.todoItem['attachmentType'] = 'doc';
			} else if (this.doc_upload && this.audioList.indexOf(this.fileType[0]) > -1) {
				this.todoItem['attachmentType'] = 'audio';
			} else if (this.doc_upload && this.videoList.indexOf(this.fileType[0]) > -1) {
				this.todoItem['attachmentType'] = 'video';
			} else {
				this.todoItem['attachmentType'] = 'photo';
			}

			this.todoItem['images'] = this.model.images.join('|');

			this.callCreateTask();
		} else if (this.model.location) {
			this.pageLoadingUiService.setPageLoading(true);
			this.todoItem['attachmentType'] = 'location';

			this.locationService
				.create({
					venueId: this.model.location.venueId,
					latitude: this.model.location.latitude,
					longitude: this.model.location.longitude,
					name: this.model.location.name,
					category: this.model.location.category,
					website: this.model.location.website,
					phone: this.model.location.phone,
				})
				.subscribe((data) => {
					this.todoItem['location'] = data.uid;
					this.callCreateTask();
				});
		} else {
			this.callCreateTask();
		}
	}

	/**
	 *
	 * Function for calling add task api
	 */
	private callCreateTask(): void {
		if (this.projectDetail || this.privacy.projectSelected) {
			this.listPrivacy = 'Team not selected';
		}

		if (this.todoItem['attachmentType'] === 'doc') {
			this.todoItem['doc'] = this.todoItem['images'];
			delete this.todoItem['images'];
		}

		if (this.todoItem['attachmentType'] === 'audio') {
			this.todoItem['audio'] = this.todoItem['images'];
			delete this.todoItem['images'];
		}

		if (this.todoItem['attachmentType'] === 'video') {
			this.todoItem['video'] = this.todoItem['images'];
			delete this.todoItem['images'];
		}

		if (this.attachmentMember && this.attachmentMember['uid']) {
			this.todoItem['attachmentType'] = 'member';
			this.todoItem['member'] = this.attachmentMember['uid'];
		}

		if (!this.model.title) {
			this.errorMessage = 'Please enter task name';
		} else if (this.display_date && !this.modelB) {
			this.errorMessage = 'Please select a date';
		} else {
			if (this.selectedTask && this.selectedTask.title === this.model.title) {
				this.todoItem['numberOfOpens'] = this.selectedTask['numberOfOpens'] + 1;
			} else {
				this.selectedTask = '';
			}

			if (this.privacy.projectSelected) {
				this.todoItem['project'] = this.privacy.projectSelected['uid'];
			}

			if (this.model.body) {
				this.todoItem['description'] = this.model.body;
			}

			this.todoItem.title = this.model.title;
			this.handleTodoHashtags(this.todoItem.title);
			this.pageLoadingUiService.setPageLoading(true);

			this.networkService.createToDoItem(this.todoItem, this.todoList_uid, this.listPrivacy).subscribe(
				(data) => {
					this.mixPanelService.track('Created Tasks', {
						workspace: this.network.name || 'NA',
						appVersion: environment.version,
						language: this.translate.currentLang,
						username: this.currentUser.username || 'NA',
						name: this.currentUser.name || 'NA',
						project: this.projectDetail || 'NA',
					});

					this.segmentService.trackEvent('Created Tasks', {
						workspace: this.network.name || 'NA',
						appVersion: environment.version,
						language: this.translate.currentLang,
						username: this.currentUser.username || 'NA',
						name: this.currentUser.name || 'NA',
						project: this.projectDetail || 'NA',
					});
					// Call function to create subtasks
					if (this.subtasks.length > 0) {
						this.checkSubtasks(data);
					} else {
						this.pageLoadingUiService.setPageLoading(false);
					}

					if (data['folderDetails']) {
						this.cacheLastTodolist(data['folderDetails']);
					} else {
						this.cacheLastTodolist('Team not selected');
					}

					this.handleStartDayEndDayModal();
					this.resetTodoForm();
					this.handleWelcomeTips();
					this.resetAdditionalOptions();
					this.setReminder(data, this.todoItem['remindersSelected'] ? this.todoItem['remindersSelected'] : []);
					this.newPostEmitter.emit(new Post(data));
					this.refreshLists.emit();
					// eslint-disable-next-line spellcheck/spell-checker
					this.assignees.forEach((member) => {
						member['checked'] = false;
					});
					this.hidePlusBtn = false;
					this.showTaskForms = false;
					this.layoutService.hideBd();
					this.confirm_members = false;
					this.assignedMembers = [];
					this.errorMessage = '';
					this.project.id = '';
					this.project.name = 'Project';
					this.privacy.currentProject = '';
					this.privacy.projectUid = '';
					this.privacy.projectSelected = '';
					this.isTodolistCreateForm = false;
					this.displayCreateTodoList = 'none';
					this.display_todoLists = 'none';
					this.displayStartEndModal = 'none';
					this.todoItem['startTime'] = null;
					this.todoItem['endTime'] = null;
					this.todoItem['remindersSelected'] = [];
					this.todoItem['selectedDueDate'] = 'daily';
					this.todoItem['fromTime'] = null;
					this.todoItem['toTime'] = null;

					if (!this.projectDetail) {
						this.projectPrivacyCp = '';
					}

					this.projectService.newTodoCreatedByExternalSource(data);
				},
				() => {
					this.handleError();
				}
			);
		}
	}

	private checkSubtasks(data): void {
		this.subtasks = this.subtasks.filter((task) => task['title']);

		if (this.subtasks.length > 0) {
			this.onCreateSubtasks(data);
		} else {
			this.subtasksIndexContainer = [];
			this.subtasks = [];
			this.pageLoadingUiService.setPageLoading(false);
		}
	}

	/**
	 * Cache last used todolist on creating task
	 * @param folder
	 */
	private cacheLastTodolist(folder): void {
		if (folder['uid']) {
			const list = folder;
			localStorage.setItem(`${this.networkId}-Todolist`, JSON.stringify(list));
		} else {
			localStorage.setItem(`${this.networkId}-Todolist`, 'Team not selected');
		}
	}

	/**
	 * Handle previous todolist from cache on task form
	 * 1. Check if LS contains current networkUid-todolist
	 * 2. Parse it from LS
	 * 3. Check if cachedList contains uid, then set the list name and uid as default
	 * 4. Set members for the cachedlist
	 * 5. If LS doesnt contains todolist,then set 'My TodoList' as deafult list
	 * 6. Handle MytodolistCP for usermentions
	 */
	private handlePreviousTodolist(): void {
		if (localStorage.getItem(`${this.networkId}-Todolist`)) {
			// 1
			const val = localStorage.getItem(`${this.networkId}-Todolist`);
			if (val === 'Team not selected' || val === 'No List Selected') {
				this.lists = {
					name: 'Team not selected',
					uid: '',
				};
				this.todoList_uid = '';
				this.listPrivacy = 'Team not selected';
				this.mentionPrivacy = '';
				this.selectToDoList();
			} else {
				const cacheList = JSON.parse(localStorage.getItem(`${this.networkId}-Todolist`)); // 2
				if (cacheList['uid']) {
					// 3
					this.lists = {
						name: cacheList['name'],
						uid: cacheList['uid'],
					};
					this.todoList_uid = cacheList['uid'];
					this.listPrivacy = cacheList; // 4
					this.selectToDoList();
				}
			}
		} else {
			this.lists = {
				name: 'Team not selected',
				uid: '',
			};
			this.todoList_uid = '';
			this.listPrivacy = 'Team not selected';
			this.mentionPrivacy = '';
			this.selectToDoList();
		}
	}

	private handleTodoHashtags(text): void {
		if (text.indexOf('#') > -1) {
			const words = text.split(' ');
			const hashes = [];
			words.forEach((element) => {
				if (element.indexOf('#') > -1) {
					const index = element.indexOf('#');
					if (index === 0) {
						const string = element.replace(/(^#)/g, '');
						if (hashes.indexOf(string) === -1) {
							hashes.push(string);
						}
					}
				}
			});
			if (hashes.length > 0) {
				this.todoItem['hashtags'] = hashes.join(',');
			}
		}
	}

	/**
	 * Function to get title suggestions for add task form
	 * 1. Reset array
	 * 2. Check if title is of length 4 and set search query
	 * 3. Call title suggestions api
	 * 4. Display title suggestions
	 * 5. If next url exists, call paginate api
	 */
	public getTaskTitleSuggestions(): void {
		this.suggestedTasks = []; // 1
		this.showTitleSuggestion = false;
		let query = '';
		if (this.model.title && (this.model.title.length === 3 || this.model.title.length === 4)) {
			// 2
			query = 'query=' + this.model.title;
			this.showTitleSuggestion = true;
		}
		if (query) {
			this.isTitleLoading = true;
			this.networkService.newFilterTodos(query).subscribe((data) => {
				// 3
				if (data['objects']) {
					this.showTitleSuggestion = true; // 4
					this.isTitleLoading = false;
					this.suggestedTasks = data.objects;
				} else {
					this.suggestedTasks = [];
					this.showTitleSuggestion = false;
					this.isTitleLoading = false;
				}
				if (data.next) {
					// 5
					this.suggestionNextUrl = data.next.split('alpha')[1];
					this.paginateTitles();
				} else {
					this.suggestionNextUrl = '';
				}
			});
		}
	}

	/**
	 * Function to get remaining title suggestions in add task form
	 * 1. Check if next suggestion url exists
	 * 2. Call paginate api for getting remaining suggestions
	 * 3. Set next suggestion url
	 * 4. Append new objects to suggestions array
	 */
	public paginateTitles(): void {
		if (this.suggestionNextUrl) {
			// 1
			this.networkService.paginateTasks(this.suggestionNextUrl).subscribe((data) => {
				// 2
				if (data.next) {
					// 3
					this.suggestionNextUrl = data.next.split('alpha')[1];
				} else {
					this.suggestionNextUrl = '';
				}
				this.suggestedTasks = this.suggestedTasks.concat(data.objects); // 4
			});
		}
	}

	/**
	 * Function to select a suggested title in add task form
	 * 1. Set selected task on a variable
	 * 2. Remove title suggestion container
	 */
	public onSelectTaskSuggestion(item): void {
		this.selectedTask = item; // 1
		this.showTitleSuggestion = false; // 2
		this.suggestedTasks = [];
		this.model.title = item.title;
	}

	/**
	 *
	 * Function which executes on selecting a hashtag in create post modal
	 * @param tag
	 */
	public onHashTagSelect(tag): void {
		this.isMentions = false;
		this.userMentions = [];
		this.hashMentions = [];
		if (this.titleFocus) {
			const startPosition = this.modelTitle.nativeElement.selectionStart;
			const endPosition = this.modelTitle.nativeElement.selectionEnd;
			if (startPosition === endPosition) {
				const text = this.modelTitle.nativeElement.value.substring(0, startPosition);
				this.mentionIndex = text.lastIndexOf('#');

				if (this.mentionIndex > -1 && this.shiftKeyUsed) {
					const strippedText = this.modelTitle.nativeElement.value.substring(this.mentionIndex, startPosition);
					if (strippedText !== '#') {
						const reg = new RegExp(strippedText, 'g');
						const replacedTitle = this.model.title.replace(reg, '#' + tag['name'] + ' ');
						this.model.title = replacedTitle;
					} else {
						const firstPart = this.model.title.substr(0, startPosition);
						const lastPart = this.model.title.substr(startPosition + 1);
						const newString = firstPart + tag['name'] + ' ' + lastPart;
						this.model.title = newString;
					}
				} else {
					const newTitle = <''>(
						`${this.modelTitle.nativeElement.value.substring(0, startPosition)}#${tag['name'] + ' '}${this.modelTitle.nativeElement.value.substring(
							endPosition
						)}`.toString()
					);
					this.model.title = newTitle;
					this.modelTitle.nativeElement.focus();
				}
			}
		} else {
			const startPosition = this.modelBody.nativeElement.selectionStart;
			const endPosition = this.modelBody.nativeElement.selectionEnd;

			if (startPosition === endPosition) {
				const body = this.modelBody.nativeElement.value.substring(0, startPosition);
				this.mentionIndex = body.lastIndexOf('#');

				if (this.mentionIndex > -1 && this.shiftKeyUsed) {
					const strippedText = this.modelBody.nativeElement.value.substring(this.mentionIndex, startPosition);
					if (strippedText !== '#') {
						const reg = new RegExp(strippedText, 'g');
						const replacedBody = this.model.body.replace(reg, '#' + tag['name'] + ' ');
						this.model.body = <''>replacedBody;
					} else {
						const firstPart = this.model.body.substr(0, startPosition);
						const lastPart = this.model.body.substr(startPosition + 1);
						const newString = firstPart + tag['name'] + ' ' + lastPart;
						this.model.body = <''>newString;
					}
				} else {
					const newTitle = <''>(
						`${this.modelBody.nativeElement.value.substring(0, startPosition)}#${tag['name'] + ' '}${this.modelBody.nativeElement.value.substring(
							endPosition
						)}`.toString()
					);
					this.model.body = newTitle;
					this.modelBody.nativeElement.focus();
				}
			}
		}

		if (this.model['hashtags'] && this.model['hashtags'].length > 0) {
			this.model['hashtags'].push(tag);
		} else {
			this.model['hashtags'] = [];
			this.model['hashtags'].push(tag);
		}
	}

	/**
	 *
	 * Function for uploading new image in question post
	 */
	private uploadQuestionPhoto(): void {
		this.question_photo = !this.question_photo;
		this.article = false;
		this.location_display = 'none';
		this.doc_upload = false;
	}

	/**
	 *
	 * Function for closing upload display in question post
	 */
	public onCloseQuestionImage(): void {
		this.model.images = [];
		this.model.preview_images = [];
		this.question_photo = false;
	}

	/**
	 *
	 * Function for uploading new image in buy sell post
	 */
	private uploadBuySellImage(): void {
		this.showAttachmentOption = !this.showAttachmentOption;
	}

	private onCloseBuySellImageContainer(): void {
		this.showAttachmentOption = false;
	}

	/**
	 * Function to get remaining todolists
	 * 1. Get all todolists
	 * 2. Set next url
	 * 3. Append new todolists to array
	 */
	public paginateLists(): void {
		if (this.scrollStatus) {
			return;
		}
		this.scrollStatus = true;
		if (this.todo_next_url) {
			this.networkService.paginateToDoLists(this.todo_next_url).subscribe((data) => {
				this.todo_next_url = data.next ? data.next.split('alpha')[1] : null; // 2

				this.todoLists = this.todoLists.concat(data.objects); // 3

				this.scrollStatus = false;
				this.updatePrivacyString();
				this.showOneOffDate();
			});
		}
	}

	/**
	 * Function on selecting a todolist and turning radio btn on/off
	 */
	public onClickToDoList(list): void {
		if (list['uid']) {
			this.lists = {
				name: list['name'],
				uid: list['uid'],
			};
			this.listPrivacy = list;
		} else {
			this.lists = {
				name: list,
				uid: '',
			};
			this.listPrivacy = list;
		}
		this.todoPrivacyCp = list.channel;
	}

	/**
	 * 1.Set everyone as default cp of projects
	 * 2.Hidden for setting everyone as default cp of projects
	 */
	public displayProjects(): void {
		if (!this.projectDetail) {
			this.privacy.getProjects();
			this.privacy.forNames = 'Everyone';
			this.privacy.previousSelection = 'Everyone';
			this.privacy.currentSelection = 'Everyone';
			this.resetPrivacyComponent();
		}
	}

	/**
	 * Function to check the cp of project is same as the sp of todo list
	 */
	private checkCpIsSame(): void {}

	/**
	 * Function to show privacy values selected from privacy modal on add new project form
	 */
	public updatePrivacyStringBasedOnPrivacyComponent(): void {
		this.forNames = '';
		if (this.privacy.custom_privacy && this.privacy.custom_privacy.length > 0) {
			const names = [];
			this.privacy.custom_privacy.forEach((user) => {
				if (user['user']) {
					names.push(user['user'].name);
				} else if (user['name']) {
					names.push(user['name']);
				} else {
					names.push(user.username);
				}
			});
			this.forNames = names.join(',');
		} else {
			if (this.privacy.selectedRole && this.privacy.selectedDepartment) {
				this.forNames = this.privacy.selectedRole.name + ', ' + this.privacy.selectedDepartment.valueLabel;
			} else if (this.privacy.selectedRole) {
				let roleName;
				if (this.privacy.selectedRole.name) {
					roleName = this.privacy.selectedRole.name;
				} else {
					roleName = this.privacy.selectedRole;
				}
				this.forNames = roleName;
			} else if (this.privacy.selectedDepartment) {
				let departmentName = '';
				departmentName = this.privacy.selectedDepartment.valueLabel;
				this.forNames = departmentName;
			} else if (this.privacy.personalFilter) {
				let personalFilter = '';
				personalFilter = this.privacy.personalFilter.valueLabel;
				this.forNames = personalFilter;
			} else if (this.privacy.channel) {
				this.forNames = this.privacy.channel.name;
			} else if (this.privacy.selectByRenewalValue) {
				this.forNames = this.privacy.selectByRenewalValue[1];
			} else if (this.privacy.selectByLevelValue) {
				this.forNames = this.privacy.selectByLevelValue.name;
			} else if (this.privacy.selectByStatusValue) {
				this.forNames = this.privacy.selectByStatusValue[1];
			} else {
				this.forNames = 'Everyone';
			}
		}
	}

	/**
	 * Function to display todolist create popup
	 * 1. Display todolist create popup
	 * 2. Display cp values
	 */
	public displayCreateNewTodolistForm(): void {
		this.displayCreateTodoList = 'block'; // 1
		this.updateSelectionFields(); // 2
		this.isTodolistCreateForm = true;
	}

	/**
	 * Function to set repeat cycle of todolist
	 * 1. Set repeat cycle selected for todolist
	 * 2. Show one off date field if repeat is one off
	 * 3. Else hide date field
	 */
	public setTodoListRepeatCycle(): void {
		this.todolist.repeatCycle = this.TodoListRepeatCycle.toLowerCase(); // 1
		if (this.TodoListRepeatCycle === 'one-off') {
			this.displayDateField = true; // 2
		} else {
			this.displayDateField = false; // 3
			this.todoListDate = {
				year: new Date().getFullYear(),
				month: new Date().getMonth() + 1,
				day: new Date().getDate(),
			};
			delete this.todolist['oneOff'];
		}
	}

	/**
	 * Update cp value according to the detail from the privacy modal.
	 */
	private updateTodoListPrivacy(isEdit = false): void {
		if (this.privacy.custom_privacy && this.privacy.custom_privacy.length > 0) {
			delete this.todolist['content_privacy'];
			this.todolist['custom_privacy'] = [];
			const names = [];
			// -
			const newObject = this.privacy.custom_privacy;
			newObject.forEach((member) => {
				member.privacyType = 'customPrivacy';
			});
			localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			this.privacy.custom_privacy.forEach((user) => {
				if (user['user']) {
					this.todolist['custom_privacy'].push(user['user'].userId);
					names.push(user['user'].name);
				} else if (user.userId) {
					this.todolist['custom_privacy'].push(user.userId);
					names.push(user.username);
				} else {
					this.todolist['custom_privacy'].push(user.id);
					names.push(user.username);
				}
			});
			if (isEdit) {
				this.privacy.showCustomPrivacy();
			}
			delete this.todolist['collaboration'];
		} else {
			let roleString = null;
			let departmentString = null;
			let personalFilterString = null;
			let selectByStatusString = null;
			let selectByRenewalString = null;
			let selectByLevelString = null;

			delete this.todolist['custom_privacy'];
			if (this.privacy.selectedRole && this.privacy.selectedDepartment) {
				roleString = '"role": "' + this.privacy.selectedRole.slug + '"';
				departmentString = '"department": "' + this.privacy.selectedDepartment.value + '"';
			} else if (this.privacy.selectedRole) {
				roleString = '"role": "' + this.privacy.selectedRole.slug + '"';
				// * ******************SPACE AFTER : ******************* */
				roleString = '"role": "' + this.privacy.selectedRole.slug + '"';
				// * ************************************** */
				const newObject = this.privacy.selectedRole;
				newObject.privacyType = 'role';
				localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			} else if (this.privacy.selectedDepartment) {
				if (this.privacy.selectedDepartment.value) {
					departmentString = '"department": "' + this.privacy.selectedDepartment.value + '"';
				}
				if (this.privacy.selectedDepartment.uid && this.privacy.selectedDepartment.name) {
					departmentString = '"department": "' + this.privacy.selectedDepartment.uid + '"';
				}
				const newObject = this.privacy.selectedDepartment;
				newObject.privacyType = 'department';
				localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			} else if (this.privacy.personalFilter) {
				personalFilterString = '"' + this.privacy.personalFilter.key + '": "' + this.privacy.personalFilter.value + '"';
				// -
				const newObject = this.privacy.personalFilter;
				newObject.privacyType = 'personalFilter';
				localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			} else if (this.privacy.selectByStatusValue) {
				if (this.privacy.selectByStatusValue.status) {
					selectByStatusString = '"status": "' + this.privacy.selectByStatusValue.status + '"';
				} else {
					selectByStatusString = '"status": "' + this.privacy.selectByStatusValue[0] + '"';
					// -
					const newObject = this.privacy.selectByStatusValue;
					if (newObject.includes('status') === false) {
						newObject.push('status');
					}
					localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
				}
			} else if (this.privacy.selectByRenewalValue) {
				if (this.privacy.selectByRenewalValue.renewalStatus) {
					selectByRenewalString = '"renewalStatus": "' + this.privacy.selectByRenewalValue.renewalStatus + '"';
				} else {
					selectByRenewalString = '"renewalStatus": "' + this.privacy.selectByRenewalValue[0] + '"';
					const newObject = this.privacy.selectByRenewalValue;
					if (newObject.includes('renewalFrequency') === false) {
						newObject.push('renewalFrequency');
					}
					localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
				}
			} else if (this.privacy.selectByLevelValue) {
				if (this.privacy.selectByLevelValue.uid) {
					selectByLevelString = '"membership_level": "' + this.privacy.selectByLevelValue.uid + '"';
					const newObject = this.privacy.selectByLevelValue;
					newObject.privacyType = 'selectByLevel';
					localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
				} else {
					selectByLevelString = '"membership_level": "' + this.privacy.selectByLevelValue.membership_level + '"';
				}
			} else if (this.privacy.channel) {
				this.todolist['channel'] = this.privacy['channel']['uid'];
				const newObject = this.privacy.channel;
				newObject['privacyType'] = 'subCircle';
				localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			}
			if (
				this.privacy.custom_privacy.length === 0 &&
				!this.privacy.selectedRole &&
				!this.privacy.role &&
				!this.privacy.personalFilter &&
				!this.privacy.selectedDepartment &&
				!this.privacy.channel &&
				!this.model.channel &&
				!this.privacy.selectByStatusValue &&
				!this.privacy.selectByRenewalValue &&
				!this.privacy.selectByLevelValue
			) {
				localStorage.setItem(`${this.networkId}`, JSON.stringify('Everyone'));
			}

			if (roleString && departmentString) {
				this.todolist['content_privacy'] = '{' + roleString + ', ' + departmentString + '}';
			} else if (roleString && !departmentString) {
				this.todolist['content_privacy'] = '{' + roleString + '}';
			} else if (!roleString && departmentString) {
				this.todolist['content_privacy'] = '{' + departmentString + '}';
			} else if (personalFilterString) {
				this.todolist['content_privacy'] = '{' + personalFilterString + '}';
			} else if (selectByStatusString) {
				this.todolist['content_privacy'] = '{' + selectByStatusString + '}';
			} else if (selectByRenewalString) {
				this.todolist['content_privacy'] = '{' + selectByRenewalString + '}';
			} else if (selectByLevelString) {
				this.todolist['content_privacy'] = '{' + selectByLevelString + '}';
			} else {
				delete this.todolist['content_privacy'];
			}
		}
		if (!this.model['channel']) {
			delete this.todolist['channel'];
		}
		if (this.todolist['custom_privacy'] && this.todolist['custom_privacy'].length > 0) {
			this.todolist['custom_privacy'] = '[' + this.todolist['custom_privacy'].join(',') + ']';
		}
	}

	/**
	 * Function on creating a todolist
	 * 1. Check if title is present for list
	 * 2. Display error if not
	 * 3. Update privacy values of todolist
	 * 4. Handle one off date string
	 * 5. Call api to create a todolist
	 * 6. Append new list created to todoLists array
	 * 7. Close todolist popup and reset all field values
	 */
	public onTodoListCreate(): void {
		if (!this.todolist['name']) {
			// 1
			this.todoListCreateError = this.translate.instant('Please enter a title'); // 2
		} else if (this.displayDateField && !this.todoListDate) {
			this.todoListCreateError = this.translate.instant('Please select a date');
		} else {
			this.pageLoadingUiService.setPageLoading(true);
			this.updateTodoListPrivacy(); // 3
			this.updateSelectionFields();
			if (this.todoListDate && this.displayDateField) {
				this.todolist.repeatCycle = 'one_off'; // 4
				this.todolist['oneOff'] = this.parserFormatter.format(this.todoListDate);
			}
			this.networkService.createToDoList(this.todolist, this.network.uid).subscribe((data) => {
				// 5
				this.todoLists.unshift(data); // 6
				this.showOneOffDate();
				this.updatePrivacyString();
				this.refreshLists.emit();
				this.displayDateField = false; // 7
				this.todoListDate = {
					year: new Date().getFullYear(),
					month: new Date().getMonth() + 1,
					day: new Date().getDate(),
				};
				this.todolist.name = '';
				this.todolist.description = '';
				this.TodoListRepeatCycle = 'daily';
				delete this.todolist['oneOff'];
				this.isTodolistCreateForm = false;
				this.displayCreateTodoList = 'none';
				//
				this.pageLoadingUiService.setPageLoading(false);
			});
		}
	}

	private removeWelcomeDialog(): void {
		this.networkService.displayWelcomeDialog = false;
	}

	/**
	 * Function to check for start/end day actions in membership
	 *
	 * @param membership
	 */
	public checkForStartDayEndDay(membership: any): void {
		this.todoAction = membership['startDay'] && !membership['endDay'] ? 'End Day' : 'Start Day';

		// if (membership["startDay"] && !membership["endDay"]) {
		//   this.todoAction = "End Day";
		// } else {
		//   this.todoAction = "Start Day";
		// }

		// patch
		// this.todoAction === "End Day" ? "Start Day" : "End Day";
	}

	/**
	 * Function to check for start/end day actions in membership
	 *
	 * @param action: 'Start Day' or 'End Day'
	 */
	public displayTodosModal(action: string): void {
		this.hidePlusBtn = true;
		this.showMenu = false;
		this.selectedTasks = [];
		this.listIds = [];
		this.selectedTodos = [];
		this.noLists = [];
		this.noListUids = [];
		this.layoutService.hideBd();
		this.startDayUserCounts = '';

		if (action === 'Start Day') {
			this.todos = [];
			this.todoListsNames = [];
			this.startDaySelected = true;
			this.noTasks = false;
			const filterString = 'assigned=true&open=true&start_day=true';
			this.getStartDayUserCounts(); // Get start day counts
			this.getStartDayTodos(filterString);
		} else {
			this.todos = [];
			this.todoListsNames = [];
			this.noTasks = false;
			this.startDaySelected = false;
			this.displayStartEndModal = 'block';
			this.getStartDayUserCounts(); // Get start day counts
			this.getEndDayTodos();
		}
	}

	/**
	 * Function to get tasks on start/end day action
	 * @param filter
	 * 1. Display modal to show Tasks
	 * 2. Call api for getting tasks
	 * 3. Set next url
	 * 4. Push todolist name of tasks into an array for displaying in modal
	 * 5. Check items for end day action
	 */
	private getStartDayTodos(filter): void {
		this.displayStartEndModal = 'block'; // 1
		this.scrollStatus = false;
		this.todosFetched = 0;
		this.networkService.newFilterTodos(filter).subscribe((data) => {
			// 2
			this.next_url = data.next ? data.next.split('alpha')[1] : '';

			if (data.count === 0) {
				this.noTasks = true;
			} else {
				this.noTasks = false;
			}
			this.todos = data.objects;
			this.todosFetched = 1;
			this.getEstimatedTime();
			this.showOneOffDateForTodos();
			this.checkUserMentionAndHashtagAndAlterBody();
			if (!this.startDaySelected) {
				// 5
				this.todos.forEach((item) => {
					item['checked'] = true;
					this.selectedTasks.push(item);
				});
			}
			this.todos.forEach((obj) => {
				// 4
				if (obj.folderDetails && obj.folderDetails.name) {
					if (this.todoListsNames.includes(obj.folderDetails.name) === false) {
						this.todoListsNames.push(obj.folderDetails.name);
					}
				} else {
					const index = this.noLists.findIndex((x) => x['uid'] === obj['uid']);
					if (index === -1) {
						this.noLists.push(obj);
					}
				}
			});
		});
	}

	/**
	 * Function to get todos for end day
	 */
	private getEndDayTodos(): void {
		this.scrollStatus = false;
		this.todosFetched = 0;
		this.networkService.getEndDayTodos().subscribe((data) => {
			console.log(data);
			this.totalCounts = data.count;

			this.next_url = data.next ? data.next.split('alpha')[1] : '';

			if (data.count === 0) {
				this.noTasks = true;
			} else {
				this.noTasks = false;
			}
			this.todos = data.objects;
			this.todosFetched = 1;
			this.getEstimatedTime();
			this.showOneOffDateForTodos();
			this.checkUserMentionAndHashtagAndAlterBody();
			this.todos.forEach((item) => {
				if (item['status'] === 'closed') {
					this.selectedTasks.push(item);
				} else {
					this.incompleteTodos.push(item);
				}
			});
			this.todos.forEach((obj) => {
				if (obj.folderDetails && obj.folderDetails.name) {
					if (this.todoListsNames.includes(obj.folderDetails.name) === false) {
						this.todoListsNames.push(obj.folderDetails.name);
					}
				} else {
					const index = this.noLists.findIndex((x) => x['uid'] === obj['uid']);
					if (index === -1) {
						this.noLists.push(obj);
					}
				}
			});
		});
	}

	public paginateTasks(): void {
		if (this.next_url) {
			if (this.scrollStatus) {
				return;
			}
			this.scrollStatus = true;
			this.todosFetched = 0;
			this.networkService.paginateTasks(this.next_url).subscribe((data) => {
				// 1
				this.next_url = data.next ? data.next.split('alpha')[1] : '';

				this.todos = this.todos.concat(data.objects); // 3
				this.getEstimatedTime();
				this.showOneOffDateForTodos();
				this.checkUserMentionAndHashtagAndAlterBody();
				this.todosFetched = 1;
				this.scrollStatus = false;
				this.todos.forEach((obj) => {
					if (obj.folderDetails && obj.folderDetails.name) {
						if (this.todoListsNames.includes(obj.folderDetails.name) === false) {
							this.todoListsNames.push(obj.folderDetails.name); // 4
						}
					} else {
						const index = this.noLists.findIndex((x) => x['uid'] === obj['uid']);
						if (index === -1) {
							this.noLists.push(obj);
						}
					}
				});
			});
		}
	}

	/**
	 * Function which executes on selecting task for start/end day action
	 * @param item
	 */
	public selectTasksForActions(item, event?: Event): void {
		const clickedElementRef: ElementRef = new ElementRef(event.target);
		if (item.status !== 'closed' && this.todoAction === 'End Day' && !item.estimate) {
			this.elapsedForm.reset();
			this.selectedTodo = item;
			// this.displayElapsed = 'block';
			// if(clickedElementRef?.nativeElement?.checked){
			// }
			this.toggleOverlay(clickedElementRef);
			return;
		} else if (item.status !== 'closed' && this.todoAction === 'End Day' && item.estimate) {
			this.selectedTodo = item;
			// this.taskReactionDialog = 'block';
			// if(clickedElementRef?.nativeElement?.checked){
			// }
			this.toggleOverlay(clickedElementRef);
			return;
		}

		if (this.selectedTasks.includes(item) === false) {
			this.selectedTasks.push(item);
		} else {
			this.selectedTasks.splice(
				this.selectedTasks.findIndex((task) => task === item),
				1
			);
		}
	}

	/**
	 * Function for checking todos on end day action
	 * 1. Check if incomplete/undone tasks are present in endday;else call function for end day action
	 * 2. Check if selected tasks for endday are equal or not to total tasks count on end day
	 * 3. Display appropraite message
	 */
	public checkTodosForEndDay(): void {
		if (this.incompleteTodos.length > 0) {
			// 1
			if (this.selectedTasks.length === this.totalCounts) {
				// 2
				this.displayEndDayConfirm = 'block';

				this.endDayText = this.translate.instant('Selected incomplete tasks will be marked complete'); // 3
			} else {
				this.displayEndDayConfirm = 'block';

				this.endDayText = this.translate.instant('You are ending your day. Good night!'); // 3
			}

			this.dailyMessageService.dailyMessageRefresh();
		} else {
			this.activateTaskComments();
		}

		this.todoAction = 'Start Day';
	}

	public activateTaskComments(): void {
		this.networkService.todoAction = '';

		if (this.selectedTasks.length > 0) {
			this.selectedTasks.forEach((task) => {
				if (task.folderDetails && task.folderDetails.uid) {
					if (this.listIds.includes(task.folderDetails.uid) === false) {
						this.listIds.push(task.folderDetails.uid); // 1
					}
				} else {
					if (this.noListUids.includes(task.uid) === false) {
						this.noListUids.push(task.uid);
					}
				}
			});

			let params;
			const obj = {};

			if (this.listIds.length > 0) {
				this.listIds.forEach((id) => {
					obj[id] = '';
					this.selectedTasks.forEach((task) => {
						if (task.folderDetails && task.folderDetails.uid) {
							if (id === task.folderDetails.uid) {
								this.selectedTodos.push(task.uid); // 2
							}
						}
					});
					obj[id] = this.selectedTodos; // 3
					this.selectedTodos = [];
				});
			}

			const jsonObj = JSON.stringify(obj);

			if (this.startDaySelected) {
				this.todoAction = 'End Day';

				params = {
					start: true,
					l: this.language,
					data: jsonObj,
					workplace_today: this.selectedWorkPlace?.value ? this.selectedWorkPlace.value : null
				};

				if (this.noListUids.length > 0) {
					params['noTaskUid'] = JSON.stringify(this.noListUids);
				}
			} else {
				this.todoAction = 'Start Day';

				params = {
					end: true,
					l: this.language,
					data: jsonObj,
				};

				if (this.noListUids.length > 0) {
					params['noTaskUid'] = JSON.stringify(this.noListUids);
				}
			}

			// return;
			this.actionsGif = 'block';
			this.networkService.postTodoListActiveMembers(params).subscribe(
				() => {
					// 4
					this.displayEndDayConfirm = 'none';
					// this.refreshDailyMessage();
					this.dailyMessageService.dailyMessageRefresh();
					setTimeout(() => {
						this.hidePlusBtn = false;
						this.actionsGif = 'none';
						this.startDaySelected = false;
					}, 1000);

					// this.startDaySelected = false;
					this.displayStartEndModal = 'none';
					this.networkService.populate();
				},
				() => {
					setTimeout(() => {
						this.hidePlusBtn = false;
						this.actionsGif = 'none';
						this.startDaySelected = false;
					}, 1000);
					// this.startDaySelected = false;
					this.displayEndDayConfirm = 'none';
					this.displayStartEndModal = 'none';
				}
			);
		} else {
			if (this.startDaySelected) {
				// eslint-disable-next-line spellcheck/spell-checker
				this.toastService.info(this.translate.instant('Please select atleast one task for start day'), {
					duration: 5000,
				});
			} else {
				// 5
				this.todoAction = 'Start Day';

				// let params;
				const obj = {};
				const jsonObj = JSON.stringify(obj);
				const params = {
					end: true,
					data: jsonObj,
					l: this.language,
				};

				this.actionsGif = 'block';
				this.networkService.postTodoListActiveMembers(params).subscribe(() => {
					// this.refreshDailyMessage();
					this.dailyMessageService.dailyMessageRefresh();

					setTimeout(() => {
						this.hidePlusBtn = false;
						this.actionsGif = 'none';
					}, 1000);

					this.displayStartEndModal = 'none';
					this.displayEndDayConfirm = 'none';
				});
			}
		}
	}

	/**
	 * Function to display task create form on add task action in start day modal
	 */
	public displayTaskCreateForm(): void {
		this.displayStartEndModal = 'none';
		this.layoutService.showBd('post-menu');
		this.selectPost({
			name: 'Add Task',
			slug: 'add-task',
		});
	}

	/**
	 * Function to display start/end day modal after closing add task form
	 */
	private handleStartDayEndDayModal(): void {
		if (this.startDaySelected) {
			this.displayTodosModal('Start Day');
		} else {
			this.displayStartEndModal = 'none';
		}
	}

	public displayCountryModal(): void {
		this.showCountryModal = 'block';
		this.field['value'] = '';
		this.isLoading = true;
		this.countries = [];
		this.searchString = '';
		this.displayCountryError = false;
		this.userService.fetchCountries().subscribe((data) => {
			this.countries = data['results'];
			if (data.next) {
				this.nextCUrl = data.next.split('alpha')[1];
			} else {
				this.nextCUrl = '';
			}
			this.isLoading = false;
		});
	}

	public paginateCountries(): void {
		if (this.nextCUrl) {
			this.userService.paginate(this.nextCUrl).subscribe((data) => {
				if (data.next) {
					this.nextCUrl = data.next.split('alpha')[1];
				} else {
					this.nextCUrl = '';
				}
				this.countries = this.countries.concat(data.results);
			});
		}
	}

	public onSearchClick(): void {
		this.isLoading = true;
		this.displayCountryError = false;
		this.countries = [];
		this.userService.searchCountries(this.searchString).subscribe((data) => {
			if (data.results.length > 0) {
				this.isLoading = false;
				this.countries = data.results;
			} else {
				this.displayCountryError = true;
				this.isLoading = false;
			}
		});
	}

	public onSearchEnter(event: any): void {
		if (event.keyCode === 13) {
			this.onSearchClick();
		}
	}

	public onSelectCountry(country): void {
		this.selectedCountry = country;
		this.field['value'] = country['uid'];
	}

	private setCountry(): void {
		this.additionalContactForm.controls.code.setValue(this.selectedCountry['countryCode']);
		this.additionalContactForm.controls.country.setValue(this.selectedCountry['name']);
		this.customer['countryCode'] = this.selectedCountry['code'];
	}

	public displayMembersForCustomer(): void {
		this.displayModalForCustomer = 'block';
		this.memberService.getMembers(this.network.uid).subscribe((data) => {
			this.noAssignee = false;
			// eslint-disable-next-line spellcheck/spell-checker
			this.assignees = data.objects;
			if (data.next) {
				this.attachmentMembersNextPageUrl = data.next.split('alpha')[1];
			} else {
				this.attachmentMembersNextPageUrl = '';
			}
		});
	}

	public paginateMembers(): void {
		if (this.attachmentMembersNextPageUrl) {
			this.postService.paginate(this.attachmentMembersNextPageUrl).subscribe((data) => {
				if (data) {
					// eslint-disable-next-line spellcheck/spell-checker
					this.assignees = this.assignees.concat(data['objects']);
					if (this.assignedMembers.length > 0) {
						// 2
						// eslint-disable-next-line spellcheck/spell-checker
						this.assignees.forEach((member) => {
							this.assignedMembers.forEach((m) => {
								if (m.uid === member.uid) {
									member['checked'] = true; // 3
								}
							});
						});
					}
					if (data.next) {
						this.attachmentMembersNextPageUrl = data.next.split('alpha')[1];
					} else {
						this.attachmentMembersNextPageUrl = '';
					}
				}
			});
		}
	}

	public displayStatesModal(): void {
		this.displayStates = 'block';
		if (this.customerStates.length === 0) {
			this.networkService.getCustomerStates().subscribe((data) => {
				if (data.CustomerStates) {
					this.customerStates = data.CustomerStates;
					const index = this.customerStates.findIndex((state) => state[0] === 'in_progress');
					if (index > -1) {
						this.customerStates.splice(index, 1);
					}
				}
			});
		}
	}

	public setPriority(): void {
		if (this.postType === 'add-task') {
			this.todoItem['priority'] = this.prioritySelected;
		} else {
			this.customer['priority'] = this.prioritySelected;
		}

		if (this.prioritySelected === 'p1') {
			this.currentPriority = 'high';
		} else if (this.prioritySelected === 'p2') {
			this.currentPriority = 'med';
		} else {
			this.currentPriority = 'low';
		}
	}

	public setCustomerState(): void {
		if (this.stateSelected) {
			this.customer['status'] = this.stateSelected;
			if (this.stateSelected === 'open') {
				this.currentState = 'New';
			} else if (this.stateSelected === 'fixed') {
				this.currentState = 'Trial';
			} else if (this.stateSelected === 'closed') {
				this.currentState = 'Active';
			} else if (this.stateSelected === 'pool') {
				this.currentState = 'Inactive';
			} else {
				this.currentState = 'In progress';
			}
		}
	}

	public setMemberForCustomer(): void {
		if (this.customerSelected['user'] && this.customerSelected['user']['name']) {
			this.currentCustomer = this.customerSelected['user']['name'];
			this.customer['assignedUser'] = this.customerSelected['user']['username'];
		}
	}

	public onUnassignedForCustomer(): void {
		this.displayModalForCustomer = 'none';
		delete this.customer['assignedUser'];
		this.currentCustomer = this.translate.instant('Unassigned');
		this.customerSelected = '';
		this.selectedCustomerUid = '';
	}

	public setCustomerSales(): void {
		if (this.salesSelected['slug']) {
			if (this.salesSelected['slug'] !== 'none') {
				this.customer['salesChannel'] = this.salesSelected['slug'];
				this.currentSaleChannel = this.salesSelected['option'];
			} else {
				delete this.customer['salesChannel'];
				this.currentSaleChannel = '';
			}
		} else {
			this.currentSaleChannel = '';
		}
	}

	public setCustomerDealType(): void {
		if (this.dealSelected['slug']) {
			if (this.dealSelected['slug'] !== 'none') {
				this.customer['dealType'] = this.dealSelected['slug'];
				this.currentDealType = this.dealSelected['option'];
			} else {
				delete this.customer['dealType'];
				this.currentDealType = '';
			}
		} else {
			this.currentDealType = '';
		}
	}

	public setCustomerType(): void {
		if (this.typeSelected['slug']) {
			if (this.typeSelected['slug'] !== 'none') {
				this.customer['customerType'] = this.typeSelected['slug'];
				this.currentCustomerType = this.typeSelected['option'];
			} else {
				delete this.customer['customerType'];
				this.currentCustomerType = '';
			}
		} else {
			this.currentCustomerType = '';
		}
	}

	public setCustomerCurrency(): void {
		this.currentCurrencyType = this.currencySelected;
		this.customer['currency'] = this.currencySelected;
		this.customerPrice = '';
	}

	public setCityForCustomer(): void {
		if (this.postType === 'add-customer' && this.selectedCity['name']) {
			this.additionalContactForm.controls.city.setValue(this.selectedCity['name']);
			this.customer['city'] = this.selectedCity['name'];
			this.customer['cityUid'] = this.selectedCity['uid'];
		}
	}

	private clearCustomerDetails(): void {
		if (this.customerListState) {
			this.customer['status'] = this.customerListState;
			this.stateSelected = this.customerListState;
			this.setCustomerState();
		} else {
			this.customer['status'] = 'open';
			this.currentState = 'new';
			this.stateSelected = 'open';
		}

		if (this.modelTitle) {
			this.modelTitle.nativeElement.style.height = 'auto';
		}

		this.currentCustomer = this.currentUser['name'];
		this.customer['assignedUser'] = this.currentUser['username'];
		this.customer['priority'] = 'p1';
		this.prioritySelected = 'p1';
		this.currentPriority = 'high';

		this.customer['title'] = '';
		this.model.title = '';
		this.model.url = '';
		this.model.images = [];
		delete this.model.location;
		this.selectedMember['uid'] = '';
		this.attachmentMember = '';
		this.selectedCity['uid'] = '';
		this.selectedCity['name'] = this.translate.instant('Location');

		this.article = false;
		this.photo_upload = false;
		this.doc_upload = false;

		this.additionalContactForm.reset();
		this.isCustomerSubmitted = false;
		delete this.customer['attachmentType'];
		delete this.customer['location'];
		delete this.customer['url'];
		delete this.customer['images'];
		delete this.customer['products'];
		delete this.customer['dealSize'];
		delete this.customer['contactName'];
		delete this.customer['buildingNumber'];
		delete this.customer['street1'];
		delete this.customer['street2'];
		delete this.customer['cityUid'];
		delete this.customer['city'];
		delete this.customer['stateName'];
		delete this.customer['zipCode'];
		delete this.customer['countryCode'];
		delete this.customer['contactNumber'];
		delete this.customer['email'];
		delete this.customer['city'];
		delete this.customer['cityUid'];
		this.typeSelected = '';
		this.dealSelected = '';
		this.salesSelected = '';
		this.customerPrice = '';
		this.customerContactName = '';
		this.selectedCountry = '';
		this.currentSaleChannel = '';
		this.currentDealType = '';
		this.currentCustomerType = '';
		this.additionalContactForm.controls.code.setValue(90);
		this.currentCurrencyType = 'TRY';
		this.currencySelected = 'TRY';
		this.privacy.selectedProduct = [];
		this.privacy.products.forEach((value) => {
			value['checked'] = false;
		});
	}

	private callCreateCustomer(): void {
		this.isCustomerSubmitted = true;

		if (this.customer['attachmentType'] === 'doc') {
			this.customer['doc'] = this.customer['images'];
			delete this.model.images;
			delete this.customer['images'];
		}

		if (this.model['mentioned_users'] && this.model['mentioned_users'].length > 0) {
			const userList = [];
			for (const u of this.model['mentioned_users']) {
				if (this.model.title.indexOf('@' + u.name) > -1) {
					userList.push(u.username);
				}
			}
			this.customer['mentioned_users'] = [];
			this.customer['mentioned_users'] = userList.join(',');
		}
		if (this.model['hashtags'] && this.model['hashtags'].length > 0) {
			const tagList = [];
			for (const h of this.model['hashtags']) {
				if (this.model.title.indexOf('#' + h['name']) > -1) {
					tagList.push(h['name']);
				}
			}
			const re = /(?:^|\W)#(\w+)(?!\w)/g;
			let match;
			while ((match = re.exec(this.model.body))) {
				if (tagList.indexOf(match[1]) < 0) {
					tagList.push(match[1]);
				}
			}
			this.customer['hashtags'] = [];
			this.customer['hashtags'] = tagList.join(',');
		}

		if (this.customer['title']) {
			if (this.privacy.selectedProduct.length !== 0) {
				const productArray: any[] = [];
				this.privacy.selectedProduct.forEach((product) => {
					if (product) {
						productArray.push(product);
					}
				});
				this.customer['products'] = productArray.join(', ');
			}
			if (this.customerPrice) {
				this.customer['dealSize'] = this.customerPrice;
				this.customer['currency'] = this.currentCurrencyType;
			}
			if (this.customerContactName) {
				this.customer['contactName'] = this.customerContactName;
			}

			const contactDetails = this.additionalContactForm.value;
			if (contactDetails['houseNo']) {
				this.customer['buildingNumber'] = contactDetails['houseNo'];
			}
			if (contactDetails['streetOne']) {
				this.customer['street1'] = contactDetails['streetOne'];
			}
			if (contactDetails['streetTwo']) {
				this.customer['street2'] = contactDetails['streetTwo'];
			}
			if (contactDetails['zip']) {
				this.customer['zipCode'] = contactDetails['zip'];
			}
			if (contactDetails['phone']) {
				this.customer['contactNumber'] = contactDetails['phone'];
				if (!this.selectedCountry) {
					this.customer['countryCode'] = 'TR';
				}
			}
			if (contactDetails['email']) {
				this.customer['email'] = contactDetails['email'];
			}
			if (contactDetails['state']) {
				this.customer['stateName'] = contactDetails['state'];
			}
			this.handleCustomerHashtags(this.customer.title);
			this.pageLoadingUiService.setPageLoading(true);
			this.networkService.createCustomer(this.customer).subscribe(
				(data) => {
					this.newCustomerDetailAddedEmitter.emit(data);
					this.newPostEmitter.emit(new Post(data));
					this.pageLoadingUiService.setPageLoading(false);
					// this.showTaskForms =false;
					// this.layoutService.hideBd();
					// this.hidePlusBtn = false;
					this.activateForm(true);
					this.handleWelcomeTips();
					this.clearCustomerDetails();
				},
				() => {
					this.handleError();
					this.clearCustomerDetails();
				}
			);
		}
	}

	private handleCustomerHashtags(text): void {
		if (text.indexOf('#') > -1) {
			const words = text.split(' ');
			const hashes = [];
			words.forEach((element) => {
				if (element.indexOf('#') > -1) {
					const index = element.indexOf('#');
					if (index === 0) {
						const string = element.replace(/(^#)/g, '');
						if (hashes.indexOf(string) === -1) {
							hashes.push(string);
						}
					}
				}
			});
			if (hashes.length > 0) {
				this.customer['hashtags'] = hashes.join(',');
			}
		}
	}

	public displayCustomersForIssue(): void {
		if (!this.lockCustomer) {
			this.displayCustomers = 'block';
			this.query = [];
			this.customers = [];
			this.isCustomersFetching = 0;
			this.networkService.getCustomers().subscribe((data) => {
				this.isCustomersFetching = 1;
				if (data['objects'] && data['objects'].length > 0) {
					this.customers = data['objects'];
				}
				if (data.next) {
					this.nextCustomerUrl = data.next.split('alpha')[1];
					this.paginateCustomers();
				} else {
					this.nextCustomerUrl = '';
				}
			});
		}
	}

	public paginateCustomers(): void {
		if (this.nextCustomerUrl) {
			this.postService.paginate(this.nextCustomerUrl).subscribe((data) => {
				if (data) {
					this.customers = this.customers.concat(data['objects']);
					if (data.next) {
						this.nextCustomerUrl = data.next.split('alpha')[1];
					} else {
						this.nextCustomerUrl = '';
					}
				}
			});
		}
	}

	public setCustomerForIssue(): void {
		if (this.selectedCustomer) {
			this.currentIssueCustomer = this.selectedCustomer['title'];
		}
	}

	public setIssuePriority(): void {
		// eslint-disable-next-line spellcheck/spell-checker
		if (this.currentpriority) {
			// eslint-disable-next-line spellcheck/spell-checker
			this.selectedPriority = this.currentpriority;
		}
	}

	public paginateMentions(): void {
		if (this.nextMentionUrl) {
			this.postService.paginate(this.nextMentionUrl).subscribe((data) => {
				if (data) {
					if (this.mentionType === 'user') {
						this.userMentions = this.userMentions.concat(data['objects']);
					} else if (this.mentionType === 'hash') {
						this.hashMentions = this.hashMentions.concat(data['objects']);
					}
					if (data.next) {
						this.nextMentionUrl = data.next.split('alpha')[1];
					} else {
						this.nextMentionUrl = '';
					}
				}
			});
		}
	}

	private returnWord(text, caretPosition): string | null {
		let words = [];
		let preText = '';
		if (text) {
			preText = text.substring(0, caretPosition);
			if (preText.indexOf(' ') > 0) {
				words = preText.split(' ');
				return words[words.length - 1]; // return last word
			} else {
				return preText;
			}
		}
		return null;
	}

	private getCaretPosition(ctrl): number | null {
		let caretPosition = 0; // IE Support
		if (this.modelBody) {
			ctrl = this.modelBody.nativeElement;
			if (ctrl.selectionStart || ctrl.selectionStart === '0') {
				caretPosition = ctrl.selectionStart;
			}
			return caretPosition;
		}
		return null;
	}

	private getCaretPositionForSubject(ctrl): number | null {
		let caretPosition = 0; // IE Support
		if (this.modelTitle) {
			ctrl = this.modelTitle.nativeElement;
			if (ctrl.selectionStart || ctrl.selectionStart === '0') {
				caretPosition = ctrl.selectionStart;
			}
			return caretPosition;
		}
		return null;
	}

	/**
	 * For handling todolist privacy for user mentions list in task form.
	 * @param list
	 */
	private handleTodolistPrivacy(list): void {
		if (list['uid'] === localStorage.getItem('MyTodolist')) {
			const arr = '[' + this.currentUser['userId'] + ']';
			this.mentionPrivacy = 'custom_privacy=' + arr;
		} else {
			if (list['contentPrivacy']) {
				this.mentionPrivacy = 'content_privacy=' + JSON.stringify(list['contentPrivacy']);
			} else if (list['customPrivacy'] && list['customPrivacy'].length > 0) {
				const customPrivacy: any[] = [];
				// let custom: any;
				list.customPrivacy.forEach((element) => {
					if (element.userId) {
						customPrivacy.push(element.userId);
					} else {
						customPrivacy.push(element.id);
					}
				});
				const custom = '[' + customPrivacy.join(',') + ']';
				this.mentionPrivacy = 'custom_privacy=' + custom;
			} else if (list['channel'] && list['channel']['uid']) {
				this.mentionPrivacy = 'channel=' + list['channel']['uid'];
			} else {
				this.mentionPrivacy = '';
			}
		}
	}

	private checkPrivacyForMentions(): void {
		delete this.model.privacy;
		delete this.personalFilterString;
		delete this.selectByStatusString;
		delete this.selectByRenewalString;
		delete this.selectByLevelString;

		if (this.privacy.custom_privacy.length !== 0) {
			const customPrivacy: any[] = [];
			// let custom: any;
			this.privacy.custom_privacy.forEach((element) => {
				if (element.userId) {
					customPrivacy.push(element.userId);
					this.privacy.custom_privacy_display = false;
				} else if (element.user) {
					customPrivacy.push(element.user.userId);
				} else {
					customPrivacy.push(element.id);
				}
			});
			const custom = '[' + customPrivacy.join(',') + ']';
			this.mentionPrivacy = 'custom_privacy=' + custom;
		} else {
			if (this.privacy.selectedRole) {
				if (this.privacy.selectedRole.slug) {
					this.roleString = {
						role: this.privacy.selectedRole.slug,
					};
				}
				if (this.privacy.selectedRole.role) {
					const role = this.privacy.selectedRole.role.toLowerCase();
					this.roleString = {
						role: role,
					};
				}
			}
			if (this.privacy.personalFilter) {
				this.personalFilterString = {};
				const key = this.privacy.personalFilter.key;
				this.personalFilterString[key] = this.privacy.personalFilter.value;
			}
			if (this.privacy.selectByStatusValue) {
				this.selectByStatusString = {
					status: this.privacy.selectByStatusValue[0],
				};
			}
			if (this.privacy.selectByRenewalValue) {
				this.selectByRenewalString = {
					renewalStatus: this.privacy.selectByRenewalValue[0],
				};
			}
			if (this.privacy.selectByLevelValue) {
				this.selectByLevelString = {
					membership_level: this.privacy.selectByLevelValue.uid,
				};
			}
			if (this.privacy.selectedDepartment) {
				if (this.privacy.selectedDepartment.value) {
					this.departmentString = {
						department: this.privacy.selectedDepartment['value'],
					};
				}
				if (this.privacy.selectedDepartment.uid) {
					this.departmentString = {
						department: this.privacy.selectedDepartment['uid'],
					};
				}
			}

			if (!this.roleString && this.departmentString) {
				this.model.content_privacy = JSON.stringify(this.departmentString);
			} else if (this.roleString && !this.departmentString) {
				this.model.content_privacy = JSON.stringify(this.roleString);
			} else if (this.personalFilterString) {
				this.model.content_privacy = JSON.stringify(this.personalFilterString);
			} else if (this.selectByStatusString) {
				this.model.content_privacy = JSON.stringify(this.selectByStatusString);
			} else if (this.selectByRenewalString) {
				this.model.content_privacy = JSON.stringify(this.selectByRenewalString);
			} else if (this.selectByLevelString) {
				this.model.content_privacy = JSON.stringify(this.selectByLevelString);
			} else {
				delete this.model.content_privacy;
			}
			if (this.model.content_privacy) {
				this.mentionPrivacy = 'content_privacy=' + this.model.content_privacy;
			}
			if (this.model.channel) {
				this.mentionPrivacy = 'channel=' + this.model.channel;
			}
			this.roleString = null;
			this.departmentString = null;
			delete this.model.content_privacy;
			delete this.model.custom_privacy;
		}

		if (
			this.privacy.custom_privacy.length === 0 &&
			!this.privacy.selectedRole &&
			!this.privacy.personalFilter &&
			!this.privacy.selectedDepartment &&
			!this.privacy.channel &&
			!this.model.channel &&
			!this.privacy.selectByStatusValue &&
			!this.privacy.selectByRenewalValue &&
			!this.privacy.selectByLevelValue
		) {
			this.mentionPrivacy = '';
		}
	}

	public manageTextBox(e): void {
		this.isTitle = false;
		e.target.style.height = e.target.scrollHeight + 'px';
		this.errorMessage = '';
	}

	public autoSizeBox(e, isSubtask = false): void | boolean {
		if (isSubtask && (e?.key === 'Enter' || e?.keyCode === 13)) {
			e.preventDefault();
			return false;
		}
		e.target.style.cssText = 'padding: 5px; background: none; resize: none; font-size: 14px;height:auto';
		e.target.style.cssText = 'padding: 5px; background: none; resize: none; font-size: 14px;height:' + e.target.scrollHeight + 'px';
	}

	/**
	 * For resetting privacy values once projectForm is closed
	 */
	public resetPrivacyComponent(): void {
		this.privacy.custom_privacy = [];
		this.privacy.selectedRole = '';
		this.privacy.selectedDepartment = '';
		this.privacy.personalFilter = '';
		this.privacy.selectedChannel = '';
		this.privacy.selectByStatusValue = '';
		this.privacy.selectByRenewalValue = '';
		this.privacy.selectByLevelValue = '';
	}

	/**
	 * For loading cp in cache if user is normal or part of multiple external channels
	 */
	private loadCachedPrivacy(): void {
		if ((!this.isExternalUser && !this.multipleExternalUser) || (this.isExternalUser && this.multipleExternalUser)) {
			this.updateSelectionFields();
		}
	}

	/// ==============================================================
	public onKeyDown(event: KeyboardEvent, isSubtask = false): void | boolean {
		if (isSubtask && (event?.key === 'Enter' || event?.keyCode === 13)) {
			event.preventDefault();
			return false;
		}
		if (this.userMentions.length > 0 || this.hashMentions.length > 0) {
			switch (event.keyCode) {
				case 38:
					event.preventDefault();
					this.arrowKeyLocation--;
					if (this.arrowKeyLocation < 0) {
						this.panel.nativeElement.scrollTop = 0;
						this.arrowKeyLocation = 0;
					}

					if (this.userMentions.length > 0) {
						this.userMentions.forEach((val, index) => {
							if (index === this.arrowKeyLocation) {
								this.addHighlightClass(val);
								this.scrollIntoView(val);
							} else {
								this.removeHighlightClass(val);
							}
						});
					} else {
						this.hashMentions.forEach((val, index) => {
							if (index === this.arrowKeyLocation) {
								this.addHighlightClass(val);
								this.scrollIntoView(val);
							} else {
								this.removeHighlightClass(val);
							}
						});
					}
					break;

				case 40:
					event.preventDefault();
					this.arrowKeyLocation++;
					if (this.arrowKeyLocation > this.mentionCount - 1) {
						this.panel.nativeElement.scrollTop = 0;
						this.arrowKeyLocation = 0;
					}

					if (this.userMentions.length > 0) {
						this.userMentions.forEach((val, index) => {
							if (index === this.arrowKeyLocation) {
								this.addHighlightClass(val);
								this.scrollIntoView(val);
							} else {
								this.removeHighlightClass(val);
							}
						});
					} else {
						this.hashMentions.forEach((val, index) => {
							if (index === this.arrowKeyLocation) {
								this.addHighlightClass(val);
								this.scrollIntoView(val);
							} else {
								this.removeHighlightClass(val);
							}
						});
					}
					break;

				case 13:
					event.preventDefault();
					if (this.mentionType === 'user') {
						this.userMentions.forEach((element, index) => {
							if (index === this.arrowKeyLocation) {
								if (this.isSubtask) {
									this.onUserSelectForSubtask(element);
								} else {
									this.onUserSelect(element);
								}
							}
						});
					} else if (this.mentionType === 'hash') {
						this.hashMentions.forEach((element, index) => {
							if (index === this.arrowKeyLocation) {
								if (this.isSubtask) {
									this.onHashTagForSubtask(element);
								} else {
									this.onHashTagSelect(element);
								}
							}
						});
					}
			}
		}
	}

	public getMentionsInTitle(e): void {
		this.userRegexFailed = false;
		this.hashRegexFailed = false;
		const userMentionRegex = /(^|\s)@(\w*(?:\s*\w*))$/;
		const hashTagRegex = /(^|\s)#(\w*(?:\s*\w*))$/;
		let text;
		const caretPosition = this.getCaretPositionForSubject(this.model.title);
		const word = this.returnWord(this.model.title, caretPosition);

		if (word !== null) {
			if (userMentionRegex.test(word)) {
				// 3
				this.mentionType = 'user';
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					// this.mentionsFetched = 1;
					text = word.replace(/(^@)/g, '');
					if (this.mentionType === 'user') {
						if (text.length >= 3) {
							this.mentionsFetched = 1;
							this.subject.next(text); // 4
						} else {
							return;
						}
					}
				}
			} else {
				this.userRegexFailed = true;
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					// 5

					this.hashMentions = [];
					this.userMentions = [];
				}
			}
			if (word !== null && hashTagRegex.test(word)) {
				// 3
				this.mentionType = 'hash';
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					this.mentionsFetched = 1;
					text = word.replace(/(^#)/g, '');
					this.subject.next(text); // 4
				}
			} else {
				this.hashRegexFailed = true;

				if (e.keyCode !== 38 && e.keyCode !== 40) {
					// 5
					this.hashMentions = [];
					this.userMentions = [];
				}
			}
		} else {
			// 6

			if (!this.isMentions) {
				this.mentionsFetched = 0;
				this.userMentions = [];
				this.hashMentions = [];
			}
		}
	}

	public getMentionsInBody(e): void {
		this.userRegexFailed = false;
		this.hashRegexFailed = false;

		// auto input resize
		if (e.target.scrollHeight >= 115 && e.target.scrollHeight <= 500) {
			e.target.style.height = `${e.target.scrollHeight}px`;
		}

		const userMentionRegex = /(^|\s)@(\w*(?:\s*\w*))$/;
		const hashTagRegex = /(^|\s)#(\w*(?:\s*\w*))$/;
		const caretPosition = this.getCaretPosition(this.model.body);
		const word = this.returnWord(this.model.body, caretPosition);

		let text;

		if (word !== null) {
			if (userMentionRegex.test(word)) {
				// 3
				this.mentionType = 'user';
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					// this.mentionsFetched = 1;
					text = word.replace(/(^@)/g, '');

					if (this.mentionType === 'user') {
						if (text.length >= 3) {
							this.mentionsFetched = 1;
							this.subject.next(text); // 4
						} else {
							return;
						}
					}
				}
			} else {
				this.userRegexFailed = true;
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					// 5

					this.hashMentions = [];
					this.userMentions = [];
				}
			}
			if (word !== null && hashTagRegex.test(word)) {
				// 3
				this.mentionType = 'hash';
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					this.mentionsFetched = 1;
					text = word.replace(/(^#)/g, '');
					this.subject.next(text); // 4
				}
			} else {
				this.hashRegexFailed = true;

				if (e.keyCode !== 38 && e.keyCode !== 40) {
					// 5
					this.hashMentions = [];
					this.userMentions = [];
				}
			}
		} else {
			// 6

			if (!this.isMentions) {
				this.mentionsFetched = 0;
				this.userMentions = [];
				this.hashMentions = [];
			}
		}
	}

	/** For adding highlight class for mention elements
	 * 1. Clear all previous highlight class on elements.
	 * 2. Add mention highlight css class.
	 * @param val
	 */
	private addHighlightClass(val): void {
		this.clearHighLightClass(); // 1
		const element = document.getElementById(val.uid);
		element.classList.add(CSS_CLASS_NAMES.highLight); // 2
	}

	/** For removing highlight class for mention elements
	 * @param val
	 */
	private removeHighlightClass(val): void {
		const element = document.getElementById(val.uid);
		element.classList.remove(CSS_CLASS_NAMES.highLight);
	}

	/**
	 * For clearing highlight class from all mention elements
	 */
	private clearHighLightClass(): void {
		this.userMentions.forEach((val) => {
			if (val.uid) {
				const element = document.getElementById(val.uid);
				element.classList.remove(CSS_CLASS_NAMES.highLight);
			}
		});
		this.hashMentions.forEach((val) => {
			if (val.uid) {
				const element = document.getElementById(val.uid);
				element.classList.remove(CSS_CLASS_NAMES.highLight);
			}
		});
	}

	/**
	 * Scroll to current mention element
	 * 1. Get current element by its uid.
	 * 2. Get offset top of current element
	 * 3. Set topPosition as scroll top hence scroll to this position
	 * @param value
	 */
	private scrollIntoView(value): void {
		const elem = document.getElementById(value.uid);
		const topPosition = elem.offsetTop;
		this.panel.nativeElement.scrollTop = topPosition;
	}

	/**
	 * On mouse hover,add or remove hightlight class
	 * 1. Clear previous remanats of hightlight class
	 * @param event
	 */
	public onHover(event: MouseEvent): void {
		this.clearHighLightClass(); // 1
		const target = event.target as HTMLElement;
		// eslint-disable-next-line spellcheck/spell-checker
		if (event.type === 'mouseover') {
			target.classList.add(CSS_CLASS_NAMES.highLight);
		} else {
			target.classList.remove(CSS_CLASS_NAMES.highLight);
		}
	}

	/**
	 * Function for displaying @/# mentions.
	 * @param type
	 * 1. Set focus on title/description field on mentions select
	 * 2. Display mentions list based on type
	 */
	public displayMentions(type): void {
		// =======[08-06-21,Mention Fixes]
		this.userRegexFailed = false;
		this.hashRegexFailed = false;
		this.isMentions = true;
		// =======
		this.arrowKeyLocation = -1;
		this.shiftKeyUsed = false;

		if (this.titleFocus) {
			// 1
			this.modelTitle.nativeElement.focus();
		} else {
			if (this.modelBody) {
				this.modelBody.nativeElement.focus();
			}
		}
		if (type === 'user') {
			// 2
			this.mentionType = 'user';
			this.subject.next('');
		} else {
			this.mentionType = 'hash';
			this.subject.next('');
		}
	}

	private checkForCustomerSettings(network): void {
		if (network && network.privacySettings) {
			if (network.privacySettings['customer']['department']) {
				const fields = network['membership']['memberRole']['fields'];
				const arr = network.privacySettings['customer']['department'];
				if (arr.length > 0 && fields.length > 0) {
					arr.forEach(() => {
						fields.forEach((field) => {
							if (field['key'] === 'department') {
								const val = field['value'];
								const index = arr.findIndex((a) => a === val);
								if (index > -1) {
									this.addCustomer = true;
								} else {
									this.addCustomer = false;
								}
							}
						});
					});
				}
			}
		}
	}

	/**
	 * On selecting reminder options in task create form
	 * @param reminder
	 */
	public onSelectReminder(reminder): void {
		const index = this.remindersSelected.findIndex((r) => r === reminder);
		if (index > -1) {
			this.remindersSelected.splice(index, 1);
		} else {
			this.remindersSelected.push(reminder);
		}
	}

	/**
	 * For creating a new reminders for content
	 */
	public setReminder(todo, reminders): void {
		if (reminders.length > 0) {
			this.taskForReminder = todo;
			const object = {};
			reminders.forEach((reminder) => {
				if (reminder === 'due_time') {
					object['event_time'] = this.setReminderDate(reminder);
				} else {
					object[reminder] = this.setReminderDate(reminder);
				}
			});

			const param = JSON.stringify(object);
			this.reminderModel.contentType = 'todo';
			this.reminderModel.contentUid = this.taskForReminder.uid;
			this.reminderModel.username = this.currentUser.username;
			this.reminderModel.repeat = true;

			if (this.taskForReminder['startTime']) {
				delete this.reminderModel['reminderOption'];
				this.reminderModel['eventReminderOption'] = param;
			} else {
				// delete this.reminderModel['eventReminderOption'];
				this.reminderModel.reminderOption = param;
			}

			// return;
			this.pageLoadingUiService.setPageLoading(true);
			this.networkService.createReminder(this.reminderModel).subscribe(
				(data) => {
					this.pageLoadingUiService.setPageLoading(false);
					todo['myRelations']['reminder'] = data['uid'];
					this.resetReminders();
				},
				() => {
					this.handleError();
				}
			);
		} else {
			this.resetReminders();
		}
	}

	/**
	 * For calculating corresponding to reminder selected
	 * @param option
	 */
	public setReminderDate(option): string {
		if (option === 'in_twenty_minutes') {
			this.timeFor = 20;
		} else if (option === 'in_one_hour') {
			this.timeFor = 60;
		} else if (option === 'in_three_hours') {
			this.timeFor = 180;
		} else if (option === 'tomorrow') {
			this.timeFor = this.minutesUntilMidnight() + 540;
		} else if (option === 'next_week') {
			this.timeFor = 6300;
		} else if (option === 'due_time' || option === 'event_time') {
			this.timeFor = -1;
		} else if (option === 'five_minutes_before') {
			this.timeFor = 5;
		} else if (option === 'thirty_minutes_before') {
			this.timeFor = 30;
		} else if (option === 'one_hour_before') {
			this.timeFor = 60;
		} else if (option === 'two_hour_before') {
			this.timeFor = 120;
		}

		let date;

		if (this.taskForReminder['estimatedTime']) {
			if (this.timeFor === -1) {
				date = new Date(this.taskForReminder['estimatedTime']);
			} else {
				date = new Date(this.taskForReminder['estimatedTime']);
				date.setMinutes(date.getMinutes() - this.timeFor);
			}
		} else if (this.taskForReminder['startTime']) {
			if (this.timeFor === -1) {
				date = new Date(this.taskForReminder['startTime']);
			} else {
				date = new Date(this.taskForReminder['startTime']);
				date.setMinutes(date.getMinutes() - this.timeFor);
			}
		} else if (this.taskForReminder['oneOff']) {
			// date = new Date();
			// date.setMinutes(date.getMinutes() + this.timeFor);

			date = new Date(this.taskForReminder['oneOff']);
			date.setMinutes(date.getMinutes() + this.timeFor);
		} else {
			date = new Date();
			date.setMinutes(date.getMinutes() + this.timeFor);
		}

		const month = date.getUTCMonth() + 1;
		const day = date.getUTCDate();
		const year = date.getUTCFullYear();
		const hours = date.getUTCHours();
		const minutes = date.getUTCMinutes();
		const seconds = date.getUTCSeconds();
		const utcDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;

		return utcDate;
	}

	/**
	 * For resetting reminders in reminder options modal
	 */
	public resetReminders(): void {
		this.timeFor = 0;
		this.taskForReminder = '';
		this.remindersSelected = [];
		this.displayTaskReminders = 'none';
		delete this.reminderModel['eventReminderOption'];
		this.reminderModel = {
			contentType: '',
			contentUid: '',
			repeat: true,
			reminderOption: '',
			username: '',
		};
		this.taskReminders.forEach((r) => {
			r['checked'] = false;
		});
	}

	private onDone(): void {
		this.displayTaskReminders = 'none';
		this.onConfirm = true;
	}

	// setTaskDueTime() {
	//   if (this.taskDueTime) {
	//     this.taskReminders = this.eventReminders;
	//     this.resetReminders();
	//     if (this.taskDueTime['hour']) {
	//       if (this.taskDueTime['minute'] < 10) {
	//         let minutes = '0' + this.taskDueTime['minute'];
	//         this.currentDueTime = this.taskDueTime['hour'] + ':' + minutes;
	//       } else {
	//         this.currentDueTime = this.taskDueTime['hour'] + ':' + this.taskDueTime['minute'];
	//       }
	//     } else {
	//       this.currentDueTime = '';
	//       this.taskReminders = this.postReminders;
	//       this.resetReminders();
	//     }
	//   } else {
	//     this.currentDueTime = '';
	//     this.taskReminders = this.postReminders;
	//     this.resetReminders();
	//   }
	// }

	public refresh(): void {
		this.refreshProducts.emit();
	}

	// -------------Subtasks------------------------------------------------------------------------------
	public onAddSubtask(event = null): void {
		event?.preventDefault();
		if (this.subtasksIndexContainer.length === 0) {
			this.appendSubtask();
		} else if (this.subtasksIndexContainer.length > 0) {
			const index = this.subtasks.length - 1;
			if (this.subtasks[index]['title']) {
				this.appendSubtask();
			} else {
				return;
			}
		}
		this.dueDateComponent.reset();
	}

	private appendSubtask(): void {
		if (this.selectedRepeatOption['slug']) {
			const x = new Subtask();
			x['assignedUser'] = this.currentUser;

			if (this.selectedRepeatOption['option'] === 'Tomorrow') {
				x['repeatCycle'] = 'one_off';
				x['oneOff'] = this.setTomorrowDate();
			} else if (this.selectedRepeatOption['option'] === 'Specify Date') {
				x['repeatCycle'] = 'one_off';
				x['oneOff'] = this.selectedDate;
			} else {
				x['repeatCycle'] = this.selectedRepeatOption['slug'];
			}

			this.subtasks.push(x);
			this.subtasksIndexContainer.push(this.subtasks.length - 1);

			timer(50).subscribe(() => {
				document.getElementById('subtask-' + (this.subtasks.length - 1)?.toString())?.focus();
			});
		}
	}

	// eslint-disable-next-line spellcheck/spell-checker
	public displaySubTaskAssignees(task): void {
		if (this.lists['name'] && this.lists['uid'] !== localStorage.getItem('MyTodolist')) {
			this.currentSub = task;
			this.subAssignee = '';
			this.memberUid = '';
			// eslint-disable-next-line spellcheck/spell-checker
			this.displaySubassignee = 'block';

			if (task?.assignedUser) {
				this.memberUid = task?.assignedUser?.username;
			}
		}
	}

	public onSetAssigneeForSubtask(): void {
		// eslint-disable-next-line spellcheck/spell-checker
		this.displaySubassignee = 'none';
		if (this.subAssignee) {
			this.currentSub['assignedUser'] = this.subAssignee['user'];
		}
	}

	public setUnassignedForSubtask(): void {
		// eslint-disable-next-line spellcheck/spell-checker
		this.displaySubassignee = 'none';
		delete this.currentSub['assignedUser'];
	}

	public onRemoveSubtask(key): void {
		this.subtasks.splice(key, 1);
		this.subtasksIndexContainer.pop();
	}

	/**
	 * For setting due date for subtask
	 */
	public onSetDueDateForSubtask(): void {
		this.displaySubDueDateOptions = 'none';
		if (this.sub === 'tomorrow') {
			this.currentSub['repeatCycle'] = 'one_off';
			this.currentSub['oneOff'] = this.setTomorrowDate();
		} else if (this.sub === 'one_off') {
			this.currentSub['repeatCycle'] = 'one_off';
			this.currentSub['oneOff'] = this.parserFormatter.format(this.subtaskDate);
		} else {
			this.currentSub['repeatCycle'] = this.sub;
		}
	}

	/**
	 * Set tomorrow date on subtask
	 */
	private setTomorrowDate(): string {
		const today = new Date();
		const tomorrow = new Date(today);
		tomorrow.setDate(today.getDate() + 1);
		let dd: any;
		let mm: any;
		dd = tomorrow.getDate();
		mm = tomorrow.getMonth() + 1;
		const yyyy = tomorrow.getFullYear();
		if (dd < 10) {
			dd = '0' + dd;
		}
		if (mm < 10) {
			mm = '0' + mm;
		}
		return yyyy + '-' + mm + '-' + dd;
	}

	private onCreateSubtasks(data): void {
		const uid = data['folderDetails']['uid'];
		const arr = [];

		this.subtasks.forEach((task) => {
			if (task['title']) {
				if (data['project']) {
					task['project'] = data['project']['uid'];
				}
				this.checkForSubtaskMentions(task);
				arr.push(task);
			}
		});

		arr.forEach((task, index) => {
			task['parent_content_uid'] = data['uid'];
			task['parent_content_type'] = 'todo';

			if (task['assignedUser'] && task['assignedUser']['username']) {
				task['assignedUser'] = task['assignedUser']['username'];
			} else {
				delete task['assignedUser'];
			}

			this.pageLoadingUiService.setPageLoading(true);
			this.networkService.createToDoItem(task, uid, this.listPrivacy).subscribe(
				(res) => {
					this.setReminder(res, task['remindersSelected'] ? task['remindersSelected'] : []);
					if (this.subtasks.length - 1 === index) {
						this.pageLoadingUiService.setPageLoading(false);

						this.clearSubtasks();
					}
				},
				() => {
					this.pageLoadingUiService.setPageLoading(false);
				}
			);
		});
	}

	private checkForSubtaskMentions(task): void {
		if (task['mentioned_users'] && task['mentioned_users'].length > 0) {
			const userList = [];
			for (const u of task['mentioned_users']) {
				if (task['title'].indexOf('@' + u.name) > -1) {
					userList.push(u.username);
				}
			}
			task['mentioned_users'] = [];
			task['mentioned_users'] = userList.join(',');
		}
		if (task['hashtags'] && task['hashtags'].length > 0) {
			const tagList = [];
			for (const h of task['hashtags']) {
				if (task['title'].indexOf('#' + h['name']) > -1) {
					tagList.push(h['name']);
				}
			}
			task['hashtags'] = [];
			task['hashtags'] = tagList.join(',');
		}
	}

	public onImgError(event): void {
		event.target.src = 'assets/images/default_avatar.jpg';
	}

	public getMentionsForSubtasks(e, index): void | boolean {
		if (e?.key === 'Enter' || e?.keyCode === 13) {
			e.preventDefault();
			return false;
		}

		this.isSubtask = true;
		this.titleFocus = false;

		this.textArea.toArray().forEach((element, i) => {
			if (index === i) {
				this.native = element;
				this.index = index;
			}
		});

		this.userRegexFailed = false;
		this.hashRegexFailed = false;
		e.target.style.height = e.target.scrollHeight + 'px';
		const userMentionRegex = /(^|\s)@(\w*(?:\s*\w*))$/;
		const hashTagRegex = /(^|\s)#(\w*(?:\s*\w*))$/;
		let text;
		const caretPosition = this.getCaretPositionForSubtask(e.target.value);
		const word = this.returnWord(e.target.value, caretPosition);

		if (word !== null) {
			if (userMentionRegex.test(word)) {
				this.mentionType = 'user';
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					// this.mentionsFetched = 1;
					text = word.replace(/(^@)/g, '');
					if (this.mentionType === 'user') {
						if (text.length >= 3) {
							this.mentionsFetched = 1;
							this.subject.next(text); // 4
						} else {
							return;
						}
					}
				}
			} else {
				this.userRegexFailed = true;
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					this.hashMentions = [];
					this.userMentions = [];
				}
			}
			if (word !== null && hashTagRegex.test(word)) {
				this.mentionType = 'hash';
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					this.mentionsFetched = 1;
					text = word.replace(/(^#)/g, '');
					this.subject.next(text); // 4
				}
			} else {
				this.hashRegexFailed = true;
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					this.hashMentions = [];
					this.userMentions = [];
				}
			}
		} else {
			if (!this.isMentions) {
				this.mentionsFetched = 0;
				this.userMentions = [];
				this.hashMentions = [];
			}
		}
	}

	private getCaretPositionForSubtask(ctrl): number {
		let caretPosition = 0;
		ctrl = this.native.nativeElement;
		if (ctrl.selectionStart || ctrl.selectionStart === '0') {
			caretPosition = ctrl.selectionStart;
		}
		return caretPosition;
	}

	public onUserSelectForSubtask(user): void {
		this.isMentions = false;
		this.userMentions = [];
		this.hashMentions = [];
		const startPosition = this.native.nativeElement.selectionStart;
		const endPosition = this.native.nativeElement.selectionEnd;
		if (startPosition === endPosition) {
			const text = this.native.nativeElement.value.substring(0, startPosition);
			this.mentionIndex = text.lastIndexOf('@');

			if (this.mentionIndex > -1 && this.shiftKeyUsed) {
				const strippedText = this.native.nativeElement.value.substring(this.mentionIndex, startPosition);

				if (strippedText !== '@') {
					const reg = new RegExp(strippedText, 'g');
					const replacedTitle = this.subtasks[this.index]['title'].replace(reg, '@' + user.name + ' ');
					this.subtasks[this.index]['title'] = replacedTitle;
				} else {
					const firstPart = this.subtasks[this.index]['title'].substr(0, startPosition);
					const lastPart = this.subtasks[this.index]['title'].substr(startPosition + 1);
					const newString = firstPart + user.name + ' ' + lastPart;
					this.subtasks[this.index]['title'] = newString;
				}
			} else {
				const newTitle = <''>(
					`${this.native.nativeElement.value.substring(0, startPosition)}@${user.name + ' '}${this.native.nativeElement.value.substring(endPosition)}`.toString()
				);
				this.subtasks[this.index]['title'] = newTitle;
				this.native.nativeElement.focus();
			}
		}

		if (this.subtasks[this.index]['mentioned_users']) {
			this.subtasks[this.index]['mentioned_users'].push(user);
		} else {
			this.subtasks[this.index]['mentioned_users'] = [];
			this.subtasks[this.index]['mentioned_users'].push(user);
		}
	}

	public onHashTagForSubtask(tag): void {
		this.isMentions = false;
		this.userMentions = [];
		this.hashMentions = [];

		const startPosition = this.native.nativeElement.selectionStart;
		const endPosition = this.native.nativeElement.selectionEnd;
		if (startPosition === endPosition) {
			const text = this.native.nativeElement.value.substring(0, startPosition);
			this.mentionIndex = text.lastIndexOf('#');

			if (this.mentionIndex > -1 && this.shiftKeyUsed) {
				const strippedText = this.native.nativeElement.value.substring(this.mentionIndex, startPosition);
				if (strippedText !== '#') {
					const reg = new RegExp(strippedText, 'g');
					const replacedTitle = this.subtasks[this.index]['title'].replace(reg, '#' + tag['name'] + ' ');
					this.subtasks[this.index]['title'] = replacedTitle;
				} else {
					const firstPart = this.subtasks[this.index]['title'].substr(0, startPosition);
					const lastPart = this.subtasks[this.index]['title'].substr(startPosition + 1);
					const newString = firstPart + tag['name'] + ' ' + lastPart;
					this.subtasks[this.index]['title'] = newString;
				}
			} else {
				const newTitle = <''>(
					`${this.native.nativeElement.value.substring(0, startPosition)}#${tag['name'] + ' '}${this.native.nativeElement.value.substring(endPosition)}`.toString()
				);
				this.subtasks[this.index]['title'] = newTitle;
				this.native.nativeElement.focus();
			}
		}

		if (this.subtasks[this.index]['hashtags']) {
			this.subtasks[this.index]['hashtags'].push(tag);
		} else {
			this.subtasks[this.index]['hashtags'] = [];
			this.subtasks[this.index]['hashtags'].push(tag);
		}
	}

	private clearSubtasks(): void {
		this.isSubtask = false;
		this.index = '';
		this.native = '';
		this.subtasks = [];
		this.subtasksIndexContainer = [];
		this.subtaskDate = {
			year: new Date().getFullYear(),
			month: new Date().getMonth() + 1,
			day: new Date().getDate(),
		};
	}

	public getSubtasks(todo): void {
		this.isShimming = 0;
		todo['subtasks'] = [];

		const status = this.todoAction === 'Start Day' ? 'open' : 'closed';

		this.networkService.getSubtasks(todo['uid'], todo['totalSubTasks'], status, '').subscribe((data) => {
			todo['subtasks'] = data.objects;
			this.isShimming = 1;
			this.checkUserMentionAndHashtagForSubtasks(todo);
		});
	}

	private checkUserMentionAndHashtagForSubtasks(todo): void {
		todo['subtasks'].forEach((task) => {
			task['altered_title'] = task['title'];
			if (task['mentionedUsers']) {
				task['mentionedUsers'].forEach((mentionedUser) => {
					const html = '<b #userMention><a class="text-green">@' + mentionedUser['name'] + '</a></b>';
					if (task.altered_title) {
						task['altered_title'] = task.altered_title.replace('@' + mentionedUser['name'], html);
					}
				});
			}
			if (task['hashtags']) {
				task['hashtags'].forEach((hashtag) => {
					if (hashtag['name']) {
						const html = '<b #hashTag><a class="hash-todo">#' + hashtag['name'] + '</a></b>';
						if (task.altered_title) {
							task['altered_title'] = task.altered_title.replace('#' + hashtag['name'], html);
						}
					}
				});
			}
		});
	}

	private handleError(): void {
		this.pageLoadingUiService.setPageLoading(false);
		this.displayError = 'block';
		if (localStorage.getItem('status')) {
			const x = JSON.parse(localStorage.getItem('status'));
			if (x['detail']) {
				this.error['detail'] = x['detail'];
			} else {
				this.error['detail'] = this.translate.instant('Something went wrong');
			}
			if (x['status']) {
				this.error['status'] = x['status'];
			}
		}
	}

	public setSubject(e): void {
		this.isCustomersFetching = 0;
		const text = e.target.value;
		this.customerSubject.next(text);
	}

	public close(): void {
		localStorage.removeItem('status');
		this.displayError = 'none';
		this.error = {
			detail: this.translate.instant('Something went wrong'),
			status: '',
		};
	}

	/**
	 * For checking if product is selected in privacy comp in issue create form.
	 */
	public checkIfProducts(): void {
		if (this.privacy.selectedProduct.length > 0) {
			this.isProduct = false;
		} else {
			this.isProduct = true;
		}
	}

	private getEstimatedTime(): void {
		this.todos.forEach((item) => {
			if (item.estimatedTime) {
				const localTime = new Date(item.estimatedTime).toLocaleString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: false });
				const d = new Date(item.estimatedTime);
				const currentDate = new Date();
				if (currentDate > d) {
				} else {
					// const sliced = localTime.slice(localTime.lastIndexOf(',') + 1, localTime.lastIndexOf(':'));
					// item['localEstimatedTime'] = sliced;
					item['localEstimatedTime'] = localTime;
				}
			}

			if (item.startTime) {
				const localTime = new Date(item.startTime).toLocaleString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: false });
				const d = new Date(item.startTime);
				const currentDate = new Date();
				if (currentDate > d) {
				} else {
					// const sliced = localTime.slice(localTime.lastIndexOf(',') + 1, localTime.lastIndexOf(':'));
					// item['localStartTime'] = sliced;
					item['localStartTime'] = localTime;
				}
			}

			if (item.dueTime && item.dueTime !== '0') {
				item['dueTimeExists'] = true;
			} else if (!item.dueTime || item.dueTime === '0') {
				if (item.estimatedTime) {
					const d = new Date(item.estimatedTime);
					const currentDate = new Date();
					if (currentDate > d) {
						item['dueTimeExists'] = true;
					} else {
						item['dueTimeExists'] = false;
					}
				} else {
					item['dueTimeExists'] = false;
				}
			}
		});
	}

	private showOneOffDateForTodos(): void {
		this.todos.forEach((item) => {
			if (item.repeatCycle === 'one_off' && item.oneOff) {
				const newDate = new Date(item.oneOff).toLocaleString();
				const month = new Date(item.oneOff).toLocaleString('default', {
					month: 'short',
				});
				const date = newDate.substring(0, newDate.indexOf('/'));
				const taskDate = date + '/' + month;
				item['newDate'] = taskDate;

				const date2 = new Date(item['oneOff']);
				item['oneOffDay'] = date2.getDate();
				const month2 = date2.toLocaleString('default', {
					month: 'long',
				});
				item['oneOffMonth'] = month2.substring(0, 3);
			}
		});
	}

	private checkUserMentionAndHashtagAndAlterBody(): void {
		this.todos.forEach((todo) => {
			if (todo['title'] && todo['title'].indexOf('<') > -1) {
				// 1
				todo['title'] = todo['title'].replace(/</g, '< ');
			}
			todo['altered_title'] = todo['title'];
			if (todo['mentionedUsers']) {
				todo['mentionedUsers'].forEach((mentionedUser) => {
					const html = '<b #userMention><a class="text-green">@' + mentionedUser['name'] + '</a></b>';
					if (todo.altered_title) {
						todo['altered_title'] = todo.altered_title.replace('@' + mentionedUser['name'], html);
					}
				});
			}
			if (todo['hashtags']) {
				todo['hashtags'].forEach((hashtag) => {
					if (hashtag['name']) {
						const html = '<b #hashTag><a class="hash-todo" >#' + hashtag['name'] + '</a></b>';
						if (todo.altered_title) {
							todo['altered_title'] = todo.altered_title.replace('#' + hashtag['name'], html);
						}
					}
				});
			}
		});
	}

	public closeStartModal(): void {
		this.displayStartEndModal = 'none';
		this.hidePlusBtn = false;
		this.networkService.todoAction = '';
	}

	public setTypeForIssue(): void {
		if (this.currentType) {
			this.selectedType = this.currentType;
		}
	}

	/**
	 * Function for displaying dueDate popup in DueDateComponent
	 */
	public showDueDateComponent(): void {
		this.taskType = 'task';
		// const today = new Date();
		// this.dueDateComponent.currentTime = today.getHours() + ':' + today.getMinutes();

		if (this.dueDateComponent) {
			this.dueDateComponent.display();
			this.dueDateComponent.selectedReminders = [];
			if (this.todoItem['remindersSelected']) {
				this.dueDateComponent.selectedReminders = this.todoItem['remindersSelected'];
			} else {
				if (this.todoItem['myRelations'] && this.todoItem['myRelations'].reminder) {
					this.pageLoadingUiService.setPageLoading(true);
					this.dueDateComponent.getContentReminders(this.todoItem);
				}
			}
			if (this.todoItem['repeatFreequency']) {
				// Resetting repeat frequency
				this.dueDateComponent.selectedRepeat = this.todoItem['repeatFreequency'];
			} else {
				this.dueDateComponent.selectedRepeat = '';
			}

			if (this.todoItem['startTime'] && this.todoItem['fromTime']) {
				this.dueDateComponent.startTime = this.todoItem['fromTime'];
			} else if (this.todoItem['startTime'] && !this.todoItem['fromTime']) {
				const a = this.todoItem['startTime'];
				const b = new Date(a);
				this.dueDateComponent.startTime = b.getHours() + ':' + (b.getMinutes() > 9 ? b.getMinutes() : '0' + b.getMinutes());
			} else {
				this.dueDateComponent.startTime = null;
			}

			if (this.todoItem['estimatedTime'] && this.todoItem['toTime']) {
				this.dueDateComponent.endTime = this.todoItem['toTime'];
			} else if (this.todoItem['estimatedTime'] && !this.todoItem['toTime']) {
				const a = this.todoItem['estimatedTime'];
				const b = new Date(a);
				this.dueDateComponent.endTime = b.getHours() + ':' + (b.getMinutes() > 9 ? b.getMinutes() : '0' + b.getMinutes());
			} else {
				this.dueDateComponent.endTime = null;
			}

			if (this.todoItem['selectedDueDate']) {
				this.dueDateComponent.selectedDueDate = this.todoItem['selectedDueDate'];
			} else {
				this.dueDateComponent.selectedDueDate = 'daily';
			}

			if (this.todoItem.repeatCycle === 'one_off') {
				this.dueDateComponent.showPreviouslySelectedDate(new Date(this.todoItem['oneOff']));
			}
		}
	}

	/**
	 * For showing due date options for a subtask
	 * @param task
	 */
	public displaySubtaskDueDate(task): void {
		this.taskType = 'subTask';
		this.currentSub = task;
		this.dueDateComponent.display();
		// this.displaySubDueDateOptions = 'block';
		// if (this.currentSub['repeatCycle'] === 'one_off') {
		//   if (this.currentSub['oneOff'] === this.setTomorrowDate()) {
		//     this.sub = 'tomorrow';
		//     this.subtaskDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
		//   } else {
		//     this.sub = 'one_off';
		//     var date = new Date(this.currentSub['oneOff']);
		//     var month = date.getMonth() + 1;
		//     var day = date.getDate();
		//     var year = date.getFullYear();
		//     this.subtaskDate = { year: year, month: month, day: day };
		//   }
		// } else {
		//   this.sub = this.currentSub['repeatCycle'];
		// }

		if (this.dueDateComponent) {
			this.dueDateComponent.display();
			this.dueDateComponent.selectedReminders = [];
			if (this.currentSub.remindersSelected) {
				this.dueDateComponent.selectedReminders = this.currentSub.remindersSelected;
			} else {
				if (task.myRelations && task.myRelations.reminder) {
					this.pageLoadingUiService.setPageLoading(true);
					this.dueDateComponent.getContentReminders(task);
				}
			}
			if (task.repeatFreequency) {
				// Resetting repeat frequency
				this.dueDateComponent.selectedRepeat = task.repeatFreequency;
			} else {
				this.dueDateComponent.selectedRepeat = '';
			}

			if (task.startTime && task.fromTime) {
				this.dueDateComponent.startTime = task.fromTime;
			} else if (task.startTime && !task.fromTime) {
				const a = task.startTime;
				const b = new Date(a);
				this.dueDateComponent.startTime = b.getHours() + ':' + (b.getMinutes() > 9 ? b.getMinutes() : '0' + b.getMinutes());
			} else {
				this.dueDateComponent.startTime = null;
			}

			if (task.estimatedTime && task.toTime) {
				this.dueDateComponent.endTime = task.toTime;
			} else if (task.estimatedTime && !task.toTime) {
				const a = task.estimatedTime;
				const b = new Date(a);
				this.dueDateComponent.endTime = b.getHours() + ':' + (b.getMinutes() > 9 ? b.getMinutes() : '0' + b.getMinutes());
			} else {
				this.dueDateComponent.endTime = null;
			}

			if (task.repeatCycle) {
				this.dueDateComponent.selectedDueDate = task.repeatCycle;
			} else {
				this.dueDateComponent.selectedDueDate = 'daily';
			}

			if (task.repeatCycle === 'one_off') {
				this.dueDateComponent.showPreviouslySelectedDate(new Date(task.oneOff));
			}
		}
	}

	/**
	 * Set startTime / endTime / repeat.. etc on todo.
	 */
	private setTodoOptions(): void {
		if (this.taskType === 'task') {
			this.todoItem['selectedDueDate'] = this.dueDateComponent['selectedDueDate'];
			if (this.dueDateComponent['selectedDueDate'] !== 'tomorrow') {
				this.todoItem['repeatCycle'] = this.dueDateComponent['selectedDueDate'];
			} else {
				this.todoItem['repeatCycle'] = 'one_off';
			}

			if (this.dueDateComponent['oneOff']) {
				this.todoItem['oneOff'] = this.dueDateComponent['oneOff'];
			}

			this.todoItem['startTime'] = this.dueDateComponent['startTime'] ? this.setUtcDate(this.dueDateComponent['startTime']) : null;
			this.todoItem['estimatedTime'] = this.dueDateComponent['endTime'] ? this.setUtcDate(this.dueDateComponent['endTime']) : null;

			this.todoItem['repeatFreequency'] = this.dueDateComponent['selectedRepeat'] ? this.dueDateComponent['selectedRepeat'] : null;
			this.todoItem['remindersSelected'] = this.dueDateComponent['selectedReminders'] ? this.dueDateComponent['selectedReminders'] : [];

			this.todoItem['fromTime'] = this.dueDateComponent['startTime'] ? this.dueDateComponent['startTime'] : null;
			this.todoItem['toTime'] = this.dueDateComponent['endTime'] ? this.dueDateComponent['endTime'] : null;
		} else {
			if (this.dueDateComponent['selectedDueDate'] !== 'tomorrow') {
				this.currentSub['repeatCycle'] = this.dueDateComponent['selectedDueDate'];
			} else {
				this.currentSub['repeatCycle'] = 'one_off';
			}
			if (this.dueDateComponent['oneOff']) {
				this.currentSub['oneOff'] = this.dueDateComponent['oneOff'];
			}

			this.currentSub['startTime'] = this.dueDateComponent['startTime'] ? this.setUtcDate(this.dueDateComponent['startTime']) : null;
			this.currentSub['fromTime'] = this.dueDateComponent['startTime'] ? this.dueDateComponent['startTime'] : null;

			this.currentSub['estimatedTime'] = this.dueDateComponent['endTime'] ? this.setUtcDate(this.dueDateComponent['endTime']) : null;
			this.currentSub['toTime'] = this.dueDateComponent['endTime'] ? this.dueDateComponent['endTime'] : null;

			this.currentSub['repeatFreequency'] = this.dueDateComponent['selectedRepeat'] ? this.dueDateComponent['selectedRepeat'] : null;
			this.currentSub['remindersSelected'] = this.dueDateComponent['selectedReminders'] ? this.dueDateComponent['selectedReminders'] : [];
		}
	}

	/**
	 * Function for setting utc date corresponding to oneOff date in todos
	 * @param time
	 */
	private setUtcDate(time): string {
		let d;
		if (this.dueDateComponent['selectedDueDate'] === 'daily') {
			const today = new Date().toISOString().slice(0, 10) + '';
			d = today + ' ' + time;
		} else {
			d = this.dueDateComponent['oneOff'] + ' ' + time;
		}
		const date = new Date(d);
		const startMonth = date.getUTCMonth() + 1;
		const startDay = date.getUTCDate();
		const startYear = date.getUTCFullYear();
		const startHours = date.getUTCHours();
		const startMinutes = date.getUTCMinutes();
		const startSeconds = date.getUTCSeconds();
		const utcDate = startYear + '-' + startMonth + '-' + startDay + ' ' + startHours + ':' + startMinutes + ':' + startSeconds;
		return utcDate;
	}

	/**
	 * Function to show the due date
	 */
	public showDueDateInDetail(task): string {
		if (task['selectedDueDate'] === 'daily') {
			return 'Today';
		} else if (task['selectedDueDate'] === 'weekly') {
			return 'This week';
		} else if (task['selectedDueDate'] === 'monthly') {
			return 'This month';
		} else if (task['selectedDueDate'] === 'tomorrow') {
			return 'Tomorrow';
		} else if (task['selectedDueDate'] === 'one_off') {
			return moment(task['oneOff']).format('yyyy-MM-DD');
		}
	}

	/**
	 * Pass attachment details on todo for done/undone or move actions
	 * @param post
	 */
	private updateAttachmentDetails(todo): void {
		delete this.todoItem['doc'];
		delete this.todoItem['url'];
		delete this.todoItem['member'];
		delete this.todoItem['attachmentType'];
		delete this.todoItem['images'];

		if (todo.attachmentDetails.gallery && todo.attachmentDetails.gallery.length > 0) {
			this.todoItem['images'] = [];
			const images: any[] = [];
			todo.attachmentDetails.gallery.forEach((img) => {
				this.todoItem['attachmentType'] = 'photo';
				images.push(img.image.uid);
			});
			this.todoItem['images'] = images.join('|');
		}
		if (todo.attachmentDetails.location && todo.attachmentDetails.location.length > 0) {
			this.todoItem['attachmentType'] = 'location';
			this.todoItem['location'] = todo.attachmentDetails.location[0].uid;
		}
		if (todo.attachmentDetails.doc && todo.attachmentDetails.doc[0].uid) {
			this.todoItem['attachmentType'] = 'doc';
			this.todoItem['doc'] = todo.attachmentDetails.doc[0].uid;
		}
		if (todo.attachmentDetails.url && todo.attachmentDetails.url[0].url) {
			this.todoItem['attachmentType'] = 'url';
			this.todoItem['url'] = todo.attachmentDetails.url[0].url;
		}
		if (todo.attachmentDetails.member && todo.attachmentDetails.member['uid']) {
			this.todoItem['attachmentType'] = 'member';
			this.todoItem['member'] = todo.attachmentDetails.member['uid'];
		}
	}

	public elapsedTimeCollected(data: { hour: number; minutes: number }): void {
		if (data) {
			let elapsedTime: number;
			if (data.minutes > 0) {
				const minutes = data.minutes / 60;
				elapsedTime = data.hour + minutes;
			} else {
				elapsedTime = data.hour;
			}
			this.elapsedForm.get('time').setValue(elapsedTime);
			this.showButtonLoader = true;
			this.manageTaskClose();
		}
	}

	/**
	 * Function to close the task from end day modal
	 */
	public manageTaskClose(): void {
		this.elapsedForm.markAllAsTouched();
		if (this.elapsedForm.valid) {
			this.pageLoadingUiService.setPageLoading(true);
			this.displayElapsed = 'none';
			this.updateAttachmentDetails(this.selectedTodo);

			const todoItem = {};
			todoItem['title'] = this.selectedTodo.title;
			todoItem['status'] = 'closed';
			todoItem['priority'] = this.selectedTodo.priority;
			todoItem['repeatCycle'] = this.selectedTodo.repeatCycle;

			if (this.selectedTodo.assignedUser && this.selectedTodo.assignedUser.username) {
				todoItem['assignedUser'] = this.selectedTodo.assignedUser.username;
			}

			if (this.selectedTodo.repeatCycle === 'one_off') {
				const oneOffDate = this.selectedTodo.oneOff;
				const splicedDate = oneOffDate.split(' ')[0];
				todoItem['oneOff'] = splicedDate;
			}

			if (this.selectedTodo.repeatFreequency) {
				todoItem['repeatFreequency'] = this.selectedTodo.repeatFreequency;
			}

			if (this.selectedTodo['project'] && this.selectedTodo['project']['uid']) {
				todoItem['project'] = this.selectedTodo['project']['uid'];
			}

			if (this.selectedTodo.numberOfOpens && this.selectedTodo.numberOfOpens > 0) {
				todoItem['numberOfOpens'] = this.selectedTodo.numberOfOpens;
			}

			if (this.elapsedForm.valid) {
				todoItem['elapsed_time'] = this.elapsedForm.value['time'];
			}

			const d = new Date().toISOString().slice(0, 10);
			const date = new Date();
			const hours = date.getUTCHours();
			const minutes = date.getUTCMinutes();
			const seconds = date.getUTCSeconds();
			const utcDateTime = d + ' ' + hours + ':' + minutes + ':' + seconds;
			todoItem['closedDate'] = utcDateTime;

			if (this.reaction) {
				todoItem['outcome'] = this.reaction;
			}
			this.networkService.updateToDoItem(todoItem, this.selectedTodo.uid).subscribe((data) => {
				if (data.status === 'closed') {
					this.showButtonLoader = false;
					this.projectService.taskCompleted.next(data);
					this.mixPanelService.track('Completed Task', {
						workspace: this.network?.name || 'NA',
						appVersion: environment.version,
						language: this.translate?.currentLang,
						username: this.currentUser?.username || 'NA',
						name: this.currentUser?.name || 'NA',
						project: data?.project?.name || 'NA',
						date: moment().format('YYYY-MM-DD'),
					});

					this.segmentService.trackEvent('Completed Task', {
						workspace: this.network?.name || 'NA',
						appVersion: environment.version,
						language: this.translate?.currentLang,
						username: this.currentUser?.username || 'NA',
						name: this.currentUser?.name || 'NA',
						project: data?.project?.name || 'NA',
						date: moment().format('YYYY-MM-DD'),
					});
				}
				this.pageLoadingUiService.setPageLoading(false);
				this.getStartDayUserCounts();
				if (this.incompleteTodos.length) {
					const index = this.incompleteTodos.indexOf((li) => li.uid === this.selectedTodo.uid);
					this.incompleteTodos.splice(index, 1);
				}
				this.selectedTasks.push(this.selectedTodo);
				this.reaction = '';
				this.selectedTodo['outcome'] = data.outcome;
				this.selectedTodo['status'] = 'closed';
				this.selectedTodo['elapsedTime'] = data.elapsedTime;
				// return
				this.createAutoComments(data);
				// this.createAutoCommentsForTask(data);
			});
		}
	}

	public reactionCollected(data: { reaction: string }): void {
		this.reaction = data.reaction;
		this.onConfirmReaction();
		// this.toggleOverlay();
	}
	/**
	 * Close task after reaction selection
	 */
	public onConfirmReaction(): void {
		if (this.reaction) {
			this.pageLoadingUiService.setPageLoading(true);
			this.displayElapsed = 'none';
			this.taskReactionDialog = 'none';
			this.updateAttachmentDetails(this.selectedTodo);
			const todoItem = {};

			todoItem['title'] = this.selectedTodo.title;
			todoItem['status'] = 'closed';
			todoItem['priority'] = this.selectedTodo.priority;
			todoItem['repeatCycle'] = this.selectedTodo.repeatCycle;

			if (this.selectedTodo.assignedUser && this.selectedTodo.assignedUser.username) {
				todoItem['assignedUser'] = this.selectedTodo.assignedUser.username;
			}

			if (this.selectedTodo.repeatCycle === 'one_off') {
				const oneOffDate = this.selectedTodo.oneOff;
				const splicedDate = oneOffDate.split(' ')[0];
				todoItem['oneOff'] = splicedDate;
			}

			if (this.selectedTodo.repeatFreequency) {
				todoItem['repeatFreequency'] = this.selectedTodo.repeatFreequency;
			}

			if (this.selectedTodo['project'] && this.selectedTodo['project']['uid']) {
				todoItem['project'] = this.selectedTodo['project']['uid'];
			}

			if (this.selectedTodo.numberOfOpens && this.selectedTodo.numberOfOpens > 0) {
				todoItem['numberOfOpens'] = this.selectedTodo.numberOfOpens;
			}

			const d = new Date().toISOString().slice(0, 10);
			const date = new Date();
			const hours = date.getUTCHours();
			const minutes = date.getUTCMinutes();
			const seconds = date.getUTCSeconds();
			const utcDateTime = d + ' ' + hours + ':' + minutes + ':' + seconds;
			todoItem['closedDate'] = utcDateTime;

			if (this.reaction) {
				todoItem['outcome'] = this.reaction;
			}

			this.networkService.updateToDoItem(todoItem, this.selectedTodo.uid).subscribe((data) => {
				if (data.status === 'closed') {
					this.mixPanelService.track('Completed Task', {
						workspace: this.network?.name || 'NA',
						appVersion: environment.version,
						language: this.translate?.currentLang,
						username: this.currentUser?.username || 'NA',
						name: this.currentUser?.name || 'NA',
						project: data?.project?.name || 'NA',
						date: moment().format('YYYY-MM-DD'),
					});

					this.segmentService.trackEvent('Completed Task', {
						workspace: this.network?.name || 'NA',
						appVersion: environment.version,
						language: this.translate?.currentLang,
						username: this.currentUser?.username || 'NA',
						name: this.currentUser?.name || 'NA',
						project: data?.project?.name || 'NA',
						date: moment().format('YYYY-MM-DD'),
					});
				}
				this.pageLoadingUiService.setPageLoading(false);
				this.getStartDayUserCounts();
				if (this.incompleteTodos.length) {
					const index = this.incompleteTodos.indexOf((li) => li.uid === this.selectedTodo.uid);
					this.incompleteTodos.splice(index, 1);
				}
				this.selectedTasks.push(this.selectedTodo);
				this.reaction = '';
				if (this.selectedTodo['status'] !== 'closed') {
					this.createAutoComments(data);
				}
				this.selectedTodo['status'] = 'closed';
				this.selectedTodo['outcome'] = data.outcome;
				this.selectedTodo['elapsedTime'] = data.elapsedTime;
				// return
				// this.createAutoComments(data);
				// this.createAutoCommentsForTask(data);
			});
		}
	}

	private resetEndDayTaskDetail(): void {
		this.selectedTodo['checked'] = false;
		this.selectedTodo = '';
		this.reaction = '';
		this.displayElapsed = 'none';
		this.taskReactionDialog = 'none';
		this.parentTask = '';
		this.elapsedForm.reset();
	}

	/** \
	 * THis will create comments when a todo is open/closed/paused etc..
	 */
	private createAutoComments(todo): void {
		const comment = {};
		if (todo['postUid']) {
			comment['contentType'] = 'post';
			comment['contentUid'] = todo['postUid'];
		} else {
			comment['contentType'] = '';
			comment['contentUid'] = '';
		}
		// comment['text'] = this.translate.instant('task-completion', {
		// 	title: todo['title'],
		// 	time: todo['elapsedTime'],
		// });

		const hour = Math.floor(todo.elapsedTime);
		const fraction = todo.elapsedTime - hour;
		// let time;
		let minutes;
		if (fraction > 0) {
			minutes = Math.ceil(fraction * 60);

			if (hour === 0) {
				comment['text'] = this.translate.instant('task-completion-minutes', {
					title: todo.title,
					minutes: minutes,
				});
			} else if (hour === 1) {
				comment['text'] = this.translate.instant('task-completion-hour-singular-minutes', {
					title: todo.title,
					hour: hour,
					minutes: minutes,
				});
			} else {
				comment['text'] = this.translate.instant('task-completion-hour-plural-minutes', {
					title: todo.title,
					hour: hour,
					minutes: minutes,
				});
			}
		} else {
			if (hour === 1) {
				comment['text'] = this.translate.instant('task-completion-hour-singular', {
					title: todo.title,
					hour: hour,
				});
			} else {
				comment['text'] = this.translate.instant('task-completion', {
					title: todo.title,
					time: hour,
				});
			}
		}

		if (comment['contentType'] && comment['contentUid']) {
			this.commentService.create(comment, this.currentUser.username).subscribe(
				() => {},
				() => {}
			);
		}
	}

	/**
	 * Create auto comment for subtasks actions like start/pause/finish etc.
	 * @param status
	 * @param todo
	 */
	private createAutoCommentsForTask(task): void {
		const comment = {};
		comment['contentType'] = 'todo';
		comment['contentUid'] = task['uid'];

		comment['text'] = this.translate.instant('task-completion', {
			title: task['title'],
			time: task['elapsedTime'],
		});

		if (comment['contentType'] && comment['contentUid']) {
			this.commentService.create(comment, this.currentUser.username).subscribe(
				() => {},
				() => {}
			);
		}
	}

	/**
	 * Function to update project reaction
	 */
	public onUpdateReaction(): void {
		this.updateAttachmentDetails(this.selectedItem);

		this.todoItem.title = this.selectedItem.title;
		this.todoItem['status'] = this.selectedItem.status;
		this.todoItem.priority = this.selectedItem.priority;
		this.todoItem.repeatCycle = this.selectedItem.repeatCycle;

		if (this.selectedItem.assignedUser && this.selectedItem.assignedUser.username) {
			this.todoItem['assignedUser'] = this.selectedItem.assignedUser.username;
		}

		if (this.selectedItem.repeatCycle === 'one_off') {
			const date = this.selectedItem.oneOff;
			const splicedDate = date.split(' ')[0];
			this.todoItem['oneOff'] = splicedDate;
		}

		if (this.selectedItem.repeatFreequency) {
			this.todoItem['repeatFreequency'] = this.selectedItem.repeatFreequency;
		}

		if (this.selectedItem['project'] && this.selectedItem['project']['uid']) {
			this.todoItem['project'] = this.selectedItem['project']['uid'];
		}

		if (this.selectedItem.numberOfOpens && this.selectedItem.numberOfOpens > 0) {
			this.todoItem['numberOfOpens'] = this.selectedItem.numberOfOpens;
		}

		this.todoItem['outcome'] = this.reaction;

		if (this.selectedItem.type === 'sub_task') {
			this.todoItem['parent_content_type'] = 'todo';
			this.todoItem['parent_content_uid'] = this.parentTask['uid'];
		}

		this.pageLoadingUiService.setPageLoading(true);
		this.networkService.updateToDoItem(this.todoItem, this.selectedItem.uid).subscribe(
			(res) => {
				if (res.status === 'closed') {
					this.mixPanelService.track('Completed Task', {
						workspace: this.network?.name || 'NA',
						appVersion: environment.version,
						language: this.translate?.currentLang,
						username: this.currentUser?.username || 'NA',
						name: this.currentUser?.name || 'NA',
						project: res?.project?.name || 'NA',
						date: moment().format('YYYY-MM-DD'),
					});

					this.segmentService.trackEvent('Completed Task', {
						workspace: this.network?.name || 'NA',
						appVersion: environment.version,
						language: this.translate?.currentLang,
						username: this.currentUser?.username || 'NA',
						name: this.currentUser?.name || 'NA',
						project: res?.project?.name || 'NA',
						date: moment().format('YYYY-MM-DD'),
					});
				}
				this.pageLoadingUiService.setPageLoading(false);
				if (res) {
					this.showEditReactionDialog = 'none';
					this.reaction = '';
					this.selectedItem['outcome'] = res['outcome'];
					delete this.todoItem['closedDate'];
					delete this.todoItem['project'];
					delete this.todoItem['repeatFreequency'];
					delete this.todoItem['oneOff'];
					delete this.todoItem['numberOfOpens'];
					delete this.todoItem['estimate'];
					delete this.todoItem['elapsed_time'];
					delete this.todoItem['outcome'];
					delete this.todoItem['parent_content_type'];
					delete this.todoItem['parent_content_uid'];
					this.parentTask = '';
				}
			},
			() => {
				this.pageLoadingUiService.setPageLoading(false);
			}
		);
	}

	/**
	 * Function to cancel update project reaction
	 */
	public onCancelReactionEdit(): void {
		this.showEditReactionDialog = 'none';
		this.reaction = '';
	}

	/**
	 * Function to edit project reaction
	 */
	public onEditReaction(task): void {
		event.stopPropagation();
		if (this.checkReactionIsEditable(task)) {
			this.selectedItem = task;
			// this.parentTask = parentTask ? parentTask:'';
			this.reaction = task['outcome'];
			this.showEditReactionDialog = 'block';
		}
	}

	/**
	 * Function to check the current reaction is editable or not
	 */
	private checkReactionIsEditable(task): boolean {
		if (this.currentUser) {
			if (
				(task['assignedUser'] &&
					(task['assignedUser'].username === this.currentUser.username || (task['author'] && task['author'].username === this.currentUser.username))) ||
				(task['author'] && task['author'].username === this.currentUser.username)
			) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	// Function to add extra hours to current time
	private addHoursToDate(date: any, hours: number): Date {
		return new Date(new Date(date).setHours(date.getHours() + hours));
	}

	/**
	 * Calculate percentage of start day tasks
	 *
	 * @param past number
	 * @param today number
	 * @param upcoming number
	 * @param type string
	 *
	 * @returns
	 */
	public calculateStartDayPercentage(past: number, today: number, upcoming: number, type: string): number {
		const total = past + today + upcoming;

		if (total > 0) {
			switch (type) {
				case 'past':
					return MathUtils.getPercentageOf(past, total);
				case 'today':
					return MathUtils.getPercentageOf(today, total);
				case 'upcoming':
					return MathUtils.getPercentageOf(upcoming, total);
				default:
					return 0;
			}
		}

		return 0;
	}

	/**
	 * Calculate percentage of end day tasks
	 *
	 * @param closed number
	 * @param totalCount number
	 *
	 * @returns
	 */
	public calculateEndDayPercentage(closed: number, total: number): number {
		return MathUtils.getPercentageOf(closed, total);
	}

	/**
	 * Function to get startDay user counts
	 */
	private getStartDayUserCounts(): void {
		this.userCountLoaded = true;
		this.networkService.getStartDayUserCounts().subscribe((res) => {
			this.userCountLoaded = false;
			this.startDayUserCounts = {
				future: res.future,
				past: res.past,
				today: res.today,
				closed: res.closed,
			};
		});
	}

	// #region Post Create Focus Card

	/**
	 * Handle post-create-modal request modal
	 * @param type Modal-Type
	 */
	public handleSelectModalType(type: string): void {
		this.showMenu = !this.showMenu;
		this.showUserSuggestionOnTitle = false;
		const modalType = { name: '', slug: '' };

		switch (type) {
			case 'meeting':
				modalType.name = 'Video conference';
				modalType.slug = 'video-conference';
				break;

			case 'recommend':
				modalType.name = 'Recommendation';
				modalType.slug = 'recommend';
				break;

			case 'question':
				modalType.name = 'Question';
				modalType.slug = 'question';
				break;

			case 'post':
				modalType.name = 'Post Something';
				modalType.slug = 'post';
				break;
		}

		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});

		if (this.showMenu) {
			this.layoutService.showBd('post-menu');
		} else {
			this.layoutService.hideBd();
		}

		this.selectPost(modalType);
	}

	// #endregion

	// #region Select Member

	public attachmentMembers = [];
	public isAttachmentMembersLoading = false;
	public assigneeFilter = 'all';
	public displayMemberForAttachment = false;

	public onAssigneeSelected(member): void {
		this.displayMemberForAttachment = false;

		this.attachmentMember = member;
		this.selectedMember.uid = member.uid;
		this.model.member = member;
		this.model.images = [];
		this.model.preview_images = [];
		this.photo_upload = false;
		this.doc_upload = false;
		this.article = false;
		this.location_display = 'none';
		delete this.model.location;
	}

	public displayMembersForAttachment(): void {
		this.displayMemberForAttachment = true;

		this.isAttachmentMembersLoading = true;

		this.memberService
			.getMembers(this.network.uid)
			.subscribe((data) => {
				this.noAssignee = false;
				this.attachmentMembers = data.objects;

				if (data.next) {
					this.attachmentMembersNextPageUrl = data.next.split('alpha')[1];
				} else {
					this.attachmentMembersNextPageUrl = '';
				}
			})
			.add(() => {
				this.isAttachmentMembersLoading = false;
			});
	}

	public paginateMemberAttachment(): void {
		if (this.attachmentMembersNextPageUrl) {
			this.postService.paginate(this.attachmentMembersNextPageUrl).subscribe((data) => {
				if (data) {
					this.attachmentMembers = this.attachmentMembers.concat(data['objects']);

					this.attachmentMembersNextPageUrl = data.next ? data.next.split('alpha')[1] : null;
				}
			});
		}
	}

	public onAssigneeSearch(searchText: string): void {
		this.isAttachmentMembersLoading = true;

		this.memberService
			.getMembers(this.network.uid, this.attachmentMembersNextPageUrl, `${this.assigneeFilter}&query=${searchText}`)
			.subscribe((data) => {
				this.attachmentMembers = data.objects.map((member: any) => ({
					...member,
					checked: this.attachmentMember?.username === member.user?.username,
				}));

				this.attachmentMembersNextPageUrl = data.next ? data.next.split('alpha')[1] : null;
			})
			.add(() => {
				this.isAttachmentMembersLoading = false;
			});
	}

	// #endregion

	// #region TUI Editor

	private initializeTuiEditor(): void {
		timer(500).subscribe(() => {
			const tuiEditorElement: HTMLElement = document.querySelector('#tuiEditor');

			if (!tuiEditorElement) {
				return;
			}

			this.tuiEditor = new Editor({
				el: tuiEditorElement,
				height: '350px',
				initialEditType: 'wysiwyg',
				previewStyle: 'vertical',
				autofocus: true,
				plugins: [[codeSyntaxHighlight, { highlighter: Prism }]],
				language: this.translate.currentLang === 'tr' ? 'tr-TR' : 'en-US',
				placeholder: this.translate.instant(
					this.postType === 'buy-sell' ? 'Description (Optional)' : this.postType === 'event' ? 'Description (Optional)' : 'What do you like to say?'
				),
				hideModeSwitch: true,
				linkAttributes: { target: '_blank' },
				frontMatter: true,
				/**
				 *  eslint-disable-next-line spellcheck/spell-checker
				 * ? Other possible fields are:- image, code, codeblock, scrollSync
				 */
				toolbarItems: [
					['heading', 'bold', 'italic', 'strike'],
					['hr', 'quote'],
					// eslint-disable-next-line spellcheck/spell-checker
					['ul', 'ol', 'indent', 'outdent'],
					['link'],
				],
			});

			this.tuiEditor.on('blur', () => {
				this.model.body = this.tuiEditor.getMarkdown();
			});
		});
	}

	// #endregion

	public toggleOverlay(triggerElement?): void {
		console.log('reached here', triggerElement);
		if (this.overlayRef && this.overlayRef.hasAttached()) {
			this.overlayRef.detach();
			this.overlayRef = null;
		} else {
			const positionStrategy = this.overlay
				.position()
				.flexibleConnectedTo(triggerElement)
				.withPositions([
					{
						originX: 'start',
						originY: 'bottom',
						overlayX: 'start',
						overlayY: 'top',
					},
				]);

			positionStrategy.setOrigin(triggerElement?.nativeElement);
			const overlayConfig = {
				hasBackdrop: true,
				backdropClass: 'cdk-overlay-transparent-backdrop',
				positionStrategy,
				// eslint-disable-next-line spellcheck/spell-checker
				scrollStrategy: this.overlay.scrollStrategies.close(),
			};

			this.overlayRef = this.overlay.create(overlayConfig);
			const portal = new TemplatePortal(this.overlayTemplate, this.viewContainerRef);
			this.overlayRef.attach(portal);
		}
	}

	public toggleCommentPopupOverlay(triggerElement?: ElementRef): void {
		if (this.commentOverlayRef && this.commentOverlayRef.hasAttached()) {
			this.commentOverlayRef.detach();
			this.commentOverlayRef = null;
		} else {
			if (!triggerElement || !triggerElement.nativeElement) {
				console.error('Invalid trigger element provided');
				return;
			}

			const positionStrategy = this.overlay
				.position()
				.flexibleConnectedTo(triggerElement)
				.withPositions([
					{
						originX: 'start',
						originY: 'bottom',
						overlayX: 'start',
						overlayY: 'top',
					},
				]);

			positionStrategy.setOrigin(triggerElement.nativeElement);

			const overlayConfig = {
				hasBackdrop: true,
				backdropClass: 'cdk-overlay-transparent-backdrop',
				positionStrategy,
				// eslint-disable-next-line spellcheck/spell-checker
				scrollStrategy: this.overlay.scrollStrategies.block(),
			};

			try {
				this.commentOverlayRef = this.overlay.create(overlayConfig);
				const portal = new TemplatePortal(this.commentPopupTemplate, this.viewContainerRef);
				this.commentOverlayRef.attach(portal);
			} catch (error) {
				console.error('Error creating or attaching overlay:', error);
			}
		}
	}

	public showCommentsPopup(event): void {
		this.toggleOverlay();
		const triggerElement = document.getElementById(event.uid.replaceAll('-', ''));
		const triggerElementRef: ElementRef = new ElementRef(triggerElement);
		this.toggleCommentPopupOverlay(triggerElementRef);
	}

	public onWorkPlaceChange(workplace: IWorkPlace): void{
		this.selectedWorkPlace = {...workplace};
		this.workplaceData.forEach(wp => {
			if(wp.value === workplace.value){
				wp.selected = true;
			}else{
				wp.selected = false;
			}
		});
		this.workplaceData = cloneDeep(this.workplaceData);
	}


	/**
	 * Function to calculate date&time when the repeat cycle is one-off
	 *
	 * 1. If dueTime=> date: one-off, time: dueTime + acronym
	 *
	 * 2. If startTime => date&time: startTime
	 *
	 * 3. If estimatedTime (endTime) => date&time: estimatedTime
	 *
	 * 4. else => date: one-off, time: null
	 *
	 * @param task current task
	 * @returns calculated date&time to be displayed
	 */
	private handleOneOffTaskTime(task: ITodo): string {
		if (task?.dueTime) {
			// return `${format(new Date(task?.oneOff), 'dd MMM')} (${task?.dueTime + 'd'})`;
			return `${format(new Date(task?.oneOff), 'dd MMM')}`; // 1
		} else if (task?.startTime) {
			const startTime = new Date(task?.startTime);
			return `${format(startTime, 'dd MMM, HH:mm')}`; // 2
		} else if (task?.estimatedTime) {
			const estimatedTime = new Date(task?.estimatedTime);
			return `${format(estimatedTime, 'dd MMM, HH:mm')}`; // 3
		} else {
			return `${format(new Date(task?.oneOff), 'dd MMM')}`; // 4
		}
	}
}
