import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ProfilesService } from '../services/profiles.service';

@Component({
	selector: 'app-user-activity-log',
	templateUrl: './user-activity-log.component.html',
	styleUrls: ['./user-activity-log.component.css'],
})
export class UserActivityLogComponent implements OnInit, OnChanges {
	@Input() public memberId: any;

	public nextUrl = '';
	public activityList: any[] = [];
	public showSpinner = false;
	public scrollStatus = false;

	constructor(private profileService: ProfilesService, private router: Router) {}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes?.memberId) {
			this.getUserActivityLog();
		}
	}

	/**
	 * Function to list all users activity log
	 */
	private getUserActivityLog(): void {
		this.showSpinner = true;
		this.scrollStatus = true;

		this.profileService.getUserActivityLog(this.memberId).subscribe((res) => {
			if (res) {
				this.showSpinner = false;
				this.scrollStatus = false;

				this.activityList = res.objects;
				this.nextUrl = res.next ? res.next.split('alpha')[1] : '';
			}
		});
	}

	/**
	 * Function to paginate the user activities
	 */
	public paginateActivities(): void {
		if (this.nextUrl) {
			this.showSpinner = true;
			this.scrollStatus = true;

			this.profileService.paginateUserActivities(this.nextUrl).subscribe((res) => {
				if (res) {
					this.showSpinner = false;
					this.scrollStatus = false;
					this.nextUrl = res.next ? res.next.split('alpha')[1] : '';

					if (res.objects && res.objects.length) {
						res.objects.forEach((li: any) => {
							this.activityList.push(li);
						});
					}
				}
			});
		}
	}

	/**
	 * Function to redirect to task detail
	 */
	public redirectToTaskDetail(activity): void {
		if (activity.label === 'Task Started' || activity.label === 'Task Completion' || activity.label === 'Task Paused') {
			if (activity.tasks && activity.tasks.length) {
				this.router.navigateByUrl('/project/task-detail/' + activity.tasks[0].uid);
			}
		}
	}
}
