import { Injectable } from '@angular/core';
import { StorageService, StorageType } from './storage.service';

@Injectable({
	providedIn: 'root'
})
export class WorkspaceCreationSessionManagementService {

	constructor(private storageService: StorageService) { }

	public clearJoinFlowSessionData(): void {
		this.storageService.removeItem('profileInfoStepCompleted', StorageType.Session);
		this.storageService.removeItem('teamCreationCompleted', StorageType.Session);
		this.storageService.removeItem('isTeamCreationSkipped', StorageType.Session);
		this.storageService.removeItem('projectCreationCompleted', StorageType.Session);
		this.storageService.removeItem('isProjectCreationSkipped', StorageType.Session);
		this.storageService.removeItem('WORKSPACE_ID_OPTIONAL_STEPS_PENDING', StorageType.Session);
		this.storageService.removeItem('TEAM_OR_CHANNEL_ID', StorageType.Session);
	}
}
