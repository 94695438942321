import { IEventType } from './../models/event-attachment';
import { BaseAttachment, IBaseAttachment } from 'projects/bee-attachment/src/lib/shared/models/base-attachment';
import { PersonAttachment, IPersonAttachment } from './../models/person-attachment';
import { Injectable } from '@angular/core';
import { ImageAttachment, IImageAttachment } from 'projects/bee-attachment/src/lib/shared/models/image-attachment';
import { FileAttachment, IFileAttachment } from '../models/file-attachment';
import { IURLAttachment, URLAttachment } from '../models/url-attachment';
import { EventAttachment, IEventAttachment, IEventStatus } from '../models/event-attachment';
// import * as moment from 'moment';
import { ILocationAttachment, LocationAttachment } from '../models/location-attachment';
import format from 'date-fns/format';

@Injectable({
	providedIn: 'root',
})
export class AttachmentService {
	public options: any;

	constructor() {}

	public parseBaseAttachment(attachment: any, data: any = null, options: any = null): IBaseAttachment {
		this.options = options;

		let baseAttachment: IBaseAttachment = null;

		if (attachment) {
			baseAttachment = new BaseAttachment({
				images: this.parseImageAttachments(attachment.gallery),
				url: this.parseUrlAttachments(attachment.url),
				person: this.parsePersonAttachments(attachment.member),
				location: this.parseLocationAttachments(attachment.location),
			});

			// doc attachment
			baseAttachment.files = this.parseFileAttachments(attachment.doc);

			if (!baseAttachment.files) {
				// audio attachment
				baseAttachment.files = this.parseFileAttachments(attachment.audio);
			}

			if (data && (data.postType === 'meeting' || data.postType === 'event')) {
				// event attachment
				baseAttachment.images = [];
				baseAttachment.event = this.parseEventAttachments(attachment, data);
			}
		}

		// console.log(baseAttachment);

		return baseAttachment;
	}

	public parseImageAttachments(attachmentSource: any): any[] {
		if (attachmentSource) {
			return attachmentSource?.map((imageItem: any) => {
				const image = imageItem?.image ?? imageItem;

				return new ImageAttachment(<IImageAttachment>{
					id: image.uid,
					name: image.name,
					type: image.type,
					url: image.url,
					data: image,
					thumbnails: <IImageAttachment>[
						// 112x112
						new ImageAttachment(<IImageAttachment>{
							id: `${image.uid}-112`,
							url: image.thumbnails['112'],
						}),
						// 120x120
						new ImageAttachment(<IImageAttachment>{
							id: `${image.uid}-120`,
							url: image.thumbnails['120'],
						}),
					],
				});
			});
		}

		return [];
	}

	public parseUrlAttachments(attachmentSource: any): any {
		if (attachmentSource && attachmentSource[0]) {
			return new URLAttachment(<IURLAttachment>{
				url: attachmentSource[0].url,
				title: attachmentSource[0].preview.title,
				description: attachmentSource[0].preview.description,
				image: attachmentSource[0].preview.image,
				fileType: attachmentSource[0].fileType,
			});
		}

		return null;
	}

	public parseFileAttachments(attachmentSource: any): any[] {
		if (attachmentSource) {
			return attachmentSource?.map(
				(file: any) =>
					new FileAttachment(<IFileAttachment>{
						id: file.uid,
						name: file.name,
						mime: file.type,
						url: file.url,
						data: file,
						size: file.sizes,
					})
			);
		}

		return null;
	}

	public parsePersonAttachments(attachmentSource: any): any {
		if (attachmentSource) {
			let avatar: string = null;

			if (attachmentSource.images && attachmentSource.images.length > 0) {
				avatar = attachmentSource.images[0].url;

				if (attachmentSource.images[0].thumbnails && attachmentSource.images[0].thumbnails[120]) {
					avatar = attachmentSource.images[0].thumbnails[120];
				}
			}

			return new PersonAttachment(<IPersonAttachment>{
				id: attachmentSource.uid,
				name: attachmentSource.firstName && attachmentSource.lastName ? `${attachmentSource.firstName} ${attachmentSource.lastName}` : attachmentSource.name,
				title: attachmentSource.primaryRole,
				avatar: avatar,
				route: `/network/profile/${attachmentSource?.memberId}?username=${attachmentSource?.username}`,
			});
		}

		return null;
	}

	public parseLocationAttachments(attachmentSource: any): ILocationAttachment | null {
		if (attachmentSource) {
			console.log(
				new LocationAttachment(<ILocationAttachment>{
					latitude: attachmentSource[0].point?.latitude,
					longitude: attachmentSource[0].point?.longitude,
				})
			);
			return new LocationAttachment(<ILocationAttachment>{
				latitude: attachmentSource[0].point?.latitude,
				longitude: attachmentSource[0].point?.longitude,
			});
		}

		return null;
	}

	public parseEventAttachments(attachmentSource: any, data: any): IEventAttachment {
		let eventStatus: IEventStatus = null,
			eventType = null;

		switch (data.meetingStatus) {
			case 1:
				eventStatus = IEventStatus.NOT_STARTED;
				break;
			case 2:
				eventStatus = IEventStatus.IN_PROGRESS;
				break;
			case 3:
				eventStatus = IEventStatus.COMPLETED;
				break;
			default:
				eventStatus = IEventStatus.NOT_STARTED;
		}

		switch (data.postType) {
			case 'meeting':
				eventType = IEventType.MEETING;
				break;
			case 'event':
				eventType = IEventType.EVENT;
				break;
			default:
				eventType = IEventType.MEETING;
		}

		if (data.title === 'No Meeting Day' || data.title === 'Focus Time' || data.title === '1:1 Meeting') {
			eventType = IEventType.CUSTOM;
		}

		const eventAttachment = <IEventAttachment>{
			title: data.title,
			backgroundImageUrl: '/assets/images/attachment/event-background-2.png',
			// startDate: moment(data.starts).format('d MMM HH:mm a'),
			// startDate: data.starts,
			startDate: format(new Date(data?.starts), 'd MMM h:m a'),
			meetingPassword: attachmentSource.meetingPassword,
			eventButtonText: data.eventButtonText,
			eventType: eventType,
			status: eventStatus,
			rsvpYesCount: data.rsvpYesCount,
			data: {
				attachment: attachmentSource,
				content: data,
			},
		};

		if (data.joinUrl) {
			eventAttachment.isOnlineMeeting = true;
			eventAttachment.onlineMeetingUrl = data.joinUrl;
			eventAttachment.eventType = IEventType.ONLINE_MEETING;

			const onlineMeetingApp = this.getOnlineMeetingApp(data.joinUrl);

			if (onlineMeetingApp === 'zoom') {
				eventAttachment.meetingAppLogoUrl = '/assets/images/newZoomLogo.svg';
			}
		}

		return new EventAttachment(eventAttachment);
	}

	public getOnlineMeetingApp(meetingUrl: string): string {
		if (meetingUrl.match(/zoom.us\/j\//)) {
			return 'zoom';
		}

		return null;
	}
}
