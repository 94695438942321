import { EventEmitter, Injectable, Output } from '@angular/core';
import { interval, Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class NprogressService {
	@Output() public progressChanged: EventEmitter<number> = new EventEmitter();

	private progress = 0;
	private timerStart = 0;
	private timerEnd = 0;
	private timer: Subscription = Subscription.EMPTY;

	constructor() {}

	public setProgress(value: number, res: string = null): void {
		if (value >= 100) {
			this.timerStart = 0;
			this.timerEnd = 0;
			this.progress = 0;
			this.timer.unsubscribe();
			this.progressChanged.emit(Math.floor(Math.random() * 99) + (this.progress + (100 - this.progress) / 2));

			timer(550).subscribe(() => {
				this.progressChanged.emit(100);

				timer(200).subscribe(() => {
					this.progressChanged.emit(0);
				});
			});
		} else {
			this.progress = value;
			this.progressChanged.emit(this.progress);
		}
	}

	public getProgress(): number {
		return this.progress;
	}

	public increaseProgress(value: number): void {
		const totalValue = this.progress + value;

		if (totalValue < 101) {
			this.setProgress(totalValue);
		}
	}

	public increaseProgressTo(value: number): void {
		if (this.progress >= value) {
			return;
		}

		this.timer = interval(50)
			.pipe(take(40))
			.subscribe(() => this.increaseProgress(2));
	}
}
