import { AgmCoreModule } from '@agm/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationPreviewComponent } from './location-preview.component';

@NgModule({
	declarations: [LocationPreviewComponent],
	imports: [CommonModule, AgmCoreModule],
	exports: [LocationPreviewComponent],
})
export class LocationPreviewModule {}
