import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationListComponent } from '.././shared/layout/location-list.component';
import { PostModule } from '../post/post.module';
import { SharedModule } from '../shared';

@NgModule({
	declarations: [LocationListComponent],
	imports: [CommonModule, SharedModule, PostModule],
	bootstrap: [LocationListComponent],
	exports: [LocationListComponent],
	providers: [],
})
export class LocationListModule {}
