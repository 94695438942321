import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { DIALOG_DATA } from '../../../shared/models/dialog-tokens';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'bee-pdf-viewer',
	templateUrl: './pdf-viewer.component.html',
	styleUrls: ['./pdf-viewer.component.scss'],
})
export class BeePdfViewerComponent implements OnInit {
	public pdfFileSource: any = null;
	public pdfZoomLevel = 1.0;

	constructor(@Inject(DIALOG_DATA) public data: any, private httpClient: HttpClient) {}

	ngOnInit(): void {
		this.httpClient
			.get('https://www.africau.edu/images/default/sample.pdf', {
				responseType: 'blob',
				headers: {
					'Content-Type': 'application/pdf',
				},
			})
			.subscribe((blob: any) => {
				const fileReader = new FileReader();

				fileReader.onload = (e: ProgressEvent<FileReader>): any => {
					if (e.target?.result) {
						this.pdfFileSource = e.target.result;
					}
				};

				fileReader.readAsArrayBuffer(blob);
			});
	}

	public setPdfZoomLevel(level: number): void {
		this.pdfZoomLevel += level;
	}
}
