import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@shared/services/common/toast.service';
import { ClipboardService } from 'ngx-clipboard';
import { fromEvent, Observable, of, timer } from 'rxjs';
import { delay, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class KeyboardUtilService {
	// #region Keydown and Keyup events

	private keydown$: Observable<Event> = fromEvent(document, 'keydown');
	private keyup$: Observable<Event> = fromEvent(document, 'keyup');
	private isLongPressMessageShown = false;

	// #endregion

	constructor(private toastService: ToastService, private translateService: TranslateService, private clipboardService: ClipboardService) {}

	/**
	 * keyboard long press handler
	 *
	 * @param {string} keyCode
	 * @returns Observable<Event>
	 */
	public keyboardLongPress(keyCode: string, longPressTime: number = 3000): Observable<Event> {
		return this.keydown$.pipe(mergeMap((e: Event) => of(e).pipe(delay(1500), takeUntil(this.keyup$)))).pipe(
			tap((event: KeyboardEvent) => {
				if (!this.isLongPressMessageShown && event.code === keyCode) {
					this.toastService.info(
						this.translateService.instant('current_version_is', {
							version: environment.version,
						}),
						{
							timeOut: 10000,
							onClick: () => {
								this.clipboardService.copyFromContent(environment.version);
								this.toastService.success(this.translateService.instant('Copied to clipboard!'));
							},
						}
					);

					this.isLongPressMessageShown = true;

					timer(longPressTime).subscribe(() => {
						this.isLongPressMessageShown = false;
					});
				}
			}),
			delay(longPressTime)
		);
	}
}
