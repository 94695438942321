import { EventEmitter, Injectable } from '@angular/core';
import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class QuickChatSocketBaseService extends Socket {
	constructor() {
		super(<SocketIoConfig>{
			url: `${environment.socketio_chat_url}&l=${localStorage.getItem('language')}`,
			options: {
				reconnection: true,
				forceNew: true,
			},
		});
	}
}

@Injectable({
	providedIn: 'root'
})
export class QuickChatSocketService {
	public socketController: EventEmitter<any> = new EventEmitter();

	constructor(private socket: QuickChatSocketBaseService) { }

	public getSocket(): QuickChatSocketBaseService {
		return this.socket;
	}

	public updateUnreadMessageCount(data): void {
		this.socketController.emit(data);
	}
}
