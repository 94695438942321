import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'core-user-list-modal',
	templateUrl: './user-list-modal.component.html',
	styleUrls: ['./user-list-modal.component.scss'],
})
export class UserListModalComponent implements OnInit {
	@Input() public title: string;
	@Input() public userList: any[] = [];
	@Input() public isLoading = true;
	@Input() public isSingleSelection = false;
	@Input() public isMultipleSelection = false;
	@Input() public isModalOpen = false;
	@Input() public paginationLoading = false;
	@Input() public enableRedirections = false;

	@Output() public onUserSelectedEvent = new EventEmitter<any>();
	@Output() public onUserSearchEvent = new EventEmitter<any>();
	@Output() public paginateScrollEvent = new EventEmitter<any>();
	@Output() public multipleSelectedMembers = new EventEmitter<any[]>();

	private selectedUsers: any[] = [];

	private searchKey: string;

	private lastEmittedUser: any = null;

	// #region isModalOpen

	@Output() public isModalOpenChange = new EventEmitter();

	// #endregion

	constructor() {}

	ngOnInit(): void {}

	// #region Modal

	public emitModalClose(): void {
		// if(!this.lastEmittedUser){
		// 	this.onUserSelectedEvent.emit(this.userList[0]);
		// }
		this.isModalOpen = false;
		this.clearSearch();
		this.isModalOpenChange.emit(false);
	}

	public onConfirmationSelectMembers(): void {
		this.multipleSelectedMembers.emit(this.selectedUsers);
		this.clearSearch();
		this.emitModalClose();
	}

	public showModal(): void {
		this.isModalOpen = true;
		this.isModalOpenChange.emit(true);
	}

	// #endregion

	public onUserSelected(user: any): void {
		if (this.isSingleSelection) {
			this.emitModalClose();

			this.onUserSelectedEvent.emit(user);
		} else if (this.isMultipleSelection) {
			if (this.selectedUsers?.length !== 0 && this.selectedUsers.includes(user)) {
				const index = this.selectedUsers.indexOf(user);
				this.selectedUsers.splice(index, 1);
				user.checked = false;
			} else {
				user.checked = true;
				this.selectedUsers = [];
				this.userList.forEach((member: any) => {
					if (member?.checked) {
						this.selectedUsers.push(member);
					}
				});
			}
		}
	}

	public onUserSearchChange(event: any): void {
		this.onUserSearchEvent.emit(event.target.value);
	}

	public searchKeyPress(event: Event): void{
		event.preventDefault();
		this.onUserSearchEvent.emit(this.searchKey);
	}

	public paginateScroll(): void {
		this.paginateScrollEvent.emit();
	}

	private clearSearch(): void{
		this.searchKey = '';
		this.onUserSearchEvent.emit('');
	}
}
