import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomSelectInputComponent } from './custom-select-input.component';
import { FormsModule } from '@angular/forms';



@NgModule({
	declarations: [
		CustomSelectInputComponent
	],
	imports: [
		CommonModule,
		FormsModule
	],
	exports: [CustomSelectInputComponent]
})
export class CustomSelectInputModule { }
