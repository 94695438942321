import { Component, Output, Input, OnInit, EventEmitter } from '@angular/core';
import { CountryCode } from './resource/country-code';
import { Country } from './models/country.model';
import * as _ from 'google-libphonenumber';

@Component({
	selector: 'app-ngx-intl-tel-input',
	templateUrl: './ngx-intl-tel-input.component.html',
	styleUrls: ['./ngx-intl-tel-input.component.css'],
	providers: [CountryCode],
})
export class NgxIntlTelInputComponent implements OnInit {
	@Input() public value = '';
	@Input() public preferredCountries: Array<string> = [];
	@Output() public valueChange: EventEmitter<string> = new EventEmitter<string>();

	public phone_number = '';
	public allCountries: Array<Country> = [];
	public preferredCountriesInDropDown: Array<Country> = [];
	public selectedCountry: Country = new Country();

	constructor(private countryCodeData: CountryCode) {
		this.fetchCountryData();
	}

	ngOnInit(): void {
		if (this.preferredCountries.length) {
			this.preferredCountries.forEach((iso2) => {
				const preferredCountry = this.allCountries.filter((c) => c.iso2 === iso2);
				this.preferredCountriesInDropDown.push(preferredCountry[0]);
			});
		}
		if (this.preferredCountriesInDropDown.length) {
			this.selectedCountry = this.preferredCountriesInDropDown[0];
		} else {
			this.selectedCountry = this.allCountries[0];
		}
	}

	public onPhoneNumberChange(): void {
		this.value = this.selectedCountry.dialCode + this.phone_number;
		this.valueChange.emit(this.value);
	}

	public onCountrySelect(country: Country, el): void {
		this.selectedCountry = country;
		if (this.phone_number.length > 0) {
			this.value = this.selectedCountry.dialCode + this.phone_number;
			this.valueChange.emit(this.value);
		}
		el.focus();
	}

	public onInputKeyPress(event): void {
		const pattern = /[0-9\+\-\ ]/;
		const inputChar = String.fromCharCode(event.charCode);
		if (!pattern.test(inputChar)) {
			event.preventDefault();
		}
	}

	protected fetchCountryData(): void {
		this.countryCodeData.allCountries.forEach((c) => {
			const country = new Country();
			country.name = c[0].toString();
			country.iso2 = c[1].toString();
			country.dialCode = c[2].toString();
			country.priority = +c[3] || 0;
			country.areaCode = +c[4] || null;
			country.flagClass = country.iso2.toLocaleLowerCase();
			country.placeHolder = this.getPhoneNumberPlaceHolder(country.iso2.toUpperCase());
			this.allCountries.push(country);
		});
	}

	protected getPhoneNumberPlaceHolder(countryCode: string): string {
		const phoneUtil = _.PhoneNumberUtil.getInstance();
		const x = _.PhoneNumberFormat;

		try {
			const phoneNumber = phoneUtil.parse('2236512366', countryCode);
			return phoneUtil.format(phoneNumber, x.INTERNATIONAL);
		} catch (e) {
			return e;
		}
	}
}
