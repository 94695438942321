/* eslint-disable spellcheck/spell-checker */
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PostService, LayoutService, NetworkService, ProfilesService, CollectionsService } from '@shared/services';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@shared/services/common/toast.service';
import { Reminder } from '@shared/models';
import { ClipboardService } from 'projects/peer-core/src/lib/services/ui/clipboard.service';
import { timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { MixPanelService } from '@core/services/third-party/mix-panel.service';
import { SegmentAnalyticsService } from '@core/services/third-party/segment-analytics.service';

@Component({
	selector: 'app-network-layout-legacy',
	templateUrl: './network-layout-legacy.component.html',
	styleUrls: ['./network-layout-legacy.component.scss'],
})
export class NetworkLayoutLegacyComponent implements OnInit, AfterViewInit, OnDestroy {
	public loadingGif = 'none';
	public post;
	private post_dummy;
	public postId;
	public showSpin = true;
	public isAuthenticated;
	public currentUser;
	public network_id;
	public selectByLevelValues: any[] = [];
	public personalFilterValues = [];
	public network;
	public networkType;
	public networkLogo;
	public showNetwork;
	public selectedChoice;
	public collections: any;
	public showDeleteConfirmation = 'none';
	public showZoomLinkModal = 'none';
	public showCollectionModal = false;
	public value;
	public editPost;
	public selectedImages: any[] = [];
	private paramsSubscriber;
	private networkSubscriber;
	private authSubscriber;
	private checkAccessibilitySub;
	private currentNetSub;
	public showReportedIssues = false;
	public showTodolistOption = false;
	public app_url;
	public store_url;
	public currentDate;
	public isLocked = false;
	public displayPointLadder = false;
	public showCustomers = false;
	private networkReady;
	public currentSubscription: any;
	public currentMonth;
	public currentDay;
	// Reports
	public reportReasons: any[] = [];
	public reportModal = 'none';
	public flag = { value: '', text: '' };
	public reportText;
	public isReported = false;

	public reminderOptions: any[] = [];
	public displayReminderModal = 'none';
	public reminderTime;
	public timeFor;
	public remindersSelected = [];
	public model = new Reminder();
	public reminderToast = false;

	public onlineStack = [];
	public viewDialog = 'none';
	public postViews: any = [];
	public viewNextUrl;
	public isFetching = 0;
	public isDevMode = !environment.production;

	constructor(
		private router: Router,
		private postService: PostService,
		public layoutService: LayoutService,
		private networkService: NetworkService,
		private translate: TranslateService,
		private profileService: ProfilesService,
		private collectionService: CollectionsService,
		private _location: Location,
		private toastService: ToastService,
		private clipboardService: ClipboardService,
		private mixPanelService: MixPanelService,
		private segmentService: SegmentAnalyticsService
	) {}

	ngOnInit(): void {
		// this.currentDate = new Date();
		// this.currentDay = this.currentDate.getDate();
		// this.currentMonth = this.currentDate.toLocaleString('default', {
		// 	month: 'long',
		// });
		// setInterval(() => {
		// 	this.currentDate = new Date();
		// 	this.currentDay = this.currentDate.getDate();
		// 	this.currentMonth = this.currentDate.toLocaleString('default', {
		// 		month: 'long',
		// 	});
		// }, 3000);
		// this.paramsSubscriber = this.activatedRoute.params.subscribe((params: Params) => {
		// 	this.postId = params['uid'];
		// 	// this.showSpin = false;
		// });
		// this.networkService.onTodoDetail = true;
		// const isMember = null;
		// this.authSubscriber = this.userService.isAuthenticated.subscribe((authenticated) => {
		// 	this.isAuthenticated = authenticated;
		// 	if (!authenticated) {
		// 		// localStorage.setItem('pb-prev-url', this.router.url);
		// 		// this.router.navigateByUrl('/login');
		// 	} else {
		// 		// this.loadingGif = 'block';
		// 		// this.postService.get(this.postId).subscribe((post) => {
		// 		// 	this.showSpin = false;
		// 		// 	this.post_dummy = new Post(post);
		// 		// 	this.post = this.post_dummy;
		// 		// 	this.postService.alterPostBodyAccordingToUserMentionAndHashtag(this.post_dummy);
		// 		// 	if (this.post_dummy) {
		// 		// 		if (this.post_dummy['networkId']) {
		// 		// 			// url error from noti to post detail commment-------
		// 		// 			this.network_id = this.post_dummy['networkId']; // .split('bulaapp://networks/')[1];
		// 		// 		} else {
		// 		// 			this.network_id = localStorage.getItem('network_id');
		// 		// 		}
		// 		// 	}
		// 		// 	this.networkService.populateNetworkBasedOnId(this.network_id);
		// 		// 	// this.loadingGif = 'none';
		// 		// });
		// 	}
		// });
		// this.userService.currentUser.subscribe((userData) => {
		// 	this.currentUser = userData;
		// });
		// this.networkSubscriber = this.networkService.networkReady.subscribe((networkLoaded) => {
		// 	if (networkLoaded) {
		// 		this.currentNetSub = this.networkService.currentNetwork.subscribe((network) => {
		// 			this.network = network;
		// 			console.log(network);
		// 			this.networkType = network.networkType;
		// 			this.networkLogo = network.logo;
		// 			this.showNetwork = true;
		// 			if (this.network && this.network.services) {
		// 				this.network.services.forEach((service) => {
		// 					if (service.slug === 'add-issue') {
		// 						this.showReportedIssues = true;
		// 					}
		// 					if (service.slug === 'add-task') {
		// 						this.showTodolistOption = true;
		// 					}
		// 					if (service.slug === 'add-customer') {
		// 						this.showCustomers = true;
		// 					}
		// 				});
		// 			}
		// 			if (this.network['pointsEarning']) {
		// 				this.displayPointLadder = true;
		// 			}
		// 			if (this.network.SubscriptionTemplates && this.network.SubscriptionTemplates.length > 0) {
		// 				this.network.SubscriptionTemplates.forEach((value) => {
		// 					if (value.cpEnabled === true) {
		// 						this.selectByLevelValues.push(value);
		// 					}
		// 				});
		// 			}
		// 			if (network.membership && network.membership.memberRole) {
		// 				this.network.membership.memberRole.fields.forEach((element) => {
		// 					if (element.CP === true) {
		// 						this.personalFilterValues.push(element);
		// 					}
		// 				});
		// 			}
		// 		});
		// 	}
		// });
		// //  this.networkReady = this.networkService.subscriptionReady.subscribe(
		// //   (subscriptionLoaded) => {
		// //     if (subscriptionLoaded) {
		// //       this.currentNetSub = this.networkService.currentSubscription.subscribe(
		// //         (subscription) => {
		// //           this.currentSubscription = subscription;
		// //           if (this.currentSubscription && this.currentSubscription['renewableDate']) {
		// //             let renewableDate = new Date(this.currentSubscription['renewableDate']);
		// //             let currentDate = new Date();
		// //             if (currentDate > renewableDate && !localStorage.getItem('networkExpired')) {
		// //               localStorage.setItem('networkExpired', 'true');
		// //               this.router.navigateByUrl('/expired');
		// //             }
		// //           }
		// //           if (this.currentSubscription && this.currentSubscription['subscriptionPlan'] &&
		// //             this.currentSubscription['subscriptionPlan']['name'] === 'Premium') {  // 1
		// //             if (subscription && !subscription['purchasedPlan']) {  // 2
		// //               this.isLocked = false;            // 3
		// //             } else {
		// //               this.isLocked = true; // 3
		// //             }
		// //           } else {
		// //             this.isLocked = false;  // 4
		// //           }
		// //         });
		// //     }
		// //   });
		// /**
		//  * For showing online/offline statues of post authors
		//  * 1. Emit set user data
		//  * 2. Get call back from onlineStack event and store online stack data
		//  */
		// if (this.socket.getSocket().ioSocket.connected) {
		// 	// 1
		// 	this.socket
		// 		.getSocket()
		// 		.emit('set-user-data', { username: this.currentUser.username, networkId: this.network.uid }, (data) => {});
		// }
		// this.socket.getSocket().on('onlineStack', (members) => {
		// 	// 2
		// 	this.onlineStack = members;
		// });
	}

	ngAfterViewInit(): void {
		// Hack: Scrolls to top of Page after page view initialized
		let top = document.getElementById('top');
		if (top !== null) {
			top.scrollIntoView();
			top = null;
		}
	}

	public checkAccessAndRedirect(is_member): void {
		this.checkAccessibilitySub = this.postService.checkAccessibility({ postId: this.postId, memberId: is_member[0].uid }).subscribe((data) => {
			if (Object.keys(data).length) {
				this.post = this.post_dummy;
			} else {
				this.router.navigateByUrl('/network');
			}
		});
	}

	public likePost(post): void {
		this.postService.like(this.currentUser.username, post).subscribe((data) => {
			post.myRelations = { like: data.uid };
			if (post['myRelations'] && post['myRelations'].like && post['likesCount']['count'] === 0) {
				post['likedText'] = this.translate.instant('You liked this');
			}
		});
	}

	public dislikePost(post): void {
		this.postService.dislike(this.currentUser.username, post.myRelations.like).subscribe(() => {
			post.myRelations = { like: null };
			post['likedText'] = '';
			post['likesCount']['count'] = 0;
		});
	}

	// Called when an event option is selected
	public onEventAttendSubmit(post, eventValue): void {
		const currentPost = post;
		const self = this;
		if (currentPost.myRelations.rsvp) {
			self.postService.updateEventAttendType(self.currentUser.username, eventValue, currentPost.myRelations.rsvp).subscribe((data) => {
				self.post.myRelations.attending = data.attending;
				// self.onCloseHandled();
			});
		} else {
			self.postService.postEventAttendType(self.currentUser.username, eventValue, currentPost.uid).subscribe((data) => {
				self.post.myRelations.attending = data.attending;
				self.post.myRelations.rsvp = data.uid;
				// self.onCloseHandled();
			});
		}
	}

	// Called when a choice is selected.
	public onChoiceSelect(post, choice): void {
		if (post.myRelations.vote) {
			this.selectedChoice = null;
		} else {
			this.selectedChoice = choice;
			this.onVoteSubmit();
		}
	}
	// Function to call the vote submit api.
	public onVoteSubmit(): void {
		const self = this;
		this.postService.postVote(this.post.uid, this.selectedChoice.uid).subscribe((data) => {
			const choiceIndex = self.post.choices.indexOf(self.selectedChoice);
			self.post.myRelations.vote = data.uid;
			self.post.myRelations.choice = data.choice.uid;
			self.post.choices[choiceIndex] = data.choice;
			self.post.totalVoteCount++;
		});
	}

	// Function to mute  user
	public onMuteClick(userId): void {
		this.profileService.muteUser(userId);
	}

	/**
	 *Function to show add to collection model
	 */
	public onAddToCollectionClick(): void {
		this.showCollectionModal = true;
		this.collectionService.collection.subscribe(() => {
			this.collections = this.collectionService.sortedCollectionSubject.value;
			if (this.collections['myCollections'].length === 0 && this.collections['followingCollections'].length === 0 && this.collections['followingCollections'].length === 0) {
				this.collectionService.populateCollection();
			}
		});
	}

	/**
	 * Function to add post to collection
	 */
	public onSelectCollection(collection): void {
		this.showCollectionModal = false;
		this.collectionService.addToCollection(collection.uid, this.post.uid).subscribe(() => {
			this.mixPanelService.track('Created Collections', {
				workspace: this.network.name || 'NA',
				appVersion: environment.version,
				language: this.translate.currentLang,
				username: this.currentUser?.username || 'NA',
				name: this.currentUser?.name || 'NA',
				date: moment().format('YYYY-MM-DD'),
			});

			this.segmentService.trackEvent('Created Collections', {
				workspace: this.network.name || 'NA',
				appVersion: environment.version,
				language: this.translate.currentLang,
				username: this.currentUser?.username || 'NA',
				name: this.currentUser?.name || 'NA',
				date: moment().format('YYYY-MM-DD'),
			});
			this.collectionService.populateCollection();
		});
	}

	/**
	 * Function to show the edit post functionality
	 */
	public onPostEditClick(): void {
		this.postService.editSelectedPost = this.post;
		this.editPost = this.post;
	}

	/**
	 * Function to show post  delete confirmation.
	 */
	public onPostDeleteClick(): void {
		this.showDeleteConfirmation = 'block';
		this.postService.showBackDrop();
		// this.deleteSelectedPost = post;
		// this.showBackdrop.subscribe(data => {
		//   if (data === 'hide') {
		//     this.hideDeleteConfirmation();
		//   }
		// });
	}

	/**
	 *Function to hide the delete confirmation modal.
	 */
	public hideDeleteConfirmation(): void {
		this.showDeleteConfirmation = 'none';
		this.postService.hideBackDrop();
	}

	/**
	 * Function to delete a post on confirmation.
	 */
	public onDeleteConfirm(): void {
		this.postService.deletePost(this.post['uid']).subscribe(() => {
			// this.posts.splice(this.posts.indexOf(this.deleteSelectedPost), 1);
			this.router.navigateByUrl('/network');
			this.hideDeleteConfirmation();
		});
	}

	public onClickImages(images): void {
		this.selectedImages = images;
	}

	/**
	 * Function which goes back to previous page
	 */
	public onBackClick(): void {
		if (localStorage.getItem('previous_route')) {
			this._location.back();
		} else {
			this.router.navigate(['/network/notifications']);
		}
	}

	/**
	 * Function to get cp of a post and display it in post detail
	 * @param value
	 */
	public getPrivacyValues(value): string {
		if (value.status) {
			return value.status;
		} else if (value.renewalStatus) {
			return value.renewalStatus;
		} else if (value.membership_level) {
			let name = '';
			this.selectByLevelValues.forEach((object) => {
				if (object.uid === value.membership_level) {
					name = object.name;
				}
			});
			return name;
		} else {
			this.value = Object.keys(value);
			const index = this.personalFilterValues.findIndex((field) => field.key === this.value);
			if (index !== -1) {
				return this.personalFilterValues[index].valueLabel;
			}
		}
	}

	/**
	 *Function for starting a zoom meeting
	 * @param startUrl
	 */
	public startZoomMeeting(startUrl): void {
		window.open(startUrl, '_blank');
	}

	/**
	 * Function for joining a zoom meeting
	 * @param post
	 */
	public joinZoomMeeting(post): void {
		if (this.currentUser.zoomLinked) {
			const url = post.joinUrl;
			this.onEventAttendSubmit(post, '1');
			window.open(url, '_blank');
		} else {
			this.showZoomLinkModal = 'block';
		}
	}

	ngOnDestroy(): void {
		if (this.currentNetSub) {
			this.currentNetSub.unsubscribe();
		}
		if (this.networkSubscriber) {
			this.networkSubscriber.unsubscribe();
		}
		if (this.paramsSubscriber) {
			this.paramsSubscriber.unsubscribe();
		}
		if (this.checkAccessibilitySub) {
			this.checkAccessibilitySub.unsubscribe();
		}
		if (this.authSubscriber) {
			this.authSubscriber.unsubscribe();
		}
		// Remove previous route from LS
		if (localStorage.getItem('previous_route')) {
			localStorage.removeItem('previous_route');
		}
		this.layoutService.unsetMobileDetect();
	}

	// Pass the clicked post  to this function and store in readMorePost
	public showMore(post): void {
		if (post['showMore']) {
			post['showMore'] = false;
		} else {
			post['showMore'] = true;
		}
	}

	/**
	 * Function to download document attachment
	 * @param attachment
	 */
	public getDownloadLink(attachment): string {
		return attachment['url'] + '?cType=' + attachment['type'] + '&file=' + attachment['name'];
	}

	private onAppUrlClick(): void {
		window.location.replace(this.app_url);

		timer(250).subscribe(() => {
			window.location.replace(this.store_url);
		});

		// setTimeout(function () {
		// 	window.location.replace(this.store_url);
		// 	clearTimeout();
		// }, 250);
	}

	public handleLinkShare(link: string): void {
		this.clipboardService.copy(link);
	}

	private onMutePost(type): void {
		this.loadingGif = 'block';
		this.profileService.mutePost(this.post.uid, type).subscribe((data) => {
			if (data.uid) {
				this.post['myRelations']['muted'] = data.uid;
			}
			this.loadingGif = 'none';
		});
	}

	private onUnMutePost(muteId): void {
		this.loadingGif = 'block';
		this.profileService.unMutePost(muteId).subscribe(
			() => {
				this.post['myRelations']['muted'] = '';
				this.loadingGif = 'none';
			},
			() => {
				this.post['myRelations']['muted'] = '';
				this.loadingGif = 'none';
			}
		);
	}

	public handlePostReport(): void {
		this.isReported = false;
		if (this.post['myRelations'] && !this.post['myRelations']['flagged']) {
			this.reportModal = 'block';
			this.flag['value'] = '';
			this.flag['text'] = '';
			this.reportReasons = [];
			this.profileService.getReasons().subscribe((data) => {
				if (data.post) {
					this.reportReasons = Object.entries(data.post);
				}
			});
		} else {
			this.reportText = 'You have reported already';
			this.isReported = true;
			setTimeout(() => {
				this.isReported = false;
			}, 7000);
		}
	}

	public onPostReport(): void {
		if (this.flag['value']) {
			this.loadingGif = 'block';
			const obj = {
				contentType: 'post',
				contentUid: this.post['uid'],
				reason: this.flag['value'],
				text: this.flag['text'],
			};

			this.networkService.reportAnAction(obj).subscribe(
				(data) => {
					this.post.myRelations['flagged'] = data.uid;
					this.resetReports();
					this.reportText = 'Your feedback is reported successfully';
					this.isReported = true;
					this.loadingGif = 'none';
					setTimeout(() => {
						this.isReported = false;
					}, 7000);
				},
				() => {
					this.resetReports();
					this.loadingGif = 'none';
				}
			);
		}
	}

	public resetReports(): void {
		this.flag['value'] = '';
		this.flag['text'] = '';
		this.reportReasons = [];
		this.reportModal = 'none';
	}

	private onAddTaskForPost(): void {
		this.post['type'] = 'postTodo';
		this.editPost = this.post;
	}

	private displayReminders(): void {
		this.displayReminderModal = 'block';
		this.reminderOptions = [];
		this.networkService.getReminderOptions().subscribe((data) => {
			if (this.post['type'] !== 'events') {
				this.reminderOptions = data.reminderOptions;
			} else {
				this.reminderOptions = data.eventReminderOptions;
			}
			if (this.remindersSelected.length > 0) {
				this.setPreviousReminders();
			}
		});
	}

	/**
	 * On selecting reminder options
	 * @param reminder
	 */
	public onSelectReminder(reminder): void {
		const index = this.remindersSelected.findIndex((r) => r === reminder);
		if (index > -1) {
			this.remindersSelected.splice(index, 1);
		} else {
			this.remindersSelected.push(reminder);
		}
	}

	/**
	 * For resetting reminders in reminder options modal
	 */
	public resetReminders(): void {
		this.displayReminderModal = 'none';
		this.timeFor = 0;
		this.remindersSelected = [];
		delete this.model['eventReminderOption'];
		this.model = {
			contentType: '',
			contentUid: '',
			repeat: true,
			reminderOption: '',
			username: '',
		};
		this.reminderOptions.forEach((r) => {
			r['checked'] = false;
		});
	}

	/**
	 * For creating a new reminders for content
	 */
	public setReminder(): void {
		if (this.remindersSelected.length > 0) {
			const object = {};
			this.remindersSelected.forEach((reminder) => {
				object[reminder] = this.setReminderDate(reminder);
			});

			const param = JSON.stringify(object);
			this.model.contentUid = this.post.uid;
			this.model.username = this.currentUser.username;
			this.model.repeat = true;

			if (this.post['type'] === 'issue') {
				this.model.contentType = 'issue';
			} else {
				this.model.contentType = 'post';
			}

			if (this.post['type'] !== 'events') {
				this.model.reminderOption = param;
			} else {
				delete this.model['reminderOption'];
				this.model['eventReminderOption'] = param;
			}

			this.loadingGif = 'block';
			this.networkService.createReminder(this.model).subscribe((data) => {
				this.post['myRelations']['reminder'] = data['uid'];
				this.loadingGif = 'none';
				this.resetReminders();
				this.reminderToast = true;
				setTimeout(() => {
					this.reminderToast = false;
				}, 8000);
			});
		} else {
			if (this.post['myRelations'] && this.post['myRelations']['reminder']) {
				this.deleteReminders();
			} else {
				this.resetReminders();
			}
		}
	}

	public deleteReminders(): void {
		this.model.contentUid = this.post.uid;
		this.model.username = this.currentUser.username;
		this.model.repeat = true;
		this.model.contentType = 'post';
		this.loadingGif = 'block';
		this.networkService.createReminder(this.model).subscribe(() => {
			this.post['myRelations']['reminder'] = '';
			this.loadingGif = 'none';
			this.resetReminders();
		});
	}

	/**
	 * For calculating corresponding to reminder selected
	 * @param option
	 */
	public setReminderDate(option): string {
		if (option === 'in_twenty_minutes') {
			this.timeFor = 20;
		} else if (option === 'in_one_hour') {
			this.timeFor = 60;
		} else if (option === 'in_three_hours') {
			this.timeFor = 180;
		} else if (option === 'tomorrow') {
			this.timeFor = this.minutesUntilMidnight() + 540;
		} else if (option === 'next_week') {
			this.timeFor = 6300;
		} else if (option === 'event_time') {
			this.timeFor = -1;
		} else if (option === 'five_minutes_before') {
			this.timeFor = 5;
		} else if (option === 'ten_minutes_before') {
			this.timeFor = 10;
		} else if (option === 'fifteen_minutes_before') {
			this.timeFor = 15;
		} else if (option === 'thirty_minutes_before') {
			this.timeFor = 30;
		} else if (option === 'one_hour_before') {
			this.timeFor = 60;
		} else if (option === 'two_hour_before') {
			this.timeFor = 120;
		} else if (option === 'one_day_before') {
			this.timeFor = 1440;
		} else if (option === 'two_day_before') {
			this.timeFor = 2880;
		} else if (option === 'one_week_before') {
			this.timeFor = 10080;
		}

		let date;
		if (this.post['type'] === 'events' || this.post['type'] === 'meeting') {
			if (this.timeFor === -1) {
				date = new Date(this.post.starts);
			} else {
				date = new Date(this.post.starts);
				date.setMinutes(date.getMinutes() - this.timeFor);
			}
		} else {
			date = new Date();
			date.setMinutes(date.getMinutes() + this.timeFor);
		}

		const month = date.getUTCMonth() + 1;
		const day = date.getUTCDate();
		const year = date.getUTCFullYear();
		const hours = date.getUTCHours();
		const minutes = date.getUTCMinutes();
		const seconds = date.getUTCSeconds();
		const utcDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
		return utcDate;
	}

	/**
	 * Get previous reminders of content
	 */
	public getReminderDetails(): void {
		let type;
		if (this.post['type'] === 'issue') {
			type = 'issues';
		} else {
			type = 'posts';
		}
		this.loadingGif = 'block';
		this.networkService.getContentReminders(type, this.post['uid']).subscribe((data) => {
			this.loadingGif = 'none';
			this.displayReminders();
			if (data.objects && data.objects.length > 0) {
				data.objects.forEach((element) => {
					if (element['reminderOption']) {
						this.remindersSelected.push(element['reminderOption']);
					} else if (element['eventReminderOption']) {
						this.remindersSelected.push(element['eventReminderOption']);
					}
				});
			}
			this.setPreviousReminders();
		});
	}

	/**
	 * Set Previous reminders on reminder options
	 */
	private setPreviousReminders(): void {
		this.reminderOptions.forEach((reminder) => {
			this.remindersSelected.forEach((r) => {
				if (r === reminder[0]) {
					reminder['checked'] = true;
				}
			});
		});
	}

	private minutesUntilMidnight(): any {
		const midnight = new Date();
		midnight.setHours(24);
		midnight.setMinutes(0);
		midnight.setSeconds(0);
		midnight.setMilliseconds(0);
		return (midnight.getTime() - new Date().getTime()) / 1000 / 60;
	}

	public getPostViews(post): void {
		this.viewDialog = 'block';
		this.postViews = [];
		this.viewNextUrl = '';
		this.isFetching = 0;
		this.postService.getPostViews(post['uid']).subscribe((data) => {
			this.postViews = data.objects;
			this.isFetching = 1;
			if (data.next) {
				this.viewNextUrl = data.next.split('alpha')[1];
				this.paginateViews();
			} else {
				this.viewNextUrl = '';
			}
		});
	}

	public paginateViews(): void {
		if (this.viewNextUrl) {
			this.postService.paginate(this.viewNextUrl).subscribe((data) => {
				this.postViews = this.postViews.concat(data.objects);
				if (data.next) {
					this.viewNextUrl = data.next.split('alpha')[1];
				} else {
					this.viewNextUrl = '';
				}
			});
		}
	}

	public isOnline(user: any): string {
		if (this.onlineStack[user]) {
			return this.onlineStack[user].isOnline;
		} else {
			return 'Offline';
		}
	}

	/**
	 * Function to update the comment count
	 */
	// newCommentCreatedEmitterFn() {
	// 	// this.post['totalMasterCommentCount'] = this.post['totalMasterCommentCount'] + 1;
	// }

	/**
	 * Function to calculate the completed task count progress bar width
	 */
	public calculateCompletedTaskProgressBarWidth(post: any): string {
		let progressWidth = '0%';

		if (post && post.todolist) {
			const closed = post.todolist.closedTodos,
				totalTask = post.todolist.totalCount,
				c = totalTask / closed,
				width = Math.floor(100 / c);

			progressWidth = width + '%';

			return progressWidth;
		}
	}

	/**
	 * Function to round the decimal number
	 * @param number
	 * @returns
	 */
	public roundDecimalPlace(number): number {
		const value = parseFloat(number);

		return Math.round(value * 100) / 100;
	}

	/**
	 * Function to calculate the completed task count progress bar width for project status update post
	 */
	public calculateProgressBarFoProjectUpdatePost(post): string {
		let progressWidth = '0%';

		if (post && post.project) {
			const closed = post.project.totalCount - post.project.openCount,
				totalCount = post.project.totalCount,
				c = totalCount / closed,
				width = Math.floor(100 / c);

			progressWidth = width + '%';

			return progressWidth;
		}
	}

	/**
	 * Function to show the add task or add issue form
	 * @param $event
	 */
	public onClickMoreOptionsEmitterFn($event): void {
		this.postService.onClickMoreOptionsFromComments.emit($event);

		setTimeout(() => {
			this.layoutService.hideBd();
			this.layoutService.showBd('add-issue');

			window.scroll(0, 0);
		}, 200);
	}

	/**
	 * Function to return the project status bar color
	 * @param projectDetails
	 * @returns
	 */
	public projectStatusBarColor(projectDetails): string {
		if (projectDetails) {
			if (projectDetails['endDate']) {
				const endDate = new Date(projectDetails['endDate']);

				if (this.currentDate > endDate) {
					return projectDetails['openCount'] > 0 ? '#ff0000' : 'var(--success-color)';
				} else if (this.currentDate <= endDate) {
					if (projectDetails['openCount'] > 0) {
						return '#ffd306';
					} else if (projectDetails['openCount'] === 0) {
						return 'var(--success-color)';
					}
				}
			}
		}
	}
}
