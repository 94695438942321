import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { MemberService, PaginationService, NetworkService, UserService } from '../../shared';
import { Router } from '@angular/router';
import { SearchService } from '../../shared/services/search.service';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';
import { InviteTeammatesComponent } from 'projects/peer-core/src/lib/components/invite-teammates/invite-teammates.component';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-admin-members',
	templateUrl: './admin-members.component.html',
	styleUrls: ['./admin-members.component.css'],
})
export class AdminMembersComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('inviteModal', { static: false })
	public inviteModalComponent: InviteTeammatesComponent;

	public appName = environment.appName;

	constructor(
		private location: Location,
		private memberService: MemberService,
		private networkService: NetworkService,
		private userService: UserService,
		private router: Router,
		private translate: TranslateService,
		private searchService: SearchService,
		private paginationService: PaginationService
	) {}

	public members = [];
	public next_url;
	public filter = { key: null, value: null };
	private isFirstSearch = true;
	private paginating = false;
	public paginateSub$: any;
	public networkLoaded;
	public currentUser;
	public network;
	public showPendingMembers;
	public fetchingMembers = false;
	private subscriptionReady;
	private currentNetSub;
	public isText = false;
	public member_count;
	public searchMemberName = '';

	ngOnInit(): void {
		if (localStorage.getItem('networkExpired')) {
			this.router.navigateByUrl('/expired');
		}
		this.searchService.resetSearchValue();
		this.networkService.populate();
		this.filter = this.memberService.filter;

		this.paginateSub$ = this.paginationService.initiateScroll.subscribe((data) => {
			if (data && !this.paginating && this.next_url) {
				this.paginate();
			}
		});

		this.networkService.networkReady.subscribe((networkLoaded) => {
			this.networkLoaded = networkLoaded;
			if (this.networkLoaded) {
				this.networkService.currentNetwork.subscribe((network) => {
					this.network = network;

					if (this.members.length === 0) {
						this.fetchMembers();
					}
				});
			}
		});

		this.userService.currentUser.subscribe((userData) => {
			// TODO: use loading spinner
			this.currentUser = userData;
		});

		this.searchService.searchValue.subscribe((data) => {
			if (data.trim() && this.isFirstSearch) {
				this.isFirstSearch = false;
			}
			if (!this.isFirstSearch) {
				if (data) {
					this.isText = true;
				} else {
					this.isText = false;
				}
				this.fetchMembers('query=' + data.trim());
			}
		});
	}

	public showInviteModal(): void {
		this.inviteModalComponent?.showInviteModal();
	}

	private fetchMembers(query = ''): void {
		this.fetchingMembers = true;
		this.memberService
			.getMembers(this.network?.uid, '', query)
			.pipe(debounceTime(query?.length > 0 ? 3500 : 0))
			.subscribe((data) => {
				this.fetchingMembers = false;
				this.members = data.objects;

				this.next_url = data.next ? data.next.split('alpha')[1] : '';

				this.member_count = this.translate.instant('showAllCount', {
					count: this.members.length,
				});
			});
	}

	public getDepartmentAndLocationData(role): string {
		let text = '';

		if (role['fields']) {
			const location = role['fields'].filter((d) => d['key'] === 'location'),
				department = role['fields'].filter((d) => d['key'] === 'department');

			if (location.length && department.length) {
				text = department?.[0]?.valueLabel ? department?.[0]?.valueLabel : '' + ' , ' + location?.[0]?.valueLabel ? location?.[0]?.valueLabel : '';
			} else if (department.length) {
				text = department?.[0]?.valueLabel ? department?.[0]?.valueLabel : '';
			} else if (location.length) {
				text = location?.[0]?.valueLabel ? location?.[0]?.valueLabel : '';
			}
		}

		return text;
	}

	public paginate(): void {
		if (this.next_url) {
			this.paginating = true;

			this.memberService.paginate(this.next_url).subscribe((data) => {
				this.paginating = false;
				this.members = this.members.concat(data.objects);

				this.next_url = data.next ? data.next.split('alpha')[1] : '';

				this.member_count = this.translate.instant('showAllCount', {
					count: this.members.length,
				});
			});
		}
	}

	ngAfterViewInit(): void {
		if (this.memberService.scrollPosition) {
			window.scroll(0, this.memberService.scrollPosition);
		}
		// Hack: Scrolls to top of Page after page view initialized
		let top = document.getElementById('top');
		if (top !== null) {
			top.scrollIntoView();
			top = null;
		}
	}

	ngOnDestroy(): void {
		this.paginationService.scrollDistance = 5;
		this.memberService.scrollPosition = document.documentElement.scrollHeight;
		if (this.paginateSub$) {
			this.paginateSub$.unsubscribe();
		}
		if (this.subscriptionReady) {
			this.subscriptionReady.unsubscribe();
		}
		if (this.currentNetSub) {
			this.currentNetSub.unsubscribe();
		}
	}

	public onClickBack(): void {
		this.location.back();
	}

	public onMemberSearch(): void {
		if (this.searchMemberName.trim()) {
			this.fetchMembers(`&query=${this.searchMemberName}`);
		}
	}
}
