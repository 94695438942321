export interface IURLAttachment {
	url?: string;
	title?: string;
	description?: string;
	image?: string;
	urlFavicon?: string;
	fileType?: string;
}

export class URLAttachment implements IURLAttachment {
	public url: string;
	public title: string;
	public description: string;
	public image: string;
	public fileType: string;
	public urlFavicon: string = null;

	constructor(values: IURLAttachment = {}) {
		Object.assign(this, values);
	}
}
