import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-layout-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
	public appURL = environment.appURL;
	public urlLinkText!: string;
	constructor() {}

	ngOnInit(): void {
		if(environment.appName === 'Peerbie'){
			this.urlLinkText = 'peerbie.com';
		}

		if(environment.appName="Neaty"){
			this.urlLinkText = 'neaty.io';
		}
	}
}
