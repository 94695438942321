import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UserService, PrivacyComponent, NetworkService, ProductService, LayoutService, PaginationService, PostService } from '../../shared';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchService } from '../../shared/services/search.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-product',
	templateUrl: './products.component.html',
	styleUrls: ['./products.component.css'],
	encapsulation: ViewEncapsulation.None,
})
export class ProductsComponent implements OnInit, OnDestroy {
	@ViewChild(PrivacyComponent, { static: true }) public privacy;
	private currentDepartmentSubscriber;
	public searchSubscription: any;
	public isFirstSearch = true;
	private departmentsReady;
	public loadingGif = 'none';
	// eslint-disable-next-line spellcheck/spell-checker
	public model = { subcircle: '' };
	public defaultCp;
	public membershipFields;
	public departments = [];
	public personalFilters = [];
	public SubscriptionTemplates = [];
	public roles = [];
	public showEditBtn = false;
	public editSelectedProduct;
	public editSelectedProductIndex: any;
	public productError = false;
	public noIssueCollections = false;
	public previousFilterString;
	public currentUser;
	public displayIssuesLists = true;
	public postUrl;
	public productIssues = [];
	public stateIssues = [];
	public closedIssues = ['Today', 'Yesterday', 'Last Week', 'Last Month', 'All Time'];
	public forNames;
	public network_id;
	public postType = '';
	public selectByLevelValues: any[] = [];
	public personalFilterValues = [];
	public options: any[] = [];
	public showPostCreateForm = true;
	public showMenu = false;

	public isPaginating = false;
	public next_url;
	public networkLoaded;
	public network;
	public filterProducts;
	public filterStatus;
	public reportedIssues: any[] = [];
	public paginateSub$: any;
	private paginating = false;
	public filter = { key: null, value: null };
	// -For Add new product----
	public products: any[] = [];
	public products_modal = 'none';
	public productName;
	public value;
	public showProductDeleteConfirmation = 'none';
	public selectedProduct;
	public selectedProductIndex: any;
	public product_next_url;
	public product = { product: '' };
	public isExternalUser = false;
	public emptyList = false;
	public isFetching = 0;

	public toggle = {};
	public archivedCounts: any;
	public filterString;

	public appLogo = environment.logo;

	constructor(
		private translate: TranslateService,
		private networkService: NetworkService,
		private productService: ProductService,
		private userService: UserService,
		private searchService: SearchService,
		private postService: PostService,
		private layoutService: LayoutService,
		private paginationService: PaginationService,
		private activatedRoute: ActivatedRoute,
		private router: Router
	) {
		this.paginationService.scrollDistance = 8;
		this.paginateSub$ = this.paginationService.initiateScroll.subscribe((data) => {
			if (data && !this.paginating) {
				this.paginate();
			}
		});
	}

	ngOnInit(): void {
		if (localStorage.getItem('networkExpired')) {
			this.router.navigateByUrl('/expired');
		}

		this.getProducts();
		this.getArchivedCounts();
		this.productService.tab = '';

		if (this.networkService.onIssueDetail && this.networkService.previousIssueFilter) {
			this.resetPreviousPage();
		}

		this.showPostCreateForm = false;
		if (localStorage.getItem('network_id')) {
			this.network_id = localStorage.getItem('network_id');
		}

		// For removing backdrop and clearing posttype on closing the issue create form modal
		this.layoutService.showBackdrop.subscribe((data) => {
			if (data === 'event-post-form' || data === 'hide') {
				this.showPostCreateForm = false;
				this.postType = '';
			}
		});

		this.networkService.networkReady.subscribe((networkLoaded) => {
			this.networkLoaded = networkLoaded;
			if (this.networkLoaded) {
				this.networkService.currentNetwork.subscribe((network) => {
					this.network = network;
					this.currentUser = this.userService.getCurrentUser();

					if (network['membership'] && network['membership']['memberRole'] && network['membership']['memberRole']['role']) {
						const memberRole = network['membership']['memberRole']['role'];
						if (memberRole.slug === 'external') {
							this.isExternalUser = true;
						}
					}

					// Filters for issues---
					if (this.network && this.network['uid']) {
						this.postService.getCategories(this.network.uid).subscribe((data) => {
							this.filterProducts = data.issueProducts;
							this.productIssues = data.issueProducts;
						});
					}

					this.networkService.getReportedIssueStatus().subscribe((status) => {
						this.filterStatus = status.IssueStatus;
						this.stateIssues = status.IssueStatus;
					});

					this.departmentsReady = this.networkService.departmentsReady.subscribe((departmentsLoaded) => {
						if (departmentsLoaded) {
							this.currentDepartmentSubscriber = this.networkService.currentDepartments.subscribe((departments) => {
								if (departments && departments['objects'].length > 0) {
									this.departments = departments.objects;
								}
							});
						}
					});

					if (window.location.pathname === '/network/products') {
						this.searchSubscription = this.searchService.searchValue.subscribe((data) => {
							if (this.isFirstSearch && data.trim()) {
								this.isFirstSearch = false;
							}
							if (!this.isFirstSearch) {
								if (data) {
									this.filterString = 'query=' + data.trim();
								} else {
									this.getProducts();
								}
							}
						});
					}
				});
			}
		});
	}

	ngOnDestroy(): void {
		if (this.departmentsReady) {
			this.departmentsReady.unsubscribe();
		}
		if (this.currentDepartmentSubscriber) {
			this.currentDepartmentSubscriber.unsubscribe();
		}
		if (this.paginateSub$) {
			this.paginateSub$.unsubscribe();
		}
	}

	/**
	 * Function to display issue create form
	 */
	public activateButton(): void {
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
		this.showPostForm();
	}

	/**
	 * Function to show post form for creating an issue
	 */
	private showPostForm(): void {
		this.showPostCreateForm = !this.showPostCreateForm;
		if (this.showPostCreateForm) {
			this.layoutService.showBd('event-post-form');
			this.postType = 'add-issue';
		} else {
			this.postType = '';
			this.layoutService.hideBd('event-post-form');
		}
	}

	public filterIssuesCollections(key, value, uid): void {
		if (uid) {
			this.filter.value = uid;
		} else {
			this.filter.value = value;
		}

		this.displayIssuesLists = false;
		if (key === 'assignedUser') {
			this.filterString = key + '=' + localStorage.getItem('uid'); // 1
		} else {
			this.filterString = key + '=' + value; // 1
		}
	}

	/**
	 * Function to reset previous issues page
	 * 1. Hide issue collections list
	 * 2. Set postUrl for displaying all issue posts
	 * 3. Reset variables on service for issues
	 */
	private resetPreviousPage(): void {
		this.displayIssuesLists = false; // 1
		this.previousFilterString = this.networkService.previousIssueFilter;
		this.postUrl = '/users/' + localStorage.getItem('uid') + '/issues/?network=' + localStorage.getItem('network_id') + '&' + this.previousFilterString; // 2
		this.networkService.onIssueDetail = false; // 3
		this.networkService.previousIssueFilter = '';
	}

	/**
	 * Function to get the department name based on department Id.
	 * Used in template
	 * @param value
	 */
	public getDepartment(value): void {
		const index = this.departments.findIndex((department) => department.uid === value);
		if (index !== -1) {
			return this.departments[index].name;
		}
	}

	/**
	 * Function to show post cp names of personal filters/memberships
	 * @param value
	 * 1. If content privacy(value) is status,show it.
	 * 2. If content privacy(value) is renewal status,show it.
	 * 3. Check if content privacy(value) is membershipLevel
	 * 4. Get membership level name from array
	 * 5. If value is manager,show cp as myTeam
	 * 6. If value is in personal filters,show it
	 */
	public getPostNames(value): string {
		if (value.status) {
			return value.status; // 1
		} else if (value.renewalStatus) {
			return value.renewalStatus; // 2
		} else if (value.membership_level) {
			// 3
			let name = '';
			this.selectByLevelValues.forEach((object) => {
				if (object.uid === value.membership_level) {
					name = object.name; // 4
				}
			});
			return name;
		} else if (value.manager) {
			return this.translate.instant('My Team'); // 5
		} else {
			this.value = Object.values(value);
			const index = this.personalFilters.findIndex((field) => field.value === this.value);
			if (index !== -1) {
				return this.personalFilters[index].valueLabel; // 6
			}
		}
	}

	public getIssuesByProduct(product): void {
		this.filter.value = product['uid'];
		this.filterString = product['uid'];
	}

	/**
	 * Function for getting products
	 * 1. Call api to get products
	 * 2. List products in products array
	 * 3. Store next url if exists
	 */
	public getProducts(): void {
		this.filter.value = '';
		this.filterString = '';
		this.products = [];
		this.isFetching = 0;
		this.productService.getProducts().subscribe((data) => {
			// 1
			this.products = data.objects; // 2
			this.calculateBarIndicator();
			this.isFetching = 1;

			this.next_url = data.next ? data.next.split('alpha')[1] : '';
		});
	}

	/**
	 * Function for handling pagination of products
	 * 1. Check if next url exists
	 * 2. Call api to get remaining products
	 * 3. Append new products to products list
	 * 4. Store next url if exists
	 */
	public paginate(): void {
		if (this.next_url) {
			// 1
			this.paginating = true;
			this.isFetching = 0;
			this.networkService.paginateIssues(this.next_url).subscribe((data) => {
				// 2
				this.products = this.products.concat(data.objects); // 3
				this.calculateBarIndicator();

				this.paginating = false;
				this.isFetching = 1;

				this.next_url = data.next ? data.next.split('alpha')[1] : '';
			});
		}
	}

	private calculateBarIndicator(): void {
		this.products.forEach((product) => {
			if (product['barIndicator']) {
				const fixed = product['barIndicator']['fixed'];
				const open = product['barIndicator']['open'];
				const net = product['barIndicator']['net'];
				const bar = fixed + open + net;
				product['bar'] = bar;
			}
		});
	}

	/**
	 * Get product state counts
	 * @param prod
	 * 1. Check if product cell is not collapsed
	 * 2. Call api to get counts
	 * 3. Set counts of state within product
	 * 4. Set collapse false after a sec to show shimmer loader correctly
	 */
	public getProductCounts(prod): void {
		if (!prod['collapse']) {
			// 1
			this.productService.getProductCount(prod['uid']).subscribe((data) => {
				// 2
				this.products.forEach((product) => {
					if (product['uid'] === prod['uid']) {
						// 3
						product['collapse'] = true;
						product['closedCount'] = data['closedCount'];
						product['fixedCount'] = data['fixedCount'];
						product['inProgressCount'] = data['inProgressCount'];
						product['openCount'] = data['openCount'];
						product['poolCount'] = data['poolCount'];
						product['releasedCount'] = data['releasedCount'];
						product['poolCount'] = data['poolCount'];
					}
				});
			});
		} else {
			setTimeout(() => {
				prod['collapse'] = false; // 4
			}, 500);
		}
	}

	/**
	 * Function to get archived products counts
	 */
	private getArchivedCounts(): void {
		this.productService.getArchivedCounts().subscribe((data) => {
			this.archivedCounts = data['archivedCounts'];
		});
	}

	public navigateProductDetail(product): void {
		const url = `/network/product-detail?id=${product.uid}`;
		this.router.navigateByUrl(url);
	}
}
