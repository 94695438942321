import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ZoomComponent } from '../zoom/zoom.component';
import { NoAuthGuard } from './no-auth-guard.service';
import { SharedModule } from '../shared';
import { ZoomAppAuthComponent } from '../zoom-app-auth/zoom-app-auth.component';
import { OauthComponent } from '../oauth/oauth.component';
import { PopupComponent } from '../popup/popup.component';
import { AuthComponent } from './auth.component';
import { CouponGuard } from '../modules/new-auth/guards/coupon.guard';

const authRouting: ModuleWithProviders<any> = RouterModule.forChild([
	{
		path: 'login',
		redirectTo: 'authentication/login',
	},
	{
		path: 'sso-login',
		redirectTo: 'authentication/sso',
	},
	{
		path: 'appsumo/:coupon',
		component: AuthComponent,
		canActivate: [CouponGuard],
	},

	{
		path: 'f6s/:f6sCoupon',
		component: AuthComponent,
		canActivate: [CouponGuard],
	},
	{
		path: 'ces/:cesCoupon',
		component: AuthComponent,
		canActivate: [CouponGuard],
	},
	{
		path: 'appsumo',
		component: AuthComponent,
		canActivate: [CouponGuard],
	},

	{
		path: 'f6s',
		component: AuthComponent,
		canActivate: [CouponGuard],
	},

	{
		path: 'ces',
		component: AuthComponent,
		canActivate: [CouponGuard],
	},

	{
		path: 'register',
		redirectTo: 'authentication/register',
	},
	{
		path: 'zoom-auth',
		component: ZoomComponent,
	},
	{
		path: 'authenticate-zoom',
		component: ZoomAppAuthComponent,
	},
	{
		path: 'show-popup',
		component: PopupComponent,
	},
	{
		path: 'oauth',
		component: OauthComponent,
	},
]);

@NgModule({
	imports: [authRouting, SharedModule],
	declarations: [],

	providers: [NoAuthGuard],
})
export class AuthModule {}
