import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeLayoutComponent } from '@layouts/home-layout/home-layout.component';
import { JoinWorkspaceComponent } from './pages/join-workspace/join-workspace.component';
import { WorkspacesComponent } from './pages/workspaces/workspaces.component';
import { HideQuickChatResolver } from '@modules/chat/components/quick-chat/resolvers/hide-quick-chat.resolver';

const routes: Routes = [
	{
		path: '',
		component: HomeLayoutComponent,
		resolve:{
			hideQuickChat: HideQuickChatResolver
		},

		children: [
			{
				path: '',
				component: WorkspacesComponent,
			},
			{
				path: 'join-workspace',
				component: JoinWorkspaceComponent,
			},

			{ path: '', redirectTo: 'workspaces', pathMatch: 'full' },
			{ path: '**', redirectTo: 'workspaces', pathMatch: 'full' },
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class HomeRoutingModule {}
