/* eslint-disable spellcheck/spell-checker */
import {
	Component,
	OnInit,
	OnChanges,
	SimpleChanges,
	ViewChildren,
	ViewChild,
	QueryList,
	ElementRef,
	Input,
	ChangeDetectorRef,
	Output,
	EventEmitter,
	OnDestroy,
} from '@angular/core';
import { PrivacyComponent } from '../../privacy/privacy.component';
import { User, Post, LocationObj, Reminder, Subtask, Todo, ITodo } from '../../models';
import { PostService, NetworkService, UserService, MemberService, LocationService, GeoLocationService, LayoutService } from '../../services';
import { TimeZoneService } from '../../services/time-zone.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Subject, Subscription, timer } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DueDateComponent } from '../../due-date/due-date.component';
import * as moment from 'moment';
import { ProjectService } from '@modules/project/services/project.service';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';
import CacheUtils from '@shared/utils/cache-utils';
import { environment } from 'src/environments/environment';
import { SegmentAnalyticsService } from '@core/services/third-party/segment-analytics.service';
import { MixPanelService } from '@core/services/third-party/mix-panel.service';

// tui implementation
import Editor from '@toast-ui/editor';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight-all.js';
import Prism from 'prismjs';
import 'prismjs/components/prism-clojure.js';
import Viewer from '@toast-ui/editor/dist/toastui-editor-viewer';
import { Router } from '@angular/router';

const CSS_CLASS_NAMES = {
	highLight: 'dd-highlight-item',
};

@Component({
	selector: 'app-post-edit',
	templateUrl: './post-edit.component.html',
	styleUrls: ['./post-edit.component.css'],
})
export class PostEditComponent implements OnInit, OnChanges, OnDestroy {
	@ViewChildren('textArea') public textArea: QueryList<ElementRef>;
	@ViewChild('modelBody', { static: false }) public modelBody: ElementRef;
	@ViewChild('modelTitle', { static: false }) public modelTitle: ElementRef;
	@ViewChild('memberList', { static: true }) public memberList: ElementRef;
	@ViewChild('choicesInput', { static: true }) public choicesInput: ElementRef;
	@ViewChild(PrivacyComponent, { static: true }) public privacy;
	@ViewChild(DueDateComponent, { static: true }) public dueDateComponent;
	@Input() public postType = 'post';
	@Input() public showMainPostForm = false;
	@Input() public post: any;
	@Input() public todoListPrivacy: any;
	@ViewChild('postEditModal', { static: true }) public postEditModal;
	@Input() public currentPage = 'post-list';
	// For resetting issue detail page after edit
	@Output() public resetIssueAnalyticsPage: EventEmitter<any> = new EventEmitter();
	@Output() public resetTodoDetailPage: EventEmitter<any> = new EventEmitter();
	@Output() public resetCustomerDetailPage: EventEmitter<any> = new EventEmitter();
	@Output() public newCustomerDetailAddedEmitter: EventEmitter<any> = new EventEmitter();
	@Output() public taskUpdatedEmitter: EventEmitter<any> = new EventEmitter();

	public isNeaty = environment.appName === 'Neaty';
	public selectedDate: any;
	public memberForTaskEdit: any;
	public object = { value: '' };
	// -For additional options in todo form
	public meridian = false;
	public spinners = false;
	public selectedDueDate: any;
	public showPostForms = false;
	public dueTimeDisabled = false;
	public showTaskTimeError = false;
	public isTaskReminderSelected = false;
	public showRepeats = false;
	public displayAdditionalModal = 'none';
	public showReminders = false;
	public taskDueTime: any;
	public taskRepeatOption = 'never';
	public displayPostLocations = 'none';
	public taskReminders = [];
	public taskReminderOption = ['none', 'None'];
	public repeats = ['never', 'daily', 'weekly', 'monthly', 'yearly'];
	public eventReminders = [
		['event_time', 'Due Time'],
		['five_minutes_before', 'Five Minutes Before'],
		['thirty_minutes_before', 'Thirty Minutes Before'],
		['one_hour_before', 'One Hour Before'],
		['two_hour_before', 'Two Hour Before'],
	];

	public postReminders = [
		['in_twenty_minutes', 'In Twenty Minutes'],
		['in_one_hour', 'In One Hour'],
		['in_three_hours', 'In Three Hours'],
		['tomorrow', 'Tomorrow'],
		['next_week', 'Next Week'],
	];
	public currentType = 'issue';
	public selectedType = 'issue';
	public displayTypes = 'none';
	public types = ['issue', 'enhancement', 'feature'];
	private reminderObject = {
		username: '',
		contentUid: '',
		contentType: '',
		repeat: false,
	};
	public timeForReminder: any;
	public reminderMessage;
	public toast_message = 'none';
	public editTaskReminderId;
	public todoReminderObject: any;
	@Output() public updatedReminderEmitter = new EventEmitter();
	// New task forms ui variables
	public showTaskForms = false;
	public displayTaskOptions = false;
	public displayIssueOptions = false;
	public displayDueTimeModal = 'none';
	public displayRepeats = 'none';
	public scrollStatus = false;
	public currentRepeat = '';
	public repeatSelected = 'never';
	public displayTaskReminders = 'none';
	public currentDueTime: any = '';
	public displayTaskShortCuts = false;
	public displayCustomerAdditionalOptions = false;
	public showTaskEditForm = false;
	// For new issue form
	public currentSelectedPriority = 'p1';
	public displayIssuePriorities = 'none';
	public displayCustomers = 'none';
	public customers = [];
	public nextCustomerUrl;
	public customerObj = { uid: '' };
	public selectedCustomer;
	public currentIssueCustomer = '';
	public issuePriorities: any = ['P1', 'P2', 'P3'];
	// -New Customer Options variables
	public displaySalesModal = 'none';
	public displayDealsModal = 'none';
	public displayTypesModal = 'none';
	public displayCurrencyModal = 'none';
	public displayContactDetails = false;
	public customerContactName;
	public salesSelected;
	public dealSelected;
	public typeSelected;
	public currencySelected = 'TRY';
	public customerPrice: any;
	public currentSaleChannel = '';
	public currentDealType = '';
	public currentCustomerType = '';
	public currentCurrencyType = 'TRY';

	public salesChannels = [
		{ option: 'None', slug: 'none' },
		{ option: 'Ads', slug: 'ads' },
		{ option: 'Search', slug: 'search' },
		{ option: 'Social Media', slug: 'social_media' },
		{ option: 'Reference', slug: 'reference' },
		{ option: 'Partner', slug: 'partner' },
	];
	public dealTypes = [
		{ option: 'None', slug: 'none' },
		{ option: 'Post Sales', slug: 'post_sales' },
		{ option: 'Sales', slug: 'sales' },
		{ option: 'Services', slug: 'services' },
	];
	public customerTypes = [
		{ option: 'None', slug: 'none' },
		{ option: 'Business', slug: 'business' },
		{ option: 'Individual', slug: 'individual' },
		{ option: 'Non Profit', slug: 'non_profit' },
		{ option: 'Education', slug: 'education' },
		{ option: 'Retail', slug: 'retail' },
		{ option: 'Health', slug: 'health' },
		{ option: 'Government', slug: 'government' },
		{ option: 'Municipality', slug: 'municipality' },
	];
	public currencyTypes = ['USD', 'EUR', 'TRY', 'INR'];
	public showCountryModal = 'none';
	public field = { value: '' };
	public selectedCountry;
	public countries: any[] = [];
	public nextCUrl;
	public countryCode;
	public searchString;
	public isCustomer = false;
	public displayCountryError = false;
	public additionalContactForm: FormGroup;
	public currentSubscription;
	private currentNetSub;
	public lockCustomer = false;
	public displayMemberForAttachment = 'none';
	public attachmentMembers = [];
	public selectedMember = { uid: '' };
	public attachmentMember;
	// --------------------------
	public isListsLoading = false;
	public meeting_password;
	public show: boolean;
	public meeting_duration;
	public fileUpload = false;
	public articleUpload = false;
	public removeUploads = false;
	public currentUser: User;
	public network;
	public titleFocus = true;
	public members: any[] = [];
	public selectedMembers: any[] = [];
	public personalFilterString: any = null;
	public showSpin;
	public posts;
	private currentDepartmentSubscriber;
	private departmentsReady;
	public nextUrl;
	public filters;
	public photo_upload = false;
	public question_image = false;
	public recommendationPost = false;
	public event_post = false;
	public classified = false;
	public showBg = false;
	public isLoading = true;
	public post_categories;
	public ordinary_post = true;
	public article = false;
	public member_display = 'none';
	public post_type = {};
	public selected_post: Post;
	public errors;
	public networkLoaded;
	public saved_post;
	public postUrl;
	public location_display = 'none';
	public locations;
	public displayError = 'none';
	public currentLocation;
	public errorMessage: string;
	public currentRate = 8;
	public startDate: any;
	public endDate: any;
	public startDateClick = false;
	public endDateClick = false;
	public eventImageExists = false;
	public eventImages: any;
	public selectedEventType: any = null;
	public timeZone: string = null;
	public tempPrice: any = null;
	public nextMembersUrl = '';
	public value;
	public memberModelType = null;
	public privacy_settings = 'none';
	public custom_privacy_display = false;
	public custom_privacy: any[] = [];
	public totalMembersCount = 0;
	public channels;
	public roles: any[] = [];
	public privacyMemberList: any[] = [];
	public membership: any = null;
	public userRole: any = null;
	public departments: any[] = [];
	public role: any;
	public selectedDepartment = '';
	public roleString: any = null;
	public departmentString: any = null;
	// question post edit
	public choices: any[] = [];
	public choiceValue: any;
	public choiceIndexContainer: any[] = [];
	public question_choices: any[] = [];
	public removeImage = true;
	public imageIndex: any;
	// Project
	public selectedProject;
	public displayProjectsModal = 'none';
	public project = { id: '', name: 'Project' };
	public project_next_url;
	public projects: any[] = [];
	public noProjects = false;
	public productCategories;
	public productCondition;
	public privacyModel: any;
	public showBackdrop: any;
	public enlargeForm: any;
	// public showMainPostForm = false;
	public isQuickPost = false;
	public showPostType = false;
	public selectedPostTypeLabel = 'Post';
	public isFree = false;
	public showMoreOptions = false;
	// Membership cp-----
	public selectByLevelValues: any[] = [];
	public selectByLevelName;
	public selectByStatusString: any = null;
	public selectByRenewalString: any = null;
	public selectByLevelString: any = null;
	// For add an issue---
	public selectPriorityArray: any = ['P1', 'P2', 'P3'];
	public selectedPriority;
	public strippedTitle;
	public newIssueTitle;
	// ToDo Item edit
	public display_options = 'none';
	public display_todoLists = 'none';
	public display_priority = 'none';
	public display_members = 'none';
	public repeatOptions = [
		{ option: 'Today', slug: 'daily' },
		{ option: 'Tomorrow', slug: 'one_off' },
		{ option: 'This week', slug: 'weekly' },
		{ option: 'This month', slug: 'monthly' },
		{ option: 'Specify Date', slug: 'one_off' },
	];

	public priorities = ['P1', 'P2', 'P3'];
	public repeatObj = { option: '' };
	public memberObj = { option: '' };
	public selectedOption = { option: '' };
	public todoListMembers: any[] = [];
	public SubscriptionTemplates = [];
	public assignedMembers: any[] = [];
	public selectedRepeatOption = { option: '', slug: '' };
	public defaultTaskMember: any;
	public next_url;
	public todoItemUid;
	public itemMember: any;
	public priority_p1 = false;
	public priority_p2 = false;
	public priority_p3 = false;
	public display_date = false;
	public oneOff_date: any;
	public confirm_members = false;
	// -Post todo create
	public showSelectTodoListOption = false;
	public todoLists: any[] = [];
	public todo_next_url;
	public lists = { name: '', uid: '' };
	public listPrivacy: any;
	public todoList_uid;
	// New Project
	public displayProjectForm = 'none';
	public forNames;
	public isProjectCreateForm = false;
	public projectName;
	public projectError = false;
	public newProject = { name: '' };
	public defaultCp;
	public membershipFields;
	public currentNetworkDefaultCp;
	public externalUserChannel: any;
	public isExternalUser = false;
	public multipleExternalUser = false;
	// --------------------------
	public showAdditionalOptions = false;
	public displayBuySellLocations = 'none';
	public buySellCities = [];
	public searchCityQuery;
	public noCities = false;
	// ================
	public selectedCity = { name: this.translate.instant('Location'), uid: '' };
	public searchList: any[] = [];
	public hashTagList: any[] = [];
	public showUserSuggestion = false;
	public showHashTagSuggestion = false;
	private lastPrivacy = localStorage.getItem('lastPrivacy') ? JSON.parse(localStorage.getItem('lastPrivacy')) : null;
	public tempImages: any[] = [];
	public questionImages: any[] = [];
	public maxPhotoUploadCount = 10;
	public showPostCustomPrivacy = false;
	public doc_upload = false;
	private documentList = ['XLSX', 'XLS', 'DOC', 'DOCX', 'PPT', 'PPTX', 'TXT', 'PDF', 'CSV'];
	private audioList = ['MP3', 'WAV'];
	private videoList = ['MP4', 'MOV'];
	public fileType = [];
	public personalFilters = [];
	private userSuggestionString = '';
	private hashSuggestionString = '';
	private range = -1;
	public showEventImageSelector = false;
	public startDateString = '';
	public endDateString = '';
	public showStartPicker = false;
	public showEndPicker = false;
	public nextHashUrl;
	public nextUserUrl;
	public userSearch = '';
	public hashTagSearch = '';
	public personals = [];
	// Create todolist variables
	public displayCreateTodoList = 'none';
	public isTodolistCreateForm = false;
	public repeatCycleOptions = ['daily', 'weekly', 'monthly', 'one-off'];
	public TodoListRepeatCycle = 'daily';
	public displayDateField = false;
	public todoListDate = {
		year: new Date().getFullYear(),
		month: new Date().getMonth() + 1,
		day: new Date().getDate(),
	};
	public todolist = {
		name: '',
		description: '',
		collaboration: true,
		repeatCycle: 'daily',
		state: 'active',
		statusPost: true,
	};
	public todoListCreateError;
	public isTitle = false;

	// add customer action
	public customer = {
		title: '',
		status: '',
		priority: '',
		category: 'customer',
		type: 'customer',
		state: 'active',
		shareable: true,
		commentable: true,
		contentType: 'network',
	};
	public customerUid;
	public assignee: any[] = [];
	public member_nextUrl;
	public noAssignee = true;
	public displayModalForCustomer = 'none';
	public displayPriorities = 'none';
	public displayStates = 'none';
	public customerSelected;
	public currentCustomer;
	public prioritySelected;
	public currentPriority;
	public priority;
	public currentState;
	public stateSelected;
	public isCustomerSubmitted = false;
	public selectedCustomerUid;
	public customerStates = [];
	public priorityOptions = [
		{ name: 'High', slug: 'p1' },
		{ name: 'Med', slug: 'p2' },
		{ name: 'Low', slug: 'p3' },
	];
	// =======================
	@ViewChild('panel', { static: false }) public panel: ElementRef<any>;
	private arrowKeyLocation = -1;
	private keyupSubscription: Subscription;
	public isMentions = false;
	public mentionCount = 0;
	public mentionType;
	public userMentions = [];
	public hashMentions = [];
	public nextMentionUrl;
	public mentionPrivacy: any;
	public CaretPosition: any;
	private subject: Subject<string> = new Subject();
	public mentionsFetched = 0;
	public hashes = [];
	public shiftKeyUsed = false;
	public userRegexFailed = false;
	public hashRegexFailed = false;
	public mentionIndex;

	// NGB Date-picker Variables
	public placement = 'right';
	public modelB = {
		year: new Date().getFullYear(),
		month: new Date().getMonth() + 1,
		day: new Date().getDate(),
	};
	public currentDate = {
		year: new Date().getFullYear(),
		month: new Date().getMonth() + 1,
		day: new Date().getDate(),
	};

	public taskForReminder;
	public reminderId;
	public reminderTime;
	public timeFor;
	public remindersSelected = [];
	public previousReminders = [];
	public reminderModel = new Reminder();
	public onConfirm = false;

	// Subtasks
	public subtaskValue: any;
	public subtasksIndexContainer: any[] = [];
	public subtasks: any[] = [];
	public currentSub;
	public subAssignee;
	public memberUid;
	public displaySubAssignee = false;
	public displaySubAssigneeModal = 'none';
	public displaySubDueDateOptions = 'none';
	public sub = 'daily';
	public subRepeats = ['daily', 'tomorrow', 'weekly', 'monthly', 'one_off'];
	public subtaskDate = {
		year: new Date().getFullYear(),
		month: new Date().getMonth() + 1,
		day: new Date().getDate(),
	};
	public subtask = new Subtask();
	public isSubtask = false;
	public native;
	public index;
	public query;
	private customerSubject: Subject<string> = new Subject();
	public isCustomersFetching = 0;

	// ===Ngx mat timepicker
	public date = new Date();
	public starts: any;
	public ends: any;
	public eventStartDate: any;
	public eventEndDate: any;
	public minDate = {
		year: this.date.getFullYear(),
		month: this.date.getMonth() + 1,
		day: this.date.getDate(),
	};
	public duration: any;
	// Ngb-date-picker vars

	public selectedTaskForEdit: any;
	private subTasksToBeDeleted = [];
	private subTasksToBeAdded = [];

	@Output() public updatedPostEmitter = new EventEmitter();
	@Output() public updatedCustomerEmitter = new EventEmitter();
	@Output() public onCloseEdit = new EventEmitter();
	// ====
	@Output() public reminderEmitter = new EventEmitter();

	// ===

	public model = new Post(
		{
			title: '',
			body: null,
			category: null,
			privacy: this.lastPrivacy,
			images: [],
			preview_images: [],
			shareable: true,
			commentable: true,
			channel: '',
		},
		true
	);

	private issueModel = {
		category: 'bugs',
		network: localStorage.getItem('network_id'),
		collection_post: true,
		type: 'issue',
		username: localStorage.getItem('uid'),
		commentable: true,
		contentType: 'network',
		state: 'active',
		shareable: true,
		description: '',
		priority: '',
		products: '',
		title: '',
	};

	// public todoItem = {
	//   'category': 'todo',
	//   'network': localStorage.getItem('network_id'),
	//   'collection_post': true,
	//   'commentable': true,
	//   'contentType': 'network',
	//   'priority': '',
	//   'repeatCycle': '',
	//   'shareable': true,
	//   'state': 'active',
	//   'title': '',
	//   'type': 'todo'
	// }
	public todoItem = new Todo();

	private submitted = false;

	public projectPrivacyCp: any;

	public errorDialogForCpSelection = 'none';

	public errorTextForCpSelection = '';

	@Input() public projectDetail: any;

	private parentTaskUID: string;

	private taskType: 'task' | 'subTask' = 'task';

	public tuiViewer: Viewer = null;
	public tuiEditor: Editor | null = null;

	constructor(
		private postService: PostService,
		private parserFormatter: NgbDateParserFormatter,
		private networkService: NetworkService,
		private fb: FormBuilder,
		private translate: TranslateService,
		private userService: UserService,
		private memberService: MemberService,
		private locationService: LocationService,
		private geoLocationService: GeoLocationService,
		private projectService: ProjectService,
		private ref: ChangeDetectorRef,
		public layoutService: LayoutService,
		public timeZoneService: TimeZoneService,
		private pageLoadingUiService: PageLoadingUiService,
		private mixPanelService: MixPanelService,
		private segmentService: SegmentAnalyticsService,
		private router: Router
	) {
		if (localStorage.getItem('networkExpired')) {
			this.router.navigateByUrl('/expired');
		}
		this.additionalContactForm = this.fb.group({
			houseNo: [''],
			streetOne: [''],
			streetTwo: [''],
			city: [''],
			state: [''],
			country: ['Turkey'],
			code: [90],
			zip: [''],
			phone: ['', [Validators.pattern('^[0-9]*$')]],
			email: [''],
		});
	}

	ngOnInit(): void {
		if (localStorage.getItem('membership')) {
			this.membership = JSON.parse(localStorage.getItem('membership'));
			this.userRole = this.membership['memberRole'].role;
			this.membership['memberRole'].fields.forEach((element) => {
				this.departmentsReady = this.networkService.departmentsReady.subscribe((departmentsLoaded) => {
					if (departmentsLoaded) {
						this.currentDepartmentSubscriber = this.networkService.currentDepartments.subscribe((departments) => {
							if (departments && departments['objects'].length > 0) {
								this.departments = departments.objects;
							}
						});
					}
				});
				if (element.CP === true) {
					this.personals.push(element);
				}
			});
		}
		if (localStorage.getItem('roles')) {
			this.roles = JSON.parse(localStorage.getItem('roles'));
		}

		this.networkService.currentNetwork.subscribe((network) => {
			this.network = network;
			this.channels = network['channels'];

			/** 1. For checking if customer service exists in network, also check whether the current plan is free tier
			 * 2. If exists,display customer selection in issue create form
			 */
			if (this.network.services && this.network.services.length > 0) {
				const index = this.network.services.findIndex((service) => service.slug === 'add-customer');
				if (index > -1 && network?.subscription?.planQuotas?.numberOfCustomers >= 0) {
					this.isCustomer = true;
				} else {
					this.isCustomer = false;
				}
			}

			/** For handling customer selection in issue create form.
			 * 1. Check if current subscription is either premium or business.
			 * 2. If premium,check is subscription is purhcased or not
			 * 3. If purchased,lock customer selection,else unlock it.
			 * 4. If subscription is business,unlock customer selection
			 */
			this.networkService.subscriptionReady.subscribe((subscriptionLoaded) => {
				if (subscriptionLoaded) {
					this.currentNetSub = this.networkService.currentSubscription.subscribe((subscription) => {
						if (subscription['subscriptionPlan']) {
							this.currentSubscription = subscription['subscriptionPlan'];
							if (this.currentSubscription?.name === 'Premium' || this.currentSubscription?.name === 'Basic') {
								// 1
								if (subscription && !subscription['purchasedPlan']) {
									// 2
									this.lockCustomer = false; // 3
								} else {
									this.lockCustomer = true;
								}
							} else {
								this.lockCustomer = false; // 4
							}
						}
					});
				}
			});

			if (network.membership && network.membership.memberRole) {
				this.defaultCp = network['membership']['memberRole']['defaultContentPrivacy'];
				this.membershipFields = network['membership']['memberRole']['fields'];
				this.membershipFields.forEach((field) => {
					if (this.currentPage !== 'collection' && this.currentPage !== 'channel-detail') {
						if (field.key === this.defaultCp) {
							if (!localStorage.getItem(`${this.network.uid}`)) {
								this.currentNetworkDefaultCp = field.valueLabel;
								this.forNames = field.valueLabel;
								this.privacy['personalFilter'] = field;
								this.privacy.previousSelection = field.valueLabel;
								this.privacy.currentSelection = field.valueLabel;
								this.privacy.personalFilterValue = field.valueLabel;
							}
						}
					} else {
					}
				});

				if (network['membership']['memberRole'] && network['membership']['memberRole']['role']) {
					const memberRole = network['membership']['memberRole']['role'];
					if (memberRole.slug === 'external') {
						if (network.channels.length > 0) {
							const index = network.channels.findIndex((channel) => channel.access === 4);
							this.externalUserChannel = network.channels[index];
						}
						if (network.channels && network.channels.length > 0) {
							const externalChannels = [];
							network.channels.forEach((channel) => {
								if (channel['access'] === 4) {
									externalChannels.push(channel);
								}
							});
							if (externalChannels.length > 1) {
								this.isExternalUser = true;
								this.multipleExternalUser = true;
							} else {
								this.isExternalUser = true;
							}
						}
					}
				}
			}

			// Subscription templates---
			if (this.network.SubscriptionTemplates && this.network.SubscriptionTemplates.length > 0) {
				this.network.SubscriptionTemplates.forEach((object) => {
					if (object.cpEnabled === true) {
						this.selectByLevelValues.push(object);
					}
				});
				this.SubscriptionTemplates = this.network.SubscriptionTemplates;
			}
		});

		this.networkService.networkReady.subscribe((networkLoaded) => {
			this.networkLoaded = networkLoaded;
		});
		this.userService.currentUser.subscribe((userData) => {
			this.currentUser = userData;
		});
		if (this.networkLoaded) {
			this.postUrl = '/networks/' + this.network.uid + '/posts/';
			// this.postService.getCategories(this.network.uid).subscribe((data) => {
			//   this.post_categories = data.objects.filter(item => item.serviceCategory === false);
			//   this.model.category = this.post_categories[0].slug;
			// });

			for (const res of this.network.services) {
				this.post_type[res.slug] = res.slug === 'post';
			}

			const self = this;
			let locations = [];
			const accuracy = { enableHighAccuracy: true };

			self.geoLocationService.getLocation(accuracy).subscribe((position: any) => {
				self.currentLocation = position;

				self.locationService.getNearMeLocations(position.coords).subscribe((data) => {
					locations = data.response.groups[0].items.map((loc) => new LocationObj(loc, '4sq'));

					self.locations = self.sortData(locations);
				});

				self.ref.detectChanges();
				this.pageLoadingUiService.setPageLoading(false);
			});
		}
		this.layoutService.showBackdrop.subscribe((data) => {
			if (data === 'post-edit') {
				this.showMainPostForm = false;
				this.post = null;
			} else if (data === 'post_locations') {
				this.location_display = 'none';
			}
		});
		this.layoutService.clickOutside.subscribe((data) => {
			if (
				data['srcElement'].className === 'dropdown pull-right user-suggestion' ||
				(data['srcElement'].attributes.id &&
					(data['srcElement'].attributes.id.nodeValue === 'hash-search' || data['srcElement'].attributes.id.nodeValue === 'hash-view-more'))
			) {
				if (this.showUserSuggestion) {
					this.showUserSuggestion = true;
				}
				if (this.showHashTagSuggestion) {
					this.showHashTagSuggestion = true;
				}
			} else {
				this.showUserSuggestion = false;
				this.showHashTagSuggestion = false;
				this.userSearch = '';
				this.hashTagSearch = '';

				this.mentionsFetched = 0;
				this.userMentions = [];
				this.hashMentions = [];
			}
		});

		this.privacy.privacyModalStatus.subscribe((data) => {
			if (data === 'closed') {
				if (this.isTodolistCreateForm) {
					this.displayCreateTodoList = 'block';
					this.display_todoLists = 'block';
					this.updatePrivacyStringBasedOnPrivacyComponent();
				}
			}
			this.checkPrivacyForMentions();
		});

		/** For handling @/# mentions searches
		 * 1. Call api for getting search results
		 * 2. If mention type is user,fill userMentions array
		 * 3. Else fill hashMentions array
		 * 4. If nextUrl exists,set nextUrl and call paginate function
		 */
		this.subject.pipe(debounceTime(400)).subscribe((searchTextValue) => {
			// 1

			const i = searchTextValue.indexOf('@');
			if (i > -1) {
				searchTextValue = searchTextValue.substring(i + 1);
			}
			this.mentionsFetched = 1;
			this.postService.getMentions(searchTextValue, this.mentionType, this.mentionPrivacy).subscribe((data) => {
				this.mentionsFetched = 0;
				this.arrowKeyLocation = -1;
				this.mentionCount = data.count;

				if (
					(this.model.title && !this.isMentions) ||
					(!this.model.title && this.isMentions) ||
					(this.model.title && this.isMentions) ||
					(this.model.body && !this.isMentions) ||
					(!this.model.body && this.isMentions) ||
					(this.model.body && this.isMentions)
				) {
					if (!this.userRegexFailed || !this.hashRegexFailed) {
						if (this.mentionType === 'user') {
							// 2
							this.hashMentions = [];
							this.userMentions = data.objects;
						} else if (this.mentionType === 'hash') {
							this.userMentions = []; // 3
							const filterData = [];
							data.objects.forEach((item: any) => {
								if (item.name !== '') {
									filterData.push(item);
								}
							});
							this.hashMentions = filterData;
						}
						if (data.next) {
							// 4
							this.nextMentionUrl = data.next.split('alpha')[1];
							this.paginateMentions();
						} else {
							this.nextMentionUrl = '';
						}
					}
				}
			});
		});

		this.customerSubject.pipe(debounceTime(400)).subscribe((query) => {
			this.customers = [];
			this.networkService.searchCustomers(query).subscribe((data) => {
				this.isCustomersFetching = 1;
				this.customers = data['objects'];
				if (data.next) {
					this.nextCustomerUrl = data.next.split('alpha')[1];
				} else {
					this.nextCustomerUrl = '';
				}
			});
		});

		/**
		 * Execute after dueDate calendar popup is closed
		 */
		this.dueDateComponent.modalStatus.subscribe((data) => {
			if (data === 'closed') {
				this.setTodoOptions();
			}
		});
	}

	ngOnDestroy(): void {
		if (this.departmentsReady) {
			this.departmentsReady.unsubscribe();
		}
		if (this.currentDepartmentSubscriber) {
			this.currentDepartmentSubscriber.unsubscribe();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.hasOwnProperty('post')) {
			if (this.post) {
				this.clearSubtasks();
				this.privacy['customPrivacyUserIds'] = [];
				this.privacy['custom_privacy'] = [];
				this.privacy['role'] = '';
				this.privacy['selectedDepartment'] = '';
				this.errorTextForCpSelection = '';
				this.projectPrivacyCp = '';
				this.showPostCustomPrivacy = false;
				if (this.post.type !== 'postTodo' && this.post.type !== 'todo' && this.post.type !== 'sub_task' && this.post.type !== 'issue' && !this.isExternalUser) {
					this.updatePrivacy();
				}
				if ((this.post.type === 'issue' || this.post.type === 'feature' || this.post.type === 'enhancement') && !this.post['addTaskForIssue']) {
					this.updateIssue();
				}
				if (
					this.post.type !== 'issue' &&
					this.post.type !== 'feature' &&
					this.post.type !== 'enhancement' &&
					this.post.type !== 'todo' &&
					this.post.type !== 'postTodo' &&
					this.post.type !== 'customer' &&
					this.post.type !== 'sub_task'
				) {
					// if(this.post.custom_privacy?.length > 0){
					// 	this.privacy['custom_privacy'] = [...this.post.custom_privacy];
					// }
					this.showPostEditModal();
				} else if (this.post.type === 'postTodo' || this.post['addTaskForIssue']) {
					this.showTodoCreateFormForPost(this.post);
				} else if (this.post.type === 'todo' || this.post.type === 'sub_task') {
					this.showToDoItemEditModal();
				} else if (this.post.type === 'customer') {
					this.handleEditCustomer();
				}
			} else {
				this.resetPostEditModal();
			}
		}
	}

	/**
	 * For handling issue post edit
	 */
	private updateIssue(): void {
		this.isTitle = false;
		this.post = this.postService.editSelectedPost;
		this.postType = this.post.type;
		this.showTaskEditForm = true;
		this.displayIssueOptions = true;
		if (this.post.product) {
			this.privacy.selectedProduct.push(this.post.product.slug);
			this.postService.productSlug = this.post.product.slug;
		}
		if (this.post.priority === 'p1') {
			this.selectedPriority = 'P1';
		} else if (this.post.priority === 'p2') {
			this.selectedPriority = 'P2';
		} else {
			this.selectedPriority = 'P3';
		}
		this.currentSelectedPriority = this.selectedPriority;
		if (this.post.title) {
			const title = this.post.title;
			const index = title.lastIndexOf(']');
			this.strippedTitle = title.substring(0, index + 1);
			this.newIssueTitle = title.substring(index + 3).trim();
		}

		this.model.title = this.newIssueTitle;
		this.model.body = this.post.description;

		if (this.post['customer'] && this.post['customer']['uid']) {
			this.currentIssueCustomer = this.post['customer']['title'];
			this.issueModel['customer'] = this.post['customer']['uid'];
			this.customerObj['uid'] = this.post['customer']['uid'];
		} else {
			this.customerObj['uid'] = '';
			this.currentIssueCustomer = '';
		}
		if (this.post.type) {
			this.currentType = this.post.type;
			this.selectedType = this.post.type;
		}

		this.updateAttachmentDetails();
	}

	/**
	 * Function to set the post privacy in the privacy modal.
	 */
	private updatePrivacy(): void {
		// this.privacy.updateSelectionFields();
		if (this.post['customPrivacy']) {
			this.privacy.resetPrivacy();
			this.privacy['customPrivacyUserIds'] = this.post['customPrivacy'];
			this.privacy.custom_privacy = this.post['customPrivacy'];

			const membersList = [];
			for (const u of this.privacy['custom_privacy']) {
				if (u.name) {
					membersList.push(u.name);
				}
			}
			this.privacy.previousSelection = membersList.join(', ');
			this.privacy.currentSelection = membersList.join(', ');
		} else if (this.post['contentPrivacy']) {
			if (this.post['contentPrivacy'].role) {
				this.privacy.resetPrivacy();
				const index = this.roles.findIndex((role) => role.slug === this.post['contentPrivacy'].role);
				this.privacy.selectedRole = this.roles[index];
				this.privacy.previousSelection = this.roles[index].name;
				this.privacy.currentSelection = this.roles[index].name;
			}
			if (this.post['contentPrivacy'].department) {
				const index = this.departments.findIndex((department) => department.uid === this.post['contentPrivacy'].department);
				if (index > -1) {
					this.privacy.resetPrivacy();
					this.privacy.selectedDepartment = this.departments[index];
					this.privacy.previousSelection = this.departments[index].name;
					this.privacy.currentSelection = this.departments[index].name;
				} else {
					this.privacy.selectedDepartment = '';
				}
			}
			if (!this.post['contentPrivacy'].role && !this.post['contentPrivacy'].department) {
				const field = Object.keys(this.post['contentPrivacy']);
				this.personals.forEach((element) => {
					if (element.key === field[0] && field[0] !== 'manager') {
						this.privacy.resetPrivacy();
						this.privacy.personalFilter = element;
						this.privacy.previousSelection = element['valueLabel'];
						this.privacy.currentSelection = element['valueLabel'];
					}
				});
				if (this.post['contentPrivacy'].status) {
					this.privacy.resetPrivacy();
					this.privacy['selectByStatusValue'] = this.post['contentPrivacy'];
				} else if (this.post['contentPrivacy'].renewalStatus) {
					this.privacy.resetPrivacy();
					this.privacy['selectByRenewalValue'] = this.post['contentPrivacy'];
				} else if (this.post['contentPrivacy'].membership_level) {
					this.privacy.resetPrivacy();
					this.privacy['selectByLevelValue'] = this.post['contentPrivacy'];
				} else if (this.post['contentPrivacy'].manager) {
					if (this.privacy.myTeam && this.privacy.myTeam.key) {
						this.privacy.resetPrivacy();
						this.privacy['personalFilter'] = this.privacy.myTeam;
					}
				}
			}
		} else if (this.post['channel'] && this.post['channel']['uid']) {
			this.privacy.resetPrivacy();
			this.privacy.channel = this.post.channel;
			this.privacy.previousSelection = this.post.channel['name'];
			this.privacy.currentSelection = this.post.channel['name'];
		} else {
			this.privacy.resetPrivacy();
			this.privacy.previousSelection = 'Everyone';
			this.privacy.currentSelection = 'Everyone';
		}

		this.checkPrivacyForMentions();
	}

	/**
	 * Function to show the post edit modal
	 */
	private showPostEditModal(): void {
		this.post = this.postService?.editSelectedPost;
		this.model.body = this.post?.body;
		this.model.title = this.post?.title;
		this.postType = this.post?.postType;

		if (
			this.post.postType === 'post' ||
			this.post.postType === 'recommendation' ||
			this.post.postType === 'question' ||
			this.post.postType === 'event' ||
			this.post.postType === 'offer'
		) {
			this.showMainPostForm = false;
			this.showTaskEditForm = false;
			this.showPostForms = true;

			this.initializeTuiEditor();
		} else {
			this.showPostForms = false;
			this.showTaskEditForm = false;
			this.showMainPostForm = true;
		}

		if (this.post?.pinnedPost && this.network.membership.memberType === 'admin') {
			this.model['pinned_post'] = true;
		} else {
			this.model['pinned_post'] = false;
		}
		if (this.post.type) {
			this.model.category = this.post.type;
		}
		if (this.post.shareable) {
			this.model.shareable = true;
		} else {
			this.model.shareable = false;
		}
		if (this.postType === 'event' || this.postType === 'meeting') {
			// this.startDateString = new Date(this.post.starts).toLocaleString();
			// this.model.starts = '';
			// this.showStartPicker = false;
			// this.showEndPicker = false;
			// if (this.post.ends) {
			//   this.endDateString = new Date(this.post.ends).toLocaleString();
			//   this.model.ends = '';
			// }
			if (this.post.starts) {
				const date = new Date(this.post.starts);
				const month = date.getMonth() + 1;
				const day = date.getDate();
				const year = date.getFullYear();
				this.eventStartDate = { year: year, month: month, day: day };
				this.starts = date.getHours() + ':' + date.getMinutes();
			} else {
				this.eventStartDate = '';
				this.starts = '';
			}
			if (this.post.ends) {
				const date = new Date(this.post.ends);
				const month = date.getMonth() + 1;
				const day = date.getDate();
				const year = date.getFullYear();
				this.eventEndDate = { year: year, month: month, day: day };
				this.ends = date.getHours() + ':' + date.getMinutes();
			} else {
				this.eventEndDate = '';
				this.ends = '';
			}
			if (this.post.duration) {
				let h: any;
				let m: any;
				h = Math.floor(this.post.duration / 60);
				m = this.post.duration % 60;
				h = h < 10 ? '0' + h : h;
				m = m < 10 ? '0' + m : m;
				this.duration = h + ':' + m;
			}

			this.postService.getEventImages().subscribe((data) => {
				if (data.count > 0) {
					this.eventImageExists = true;
					this.eventImages = data.objects;
				} else {
					this.eventImageExists = false;
				}
			});

			if (this.post['locationName']) {
				this.model['venueName'] = this.post['locationName'];
			}
		}
		if (this.postType === 'meeting') {
			if (this.post.meetingPassword) {
				this.meeting_password = this.post.meetingPassword;
			}
			// if (this.post.duration) {
			//   this.meeting_duration = this.post.duration;
			// }
		}
		// If posttype is question, add the choices in array for displaying
		if (this.postType === 'question') {
			this.post.choices.forEach((choice) => {
				this.question_choices.push(choice);
				this.updateQuestionPostImage();
			});
		}
		if (this.postType === 'offer') {
			this.pageLoadingUiService.setPageLoading(true);
			this.postService.getFiltersAndCategories().subscribe((data) => {
				this.pageLoadingUiService.setPageLoading(false);
				this.productCategories = data['offerCategories'];
				this.productCondition = data['conditions'];
			});
			if (this.post['price']) {
				if (this.post['price']['sellPrice']) {
					this.model.sellPrice = this.post['price']['sellPrice'];
					this.isFree = false;
				} else {
					this.isFree = true;
					this.model.sellPrice = 0;
				}
				if (this.post['price']['fixed']) {
					this.model.fixedPrice = 1;
				} else {
					this.model.fixedPrice = 0;
				}
			}
			if (this.post.condition) {
				this.model.condition = this.post.condition;
			}
			if (this.post.locationName) {
				this.selectedCity['name'] = this.post.locationName;
			}
		}

		if (this.postType !== 'question') {
			this.updateAttachmentDetails();
		}

		this.privacy.getMembers();
	}

	public onCloseCustomImage(): void {
		this.model.images = [];
		this.model.preview_images = [];
		this.photo_upload = false;
		this.model.images.push(this.eventImages[0].upload.uid);
	}

	public onSelectEventType(event): void {
		this.model.images = [];
		this.model.preview_images = [];
		this.model.images.push(event.upload.uid);
		// this.model.preview_images.push(event.upload.thumbnails.400);
	}

	/* Function to reset post edit modal values */
	private resetPostEditModal(): void {
		// this.post = null;
		this.model.body = '';
		this.model.title = '';
		// this.postType = null;
		this.model['pinned_post'] = false;
		this.model.category = '';
		this.model.shareable = true;
	}

	/**
	 * Copy the attachment details in post component to temporary variables for editing.
	 */
	private updateAttachmentDetails(): void {
		if (this.post.attachmentDetails.gallery && this.post.attachmentDetails.gallery.length > 0) {
			this.removeImage = false;
			this.tempImages = JSON.parse(JSON.stringify(this.post.attachmentDetails.gallery));
			//
			this.post.attachmentDetails.gallery.forEach((image) => {
				this.model.images.push(image.image.uid);
			});
			if (this.post.attachmentDetails.gallery.length <= 10) {
				this.maxPhotoUploadCount = 10 - this.post.attachmentDetails.gallery.length;
			} else {
				this.maxPhotoUploadCount = 0;
			}
		}
		if (this.post.attachmentDetails.location && this.post.attachmentDetails.location.length > 0) {
			this.model.location = this.post.attachmentDetails.location[0];
		}
		if (this.post.attachmentDetails.doc && this.post.attachmentDetails.doc[0].uid) {
			this.fileUpload = true;
			this.model.attachmentType = 'doc';
			this.model['doc'] = this.post.attachmentDetails.doc[0].uid;
		}
		if (this.post.attachmentDetails.url && this.post.attachmentDetails.url[0].url) {
			this.articleUpload = true;
			this.model['url'] = this.post.attachmentDetails.url[0].url;
		}
		if (this.post.attachmentDetails.member && this.post.attachmentDetails.member.uid) {
			this.model.member = this.post.attachmentDetails.member;
			this.attachmentMember = this.post.attachmentDetails.member;
		}
	}

	public getCitiesForBuySell(): void {
		this.displayBuySellLocations = 'block';
		this.noCities = true;
		this.buySellCities = [];
		this.searchCityQuery = '';
		const nearMeLocation = this.currentLocation.coords.latitude + ',' + this.currentLocation.coords.longitude;
		this.networkService.getCitiesForRoleUpdate(nearMeLocation).subscribe((data) => {
			this.noCities = false;
			if (data.results && data.results.length > 0) {
				this.buySellCities = data.results;
			}
		});
	}

	public onSearchCity(): void {
		this.noCities = true;
		this.buySellCities = [];
		const nearMeLocation = this.currentLocation.coords.latitude + ',' + this.currentLocation.coords.longitude;
		this.networkService.searchCitiesForRoleUpdate(nearMeLocation, this.searchCityQuery).subscribe((data) => {
			this.noCities = false;
			if (data.results && data.results.length > 0) {
				this.buySellCities = data.results;
			}
		});
	}

	public onSearchCityEnter(event): void {
		if (event.keyCode === 13) {
			this.onSearchCity();
		}
	}

	public onSelectCity(city): void {
		this.selectedCity['name'] = city['name'];
		this.selectedCity['uid'] = city['uid'];
	}

	/**
	 * Function executed when remove image button clicked in the image list.
	 */
	public onRemoveImage(index: number = null): void {
		this.photo_upload = false;
		this.model.images = [];
		this.model.preview_images = [];
		this.tempImages = [];

		if (index) {
			this.imageIndex = index;
			this.tempImages.splice(index, 1);
			this.model.images.splice(index, 1);
			this.maxPhotoUploadCount = this.maxPhotoUploadCount + 1;

			if (this.postType === 'event') {
				this.showEventImageSelector = true;
			} else {
				this.showEventImageSelector = false;
			}
		}
	}

	/**
	 * Function to close post edit modal
	 */
	public closeEditModal(): void {
		this.errorMessage = '';
		this.attachmentMember = '';
		this.selectedMember.uid = '';
		this.privacy.channel = '';
		this.postService.productSlug = '';
		this.showTaskEditForm = false;
		this.displayContactDetails = false;
		this.showPostForms = false;
		this.showEventImageSelector = false;
		this.showMainPostForm = false;
		this.doc_upload = false;
		this.post = {};
		this.postService.editSelectedPost = null;
		this.privacy.selectedProduct = [];
		this.layoutService.hideBd();
		// for closing loc modal and removing img--/
		this.model.location = null;
		this.model.images = [];
		this.tempImages = [];
		this.onCloseEdit.emit('closed');
		// for resetting question post edit form
		this.question_choices = [];
		this.question_image = false;
		this.questionImages = [];
		this.removeImage = true;
		this.removeUploads = false;
		this.resetReminders();
		this.resetAdditionalOptions(); // Reset additionalOptions in task form
		this.resetEditCustomer();
		this.clearSubtasks();
		this.taskReminderOption = ['none', 'None']; // Reset reminder fields on additional options
		this.isTaskReminderSelected = false; // Set default for task reminder field
		this.display_date = false; // Reset date fields
		this.oneOff_date = ''; // Reset date fields
		this.post = null;
		this.clearSubtasks();
	}

	/**
	 * Function called when privacy button in post create is clicked
	 */
	public onPrivacyClick(): void {
		if (!this.isExternalUser || (this.isExternalUser && this.multipleExternalUser)) {
			this.privacy.showPrivacy('post-edit');
			const subscription = this.privacy.isPrivacyActive.subscribe((data) => {
				if (data) {
					this.showMainPostForm = false;
				} else if (this.privacy.privacyType === 'post-edit') {
					this.showMainPostForm = true;
					// this.showPostCustomPrivacy = false;
					subscription.unsubscribe();
				}
			});
			/** Created for managing todolist create popup in task create form
			 * 1. Check if form in add task type and if todolist create form is used
			 * 2. Hide todolists modal
			 * 3. Hide todolist create popup
			 */
			if ((this.postType === 'postTodo' || this.postType === 'todo') && this.displayCreateTodoList !== 'none') {
				// 1
				this.display_todoLists = 'none'; // 2
				this.displayCreateTodoList = 'none'; // 3
				this.isTodolistCreateForm = true;
			}
			this.memberModelType = 'privacy';
		}
	}

	private sortData(data: any): any {
		return data.sort(function (m1, m2) {
			if (m1.distance < m2.distance) {
				return -1;
			} else if (m1.distance > m2.distance) {
				return 1;
			} else {
				return 0;
			}
		});
	}

	public onSubmit(): void {
		this.submitted = true;
	}

	public highlight(): void {
		this.showBg = true;
		// this.postType = 'post';
	}

	public disableIt(): void {
		this.model.images = [];
		this.tempImages = [];
		this.selectedMember['uid'] = '';
		this.attachmentMember = '';
		delete this.model.member;
		this.removeUploads = false;
		this.fileUpload = false;
		this.doc_upload = false;
		this.postService.productSlug = '';
		this.postType = 'post';
		this.article = false;
		this.photo_upload = false;
		this.location_display = 'none';
		this.post_type = {
			'buy-sell': false,
			event: false,
			message: false,
			post: true,
			recommend: false,
		};
		this.showMainPostForm = false;
		this.showTaskEditForm = false;
		this.showPostForms = false;
		this.showEventImageSelector = false;
		this.postService.hideBackDrop();
		this.fileType = [];
		this.privacy.currentProject = '';
		this.privacy.projectUid = '';
		this.privacy.projectSelected = '';
		delete this.model['venueName'];
		delete this.todoItem['numberOfOpens'];
	}

	public selectPost(type): void {
		const slug = type['slug'];
		this.postType = slug;

		for (const res of this.network.services) {
			this.post_type[res.slug] = false;
		}

		this.post_type[slug] = true;
		this.model.title = '';
		if (slug === 'recommend') {
			this.model.title = 'Recommendation';
			this.model.category = 'recommendations';
		} else if (slug === 'event' || slug === 'events') {
			this.postType = 'event';
			delete this.model.member;
			this.timeZone = this.timeZoneService.getTimeZone();
			this.model.category = 'event';
			this.postService.getEventImages().subscribe((data) => {
				if (data.meta.count > 0) {
					this.eventImageExists = true;
					this.eventImages = data.objects;
					this.model.images.push(this.eventImages[0].upload.uid);
				} else {
					this.eventImageExists = false;
				}
			});
		} else if (slug === 'buy-sell') {
			delete this.model.member;
			this.model.condition = '';
			this.model.category = '';
		} else if (slug === 'post') {
			this.model.category = 'status';
		}
	}

	/*
	 * Function to update post based on type.
	 */
	public updatePost(): void {
		this.shiftKeyUsed = false;

		switch (this.postType) {
			case 'event':
				this.postCreatePrecheck();
				// eslint-disable-next-line spellcheck/spell-checker
				this.model.link = 'www.andoneapp.com';
				this.model.cost = 'free';
				this.model.type = 'event';
				this.model.category = 'events';
				delete this.model.condition;

				if (this.eventStartDate && this.starts) {
					const d = this.parserFormatter.format(this.eventStartDate) + ' ' + this.starts;
					const date = new Date(d);
					const startMonth = date.getUTCMonth() + 1;
					const startDay = date.getUTCDate();
					const startYear = date.getUTCFullYear();
					const startHours = date.getUTCHours();
					const startMinutes = date.getUTCMinutes();
					const startSeconds = date.getUTCSeconds();
					const utcDate = startYear + '-' + startMonth + '-' + startDay + ' ' + startHours + ':' + startMinutes + ':' + startSeconds;
					this.model.starts = utcDate;
				}
				if (this.eventEndDate) {
					if (this.ends) {
						const d = this.parserFormatter.format(this.eventEndDate) + ' ' + this.ends;
						const date = new Date(d);
						const startMonth = date.getUTCMonth() + 1;
						const startDay = date.getUTCDate();
						const startYear = date.getUTCFullYear();
						const startHours = date.getUTCHours();
						const startMinutes = date.getUTCMinutes();
						const startSeconds = date.getUTCSeconds();
						const utcDate = startYear + '-' + startMonth + '-' + startDay + ' ' + startHours + ':' + startMinutes + ':' + startSeconds;
						this.model.ends = utcDate;
					} else {
						this.errorMessage = 'Please select end time for the event.';
						return;
					}
				}

				if (this.model.images && this.model.images.length > 0) {
					if (this.model.images.length > 1) {
						this.model.images.splice(0, 1);
					}
					if (this.doc_upload && this.documentList.indexOf(this.fileType[0]) > -1) {
						this.model.attachmentType = 'doc';
					} else if (this.doc_upload && this.audioList.indexOf(this.fileType[0]) > -1) {
						this.model.attachmentType = 'audio';
					} else if (this.doc_upload && this.videoList.indexOf(this.fileType[0]) > -1) {
						this.model.attachmentType = 'video';
					} else {
						this.model.attachmentType = 'photo';
					}
					this.model.images = this.model.images.join('|');
				}

				if (!this.article && !this.articleUpload) {
					delete this.model.url;
				} else {
					this.model.attachmentType = 'url';
				}
				if (this.model.member) {
					this.model.attachmentType = 'member';
					this.model.member = this.model.member.uid;
				}
				if (this.model.location) {
					this.pageLoadingUiService.setPageLoading(true);
					this.locationService
						.create({
							venueId: this.model.location.venueId,
							latitude: this.model.location.latitude,
							longitude: this.model.location.longitude,
							name: this.model.location.name,
							category: this.model.location.category.categoryId ? this.model.location.category.categoryId : this.model.location.category,
							website: this.model.location.website ? this.model.location.website : '',
							phone: this.model.location.phone,
						})
						.subscribe((data) => {
							delete this.model.location;
							this.model['venueName'] = data['name'];
							this.callUpdateApi();
						});
				} else {
					this.callUpdateApi();
				}
				break;
			case 'offer':
				if (!this.model.images || this.model.images.length === 0) {
					this.errorMessage = 'Please select an image to create an offer';
					break;
				}
				this.postCreatePrecheck();
				this.model.action = 'public';
				this.model.type = 'offer';
				this.model.sellPrice = Number(this.model.sellPrice);
				this.model.preview_images = [];
				if (typeof this.model.images !== 'undefined' && this.model.images.length > 0) {
					this.model.attachmentType = 'photo';
					this.model.images = this.model.images.join('|');
				}
				if (this.model.fixedPrice) {
					this.model.fixedPrice = 1;
				} else {
					this.model.fixedPrice = 0;
				}
				if (!this.article && !this.articleUpload) {
					delete this.model.url;
				} else {
					this.model.attachmentType = 'url';
				}

				if (this.selectedCity && this.selectedCity.uid) {
					this.model['city'] = this.selectedCity['uid'];
				}
				this.callUpdateApi();
				break;
			case 'question':
				this.postCreatePrecheck();
				this.model.type = 'question';
				this.model.category = 'questions';
				let choiceString: string;
				this.question_choices.forEach(function (item, index) {
					if (index !== 0) {
						choiceString += '|choice:' + item.choice + '<#>order:' + index;
					} else {
						choiceString = 'choice:' + item.choice + '<#>order:' + index;
					}
				});
				if (this.choiceValue) {
					choiceString += (choiceString ? '|' : '') + 'choice:' + this.choiceValue + '<#>order:' + this.question_choices.length;
				}
				this.model.choices = choiceString;
				this.model.preview_images = [];
				if (typeof this.model.images !== 'undefined' && this.model.images.length > 0) {
					if (this.doc_upload && this.documentList.indexOf(this.fileType[0]) > -1) {
						this.model.attachmentType = 'doc';
					} else if (this.doc_upload && this.audioList.indexOf(this.fileType[0]) > -1) {
						this.model.attachmentType = 'audio';
					} else if (this.doc_upload && this.videoList.indexOf(this.fileType[0]) > -1) {
						this.model.attachmentType = 'video';
					} else {
						this.model.attachmentType = 'photo';
					}
					this.model.images = this.model.images.join('|');
				}
				this.callUpdateApi();
				break;
			case 'recommend':
				this.postCreatePrecheck();
				this.model.type = 'recommendation';
				this.model.category = 'recommendations';
				this.model.preview_images = [];
				if (typeof this.model.images !== 'undefined' && this.model.images.length > 0) {
					if (this.doc_upload && this.documentList.indexOf(this.fileType[0]) > -1) {
						this.model.attachmentType = 'doc';
					} else if (this.doc_upload && this.audioList.indexOf(this.fileType[0]) > -1) {
						this.model.attachmentType = 'audio';
					} else if (this.doc_upload && this.videoList.indexOf(this.fileType[0]) > -1) {
						this.model.attachmentType = 'video';
					} else {
						this.model.attachmentType = 'photo';
					}
					this.model.images = this.model.images.join('|');
					this.callUpdateApi();
				} else if (this.model.location) {
					this.model.attachmentType = 'location';
					this.model['rating'] = this.range;
					this.locationService
						.create({
							venueId: this.model.location.venueId,
							latitude: this.model.location.latitude,
							longitude: this.model.location.longitude,
							name: this.model.location.name,
							category: this.model.location.category,
							website: this.model.location.website,
							phone: this.model.location.phone,
						})
						.subscribe((data) => {
							this.model.location = data.uid;
							this.callUpdateApi();
						});
					this.callUpdateApi();
				} else {
					this.callUpdateApi();
				}
				break;
			case 'issue':
				this.setIssueEdit();
				break;
			case 'feature':
				this.setIssueEdit();
				break;
			case 'enhancement':
				this.setIssueEdit();
				break;

			case 'edit-customer':
				this.model.preview_images = [];
				if (this.model['url']) {
					this.customer['attachmentType'] = 'url';
					this.customer['url'] = this.model['url'];
				} else if (this.doc_upload && this.documentList.indexOf(this.fileType[0]) > -1) {
					this.customer['attachmentType'] = 'doc';
					this.customer['doc'] = this.model.images.join('|');
					this.model.images = [];
				} else if (this.model.images.length > 0) {
					this.customer['images'] = this.model.images.join('|');
					this.customer['attachmentType'] = 'photo';
				} else if (this.fileUpload && this.model['doc']) {
					this.customer['attachmentType'] = 'doc';
					this.customer['doc'] = this.model['doc'];
				} else if (this.model['location']) {
					this.customer['attachmentType'] = 'location';
					if (this.model['location']['uid']) {
						this.customer['location'] = this.model['location']['uid'];
					} else {
						this.locationService
							.create({
								venueId: this.model.location.venueId,
								latitude: this.model.location.latitude,
								longitude: this.model.location.longitude,
								name: this.model.location.name,
								category: this.model.location.category,
								website: this.model.location.website,
								phone: this.model.location.phone,
							})
							.subscribe((data) => {
								this.customer['location'] = data.uid;
								this.callUpdateCustomer();
							});
					}
				}
				this.callUpdateCustomer();
				break;

			case 'todo':
				this.model.preview_images = [];
				if (this.model['url']) {
					this.todoItem['attachmentType'] = 'url';
					this.todoItem['url'] = this.model['url'];
				} else if (this.doc_upload && this.documentList.indexOf(this.fileType[0]) > -1) {
					this.todoItem['attachmentType'] = 'doc';
					this.todoItem['doc'] = this.model.images.join('|');
					this.model.images = [];
				} else if (this.model.images.length > 0) {
					this.todoItem['images'] = this.model.images.join('|');
					this.todoItem['attachmentType'] = 'photo';
				} else if (this.fileUpload && this.model['doc']) {
					this.todoItem['attachmentType'] = 'doc';
					this.todoItem['doc'] = this.model['doc'];
				} else if (this.model['location']) {
					this.todoItem['attachmentType'] = 'location';
					if (this.model['location']['uid']) {
						this.todoItem['location'] = this.model['location']['uid'];
					} else {
						this.locationService
							.create({
								venueId: this.model.location.venueId,
								latitude: this.model.location.latitude,
								longitude: this.model.location.longitude,
								name: this.model.location.name,
								category: this.model.location.category,
								website: this.model.location.website,
								phone: this.model.location.phone,
							})
							.subscribe((data) => {
								if (data['uid']) {
									this.todoItem['location'] = data.uid;
									this.updateToDoItem();
								}
							});
					}
				}

				if (this.model.member) {
					this.todoItem['attachmentType'] = 'member';
					this.todoItem['member'] = this.model.member.uid;
					delete this.model.member;
				}

				this.model.postType = 'todo';

				this.updateToDoItem();
				break;

			case 'sub_task':
				this.model.preview_images = [];
				if (this.model['url']) {
					this.todoItem['attachmentType'] = 'url';
					this.todoItem['url'] = this.model['url'];
				} else if (this.doc_upload && this.documentList.indexOf(this.fileType[0]) > -1) {
					this.todoItem['attachmentType'] = 'doc';
					this.todoItem['doc'] = this.model.images.join('|');
					this.model.images = [];
				} else if (this.model.images.length > 0) {
					this.todoItem['images'] = this.model.images.join('|');
					this.todoItem['attachmentType'] = 'photo';
				} else if (this.fileUpload && this.model['doc']) {
					this.todoItem['attachmentType'] = 'doc';
					this.todoItem['doc'] = this.model['doc'];
				} else if (this.model['location']) {
					this.todoItem['attachmentType'] = 'location';
					if (this.model['location']['uid']) {
						this.todoItem['location'] = this.model['location']['uid'];
					} else {
						this.locationService
							.create({
								venueId: this.model.location.venueId,
								latitude: this.model.location.latitude,
								longitude: this.model.location.longitude,
								name: this.model.location.name,
								category: this.model.location.category,
								website: this.model.location.website,
								phone: this.model.location.phone,
							})
							.subscribe((data) => {
								if (data['uid']) {
									this.todoItem['location'] = data.uid;
									this.updateToDoItem();
								}
							});
					}
				}

				if (this.model.member) {
					this.todoItem['attachmentType'] = 'member';
					this.todoItem['member'] = this.model.member.uid;
					delete this.model.member;
				}

				this.updateToDoItem();
				break;

			case 'meeting':
				this.model.type = 'meeting';
				this.model.category = 'meeting';
				this.model.meeting_password = this.meeting_password;
				delete this.model.condition;

				if (this.eventStartDate && this.starts) {
					const d = this.parserFormatter.format(this.eventStartDate) + ' ' + this.starts;
					const date = new Date(d);
					const startMonth = date.getUTCMonth() + 1;
					const startDay = date.getUTCDate();
					const startYear = date.getUTCFullYear();
					const startHours = date.getUTCHours();
					const startMinutes = date.getUTCMinutes();
					const startSeconds = date.getUTCSeconds();
					const utcDate = startYear + '-' + startMonth + '-' + startDay + ' ' + startHours + ':' + startMinutes + ':' + startSeconds;
					this.model.starts = utcDate;
				}
				if (this.duration) {
					const hour = this.duration.split(':')[0];
					const minute = this.duration.split(':')[1];
					this.model.duration = Math.floor(hour * 60) + Number(minute);
				}

				this.callZoomRefresh();
				this.postCreatePrecheck();
				this.callUpdateApi();
				break;
			default:
				this.postCreatePrecheck();
				this.model.type = 'post';
				this.model.preview_images = [];

				if (this.removeImage && this.tempImages.length > 0) {
					delete this.model.attachmentType;
					this.model.images.splice(this.imageIndex, 1);
				}

				if (typeof this.model.images !== 'undefined' && this.model.images.length > 0) {
					if (this.doc_upload && this.documentList.indexOf(this.fileType[0]) > -1) {
						this.model.attachmentType = 'doc';
					} else if (this.doc_upload && this.audioList.indexOf(this.fileType[0]) > -1) {
						this.model.attachmentType = 'audio';
					} else if (this.doc_upload && this.videoList.indexOf(this.fileType[0]) > -1) {
						this.model.attachmentType = 'video';
					} else {
						this.model.attachmentType = 'photo';
					}
					this.model.images = this.model.images.join('|');
				}

				if (!this.article && !this.articleUpload) {
					delete this.model.url;
				} else {
					this.model.attachmentType = 'url';
				}
				if (this.model.member) {
					this.model.attachmentType = 'member';
					this.model.member = this.model.member.uid;
				}

				if (this.model.location) {
					if (!this.model['location']['point']) {
						this.model.attachmentType = 'location';
						this.model.rating = 5;
						this.locationService
							.create({
								venueId: this.model.location.venueId,
								latitude: this.model.location.latitude,
								longitude: this.model.location.longitude,
								name: this.model.location.name,
								category: this.model.location.category,
								website: this.model.location.website,
								phone: this.model.location.phone,
							})
							.subscribe((data) => {
								this.model.location = data.uid;
								this.callUpdateApi();
							});
					} else {
						this.model.attachmentType = 'location';
						this.model.location = this.model['location']['uid'];
						this.callUpdateApi();
					}
				} else {
					this.callUpdateApi();
				}
		}
	}

	// For calling zoom refresh api for zoom meeting delete
	public callZoomRefresh(): void {
		if (this.currentUser && this.currentUser.zoomLinked) {
			// check if zoom in expired or not
			if (this.currentUser.zoomExpires) {
				const currentDate = new Date();
				const zoomExpiryDate = this.currentUser.zoomExpires;
				const splicedZoomDate = zoomExpiryDate.slice(0, zoomExpiryDate.lastIndexOf('.'));
				const u = '+0000';
				const utcZoomExpiryDate = splicedZoomDate.concat(u);
				const expiryDate = new Date(utcZoomExpiryDate);
				if (currentDate > expiryDate) {
					// Call zoom refresh api since zoom is expired
					const data = {};
					this.userService.zoomAuthRefresh(data).subscribe(() => {
						// call user details api and update current user data
						this.userService.updateUserDetails();
						this.currentUser = this.userService.getCurrentUser();
						// Remove user details from LS,hence on refresh updated user details gets on LS
						localStorage.removeItem('userDetails');
						CacheUtils.removeStoredData('userDetails');
					});
				} else {
				}
			}
		}
	}

	/**
	 * Function to remove the unwanted content from the model and create privacy in modal.
	 */
	private postCreatePrecheck(): void {
		delete this.model.privacy;
		delete this.model.setCommentUrl;
		delete this.selectByStatusString;
		delete this.selectByRenewalString;
		delete this.selectByLevelString;

		if (this.model['mentioned_users'] && this.model['mentioned_users'].length > 0) {
			const userList = [];
			for (const u of this.model['mentioned_users']) {
				if (this.model.body.indexOf('@' + u.name) > -1) {
					userList.push(u.username);
				}
				if (this.model.title.indexOf('@' + u.name) > -1) {
					userList.push(u.username);
				}
			}
			this.model['mentioned_users'] = [];
			this.model['mentioned_users'] = userList.join(',');
		}
		if (this.model['hashtags'] && this.model['hashtags'].length > 0) {
			const tagList = [];
			for (const h of this.model['hashtags']) {
				if (this.model.body.indexOf('#' + h['name']) > -1) {
					tagList.push(h['name']);
				}

				if (this.model.title.indexOf('#' + h['name']) > -1) {
					tagList.push(h['name']);
				}
			}
			const re = /(?:^|\W)#(\w+)(?!\w)/g;
			let match;
			while ((match = re.exec(this.model.body))) {
				if (tagList.indexOf(match[1]) < 0) {
					tagList.push(match[1]);
				}
			}
			this.model['hashtags'] = [];
			this.model['hashtags'] = tagList.join(',');
		}

		if (this.privacy.custom_privacy.length !== 0) {
			const customPrivacy: any[] = [];
			this.privacy.custom_privacy.forEach((element) => {
				if (element.userId) {
					customPrivacy.push(element.userId);
				} else if (element.user) {
					customPrivacy.push(element.user.userId);
				} else {
					customPrivacy.push(element.id);
				}
			});
			this.model.custom_privacy = '[' + customPrivacy.join(',') + ']';

			delete this.model.content_privacy;
		} else {
			if (this.privacy.selectedRole) {
				if (this.privacy.selectedRole.slug) {
					this.roleString = { role: this.privacy.selectedRole.slug };
					const newObject = this.privacy.selectedRole;
					newObject.privacyType = 'role';
					localStorage.setItem(`${this.network.uid}`, JSON.stringify(newObject));
				}
			}
			if (this.privacy.selectedDepartment) {
				if (this.privacy.selectedDepartment.value) {
					this.departmentString = {
						department: this.privacy.selectedDepartment['value'],
					};
				}
				if (this.privacy.selectedDepartment.uid) {
					this.departmentString = {
						department: this.privacy.selectedDepartment['uid'],
					};
				}
				const newObject = this.privacy.selectedDepartment;
				newObject.privacyType = 'department';
				localStorage.setItem(`${this.network.uid}`, JSON.stringify(newObject));
			}
			if (this.privacy.personalFilter) {
				this.personalFilterString = {};
				const key = this.privacy.personalFilter.key;
				this.personalFilterString[key] = this.privacy.personalFilter.value;
				const newObject = this.privacy.personalFilter;
				newObject.privacyType = 'personalFilter';
				localStorage.setItem(`${this.network.uid}`, JSON.stringify(newObject));
			}
			if (this.privacy.channel) {
				this.model.channel = this.privacy['channel']['uid'];
				const newObject = this.privacy.channel;
				newObject.privacyType = 'channel';
				localStorage.setItem(`${this.network.uid}`, JSON.stringify(newObject));
			}
			// Select By Status--->
			if (this.privacy.selectByStatusValue) {
				this.selectByStatusString = {
					status: this.privacy.selectByStatusValue.status,
				};
			}
			// -Select By Renewal---->
			if (this.privacy.selectByRenewalValue) {
				this.selectByRenewalString = {
					renewalStatus: this.privacy.selectByRenewalValue.renewalStatus,
				};
			}
			// -Select By Level---->
			if (this.privacy.selectByLevelValue) {
				this.selectByLevelString = {
					membership_level: this.privacy.selectByLevelValue.membership_level,
				};
			}

			if (this.roleString && this.departmentString) {
				// this.model.content_privacy = '{' + this.roleString + ', ' + this.departmentString + '}';
			} else if (!this.roleString && this.departmentString) {
				this.model.content_privacy = JSON.stringify(this.departmentString);
			} else if (this.roleString && !this.departmentString) {
				this.model.content_privacy = JSON.stringify(this.roleString);
			} else if (this.personalFilterString) {
				this.model.content_privacy = JSON.stringify(this.personalFilterString);
			} else if (this.selectByStatusString) {
				this.model.content_privacy = JSON.stringify(this.selectByStatusString);
			} else if (this.selectByRenewalString) {
				this.model.content_privacy = JSON.stringify(this.selectByRenewalString);
			} else if (this.selectByLevelString) {
				this.model.content_privacy = JSON.stringify(this.selectByLevelString);
			} else {
				delete this.model.content_privacy;
			}
			this.roleString = null;
			this.departmentString = null;
			this.personalFilterString = null;
			delete this.model.custom_privacy;
		}

		if (this.model.channel === '') {
			delete this.model.channel;
		}

		if (this.isExternalUser && this.externalUserChannel && !this.multipleExternalUser) {
			this.model.channel = this.externalUserChannel['uid'];
		}

		if (this.model.shareable) {
			this.model.shareable = 1;
		} else {
			this.model.shareable = 0;
		}

		if (this.model.commentable) {
			this.model.commentable = 1;
		} else {
			this.model.commentable = 0;
		}

		if (this.post['state'] === 'sold' && !this.post['isResell']) {
			this.model.state = 'sold';
		} else {
			this.model.state = 'active';
		}

		const _post = this.post,
			_model = this.model;

		setTimeout(() => {
			const viewerElement: HTMLElement = document.querySelector(`#post-content-${_post.uid}`);

			const tuiViewer: Viewer = Editor.factory({
				el: viewerElement,
				viewer: true,
				height: '500px',
				initialValue: _model.body,
				plugins: [[codeSyntaxHighlight, { highlighter: Prism }]],
				linkAttributes: { target: '_blank' },
				frontMatter: true,
			});

			this.model.tuiViewer = tuiViewer;

			console.log(_post, this.model);
		}, 2000);

		this.model.preview_images = [];
	}

	/**
	 * Function to call the update API
	 */
	private callUpdateApi(): void {
		if (this.currentPage === 'collection') {
			this.model['collection_post'] = true;
		}
		if (this.model.attachmentType === 'doc' && !this.fileUpload) {
			this.model['doc'] = this.model.images;
			delete this.model.images;
		}
		if (this.model.attachmentType === 'audio') {
			this.model['audio'] = this.model.images;
			delete this.model.images;
		}
		if (this.model.attachmentType === 'video') {
			this.model['video'] = this.model.images;
			delete this.model.images;
		}
		this.pageLoadingUiService.setPageLoading(true);

		this.postService.updatePost(this.model, this.post.uid).subscribe(
			(data) => {
				this.model = new Post(
					{
						title: null,
						body: this.model.body,
						category: null,
						privacy: this.lastPrivacy,
						images: [],
						preview_images: [],
						shareable: true,
						commentable: true,
						custom_privacy: undefined,
						channel: undefined,
						content_privacy: undefined,
						department: null,
					},
					true
				);
				this.disableIt();
				this.pageLoadingUiService.setPageLoading(false);
				this.layoutService.hideBd();
				this.model.category = 'status';
				this.postType = 'post';
				this.selectedDepartment = '';
				this.model.location = null;
				this.removeImage = true;
				this.model.images = [];
				this.tempImages = [];
				this.questionImages = [];
				this.postService.alterPostBodyAccordingToUserMentionAndHashtag(data);
				this.updatedPostEmitter.emit(new Post(data));
				this.question_choices = [];
				this.question_image = false;
				this.doc_upload = false;
				this.fileUpload = false;
				this.articleUpload = false;
				this.removeUploads = false;
				this.privacy.channel = '';
				// ====Reset ngx mat picker
				this.starts = '';
				this.eventStartDate = '';
				this.ends = '';
				this.eventEndDate = '';

				this.projectService.todoUpdatedByExternalSource(data);
			},
			(err) => {
				this.errors = err;
			}
		);
	}

	private getCaretPosition(ctrl): number {
		let caretPosition = 0; // IE Support
		ctrl = this.modelBody.nativeElement;

		if (ctrl.selectionStart || ctrl.selectionStart === '0') {
			caretPosition = ctrl.selectionStart;
		}
		return caretPosition;
	}

	private returnWord(text, caretPosition): string | null {
		if (text) {
			const preText = text.substring(0, caretPosition);
			if (preText.indexOf(' ') > 0) {
				const words = preText.split(' ');
				return words[words.length - 1]; // return last word
			} else {
				return preText;
			}
		}
		return null;
	}

	public uploadPhoto(): void {
		this.photo_upload = !this.photo_upload;
		this.doc_upload = false;
		this.article = false;
		this.location_display = 'none';
		this.model.location = '';
		if (this.model.preview_images && this.model.preview_images.length > 0) {
			this.model.preview_images = [];
			this.model.images = [];
		}
	}

	public postLocation(): void {
		if (this.model.location) {
			delete this.model.location;
		} else {
			this.article = false;
			this.photo_upload = false;
			this.layoutService.showBd('post_locations');
			this.location_display = 'block';
		}
	}

	/* Function to select location*/
	public onLocationSelect(loc): void {
		this.removeImage = true;
		this.model.location = loc;
		this.layoutService.hideBd();
	}
	/** Function to remove the selected modal*/
	public onRemoveLocation(): void {
		this.model.location = null;
	}

	/** Modal functions */
	public onLocationModalClose(): void {
		this.location_display = 'none';
		this.layoutService.hideBd();
	}

	/** Function to close modals*/
	public onCloseHandled(): void {
		this.member_display = 'none';
		this.location_display = 'none';
		this.memberModelType = null;
		this.privacy_settings = 'none';
	}

	private getCaretPositionForSubject(ctrl): number {
		let caretPosition = 0; // IE Support
		if (this.modelTitle) {
			ctrl = this.modelTitle.nativeElement;

			if (ctrl.selectionStart || ctrl.selectionStart === '0') {
				caretPosition = ctrl.selectionStart;
			}
			return caretPosition;
		}

		return null;
	}

	private showStartDatePicker(element): void {
		this.showStartPicker = true;
		setTimeout(() => {
			element.focus();
		}, 20);
	}

	private showEndDatePicker(element): void {
		this.showEndPicker = true;
		setTimeout(() => {
			element.focus();
		}, 20);
	}

	public onBlur(): void {
		if (!this.model.starts) {
			this.showStartPicker = false;
		}
	}

	// For add an issue---->
	public onSelectProductClick(): void {
		this.privacy.showSelectProduct();
	}

	private addAnIssue(): void {
		if (this.model['mentioned_users'] && this.model['mentioned_users'].length > 0) {
			const userList = [];
			for (const u of this.model['mentioned_users']) {
				if (this.model.body !== null) {
					if (this.model.body.indexOf('@' + u.name) > -1) {
						userList.push(u.username);
					}
				}
				if (this.model.title.indexOf('@' + u.name) > -1) {
					userList.push(u.username);
				}
			}
			this.issueModel['mentioned_users'] = [];
			this.issueModel['mentioned_users'] = userList.join(',');
		}
		if (this.model['hashtags'] && this.model['hashtags'].length > 0) {
			const tagList = [];
			for (const h of this.model['hashtags']) {
				if (this.model.body !== null) {
					if (this.model.body.indexOf('#' + h['name']) > -1) {
						tagList.push(h['name']);
					}
				}
				if (this.model.title.indexOf('#' + h['name']) > -1) {
					tagList.push(h['name']);
				}
			}
			const re = /(?:^|\W)#(\w+)(?!\w)/g;
			let match;
			while ((match = re.exec(this.model.body))) {
				if (tagList.indexOf(match[1]) < 0) {
					tagList.push(match[1]);
				}
			}
			this.issueModel['hashtags'] = [];
			this.issueModel['hashtags'] = tagList.join(',');
		}

		if (this.privacy.selectedProduct.length !== 0) {
			const productArray: any[] = [];
			this.privacy.selectedProduct.forEach((product) => {
				if (product) {
					productArray.push(product);
				}
			});
			this.issueModel.products = productArray.join(', ');
		}

		if (this.selectedPriority) {
			const priority = this.selectedPriority.toLowerCase();
			this.issueModel.priority = priority;
		}

		this.handleIssueHashtags(this.model.title);
		if (this.model.body) {
			this.handleIssueHashtags(this.model.body);
		}

		this.issueModel.title = this.strippedTitle + ' - ' + this.model.title;
		this.issueModel.description = this.model.body;
		delete this.model.body;
	}

	private callUpdateIssue(): void {
		this.pageLoadingUiService.setPageLoading(true);
		this.postService.updateIssue(this.issueModel, this.post.uid).subscribe((data) => {
			this.pageLoadingUiService.setPageLoading(false);
			this.postService.alterPostBodyAccordingToUserMentionAndHashtag(data);
			this.updatedPostEmitter.emit(new Post(data));
			if (this.privacy.products) {
				this.privacy.products.forEach((value) => {
					value['checked'] = false;
				});
			}
			// For resetting issue detail page after edit
			this.resetIssueAnalyticsPage.emit();
			this.privacy.selectedProduct = [];
			delete this.issueModel['attachmentType'];
			delete this.issueModel['doc'];
			delete this.issueModel['url'];
			delete this.issueModel['images'];
			delete this.issueModel['location'];
			delete this.issueModel['customer'];
			this.removeImage = true;
			this.model.images = [];
			this.tempImages = [];
			this.removeUploads = false;
			this.fileUpload = false;
			this.doc_upload = false;
			this.isTitle = false;
			this.disableIt();
			this.layoutService.hideBd();
		});
	}

	private handleIssueHashtags(text): void {
		if (text.indexOf('#') > -1) {
			const words = text.split(' ');
			words.forEach((element) => {
				if (element.indexOf('#') > -1) {
					const index = element.indexOf('#');
					if (index === 0) {
						const string = element.replace(/(^#)/g, '');
						if (this.hashes.indexOf(string) === -1) {
							this.hashes.push(string);
						}
					}
				}
			});
			if (this.hashes.length > 0) {
				this.issueModel['hashtags'] = this.hashes.join(',');
			}
		}
	}

	// Show and hide password for zoom meeting
	public showPassword(): void {
		this.show = !this.show;
	}

	// __________________________________EDIT TODO ITEMS FUNCTIONS__________________________________________//

	/**
	 * Function ti get todo details
	 */
	private getTodoDetailsForEdit(todoUid): void {
		this.pageLoadingUiService.setPageLoading(true);
		this.networkService.getTask(todoUid).subscribe(
			(res) => {
				this.pageLoadingUiService.setPageLoading(false);
				if (res) {
					this.post['project'] = res['project'];
					this.parentTaskUID = res?.parentTodoUid;

					if (res.totalSubTasks && res.type === 'todo') {
						this.networkService.getSubtasks(res?.uid, res?.totalSubTasks, '', '').subscribe((data) => {
							data.objects.forEach((obj) => {
								this.subtasks.push(obj);
								// this.subtasksIndexContainer.push(this.subtasks.length - 1);
							});
						});
					}
					/** Set project privacy */
					if (this.post.project) {
						this.projectPrivacyCp = this.post.project.channel ? this.post.project.channel.name : 'Everyone';
						this.privacy.projectSelected = this.post.project;
						this.privacy.currentProject = this.post.project;
					} else if (this.post['folderDetails'] && this.post['folderDetails']['name']) {
						this.projectPrivacyCp = this.post['folderDetails']['name'];
					} else {
						this.post.project = null;
						this.projectPrivacyCp = 'Team not selected';
					}
				}
			},
			() => {
				this.pageLoadingUiService.setPageLoading(false);
			}
		);
	}

	/**
	 * Display ToDo Item edit modal
	 */
	private showToDoItemEditModal(): void {
		if (this.post) {
			this.getTodoDetailsForEdit(this.post.uid);
		}
		this.dueDateComponent.currentTime = null;
		if (this.post['folderDetails']) {
			this.handleTodolistPrivacy(this.post['folderDetails']);
		}
		if (this.post.description) {
			this.model.body = this.post.description;
		}

		if (this.post['folderDetails'] && this.post['folderDetails']['uid']) {
			this.listPrivacy = {
				name: this.post['folderDetails']['name'],
				uid: this.post['folderDetails']['uid'],
			};
		} else {
			this.listPrivacy = 'Team not selected';
		}

		this.postService.editSelectedPost = this.post;
		this.model.title = this.post.title;
		this.removeImage = false;
		this.showMainPostForm = false;
		this.showTaskEditForm = true;
		this.displayTaskOptions = true;
		this.postType = this.post.type;
		// Set default values on edit form
		this.taskReminders = this.postReminders;
		this.assignedMembers = [];
		this.todoItemUid = this.post.uid;
		this.todoItem.title = this.post.title;
		this.todoItem['status'] = this.post['status'];
		if (this.post.assignedUser && this.post.assignedUser.username) {
			this.todoItem['assignedUser'] = this.post.assignedUser.username;
		}
		this.todoItem.priority = this.post.priority;
		this.privacy.projectSelected = null;
		if (this.post.project && this.post.project.uid) {
			this.privacy.projectSelected = this.post.project;
			this.privacy.projectUid = this.post.project.uid;
			this.todoItem['project'] = this.post.project.uid;
		}

		if (this.post['repeatCycle']) {
			this.dueDateComponent['selectedDueDate'] = this.post['repeatCycle'];
		} else {
			this.dueDateComponent['selectedDueDate'] = 'daily';
		}

		// if (this.post['oneOff']) {
		//   let d = new Date(this.post.oneOff);
		//   this.dueDateComponent.setPreviousDateOnCalendar(d);
		//   this.dueDateComponent.setDate(d);

		// }

		if (this.post['estimatedTime']) {
			const a = this.post.estimatedTime;
			const b = new Date(a);
			this.dueDateComponent.endTime = b.getHours() + ':' + (b.getMinutes() > 9 ? b.getMinutes() : '0' + b.getMinutes());
		} else {
			this.dueDateComponent.endTime = null;
		}

		if (this.post['startTime']) {
			const a = this.post.startTime;
			const b = new Date(a);
			this.dueDateComponent.startTime = b.getHours() + ':' + (b.getMinutes() > 9 ? b.getMinutes() : '0' + b.getMinutes());
		} else {
			this.dueDateComponent.startTime = null;
		}

		if (this.post.repeatFreequency) {
			this.dueDateComponent['selectedRepeat'] = this.post.repeatFreequency;
		} else {
			this.dueDateComponent['selectedRepeat'] = '';
		}

		this.selectedTaskForEdit = this.post;

		if (this.todoItem) {
			this.taskType = 'task';
			this.todoItem['remindersSelected'] = [];
			this.todoItem['selectedDueDate'] = this.selectedTaskForEdit.repeatCycle;
			if (this.selectedTaskForEdit.startTime) {
				const a = this.selectedTaskForEdit['startTime'];
				const b = new Date(a);
				this.todoItem['fromTime'] = b.getHours() + ':' + (b.getMinutes() > 9 ? b.getMinutes() : '0' + b.getMinutes());
			} else {
				this.todoItem['fromTime'] = null;
			}
			if (this.selectedTaskForEdit.estimatedTime) {
				const a = this.selectedTaskForEdit['estimatedTime'];
				const b = new Date(a);
				this.todoItem['toTime'] = b.getHours() + ':' + (b.getMinutes() > 9 ? b.getMinutes() : '0' + b.getMinutes());
			} else {
				this.todoItem['toTime'] = null;
			}
		}

		this.dueDateComponent.selectedReminders = [];

		if (this.post.myRelations && this.post.myRelations.reminder) {
			this.dueDateComponent.getContentReminders(this.post);
		}

		this.prioritySelected = this.post.priority;
		this.todoItem['priority'] = this.post.priority;
		if (this.post.priority === 'p1') {
			this.currentPriority = 'high';
		} else if (this.post.priority === 'p2') {
			this.currentPriority = 'med';
		} else {
			this.currentPriority = 'low';
		}

		if (this.post.assignedUser && this.post.assignedUser.name) {
			this.defaultTaskMember = this.post.assignedUser.name;
		} else {
			if (this.post.assignedUser && this.post.assignedUser.username) {
				this.defaultTaskMember = this.post.assignedUser.username;
			} else {
				this.defaultTaskMember = this.translate.instant('Unassigned');
			}
		}

		if (this.todoListPrivacy && this.todoListPrivacy !== 'Everyone') {
			const privacy = this.todoListPrivacy;
			this.setPrivacyOfToDoList(privacy);
		} else {
			this.getToDoListMembers('all', 'all');
		}

		if (this.post.repeatCycle === 'one_off') {
			this.dueDateComponent.showPreviouslySelectedDate(new Date(this.post.oneOff));
		}

		this.setTodoOptions();
		this.updateAttachmentDetails();
	}

	private handleEditCustomer(): void {
		this.model.title = this.post.title;
		this.showMainPostForm = false;
		this.showTaskEditForm = true;
		this.displayCustomerAdditionalOptions = true;
		this.removeImage = false;
		this.updateAttachmentDetails();
		this.postType = 'edit-customer';
		this.customerUid = this.post.uid;
		this.prioritySelected = this.post.priority;
		this.customer.title = this.post.title;
		this.customer.priority = this.post.priority;
		this.customer.status = this.post.status;
		this.stateSelected = this.post.status;
		if (this.post.status === 'open') {
			this.currentState = 'new';
		} else if (this.post.status === 'pool') {
			this.currentState = 'inactive';
		} else if (this.post.status === 'fixed') {
			this.currentState = 'trial';
		} else if (this.post.status === 'closed') {
			this.currentState = 'active';
		} else {
			this.currentState = 'in progress';
		}

		if (this.post.priority === 'p1') {
			this.currentPriority = 'high';
		} else if (this.post.priority === 'p2') {
			this.currentPriority = 'med';
		} else {
			this.currentPriority = 'low';
		}

		if (this.post.assignedUser) {
			this.currentCustomer = this.post.assignedUser['name'];
			this.selectedCustomerUid = this.post.assignedUser['username'];
		} else {
			this.currentCustomer = this.translate.instant('Unassigned');
			this.selectedCustomerUid = '';
			this.customerSelected = '';
			delete this.customer['assignedUser'];
		}

		// if (this.post.product && this.post.product['slug']) {
		//   this.privacy.selectedProduct.push(this.post.product.slug);
		//   this.postService.productSlug = this.post.product.slug;
		// }
		if (this.post.products && this.post.products.length > 0) {
			this.privacy.products = [];
			this.privacy.selectedProduct = [];
			this.post.products.forEach((product) => {
				product['checked'] = true;
				if (product['slug']) {
					this.privacy.selectedProduct.push(product.slug);
					this.privacy.products.push(product);
				}
			});
			// this.privacy.selectedProduct.push(this.post.product.slug);
			// this.postService.productSlug = this.post.product.slug;
		}

		if (this.post.salesChannel) {
			const index = this.salesChannels.findIndex((channel) => channel.slug === this.post.salesChannel);
			if (index > -1) {
				this.salesSelected = this.salesChannels[index];
				this.customer['salesChannel'] = this.salesSelected['slug'];
				this.currentSaleChannel = this.salesSelected['option'];
			}
		}
		if (this.post.dealType) {
			const index = this.dealTypes.findIndex((deal) => deal.slug === this.post.dealType);
			if (index > -1) {
				this.dealSelected = this.dealTypes[index];
				this.customer['dealType'] = this.dealSelected['slug'];
				this.currentDealType = this.dealSelected['option'];
			}
		}
		if (this.post.customerType) {
			const index = this.customerTypes.findIndex((custom) => custom.slug === this.post.customerType);
			if (index > -1) {
				this.typeSelected = this.customerTypes[index];
				this.customer['customerType'] = this.typeSelected['slug'];
				this.currentCustomerType = this.typeSelected['option'];
			}
		}
		if (this.post.currency) {
			this.currentCurrencyType = this.post.currency;
			const index = this.currencyTypes.findIndex((currency) => currency === this.post.currency);
			if (index > -1) {
				this.currencySelected = this.currencyTypes[index];
				this.currentCurrencyType = this.currencySelected;
			}
		}
		if (this.post.dealSize) {
			this.customerPrice = this.post.dealSize;
			this.customer['dealSize'] = this.customerPrice;
		}

		if (this.post.address) {
			const address = this.post.address;
			if (address['buildingNumber']) {
				this.additionalContactForm.controls.houseNo.setValue(address['buildingNumber']);
			}
			if (address['street1']) {
				this.additionalContactForm.controls.streetOne.setValue(address['street1']);
			}
			if (address['street2']) {
				this.additionalContactForm.controls.streetTwo.setValue(address['street2']);
			}
			if (address['city']) {
				this.additionalContactForm.controls.city.setValue(address['city']);
				this.customer['cityUid'] = address['cityUid'];
			}
			if (address['state']) {
				this.additionalContactForm.controls.state.setValue(address['state']);
			}
			if (address['postcode']) {
				this.additionalContactForm.controls.zip.setValue(address['postcode']);
			}
			if (address['email']) {
				this.additionalContactForm.controls.email.setValue(address['email']);
			}
			if (address['contactNumber']) {
				this.additionalContactForm.controls.phone.setValue(address['contactNumber']);
			}
			if (address['countryCode']) {
				this.customer['countryCode'] = address['countryCode'];
			}
			if (address['countryName']) {
				this.additionalContactForm.controls.country.setValue(address['countryName']);
			}
			if (address['phoneCode']) {
				this.additionalContactForm.controls.code.setValue(address['phoneCode']);
			}
			if (address['contactName']) {
				this.customerContactName = address['contactName'];
			}
		}
	}

	private resetEditCustomer(): void {
		this.customerSelected = '';
		this.selectedCustomerUid = '';
		this.currentCustomer = '';
		this.currentPriority = '';
		this.currentState = '';
		this.customer.title = '';
		this.customer.status = '';
		this.customer.priority = '';
		this.customerUid = '';
	}

	public displayStatesModal(): void {
		this.displayStates = 'block';
		if (this.customerStates.length === 0) {
			this.networkService.getCustomerStates().subscribe((data) => {
				if (data.CustomerStates) {
					this.customerStates = data.CustomerStates;
					const index = this.customerStates.findIndex((state) => state[0] === 'in_progress');
					if (index > -1) {
						this.customerStates.splice(index, 1);
					}
				}
			});
		}
	}

	public displayMembersForAttachment(): void {
		this.displayMemberForAttachment = 'block';
		this.memberService.getMembers(this.network.uid).subscribe((data) => {
			this.noAssignee = false;
			this.attachmentMembers = data.objects;
			if (data.next) {
				this.member_nextUrl = data.next.split('alpha')[1];
			} else {
				this.member_nextUrl = '';
			}
		});
	}

	public paginateMemberAttachment(): void {
		if (this.member_nextUrl) {
			this.postService.paginate(this.member_nextUrl).subscribe((data) => {
				if (data) {
					this.attachmentMembers = this.attachmentMembers.concat(data['objects']);
					if (data.next) {
						this.member_nextUrl = data.next.split('alpha')[1];
					} else {
						this.member_nextUrl = '';
					}
				}
			});
		}
	}

	public onSelectPostMember(): void {
		this.displayMemberForAttachment = 'none';
		if (this.model.member) {
			this.onRemoveDocument();
			this.attachmentMember = this.model.member;
			this.model.images = [];
			this.model.preview_images = [];
			this.tempImages = [];
			this.photo_upload = false;
			this.doc_upload = false;
			this.article = false;
			this.location_display = 'none';
			delete this.model.location;
		}
	}

	public removeSelectedMember(): void {
		this.selectedMember['uid'] = '';
		this.attachmentMember = '';
		delete this.model.member;
	}

	public displayMembersForCustomer(): void {
		this.displayModalForCustomer = 'block';
		this.assignee = [];
		this.noAssignee = true;
		this.memberService.getMembers(this.network.uid).subscribe((data) => {
			this.noAssignee = false;
			this.assignee = data.objects;
			if (data.next) {
				this.member_nextUrl = data.next.split('alpha')[1];
			} else {
				this.member_nextUrl = '';
			}
		});
	}

	public paginateCustomerMembers(): void {
		if (this.member_nextUrl) {
			this.postService.paginate(this.member_nextUrl).subscribe((data) => {
				if (data) {
					this.assignee = this.assignee.concat(data['objects']);
					if (data.next) {
						this.member_nextUrl = data.next.split('alpha')[1];
					} else {
						this.member_nextUrl = '';
					}
				}
			});
		}
	}

	public setPriority(): void {
		if (this.postType === 'todo' || this.postType === 'postTodo') {
			this.todoItem['priority'] = this.prioritySelected;
		} else {
			this.customer['priority'] = this.prioritySelected;
		}

		if (this.prioritySelected === 'p1') {
			this.currentPriority = 'high';
		} else if (this.prioritySelected === 'p2') {
			this.currentPriority = 'med';
		} else {
			this.currentPriority = 'low';
		}
	}

	public setCustomerState(): void {
		if (this.stateSelected) {
			this.customer['status'] = this.stateSelected;
			if (this.stateSelected === 'open') {
				this.currentState = 'New';
			} else if (this.stateSelected === 'fixed') {
				this.currentState = 'Trial';
			} else if (this.stateSelected === 'closed') {
				this.currentState = 'Active';
			} else if (this.stateSelected === 'pool') {
				this.currentState = 'Inactive';
			} else {
				this.currentState = 'In progress';
			}
		}
	}

	public setMemberForCustomer(): void {
		if (this.customerSelected) {
			if (this.customerSelected['user'] && this.customerSelected['user']['name']) {
				this.currentCustomer = this.customerSelected['user']['name'];
				this.customer['assignedUser'] = this.customerSelected['user']['username'];
			}
		}
	}

	// eslint-disable-next-line spellcheck/spell-checker
	public onUassignedForCustomer(): void {
		this.displayModalForCustomer = 'none';
		delete this.customer['assignedUser'];
		this.currentCustomer = this.translate.instant('Unassigned');
		this.customerSelected = '';
		this.selectedCustomerUid = '';
	}

	private callUpdateCustomer(): void {
		if (this.model['mentioned_users'] && this.model['mentioned_users'].length > 0) {
			const userList = [];
			for (const u of this.model['mentioned_users']) {
				if (this.model.title.indexOf('@' + u.name) > -1) {
					userList.push(u.username);
				}
			}
			this.customer['mentioned_users'] = [];
			this.customer['mentioned_users'] = userList.join(',');
		}
		if (this.model['hashtags'] && this.model['hashtags'].length > 0) {
			const tagList = [];
			for (const h of this.model['hashtags']) {
				if (this.model.title.indexOf('#' + h['name']) > -1) {
					tagList.push(h['name']);
				}
			}
			const re = /(?:^|\W)#(\w+)(?!\w)/g;
			let match;
			while ((match = re.exec(this.model.body))) {
				if (tagList.indexOf(match[1]) < 0) {
					tagList.push(match[1]);
				}
			}
			this.customer['hashtags'] = [];
			this.customer['hashtags'] = tagList.join(',');
		}

		const contactDetails = this.additionalContactForm.value;
		if (contactDetails['houseNo']) {
			this.customer['buildingNumber'] = contactDetails['houseNo'];
		}
		if (contactDetails['streetOne']) {
			this.customer['street1'] = contactDetails['streetOne'];
		}
		if (contactDetails['streetTwo']) {
			this.customer['street2'] = contactDetails['streetTwo'];
		}
		if (contactDetails['zip']) {
			this.customer['zipCode'] = contactDetails['zip'];
		}
		if (contactDetails['phone']) {
			this.customer['contactNumber'] = contactDetails['phone'];
			if (!this.selectedCountry && !this.customer['countryCode']) {
				this.customer['countryCode'] = 'TR';
			}
		}
		if (contactDetails['email']) {
			this.customer['email'] = contactDetails['email'];
		}
		if (contactDetails['state']) {
			this.customer['stateName'] = contactDetails['state'];
		}
		if (this.customerContactName) {
			this.customer['contactName'] = this.customerContactName;
		}
		if (this.customerPrice) {
			this.customer['dealSize'] = this.customerPrice;
			this.customer['currency'] = this.currentCurrencyType;
		}
		if (this.privacy.selectedProduct.length !== 0) {
			const productArray: any[] = [];
			this.privacy.selectedProduct.forEach((product) => {
				if (product) {
					productArray.push(product);
				}
			});
			this.customer['product'] = productArray.join(', ');
		}
		this.customer.title = this.model.title;
		this.pageLoadingUiService.setPageLoading(true);
		this.networkService.editCustomer(this.customerUid, this.customer).subscribe(
			(data) => {
				this.newCustomerDetailAddedEmitter.emit(data);
				this.updatedCustomerEmitter.emit(new Post(data));
				this.resetCustomerDetailPage.emit();
				this.onCloseEdit.emit();
				this.layoutService.hideBd();
				this.pageLoadingUiService.setPageLoading(false);
				this.clearCustomerDetails();
				this.model = new Post(
					{
						title: null,
						body: this.model.body,
						category: null,
						privacy: this.lastPrivacy,
						images: [],
						preview_images: [],
						shareable: true,
						commentable: true,
						custom_privacy: undefined,
						channel: undefined,
						content_privacy: undefined,
						department: null,
					},
					true
				);
			},
			() => {
				this.layoutService.hideBd();
				this.pageLoadingUiService.setPageLoading(false);
				this.clearCustomerDetails();
			}
		);
	}

	private clearCustomerDetails(): void {
		this.disableIt();
		this.additionalContactForm.reset();
		this.isCustomerSubmitted = false;
		delete this.model.location;
		delete this.customer['attachmentType'];
		delete this.customer['location'];
		delete this.customer['url'];
		delete this.customer['images'];
		delete this.customer['products'];
		delete this.customer['dealSize'];
		delete this.customer['contactName'];
		delete this.customer['buildingNumber'];
		delete this.customer['street1'];
		delete this.customer['street2'];
		delete this.customer['cityUid'];
		delete this.customer['city'];
		delete this.customer['stateName'];
		delete this.customer['zipCode'];
		delete this.customer['countryCode'];
		delete this.customer['contactNumber'];
		delete this.customer['email'];
		delete this.customer['city'];
		delete this.customer['cityUid'];
		this.typeSelected = '';
		this.dealSelected = '';
		this.salesSelected = '';
		this.customerPrice = '';
		this.customerContactName = '';
		this.selectedCountry = '';
		this.currentSaleChannel = '';
		this.currentDealType = '';
		this.currentCustomerType = '';
		this.additionalContactForm.controls.code.setValue(90);
		this.currentCurrencyType = 'TRY';
		this.currencySelected = 'TRY';
		this.postService.productSlug = '';
		this.privacy.selectedProduct = [];
		this.privacy.products.forEach((value) => {
			value['checked'] = false;
		});
	}

	/**
	 * Function to show members modal
	 */
	private showToDoListMembers(): void {
		this.display_members = 'block';
	}

	/**
	 * Function to select a member of todolist and set it on todo item
	 */
	private selectToDoListMember(): void {
		this.defaultTaskMember = this.memberObj.option;
		this.todoItem['assignedUser'] = this.itemMember.username;
		this.display_members = 'none';
	}

	/**
	 * Function to set key value pair for filtering members based on privacy
	 */
	private setPrivacyOfToDoList(privacy): void {
		if (privacy.channel && !privacy.access) {
			const uid = privacy.channel;
			this.getToDoListMembers('channel', uid);
		} else if (privacy.channel && privacy.access === 'external') {
			const uid = privacy.channel;
			this.getExternalChannelMembers(uid);
		} else if (privacy.role) {
			const p = privacy;
			const key = Object.keys(p);
			const value = Object.values(p);
			const newValue = value[0].toString().toLowerCase();
			this.getToDoListMembers(key[0], newValue);
		} else if (privacy.status) {
			const p = privacy;
			const key = 'membership_status';
			const value = Object.values(p);
			this.getToDoListMembers(key, value[0]);
		} else if (privacy.renewalStatus) {
			const p = privacy;
			const key = 'renewal_status';
			const value = Object.values(p);
			this.getToDoListMembers(key, value[0]);
		} else if (Array.isArray(privacy)) {
			const customPrivacy = [];
			let filter;
			privacy.forEach((user) => {
				if (user.id) {
					customPrivacy.push(user.id);
				}
				filter = '[' + customPrivacy.join(',') + ']';

				this.getToDoListMembers('custom_privacy', filter);
			});
		} else {
			const p = privacy;
			const key = Object.keys(p);
			const value = Object.values(p);
			this.getToDoListMembers(key[0], value[0]);
		}
	}

	// -------------------------------------------------------

	/**
	 * Function to display modal for members in a todolist
	 */
	public displayListMembersModal(): void {
		if (this.listPrivacy['uid'] !== localStorage.getItem('MyTodolist')) {
			if (this.privacy.projectSelected) {
				const project = this.privacy.projectSelected ? this.privacy.projectSelected : '';
				if (project && project.channel && project.channel.uid) {
					this.isLoading = true;
					this.todoListMembers = [];
					this.getToDoListMembers('channel', project.channel.uid);
				} else {
					this.isLoading = true;
					this.todoListMembers = [];
					this.getToDoListMembers('all', 'all');
				}
			}
			this.display_members = 'block';
		}
	}

	/**
	 * Function to add members to array for task form
	 * 1. Check if array contains selected member already
	 * 2. If selected member doesn't exists,then push member
	 * 3. Else remove member
	 */
	public selectMembersForTask(member): void {
		const index = this.assignedMembers.findIndex((x) => x.uid === member.uid); // 1
		if (index === -1) {
			// 2
			this.assignedMembers.push(member);
		} else {
			this.assignedMembers.splice(index, 1); // 3
		}
	}

	public selectMembersForTaskEdit(member): void {
		this.object['value'] = member.uid;
		if (member.user && member.user.username) {
			this.memberForTaskEdit = member;
		}
	}

	/*
	 * Function which puts selected members for a task in a todo item
	 */
	public onConfirmMembersForTask(): void {
		const taskMembers: any[] = [];
		if (this.assignedMembers.length > 0) {
			this.assignedMembers.forEach((member) => {
				if (member && member.user) {
					taskMembers.push(member.user.username);
				}
			});
			this.todoItem['assignedUser'] = taskMembers.join(', ');
			this.confirm_members = true;
			this.defaultTaskMember = '';
			this.display_members = 'none';
		} else {
			if (this.defaultTaskMember === 'Unassigned' && !this.memberForTaskEdit) {
				delete this.todoItem['assignedUser'];
				this.defaultTaskMember = this.translate.instant('Unassigned');
				this.display_members = 'none';
			} else if (this.memberForTaskEdit && this.memberForTaskEdit.user) {
				this.defaultTaskMember = this.memberForTaskEdit['user']['name'];
				this.todoItem['assignedUser'] = this.memberForTaskEdit['user']['username'];
				this.display_members = 'none';
			} else {
				this.defaultTaskMember = this.currentUser.name;
				this.display_members = 'none';
			}
		}
	}

	/**
	 * Function to get todolist members based on cp of todolist
	 * 1. Get all members
	 * 2. Check if members are already selected for task
	 * 3. If members are selected,turn radio button on
	 * 4. Else turn all radio button off
	 * 5. Set next url
	 */
	private getToDoListMembers(key, value): void {
		let filterString;
		if (key === 'all') {
			filterString = 'external_members=false';
		} else {
			filterString = key + '=' + value;
		}
		this.memberService.getMembers(this.network.uid, '', filterString).subscribe((data) => {
			// 1
			this.isLoading = false;
			this.todoListMembers = data.objects;
			if (this.assignedMembers.length > 0) {
				// 2
				this.todoListMembers.forEach((member) => {
					this.assignedMembers.forEach((m) => {
						if (m.uid === member.uid) {
							member['checked'] = true; // 3
						}
					});
				});
			} else {
				this.todoListMembers.forEach((member) => {
					// 4
					member['checked'] = false;
				});
			}

			if (this.postType === 'todo') {
				this.todoListMembers.forEach((member) => {
					// 4
					if (member['user']['username'] === this.todoItem['assignedUser']) {
						this.object['value'] = member.uid;
					}
				});
			}

			if (data.next) {
				this.next_url = data.next ? data.next.split('alpha')[1] : '';

				this.paginateMembers();
			} else {
				this.next_url = '';
			}
		});
	}

	private getExternalChannelMembers(value): void {
		this.memberService.getMembersForExternalChannel(value).subscribe((data) => {
			this.todoListMembers = data.objects;
			if (this.assignedMembers.length > 0) {
				// 2
				this.todoListMembers.forEach((member) => {
					this.assignedMembers.forEach((m) => {
						if (m.uid === member.uid) {
							member['checked'] = true; // 3
						}
					});
				});
			} else {
				this.todoListMembers.forEach((member) => {
					// 4
					member['checked'] = false;
				});
			}

			if (this.postType === 'todo') {
				this.todoListMembers.forEach((member) => {
					// 4
					if (member['user']['username'] === this.todoItem['assignedUser']) {
						this.object['value'] = member.uid;
					}
				});
			}

			if (data.next) {
				this.next_url = data.next ? data.next.split('alpha')[1] : '';

				this.paginateMembers();
			} else {
				this.next_url = '';
			}
		});
	}

	// Update privacy values of a list
	private updatePrivacyString(): void {
		this.todoLists.forEach((list) => {
			if (localStorage.getItem('MyTodolist') && list.uid === localStorage.getItem('MyTodolist')) {
				list['privacy_name'] = this.currentUser.name;
			} else {
				if (list['customPrivacy']) {
					list['customPrivacy'].forEach(() => {
						list['privacy_name'] = this.translate.instant('Custom Selection');
					});
				} else if (list['contentPrivacy']) {
					if (list['contentPrivacy'].role && list['contentPrivacy'].department) {
						const index = this.departments.findIndex((department) => department.uid === list['contentPrivacy'].department);
						if (index > -1) {
							list['privacy_name'] = this.departments[index]['name'] + ' , ' + list['contentPrivacy'].role;
						}
					} else if (list['contentPrivacy'].role) {
						list['privacy_name'] = list['contentPrivacy'].role;
						//
					} else if (list['contentPrivacy'].department) {
						const index = this.departments.findIndex((department) => department.uid === list['contentPrivacy'].department);
						if (index > -1) {
							list['privacy_name'] = this.departments[index]['name'];
						}
					} else if (!list['contentPrivacy'].role || !list['contentPrivacy'].department) {
						this.value = Object.keys(list['contentPrivacy']);
						this.personals.forEach((element) => {
							if (element.key === this.value[0]) {
								list['privacy_name'] = element.valueLabel;
							}
						});
						if (list['contentPrivacy'].status) {
							list['privacy_name'] = list['contentPrivacy'].status;
						} else if (list['contentPrivacy'].renewalStatus) {
							list['privacy_name'] = list['contentPrivacy'].renewalStatus;
						} else if (list['contentPrivacy'].membership_level) {
							const index = this.SubscriptionTemplates.findIndex((val) => val.uid === list['contentPrivacy'].membership_level);
							if (index > -1) {
								list['privacy_name'] = this.SubscriptionTemplates[index]['name'];
							}
						} else if (list['contentPrivacy'].trialPeriod) {
							list['privacy_name'] = list['contentPrivacy'].trialPeriod;
						}
					}
				} else if (list['channel'] && Object.keys(list['channel']).length) {
					list['privacy_name'] = list['channel'].name;
				} else {
					list['privacy_name'] = this.translate.instant('Everyone');
				}
			}
		});
	}

	/**
	 * Function to show one off date of  todolists
	 */
	private showOneOffDate(): void {
		this.todoLists.forEach((list) => {
			if (list.repeatCycle === 'one_off' && list.oneOff) {
				const newDate = new Date(list.oneOff).toLocaleString();
				list['newDate'] = newDate.substring(0, newDate.indexOf(','));
			}
		});
	}

	/*
	 * Function to close all todo edit modals
	 */
	public closeModal(): void {
		this.display_todoLists = 'none';
		this.display_options = 'none';
		this.display_members = 'none';
		this.display_priority = 'none';
		this.assignedMembers = [];
		this.defaultTaskMember = this.currentUser.name;
		this.todoListMembers.forEach((member) => {
			member['checked'] = false;
		});
	}

	/**
	 * Function to get remaining members in members modal
	 */
	public paginateMembers(): void {
		if (this.next_url) {
			this.networkService.paginateIssues(this.next_url).subscribe((data) => {
				this.next_url = data.next ? data.next.split('alpha')[1] : '';

				this.todoListMembers = this.todoListMembers.concat(data.objects);
				if (this.postType === 'todo') {
					this.todoListMembers.forEach((member) => {
						// 4
						if (member['user']['username'] === this.todoItem['assignedUser']) {
							this.object['value'] = member.uid;
						}
					});
				}
				if (this.next_url) {
					this.paginateMembers();
				}
			});
		}
	}

	/**
	 * Function to Update todo item
	 */
	public updateToDoItem(): void {
		if (this.model['mentioned_users'] && this.model['mentioned_users'].length > 0) {
			const userList = [];
			for (const u of this.model['mentioned_users']) {
				if (this.model.title.indexOf('@' + u.name) > -1) {
					userList.push(u.username);
				}
			}
			this.todoItem['mentioned_users'] = [];
			this.todoItem['mentioned_users'] = userList.join(',');
		}
		if (this.model['hashtags'] && this.model['hashtags'].length > 0) {
			const tagList = [];
			for (const h of this.model['hashtags']) {
				if (this.model.title.indexOf('#' + h['name']) > -1) {
					tagList.push(h['name']);
				}
			}
			const re = /(?:^|\W)#(\w+)(?!\w)/g;
			let match;
			while ((match = re.exec(this.model.body))) {
				if (tagList.indexOf(match[1]) < 0) {
					tagList.push(match[1]);
				}
			}
			this.todoItem['hashtags'] = [];
			this.todoItem['hashtags'] = tagList.join(',');
		}

		if (!this.model.title) {
			this.errorMessage = 'Please enter task name';
		} else if (this.display_date && !this.modelB) {
			this.errorMessage = 'Please select a date';
		} else {
			if (this.privacy.projectSelected) {
				this.todoItem['project'] = this.privacy.projectSelected['uid'];
			}

			if (this.model.body) {
				this.todoItem['description'] = this.model.body;
			} else {
				this.todoItem['description'] = '';
			}

			this.todoItem.title = this.model.title;

			if (this.parentTaskUID) {
				this.todoItem['parent_content_uid'] = this.parentTaskUID;
				this.todoItem['parent_content_type'] = 'todo';
			}

			this.pageLoadingUiService.setPageLoading(true);

			this.networkService.updateToDoItem(this.todoItem, this.todoItemUid).subscribe(
				(data: any) => {
					// if (this.dueDateComponent['previousReminders'] && this.dueDateComponent['previousReminders'].length > 0) {
					//   if (!this.arraysEqual(this.dueDateComponent['previousReminders'], this.remindersSelected)) {
					//     this.setReminder(data);
					//   }
					// } else if (this.remindersSelected.length > 0) {
					//   this.setReminder(data);
					// }

					if (data.status === 'closed') {
						this.mixPanelService.track('Completed Task', {
							workspace: this.network?.name || 'NA',
							appVersion: environment.version,
							language: this.translate?.currentLang,
							username: this.currentUser?.username || 'NA',
							name: this.currentUser?.name || 'NA',
							project: data?.project?.name || 'NA',
							date: moment().format('YYYY-MM-DD'),
						});

						this.segmentService.trackEvent('Completed Task', {
							workspace: this.network?.name || 'NA',
							appVersion: environment.version,
							language: this.translate?.currentLang,
							username: this.currentUser?.username || 'NA',
							name: this.currentUser?.name || 'NA',
							project: data?.project?.name || 'NA',
							date: moment().format('YYYY-MM-DD'),
						});
					}

					this.setReminderForTaskAndSubTask(data, this.todoItem['remindersSelected'] ? this.todoItem['remindersSelected'] : []);

					if (this.subTasksToBeAdded.length > 0) {
						this.onCreateSubtasks(data);
					} else {
						this.pageLoadingUiService.setPageLoading(false);
					}

					this.updatedPostEmitter.emit(new Post(data));

					if (this.subTasksToBeAdded?.length > 0 || this.subTasksToBeDeleted?.length > 0 || this.parentTaskUID) {
						const newTask = <ITodo>{ ...data };
						this.projectService.onSubTaskEditEvent.emit(newTask);
					}

					this.resetAdditionalOptions();
					this.disableIt();
					this.layoutService.hideBd();
					this.todoListMembers.forEach((member) => {
						member['checked'] = false;
					});
					this.errorMessage = '';
					this.oneOff_date = '';
					this.project.id = '';
					this.project.name = 'Project';
					delete this.todoItem['project'];
					this.display_date = false;
					delete this.todoItem['images'];
					delete this.todoItem['attachmentType'];
					delete this.todoItem['member'];
					delete this.todoItem['doc'];
					delete this.todoItem['location'];
					delete this.todoItem['url'];
					delete this.todoItem['parent_content_uid'];
					delete this.todoItem['parent_content_type'];

					this.attachmentMember = '';
					this.removeImage = true;
					this.model.images = [];
					this.tempImages = [];
					this.removeUploads = false;
					this.fileUpload = false;
					this.doc_upload = false;
					this.resetTodoDetailPage.emit();

					this.subTasksToBeDeleted.forEach((taskUID) => {
						this.networkService.deleteToDoItem(taskUID, '').subscribe(() => {});
					});

					if (data.type === 'sub_task' && this.parentTaskUID) {
						this.networkService.getTask(this.parentTaskUID).subscribe((task: ITodo) => {
							this.projectService.onToggleSubTasksClickEvent.emit(task);
						});
					} else {
						this.projectService.todoUpdatedByExternalSource(data);
					}

					this.taskUpdatedEmitter.emit(data);
					this.parentTaskUID = '';
				},
				() => {
					this.clearSubtasks();

					this.pageLoadingUiService.setPageLoading(false);
				}
			);
		}
	}

	private handleTodoAttachmentsOnEdit(): void {
		if (this.model['url']) {
			this.todoItem['attachmentType'] = 'url';
			this.todoItem['url'] = this.model['url'];
		}
		if (this.model['location']) {
			this.todoItem['attachmentType'] = 'location';
			if (this.model['location']['uid']) {
				this.todoItem['location'] = this.model['location']['uid'];
			} else {
				this.locationService
					.create({
						venueId: this.model.location.venueId,
						latitude: this.model.location.latitude,
						longitude: this.model.location.longitude,
						name: this.model.location.name,
						category: this.model.location.category,
						website: this.model.location.website,
						phone: this.model.location.phone,
					})
					.subscribe((data) => {
						this.todoItem['location'] = data.uid;
					});
			}
		}
		if (this.doc_upload && this.documentList.indexOf(this.fileType[0]) > -1) {
			this.todoItem['attachmentType'] = 'doc';
			this.todoItem['doc'] = this.model.images.join('|');
			this.model.images = [];
		}
		if (this.model.images.length > 0 && !this.removeImage) {
			this.todoItem['images'] = this.model.images.join('|');
			this.todoItem['attachmentType'] = 'photo';
		}
		if (this.fileUpload && this.model['doc']) {
			this.todoItem['attachmentType'] = 'doc';
			this.todoItem['doc'] = this.model['doc'];
		}
	}

	/*
	 * Function to show add task modal for post/events/issues etc
	 */
	private showTodoCreateFormForPost(post): void {
		this.todoItem['priority'] = 'p1';
		this.displayTaskOptions = false;
		if (post['addTaskForIssue']) {
			this.postType = 'postTodo';
			this.model.title = `[Issue] - ${post.title}`;
			this.todoItem['contentType'] = 'issue';
			this.todoItem['contentUid'] = post.uid;
		} else {
			this.postType = this.post.type;
			if (post['postType'] === 'meeting') {
				if (post['title']) {
					this.model.title = `[Video Conference] - ${post.title}`;
				} else {
					this.model.title = '[Video Conference] - ';
				}
			} else {
				if (post['title']) {
					this.model.title = `[${post.postType}] - ${post['title']}`;
				} else {
					this.model.title = `[${post.postType}] -`;
				}
			}
			if (post.postType === 'customer') {
				this.todoItem['contentType'] = 'customer';
			} else {
				this.todoItem['contentType'] = 'post';
			}
			this.todoItem['contentUid'] = post.uid;
		}

		/**
		 * 1. If post is event,then set event starts as default date
		 * 2. Utc convesion of starts date from server
		 * 3. Set default event time as due time
		 * 4. Set estimated time based on event starts
		 */

		if (this.post.starts && (this.post['postType'] === 'event' || this.post['postType'] === 'meeting')) {
			const date = new Date(this.post.starts); // 1
			const startMonth = date.getUTCMonth() + 1;
			const startDay = date.getUTCDate();
			const startYear = date.getUTCFullYear();
			const startHours = date.getUTCHours();
			const startMinutes = date.getUTCMinutes();
			const startSeconds = date.getUTCSeconds();
			const utcDate = startYear + '-' + startMonth + '-' + startDay + ' ' + startHours + ':' + startMinutes + ':' + startSeconds; // 2
			this.todoItem['oneOff'] = utcDate; // 1
			this.display_date = true;
			this.modelB = { year: startYear, month: startMonth, day: startDay };
			this.selectedDate = startYear + '-' + startMonth + '-' + startDay;
			this.selectedDueDate = { option: 'Specify Date', slug: 'one_off' };
			this.selectedRepeatOption.option = 'Specify Date';
			this.repeatObj.option = 'Specify Date';

			// Due time set
			this.todoItem['estimatedTime'] = utcDate;
			const taskDatetime = this.post.starts;
			const d = new Date(taskDatetime);
			this.taskDueTime = { hour: d.getHours(), minute: d.getMinutes() }; // 3
			if (this.taskDueTime['minute'] < 10) {
				const minutes = '0' + this.taskDueTime['minute'];
				this.currentDueTime = this.taskDueTime['hour'] + ':' + minutes;
			} else {
				this.currentDueTime = this.taskDueTime['hour'] + ':' + this.taskDueTime['minute'];
			}
		} else {
			this.todoItem['selectedDueDate'] = this.todoItem['repeatCycle'];
			this.dueDateComponent.reset();

			this.selectedRepeatOption = { option: 'Today', slug: 'daily' };
			this.repeatObj.option = 'Today';
		}

		this.privacy.projectUid = '';
		this.privacy.currentProject = '';
		this.privacy.projectSelected = '';

		this.showMainPostForm = false;
		this.showTaskEditForm = true;
		this.showSelectTodoListOption = true;

		this.lists = { name: '', uid: '' };
		this.assignedMembers = [];
		this.handlePreviousTodolist();

		// this.taskReminders = this.eventReminders;
		this.taskReminders = this.postReminders;
		this.currentPriority = 'med';
		this.prioritySelected = 'p2';

		if (this.currentUser.name) {
			this.defaultTaskMember = this.currentUser.name;
			this.todoItem['assignedUser'] = this.currentUser.username;
		} else {
			this.defaultTaskMember = this.currentUser.username;
			this.todoItem['assignedUser'] = this.currentUser.username;
		}

		this.updateAttachmentDetails();
	}

	/**
	 *
	 * Function to call the api for post todoitem creation
	 */
	private callCreateTask(): void {
		if (this.model.body) {
			this.todoItem['description'] = this.model.body;
		}
		if (!this.model.title) {
			this.errorMessage = 'Please enter task name';
		} else if (this.display_date && !this.modelB) {
			this.errorMessage = 'Please select a date';
		} else {
			// if (this.selectedRepeatOption.option === 'Specify Date') {
			//   this.todoItem.repeatCycle = 'one_off';
			//   this.todoItem['oneOff'] = this.parserFormatter.format(this.modelB);
			// } else if (this.selectedRepeatOption.option === 'Today') {
			//   this.todoItem.repeatCycle = 'daily';

			// } else if (this.selectedRepeatOption.option === 'This week') {
			//   this.todoItem.repeatCycle = 'weekly';

			// } else if (this.selectedRepeatOption.option === 'This month') {
			//   this.todoItem.repeatCycle = 'monthly';
			// }
			// else if (this.selectedRepeatOption.option === 'Tomorrow') {   // Handle tomorrow due date for task create
			//   const today = new Date();
			//   const tomorrow = new Date(today);
			//   tomorrow.setDate(today.getDate() + 1);

			//   let dd: any;
			//   let mm: any;
			//   dd = tomorrow.getDate();
			//   mm = tomorrow.getMonth() + 1;
			//   let yyyy = tomorrow.getFullYear();
			//   if (dd < 10) {
			//     dd = '0' + dd;
			//   }
			//   if (mm < 10) {
			//     mm = '0' + mm;
			//   }
			//   let tomorrowDate = yyyy + '-' + mm + '-' + dd;
			//   this.todoItem.repeatCycle = 'one_off';
			//   this.todoItem['oneOff'] = tomorrowDate;
			// }

			if (this.privacy.projectSelected) {
				this.todoItem['project'] = this.privacy.projectSelected['uid'];
			}

			if (this.attachmentMember && this.attachmentMember['uid']) {
				this.todoItem['attachmentType'] = 'member';
				this.todoItem['member'] = this.attachmentMember['uid'];
			}

			this.todoItem.title = this.model.title;
			this.setAdditionalOptions();
			this.pageLoadingUiService.setPageLoading(true);

			this.networkService.createToDoItem(this.todoItem, this.todoList_uid, this.listPrivacy).subscribe(
				(data) => {
					this.mixPanelService.track('Created Tasks', {
						workspace: this.network?.name || 'NA',
						appVersion: environment.version,
						language: this.translate.currentLang,
						username: this.currentUser.username || 'NA',
						name: this.currentUser.name || 'NA',
						project: this.projectDetail || 'NA',
					});

					this.segmentService.trackEvent('Created Tasks', {
						workspace: this.network?.name || 'NA',
						appVersion: environment?.version,
						language: this.translate?.currentLang,
						username: this.currentUser.username || 'NA',
						name: this.currentUser.name || 'NA',
						project: this.projectDetail || 'NA',
					});

					// Call function to create subtasks
					if (this.subtasks.length > 0) {
						this.checkSubtasks(data);
					} else {
						this.pageLoadingUiService.setPageLoading(false);
					}
					// ====
					if (data['folderDetails']) {
						this.cacheLastTodolist(data['folderDetails']);
					} else {
						this.cacheLastTodolist('Team not selected');
					}
					this.todoListMembers.forEach((member) => {
						member['checked'] = false;
					});

					this.resetTodoForm();
					this.resetAdditionalOptions();
					this.setReminder(data);
					// this.pageLoadingUiService.setPageLoading(false);
					this.confirm_members = false;
					this.assignedMembers = [];
					this.handlePreviousTodolist();
					this.errorMessage = '';
					this.project.id = '';
					this.project.name = 'Project';
					delete this.todoItem['project'];
					this.display_date = false;
					// Clear all attachment variables
					this.article = false;
					this.photo_upload = false;
					this.doc_upload = false;
					delete this.todoItem['doc'];
					delete this.todoItem['url'];
					delete this.todoItem['attachmentType'];
					delete this.todoItem['images'];
					delete this.todoItem['member'];
					this.showTaskEditForm = false;
					this.layoutService.hideBd();
				},
				() => {
					this.pageLoadingUiService.setPageLoading(false);
				}
			);
		}
	}

	private checkSubtasks(data): void {
		this.subtasks = this.subtasks.filter((task) => task['title']);

		if (this.subtasks.length > 0) {
			this.onCreateSubtasks(data);
		} else {
			this.subtasksIndexContainer = [];
			this.subtasks = [];
			this.subTasksToBeAdded = [];
			this.subTasksToBeDeleted = [];
			this.pageLoadingUiService.setPageLoading(false);
		}
	}

	private arraysEqual(a1, a2): boolean {
		return JSON.stringify(a1) === JSON.stringify(a2);
	}

	private resetAttachments(): void {
		this.post = '';
		this.selectedMember['uid'] = '';
		this.attachmentMember = '';
		this.article = false;
		this.photo_upload = false;
		this.doc_upload = false;
		this.tempImages = [];
		this.model.images = [];
		this.model.preview_images = [];
		this.location_display = 'none';
		delete this.todoItem['attachmentType'];
		delete this.todoItem['doc'];
		delete this.todoItem['url'];
		delete this.todoItem['images'];
		delete this.todoItem['member'];
		delete this.model['venueName'];
		delete this.model['doc'];
		delete this.model['url'];
		delete this.model['location'];
	}

	private resetTodoForm(): void {
		this.resetAttachments();
		this.privacy.projectUid = '';
		this.privacy.currentProject = '';
		this.privacy.projectSelected = '';
		this.mentionPrivacy = '';
		this.taskReminderOption = ['none', 'None'];
		this.currentPriority = 'med';
		this.prioritySelected = 'p2';
		this.todoItem['priority'] = 'p2';
		this.taskReminders = this.postReminders;
		this.selectedRepeatOption = { option: 'Today', slug: 'daily' };
		this.repeatObj.option = 'Today';
		this.todoItem['title'] = '';
		this.todoItem['description'] = '';
		this.model.title = '';
		this.model.body = '';
		this.model.images = [];
		this.model.url = '';
		this.tempImages = [];
		this.choices = [];
		this.fileType = [];
		this.hashes = [];
		delete this.todoItem['numberOfOpens'];
		delete this.todoItem['hashtags'];
		delete this.todoItem['contentUid'];
		delete this.todoItem['contentType'];
		delete this.todoItem['project'];
		this.networkService.clearFormSubscriptions();

		this.display_date = false;
		this.selectedDate = '';

		if (this.currentUser.name) {
			this.defaultTaskMember = this.currentUser.name;
			this.todoItem['assignedUser'] = this.currentUser.username;
		} else {
			this.defaultTaskMember = this.currentUser.username;
			this.todoItem['assignedUser'] = this.currentUser.username;
		}
		if (this.modelTitle) {
			this.modelTitle.nativeElement.style.height = 'auto';
		}
		if (this.modelBody) {
			this.modelBody.nativeElement.style.height = 'auto';
		}
	}

	/**
	 * Cache last used todolist on creating task
	 * @param folder
	 */
	private cacheLastTodolist(folder): void {
		if (folder['uid']) {
			const list = folder;
			localStorage.setItem(`${this.network.uid}-Todolist`, JSON.stringify(list));
		} else {
			localStorage.setItem(`${this.network.uid}-Todolist`, 'Team not selected');
		}
	}

	private handlePreviousTodolist(): void {
		if (localStorage.getItem(`${this.network.uid}-Todolist`)) {
			// 1
			const val = localStorage.getItem(`${this.network.uid}-Todolist`);
			if (val === 'Team not selected' || val === 'No List Selected') {
				this.lists = { name: 'Team not selected', uid: '' };
				this.todoList_uid = '';
				this.listPrivacy = 'Team not selected';
				this.mentionPrivacy = '';
				this.selectToDoList();
			} else {
				const cacheList = JSON.parse(localStorage.getItem(`${this.network.uid}-Todolist`)); // 2
				if (cacheList['uid']) {
					// 3
					this.lists = { name: cacheList['name'], uid: cacheList['uid'] };
					this.todoList_uid = cacheList['uid'];
					this.listPrivacy = cacheList; // 4
					this.selectToDoList();
				}
			}
		} else {
			this.lists = { name: 'Team not selected', uid: '' };
			this.todoList_uid = '';
			this.listPrivacy = 'Team not selected';
			this.mentionPrivacy = '';
			this.selectToDoList();
		}
	}

	/**
	 *
	 * Function for selecting a todolist and setting members
	 */
	public selectToDoList(): void {
		if (this.listPrivacy && this.listPrivacy !== 'Team not selected') {
			this.setDefaultRepeatCycle(this.listPrivacy);
			this.handleTodolistPrivacy(this.listPrivacy);
			this.todoList_uid = this.listPrivacy.uid;
			this.display_todoLists = 'none';
			// Reset assigneesss on selecting a new todo list
			this.assignedMembers = [];
			this.defaultTaskMember = this.currentUser.name;
			this.todoItem['assignedUser'] = this.currentUser.username;
			if (this.lists['name']) {
				if (this.listPrivacy && this.listPrivacy.channel && this.listPrivacy.channel.uid) {
					const uid = this.listPrivacy.channel.uid;

					if (this.listPrivacy.channel.access === 4) {
						this.getExternalChannelMembers(uid);
					} else {
						this.getToDoListMembers('channel', uid);
					}
				} else if (this.listPrivacy.contentPrivacy) {
					if (!this.listPrivacy.contentPrivacy.role) {
						if (this.listPrivacy.contentPrivacy.status) {
							const privacy = this.listPrivacy.contentPrivacy;
							const key = 'membership_status';
							const value = Object.values(privacy);
							this.getToDoListMembers(key, value[0]);
						} else if (this.listPrivacy.contentPrivacy.renewalStatus) {
							const privacy = this.listPrivacy.contentPrivacy;
							const key = 'renewal_status';
							const value = Object.values(privacy);
							this.getToDoListMembers(key, value[0]);
						} else {
							const privacy = this.listPrivacy.contentPrivacy;
							const key = Object.keys(privacy);
							const value = Object.values(privacy);
							this.getToDoListMembers(key[0], value[0]);
						}
					} else if (this.listPrivacy.contentPrivacy.role) {
						const privacy = this.listPrivacy.contentPrivacy;
						const key = Object.keys(privacy);
						const value = Object.values(privacy);
						const newVal = value[0].toString().toLowerCase();
						this.getToDoListMembers(key[0], newVal);
					}
				} else if (this.listPrivacy.customPrivacy && this.listPrivacy.customPrivacy.length > 0) {
					const customPrivacy = [];
					let filter;
					this.listPrivacy.customPrivacy.forEach((user) => {
						if (user.id) {
							customPrivacy.push(user.id);
						}
						filter = '[' + customPrivacy.join(',') + ']';
					});
					this.getToDoListMembers('custom_privacy', filter);
				} else {
					this.getToDoListMembers('all', 'all');
				}
			}
		} else if (this.listPrivacy === 'Team not selected') {
			this.todoList_uid = '';
			this.mentionPrivacy = '';
			this.getToDoListMembers('all', 'all');
			this.display_todoLists = 'none';
		} else {
			this.display_todoLists = 'none';
		}
	}

	/**
	 * For handling todolist privacy for user mentions list in task form.
	 * @param list
	 */
	public handleTodolistPrivacy(list): void {
		if (list['uid'] === localStorage.getItem('MyTodolist')) {
			const arr = '[' + this.currentUser['userId'] + ']';
			this.mentionPrivacy = 'custom_privacy=' + arr;
		} else {
			if (list['contentPrivacy']) {
				this.mentionPrivacy = 'content_privacy=' + JSON.stringify(list['contentPrivacy']);
			} else if (list['customPrivacy'] && list['customPrivacy'].length > 0) {
				const customPrivacy: any[] = [];
				// let custom: any;
				list.customPrivacy.forEach((element) => {
					if (element.userId) {
						customPrivacy.push(element.userId);
					} else {
						customPrivacy.push(element.id);
					}
				});
				const custom = '[' + customPrivacy.join(',') + ']';
				this.mentionPrivacy = 'custom_privacy=' + custom;
			} else if (list['channel'] && list['channel']['uid']) {
				this.mentionPrivacy = 'channel=' + list['channel']['uid'];
			} else {
				this.mentionPrivacy = '';
			}
		}
	}

	/**
	 * Handle repeat cycle of todolist on task create form on
	 * selecting a todolist
	 * @param list
	 */
	public setDefaultRepeatCycle(list): void {
		if (list['repeatCycle'] === 'daily') {
			this.selectedRepeatOption = { option: 'Today', slug: 'daily' };
			this.repeatObj.option = 'Today';
		} else if (list['repeatCycle'] === 'monthly') {
			this.selectedRepeatOption = { option: 'This month', slug: 'monthly' };
			this.repeatObj.option = 'This month';
		} else if (list['repeatCycle'] === 'weekly') {
			this.selectedRepeatOption = { option: 'This week', slug: 'weekly' };
			this.repeatObj.option = 'This week';
		} else if (list['repeatCycle'] === 'one_off') {
			this.selectedRepeatOption = { option: 'Specify Date', slug: 'one_off' };
			this.repeatObj.option = 'Specify Date';
			this.display_date = true;
			const d = list['oneOff'];
			const x = new Date(d);
			this.oneOff_date = x;
			const startMonth = x.getMonth() + 1;
			const startDay = x.getDate();
			const startYear = x.getFullYear();
			this.selectedDate = startYear + '-' + startMonth + '-' + startDay;
		}
	}

	/**
	 * Set privacy of todolist as default for project
	 * 1. Check if contentPrivacy exists within todolist.
	 * 2. Check if department exist as contentPrivacy and handle department.
	 * 3. Check if role exist as contentPrivacy and handle role.
	 * 4. Check if personalFilters(location) exists as contentPrivacy and handle it.
	 * 5. Check if membership cp exists as contentPrivacy and handle it.
	 * 6. Check if customPrivacy exists as cp and handle it.
	 * 7. Check if channel exists as cp and handle it.
	 */
	public getProjectPrivacy(data): void {
		if (data['folderDetails']['name'] !== 'My Todo List') {
			if (data['contentPrivacy']) {
				// 1
				if (data['contentPrivacy'].department) {
					// 2

					const index = this.departments.findIndex((department) => department.uid === data['contentPrivacy'].department);
					if (index > -1) {
						this.privacy.forNames = this.departments[index].name;
						this.privacy['selectedDepartment'] = this.departments[index];
					}
				} else {
					this.privacy['selectedDepartment'] = null;
				}

				if (data['contentPrivacy'].role) {
					// 3
					const role = {
						name: data['contentPrivacy'].role,
						slug: data['contentPrivacy'].role,
					};
					this.privacy['selectedRole'] = role;
					this.privacy.forNames = data['contentPrivacy'].role;
				} else {
					this.privacy['selectedRole'] = null;
				}

				if (!data['contentPrivacy'].role && !data['contentPrivacy'].department) {
					// 4
					const field = Object.keys(data['contentPrivacy']);
					this.personals.forEach((element) => {
						if (element.key === field[0] && field[0] !== 'manager') {
							this.privacy.forNames = element.valueLabel;
							this.privacy['personalFilter'] = element;
						}
					});
					if (data['contentPrivacy'].status) {
						// 5
						this.privacy.forNames = data['contentPrivacy'].status;
						this.privacy['selectByStatusValue'] = data['contentPrivacy'];
					} else if (data['contentPrivacy'].renewalStatus) {
						this.privacy.forNames = data['contentPrivacy'].renewalStatus;
						this.privacy['selectByRenewalValue'] = data['contentPrivacy'];
					} else if (data['contentPrivacy'].membership_level) {
						this.privacy['selectByLevelValue'] = data['contentPrivacy'];
					} else if (data['contentPrivacy'].manager) {
						if (this.privacy.myTeam && this.privacy.myTeam.key) {
							this.privacy['personalFilter'] = this.privacy.myTeam;
							this.privacy.forNames = this.translate.instant('My Team');
						}
					}
				}
			} else {
				this.privacy['content_privacy'] = null;
				this.privacy['selectedDepartment'] = null;
				this.privacy['role'] = null;
			}

			if (data['customPrivacy'] && data['customPrivacy'].length > 0) {
				// 6
				this.privacy['custom_privacy'] = data['customPrivacy'];
				const names = [];
				this.privacy.custom_privacy.forEach((user) => {
					if (user['user']) {
						names.push(user['user'].name);
					} else if (user['name']) {
						names.push(user['name']);
					} else {
						names.push(user.username);
					}
				});
				this.privacy.forNames = names.join(',');
			} else {
				this.privacy['custom_privacy'] = [];
			}

			if (data['channel']) {
				// 7
				this.privacy.forNames = data['channel']['name'];
				this.privacy['channel'] = data['channel'];
			}

			if (!data['contentPrivacy'] && !data['customPrivacy'] && !data['channel']) {
				this.privacy.forNames = 'Everyone';
				this.privacy['custom_privacy'] = [];
				this.privacy['selectedDepartment'] = '';
				this.privacy['selectedRole'] = '';
				this.privacy['channel'] = '';
			}
		} else {
			this.privacy.custom_privacy = [];
			const names = [];
			const a = {
				id: this.currentUser['userId'],
				username: this.currentUser['name'],
			};
			this.privacy.custom_privacy.push(a);
			this.privacy.custom_privacy.forEach((user) => {
				if (user['user']) {
					names.push(user['user'].name);
				} else if (user['name']) {
					names.push(user['name']);
				} else {
					names.push(user.username);
				}
			});
			this.privacy.forNames = names.join(',');
		}
	}

	/**
	 * Function to create a todo item of a post
	 */
	public createPostAsToDoItem(): void {
		if (this.model['mentioned_users'] && this.model['mentioned_users'].length > 0) {
			const userList = [];
			for (const u of this.model['mentioned_users']) {
				if (this.model.title.indexOf('@' + u.name) > -1) {
					userList.push(u.username);
				}
			}
			this.todoItem['mentioned_users'] = [];
			this.todoItem['mentioned_users'] = userList.join(',');
		}
		if (this.model['hashtags'] && this.model['hashtags'].length > 0) {
			const tagList = [];
			for (const h of this.model['hashtags']) {
				if (this.model.title.indexOf('#' + h['name']) > -1) {
					tagList.push(h['name']);
				}
			}
			const re = /(?:^|\W)#(\w+)(?!\w)/g;
			let match;
			while ((match = re.exec(this.model.body))) {
				if (tagList.indexOf(match[1]) < 0) {
					tagList.push(match[1]);
				}
			}
			this.todoItem['hashtags'] = [];
			this.todoItem['hashtags'] = tagList.join(',');
		}

		if (this.model.images && this.model.images.length > 0) {
			this.todoItem['attachmentType'] = 'photo';
			this.todoItem['images'] = this.model.images.join('|');
		} else if (this.model['doc']) {
			this.todoItem['attachmentType'] = 'doc';
			this.todoItem['doc'] = this.model['doc'];
		} else if (this.model['location'] && this.model['location']['uid']) {
			this.todoItem['attachmentType'] = 'location';
			this.todoItem['location'] = this.model['location']['uid'];
		} else if (this.model['url']) {
			this.todoItem['attachmentType'] = 'url';
			this.todoItem['url'] = this.model['url'];
		} else if (this.attachmentMember && this.attachmentMember['uid']) {
			this.todoItem['attachmentType'] = 'member';
			this.todoItem['member'] = this.attachmentMember['uid'];
		}

		this.callCreateTask();
	}

	/**
	 * Function for uploading new image in edit question post
	 */
	public uploadQuestionPostImage(): void {
		this.question_image = !this.question_image;
		this.article = false;
		this.location_display = 'none';
		this.doc_upload = false;
	}

	/**
	 * Function for closing upload image display in edit question post
	 */
	public onCloseImageDisplay(): void {
		this.model.images = [];
		this.model.preview_images = [];
		this.question_image = false;
	}

	/**
	 * Function for uploading image in a question post locally and showing it in edit form
	 */
	public updateQuestionPostImage(): void {
		if (this.post.attachmentDetails.gallery && this.post.attachmentDetails.gallery.length > 0) {
			this.removeImage = false;
			this.questionImages = this.post.attachmentDetails.gallery;
			this.post.attachmentDetails.gallery.forEach((image) => {
				this.model.images.push(image.image.uid);
			});
			if (this.post.attachmentDetails.gallery.length <= 4) {
				this.maxPhotoUploadCount = 4 - this.post.attachmentDetails.gallery.length;
			} else {
				this.maxPhotoUploadCount = 0;
			}
		}
	}

	/**
	 * Function for removing image in edit question post
	 */
	public onRemoveQuestionImage(): void {
		this.removeImage = true;
		delete this.model.attachmentType;
		this.model.images = [];
	}

	/**
	 * Function for removing documents in edit post and clearing the modal
	 */
	public onRemoveDocument(): void {
		this.removeUploads = true;
		this.fileUpload = false;
		this.articleUpload = false;
		this.model.attachmentType = '';
		delete this.model['doc'];
		delete this.model['url'];
	}

	/**
	 * Function for uploading documents in edit post
	 */
	public uploadDocument(): void {
		// This will clear already present docs/urls
		this.onRemoveDocument();
		this.onRemoveLocation();
		this.removeSelectedMember();
		this.onRemoveImage();
		this.doc_upload = !this.doc_upload;
		this.article = false;
		this.location_display = 'none';
		// if (this.model.preview_images && this.model.preview_images.length > 0) {
		//   this.model.preview_images = [];
		//   this.model.images = [];
		// }
	}

	/**
	 * Function for uploading urls in edit post
	 */
	public postArticle(): void {
		// This will clear already present docs/urls
		this.onRemoveDocument();
		this.onRemoveLocation();
		this.onRemoveImage();
		this.removeSelectedMember();
		this.article = !this.article;
	}

	/**
	 * Function to get all todolists for add task form
	 * 1. Show loader
	 * 2. Get all todolists
	 * 3. Set next url if exists
	 * 4. Call pagination if next url exists
	 */
	public displayTodoListsModal(): void {
		if (this.projectPrivacyCp) {
			this.errorDialogForCpSelection = 'block';
			this.errorTextForCpSelection = this.translate.instant('You need to remove the project in order to change the team');
			return;
		}

		this.todoLists = [];
		this.isListsLoading = true;
		this.scrollStatus = false; // 1
		this.display_todoLists = 'block';
		this.networkService.getToDoListForItem().subscribe((list) => {
			// 2
			this.isListsLoading = false;
			this.todoLists = list.objects;
			this.updatePrivacyString();
			this.showOneOffDate();
			if (list.next) {
				// 3
				this.todo_next_url = list.next.split('alpha')[1];
				this.paginateLists(); // 4
			} else {
				this.todo_next_url = '';
			}
		});
	}

	/**
	 * Function to get remaining todolists
	 * 1. Get all todolists
	 * 2. Set next url
	 * 3. Append new todolists to array
	 */
	public paginateLists(): void {
		if (this.scrollStatus) {
			return;
		}
		this.scrollStatus = true;
		if (this.todo_next_url) {
			this.networkService.paginateToDoLists(this.todo_next_url).subscribe((data) => {
				// 1
				if (data.next) {
					this.todo_next_url = data.next.split('alpha')[1]; // 2
				} else {
					this.todo_next_url = '';
				}
				this.todoLists = this.todoLists.concat(data.objects); // 3
				this.scrollStatus = false;
				this.updatePrivacyString();
				this.showOneOffDate();
			});
		}
	}

	/**
	 * Function on selecting a todolist and turning radio btn on/off
	 */
	public onClickToDoList(list): void {
		if (list['uid']) {
			this.lists = { name: list['name'], uid: list['uid'] };
			this.listPrivacy = list;
		} else {
			this.lists = { name: list, uid: '' };
			this.listPrivacy = list;
		}
	}

	public displayProjects(): void {
		// this.setProjectPrivacy(this.post);
		this.privacy.getProjects();
		this.privacy.forNames = 'Everyone';
		this.privacy.previousSelection = 'Everyone';
		this.privacy.currentSelection = 'Everyone';
		this.resetPrivacyComponent();
	}

	/*
	 * Function to get remaining projects
	 * 1. Get all projects
	 * 2. Set next url
	 * 3. Append new projects to existing array
	 */
	public paginateProjects(): void {
		if (this.project_next_url) {
			this.networkService.paginateProjects(this.project_next_url).subscribe((data) => {
				// 1
				if (data.next) {
					this.project_next_url = data.next.split('alpha')[1]; // 2
				} else {
					this.project_next_url = '';
				}
				this.projects = this.projects.concat(data.objects); // 3
			});
		}
	}

	/**
	 * Function which executes on clicking a project
	 * @param project
	 */
	public onClickProject(project): void {
		if (this.project.id === project.uid) {
			this.project['id'] = '';
		} else {
			this.selectedProject = project;
			this.project['id'] = project.uid;
		}
	}

	/**
	 * Function which executes on selecting a project and adding
	 * its uid to task create object
	 */
	public onSelectProject(): void {
		if (this.project.id === '') {
			delete this.todoItem['project'];
			this.project.name = this.translate.instant('Project');
			this.displayProjectsModal = 'none';
		} else {
			this.project['name'] = this.selectedProject['name'];
			this.todoItem['project'] = this.project.id;
			this.displayProjectsModal = 'none';
		}
	}

	/**
	 * Function to display new project form
	 */
	public displayCreateNewProjectForm(): void {
		this.displayProjectForm = 'block';
		this.updateSelectionFields();
		this.isProjectCreateForm = true;
	}

	/**
	 * Function to show privacy values selected from privacy modal on add new project form
	 */
	public updatePrivacyStringBasedOnPrivacyComponent(): void {
		this.forNames = '';
		if (this.privacy.isPrivate) {
			this.forNames = this.currentUser.name;
		}
		if (this.privacy.custom_privacy && this.privacy.custom_privacy.length > 0) {
			const names = [];
			this.privacy.custom_privacy.forEach((user) => {
				if (user['user']) {
					names.push(user['user'].name);
				} else if (user['name']) {
					names.push(user['name']);
				} else {
					names.push(user.username);
				}
			});
			this.forNames = names.join(',');
		} else {
			if (this.privacy.selectedRole && this.privacy.selectedDepartment) {
				this.forNames = this.privacy.selectedRole.name + ', ' + this.privacy.selectedDepartment.valueLabel;
			} else if (this.privacy.selectedRole) {
				let roleName;
				if (this.privacy.selectedRole.name) {
					roleName = this.privacy.selectedRole.name;
				} else {
					roleName = this.privacy.selectedRole;
				}
				this.forNames = roleName;
			} else if (this.privacy.selectedDepartment) {
				let departmentName = '';
				departmentName = this.privacy.selectedDepartment.valueLabel;
				this.forNames = departmentName;
			} else if (this.privacy.personalFilter) {
				let personalFilter = '';
				personalFilter = this.privacy.personalFilter.valueLabel;
				this.forNames = personalFilter;
			} else if (this.privacy.channel) {
				this.forNames = this.privacy.channel.name;
			} else if (this.privacy.selectByRenewalValue) {
				this.forNames = this.privacy.selectByRenewalValue[1];
			} else if (this.privacy.selectByLevelValue) {
				this.forNames = this.privacy.selectByLevelValue.name;
			} else if (this.privacy.selectByStatusValue) {
				this.forNames = this.privacy.selectByStatusValue[1];
			} else {
				this.forNames = 'Everyone';
			}
		}
	}

	/**
	 * Update cp value on creating a product according to the detail from the privacy modal.
	 */
	public updatePrivacyValues(): void {
		if (this.privacy.custom_privacy && this.privacy.custom_privacy.length > 0) {
			delete this.newProject['content_privacy'];
			delete this.newProject['channel'];
			this.newProject['custom_privacy'] = [];
			const names = [];
			const newObject = this.privacy.custom_privacy;
			newObject.forEach((member) => {
				member.privacyType = 'customPrivacy';
			});
			localStorage.setItem(`${this.network.uid}`, JSON.stringify(newObject));
			this.privacy.custom_privacy.forEach((user) => {
				if (user['user']) {
					this.newProject['custom_privacy'].push(user['user'].userId);
					names.push(user['user'].name);
				} else if (user.userId) {
					this.newProject['custom_privacy'].push(user.userId);
					names.push(user.username);
				} else {
					this.newProject['custom_privacy'].push(user.id);
					names.push(user.username);
				}
			});
		} else {
			let roleString = null;
			let departmentString = null;
			let personalFilterString = null;
			let selectByStatusString = null;
			let selectByRenewalString = null;
			let selectByLevelString = null;
			delete this.project['custom_privacy'];
			delete this.project['channel'];

			if (this.privacy.selectedRole && this.privacy.selectedDepartment) {
				roleString = '"role": "' + this.privacy.selectedRole.slug + '"';
				departmentString = '"department": "' + this.privacy.selectedDepartment.value + '"';
			} else if (this.privacy.selectedRole) {
				roleString = '"role": "' + this.privacy.selectedRole.slug + '"';
				// * ******************SPACE AFTER : ******************* */
				roleString = '"role": "' + this.privacy.selectedRole.slug + '"';
				const newObject = this.privacy.selectedRole;
				newObject.privacyType = 'role';
				localStorage.setItem(`${this.network.uid}`, JSON.stringify(newObject));
			} else if (this.privacy.selectedDepartment) {
				departmentString = '"department":"' + this.privacy.selectedDepartment.value + '"';
				// let departmentName = '';
				// departmentName = this.privacy.selectedDepartment.valueLabel;
				departmentString = '"department": "' + this.privacy.selectedDepartment.value + '"';
				// -
				const newObject = this.privacy.selectedDepartment;
				newObject.privacyType = 'department';
				localStorage.setItem(`${this.network.uid}`, JSON.stringify(newObject));
			} else if (this.privacy.personalFilter) {
				// let personalFilterName = '';
				// personalFilterName = this.privacy.personalFilter.valueLabel;
				personalFilterString = '"' + this.privacy.personalFilter.key + '": "' + this.privacy.personalFilter.value + '"';
				// -
				const newObject = this.privacy.personalFilter;
				newObject.privacyType = 'personalFilter';
				localStorage.setItem(`${this.network.uid}`, JSON.stringify(newObject));
			} else if (this.privacy.selectByStatusValue) {
				if (this.privacy.selectByStatusValue.status) {
					selectByStatusString = '"status": "' + this.privacy.selectByStatusValue.status + '"';
				} else {
					selectByStatusString = '"status": "' + this.privacy.selectByStatusValue[0] + '"';
					const newObject = this.privacy.selectByStatusValue;
					if (newObject.includes('status') === false) {
						newObject.push('status');
					}
					localStorage.setItem(`${this.network.uid}`, JSON.stringify(newObject));
				}
			} else if (this.privacy.selectByRenewalValue) {
				if (this.privacy.selectByRenewalValue.renewalStatus) {
					selectByRenewalString = '"renewalStatus": "' + this.privacy.selectByRenewalValue.renewalStatus + '"';
				} else {
					selectByRenewalString = '"renewalStatus": "' + this.privacy.selectByRenewalValue[0] + '"';
					const newObject = this.privacy.selectByRenewalValue;
					if (newObject.includes('renewalFrequency') === false) {
						newObject.push('renewalFrequency');
					}
					localStorage.setItem(`${this.network.uid}`, JSON.stringify(newObject));
				}
			} else if (this.privacy.selectByLevelValue) {
				if (this.privacy.selectByLevelValue.uid) {
					selectByLevelString = '"membership_level": "' + this.privacy.selectByLevelValue.uid + '"';
					const newObject = this.privacy.selectByLevelValue;
					newObject.privacyType = 'selectByLevel';
					localStorage.setItem(`${this.network.uid}`, JSON.stringify(newObject));
				} else {
					selectByLevelString = '"membership_level": "' + this.privacy.selectByLevelValue.membership_level + '"';
				}
			} else if (this.privacy['channel']) {
				this.newProject['channel'] = this.privacy['channel']['uid'];
				const newObject = this.privacy.channel;
				newObject['privacyType'] = 'subCircle';
				localStorage.setItem(`${this.network.uid}`, JSON.stringify(newObject));
			}
			if (
				this.privacy.custom_privacy.length === 0 &&
				!this.privacy.selectedRole &&
				!this.privacy.role &&
				!this.privacy.personalFilter &&
				!this.privacy.selectedDepartment &&
				!this.privacy.channel &&
				!this.model.channel &&
				!this.privacy.selectByStatusValue &&
				!this.privacy.selectByRenewalValue &&
				!this.privacy.selectByLevelValue
			) {
				localStorage.setItem(`${this.network.uid}`, JSON.stringify('Everyone'));
			}

			if (roleString && departmentString) {
				this.newProject['content_privacy'] = '{' + roleString + ', ' + departmentString + '}';
			} else if (roleString && !departmentString) {
				this.newProject['content_privacy'] = '{' + roleString + '}';
			} else if (!roleString && departmentString) {
				this.newProject['content_privacy'] = '{' + departmentString + '}';
			} else if (personalFilterString) {
				this.newProject['content_privacy'] = '{' + personalFilterString + '}';
			} else if (selectByStatusString) {
				this.newProject['content_privacy'] = '{' + selectByStatusString + '}';
			} else if (selectByRenewalString) {
				this.newProject['content_privacy'] = '{' + selectByRenewalString + '}';
			} else if (selectByLevelString) {
				this.newProject['content_privacy'] = '{' + selectByLevelString + '}';
			} else {
				delete this.newProject['content_privacy'];
			}
		}
		if (this.newProject['custom_privacy'] && this.newProject['custom_privacy'].length > 0) {
			this.newProject['custom_privacy'] = '[' + this.newProject['custom_privacy'].join(',') + ']';
		}
	}

	/** Retrieving cp on switching networks */
	private updateSelectionFields(): void {
		if (localStorage.getItem(`${this.network.uid}`) && this.currentPage !== 'collection' && this.currentPage !== 'channel-detail') {
			const retrievedCustomPrivacy = JSON.parse(localStorage.getItem(`${this.network.uid}`));

			if (retrievedCustomPrivacy.privacyType === 'role') {
				this.privacy['selectedRole'] = retrievedCustomPrivacy;
				this.forNames = retrievedCustomPrivacy.name;
				this.privacy.previousSelection = retrievedCustomPrivacy.name;
				this.privacy.currentSelection = retrievedCustomPrivacy.name;
				if (retrievedCustomPrivacy.role) {
					this.privacy.previousSelection = retrievedCustomPrivacy.role;
					this.privacy.currentSelection = retrievedCustomPrivacy.role;
				}
			}
			if (retrievedCustomPrivacy.privacyType === 'department') {
				if (retrievedCustomPrivacy.valueLabel) {
					this.privacy.previousSelection = retrievedCustomPrivacy.valueLabel;
					this.privacy.currentSelection = retrievedCustomPrivacy.valueLabel;
					this.forNames = retrievedCustomPrivacy.valueLabel;
				}
				if (retrievedCustomPrivacy.name && retrievedCustomPrivacy.uid) {
					this.privacy.previousSelection = retrievedCustomPrivacy.name;
					this.privacy.currentSelection = retrievedCustomPrivacy.name;
					this.forNames = retrievedCustomPrivacy.name;
				}
			}
			if (retrievedCustomPrivacy.privacyType === 'channel' || retrievedCustomPrivacy.privacyType === 'subCircle') {
				this.model.channel = retrievedCustomPrivacy.uid;
				this.privacy.channel = retrievedCustomPrivacy;
				this.privacy.previousSelection = retrievedCustomPrivacy.name;
				this.privacy.currentSelection = retrievedCustomPrivacy.name;
				this.forNames = retrievedCustomPrivacy.name;
			}
			if (retrievedCustomPrivacy.privacyType === 'personalFilter') {
				this.privacy['personalFilter'] = retrievedCustomPrivacy;
				this.privacy.previousSelection = retrievedCustomPrivacy.valueLabel;
				this.privacy.currentSelection = retrievedCustomPrivacy.valueLabel;
				this.forNames = retrievedCustomPrivacy.valueLabel;
			}
			if (retrievedCustomPrivacy.privacyType === 'selectByLevel') {
				this.privacy['selectByLevelValue'] = retrievedCustomPrivacy;
				this.privacy.previousSelection = retrievedCustomPrivacy.name;
				this.privacy.currentSelection = retrievedCustomPrivacy.name;
				this.forNames = retrievedCustomPrivacy.name;
			}
			if (retrievedCustomPrivacy === 'Everyone') {
				// this.currentNetworkDefaultCp = '';
				this.privacy.previousSelection = 'Everyone';
				this.privacy.currentSelection = 'Everyone';
				this.forNames = 'Everyone';
			}
			if (Array.isArray(retrievedCustomPrivacy)) {
				retrievedCustomPrivacy.forEach((member) => {
					if (member.privacyType === 'customPrivacy') {
						this.privacy['custom_privacy'] = retrievedCustomPrivacy;
						if (member.name) {
							this.privacy.previousSelection = member.name;
							this.privacy.currentSelection = member.name;
							this.forNames = member.name;
						}
						if (member.user) {
							this.privacy.previousSelection = member.user.name;
							this.privacy.currentSelection = member.user.name;
							this.forNames = member.user.name;
						}
						if (member.username) {
							this.privacy.previousSelection = member.username;
							this.privacy.currentSelection = member.username;
							this.forNames = member.username;
						}
					}
				});
				if (retrievedCustomPrivacy.includes('status') === true) {
					this.privacy.selectByStatusValue = retrievedCustomPrivacy;
					this.privacy.previousSelection = retrievedCustomPrivacy[1];
					this.privacy.currentSelection = retrievedCustomPrivacy[1];
					this.forNames = retrievedCustomPrivacy[1];
				}
				if (retrievedCustomPrivacy.includes('renewalFrequency') === true) {
					this.privacy.selectByRenewalValue = retrievedCustomPrivacy;
					this.privacy.previousSelection = retrievedCustomPrivacy[1];
					this.privacy.currentSelection = retrievedCustomPrivacy[1];
					this.forNames = retrievedCustomPrivacy[1];
				}
			}
		}
	}

	/**
	 * Function to create a new project and display created project
	 */
	public createNewProject(): void {
		if (!this.projectName) {
			// Show validation error
			this.projectError = true;
		} else {
			this.updatePrivacyValues();
			this.updateSelectionFields();
			this.newProject.name = this.projectName;
			this.pageLoadingUiService.setPageLoading(true);
			this.networkService.createNewProject(this.newProject, this.network.uid).subscribe((data) => {
				// Append new product
				this.projects.unshift(data);
				this.projectName = '';
				this.projectError = false;
				this.displayProjectForm = 'none';
				this.pageLoadingUiService.setPageLoading(false);
			});
		}
	}

	// _________________________________________________________________________________//

	/**
	 * Function to display additional options modal for tasks
	 */
	public displayAdditionalOptions(): void {
		this.displayAdditionalModal = 'block';
	}

	/**
	 * Function on selecting a task repeat option
	 * 1. Put selected repeat on a variable
	 * 2. Hide Options dropdown
	 */
	public onSelectTaskRepeat(option): void {
		this.taskRepeatOption = option; // 1
		this.showRepeats = false; // 2
	}

	/**
	 * Function on selecting a task reminder option
	 * 1. Put selected repeat on a variable
	 * 2. Hide Options dropdown
	 */
	public onSelectTaskReminder(option): void {
		this.taskReminderOption = option; // 1
		this.showReminders = false; // 2
		this.isTaskReminderSelected = true;
	}

	/**
	 *  Function on selecting a task reminder option
	 * 1. Check if repeat cycle is not daily or specify date
	 * 2. Show error message and disable time picker if true
	 * 3. Else enable time picker and hide error message
	 */
	public onEstimatedTimeSelect(): void {
		if (this.repeatObj['option'] !== 'Today' && this.repeatObj['option'] !== 'Specify Date' && this.repeatObj['option'] !== 'Tomorrow') {
			// 1
			this.showTaskTimeError = true; // 2
			this.dueTimeDisabled = true;
		} else {
			delete this.todoItem['estimatedTime'];
			this.showTaskTimeError = false; // 3
			this.dueTimeDisabled = false;
		}
	}

	public setTaskRepeat(): void {
		this.currentRepeat = this.repeatSelected;
		if (this.repeatSelected !== 'never') {
			this.todoItem['repeatFreequency'] = this.repeatSelected;
		} else {
			this.currentRepeat = '';
			delete this.todoItem['repeatFreequency'];
		}
	}

	/**
	 * Function to make radio btn on/off for repeat cycles
	 * @param val
	 */
	public onDueDateOptionClick(val): void {
		this.selectedDueDate = val;
		this.repeatObj = { option: val.option };
		if (this.repeatObj['option'] === 'weekly' || this.repeatObj['option'] === 'monthly') {
			this.taskReminderOption = ['none', 'None'];
			this.taskReminders = this.postReminders;
			this.currentDueTime = '';
			this.taskDueTime = '';
		} else {
			this.taskReminderOption = ['none', 'None'];
			this.taskReminders = this.eventReminders;
		}

		if (this.selectedDueDate && this.selectedDueDate.option === 'Specify Date') {
			this.display_date = true;
			this.oneOff_date = new Date();
		} else {
			this.display_date = false;
			this.oneOff_date = '';
		}
	}

	/**
	 * Select a due date option
	 */
	public onSelectDueDateOption(): void {
		if (this.selectedDueDate) {
			this.selectedRepeatOption = this.selectedDueDate;
			this.display_options = 'none';
			if (this.selectedDueDate.option === 'Specify Date' && this.modelB) {
				this.selectedDate = this.parserFormatter.format(this.modelB);
			} else {
				this.selectedDate = '';
				this.oneOff_date = '';
			}
		} else {
			this.display_options = 'none';
		}
	}

	/**
	 * Function on selecting unassigned option in todos
	 * 1. Close members modal
	 * 2. Set assignedUser empty
	 * 3. Set Unassigned on todo form
	 * 4. Set assigned members array empty
	 * 5. Turn off all radio btn on members modal
	 */
	private onUnassignedSelected(): void {
		this.display_members = 'none'; // 1
		delete this.todoItem['assignedUser']; // 2
		this.defaultTaskMember = this.translate.instant('Unassigned'); // 3
		this.assignedMembers = []; // 4
		this.confirm_members = false;
		this.memberForTaskEdit = '';
		this.object['value'] = '';
		this.todoListMembers.forEach((member) => {
			member['checked'] = false; // 5
		});
	}

	public resetReminderFields(): void {
		this.editTaskReminderId = '';
		this.timeForReminder = 0;
		// this.toast_message = 'block';
		delete this.reminderObject['reminderOption'];
		delete this.reminderObject['eventReminderOption'];
		this.taskReminderOption = ['none', 'None'];
		this.isTaskReminderSelected = false;
		// this.closeAfterSixSeconds();
	}

	private minutesUntilMidnight(): any {
		const midnight = new Date();
		midnight.setHours(24);
		midnight.setMinutes(0);
		midnight.setSeconds(0);
		midnight.setMilliseconds(0);
		return (midnight.getTime() - new Date().getTime()) / 1000 / 60;
	}

	/**
	 * Function to close reminder message automatically
	 */
	public closeAfterSixSeconds(): void {
		setTimeout(() => {
			this.toast_message = 'none';
		}, 8000);
	}

	/**
	 * Set Previous reminders on reminder options
	 */
	public displayPreviousReminders(): void {
		this.taskReminders.forEach((reminder) => {
			this.remindersSelected.forEach((r) => {
				if (r === reminder[0]) {
					reminder['checked'] = true;
				}
			});
		});
	}

	/**
	 * Function for setting estimated Time and repeats for tasks
	 * 1. Check if due time is selected for task
	 * 2. Set task due time in hour:min format
	 * 3. Check if repeat cycle is one off
	 * 4. Set Estimated time
	 * 5. If repeat cycle is not one off set estimated time with current date
	 * 6. IF task due time is not selected,remove estimated Time from todo object
	 * 7. If repeat option is selected for tasks set repeat frequency
	 * 8. Else delete repeat frequency from todo object
	 */
	public setAdditionalOptions(): void {
		if (this.taskDueTime && (this.taskDueTime['hour'] || this.taskDueTime['minute']) && !this.todoItem['estimatedTime']) {
			// 1
			const newDueTime = `${this.taskDueTime.hour}:${this.taskDueTime.minute}`; // 2
			if (this.repeatObj['option'] === 'Specify Date') {
				// 3
				const d = this.selectedDate + ' ' + newDueTime;
				const date = new Date(d);
				const startMonth = date.getUTCMonth() + 1;
				const startDay = date.getUTCDate();
				const startYear = date.getUTCFullYear();
				const startHours = date.getUTCHours();
				const startMinutes = date.getUTCMinutes();
				const startSeconds = date.getUTCSeconds();
				const utcDate = startYear + '-' + startMonth + '-' + startDay + ' ' + startHours + ':' + startMinutes + ':' + startSeconds;
				this.todoItem['estimatedTime'] = utcDate;
			} else if (this.repeatObj['option'] === 'Tomorrow') {
				const today = new Date();
				const tomorrow = new Date(today);
				tomorrow.setDate(today.getDate() + 1);
				const tomorrowDate = new Date(tomorrow).toISOString().slice(0, 10) + ''; // 5
				const d = tomorrowDate + ' ' + newDueTime;
				const date = new Date(d);
				const startMonth = date.getUTCMonth() + 1;
				const startDay = date.getUTCDate();
				const startYear = date.getUTCFullYear();
				const startHours = date.getUTCHours();
				const startMinutes = date.getUTCMinutes();
				const startSeconds = date.getUTCSeconds();
				const utcDate = startYear + '-' + startMonth + '-' + startDay + ' ' + startHours + ':' + startMinutes + ':' + startSeconds;
				this.todoItem['estimatedTime'] = utcDate;
			} else {
				const todayDate = new Date().toISOString().slice(0, 10) + ''; // 5
				const d = todayDate + ' ' + newDueTime;
				const date = new Date(d);
				const startMonth = date.getUTCMonth() + 1;
				const startDay = date.getUTCDate();
				const startYear = date.getUTCFullYear();
				const startHours = date.getUTCHours();
				const startMinutes = date.getUTCMinutes();
				const startSeconds = date.getUTCSeconds();
				const utcDate = startYear + '-' + startMonth + '-' + startDay + ' ' + startHours + ':' + startMinutes + ':' + startSeconds;
				this.todoItem['estimatedTime'] = utcDate;
			}
		}

		// if (this.taskRepeatOption !== 'never') {
		//   this.todoItem['repeatFreequency'] = this.taskRepeatOption; // 7
		// } else {
		//   delete this.todoItem['repeatFreequency']; // 8
		// }
	}

	/**
	 * Function to reset additional options fields in task form
	 * 1. Reset due time field
	 * 2. Reset repeat and reminder fields
	 * 3. Reset additional options ui fields
	 * 4. Remove estimated time and repeat frequency from todoItem object
	 */
	public resetAdditionalOptions(): void {
		// delete this.todoItem['assignedUser'];
		this.memberForTaskEdit = '';
		this.object['value'] = '';
		this.selectedDate = '';
		this.taskDueTime = ''; // 1
		this.taskRepeatOption = 'never'; // 2
		this.displayAdditionalModal = 'none'; // 3
		this.showReminders = false;
		this.showRepeats = false;
		this.repeatOptions = [
			{ option: 'Today', slug: 'daily' },
			{ option: 'Tomorrow', slug: 'one_off' },
			{ option: 'This week', slug: 'weekly' },
			{ option: 'This month', slug: 'monthly' },
			{ option: 'Specify Date', slug: 'one_off' },
		];
		delete this.todoItem['estimatedTime']; // 4
		delete this.todoItem['repeatFreequency'];
		this.currentRepeat = '';
		this.repeatSelected = 'never';
		this.currentDueTime = '';
		this.dueDateComponent.reset();
	}

	/**
	 * Display repeat cycle options and set current option
	 */
	public showRepeatOptions(): void {
		this.display_options = 'block';
	}

	/**
	 * Function to display Reminder options based on due time
	 * 1. IF due is selected,show event reminder options
	 * 2. Else show post reminder options
	 */
	public displayTaskReminderOptions(): void {
		if (this.taskDueTime) {
			// 1
			this.taskReminders = this.eventReminders;
		} else {
			// 2
			this.taskReminders = this.postReminders;
		}
	}

	/**
	 * Function to display todolist create popup
	 * 1. Display todolist create popup
	 * 2. Display cp values
	 */
	public displayCreateNewTodolistForm(): void {
		this.displayCreateTodoList = 'block'; // 1
		this.updateSelectionFields(); // 2
		this.isTodolistCreateForm = true;
	}

	/**
	 * Function to set repeat cycle of todolist
	 * 1. Set repeat cycle selected for todolist
	 * 2. Show one off date field if repeat is one off
	 * 3. Else hide date field
	 */
	public setTodoListRepeatCycle(): void {
		this.todolist.repeatCycle = this.TodoListRepeatCycle.toLowerCase(); // 1
		if (this.TodoListRepeatCycle === 'one-off') {
			this.displayDateField = true; // 2
		} else {
			this.displayDateField = false; // 3
			this.todoListDate = {
				year: new Date().getFullYear(),
				month: new Date().getMonth() + 1,
				day: new Date().getDate(),
			};
			delete this.todolist['oneOff'];
		}
	}

	/**
	 * Update cp value according to the detail from the privacy modal.
	 */
	public updateTodoListPrivacy(isEdit = false): void {
		if (this.privacy.custom_privacy && this.privacy.custom_privacy.length > 0) {
			delete this.todolist['content_privacy'];
			this.todolist['custom_privacy'] = [];
			const names = [];
			// -
			const newObject = this.privacy.custom_privacy;
			newObject.forEach((member) => {
				member.privacyType = 'customPrivacy';
			});
			localStorage.setItem(`${this.network.uid}`, JSON.stringify(newObject));
			this.privacy.custom_privacy.forEach((user) => {
				if (user['user']) {
					this.todolist['custom_privacy'].push(user['user'].userId);
					names.push(user['user'].name);
				} else if (user.userId) {
					this.todolist['custom_privacy'].push(user.userId);
					names.push(user.username);
				} else {
					this.todolist['custom_privacy'].push(user.id);
					names.push(user.username);
				}
			});
			if (isEdit) {
				this.privacy.showCustomPrivacy();
			}
			delete this.todolist['collaboration'];
		} else {
			let roleString = null;
			let departmentString = null;
			let personalFilterString = null;
			let selectByStatusString = null;
			let selectByRenewalString = null;
			let selectByLevelString = null;

			delete this.todolist['custom_privacy'];
			if (this.privacy.selectedRole && this.privacy.selectedDepartment) {
				roleString = '"role": "' + this.privacy.selectedRole.slug + '"';
				departmentString = '"department": "' + this.privacy.selectedDepartment.value + '"';
			} else if (this.privacy.selectedRole) {
				roleString = '"role": "' + this.privacy.selectedRole.slug + '"';
				// * ******************SPACE AFTER : ******************* */
				roleString = '"role": "' + this.privacy.selectedRole.slug + '"';
				// * ************************************** */
				const newObject = this.privacy.selectedRole;
				newObject.privacyType = 'role';
				localStorage.setItem(`${this.network.uid}`, JSON.stringify(newObject));
			} else if (this.privacy.selectedDepartment) {
				if (this.privacy.selectedDepartment.value) {
					departmentString = '"department": "' + this.privacy.selectedDepartment.value + '"';
				}
				if (this.privacy.selectedDepartment.uid && this.privacy.selectedDepartment.name) {
					departmentString = '"department": "' + this.privacy.selectedDepartment.uid + '"';
				}
				const newObject = this.privacy.selectedDepartment;
				newObject.privacyType = 'department';
				localStorage.setItem(`${this.network.uid}`, JSON.stringify(newObject));
			} else if (this.privacy.personalFilter) {
				// let personalFilterName = '';
				// personalFilterName = this.privacy.personalFilter.valueLabel;
				personalFilterString = '"' + this.privacy.personalFilter.key + '": "' + this.privacy.personalFilter.value + '"';
				// -
				const newObject = this.privacy.personalFilter;
				newObject.privacyType = 'personalFilter';
				localStorage.setItem(`${this.network.uid}`, JSON.stringify(newObject));
			} else if (this.privacy.selectByStatusValue) {
				if (this.privacy.selectByStatusValue.status) {
					selectByStatusString = '"status": "' + this.privacy.selectByStatusValue.status + '"';
				} else {
					selectByStatusString = '"status": "' + this.privacy.selectByStatusValue[0] + '"';
					// -
					const newObject = this.privacy.selectByStatusValue;
					if (newObject.includes('status') === false) {
						newObject.push('status');
					}
					localStorage.setItem(`${this.network.uid}`, JSON.stringify(newObject));
				}
			} else if (this.privacy.selectByRenewalValue) {
				if (this.privacy.selectByRenewalValue.renewalStatus) {
					selectByRenewalString = '"renewalStatus": "' + this.privacy.selectByRenewalValue.renewalStatus + '"';
				} else {
					selectByRenewalString = '"renewalStatus": "' + this.privacy.selectByRenewalValue[0] + '"';
					const newObject = this.privacy.selectByRenewalValue;
					if (newObject.includes('renewalFrequency') === false) {
						newObject.push('renewalFrequency');
					}
					localStorage.setItem(`${this.network.uid}`, JSON.stringify(newObject));
				}
			} else if (this.privacy.selectByLevelValue) {
				if (this.privacy.selectByLevelValue.uid) {
					selectByLevelString = '"membership_level": "' + this.privacy.selectByLevelValue.uid + '"';
					const newObject = this.privacy.selectByLevelValue;
					newObject.privacyType = 'selectByLevel';
					localStorage.setItem(`${this.network.uid}`, JSON.stringify(newObject));
				} else {
					selectByLevelString = '"membership_level": "' + this.privacy.selectByLevelValue.membership_level + '"';
				}
			} else if (this.privacy.channel) {
				this.todolist['channel'] = this.privacy['channel']['uid'];
				const newObject = this.privacy.channel;
				newObject['privacyType'] = 'subCircle';
				localStorage.setItem(`${this.network.uid}`, JSON.stringify(newObject));
			}
			if (
				this.privacy.custom_privacy.length === 0 &&
				!this.privacy.selectedRole &&
				!this.privacy.role &&
				!this.privacy.personalFilter &&
				!this.privacy.selectedDepartment &&
				!this.privacy.channel &&
				!this.model.channel &&
				!this.privacy.selectByStatusValue &&
				!this.privacy.selectByRenewalValue &&
				!this.privacy.selectByLevelValue
			) {
				localStorage.setItem(`${this.network.uid}`, JSON.stringify('Everyone'));
			}

			if (roleString && departmentString) {
				this.todolist['content_privacy'] = '{' + roleString + ', ' + departmentString + '}';
			} else if (roleString && !departmentString) {
				this.todolist['content_privacy'] = '{' + roleString + '}';
			} else if (!roleString && departmentString) {
				this.todolist['content_privacy'] = '{' + departmentString + '}';
			} else if (personalFilterString) {
				this.todolist['content_privacy'] = '{' + personalFilterString + '}';
			} else if (selectByStatusString) {
				this.todolist['content_privacy'] = '{' + selectByStatusString + '}';
			} else if (selectByRenewalString) {
				this.todolist['content_privacy'] = '{' + selectByRenewalString + '}';
			} else if (selectByLevelString) {
				this.todolist['content_privacy'] = '{' + selectByLevelString + '}';
			} else {
				delete this.todolist['content_privacy'];
			}
		}
		if (!this.model['channel']) {
			delete this.todolist['channel'];
		}
		if (this.todolist['custom_privacy'] && this.todolist['custom_privacy'].length > 0) {
			this.todolist['custom_privacy'] = '[' + this.todolist['custom_privacy'].join(',') + ']';
		}
	}

	/**
	 * Function on creating a todolist
	 * 1. Check if title is present for list
	 * 2. Display error if not
	 * 3. Update privacy values of todolist
	 * 4. Handle one off date string
	 * 5. Call api to create a todolist
	 * 6. Append new list created to todoLists array
	 * 7. Close todolist popup and reset all field values
	 */
	public onTodoListCreate(): void {
		if (!this.todolist['name']) {
			// 1
			this.todoListCreateError = this.translate.instant('Please enter a title'); // 2
		} else if (this.displayDateField && !this.todoListDate) {
			this.todoListCreateError = this.translate.instant('Please select a date');
		} else {
			this.pageLoadingUiService.setPageLoading(true);
			this.updateTodoListPrivacy(); // 3
			this.updateSelectionFields();
			if (this.todoListDate && this.displayDateField) {
				this.todolist.repeatCycle = 'one_off'; // 4
				this.todolist['oneOff'] = this.parserFormatter.format(this.todoListDate);
			}

			this.networkService.createToDoList(this.todolist, this.network.uid).subscribe((data) => {
				// 5
				this.todoLists.unshift(data); // 6
				this.showOneOffDate();
				this.updatePrivacyString();
				this.displayDateField = false; // 7
				this.todoListDate = {
					year: new Date().getFullYear(),
					month: new Date().getMonth() + 1,
					day: new Date().getDate(),
				};
				this.todolist.name = '';
				this.todolist.description = '';
				this.TodoListRepeatCycle = 'daily';
				delete this.todolist['oneOff'];
				this.isTodolistCreateForm = false;
				this.displayCreateTodoList = 'none';
				this.pageLoadingUiService.setPageLoading(false);
			});
		}
	}

	public displayCustomersForIssue(): void {
		if (!this.lockCustomer) {
			this.displayCustomers = 'block';
			this.customers = [];
			this.query = '';
			this.networkService.getCustomers().subscribe((data) => {
				if (data['objects'] && data['objects'].length > 0) {
					this.customers = data['objects'];
				}
				if (data.next) {
					this.nextCustomerUrl = data.next.split('alpha')[1];
					this.paginateCustomers();
				} else {
					this.nextCustomerUrl = '';
				}
			});
		}
	}

	public paginateCustomers(): void {
		if (this.nextCustomerUrl) {
			this.postService.paginate(this.nextCustomerUrl).subscribe((data) => {
				if (data) {
					this.customers = this.customers.concat(data['objects']);
					if (data.next) {
						this.nextCustomerUrl = data.next.split('alpha')[1];
					} else {
						this.nextCustomerUrl = '';
					}
				}
			});
		}
	}

	public setCustomerForIssue(): void {
		if (this.selectedCustomer) {
			this.currentIssueCustomer = this.selectedCustomer['title'];
		}
	}

	public setIssuePriority(): void {
		if (this.currentSelectedPriority) {
			this.selectedPriority = this.currentSelectedPriority;
		}
	}

	public displayCountryModal(): void {
		this.showCountryModal = 'block';
		this.field['value'] = '';
		this.isLoading = true;
		this.countries = [];
		this.searchString = '';
		this.displayCountryError = false;
		this.userService.fetchCountries().subscribe((data) => {
			this.countries = data['results'];
			if (data.next) {
				this.nextCUrl = data.next.split('alpha')[1];
			} else {
				this.nextCUrl = '';
			}
			this.isLoading = false;
		});
	}

	public paginateCountries(): void {
		if (this.nextCUrl) {
			this.userService.paginate(this.nextCUrl).subscribe((data) => {
				if (data.next) {
					this.nextCUrl = data.next.split('alpha')[1];
				} else {
					this.nextCUrl = '';
				}
				this.countries = this.countries.concat(data.results);
			});
		}
	}

	public onSearchClick(): void {
		this.isLoading = true;
		this.displayCountryError = false;
		this.countries = [];
		this.userService.searchCountries(this.searchString).subscribe((data) => {
			if (data.results.length > 0) {
				this.isLoading = false;
				this.countries = data.results;
			} else {
				this.displayCountryError = true;
				this.isLoading = false;
			}
		});
	}

	public onSearchEnter(event: any): void {
		if (event.keyCode === 13) {
			this.onSearchClick();
		}
	}

	public onSelectCountry(country): void {
		this.selectedCountry = country;
		this.field['value'] = country['uid'];
	}

	public setCustomerSales(): void {
		if (this.salesSelected['slug']) {
			if (this.salesSelected['slug'] !== 'none') {
				this.customer['salesChannel'] = this.salesSelected['slug'];
				this.currentSaleChannel = this.salesSelected['option'];
			} else {
				delete this.customer['salesChannel'];
				this.currentSaleChannel = '';
			}
		} else {
			this.currentSaleChannel = '';
		}
	}

	public setCustomerDealType(): void {
		if (this.dealSelected['slug']) {
			if (this.dealSelected['slug'] !== 'none') {
				this.customer['dealType'] = this.dealSelected['slug'];
				this.currentDealType = this.dealSelected['option'];
			} else {
				delete this.customer['dealType'];
				this.currentDealType = '';
			}
		} else {
			this.currentDealType = '';
		}
	}

	public setCustomerType(): void {
		if (this.typeSelected['slug']) {
			if (this.typeSelected['slug'] !== 'none') {
				this.customer['customerType'] = this.typeSelected['slug'];
				this.currentCustomerType = this.typeSelected['option'];
			} else {
				delete this.customer['customerType'];
				this.currentCustomerType = '';
			}
		} else {
			this.currentCustomerType = '';
		}
	}

	public setCustomerCurrency(): void {
		this.currentCurrencyType = this.currencySelected;
		this.customer['currency'] = this.currencySelected;
		this.customerPrice = '';
	}

	public setCountry(): void {
		this.additionalContactForm.controls.code.setValue(this.selectedCountry['countryCode']);
		this.additionalContactForm.controls.country.setValue(this.selectedCountry['name']);
		this.customer['countryCode'] = this.selectedCountry['code'];
	}

	public setCityForCustomer(): void {
		if (this.postType === 'edit-customer' && this.selectedCity['name']) {
			this.additionalContactForm.controls.city.setValue(this.selectedCity['name']);
			this.customer['city'] = this.selectedCity['name'];
			this.customer['cityUid'] = this.selectedCity['uid'];
		}
	}

	public getExternalChannel(): void {
		if (this.post['channel'] && this.post['channel']['name']) {
			this.privacy.channel = this.post.channel;
			return this.post['channel']['name'];
		}
	}

	/**
	 * For resetting privacy values once projectForm is closed
	 */
	public resetPrivacyComponent(): void {
		this.privacy.custom_privacy = [];
		this.privacy.selectedRole = '';
		this.privacy.selectedDepartment = '';
		this.privacy.personalFilter = '';
		this.privacy.selectedChannel = '';
		this.privacy.selectByStatusValue = '';
		this.privacy.selectByRenewalValue = '';
		this.privacy.selectByLevelValue = '';
	}

	public paginateMentions(): void {
		if (this.nextMentionUrl) {
			this.postService.paginate(this.nextMentionUrl).subscribe((data) => {
				if (data) {
					if (this.mentionType === 'user') {
						this.userMentions = this.userMentions.concat(data['objects']);
					} else if (this.mentionType === 'hash') {
						this.hashMentions = this.hashMentions.concat(data['objects']);
					}
					if (data.next) {
						this.nextMentionUrl = data.next.split('alpha')[1];
					} else {
						this.nextMentionUrl = '';
					}
				}
			});
		}
	}

	public checkShiftKey(event, isSubtask = false): void | boolean {
		if (isSubtask && (event?.key === 'Enter' || event?.keyCode === 13)) {
			event.preventDefault();
			return false;
		}
		// -Check if shift key is pressed for user mention / hashtags
		if (event.shiftKey) {
			this.shiftKeyUsed = true;
		}
	}

	public manageTextBox(e): void {
		e.target.style.height = e.target.scrollHeight + 'px';
		this.errorMessage = '';
	}

	public onUserSelect(user): void {
		this.isMentions = false;
		this.userMentions = [];
		this.hashMentions = [];
		if (this.titleFocus) {
			const startPos = this.modelTitle.nativeElement.selectionStart;
			const endPos = this.modelTitle.nativeElement.selectionEnd;
			if (startPos === endPos) {
				const text = this.modelTitle.nativeElement.value.substring(0, startPos);
				this.mentionIndex = text.lastIndexOf('@');

				if (this.mentionIndex > -1 && this.shiftKeyUsed) {
					const strippedText = this.modelTitle.nativeElement.value.substring(this.mentionIndex, startPos);

					if (strippedText !== '@') {
						const reg = new RegExp(strippedText, 'g');
						const replacedTitle = this.model.title.replace(reg, '@' + user.name + ' ');
						this.model.title = replacedTitle;
						// this.shiftKeyUsed = false;
					} else {
						// this.shiftKeyUsed = false;
						const firstPart = this.model.title.substr(0, startPos);
						const lastPart = this.model.title.substr(startPos + 1);
						const newString = firstPart + user.name + ' ' + lastPart;
						this.model.title = newString;
					}
				} else {
					const newTitle = <''>(
						`${this.modelTitle.nativeElement.value.substring(0, startPos)}@${user.name + ' '}${this.modelTitle.nativeElement.value.substring(endPos)}`.toString()
					);
					this.model.title = newTitle;
					this.modelTitle.nativeElement.focus();
				}
			}
			// }
		} else {
			const startPos = this.modelBody.nativeElement.selectionStart;
			const endPos = this.modelBody.nativeElement.selectionEnd;

			if (startPos === endPos) {
				const body = this.modelBody.nativeElement.value.substring(0, startPos);
				this.mentionIndex = body.lastIndexOf('@');

				if (this.mentionIndex > -1 && this.shiftKeyUsed) {
					const strippedText = this.modelBody.nativeElement.value.substring(this.mentionIndex, startPos);
					if (strippedText !== '@') {
						const reg = new RegExp(strippedText, 'g');
						const replacedBody = this.model.body.replace(reg, '@' + user.name + ' ');
						this.model.body = <''>replacedBody;
						// this.shiftKeyUsed = false;
					} else {
						// this.shiftKeyUsed = false;
						const firstPart = this.model.body.substr(0, startPos);
						const lastPart = this.model.body.substr(startPos + 1);
						const newString = firstPart + user.name + ' ' + lastPart;
						this.model.body = <''>newString;
					}
				} else {
					const newTitle = <''>(
						`${this.modelBody.nativeElement.value.substring(0, startPos)}@${user.name + ' '}${this.modelBody.nativeElement.value.substring(endPos)}`.toString()
					);
					this.model.body = newTitle;
					this.modelBody.nativeElement.focus();
				}
			}
			// }
		}
		if (this.model['mentioned_users']) {
			this.model['mentioned_users'].push(user);
		} else {
			this.model['mentioned_users'] = [];
			this.model['mentioned_users'].push(user);
		}
	}

	/**
	 *
	 * Function which executes on selecting a hashtag in create post modal
	 * @param tag
	 */
	public onHashTagSelect(tag): void {
		this.isMentions = false;
		this.userMentions = [];
		this.hashMentions = [];
		if (this.titleFocus) {
			const startPos = this.modelTitle.nativeElement.selectionStart;
			const endPos = this.modelTitle.nativeElement.selectionEnd;
			if (startPos === endPos) {
				const text = this.modelTitle.nativeElement.value.substring(0, startPos);
				this.mentionIndex = text.lastIndexOf('#');

				if (this.mentionIndex > -1 && this.shiftKeyUsed) {
					const strippedText = this.modelTitle.nativeElement.value.substring(this.mentionIndex, startPos);
					if (strippedText !== '#') {
						const reg = new RegExp(strippedText, 'g');
						const replacedTitle = this.model.title.replace(reg, '#' + tag['name'] + ' ');
						this.model.title = replacedTitle;
						// this.shiftKeyUsed = false;
					} else {
						// this.shiftKeyUsed = false;
						const firstPart = this.model.title.substr(0, startPos);
						const lastPart = this.model.title.substr(startPos + 1);
						const newString = firstPart + tag['name'] + ' ' + lastPart;
						this.model.title = newString;
					}
				} else {
					const newTitle = <''>(
						`${this.modelTitle.nativeElement.value.substring(0, startPos)}#${tag['name'] + ' '}${this.modelTitle.nativeElement.value.substring(endPos)}`.toString()
					);
					this.model.title = newTitle;
					this.modelTitle.nativeElement.focus();
				}
			}
		} else {
			const startPos = this.modelBody.nativeElement.selectionStart;
			const endPos = this.modelBody.nativeElement.selectionEnd;

			if (startPos === endPos) {
				const body = this.modelBody.nativeElement.value.substring(0, startPos);
				this.mentionIndex = body.lastIndexOf('#');

				if (this.mentionIndex > -1 && this.shiftKeyUsed) {
					const strippedText = this.modelBody.nativeElement.value.substring(this.mentionIndex, startPos);
					if (strippedText !== '#') {
						const reg = new RegExp(strippedText, 'g');
						const replacedBody = this.model.body.replace(reg, '#' + tag['name'] + ' ');
						this.model.body = <''>replacedBody;
						// this.shiftKeyUsed = false;
					} else {
						// this.shiftKeyUsed = false;
						const firstPart = this.model.body.substr(0, startPos);
						const lastPart = this.model.body.substr(startPos + 1);
						const newString = firstPart + tag['name'] + ' ' + lastPart;
						this.model.body = <''>newString;
					}
				} else {
					const newTitle = <''>(
						`${this.modelBody.nativeElement.value.substring(0, startPos)}#${tag['name'] + ' '}${this.modelBody.nativeElement.value.substring(endPos)}`.toString()
					);
					this.model.body = newTitle;
					this.modelBody.nativeElement.focus();
				}
			}
		}
		if (this.model['hashtags'] && this.model['hashtags'].length > 0) {
			this.model['hashtags'].push(tag);
		} else {
			this.model['hashtags'] = [];
			this.model['hashtags'].push(tag);
		}
	}

	public checkPrivacyForMentions(): void {
		delete this.model.privacy;
		delete this.personalFilterString;
		delete this.selectByStatusString;
		delete this.selectByRenewalString;
		delete this.selectByLevelString;

		if (this.privacy.custom_privacy.length !== 0) {
			const customPrivacy: any[] = [];
			// let custom: any;
			this.privacy.custom_privacy.forEach((element) => {
				if (element.userId) {
					customPrivacy.push(element.userId);
					this.privacy.custom_privacy_display = false;
				} else if (element.user) {
					customPrivacy.push(element.user.userId);
				} else {
					customPrivacy.push(element.id);
				}
			});
			const custom = '[' + customPrivacy.join(',') + ']';
			this.mentionPrivacy = 'custom_privacy=' + custom;
		} else {
			if (this.privacy.selectedRole) {
				if (this.privacy.selectedRole.slug) {
					this.roleString = { role: this.privacy.selectedRole.slug };
				}
				if (this.privacy.selectedRole.role) {
					const role = this.privacy.selectedRole.role.toLowerCase();
					this.roleString = { role: role };
				}
			}
			if (this.privacy.personalFilter) {
				this.personalFilterString = {};
				const key = this.privacy.personalFilter.key;
				this.personalFilterString[key] = this.privacy.personalFilter.value;
			}
			if (this.privacy.selectByStatusValue) {
				this.selectByStatusString = {
					status: this.privacy.selectByStatusValue[0],
				};
			}
			if (this.privacy.selectByRenewalValue) {
				this.selectByRenewalString = {
					renewalStatus: this.privacy.selectByRenewalValue[0],
				};
			}
			if (this.privacy.selectByLevelValue) {
				this.selectByLevelString = {
					membership_level: this.privacy.selectByLevelValue.uid,
				};
			}
			if (this.privacy.selectedDepartment) {
				if (this.privacy.selectedDepartment.value) {
					this.departmentString = {
						department: this.privacy.selectedDepartment['value'],
					};
				}
				if (this.privacy.selectedDepartment.uid) {
					this.departmentString = {
						department: this.privacy.selectedDepartment['uid'],
					};
				}
			}

			if (!this.roleString && this.departmentString) {
				this.model.content_privacy = JSON.stringify(this.departmentString);
			} else if (this.roleString && !this.departmentString) {
				this.model.content_privacy = JSON.stringify(this.roleString);
			} else if (this.personalFilterString) {
				this.model.content_privacy = JSON.stringify(this.personalFilterString);
			} else if (this.selectByStatusString) {
				this.model.content_privacy = JSON.stringify(this.selectByStatusString);
			} else if (this.selectByRenewalString) {
				this.model.content_privacy = JSON.stringify(this.selectByRenewalString);
			} else if (this.selectByLevelString) {
				this.model.content_privacy = JSON.stringify(this.selectByLevelString);
			} else {
				delete this.model.content_privacy;
			}
			if (this.model.content_privacy) {
				this.mentionPrivacy = 'content_privacy=' + this.model.content_privacy;
			}
			if (this.model.channel) {
				this.mentionPrivacy = 'channel=' + this.model.channel;
			}
			this.roleString = null;
			this.departmentString = null;
			delete this.model.content_privacy;
			delete this.model.custom_privacy;
		}

		if (
			this.privacy.custom_privacy.length === 0 &&
			!this.privacy.selectedRole &&
			!this.privacy.personalFilter &&
			!this.privacy.selectedDepartment &&
			!this.privacy.channel &&
			!this.model.channel &&
			!this.privacy.selectByStatusValue &&
			!this.privacy.selectByRenewalValue &&
			!this.privacy.selectByLevelValue
		) {
			this.mentionPrivacy = '';
		}
	}

	/// ==============================================================
	public onkeyDown(event: KeyboardEvent, isSubtask = false): void | boolean {
		if (isSubtask && (event?.key === 'Enter' || event?.keyCode === 13)) {
			event.preventDefault();
			return false;
		}
		if (this.userMentions.length > 0 || this.hashMentions.length > 0) {
			switch (event.keyCode) {
				case 38:
					event.preventDefault();
					this.arrowKeyLocation--;
					if (this.arrowKeyLocation < 0) {
						this.panel.nativeElement.scrollTop = 0;
						this.arrowKeyLocation = 0;
					}

					if (this.userMentions.length > 0) {
						this.userMentions.forEach((val, index) => {
							if (index === this.arrowKeyLocation) {
								this.addHightLightClass(val);
								this.scrollIntoView(val);
							} else {
								this.removeHightLightClass(val);
							}
						});
					} else {
						this.hashMentions.forEach((val, index) => {
							if (index === this.arrowKeyLocation) {
								this.addHightLightClass(val);
								this.scrollIntoView(val);
							} else {
								this.removeHightLightClass(val);
							}
						});
					}
					break;

				case 40:
					event.preventDefault();
					this.arrowKeyLocation++;
					if (this.arrowKeyLocation > this.mentionCount - 1) {
						this.panel.nativeElement.scrollTop = 0;
						this.arrowKeyLocation = 0;
					}

					if (this.userMentions.length > 0) {
						this.userMentions.forEach((val, index) => {
							if (index === this.arrowKeyLocation) {
								this.addHightLightClass(val);
								this.scrollIntoView(val);
							} else {
								this.removeHightLightClass(val);
							}
						});
					} else {
						this.hashMentions.forEach((val, index) => {
							if (index === this.arrowKeyLocation) {
								this.addHightLightClass(val);
								this.scrollIntoView(val);
							} else {
								this.removeHightLightClass(val);
							}
						});
					}
					break;

				case 13:
					event.preventDefault();
					if (this.mentionType === 'user') {
						this.userMentions.forEach((element, index) => {
							if (index === this.arrowKeyLocation) {
								if (this.isSubtask) {
									this.onUserSelectForSubtask(element);
								} else {
									this.onUserSelect(element);
								}
							}
						});
					} else if (this.mentionType === 'hash') {
						this.hashMentions.forEach((element, index) => {
							if (index === this.arrowKeyLocation) {
								if (this.isSubtask) {
									this.onHashTagForSubtask(element);
								} else {
									this.onHashTagSelect(element);
								}
							}
						});
					}
			}
		}
	}

	// onkeyDown(event: KeyboardEvent) {

	//   if (this.userMentions.length > 0 || this.hashMentions.length > 0) {
	//     switch (event.keyCode) {
	//       case 38:
	//         event.preventDefault();
	//         this.arrowKeyLocation--;
	//         if (this.arrowKeyLocation < 0) {
	//           this.panel.nativeElement.scrollTop = 0;
	//           this.arrowKeyLocation = 0;
	//         }

	//         if (this.userMentions.length > 0) {
	//           this.userMentions.forEach((val, index) => {
	//             if (index === this.arrowKeyLocation) {
	//               this.addHightLightClass(val);
	//               this.scrollIntoView(val);
	//             } else {
	//               this.removeHightLightClass(val);
	//             }
	//           });
	//         } else {
	//           this.hashMentions.forEach((val, index) => {
	//             if (index === this.arrowKeyLocation) {

	//               this.addHightLightClass(val);
	//               this.scrollIntoView(val);
	//             } else {
	//               this.removeHightLightClass(val);
	//             }
	//           });
	//         }
	//         break;

	//       case 40:
	//         event.preventDefault();
	//         this.arrowKeyLocation++;
	//         if (this.arrowKeyLocation > this.mentionCount - 1) {
	//           this.panel.nativeElement.scrollTop = 0;
	//           this.arrowKeyLocation = 0;
	//         }

	//         if (this.userMentions.length > 0) {
	//           this.userMentions.forEach((val, index) => {
	//             if (index === this.arrowKeyLocation) {

	//               this.addHightLightClass(val);
	//               this.scrollIntoView(val);
	//             } else {
	//               this.removeHightLightClass(val);
	//             }
	//           });
	//         } else {
	//           this.hashMentions.forEach((val, index) => {
	//             if (index === this.arrowKeyLocation) {
	//               this.addHightLightClass(val);
	//               this.scrollIntoView(val);
	//             } else {
	//               this.removeHightLightClass(val);
	//             }
	//           });
	//         }
	//         break;

	//       case 13:
	//         event.preventDefault();
	//         if (this.mentionType === 'user') {
	//           this.userMentions.forEach((element, index) => {
	//             if (index === this.arrowKeyLocation) {
	//               this.onUserSelect(element);
	//             }
	//           });
	//         } else if (this.mentionType === 'hash') {
	//           this.hashMentions.forEach((element, index) => {
	//             if (index === this.arrowKeyLocation) {
	//               this.onHashTagSelect(element);
	//             }
	//           });
	//         }
	//     }
	//   }
	// }

	public getMentionsInTitle(e): void {
		this.isTitle = false;
		this.userRegexFailed = false;
		this.hashRegexFailed = false;
		const userMentionRegex = /(^|\s)@(\w*(?:\s*\w*))$/;
		const hashTagRegex = /(^|\s)#(\w*(?:\s*\w*))$/;
		let text;
		const caretPosition = this.getCaretPositionForSubject(this.model.title);
		const word = this.returnWord(this.model.title, caretPosition);

		if (word !== null) {
			if (userMentionRegex.test(word)) {
				// 3
				this.mentionType = 'user';
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					// this.mentionsFetched = 1;
					text = word.replace(/(^@)/g, '');
					if (this.mentionType === 'user') {
						if (text.length >= 3) {
							this.mentionsFetched = 1;
							this.subject.next(text); // 4
						} else {
							return;
						}
					}
				}
			} else {
				this.userRegexFailed = true;
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					// 5

					this.hashMentions = [];
					this.userMentions = [];
				}
			}
			if (word !== null && hashTagRegex.test(word)) {
				// 3
				this.mentionType = 'hash';
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					this.mentionsFetched = 1;
					text = word.replace(/(^#)/g, '');

					this.subject.next(text); // 4
				}
			} else {
				this.hashRegexFailed = true;

				if (e.keyCode !== 38 && e.keyCode !== 40) {
					// 5
					this.hashMentions = [];
					this.userMentions = [];
				}
			}
		} else {
			// 6

			if (!this.isMentions) {
				this.mentionsFetched = 0;
				this.userMentions = [];
				this.hashMentions = [];
			}
		}
	}

	public getMentionsInBody(e): void {
		this.userRegexFailed = false;
		this.hashRegexFailed = false;
		e.target.style.height = e.target.scrollHeight + 'px';
		const userMentionRegex = /(^|\s)@(\w*(?:\s*\w*))$/;
		const hashTagRegex = /(^|\s)#(\w*(?:\s*\w*))$/;
		let text;
		const caretPosition = this.getCaretPosition(this.model.body);
		const word = this.returnWord(this.model.body, caretPosition);

		if (word !== null) {
			if (userMentionRegex.test(word)) {
				// 3
				this.mentionType = 'user';
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					// this.mentionsFetched = 1;
					text = word.replace(/(^@)/g, '');

					if (this.mentionType === 'user') {
						if (text.length >= 3) {
							this.mentionsFetched = 1;
							this.subject.next(text); // 4
						} else {
							return;
						}
					}
				}
			} else {
				this.userRegexFailed = true;
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					// 5

					this.hashMentions = [];
					this.userMentions = [];
				}
			}
			if (word !== null && hashTagRegex.test(word)) {
				// 3
				this.mentionType = 'hash';
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					this.mentionsFetched = 1;
					text = word.replace(/(^#)/g, '');
					this.subject.next(text); // 4
				}
			} else {
				this.hashRegexFailed = true;

				if (e.keyCode !== 38 && e.keyCode !== 40) {
					// 5
					this.hashMentions = [];
					this.userMentions = [];
				}
			}
		} else {
			// 6

			if (!this.isMentions) {
				this.mentionsFetched = 0;
				this.userMentions = [];
				this.hashMentions = [];
			}
		}
	}

	/** For adding highlight class for mention elements
	 * 1. Clear all previous highlight class on elements.
	 * 2. Add mention highlight css class.
	 * @param val
	 */
	public addHightLightClass(val): void {
		this.clearHighLightClass(); // 1
		const element = document.getElementById(val.uid);
		element.classList.add(CSS_CLASS_NAMES.highLight); // 2
	}

	/** For removing highlight class for mention elements
	 * @param val
	 */
	public removeHightLightClass(val): void {
		const element = document.getElementById(val.uid);
		element.classList.remove(CSS_CLASS_NAMES.highLight);
	}

	/**
	 * For clearing highlight class from all mention elements
	 */
	public clearHighLightClass(): void {
		this.userMentions.forEach((val) => {
			if (val.uid) {
				const element = document.getElementById(val.uid);
				element.classList.remove(CSS_CLASS_NAMES.highLight);
			}
		});
		this.hashMentions.forEach((val) => {
			if (val.uid) {
				const element = document.getElementById(val.uid);
				element.classList.remove(CSS_CLASS_NAMES.highLight);
			}
		});
	}

	/**
	 * Scroll to current mention element
	 * 1. Get current elemennt by its uid.
	 * 2. Get offset top of current element
	 * 3. Set topPos as scrolltop hence scroll to this position
	 * @param val
	 */
	public scrollIntoView(val): void {
		const elem = document.getElementById(val.uid);
		const topPos = elem.offsetTop;
		this.panel.nativeElement.scrollTop = topPos;
	}

	/**
	 * On mouse hover,add or remove hightlight class
	 * 1. Clear previous remanats of hightlight class
	 * @param event
	 */
	public onHover(event: MouseEvent): void {
		this.clearHighLightClass(); // 1
		const target = event.target as HTMLElement;
		if (event.type === 'mouseover') {
			target.classList.add(CSS_CLASS_NAMES.highLight);
		} else {
			target.classList.remove(CSS_CLASS_NAMES.highLight);
		}
	}

	/**
	 * Function for displaying @/# mentions.
	 * @param type
	 * 1. Set focus on title/description field on mentions select
	 * 2. Display mentions list based on type
	 */
	public displayMentions(type): void {
		// =======[08-06-21,Mention Fixes]
		this.userRegexFailed = false;
		this.hashRegexFailed = false;
		this.isMentions = true;
		// =======
		this.arrowKeyLocation = -1;
		this.shiftKeyUsed = false;

		if (this.titleFocus) {
			// 1
			this.modelTitle.nativeElement.focus();
		} else {
			this.modelBody.nativeElement.focus();
		}
		if (type === 'user') {
			// 2
			this.mentionType = 'user';
			this.subject.next('');
		} else {
			this.mentionType = 'hash';
			this.subject.next('');
		}
	}

	/**
	 * On selecting reminder options in task create form
	 * @param reminder
	 */
	public onSelectReminder(reminder): void {
		const index = this.remindersSelected.findIndex((r) => r === reminder);
		if (index > -1) {
			this.remindersSelected.splice(index, 1);
		} else {
			this.remindersSelected.push(reminder);
		}
	}

	public removeCurrentAttachments(): void {
		this.tempImages = [];
		this.model.preview_images = [];
		this.model.images = [];
		this.selectedMember['uid'] = '';
		this.attachmentMember = '';
		this.model.location = '';
		this.removeUploads = true;
		delete this.model['member'];
		delete this.model['doc'];
		delete this.model['url'];
	}

	/**
	 * For creating a new reminders for content
	 */
	public setReminder(todo): void {
		if (this.remindersSelected.length > 0) {
			this.taskForReminder = todo;
			const object = {};
			this.remindersSelected.forEach((reminder) => {
				if (reminder === 'due_time') {
					object['event_time'] = this.setReminderDate(reminder);
				} else {
					object[reminder] = this.setReminderDate(reminder);
				}
			});
			const param = JSON.stringify(object);
			this.reminderModel.contentType = 'todo';
			this.reminderModel.contentUid = this.taskForReminder.uid;
			this.reminderModel.username = this.currentUser.username;
			this.reminderModel.repeat = true;

			if (this.taskForReminder['estimatedTime']) {
				delete this.reminderModel['reminderOption'];
				this.reminderModel['eventReminderOption'] = param;
			} else {
				this.reminderModel.reminderOption = param;
			}

			this.pageLoadingUiService.setPageLoading(true);
			this.networkService.createReminder(this.reminderModel).subscribe(
				(data) => {
					this.pageLoadingUiService.setPageLoading(false);
					this.reminderEmitter.emit(data.uid);
					this.resetReminders();
				},
				() => {
					this.pageLoadingUiService.setPageLoading(false);
				}
			);
		} else {
			if (this.reminderId) {
				this.deleteReminders(todo);
			} else {
				this.resetReminders();
			}
		}
	}

	/**
	 * For creating a new reminders for content
	 */
	public setReminderForTaskAndSubTask(todo, reminders): void {
		if (reminders.length > 0) {
			this.taskForReminder = todo;
			const object = {};
			reminders.forEach((reminder) => {
				if (reminder === 'due_time') {
					object['event_time'] = this.setReminderDate(reminder);
				} else {
					object[reminder] = this.setReminderDate(reminder);
				}
			});
			const param = JSON.stringify(object);
			if (this.taskForReminder['estimatedTime']) {
				delete this.reminderModel['reminderOption'];
				this.reminderModel['eventReminderOption'] = param;
			} else {
				this.reminderModel.reminderOption = param;
			}

			this.reminderModel.contentType = 'todo';
			this.reminderModel.contentUid = this.taskForReminder.uid;
			this.reminderModel.username = this.currentUser.username;
			this.reminderModel.repeat = true;

			this.pageLoadingUiService.setPageLoading(true);
			this.networkService.createReminder(this.reminderModel).subscribe(
				(data) => {
					this.pageLoadingUiService.setPageLoading(false);
					this.reminderEmitter.emit(data.uid);
					this.resetReminders();
				},
				() => {
					this.pageLoadingUiService.setPageLoading(false);
				}
			);
		} else {
			this.deleteReminders(todo);
			if (this.reminderId) {
			} else {
				this.resetReminders();
			}
		}
	}

	public deleteReminders(todo): void {
		this.reminderModel.contentUid = todo.uid;
		this.reminderModel.username = this.currentUser.username;
		this.reminderModel.repeat = true;
		this.reminderModel.contentType = 'todo';
		this.pageLoadingUiService.setPageLoading(true);
		this.networkService.createReminder(this.reminderModel).subscribe(() => {
			this.pageLoadingUiService.setPageLoading(false);
			this.resetReminders();
		});
	}

	/**
	 * For calculating corresponding to reminder selected
	 * @param option
	 */
	public setReminderDate(option): string {
		if (option === 'in_twenty_minutes') {
			this.timeFor = 20;
		} else if (option === 'in_one_hour') {
			this.timeFor = 60;
		} else if (option === 'in_three_hours') {
			this.timeFor = 180;
		} else if (option === 'tomorrow') {
			this.timeFor = this.minutesUntilMidnight() + 540;
		} else if (option === 'next_week') {
			this.timeFor = 6300;
		} else if (option === 'due_time' || option === 'event_time') {
			this.timeFor = -1;
		} else if (option === 'five_minutes_before') {
			this.timeFor = 5;
		} else if (option === 'thirty_minutes_before') {
			this.timeFor = 30;
		} else if (option === 'one_hour_before') {
			this.timeFor = 60;
		} else if (option === 'two_hour_before') {
			this.timeFor = 120;
		}

		let date;
		if (this.taskForReminder['estimatedTime']) {
			if (this.timeFor === -1) {
				date = new Date(this.taskForReminder['estimatedTime']);
			} else {
				date = new Date(this.taskForReminder['estimatedTime']);
				date.setMinutes(date.getMinutes() - this.timeFor);
			}
		} else if (this.taskForReminder['startTime']) {
			if (this.timeFor === -1) {
				date = new Date(this.taskForReminder['startTime']);
			} else {
				date = new Date(this.taskForReminder['startTime']);
				date.setMinutes(date.getMinutes() - this.timeFor);
			}
		} else {
			date = new Date();
			date.setMinutes(date.getMinutes() + this.timeFor);
		}

		const month = date.getUTCMonth() + 1;
		const day = date.getUTCDate();
		const year = date.getUTCFullYear();
		const hours = date.getUTCHours();
		const minutes = date.getUTCMinutes();
		const seconds = date.getUTCSeconds();
		const utcDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
		return utcDate;
	}

	/**
	 * For resetting reminders in reminder options modal
	 */
	public resetReminders(): void {
		this.timeFor = 0;
		this.reminderId = '';
		this.taskForReminder = '';
		this.previousReminders = [];
		this.remindersSelected = [];
		this.displayTaskReminders = 'none';
		delete this.reminderModel['eventReminderOption'];
		this.reminderModel = {
			contentType: '',
			contentUid: '',
			repeat: true,
			reminderOption: '',
			username: '',
		};
		this.taskReminders.forEach((r) => {
			r['checked'] = false;
		});
	}

	public onDone(): void {
		this.displayTaskReminders = 'none';
		this.onConfirm = true;
	}

	public setTaskDueTime(): void {
		if (this.taskDueTime) {
			this.taskReminders = this.eventReminders;
			this.resetReminders();
			if (this.taskDueTime['hour']) {
				if (this.taskDueTime['minute'] < 10) {
					const minutes = '0' + this.taskDueTime['minute'];
					this.currentDueTime = this.taskDueTime['hour'] + ':' + minutes;
				} else {
					this.currentDueTime = this.taskDueTime['hour'] + ':' + this.taskDueTime['minute'];
				}
			} else {
				this.currentDueTime = '';
				this.taskReminders = this.postReminders;
				this.resetReminders();
			}
		} else {
			this.currentDueTime = '';
			this.taskReminders = this.postReminders;
			this.resetReminders();
		}
	}

	// Subtasks
	public onAddSubtask(): void {
		if (this.subtasksIndexContainer.length === 0) {
			const x = new Subtask();
			x['assignedUser'] = this.currentUser;
			this.subtasks.push(x);
			this.subtasksIndexContainer.push(this.subtasks.length - 1);
			this.subTasksToBeAdded.push(x);
			timer(50).subscribe(() => {
				document.getElementById('subtask-' + (this.subtasks.length - 1)?.toString())?.focus();
			});
		} else if (this.subtasksIndexContainer.length > 0) {
			const index = this.subtasks.length - 1;
			if (this.subtasks[index]['title']) {
				const x = new Subtask();
				x['assignedUser'] = this.currentUser;
				this.subtasks.push(x);
				this.subtasksIndexContainer.push(this.subtasks.length - 1);
				this.subTasksToBeAdded.push(x);
				timer(50).subscribe(() => {
					document.getElementById('subtask-' + (this.subtasks.length - 1)?.toString())?.focus();
				});
			} else {
				return;
			}
		}

		// this.dueDateComponent.reset();
	}

	// eslint-disable-next-line spellcheck/spell-checker
	public displaySubtaskAssignees(task): void {
		if (this.listPrivacy && this.listPrivacy['uid'] !== localStorage.getItem('MyTodolist')) {
			this.currentSub = task;
			this.subAssignee = '';
			this.memberUid = '';
			this.displaySubAssigneeModal = 'block';
			if (task['assignedUser']) {
				this.memberUid = task['assignedUser']['username'];
			}
		}
	}

	public onSetAssigneeForSubtask(): void {
		this.displaySubAssigneeModal = 'none';
		if (this.subAssignee) {
			this.currentSub['assignedUser'] = this.subAssignee['user'];
		}
	}

	public setUnassignedForSubtask(): any {
		this.displaySubAssigneeModal = 'none';
		delete this.currentSub['assignedUser'];
	}

	public onRemoveSubtask(key): void {
		this.subTasksToBeDeleted.push(this.subtasks[key]?.uid);
		this.subtasks.splice(key, 1);
		this.subtasksIndexContainer.pop();
	}

	/**
	 * For setting due date for subtask
	 */
	public onSetDueDateForSubtask(): void {
		this.displaySubDueDateOptions = 'none';
		if (this.sub === 'tomorrow') {
			this.currentSub['repeatCycle'] = 'one_off';
			this.currentSub['oneOff'] = this.setTomorrowDate();
		} else if (this.sub === 'one_off') {
			this.currentSub['repeatCycle'] = 'one_off';
			this.currentSub['oneOff'] = this.parserFormatter.format(this.subtaskDate);
		} else {
			this.currentSub['repeatCycle'] = this.sub;
		}
	}

	/**
	 * Set tomorrow date on subtask
	 */
	public setTomorrowDate(): any {
		const today = new Date();
		const tomorrow = new Date(today);
		tomorrow.setDate(today.getDate() + 1);
		let dd: any;
		let mm: any;
		dd = tomorrow.getDate();
		mm = tomorrow.getMonth() + 1;
		const yyyy = tomorrow.getFullYear();
		if (dd < 10) {
			dd = '0' + dd;
		}
		if (mm < 10) {
			mm = '0' + mm;
		}
		return yyyy + '-' + mm + '-' + dd;
	}

	public onCreateSubtasks(data): void {
		const uid = data['folderDetails']['uid'];
		const arr = [];
		this.subTasksToBeAdded.forEach((task) => {
			if (task['title']) {
				if (data['project']) {
					task['project'] = data['project']['uid'];
				}
				this.checkForSubtaskMentions(task);
				arr.push(task);
			}
		});

		arr.forEach((task, index) => {
			task['parent_content_uid'] = data['uid'];
			task['parent_content_type'] = 'todo';

			if (task['assignedUser'] && task['assignedUser']['username']) {
				task['assignedUser'] = task['assignedUser']['username'];
			} else {
				delete task['assignedUser'];
			}

			this.pageLoadingUiService.setPageLoading(true);
			task['network'] = this.network?.uid;
			this.networkService.createToDoItem(task, uid, this.listPrivacy).subscribe(
				(todo) => {
					this.setReminderForTaskAndSubTask(todo, task['remindersSelected'] ? task['remindersSelected'] : []);
					if (this.subtasks.length - 1 === index) {
						this.updatedPostEmitter.emit(new Post(data));
						this.taskUpdatedEmitter.emit(data);
						this.pageLoadingUiService.setPageLoading(false);
						this.clearSubtasks();
					}
				},
				(err) => {
					this.handleError(err);
				}
			);
		});
	}

	public checkForSubtaskMentions(task): void {
		if (task['mentioned_users'] && task['mentioned_users'].length > 0) {
			const userList = [];
			for (const u of task['mentioned_users']) {
				if (task['title'].indexOf('@' + u.name) > -1) {
					userList.push(u.username);
				}
			}
			task['mentioned_users'] = [];
			task['mentioned_users'] = userList.join(',');
		}
		if (task['hashtags'] && task['hashtags'].length > 0) {
			const tagList = [];
			for (const h of task['hashtags']) {
				if (task['title'].indexOf('#' + h['name']) > -1) {
					tagList.push(h['name']);
				}
			}
			task['hashtags'] = [];
			task['hashtags'] = tagList.join(',');
		}
	}

	public onImgError(event): void {
		event.target.src = 'assets/images/default_avatar.jpg';
	}

	public getMentionsForSubtasks(e, index): void {
		this.isSubtask = true;
		this.titleFocus = false;

		this.textArea.toArray().forEach((element, i) => {
			if (index === i) {
				this.native = element;
				this.index = index;
			}
		});

		this.userRegexFailed = false;
		this.hashRegexFailed = false;
		e.target.style.height = e.target.scrollHeight + 'px';
		const userMentionRegex = /(^|\s)@(\w*(?:\s*\w*))$/;
		const hashTagRegex = /(^|\s)#(\w*(?:\s*\w*))$/;
		let text;
		const caretPosition = this.getCaretPositionForSubtask(e.target.value);
		const word = this.returnWord(e.target.value, caretPosition);

		if (word !== null) {
			if (userMentionRegex.test(word)) {
				this.mentionType = 'user';
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					// this.mentionsFetched = 1;
					text = word.replace(/(^@)/g, '');
					if (this.mentionType === 'user') {
						if (text.length >= 3) {
							this.mentionsFetched = 1;
							this.subject.next(text); // 4
						} else {
							return;
						}
					}
				}
			} else {
				this.userRegexFailed = true;
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					this.hashMentions = [];
					this.userMentions = [];
				}
			}
			if (word !== null && hashTagRegex.test(word)) {
				this.mentionType = 'hash';
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					this.mentionsFetched = 1;
					text = word.replace(/(^#)/g, '');
					this.subject.next(text); // 4
				}
			} else {
				this.hashRegexFailed = true;
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					this.hashMentions = [];
					this.userMentions = [];
				}
			}
		} else {
			if (!this.isMentions) {
				this.mentionsFetched = 0;
				this.userMentions = [];
				this.hashMentions = [];
			}
		}
	}

	private getCaretPositionForSubtask(ctrl): number {
		let caretPosition = 0;
		ctrl = this.native.nativeElement;
		if (ctrl.selectionStart || ctrl.selectionStart === '0') {
			caretPosition = ctrl.selectionStart;
		}
		return caretPosition;
	}

	public onUserSelectForSubtask(user): void {
		this.isMentions = false;
		this.userMentions = [];
		this.hashMentions = [];
		const startPosition = this.native.nativeElement.selectionStart;
		const endPosition = this.native.nativeElement.selectionEnd;
		if (startPosition === endPosition) {
			const text = this.native.nativeElement.value.substring(0, startPosition);
			this.mentionIndex = text.lastIndexOf('@');

			if (this.mentionIndex > -1 && this.shiftKeyUsed) {
				const strippedText = this.native.nativeElement.value.substring(this.mentionIndex, startPosition);

				if (strippedText !== '@') {
					const reg = new RegExp(strippedText, 'g');
					const replacedTitle = this.subtasks[this.index]['title'].replace(reg, '@' + user.name + ' ');
					this.subtasks[this.index]['title'] = replacedTitle;
				} else {
					const firstPart = this.subtasks[this.index]['title'].substr(0, startPosition);
					const lastPart = this.subtasks[this.index]['title'].substr(startPosition + 1);
					const newString = firstPart + user.name + ' ' + lastPart;
					this.subtasks[this.index]['title'] = newString;
				}
			} else {
				const newTitle = <''>(
					`${this.native.nativeElement.value.substring(0, startPosition)}@${user.name + ' '}${this.native.nativeElement.value.substring(endPosition)}`.toString()
				);
				this.subtasks[this.index]['title'] = newTitle;
				this.native.nativeElement.focus();
			}
		}

		if (this.subtasks[this.index]['mentioned_users']) {
			this.subtasks[this.index]['mentioned_users'].push(user);
		} else {
			this.subtasks[this.index]['mentioned_users'] = [];
			this.subtasks[this.index]['mentioned_users'].push(user);
		}
	}

	public onHashTagForSubtask(tag): void {
		this.isMentions = false;
		this.userMentions = [];
		this.hashMentions = [];

		const startPosition = this.native.nativeElement.selectionStart;
		const endPosition = this.native.nativeElement.selectionEnd;
		if (startPosition === endPosition) {
			const text = this.native.nativeElement.value.substring(0, startPosition);
			this.mentionIndex = text.lastIndexOf('#');

			if (this.mentionIndex > -1 && this.shiftKeyUsed) {
				const strippedText = this.native.nativeElement.value.substring(this.mentionIndex, startPosition);
				if (strippedText !== '#') {
					const reg = new RegExp(strippedText, 'g');
					const replacedTitle = this.subtasks[this.index]['title'].replace(reg, '#' + tag['name'] + ' ');
					this.subtasks[this.index]['title'] = replacedTitle;
				} else {
					const firstPart = this.subtasks[this.index]['title'].substr(0, startPosition);
					const lastPart = this.subtasks[this.index]['title'].substr(startPosition + 1);
					const newString = firstPart + tag['name'] + ' ' + lastPart;
					this.subtasks[this.index]['title'] = newString;
				}
			} else {
				const newTitle = <''>(
					`${this.native.nativeElement.value.substring(0, startPosition)}#${tag['name'] + ' '}${this.native.nativeElement.value.substring(endPosition)}`.toString()
				);
				this.subtasks[this.index]['title'] = newTitle;
				this.native.nativeElement.focus();
			}
		}

		if (this.subtasks[this.index]['hashtags']) {
			this.subtasks[this.index]['hashtags'].push(tag);
		} else {
			this.subtasks[this.index]['hashtags'] = [];
			this.subtasks[this.index]['hashtags'].push(tag);
		}
	}

	private clearSubtasks(): void {
		this.isSubtask = false;
		this.index = '';
		this.native = '';
		this.subtasks = [];
		this.subtasksIndexContainer = [];
		this.subtaskDate = {
			year: new Date().getFullYear(),
			month: new Date().getMonth() + 1,
			day: new Date().getDate(),
		};
		this.subTasksToBeAdded = [];
		this.subTasksToBeDeleted = [];
	}

	public autoSizeBox(e, isSubtask = false): void | boolean {
		if (isSubtask && (e?.key === 'Enter' || e?.keyCode === 13)) {
			e.preventDefault();
			return false;
		}
		e.target.style.cssText = 'padding: 5px; background: none; resize: none; font-size: 14px;height:auto';
		e.target.style.cssText = 'padding: 5px; background: none; resize: none; font-size: 14px;height:' + e.target.scrollHeight + 'px';
	}

	public setSubject(e): void {
		this.isCustomersFetching = 0;
		const text = e.target.value;
		this.customerSubject.next(text);
	}

	public setTypeForIssue(): void {
		if (this.currentType) {
			this.selectedType = this.currentType;
		}
	}

	private setIssueEdit(): void {
		this.model.preview_images = [];

		if (!this.model.title) {
			this.isTitle = true;
			return;
		} else {
			this.addAnIssue();
		}

		if (this.selectedCustomer) {
			this.issueModel['customer'] = this.selectedCustomer['uid'];
		}
		if (this.selectedType) {
			this.issueModel['type'] = this.selectedType;
		}
		if (this.model['url']) {
			this.issueModel['attachmentType'] = 'url';
			this.issueModel['url'] = this.model['url'];
		} else if (this.doc_upload && this.documentList.indexOf(this.fileType[0]) > -1) {
			this.issueModel['attachmentType'] = 'doc';
			this.issueModel['doc'] = this.model.images.join('|');
			this.model.images = [];
		} else if (this.model.images.length > 0) {
			this.issueModel['images'] = this.model.images.join('|');
			this.issueModel['attachmentType'] = 'photo';
		} else if (this.fileUpload && this.model['doc']) {
			this.issueModel['attachmentType'] = 'doc';
			this.issueModel['doc'] = this.model['doc'];
		} else if (this.model['location']) {
			this.issueModel['attachmentType'] = 'location';
			if (this.model['location']['uid']) {
				this.issueModel['location'] = this.model['location']['uid'];
			} else {
				this.locationService
					.create({
						venueId: this.model.location.venueId,
						latitude: this.model.location.latitude,
						longitude: this.model.location.longitude,
						name: this.model.location.name,
						category: this.model.location.category,
						website: this.model.location.website,
						phone: this.model.location.phone,
					})
					.subscribe((data) => {
						this.issueModel['location'] = data.uid;
						this.callUpdateIssue();
					});
			}
		}
		this.callUpdateIssue();
	}

	public showDueDateComponent(): void {
		this.taskType = 'task';

		if (this.dueDateComponent) {
			this.dueDateComponent.display();
			this.dueDateComponent.selectedReminders = [];
			if (this.todoItem['remindersSelected']) {
				this.dueDateComponent.selectedReminders = this.todoItem['remindersSelected'];
			} else {
				if (this.todoItem['myRelations'] && this.todoItem['myRelations'].reminder) {
					this.pageLoadingUiService.setPageLoading(true);
					this.dueDateComponent.getContentReminders(this.todoItem);
				}
			}
			if (this.todoItem['repeatFreequency']) {
				// Resetting repeat frequency
				this.dueDateComponent.selectedRepeat = this.todoItem['repeatFreequency'];
			} else {
				this.dueDateComponent.selectedRepeat = '';
			}

			if (this.todoItem['startTime'] && this.todoItem['fromTime']) {
				this.dueDateComponent.startTime = this.todoItem['fromTime'];
			} else if (this.todoItem['startTime'] && !this.todoItem['fromTime']) {
				const a = this.todoItem['startTime'];
				const b = new Date(a);
				this.dueDateComponent.startTime = b.getHours() + ':' + (b.getMinutes() > 9 ? b.getMinutes() : '0' + b.getMinutes());
			} else {
				this.dueDateComponent.startTime = null;
			}

			if (this.todoItem['estimatedTime'] && this.todoItem['toTime']) {
				this.dueDateComponent.endTime = this.todoItem['toTime'];
			} else if (this.todoItem['estimatedTime'] && !this.todoItem['toTime']) {
				const a = this.todoItem['estimatedTime'];
				const b = new Date(a);
				this.dueDateComponent.endTime = b.getHours() + ':' + (b.getMinutes() > 9 ? b.getMinutes() : '0' + b.getMinutes());
			} else {
				this.dueDateComponent.endTime = null;
			}

			if (this.todoItem['selectedDueDate']) {
				this.dueDateComponent.selectedDueDate = this.todoItem['selectedDueDate'];
			} else {
				this.dueDateComponent.selectedDueDate = 'daily';
			}

			if (this.todoItem.repeatCycle === 'one_off') {
				this.dueDateComponent.showPreviouslySelectedDate(new Date(this.todoItem['oneOff']));
			}
		}
	}

	/**
	 * For showing due date options for a subtask
	 * @param task
	 */
	public displaySubtaskDueDate(task): void {
		this.taskType = 'subTask';
		this.currentSub = task;

		// this.displaySubDueDateOptions = 'block';
		// if (this.currentSub['repeatCycle'] === 'one_off') {
		//   if (this.currentSub['oneOff'] === this.setTomorrowDate()) {
		//     this.sub = 'tomorrow';
		//     this.subtaskDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
		//   } else {
		//     this.sub = 'one_off';
		//     var date = new Date(this.currentSub['oneOff']);
		//     var month = date.getMonth() + 1;
		//     var day = date.getDate();
		//     var year = date.getFullYear();
		//     this.subtaskDate = { year: year, month: month, day: day };
		//   }
		// } else {
		//   this.sub = this.currentSub['repeatCycle'];
		// }
		if (this.dueDateComponent) {
			this.dueDateComponent.display();
			this.dueDateComponent.selectedReminders = [];

			if (task.repeatCycle) {
				this.dueDateComponent.selectedDueDate = task.repeatCycle;
			} else {
				this.dueDateComponent.selectedDueDate = 'daily';
			}

			this.currentSub['selecteDueDate'] = this.dueDateComponent.selectedDueDate;

			if (this.currentSub.remindersSelected) {
				this.dueDateComponent.selectedReminders = this.currentSub.remindersSelected;
			} else {
				if (task.myRelations && task.myRelations.reminder) {
					this.pageLoadingUiService.setPageLoading(true);
					this.dueDateComponent.getContentReminders(task);
				}
			}

			if (task.repeatFreequency) {
				// Resetting repeat frequency
				this.dueDateComponent.selectedRepeat = task.repeatFreequency;
			} else {
				this.dueDateComponent.selectedRepeat = '';
			}

			if (task.fromTime) {
				this.dueDateComponent.startTime = task['fromTime'];
			} else {
				this.dueDateComponent.startTime = null;
			}

			if (task.toTime) {
				this.dueDateComponent.endTime = task.toTime;
			} else {
				this.dueDateComponent.endTime = null;
			}

			if (task.repeatCycle === 'one_off') {
				this.dueDateComponent.showPreviouslySelectedDate(new Date(task.oneOff));
			}
		}
	}

	private setTodoOptions(): void {
		// if (this.dueDateComponent['selectedDueDate'] !== 'tomorrow') {
		//   this.todoItem['repeatCycle'] = this.dueDateComponent['selectedDueDate'];
		// } else {
		//   this.todoItem['repeatCycle'] = 'one_off';
		// }
		// if (this.dueDateComponent['oneOff']) {
		//   this.todoItem['oneOff'] = this.dueDateComponent['oneOff'];
		// } else {
		//   delete this.todoItem['oneOff'];
		// }
		// if (this.dueDateComponent['startTime'] && this.dueDateComponent['startTime'] !== "00:00") {
		//   this.todoItem['startTime'] = this.setUtcDate(this.dueDateComponent['startTime']);
		// } else {
		//   delete this.todoItem['startTime'];
		// }
		// if (this.dueDateComponent['endTime'] && this.dueDateComponent['endTime'] !== "00:00") {
		//   this.todoItem['estimatedTime'] = this.setUtcDate(this.dueDateComponent['endTime']);
		// } else {
		//   delete this.todoItem['estimatedTime'];
		// }
		// if (this.dueDateComponent['selectedRepeat']) {
		//   this.todoItem['repeatFreequency'] = this.dueDateComponent['selectedRepeat'];
		// } else {
		//   delete this.todoItem['repeatFreequency'];
		// }
		// if (this.dueDateComponent['selectedReminders'] && this.dueDateComponent['selectedReminders'].length > 0) {
		//   this.remindersSelected = this.dueDateComponent['selectedReminders'];
		// } else {
		//   this.remindersSelected = [];
		// }
		if (this.taskType === 'task') {
			this.todoItem['selectedDueDate'] = this.dueDateComponent['selectedDueDate'];
			if (this.dueDateComponent['selectedDueDate'] !== 'tomorrow') {
				this.todoItem['repeatCycle'] = this.dueDateComponent['selectedDueDate'];
			} else {
				this.todoItem['repeatCycle'] = 'one_off';
			}

			if (this.dueDateComponent['oneOff']) {
				this.todoItem['oneOff'] = this.dueDateComponent['oneOff'];
			}

			this.todoItem['startTime'] = this.dueDateComponent['startTime'] ? this.setUtcDate(this.dueDateComponent['startTime']) : null;
			this.todoItem['estimatedTime'] = this.dueDateComponent['endTime'] ? this.setUtcDate(this.dueDateComponent['endTime']) : null;

			this.todoItem['repeatFreequency'] = this.dueDateComponent['selectedRepeat'] ? this.dueDateComponent['selectedRepeat'] : null;
			this.todoItem['remindersSelected'] = this.dueDateComponent['selectedReminders'] ? this.dueDateComponent['selectedReminders'] : [];

			this.todoItem['fromTime'] = this.dueDateComponent['startTime'] ? this.dueDateComponent['startTime'] : null;
			this.todoItem['toTime'] = this.dueDateComponent['endTime'] ? this.dueDateComponent['endTime'] : null;
		} else {
			if (this.dueDateComponent['selectedDueDate'] !== 'tomorrow') {
				this.currentSub['repeatCycle'] = this.dueDateComponent['selectedDueDate'];
			} else {
				this.currentSub['repeatCycle'] = 'one_off';
			}
			if (this.dueDateComponent['oneOff']) {
				this.currentSub['oneOff'] = this.dueDateComponent['oneOff'];
			}

			this.currentSub['startTime'] = this.dueDateComponent['startTime'] ? this.setUtcDate(this.dueDateComponent['startTime']) : null;
			this.currentSub['fromTime'] = this.dueDateComponent['startTime'] ? this.dueDateComponent['startTime'] : null;

			this.currentSub['estimatedTime'] = this.dueDateComponent['endTime'] ? this.setUtcDate(this.dueDateComponent['endTime']) : null;
			this.currentSub['toTime'] = this.dueDateComponent['endTime'] ? this.dueDateComponent['endTime'] : null;

			this.currentSub['repeatFreequency'] = this.dueDateComponent['selectedRepeat'] ? this.dueDateComponent['selectedRepeat'] : null;
			this.currentSub['remindersSelected'] = this.dueDateComponent['selectedReminders'] ? this.dueDateComponent['selectedReminders'] : [];
		}
	}

	private setUtcDate(time): string {
		let d;
		if (this.dueDateComponent['selectedDueDate'] === 'daily') {
			const today = new Date().toISOString().slice(0, 10) + '';
			d = today + ' ' + time;
		} else {
			d = this.dueDateComponent['oneOff'] + ' ' + time;
		}
		const date = new Date(d);
		const startMonth = date.getUTCMonth() + 1;
		const startDay = date.getUTCDate();
		const startYear = date.getUTCFullYear();
		const startHours = date.getUTCHours();
		const startMinutes = date.getUTCMinutes();
		const startSeconds = date.getUTCSeconds();
		const utcDate = startYear + '-' + startMonth + '-' + startDay + ' ' + startHours + ':' + startMinutes + ':' + startSeconds;
		return utcDate;
	}

	/**
	 * Function to set project privacy cp
	 */
	public emitCurrentSelectedProjectCpFn($event): void {
		if ($event) {
			this.projectPrivacyCp = $event.name;
		} else {
			this.projectPrivacyCp = 'Everyone';
		}

		this.confirm_members = false;
		this.assignedMembers = [];
		this.defaultTaskMember = this.currentUser.name;
	}

	public projectDeselectedEmitterFn(): void {
		this.projectPrivacyCp = 'Team not selected';
		this.todoItem['project'] = null;
		this.confirm_members = false;
		this.assignedMembers = [];
		this.defaultTaskMember = this.currentUser.name;
	}

	/**
	 * Function to show the due date
	 */
	public showDueDateInDetail(task): string {
		if (task['selectedDueDate'] === 'daily') {
			return 'Today';
		} else if (task['selectedDueDate'] === 'weekly') {
			return 'This week';
		} else if (task['selectedDueDate'] === 'monthly') {
			return 'This month';
		} else if (task['selectedDueDate'] === 'tomorrow') {
			return 'Tomorrow';
		} else if (task['selectedDueDate'] === 'one_off') {
			return moment(task['oneOff']).format('yyyy-MM-DD');
		}
	}

	/**
	 * Function to hide the loader after successfully fetched the reminder details
	 */
	public successfullyFetchedRemainderDetailsEmitter(): void {
		this.pageLoadingUiService.setPageLoading(false);
	}

	// #region Error Handling

	public error = { detail: '', status: '' };

	public close(): void {
		localStorage.removeItem('status');
		this.displayError = 'none';
		this.error = {
			detail: this.translate.instant('Something went wrong'),
			status: '',
		};
	}

	private handleError(error): void {
		this.pageLoadingUiService.setPageLoading(false);
		this.displayError = 'block';
		if (error) {
			if (error?.errors?.error?.detail) {
				this.error['detail'] = error?.errors?.error?.detail;
			} else {
				this.error['detail'] = this.translate.instant('Something went wrong');
			}

			if (error?.errors?.status) {
				this.error['status'] = error?.errors?.status;
			}
		}
	}

	// #endregion

	// #region TUI Editor

	private initializeTuiEditor(): void {
		timer(500).subscribe(() => {
			const tuiEditorElement: HTMLElement = document.querySelector('#tuiEditor');

			if (!tuiEditorElement) {
				return;
			}

			this.tuiEditor = new Editor({
				el: tuiEditorElement,
				height: '500px',
				initialEditType: 'wysiwyg',
				previewStyle: 'vertical',
				autofocus: true,
				plugins: [[codeSyntaxHighlight, { highlighter: Prism }]],
				language: this.translate.currentLang === 'tr' ? 'tr-TR' : 'en-US',
				placeholder: this.translate.instant(
					this.postType === 'buy-sell' ? 'Description (Optional)' : this.postType === 'event' ? 'Description (Optional)' : 'What do you like to say?'
				),
				linkAttributes: { target: '_blank' },
				frontMatter: true,
				/**
				 *  eslint-disable-next-line spellcheck/spell-checker
				 * ? Other possible fields are:- image, code, codeblock, scrollSync
				 */
				toolbarItems: [
					['heading', 'bold', 'italic', 'strike'],
					['hr', 'quote'],
					// eslint-disable-next-line spellcheck/spell-checker
					['ul', 'ol', 'indent', 'outdent'],
					['link'],
				],
			});

			this.tuiEditor.setMarkdown(this.model.body);

			this.tuiEditor.on('blur', () => {
				this.model.body = this.tuiEditor.getMarkdown();
			});
		});
	}

	// #endregion
}
