import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarService } from 'projects/peer-core/src/lib/services/ui/sidebar.service';
import { NetworkService, NotificationService, UserService } from '../shared';

@Component({
	selector: 'app-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.css'],
})
export class NotificationComponent implements OnInit {
	public nextUrl;
	public notifications;
	public currentUser;
	public membershipList;
	public externalUserChannel: any;
	public isExternalUser = false;
	public title;
	public isAuthenticated;
	public showSpin = false;
	public userNotificationSummary;
	public channelMembershipList;
	public categoryFilters;
	public currentSelectedFilter = '';
	public network;
	private networkSubscriber;
	private currentNetSub;
	public networkType;
	public networkLogo;
	public showNetwork;

	constructor(
		private networkService: NetworkService,
		private notificationService: NotificationService,
		private userService: UserService,
		private router: Router,
		public sidebarService: SidebarService
	) {
		window.onscroll = (): void => {
			const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
			const body = document.body,
				html = document.documentElement;
			const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
			const windowBottom = windowHeight + window.pageYOffset;
			if (windowBottom >= docHeight) {
				this.paginate();
			}
		};
	}

	ngOnInit(): void {
		// TODO: use loading spinner

		this.categoryFilters = ['buy & sell', 'events', 'recommendations', 'messages', 'crime & safety', 'general', 'roommate'];
		this.userService.populate();
		this.userService.isAuthenticated.subscribe((authenticated) => {
			this.isAuthenticated = authenticated;
			if (authenticated) {
				this.title = 'Networks';
				this.userService.currentUser.subscribe((userData) => {
					this.currentUser = userData;
				});

				this.notificationService.getUserNotifications(this.currentUser.username, 'type=account').subscribe((notificationData) => {
					if (notificationData.next) {
						this.nextUrl = notificationData.next.split('alpha')[1];
					} else {
						this.nextUrl = '';
					}
					this.notifications = notificationData.objects;
				});

				this.membershipList = this.userService.getCurrentUser()['activeMemberships'];
				// eslint-disable-next-line spellcheck/spell-checker
				this.channelMembershipList = this.userService.getCurrentUser()['activechannelMemberships'];
				this.membershipList.sort(function (m1, m2) {
					if (m1.network.networkType < m2.network.networkType) {
						return -1;
					} else if (m1.network.networkType > m2.network.networkType) {
						return 1;
					} else {
						return 0;
					}
				});
			} else {
				this.router.navigateByUrl('/authentication/login');
			}
		});

		this.networkSubscriber = this.networkService.networkReady.subscribe((networkLoaded) => {
			if (networkLoaded) {
				this.currentNetSub = this.networkService.currentNetwork.subscribe((network) => {
					this.network = network;
					this.networkType = network.networkType;
					this.networkLogo = network.logo;
					this.showNetwork = true;
				});
			}
		});
	}

	public getUserNotifications(filter): void {
		this.currentSelectedFilter = filter;
		event.preventDefault();

		// TODO: use loading spinner

		this.notificationService.getUserNotifications(this.currentUser.username, filter).subscribe((notificationData) => {
			if (notificationData.next) {
				this.nextUrl = notificationData.next.split('alpha')[1];
			} else {
				this.nextUrl = '';
			}
			this.notifications = notificationData.objects;
		});
	}
	public paginate(): void {
		if (this.nextUrl) {
			this.showSpin = true;
			this.notificationService.paginate(this.nextUrl).subscribe((notificationData) => {
				if (notificationData.next) {
					this.nextUrl = notificationData.next.split('alpha')[1];
				} else {
					this.nextUrl = '';
				}
				this.notifications = this.notifications.concat(notificationData.objects);
				this.showSpin = false;
			});
		}
	}
}
