export class Issue {
	public category = 'bugs';
	public network: string = localStorage.getItem('network_id');
	public collection_post: any = true;
	public commentable: any = true;
	public priority = 'p2';
	public shareable: any = true;
	public state: any = 'active';
	public title: any = null;
	public type: any = 'issue';
	public username: any = localStorage.getItem('uid');
	public contentType: any = 'network';
	public products: any = null;
}
