import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, UrlTree } from '@angular/router';
import { Network } from '@shared/models';
import { NetworkService } from '@shared/services';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SubscriptionGuard implements CanActivate, CanLoad {
	constructor(private networkService: NetworkService, private router: Router) {}

	public canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		this.networkService.currentNetwork.subscribe((network: Network) => {
			if (network && network?.subscription?.status) {
				switch (network?.subscription?.status) {
					case 'active':
					case 'trialing':
						return true;

					case 'incomplete':
					case 'incomplete_expired':
					case 'past_due':
					case 'canceled':
					case 'unpaid':
						localStorage.setItem('networkExpired', 'true');
						return this.router.createUrlTree(['/expired']);

					default:
						return true;
				}
			}
		});
		return true;
	}

	public canLoad(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		this.networkService.currentNetwork.subscribe((network: Network) => {
			if (network && network?.subscription?.status) {
				switch (network?.subscription?.status) {
					case 'active':
					case 'trialing':
						return true;

					case 'incomplete':
					case 'incomplete_expired':
					case 'past_due':
					case 'canceled':
					case 'unpaid':
						return false;

					default:
						return true;
				}
			}
		});
		return true;
	}
}
