export interface ILocationAttachment {
	latitude: number;
	longitude: number;
}

export class LocationAttachment implements ILocationAttachment {
	public latitude: number;
	public longitude: number;

	constructor(
		values: ILocationAttachment = {
			latitude: 0,
			longitude: 0,
		}
	) {
		Object.assign(this, values);
	}
}
