import { ISection } from '@shared/models/section.model';
import { IPaginatedUI } from './base/paginated-data-ui';

export enum TaskStatus {
	open = 'open',
	in_progress = 'in_progress',
	paused = 'paused',
	closed = 'closed',
}
export interface ITodoData extends IPaginatedUI {
	count?: number;
	next?: string;
	previous?: null | string;
	offset?: number;
	objects?: ITodo[];
	type?: TaskStatus;
}

export class TodoData implements ITodoData {
	public count?: number = 0;
	public next?: string = null;
	public previous?: null = null;
	public offset?: number;
	public objects?: ITodo[] = [];
	public isLoading?: boolean = false;

	constructor(values: Object = {}) {
		Object.assign(this, values);
	}
}

export interface ITodoUI {
	subTasksNextUrl?: string;
	plainCreatedAt?: string;
	plainClosedAt?: string;
	altered_title?: string;
	createdAt?: Date;
	closedAt?: Date;
	relatedContent?: any;
	attachmentDetails?: {
		gallery: any;
		audio: any;
		doc: any;
		url: any;
		member: any;
		video: any; // TODO: implement
		location: any; // TODO: implement
	};
	localEstimatedTime?: any;
	localStartTime?: any;
	oneOff?: any;
	isHovered?: boolean;
	isSelected?: boolean;
	isFocused?: boolean;

	// calendar UI properties
	createdAtMmYyyy?: string;
	closedAtMmYyyy?: string;

	estimate: any;
	elapsed: any;
	numberOfOpens: number;
	doc?: any;
	url?: string;
	attachmentType?: string;
	images?: string;
	member?: any;

	isStatusLoading: boolean;
	index: number;

	isChildTreeOpen: boolean;
	isSubTasksLoading: boolean;
	isEditing: boolean;
	isSubTask?: boolean;
	parent?: ITodo;
}

export interface ITodo extends ITodoUI {
	description?: string;
	estimatedTime?: string;
	startTime?: string;
	dueTime?: string | number;
	sectionTodo?: boolean;
	sectionId?: string;
	uid: string | null;
	category: string;
	network: string | null;
	created: string;
	collection_post: any;
	commentable: any;
	priority: string;
	repeatCycle: any;
	shareable: any;
	state: any;
	title: any;
	type: any;
	outcome?: string;
	repeatFreequency?: any;
	elapsed_time?: any;
	assignedUser: any;
	status: string | null;
	editTitle: string | null;
	subTasks: ITodo[];
	section: any;
	author: any;
	myRelations: any;
	shareUrl: string | null;
	project: any;
	titleContainerDoubleClicked: boolean;
	isDraft: boolean;
	postUid: string;
	oneOff: string;
	closedDate: string;
	elapsedTime: number;
	totalSubTasks?: number;
	closedSubTasks?: number;
	likesCount?: any;
	comments?: any;
	commentCount?: number;
}

export class Todo implements ITodo {
	public uid: string | null = null;
	public category = 'todo';
	public network: string = localStorage.getItem('network_id');
	public collection_post: any = true;
	public commentable: any = true;
	public created: string = null;
	public priority = 'p2';
	public repeatCycle: any = 'daily';
	public shareable: any = true;
	public state: any = 'active';
	public title: any = null;
	public type: any = 'todo';
	public outcome?: string = null;
	public repeatFreequency?: any = null;
	public elapsed_time?: any = null;
	public assignedUser: any = null;
	public status: TaskStatus | string | null = null;
	public isEditing = false;
	public editTitle: string | null = null;
	public isChildTreeOpen = false;
	public subTasks: any[] = [];
	public isSubTasksLoading = false;
	public section: any = null;
	public author: any = null;
	public myRelations: any = null;
	public shareUrl: string | null = null;
	public project: any = null;
	public toggle = false;
	public titleContainerDoubleClicked = false;
	public isDraft = false;
	public totalSubTasks = 0;
	public isSelected = false;
	public isFocused = false;
	public estimate: any = null;
	public elapsed: any = null;
	public numberOfOpens = 0;
	public postUid = null;
	public oneOff = null;
	public closedDate = null;
	public isStatusLoading = false;
	public elapsedTime = 0;
	public index = -1; // not implemented yet
	public parentTodoUid?: string;
	public multipleTodos?: any;

	constructor(values: Object = {}) {
		Object.assign(this, values);

		this.status = TaskStatus[values['status']];
	}
}

export interface TaskToggle {
	task: ITodo;
	targetStatus: TaskStatus;
	index: number;
	section: ISection;
}

export interface ITaskStatusForm {
	estimate?: number;
	elapsed_time?: number;
	outcome?: string | 'none'; // reaction
	uid?: string;
	status?: string;
	assignedUser?: string;
	closedDate?: string;
	priority?: string;
	repeatCycle?: string;
	title?: string;
	oneOff?: string;
	repeatFreequency?: string;
	project?: string;
}

export class TaskStatusForm implements ITaskStatusForm {
	// all fields are optional because we are using this class to update only the fields that are changed
}
