import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagePreviewComponent } from './image-preview.component';
import { LightboxModule } from 'ng-gallery/lightbox';

@NgModule({
	declarations: [ImagePreviewComponent],
	imports: [CommonModule, LightboxModule],
	exports: [ImagePreviewComponent],
})
export class ImagePreviewModule {}
