import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { timer } from 'rxjs';

@Component({
	selector: 'app-youtube-player-modal',
	templateUrl: './youtube-player-modal.component.html',
	styleUrls: ['./youtube-player-modal.component.css'],
})
export class YoutubePlayerModalComponent implements OnInit {
	@Input() public youtubeEmbedUrl: string;
	@Input() public title: any;

	private youTubePlayerIFrame: ElementRef;
	@ViewChild('youTubePlayerIFrame', { static: false }) set content(content: ElementRef) {
		if (content) {
			this.youTubePlayerIFrame = content;
		}
	}

	constructor(public activeModal: NgbActiveModal) {}

	ngOnInit(): void {
		timer(500).subscribe(() => {
			this.youTubePlayerIFrame.nativeElement.src = this.youtubeEmbedUrl;
		});
	}

	public closeModal(): void {
		this.activeModal?.close();
	}
}
