declare const buffer: { from: (arg0: any) => any };

export class Conversation {
	public id: string;
	public lastMessage: any;
	public receivedAt: string;
	public userId: any;
	public user: any;
	public unreadCount: number;
	public isCurrentParticipant: any;
	public isUnread: boolean;
	public recipientStatus: any;
	public metadata: any;
	public totalMessageCount: number;
	public url: string;
	public isGroup: boolean;
	public participants = [];
	public conversationName: string;
	public participant_list: any[] = [];

	constructor(conversation, loggedUser) {
		for (const key of Object.keys(conversation)) {
			if (key === '__lastMessage') {
				let body = '';
				for (let it = conversation.__lastMessage.parts.values(), val = null; (val = it.next().value); ) {
					if (val.mimeType === 'text/plain') {
						body += val.body;
					} else if (val.mimeType === 'video/mp4') {
						body += 'Attachment - video';
					} else if (val.mimeType === 'location/coordinate') {
						body += 'Attachment - Location';
						// eslint-disable-next-line spellcheck/spell-checker
					} else if (val.mimeType === 'application/vnd.layer.text+json') {
						body += JSON.parse(val.body)['text'];
					} else {
						body += 'Attachment - Image';
					}
					break;
				}
				this['lastMessage'] = {
					body: body, // ["[[Entries]]"][0].value.body,
					sender: conversation.__lastMessage.sender.userId,
					senderName: conversation.__lastMessage.sender.displayName,
				};
				this['receivedAt'] = conversation.__lastMessage.receivedAt;
			} else if (key === '__participants') {
				for (const p of conversation.__participants) {
					if (!p.isMine) {
						this['userId'] = p.userId;
					}
				}
			} else if (key === '__metadata' && conversation.metadata) {
				this['metadata'] = conversation.__metadata;
				if (conversation.__metadata.conversationName) {
					this['isGroup'] = true;
					this['conversationName'] = conversation.__metadata.conversationName;
				}
			} else if (key in conversation) {
				this[key] = conversation[key];
			}
		}
		for (const p of conversation.participants) {
			if (loggedUser !== p.userId) {
				this['senderName'] = p.displayName;
				this['senderImage'] = p.avatarUrl;
			}
			this['participant_list'].push(p);
			this['participants'].push(p.userId);
		}
		if (conversation.lastMessage) {
			this['isUnread'] = conversation.lastMessage.isUnread;
		} else {
			this['receivedAt'] = new Date().toDateString();
		}
	}
}

export class Message {
	public id: string;
	public body: any;
	public receivedAt: string;
	public userId: any;
	public user: any;
	public sender: any;
	public senderName: any;
	public senderImage: any;
	public unreadCount: number;
	public isCurrentParticipant: any;
	public isRead: boolean;
	public recipientStatus: any;
	public metadata: any;
	public url: string;
	public conversationId: string;
	public isGroup: boolean;
	public isSender: boolean;
	public latitude: string;
	public longitude: string;
	public messageParts: any;

	constructor(message) {
		for (const key of Object.keys(message)) {
			if (key === '__parts') {
				let body = '';
				const that = this;
				for (let it = message.__parts.values(), val = null; (val = it.next().value); ) {
					if (val.mimeType === 'text/plain') {
						if (!val.body) {
							val.fetchContent(function (b) {
								body = b;
							});
						} else {
							body = val.body;
						}
						break;
					} else if (val.mimeType === 'video/mp4') {
						body = 'Attachment - video';
						break;
					} else if (val.mimeType === 'location/coordinates' || val.mimeType === 'text/location' || val.mimeType === 'location/coordinate') {
						body = 'Attachment - Location';
						if (!val.url && val.hasContent) {
							val.fetchStream(function (url) {
								that.getCoordinatesFromUrl(url);
							});
						} else {
							if (!val.body) {
								val.fetchContent();
							} else {
								if (val.encoding) {
									// deprecated
									// var bs = new buffer(val.body, 'base64')

									const bs = buffer.from(val.body);

									const s = bs.toString();
									this.messageParts = JSON.parse(s);
								} else if (val.body) {
									that.getCoordinatesFromUrl(val.body);
								} else {
									this.getCoordinates(val);
								}
							}
						}
						break;
						// eslint-disable-next-line spellcheck/spell-checker
					} else if (val.mimeType === 'application/vnd.layer.text+json') {
						body = JSON.parse(val.body)['text'];
						break;
					} else {
						body = 'Attachment - Image';

						if (!val.url && val.hasContent) {
							val.fetchStream(function (url) {
								that.messageParts = { url: url, type: val.mimeType };
							});
						} else if (val.url) {
							this.messageParts = { url: val.url, type: val.mimeType };
						}
						break;
					}
				}
				if (!this.body) {
					this['body'] = body;
				}
			}

			this['sender'] = message.sender.userId;
			this['receivedAt'] = message.receivedAt;
			this['isRead'] = message.__isRead;
		}

		this['senderName'] = message.sender.displayName;
		this['senderImage'] = message.sender.avatarUrl;
		this['conversationId'] = message.conversationId;
	}

	public isThatUserSender(username): string {
		if (this.sender === username) {
			return 'is-sender';
		} else {
			return '';
		}
	}

	public getCoordinates(val): void {
		const that = this;
		if (val.body instanceof Blob) {
			const reader = new FileReader();
			reader.onload = function (e: any): void {
				const loc = e.target.result;
				that.messageParts = JSON.parse(loc);
			};
			reader.readAsText(val.body);
		} else if (val instanceof Blob) {
			const reader = new FileReader();
			reader.onload = function (e: any): void {
				const loc = e.target.result;
				that.messageParts = JSON.parse(loc);
			};
			reader.readAsText(val);
		} else if (val.body) {
			that.messageParts = JSON.parse(val.body);
		} else {
			that.messageParts = JSON.parse(val);
		}
	}

	public getCoordinatesFromUrl(url): void {
		const that = this;
		if (url instanceof Blob) {
			const reader = new FileReader();
			reader.onload = function (e: any): void {
				const loc = e.target.result;
				that.messageParts = JSON.parse(loc);
			};
			reader.readAsText(url);
		} else {
			that.messageParts = JSON.parse(url);
		}
	}
}
