/* eslint-disable spellcheck/spell-checker */
import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { UserService, BuySellService, ClassiFied, NetworkService, LayoutService, PaginationService } from '../shared';
import { ActivatedRoute, Router } from '@angular/router';
import { PostComponent } from '../post/post.component';
import { SearchService } from '../shared/services/search.service';

@Component({
	selector: 'app-buy-sell',
	templateUrl: './buy-sell.component.html',
	styleUrls: ['./buy-sell.component.css'],
})
export class BuySellComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('postComponent', { static: true }) public postComponent: PostComponent;
	public loadingGif = 'none';
	public myTeamUid: any;
	public showMyTeam = false;
	public showPostCreateForm = false;
	public postType = '';
	public currentUser;
	public network;
	public fields;
	public showSpin;
	public filter = { value: '' };
	public classifieds = [];
	public nextUrl;
	public showCategories = false;
	public showCondition = false;
	public showChannel = false;
	public showPostCreate = false;
	public searchString = '';
	public isFirstSearch = false;
	private paginating = false;
	public paginationSub$: any;
	public networkId: any;
	public carouselOne;
	public filters;
	public channelMembershipList;
	public selectedTab = 'detail';

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private buySellService: BuySellService,
		private networkService: NetworkService,
		private userService: UserService,
		private layoutService: LayoutService,
		public searchService: SearchService,
		private paginationService: PaginationService
	) {
		this.classifieds = this.buySellService.buySellList;
		this.nextUrl = this.buySellService.nextUrl;
		this.paginationService.scrollDistance = 5;
		this.paginationSub$ = this.paginationService.initiateScroll.subscribe((data) => {
			if (data && !this.paginating) {
				this.paginate();
			}
		});
	}
	ngOnInit(): void {
		if (localStorage.getItem('networkExpired')) {
			this.router.navigateByUrl('/expired');
		}

		this.carouselOne = {
			grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
			slide: 1,
			speed: 400,
			interval: 4000,
			point: {
				visible: true,
			},
			load: 2,
			touch: true,
			loop: true,
			custom: 'banner',
		};
		this.networkService.currentNetwork.subscribe((network) => {
			this.network = network;
			this.channelMembershipList = this.network.channels;
			if (network.membership && network.membership.memberRole) {
				this.fields = network['membership']['memberRole']['fields'];
				this.checkIfManager();
			}
		});
		this.userService.getCurrentUser();
		this.userService.currentUser.subscribe((userData) => {
			this.currentUser = userData;
		});

		this.getClassifieds();

		this.buySellService.getFilters().subscribe((data) => {
			this.filters = data;
		});

		this.postComponent.newPostEmitter.subscribe((data) => {
			if (data) {
				this.getClassifieds();
			}
		});

		this.layoutService.showBackdrop.subscribe((data) => {
			if (data === 'event-post-form' || data === 'hide') {
				this.showPostCreateForm = false;
				this.postType = '';
			}
		});

		this.searchService.searchValue.subscribe((value) => {
			this.searchString = value;
			if (this.isFirstSearch && value.trim()) {
				this.isFirstSearch = false;
			}
			if (!this.isFirstSearch) {
				this.filterClassifieds('all', value);
			}
		});
	}

	ngOnDestroy(): void {
		this.buySellService.buySellList = this.classifieds;
		this.buySellService.nextUrl = this.nextUrl;
		this.buySellService.scrollPosition = document.documentElement.scrollHeight;
		this.paginationService.scrollDistance = 5;
		if (this.paginationSub$) {
			this.paginationSub$.unsubscribe();
		}
	}

	ngAfterViewInit(): void {
		if (this.buySellService.scrollPosition) {
			window.scroll(0, this.buySellService.scrollPosition);
		}
		// Hack: Scrolls to top of Page after page view initialized
		let top = document.getElementById('top');
		if (top !== null) {
			top.scrollIntoView();
			top = null;
		}
	}

	public filterClassifieds(filter, q = ''): void {
		this.filter = { value: '' };
		event.preventDefault();
		this.loadingGif = 'block';
		let url = '/networks/' + this.network.uid + '/posts/?type=offer';
		if (filter !== 'all') {
			url = url + '&' + filter;
		}
		if (q) {
			url = url + '&query=' + q;
		}
		this.buySellService.getClassifieds(url).subscribe((data) => {
			this.classifieds = data.objects.map((object) => new ClassiFied(object));
			if (data.next) {
				this.nextUrl = data.next.split('alpha')[1];
			} else {
				this.nextUrl = '';
			}
			this.loadingGif = 'none';
		});
	}

	public filterBuySell(value): void {
		this.filter = { value: value };
	}

	public paginate(): void {
		if (this.nextUrl) {
			this.paginating = true;
			this.showSpin = true;
			this.buySellService.getClassifieds(this.nextUrl).subscribe((classifieds) => {
				this.paginating = false;
				if (classifieds.next) {
					this.nextUrl = classifieds.next.split('alpha')[1];
				} else {
					this.nextUrl = '';
				}
				this.classifieds = this.classifieds.concat(classifieds.objects.map((object) => new ClassiFied(object)));
				this.showSpin = false;
			});
		}
	}

	public enableCategories(): void {
		this.showCategories = !this.showCategories;
	}
	public enableCondition(): void {
		this.showCondition = !this.showCondition;
	}
	public enableChannel(): void {
		this.showChannel = !this.showChannel;
	}

	// showPostCreateForm() {
	//   if (this.showPostCreate) {
	//     this.layoutService.hideBd();
	//     this.showPostCreate = false;
	//   } else {
	//     this.showPostCreate = true;
	//     this.layoutService.showBd('post');
	//   }
	// }

	/**
	 * Function to check if user has manager cp
	 * 1. Check if local storage contains manager
	 * 2. Set manager uid and display my team filter
	 */
	private checkIfManager(): void {
		if (localStorage.getItem('manager')) {
			const obj = JSON.parse(localStorage.getItem('manager'));
			this.showMyTeam = true;
			this.myTeamUid = obj.uid;
		}
	}

	public activatePostCreate(): void {
		const scrollToTop = window.setInterval(() => {
			const position = window.pageYOffset;

			if (position > 0) {
				window.scrollTo(0, position - 50);
			} else {
				window.clearInterval(scrollToTop);
			}
		}, 3);

		this.showPostCreateForm = !this.showPostCreateForm;
		if (this.showPostCreateForm) {
			this.layoutService.showBd('event-post-form');
			this.postType = 'buy-sell';
		} else {
			this.postType = '';
			this.layoutService.hideBd('event-post-form');
		}
	}

	private getClassifieds(): void {
		this.loadingGif = 'block';
		this.buySellService.getClassifieds('/networks/' + this.network.uid + '/posts/?type=offer').subscribe((data) => {
			this.classifieds = data.objects.map((object) => new ClassiFied(object));
			if (data.next) {
				this.nextUrl = data.next.split('alpha')[1];
				this.paginate();
			} else {
				this.nextUrl = '';
			}
			this.loadingGif = 'none';
		});
	}
}
