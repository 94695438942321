import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NetworkService, MemberService, ChannelService, UserService } from '../../shared';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';
import { ClipboardService } from 'projects/peer-core/src/lib/services/ui/clipboard.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-channel-detail',
	templateUrl: './channel-detail.component.html',
	styleUrls: ['./channel-detail.component.css'],
})
export class ChannelDetailComponent implements OnInit, OnDestroy {
	public privacy = {};
	public showMute = true;
	public channel_id;
	public muteId;
	public channel: any;
	public postUrl: any;
	public showMembers = false;
	public members: any[] = [];
	public next_url = '';
	public submittedRequest = false;
	public showDeleteConfirmation = 'none';
	public showEditConfirmationModal = 'none';
	public showLeaveConfirmation = 'none';
	private dummy_channel;
	private network_id;
	private networkSubscriber;
	private currentNetSub;
	private paramsSubscriber;
	private checkAccessibilitySub;
	public network;
	private app_url;
	private store_url;
	private is_member = null;
	public currentUser;
	public currentUserUsername;
	private channelSubscriber;
	private authSubscriber;
	//
	public displayMemberList = false;
	public totalMembersCount = 0;
	public networkLoaded;
	public memberData;
	public hideMembers = false;
	public roles;
	public searchRole = 'all';
	public memberList: any[] = [];
	public memberSearchString;
	public channelMembers: any[] = [];
	public availabilityStatus: any[] = [];
	public status: any[] = [];
	public renewalFrequency: any[] = [];
	public trialPeriod: any[] = [];
	public membershipLevels: any[] = [];
	public filter = { key: null, value: null };
	public showSelectByLevel = false;
	public subscriptionTemplates;
	public memberString;
	public isLoading = false;
	public onSearching = true;
	public showEditOptions = true;
	public showAddMembers = false;
	public showMembershipFilter = false;
	public display_text = false;
	public channelModerator;
	public nextMembersUrl;
	private subscription: Subscription = new Subscription();
	public externalChannel = false;
	public isExternalUser = false;
	public isSpining = 0;

	public view = 'detail';
	public hideButton = false;
	public isMembersFetched = 0;
	private subject: Subject<string> = new Subject();
	public clearSearch = false;
	public searchString;
	public initialMembers = [];
	public removedMembers = [];
	public isArchived = false;

	public confirmModal?: NzModalRef;

	constructor(
		private activatedRoute: ActivatedRoute,
		private networkService: NetworkService,
		private router: Router,
		private translate: TranslateService,
		private location: Location,
		private memberService: MemberService,
		private channelService: ChannelService,
		private userService: UserService,
		private pageLoadingUiService: PageLoadingUiService,
		private clipboardService: ClipboardService,
		private nzModalService: NzModalService,
		private translateService: TranslateService
	) { }

	ngOnInit(): void {
		this.paramsSubscriber = this.activatedRoute.queryParams.subscribe((params) => {
			if (params['archived']) {
				this.isArchived = true;
			} else {
				this.isArchived = false;
			}
			this.authSubscriber = this.userService.isAuthenticated.subscribe((authenticated) => {
				if (!authenticated) {
					localStorage.setItem('pb-prev-url', this.router.url);
					this.router.navigateByUrl('/authentication/login');
				} else {
					this.currentUser = this.userService.getCurrentUser();
					this.currentUserUsername = this.currentUser.username;
					const channelId = params['id'];
					this.channel_id = params['id'];

					if (channelId) {
						this.channelSubscriber = this.networkService?.getChannel(channelId, this.isArchived)?.subscribe((data) => {
							if (data.access === 4) {
								this.externalChannel = true;
							} else {
								this.externalChannel = false;
							}
							this.channel = data;
							this.getMembersList();

							if (data['requestCount'] && data['requestCount'] > 0) {
							}

							if (params['external']) {
								this.isExternalUser = true;
								if ((!this.channel['membership'] && !this.channel['membership']['state']) || (this.channel['membership'] && !this.channel['membership']['state'])) {
									this.onJoinClick();
								}
							}

							const obj = {
								name: data.name,
								uid: data.uid,
							};
							this.privacy['channel'] = obj;

							if (data.muted) {
								this.showMute = false;
								this.muteId = data.muted;
							}
							this.dummy_channel = data;
							this.network_id = data['networkUid'];

							this.is_member = this.userService.memberships.filter((am) => am.network.uid === this.network_id);

							this.networkService.populateNetworkBasedOnId(this.network_id);
							if (this.isArchived) {
								this.postUrl = '/networks/' + data['networkUid'] + '/posts/?archived=true&channel=' + params['id'] + '&username=' + localStorage.getItem('uid');
							} else {
								this.postUrl = '/networks/' + data['networkUid'] + '/posts/?channel=' + params['id'] + '&username=' + localStorage.getItem('uid');
							}

							this.networkService.currentNetwork.subscribe((network) => {
								this.network = network;
								this.roles = network.roles;
								if (this.network.membership && this.network.membership.memberType) {
									if (this.network.membership.memberType === 'admin') {
										this.showMembershipFilter = true;
									}
									if (network['membership']['memberRole'] && network['membership']['memberRole']['role'].slug === 'external') {
										this.isExternalUser = true;
									}
								}
							});

							this.networkService.getSelectByStatusRenewalFrequency().subscribe((res) => {
								this.status = res.status;
								this.renewalFrequency = res.renewalStatus;
								this.trialPeriod = res.trialPeriod;
							});

							if (this.network.availabilityStatusChoices && this.network.availabilityStatusChoices.length > 0) {
								this.availabilityStatus = this.network.availabilityStatusChoices;
							}

							this.subscriptionTemplates = this.network.SubscriptionTemplates;
							if (this.subscriptionTemplates && this.subscriptionTemplates.length > 0) {
								this.showSelectByLevel = true;
								this.subscriptionTemplates.forEach((element) => {
									if (element.cpEnabled === true) {
										this.membershipLevels.push(element);
									}
								});
							}
						});
					}

					this.subject.pipe(debounceTime(350)).subscribe((searchTextValue) => {
						this.onSearching = false;
						this.isMembersFetched = 0;
						this.channelMembers = [];
						if (this.network && this.network['uid']) {
							this.memberService.getMembers(this.network.uid, '', 'query=' + searchTextValue).subscribe((data) => {
								this.isMembersFetched = 1;
								data.objects.forEach((member, index) => {
									if (member.user.username === this.channelModerator.user.username) {
										data.objects.splice(index, 1);
									}
								});
								this.channelMembers = data.objects;
								if (data.next) {
									this.nextMembersUrl = data.next.split('alpha')[1];
								} else {
									this.nextMembersUrl = '';
								}
								this.setCurrentChannelMembers();
							});
						}
					});
				}
			});
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
		if (this.currentNetSub) {
			this.currentNetSub.unsubscribe();
		}
		if (this.networkSubscriber) {
			this.networkSubscriber.unsubscribe();
		}
		if (this.paramsSubscriber) {
			this.paramsSubscriber.unsubscribe();
		}
		if (this.checkAccessibilitySub) {
			this.checkAccessibilitySub.unsubscribe();
		}
		if (this.channelSubscriber) {
			this.channelSubscriber.unsubscribe();
		}
		if (this.authSubscriber) {
			this.authSubscriber.unsubscribe();
		}
	}

	private checkAccessAndRedirect(is_member): void {
		this.checkAccessibilitySub = this.channelService
			.checkAccessibility({
				channelId: this.dummy_channel.uid,
				memberId: is_member[0].uid,
			})
			.subscribe((data) => {
				if (Object.keys(data).length) {
					this.channel = this.dummy_channel;
				} else {
					this.router.navigateByUrl('/network');
				}
			});
	}

	public onLeaveClick(): void {
		this.showLeaveConfirmation = 'block';
		// window.location.reload();
	}

	public onConfirmLeaveChannel(): void {
		this.pageLoadingUiService.setPageLoading(true);
		// eslint-disable-next-line spellcheck/spell-checker
		this.networkService.leavechannel(this.channel.uid, this.channel.membership.uid).subscribe(() => {
			this.pageLoadingUiService.setPageLoading(false);
			sessionStorage.setItem('refresh-channel-list', '1');
			this.router.navigate(['/network/channel']);
		});
	}

	/**
	 * 1.Update network object after user joins channel
	 */
	public onJoinClick(): void {
		this.isSpining = 1;
		this.networkService.joinChannel(this.channel.uid).subscribe((data) => {
			sessionStorage.setItem('refresh-channel-list', '1');
			this.isSpining = 0;
			if (data['state']) {
				this.channel.membership = {};
				this.channel.membership['state'] = 1;
				this.channel.membership['uid'] = data['uid'];
				this.channel.memberCount++;
			} else {
				this.channel.membership = {};
				this.channel.membership['uid'] = data['uid'];
				this.submittedRequest = true;
			}
			this.networkService.populate(); // 1
		});
	}

	public paginate(): void {
		if (this.next_url) {
			this.memberService.paginate(this.next_url).subscribe((data) => {
				this.members = this.members.concat(data.objects);
				if (data.next) {
					this.next_url = data.next ? data.next.split('alpha')[1] : '';

					this.paginate();
				} else {
					this.next_url = '';
				}
			});
		}
	}
	/**
	 * To show delete confirmation.
	 */
	public onClickDelete(): void {
		this.showDeleteConfirmation = 'block';
	}
	/**
	 * To hide delete confirmation.
	 */
	// eslint-disable-next-line spellcheck/spell-checker
	public onHideDeleteConfirmation(): void {
		this.showDeleteConfirmation = 'none';
	}
	/**
	 * Function to show call delete api.
	 */
	public onDeleteConfirm(): void {
		this.pageLoadingUiService?.setPageLoading(true);
		this.channelService
			?.deleteChannel(this.channel?.uid)
			.subscribe(
				() => {
					sessionStorage.setItem('value', 'deleteChannel');
					sessionStorage.setItem('refresh-channel-list', '1');
					this.onHideDeleteConfirmation();
					this.networkService?.populate();
					this.router.navigateByUrl('/network/channel');
				},
				(error: any) => {
					this.confirmModal = this.nzModalService.error({
						nzTitle: this.translateService.instant('Error'),
						nzContent: error?.errors?.error?.detail,
						nzOnOk: () => { },
					});
				}
			)
			.add(() => {
				this.pageLoadingUiService?.setPageLoading(false);
			});
	}
	/**
	 * To Show the edit confirmation modal.
	 */
	public onClickEdit(): void {
		this.showEditConfirmationModal = 'block';
	}
	/**
	 * Function to hide the delete confirmation modal.
	 */
	public onHideEditConfirmation(): void {
		this.showEditConfirmationModal = 'none';
	}
	/**
	 * Function to redirect to the edit page.
	 */
	public onConfirmEdit(): void {
		const url = `/network/channel-edit?id=${this.channel.uid}`;
		this.router.navigateByUrl(url);
	}

	// Function for mute channel
	public onMuteClick(): void {
		this.pageLoadingUiService.setPageLoading(true);
		this.networkService.muteContents(this.channel_id).subscribe(() => {
			this.showMute = false;
			this.pageLoadingUiService.setPageLoading(false);
		});
	}

	// Function for unmute channel
	public onUnMuteClick(): void {
		this.pageLoadingUiService.setPageLoading(true);
		this.networkService.unMuteContents(this.muteId).subscribe(
			() => {
				this.showMute = true;
				this.pageLoadingUiService.setPageLoading(false);
			},
			() => {
				this.showMute = true;
				this.pageLoadingUiService.setPageLoading(false);
			}
		);
	}

	private purgeMemberList(): void {
		const pointer = this;
		const newArray = this.channelMembers.filter(function (member) {
			return pointer.memberList.includes(member);
		});
		this.channelMembers = newArray;
	}

	// Function for searching a member in channel
	public onSearchMembers(): void {
		this.onSearching = false;
		this.isMembersFetched = 0;
		this.purgeMemberList();
		if (this.memberSearchString || this.memberSearchString === '') {
			this.memberService.getMembers(this.network.uid, '', 'query=' + this.memberSearchString).subscribe((data) => {
				this.isMembersFetched = 1;

				data.objects.forEach((member, index) => {
					if (member.user.username === this.channelModerator.user.username) {
						data.objects.splice(index, 1);
					}
				});
				data.objects.forEach((element) => {
					if (!this.memberList.includes(element)) {
						this.onSearching = true;
						this.channelMembers.push(element);
					}
				});
				this.totalMembersCount = data.count;
				if (data.next) {
					this.nextMembersUrl = data.next.split('alpha')[1];
				} else {
					this.nextMembersUrl = '';
				}
				this.setCurrentChannelMembers();
			});
		}
	}

	// Function to make a channel member as admin
	public makeChannelMemberAdmin(member, action): void {
		const obj = {
			action: action,
			username: this.currentUser.username,
		};
		this.pageLoadingUiService.setPageLoading(true);
		this.memberService.makeChannelAdmin(this.channel_id, member.channelMemberUid, obj).subscribe(() => {
			// eslint-disable-next-line spellcheck/spell-checker
			this.networkService.getchannelMembers(this.channel_id, this.isArchived).subscribe((res) => {
				this.members = res.objects;
			});
			this.pageLoadingUiService.setPageLoading(false);
		});
	}

	/**
	 * Function for filtering members
	 * @param key
	 * @param value
	 */
	public filterMembers(key = 'all', value = ''): void {
		this.display_text = false;
		this.isLoading = false;
		this.isMembersFetched = 0;
		this.channelMembers = [];
		this.filter.key = key;
		this.filter.value = value;
		this.memberService.filter = this.filter;
		let filterString;

		if (key === 'all') {
			filterString = '';
		} else {
			filterString = this.filter.key + '=' + this.filter.value;
		}

		this.memberService.getMembers(this.network.uid, '', filterString).subscribe((data) => {
			this.isLoading = true;
			this.isMembersFetched = 1;
			if (data.objects.length > 0) {
				data.objects.forEach((member, index) => {
					if (member.user.username === this.channelModerator.user.username) {
						data.objects.splice(index, 1);
					}
				});
				data.objects.forEach((element) => {
					if (!this.memberList.includes(element)) {
						this.channelMembers.push(element);
					}
				});
				this.totalMembersCount = data.count;
				if (data.next) {
					this.nextMembersUrl = data.next.split('alpha')[1];
				} else {
					this.nextMembersUrl = '';
				}
			} else {
				this.display_text = true;
			}

			this.setCurrentChannelMembers();
		});
	}

	public deleteChannelMember(username): void {
		this.pageLoadingUiService.setPageLoading(true);
		this.memberService.deleteChannelMember(this.channel.uid, username).subscribe(() => {
			// eslint-disable-next-line spellcheck/spell-checker
			this.networkService.getchannelMembers(this.channel_id, this.isArchived).subscribe((res) => {
				this.members = res.objects;
			});
			this.pageLoadingUiService.setPageLoading(false);
		});
	}

	public handleChannelView(): void {
		if (this.view === 'detail') {
			this.view = 'members-list';
			this.getMembersList();
			if (this.channel && this.channel['membership']) {
				// if (this.channel['membership']['channelMemberType'] === 'admin') {
				if (this.channel['membership']['channelMemberType'] === 'admin' && !this.isArchived) {
					this.hideButton = false;
				} else {
					this.hideButton = true;
				}
			} else {
				this.hideButton = true;
			}
		} else if (this.view === 'members-list') {
			this.view = 'add-members';
			this.hideButton = true;
			this.clearSearchLists();
			this.getNetworkMembers();
		}
	}

	public handleBack(): void {
		this.hideButton = false;
		if (this.view === 'detail') {
			this.location.back();
		} else if (this.view === 'members-list') {
			this.view = 'detail';
		} else if (this.view === 'add-members') {
			this.view = 'members-list';
			this.hideButton = false;
			this.getMembersList();
		} else if (this.view === 'pending') {
			this.view = 'detail';
			this.hideButton = false;
		}
	}

	/**
	 * For getting members in a channel
	 */
	private getMembersList(): void {
		this.members = [];
		this.isMembersFetched = 0;
		// eslint-disable-next-line spellcheck/spell-checker
		this.networkService.getchannelMembers(this.channel.uid, this.isArchived).subscribe((data) => {
			this.isMembersFetched = 1;
			this.members = data.objects;
			this.members.forEach((member) => {
				if (member.user.username === this.currentUser['username']) {
					if (member.channelMemberType === 'admin') {
						this.channelModerator = member;
					}
				}
			});

			if (this.channelModerator) {
				this.members.forEach((member) => {
					if (member.user.username !== this.channelModerator.user.username) {
						this.initialMembers.push(member);
					}
				});
			}

			if (data.next) {
				this.next_url = data.next ? data.next.split('alpha')[1] : '';
				this.paginate();
			} else {
				this.next_url = '';
			}
		});
	}

	private getNetworkMembers(): void {
		this.channelMembers = [];
		this.isMembersFetched = 0;
		this.memberService.getMembers(this.network.uid, this.nextMembersUrl).subscribe((data) => {
			this.isMembersFetched = 1;

			data.objects.forEach((member, index) => {
				if (member.user.username === this.channelModerator.user.username) {
					data.objects.splice(index, 1);
				}
			});

			data.objects.forEach((member, index) => {
				if (!member.state) {
					data.objects.splice(index, 1);
				}
			});

			data.objects.forEach((element) => {
				this.channelMembers.push(element);
			});

			if (data.next) {
				this.nextMembersUrl = data.next.split('alpha')[1];
			} else {
				this.nextMembersUrl = '';
			}

			this.setCurrentChannelMembers();
		});
	}

	public paginateMembers(): void {
		if (this.nextMembersUrl) {
			this.userService.paginate(this.nextMembersUrl).subscribe((data) => {
				this.channelMembers = this.channelMembers.concat(data.objects);
				this.setCurrentChannelMembers();
				if (data.next) {
					this.nextMembersUrl = data.next.split('alpha')[1];
				} else {
					this.nextMembersUrl = '';
				}
			});
		}
	}

	private setCurrentChannelMembers(): void {
		this.members.forEach((member) => {
			this.channelMembers.forEach((channelMember) => {
				if (channelMember.user.username === member.user.username) {
					channelMember['selected'] = true;
					this.setMembers(member);
				}
			});
		});
	}

	// Function for adding members from members list for a channel
	private setMembers(member): void {
		const index = this.memberList.findIndex((m) => m['user']['username'] === member['user']['username']);
		if (index === -1) {
			this.memberList.push(member);
		}
		// else {
		//   this.memberList.splice(index, 1);
		// }
	}

	public onClickMembers(member): void {
		if (member['selected']) {
			member['selected'] = false;
		} else {
			member['selected'] = true;
		}
		const index = this.memberList.findIndex((m) => m['user']['username'] === member['user']['username']);
		if (index === -1) {
			this.memberList.push(member);
		} else {
			const indexOfUser = this.initialMembers.findIndex((a) => a['user']['username'] === member['user']['username']);
			if (indexOfUser > -1) {
				this.removedMembers.push(member);
			}
			this.memberList.splice(index, 1);
		}
	}

	public onSaveClick(): void {
		this.pageLoadingUiService.setPageLoading(true);
		if (this.memberList.length > 0) {
			this.onAddMembers();
		}
		if (this.removedMembers.length > 0) {
			this.onRemoveMembers();
		}
	}

	public onAddMembers(): void {
		const self = this;
		this.memberString = '';
		this.memberList.forEach(function (member) {
			if (self.memberString === '') {
				self.memberString = member.user.username;
			} else {
				self.memberString += '|' + member.user.username;
			}
		});
		const postData = { user: this.memberString };
		this.networkService.addMembers(postData, this.channel['uid']).subscribe(() => {
			this.reset();
			this.pageLoadingUiService.setPageLoading(false);
		});
	}

	public onRemoveMembers(): void {
		const self = this;
		this.memberString = '';
		this.removedMembers.forEach(function (member) {
			if (self.memberString === '') {
				self.memberString = member.user.username;
			} else {
				self.memberString += '|' + member.user.username;
			}
		});
		const postData = { delete_users: this.memberString };
		this.networkService.addMembers(postData, this.channel['uid']).subscribe(() => {
			this.reset();
			this.pageLoadingUiService.setPageLoading(false);
		});
	}

	public reset(): void {
		this.memberString = '';
		this.initialMembers = [];
		this.memberList = [];
		this.removedMembers = [];
		this.view = 'members-list';
		this.hideButton = false;
		this.getMembersList();
	}

	public setSubject(e): void {
		if (e.target.value) {
			this.clearSearch = true;
			const text = e.target.value;
			this.subject.next(text);
		} else {
			this.clearSearch = false;
		}
	}

	public clearSearchLists(): void {
		this.clearSearch = false;
		this.subject.next('');
		this.searchString = '';
	}

	public displayPendingMembers(): void {
		this.view = 'pending';
		this.hideButton = true;
		this.members = [];
		this.isMembersFetched = 0;
		this.channelService.getPendingChannelRequests(this.channel.uid).subscribe((data) => {
			this.members = data.objects;
			this.isMembersFetched = 1;
		});
	}

	public onConfirmMembership(member, i): void {
		this.pageLoadingUiService.setPageLoading(true);
		this.channelService.handleChannelMembership(this.channel['uid'], member['uid']).subscribe(() => {
			this.getChannelDetails();
			this.members.splice(i, 1);
			this.pageLoadingUiService.setPageLoading(false);
		});
	}

	public onDeleteMembership(member, i): void {
		this.pageLoadingUiService.setPageLoading(true);
		this.channelService.deleteChannelMembership(this.channel['uid'], member['uid']).subscribe(() => {
			this.getChannelDetails();
			this.members.splice(i, 1);
			this.pageLoadingUiService.setPageLoading(false);
		});
	}

	public onCancelJoinRequest(): void {
		this.isSpining = 1;
		this.channelService.deleteChannelMembership(this.channel['uid'], this.channel['membership']['uid']).subscribe(() => {
			this.channel.membership = {};
			this.submittedRequest = false;
			this.isSpining = 0;
		});
	}

	private getChannelDetails(): void {
		this.networkService.getChannel(this.channel['uid'], this.isArchived).subscribe((data) => {
			this.channel = data;
		});
	}

	public handleArchive(state): void {
		const body = {};
		body['name'] = this.channel['name'];
		body['access'] = this.channel['access'];
		body['username'] = this.channel['moderator']['username'];
		if (state === 'archive') {
			body['archived'] = 'true';
		} else {
			body['archived'] = 'false';
		}
		if (this.channel['description']) {
			body['description'] = this.channel['description'];
		}
		if (this.channel['logo'] && this.channel['logo']['uid']) {
			body['logo'] = this.channel['logo']['uid'];
		}
		if (this.channel['contentPrivacy']) {
			body['content_privacy'] = JSON.stringify(this.channel['contentPrivacy']);
		} else if (this.channel['customPrivacy'] && this.channel['customPrivacy'].length > 0) {
			const customPrivacy: any[] = [];
			// let custom: any;
			this.channel.customPrivacy.forEach((element) => {
				if (element.userId) {
					customPrivacy.push(element.userId);
				} else {
					customPrivacy.push(element.id);
				}
			});
			const custom = '[' + customPrivacy.join(',') + ']';
			body['custom_privacy'] = custom;
		}

		this.pageLoadingUiService.setPageLoading(true);
		this.channelService.updateChannelDetails(this.channel['uid'], body).subscribe(
			() => {
				this.pageLoadingUiService.setPageLoading(false);
				this.location.back();
			},
			() => { }
		);
	}

	public onClickEditMember(): void {
		this.view = 'add-members';
		this.hideButton = true;
		this.clearSearchLists();
		this.getNetworkMembers();
	}

	// #region UI Interactions

	public handleLinkShare(link: any): void {
		const shareUrl = link?.shareUrl ? `${environment.appURL}?share_url=${link?.shareUrl}&nid=${link?.uid}` : '';

		this.clipboardService.copy(
			this.translate.instant('channelInvite', {
				username: this.currentUser.name,
				channelName: this.channel.name,
				networkName: this.network.name,
				inviteLink: shareUrl,
			})
		);
	}

	// #endregion
}
