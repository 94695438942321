import { Component, OnInit } from '@angular/core';
import { NetworkService, PostService, UserService, Reminder, LayoutService } from '../../shared';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { ToastService } from '../../shared/services/common/toast.service';
import { ClipboardService } from 'projects/peer-core/src/lib/services/ui/clipboard.service';

@Component({
	selector: 'app-customer-detail',
	templateUrl: './customer-detail.component.html',
	styleUrls: ['./customer-detail.component.css'],
})
export class CustomerDetailComponent implements OnInit {
	public loadingGif = 'none';
	public customerDetail;
	public isEditReminder = false;
	public selectedImages: any[] = [];
	public showComments = false;
	public currentUser;
	public network;
	public deleteCustomerModal = 'none';
	public showContactDetails = false;
	public editCustomer;
	public customerUid;
	public isLoading = false;
	public networkLoaded;
	public reminderOptions: any[] = [];
	public displayReminderModal = 'none';
	public reminderTime;
	public remindersSelected = [];
	public model = new Reminder();
	public reminderToast = false;
	public timeFor = 0;

	public countries = [
		{ name: 'Afghanistan', code: 'AF' },
		{ name: 'land Islands', code: 'AX' },
		{ name: 'Albania', code: 'AL' },
		{ name: 'Algeria', code: 'DZ' },
		{ name: 'American Samoa', code: 'AS' },
		// eslint-disable-next-line spellcheck/spell-checker
		{ name: 'AndorrA', code: 'AD' },
		{ name: 'Angola', code: 'AO' },
		{ name: 'Anguilla', code: 'AI' },
		{ name: 'Antarctica', code: 'AQ' },
		{ name: 'Antigua and Barbuda', code: 'AG' },
		{ name: 'Argentina', code: 'AR' },
		{ name: 'Armenia', code: 'AM' },
		{ name: 'Aruba', code: 'AW' },
		{ name: 'Australia', code: 'AU' },
		{ name: 'Austria', code: 'AT' },
		{ name: 'Azerbaijan', code: 'AZ' },
		{ name: 'Bahamas', code: 'BS' },
		{ name: 'Bahrain', code: 'BH' },
		{ name: 'Bangladesh', code: 'BD' },
		{ name: 'Barbados', code: 'BB' },
		{ name: 'Belarus', code: 'BY' },
		{ name: 'Belgium', code: 'BE' },
		{ name: 'Belize', code: 'BZ' },
		{ name: 'Benin', code: 'BJ' },
		{ name: 'Bermuda', code: 'BM' },
		{ name: 'Bhutan', code: 'BT' },
		{ name: 'Bolivia', code: 'BO' },
		{ name: 'Bosnia and Herzegovina', code: 'BA' },
		{ name: 'Botswana', code: 'BW' },
		// eslint-disable-next-line spellcheck/spell-checker
		{ name: 'Bouvet Island', code: 'BV' },
		{ name: 'Brazil', code: 'BR' },
		{ name: 'British Indian Ocean Territory', code: 'IO' },
		// eslint-disable-next-line spellcheck/spell-checker
		{ name: 'Brunei Darussalam', code: 'BN' },
		{ name: 'Bulgaria', code: 'BG' },
		// eslint-disable-next-line spellcheck/spell-checker
		{ name: 'Burkina Faso', code: 'BF' },
		{ name: 'Burundi', code: 'BI' },
		{ name: 'Cambodia', code: 'KH' },
		{ name: 'Cameroon', code: 'CM' },
		{ name: 'Canada', code: 'CA' },
		{ name: 'Cape Verde', code: 'CV' },
		{ name: 'Cayman Islands', code: 'KY' },
		{ name: 'Central African Republic', code: 'CF' },
		{ name: 'Chad', code: 'TD' },
		{ name: 'Chile', code: 'CL' },
		{ name: 'China', code: 'CN' },
		{ name: 'Christmas Island', code: 'CX' },
		{ name: 'Cocos (Keeling) Islands', code: 'CC' },
		{ name: 'Colombia', code: 'CO' },
		{ name: 'Comoros', code: 'KM' },
		{ name: 'Congo', code: 'CG' },
		{ name: 'Congo, The Democratic Republic of the', code: 'CD' },
		{ name: 'Cook Islands', code: 'CK' },
		// eslint-disable-next-line spellcheck/spell-checker
		{ name: 'Costa Rica', code: 'CR' },
		// eslint-disable-next-line spellcheck/spell-checker
		{ name: 'Cote D Ivoire', code: 'CI' },
		{ name: 'Croatia', code: 'HR' },
		{ name: 'Cuba', code: 'CU' },
		{ name: 'Cyprus', code: 'CY' },
		{ name: 'Czech Republic', code: 'CZ' },
		{ name: 'Denmark', code: 'DK' },
		{ name: 'Djibouti', code: 'DJ' },
		{ name: 'Dominica', code: 'DM' },
		{ name: 'Dominican Republic', code: 'DO' },
		{ name: 'Ecuador', code: 'EC' },
		{ name: 'Egypt', code: 'EG' },
		{ name: 'El Salvador', code: 'SV' },
		{ name: 'Equatorial Guinea', code: 'GQ' },
		{ name: 'Eritrea', code: 'ER' },
		{ name: 'Estonia', code: 'EE' },
		{ name: 'Ethiopia', code: 'ET' },
		// eslint-disable-next-line spellcheck/spell-checker
		{ name: 'Falkland Islands (Malvinas)', code: 'FK' },
		// eslint-disable-next-line spellcheck/spell-checker
		{ name: 'Faroe Islands', code: 'FO' },
		{ name: 'Fiji', code: 'FJ' },
		{ name: 'Finland', code: 'FI' },
		{ name: 'France', code: 'FR' },
		{ name: 'French Guiana', code: 'GF' },
		{ name: 'French Polynesia', code: 'PF' },
		{ name: 'French Southern Territories', code: 'TF' },
		{ name: 'Gabon', code: 'GA' },
		{ name: 'Gambia', code: 'GM' },
		{ name: 'Georgia', code: 'GE' },
		{ name: 'Germany', code: 'DE' },
		{ name: 'Ghana', code: 'GH' },
		{ name: 'Gibraltar', code: 'GI' },
		{ name: 'Greece', code: 'GR' },
		{ name: 'Greenland', code: 'GL' },
		{ name: 'Grenada', code: 'GD' },
		{ name: 'Guadeloupe', code: 'GP' },
		{ name: 'Guam', code: 'GU' },
		{ name: 'Guatemala', code: 'GT' },
		{ name: 'Guernsey', code: 'GG' },
		{ name: 'Guinea', code: 'GN' },
		{ name: 'Guinea-Bissau', code: 'GW' },
		{ name: 'Guyana', code: 'GY' },
		{ name: 'Haiti', code: 'HT' },
		// eslint-disable-next-line spellcheck/spell-checker
		{ name: 'Heard Island and Mcdonald Islands', code: 'HM' },
		{ name: 'Holy See (Vatican City State)', code: 'VA' },
		{ name: 'Honduras', code: 'HN' },
		{ name: 'Hong Kong', code: 'HK' },
		{ name: 'Hungary', code: 'HU' },
		{ name: 'Iceland', code: 'IS' },
		{ name: 'India', code: 'IN' },
		{ name: 'Indonesia', code: 'ID' },
		{ name: 'Iran, Islamic Republic Of', code: 'IR' },
		{ name: 'Iraq', code: 'IQ' },
		{ name: 'Ireland', code: 'IE' },
		{ name: 'Isle of Man', code: 'IM' },
		{ name: 'Israel', code: 'IL' },
		{ name: 'Italy', code: 'IT' },
		{ name: 'Jamaica', code: 'JM' },
		{ name: 'Japan', code: 'JP' },
		{ name: 'Jersey', code: 'JE' },
		{ name: 'Jordan', code: 'JO' },
		{ name: 'Kazakhstan', code: 'KZ' },
		{ name: 'Kenya', code: 'KE' },
		{ name: 'Kiribati', code: 'KI' },
		{ name: "Korea, Democratic People's Republic of", code: 'KP' },
		{ name: 'Korea, Republic of', code: 'KR' },
		{ name: 'Kuwait', code: 'KW' },
		{ name: 'Kyrgyzstan', code: 'KG' },
		{ name: "Lao People's Democratic Republic", code: 'LA' },
		{ name: 'Latvia', code: 'LV' },
		{ name: 'Lebanon', code: 'LB' },
		{ name: 'Lesotho', code: 'LS' },
		{ name: 'Liberia', code: 'LR' },
		// eslint-disable-next-line spellcheck/spell-checker
		{ name: 'Libyan Arab Jamahiriya', code: 'LY' },
		{ name: 'Liechtenstein', code: 'LI' },
		{ name: 'Lithuania', code: 'LT' },
		{ name: 'Luxembourg', code: 'LU' },
		{ name: 'Macao', code: 'MO' },
		{ name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
		{ name: 'Madagascar', code: 'MG' },
		{ name: 'Malawi', code: 'MW' },
		{ name: 'Malaysia', code: 'MY' },
		{ name: 'Maldives', code: 'MV' },
		{ name: 'Mali', code: 'ML' },
		{ name: 'Malta', code: 'MT' },
		{ name: 'Marshall Islands', code: 'MH' },
		{ name: 'Martinique', code: 'MQ' },
		{ name: 'Mauritania', code: 'MR' },
		{ name: 'Mauritius', code: 'MU' },
		// eslint-disable-next-line spellcheck/spell-checker
		{ name: 'Mayotte', code: 'YT' },
		{ name: 'Mexico', code: 'MX' },
		{ name: 'Micronesia, Federated States of', code: 'FM' },
		{ name: 'Moldova, Republic of', code: 'MD' },
		{ name: 'Monaco', code: 'MC' },
		{ name: 'Mongolia', code: 'MN' },
		{ name: 'Montenegro', code: 'ME' },
		{ name: 'Montserrat', code: 'MS' },
		{ name: 'Morocco', code: 'MA' },
		{ name: 'Mozambique', code: 'MZ' },
		{ name: 'Myanmar', code: 'MM' },
		{ name: 'Namibia', code: 'NA' },
		{ name: 'Nauru', code: 'NR' },
		{ name: 'Nepal', code: 'NP' },
		{ name: 'Netherlands', code: 'NL' },
		{ name: 'Netherlands Antilles', code: 'AN' },
		{ name: 'New Caledonia', code: 'NC' },
		{ name: 'New Zealand', code: 'NZ' },
		{ name: 'Nicaragua', code: 'NI' },
		{ name: 'Niger', code: 'NE' },
		{ name: 'Nigeria', code: 'NG' },
		// eslint-disable-next-line spellcheck/spell-checker
		{ name: 'Niue', code: 'NU' },
		{ name: 'Norfolk Island', code: 'NF' },
		{ name: 'Northern Mariana Islands', code: 'MP' },
		{ name: 'Norway', code: 'NO' },
		{ name: 'Oman', code: 'OM' },
		{ name: 'Pakistan', code: 'PK' },
		// eslint-disable-next-line spellcheck/spell-checker
		{ name: 'Palau', code: 'PW' },
		{ name: 'Palestinian Territory, Occupied', code: 'PS' },
		{ name: 'Panama', code: 'PA' },
		// eslint-disable-next-line spellcheck/spell-checker
		{ name: 'Papua New Guinea', code: 'PG' },
		{ name: 'Paraguay', code: 'PY' },
		{ name: 'Peru', code: 'PE' },
		{ name: 'Philippines', code: 'PH' },
		{ name: 'Pitcairn', code: 'PN' },
		{ name: 'Poland', code: 'PL' },
		{ name: 'Portugal', code: 'PT' },
		{ name: 'Puerto Rico', code: 'PR' },
		{ name: 'Qatar', code: 'QA' },
		{ name: 'Reunion', code: 'RE' },
		{ name: 'Romania', code: 'RO' },
		{ name: 'Russian Federation', code: 'RU' },
		{ name: 'RWANDA', code: 'RW' },
		{ name: 'Saint Helena', code: 'SH' },
		// eslint-disable-next-line spellcheck/spell-checker
		{ name: 'Saint Kitts and Nevis', code: 'KN' },
		{ name: 'Saint Lucia', code: 'LC' },
		// eslint-disable-next-line spellcheck/spell-checker
		{ name: 'Saint Pierre and Miquelon', code: 'PM' },
		{ name: 'Saint Vincent and the Grenadines', code: 'VC' },
		{ name: 'Samoa', code: 'WS' },
		// eslint-disable-next-line spellcheck/spell-checker
		{ name: 'San Marino', code: 'SM' },
		// eslint-disable-next-line spellcheck/spell-checker
		{ name: 'Sao Tome and Principe', code: 'ST' },
		{ name: 'Saudi Arabia', code: 'SA' },
		{ name: 'Senegal', code: 'SN' },
		{ name: 'Serbia', code: 'RS' },
		{ name: 'Seychelles', code: 'SC' },
		// eslint-disable-next-line spellcheck/spell-checker
		{ name: 'Sierra Leone', code: 'SL' },
		{ name: 'Singapore', code: 'SG' },
		{ name: 'Slovakia', code: 'SK' },
		{ name: 'Slovenia', code: 'SI' },
		{ name: 'Solomon Islands', code: 'SB' },
		{ name: 'Somalia', code: 'SO' },
		{ name: 'South Africa', code: 'ZA' },
		{ name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
		{ name: 'Spain', code: 'ES' },
		// eslint-disable-next-line spellcheck/spell-checker
		{ name: 'Sri Lanka', code: 'LK' },
		{ name: 'Sudan', code: 'SD' },
		{ name: 'Suriname', code: 'SR' },
		// eslint-disable-next-line spellcheck/spell-checker
		{ name: 'Svalbard and Jan Mayen', code: 'SJ' },
		{ name: 'Swaziland', code: 'SZ' },
		{ name: 'Sweden', code: 'SE' },
		{ name: 'Switzerland', code: 'CH' },
		{ name: 'Syrian Arab Republic', code: 'SY' },
		{ name: 'Taiwan, Province of China', code: 'TW' },
		{ name: 'Tajikistan', code: 'TJ' },
		{ name: 'Tanzania, United Republic of', code: 'TZ' },
		{ name: 'Thailand', code: 'TH' },
		// eslint-disable-next-line spellcheck/spell-checker
		{ name: 'Timor-Leste', code: 'TL' },
		{ name: 'Togo', code: 'TG' },
		// eslint-disable-next-line spellcheck/spell-checker
		{ name: 'Tokelau', code: 'TK' },
		{ name: 'Tonga', code: 'TO' },
		{ name: 'Trinidad and Tobago', code: 'TT' },
		{ name: 'Tunisia', code: 'TN' },
		{ name: 'Turkey', code: 'TR' },
		{ name: 'Turkmenistan', code: 'TM' },
		// eslint-disable-next-line spellcheck/spell-checker
		{ name: 'Turks and Caicos Islands', code: 'TC' },
		{ name: 'Tuvalu', code: 'TV' },
		{ name: 'Uganda', code: 'UG' },
		{ name: 'Ukraine', code: 'UA' },
		{ name: 'United Arab Emirates', code: 'AE' },
		{ name: 'United Kingdom', code: 'GB' },
		{ name: 'United States', code: 'US' },
		{ name: 'United States Minor Outlying Islands', code: 'UM' },
		{ name: 'Uruguay', code: 'UY' },
		{ name: 'Uzbekistan', code: 'UZ' },
		{ name: 'Vanuatu', code: 'VU' },
		{ name: 'Venezuela', code: 'VE' },
		// eslint-disable-next-line spellcheck/spell-checker
		{ name: 'Viet Nam', code: 'VN' },
		{ name: 'Virgin Islands, British', code: 'VG' },
		{ name: 'Virgin Islands, U.S.', code: 'VI' },
		// eslint-disable-next-line spellcheck/spell-checker
		{ name: 'Wallis and Futuna', code: 'WF' },
		{ name: 'Western Sahara', code: 'EH' },
		{ name: 'Yemen', code: 'YE' },
		{ name: 'Zambia', code: 'ZM' },
		{ name: 'Zimbabwe', code: 'ZW' },
	];

	public postType: 'add-issue' | 'add-task' | '' = '';

	constructor(
		private router: Router,
		private translate: TranslateService,
		private networkService: NetworkService,
		private activatedRoute: ActivatedRoute,
		private userService: UserService,
		private postService: PostService,
		private _location: Location,
		private layoutService: LayoutService,
		private toastService: ToastService,
		private clipboardService: ClipboardService
	) {}

	ngOnInit(): void {
		this.activatedRoute.params.subscribe((params) => {
			this.customerUid = params.id;
			this.getCustomersDetail();
		});

		this.networkService.networkReady.subscribe((networkLoaded) => {
			this.networkLoaded = networkLoaded;
			if (networkLoaded) {
				this.networkService.currentNetwork.subscribe((network) => {
					this.network = network;
				});
				this.userService.currentUser.subscribe((userData) => {
					this.currentUser = userData;
				});
			}
		});
	}

	public getCustomersDetail(): void {
		this.isLoading = false;
		this.showComments = false;
		this.networkService.getCustomerDetails(this.customerUid).subscribe(
			(data) => {
				this.isLoading = true;
				data['type'] = 'customer';
				this.customerDetail = data;
				this.checkUserMentionAndHashtagAndAlterBody();
				this.showComments = true;
				if (this.customerDetail['address'] && this.customerDetail['address']['countryCode']) {
					const code = this.customerDetail['address']['countryCode'];
					this.setCountry(code);
				}
			},
			() => {
				this.isLoading = true;
			}
		);
	}

	/**
	 * Function to check the user mention and hash tag in post list if exists change the post body
	 */
	private checkUserMentionAndHashtagAndAlterBody(): void {
		if (this.customerDetail['title'] && this.customerDetail['title'].indexOf('<') > -1) {
			// 1
			this.customerDetail['title'] = this.customerDetail['title'].replace(/</g, '< ');
		}

		this.customerDetail['altered_title'] = this.customerDetail['title'];
		if (this.customerDetail['mentionedUsers']) {
			this.customerDetail['mentionedUsers'].forEach((mentionedUser) => {
				const html = '<b #userMention><a class="text-green">@' + mentionedUser['name'] + '</a></b>';
				if (this.customerDetail.altered_title) {
					this.customerDetail['altered_title'] = this.customerDetail.altered_title.replace('@' + mentionedUser['name'], html);
				}
			});
		}
		if (this.customerDetail['hashtags']) {
			this.customerDetail['hashtags'].forEach((hashtag) => {
				if (hashtag['name']) {
					const html = '<b #hashTag><a class="hash-customer">#' + hashtag['name'] + '</a></b>';
					if (this.customerDetail.altered_title) {
						this.customerDetail['altered_title'] = this.customerDetail.altered_title.replace('#' + hashtag['name'], html);
					}
				}
			});
		}
	}

	/** 31-05-21,customer hashtags handling
	 * Manage redirection to customer detail page
	 * @param e
	 * @param uid
	 */
	public handleRedirection(e): void {
		if (e.target && e.target.className) {
			const type = e.target.className;
			if (type === 'hash-customer') {
				const text = e.target.innerHTML.split('#')[1];
				const hashtags = this.customerDetail['hashtags'];
				hashtags.forEach((tag) => {
					if (tag['name'] === text) {
						this.router.navigateByUrl('/network/customers?hash_tag=' + tag['uid'] + '&name=' + tag['name']);
					}
				});
			}
		}
	}

	public deleteCustomer(): void {
		this.loadingGif = 'block';
		this.networkService.deleteCustomer(this.customerDetail['uid']).subscribe(() => {
			this.deleteCustomerModal = 'none';
			this.loadingGif = 'none';
			this._location.back();
		});
	}

	public onEditCustomer(): void {
		this.editCustomer = this.customerDetail;
	}

	public onCreateCustomerTask(): void {
		const customer = this.customerDetail;
		if (customer['type'] === 'customer') {
			customer['postType'] = 'customer';
			customer['type'] = 'postTodo';
		}
		this.editCustomer = customer;
	}

	/**
	 * Function to create a reminder based on reminder option selected
	 */
	// createRemainder() {
	//   if (!this.reminder['value']) {

	//   }
	//   else {
	//     let option = this.reminder['value'];
	//     this.loadingGif = 'block';
	//     if (option === 'in_twenty_minutes') {
	//       this.reminderMessage = this.translate.instant('A reminder is set for 20 minutes');
	//       this.timeForReminder = 20;
	//     } else if (option === 'in_one_hour') {
	//       this.reminderMessage = this.translate.instant('A reminder is set for 1 hour');
	//       this.timeForReminder = 60;
	//     } else if (option === 'in_three_hours') {
	//       this.reminderMessage = this.translate.instant('A reminder is set for 3 hours');
	//       this.timeForReminder = 180;
	//     } else if (option === 'tomorrow') {
	//       this.reminderMessage = this.translate.instant('A reminder is set for tomorrow');
	//       this.timeForReminder = this.minutesUntilMidnight() + 540;
	//     } else {
	//       this.reminderMessage = this.translate.instant('A reminder is set for next week');
	//       this.timeForReminder = 6300;
	//     }

	//     var date = new Date();
	//     date.setMinutes(date.getMinutes() + this.timeForReminder);
	//     var month = date.getUTCMonth() + 1; //months from 1-12
	//     var day = date.getUTCDate();
	//     var year = date.getUTCFullYear();
	//     var hours = date.getUTCHours();
	//     var minutes = date.getUTCMinutes();
	//     var seconds = date.getUTCSeconds();
	//     var utcDate = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
	//     this.reminderTime = utcDate;

	//     this.reminderObject.username = this.currentUser.username;
	//     this.reminderObject.contentUid = this.customerDetail.uid;
	//     this.reminderObject.contentType = 'customer';
	//     //this.reminderObject.reminderOption = option;
	//     this.reminderObject.repeat = true;
	//     this.reminderObject['reminder'] = this.reminderTime;
	//     this.networkService.createReminder(this.reminderObject).subscribe((data) => {
	//       this.timeForReminder = 0;
	//       this.reminder.value = '';
	//       this.displayReminderModal = 'none';
	//
	//       this.display_toast = 'block';
	//       this.customerDetail['myRelations']['reminder'] = data.uid;
	//       this.closeReminderToastModal();
	//     });

	//     if (!this.isEditReminder) {
	//       this.networkService.createReminder(this.reminderObject).subscribe((data) => {
	//         this.customerDetail['myRelations']['reminder'] = data.uid;
	//         this.timeForReminder = 0;
	//         this.reminder.value = '';
	//         this.displayReminderModal = 'none';
	//         this.loadingGif = 'none';
	//         this.display_toast = 'block';
	//         this.closeReminderToastModal();
	//       });
	//     } else {
	//       this.networkService.editRemainder(this.reminderObject, this.customerDetail.myRelations.reminder).subscribe((data) => {
	//         this.customerDetail['myRelations']['reminder'] = data.uid;
	//         this.timeForReminder = 0;
	//         this.reminder.value = '';
	//         this.displayReminderModal = 'none';
	//         this.loadingGif = 'none';
	//         this.display_toast = 'block';
	//         this.closeReminderToastModal();
	//       });
	//     }

	//   }

	// }

	// /**
	//  * Function to close toast modal after 8 secs
	//  */
	// closeReminderToastModal() {
	//   setTimeout(() => {
	//     this.display_toast = 'none';
	//   }, 8000)
	// }

	public getDownloadLink(attachment): string {
		return attachment['url'] + '?cType=' + attachment['type'] + '&file=' + attachment['name'];
	}

	public onBackClick(): void {
		this._location.back();
	}

	/**
	 * Function to execute like on todo
	 * @param todo
	 * 1. Call like api
	 * 2. Set like uid in myRelations of todo detail object
	 * 3. Set liked text within todo object
	 */
	public likeCustomer(): void {
		this.postService.like(this.currentUser.username, this.customerDetail).subscribe((data) => {
			// 1
			this.customerDetail.myRelations = { like: data.uid }; // 2
			if (this.customerDetail['myRelations'] && this.customerDetail['myRelations'].like && this.customerDetail['likesCount']['count'] === 0) {
				// 3
				this.customerDetail['likedText'] = this.translate.instant('You liked this');
			}
		});
	}

	/**
	 * Function to execute dislike on todo
	 * @param todo
	 * 1. Call dislike api
	 * 2. Remove like uid from myRelations of todo object
	 */
	public dislikeCustomer(): void {
		this.postService.dislike(this.currentUser.username, this.customerDetail.myRelations.like).subscribe(() => {
			// 1
			this.customerDetail.myRelations = { like: null };
			this.customerDetail['likedText'] = ''; // 2
			this.customerDetail['likesCount']['count'] = 0;
		});
	}

	public handleLinkShare(link: string): void {
		this.clipboardService.copy(link);
	}

	public showPostSlideShow(): void {
		this.selectedImages = this.customerDetail.attachmentDetails.gallery;
	}

	public setCountry(code): void {
		const index = this.countries.findIndex((c) => c.code === code);
		if (index > -1) {
			this.customerDetail['address']['countryName'] = this.countries[index]['name'];
			this.userService.searchCountries(this.countries[index]['name']).subscribe((data) => {
				if (data.results.length > 0) {
					data.results.forEach((x) => {
						if (x['name'] === this.countries[index]['name']) {
							if (x['countryCode']) {
								this.customerDetail['address']['phoneCode'] = x['countryCode'];
							}
						}
					});
				}
			});
		}
	}

	public displayReminders(): void {
		this.displayReminderModal = 'block';
		this.reminderOptions = [];
		this.networkService.getReminderOptions().subscribe((data) => {
			this.reminderOptions = data.reminderOptions;
			if (this.remindersSelected.length > 0) {
				this.setPreviousReminders();
			}
		});
	}

	private minutesUntilMidnight(): any {
		const midnight = new Date();
		midnight.setHours(24);
		midnight.setMinutes(0);
		midnight.setSeconds(0);
		midnight.setMilliseconds(0);
		return (midnight.getTime() - new Date().getTime()) / 1000 / 60;
	}

	/**
	 * On selecting reminder options
	 *
	 * @param reminder
	 */
	public onSelectReminder(reminder): void {
		const index = this.remindersSelected.findIndex((r) => r === reminder);
		if (index > -1) {
			this.remindersSelected.splice(index, 1);
		} else {
			this.remindersSelected.push(reminder);
		}
	}

	/**
	 * For resetting reminders in reminder options modal
	 */
	public resetReminders(): void {
		this.displayReminderModal = 'none';
		this.timeFor = 0;
		this.remindersSelected = [];
		this.reminderOptions.forEach((r) => {
			r['checked'] = false;
		});
	}

	/**
	 * For creating a new reminders for content
	 */
	public setRemainder(): void {
		if (this.remindersSelected.length > 0) {
			const object = {};
			this.remindersSelected.forEach((reminder) => {
				object[reminder] = this.setReminderDate(reminder);
			});
			const param = JSON.stringify(object);
			this.model.contentType = 'customer';
			this.model.contentUid = this.customerDetail.uid;
			this.model.username = this.currentUser.username;
			this.model.repeat = true;
			this.model.reminderOption = param;
			this.loadingGif = 'block';
			this.networkService.createReminder(this.model).subscribe((data) => {
				this.customerDetail['myRelations']['reminder'] = data['uid'];
				this.loadingGif = 'none';
				this.resetReminders();
				this.reminderToast = true;
				setTimeout(() => {
					this.reminderToast = false;
				}, 8000);
			});
		} else {
			if (this.customerDetail['myRelations'] && this.customerDetail['myRelations']['reminder']) {
				this.deleteReminders();
			} else {
				this.resetReminders();
			}
		}
	}

	public deleteReminders(): void {
		this.model.contentUid = this.customerDetail.uid;
		this.model.username = this.currentUser.username;
		this.model.repeat = true;
		this.model.contentType = 'customer';
		this.loadingGif = 'block';
		this.networkService.createReminder(this.model).subscribe(() => {
			this.customerDetail['myRelations']['reminder'] = '';
			this.loadingGif = 'none';
			this.resetReminders();
		});
	}

	/**
	 * For calculating corresponding to reminder selected
	 * @param option
	 */
	public setReminderDate(option): string {
		if (option === 'in_twenty_minutes') {
			this.timeFor = 20;
		} else if (option === 'in_one_hour') {
			this.timeFor = 60;
		} else if (option === 'in_three_hours') {
			this.timeFor = 180;
		} else if (option === 'tomorrow') {
			this.timeFor = this.minutesUntilMidnight() + 540;
		} else {
			this.timeFor = 6300;
		}
		const date = new Date();
		date.setMinutes(date.getMinutes() + this.timeFor);
		const month = date.getUTCMonth() + 1;
		const day = date.getUTCDate();
		const year = date.getUTCFullYear();
		const hours = date.getUTCHours();
		const minutes = date.getUTCMinutes();
		const seconds = date.getUTCSeconds();
		const utcDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
		return utcDate;
	}

	/**
	 * Get previous reminders of content
	 */
	public getReminderDetails(): void {
		this.loadingGif = 'block';
		this.networkService.getContentReminders('customers', this.customerDetail['uid']).subscribe((data) => {
			this.loadingGif = 'none';
			this.displayReminders();
			if (data.objects && data.objects.length > 0) {
				data.objects.forEach((element) => {
					if (element['reminderOption']) {
						this.remindersSelected.push(element['reminderOption']);
					}
				});
			}
			this.setPreviousReminders();
		});
	}

	/**
	 * Set Previous reminders on reminder options
	 */
	private setPreviousReminders(): void {
		this.reminderOptions.forEach((reminder) => {
			this.remindersSelected.forEach((r) => {
				if (r === reminder[0]) {
					reminder['checked'] = true;
				}
			});
		});
	}

	/**
	 * Function to show the add task or add issue form
	 * @param $event
	 */
	public onClickMoreOptionsEmitterFn($event): void {
		this.postType = $event.type;
		this.postService.onClickMoreOptionsFromComments.emit($event);
		setTimeout(() => {
			this.layoutService.hideBd();
			this.layoutService.showBd('add-issue');
			window.scroll(0, 0);
		}, 200);
	}
}
