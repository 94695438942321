import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NprogressComponent } from './nprogress.component';

@NgModule({
	declarations: [NprogressComponent],
	imports: [CommonModule],
	exports: [NprogressComponent],
})
export class NprogressModule {}
