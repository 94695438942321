import { IImageAttachment } from 'projects/bee-attachment/src/lib/shared/models/image-attachment';
import { Injectable } from '@angular/core';
import { GalleryItem, Gallery, ImageItem, ImageSize, ThumbnailsPosition, GalleryConfig } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import { ITemplateImage } from 'projects/custom-flows/src/lib/core/models/template';

@Injectable({
	providedIn: 'root',
})
export class LightboxService {
	private galleryImages: GalleryItem[];

	constructor(private lightbox: Lightbox, private gallery: Gallery) {}

	public initLightbox(images: IImageAttachment[], index: number = 0): void {
		if (images?.length) {
			this.galleryImages = images.map(
				(item: IImageAttachment) => new ImageItem({ src: item.url, thumb: item.thumbnails.find((x: IImageAttachment, i: number) => i === 1)?.url })
			);

			// get a lightbox gallery ref
			const lightboxRef = this.gallery.ref('lightbox');

			// add custom gallery config to the lightbox (optional)
			lightboxRef.setConfig(<GalleryConfig>{
				imageSize: ImageSize.Cover,
				thumb: true,
				thumbPosition: ThumbnailsPosition.Bottom,
				nav: true,
				dots: false,
				gestures: true,
				thumbWidth: 100,
				loadingStrategy: 'lazy',
				thumbView: 'contain',
			});

			// load items into the lightbox gallery ref
			lightboxRef.load(this.galleryImages);

			// open lightbox gallery
			this.lightbox.open(index, 'lightbox', {
				panelClass: 'fullscreen',
			});
		}
	}

	/**
	 * Function to preview single image
	 * @param image Image Object
	 */
	public previewIndividualImage(image: ITemplateImage): void {
		/**
		 * Prepare lightbox gallery reference & gallery of image to be shown
		 */
		const lightboxRef = this.gallery.ref('lightbox'),
			gallery = [new ImageItem({ src: image.url, thumb: image.thumbnails[120] })];

		/**
		 * Add gallery config to the lightbox
		 * ? OPTIONAL
		 */
		lightboxRef.setConfig(<GalleryConfig>{
			imageSize: ImageSize.Cover,
			thumb: true,
			thumbPosition: ThumbnailsPosition.Bottom,
			nav: true,
			dots: false,
			gestures: true,
			thumbWidth: 100,
			loadingStrategy: 'lazy',
			thumbView: 'contain',
		});

		/**
		 * Load images from the lightbox gallery
		 */
		lightboxRef.load(gallery);

		/**
		 * Open the lightbox gallery
		 */
		this.lightbox.open(0, 'lightbox', {
			panelClass: 'fullscreen',
		});
	}
}
