import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BeeTextboxComponent } from './textbox/textbox.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BeeTelInputComponent } from './tel-input/tel-input.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BeeUiSharedModule } from '../../shared/bee-ui-shared.module';
import { TimezoneCountryService } from '../../shared/services/timezone-country.service';
import { BeePasswordBoxComponent } from './password-box/password-box.component';

@NgModule({
	declarations: [BeeTextboxComponent, BeeTelInputComponent, BeePasswordBoxComponent],
	imports: [CommonModule, FormsModule, ReactiveFormsModule, NgxIntlTelInputModule, BeeUiSharedModule],
	exports: [BeeTextboxComponent, BeeTelInputComponent, BeePasswordBoxComponent],
	providers: [TimezoneCountryService],
})
export class BeeInputModule {}
