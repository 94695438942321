import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticationService } from '@modules/new-auth/services/authentication.service';
import CacheUtils from '@shared/utils/cache-utils';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class CouponGuard implements CanActivate {
	constructor(private router: Router, private authenticationService: AuthenticationService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (CacheUtils.getStoredData('userDetails') !== null || localStorage.getItem('userDetails') !== null) {
			// Handle already login redirection
			return this.router.createUrlTree(['/']);
		} else {
			this.authenticationService.setAuthenticationStep('form');
			if (route.paramMap.get('coupon')) {
				// Check For AppSumo Coupon, if present, navigate to register via email page with coupon code in queryParams
				return this.router.createUrlTree(['/authentication/register'], { queryParams: { appSumoCode: route.paramMap.get('coupon') } });
			} else if (route.paramMap.get('f6sCoupon')) {
				// Check For f6s Coupon, if present, navigate to register via email page with coupon code in queryParams
				return this.router.createUrlTree(['/authentication/register'], { queryParams: { f6sCode: route.paramMap.get('f6sCoupon') } });
			} else if (route.paramMap.get('cesCoupon')) {
				// Check For ces Coupon, if present, navigate to register via email page with coupon code in queryParams
				return this.router.createUrlTree(['/authentication/register'], { queryParams: { cesCode: route.paramMap.get('cesCoupon') } });
			} else if (state.url === '/ces') {
				return this.router.createUrlTree(['/authentication/register'], { queryParams: { cesCode: '' } });
			} else if (state.url === '/appsumo') {
				return this.router.createUrlTree(['/authentication/register'], { queryParams: { appSumoCode: '' } });
			} else if (state.url === '/f6s') {
				return this.router.createUrlTree(['/authentication/register'], { queryParams: { f6sCode: '' } });
			}
		}
	}
}
