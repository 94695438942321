import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@shared/services/common/toast.service';
import { ClipboardService as NgxClipboardService } from 'ngx-clipboard';

@Injectable({
	providedIn: 'root',
})
export class ClipboardService {
	constructor(private clipboardService: NgxClipboardService, private toastService: ToastService, private translateService: TranslateService) {}

	public copy(text: string): void {
		if (text && text.length > 0) {
			this.clipboardService.copyFromContent(text.trim());
			this.toastService.info(this.translateService.instant('Copied to clipboard'));
		}
	}
}
