import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OvalLoaderComponent } from './oval-loader/oval-loader.component';



@NgModule({
	declarations: [
		OvalLoaderComponent
	],
	exports: [
		OvalLoaderComponent
	],
	imports: [
		CommonModule
	]
})
export class OvalLoaderModule { }
