import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import MathUtils from '@shared/utils/math-utils';
import { Chart, registerables } from 'chart.js';
import doughnutChartPlugin from './../../../../../../projects/peer-core/src/lib/plugins/chartjs/doughnut-chart-plugin';
import { timer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

Chart.register(...registerables);
@Component({
	selector: 'app-new-team-dashboard',
	templateUrl: './new-team-dashboard.component.html',
	styleUrls: ['./new-team-dashboard.component.scss'],
})
export class NewTeamDashboardComponent implements OnInit, OnDestroy {
	@ViewChild('doughnutGraphCanvas') public doughnutGraphCanvas: ElementRef;
	@Input() public post: any;
	public team: any;

	public detailsExpanded = false;

	public chart: any;
	private chartConfigs: any;
	private chartInstance: Chart;
	private graphData: any;
	public userTasksCounts: any;

	constructor(private translateService: TranslateService, public router: Router) {
		this.chartConfigs = {
			type: 'doughnut',
			data: {
				datasets: [],
			},
			plugins: [doughnutChartPlugin],
		};
	}

	ngOnInit(): void {
		this.team = this.setTeamProgressWidth(this.post?.todolist);
	}

	/**
	 * Add progress bar width based on todolist counts
	 * @param team specified team
	 * @returns team object with progress percentage
	 */
	private setTeamProgressWidth(team: any): any {
		team.progressPercent = MathUtils.getPercentageOf(team?.closedTodos, team?.totalCount);
		return team;
	}

	/**
	 * Open project detail view
	 */
	public openTeamDetails(): void {
		this.detailsExpanded = !this.detailsExpanded;

		if (this.detailsExpanded) {
			this.graphData = [];
		}

		timer(500).subscribe(() => {
			this.loadGraph();
		});
	}

	/**
	 * Load the task count graph
	 */
	private loadGraph(): void {
		const drawGraph: any = this.doughnutGraphCanvas?.nativeElement;

		if (drawGraph) {
			const context = drawGraph.getContext('2d'),
				doughnutOptions: any = {
					...Chart.defaults.datasets.doughnut,
					...{
						cutout: '70%',
					},
				};

			// global configs
			Chart.defaults.elements.arc.borderWidth = 0;
			Chart.defaults.datasets.doughnut = doughnutOptions;
			Chart.defaults.plugins.tooltip.enabled = false;

			// #region Graph Data

			if (this.graphData && this.graphData?.length === 0) {
				this.graphData.push({
					label: this.translateService.instant('Open'),
					value: this.team?.openTodos,
					color: '#10B981',
				});
				this.graphData.push({
					label: this.translateService.instant('Closed'),
					value: this.team?.closedTodos,
					color: '#F59E0B',
				});

				this.chartConfigs.data.datasets = [
					{
						data: this.graphData.map((data) => data.value),
						backgroundColor: this.graphData.map((data) => data.color),
					},
				];
			}

			// #endregion

			this.destroyChart();
			this.chartInstance = new Chart(context, this.chartConfigs);
		}
	}

	/**
	 * Function to calculate end date of the week from start date
	 * @returns End of the week date
	 */
	public endOfTheWeek(): Date {
		if(this.post?.starts){
			const startDate = new Date(this.post?.starts ? this.post.starts : ''),
				endDate = new Date();
			endDate.setDate(startDate.getDate() + 6);

			return endDate;
		}else{
			return null;
		}

	}

	/**
	 * Destroy the chart before re-rendering
	 */
	private destroyChart(): void {
		if (this.chartInstance) {
			this.chartInstance.destroy();
		}
	}

	ngOnDestroy(): void {
		this.destroyChart();
	}
}
