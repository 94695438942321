import { Pipe, PipeTransform, NgZone, ChangeDetectorRef, OnDestroy } from '@angular/core';
// eslint-disable-next-line spellcheck/spell-checker
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
	// eslint-disable-next-line spellcheck/spell-checker
	constructor(private sanitizer: DomSanitizer) {}

	public transform(url): SafeResourceUrl {
		// eslint-disable-next-line spellcheck/spell-checker
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}
}

@Pipe({ name: 'profile_img' })
export class ProfileImagePipe implements PipeTransform {
	constructor() {}

	public transform(value: string): string {
		if (!value) {
			return 'UN';
		}

		const nameArr = value.split(' ');
		let str = nameArr[0].substr(0, 1);

		if (nameArr.length > 1) {
			str += nameArr[1].substr(0, 1);
		}

		return str;
	}
}

@Pipe({ name: 'round' })
export class RoundPipe implements PipeTransform {
	/**
	 *
	 * @param value
	 * @returns {number}
	 */
	public transform(value: number): number {
		return Math.round(value);
	}
}

@Pipe({ name: 'replaceUnderscore' })
export class ReplaceUnderscorePipe implements PipeTransform {
	public transform(value: string): string {
		return value ? value.replace(/_/g, ' ') : value;
	}
}

// eslint-disable-next-line spellcheck/spell-checker
@Pipe({ name: 'replaceTextline' })
// eslint-disable-next-line spellcheck/spell-checker
export class ReplaceTextlinePipe implements PipeTransform {
	public transform(value: string): string {
		return value ? value.replace(/-/g, ' ') : value;
	}
}

@Pipe({
	name: 'timeAgo',
	pure: false,
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {
	private timer: number;

	constructor(private changeDetectorRef: ChangeDetectorRef, private ngZone: NgZone, private translate: TranslateService) {}

	ngOnDestroy(): void {
		this.removeTimer();
	}

	public transform(dateString: string): string {
		this.removeTimer();
		let value = dateString.toString();

		if (navigator.appCodeName !== 'Mozilla') {
			value = value.replace(/-/g, '/');
		}

		value = value.replace(/T/g, ' ');
		value = value.replace(/Z/g, '+0000');

		const d = new Date(value);
		const now = new Date();
		const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
		const timeToUpdate = this.getSecondsUntilUpdate(seconds) * 1000;

		this.timer = this.ngZone.runOutsideAngular(() => {
			if (typeof window !== 'undefined') {
				return window.setTimeout(() => {
					this.ngZone.run(() => this.changeDetectorRef.markForCheck());
				}, timeToUpdate);
			}
			return null;
		});

		const minutes = Math.round(Math.abs(seconds / 60)),
			hours = Math.round(Math.abs(minutes / 60)),
			days = Math.round(Math.abs(hours / 24)),
			months = Math.round(Math.abs(days / 30.416)),
			years = Math.round(Math.abs(days / 365));

		if (seconds <= 45) {
			return this.translate.instant('a few seconds ago');
		} else if (seconds <= 90) {
			return this.translate.instant('a minute ago');
		} else if (minutes <= 45) {
			return minutes + ' ' + this.translate.instant('minutes ago');
		} else if (minutes <= 90) {
			return this.translate.instant('an hour ago');
		} else if (hours <= 22) {
			return hours + ' ' + this.translate.instant('hours ago');
		} else if (hours <= 36) {
			return this.translate.instant('a day ago');
		} else if (days <= 25) {
			return days + ' ' + this.translate.instant('days ago');
		} else if (days <= 45) {
			return this.translate.instant('a month ago');
		} else if (days <= 345) {
			return months + ' ' + this.translate.instant('months ago');
		} else if (days <= 545) {
			return this.translate.instant('a year ago');
		} else {
			// (days > 545)
			return years + ' ' + this.translate.instant('years ago');
		}
	}

	private removeTimer(): void {
		if (this.timer) {
			window.clearTimeout(this.timer);

			this.timer = null;
		}
	}

	private getSecondsUntilUpdate(seconds: number): number {
		const min = 60,
			hr = min * 60,
			day = hr * 24;

		if (seconds < min) {
			// less than 1 min, update ever 2 secs
			return 2;
		} else if (seconds < hr) {
			// less than an hour, update every 30 secs
			return 30;
		} else if (seconds < day) {
			// less then a day, update every 5 minutes
			return 300;
		} else {
			// update every hour
			return 3600;
		}
	}
}
