import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { NetworkService } from './network.service';

@Injectable({
	providedIn: 'root',
})
export class SingleNetworkGuard implements CanActivate {
	constructor(private userService: UserService, private networkService: NetworkService, private router: Router) {}

	canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		// if user is not in purchase scenario,
		// then call getCurrentLoggedInUserDetailResolve() to redirect user to network
		this.userService.isAuthenticated.subscribe((status: any) => {
			if (status === true) {
				if (!localStorage.getItem('branchData')) {
					this.userService.currentUser.subscribe((user: any) => {
						if (
							user &&
							user.membershipCount &&
							user.membershipCount === 1 &&
							!user['purchaseScenario'] &&
							!localStorage.getItem('app_network_id') &&
							!localStorage.getItem('zoom_authentication')
						) {
							return this.getCurrentLoggedInUserDetailResolve().then(() => {
								const count = localStorage.getItem('membershipCount');

								if (count === '1') {
									window.location.replace('/network');

									return true;
								}
							});
						} else {
							return true;
						}
					});
				}
			}
		});

		return true;
	}

	/**
	 * Function to get single network uid
	 *
	 * 1.Set memberships api call
	 * 2.If api call has success response,set network id to localstorage
	 * 3.else show error in console
	 */
	private getCurrentLoggedInUserDetailResolve(): any {
		const data = this.userService.getMemberships(); // 1
		let promise;

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		return (promise = new Promise((resolve, reject) => {
			data.subscribe(
				(res) => {
					if (res) {
						// 2
						resolve(res.objects);
						localStorage.setItem('network_id', res?.objects[0]?.network?.uid);

						this.networkService.networkId = res.objects[0].network.uid;
					}
				},
				() => {
					// 3
				}
			);
		}));
	}
}
