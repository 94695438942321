import { UserService } from './shared/services/user.service';
import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ApiService } from './shared/services/api.service';
import { JwtService } from './shared/services/jwt.service';

@Injectable()
export class MockHttpCalIInterceptor implements HttpInterceptor {
	public body: any;
	public accessToken: any;
	public expires;

	constructor(private injector: Injector, private apiService: ApiService, private jwtService: JwtService, private userService: UserService) {}

	/**
	 * Intercept every api call
	 * 1. Check if error status for an api call is 403
	 * 2. If so,call api to get new access token
	 * 3. Retry last api call with new token
	 * @param request
	 * @param next
	 */
	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// internet connection check
		if (!window.navigator.onLine) {
			const error = { status: 0, detail: 'Check Connectivity!' };

			return throwError(new HttpErrorResponse(error));
		} else {
			// handle http errors
			return next.handle(request).pipe(
				catchError((err) => {
					if (err instanceof HttpErrorResponse) {
						// unauthenticated
						if (err.status === 401) {
							this.userService.logout();

							window.location.href = '/authentication/login';

							return next.handle(request);
						} else if (err.status === 403) {
							// forbidden, possibly require refresh token
							return this.apiService.requestAccessToken().pipe(
								switchMap((authResponse) => {
									this.saveToken(authResponse);
									return next.handle(this.injectToken(request)); // 3
								})
							);
						}
					}

					throw err;
				})
			);
		}
	}

	/**
	 * Inject new token into header
	 * @param request
	 */
	public injectToken(request: HttpRequest<any>): any {
		const token = this.jwtService.getToken();

		return request.clone({
			setHeaders: {
				Authorization: `Bearer ${token}`,
			},
		});
	}

	/**
	 * 1. Remove all previous token details from LS
	 * 2. Save new token details in local storage
	 * @param response
	 */
	private saveToken(response): void {
		this.body = response;
		this.accessToken = response.access;
		this.expires = response.expires;

		localStorage.removeItem('token');
		localStorage.removeItem('tokenExpires'); // 1

		window.localStorage['token'] = this.accessToken;
		window.localStorage['tokenExpires'] = this.expires; // 2
	}
}
