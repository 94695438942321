import { AttachmentService } from 'projects/bee-attachment/src/lib/shared/services/attachment.service';
import { NgModule } from '@angular/core';
import { AttachmentBodyModule } from './modules/attachment-body/attachment-body.module';

@NgModule({
	declarations: [],
	imports: [AttachmentBodyModule],
	exports: [AttachmentBodyModule],
	providers: [AttachmentService]
})
export class BeeAttachmentModule {}
