import { BeeButtonComponent } from './button/button.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BeeLabelButtonComponent } from './label-button/label-button.component';

@NgModule({
	declarations: [BeeButtonComponent, BeeLabelButtonComponent],
	imports: [CommonModule],
	exports: [BeeButtonComponent, BeeLabelButtonComponent],
})
export class BeeButtonModule {}
