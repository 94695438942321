import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export class FilterMenuOption {
	public label: string;
	public value: string = null;
	public isSelected? = false;
	public color?: string;
	public textColor?: string;

	constructor(data?: FilterMenuOption) {
		Object.assign(this, data);
	}
}

export interface IFilterMenu {
	title: string;
	icon: string;
	options?: FilterMenuOption[] | null;
	isOpen?: boolean;
	isMultiSelectEnabled?: boolean;
	filterKey?: any;
	isVisible?: boolean;
	selectedOptionValue?: any | null;
}

export class FilterMenu implements IFilterMenu {
	public title: string;
	public icon: string;
	public options: FilterMenuOption[] | null = null;
	public isOpen = false;
	public isMultiSelectEnabled? = false;
	public filterKey: any = null;
	public isVisible = true;
	public selectedOptionValue = null;

	constructor(data?: IFilterMenu) {
		Object.assign(this, data);
	}
}

@Component({
	selector: 'app-filter-menu',
	templateUrl: './filter-menu.component.html',
	styleUrls: ['./filter-menu.component.scss'],
})
export class FilterMenuComponent implements OnInit {
	@Output() public filterOptionSelectedEvent: EventEmitter<any> = new EventEmitter<any>();
	@Input() public isMenuOpen = false;
	@Input() public filterMenuItems: IFilterMenu[] = [];
	@Output() public menuCloseEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

	private selectedOption: FilterMenuOption | null = null;

	constructor() {}

	ngOnInit(): void {}

	public onMenuItemHeaderClick(menuItem: FilterMenu): void {
		if (menuItem?.isOpen !== !menuItem?.isOpen) {
			menuItem.isOpen = !menuItem.isOpen;
		}

		if (!menuItem.options) {
			this.filterOptionSelectedEvent?.emit({ isSelected: false, menuItem, optionItem: null });
		}

		if (!menuItem.isOpen) {
			menuItem.options?.forEach((option) => {
				option.isSelected = false;
			});
		}

		this.resetSelections(menuItem);
	}

	public selectedOptionItemChange(menuItem: FilterMenu, optionItem: FilterMenuOption): void {
		this.selectedOption = optionItem;
	}

	public onOptionGroupChange(menuItem: FilterMenu): void {
		this.filterOptionSelectedEvent?.emit({ menuItem, optionItem: this.selectedOption });
		this.resetSelections(menuItem);
	}

	private resetSelections(menuItem: FilterMenu): void {
		const index = this.filterMenuItems?.findIndex((item) => item === menuItem);

		if (index > -1) {
			this.filterMenuItems[index].selectedOptionValue = this.selectedOption?.value;

			this.filterMenuItems?.forEach((item: FilterMenu, i: number) => {
				if (i !== index) {
					item.selectedOptionValue = null;
				}
			});
		}
	}

	public closeFilterMenu(): void {
		this.menuCloseEvent?.emit(false);
	}
}
