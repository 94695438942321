import { PageLoadingUiService } from './../../../../@core/services/ui/common/page-loading-ui.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkspaceService } from '@core/services/workspace/workspace.service';
import { CreateWorkspaceSteps, JoinWorkspaceSteps } from '@modules/home/models/workspace-wizard-steps';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IWorkspaceDepartment, IWorkspaceDepartmentResponse, WorkspaceDepartmentResponse } from '@shared/models/workspace/department';
import { IWorkspace, IWorkspacePaginatedResponse, Workspace } from '@shared/models/workspace/workspace';
import { NetworkService, ProfilesService, SocketIoService, UserService } from '@shared/services';
import { TextboxType } from 'projects/bee-ui/src/lib/shared/models/textbox-type';
import { BranchIoService } from 'projects/peer-core/src/lib/services/third-party/branch-io.service';
import { debounceTime } from 'rxjs/operators';
import { Network } from '@shared/models';
import { TranslateService } from '@ngx-translate/core';
import { timer } from 'rxjs';

@Component({
	selector: 'app-join-workspace',
	templateUrl: './join-workspace.component.html',
	styleUrls: ['./join-workspace.component.scss'],
})
export class JoinWorkspaceComponent implements OnInit {
	@ViewChild('privateWorkspaceModal', { static: false }) public privateWorkspaceModalRef: NgbModalRef;
	private privateWorkspaceModal: NgbModalRef;

	// steps
	public JoinWorkspaceSteps: typeof JoinWorkspaceSteps = JoinWorkspaceSteps;
	public CreateWorkspaceSteps: typeof CreateWorkspaceSteps = CreateWorkspaceSteps;

	// selected steps
	public SelectedJoinWorkspaceStep: JoinWorkspaceSteps | null = JoinWorkspaceSteps.SearchWorkspace;
	public SelectedCreateWorkspaceStep: CreateWorkspaceSteps | null = null;

	// forms
	public TextboxType: typeof TextboxType = TextboxType;
	public searchWorkspaceField: FormControl = new FormControl();
	public userTitleField: FormControl = new FormControl();
	public isWorkspacesLoading = false;

	private previousWorkspaceSearch = null;

	public currentUser: any;
	public workspaces: any[] = [];
	public selectedWorkspace: IWorkspace;
	public departmentsResponse: IWorkspaceDepartmentResponse = new WorkspaceDepartmentResponse();
	public selectedDepartment: IWorkspaceDepartment;

	private selectedNetworkId;
	private membershipId;
	private paramsExists;
	private view;
	private stateParam;
	private isMembershipCancel: boolean;
	public displayPendingCancel;

	constructor(
		private router: Router,
		private userService: UserService,
		private workspaceService: WorkspaceService,
		private modalService: NgbModal,
		private branchIoService: BranchIoService,
		private pageLoadingUiService: PageLoadingUiService,
		private networkService: NetworkService,
		private activatedRoute: ActivatedRoute,
		private translateService: TranslateService,
		private socket: SocketIoService,
		private profileService: ProfilesService
	) {
		this.branchIoService.clearBranchIoData();

		// this.activatedRoute.queryParams.subscribe((params) => {
		// 	if (params && params['id']) {
		// 		this.selectedNetworkId = params['id'];
		// 		this.membershipId = params['membership'];
		// 		this.paramsExists = true;

		// 		if (params['inviteOnly']) {
		// 			this.view = 'adminApproval';
		// 			this.getMemberShipDetails();
		// 			this.getNetworkSubscriptionDetails(this.selectedNetworkId);
		// 		}
		// 		if (params['roleValidation']) {
		// 			this.view = 'emailValidation';
		// 			this.getMemberShipDetails();
		// 			this.getNetworkSubscriptionDetails(this.selectedNetworkId);
		// 			if (params['email']) {
		// 				this.email = params['email'];
		// 				this.defaultDomain = params['email'];
		// 			} else {
		// 				this.email = '';
		// 			}
		// 		}

		// 		if (params['admin']) {
		// 			if (params['validationMethod']) {
		// 				if (params['validationMethod'] === 'email') {
		// 					this.view = 'emailValidation';
		// 					if (params['email']) {
		// 						this.email = params['email'];
		// 						this.defaultDomain = params['email'];
		// 					}
		// 				} else if (params['validationMethod'] === 'multiple-role') {
		// 					this.view = 'roleSelection';
		// 					this.loader = 1;
		// 					this.networkService.get(params['id']).subscribe((networkData) => {
		// 						this.setRoleForNetworkAdmin(networkData);
		// 						if (networkData['membership'] && networkData['membership'].uid) {
		// 							this.createdNetworkMembershipId = networkData['membership'].uid;
		// 						}
		// 						if (networkData['inviteOnly']) {
		// 							this.stateParam = 1;
		// 						} else {
		// 							this.stateParam = 0;
		// 						}
		// 					});
		// 				} else {
		// 					this.view = 'roleFields';
		// 					this.loader = 1;
		// 					this.networkService.get(params['id']).subscribe((networkData) => {
		// 						this.setRoleForNetworkAdmin(networkData);
		// 						if (networkData['membership'] && networkData['membership'].uid) {
		// 							this.createdNetworkMembershipId = networkData['membership'].uid;
		// 						}
		// 						if (networkData['inviteOnly']) {
		// 							this.stateParam = 1;
		// 						} else {
		// 							this.stateParam = 0;
		// 						}
		// 					});
		// 				}
		// 			}
		// 		}
		// 	}

		// 	if (params && params['branch_networkId']) {
		// 		this.getBranchNetworkDetails(params['branch_networkId']);

		// 		if (params['content_type'] && params['content_uid']) {
		// 			this.branchIoService.setBranchData({
		// 				contentType: params['content_type'],
		// 				contentUid: params['content_uid'],
		// 			});
		// 		}
		// 	}
		// });
	}

	ngOnInit(): void {
		this.currentUser = this.userService.getCurrentUser();

		this.searchWorkspaceField.valueChanges.pipe(debounceTime(500)).subscribe((value: string) => {
			if (this.previousWorkspaceSearch !== value) {
				this.searchWorkspaces(value);

				this.previousWorkspaceSearch = value;
			}
		});

		// on member delete event
		// this.socket.getSocket().on('member-delete', (data) => {
		// 	if (!this.isMembershipCancel && (this.view === 'adminApproval' || this.view === 'emailValidation' || this.view === 'success')) {
		// 		const socketData = JSON.parse(data);
		// 		if (socketData['memberId'] && socketData['memberId'] === this.membershipId) {
		// 			// 1
		// 			this.displayDeleteDialog = 'block'; // 2
		// 		} else {
		// 			this.displayDeleteDialog = 'none';
		// 		}
		// 	}
		// });
	}

	// #region Loaders

	/**
	 * Search workspaces
	 *
	 * @param value string
	 * @returns void
	 */
	public searchWorkspaces(value: string): void {
		if (this.isWorkspacesLoading) {
			return;
		}

		if (value) {
			this.isWorkspacesLoading = true;

			this.workspaceService
				.searchInWorkspaces(value.trim(), 'enterprise')
				.subscribe((data: IWorkspacePaginatedResponse) => {
					this.workspaces = data.objects
						?.map((ws: any) => ({
							network: new Workspace(ws),
						}))
						.filter((ws: any) => !ws.network?.membershipUid);

					this.previousWorkspaceSearch = value;
				})
				.add(() => {
					this.isWorkspacesLoading = false;
				});
		} else {
			this.workspaces = [];
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	private getNetworkSubscriptionDetails(networkUid: string): void {
		// this.networkService.getNetworkActiveSubscription(networkUid).subscribe(
		// 	(data) => {
		// 		if (!data.trialEnd) {
		// 			// 1
		// 			if (data.numberOfSeats && data['network'] && data['network']['activeMembersCount'] >= 0) {
		// 				const numberOfSeats = data.numberOfSeats;
		// 				const activeMembers = data['network']['activeMembersCount'];
		// 				if (data['status']) {
		// 					if (data['status'] !== 'active' && data['status'] !== 'trialing') {
		// 						this.seatsExceeded = true;
		// 						this.displayError = 'block';
		// 						this.modelText = this.translateService.instant(
		// 							'We could not log you in because there is a problem with your MyWork account. Your system admin will need to upgrade the account within 90 days.'
		// 						);
		// 					}
		// 				} else if (activeMembers >= numberOfSeats) {
		// 					this.seatsExceeded = true;
		// 					this.displayError = 'block';
		// 					this.modelText = this.translateService.instant(
		// eslint-disable-next-line spellcheck/spell-checker
		// 						'Number of paid seats exceeded. Your network admin is informed. Your co-workers look forward to seeing you at PeerBie.'
		// 					);
		// 				} else {
		// 					this.seatsExceeded = false;
		// 					this.displayError = 'none';
		// 					this.modelText = '';
		// 				}
		// 			}
		// 		} else {
		// 		}
		// 	},
		// 	(err) => {}
		// );
	}

	private getMemberShipDetails(): void {
		this.pageLoadingUiService.setPageLoading(true);

		// this.networkService
		// 	.getMembershipDetails(this.membershipId)
		// 	.subscribe((data: any) => {
		// 		if (data.uid) {
		// 			this.memberId = data.uid;
		// 		}

		// 		if (data['network'] && data['network']) {
		// 			this.name = data['network']['name'];
		// 		}
		// 	})
		// 	.add(() => {
		// 		this.pageLoadingUiService.setPageLoading(false);
		// 	});
	}

	/**
	 * Cancel pending membership
	 *
	 * @returns void
	 */
	private cancelPendingMembership(): void {
		this.pageLoadingUiService.setPageLoading(true);

		const credential = { state: 'inactive' };
		this.isMembershipCancel = true;

		this.profileService
			.cancelPendingMembership(this.selectedNetworkId, this.membershipId, credential)
			.subscribe(() => {
				this.displayPendingCancel = 'none';

				this.router.navigateByUrl('/');
			})
			.add(() => {
				this.pageLoadingUiService.setPageLoading(false);
			});
	}

	// #endregion

	// #region Join Workspace

	public onJoinWorkspaceClick(): void {
		this.SelectedJoinWorkspaceStep = JoinWorkspaceSteps.SearchWorkspace;
		this.SelectedCreateWorkspaceStep = null;
	}

	public onWorkspaceClick(workspace: IWorkspace): void {
		this.selectedWorkspace = workspace;

		if (workspace.network.inviteOnly && !workspace.network.membershipUid) {
			this.privateWorkspaceModal = this.modalService.open(this.privateWorkspaceModalRef, <NgbModalOptions>{
				backdrop: 'static',
				centered: true,
				size: 'md',
				keyboard: true,
			});

			return;
		}

		this.SelectedJoinWorkspaceStep = JoinWorkspaceSteps.DepartmentSelection;

		this.departmentsResponse.isLoading = true;

		this.workspaceService
			.getWorkspaceDepartments(
				workspace.network.uid,
				this.departmentsResponse?.offset > 0 ? this.departmentsResponse?.offset + this.departmentsResponse?.limit : 0,
				this.departmentsResponse?.limit
			)
			.subscribe((response: IWorkspaceDepartmentResponse) => {
				this.departmentsResponse = response;
			})
			.add(() => {
				this.departmentsResponse.isLoading = false;
			});
	}

	public onDepartmentClick(department: IWorkspaceDepartment): void {
		this.SelectedJoinWorkspaceStep = JoinWorkspaceSteps.TitleSelection;

		this.selectedDepartment = department;
	}

	public closePrivateWorkspaceModal(): void {
		this.privateWorkspaceModal?.close();
	}

	public onJoinWorkspaceResetClick(): void {
		this.SelectedJoinWorkspaceStep = null;
		this.SelectedCreateWorkspaceStep = null;
		this.selectedDepartment = null;
		this.departmentsResponse = null;
	}

	public onJoinFinishClick(): void {
		const userTitle = this.userTitleField.value;

		if (!userTitle) {
			return;
		}

		this.onJoinWorkspaceResetClick();
	}

	// #endregion

	// #region Helpers

	private getBranchNetworkDetails(uid: string): void {
		this.pageLoadingUiService.setPageLoading(true);

		this.networkService
			.get(uid)
			.subscribe((networkData) => {
				this.selectNetwork(networkData);
			})
			.add(() => {
				this.pageLoadingUiService.setPageLoading(false);
			});
	}

	private naviagteToNetwork(workspace: IWorkspace): void {
		this.pageLoadingUiService.setPageLoading(true);

		if (workspace.network?.uid) {
			const uid = workspace.network?.uid || workspace.uid;

			this.workspaceService
				.getWorkspace(uid)
				.subscribe((networkData: any) => {
					this.networkService.setNetwork(new Network(networkData));

					if (!this.branchIoService.branchData.contentType && !this.branchIoService.branchData.contentUid) {
						this.networkService.displayWelcomeDialog = true;
						this.router.navigateByUrl('/');

						// FIXME: reload page to fix issue with network not being set (possibly a state bug)
						timer(1000).subscribe(() => {
							window.location.reload();
						});
					} else {
						this.networkService.displayWelcomeDialog = true;
						this.branchIoService.handleBranchRedirection();
					}
				})
				.add(() => {
					this.pageLoadingUiService.setPageLoading(false);
				});
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	private selectNetwork(network): void {
		// if (network['name']) {
		// 	this.name = network['name'];
		// }
		// if (this.activeMemberships.length >= 0) {
		// 	this.createdNetworkMembershipId = '';
		// 	this.email = '';
		// 	const index = this.activeMemberships.findIndex((membership) => membership['network'].uid === network.uid);
		// 	if (index !== -1) {
		// 		const membership = this.activeMemberships[index];
		// 		if (membership.state === 1) {
		// 			this.displayError = 'block';
		// 			this.modelText = this.translateService.instant('You have already joined in this network');
		// 		}
		// 		if (membership.state === 0) {
		// 			if (membership['network']['adminUsername'] !== this.currentUser['username']) {
		// 				this.membershipId = membership.uid;
		// 				this.selectedNetworkId = membership['network'].uid;
		// 				if ((membership.validationMethod === 1 || membership.validationMethod === 2) && !membership['network']['inviteOnly']) {
		// 					this.view = 'emailValidation';
		// 					this.getNetworkSubscriptionDetails(this.selectedNetworkId);
		// 					if (membership.network && membership['network']['validationValue']) {
		// 						const validationValue = membership['network']['validationValue'];
		// 						if (validationValue.indexOf('://') > -1) {
		// 							const domain = validationValue.split('://')[1];
		// 							this.defaultDomain = '@' + domain;
		// 							this.email = '@' + domain;
		// 						} else if (validationValue.indexOf('www.') > -1) {
		// 							const domain = validationValue.split('www.')[1];
		// 							this.defaultDomain = '@' + domain;
		// 							this.email = '@' + domain;
		// 						} else {
		// 							this.defaultDomain = '@' + validationValue;
		// 							this.email = '@' + validationValue;
		// 						}
		// 					}
		// 				}
		// 				if (membership['network'] && membership['network']['inviteOnly']) {
		// 					this.view = 'adminApproval';
		// 					this.getNetworkSubscriptionDetails(this.selectedNetworkId);
		// 				}
		// 			} else {
		// 				if (!membership['validationMethod']) {
		// 					if (network['inviteOnly']) {
		// 						this.stateParam = 1;
		// 					} else {
		// 						this.stateParam = 0;
		// 					}
		// 					this.setRoleForNetworkAdmin(network);
		// 				} else {
		// 					this.view = 'emailValidation';
		// 					if (membership.network && membership['network']['validationValue']) {
		// 						const validationValue = membership['network']['validationValue'];
		// 						if (validationValue.indexOf('://') > -1) {
		// 							const domain = validationValue.split('://')[1];
		// 							this.defaultDomain = '@' + domain;
		// 						} else if (validationValue.indexOf('www.') > -1) {
		// 							const domain = validationValue.split('www.')[1];
		// 							this.defaultDomain = '@' + domain;
		// 						} else {
		// 							this.defaultDomain = '@' + validationValue;
		// 						}
		// 					}
		// 					if (this.checkEmail(this.queryField['value'])) {
		// 						this.email = this.queryField['value'];
		// 					} else {
		// 						this.email = this.defaultDomain;
		// 					}
		// 				}
		// 			}
		// 		}
		// 	} else {
		// 		this.resetRoleFields();
		// 		if (this.branchContentType !== 'External-Channels') {
		// 			if (network.roles && network.roles.length > 0) {
		// 				const indexOfExternal = network.roles.findIndex((role) => role.slug === 'external');
		// 				if (indexOfExternal > -1) {
		// 					network.roles.splice(indexOfExternal, 1);
		// 				}
		// 				if (network.roles.length === 1) {
		// 					if (network.inviteOnly) {
		// 						if (network['adminUsername'] && this.currentUser['username'] === network['adminUsername']) {
		// 							this.inviteOnlyNetwork = false;
		// 							this.stateParam = 1;
		// 						} else {
		// 							this.inviteOnlyNetwork = true;
		// 							this.stateParam = 0;
		// 						}
		// 						this.networkEmail = network['email'];
		// 					} else {
		// 						this.inviteOnlyNetwork = false;
		// 					}
		// 					this.networkUid = network.uid;
		// 					this.role = network.roles[0];
		// 					if (network['membershipUid']) {
		// 						this.setRoleForNetworkAdmin(network);
		// 					} else {
		// 						this.getRoleFields(network.uid);
		// 					}
		// 				} else {
		// 					this.selectedNetwork = network;
		// 					this.networkName = network['name'];
		// 					this.roles = network['roles'];
		// 					this.selectedRole = this.roles[0];
		// 					this.view = 'roleSelection';
		// 				}
		// 			}
		// 		} else {
		// 			this.stateParam = 1;
		// 			this.role = { slug: 'external', name: 'External' };
		// 			this.getRoleFields(network.uid);
		// 		}
		// 	}
		// }
	}

	// #endregion
}
