import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { UserService, ProfilesService, NetworkService } from '../shared';
import CacheUtils from '@shared/utils/cache-utils';

@Component({
	selector: 'app-user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.css'],
})
export class UserComponent implements OnInit {
	public nextUrl;
	public notifications;
	public currentUser;
	public isAuthenticated;
	public showSpin = false;
	public userNotificationSummary;
	public channelMembershipList;
	public categoryFilters;
	public profile_id;
	public is_same_user = false;
	public current_user;
	public postUrl;
	public members;
	public next_url;
	public mutedStatus;
	public reasons;
	public showOptionsModal = 'none';
	public showErrorModal = 'none';
	public showSuccessModal = 'none';
	public successMessage = '';

	constructor(
		private userService: UserService,
		private profileService: ProfilesService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private networkService: NetworkService
	) {}

	ngOnInit(): void {
		// TODO: use loading spinner
		this.userService.populate();
		this.activatedRoute.params.subscribe((params: Params) => {
			this.profile_id = params['memberId'];
		});
		this.userService.currentUser.subscribe((userData) => {
			this.currentUser = userData;
		});

		// this.memberService.getMembers(localStorage.getItem('network_id')).subscribe((data) => {
		//   this.members = data.objects;

		//   this.next_url = data.next ? data.next.split('alpha')[1] : '';
		// });

		this.networkService.networkReady.subscribe(() => {
			// this.networkLoaded = networkLoaded;
		});
		this.userService.isAuthenticated.subscribe((authenticated) => {
			this.isAuthenticated = authenticated;
			if (authenticated) {
				this.profileService.getMemberShip(this.currentUser.username, this.profile_id).subscribe((profile) => {
					this.current_user = profile;
					if (this.current_user) {
						this.postUrl = '/users/' + this.current_user.user.username + '/posts/';
					}
				});
			} else {
				this.router.navigateByUrl('/authentication/login');
			}
		});
	}

	public logout(): void {
		this.userService.purgeAuth();
		this.networkService.destroyNetwork();
		localStorage.removeItem('membership');
		localStorage.removeItem('roles');
		localStorage.removeItem('sub_networks');
		// For removing user details and network details from localstorage on logout
		localStorage.removeItem('userDetails');
		CacheUtils.removeStoredData('userDetails');
		localStorage.removeItem('network_settings');
		// -
		this.router.navigateByUrl('/');
	}

	public followUser(): void {
		this.profileService.followUser(this.current_user.user.username).subscribe(() => {
			this.current_user.user.myRelations.following = false;
		});
	}
	public unFollowUser(): void {
		this.profileService.unFollowUser(this.current_user.user.username).subscribe(() => {
			this.current_user.user.myRelations.following = true;
		});
	}
	public onReportClick(): void {
		if (this.current_user.user.myRelations.flagged) {
			this.showErrorModal = 'block';
		} else {
			this.profileService.getReasons().subscribe((data) => {
				this.reasons = data['member'];
				this.showOptionsModal = 'block';
			});
		}
	}
	public onReasonClick(reason, text): void {
		// TODO: use loading spinner
		this.profileService.reportUser(this.profile_id, reason, text).subscribe(() => {
			this.showOptionsModal = 'none';
		});
	}
	public onMuteClick(): void {
		// TODO: use loading spinner
		// const index = this.members.indexOf((element) => element.uid === this.profile_id);
		this.profileService.muteUser(this.current_user.user.userId).subscribe(() => {
			this.showSuccessModal = 'block';
		});
	}

	public onBlockClick(): void {
		// TODO: implement or remove
	}

	public onSuccessModalShow(message): void {
		this.onCloseHandle();
		this.showSuccessModal = 'block';
		this.successMessage = message;
	}

	public onCloseHandle(): void {
		this.showOptionsModal = 'none';
		this.showErrorModal = 'none';
		this.showSuccessModal = 'none';
	}
}
