import * as store from 'store';

export default class CacheUtils {
	/**
	 * Set data to the store
	 *
	 * @param data any
	 * @param key string
	 * @param expire number
	 */
	public static storeData(key: string, data: any, expire: number = 0): void {
		if (data) {
			store.set(key, data);

			if (expire) {
				store.set(`${key}Expire`, Date.now() + expire);
			}
		}
	}

	/**
	 * Get stored data from the store
	 *
	 * @param key string
	 * @param fallbackValue any
	 *
	 * @returns any
	 */
	public static getStoredData(key: string, fallbackValue: any = null): any {
		const storedDataExpire = store.get(`${key}Expire`);

		if (storedDataExpire && storedDataExpire > Date.now()) {
			const storedDataWithExpire = store.get(key);

			if (storedDataWithExpire) {
				return storedDataWithExpire;
			}
		}

		const storedData = store.get(key);

		if (storedData) {
			return storedData;
		}

		return fallbackValue;
	}

	/**
	 * Remove stored data from the store
	 *
	 * @param key string
	 */
	public static removeStoredData(key: string | string[]): void {
		if (Array.isArray(key)) {
			key.forEach((item) => {
				store.remove(item);
				store.remove(`${item}Expire`);
			});

			return;
		}

		store.remove(key);
		store.remove(`${key}Expire`);
	}
}
