import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ChannelComponent } from './channel.component';
import { SharedModule, ChannelService } from '../shared';
import { AgmCoreModule } from '@agm/core';
import { ChannelDetailComponent } from './channel-detail/channel-detail.component';
import { CreateChannelComponent } from './create-channel/create-channel.component';
import { ChannelEditComponent } from './channel-edit/channel-edit.component';
import { PostModule } from '../post/post.module';
import { ArchivedChannelsComponent } from './archived-channels/archived-channels.component';
import { CommonModule } from '@angular/common';
import { BeeAvatarModule } from 'projects/bee-ui/src/lib/modules/avatar/avatar.module';
import { PeerCoreModule } from 'projects/peer-core/src/public-api';

@NgModule({
	imports: [BrowserModule, AgmCoreModule, SharedModule, PostModule, CommonModule, BeeAvatarModule, PeerCoreModule],
	declarations: [ChannelComponent, ChannelDetailComponent, CreateChannelComponent, ChannelEditComponent, ArchivedChannelsComponent],
	bootstrap: [ChannelComponent],
	providers: [ChannelService],
})
export class ChannelModule {}
