import { ISection } from '@shared/models/section.model';
import { Observable, Subject } from 'rxjs';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { ApiService } from '@shared/services/api.service';
import CacheUtils from '@shared/utils/cache-utils';
import { ITodoDTO } from '@shared/models/project/dtos/todo.dto';
import { ITodo, ITodoData } from '@shared/models/todo.model';
import { HttpParams } from '@angular/common/http';
import { DailyTaskCountResponse } from '../models/daily-task-counts';

const BASE_API_PATH = '/networks';

@Injectable({
	providedIn: 'root',
})
export class ProjectService {
	@Output() public newTodoCreatedByExternalSourceEvent: EventEmitter<any> = new EventEmitter();
	@Output() public todoUpdatedByExternalSourceEvent: EventEmitter<any> = new EventEmitter();

	@Output() public onTaskTitleContainerDoubleClickEvent: EventEmitter<any> = new EventEmitter();
	@Output() public onTaskAssigneeListShowClickEvent: EventEmitter<any> = new EventEmitter();
	@Output() public onTaskTitleEditBlurEvent: EventEmitter<any> = new EventEmitter();
	@Output() public onShowDueDateModalClickEvent: EventEmitter<any> = new EventEmitter();
	@Output() public onToggleSubTasksClickEvent: EventEmitter<any> = new EventEmitter();

	@Output() public showToDoActionsEvent: EventEmitter<any> = new EventEmitter();
	@Output() public onEditTaskClickEvent: EventEmitter<any> = new EventEmitter();
	@Output() public onTaskDeleteClickEvent: EventEmitter<any> = new EventEmitter();
	@Output() public onDuplicateClickEvent: EventEmitter<any> = new EventEmitter();
	@Output() public onMoveAnotherTeamClickEvent: EventEmitter<any> = new EventEmitter();
	@Output() public onShareClickEvent: EventEmitter<any> = new EventEmitter();
	@Output() public onTaskTitleCopyClickEvent: EventEmitter<any> = new EventEmitter();
	@Output() public onEditReminderClickEvent: EventEmitter<any> = new EventEmitter();
	@Output() public onSetReminderClickEvent: EventEmitter<any> = new EventEmitter();

	@Output() public handleTaskOpenCloseEvent: EventEmitter<any> = new EventEmitter();
	@Output() public handleTaskStartPauseEvent: EventEmitter<any> = new EventEmitter();

	@Output() public onTaskContainerScrollEvent: EventEmitter<any> = new EventEmitter();

	@Output() public handleTeamAccordionTasksRefresh: EventEmitter<boolean> = new EventEmitter();

	/**
	 * To inform the list-item component that the subtask is edited
	 */
	@Output() public onSubTaskEditEvent: EventEmitter<any> = new EventEmitter();

	public networkId: string | null = null;
	private paginationObservable: Observable<any>;

	public commentTabSelectionAndFocus= new Subject();

	public taskCompleted = new Subject<ITodo>();

	constructor(private apiService: ApiService) {
		this.networkId = CacheUtils.getStoredData('network_id');
	}

	// #region Task

	/**
	 * Get todo details
	 *
	 * @param id string
	 *
	 * @returns Observable<any>
	 */
	public getTask(id: string): Observable<any> {
		return this.apiService.get(`/todos/${id}/`);
	}

	/**
	 * Update task
	 *
	 * @param task any
	 *
	 * @returns : Observable<any>
	 */
	public updateTask(task: ITodo | any): Observable<any> {
		return this.apiService.put(`/todos/${task.uid}/`, task);
	}

	/**
	 * Delete selected task
	 *
	 * @param todoItemId string
	 * @param param 'past' | 'future' | ''
	 * @param username string
	 *
	 * @returns : Observable<any>
	 */
	public deleteTask(todoItemId: string, param: 'past' | 'future' | '', username: string): Observable<any> {
		const baseUrl = `/todos/${todoItemId}/?username=${username}`;

		if (param === 'past') {
			return this.apiService.delete(`${baseUrl}&delete_all=true`);
		} else if (param === 'future') {
			return this.apiService.delete(`${baseUrl}&delete_future=true`);
		} else {
			return this.apiService.delete(`${baseUrl}`);
		}
	}

	// #endregion

	// #region Event Methods

	public newTodoCreatedByExternalSource(todo: any): void {
		this.newTodoCreatedByExternalSourceEvent.emit(todo);
	}

	public todoUpdatedByExternalSource(todo: any): void {
		this.todoUpdatedByExternalSourceEvent.emit(todo);
	}

	// #endregion

	/**
	 * Get pagination data
	 */
	public getPaginationUrlData(url: string): Observable<any> {
		// if (this.paginationObservable) {
		// 	return this.paginationObservable;
		// }

		// this.paginationObservable = this.apiService.get(url);

		// return this.paginationObservable;
		return this.apiService.get(url);
	}

	/**
	 * Get all projects
	 *
	 * @param network_id string
	 * @param query string
	 */
	public getAllProjects(network_id: string, query: string, isArchiveMode: boolean, offset: number = 0, limit: number = 10): Observable<any> {
		query = query ? query : '';
		query = isArchiveMode ? query + '&archived=true' : query;
		query = query + `&offset=${offset}&limit=${limit}`;

		return this.apiService.get(`${BASE_API_PATH}/${network_id}/projects/?${query}`);
	}

	public getArchivedProjectsCount(): Observable<any> {
		return this.apiService.get(`${BASE_API_PATH}/${this.networkId}/project_counts/`);
	}

	/**
	 * Actually this endpoint is calling as a 'todolists' endpoint
	 *
	 * @param network_id string
	 */
	public getTeams(network_id: string, query: string): Observable<any> {
		return this.apiService.get(`${BASE_API_PATH}/${network_id}/todolists/?${query ? 'query=' + query : ''}`);
	}

	/**
	 * Get project details
	 *
	 * @param projectId string
	 */
	public getProject(projectId: string): Observable<any> {
		return this.apiService.get(`${BASE_API_PATH}/${this.networkId}/projects/${projectId}/`);
	}

	public deleteToDoList(networkId: string, id: string): Observable<any> {
		return this.apiService.delete(`/networks/${networkId}/projects/${id}/`);
	}

	/**
	 * Get project sections
	 *
	 * @param uid string
	 * @param limit
	 */
	public getProjectSections(projectId: string, limit: number): Observable<any> {
		return this.apiService.get(`/projects/${projectId}/sections/?${limit ? 'limit=' + limit : ''}`);
	}

	/**
	 * Get project section todos
	 *
	 * @param projectId string
	 * @param filter: any
	 */
	public getProjectSectionTodos(projectId: string, filter: any, offset: number = 0, limit: number = 10): Observable<any> {
		return this.apiService.get(`/sections/${projectId}/todos/?${this.serializeObject(filter)}&offset=${offset}&limit=${limit}`);
	}

	/**
	 * Reorder sections
	 */
	public reOrderSections(params: any): Observable<any> {
		return this.apiService.post('/sections/order/', {
			contents: JSON.stringify(params),
		});
	}

	/**
	 * Get to dos for a given project
	 */
	public getTodosForProject(projectId: string, param: string, isArchived: boolean = false): Observable<any> {
		const baseUrl = `/projects/${projectId}/todos/?${param ? param : ''}&network=${this.networkId}&remove_section_tasks=true`;

		if (param) {
			if (isArchived) {
				return this.apiService.get(`${baseUrl}&archived=true`);
			} else {
				return this.apiService.get(`${baseUrl}`);
			}
		} else {
			if (isArchived) {
				return this.apiService.get(`${baseUrl}&archived=true`);
			} else {
				return this.apiService.get(`${baseUrl}`);
			}
		}
	}

	/**
	 * Universal method for getting todos
	 * Use ITodoDTO interface for the response
	 *
	 * @param projectId string
	 * @param params ITodoDTO
	 *
	 * @returns Observable<any>
	 */
	public getTodosByProject(projectId: string, params: ITodoDTO): Observable<any> {
		return this.apiService.get(`/projects/${projectId}/todos/?${this.serializeObject(params)}`);
	}

	public getTodoCountsByProject(projectId: string, params: ITodoDTO): Observable<any> {
		return this.apiService.get(`/projects/${projectId}/todo_count_dates/?${this.serializeObject(params)}`);
	}

	/**
	 * Get calendar todos
	 */
	public getCalendarTodos(projectId: string, param: string, isArchived: boolean = false): Observable<any> {
		const baseUrl = `/projects/${projectId}/todos/?${param ? param : ''}&network=${this.networkId}&limit=1000`;

		if (param) {
			if (isArchived) {
				return this.apiService.get(`${baseUrl}&archived=true`);
			} else {
				return this.apiService.get(`${baseUrl}`);
			}
		} else {
			if (isArchived) {
				return this.apiService.get(`${baseUrl}&archived=true`);
			} else {
				return this.apiService.get(`${baseUrl}`);
			}
		}
	}

	/**
	 * Get tasks for a given project
	 *
	 * @param projectId project id
	 * @param params query params
	 * @returns Observable<any>
	 */
	public getBoardTasks(projectId: string, params: HttpParams, offset: number = 0, limit: number = 10): Observable<ITodoData> {
		params = params.append('offset', offset.toString());
		params = params.append('limit', limit.toString());

		return this.apiService.get(`/projects/${projectId}/todos/?${params.toString()}`);
	}

	/**
	 * Get user's calendar to dos
	 *
	 * @param filter to do filter
	 */
	public getUsersCalendarTodos(filter: string, username: string): Observable<any> {
		return this.apiService.get(`${BASE_API_PATH}/${this.networkId}/${username}/items_count/?${filter}`);
	}

	/**
	 * Update project details
	 *
	 * @param projectId string
	 * @param data any
	 */
	public updateProject(projectId: string, data: any): Observable<any> {
		return this.apiService.put(`${BASE_API_PATH}/${this.networkId}/projects/${projectId}/`, data);
	}

	// TODO: convert to getUserTasks method
	public newFilterTodos(filter: string, username: string, offset: number = 0, limit: number = 10): Observable<any> {
		return this.apiService.get(`/users/${username}/todos/?newFormat=True&network=${this.networkId}&${filter}&offset=${offset}&limit=${limit}`);
	}

	public newFilterManagerTodos(filter: string, member_uid: string, offset: number = 0, limit: number = 10): Observable<any> {
		return this.apiService.get(`/managers/${member_uid}/team_todos/?newFormat=True&network=${this.networkId}&${filter}&offset=${offset}&limit=${limit}`);
	}

	/**
	 * Create new todo
	 *
	 * @param data context data
	 * @param username string
	 * @param todoListUid string
	 *
	 * @returns Observable<any>
	 */
	public createToDo(data: any, username: string, sectionId: string | null = null): Observable<any> {
		// add to section
		if (sectionId) {
			// FIXME: possible bug
			return this.apiService.post(`/todolists/${sectionId}/todos/`, data);
		}

		// add to user's todo list
		return this.apiService.post(`/users/${username}/todos/?network=${this.networkId}`, data);
	}

	/**
	 * Get sub todos
	 *
	 * @param uid string
	 * @param limit string
	 * @param status string
	 * @param section string
	 *
	 * @returns Observable<any>
	 */
	public getSubtasks(uid: string, limit: string | number, status: string, section: string): Observable<any> {
		const baseUrl = `/todos/${uid}/sub_tasks/?limit=${limit}`;

		if (status === 'open') {
			return this.apiService.get(`${baseUrl}&status=open`);
		} else if (status === 'closed') {
			return this.apiService.get(`${baseUrl}&status=open&newFormat=true&today=true&assigned=true&network=${this.networkId}`);
		} else if (section) {
			return this.apiService.get(`${baseUrl}&remove_section_tasks=true`);
		} else {
			return this.apiService.get(`${baseUrl}`);
		}
	}

	/**
	 * Delete section
	 *
	 * @param uid string
	 * @param param string
	 *
	 * @returns Observable<any>
	 */
	public deleteSection(uid: string, param: string): Observable<any> {
		return this.apiService.delete(`/sections/${uid}/?${param ? 'param=' + param : ''}`);
	}

	/**
	 * Create section for a project
	 *
	 * @param params string
	 *
	 * @returns : Observable<any>
	 */
	public createSection(params: string): Observable<any> {
		return this.apiService.post('/sections/', params);
	}

	/**
	 * Update section
	 *
	 * @param uid string
	 * @param params any
	 *
	 * @returns : Observable<any>
	 */
	public updateSection(uid: string, params: any): Observable<any> {
		return this.apiService.put(`/sections/${uid}/`, params);
	}

	/**
	 * Add todo to target section
	 *
	 * @param uid string
	 * @param params any
	 *
	 * @returns : Observable<any>
	 */
	public addTaskToSection(uid: string, params: any): Observable<any> {
		return this.apiService.put(`/sections/${uid}/`, params);
	}

	/**
	 * Get section details
	 *
	 * @param uid string
	 *
	 * @returns : Observable<any>
	 */
	public getSectionDetail(uid: string): Observable<any> {
		return this.apiService.get(`/sections/${uid}/`);
	}

	/**
	 * Delete todo in selected section
	 *
	 * @param sectionId string
	 * @param taskId string
	 *
	 * @returns : Observable<any>
	 */
	public deleteTaskInSection(sectionId: string, taskId: string): Observable<any> {
		return this.apiService.delete(`/sections/${sectionId}/todo/${taskId}/`);
	}

	/**
	 * Reorder todos
	 *
	 * @param uid string
	 * @param params any
	 *
	 * @returns : Observable<any>
	 */
	public reorderTasks(uid: string, params: any): Observable<any> {
		return this.apiService.post(`/projects/${uid}/todo_order/`, params);
	}

	/**
	 * Move selected task to another section
	 *
	 * @param sourceSection source section
	 * @param task selected task
	 * @param destinationSection destination section
	 *
	 * @returns Observable<any>
	 */
	public moveTaskBetweenSections(sourceSection: ISection, task: ITodo, destinationSection: ISection): Observable<any> {
		const dataBody: any = { uid: destinationSection.uid };

		return this.apiService.put(`/sections/${sourceSection.uid}/todo/${task.uid}/`, dataBody);
	}

	// #region Team (Todolist)

	public getTeamTasks(todolist_uid, filterString, state): Observable<any> {
		if (state) {
			if (filterString) {
				return this.apiService.get('/todolists/' + todolist_uid + '/todos/' + '?archived=true&' + filterString);
			} else {
				return this.apiService.get('/todolists/' + todolist_uid + '/todos/?archived=true');
			}
		} else {
			if (filterString) {
				return this.apiService.get('/todolists/' + todolist_uid + '/todos/' + '?' + filterString);
			} else {
				return this.apiService.get('/todolists/' + todolist_uid + '/todos/');
			}
		}
	}

	public getTeamDetails(id: string, state: boolean): Observable<any> {
		if (state) {
			return this.apiService.get('/todolists/' + id + '/?archived=true');
		} else {
			return this.apiService.get('/todolists/' + id + '/');
		}
	}

	/**
	 * To get the list of members of a team
	 * @param teamId Current team uid
	 * @returns Team Members
	 */
	public getTeamMembers(teamId: string): Observable<any> {
		return this.apiService.get(`/todolists/${teamId}/members/`);
	}

	/**
	 * For team members list pagination
	 * @param url Paginate URL
	 * @returns Paginated results
	 */
	public paginate(url: string): Observable<any> {
		return this.apiService.get(url);
	}

	/**
	 * To get additional team details to be displayed on accordion opening
	 * @param teamId Current team uid
	 * @returns Additional team details
	 */
	public getAdditionalTeamDetails(teamId: string): Observable<any> {
		return this.apiService.get(`/todolists/${teamId}/`);
	}

	/**
	 * Fetch recent team activities/tasks
	 * @param teamId Current Team ID
	 * @returns Recent Tasks list
	 */
	public getTeamRecentTasks(teamId: string): Observable<any> {
		return this.apiService.get(`/todolists/${teamId}/member_activities/`);
	}


	// get task count data for calendar view
	public getTaskCountDataForCalendarView(params: HttpParams): Observable<DailyTaskCountResponse>{
		// eslint-disable-next-line spellcheck/spell-checker
		const url = `/networks/${this.networkId}/todocounts/`;
		return this.apiService.get(url, params);
	}

	// #endregion

	// #region Project View Tab Cache

	public getProjectTabViewType(): string {
		return CacheUtils.getStoredData('projectTabViewState', 'board');
	}

	public setProjectTabViewType(state: string): void {
		return CacheUtils.storeData('projectTabViewState', state);
	}

	// #endregion

	// #region My Tasks View Tab Cache

	public getMyTasksTabViewType(): string {
		return CacheUtils.getStoredData('myTasksTabViewState', 'board');
	}

	public setMyTasksTabViewType(state: string): void {
		return CacheUtils.storeData('myTasksTabViewState', state);
	}

	public getMyTaskListTab(): string {
		return CacheUtils.getStoredData('myTasksListTabState', 'all');
	}

	public setMyTaskListTab(state: string): void {
		return CacheUtils.storeData('myTasksListTabState', state);
	}

	// #endregion

	private serializeObject(obj: any): string {
		const str: any[] = [];

		for (const p in obj) {
			if (obj.hasOwnProperty(p)) {
				str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
			}
		}

		return str.join('&');
	}
}
