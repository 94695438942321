import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { UserService, User, networkTypes, PbTranslateService } from '../shared';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-home-page',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
	private networkTypes: any;
	constructor(private userService: UserService, public translate: PbTranslateService, private router: Router, private activatedRoute: ActivatedRoute) {
		this.networkTypes = networkTypes;
	}
	public isAuthenticated: boolean;
	private currentUser: User;
	private signupError = true;

	public title;
	public membershipList;
	public userNotifications;
	public userNotificationSummary;
	public message;
	public showSignUpMessage = false;
	public nextUrl;
	public paginating;
	public defaultNetworkType;
	public defaultPurchasePlan;
	public showSignupForm = false;
	public appLogo = environment.logo;

	ngOnInit(): void {
		this.userService.isAuthenticated.subscribe((authenticated) => {
			this.isAuthenticated = authenticated;
			if (authenticated) {
				this.title = 'Networks';
				this.membershipList = this.userService.memberships;
				this.membershipList.sort(function (m1, m2) {
					if (m1.network.networkType < m2.network.networkType) {
						return -1;
					} else if (m1.network.networkType > m2.network.networkType) {
						return 1;
					} else {
						return 0;
					}
				});
			} else {
			}
		});
		this.userService.currentUser.subscribe((userData) => {
			this.currentUser = userData;
			// Firebase push notification.
		});

		/**
		 * For Purchase Scenario
		 * 1. Check if url contains network type and purchase plan
		 * 2. Collect network type and purchase plan on url and store it in LS
		 * */
		// this.activatedRoute.queryParams.subscribe(params => {
		//   if (params.networkType) {  // 1
		//     this.showSignupForm = true;
		//     this.defaultNetworkType = params.networkType;
		//     localStorage.setItem('defaultNetworkType',this.defaultNetworkType);  // 2
		//   }
		//   if (params.purchasePlan) {  // 1
		//     this.defaultPurchasePlan = params.purchasePlan;
		//     localStorage.setItem('defaultPurchasePlan',this.defaultPurchasePlan);  // 2
		//   }
		// });

		this.activatedRoute.queryParams.subscribe((params: Params) => {
			// eslint-disable-next-line spellcheck/spell-checker
			if (params['gspk'] && params['gsxid']) {
				// localStorage.setItem('isPartnerStack', 'true');
			}
		});
	}
}
