import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'bee-label-button',
	templateUrl: './label-button.component.html',
	styleUrls: ['./label-button.component.scss'],
})
export class BeeLabelButtonComponent implements OnInit {
	@Output() public click: EventEmitter<Event> = new EventEmitter<Event>();

	constructor() {}

	ngOnInit(): void {}

	public onClick(event: Event): void {
		this.click.emit(event);
	}
}
