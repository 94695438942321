import { Component, OnDestroy, OnInit } from '@angular/core';
import { NetworkService, Post } from '../../shared';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject, Subscription, timer } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { TranslateService } from '@ngx-translate/core';

interface ITimeZone {
	id: number;
	name: string;
	offset: number;
}

@Component({
	selector: 'app-admin-panel',
	templateUrl: './admin-panel.component.html',
	styleUrls: ['./admin-panel.component.css'],
})
export class AdminPanelComponent implements OnInit, OnDestroy {
	public showCustomers = false;
	public spinners = false;
	public meridian = false;
	public starts: any = { hour: 10, minute: 0 };
	public ends: any = { hour: 20, minute: 0 };
	public fileType = [];
	public tab = 'basic';
	public network;
	public quickMessage = false;
	public shareability = false;
	public welcome = false;
	public status = false;
	public timezones: ITimeZone[] = [];
	public element = { name: '' };
	public nextUrl;
	public selected: any = 3;
	public isFetching = 0;
	public currentZone = null;
	public choices = [
		{ slug: 'Local', key: 0 },
		{ slug: 'Region', key: 1 },
		{ slug: 'Country', key: 2 },
		{ slug: 'Global', key: 3 },
	];
	public weekSettings = {
		week_start: 'Monday',
		month_start: '1',
		this_week: 'Till Next Monday',
		work_week: 'Monday-Friday',
	};

	public workdays = [
		{ day: 'Sunday', checked: false },
		{ day: 'Monday', checked: true },
		{ day: 'Tuesday', checked: true },
		{ day: 'Wednesday', checked: true },
		{ day: 'Thursday', checked: true },
		{ day: 'Friday', checked: true },
		{ day: 'Saturday', checked: false },
	];

	public weekStarts = 'Monday';
	public weekEnds = 'Friday';
	public rangeErr = false;
	public departmentForm: FormGroup;
	public messageForm: FormGroup;
	public emailDomainForm: FormGroup;

	public departments = [];
	public customerDepartments = [];
	public selectedDepartment: any = [];
	public messages: any[] = [];
	public settings = {};
	public clear = false;
	public query;
	public emailDomainValue;

	public updateNetworkName = false;
	public updateLogo = false;
	public coverageDialog = false;
	public timezoneDialog = false;
	public departmentDialog = false;
	public departmentsDialog = false;
	public customerSettings = false;
	public workdaysDialog = false;
	public quickMessageDialog = false;
	public servicesDialog = false;
	public emailDomain = false;

	public showAdditionalSettingError = false;

	public services = [
		{ name: 'Add Customer', slug: 'add-customer' },
		{ name: 'Report Issue', slug: 'add-issue' },
		{ name: 'Add Task', slug: 'add-task' },
		{ name: 'Setup Meeting', slug: 'event' },
		{ name: 'Post Something', slug: 'post' },
		{ name: 'Ask A Question', slug: 'question' },
		{ name: 'Recommend Something', slug: 'recommend' },
		{ name: 'Video Conference', slug: 'video-conference' },
	];

	public model = new Post(
		{
			title: '',
			body: '',
			category: null,
			privacy: '',
			images: [],
			preview_images: [],
			shareable: true,
			commentable: true,
			channel: '',
		},
		true
	);

	private subject: Subject<string> = new Subject();
	public confirmModal?: NzModalRef;
	private networkSubscriber: Subscription;

	constructor(
		private networkService: NetworkService,
		private fb: FormBuilder,
		private location: Location,
		private pageLoadingUiService: PageLoadingUiService,
		private nzModalService: NzModalService,
		private translateService: TranslateService
	) {
		this.departmentForm = this.fb.group({
			name: ['', Validators.required],
			// 'abbreviation': ['', Validators.required]
		});

		this.messageForm = this.fb.group({
			name: ['', Validators.required],
		});

		this.emailDomainForm = this.fb.group({
			name: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:.[a-zA-Z]{2,})+')])],
		});
	}

	ngOnInit(): void {
		this.networkSubscriber = this.networkService.networkReady.subscribe((networkLoaded) => {
			if (networkLoaded) {
				this.networkService.currentNetwork.subscribe((network) => {
					this.pageLoadingUiService.setPageLoading(false);
					this.network = network;
					this.checkIfCustomer();
					// eslint-disable-next-line spellcheck/spell-checker
					this.shareability = network['sharability'];

					if (network['welcome']) {
						this.welcome = true;
					} else {
						this.welcome = false;
					}

					if (network['timezone']) {
						this.currentZone['name'] = network['timezone'];
						this.selectedZone['name'] = network['timezone'];
					}

					if (network['coverage']) {
						this.selected = network['coverage'];
					}

					if (network['quickMessages']) {
						this.quickMessage = network['quickMessages'];
					}

					if (network['statusChangeNotification']) {
						this.status = network['statusChangeNotification'];
					}
				});
			}
		});

		if (this.network && this.network['uid']) {
			this.networkService.getDepartments(this.network['uid']).subscribe((data) => {
				this.customerDepartments = data.objects;
				this.customerDepartments[0] = { name: 'All' };
				this.checkCustomerSettings(this.network);

				if (data.next) {
					this.nextUrl = data.next.split('alpha')[1];
					this.paginate();
				} else {
					this.nextUrl = '';
				}
			});

			this.networkService.getDomains().subscribe((data) => {
				if (data.results.length) {
					this.emailDomainValue = data['results'][0];
					this.emailDomainForm.setValue({ name: data['results'][0].name });
				}
			});
		}

		this.getServices();
		this.getMessages();

		this.subject.pipe(debounceTime(1500)).subscribe((query) => {
			this.networkService.searchTimeZone(query).subscribe((data: any) => {
				this.timezones = <ITimeZone[]>data.objects;
				this.nextTimeZoneURL = data?.next?.split('alpha')[1];
				this.isFetching = 1;
			});
		});
	}

	ngOnDestroy(): void {
		this.networkSubscriber.unsubscribe();
	}

	public updateCompanyName(): void {
		if (this.element['name']) {
			this.pageLoadingUiService.setPageLoading(true);
			this.networkService.updateNetwork(this.element['name'], this.network, 'name').subscribe(
				() => {
					this.pageLoadingUiService.setPageLoading(false);
					this.updateNetworkName = false;
					this.networkService.populate();
				},
				() => {
					this.pageLoadingUiService.setPageLoading(false);
					this.updateNetworkName = false;
				}
			);
		} else {
			this.updateNetworkName = false;
		}
	}

	public updateCompanyLogo(): void {
		this.pageLoadingUiService.setPageLoading(true);
		this.model.images = this.model.images.join('|');
		this.networkService
			.updateNetwork(this.model.images, this.network, 'logo')
			.subscribe(
				() => {
					this.reset();
					timer(1000).subscribe(() => {
						this.networkService.populate();
					});
				},
				(error: any) => this.onUpdateTimeZoneError(error)
			)
			.add(() => {
				this.pageLoadingUiService.setPageLoading(false);
				this.updateLogo = false;
			});
	}

	public updateShareability(): void {
		this.shareability = !this.shareability;
		this.pageLoadingUiService.setPageLoading(true);

		// eslint-disable-next-line spellcheck/spell-checker
		this.networkService.updateNetwork(this.shareability, this.network, 'sharability').subscribe(
			() => {
				this.reset();
				this.networkService.populate();
			},
			() => {
				this.pageLoadingUiService.setPageLoading(false);
				this.updateLogo = false;
			}
		);
	}

	public updateWelcome(): void {
		this.welcome = !this.welcome;
		this.pageLoadingUiService.setPageLoading(true);
		this.networkService.updateNetwork(this.welcome, this.network, 'welcome').subscribe(
			() => {
				this.reset();
				this.networkService.populate();
			},
			() => {
				this.pageLoadingUiService.setPageLoading(false);
				this.updateLogo = false;
			}
		);
	}

	public updateQuickMessage(): void {
		this.quickMessage = !this.quickMessage;
		this.pageLoadingUiService.setPageLoading(true);
		this.networkService.updateNetwork(this.quickMessage, this.network, 'quickMessages').subscribe(
			() => {
				this.reset();
				this.networkService.populate();
			},
			() => {
				this.pageLoadingUiService.setPageLoading(false);
				this.updateLogo = false;
			}
		);
	}

	public updateCoverage(): void {
		this.pageLoadingUiService.setPageLoading(true);
		this.networkService.updateNetwork(this.selected, this.network, 'coverage').subscribe(
			() => {
				this.reset();
				this.pageLoadingUiService.setPageLoading(false);
				this.networkService.populate();
			},
			() => {
				this.pageLoadingUiService.setPageLoading(false);
				this.updateLogo = false;
			}
		);
	}

	public updateStatusChange(): void {
		this.status = !this.status;
		this.pageLoadingUiService.setPageLoading(true);
		this.networkService.updateNetwork(this.status, this.network, 'statusChangeNotification').subscribe(
			() => {
				this.reset();
				this.networkService.populate();
			},
			() => {
				this.pageLoadingUiService.setPageLoading(false);
			}
		);
	}

	public getDepartments(): void {
		if (this.network && this.network['uid']) {
			this.departmentsDialog = true;
			this.departments = [];
			this.isFetching = 0;
			this.networkService.getDepartments(this.network['uid']).subscribe(
				(data) => {
					this.departments = data.objects;
					this.isFetching = 1;
					if (data.next) {
						this.nextUrl = data.next.split('alpha')[1];
						this.paginate();
					} else {
						this.nextUrl = '';
					}
				},
				() => {
					this.departments = [];
					this.isFetching = 1;
				}
			);
		}
	}

	public paginate(): void {
		if (this.nextUrl) {
			this.networkService.paginateIssues(this.nextUrl).subscribe((data) => {
				if (data) {
					this.departments = this.departments.concat(data['objects']);
					this.checkCustomerSettings(this.network);
					if (data.next) {
						this.nextUrl = data.next.split('alpha')[1];
					} else {
						this.nextUrl = '';
					}
				}
			});
		}
	}

	public removeDepartment(department, index): void {
		this.pageLoadingUiService.setPageLoading(true);
		this.networkService.deleteDepartment(department['uid']).subscribe(
			() => {
				this.departments.splice(index, 1);
				this.pageLoadingUiService.setPageLoading(false);
				this.departmentDialog = false;
			},
			() => {
				this.pageLoadingUiService.setPageLoading(false);
			}
		);
	}

	public createDepartment(): void {
		if (this.departmentForm.valid) {
			this.pageLoadingUiService.setPageLoading(true);
			this.networkService.createDepartment(this.departmentForm.value).subscribe(
				() => {
					this.pageLoadingUiService.setPageLoading(false);
					this.resetDepartment();
				},
				() => {
					this.pageLoadingUiService.setPageLoading(false);
				}
			);
		} else {
			this.departmentDialog = false;
		}
	}

	public resetDepartment(): void {
		this.departmentForm.reset();
		this.departmentDialog = false;
	}

	public createQuickMessage(): void {
		if (this.messageForm.valid) {
			this.pageLoadingUiService.setPageLoading(true);
			this.networkService.createNewMessage(this.messageForm.value).subscribe(
				() => {
					this.messageForm.reset();
					this.pageLoadingUiService.setPageLoading(false);
					this.getMessages();
				},
				() => {
					this.pageLoadingUiService.setPageLoading(false);
				}
			);
		} else {
			this.quickMessageDialog = false;
		}
	}

	public resetMessageForm(): void {
		this.messageForm.reset();
	}

	public deleteQuickMessage(message, i): void {
		this.pageLoadingUiService.setPageLoading(true);
		this.networkService.deleteQuickMessage(message['slug']).subscribe(
			() => {
				this.pageLoadingUiService.setPageLoading(false);
				this.messages.splice(i, 1);
			},
			() => {
				this.pageLoadingUiService.setPageLoading(false);
			}
		);
	}

	public onSelectDepartment(department: any): void {
		if (department['uid']) {
			const i = this.selectedDepartment.findIndex((a) => a['name'] === 'All');

			if (i > -1) {
				this.selectedDepartment.splice(i, 1);
			}

			this.customerDepartments[0]['checked'] = false;
			const index = this.selectedDepartment.findIndex((_department) => _department === department['uid']);

			if (index > -1) {
				this.selectedDepartment.splice(index, 1);
			} else {
				this.selectedDepartment.push(department['uid']);
			}
		} else {
			this.selectedDepartment = [];
			this.customerDepartments.forEach((_department) => {
				_department['checked'] = false;
			});

			this.customerDepartments[0]['checked'] = true;
			this.selectedDepartment.push(department);
		}
	}

	public handleCustomerSettings(): void {
		this.pageLoadingUiService.setPageLoading(true);

		if (this.selectedDepartment && this.selectedDepartment[0] && this.selectedDepartment[0]['name'] !== 'All') {
			const selectedDepartments = [];
			this.selectedDepartment.forEach((c) => {
				if (!selectedDepartments.includes(c)) {
					selectedDepartments.push(c);
				}
			});
			const a = '{"department": ' + JSON.stringify(selectedDepartments) + '}';
			const b = '{"customer":' + a + '}';
			this.settings['privacy_settings'] = b;
		} else {
			this.settings = {};
		}

		this.networkService.updateCustomerSettings(this.settings).subscribe((data) => {
			this.networkService.populate();
			this.selectedDepartment = [];
			this.customerDepartments.forEach((department) => {
				department['checked'] = false;
			});
			this.settings = {};
			this.customerSettings = false;
			this.pageLoadingUiService.setPageLoading(false);
			this.checkCustomerSettings(data);
		});
	}

	public checkCustomerSettings(network): void {
		if (this.customerDepartments && this.customerDepartments.length) {
			this.customerDepartments.forEach((li) => {
				li['checked'] = false;
			});
		}
		if (network && network.privacySettings) {
			if (network.privacySettings['customer']['department']) {
				const arr = network.privacySettings['customer']['department'];
				if (arr.length > 0) {
					arr.forEach((id) => {
						this.customerDepartments.forEach((department) => {
							if (department['uid'] === id) {
								department['checked'] = true;
								this.selectedDepartment.push(id);
							}
						});
					});
				}
			}
		} else {
			this.customerDepartments[0]['checked'] = true;
			this.selectedDepartment.push({ name: 'All' });
		}
	}

	public reset(): void {
		// this.pageLoadingUiService.setPageLoading(false);
		this.coverageDialog = false;
		this.timezoneDialog = false;
		this.workdaysDialog = false;
		this.model.images = [];
		this.model.preview_images = [];
		this.model['name'] = '';
		this.updateNetworkName = false;
		this.updateLogo = false;
	}

	public reminderSettings(): void {
		this.workdaysDialog = true;
	}

	public handleWorkDays(): void {
		if (!this.rangeErr) {
			this.weekSettings['week_start'] = this.weekStarts;
			this.weekSettings['work_week'] = this.weekStarts + '-' + this.weekEnds;
			this.weekSettings['this_week'] = 'Till Next ' + this.weekStarts;
			if (this.starts) {
				this.weekSettings['work_day_starts'] = this.starts['hour'] + ':' + this.starts['minute'];
			}
			if (this.ends) {
				this.weekSettings['work_day_ends'] = this.ends['hour'] + ':' + this.ends['minute'];
			}

			this.pageLoadingUiService.setPageLoading(true);
			this.networkService.updateNetwork(JSON.stringify(this.weekSettings), this.network, 'reminderSettings').subscribe(
				() => {
					this.reset();
					this.pageLoadingUiService.setPageLoading(false);
					this.networkService.populate();
				},
				() => {
					this.pageLoadingUiService.setPageLoading(false);
					this.workdaysDialog = false;
				}
			);
		} else {
			this.workdaysDialog = false;
		}
	}

	public onChangeDays(): void {
		if (this.weekStarts === this.weekEnds) {
			this.rangeErr = true;
		} else {
			this.rangeErr = false;
		}
	}

	private getMessages(): void {
		this.networkService.getQuickMessages().subscribe((data) => {
			this.messages = data['postCategories'];
		});
	}

	private getServices(): void {
		this.networkService.getServices().subscribe((data) => {
			this.services = this.services.map((service: any) => {
				const index = data.findIndex((i: any) => i?.slug === service?.slug);
				service['checked'] = index > -1 ? true : false;

				switch (service?.slug) {
					case 'message':
						const i = this.services.indexOf(service);
						this.services.splice(i, 1);
						break;

					case 'add-customer':
						if (this.network?.subscription?.planQuotas?.numberOfCustomers === -1) {
							const x = this.services.indexOf(service);
							this.services.splice(x, 1);
							break;
						}
				}

				return service;
			});

			// const index = this.services.findIndex((service) => service['slug'] === 'message');
			// if (index > -1) {
			// 	this.services.splice(index, 1);
			// }
			// this.services = data;
			// this.services.forEach((service) => {
			// this.network['services'].forEach((_service) => {
			// if (_service['slug'] === service['slug']) {
			// 	service['checked'] = true;
			// }
			// });
			// 	service['checked'] = this.network.services.includes(service);
			// 	if (service.slug === 'add-customer' && this.network?.subscription?.planQuotas?.numberOfCustomers === -1) {
			// 		const i = this.services.indexOf(service),
			// 			x = this.network.services.indexOf(service);
			// 		this.services.splice(i, 1);
			// 		this.network.services.splice(x, 1);
			// 	}
			// });
		});
	}

	public handleNetworkService(service): void {
		this.pageLoadingUiService.setPageLoading(true);
		if (service['checked']) {
			this.networkService.deleteService(service['slug']).subscribe(
				() => {
					this.networkService.populate();
				},
				() => {
					this.pageLoadingUiService.setPageLoading(false);
				}
			);
		} else {
			this.networkService.updateService(service['slug']).subscribe(
				() => {
					this.networkService.populate();
				},
				() => {
					this.pageLoadingUiService.setPageLoading(false);
				}
			);
		}
	}

	public createEmailDomainForm(): void {
		this.pageLoadingUiService.setPageLoading(true);

		this.networkService.createDomain(this.emailDomainForm.value).subscribe((res) => {
			// pass
			this.pageLoadingUiService.setPageLoading(false);
			this.emailDomainValue = res;
		});
	}

	public resetEmailDomainForm(): void {
		if (!this.emailDomainValue) {
			this.emailDomainForm.reset();
		}
		this.emailDomain = false;
	}

	public deleteEmailDomain(): void {
		this.pageLoadingUiService.setPageLoading(true);
		this.networkService.deleteDomain(this.emailDomainValue.id).subscribe(() => {
			this.pageLoadingUiService.setPageLoading(false);
			this.emailDomainForm.reset();
			this.emailDomainValue = null;
		});
	}

	private checkIfCustomer(): void {
		const index = this.network['services'].findIndex((service) => service['slug'] === 'add-customer');
		if (index > -1) {
			this.showCustomers = true;
		} else {
			this.showCustomers = false;
		}
	}

	public onClickAdvancedTab(): void {
		console.log(this.network);
		if (this.checkAdvanceSectionIsEnabled()) {
			this.tab = 'advanced';
			this.showAdditionalSettingError = false;
		} else {
			this.showAdditionalSettingError = true;
			this.tab = 'basic';
		}

		// this.tab ='advanced';
	}

	/**
	 * Function to check the plan name
	 * @returns
	 */
	public checkAdvanceSectionIsEnabled(): boolean {
		if (
			this.network &&
			this.network.subscription &&
			this.network.subscription.network &&
			this.network.subscription.network.subscription &&
			this.network.subscription.network.subscription.subscriptionPlan
		) {
			if (
				// this.network?.subscription?.planQuotas?.numberOfTodos !== 0 ||
				this.network?.subscription?.network?.subscription?.subscriptionPlan?.name === 'Basic' ||
				this.network?.subscription?.network?.subscription?.subscriptionPlan?.name === 'Premium' ||
				this.network?.subscription?.network?.subscription?.subscriptionPlan?.name === 'Starter'
			) {
				return false;
			} else {
				return true;
			}
		} else {
			return false;
		}
	}

	public showWhoCanSeeCustomers(): any {
		const privacy: any[] = [];
		if (
			this.network &&
			this.network.privacySettings &&
			this.network.privacySettings.customer &&
			this.network.privacySettings.customer.department &&
			this.network.privacySettings.customer.department.length &&
			this.customerDepartments &&
			this.customerDepartments.length
		) {
			this.network.privacySettings.customer.department.forEach((li) => {
				this.customerDepartments.forEach((department) => {
					if (department.uid === li) {
						privacy.push(department);
					}
				});
			});
		}
		return privacy;
	}

	public checkCustomerDepartmentIsSelected(): boolean {
		if (this.customerDepartments && this.customerDepartments.length) {
			const selected = this.customerDepartments.find((li) => li.checked);
			if (selected) {
				return true;
			} else {
				return false;
			}
		}
	}

	public showNetworkCoverage(): string {
		if (this.network) {
			if (this.network.coverage) {
				const coverage: any = this.choices.find((li) => li.key === this.network.coverage);
				if (coverage) {
					return coverage.slug;
				} else {
					return 'Global';
				}
			}
		}
	}

	public onClickBack(): void {
		this.location.back();
	}

	// #region Time Zone

	public selectedZone = null;

	public updateTimezone(): void {
		if (this.selectedZone?.name) {
			this.pageLoadingUiService.setPageLoading(true);
			this.networkService.updateNetwork(this.selectedZone?.name, this.network, 'timezone').subscribe(
				() => {
					this.reset();
					this.networkService.populate();
					this.currentZone = this.selectedZone;
				},
				(err: any) => {
					this.onUpdateTimeZoneError(err);
					this.pageLoadingUiService.setPageLoading(false);
					this.timezoneDialog = false;
				}
			);
		} else {
			this.timezoneDialog = false;
		}
	}

	public setSubject(e): void {
		if (e?.target?.value) {
			this.timezones = [];
			this.isFetching = 0;
			this.clear = true;
			const text = e.target.value;
			this.subject.next(text.trim());
		} else {
			this.clear = false;
			this.fetchTimezones();
		}
	}

	public clearSearch(): void {
		this.clear = false;
		this.query = '';
		this.fetchTimezones();
	}

	private nextTimeZoneURL = '';
	public displayTimezones(): void {
		this.fetchTimezones();
		this.timezoneDialog = true;
		this.query = '';
	}

	public fetchTimezones(): void {
		this.isFetching = 0;
		this.networkService.getTimezones().subscribe((timezones) => {
			if (timezones) {
				this.timezones = <ITimeZone[]>timezones.objects;
				this.nextTimeZoneURL = timezones.next ? timezones?.next?.split('alpha')[1] : '';
			}

			this.isFetching = 1;
		});
	}

	public paginateTimeZones(): void {
		if (this.nextTimeZoneURL) {
			this.isFetching = 0;
			this.networkService.paginateTimeZone(this.nextTimeZoneURL).subscribe((data) => {
				if (data) {
					this.timezones = this.timezones.concat(<ITimeZone[]>data.objects);
					if (data.next) {
						this.nextTimeZoneURL = data?.next?.split('alpha')[1];
					} else {
						this.nextTimeZoneURL = '';
					}

					this.isFetching = 1;
				}
			});
		}
	}

	public closeTimeZoneModal(): void {
		this.selectedZone = this.currentZone;
		this.timezoneDialog = false;
	}

	/**
	 * Handle error messages from updating timeZone to display error modal
	 * @param error Error Object
	 */
	public onUpdateTimeZoneError(err: any): void {
		this.confirmModal = this.nzModalService.error({
			nzTitle: this.translateService.instant('Error'),
			nzContent: err?.errors?.error?.detail ? err?.errors?.error?.detail : '',
			nzOnOk: () => {},
		});
	}

	// #endregion
}
