import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TEXTBOX_VALUE_ACCESSOR } from '../../../shared/providers/textbox-accessor';
import { AbstractControl } from '@angular/forms';
import { BeeError } from '../../../shared/models/custom-error';
import { FormServiceService } from '../../../shared/services/form-service.service';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { TimezoneCountryService } from '../../../shared/services/timezone-country.service';
import { ICountry } from '../../../shared/models/country';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'bee-tel-input',
	templateUrl: './tel-input.component.html',
	styleUrls: ['./tel-input.component.scss'],
	providers: [TEXTBOX_VALUE_ACCESSOR],
})
export class BeeTelInputComponent implements AfterViewInit {
	@Input() public id?: string;
	@Input() public placeholder?: string;
	@Input() public formControlName?: string;
	@Input() public title?: string;
	@Input() public required = false;
	@Input() public minLength?: number;
	@Input() public maxLength?: number;
	@Input() public size: 'sm' | 'md' | 'lg' = 'md';
	@Input() public control: AbstractControl;
	@Input() public cleanValue = true;
	@Input() public numberFormat: PhoneNumberFormat;

	@Output() public countryChanged: EventEmitter<{ county: ICountry }> = new EventEmitter<{ county: ICountry }>();

	public errors: BeeError[] = [];

	// phone input
	public SearchCountryField = SearchCountryField;
	public CountryISO = CountryISO;
	public preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
	public selectedCountryISO: CountryISO = CountryISO.UnitedStates;

	constructor(private formServiceService: FormServiceService, private timezoneCountryService: TimezoneCountryService) {}

	public ngAfterViewInit(): void {
		this.control.statusChanges.subscribe((status: string) => {
			if (status === 'VALID') {
				this.errors = [];
			} else if (status === 'INVALID') {
				this.errors = this.formServiceService.handleFormErrors(this.control, {
					validatePhoneNumber: this.numberFormat,
				});
			}
		});

		const timezoneCountry = this.timezoneCountryService.getTimezoneCountry();

		if (timezoneCountry && timezoneCountry.country) {
			this.selectedCountryISO = CountryISO[timezoneCountry.country.name];

			this.countryChanged.emit(<any>{
				country: timezoneCountry.country,
				event: null,
			});
		}
	}

	public onCountryChange(event: any): void {
		const country = this.timezoneCountryService.findCountry(event.iso2);

		if (country) {
			this.countryChanged.emit(<any>{
				event: event,
				country: country,
			});
		}
	}
}
