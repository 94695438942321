import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class PreviousRouteService {
	private previousURLSubject = new BehaviorSubject<string>(null);
	public previousURL = this.previousURLSubject.asObservable().pipe(distinctUntilChanged());

	/**
	 * Function to set previous URL to behavior subject
	 * @param url Previous URL
	 */
	public setPreviousURL(url: string): void {
		this.previousURLSubject.next(url);
	}
}
