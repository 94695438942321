import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable()
export class FileService {
	constructor(private apiService: ApiService) {}

	public upload(files): Observable<any> {
		return this.apiService.upload('/uploads/', files);
	}

	public uploadProfileImage(files): Observable<any> {
		return this.apiService.upload('/users/' + localStorage.getItem('uid') + '/images/', files);
	}
}
