import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-plus-button',
	templateUrl: './plus-button.component.html',
	styleUrls: ['./plus-button.component.css'],
})
export class PlusButtonComponent implements OnInit {
	@Output() public clickActionEvent: EventEmitter<any> = new EventEmitter<any>();
	@Input() public route: string[];
	@Input() public isActive = false;

	constructor() {}

	ngOnInit(): void {}

	public clickAction(): void {
		this.clickActionEvent.emit();
	}
}
