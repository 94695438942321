export interface IImageAttachmentUI {
	isLoading?: boolean;
	data?: any;
}

export interface IImageAttachment extends IImageAttachmentUI {
	id?: string | number;
	name?: string;
	url?: string;
	type?: string;
	thumbnails?: IImageAttachment[];
}

export class ImageAttachment implements IImageAttachment {
	public id?: string | number;
	public name?: string;
	public url?: string;
	public isLoading = false;
	public data: any = null;
	public thumbnails?: IImageAttachment[] = [];

	constructor(values: IImageAttachment = {}) {
		Object.assign(this, values);
	}
}
