import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { UserComponent } from './user.component';
import { SharedModule } from '../shared';
import { AgmCoreModule } from '@agm/core';

const networkRouting: ModuleWithProviders<any> = RouterModule.forChild([
	{
		path: 'profile/:memberId',
		component: UserComponent,
	},
]);

@NgModule({
	imports: [networkRouting, BrowserModule, AgmCoreModule, SharedModule],
	declarations: [UserComponent],
	bootstrap: [UserComponent],
	providers: [],
})
export class UserModule {}
