import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-comment-list',
	templateUrl: './comment-list.component.html',
	styleUrls: ['./comment-list.component.scss']
})
export class CommentListComponent implements OnInit {

	constructor() { }

	ngOnInit(): void {
	}

}
