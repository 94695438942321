export class LocationObj {
	public uid: number;
	public name: '';
	public website: '';
	public averageRating: number;
	public checkinCount: string;
	public country: '';
	public state: string;
	public image: any;
	public phone: '';
	public reviews: any;
	public longitude: any;
	public latitude: any;
	public source: any;
	public distance: any;
	public category: string;
	public lastReview: any;
	public venueId: '';
	public ratingSignal: any;
	// eslint-disable-next-line spellcheck/spell-checker
	public formatedDistance: any;
	public address: string;

	constructor(checkin: any, type = 'loc') {
		if (type === 'loc') {
			for (const key of Object.keys(checkin)) {
				if (key in checkin) {
					this[key] = checkin[key];
				}
			}
			// eslint-disable-next-line spellcheck/spell-checker
			this.formatedDistance = Number(this.distance / 1000).toFixed(2) + ' km away';
			if (this.lastReview) {
				this.lastReview = '<b>' + this.lastReview.user.name + '</b> said ' + this.lastReview.body;
			}
		} else {
			for (const key of Object.keys(checkin.venue)) {
				if (key in checkin.venue) {
					if (key === 'categories') {
						// this['category'] = checkin.venue[key][0]['name']
						this['category'] = checkin.venue[key][0]['id'];
						this['categoryName'] = checkin.venue[key][0]['name'];
					} else if (key === 'stats') {
						if (checkin.venue[key]['checkinsCount']) {
							this['checkinCount'] = checkin.venue[key]['checkinsCount'] + ' Checked in here';
						} else {
							this['checkinCount'] = '';
						}
					} else if (key === 'rating') {
						this['averageRating'] = checkin.venue[key];
					} else if (key === 'ratingSignal') {
						this[key] = checkin.venue[key];
					} else if (key === 'location') {
						this['distance'] = checkin.venue[key]['distance'];
						// eslint-disable-next-line spellcheck/spell-checker
						this.formatedDistance = Number(checkin.venue[key]['distance'] / 1000).toFixed(2) + ' km away';
						this['latitude'] = checkin.venue.location.lat;
						this['longitude'] = checkin.venue.location.lng;
					} else if (key === 'name') {
						this[key] = checkin.venue[key];
					} else if (key === 'url') {
						this['website'] = checkin.venue[key];
					} else if (key === 'id') {
						this['venueId'] = checkin.venue['id'];
					}
				}
			}
		}
	}
}
