import { LocationPreviewModule } from './../location-preview/location-preview.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttachmentBodyComponent } from './attachment-body.component';
import { ImagePreviewModule } from '../image-preview/image-preview.module';
import { UrlPreviewModule } from '../url-preview/url-preview.module';
import { FilePreviewModule } from '../file-preview/file-preview.module';
import { PersonPreviewModule } from '../person-preview/person-preview.module';
import { EventPreviewModule } from '../event-preview/event-preview.module';

@NgModule({
	declarations: [AttachmentBodyComponent],
	imports: [CommonModule, ImagePreviewModule, UrlPreviewModule, FilePreviewModule, PersonPreviewModule, EventPreviewModule, LocationPreviewModule],
	exports: [AttachmentBodyComponent],
})
export class AttachmentBodyModule {}
