import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { ProfilesService } from '../services/profiles.service';
import { NetworkService } from '../services/network.service';
import { MemberService } from '../services/member.service';
import { LocationObj, Network } from '../models';
import { GeoLocationService, LocationService, LayoutService } from '../services';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { InboxSocketService } from '../../shared/services/inbox-socket.service';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';
import { LightboxService } from 'projects/peer-core/src/lib/services/ui/lightbox.service';
import { IImageAttachment, ImageAttachment } from 'projects/bee-attachment/src/lib/shared/models/image-attachment';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-user-profile',
	templateUrl: './user-profile.component.html',
	styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
	public searchQuery;
	public nextUrl;
	public notifications;
	public currentUser;
	public isAuthenticated;
	public settings = {};
	public showSpin = false;
	public userNotificationSummary;
	public channelMembershipList;
	public categoryFilters;
	public roleObject: any;
	public profile_id;
	public is_same_user = false;
	public currentProfile;
	public postUrl;
	public next_url;
	public mutedStatus = false;
	public reasons;
	public showOptionsModal = 'none';
	public showErrorModal = 'none';
	public showSuccessModal = 'none';
	public successMessage = '';
	public selectedTab: 'activity' | 'posts' | '' = 'activity';
	public selectedReason: { reason: any; text: any } = null;
	public reportModalDisplay = false;
	public nextModalDisplay = false;
	public showSettings = '';
	public availabilityStatusChoices: any[] = [];
	public roles: any[] = [];
	public networkId = '';
	public profileFields: any[] = [];
	public userProfileInfo: any;
	public userProfileFields: any[] = [];
	public muteUid;

	public bioLineEditValue = '';
	public statusEditValue = '';
	public editRole: any;
	public editRoleFields: any[] = [];
	public currentLocation: any;
	public locationsList: any[] = [];
	public locationDisplay = 'none';
	public verificationEmail: any;
	public validationRule: any;
	public roleDetailError = '';
	public email = '';
	public userMuted = false;

	public updateRolePage = false;
	public currentRole;
	public isLoading = true;
	public roleFields = [];
	public myFormGroup: FormGroup;
	public roleFormGroup: FormGroup;

	public selectedDepartment: any;
	public selectedLocation: any;
	public selectedMember: any;
	public displayDepartmentsModal = 'none';
	public displayLocationsModal = 'none';
	public displayMembersModal = 'none';

	public departments = [];
	public locations = [];
	public members = [];

	public noDepartments = false;
	public noLocations = false;
	public noMembers = false;
	public paramsExists = false;

	public department = { uid: '' };
	public location = { uid: '' };
	public member = { id: '' };
	public displayError = 'none';
	public modelText: any;
	public selectedRole: any;
	public network;
	public isExternalUser = false;
	public onlineStack = [];

	public networkServices: any[] = [];
	public isAddPostIsAvailable = false;
	public isAddTaskIsAvailable = false;

	private currentMemberUsername: string;
	public leaveNetworkModal = 'none';
	public isExpired = false;
	public membershipUid: string;
	public loadingGif = 'none';
	public appLogo = environment.logo;

	constructor(
		public userService: UserService,
		private profileService: ProfilesService,
		private socket: InboxSocketService,
		private activatedRoute: ActivatedRoute,
		private networkService: NetworkService,
		private memberService: MemberService,
		private geoLocationService: GeoLocationService,
		private locationService: LocationService,
		private ref: ChangeDetectorRef,
		private _location: Location,
		private layoutService: LayoutService,
		private pageLoadingUiService: PageLoadingUiService,
		private lightboxService: LightboxService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.activatedRoute.params.subscribe((params: Params) => {
			this.profile_id = params['memberId'];
		});

		this.activatedRoute.queryParams.subscribe((queryParams: Params) => {
			/**
			 * To get selected profile's username from queryParams
			 */
			if (queryParams['username']) {
				this.currentMemberUsername = queryParams['username'];
			}

			/**
			 * Handle additional states and manage functionality accordingly
			 * 1. Check for role update requirement
			 * 2. Check for edit member requirement
			 */
			if (queryParams['roleUpdate']) {
				// 1
				this.showSettings = 'all';
			} else if (queryParams['editMember']) {
				// 2
				this.showSettings = 'all';
				setTimeout(() => {
					this.onRoleUpdateClick();
				}, 50);
			}
		});

		this.userService.currentUser.subscribe((userData) => {
			this.currentUser = userData;
		});

		this.networkService.currentNetwork.subscribe((network: any) => {
			this.network = network;
			this.networkServices = network?.services ? network?.services : [];
			this.availabilityStatusChoices = network['availabilityStatusChoices'];
			this.roles = network?.roles;
			this.networkId = network?.uid;

			if (network?.membership?.memberRole?.fields) {
				this.profileFields = network?.membership?.memberRole?.fields;

				const index = this.profileFields.findIndex((field) => field?.key === 'manager'),
					department = this.profileFields[this.profileFields.findIndex((field: any) => field?.type === 'department')];

				if (index > -1) {
					this.profileFields.splice(index, 1);
				}

				this.selectedDepartment = {
					name: department?.valueLabel,
					uid: department?.value,
				};
			}

			const memberRole = network?.membership?.memberRole?.role;

			if (memberRole?.slug === 'external') {
				this.isExternalUser = true;
			}

			if (network?.services && network?.services?.length > 0) {
				const addTask = network?.services.some((li: any) => li?.slug === 'add-task'),
					addPost = network?.services.some((li: any) => li?.slug === 'post');

				this.isAddPostIsAvailable = addPost ? true : false;
				this.isAddTaskIsAvailable = addTask ? true : false;
			}
		});

		this.getMemberShipDetails();

		const self = this;
		let locations = [];
		const accuracy = { enableHighAccuracy: true };

		self.geoLocationService.getLocation(accuracy).subscribe((position: any) => {
			self.currentLocation = position;

			self.locationService.getNearMeLocations(position.coords).subscribe((data) => {
				locations = data.response.groups[0].items.map((loc) => new LocationObj(loc, '4sq'));

				self.locations = self.sortData(locations);
			});

			self.ref.detectChanges();
		});

		/**
		 * For showing online/offline statues of post authors
		 * 1. Emit set user data
		 * 2. Get call back from onlineStack event and store online stack data
		 */
		if (this.socket.getSocket().ioSocket.connected) {
			// 1
			this.socket.getSocket().emit('set-user-data', { username: this.currentUser.username, networkId: this.network.uid }, () => {});
		}

		this.socket.getSocket().on('onlineStack', (members) => {
			// 2
			this.onlineStack = members;
		});

		if (!this.isAddTaskIsAvailable && this.isAddPostIsAvailable) {
			this.selectedTab = 'posts';
		} else if (!this.isAddTaskIsAvailable && !this.isAddPostIsAvailable) {
			this.selectedTab = '';
		}

		this.isExpired = localStorage.getItem('networkExpired') ? true : false;

		if (localStorage.getItem('membership')) {
			const membership = JSON.parse(localStorage.getItem('membership'));
			this.membershipUid = membership['uid'];
		}
	}

	private sortData(data: any): any {
		return data.sort(function (m1, m2) {
			if (m1.distance < m2.distance) {
				return -1;
			} else if (m1.distance > m2.distance) {
				return 1;
			} else {
				return 0;
			}
		});
	}

	private getMemberShipDetails(): void {
		this.pageLoadingUiService.setPageLoading(true);

		this.profileService.getMemberShip(this.currentMemberUsername, this.profile_id).subscribe(
			(profile) => {
				this.currentProfile = profile;
				this.userProfileInfo = this.currentProfile.memberRole.info;

				if (this.currentProfile['memberRole'] && this.currentProfile['memberRole']['fields'].length > 0) {
					this.userProfileFields = this.currentProfile['memberRole']['fields'];
				} else {
					this.userProfileFields = [];
				}

				if (this.currentRole) {
					this.getRoleFields(this.networkId);
				}

				if (this.currentProfile) {
					this.postUrl = '/users/' + this.currentProfile.user.username + '/posts/?contentType=network&contentUid=' + this.networkService.getNetworkUid();
					this.pageLoadingUiService.setPageLoading(false);
					this.layoutService.hideBd();
				}
			},
			() => {
				this.pageLoadingUiService.setPageLoading(false);
			}
		);
	}

	public followUser(): void {
		this.pageLoadingUiService.setPageLoading(true);
		this.profileService.followUser(this.currentProfile.user.username).subscribe(() => {
			this.pageLoadingUiService.setPageLoading(false);
			this.currentProfile.user.myRelations.following = true;
			this.currentProfile.followerCount = this.currentProfile.followerCount + 1;
		});
	}

	public unFollowUser(): void {
		this.pageLoadingUiService.setPageLoading(true);
		this.profileService.unFollowUser(this.currentProfile.user.username).subscribe(() => {
			this.currentProfile.user.myRelations.following = false;
			this.currentProfile.followerCount = this.currentProfile.followerCount - 1;
			this.pageLoadingUiService.setPageLoading(false);
		});
	}

	public onReportClick(): void {
		if (
			(this.currentProfile.myRelations && this.currentProfile.myRelations.flagged) ||
			(this.currentProfile.user.myRelations && this.currentProfile.user.myRelations.flagged)
		) {
		} else {
			this.pageLoadingUiService.setPageLoading(true);
			this.profileService.getReasons().subscribe((data) => {
				this.reasons = data['member'];
				this.pageLoadingUiService.setPageLoading(false);
			});
		}
		this.toggleReportModal(true);
	}

	public onReasonClick(reason, text): void {
		this.selectedReason = {
			reason: reason,
			text: text,
		};
	}

	public onSubmitReason(): void {
		if (this.selectedReason) {
			this.pageLoadingUiService.setPageLoading(true);

			this.profileService
				.reportUser(this.profile_id, this.selectedReason['reason'], this.selectedReason['text'])
				.subscribe((data) => {
					this.currentProfile['myRelations']['flagged'] = data['uid'];
				})
				.add(() => {
					this.pageLoadingUiService.setPageLoading(false);
				});
		}

		this.toggleReportModal(false);
		this.toggleNextModal(true);
	}

	public onMuteClick(): void {
		this.pageLoadingUiService.setPageLoading(true);

		if (!this.mutedStatus) {
			this.profileService.muteUser(this.currentProfile.user.userId).subscribe((data) => {
				this.muteUid = data.muteUid;
				this.mutedStatus = true;
				this.showSuccessModal = 'block';
				this.pageLoadingUiService.setPageLoading(false);
			});
		} else {
			this.profileService.unMuteUser(this.currentProfile.user.userId, this.muteUid).subscribe((data) => {
				this.muteUid = data.muteUid;
				this.mutedStatus = false;
				this.showSuccessModal = 'block';
				this.pageLoadingUiService.setPageLoading(false);
			});
		}
	}

	public onBlockClick(): void {
		// TODO: implement or remove
	}

	public onSuccessModalShow(message): void {
		this.onCloseHandle();
		this.showSuccessModal = 'block';
		this.successMessage = message;
	}

	public onCloseHandle(): void {
		this.showOptionsModal = 'none';
		this.showErrorModal = 'none';
		this.showSuccessModal = 'none';
	}

	public onTabSelect(tab: any): void {
		this.selectedTab = tab;
	}

	public toggleReportModal(property): void {
		this.reportModalDisplay = property;
	}

	public toggleNextModal(property): void {
		this.nextModalDisplay = property;
	}

	public onSettingsClick(): void {
		this.getMemberShipDetails();

		if (this.showSettings === 'all') {
			this.showSettings = '';
		} else {
			this.showSettings = 'all';
		}
	}

	public onBioEditClick(): void {
		this.bioLineEditValue = this.currentProfile.bioLine;
		// eslint-disable-next-line spellcheck/spell-checker
		this.showSettings = 'bioline';
	}

	public onResetBioLineEdit(): void {
		this.showSettings = 'all';
		this.bioLineEditValue = this.currentProfile.bioLine;
	}

	public onBioSaveClick(): void {
		const credential = { bioLine: this.bioLineEditValue };

		if (this.currentProfile['privacySettings']) {
			if (this.currentProfile['privacySettings']['calendar']) {
				const type = this.currentProfile['privacySettings']['calendar'];
				if (type['department']) {
					const a = '{"department": "' + type.department + '"}';
					const b = '{"calendar":' + a + '}';
					credential['privacy_settings'] = b;
				} else if (type['manager']) {
					const a = '{"manager": "' + true + '"}';
					const b = '{"calendar":' + a + '}';
					credential['privacy_settings'] = b;
				} else if (type['onlyMe']) {
					const a = '{"onlyMe": "' + true + '"}';
					const b = '{"calendar":' + a + '}';
					credential['privacy_settings'] = b;
				}
			}
		}

		this.onSave(credential);
		this.showSettings = 'all';
	}

	public onStatusEditClick(): void {
		this.showSettings = 'status';
		this.statusEditValue = this.currentProfile.availabilityStatus;
	}

	public onResetStatusUpdate(): void {
		this.showSettings = 'all';
		this.statusEditValue = this.currentProfile.availabilityStatus;
	}

	public onStatusSaveClick(): void {
		const credential = { availabilityStatus: this.statusEditValue };

		if (this.currentProfile['privacySettings']) {
			if (this.currentProfile['privacySettings']['calendar']) {
				const type = this.currentProfile['privacySettings']['calendar'];
				if (type['department']) {
					const a = '{"department": "' + type.department + '"}';
					const b = '{"calendar":' + a + '}';
					credential['privacy_settings'] = b;
				} else if (type['manager']) {
					const a = '{"manager": "' + true + '"}';
					const b = '{"calendar":' + a + '}';
					credential['privacy_settings'] = b;
				} else if (type['onlyMe']) {
					const a = '{"onlyMe": "' + true + '"}';
					const b = '{"calendar":' + a + '}';
					credential['privacy_settings'] = b;
				}
			}
		}
		this.onSave(credential);
		this.showSettings = 'all';
	}

	public onResetRoleUpdate(): void {
		this.showSettings = 'all';
		this.editRole = this.currentProfile.memberRole.role.slug;
	}

	public onSubmitRole(): void {
		this.showSettings = 'role-detail';
		this.pageLoadingUiService.setPageLoading(true);
		this.networkService.getRoleDetails(this.editRole, this.networkId).subscribe((data) => {
			this.pageLoadingUiService.setPageLoading(false);
			this.editRoleFields = data['info']['fields'];

			this.networkService.departmentsReady.subscribe((departmentsLoaded) => {
				if (departmentsLoaded) {
					this.networkService.currentDepartments.subscribe((departments) => {
						this.departments = departments.objects;
					});
				}
			});
		});
	}

	public onResetRoleDetailUpdate(): void {
		this.showSettings = 'role';
	}

	public onSave(credential): void {
		this.pageLoadingUiService.setPageLoading(true);

		this.networkService.memberPrivacySettings(this.currentProfile.user.memberId, credential).subscribe((data) => {
			this.pageLoadingUiService.setPageLoading(false);
			if (data) {
				this.currentProfile = data;
			}
		});
	}

	public onLocationClick(): void {
		this.pageLoadingUiService.setPageLoading(true);
		const nearMeLocation = this.currentLocation.coords.latitude + ',' + this.currentLocation.coords.longitude;
		this.networkService.getCities(nearMeLocation).subscribe((data) => {
			this.locationsList = data['results'];
			this.locationDisplay = 'block';
			this.pageLoadingUiService.setPageLoading(false);
		});
	}

	public onLocationSelect(loc): void {
		this.location = loc;
		this.locationDisplay = 'none';
	}

	public onRemoveLocation(): void {
		this.location = null;
	}

	public loadNetwork(networkUid): void {
		this.pageLoadingUiService.setPageLoading(true);
		this.networkService.get(networkUid).subscribe((networkData) => {
			this.networkService.setNetwork(new Network(networkData));
			this.pageLoadingUiService.setPageLoading(false);
		});
	}

	public showEmailValidation(domain = ''): void {
		this.email = domain;
		this.showSettings = 'role-email-validation';
	}

	public onResetEmailValidation(): void {
		this.showSettings = 'role-detail';
	}

	public onSubmitEmailValidation(): void {
		this.showSettings = 'all';
	}

	public closeLocationModal(): void {
		this.locationDisplay = 'none';
	}

	public onClickBack(): void {
		this._location.back();
	}

	public showInLightbox(): void {
		if (this.currentProfile?.user?.images?.length > 0 && (this.currentProfile?.user?.images[0].thumbnails || this.currentProfile?.user?.images[0].url)) {
			const image = this.currentProfile?.user?.images[0];

			const images = [
				new ImageAttachment(<IImageAttachment>{
					id: image.uid,
					name: image.url,
					url: image.url,
					data: image,
				}),
			];

			this.lightboxService.initLightbox(images);
		}
	}

	public onRoleUpdateClick(): void {
		this.checkNetworkRoles();
	}

	public checkNetworkRoles(): void {
		if (!this.isExternalUser) {
			if (this.roles.length > 0) {
				const index = this.roles.findIndex((role) => role.slug === 'external');
				if (index > -1) {
					this.roles.splice(index, 1);
				}
				if (this.roles.length === 1) {
					this.showSettings = 'roleUpdate';
					this.currentRole = this.roles[0];
					this.getRoleFields(this.networkId);
				} else {
					this.showSettings = 'multipleRoleSelection';
					this.selectedRole = this.roles[0];
				}
			}
		} else {
			this.showSettings = 'roleUpdate';
			this.currentRole = { slug: 'external', name: 'External' };
			this.getRoleFields(this.networkId);
		}
	}

	public showValuesInRoleUpdateForm(): void {}

	public getRoleFields(uid): void {
		this.isLoading = true;
		this.roleFields = [];

		this.networkService.getRoleFieldsUpdate(this.currentRole['slug'], uid).subscribe(() => {
			this.isLoading = false;

			if (this.currentProfile && this.currentProfile.memberRole && this.currentProfile.memberRole.fields.length > 0) {
				this.roleFields = this.currentProfile.memberRole.fields;
				const result = this.roleFields.filter((element) => element.key !== 'email');
				this.roleFields = result;
				const group = {};

				this.roleFields.forEach((input_template) => {
					if (input_template.mandatory) {
						group[input_template.key] = new FormControl('', [Validators.required]);
					} else {
						group[input_template.key] = new FormControl('');
					}
				});

				this.roleFields.forEach((input_template) => {
					if (input_template['valueLabel']) {
						group[input_template.key] = new FormControl(input_template['valueLabel']);
					}
				});

				const departmentIndex = this.roleFields.findIndex((field) => field?.type === 'department');

				this.selectedDepartment = {
					name: this.roleFields[departmentIndex]?.valueLabel,
					uid: this.roleFields[departmentIndex]?.value,
				};

				console.log('ROLE FIELDS', this.roleFields);

				this.myFormGroup = new FormGroup(group);
				const roleGroup = {};
				this.roleFields.forEach((input_template) => {
					if (input_template['value']) {
						roleGroup[input_template.key] = new FormControl(input_template['value']);
					}
				});
				this.roleFormGroup = new FormGroup(roleGroup);
			}
		});
	}

	public onSubmitRoleDetails(): void {
		this.myFormGroup.markAllAsTouched();

		if (this.myFormGroup.valid) {
			let roleDetails = {};
			let myObject = {};
			myObject = this.myFormGroup.value;
			roleDetails = this.roleFormGroup.value;

			if (this.currentRole.slug) {
				roleDetails['role'] = this.currentRole.slug;
				roleDetails['network'] = this.networkId;
			}
			if (this.selectedDepartment && this.selectedDepartment['uid']) {
				roleDetails['department'] = this.selectedDepartment['uid'];
			}
			if (this.selectedLocation && this.selectedLocation['uid']) {
				roleDetails['location'] = this.selectedLocation['uid'];
			}
			if (this.selectedMember && this.selectedMember['uid']) {
				roleDetails['manager'] = this.selectedMember['uid'];
			}

			if (myObject['title']) {
				roleDetails['title'] = myObject['title'];
			}
			if (myObject['start']) {
				roleDetails['start'] = myObject['start'];
			}
			if (myObject['company']) {
				roleDetails['company'] = myObject['company'];
			}
			if (myObject['industry']) {
				roleDetails['industry'] = myObject['industry'];
			}

			if (this.currentProfile['privacySettings']) {
				if (this.currentProfile['privacySettings']['calendar']) {
					const type = this.currentProfile['privacySettings']['calendar'];
					if (type['department']) {
						const a = '{"department": "' + type.department + '"}';
						const b = '{"calendar":' + a + '}';
						roleDetails['privacy_settings'] = b;
					} else if (type['manager']) {
						const a = '{"manager": "' + true + '"}';
						const b = '{"calendar":' + a + '}';
						roleDetails['privacy_settings'] = b;
					} else if (type['onlyMe']) {
						const a = '{"onlyMe": "' + true + '"}';
						const b = '{"calendar":' + a + '}';
						roleDetails['privacy_settings'] = b;
					}
				}
			}

			this.pageLoadingUiService.setPageLoading(true);
			// this.networkService.updateRoleFields(this.currentProfile['uid'],roleDetails)
			this.networkService.memberPrivacySettings(this.currentProfile['uid'], roleDetails).subscribe(
				() => {
					this.pageLoadingUiService.setPageLoading(false);
					this.networkService.populate();
					this.getMemberShipDetails();
					myObject = {};
					roleDetails = {};
					this.showSettings = '';
				},
				() => {
					this.pageLoadingUiService.setPageLoading(false);
					this.showSettings = '';
					myObject = {};
					roleDetails = {};
				}
			);
		}
	}

	/**
	 * Function which set default role
	 * 1. Check if selected role exists in roles array
	 * 2. If exists, set that role object as selected role
	 */
	public onRoleChange(): void {
		const index = this.roles.findIndex((role) => role.slug === this.selectedRole); // 1

		if (index > 0) {
			this.selectedRole = this.roles[index]; // 2
		}
	}

	/**
	 * Function which set roles on clicking next in role update form
	 * 1. Set role
	 */
	public setRole(): void {
		if (this.selectedRole) {
			this.currentRole = this.selectedRole;
			this.showSettings = 'roleUpdate';
			this.getRoleFields(this.networkId);
		}
	}

	public onRoleFieldSelection(field): void {
		this.departments = [];
		this.locations = [];
		this.members = [];
		this.nextUrl = '';
		const selectedField = field.key;
		switch (selectedField) {
			case 'department':
				if (field['value']) {
					this.department['uid'] = field.value;
				}
				this.getDepartments();
				break;
			case 'location':
				if (field['value']) {
					this.location['uid'] = field.value;
				}
				this.getLocations();
				break;
			case 'manager':
				if (field['value']) {
					this.member['id'] = field.value;
				}
				this.getMembers();
				break;
			default:
				break;
		}
	}

	public backToPreviousView(): void {
		this.getMemberShipDetails();

		if (this.showSettings === 'roleUpdate' && !this.selectedRole) {
			this.showSettings = 'all';
		} else {
			this.showSettings = 'multipleRoleSelection';
		}
	}

	/**
	 * Function to get all departments and display it
	 * 1. Display departments modal
	 * 2. Show spinner
	 * 3. Call api for getting departments
	 * 4. Hide spinner
	 * 5. Set departments array
	 */
	public getDepartments(): void {
		this.displayDepartmentsModal = 'block'; // 1
		this.noDepartments = true; // 2
		this.networkService.getDepartments(this.networkId).subscribe((data) => {
			// 3
			this.noDepartments = false; // 4
			if (data.objects && data.objects.length > 0) {
				this.departments = data.objects; // 5
			}
			if (data.next) {
				this.nextUrl = data.next.split('alpha')[1];
				this.paginateDepartments();
			} else {
				this.nextUrl = '';
			}
		});
	}

	private paginateDepartments(): void {
		if (this.nextUrl !== '') {
			this.networkService.paginateTasks(this.nextUrl).subscribe((data) => {
				if (data.next) {
					this.nextUrl = data.next.split('alpha')[1];
				} else {
					this.nextUrl = '';
				}
				data.objects.forEach((department) => {
					const index = this.departments.findIndex((i) => i?.uid === department?.uid);
					if (index === -1) {
						this.departments.push(department);
					}
				});
				// this.departments = this.departments.concat(data.objects);
			});
		}
	}

	/**
	 * Function to get all locations and display it
	 *
	 * 1. Display location modal
	 * 2. Set latitude and longitude of current location
	 * 3. Call api for getting locations
	 * 4. Set location data on locations array
	 */
	public getLocations(): void {
		this.locations = [];
		this.displayLocationsModal = 'block'; // 1
		this.noLocations = true;
		const nearMeLocation = this.currentLocation.coords.latitude + ',' + this.currentLocation.coords.longitude; // 2

		this.networkService.getCitiesForRoleUpdate(nearMeLocation).subscribe((data) => {
			// 3
			this.noLocations = false;
			if (data.results && data.results.length > 0) {
				this.locations = data.results; // 4
			}
		});
	}

	/**
	 * Function to get all members and display it
	 * 1. Display members modal
	 * 2. Show spinner
	 * 3. Call api for getting members
	 * 4. Check if next url exists,call pagination function
	 * 5. Hide spinner
	 * 6. Set members array
	 */
	public getMembers(): void {
		this.displayMembersModal = 'block'; // 1
		this.noMembers = true; // 2

		this.memberService.getMembers(this.networkId).subscribe((data) => {
			// 3
			this.noMembers = false; // 4
			if (data.next) {
				this.nextUrl = data.next.split('alpha')[1]; // 5
				this.paginateMembers();
			} else {
				this.nextUrl = '';
			}
			const index = data.objects.findIndex((m) => m['user']['username'] === this.currentUser['username']);
			if (index > -1) {
				data.objects.splice(index, 1);
			}
			this.members = data.objects; // 6
		});
	}

	/**
	 * Function to get remaining members \
	 * 1. Check if next url exists
	 * 2. Call pagination api
	 * 3. Set next url if exists
	 * 4. Append new members objects to members array
	 */
	public paginateMembers(): void {
		if (this.nextUrl !== '') {
			// 1
			this.networkService.paginateTasks(this.nextUrl).subscribe((data) => {
				// 2
				if (data.next) {
					// 3
					this.nextUrl = data.next.split('alpha')[1];
				} else {
					this.nextUrl = '';
				}
				this.members = this.members.concat(data.objects); // 4
			});
		}
	}

	/**
	 * Function on selecting department
	 * 1.Set selected department object
	 * 2.Set its uid for radio btn on
	 */
	public onSelectDepartment(department): void {
		this.selectedDepartment = department; // 1
		this.department.uid = department.uid; // 2
	}

	/**
	 * Function to set department selected
	 * 1. Close departments modal
	 * 2. Set selected department name on role object
	 */
	public onSetDepartment(): void {
		this.displayDepartmentsModal = 'none'; // 1
		if (this.selectedDepartment) {
			this.myFormGroup.controls.department.setValue(this.selectedDepartment.name); // 2
		}
	}

	/**
	 *  Function on selecting location
	 * 1.Set selected location object
	 * 2.Set its uid for radio btn on
	 */
	public onSelectLocation(loc): void {
		this.selectedLocation = loc; // 1
		this.location.uid = loc.uid; // 2
	}

	/**
	 * Function to set location selected
	 * 1. Close locations modal
	 *
	 * 2. Set selected location name on role object
	 */
	public onSetLocation(): void {
		this.displayLocationsModal = 'none'; // 1
		if (this.selectedLocation) {
			this.myFormGroup.controls.location.setValue(this.selectedLocation.name); // 2
		}
	}

	/**
	 * Function on selecting manager
	 *
	 * 1. Set selected manger object
	 * 2. Set its uid for radio btn on
	 */
	public onSelectManager(member): void {
		this.selectedMember = member; // 1
		this.member.id = member.uid; // 2
	}

	/**
	 * Function to set manager selected
	 *
	 * 1. Close managers modal
	 * 2. Set selected manager name on role object
	 */
	public onSetManager(): void {
		this.displayMembersModal = 'none'; // 1
		if (this.selectedMember) {
			this.myFormGroup.controls.manager.setValue(this.selectedMember['user'].name); // 2
		}
	}

	public onSearchClick(): void {
		this.noLocations = true;
		this.locations = [];
		const nearMeLocation = this.currentLocation.coords.latitude + ',' + this.currentLocation.coords.longitude;

		this.networkService.searchCitiesForRoleUpdate(nearMeLocation, this.searchQuery).subscribe((data: any) => {
			this.noLocations = false;

			if (data.results && data.results.length > 0) {
				this.locations = data.results;
			}
		});
	}

	public isOnline(user: any): string {
		if (this.onlineStack[user]) {
			return this.onlineStack[user].isOnline;
		} else {
			return 'Offline';
		}
	}

	public onSearchEnter(event: any): void {
		if (event.keyCode === 13) {
			this.onSearchClick();
		}
	}

	/**
	 * Function to return profile icons for details
	 */
	public returnUserProfileImages(field): string {
		if (field) {
			if (field.key === 'department') {
				return 'assets/img/profile_department.png';
			} else if (field.key === 'start') {
				return 'assets/img/profile_manager.png';
			} else if (field.key === 'title') {
				return 'assets/img/profile_title.png';
			} else if (field.key === 'location') {
				return 'assets/img/profile_location.png';
			} else if (field.key === 'email') {
				return 'assets/img/profile_email.png';
			} else if (field.key === 'manager') {
				return 'assets/img/profile_manager.png';
			}
		}
	}

	public removeUserFromNetwork(): void {
		window.localStorage.removeItem('network_id');
		this.networkService.clearNetworkSubject();
		this.loadingGif = 'block';
		this.networkService.deleteMembership(this.membershipUid).subscribe(() => {
			this.loadingGif = 'none';
			this.handleMembershipCount();
		});
	}

	private handleMembershipCount(): void {
		if (localStorage.getItem('membershipCount')) {
			// let count: any;
			const count = localStorage.getItem('membershipCount');
			let n = Number(count);
			n = n - 1;
			localStorage.setItem('membershipCount', JSON.stringify(n));
			if (n >= 1) {
				this.router.navigateByUrl('/');
			} else {
				this.router.navigateByUrl('/workspace/welcome');
			}
		}
	}

	private clearCache(): void {
		if (localStorage.getItem('networkExpired')) {
			localStorage.removeItem('networkExpired');
		}
	}
}
