/* eslint-disable spellcheck/spell-checker */
import { Component, OnInit, Input, Output, EventEmitter, HostListener, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FileService, NetworkService } from '../services';
import CacheUtils from '@shared/utils/cache-utils';
import { ImageVal } from '../../../../projects/custom-flows/src/lib/core/models/column';

@Component({
	selector: 'app-file-upload',
	templateUrl: './file-upload.component.html',
	styleUrls: ['./file-upload.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadComponent implements OnInit {
	public errors: Array<string> = [];
	public dragAreaClass = 'dragarea';
	@Input() public comment = false;
	@Input() public model;
	@Input() public fileType: any[] = [];
	@Input() public isProfileImage;
	@Input() public isNetworkLogo;
	@Input() public currentPage = '';
	@Input() public fileExt = 'JPG, GIF, PNG, JPEG, SVG';
	// @Input() public maxFiles: number = 5;
	@Input() public maxFiles = 1;
	// @Input() public maxSize: number = 15; // 15MB
	@Input() public maxSize = 40; // 40MB
	@Input() public uploadType = 'images';
	@Input() public dragAreaSize: 'sm' | 'md' | 'lg' = 'lg';
	@Output() public uploadStatus = new EventEmitter();
	@Output() public fileTypeEmitter = new EventEmitter();
	@Output() public imagesUrl = new EventEmitter();

	@Output() public profileImageUploaded = new EventEmitter();
	@Output() public networkImageUploader = new EventEmitter();
	@Output() public documentEmitter = new EventEmitter();

	@Input() public maxImageFiles: any = 1;

	@Input() public useWithoutReferenceType = false;

	@Input() public designWithIconAndBrowseButton = false;
	// eslint-disable-next-line spellcheck/spell-checker
	@HostListener('dragover', ['$event']) public onDragOver(event: any): void {
		// eslint-disable-next-line spellcheck/spell-checker
		this.dragAreaClass = 'droparea';
		event.preventDefault();
		event.stopPropagation();

		const files = event.dataTransfer.files;
		this.saveFiles(files);
	}

	// eslint-disable-next-line spellcheck/spell-checker
	@HostListener('dragenter', ['$event']) public onDragEnter(event: any): void {
		// eslint-disable-next-line spellcheck/spell-checker
		this.dragAreaClass = 'droparea';
		event.preventDefault();
		event.stopPropagation();

		const files = event.dataTransfer.files;
		this.saveFiles(files);
	}

	@HostListener('dragend', ['$event']) public onDragEnd(event: any): void {
		// eslint-disable-next-line spellcheck/spell-checker
		this.dragAreaClass = 'dragarea';
		event.preventDefault();
		event.stopPropagation();

		const files = event.dataTransfer.files;
		this.saveFiles(files);
	}

	// eslint-disable-next-line spellcheck/spell-checker
	@HostListener('dragleave', ['$event']) public onDragLeave(event: any): void {
		// eslint-disable-next-line spellcheck/spell-checker
		this.dragAreaClass = 'dragarea';
		event.preventDefault();
		event.stopPropagation();

		const files = event.dataTransfer.files;
		this.saveFiles(files);
	}

	@HostListener('drop', ['$event']) public onDrop(event: any): void {
		// eslint-disable-next-line spellcheck/spell-checker
		this.dragAreaClass = 'dragarea';
		event.preventDefault();
		event.stopPropagation();

		const files = event.dataTransfer.files;
		this.saveFiles(files);
	}

	public image_uids = [];
	public images = [];
	public accept = 'image/*';
	public uploadInProgress = false;
	public uploadInprogressFileName = '';
	public displayUploading = 'none';
	public isFilePresent = false;
	private network: any;

	public displayErrorDialog = 'none';

	public modalErrorText = '';
	constructor(private fileService: FileService, private networkService: NetworkService, private changeDetectorRef: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.networkService.networkReady.subscribe((networkLoaded) => {
			if (networkLoaded) {
				this.networkService.currentNetwork.subscribe((network) => {
					this.network = network;
					const fileSize = Number(network?.subscription?.planQuotas?.fileSize);
					if (fileSize > 0 || network?.subscription?.planQuotas?.fileSize === '10485760') {
						this.maxSize = 5;
					}
				});
			}
		});
		if (this.uploadType === 'documents') {
			// eslint-disable-next-line spellcheck/spell-checker
			this.accept = '.xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf, .mp3, .wav, .csv, .xls, .xlsx, .ppt, .pptx';
		}
	}

	public onFileChange(event): void {
		if (this.useWithoutReferenceType) {
			const files = event.target.files;
			this.saveFiles(files);
			event.target.value = '';
		} else if (this.model && this.model['preview_images'] && this.model['preview_images'].length < this.maxFiles) {
			this.isFilePresent = false;
			const files = event.target.files;

			this.saveFiles(files);
			event.target.value = '';
		} else {
			this.errors = [];

			this.errors.push(`Error: At a time you can upload only ${this.maxImageFiles} files`);
		}
	}

	public saveFiles(files): void {
		this.errors = []; // Clear error
		const fileNames = [];

		if (files.length > 0 && !this.isValidFiles(files)) {
			this.uploadStatus.emit(false);
			return;
		}

		this.uploadInProgress = true;
		this.displayUploading = 'block';
		const formData: FormData = new FormData();
		formData.append(
			'network_uid',
			this.network?.uid ? this.network?.uid : localStorage.getItem('network_id') ? localStorage.getItem('network_id') : CacheUtils.getStoredData('network_id')
		);

		if (this.isProfileImage) {
			formData.append('payload', files[0], files[0].name);
			this.uploadInprogressFileName = files[0].name;
			formData.append('username', localStorage.getItem('uid'));

			this.fileService.uploadProfileImage(formData).subscribe(
				(success) => {
					this.uploadInprogressFileName = '';
					this.uploadInProgress = false;
					this.displayUploading = 'none';
					this.model.images.push(success['uid']);
					this.image_uids.push(success['uid']);
					this.profileImageUploaded.emit(success['uid']);
					this.changeDetectorRef.detectChanges();
				},
				(error) => {
					this.uploadInProgress = false;
					this.displayUploading = 'none';
					if (!window.navigator.onLine) {
						this.errors.push('You are currently offline.Check your internet access.');
					} else {
						this.errors.push(error.ExceptionMessage);
					}
					this.displayErrorDialog = 'block';
					// eslint-disable-next-line spellcheck/spell-checker
					this.modalErrorText = 'Aww Snap! Something went wrong';
					this.changeDetectorRef.detectChanges();
				}
			);
		} else if (this.useWithoutReferenceType) {
			const imagesUrl = [];
			formData.append('payload', files[0], files[0].name);
			fileNames.push(files[0].name);
			this.uploadInprogressFileName = files[0].name;
			const type = files[0].type;
			this.fileService.upload(formData).subscribe(
				(success) => {
					const imageData: ImageVal = {
						images: [],
						previewImages: [],
					};
					imagesUrl.push({ url: success['url'], type: type });
					this.uploadInprogressFileName = '';
					this.uploadInProgress = false;
					this.displayUploading = 'none';
					imageData.images?.push(success['uid']);
					imageData.previewImages?.push(success);
					this.documentEmitter.emit(imageData);
					// this.isFilePresent = true;
					this.changeDetectorRef.detectChanges();
				},
				(error) => {
					this.uploadInProgress = false;
					this.displayUploading = 'none';
					if (!window.navigator.onLine) {
						this.errors.push('You are currently offline.Check your internet access.');
					} else {
						this.errors.push(error.ExceptionMessage);
					}
					this.displayErrorDialog = 'block';
					// eslint-disable-next-line spellcheck/spell-checker
					this.modalErrorText = 'Aww Snap! Something went wrong';
					this.changeDetectorRef.detectChanges();
				}
			);
		} else {
			if (files.length > 0) {
				const imagesUrl = [];
				for (let j = 0; j < files.length; j++) {
					formData.append('payload', files[j], files[j].name);
					fileNames.push(files[j].name);
					const no = j;
					this.uploadInprogressFileName = files[j].name;
					const type = files[no].type;
					this.fileService.upload(formData).subscribe(
						(success) => {
							imagesUrl.push({ url: success['url'], type: type });

							if (this.currentPage === 'inbox') {
								this.uploadStatus.emit(true);
								this.imagesUrl.emit(imagesUrl);
							}
							this.uploadInprogressFileName = '';
							this.uploadInProgress = false;
							this.displayUploading = 'none';
							this.model['images'].push(success['uid']);
							// ===For document attachment in chat
							this.documentEmitter.emit(success);
							// ===
							this.image_uids.push(success['uid']);
							if (!this.model['preview_images']) {
								this.model['preview_images'] = [];
							}
							success['name'] = fileNames[no];
							this.model['preview_images'].push(success);

							this.isFilePresent = true;
							this.changeDetectorRef.detectChanges();

							if (this.isNetworkLogo) {
								this.networkImageUploader.emit(success['uid']);
							}
						},
						(error) => {
							this.uploadInProgress = false;
							this.displayUploading = 'none';
							if (!window.navigator.onLine) {
								this.errors.push('You are currently offline.Check your internet access.');
							} else {
								this.errors.push(error.ExceptionMessage);
							}
							this.displayErrorDialog = 'block';
							// eslint-disable-next-line spellcheck/spell-checker
							this.modalErrorText = 'Aww Snap! Something went wrong';
							this.changeDetectorRef.detectChanges();
						}
					);
				}
			}
		}
	}

	public isValidFiles(files): boolean {
		// Check Number of files
		if (files.length > this.maxFiles || this.isFilePresent) {
			this.displayUploading = 'none';
			this.errors = [];

			this.errors.push('Error: At a time you can upload only one file');
			this.changeDetectorRef.detectChanges();
			return;
		}

		this.isValidFileExtension(files);
		return this.errors.length === 0;
	}

	public isValidFileExtension(files): string | void {
		// // Make array of file extensions
		const extensions = this.fileExt.split(',').map(function (x) {
			return x.toLocaleUpperCase().trim();
		});

		for (let i = 0; i < files.length; i++) {
			// Get file extension
			const ext = files[i].name.toUpperCase().split('.').pop() || files[i].name;
			// Check the extension exists
			const exists = extensions.includes(ext);

			if (!exists) {
				this.displayUploading = 'none';
				this.errors.push('Error (Extension): ' + files[i].name);
				this.changeDetectorRef.detectChanges();
			}

			// Using this for setting the post attachment type
			this.fileType = [];
			this.fileType.push(ext);
			this.fileTypeEmitter.emit(ext);
			// Check file size
			this.isValidFileSize(files[i]);
		}
	}

	public isValidFileSize(file): void {
		const fileSizeInMB = file.size / (1024 * 1000);
		const size = Math.round(fileSizeInMB * 100) / 100; // convert up to 2 decimal place

		console.log(this.maxSize);

		if (size > this.maxSize) {
			this.errors.push('Error (File Size): ' + file.name + ': exceed file size limit of ' + this.maxSize + 'MB ( ' + size + 'MB )');
		}
	}

	/**
	 * Remove image from preview list
	 *
	 * @param index number
	 */
	public removeImage(index: number): void {
		this.errors = [];
		this.isFilePresent = false;
		this.model.preview_images.splice(index, 1);
		this.image_uids.splice(index, 1);
		this.model.images.splice(index, 1);
	}

	// refreshImages(status){
	//   if (status === true){

	//     //this.getImageData();
	//   }
	// }
	// getImageData(){
	//   this.fileService.getImages().subscribe(
	//     data =>{ this.images = data.result},
	//     error => this.errorMessage = error
	//   )
	// }
}
