import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskInteractionManagerComponent } from './task-interaction-manager/task-interaction-manager.component';
import { TaskFeedbackComponent } from './task-feedback/task-feedback.component';
import { TaskTimerControlsComponent } from './task-timer-controls/task-timer-controls.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { OvalLoaderModule } from '@shared/oval-loader/oval-loader.module';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { CommentPopupModule } from '@shared/comment-popup/comment-popup.module';



@NgModule({
	declarations: [
		TaskInteractionManagerComponent,
		TaskFeedbackComponent,
		TaskTimerControlsComponent,
		ClickOutsideDirective
	],
	exports: [
		TaskInteractionManagerComponent,
		TaskFeedbackComponent,
		TaskTimerControlsComponent,
		ClickOutsideDirective
	],
	imports: [
		CommonModule,
		TranslateModule,
		ReactiveFormsModule,
		OvalLoaderModule,
		CommentPopupModule
	]
})
export class TaskStartEndPopupModule { }
