import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-home-layout',
	templateUrl: './home-layout.component.html',
	styleUrls: ['./home-layout.component.scss'],
})
export class HomeLayoutComponent implements OnInit {
	public isDevMode = !environment.production;

	constructor() {}

	ngOnInit(): void {}
}
