import { Component, OnInit } from '@angular/core';
import { NetworkService, PaginationService } from '../../shared';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-archived-channels',
	templateUrl: './archived-channels.component.html',
	styleUrls: ['./archived-channels.component.css'],
})
export class ArchivedChannelsComponent implements OnInit {
	public archivedChannels = [];
	public nextUrl;
	public isFetching = 0;
	public paginateSub$: any;
	private paginating = false;
	public network;
	public departments = [];
	public selectByLevelValues = [];
	public personalFilters = [];
	public value;

	constructor(private networkService: NetworkService, private location: Location, private translate: TranslateService, private paginationService: PaginationService) {
		this.paginationService.scrollDistance = 8;
		this.paginateSub$ = this.paginationService.initiateScroll.subscribe((data) => {
			if (data && !this.paginating) {
				this.paginate();
			}
		});
	}

	ngOnInit(): void {
		this.networkService.currentNetwork.subscribe((network) => {
			this.network = network;
		});

		this.networkService.departmentsReady.subscribe((departmentsLoaded) => {
			if (departmentsLoaded) {
				this.networkService.currentDepartments.subscribe((departments) => {
					if (departments && departments['objects'].length > 0) {
						this.departments = departments.objects;
					}
				});
			}
		});

		this.getChannels();
	}

	private getChannels(): void {
		this.isFetching = 0;
		this.networkService.getChannels('/networks/' + localStorage.getItem('network_id') + '/channels/', 'archived=true').subscribe((data) => {
			this.isFetching = 1;
			this.archivedChannels = data.objects;
			if (data['next']) {
				this.nextUrl = data['next'];
			} else {
				this.nextUrl = null;
			}
		});
	}

	public paginate(): void {
		if (this.nextUrl) {
			this.isFetching = 0;
			this.paginating = true;
			this.networkService.paginateToDoLists(this.nextUrl).subscribe((data) => {
				this.paginating = false;
				this.isFetching = 1;
				if (data.next) {
					this.nextUrl = data.next.split('alpha')[1];
				} else {
					this.nextUrl = '';
				}
				this.archivedChannels = this.archivedChannels.concat(data.objects);
			});
		}
	}

	public onClickBack(): void {
		this.location.back();
	}

	/**
	 * Function to get department name
	 * @param value
	 */
	public getDepartmentNameBasedOnId(value): string {
		const index = this.departments.findIndex((department) => department.uid === value);
		if (index !== -1) {
			return this.departments[index].name;
		}
	}

	/**
	 * Function to show post cp names of personal filters/memberships
	 * @param value
	 * 1. If content privacy(value) is status,show it.
	 * 2. If content privacy(value) is renewal status,show it.
	 * 3. Check if content privacy(value) is membershipLevel
	 * 4. Get membership level name from array
	 * 5. If value is manager,show cp as myTeam
	 * 6. If value is in personal filters,show it
	 */
	public getPostNames(value): string {
		if (value.status) {
			return value.status; // 1
		} else if (value.renewalStatus) {
			return value.renewalStatus; // 2
		} else if (value.membership_level) {
			// 3
			let name = '';
			this.selectByLevelValues.forEach((object) => {
				if (object.uid === value.membership_level) {
					name = object.name; // 4
				}
			});
			return name;
		} else if (value.manager) {
			return this.translate.instant('My Team'); // 5
		} else {
			this.value = Object.values(value);
			const index = this.personalFilters.findIndex((field) => field.value === this.value);
			if (index !== -1) {
				return this.personalFilters[index].valueLabel; // 6
			}
		}
	}
}
