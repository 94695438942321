import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-pulse-loader',
	templateUrl: './pulse-loader.component.html',
	styleUrls: ['./pulse-loader.component.scss'],
})
export class PulseLoaderComponent implements OnInit {
	@Input() public isLoading = false;
	@Input() public size = '44px';
	@Input() public pulseType = null;
	@Input() public strokeSize = '2';

	constructor() {}

	ngOnInit(): void {}
}
