// This directive is to handle click events outside of a specified element.

import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
	selector: '[appClickOutside]'
})
export class ClickOutsideDirective {
	@Output() public appClickOutside = new EventEmitter<void>(); // Event emitter for handling the click outside event
	public isParentVisible = false; // Flag to track if the parent element is visible

	constructor(private elementRef: ElementRef) { }

	@HostListener('document:click', ['$event']) // Host listener for the document click event
	public onClick(event: Event): void {
		if (!this.elementRef.nativeElement.contains(event.target)) { // Check if the clicked target is outside the specified element
			// eslint-disable-next-line spellcheck/spell-checker
			const parentRect = this.elementRef.nativeElement.parentElement.getBoundingClientRect(); // Get the bounding client rect of the parent element
			const viewportHeight = window.innerHeight; // Get the height of the viewport
			// eslint-disable-next-line spellcheck/spell-checker
			const isParentVisibleInView = parentRect.top >= 0 && parentRect.bottom <= viewportHeight; // Check if the parent element is fully visible within the viewport
			if (isParentVisibleInView) { // If the parent element is fully visible
				if (this.isParentVisible) { // If the parent element was previously visible
					this.appClickOutside.emit(); // Emit the click outside event
				}
				this.isParentVisible = true; // Update the flag to indicate that the parent element is now visible
			}
		}
	}
}
