import { WorkspacesComponent } from './pages/workspaces/workspaces.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './home.component';
import { HomeLayoutComponent } from '@layouts/home-layout/home-layout.component';
import { HomeLayoutModule } from '@layouts/home-layout/home-layout.module';
import { SharedModule } from '@shared/shared.module';
import { JoinWorkspaceComponent } from './pages/join-workspace/join-workspace.component';
import { BeeAvatarModule } from 'projects/bee-ui/src/lib/modules/avatar/avatar.module';
import { TranslateModule } from '@ngx-translate/core';
import { BeeButtonModule } from 'projects/bee-ui/src/lib/modules/button/button.module';
import { BeeInputModule } from 'projects/bee-ui/src/lib/modules/input/input.module';
import { BeeLabelModule } from 'projects/bee-ui/src/lib/modules/label/label.module';
import { PeerCoreModule } from 'projects/peer-core/src/public-api';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
	declarations: [HomeComponent, WorkspacesComponent, JoinWorkspaceComponent],
	imports: [
		CommonModule,
		HomeRoutingModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		HomeLayoutModule,
		TranslateModule,
		SharedModule,
		BeeAvatarModule,
		BeeButtonModule,
		BeeInputModule,
		BeeLabelModule,
		PeerCoreModule,
	],
	exports: [WorkspacesComponent],
	bootstrap: [HomeLayoutComponent],
	providers: [CookieService],
})
export class HomeModule {}
