import { Comment } from './comment.model';
import StrUtils from '@shared/utils/str-utils';

export interface AttachmentDetail {
	url?: URL[];
}

export interface URLUI {
	fileType?: string;
}

export interface URL extends URLUI {
	url?: string;
	preview?: Preview;
}

export interface Preview {
	image?: null;
	title?: null;
	source?: string;
	description?: string;
}

export interface IPostUI {
	postType?: string;
}

export interface IPost extends IPostUI {
	uid: number;
	author: any;
	title: any;
	body: string;
	rating: number;
	type: string;
	contentType: string;
	contentUid: string;
	channel: any;
	myRelations: any;
	state: string;
	pinned: boolean;
	attachmentDetails: AttachmentDetail | any;
	commentable: any;
	relatedId: any;
	comments: any;
	duration: any;
	bookmarkCount: number;
	commentCount: number;
	roleDetails: any;
	tag: any;
	lastLikes: any;
	flagCount: number;
	resourceUri: string;
	created: any;
	relatedContent: any;
	upload: any;
	longitude: any;
	latitude: any;
	privacy: any;
	shareable: any;
	likesCount: number;
	images: any;
	category: any;
	url: any;
	attachmentType: any;
	commentUrl: string;
	show: boolean;
	commentFirstClick: boolean;
	newComment: any;
	preview_images: any;
	member: any;
	custom_privacy: any;
	location: any;
	starts: any;
	ends: any;
	link: any;
	sellPrice: any;
	product: any;
	condition: string;
	fixedPrice: any;
	cost: string;
	action: string;
	role: string;
	choices: string;
	content_privacy: any;
	network: string;
	username: string;
	status: string;
	meeting_password: any;
	postType: string;
}

export class Post implements IPost {
	public uid: number;
	public author: any;
	public title: any;
	public body: string;
	public rating: number;
	public type: string;
	public contentType: string;
	public contentUid: string;
	public channel: any;
	public myRelations: any;
	public state: string;
	public pinned: boolean;
	public attachmentDetails: AttachmentDetail | any;
	public commentable: any;
	public relatedId: any;
	public comments: any;
	public duration: any;
	public bookmarkCount: number;
	public commentCount: number;
	public roleDetails: any;
	public tag: any;
	public lastLikes: any;
	public flagCount: number;
	public resourceUri: string;
	public created: any;
	public relatedContent: any;
	public upload: any;
	public longitude: any;
	public latitude: any;
	public privacy: any;
	public shareable: any;
	public likesCount: number;
	public images: any;
	public category: any;
	public url: any;
	public attachmentType: any;
	public commentUrl: string;
	public show: boolean;
	public commentFirstClick: boolean;
	public newComment: any;
	public preview_images: any;
	public member: any;
	public custom_privacy: any;
	public location: any;
	public starts: any;
	public ends: any;
	public link: any;
	public sellPrice: any;
	public product: any;
	public condition: string;
	public fixedPrice: any;
	public cost: string;
	public action: string;
	public role: string;
	public choices: string;
	public content_privacy: any;
	public network: string = localStorage.getItem('network_id');
	public username: string = localStorage.getItem('uid');
	public status: string;
	public meeting_password: any;
	public postType = 'post';
	public project?: any;
	public todolist?: any;
	public pinnedPost?: boolean;
	public tuiViewer: any;

	constructor(post: any, form_obj = false) {
		for (const key of Object.keys(post)) {
			if (key in post) {
				this[key] = post[key];
			}
		}

		if (post.lastComments) {
			this.comments = [new Comment(post.lastComments[0])];
		}

		if (!form_obj) {
			this.commentUrl = post.commentUrl;
			this.show = true;
			this.commentFirstClick = true;
			this.commentUrl = '/posts/' + post.uid + '/comments/';
			this.newComment = new Comment({ text: '', isLink: false, url: '', images: [], preview_images: [] }, true);
		}

		if (post.attachmentDetails && post.attachmentDetails.gallery && post.attachmentDetails.gallery.length > 1 && post.attachmentDetails.gallery.length % 2) {
			for (const p of post.attachmentDetails.gallery) {
				p['adjust'] = 'width';
				break;
			}
		}

		if (post.attachmentDetails && post.attachmentDetails.gallery && post.attachmentDetails.gallery.length === 1) {
			for (const p of post.attachmentDetails.gallery) {
				p['adjust'] = 'full-width';
			}
		}

		if (this.attachmentDetails && this.attachmentDetails.url) {
			for (const url of this.attachmentDetails.url) {
				if (url.url && (!url.preview || !url.preview.image)) {
					url.fileType = StrUtils.getExtensionFromUrl(url.url);
				}
			}
		}
	}

	public setCommentUrl(data): void {
		if (data.next) {
			this.commentUrl = data.next.split('alpha')[1];
		} else {
			this.commentUrl = '';
		}
	}
}
