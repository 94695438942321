import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-popup',
	templateUrl: './popup.component.html',
	styleUrls: ['./popup.component.css'],
})
export class PopupComponent implements OnInit {
	public showRedirectForm = 'block';

	constructor(private router: Router) {}

	ngOnInit(): void {}

	// eslint-disable-next-line spellcheck/spell-checker
	/**
	 * Function to redirect to apps based on app network id
	 * 1.Redirect to MyWork app
	 * 2.Redirect to Adim adim
	 * 3.Redirect to Tac App
	 * 4.Redirect to University
	 */
	public redirectToApp(): void {
		this.showRedirectForm = 'none';
		if (localStorage.getItem('app_network_id') === 'MyWork') {
			window.location.href = 'https://mywork.peerbie.com/ddncDYcnq6';
		} else if (localStorage.getItem('app_network_id') === '8e41ca150430462fa44a0092272cef32') {
			window.location.href = 'https://tac.peerbie.com/43Tdaaxoq6';
		} else if (localStorage.getItem('app_network_id') === 'd4a83e5557114c38b03a282e363e36b8') {
			window.location.href = 'https://adimadim.peerbie.com/vIeN6COoq6';
		} else if (localStorage.getItem('app_network_id') === 'university') {
			window.location.href = 'https://university.peerbie.com/TBIfLhDoq6';
		}
	}
}
