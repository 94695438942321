import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NetworkLayoutComponent } from '@layouts/network-layout/network-layout.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { FormsModule } from '@angular/forms';
import { NetworkLayoutLegacyComponent } from './network-layout-legacy/network-layout-legacy.component';
import { PeerCoreModule } from 'projects/peer-core/src/public-api';

@NgModule({
	declarations: [NetworkLayoutComponent, NetworkLayoutLegacyComponent],
	imports: [CommonModule, FormsModule, TranslateModule, SharedModule, PeerCoreModule],
	exports: [NetworkLayoutComponent, NetworkLayoutLegacyComponent],
	bootstrap: [NetworkLayoutComponent],
})
export class NetworkLayoutModule {}
