import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BeeFilePreviewComponent } from './file-preview.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { BeePdfViewerComponent } from './pdf-viewer/pdf-viewer.component';

@NgModule({
	declarations: [BeeFilePreviewComponent, BeePdfViewerComponent],
	imports: [CommonModule, PdfViewerModule],
	exports: [BeeFilePreviewComponent],
})
export class FilePreviewModule {}
