export class User {
	public email: string;
	public access: string;
	public refresh: string;
	public udid: string;
	public phone: string;
	public bio: string;
	public username: string;
	public images: any;
	// eslint-disable-next-line spellcheck/spell-checker
	public resourceUri: string;
	public name: string;
	public type: string;
	public unreadInAppNotificationsCount: string;
	public zoomLinked: any;
	public zoomExpires: any;
	public activeMemberships: any;
	public membershipCount: any;
	public userId?: number;
}

export interface ICurrentUser {
	id?: number;
	name?: string;
	username?: string;
	userId?: number;
	muteSettings?: any[];
	dailyDigestSettings?: any[];
	contactsMute?: boolean;
	tags?: any[];
	postsLastSeen?: null;
	zoomLinked?: boolean;
	zoomExpires?: null;
	dateJoined?: string;
	membershipCount?: number;
	images?: IImage[];
	country?: ICountry;
	currentLocation?: any;
	hometownLocation?: any;
	phoneNumber?: string;
	unreadInAppNotificationsCount?: number;
	firstName?: string;
	lastName?: string;
	email?: string;
	muted?: string;
	gender?: string;
	dob?: null;
	availabilityStatus?: null;
	timezone?: string;
	emailVerified?: boolean;
	formattedRole?: string;
	primaryRole?: string;
	memberId?: string;
	manager?: string;
}

export class CurrentUser implements ICurrentUser {
	constructor(data?: ICurrentUser) {
		Object.assign(this, data);
	}
}

export interface ICountry {
	uid?: string;
	name?: string;
	code?: string;
	contactsServices?: IContactsService[];
	networkService?: INetworkService;
	contactsCategories?: IContactsCategory[];
	countryCode?: number;
}

export interface IContactsCategory {
	serviceCategory?: boolean;
	name?: string;
	slug?: string;
}

export interface IContactsService {
	name?: string;
	slug?: string;
}

export interface INetworkService {
	clubs?: string;
	public?: string;
	school?: string;
	community?: string;
	elections?: string;
	enterprise?: string;
	university?: string;
	neighborhood?: string;
	friendsFamily?: string;
	universityVolunteer?: string;
}

export interface IImage {
	sizes?: ISizes;
	thumbnails?: { [key: string]: string };
	uid?: string;
	url?: string;
}

export interface ISizes {
	width?: number;
	height?: number;
}
