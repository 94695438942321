import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService, NetworkService, JoinNetworkFormsComponent } from '../../shared';
import { ActivatedRoute, Router, Params } from '@angular/router';

@Component({
	selector: 'app-join-network',
	templateUrl: './join-network.component.html',
	styleUrls: ['./join-network.component.css'],
})
export class JoinNetworkComponent implements OnInit {
	public userDetails;
	public networkDetails: any = null;
	public showComponent = true;
	public currentUser;
	public networksTypes;
	private isAuthenticated;
	private network_id;
	public requested_network;

	@ViewChild(JoinNetworkFormsComponent, { static: false }) public joinNetwork;

	constructor(private userService: UserService, public networkService: NetworkService, private router: Router, private activatedRoute: ActivatedRoute) {
		this.userDetails = this.userService.getCurrentUser();
	}
	ngOnInit(): void {
		// TODO: use loading spinner

		this.networksTypes = this.networkService.pupulateNetworkTypesArray(this.userService.getCurrentUser()['country'].networkService);

		this.networkService.getNetworkTypes().subscribe((data) => {
			this.networkDetails = data;
		});

		this.activatedRoute.queryParams.subscribe((params: Params) => {
			this.network_id = params['uid'];

			this.networkService.get(this.network_id).subscribe((networkData) => {
				this.joinNetwork.hideSearchAndNetworkTypes();
				this.joinNetwork.fromMobile = true;
				this.requested_network = networkData;
				this.joinNetwork.onNetworkSelect(networkData);
			});
		});

		this.userService.isAuthenticated.subscribe((authenticated) => {
			this.isAuthenticated = authenticated;
			if (!authenticated) {
				this.router.navigateByUrl('/authentication/login');
			}
		});

		this.userService.currentUser.subscribe((userData) => {
			this.currentUser = userData;
			if (this.currentUser.userId) {
				this.networksTypes = this.networkService.pupulateNetworkTypesArray(this.currentUser.country.networkService);
			}
		});
	}
	public onSelectNetworkType(type): void {
		this.joinNetwork.onSelectNetworkType(type);
	}
}
