import { Injectable } from '@angular/core';
import CacheUtils from '@shared/utils/cache-utils';
import * as mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class MixPanelService {
	private userDetails: any = null;

	constructor() {}

	public setUserData(): void {
		if (environment.production) {
			if (typeof CacheUtils.getStoredData('userDetails') === 'string') {
				this.userDetails = JSON.parse(CacheUtils.getStoredData('userDetails'));
			} else {
				this.userDetails = CacheUtils.getStoredData('userDetails') || JSON.parse(localStorage.getItem('userDetails'));
			}
			console.log('MixPanelController', this.userDetails);
		}
	}

	public postInit(data: any): void {
		if (!data) {
			this.setUserData();
		}

		if (environment.production) {
			try {
				mixpanel.people.set({
					$name: data ? data?.name : this.userDetails?.name,
					$email: data ? data.email : this.userDetails?.email,
					$distinct_id: data ? data?.username : this.userDetails?.username,
				});

				mixpanel.identify(data ? data?.username : this.userDetails?.username);
			} catch (error) {
				console.log(error);
			}
		}
	}

	public track(title: string, data: any = {}): void {
		this.setUserData();
		if (environment.production) {
			try {
				mixpanel.track(title, {
					...data,
					...{
						distinct_id: this.userDetails?.username,
						unique_id: this.userDetails?.userId,
					},
				});
			} catch (error) {
				console.log(error);
			}
		}
	}

	public reset(): void {
		mixpanel.reset();
	}
}
