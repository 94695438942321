import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';
import { NetworkService, PaginationService, ProfilesService } from '../shared';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-point-detail',
	templateUrl: './point-detail.component.html',
	styleUrls: ['./point-detail.component.css'],
})
export class PointDetailComponent implements OnInit, OnDestroy {
	public pointDetails: any[] = [];

	private nextUrl = '';

	public showSpinner = false;

	private paginateSub$: any;
	public appLogo = environment.logo;

	constructor(
		private locationService: Location,
		private networkService: NetworkService,
		private activatedRoute: ActivatedRoute,
		private profileService: ProfilesService,
		private paginationService: PaginationService,
		public pageLoadingUiService: PageLoadingUiService
	) {
		this.paginationService.scrollDistance = 50;
		this.paginateSub$ = this.paginationService.initiateScroll.subscribe((data) => {
			if (!this.showSpinner && data) {
				this.paginateList();
			}
		});
	}

	ngOnInit(): void {
		this.activatedRoute.params.subscribe((params) => {
			if (params['id']) {
				this.getPointDetails(params['id']);
			}
		});
	}

	ngOnDestroy(): void {
		if (this.paginateSub$) {
			this.paginateSub$.unsubscribe();
			this.paginationService.resetScrollInitiation();
		}
	}

	/**
	 * Function to go back
	 */
	public onClickBack(): void {
		this.locationService.back();
	}

	/**
	  Function to get point details
	 */
	private getPointDetails(id): void {
		this.pageLoadingUiService.setPageLoading(true);
		const date = this.networkService.setCurrentUtcDate();
		this.networkService.getPointDetails(id, date).subscribe((res) => {
			this.pageLoadingUiService.setPageLoading(false);
			if (res) {
				this.pointDetails = res.objects;
				this.nextUrl = res.next ? res.next.split('alpha')[1] : '';
				this.pointDetails.forEach((li) => {
					this.showPoint(li);
				});
			}
		});
	}

	/**
	 * Function to modify point
	 */
	private showPoint(log): void {
		if (log['rewardValue']) {
			const reward = Number(log['rewardValue']);
			if (Math.sign(reward) === 1) {
				log['pointType'] = 'positive';
				log['rewardValue'] = '+' + reward;
			} else {
				log['pointType'] = 'negative';
			}
		}
	}

	/**
	 * Function to paginate the list
	 */
	private paginateList(): void {
		if (this.nextUrl) {
			this.showSpinner = true;
			this.profileService.paginateUserActivities(this.nextUrl).subscribe((res) => {
				if (res) {
					this.showSpinner = false;
					this.nextUrl = res.next ? res.next.split('alpha')[1] : '';
					Array.prototype.push.apply(this.pointDetails, res.objects);
					this.pointDetails.forEach((li) => {
						this.showPoint(li);
					});
				}
			});
		}
	}
}
