import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CollectionsService, UserService, NetworkService, LayoutService, Post } from '../../shared';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../shared/services/common/toast.service';
import { ClipboardService } from 'projects/peer-core/src/lib/services/ui/clipboard.service';

@Component({
	selector: 'app-collection-detail',
	templateUrl: './collection-detail.component.html',
	styleUrls: ['./collection-detail.component.css'],
})
export class CollectionDetailComponent implements OnInit, OnDestroy {
	public model = new Post(
		{
			title: null,
			body: '',
			category: null,
			privacy: '',
			images: [],
			preview_images: [],
			shareable: true,
			commentable: true,
			channel: '',
		},
		true
	);
	public loadingGif = 'none';
	public collection;
	public postUrl = null;
	public currentUserUid = localStorage.getItem('uid');
	public privacy = {};
	public privacyString = '';
	public value;
	public isMyFavorites = false;
	public personalFilters: any[] = [];
	public currentUser: any;
	public departments = [];
	public x;
	public showDeleteConfirmation = 'none';
	public isRedirectFromEditPage = false;
	public muteId;
	public showMute = true;
	private network_id;
	private networkSubscriber;
	private currentNetSub;
	private paramsSubscriber;
	private checkAccessibilitySub;
	public dummy_collection;
	public network;
	private app_url;
	private store_url;
	private is_member = null;
	public collectionId;
	public externalUserChannel: any;
	public isExternalUser = false;
	public isArchived = false;

	constructor(
		private activatedRoute: ActivatedRoute,
		private collectionService: CollectionsService,
		private userService: UserService,
		private router: Router,
		private _location: Location,
		public networkService: NetworkService,
		public layoutService: LayoutService,
		private translate: TranslateService,
		private toastService: ToastService,
		private clipboardService: ClipboardService
	) {}

	ngOnInit(): void {
		this.networkSubscriber = this.networkService.networkReady.subscribe((networkLoaded) => {
			if (networkLoaded) {
				this.currentNetSub = this.networkService.currentNetwork.subscribe((network) => {
					this.network = network;
					if (network && network['membership'] && network['membership']['memberRole']) {
						network.membership.memberRole.fields.forEach((element) => {
							this.networkService.departmentsReady.subscribe((departmentsLoaded) => {
								if (departmentsLoaded) {
									this.networkService.currentDepartments.subscribe((departments) => {
										this.departments = departments.objects;
									});
								}
							});
							if (element.CP === true) {
								this.personalFilters.push(element);
							}
						});
					}

					if (this.network['membership'] && this.network['membership']['memberRole'] && this.network['membership']['memberRole']['role']) {
						const memberRole = this.network['membership']['memberRole']['role'];
						if (memberRole.slug === 'external') {
							this.isExternalUser = true;
							if (this.network.channels.length > 0) {
								const index = this.network.channels.findIndex((channel) => channel.access === 4);
								this.externalUserChannel = this.network.channels[index];
							}
						}
					}
				});
			}
		});

		this.activatedRoute.queryParams.subscribe((params) => {
			const id = params['id'];
			this.collectionId = id;
			if (params['fromEditPage']) {
				this.isRedirectFromEditPage = true;
			}
			if (params['archived']) {
				this.isArchived = true;
			} else {
				this.isArchived = false;
			}
			this.userService.isAuthenticated.subscribe((authenticated) => {
				if (!authenticated) {
					localStorage.setItem('pb-prev-url', this.router.url);
					this.router.navigateByUrl('/authentication/login');
				} else {
					this.currentUser = this.userService.getCurrentUser();
					if (this.isArchived) {
						this.postUrl = '/folders/' + id + '/contents/?archived=true';
					} else {
						this.postUrl = '/folders/' + id + '/contents/';
					}

					this.collectionService.getCollectionDetail(id, this.isArchived).subscribe((data) => {
						if (data.muted) {
							this.showMute = false;
							this.muteId = data.muted;
						}
						data['privacy'] = data['channel'];
						this.dummy_collection = data;
						this.collection = data;
						this.network_id = localStorage.getItem('network_id');

						this.networkService.populateNetworkBasedOnId(this.network_id);

						if (!this.isExternalUser) {
							this.privacy['custom_privacy'] = this.dummy_collection['customPrivacy'];
							this.privacy['content_privacy'] = this.dummy_collection['contentPrivacy'];
							this.privacy['channel'] = this.dummy_collection['channel'];
						}

						if (localStorage.getItem('favoriteId')) {
							if (this.dummy_collection['uid'] === localStorage.getItem('favoriteId')) {
								this.privacyString = this.currentUser.name;
								this.isMyFavorites = true;
								const cp = {
									id: this.currentUser['userId'],
									username: this.currentUser['name'],
								};
								this.privacy['custom_privacy'] = [];
								this.privacy['custom_privacy'].push(cp);
							} else {
								this.updatePrivacyString(this.dummy_collection);
							}
						}

						// this.updatePrivacyString(this.dummy_collection);

						// this.networkSubscriber = this.networkService.networkReady.subscribe(
						//   (networkLoaded) => {
						//     if (networkLoaded) {
						//       this.currentNetSub = this.networkService.currentNetwork.subscribe(
						//         (network) => {
						//           this.network = network;
						//           network.membership.memberRole.fields.forEach(element => {
						//             this.departments = this.networkService.departments;
						//             if (element.CP === true) {
						//               this.personalFilters.push(element);
						//             }
						//           });

						//           if (this.network['membership']['memberRole'] && this.network['membership']['memberRole']['role']) {
						//             let memberRole = this.network['membership']['memberRole']['role'];
						//             if (memberRole.slug === 'external') {

						//               this.isExternalUser = true;
						//               if (this.network.channels.length > 0) {
						//                 const index = this.network.channels.findIndex((channel) => channel.access === 4);
						//                 this.externalUserChannel = this.network.channels[index];
						//               }
						//             }
						//           }

						//           if (!this.isExternalUser) {
						//             this.privacy['custom_privacy'] = this.dummy_collection['customPrivacy'];
						//             this.privacy['content_privacy'] = this.dummy_collection['contentPrivacy'];
						//             this.privacy['channel'] = this.dummy_collection['channel'];
						//           }

						//           this.updatePrivacyString(this.dummy_collection);

						//           // var is_channel_member = [];
						//           // if (this.dummy_collection.channel && Object.keys(this.dummy_collection.channel).length) {
						//           //   is_channel_member = this.network.channels.filter(am => am.uid === this.dummy_collection.channel.uid);
						//           // }

						//           // if (!this.dummy_collection.channel || !Object.keys(this.dummy_collection.channel).length || is_channel_member.length) {
						//           //   this.checkAccessAndRedirect(this.is_member);
						//           // }
						//           // else if (this.dummy_collection.channel && Object.keys(this.dummy_collection.channel).length) {
						//           //   this.networkService.joinChannel(this.dummy_collection.channel.uid).subscribe(data => {
						//           //     // sessionStorage.setItem('refresh-channel-list', '1');

						//           //     this.checkAccessAndRedirect(this.is_member);
						//           //   });
						//           // }
						//           //----------------------------------------------------
						//         });
						//     }
						//   })
					});
				}
			});
		});
	}

	private updatePrivacyString(collection): void {
		this.privacyString = '';
		if (collection['customPrivacy']) {
			collection['customPrivacy'].forEach((cp, index) => {
				this.privacyString = `${this.privacyString}${index > 0 ? ', ' : ' '}${cp.username}`;
			});
		} else if (collection['contentPrivacy']) {
			if (collection['contentPrivacy'].role && collection['contentPrivacy'].department) {
				const index = this.departments.findIndex((department) => department.value === collection['contentPrivacy'].department);
				if (index > -1) {
					this.privacyString = this.departments[index]['valueLabel'] + ' , ' + collection['contentPrivacy'].role;
				}
			} else if (collection['contentPrivacy'].role) {
				this.privacyString = collection['contentPrivacy'].role;
				//
			} else if (collection['contentPrivacy'].department) {
				const index = this.departments.findIndex((department) => department.uid === collection['contentPrivacy'].department);
				if (index > -1) {
					this.privacyString = this.departments[index]['name'];
				}
			} else if (!collection['contentPrivacy'].role || !collection['contentPrivacy'].department) {
				this.value = Object.keys(collection['contentPrivacy']);
				this.personalFilters.forEach((element) => {
					if (element.key === this.value[0]) {
						this.privacyString = element.valueLabel;
					}
				});
				if (collection['contentPrivacy'].manager) {
					this.privacyString = this.translate.instant('My Team');
				}
			}
		} else if (collection['channel'] && Object.keys(collection['channel']).length) {
			this.privacyString = collection['channel'].name;
		} else {
			this.privacyString = 'Everyone';
		}
	}

	private checkAccessAndRedirect(is_member): void {
		this.checkAccessibilitySub = this.collectionService
			.checkAccessibility({
				collectionId: this.dummy_collection.uid,
				memberId: is_member[0].uid,
			})
			.subscribe((data) => {
				if (Object.keys(data).length) {
					this.collection = this.dummy_collection;
				} else {
					this.router.navigateByUrl('/network');
				}
			});
	}

	public onClickDelete(): void {
		this.showDeleteConfirmation = 'block';
	}

	public hideDeleteConfirmation(): void {
		this.showDeleteConfirmation = 'none';
	}

	public onDeleteConfirm(): void {
		this.loadingGif = 'block';
		this.showDeleteConfirmation = 'none';
		this.collectionService.deleteCollection(this.collection.uid).subscribe(() => {
			this.loadingGif = 'none';
			this._location.back();
		});
	}
	/**
	 * Navigate to previous page.
	 */
	public onClickBack(): void {
		if (this.isRedirectFromEditPage) {
			this.router.navigateByUrl('/network/collections');
		} else {
			this._location.back();
		}
	}

	ngOnDestroy(): void {
		if (this.currentNetSub) {
			this.currentNetSub.unsubscribe();
		}
		if (this.networkSubscriber) {
			this.networkSubscriber.unsubscribe();
		}
		if (this.paramsSubscriber) {
			this.paramsSubscriber.unsubscribe();
		}
		if (this.checkAccessibilitySub) {
			this.checkAccessibilitySub.unsubscribe();
		}
	}

	// Function for mute collection
	public onMuteClick(): void {
		this.loadingGif = 'block';
		this.networkService.muteContents(this.collectionId).subscribe((data) => {
			this.showMute = false;
			this.dummy_collection['muted'] = data['uid'];
			this.loadingGif = 'none';
		});
	}

	// Function for unmute collection
	public onUnMuteClick(): void {
		this.loadingGif = 'block';
		this.networkService.unMuteContents(this.dummy_collection['muted']).subscribe(
			() => {
				this.dummy_collection['muted'] = '';
				this.showMute = true;
				this.loadingGif = 'none';
			},
			() => {
				this.showMute = true;
				this.loadingGif = 'none';
			}
		);
	}

	public handleLinkShare(link: any): void {
		this.clipboardService.copy(
			this.translate.instant('collectionInvite', {
				username: this.currentUser.name,
				collectionName: this.collection.name,
				networkName: this.network.name,
				inviteLink: link,
			})
		);
	}

	public handleArchive(state): void {
		const archived = {};
		archived['name'] = this.collection['name'];
		archived['state'] = 'active';
		archived['collaboration'] = this.collection['collaboration'];
		if (state === 'archive') {
			archived['archived'] = 'true';
		} else {
			archived['archived'] = 'false';
		}
		if (this.collection['description']) {
			archived['description'] = this.collection['description'];
		}

		if (this.collection['contentPrivacy']) {
			archived['content_privacy'] = JSON.stringify(this.collection['contentPrivacy']);
		} else if (this.collection['customPrivacy'] && this.collection['customPrivacy'].length > 0) {
			const customPrivacy: any[] = [];
			// let custom: any;
			this.collection.customPrivacy.forEach((element) => {
				if (element.userId) {
					customPrivacy.push(element.userId);
				} else {
					customPrivacy.push(element.id);
				}
			});
			const custom = '[' + customPrivacy.join(',') + ']';
			archived['custom_privacy'] = custom;
		} else if (this.collection['channel'] && this.collection['channel']['uid']) {
			archived['channel'] = this.collection['channel']['uid'];
		}

		this.loadingGif = 'block';
		this.collectionService.updateCollection(this.collection.uid, archived).subscribe(
			(data) => {
				this.loadingGif = 'none';
				this.collection = data;
				this._location.back();
			},
			() => {}
		);
	}
}
