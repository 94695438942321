/* eslint-disable spellcheck/spell-checker */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
	selector: 'app-placeholder-lottie',
	templateUrl: './placeholder-lottie.component.html',
	styleUrls: ['./placeholder-lottie.component.scss'],
})
export class PlaceholderLottieComponent implements OnInit, OnChanges {
	@Input() public placeHolderLottie = '';

	public options: AnimationOptions;

	constructor() {}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.hasOwnProperty('placeHolderLottie')) {
			this.options = {
				path: `assets/lottie/${changes?.placeHolderLottie?.currentValue}.json`,
				loop: true,
				autoplay: true,
				rendererSettings: {
					preserveAspectRatio: 'xMidYMid slice',
				},
			};
		}
	}
}
