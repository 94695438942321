import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FileUploadComponent } from '@shared/index';
import { LocationObj, Post } from '@shared/models';
import { GeoLocationService, LayoutService, LocationService, NetworkService } from '@shared/services';
import { timer } from 'rxjs';

@Component({
	selector: 'app-attachment-bubble-quick-chat',
	templateUrl: './attachment-bubble-quick-chat.component.html',
	styleUrls: ['./attachment-bubble-quick-chat.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentBubbleQuickChatComponent implements OnInit {
	@Output() public attachmentFileStatusEmitter = new EventEmitter<any>();
	@Output() public attachmentTypeEmitter = new EventEmitter<string>();

	@ViewChild('fileUpload', { static: true }) public fileUpload: FileUploadComponent;

	// #modalRegion
	public imageUploadModalOpen = 'none';
	public locationSelectionModalOpen = 'none';
	public documentSelectionModalOpen = 'none';
	// #endRegion

	public locations: any;
	public locationErrorMessage = '';
	public isLocation = true;

	private fileUploadData: any;

	public model = new Post(
		{
			title: '',
			body: '',
			category: null,
			privacy: '',
			images: [],
			preview_images: [],
			shareable: true,
			commentable: true,
			channel: '',
		},
		true
	);

	constructor(
		public layoutService: LayoutService,
		private geoLocationService: GeoLocationService,
		private locationService: LocationService,
		private networkService: NetworkService,
		private changeDetectorRef: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.fileUpload?.documentEmitter.subscribe((data) => {
			this.fileUploadData = data;
		});
	}

	// #Modal-handling starts

	/**
	 * Main Attachment modal open function
	 * @param type Modal type
	 */
	public onClickAttachment(type: string): void {
		switch (type) {
			case 'image':
				this.imageUploadModalOpen = 'block';
				this.model['images'] = [];
				this.model['preview_images'] = [];
				break;
			case 'location':
				this.onLocationSearch();
				break;
			case 'document':
				this.documentSelectionModalOpen = 'block';

				break;
		}
	}

	/**
	 * Main modal close function
	 * @param type Modal type
	 */
	public closeModal(type: string): void {
		switch (type) {
			case 'image':
				this.imageUploadModalOpen = 'none';
				break;
			case 'location':
				this.locationSelectionModalOpen = 'none';
				break;
			case 'document':
				this.documentSelectionModalOpen = 'none';
				this.model.images = [];
				this.model.preview_images = [];
				if (this.fileUpload?.maxFiles && this.fileUpload?.isFilePresent) {
					this.fileUpload.maxFiles = 1;
					this.fileUpload.isFilePresent = false;
				}
				break;
		}

		this.changeDetectorRef.detectChanges();
	}

	// #endRegion

	/**
	 * Location Sharing
	 */
	public handleLocationShare(event: any): void {
		this.attachmentFileStatusEmitter.emit(event);
		this.attachmentTypeEmitter.emit('location');
		this.closeModal('location');
	}

	/**
	 * Document Sharing
	 */
	public handleDocumentShare(): void {
		timer(150).subscribe(() => {
			if (this.fileUploadData) {
				this.attachmentFileStatusEmitter.emit(this.fileUploadData);
				this.attachmentTypeEmitter.emit('document');
				this.closeModal('document');
			}
			// console.log('document', this.fileUploadData);
		});
		this.closeModal('document');
	}

	/**
	 * Image Sharing
	 */
	public handleImageShare(event: any): void {
		this.attachmentFileStatusEmitter.emit(event[0]);
		this.attachmentTypeEmitter.emit('image');
		this.closeModal('image');
	}

	/**
	 * Link (url) Sharing
	 */
	private handleUrlShare(event: any): void {
		this.attachmentFileStatusEmitter.emit(event);
		this.attachmentTypeEmitter.emit('url');
		this.closeModal('url');
	}

	/**
	 * Contact Sharing
	 */
	private handleContactShare(event: any): void {
		this.attachmentFileStatusEmitter.emit(event);
		this.attachmentTypeEmitter.emit('contact');
		this.closeModal('contact');
	}

	// #Location-handling starts

	/**
	 * Sort location
	 * @param data
	 * @returns
	 */
	private sortData(data: any): void {
		return data.sort(function (m1: any, m2: any) {
			if (m1.distance < m2.distance) {
				return -1;
			} else if (m1.distance > m2.distance) {
				return 1;
			} else {
				return 0;
			}
		});
	}

	/**
	 * Handle location search
	 * @param element search keyword
	 */
	public onLocationSearch(element: any = ''): void {
		let q = null;

		if (element) {
			q = element.target.value;
		} else {
			this.locationSelectionModalOpen = 'block';
		}

		this.locations = [];
		this.locationErrorMessage = '';

		if (this.locations?.length === 0 || q) {
			const self = this;
			let locations = [];
			const accuracy = { enableHighAccuracy: true };

			this.geoLocationService?.getLocation(accuracy).subscribe(
				(position: any) => {
					// const currentLocation = position;
					self.locationService?.getNearMeLocations(position.coords, q).subscribe((data: any) => {
						locations = data?.response?.groups[0]?.items.map((loc: any) => new LocationObj(loc, '4sq'));
						self.locations = self.sortData(locations);
					});

					self.locationErrorMessage = '';
					self.isLocation = true;
					self.changeDetectorRef.detectChanges();
				},
				function (error) {
					self.locationErrorMessage = error;
					self.isLocation = false;
					self.changeDetectorRef.detectChanges();
				}
			);
		}
	}

	/**
	 * Current Location selection
	 */
	public sendCurrentLocation(): void {
		if (navigator) {
			navigator.geolocation.getCurrentPosition((position) => {
				const lng = +position.coords.longitude;
				const lat = +position.coords.latitude;
				const locObj = { latitude: lat, longitude: lng };
				const selectedLocation = locObj;
				this.handleLocationShare(selectedLocation);
			});
		}
	}
	// #endRegion
}
