import { Component, Input, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
	selector: 'app-success-animation',
	templateUrl: './success-animation.component.html',
	styleUrls: ['./success-animation.component.scss'],
})
export class SuccessAnimationComponent implements OnInit {
	@Input() public isVisible = false;

	public options: AnimationOptions = {
		// eslint-disable-next-line spellcheck/spell-checker
		path: '/assets/lottie/success-finisher.json',
		loop: true,
		// eslint-disable-next-line spellcheck/spell-checker
		autoplay: true,
		// eslint-disable-next-line spellcheck/spell-checker
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	constructor() {}
	ngOnInit(): void {}

	public animationCreated(animationItem: AnimationItem): void {
		animationItem.setSpeed(2);
	}
}
