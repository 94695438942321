import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeLayoutComponent } from './home-layout.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
	declarations: [HomeLayoutComponent],
	imports: [CommonModule, SharedModule],
	exports: [HomeLayoutComponent],
	bootstrap: [HomeLayoutComponent],
})
export class HomeLayoutModule {}
