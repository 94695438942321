import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NetworkService, LayoutService } from '../../shared';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-purchase-plans',
	templateUrl: './purchase-plans.component.html',
	styleUrls: ['./purchase-plans.component.css'],
})
export class PurchasePlansComponent implements OnInit, OnDestroy {
	private networkSubscriber;
	private currentNetSub;
	private activeSubscriber;
	private subscriptionReady;
	private subscription: Subscription = new Subscription();

	public appURL = environment.appURL;
	public isProcessing = false;
	public modalText;
	public displayCancelMessage = 'none';
	public showBackBtn = false;
	public subscriptionCancelDate: any;
	public stripeCustomerId: any;
	public stripeKey: any;
	public trialEnds: any;
	public network;
	public purchasePlan;
	public seatsCount: any = 5;
	public purchasePlans = [];
	public defaultPurchasePlan;
	public currentRenewalFrequency;
	public currentSubscription;
	public purchasedPlan = false;
	public currentPlanSeats: any;
	public subscriptionCancel = false;
	public isAdmin = false;
	public renewableDate: any;
	public cancelDate: any;
	public planDetails = {
		network: '',
		currency: '',
		seats: '',
		renewal_frequency: '',
		subscription_template: '',
	};
	public isSubscriptionUpdate = false;
	public isAnnuallyRenewal = true;

	constructor(
		private translate: TranslateService,
		private router: Router,
		private _location: Location,
		public networkService: NetworkService,
		public layoutService: LayoutService,
		private pageLoadingUiService: PageLoadingUiService
	) {
		console.log('reached purchase plans');
	}

	/**
	 * 1. Check if LS contains default purchase plan
	 * 2. Set default purchase plan locally
	 * 3. Else set default purchase plan empty
	 */
	ngOnInit(): void {
		console.log('reached purchase plans');
		this.networkService.getNetworkSubscriptionDetails();

		this.networkSubscriber = this.networkService.networkReady.subscribe((networkLoaded) => {
			if (networkLoaded) {
				this.currentNetSub = this.networkService.currentNetwork.subscribe((network) => {
					this.network = network;
					if (this.network['membership'] && this.network['membership']['memberType'] === 'admin') {
						this.isAdmin = true;
					} else {
						this.isAdmin = false;
					}
				});
			}
		});

		localStorage.removeItem('status');
		localStorage.removeItem('currentPurchasePlan');
		localStorage.removeItem('currentSubscriptionStatus');

		this.getPurchasePlans();

		if (localStorage.getItem('payment_status')) {
			localStorage.removeItem('payment_status');
		}
	}

	public ngOnDestroy(): void {
		if (this.currentNetSub) {
			this.currentNetSub.unsubscribe();
		}

		if (this.activeSubscriber) {
			this.activeSubscriber.unsubscribe();
		}

		if (this.subscriptionReady) {
			this.subscriptionReady.unsubscribe();
		}

		if (this.subscription) {
			this.subscription.unsubscribe();
		}

		if (this.networkSubscriber) {
			this.networkSubscriber.unsubscribe();
		}
	}

	/**
	 * Function to get all purchase plans for a network
	 * 1. Call api to get purchase plans
	 * 2a. Filter out old pricing plans
	 * 2b. Add features and annual pricing for each plan
	 * 3. Sort plans based on ascending order of plan fee
	 * 4. Save purchase plans on Local Storage
	 * 5. Set number of seats 5 as default
	 * 6. Calculate annual fee for a plan
	 * 7. If there's default purchase plan,highlight that plan
	 */
	private getPurchasePlans(): void {
		this.pageLoadingUiService.setPageLoading(true);

		this.subscription = this.networkService.getSubscriptions(this.network.uid).subscribe(
			(subscriptions) => {
				// 1
				if (subscriptions.stripe_customer_id) {
					this.stripeCustomerId = subscriptions.stripe_customer_id;
				}
				if (subscriptions.stripe_key) {
					this.stripeKey = subscriptions.stripe_key;
				}

				if (subscriptions.subscription_templates) {
					this.purchasePlans = subscriptions.subscription_templates;

					// 2b
					this.purchasePlans.forEach((plan: any) => {
						switch (plan?.name) {
							case 'Starter':
								// eslint-disable-next-line spellcheck/spell-checker
								plan.features = ['3 Projects', '1500 Tasks', '100 mb / max 5mb per doc', '2 week data history',
									'Team Feed', 'Unlimited messaging', 'Performance tracking', 'Admin console', 'List view projects',
									'Board view', 'Calendar view', 'Unlimited posts', 'Private teams', 'Daily flows', 'Time Tracking', 'IOS and android apps'
								];
								if (plan.renewal_frequency) {
									plan['seats'] = 5;
									plan['annualFee'] = 0;
								}
								break;
							case 'Basic':
							case 'Premium':
								plan.features = ['Unlimited Projects', '3000 Tasks', 'Unlimited users + 2 guests', 'Custom permissions', '20GB Storage', 'Unlimited data history', 'Direct customer support (email)'];
								if (plan.renewal_frequency) {
									plan['seats'] = 5;
									plan['annualFee'] = 7;
								}
								break;
							case 'Pro':
							case 'Business':
								// eslint-disable-next-line spellcheck/spell-checker
								plan.features = ['Unlimited Tasks', 'Unlimited users + 5 guests',  '100GB File Storage', 'Active directory and G-suite Integration', 'Tailor-made onboarding',  'Live customer support'];
								if (plan.renewal_frequency) {
									plan['seats'] = 5;
									plan['annualFee'] = 9;
								}
								break;
						}
					});

					this.purchasePlans.sort(
						(a, b) => a.fee - b.fee // 3
					);
					const enterprisePlan = {
						name: 'Enterprise',
						// eslint-disable-next-line spellcheck/spell-checker
						features: ['SSO Login', 'Custom Integrations', 'Cloud and Onprem', 'White Label', 'Unlimited guests', '1TB storage', 'Custom Feature development'],
					};
					this.purchasePlans.push(enterprisePlan);
					localStorage.setItem('purchasePlans', JSON.stringify(this.purchasePlans)); // 4
					this.getUserActivePlanDetails();
				}
			},
			() => {
				this.pageLoadingUiService.setPageLoading(false);
			}
		);
	}

	/**
	 * Function for getting payment details of selected plan
	 * @param selectedPlan
	 */
	private onSelectPurchasePlan(selectedPlan): void {
		localStorage.setItem('currentPurchasePlan', JSON.stringify(selectedPlan));

		this.router.navigateByUrl(`/network/purchase?key=${this.stripeKey}&id=${this.stripeCustomerId}`);
	}

	private onUpdatePurchasePlan(selectedPlan): void {
		localStorage.setItem('currentPurchasePlan', JSON.stringify(selectedPlan));

		this.router.navigateByUrl(`/network/purchase?key=${this.stripeKey}&id=${this.stripeCustomerId}&update=true`);
	}

	public redirectToCheckout(plan: any): void {
		if (this.isAdmin) {
			plan.renewal_frequency = this.isAnnuallyRenewal ? 'yearly' : 'monthly';

			localStorage.setItem('currentPurchasePlan', JSON.stringify(plan));

			if (plan.createSubscription) {
				this.router.navigateByUrl(`/network/purchase?key=${this.stripeKey}&id=${this.stripeCustomerId}`);
			} else if (plan.selectedPlan && !plan.createSubscription) {
				this.router.navigateByUrl(`/network/purchase?key=${this.stripeKey}&id=${this.stripeCustomerId}&update=true`);
			}
		}
	}

	private getUserActivePlanDetails(): void {
		this.subscriptionReady = this.networkService.subscriptionReady.subscribe((subscriptionLoaded) => {
			if (subscriptionLoaded) {
				this.activeSubscriber = this.networkService.currentSubscription.subscribe(
					(data) => {
						this.pageLoadingUiService.setPageLoading(false);
						if (data.subscriptionPlan) {
							this.currentSubscription = data['subscriptionPlan']['name'].toLowerCase();
							this.currentPlanSeats = 5;
							// data['numberOfSeats'];

							if (data['subscriptionStripeData'] && data['subscriptionStripeData']['interval']) {
								const interval = data['subscriptionStripeData']['interval'];

								if (interval === 'month') {
									this.currentRenewalFrequency = 'monthly';
								} else {
									this.currentRenewalFrequency = 'yearly';
								}
							}

							if (data.trialEnd > 0) {
								this.trialEnds = data.trialEnd;
							}

							if (data.cancelAt) {
								this.cancelDate = this.formatDate(data.cancelAt);
								this.renewableDate = this.formatDate(data.renewableDate);

								this.manageSubscriptions();
							} else {
								this.cancelDate = '';
								this.renewableDate = '';

								if (!data['purchasedPlan']) {
									this.purchasedPlan = false;
									this.manageSubscriptions();
									this.networkService.previousPaymentDetails = '';
								} else {
									this.manageSubscriptions();
									this.purchasedPlan = true;
									this.networkService.previousPaymentDetails = data;
								}
							}

							this.highlightCurrentSubscription(this.trialEnds);
							localStorage.setItem('currentSubscriptionStatus', data?.status);
						}
					},
					() => {
						this.pageLoadingUiService.setPageLoading(false);
					}
				);
			}
		});
	}

	private formatDate(_date: any): string {
		// let day: any,
		//   month: any,
		//   year: any,
		const date = new Date(_date),
			month = date.getMonth() + 1,
			day = date.getDate(),
			year = date.getFullYear();

		let selectedDay, selectedMonth;

		if (day < 10) {
			selectedDay = '0' + day;
		} else {
			selectedDay = day;
		}

		if (month < 10) {
			selectedMonth = '0' + month;
		} else {
			selectedMonth = month;
		}

		const formattedDate = selectedDay + '-' + selectedMonth + '-' + year;

		return formattedDate;
	}

	private highlightCurrentSubscription(trialEnds): void {
		this.purchasePlans.forEach((plan) => {
			if (plan.name && this.currentSubscription) {
				const name = plan.name.toLowerCase();

				if (plan.renewal_frequency && name === this.currentSubscription && !localStorage.getItem('networkExpired')) {
					plan.selectedPlan = true;
					plan.trial_period = '';
					plan.trialEnds = trialEnds;
					plan.planText = this.translate.instant('Your Current Plan');
					plan.seats = this.currentPlanSeats;
					if (this.currentRenewalFrequency) {
						plan.renewal_frequency = this.currentRenewalFrequency;
					}

					if (this.purchasedPlan && !this.cancelDate) {
						plan.createSubscription = false;
						plan.trial_period = '';
					} else if (this.cancelDate) {
						plan.trialEnds = '';
					}
				}
			}
		});
	}

	private manageSubscriptions(): void {
		this.purchasePlans.forEach((plan) => {
			if (plan['renewal_frequency']) {
				if (!this.purchasedPlan && !this.cancelDate && !localStorage.getItem('networkExpired')) {
					plan['createSubscription'] = true;
					// eslint-disable-next-line spellcheck/spell-checker
					plan['cancelledSubscription'] = false;
				} else if (this.cancelDate) {
					plan['createSubscription'] = true;
					// eslint-disable-next-line spellcheck/spell-checker
					plan['cancelledSubscription'] = true;
				} else if (localStorage.getItem('networkExpired')) {
					plan['createSubscription'] = true;
					// eslint-disable-next-line spellcheck/spell-checker
					plan['cancelledSubscription'] = true;
				}
			}
		});
	}

	private createSubscriptionPayment(essentialPlan): void {
		this.pageLoadingUiService.setPageLoading(true);

		const object = {
			subscription_template: essentialPlan.uid,
			network: this.network.uid,
			seats: 1,
			renewal_frequency: 'monthly',
		};
		this.networkService.createSubscriptionPayment(object).subscribe(
			() => {
				this.pageLoadingUiService.setPageLoading(false);
				essentialPlan['selectedPlan'] = true;
			},
			() => {
				this.pageLoadingUiService.setPageLoading(false);
			}
		);
	}

	private confirmSubscriptionCancel(): void {
		this.isProcessing = true;

		const data = {
			action: 'cancel',
			network: this.network.uid,
		};

		this.networkService.cancelSubscriptionPlan(data).subscribe(
			(res) => {
				const date = res.nextBill;
				const d = new Date(date);
				let selectedDay: any;
				let selectedMonth: any;

				const month = d.getMonth() + 1;
				const day = d.getDate();
				const year = d.getFullYear();

				if (day < 10) {
					selectedDay = '0' + day;
				} else {
					selectedDay = day;
				}

				if (month < 10) {
					selectedMonth = '0' + month;
				} else {
					selectedMonth = month;
				}

				this.networkService.getNetworkSubscriptionDetails();
				this.subscriptionCancelDate = selectedDay + '-' + selectedMonth + '-' + year;
				this.isProcessing = false;
				this.showBackBtn = true;
			},
			() => {
				this.isProcessing = false;
				this.displayCancelMessage = 'none';
			}
		);
	}

	public onClickBack(): void {
		this._location.back();
	}
}
