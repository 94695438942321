import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class InboxService {
	constructor(private apiService: ApiService) {}

	// eslint-disable-next-line spellcheck/spell-checker
	public getToken(username, nonse): Observable<any> {
		return this.apiService.post('/users/' + username + '/chat/', {
			// eslint-disable-next-line spellcheck/spell-checker
			nonce: nonse,
		});
	}

	public sendLayerNotification(username, data): Observable<any> {
		// eslint-disable-next-line spellcheck/spell-checker
		return this.apiService.post('/users/' + username + '/layernotification/', data);
	}

	public searchConversations(networkId, query, userId): Observable<any> {
		// return this.apiService.chatGet(`/users/${userId}/conversations?network=${networkId}&query=${query}`);
		return this.apiService.chatGet(`/${userId}/conversations?network=${networkId}&query=${query}`);
	}
}
