import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { SharedModule } from '../shared';
import { PostComponent } from './post.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { BeeAttachmentModule } from 'projects/bee-attachment/src/public-api';
import { PeerCoreModule } from 'projects/peer-core/src/public-api';

// ant design
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzInputModule } from 'ng-zorro-antd/input';
import { TaskStartEndPopupModule } from '@shared/task-start-end-popups/task-start-end-popups.module';
import { ReplacePipe } from './pipes/replace.pipe';
import { CommentPopupModule } from '@shared/comment-popup/comment-popup.module';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { CustomSelectInputModule } from '@shared/custom-select-input/custom-select-input.module';

@NgModule({
	imports: [FormsModule, AgmCoreModule, SharedModule, NgbModule, NgxMaterialTimepickerModule, BeeAttachmentModule, PeerCoreModule, NzModalModule, NzInputModule, TaskStartEndPopupModule, CommentPopupModule, NzSelectModule, CustomSelectInputModule],
	declarations: [PostComponent, ReplacePipe],
	exports: [PostComponent, ReplacePipe],
	bootstrap: [PostComponent],
	providers: [],
})
export class PostModule {}
