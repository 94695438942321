import { Component, OnInit, ViewChild, Input, SimpleChanges, ElementRef, OnChanges, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService, NetworkService, LayoutService, PaginationService, ProductService, ProfilesService, PostService, CommentsService, PrivacyComponent } from '../../shared';
import { Location } from '@angular/common';
import { Comment, Reminder, Post, Issue } from '../../shared/models';
import { TranslateService } from '@ngx-translate/core';
import { PostEditComponent } from '../../shared/layout/post-edit/post-edit.component';
import { PostComponent } from '../../post/post.component';
import { SearchService } from '../../shared/services/search.service';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Subject, Subscription, timer } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ToastService } from '../../shared/services/common/toast.service';
import { ClipboardService } from 'projects/peer-core/src/lib/services/ui/clipboard.service';
import { LightboxService } from 'projects/peer-core/src/lib/services/ui/lightbox.service';
import { IImageAttachment, ImageAttachment } from 'projects/bee-attachment/src/lib/shared/models/image-attachment';
import { ContextMenuComponent, ContextMenuService } from '@perfectmemory/ngx-contextmenu';

const CSS_CLASS_NAMES = {
	highLight: 'dd-highlight-item',
};

@Component({
	selector: 'app-product-detail',
	templateUrl: './product-detail.component.html',
	styleUrls: ['./product-detail.component.css'],
})
export class ProductDetailComponent implements OnInit, OnChanges, OnDestroy {
	@ViewChild('productItemContextMenu')
	public productItemContextMenu: ContextMenuComponent;

	@ViewChild('productContextMenu')
	public productContextMenu: ContextMenuComponent;

	constructor(
		private router: Router,
		private location: Location,
		private commentsService: CommentsService,
		private postService: PostService,
		private profileService: ProfilesService,
		private productService: ProductService,
		private userService: UserService,
		private paginationService: PaginationService,
		private networkService: NetworkService,
		private activatedRoute: ActivatedRoute,
		private translate: TranslateService,
		public layoutService: LayoutService,
		private searchService: SearchService,
		private toastService: ToastService,
		private clipboardService: ClipboardService,
		private lightboxService: LightboxService,
		private contextMenuService: ContextMenuService
	) {
		this.paginationService.scrollDistance = 8;
		this.paginateSub$ = this.paginationService.initiateScroll.subscribe((data) => {
			if (data && !this.paginating) {
				this.paginate();
			}
		});
	}
	@Input() public filterString;
	@Input() public currentPage = 'product-detail';
	@ViewChild('postEdit', { static: true }) public postEdit: PostEditComponent;
	@ViewChild('postComponent', { static: true }) public postComponent: PostComponent;
	@ViewChild(PrivacyComponent, { static: true }) public privacy;
	@ViewChild('text', { static: false }) public text: ElementRef;
	public modal = { channel: '' };
	public showPostCreateForm = false;
	public postType;
	public searchSubscription: any;
	public isFirstSearch = true;
	public uid;
	public loadingGif = 'none';
	public progressWidth: any;
	public issues = [];
	public nextUrl;
	public paginateSub$: any;
	private paginating = false;
	public isFetching = 0;
	public product;
	public privacyString = '';
	public network;
	public currentUser;
	private networkSubscriber;
	private currentNetSub;
	private currentDepartmentSubscriber;
	private departmentsReady;
	public departments = [];
	public personalFilters = [];
	public SubscriptionTemplates = [];
	public roles = [];
	public value;
	public selectedIssue;
	public tab;
	public index;
	public editSelectedIssue;
	public isArchived = false;
	public param = { product: '' };
	public forNames;
	// Modals
	public displayProductDelete = 'none';
	public deleteModal = 'none';
	public displayVersionModal = 'none';
	public displayInprogressModal = 'none';
	public displayProductEdit = 'none';
	// Reports
	public reportReasons: any[] = [];
	public reportModal = 'none';
	public flag = { value: '', text: '' };
	public reportPost;
	public reportText;
	public isReported = false;
	// Reminders
	public reminderOptions: any[] = [];
	public displayReminderModal = 'none';
	public reminderTime;
	public timeFor;
	public remindersSelected = [];
	public reminderModel = new Reminder();
	public reminderToast = false;
	public issueForReminder;
	// issue actions
	public estimatedTime;
	public version;
	public error = false;
	public filter = '';
	public counts;

	public boardView = 'none';
	public openPosts: any[] = [];
	public inProgressPosts: any[] = [];
	public closedPosts: any[] = [];
	public fixedPosts: any[] = [];
	public releasedPosts: any[] = [];
	public poolPosts: any[] = [];
	public openFetching = 0;
	public inProgressFetching = 0;
	public closedFetching = 0;
	public fixedFetching = 0;
	public releasedFetching = 0;
	public poolFetching = 0;
	public openNextUrl;
	public inProgressNextUrl;
	public closedNextUrl;
	public fixedNextUrl;
	public releasedNextUrl;
	public poolNextUrl;
	public scrollStatus = false;
	public openCount;
	public inProgressCount;
	public fixedCount;
	public releasedCount;
	public closedCount;
	public poolCount;
	public query;
	public boardPost;
	public currentPriority = 'p2';
	public displayPriority = 'none';
	public priorities = ['p1', 'p2', 'p3'];

	// #region Context Menu
	public selectedItem: any;
	public selectedItemIndex = 0;
	// #endregion

	@ViewChild('panel', { static: false }) public panel: ElementRef<any>;
	private arrowKeyLocation = -1;
	public shiftKeyUsed = false;
	private keyupSubscription: Subscription;
	public isMentions = false;
	public mentionCount = 0;
	public mentionType;
	public mentionIndex;
	public userMentions = [];
	public hashMentions = [];
	public nextMentionUrl;
	public mentionPrivacy: any;
	public CaretPosition: any;
	private subject: Subject<string> = new Subject();
	public mentionsFetched = 0;
	public userRegexFailed = false;
	public hashRegexFailed = false;
	public hashes = [];
	public native;
	public todoListUid;

	public model = new Post(
		{
			title: null,
			body: null,
			state: '',
			type: null,
			status: null,
			shareable: true,
			commentable: true,
			category: null,
		},
		true
	);
	public comment = new Comment({ text: '', isLink: false, auto_generated: true }, true);

	ngOnInit(): void {
		this.activatedRoute.queryParams.subscribe((params) => {
			if (params['archived']) {
				this.isArchived = true;
			} else {
				this.isArchived = false;
			}
			if (params['id']) {
				this.uid = params['id'];
				this.getProductDetails(params['id']);
				this.getProductCounts(this.uid);

				if (this.productService.tab) {
					this.getIssues(this.productService.tab);
				} else {
					this.getIssues('open');
				}
			}
		});

		this.networkSubscriber = this.networkService.networkReady.subscribe((networkLoaded) => {
			if (networkLoaded) {
				this.currentNetSub = this.networkService.currentNetwork.subscribe((network) => {
					this.network = network;
					this.roles = network['roles'];
					this.currentUser = this.userService.getCurrentUser();

					if (network && network['membership']) {
						network.membership.memberRole.fields.forEach((element) => {
							this.departmentsReady = this.networkService.departmentsReady.subscribe((departmentsLoaded) => {
								if (departmentsLoaded) {
									this.currentDepartmentSubscriber = this.networkService.currentDepartments.subscribe((departments) => {
										if (departments && departments['objects'].length > 0) {
											this.departments = departments.objects;
										}
									});
								}
							});

							if (element.CP === true) {
								this.personalFilters.push(element);
							}
						});
					}

					if (this.network.SubscriptionTemplates) {
						this.SubscriptionTemplates = this.network.SubscriptionTemplates;
					}
				});
			}
		});

		/** For handling @/# mentions searches
		 * 1. Call api for getting search results
		 * 2. If mention type is user,fill userMentions array
		 * 3. Else fill hashMentions array
		 * 4. If nextUrl exists,set nextUrl and call paginate function
		 */
		this.subject.pipe(debounceTime(400)).subscribe((searchTextValue) => {
			// 1
			const i = searchTextValue.indexOf('@');
			if (i > -1) {
				searchTextValue = searchTextValue.substring(i + 1);
			}
			if (this.mentionType === 'user') {
				if (searchTextValue.length >= 3) {
					this.mentionsFetched = 1;
				} else {
					return;
				}
			}
			this.postService.getMentions(searchTextValue, this.mentionType, this.mentionPrivacy).subscribe((data) => {
				this.mentionsFetched = 0;
				this.arrowKeyLocation = -1;
				this.mentionCount = data.count;

				if (!this.userRegexFailed || !this.hashRegexFailed) {
					if (this.mentionType === 'user') {
						// 2
						this.hashMentions = [];
						this.userMentions = data.objects;
					} else if (this.mentionType === 'hash') {
						this.userMentions = []; // 3
						const filterData = [];
						data.objects.forEach((item: any) => {
							if (item.name !== '') {
								filterData.push(item);
							}
						});
						this.hashMentions = filterData;
					}
					if (data.next) {
						// 4
						this.nextMentionUrl = data.next.split('alpha')[1];
						this.paginateMentions();
					} else {
						this.nextMentionUrl = '';
					}
				}
			});
		});

		this.postEdit.updatedPostEmitter.subscribe((data) => {
			if (data) {
				if (this.boardView === 'none') {
					let index = -1;
					index = this.findWithAttr(this.issues, 'uid', data['uid']);
					if (index > -1) {
						this.issues[index] = data;
					}
					this.checkUserMentionAndHashtag();
				} else {
					if (data['status'] === 'open') {
						this.getOpenLists('');
					} else if (data['status'] === 'in_progress') {
						this.getInProgressLists('');
					} else if (data['status'] === 'fixed') {
						this.getFixedLists('');
					} else if (data['status'] === 'closed') {
						this.getClosedLists('');
					} else if (data['status'] === 'pool') {
						this.getPoolLists('');
					} else {
						this.getReleasedLists('');
					}
				}
			}
		});

		this.postComponent.newPostEmitter.subscribe((data) => {
			if (data) {
				this.getIssues('open');
				this.getProductDetails(this.product['uid']);
				this.getProductCounts(this.product['uid']);
			}
		});

		this.privacy.privacyModalStatus.subscribe((data) => {
			if (data === 'closed') {
				this.layoutService.hideBd();
				this.updatePrivacyValues();
			}
		});

		this.layoutService.showBackdrop.subscribe((data) => {
			if (data === 'event-post-form' || data === 'hide') {
				this.showPostCreateForm = false;
				this.postType = '';
			}
		});

		if (window.location.pathname === '/network/product-detail') {
			this.searchSubscription = this.searchService.searchValue.subscribe((data) => {
				if (this.isFirstSearch && data.trim()) {
					this.isFirstSearch = false;
				}
				if (!this.isFirstSearch) {
					let text = '';
					if (data) {
						text = data.trim();
					}
					this.isFetching = 0;
					this.issues = [];
					this.productService.searchIssues(this.uid, this.tab, text).subscribe((res) => {
						this.issues = res.objects;
						this.isFetching = 1;
						this.checkUserMentionAndHashtag();

						if (res.next) {
							this.nextUrl = res.next.split('alpha')[1];
						} else {
							this.nextUrl = '';
						}
					});
				}
			});
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.hasOwnProperty('filterString')) {
			this.getIssuesInProducts(this.filterString);
		}
	}

	ngOnDestroy(): void {
		if (this.departmentsReady) {
			this.departmentsReady.unsubscribe();
		}
		if (this.currentDepartmentSubscriber) {
			this.currentDepartmentSubscriber.unsubscribe();
		}
		if (this.networkSubscriber) {
			this.networkSubscriber.unsubscribe();
		}
		if (this.currentNetSub) {
			this.currentNetSub.unsubscribe();
		}
		if (this.paginateSub$) {
			this.paginateSub$.unsubscribe();
		}
		if (this.searchSubscription) {
			this.searchSubscription.unsubscribe();
		}
		this.searchService.resetSearchValue();
	}

	private getProductDetails(uid): void {
		this.productService.getProductDetail(uid, this.isArchived).subscribe((data) => {
			this.product = data;
			this.updatePrivacyString(data);
		});
	}

	private getProductCounts(uid): void {
		this.productService.getProductCount(uid).subscribe((data) => {
			this.counts = data;

			this.calculateProgressBar();
		});
	}

	private calculateProgressBar(): void {
		if (this.product) {
			this.progressWidth = '0%';

			const totalCount = this.product.totalCount,
				openCount = this.counts.openCount;

			console.log(totalCount, openCount);
			if (totalCount > 0) {
				const c = totalCount / openCount,
					width = Math.floor(100 / c);

				console.log(c, width);

				this.progressWidth = width + '%';
			}
		}
	}

	private updatePrivacyString(product): void {
		this.privacyString = '';
		if (product['customPrivacy']) {
			product['customPrivacy'].forEach((cp, index) => {
				this.privacyString = `${this.privacyString}${index > 0 ? ', ' : ' '}${cp.name}`;
				this.forNames = this.privacyString;
			});
		} else if (product['contentPrivacy']) {
			if (product['contentPrivacy'].role && product['contentPrivacy'].department) {
				const index = this.departments.findIndex((department) => department.uid === Object.values(product['contentPrivacy']));
				if (index > -1) {
					this.privacyString = this.departments[index]['name'] + ' , ' + product['contentPrivacy'].role;
				}
			} else if (product['contentPrivacy'].role) {
				this.privacyString = product['contentPrivacy'].role;
			} else if (product['contentPrivacy'].department) {
				const index = this.departments.findIndex((department) => department.uid === Object.values(product['contentPrivacy']));
				if (index > -1) {
					this.privacyString = this.departments[index]['name'];
				}
			} else if (!product['contentPrivacy'].role || !product['contentPrivacy'].department) {
				this.value = Object.keys(product['contentPrivacy']);
				this.personalFilters.forEach((element) => {
					if (element.key === this.value[0]) {
						this.privacyString = element.valueLabel;
					}
				});

				if (product['contentPrivacy'].status) {
					this.privacyString = product['contentPrivacy'].status;
				} else if (product['contentPrivacy'].renewalStatus) {
					this.privacyString = product['contentPrivacy'].renewalStatus;
				} else if (product['contentPrivacy'].membership_level) {
					const index = this.SubscriptionTemplates.findIndex((val) => val.uid === product['contentPrivacy'].membership_level);
					if (index > -1) {
						this.privacyString = this.SubscriptionTemplates[index]['name'];
					}
				} else if (product['contentPrivacy'].trialPeriod) {
					this.privacyString = product['contentPrivacy'].trialPeriod;
				} else if (product['contentPrivacy'].manager) {
					this.privacyString = this.translate.instant('My Team');
				}
			}
			this.forNames = this.privacyString;
		} else if (product['channel'] && Object.keys(product['channel']).length) {
			this.privacyString = product['channel'].name;
			this.forNames = this.privacyString;
		} else {
			this.privacyString = 'Everyone';
			this.forNames = this.privacyString;
		}
		this.privacy.previousSelection = this.forNames;
		this.privacy.currentSelection = this.forNames;
	}

	/**
	 * Function on switching between filters
	 * @param tab
	 */
	public displayIssues(tab): void {
		if (this.isFetching === 1) {
			this.productService.tab = tab;
			this.getIssues(tab);
		}
	}

	/**
	 * Function to get issues based on product state
	 * @param state
	 */
	// getIssues(state) {
	//   this.tab = state;
	//   this.isFetching = 0;
	//   this.issues = [];
	//   this.productService.getIssuesByStatus(this.uid, state, this.isArchived).subscribe((data) => {
	//     this.issues = data.objects;
	//     this.isFetching = 1;
	//     this.checkUserMentionAndHashtag();

	//     if (data.next) {
	//       this.nextUrl = data.next.split('alpha')[1];
	//     } else {
	//       this.nextUrl = '';
	//     }
	//   }, (err) => {
	//     this.issues = []
	//     this.isFetching = 1;
	//   });

	// }

	private getIssues(state): void {
		this.tab = state;
		this.isFetching = 0;
		this.issues = [];
		this.productService.filterByType(this.uid, state, this.isArchived, this.filter, '').subscribe(
			(data) => {
				this.issues = data.objects;
				this.isFetching = 1;
				this.checkUserMentionAndHashtag();

				if (data.next) {
					this.nextUrl = data.next.split('alpha')[1];
				} else {
					this.nextUrl = '';
				}
			},
			() => {
				this.issues = [];
				this.isFetching = 1;
			}
		);
	}

	/**
	 * Function for managing pagination of issues if next url exists
	 */
	public paginate(): void {
		if (this.nextUrl) {
			this.paginating = true;
			this.isFetching = 0;
			this.productService.paginate(this.nextUrl).subscribe((data) => {
				this.issues = this.issues.concat(data.objects); // 3
				this.checkUserMentionAndHashtag();
				this.paginating = false;
				this.isFetching = 1;

				if (data.next) {
					this.nextUrl = data.next.split('alpha')[1];
				} else {
					this.nextUrl = '';
				}
			});
		}
	}

	/**
	 * Function to manage user mentions/hashtags within issue title
	 * 1.If string contains '<',then its cut off while displaying in innerHtml.Hence its replaced by '< '
	 */
	private checkUserMentionAndHashtag(): void {
		this.issues.forEach((issue) => {
			if (issue['title'] && issue['title'].indexOf('<') > -1) {
				// 1
				issue['title'] = issue['title'].replace(/</g, '< ');
			}

			issue['altered_title'] = issue['title'];
			if (issue['mentionedUsers']) {
				issue['mentionedUsers'].forEach((mentionedUser) => {
					const html = '<b #userMention><a class="text-green">@' + mentionedUser['name'] + '</a></b>';
					if (issue.altered_title) {
						issue['altered_title'] = issue.altered_title.replace('@' + mentionedUser['name'], html);
					}
				});
			}
			if (issue['hashtags']) {
				issue['hashtags'].forEach((hashtag) => {
					if (hashtag['name']) {
						const html = '<b #hashTag><a class="hash-todo">#' + hashtag['name'] + '</a></b>';
						if (issue.altered_title) {
							issue['altered_title'] = issue.altered_title.replace('#' + hashtag['name'], html);
						}
					}
				});
			}
		});
	}

	/**
	 * Function to manage issue post link share
	 * @param post
	 */
	public handleIssueLinkShare(post: any): void {
		let text;
		if (post['title'] && post['description']) {
			text = `${post.title.substring(0, 80)}.
      ${post.description.substring(0, 110)}....
      ${post.shareUrl}`;
		} else if (post['title'] && !post['description']) {
			text = `${post.title.substring(0, 80)}.
      ${post.shareUrl}`;
		} else if (!post['title'] && post['description']) {
			text = `${post.description.substring(0, 110)}....
      ${post.shareUrl}`;
		} else {
			text = post.shareUrl;
		}

		this.clipboardService.copy(text);
	}

	public displayTaskCreate(issue): void {
		issue['addTaskForIssue'] = true;
		this.editSelectedIssue = issue;
	}

	public onIssueEdit(issue): void {
		delete issue['addTaskForIssue'];
		this.editSelectedIssue = issue;
		this.postService.editSelectedPost = issue;
	}

	/**
	 * Function to show issue delete confirm modal
	 * @param issue
	 * @param i
	 */
	public showIssueDelete(issue, i): void {
		this.index = i;
		this.selectedIssue = issue;
		this.deleteModal = 'block';
	}

	/**
	 * Function for deleting an issue
	 * 1. Call api to delete an issue
	 * 2. Remove deleted issue from issues list
	 * 3. Close modal,reset selected issue and index position
	 */
	public onDeleteConfirm(): void {
		this.loadingGif = 'block';
		this.productService.deleteIssue(this.selectedIssue['uid']).subscribe(() => {
			// 1
			this.deleteModal = 'none'; // 3
			this.loadingGif = 'none';
			this.index = '';
			this.getProductDetails(this.product['uid']);
			this.getProductCounts(this.product['uid']);

			if (this.boardView === 'block') {
				if (this.selectedIssue['status'] === 'open') {
					this.getOpenLists('');
				} else if (this.selectedIssue['status'] === 'in_progress') {
					this.getInProgressLists('');
				} else if (this.selectedIssue['status'] === 'fixed') {
					this.getFixedLists('');
				} else if (this.selectedIssue['status'] === 'closed') {
					this.getClosedLists('');
				} else if (this.selectedIssue['status'] === 'pool') {
					this.getPoolLists('');
				} else {
					this.getReleasedLists('');
				}
			} else {
				this.issues.splice(this.index, 1); // 2
			}
			this.selectedIssue = '';
		});
	}

	/**
	 * Function to mute an issue
	 * @param issue
	 */
	public onMuteIssue(issue): void {
		this.loadingGif = 'block';
		this.productService.muteIssue(issue['uid']).subscribe((data) => {
			this.loadingGif = 'none';
			if (data.uid) {
				issue['myRelations']['muted'] = data.uid;
			}
		});
	}

	/**
	 * Function to unmute an issue
	 * @param issue
	 * @param muteId
	 */
	public onUnMuteIssue(issue, muteId): void {
		this.loadingGif = 'block';
		this.productService.unMuteIssue(muteId).subscribe(
			() => {
				issue['myRelations']['muted'] = '';
				this.loadingGif = 'none';
			},
			() => {
				issue['myRelations']['muted'] = '';
				this.loadingGif = 'none';
			}
		);
	}

	/**
	 * Function for showing report options in modal
	 * @param post
	 */
	public handlePostReport(post): void {
		this.isReported = false;
		if (post['myRelations'] && !post['myRelations']['flagged']) {
			this.reportModal = 'block';
			this.flag['value'] = '';
			this.flag['text'] = '';
			this.reportReasons = [];
			this.reportPost = post;
			this.profileService.getReasons().subscribe((data) => {
				if (data.post) {
					this.reportReasons = Object.entries(data.post);
				}
			});
		} else {
			this.reportText = 'You have reported already';
			this.isReported = true;
			setTimeout(() => {
				this.isReported = false;
			}, 7000);
		}
	}

	/**
	 * Function for handling report actions
	 */
	public onPostReport(): void {
		if (this.flag['value']) {
			this.loadingGif = 'block';
			const obj = {
				contentType: 'issue',
				contentUid: this.reportPost['uid'],
				reason: this.flag['value'],
				text: this.flag['text'],
			};

			this.networkService.reportAnAction(obj).subscribe(
				(data) => {
					this.issues.forEach((post) => {
						if (post.uid === this.reportPost.uid) {
							post.myRelations['flagged'] = data.uid;
						}
					});

					this.reportPost['myRelations']['flagged'] = data.uid;

					this.resetReports();
					this.reportText = 'Your feedback is reported successfully';
					this.isReported = true;
					this.loadingGif = 'none';
					setTimeout(() => {
						this.isReported = false;
					}, 7000);
				},
				() => {
					this.resetReports();
					this.loadingGif = 'none';
				}
			);
		}
	}

	/**
	 * Function for resetting reports modal
	 */
	public resetReports(): void {
		this.reportPost = '';
		this.flag['value'] = '';
		this.flag['text'] = '';
		this.reportReasons = [];
		this.reportModal = 'none';
	}

	/**
	 * Function for displaying reminder options
	 * @param post
	 */
	public displayReminders(post): void {
		this.issueForReminder = post;
		this.displayReminderModal = 'block';
		this.reminderOptions = [];
		this.networkService.getReminderOptions().subscribe((data) => {
			if (post['type'] !== 'events') {
				this.reminderOptions = data.reminderOptions;
			} else {
				this.reminderOptions = data.eventReminderOptions;
			}
			if (this.remindersSelected.length > 0) {
				this.setPreviousReminders();
			}
		});
	}

	/**
	 * On selecting reminder options
	 * @param reminder
	 */
	public onSelectReminder(reminder): void {
		const index = this.remindersSelected.findIndex((r) => r === reminder);
		if (index > -1) {
			this.remindersSelected.splice(index, 1);
		} else {
			this.remindersSelected.push(reminder);
		}
	}

	/**
	 * For resetting reminders in reminder options modal
	 */
	public resetReminders(): void {
		this.issueForReminder = '';
		this.displayReminderModal = 'none';
		this.timeFor = 0;
		this.remindersSelected = [];
		delete this.reminderModel['eventReminderOption'];
		this.reminderModel = {
			contentType: '',
			contentUid: '',
			repeat: true,
			reminderOption: '',
			username: '',
		};
		this.reminderOptions.forEach((r) => {
			r['checked'] = false;
		});
	}

	/**
	 * For creating a new reminders for content
	 */
	public setReminder(): void {
		if (this.remindersSelected.length > 0) {
			const object = {};
			this.remindersSelected.forEach((reminder) => {
				object[reminder] = this.setReminderDate(reminder);
			});

			const param = JSON.stringify(object);
			this.reminderModel.contentUid = this.issueForReminder.uid;
			this.reminderModel.username = this.currentUser.username;
			this.reminderModel.repeat = true;
			this.reminderModel.contentType = 'issue';
			this.reminderModel.reminderOption = param;

			this.loadingGif = 'block';
			this.networkService.createReminder(this.reminderModel).subscribe((data) => {
				this.issueForReminder['myRelations']['reminder'] = data['uid'];
				this.loadingGif = 'none';
				this.resetReminders();
				this.reminderToast = true;
				setTimeout(() => {
					this.reminderToast = false;
				}, 8000);
			});
		} else {
			if (this.issueForReminder['myRelations'] && this.issueForReminder['myRelations']['reminder']) {
				this.deleteReminders();
			} else {
				this.resetReminders();
			}
		}
	}

	/**
	 * Function for deleting previous reminders on edit
	 */
	public deleteReminders(): void {
		this.reminderModel.contentUid = this.issueForReminder.uid;
		this.reminderModel.username = this.currentUser.username;
		this.reminderModel.repeat = true;
		this.reminderModel.contentType = 'issue';

		this.loadingGif = 'block';
		this.networkService.createReminder(this.reminderModel).subscribe(() => {
			this.issueForReminder['myRelations']['reminder'] = '';
			this.loadingGif = 'none';
			this.resetReminders();
		});
	}

	/**
	 * For calculating corresponding to reminder selected
	 * @param option
	 */
	public setReminderDate(option): string {
		if (option === 'in_twenty_minutes') {
			this.timeFor = 20;
		} else if (option === 'in_one_hour') {
			this.timeFor = 60;
		} else if (option === 'in_three_hours') {
			this.timeFor = 180;
		} else if (option === 'tomorrow') {
			this.timeFor = this.minutesUntilMidnight() + 540;
		} else if (option === 'next_week') {
			this.timeFor = 6300;
		}

		const date = new Date();
		date.setMinutes(date.getMinutes() + this.timeFor);
		const month = date.getUTCMonth() + 1;
		const day = date.getUTCDate();
		const year = date.getUTCFullYear();
		const hours = date.getUTCHours();
		const minutes = date.getUTCMinutes();
		const seconds = date.getUTCSeconds();
		const utcDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
		return utcDate;
	}

	/**
	 * Get previous reminders of content
	 */
	public getReminderDetails(issue): void {
		this.loadingGif = 'block';
		this.networkService.getContentReminders('issues', issue['uid']).subscribe((data) => {
			this.loadingGif = 'none';
			this.displayReminders(issue);
			if (data.objects && data.objects.length > 0) {
				data.objects.forEach((element) => {
					if (element['reminderOption']) {
						this.remindersSelected.push(element['reminderOption']);
					} else if (element['eventReminderOption']) {
						this.remindersSelected.push(element['eventReminderOption']);
					}
				});
			}
			this.setPreviousReminders();
		});
	}

	/**
	 * Set Previous reminders on reminder options
	 */
	private setPreviousReminders(): void {
		this.reminderOptions.forEach((reminder) => {
			this.remindersSelected.forEach((r) => {
				if (r === reminder[0]) {
					reminder['checked'] = true;
				}
			});
		});
	}

	private minutesUntilMidnight(): any {
		const midnight = new Date();
		midnight.setHours(24);
		midnight.setMinutes(0);
		midnight.setSeconds(0);
		midnight.setMilliseconds(0);
		return (midnight.getTime() - new Date().getTime()) / 1000 / 60;
	}

	/**
	 * Function for displaying estimate time modal for moveToInprogress action
	 * @param issue
	 * @param i
	 */
	public showInProgressModal(issue, i): void {
		this.selectedIssue = issue;
		this.index = i;
		this.error = false;
		this.estimatedTime = '';
		this.displayInprogressModal = 'block';

		setTimeout(() => {
			document.getElementById('estimatedTime').focus();
		}, 50);
	}

	/**
	 * Function for closing and resetting issue action variables
	 */
	public resetModals(): void {
		this.displayInprogressModal = 'none';
		this.displayVersionModal = 'none';
		this.selectedIssue = '';
		this.index = '';
		this.error = false;
		if (this.boardView === 'block') {
			this.getOpenLists('');
			this.getInProgressLists('');
			this.getFixedLists('');
			this.getReleasedLists('');
			this.getClosedLists('');
			this.getPoolLists('');
		}
	}

	private close(): void {
		this.displayInprogressModal = 'none';
		this.displayVersionModal = 'none';
		this.selectedIssue = '';
		this.index = '';
		this.error = false;
	}

	/**
	 * Function on confirming move to inprogress action
	 */
	public onConfirmInProgress(): void {
		if (this.estimatedTime) {
			this.inProgressCount = this.inProgressCount + 1;
			this.handleIssueStateChange(this.selectedIssue, 'in_progress', this.index);
		} else {
			this.error = true;
		}
	}

	/**
	 * Function on confirming version number for move to released action
	 */
	public onConfirmVersion(): void {
		if (this.version) {
			this.releasedCount = this.releasedCount + 1;
			this.handleIssueStateChange(this.selectedIssue, 'released', this.index);
		} else {
			this.error = true;
		}
	}

	/**
	 * Function for displaying version no: modal for move to released action
	 * @param issue
	 * @param i
	 */
	public showReleasedModal(issue, i): void {
		this.selectedIssue = issue;
		this.index = i;
		this.error = false;
		this.version = '';
		this.displayVersionModal = 'block';
	}

	/**
	 * Function for handling issue state changes on move actions
	 * @param issue
	 * @param state
	 * @param index
	 * 1.For refreshing lists on moving
	 * 2.For settting updated post to previous index pos in list
	 */
	public handleIssueStateChange(issue, state, index): void {
		this.updateAttachmentDetails(issue);
		this.loadingGif = 'block';
		this.model.title = issue.title;
		this.model.state = issue.state;
		this.model.type = 'issue';
		this.model.status = state;
		this.model.shareable = issue.shareable;
		this.model.commentable = issue.commentable;
		this.model['collection_post'] = '1';
		this.model['estimate'] = this.estimatedTime;
		this.model['assignedUser'] = this.currentUser.username;
		this.model.category = 'bugs';
		this.comment.contentType = 'issue';
		this.comment.contentUid = issue.uid;

		if (issue.priority) {
			this.model['priority'] = issue.priority;
		}
		if (issue.description) {
			this.model['description'] = issue.description;
		}
		if (issue.type) {
			this.model['type'] = issue.type;
		}
		if (this.boardView === 'none') {
			this.issues.splice(index, 1);
		}

		this.postService.updateIssue(this.model, issue.uid).subscribe(
			(data) => {
				this.reset();
				this.getProductDetails(this.product['uid']);
				this.getProductCounts(this.product['uid']);

				if (this.boardView === 'block' && index !== -1) {
					// 1
					this.refresh(issue, data);
				} else {
					this.setToPreviousIndex(issue, data); // 2
				}

				if (state === 'open') {
					this.comment['text'] = this.translate.instant('moveToOpenText', {
						opens: data.numberOfOpens,
					});
				} else if (state === 'pool') {
					this.comment['text'] = this.translate.instant('Move to Pool Notes: Issue is on hold');
				} else if (state === 'fixed') {
					this.comment['text'] = this.translate.instant('moveToFixedText', {
						developmentTime: data.developmentTime,
					});
				} else if (state === 'closed') {
					this.comment['text'] = this.translate.instant('moveToClosedText', {
						time: data.elapsedTime,
					});
				} else if (state === 'released') {
					this.comment['text'] = this.translate.instant('moveToReleasedText', {
						version: this.version,
					});
				} else {
					this.comment['text'] = this.translate.instant('moveToInProgressText', {
						user: this.currentUser.name,
						estimate: this.estimatedTime,
					});
				}

				this.commentsService.create(this.comment, this.currentUser.username).subscribe(
					() => {
						this.loadingGif = 'none';
					},
					() => {
						this.loadingGif = 'none';
					}
				);
			},
			() => {
				this.loadingGif = 'none';
			}
		);
	}

	private refresh(issue, data): void {
		if (issue['status'] === 'open') {
			this.getOpenLists('');
		} else if (issue['status'] === 'in_progress') {
			this.getInProgressLists('');
		} else if (issue['status'] === 'fixed') {
			this.getFixedLists('');
		} else if (issue['status'] === 'closed') {
			this.getClosedLists('');
		} else if (issue['status'] === 'released') {
			this.getReleasedLists('');
		} else {
			this.getPoolLists('');
		}

		if (data['status'] === 'open') {
			this.getOpenLists('');
		} else if (data['status'] === 'in_progress') {
			this.getInProgressLists('');
		} else if (data['status'] === 'fixed') {
			this.getFixedLists('');
		} else if (data['status'] === 'closed') {
			this.getClosedLists('');
		} else if (data['status'] === 'released') {
			this.getReleasedLists('');
		} else {
			this.getPoolLists('');
		}
	}

	private setToPreviousIndex(issue, data): void {
		let index;
		if (data['status'] === 'open') {
			index = this.openPosts.findIndex((post) => post['uid'] === data['uid']);
			if (index > -1) {
				this.openPosts[index] = data;
				this.handleMentions(this.openPosts);
				this.reorderLists(this.openPosts);
			}
		} else if (data['status'] === 'in_progress') {
			index = this.inProgressPosts.findIndex((post) => post['uid'] === data['uid']);
			if (index > -1) {
				this.inProgressPosts[index] = data;
				this.handleMentions(this.inProgressPosts);
				this.reorderLists(this.inProgressPosts);
			}
		} else if (data['status'] === 'fixed') {
			index = this.fixedPosts.findIndex((post) => post['uid'] === data['uid']);
			if (index > -1) {
				this.fixedPosts[index] = data;
				this.handleMentions(this.fixedPosts);
				this.reorderLists(this.fixedPosts);
			}
		} else if (data['status'] === 'closed') {
			index = this.closedPosts.findIndex((post) => post['uid'] === data['uid']);
			if (index > -1) {
				this.closedPosts[index] = data;
				this.handleMentions(this.closedPosts);
				this.reorderLists(this.closedPosts);
			}
		} else if (data['status'] === 'released') {
			index = this.releasedPosts.findIndex((post) => post['uid'] === data['uid']);
			if (index > -1) {
				this.releasedPosts[index] = data;
				this.handleMentions(this.releasedPosts);
				this.reorderLists(this.releasedPosts);
			}
		} else {
			index = this.poolPosts.findIndex((post) => post['uid'] === data['uid']);
			if (index > -1) {
				this.poolPosts[index] = data;
				this.handleMentions(this.poolPosts);
				this.reorderLists(this.poolPosts);
			}
		}
	}

	/**
	 * Pass attachment details on a post on issue actions
	 * @param issue
	 */
	private updateAttachmentDetails(issue): void {
		if (issue.attachmentDetails.gallery && issue.attachmentDetails.gallery.length > 0) {
			const images: any[] = [];
			this.model['images'] = [];
			issue.attachmentDetails.gallery.forEach((img) => {
				this.model.attachmentType = 'photo';
				images.push(img.image.uid);
			});
			this.model['images'] = images.join('|');
		}
		if (issue.attachmentDetails.location && issue.attachmentDetails.location.length > 0) {
			this.model.attachmentType = 'location';
			this.model['location'] = issue.attachmentDetails.location[0].uid;
		}
		if (issue.attachmentDetails.doc && issue.attachmentDetails.doc[0].uid) {
			this.model.attachmentType = 'doc';
			this.model['doc'] = issue.attachmentDetails.doc[0].uid;
		}
		if (issue.attachmentDetails.url && issue.attachmentDetails.url[0].url) {
			this.model.attachmentType = 'url';
			this.model['url'] = issue.attachmentDetails.url[0].url;
		}
	}

	/**
	 * Function for resetting attachments in issue model
	 */
	public reset(): void {
		this.close();
		delete this.model['images'];
		delete this.model['attachmentType'];
		delete this.model['doc'];
		delete this.model['url'];
		delete this.model['location'];
		delete this.model['description'];
	}

	/**
	 * Function on deleting a product
	 */
	public confirmProductDelete(): void {
		this.loadingGif = 'block';
		this.productService.deleteProduct(this.product['uid']).subscribe(() => {
			this.displayProductDelete = 'none';
			this.loadingGif = 'none';
			this.location.back();
		});
	}

	/**
	 * Function for archive/un-archiving a product
	 * @param state
	 */
	public handleArchive(state): void {
		const archived = {};
		archived['name'] = this.product['name'];

		if (state === 'archive') {
			archived['archived'] = 'true';
		} else {
			archived['archived'] = 'false';
		}

		if (this.product['contentPrivacy']) {
			archived['content_privacy'] = JSON.stringify(this.product['contentPrivacy']);
		} else if (this.product['customPrivacy'] && this.product['customPrivacy'].length > 0) {
			const customPrivacy: any[] = [];
			// let custom: any;
			this.product.customPrivacy.forEach((element) => {
				if (element.userId) {
					customPrivacy.push(element.userId);
				} else {
					customPrivacy.push(element.id);
				}
			});
			const custom = '[' + customPrivacy.join(',') + ']';
			archived['custom_privacy'] = custom;
		} else if (this.product['channel'] && this.product['channel']['uid']) {
			archived['channel'] = this.product['channel']['uid'];
		}

		this.loadingGif = 'block';
		this.networkService.updateProduct(archived, this.network.uid, this.product.uid).subscribe(() => {
			this.loadingGif = 'none';
			this.location.back();
		});
	}

	public navigateIssueDetail(issue): void {
		const url = `/network/issue-detail?id=${issue.uid}`;
		this.router.navigateByUrl(url);
	}

	public displayEdit(): void {
		this.displayProductEdit = 'block';
		this.param['product'] = this.product['name'];
		this.handleProductPrivacy(this.product);
	}

	public closeEdit(): void {
		this.error = false;
		this.displayProductEdit = 'none';
	}

	public handleProductPrivacy(data): void {
		if (data) {
			if (data['contentPrivacy']) {
				this.privacy['content_privacy'] = data['contentPrivacy'];
				if (data['contentPrivacy'].department) {
					const index = this.departments.findIndex((department) => department.uid === data['contentPrivacy'].department);
					if (index > -1) {
						this.privacy['selectedDepartment'] = this.departments[index];
					}
				} else {
					this.privacy['selectedDepartment'] = null;
				}
				if (data['contentPrivacy'].role) {
					const index = this.roles.findIndex((role) => role.name === data['contentPrivacy'].role);
					this.privacy['role'] = this.roles[index];
				} else {
					this.privacy['role'] = null;
				}

				if (!data['contentPrivacy'].role && !data['contentPrivacy'].department) {
					const field = Object.keys(data['contentPrivacy']);
					this.personalFilters.forEach((element) => {
						if (element.key === field[0] && field[0] !== 'manager') {
							this.privacy['personalFilter'] = element;
						}
					});
					if (data['contentPrivacy'].status) {
						this.privacy['selectByStatusValue'] = data['contentPrivacy'];
					} else if (data['contentPrivacy'].renewalStatus) {
						this.privacy['selectByRenewalValue'] = data['contentPrivacy'];
					} else if (data['contentPrivacy'].membership_level) {
						this.privacy['selectByLevelValue'] = data['contentPrivacy'];
					} else if (data['contentPrivacy'].manager) {
						if (this.privacy.myTeam && this.privacy.myTeam.key) {
							this.privacy['personalFilter'] = this.privacy.myTeam;
						}
					}
				}
			} else {
				this.privacy['content_privacy'] = null;
				this.privacy['selectedDepartment'] = null;
				this.privacy['role'] = null;
			}
			if (data['customPrivacy']) {
				this.privacy['custom_privacy'] = data['customPrivacy'];
			} else {
				this.privacy['custom_privacy'] = [];
			}
			if (data['channel']) {
				this.privacy.channel = data['channel'];
			}
		}
	}

	private updatePrivacyValues(): void {
		if (this.privacy.custom_privacy && this.privacy.custom_privacy.length > 0) {
			delete this.param['content_privacy'];
			delete this.param['channel'];
			this.param['custom_privacy'] = [];
			const names = [];
			const newObject = this.privacy.custom_privacy;
			newObject.forEach((member) => {
				member.privacyType = 'customPrivacy';
			});
			localStorage.setItem(`${this.network['uid']}`, JSON.stringify(newObject));
			this.privacy.custom_privacy.forEach((user) => {
				if (user['user']) {
					this.param['custom_privacy'].push(user['user'].userId);
					names.push(user['user'].name);
				} else if (user.userId) {
					this.param['custom_privacy'].push(user.userId);
					names.push(user.username);
				} else {
					this.param['custom_privacy'].push(user.id);
					names.push(user.username);
				}
			});
		} else {
			let roleString = null;
			let departmentString = null;
			let personalFilterString = null;
			let selectByStatusString = null;
			let selectByRenewalString = null;
			let selectByLevelString = null;
			delete this.param['custom_privacy'];
			delete this.param['channel'];

			if (this.privacy.selectedRole && this.privacy.selectedDepartment) {
				roleString = '"role": "' + this.privacy.selectedRole.slug + '"';
				departmentString = '"department": "' + this.privacy.selectedDepartment['value'] + '"';
			} else if (this.privacy.selectedRole) {
				roleString = '"role": "' + this.privacy.selectedRole.slug + '"';
				roleString = '"role": "' + this.privacy.selectedRole.slug + '"';
				const newObject = this.privacy.selectedRole;
				newObject.privacyType = 'role';
				localStorage.setItem(`${this.network['uid']}`, JSON.stringify(newObject));
			} else if (this.privacy.selectedDepartment) {
				if (this.privacy.selectedDepartment['uid']) {
					departmentString = '"department":"' + this.privacy.selectedDepartment['uid'] + '"';
				}
				if (this.privacy.selectedDepartment['value']) {
					departmentString = '"department":"' + this.privacy.selectedDepartment['value'] + '"';
				}
				const newObject = this.privacy.selectedDepartment;
				newObject['privacyType'] = 'department';
				localStorage.setItem(`${this.network['uid']}`, JSON.stringify(newObject));
			} else if (this.privacy.personalFilter) {
				// let personalFilterName = '';
				// personalFilterName = this.privacy.personalFilter['valueLabel'];
				personalFilterString = '"' + this.privacy.personalFilter['key'] + '": "' + this.privacy.personalFilter['value'] + '"';
				const newObject = this.privacy.personalFilter;
				newObject['privacyType'] = 'personalFilter';
				localStorage.setItem(`${this.privacy.networkId}`, JSON.stringify(newObject));
			} else if (this.privacy.selectByStatusValue) {
				if (this.privacy.selectByStatusValue.status) {
					selectByStatusString = '"status": "' + this.privacy.selectByStatusValue.status + '"';
				} else {
					selectByStatusString = '"status": "' + this.privacy.selectByStatusValue[0] + '"';
					const newObject = this.privacy.selectByStatusValue;
					if (newObject.includes('status') === false) {
						newObject.push('status');
					}
					localStorage.setItem(`${this.network['uid']}`, JSON.stringify(newObject));
				}
			} else if (this.privacy.selectByRenewalValue) {
				if (this.privacy.selectByRenewalValue.renewalStatus) {
					selectByRenewalString = '"renewalStatus": "' + this.privacy.selectByRenewalValue.renewalStatus + '"';
				} else {
					selectByRenewalString = '"renewalStatus": "' + this.privacy.selectByRenewalValue[0] + '"';
					const newObject = this.privacy.selectByRenewalValue;
					if (newObject.includes('renewalFrequency') === false) {
						newObject.push('renewalFrequency');
					}
					localStorage.setItem(`${this.network['uid']}`, JSON.stringify(newObject));
				}
			} else if (this.privacy.selectByLevelValue) {
				if (this.privacy.selectByLevelValue.uid) {
					selectByLevelString = '"membership_level": "' + this.privacy.selectByLevelValue.uid + '"';
					const newObject = this.privacy.selectByLevelValue;
					newObject.privacyType = 'selectByLevel';
					localStorage.setItem(`${this.network['uid']}`, JSON.stringify(newObject));
				} else {
					selectByLevelString = '"membership_level": "' + this.privacy.selectByLevelValue.membership_level + '"';
				}
			} else if (this.privacy.channel) {
				this.param['channel'] = this.privacy.channel['uid'];
				const newObject = this.privacy.channel;
				newObject['privacyType'] = 'subCircle';
				localStorage.setItem(`${this.network['uid']}`, JSON.stringify(newObject));
			}
			if (
				this.privacy.custom_privacy.length === 0 &&
				!this.privacy.selectedRole &&
				!this.privacy.role &&
				!this.privacy.personalFilter &&
				!this.privacy.selectedDepartment &&
				!this.privacy.channel &&
				!this.privacy.selectByStatusValue &&
				!this.privacy.selectByRenewalValue &&
				!this.privacy.selectByLevelValue
			) {
				localStorage.setItem(`${this.network['uid']}`, JSON.stringify('Everyone'));
			}
			if (roleString && departmentString) {
				this.param['content_privacy'] = '{' + roleString + ', ' + departmentString + '}';
			} else if (roleString && !departmentString) {
				this.param['content_privacy'] = '{' + roleString + '}';
			} else if (!roleString && departmentString) {
				this.param['content_privacy'] = '{' + departmentString + '}';
			} else if (personalFilterString) {
				this.param['content_privacy'] = '{' + personalFilterString + '}';
			} else if (selectByStatusString) {
				this.param['content_privacy'] = '{' + selectByStatusString + '}';
			} else if (selectByRenewalString) {
				this.param['content_privacy'] = '{' + selectByRenewalString + '}';
			} else if (selectByLevelString) {
				this.param['content_privacy'] = '{' + selectByLevelString + '}';
			} else {
				delete this.param['content_privacy'];
			}
		}
		if (this.param['custom_privacy'] && this.param['custom_privacy'].length > 0) {
			this.param['custom_privacy'] = '[' + this.param['custom_privacy'].join(',') + ']';
		}
		this.updatePrivacyStringBasedOnPrivacyComponent();
	}

	public updatePrivacyStringBasedOnPrivacyComponent(): void {
		this.forNames = '';
		if (this.privacy.isPrivate) {
			this.forNames = this.currentUser.name;
		} else if (this.privacy.custom_privacy && this.privacy.custom_privacy.length > 0) {
			const names = [];
			this.privacy.custom_privacy.forEach((user) => {
				if (user['user']) {
					names.push(user['user'].name);
				} else if (user['name']) {
					names.push(user['name']);
				} else {
					names.push(user.username);
				}
			});
			this.forNames = names.join(',');
		} else {
			if (this.privacy.selectedRole && this.privacy.selectedDepartment) {
				this.forNames = this.privacy.selectedRole.name + ', ' + this.privacy.selectedDepartment.valueLabel;
			} else if (this.privacy.selectedRole) {
				let roleName;
				if (this.privacy.selectedRole.name) {
					roleName = this.privacy.selectedRole.name;
				} else {
					roleName = this.privacy.selectedRole;
				}
				this.forNames = roleName;
			} else if (this.privacy.selectedDepartment) {
				let departmentName;
				if (this.privacy.selectedDepartment.valueLabel) {
					departmentName = this.privacy.selectedDepartment.valueLabel;
					this.forNames = departmentName;
				}
				if (this.privacy.selectedDepartment.name && this.privacy.selectedDepartment.uid) {
					departmentName = this.privacy.selectedDepartment.name;
					this.forNames = departmentName;
				}
			} else if (this.privacy.personalFilter) {
				let personalFilter = '';
				personalFilter = this.privacy.personalFilter.valueLabel;
				this.forNames = personalFilter;
			} else if (this.privacy.channel) {
				this.forNames = this.privacy.channel.name;
			} else if (this.privacy.selectByRenewalValue) {
				this.forNames = this.privacy.selectByRenewalValue[1];
			} else if (this.privacy.selectByLevelValue) {
				this.forNames = this.privacy.selectByLevelValue.name;
			} else if (this.privacy.selectByStatusValue) {
				this.forNames = this.privacy.selectByStatusValue[1];
			} else {
				this.forNames = 'Everyone';
			}
		}
	}

	public onEditProduct(): void {
		if (!this.param['product']) {
			this.error = true;
		} else {
			this.error = false;
			this.updatePrivacyValues();

			this.loadingGif = 'block';
			this.networkService.updateProduct(this.param, this.network.uid, this.product.uid).subscribe((data) => {
				this.error = false;
				this.product = data;
				this.updatePrivacyString(data);
				this.displayProductEdit = 'none';
				this.loadingGif = 'none';
			});
		}
	}

	private getIssuesInProducts(filter): void {
		this.isFetching = 0;
		this.issues = [];
		this.productService.getIssues(filter).subscribe(
			(data) => {
				this.issues = data.objects;
				this.isFetching = 1;
				this.checkUserMentionAndHashtag();

				if (data.next) {
					this.nextUrl = data.next.split('alpha')[1];
				} else {
					this.nextUrl = '';
				}
			},
			() => {
				this.issues = [];
				this.isFetching = 1;
			}
		);
	}

	public activateButton(): void {
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
		this.layoutService.hideBd();
		this.showProductForm();
	}

	/**
	 * Function to show the product form
	 */
	private showProductForm(): void {
		this.showPostCreateForm = !this.showPostCreateForm;
		if (this.showPostCreateForm) {
			this.layoutService.showBd('event-post-form');
			this.postType = 'add-issue';
		}
	}

	public onPrivacyClick(): void {
		this.privacy.showPrivacy();
		this.privacy.privacy_settings = 'block';
	}

	public onClickBack(): void {
		this.location.back();
	}

	private findWithAttr(array, attr, value): number {
		for (let i = 0; i < array.length; i += 1) {
			if (array[i][attr] === value) {
				return i;
			}
		}
		return -1;
	}

	public filterIssues(type): void {
		this.filter = type;
		this.isFetching = 0;
		this.issues = [];
		this.productService.filterByType(this.uid, this.tab, this.isArchived, type, '').subscribe(
			(data) => {
				this.issues = data.objects;
				this.isFetching = 1;
				this.checkUserMentionAndHashtag();

				if (data.next) {
					this.nextUrl = data.next.split('alpha')[1];
				} else {
					this.nextUrl = '';
				}
			},
			() => {
				this.issues = [];
				this.isFetching = 1;
			}
		);
	}

	public onImgError(event): void {
		event.target.src = 'assets/images/default_avatar.jpg';
	}

	public displayStatusView(): void {
		this.boardView = 'block';
		this.scrollStatus = false;
		this.boardPost = '';
		this.selectedIssue = '';
		this.index = '';
		this.getOpenLists('');
		this.getInProgressLists('');
		this.getFixedLists('');
		this.getClosedLists('');
		this.getReleasedLists('');
		this.getPoolLists('');
	}

	public closeBoard(): void {
		this.boardView = 'none';
		this.selectedIssue = '';
		this.index = '';
		this.scrollStatus = false;
		this.getIssues('open');
	}

	private getOpenLists(query): void {
		this.openFetching = 0;
		this.openPosts = [];
		this.productService.filterByType(this.uid, 'open', this.isArchived, '', query).subscribe(
			(data) => {
				this.openCount = data.count;
				this.openPosts = data.objects;
				this.openFetching = 1;
				this.handleMentions(this.openPosts);
				if (data.next) {
					this.openNextUrl = data.next.split('alpha')[1];
				} else {
					this.openNextUrl = '';
				}
			},
			() => {
				this.issues = [];
				this.openFetching = 1;
			}
		);
	}

	public paginateOpen(): void {
		if (this.openNextUrl) {
			if (this.scrollStatus) {
				return;
			}
			this.scrollStatus = true;
			this.openFetching = 0;
			this.networkService.paginateToDoLists(this.openNextUrl).subscribe((data) => {
				if (data.next) {
					this.openNextUrl = data.next.split('alpha')[1];
				} else {
					this.openNextUrl = '';
				}
				this.openPosts = this.openPosts.concat(data.objects);
				this.openFetching = 1;
				this.scrollStatus = false;
				this.handleMentions(this.openPosts);
			});
		}
	}

	private getInProgressLists(query): void {
		this.inProgressFetching = 0;
		this.inProgressPosts = [];
		this.productService.filterByType(this.uid, 'in_progress', this.isArchived, '', query).subscribe(
			(data) => {
				this.inProgressCount = data.count;
				this.inProgressPosts = data.objects;
				this.inProgressFetching = 1;
				this.handleMentions(this.inProgressPosts);
				if (data.next) {
					this.inProgressNextUrl = data.next.split('alpha')[1];
				} else {
					this.inProgressNextUrl = '';
				}
			},
			() => {
				this.issues = [];
				this.openFetching = 1;
			}
		);
	}

	public paginateInProgress(): void {
		if (this.inProgressNextUrl) {
			if (this.scrollStatus) {
				return;
			}
			this.scrollStatus = true;
			this.inProgressFetching = 0;
			this.networkService.paginateToDoLists(this.inProgressNextUrl).subscribe((data) => {
				if (data.next) {
					this.inProgressNextUrl = data.next.split('alpha')[1];
				} else {
					this.inProgressNextUrl = '';
				}
				this.inProgressPosts = this.inProgressPosts.concat(data.objects);
				this.inProgressFetching = 1;
				this.scrollStatus = false;
				this.handleMentions(this.inProgressPosts);
			});
		}
	}

	private getClosedLists(query): void {
		this.closedFetching = 0;
		this.closedPosts = [];
		this.productService.filterByType(this.uid, 'closed', this.isArchived, '', query).subscribe(
			(data) => {
				this.closedCount = data.count;
				this.closedPosts = data.objects;
				this.closedFetching = 1;
				this.handleMentions(this.closedPosts);
				if (data.next) {
					this.closedNextUrl = data.next.split('alpha')[1];
				} else {
					this.closedNextUrl = '';
				}
			},
			() => {
				this.issues = [];
				this.openFetching = 1;
			}
		);
	}

	public paginateClosed(): void {
		if (this.closedNextUrl) {
			if (this.scrollStatus) {
				return;
			}
			this.scrollStatus = true;
			this.closedNextUrl = 0;
			this.networkService.paginateToDoLists(this.closedNextUrl).subscribe((data) => {
				if (data.next) {
					this.closedNextUrl = data.next.split('alpha')[1];
				} else {
					this.closedNextUrl = '';
				}
				this.closedPosts = this.closedPosts.concat(data.objects);
				this.closedFetching = 1;
				this.scrollStatus = false;
				this.handleMentions(this.closedPosts);
			});
		}
	}

	private getFixedLists(query): void {
		this.fixedFetching = 0;
		this.fixedPosts = [];
		this.productService.filterByType(this.uid, 'fixed', this.isArchived, '', query).subscribe(
			(data) => {
				this.fixedCount = data.count;
				this.fixedPosts = data.objects;
				this.fixedFetching = 1;
				this.handleMentions(this.fixedPosts);
				if (data.next) {
					this.fixedNextUrl = data.next.split('alpha')[1];
				} else {
					this.fixedNextUrl = '';
				}
			},
			() => {
				this.issues = [];
				this.openFetching = 1;
			}
		);
	}

	public paginateFixed(): void {
		if (this.fixedNextUrl) {
			if (this.scrollStatus) {
				return;
			}
			this.scrollStatus = true;
			this.fixedNextUrl = 0;
			this.networkService.paginateToDoLists(this.fixedNextUrl).subscribe((data) => {
				if (data.next) {
					this.fixedNextUrl = data.next.split('alpha')[1];
				} else {
					this.fixedNextUrl = '';
				}
				this.fixedPosts = this.fixedPosts.concat(data.objects);
				this.fixedFetching = 1;
				this.scrollStatus = false;
				this.handleMentions(this.fixedPosts);
			});
		}
	}

	private getReleasedLists(query): void {
		this.releasedFetching = 0;
		this.releasedPosts = [];
		this.productService.filterByType(this.uid, 'released', this.isArchived, '', query).subscribe(
			(data) => {
				this.releasedCount = data.count;
				this.releasedPosts = data.objects;
				this.releasedFetching = 1;
				this.handleMentions(this.releasedPosts);
				if (data.next) {
					this.releasedNextUrl = data.next.split('alpha')[1];
				} else {
					this.releasedNextUrl = '';
				}
			},
			() => {
				this.issues = [];
				this.openFetching = 1;
			}
		);
	}

	public paginateReleased(): void {
		if (this.releasedNextUrl) {
			if (this.scrollStatus) {
				return;
			}
			this.scrollStatus = true;
			this.releasedNextUrl = 0;
			this.networkService.paginateToDoLists(this.releasedNextUrl).subscribe((data) => {
				if (data.next) {
					this.releasedNextUrl = data.next.split('alpha')[1];
				} else {
					this.releasedNextUrl = '';
				}
				this.releasedPosts = this.releasedPosts.concat(data.objects);
				this.releasedFetching = 1;
				this.scrollStatus = false;
				this.handleMentions(this.releasedPosts);
			});
		}
	}

	private getPoolLists(query): void {
		this.poolFetching = 0;
		this.poolPosts = [];
		this.productService.filterByType(this.uid, 'pool', this.isArchived, '', query).subscribe(
			(data) => {
				this.poolCount = data.count;
				this.poolPosts = data.objects;
				this.poolFetching = 1;
				this.handleMentions(this.poolPosts);
				if (data.next) {
					this.poolNextUrl = data.next.split('alpha')[1];
				} else {
					this.poolNextUrl = '';
				}
			},
			() => {
				this.issues = [];
				this.openFetching = 1;
			}
		);
	}

	public paginatePool(): void {
		if (this.poolNextUrl) {
			if (this.scrollStatus) {
				return;
			}
			this.scrollStatus = true;
			this.poolNextUrl = 0;
			this.networkService.paginateToDoLists(this.poolNextUrl).subscribe((data) => {
				if (data.next) {
					this.poolNextUrl = data.next.split('alpha')[1];
				} else {
					this.poolNextUrl = '';
				}
				this.poolPosts = this.poolPosts.concat(data.objects);
				this.poolFetching = 1;
				this.scrollStatus = false;
				this.handleMentions(this.poolPosts);
			});
		}
	}

	public onDrop(event, state, posts): void {
		if (this.checkIfPost(event.item.data, posts)) {
			if (event.previousIndex !== event.currentIndex) {
				this.loadingGif = 'block';
				moveItemInArray(posts, event.previousIndex, event.currentIndex);
				this.reorderLists(posts);
			}
		} else {
			posts.splice(event.currentIndex, 0, event.item.data);
			if (event.item.data['status'] === 'open') {
				this.openCount = this.openCount - 1;
				this.openPosts.splice(event.previousIndex, 1);
			} else if (event.item.data['status'] === 'in_progress') {
				this.inProgressCount = this.inProgressCount - 1;
				this.inProgressPosts.splice(event.previousIndex, 1);
			} else if (event.item.data['status'] === 'fixed') {
				this.fixedCount = this.fixedCount - 1;
				this.fixedPosts.splice(event.previousIndex, 1);
			} else if (event.item.data['status'] === 'closed') {
				this.closedCount = this.closedCount - 1;
				this.closedPosts.splice(event.previousIndex, 1);
			} else if (event.item.data['status'] === 'released') {
				this.releasedCount = this.releasedCount - 1;
				this.releasedPosts.splice(event.previousIndex, 1);
			} else {
				this.poolCount = this.poolCount - 1;
				this.poolPosts.splice(event.previousIndex, 1);
			}

			if (state === 'open') {
				this.openCount = this.openCount + 1;
				this.handleIssueStateChange(event.item.data, state, -1);
			} else if (state === 'in_progress') {
				this.showInProgressModal(event.item.data, -1);
			} else if (state === 'fixed') {
				this.fixedCount = this.fixedCount + 1;
				this.handleIssueStateChange(event.item.data, state, -1);
			} else if (state === 'closed') {
				this.closedCount = this.closedCount + 1;
				this.handleIssueStateChange(event.item.data, state, -1);
			} else if (state === 'released') {
				this.showReleasedModal(event.item.data, -1);
			} else {
				this.poolCount = this.poolCount + 1;
				this.handleIssueStateChange(event.item.data, state, -1);
			}
		}
	}

	public onSearchEnter(event: any): void {
		if (event.keyCode === 13) {
			this.onSearchBoard();
		}
	}

	public onSearchBoard(): void {
		if (this.query) {
			this.getOpenLists(this.query);
			this.getInProgressLists(this.query);
			this.getFixedLists(this.query);
			this.getClosedLists(this.query);
			this.getReleasedLists(this.query);
			this.getPoolLists(this.query);
		} else {
			this.getOpenLists('');
			this.getInProgressLists('');
			this.getFixedLists('');
			this.getClosedLists('');
			this.getReleasedLists('');
			this.getPoolLists('');
		}
	}

	private reorderLists(posts): void {
		const arr = [];
		const params = {};
		posts.forEach((post, index) => {
			if (post['uid']) {
				const object = { uid: post['uid'], order: index + 1 };
				arr.push(object);
			}
		});
		params['contents'] = JSON.stringify(arr);
		this.productService.reorderIssues(this.product['uid'], params).subscribe(
			() => {
				this.loadingGif = 'none';
			},
			() => {
				this.loadingGif = 'none';
			}
		);
	}

	public display(): void {
		this.displayPriority = 'block';
	}

	public setPriority(): void {
		this.displayPriority = 'none';
		this.boardPost['priority'] = this.currentPriority;
	}

	public autoSizeTask(e, post): void {
		if (post['title'] && e.keyCode === 13 && this.userMentions.length === 0 && this.hashMentions.length === 0) {
			e.preventDefault();
			this.onCreateIssueInBoard(post);
		}
		e.target.style.cssText = 'padding: 5px; background: none; resize: none;height:auto;border:none;font-size:14px';
		e.target.style.cssText = 'padding: 5px; background: none; resize: none;border:none;font-size:14px;height:' + e.target.scrollHeight + 'px';
	}

	public onAddIssue(): void {
		if (!this.boardPost) {
			const x = new Issue();
			this.boardPost = x;
			this.boardPost['products'] = this.product['slug'];
			setTimeout(() => {
				this.text.nativeElement.focus();
			}, 0);
		} else {
			this.boardPost = '';
		}
	}

	public onCreateIssueInBoard(issue): void {
		if (issue['title'] && issue['title'].trim()) {
			this.loadingGif = 'block';
			this.checkForMentions(this.boardPost);
			this.handleIssueHashtags(this.boardPost.title);
			this.postService.createIssue(this.boardPost).subscribe(
				(data) => {
					this.loadingGif = 'none';
					this.openPosts.unshift(data);
					this.handleMentions(this.openPosts);
					this.boardPost = '';
					this.currentPriority = 'p2';
				},
				() => {
					this.loadingGif = 'none';
				}
			);
		}
	}

	private handleIssueHashtags(text): void {
		if (text.indexOf('#') > -1) {
			const words = text.split(' ');
			const hashes = [];
			words.forEach((element) => {
				if (element.indexOf('#') > -1) {
					const index = element.indexOf('#');
					if (index === 0) {
						const string = element.replace(/(^#)/g, '');
						if (hashes.indexOf(string) === -1) {
							hashes.push(string);
						}
					}
				}
			});
			if (hashes.length > 0) {
				this.boardPost['hashtags'] = hashes.join(',');
			}
		}
	}

	private checkForMentions(task): void {
		if (task['mentioned_users'] && task['mentioned_users'].length > 0) {
			const userList = [];
			for (const u of task['mentioned_users']) {
				if (task['title'].indexOf('@' + u.name) > -1) {
					userList.push(u.username);
				}
			}
			task['mentioned_users'] = [];
			task['mentioned_users'] = userList.join(',');
		}
		if (task['hashtags'] && task['hashtags'].length > 0) {
			const tagList = [];
			for (const h of task['hashtags']) {
				if (task['title'].indexOf('#' + h['name']) > -1) {
					tagList.push(h['name']);
				}
			}
			task['hashtags'] = [];
			task['hashtags'] = tagList.join(',');
		}
	}

	public enter(e): void {
		const elem = e['container']['element']['nativeElement'];
		elem.scrollIntoView({ behavior: 'smooth', inline: 'center' });
	}

	private checkIfPost(post: any, posts: any[]): boolean {
		if (posts.length > 0) {
			const index = posts.findIndex((x) => x['uid'] === post['uid']);
			if (index > -1) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	private handleMentions(lists: any[]): void {
		lists.forEach((post) => {
			if (post['title'] && post['title'].indexOf('<') > -1) {
				post['title'] = post['title'].replace(/</g, '< ');
			}

			post['altered_title'] = post['title'];
			if (post['mentionedUsers']) {
				post['mentionedUsers'].forEach((mentionedUser) => {
					const html = '<b #userMention><a class="text-green">@' + mentionedUser['name'] + '</a></b>';
					if (post.altered_title) {
						post['altered_title'] = post.altered_title.replace('@' + mentionedUser['name'], html);
					}
				});
			}
			if (post['hashtags']) {
				post['hashtags'].forEach((hashtag) => {
					if (hashtag['name']) {
						const html = '<b #hashTag><a class="hash-todo">#' + hashtag['name'] + '</a></b>';
						if (post.altered_title) {
							post['altered_title'] = post.altered_title.replace('#' + hashtag['name'], html);
						}
					}
				});
			}
		});
	}

	public checkShiftKey(event): void {
		// -Check if shift key is pressed for user mention / hashtags
		if (event.shiftKey) {
			this.shiftKeyUsed = true;
		}
	}

	public onKeyDown(event: KeyboardEvent, post): void {
		if (this.userMentions.length > 0 || this.hashMentions.length > 0) {
			switch (event.keyCode) {
				case 38:
					event.preventDefault();
					this.arrowKeyLocation--;
					if (this.arrowKeyLocation < 0) {
						this.panel.nativeElement.scrollTop = 0;
						this.arrowKeyLocation = 0;
					}

					if (this.userMentions.length > 0) {
						this.userMentions.forEach((val, index) => {
							if (index === this.arrowKeyLocation) {
								this.addHighlightClass(val);
								this.scrollIntoView(val);
							} else {
								this.removeHighlightClass(val);
							}
						});
					} else {
						this.hashMentions.forEach((val, index) => {
							if (index === this.arrowKeyLocation) {
								this.addHighlightClass(val);
								this.scrollIntoView(val);
							} else {
								this.removeHighlightClass(val);
							}
						});
					}
					break;

				case 40:
					event.preventDefault();
					this.arrowKeyLocation++;
					if (this.arrowKeyLocation > this.mentionCount - 1) {
						this.panel.nativeElement.scrollTop = 0;
						this.arrowKeyLocation = 0;
					}

					if (this.userMentions.length > 0) {
						this.userMentions.forEach((val, index) => {
							if (index === this.arrowKeyLocation) {
								this.addHighlightClass(val);
								this.scrollIntoView(val);
							} else {
								this.removeHighlightClass(val);
							}
						});
					} else {
						this.hashMentions.forEach((val, index) => {
							if (index === this.arrowKeyLocation) {
								this.addHighlightClass(val);
								this.scrollIntoView(val);
							} else {
								this.removeHighlightClass(val);
							}
						});
					}
					break;

				case 13:
					event.preventDefault();
					if (this.mentionType === 'user') {
						this.userMentions.forEach((element, index) => {
							if (index === this.arrowKeyLocation) {
								if (post) {
									this.onUserSelect(element, post);
								}
							}
						});
					} else if (this.mentionType === 'hash') {
						this.hashMentions.forEach((element, index) => {
							if (index === this.arrowKeyLocation) {
								if (post) {
									this.onHashTag(element, post);
								}
							}
						});
					}
			}
		}
	}

	public onUserSelect(user, task): void {
		this.isMentions = false;
		this.userMentions = [];
		this.hashMentions = [];
		const startPosition = this.native.selectionStart;
		const endPosition = this.native.selectionEnd;

		if (startPosition === endPosition) {
			const text = this.native.value.substring(0, startPosition);
			this.mentionIndex = text.lastIndexOf('@');

			if (this.mentionIndex > -1 && this.shiftKeyUsed) {
				const strippedText = this.native.value.substring(this.mentionIndex, startPosition);

				if (strippedText !== '@') {
					if (task) {
						const reg = new RegExp(strippedText, 'g');
						const replacedTitle = task['title'].replace(reg, '@' + user.name + ' ');
						task['title'] = replacedTitle;
					}
				} else {
					if (task) {
						const firstPart = task['title'].substr(0, startPosition);
						const lastPart = task['title'].substr(startPosition + 1);
						const newString = firstPart + user.name + ' ' + lastPart;
						task['title'] = newString;
					}
				}
			} else {
				const newTitle = <''>`${this.native.value.substring(0, startPosition)}@${user.name + ' '}${this.native.value.substring(endPosition)}`.toString();
				if (task) {
					task['title'] = newTitle;
				}
				this.native.focus();
			}
		}

		if (task) {
			if (task['mentioned_users']) {
				task['mentioned_users'].push(user);
			} else {
				task['mentioned_users'] = [];
				task['mentioned_users'].push(user);
			}
		}
	}

	public onHashTag(tag, task): void {
		this.isMentions = false;
		this.userMentions = [];
		this.hashMentions = [];

		const startPosition = this.native.selectionStart;
		const endPosition = this.native.selectionEnd;

		if (startPosition === endPosition) {
			const text = this.native.value.substring(0, startPosition);
			this.mentionIndex = text.lastIndexOf('#');

			if (this.mentionIndex > -1 && this.shiftKeyUsed) {
				const strippedText = this.native.value.substring(this.mentionIndex, startPosition);
				if (strippedText !== '#') {
					const reg = new RegExp(strippedText, 'g');
					if (task) {
						const replacedTitle = task['title'].replace(reg, '#' + tag['name'] + ' ');
						task['title'] = replacedTitle;
					}
				} else {
					if (task) {
						const firstPart = task['title'].substr(0, startPosition);
						const lastPart = task['title'].substr(startPosition + 1);
						const newString = firstPart + tag['name'] + ' ' + lastPart;
						task['title'] = newString;
					}
				}
			} else {
				const newTitle = <''>`${this.native.value.substring(0, startPosition)}#${tag['name'] + ' '}${this.native.value.substring(endPosition)}`.toString();
				this.native.focus();
				if (task) {
					task['title'] = newTitle;
				}
			}
		}

		if (task) {
			if (task['hashtags']) {
				task['hashtags'].push(tag);
			} else {
				task['hashtags'] = [];
				task['hashtags'].push(tag);
			}
		}
	}

	public getMentionsForSubtasks(e, index): void {
		this.native = e['path'][0];
		this.index = index;
		this.userRegexFailed = false;
		this.hashRegexFailed = false;
		e.target.style.height = e.target.scrollHeight + 'px';
		const userMentionRegex = /(^|\s)@(\w*(?:\s*\w*))$/;
		const hashTagRegex = /(^|\s)#(\w*(?:\s*\w*))$/;
		let text;
		const caretPosition = this.getCaretPositionForSubtask(e.target.value);
		const word = this.returnWord(e.target.value, caretPosition);

		if (word !== null) {
			if (userMentionRegex.test(word)) {
				this.mentionType = 'user';
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					// this.mentionsFetched = 1;
					text = word.replace(/(^@)/g, '');
					this.subject.next(text);
				}
			} else {
				this.userRegexFailed = true;
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					this.hashMentions = [];
					this.userMentions = [];
				}
			}
			if (word !== null && hashTagRegex.test(word)) {
				this.mentionType = 'hash';
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					this.mentionsFetched = 1;
					text = word.replace(/(^#)/g, '');
					this.subject.next(text); // 4
				}
			} else {
				this.hashRegexFailed = true;
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					this.hashMentions = [];
					this.userMentions = [];
				}
			}
		} else {
			if (!this.isMentions) {
				this.mentionsFetched = 0;
				this.userMentions = [];
				this.hashMentions = [];
			}
		}
	}

	public paginateMentions(): void {
		if (this.nextMentionUrl) {
			this.postService.paginate(this.nextMentionUrl).subscribe((data) => {
				if (data) {
					if (this.mentionType === 'user') {
						this.userMentions = this.userMentions.concat(data['objects']);
					} else if (this.mentionType === 'hash') {
						this.hashMentions = this.hashMentions.concat(data['objects']);
					}
					if (data.next) {
						this.nextMentionUrl = data.next.split('alpha')[1];
					} else {
						this.nextMentionUrl = '';
					}
				}
			});
		}
	}

	private getCaretPositionForSubtask(ctrl): number {
		let caretPosition = 0;
		ctrl = this.native;
		if (ctrl.selectionStart || ctrl.selectionStart === '0') {
			caretPosition = ctrl.selectionStart;
		}
		return caretPosition;
	}

	private returnWord(text, caretPosition): string {
		let words = [];
		let preText = '';
		if (text) {
			preText = text.substring(0, caretPosition);
			if (preText.indexOf(' ') > 0) {
				words = preText.split(' ');
				return words[words.length - 1]; // return last word
			} else {
				return preText;
			}
		}
		return null;
	}

	/** For adding highlight class for mention elements
	 * 1. Clear all previous highlight class on elements.
	 * 2. Add mention highlight css class.
	 * @param val
	 */
	private addHighlightClass(val): void {
		this.clearHighLightClass(); // 1
		const element = document.getElementById(val.uid);
		element.classList.add(CSS_CLASS_NAMES.highLight); // 2
	}

	/** For removing highlight class for mention elements
	 * @param val
	 */
	private removeHighlightClass(val): void {
		const element = document.getElementById(val.uid);
		element.classList.remove(CSS_CLASS_NAMES.highLight);
	}

	/**
	 * For clearing highlight class from all mention elements
	 */
	private clearHighLightClass(): void {
		this.userMentions.forEach((val) => {
			if (val.uid) {
				const element = document.getElementById(val.uid);
				element.classList.remove(CSS_CLASS_NAMES.highLight);
			}
		});
		this.hashMentions.forEach((val) => {
			if (val.uid) {
				const element = document.getElementById(val.uid);
				element.classList.remove(CSS_CLASS_NAMES.highLight);
			}
		});
	}

	/**
	 * Scroll to current mention element
	 * 1. Get current element by its uid.
	 * 2. Get offset top of current element
	 * 3. Set top Position as scroll top hence scroll to this position
	 * @param val
	 */
	private scrollIntoView(val): void {
		const elem = document.getElementById(val.uid);
		const topPosition = elem.offsetTop;
		this.panel.nativeElement.scrollTop = topPosition;
	}

	/**
	 * On mouse hover,add or remove hightlight class
	 * 1. Clear previous remanats of hightlight class
	 * @param event
	 */
	public onHover(event: MouseEvent): void {
		this.clearHighLightClass(); // 1
		const target = event.target as HTMLElement;
		// eslint-disable-next-line spellcheck/spell-checker
		if (event.type === 'mouseover') {
			target.classList.add(CSS_CLASS_NAMES.highLight);
		} else {
			target.classList.remove(CSS_CLASS_NAMES.highLight);
		}
	}

	public showInLightbox(issue: any): void {
		const images = issue.attachmentDetails?.gallery?.map((imageItem: any) => {
			const image = imageItem.image;

			return new ImageAttachment(<IImageAttachment>{
				id: image.uid,
				name: image.name,
				type: image.type,
				url: image.url,
				data: image,
			});
		});

		this.lightboxService.initLightbox(images);
	}

	/**
	 * Item Context menu open
	 * @param event Mouse Event
	 * @param item Selected Item
	 * @param i Selected Item Index
	 */
	public onProductItemContextMenuOpen(event: MouseEvent, item: any, i: number): void {
		event.preventDefault();
		event.stopPropagation();

		this.selectedItem = item;
		this.selectedItemIndex = i;

		this.contextMenuService?.closeAllContextMenus(null);

		timer(250).subscribe(() => {
			this.contextMenuService.show.next({
				contextMenu: this.productItemContextMenu,
				event,
				item: null,
			});
		});
	}

	/**
	 * Open Product context menu
	 * @param event Mouse Event
	 */
	public onProductContextMenuOpen(event: MouseEvent): void {
		event.preventDefault();
		event.stopPropagation();

		this.contextMenuService?.closeAllContextMenus(null);

		timer(250).subscribe(() => {
			this.contextMenuService.show.next({
				contextMenu: this.productContextMenu,
				event,
				item: null,
			});
		});
	}
}
