import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService, Network, NetworkService, MemberService, ChannelService, PaginationService } from '../shared';
import { PrivacyComponent } from '../shared';
import { SearchService } from '../shared/services/search.service';
import { TranslateService } from '@ngx-translate/core';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';

@Component({
	selector: 'app-channel',
	templateUrl: './channel.component.html',
	styleUrls: ['./channel.component.scss'],
})
export class ChannelComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild(PrivacyComponent, { static: true }) public privacy;
	public myChannels = [];
	public adminChannels = [];
	public otherChannels = [];
	public filter: any;
	public showSpin;
	public archivedCounts;
	public showSpins = '&#1547';
	public network: Network;
	public nextUrl: any;
	public displayCreateChannel = 'none';
	public visibilities = [
		{ name: 'Public', value: 0 },
		{ name: 'Private', value: 1 },
		{ name: 'Hidden', value: 2 },
	];
	public selectedVisibility;
	public userDetails;
	public membershipDetails: any;
	public role = { role: null, roleSlug: null };
	public roles: any;
	public model = { preview_images: [], images: [] };
	public members: any[] = [];
	public displayPrivacyModal = 'none';
	public selectedRole: any;
	public showCustomPrivacy = false;
	public emptyChannels = false;
	public customPrivacy: any[] = [];
	public customPrivacyString = null;
	public roleString = null;
	public totalMembersCount = 0;
	public nextMembersUrl = null;
	public networkLoaded;
	public disableInputButton = true;
	public memberList: any[] = [];
	public departments = [];
	public personalFilters = [];
	public selectByLevelValues = [];
	public value;
	public channel = {
		name: null,
		description: null,
		content_privacy: null,
		username: null,
		custom_privacy: null,
		logo: null,
		access: 0,
	};
	public memberModalDisplay = 'none';
	public channelReviewModal = 'none';
	public channelMembersString;
	public memberString;
	public isFirstSearch = true;
	private paginating = false;
	private paginateSub$: any;
	public showTeamActionModal = false;
	private currentUser: any;
	public channelsLoading = false;

	constructor(
		private networkService: NetworkService,
		public router: Router,
		public userServices: UserService,
		public memberService: MemberService,
		private searchService: SearchService,
		public channelService: ChannelService,
		private paginationService: PaginationService,
		private translate: TranslateService,
		public pageLoadingUiService: PageLoadingUiService,
		private userService: UserService
	) {
		this.channel.access = 0;
		this.networkService.currentNetwork.subscribe((network) => {
			this.network = network;
		});

		this.paginationService.scrollDistance = 7;
		this.paginateSub$ = this.paginationService.initiateScroll.subscribe((data) => {
			if (data && !this.paginating) {
				this.paginate();
			}
		});
		this.userDetails = this.userServices.getCurrentUser();
		if (!sessionStorage.getItem('refresh-channel-list')) {
			this.adminChannels = this.channelService.adminChannels;
			this.myChannels = this.channelService.myChannels;
			this.otherChannels = this.channelService.otherChannels;
		} else {
			sessionStorage.removeItem('refresh-channel-list');
			this.channelService.adminChannels = [];
			this.channelService.myChannels = [];
			this.channelService.otherChannels = [];
			this.channelService.scrollPosition = 0;
			this.channelService.nextUrl = null;
		}
	}

	ngOnInit(): void {
		if (localStorage.getItem('networkExpired')) {
			this.router.navigateByUrl('/expired');
		}

		this.userService.currentUser.subscribe((userData: any) => {
			this.currentUser = userData;
		});

		this.searchService.searchValue.subscribe((data) => {
			if (data.trim() && this.isFirstSearch) {
				this.isFirstSearch = false;
			}
			if (!this.isFirstSearch) {
				this.fetchChannels(data.trim());
			}
		});
		if (this.myChannels.length === 0 && this.otherChannels.length === 0 && this.adminChannels.length === 0) {
			this.fetchChannels();
		}

		if (this.network.membership && this.network.membership.memberRole) {
			this.network.membership.memberRole.fields.forEach((element) => {
				if (element.CP === true) {
					this.personalFilters.push(element);
				}
			});
		}
		if (this.network.SubscriptionTemplates && this.network.SubscriptionTemplates.length > 0) {
			this.network.SubscriptionTemplates.forEach((value) => {
				if (value.cpEnabled === true) {
					this.selectByLevelValues.push(value);
				}
			});
		}

		this.networkService.departmentsReady.subscribe((departmentsLoaded) => {
			if (departmentsLoaded) {
				this.networkService.currentDepartments.subscribe((departments) => {
					if (departments && departments['objects'].length > 0) {
						this.departments = departments.objects;
					}
				});
			}
		});

		this.getArchivedCounts();
	}

	ngOnDestroy(): void {
		this.channelService.scrollPosition = document.documentElement.scrollHeight;
		if (this.paginateSub$) {
			this.paginateSub$.unsubscribe();
		}
	}

	ngAfterViewInit(): void {
		if (this.channelService.scrollPosition) {
			window.scroll(0, this.channelService.scrollPosition);
		}
		// Hack: Scrolls to top of Page after page view initialized
		let top = document.getElementById('top');
		if (top !== null) {
			top.scrollIntoView();
			top = null;
		}
	}

	private fetchChannels(query = ''): void {
		let q = '';
		if (query) {
			q = 'query=' + query;
		}
		this.pageLoadingUiService.setPageLoading(true);
		this.getChannels(q);
	}

	public paginate(): void {
		if (this.nextUrl) {
			this.paginating = true;
			this.nextUrl = this.nextUrl.split('/alpha')[1];
			this.networkService
				.getNextChannel(this.nextUrl)
				.subscribe((data) => {
					this.paginating = false;
					if (data.next) {
						this.nextUrl = data.next;
						this.channelService.nextUrl = data['next'];
					} else {
						this.nextUrl = '';
						this.channelService.nextUrl = '';
					}
					for (const item of data.objects) {
						if (item['moderator'] && item['membership']) {
							if (item['moderator']?.username === this.currentUser?.username) {
								if (!this.adminChannels.includes(item)) {
									this.adminChannels.push(item);
								}
							} else if (item['membership'].state === 1) {
								if (!this.myChannels.includes(item)) {
									this.myChannels.push(item);
								}
							} else {
								if (!this.otherChannels.includes(item)) {
									this.otherChannels.push(item);
								}
							}
						}
					}
					this.channelService.adminChannels = this.adminChannels;
					this.channelService.myChannels = this.myChannels;
					this.channelService.otherChannels = this.otherChannels;
				})
				.add(() => {
					if (this.nextUrl) {
						this.paginate();
					}
				});
		}
	}

	public onCreateChannelClick(): void {
		const index = this.userServices.memberships.findIndex((x) => x['network'].uid === localStorage.getItem('network_id'));
		this.roles = this.networkService.getCurrentNetwork().roles;
		if (index > -1) {
			this.membershipDetails = this.userServices.memberships[index];
			this.role['role'] = this.membershipDetails.role;
			this.role['roleSlug'] = this.membershipDetails.roleSlug;
			this.channelMembersString = this.role['role'];
		} else {
			this.membershipDetails = null;
		}
		this.displayCreateChannel = 'block';
	}

	public onClickRole(): void {
		if (this.membershipDetails['memberType'] === 'admin') {
			this.selectedRole = this.role['roleSlug'];
			this.displayPrivacyModal = 'block';
			this.privacy.privacy_settings = 'block';
		} else {
			this.displayPrivacyModal = 'none';
			this.privacy.privacy_settings = 'none';
		}
	}

	public getChannels(q = ''): void {
		this.emptyChannels = false;
		this.channelsLoading = true;
		this.networkService
			.getChannels('/networks/' + localStorage.getItem('network_id') + '/channels/', q)
			.subscribe((data) => {
				const adminChannels = [],
					myChannels = [],
					otherChannels = [];
				if (data.objects.length === 0) {
					this.emptyChannels = true;
				} else {
					this.emptyChannels = false;
				}
				if (data['next']) {
					this.nextUrl = data['next'];
					this.channelService.nextUrl = data['next'];
				} else {
					this.nextUrl = null;
					this.channelService.nextUrl = null;
				}
				for (const item of data.objects) {
					if (item['moderator'] && item['membership']) {
						if (item['moderator']?.username === this.currentUser?.username) {
							adminChannels.push(item);
						} else if (item['membership'].state === 1) {
							myChannels.push(item);
						} else {
							otherChannels.push(item);
						}
					}
				}

				this.channelService.adminChannels = this.adminChannels = adminChannels;
				this.channelService.myChannels = this.myChannels = myChannels;
				this.channelService.otherChannels = this.otherChannels = otherChannels;
			})
			.add(() => {
				this.channelsLoading = false;
				this.pageLoadingUiService.setPageLoading(false);
				if (this.nextUrl) {
					this.paginate();
				}
			});
	}

	public onClickCustomPrivacy(): void {
		this.getMembers();
		this.showCustomPrivacy = !this.showCustomPrivacy;
	}

	public onCustomPrivacySelect(member): void {
		this.customPrivacy.push(member);
	}

	public getMembers(): void {
		if (this.totalMembersCount === 0 || this.totalMembersCount > this.members.length) {
			this.networkService.networkReady.subscribe((networkLoaded) => {
				this.networkLoaded = networkLoaded;
				if (this.networkLoaded) {
					this.networkService.currentNetwork.subscribe((network) => {
						this.network = network;
						this.memberService.getMembers(this.network.uid, this.nextMembersUrl).subscribe((data) => {
							data.objects.forEach((element) => {
								this.members.push(element);
							});
							this.totalMembersCount = data.meta.total_count;
							if (data.next) {
								this.nextMembersUrl = data.next.split('alpha')[1];
							} else {
								this.nextMembersUrl = '';
								this.pageLoadingUiService.setPageLoading(false);
							}
						});
					});
				}
			});
		}
	}

	public onSubmitPrivacy(): void {
		const privacyArray: any[] = [];
		if (this.customPrivacy.length > 0) {
			this.channelMembersString = '';
			this.customPrivacy.forEach((member) => {
				if (this.channelMembersString === '') {
					this.channelMembersString = member.user.name;
				} else {
					this.channelMembersString = this.channelMembersString + ',' + member.user.name;
				}
				privacyArray.push(member.user.userId);
			});
			this.customPrivacyString = '[' + privacyArray.join(',') + ']';
			this.channel['custom_privacy'] = this.customPrivacyString;
			delete this.channel['content_privacy'];
		} else {
			if (this.selectedRole === '') {
				this.channelMembersString = 'Public';
			} else {
				const index = this.roles.findIndex((role) => role.slug === this.selectedRole);
				this.channelMembersString = '';
				this.customPrivacyString = null;
				this.roleString = '"role":"' + this.selectedRole + '"';
				this.channel['content_privacy'] = this.roleString;
				this.channelMembersString = this.roles[index].name;
				delete this.channel['custom_privacy'];
			}
		}
		this.displayPrivacyModal = 'none';
		this.privacy.privacy_settings = 'none';
	}

	public onCloseHandle(): void {
		this.displayCreateChannel = 'none';
		this.memberModalDisplay = 'none';
		this.channelReviewModal = 'none';
	}

	public onClosePrivacy(): void {
		this.privacy.privacy_settings = 'none';
	}

	public onTitleChange(): void {
		if (this.channel.name && this.channel.description) {
			this.disableInputButton = false;
		}
	}

	public onClickMembers(member): void {
		this.memberList.push(member);
	}

	public displayMemberModal(): void {
		this.getMembers();
		this.memberModalDisplay = 'block';
	}

	public onSubmitMembers(): void {
		this.memberModalDisplay = 'none';
		this.channelReviewModal = 'block';
	}

	public onSubmitNext(): void {
		this.getMembers();
		this.displayMemberModal();
		this.displayCreateChannel = 'none';
	}

	public onSubmitReview(): void {
		this.channelReviewModal = 'none';
		this.networkService.createChannel(this.channel).subscribe((data) => {
			if (data['uid']) {
				const self = this;
				this.memberString = '';
				this.memberList.forEach(function (member) {
					if (self.memberString === '') {
						self.memberString = member.user.username;
					} else {
						self.memberString += '|' + member.user.username;
					}
				});
				const postData = { user: this.memberString };
				this.networkService.addMembers(postData, data['uid']).subscribe(function () {
					this.channel = {
						name: null,
						description: null,
						content_privacy: null,
						username: null,
						custom_privacy: null,
						logo: null,
						access: 0,
					};
				});
			}
		});
	}

	public filterChannels(f, q): void {
		this.filter = { value: q };
		this.getChannels(f + '=' + q);
		this.pageLoadingUiService.setPageLoading(true);
	}

	/**
	 * Function to get department name
	 * @param value
	 */
	public getDepartmentNameBasedOnId(value): string {
		const index = this.departments.findIndex((department) => department.uid === value);
		if (index !== -1) {
			return this.departments[index].name;
		}
	}

	/**
	 * Function to show post cp names of personal filters/memberships
	 * @param value
	 * 1. If content privacy(value) is status,show it.
	 * 2. If content privacy(value) is renewal status,show it.
	 * 3. Check if content privacy(value) is membershipLevel
	 * 4. Get membership level name from array
	 * 5. If value is manager,show cp as myTeam
	 * 6. If value is in personal filters,show it
	 */
	public getPostNames(value): string {
		if (value.status) {
			return value.status; // 1
		} else if (value.renewalStatus) {
			return value.renewalStatus; // 2
		} else if (value.membership_level) {
			// 3
			let name = '';
			this.selectByLevelValues.forEach((object) => {
				if (object.uid === value.membership_level) {
					name = object.name; // 4
				}
			});
			return name;
		} else if (value.manager) {
			return this.translate.instant('My Team'); // 5
		} else {
			this.value = Object.values(value);
			const index = this.personalFilters.findIndex((field) => field.value === this.value);
			if (index !== -1) {
				return this.personalFilters[index].valueLabel; // 6
			}
		}
	}

	private getArchivedCounts(): void {
		this.channelService.getArchivedCounts(this.network['membershipUid']).subscribe((data) => {
			this.archivedCounts = data['archivedCounts'];
		});
	}

	public teamPlusActionButton(): void {
		this.showTeamActionModal = !this.showTeamActionModal;
	}
}
