import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable()
export class MemberService {
	public members: any[] = [];
	public nextUrl = '';
	public filter = { key: null, value: null };
	public scrollPosition = 0;

	constructor(private apiService: ApiService) {}

	public getMembers(networkId: string, q: string = null, filter: string = null, useActiveFilter: boolean = true): Observable<any> {
		if (q) {
			return this.apiService.get(q);
		} else if (filter && useActiveFilter) {
			return this.apiService.get(`/networks/${networkId}/members/?filter=active&${filter}`);
		} else if (!useActiveFilter) {
			return this.apiService.get(`/networks/${networkId}/members/?${filter}`);
		}

		return this.apiService.get(`/networks/${networkId}/members/?filter=active`);
	}

	public getMembersForChat(search: string): Observable<any> {
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/users/?query=' + search);
	}

	public getMembersForExternalChannel(channelUid): Observable<any> {
		return this.apiService.get('/channels/' + channelUid + '/network_members/');
	}

	public paginate(url): Observable<any> {
		return this.apiService.get(url);
	}

	public resetMembers(): void {
		this.members = [];
		this.nextUrl = '';
		this.filter = { key: null, value: null };
		this.scrollPosition = 0;
	}

	public makeChannelAdmin(channel_id, member_id, obj): Observable<any> {
		const url = '/channels/' + channel_id + '/members/' + member_id + '/';

		return this.apiService.put(url, obj);
	}

	public deleteChannelMember(channel_id, username): Observable<any> {
		const obj = { delete_users: username };
		const url = '/channels/' + channel_id + '/members/';

		return this.apiService.post(url, obj);
	}
}
