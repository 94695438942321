import { NetworkService, LayoutService, UserService, ProfilesService } from '../shared';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { CalendarEvent, CalendarView, CalendarMonthViewBeforeRenderEvent } from 'angular-calendar';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { PostEditComponent } from '../shared/layout/post-edit/post-edit.component';
import { PostComponent } from '../post/post.component';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';

@Component({
	selector: 'app-calendar-view',
	styleUrls: ['./calendar-view.component.css'],
	templateUrl: './calendar-view.component.html',
})
export class CalendarViewComponent implements OnInit {
	@ViewChild('postEdit', { static: true }) public postEdit: PostEditComponent;
	@ViewChild('postComponent', { static: true }) public postComponent: PostComponent;

	public view: CalendarView = CalendarView.Month;
	public CalendarView = CalendarView;
	public renderEvents = [];

	private isCalendarUserChanged = false;

	public currentUser: any;
	public network: any;
	public todolistUid;
	public todoItems = [];
	public projectDetail;
	public currentDate;
	public selectedDate;
	public memberShip;
	public timeNow;
	public username;
	public date;
	public currentSelectedDate;
	public uid;
	public currentUserUid = localStorage.getItem('uid');
	public value;
	public showPostCreateForm = false;
	public showDefaultPost = true;
	public noAssignedItems = false;
	public setStartDayComment = false;
	public display_Completed = true;
	public displayMyTasks = false;
	public postType;
	public showDeleteConfirmation = 'none';
	public isRedirectFromEditPage = false;
	public selectedTodos: any = [];
	public selectedToDoItem: any;
	public todoDetail;
	public calendarDate;
	public collaboration: boolean;
	public showMembers = false;
	public tasksCounts = [];
	public selectedMonth;
	public selectedCalendarDate: any;
	public dateSelected;
	public paramType = 'task';
	public currentView;
	public showFilter = false;
	public projectSlug;
	public projectUid;
	public loadingGif = 'none';
	public showMenuOptions = false;
	public options = [];
	public visibilityOptions = 'none';
	public shareOptions = ['Everyone', 'My Department', 'Only Me'];
	public selectedOption;
	public settings = {};

	public department;
	public members = [];
	public membersFiltered = [];
	public member: any = {
		username: '',
	};
	public memberName = '';
	public memberImage: 'assets/img/default_avatar.jpg';
	public nextUrl;
	public months;
	public isArchived = false;
	public showSharedCalendar = false;
	public filterSelected = false;

	public viewDate: Date = new Date();
	public customCellTemplate;
	public modalData: {
		action: string;
		event: CalendarEvent;
	};
	public locale: string;
	public refresh: Subject<any> = new Subject();
	public isManager = false;
	public myTeamView = false;

	public beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
		this.months = renderEvent;
		this.renderEvents = renderEvent.body;
		this.getCountsForMonth();
	}

	constructor(
		private networkService: NetworkService,
		public layoutService: LayoutService,
		private userService: UserService,
		private activatedRoute: ActivatedRoute,
		private location: Location,
		private translate: TranslateService,
		private profileService: ProfilesService,
		private router: Router,
		private pageLoadingUiService: PageLoadingUiService
	) {
		this.networkService.currentNetwork.subscribe((network) => {
			this.network = network;
			if (this.network) {
				this.isManager = this.network?.membership?.isManager ? true : false;
				return;
			}
		});
	}

	/**
	 * 1. Get todolist uid from route
	 * 2. Set current user data
	 * 3. Call function to get todos of current date
	 * 4. Set current network data
	 * 5. Get todo List detail
	 * 6. Update todo after it is edited from post edit componenet
	 * 7. Show estimated time and oneOff date of todo if exists
	 * 8. Manage language set on Localstorage
	 * 9. Append new todo item to todoitems from post component
	 *10. For removing backdrop and clearing posttype on closing add task form modal
	 */
	ngOnInit(): void {
		if (localStorage.getItem('networkExpired')) {
			this.router.navigateByUrl('/expired');
		}

		this.activatedRoute.queryParams.subscribe((params) => {
			if (params['archived']) {
				this.isArchived = true;
			} else {
				this.isArchived = false;
			}

			if (params['id']) {
				const id = params['id'];

				this.showFilter = false;
				this.currentView = 'todolist';
				this.todolistUid = id; // 1

				this.networkService.getToDoListDetail(this.todolistUid, this.isArchived).subscribe((detail) => {
					this.todoDetail = detail; // 5
					this.collaboration = this.todoDetail['collaboration'];

					if (this.todoDetail['user'] && this.todoDetail['user']['username'] === this.currentUser.username) {
						this.collaboration = true;
					}
				});
			} else if (params['slug']) {
				this.showFilter = false;
				this.currentView = 'project';
				this.projectSlug = params['slug'];
				this.projectUid = params['uid'];

				this.projectDetail = {
					uid: this.projectUid,
					slug: this.projectSlug,
					name: params['name'],
				};
			} else {
				this.currentView = 'todolist';
				this.showFilter = true;
			}
		});

		this.currentUser = this.userService.getCurrentUser(); // 2
		this.member = this.currentUser;
		this.memberName = this.currentUser.name.split(' ')[0];
		this.memberImage =
			this.currentUser && this.currentUser.images && this.currentUser.images[0] && this.currentUser.images[0].thumbnails
				? this.currentUser.images[0].thumbnails[120]
				: 'assets/img/default_avatar.jpg';
		this.getMemberShip();
		this.setItemsForMonth(this.viewDate); // 3

		this.selectedCalendarDate = new Date();

		if (localStorage.getItem('language')) {
			// 8
			this.locale = localStorage.getItem('language');
		}

		this.postComponent.newPostEmitter.subscribe((data) => {
			// 9
			if (data) {
				this.getCountsForMonth();
				this.setItemsForMonth(this.selectedCalendarDate);
			}
		});

		this.layoutService.showBackdrop.subscribe((data) => {
			// 10
			if (data === 'event-post-form' || data === 'hide') {
				this.showPostCreateForm = false;
				this.postType = '';
				this.showMenuOptions = false;
			}
		});

		if (this.network['membership']) {
			if (this.network['membership']['memberRole']['fields'].length > 0) {
				const fields = this.network['membership']['memberRole']['fields'];
				fields.forEach((field) => {
					if (field['key'] === 'department') {
						this.department = field.value;
					}
					if (field['key'] === 'manager' && field['value']) {
						this.shareOptions = ['Everyone', 'My Team', 'My Department', 'Only Me'];
					}
				});
			}
		}

		this.getMembers();
	}

	public dayClicked(selectedDay): void {
		const d1 = selectedDay.date;
		this.selectedCalendarDate = d1;

		if (d1.setHours(0, 0, 0, 0) !== this.viewDate.setHours(0, 0, 0, 0)) {
			if (this.todoDetail && this.todoDetail['uid']) {
				this.todoDetail['selectedCalendarDate'] = d1;
			} else if (this.projectDetail && this.projectDetail['uid']) {
				this.projectDetail['selectedCalendarDate'] = d1;
			} else {
				this.calendarDate = d1;
			}
		} else {
			this.reset();
		}

		this.setSelectedDate(d1);
		this.setItemsForMonth(d1);
		const currentDate = this.viewDate.getDate();

		this.renderEvents.forEach((day) => {
			if (day.isPast && day['dotted']) {
				day.cssClass = 'red-dot';
			}

			if (currentDate !== day.date.getDate()) {
				if (!day['dotted']) {
					day.cssClass = 'bg-white';
				} else if (day['dotted'] && day['isFuture']) {
					day.cssClass = 'gray-dot';
				}
			}

			if (currentDate !== d1.getDate()) {
				if (selectedDay['dotted']) {
					if (selectedDay['isFuture']) {
						selectedDay.cssClass = 'lobster-gray';
					} else {
						selectedDay.cssClass = 'lobster-red';
					}
				} else {
					selectedDay.cssClass = 'bg-peach';
				}
			}
		});
	}

	public reset(): void {
		if (this.calendarDate) {
			this.calendarDate = '';
		} else if (this.todoDetail && this.todoDetail['selectedCalendarDate']) {
			delete this.todoDetail['selectedCalendarDate'];
		} else if (this.projectDetail && this.projectDetail['selectedCalendarDate']) {
			delete this.projectDetail['selectedCalendarDate'];
		}
	}

	/**
	 * Function to get todo items and display it
	 *
	 * 1. Set selected date
	 * 2. Get date,month,year of incoming date
	 * 3. Format date
	 * 4. Call api to get todo items
	 * 5. Set next url if present
	 * 6. Display todo items
	 * 7. Call function to show oneoff date and estimated time
	 *
	 * @param date
	 */
	private setItemsForMonth(date): void {
		// this.selectedCalendarDate = date;
		this.timeNow = new Date().getSeconds();
		this.setSelectedDate(date); // 1
		let dd = date.getDate(); // 2
		let mm = date.getMonth() + 1;

		const yyyy = date.getFullYear();

		if (dd < 10) {
			dd = '0' + dd;
		}

		if (mm < 10) {
			mm = '0' + mm;
		}

		// let selectedDate = yyyy + '/' + mm + '/' + dd;  // 3
		const selectedDate = yyyy + '-' + mm + '-' + dd; // 3

		if (this.paramType === 'event' || this.paramType === 'meeting') {
			this.currentView = '';
			this.date = selectedDate;
		} else if (this.paramType === 'assignedBy') {
			if (this.currentView !== 'project') {
				this.currentView = 'todolist';
			}

			this.uid = '';
			const filter = 'date=' + selectedDate + '&assigned_to=true&open=true';
			this.date = filter;
		} else {
			if (this.currentView !== 'project') {
				this.currentView = 'todolist';
			}

			if (this.todolistUid) {
				this.uid = this.todolistUid;

				const filter = 'date=' + selectedDate + '&open=true';

				this.date = filter;
			} else {
				this.uid = '';
				const filter = 'date=' + selectedDate;
				this.date = filter;

				if (this.member.username) {
					this.username = this.member.username;
				} else {
					this.username = this.currentUser['username'];
				}
			}
		}
	}

	/**
	 * Function to set selected date and manage its translation
	 * @param date
	 * 1. Strip off month from date
	 * 2. Set month based on selected month
	 */
	private setSelectedDate(date): void {
		const month = date.getMonth();
		this.dateSelected = date.toDateString().substring(7); // 1

		if (month === 0) {
			// 2
			this.selectedMonth = this.translate.instant('Jan');
		} else if (month === 1) {
			this.selectedMonth = this.translate.instant('Feb');
		} else if (month === 2) {
			this.selectedMonth = this.translate.instant('Mar');
		} else if (month === 3) {
			this.selectedMonth = this.translate.instant('Apr');
		} else if (month === 4) {
			this.selectedMonth = this.translate.instant('May');
		} else if (month === 5) {
			this.selectedMonth = this.translate.instant('Jun');
		} else if (month === 6) {
			this.selectedMonth = this.translate.instant('Jul');
		} else if (month === 7) {
			this.selectedMonth = this.translate.instant('Aug');
		} else if (month === 8) {
			this.selectedMonth = this.translate.instant('Sep');
		} else if (month === 9) {
			this.selectedMonth = this.translate.instant('Oct');
		} else if (month === 10) {
			this.selectedMonth = this.translate.instant('Nov');
		} else {
			this.selectedMonth = this.translate.instant('Dec');
		}
	}

	/**
	 * Function to get todos count for dates
	 */
	public getCountsForMonth(): void {
		if (this.selectedCalendarDate) {
			this.setItemsForMonth(this.selectedCalendarDate);
		} else {
			this.setItemsForMonth(this.viewDate);
		}

		const now = this.renderEvents[10].date,
			monthStartDay = new Date(now.getFullYear(), now.getMonth(), 1),
			monthEndDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

		let dd1: any;
		let mm1: any;

		dd1 = monthStartDay.getDate();
		mm1 = monthStartDay.getMonth() + 1;

		const yyyy1 = monthStartDay.getFullYear();

		if (dd1 < 10) {
			dd1 = '0' + dd1;
		}

		if (mm1 < 10) {
			mm1 = '0' + mm1;
		}

		const fromDate = yyyy1 + '-' + mm1 + '-' + dd1;

		let dd2: any;
		let mm2: any;

		dd2 = monthEndDay.getDate();
		mm2 = monthEndDay.getMonth() + 1;
		const yyyy2 = monthEndDay.getFullYear();

		if (dd2 < 10) {
			dd2 = '0' + dd2;
		}

		if (mm2 < 10) {
			mm2 = '0' + mm2;
		}

		const endDate = yyyy2 + '-' + mm2 + '-' + dd2;
		const filter = 'from_date=' + fromDate + '&end_date=' + endDate;

		if (this.currentView === 'todolist' && !this.showFilter) {
			this.networkService.getTodosCount(this.todolistUid, filter, this.isArchived).subscribe((data) => {
				this.tasksCounts = data.data; // 2
				this.showDotForDates();
			});
		}

		if (this.currentView === 'todolist' && this.showFilter) {
			this.networkService.getUserItemsCounts(filter).subscribe((data) => {
				this.tasksCounts = data.data;
				this.showDotForDates();
			});
		}

		if (this.currentView === 'project') {
			this.networkService.getProjectTodosCount(this.projectUid, filter, this.isArchived).subscribe((data) => {
				this.tasksCounts = data.data;
				this.showDotForDates();
			});
		}
	}

	/**
	 * Function for setting dots on dates in calendar
	 */
	private showDotForDates(): void {
		this.tasksCounts.forEach((d1) => {
			this.renderEvents.forEach((d2) => {
				if (d1.date) {
					const date1 = new Date(d1.date);
					if (d2.date) {
						const date2 = d2.date;
						if (date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth()) {
							d2.cssClass = 'gray-dot';
							d2['dotted'] = true;
						}
					}
				}
			});
		});

		this.renderEvents.forEach((day) => {
			if (day.isPast && day['dotted']) {
				day.cssClass = 'red-dot';
			}
		});
	}

	/**
	 * Function for activating plus button action
	 * 1. Scroll to top of screen
	 * 2. Check if current page is myCalendar,then set menu action options
	 * 3. Else display task create form directly
	 */
	public plusButtonAction(): void {
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});

		this.layoutService.hideBd();

		if (this.showFilter) {
			// 2
			this.options = [
				{
					name: 'Add Task',
					slug: 'task',
				},
				{
					name: 'Setup an event',
					slug: 'event',
				},
				{
					name: 'Video Conference',
					slug: 'meeting',
				},
			];

			this.layoutService.showBd('event-post-form');
			this.showMenuOptions = true;
		} else {
			// 3
			this.showMenuOptions = false;
			this.showAddTaskForm();
		}
	}

	/**
	 * Function to display task create form
	 */
	private showAddTaskForm(): void {
		this.showPostCreateForm = !this.showPostCreateForm;

		if (this.showPostCreateForm) {
			this.layoutService.showBd('event-post-form');
			this.postType = 'add-task';
		} else {
			this.postType = '';
			this.layoutService.hideBd('event-post-form');
		}
	}

	/**
	 * Function for displaying task/event/meeting form modals
	 *
	 * 1. Close options modal
	 * 2. Check if selected option is event, then set posttype as event
	 * 3. Check if selected option is task, then set posttype as task
	 * 4. Check if selected option is meeting, then set posttype as meeting
	 *
	 * @param option
	 */
	public onSelectMenu(option: any): void {
		this.closeMenuOptions(); // 1
		this.showPostCreateForm = !this.showPostCreateForm;

		if (this.showPostCreateForm) {
			this.layoutService.showBd('event-post-form');

			if (option.slug === 'event') {
				// 2
				this.postType = 'event';
			} else if (option.slug === 'meeting') {
				// 3
				this.postType = 'meeting';
			} else {
				this.postType = 'add-task'; // 4
				// this.todoDetail['name'] = 'My Todo List';
				// this.todoDetail['uid'] = localStorage.getItem('MyTodolist');
				// this.todoDetail['repeatCycle'] = 'daily';
			}
		} else {
			this.postType = '';
			this.layoutService.hideBd('event-post-form');
		}
	}

	public filterCalendar(param): void {
		this.filterSelected = true;
		this.paramType = param;
		this.tasksCounts = [];
		this.renderEvents.forEach((day) => {
			day.cssClass = '';
			day['dotted'] = false;
		});

		this.myTeamView = param === 'manager' ? true : false;

		if (this.paramType === 'task' || this.paramType === 'assignedBy') {
			if (this.paramType === 'task') {
				this.username = this.member['username'];
			} else {
				this.username = localStorage.getItem('uid');
			}

			this.currentView = 'todolist';
		}

		this.getCountsForMonth();

		if (this.selectedCalendarDate) {
			this.setItemsForMonth(this.selectedCalendarDate);
		} else {
			this.setItemsForMonth(this.viewDate);
		}
	}

	/**
	 * Function for closing options modal
	 */
	public closeMenuOptions(): void {
		this.showMenuOptions = false;
		this.layoutService.hideBd();
	}

	/**
	 * Function which redriects to previous page
	 */
	public onBackClick(): void {
		this.location.back();
	}

	/**
	 * Get member list for calendar
	 */
	private getMembers(): void {
		this.networkService.getCalendarMembers().subscribe((data) => {
			this.membersFiltered = [];

			// sort members by name
			const sortedData = data.sort((a: any, b: any) => a.firstName.localeCompare(b.firstName));

			this.members = [
				// set current user as first member
				...sortedData.filter((x: any) => x.username === this.currentUserUid),
				// set other members
				...sortedData.filter((x: any) => x.username !== this.currentUserUid),
			].map((x: any) => {
				// set initials
				x.searchString = `${x.firstName}${x.lastName}${x.primaryRole}`.toLowerCase();

				return x;
			});
		});
	}

	/**
	 * Search in members list
	 *
	 * @param event any
	 */
	public searchCalendarMember(event: any): void {
		this.membersFiltered = this.members.filter((x: any) => x.searchString.includes(event.target.value.toLowerCase())) || [];
	}

	public viewTop(): void {
		const scrollToTop = window.setInterval(() => {
			// 1
			const position = window.pageYOffset;

			if (position > 0) {
				window.scrollTo(0, position - 90);
			} else {
				window.clearInterval(scrollToTop);
			}
		}, 3);

		this.layoutService.hideBd();
	}

	public updateSettings(): void {
		if (this.selectedOption) {
			this.pageLoadingUiService.setPageLoading(true);

			if (this.selectedOption === 'My Department') {
				const a = '{"department": "' + this.department + '"}';
				const b = '{"calendar":' + a + '}';
				this.settings['privacy_settings'] = b;
			} else if (this.selectedOption === 'My Team') {
				const a = '{"manager": "' + true + '"}';
				const b = '{"calendar":' + a + '}';
				this.settings['privacy_settings'] = b;
			} else if (this.selectedOption === 'Only Me') {
				const a = '{"onlyMe": "' + true + '"}';
				const b = '{"calendar":' + a + '}';
				this.settings['privacy_settings'] = b;
			} else {
				this.settings = '';
			}

			this.networkService.memberPrivacySettings(this.network.membershipUid, this.settings).subscribe(() => {
				this.getMemberShip();
				this.settings = {};
				this.pageLoadingUiService.setPageLoading(false);
				this.visibilityOptions = 'none';
			});
		}
	}

	private getMemberShip(): void {
		this.profileService.getMemberShip(this.currentUser.username, this.network['membershipUid']).subscribe((profile) => {
			this.memberShip = profile;

			if (this.memberShip['privacySettings']) {
				if (this.memberShip['privacySettings']['calendar']) {
					const type = this.memberShip['privacySettings']['calendar'];

					if (type['department']) {
						this.selectedOption = 'My Department';
					} else if (type['manager']) {
						this.selectedOption = 'My Team';
					} else if (type['onlyMe']) {
						this.selectedOption = 'Only Me';
					} else {
						this.selectedOption = 'Everyone';
					}
				}
			} else {
				this.selectedOption = 'Everyone';
			}
		});
	}

	/**
	 * Switch users calendar
	 *
	 * @param member any
	 */
	public displayMemberCalendar(member: any): void {
		// TODO: apply better solution

		if (!this.isCalendarUserChanged && this.currentUserUid !== member.username) {
			this.isCalendarUserChanged = true;
		} else if (this.isCalendarUserChanged && this.currentUserUid === member.username) {
			window.location.reload();
		}

		this.showSharedCalendar = false;

		let dd = this.selectedCalendarDate.getDate();
		let mm = this.selectedCalendarDate.getMonth() + 1;
		const yyyy = this.selectedCalendarDate.getFullYear();

		if (dd < 10) {
			dd = '0' + dd;
		}

		if (mm < 10) {
			mm = '0' + mm;
		}

		const dateFormatted = yyyy + '/' + mm + '/' + dd;
		this.date = 'date=' + dateFormatted;
		this.pageLoadingUiService.setPageLoading(true);
		this.paramType = 'task';
		this.currentView = 'todolist';
		this.member['username'] = member['username'];
		this.timeNow = new Date().getSeconds();
		this.username = member['username'];

		this.member = member;
		this.memberName = member.name.split(' ')[0];
		this.memberImage =
			this.member.images && this.member.images[0] && this.member.images[0].thumbnails ? this.member.images[0].thumbnails[120] : 'assets/img/default_avatar.jpg';

		this.tasksCounts = [];
		this.renderEvents.forEach((day) => {
			day.cssClass = '';
			day['dotted'] = false;
		});

		this.networkService.getCountsOfOthers(member['username']).subscribe((data) => {
			this.tasksCounts = data.data;

			this.setItemsForMonth(this.viewDate);
			this.showDotForDates();

			this.pageLoadingUiService.setPageLoading(false);
		});
	}
}
