import { distinctUntilChanged, map } from 'rxjs/operators';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject, Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { ApiService } from './api.service';
import { JwtService } from './jwt.service';
import { User } from '../models';
import { Router } from '@angular/router';
import { IWorkspacePaginatedResponse } from '@shared/models/workspace/workspace';
import { HttpParams } from '@angular/common/http';
import { MixPanelService } from '@core/services/third-party/mix-panel.service';
import CacheUtils from '@shared/utils/cache-utils';
import { SegmentAnalyticsService } from '@core/services/third-party/segment-analytics.service';

@Injectable()
export class UserService {
	@Output() public logoutEvent: EventEmitter<any> = new EventEmitter();

	private user = {};
	private currentUserSubject = new BehaviorSubject<any>(this.user);
	public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());
	private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
	public isAuthenticated = this.isAuthenticatedSubject.asObservable();
	public nextUrl;
	public memberships: any[] = [];
	public membership_nextUrl;
	public isPartnerStack = false;

	private username: string = null;

	constructor(
		public router: Router,
		private apiService: ApiService,
		private jwtService: JwtService,
		private location: Location,
		private mixPanelService: MixPanelService,
		private segmentService: SegmentAnalyticsService
	) {
		this.username = localStorage.getItem('uid') ?? this.getCurrentUser()?.['uid'];
	}

	// Verify JWT in localstorage with server & load user's info.
	// This runs once on application startup.
	public populate(): void {
		/** If JWT detected, attempt to get & store user's info
		 * 1. If user details exists in Localstorage its used,otherwise user detail api is called
		 * 2. For handling user details on purchase scenario
		 */
		if (this.jwtService.getToken()) {
			if (localStorage.getItem('userDetails')) {
				const userDetail = JSON.parse(localStorage.getItem('userDetails')); // 1
				if (userDetail.user && userDetail.user.access) {
					const userObject = userDetail.user; // 2
					userObject['purchaseScenario'] = true;
					this.setAuth(userObject);
				} else {
					this.setAuth(userDetail);
				}
			} else {
				this.apiService.get('/users/' + this.jwtService.getUid() + '/').subscribe(
					(data) => {
						localStorage.setItem('userDetails', JSON.stringify(data));
						CacheUtils.storeData('userDetails', JSON.stringify(data));
						this.setAuth(data);
					},
					() => {
						this.purgeAuth();
					}
				);
			}
		} else {
			// Remove any potential remnants of previous auth states
			this.purgeAuth();
		}
	}

	/** Function to set user details on localStorage and set user details as observable
	 * Modified:14-10-20
	 * @param user
	 * 1. Save access token
	 * 2. Save UID sent from server in local storage
	 * 3. Set current user data into observable
	 * 4. Set isAuthenticated to true
	 * 5. Set device token
	 * 6. Set refresh token in local storage
	 * 7. Set token expires in local storage
	 */
	public setAuth(user: User): void {
		/**
		 * To override user details as string
		 */
		if (typeof user === 'string') {
			user = JSON.parse(user);
		}

		if (!localStorage.getItem('token')) {
			// 1
			this.jwtService.saveToken(user?.access);
		}
		this.jwtService.saveUid(user?.username); // 2
		if (user.udid) {
			this.jwtService.saveDeviceUid(user?.udid);
		}
		if (user['deviceToken']) {
			// 5
			localStorage.setItem('device-token', user['deviceToken']);
		}
		if (user['refresh']) {
			// 6
			localStorage.setItem('refreshToken', user?.refresh);
		}
		if (user['expires']) {
			// 7
			localStorage.setItem('tokenExpires', user['expires']);
		}
		this.storeMembershipCount(user);
		this.currentUserSubject.next(user); // 3
		this.isAuthenticatedSubject.next(true); // 4
		this.mixPanelService.postInit(user);
		this.segmentService.setupUserData(user);
	}

	public purgeAuth(): void {
		console.log('removing auth data');
		// Remove JWT from localstorage
		this.jwtService.destroyToken();
		// Set current user to an empty object
		this.currentUserSubject.next(new User());
		// Set auth status to false
		this.isAuthenticatedSubject.next(false);
		// Removing the previous URL.
		localStorage.removeItem('pb-prev-url');
		// Removing the last selected privacy.
		localStorage.removeItem('lastPrivacy');
		// Removing the notification seen time.
		localStorage.removeItem('last-notification');
		// Removing the fcm token.
		localStorage.removeItem('fcm_token');
		// Remove token expires date
		localStorage.removeItem('tokenExpires');
		// Remove refresh token
		localStorage.removeItem('refreshToken');

		this.memberships = [];
		this.membership_nextUrl = [];
	}

	public attemptAuth(type, credentials, currentLocation: GeolocationPosition): Observable<User> {
		credentials.username = credentials.phone ? credentials.phone : credentials.email ? credentials.email : '';
		credentials.type = 'auth';
		let url = '';
		if (currentLocation && currentLocation.coords && localStorage.getItem('device-token')) {
			url = '/token/?near=' + currentLocation.coords.latitude + '-' + currentLocation.coords.longitude + '&device-token=' + localStorage.getItem('device-token');
		} else if (currentLocation && currentLocation.coords) {
			url = '/token/?near=' + currentLocation.coords.latitude + '-' + currentLocation.coords.longitude;
		} else if (localStorage.getItem('device-token')) {
			url = '/token/?device-token=' + localStorage.getItem('device-token');
		} else {
			url = '/token/';
		}
		return this.apiService.post(url, credentials).pipe(
			map((data) => {
				// Set user details to local storage
				localStorage.setItem('userDetails', JSON.stringify(data));
				CacheUtils.storeData('userDetails', JSON.stringify(data));
				this.setAuth(data);
				return data;
			})
		);
	}

	public setCurrentUserInfo(code): Subscription {
		if (!localStorage.getItem('token')) {
			this.jwtService.saveToken(code);
			this.location.replaceState('/');
		}

		return this.apiService.get('/users/current-user/').subscribe((data) => {
			this.jwtService.destroyToken();
			// Set user details to local storage
			localStorage.setItem('userDetails', JSON.stringify(data));
			CacheUtils.storeData('userDetails', JSON.stringify(data));

			this.setAuth(data);
			return data;
		});
	}

	public storeMembershipCount(user): void {
		if (user['membershipCount']) {
			if (!localStorage.getItem('membershipCount')) {
				localStorage.setItem('membershipCount', user['membershipCount']);
			}
		}
	}

	public getCurrentUser(): User {
		/**
		 * To override user details as string
		 */
		return typeof this.currentUserSubject.value === 'string' ? JSON.parse(this.currentUserSubject.value) : this.currentUserSubject.value;
	}

	/**
	 * Get user's membership list (workspaces)
	 *
	 * @returns Observable<IWorkspacePaginatedResponse>
	 */
	public getMemberships(__includeAppId: boolean = false): Observable<IWorkspacePaginatedResponse> {
		let httpParams = new HttpParams();

		if (__includeAppId || localStorage.getItem('network_id')) {
			httpParams = httpParams.set('__includeAppId', 'true');
		}

		return this.apiService.get(`/users/${this.username}/memberships/`, httpParams);
	}
	public paginate(url: string): Observable<any> {
		return this.apiService.get(url);
	}

	public updateUserDetails(): any {
		return this.apiService.get('/users/' + localStorage.getItem('uid') + '/').subscribe((data) => {
			/**
			 * To override user details as string
			 */
			if (typeof data === 'string') {
				data = JSON.parse(data);
			}
			this.currentUserSubject.next(data);
		});
	}

	public updateSignedUpUser(data, username = null): Observable<any> {
		return this.apiService.put(`/users/${username ?? localStorage.getItem('uid')}/`, data);
	}

	public updateUserSubject(data): void {
		/**
		 * To override user details as string
		 */
		if (typeof data === 'string') {
			data = JSON.parse(data);
		}
		this.currentUserSubject.next(data);
		this.isAuthenticatedSubject.next(true);
		this.mixPanelService.postInit(data);
		this.segmentService.setupUserData(data);
	}

	/** Function to set user details on localStorage and set user details as observable
	 * Modified:19-01-21
	 * @param user
	 * 1. Save access token
	 * 2. Save UID sent from server in local storage
	 * 3. Set current user data into observable
	 * 4. Set isAuthenticated to true
	 * 5. Set device token
	 * 6. Set refresh token in local storage
	 * 7. Set token expires in local storage
	 */
	public setAuthForSignUp(user: User): void {
		/**
		 * To override user details as string
		 */
		if (typeof user === 'string') {
			user = JSON.parse(user);
		}
		if (!localStorage.getItem('token')) {
			// 1
			this.jwtService.saveToken(user.access);
		}
		if (user.username) {
			this.jwtService.saveUid(user.username); // 2
		}
		if (user.udid) {
			this.jwtService.saveDeviceUid(user.udid);
		}

		this.currentUserSubject.next(user); // 3

		if (user['deviceToken']) {
			// 5
			localStorage.setItem('device-token', user['deviceToken']);
		}
		if (user['refresh']) {
			// 6
			localStorage.setItem('refreshToken', user.refresh);
		}
		if (user['expires']) {
			// 7
			localStorage.setItem('tokenExpires', user['expires']);
		}
	}

	public getUser(): Observable<any> {
		return this.apiService.get('/users/' + localStorage.getItem('uid') + '/');
	}

	// Update the user on the server (phone, pass, etc)
	public update(user): Observable<User> {
		return this.apiService.put('/users/' + localStorage.getItem('uid') + '/', user).pipe(
			map((data) => {
				/**
				 * To override user details as string
				 */
				if (typeof data === 'string') {
					data = JSON.parse(data);
				}
				this.currentUserSubject.next(data); // Update the currentUser observable
				localStorage.removeItem('userDetails');
				CacheUtils.removeStoredData('userDetails');
				this.mixPanelService.postInit(data);
				this.segmentService.setupUserData(data);
				return data.user;
			})
		);
	}

	public updatePassword(param): Observable<any> {
		return this.apiService.put('/users/' + localStorage.getItem('uid') + '/password/change/', param);
	}

	public fetchCountries(): Observable<any> {
		return this.apiService.get('/geo/countries/?limit=20');
	}

	public searchCountries(query): Observable<any> {
		return this.apiService.get(`/geo/countries/?limit=20&query=${query}`);
	}

	public submitCountryAndPhone(credentials): Observable<any> {
		const url = '/accounts/presignup/';
		return this.apiService.post(url, credentials);
	}

	public userSignUp(credentials): Observable<any> {
		const url = '/accounts/signup/';
		return this.apiService.post(url, credentials);
	}

	public zoomAuth(data): Observable<any> {
		return this.apiService.post('/zoom-auth/', data);
	}

	public zoomAuthRefresh(data): Observable<any> {
		return this.apiService.post('/zoom-refresh/', data);
	}

	public getNetworkTypes(): Observable<any> {
		return this.apiService.get('/_support/networkSettings/');
	}

	public createNewNetwork(credentials): Observable<any> {
		return this.apiService.post('/networks/', credentials);
	}

	public iPAuth(data): Observable<any> {
		return this.apiService.post('/oauth/', data);
	}

	public getNetworkId(email): Observable<any> {
		return this.apiService.post('/sso/network/', { email: email });
	}

	/**
	 * Function to validate app sumo coupon
	 */
	public validateCoupon(url): Observable<any> {
		return this.apiService.customGetMethod(url);
	}

	/**
	 * Function to delete user
	 * @param username current user
	 * @returns Observable
	 */
	public deleteUser(username: string): Observable<any> {
		return this.apiService.delete(`/users/${username}/`);
	}

	public logout(data: any = null): void {
		this.logoutEvent.emit(data);

		Object.keys(localStorage).forEach((key) => {
			if (key.indexOf('-Todolist') > -1) {
				localStorage.removeItem(key);
			}
			if (key.indexOf('-Posts') > -1) {
				localStorage.removeItem(key);
			}
			if (key.indexOf('-lists') > -1) {
				localStorage.removeItem(key);
			}
			if (key.indexOf('-projects') > -1) {
				localStorage.removeItem(key);
			}
		});

		this.purgeAuth();
		this.memberships = [];
		this.nextUrl = [];
		// this.membership_nextUrl;

		window.localStorage.removeItem('network_id');
		localStorage.removeItem('membership');
		localStorage.removeItem('MyTodolist');
		localStorage.removeItem('favoriteId');
		localStorage.removeItem('notificationCount');
		localStorage.removeItem('zoom_authentication');
		localStorage.removeItem('device-token');
		localStorage.removeItem('todo-prev-route');
		localStorage.removeItem('purchasePlans');
		localStorage.removeItem('seatsCount');
		localStorage.removeItem('manager');
		localStorage.removeItem('networkExpired');
		localStorage.removeItem('welcome-tips');
		localStorage.removeItem('member-delete');
		localStorage.removeItem('branchData');
		localStorage.removeItem('branch_session_first');
		localStorage.removeItem('app_network_id');
		localStorage.removeItem('tab');
		localStorage.removeItem('detail-tab');
		localStorage.removeItem('status');
		localStorage.removeItem('currentPurchasePlan');
		localStorage.removeItem('user_language');
		localStorage.removeItem('membership');
		localStorage.removeItem('roles');
		localStorage.removeItem('sub_networks');
		localStorage.removeItem('userDetails');
		CacheUtils.removeStoredData('userDetails');
		localStorage.removeItem('network_settings');
		localStorage.removeItem('defaultNetworkType');
		localStorage.removeItem('defaultPurchasePlan');
		localStorage.removeItem('currentSubscriptionStatus');
		localStorage.removeItem('member-delete');
		localStorage.removeItem('membershipCount');
		localStorage.removeItem('isPartnerStack');

		this.router.navigateByUrl('/');
	}
}
