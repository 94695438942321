import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';

const GEOLOCATION_ERRORS = {
	'errors.location.unsupportedBrowser': 'Browser does not support location services',
	'errors.location.permissionDenied': 'You have rejected access to your location',
	'errors.location.positionUnavailable': 'Unable to determine your location',
	'errors.location.timeout': 'Service timeout has been reached',
};

@Injectable()
export class GeoLocationService {
	private locationObservable: Observable<any>;

	private currentLocationSubject = new BehaviorSubject<GeolocationPosition>(null);

	public getLocation(geoLocationOptions?: any): Observable<any> {
		if (this.locationObservable) {
			return this.locationObservable;
		}

		geoLocationOptions = geoLocationOptions || { timeout: 5000 };

		this.locationObservable = Observable.create((observer) => {
			if (navigator && navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					(position) => {
						observer.next(position);
						observer.complete();
					},
					(error) => {
						switch (error.code) {
							case 1:
								observer.error(GEOLOCATION_ERRORS['errors.location.permissionDenied']);
								break;
							case 2:
								observer.error(GEOLOCATION_ERRORS['errors.location.positionUnavailable']);
								break;
							case 3:
								observer.error(GEOLOCATION_ERRORS['errors.location.timeout']);
								break;
						}
					},
					geoLocationOptions
				);
			} else {
				observer.error(GEOLOCATION_ERRORS['errors.location.unsupportedBrowser']);
			}
		}).pipe(share());

		return this.locationObservable;
	}


	public getCurrentLocation(): GeolocationPosition {
		return this.currentLocationSubject.value;
	}

	public setCurrentLocation(position: GeolocationPosition): void{
		this.currentLocationSubject.next(position);
	}
}

export const geolocationServiceInjectables: Array<any> = [{ provide: GeoLocationService, useClass: GeoLocationService }];
