export class Country {
	public name: string;
	public iso2: string;
	public dialCode: string;
	public priority: number;
	public areaCode: number;
	public flagClass: string;
	public placeHolder: string;

	constructor() {
		this.name = '';
		this.iso2 = '';
		this.dialCode = '';
		this.priority = 0;
		this.areaCode = null;
		this.flagClass = '';
		this.placeHolder = '';
	}
}
