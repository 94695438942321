import { IBranchData } from './../../models/third-party/branch-data';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import CacheUtils from '@shared/utils/cache-utils';

@Injectable({
	providedIn: 'root',
})
export class BranchIoService {
	public branchData: IBranchData;

	constructor(private router: Router) {}

	/**
	 * Handle branch redirection
	 *
	 * @source: branch.io implementation
	 * @return void
	 */
	public handleBranchRedirection(): void {
		if (this.branchData && this.branchData.contentType) {
			CacheUtils.removeStoredData(['branchData', 'branch_session_first']);

			let route = '';

			if (this.branchData.contentType === 'ToDoList') {
				route = `/project/team/${this.branchData.contentUid}`;
			} else if (this.branchData.contentType === 'ToDo') {
				route = '/project/task-detail/' + this.branchData.contentUid;
			} else if (this.branchData.contentType === 'Customer') {
				route = '/network/customer/' + this.branchData.contentUid;
			} else if (this.branchData.contentType === 'Channels') {
				route = '/network/view-channel?id=' + this.branchData.contentUid;
			} else if (this.branchData.contentType === 'Collections') {
				route = '/network/collection?id=' + this.branchData.contentUid;
			} else if (this.branchData.contentType === 'Issue') {
				route = '/network/issue-detail?id=' + this.branchData.contentUid;
			} else if (this.branchData.contentType === 'Post') {
				route = '/posts/' + this.branchData.contentUid;
			} else if (this.branchData.contentType === 'External-Channels') {
				route = '/network/view-channel?id=' + this.branchData.contentUid + '&external=true';
			}

			this.router.navigateByUrl(route);
		}
	}

	/**
	 * Clear branch data from local storage
	 *
	 * @return void
	 */
	public clearBranchIoData(): void {
		CacheUtils.removeStoredData(['branchData', 'branch_session_first']);
	}

	/**
	 * Set branch data
	 *
	 * @param branchData IBranchData
	 * @returns void
	 */
	public setBranchData(branchData: IBranchData): void {
		this.branchData = branchData;
	}
}
