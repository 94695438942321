import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-terms',
	templateUrl: './terms.component.html',
	styleUrls: ['./terms.component.css'],
})
export class TermsComponent implements OnInit {
	constructor(private location: Location) {}
	public isNeaty = environment.appName === 'Neaty';
	public appName = environment.appName;
	ngOnInit(): void {}

	public onBackClick(): void {
		this.location.back();
	}
}
