import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable()
export class ProductService {
	public tab;

	constructor(private apiService: ApiService) {}

	public getArchivedCounts(): Observable<any> {
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/product_counts/');
	}

	public getArchivedProducts(): Observable<any> {
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/products/?archived=true');
	}

	public getProducts(): Observable<any> {
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/products/');
	}

	public deleteProduct(uid): Observable<any> {
		return this.apiService.delete('/networks/' + localStorage.getItem('network_id') + '/products/' + uid + '/');
	}

	public getProductCount(uid): Observable<any> {
		return this.apiService.get('/products/' + uid + '/counts/');
	}

	public getProductDetail(uid, state): Observable<any> {
		if (state) {
			return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/products/' + uid + '/?archived=true');
		} else {
			return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/products/' + uid + '/');
		}
	}

	public getIssuesByStatus(uid, slug, state): Observable<any> {
		if (state) {
			return this.apiService.get('/products/' + uid + '/issues/' + slug + '/?archived=true');
		} else {
			return this.apiService.get('/products/' + uid + '/issues/' + slug + '/');
		}
	}

	public searchIssues(uid, slug, query): Observable<any> {
		return this.apiService.get('/products/' + uid + '/issues/' + slug + '/?query=' + query);
	}

	public getIssues(filter): Observable<any> {
		if (filter.indexOf('=') === -1) {
			return this.apiService.get('/products/' + filter + '/issues/');
		} else {
			return this.apiService.get('/users/' + localStorage.getItem('uid') + '/issues/?network=' + localStorage.getItem('network_id') + '&' + filter);
		}
	}

	public getIssuesByProduct(uid): Observable<any> {
		return this.apiService.get('/products/' + uid + '/issues/');
	}

	public paginate(url): Observable<any> {
		return this.apiService.get(url);
	}

	public deleteIssue(uid): Observable<any> {
		const url = '/issues/' + uid + '/?username=' + localStorage.getItem('uid');
		return this.apiService.delete(url);
	}

	public muteIssue(uid): Observable<any> {
		// eslint-disable-next-line spellcheck/spell-checker
		const url = '/users/' + localStorage.getItem('uid') + '/mutecontents/';
		const data = {
			contentType: 'issue',
			contentUid: uid,
			networkUid: localStorage.getItem('network_id'),
		};
		return this.apiService.post(url, data);
	}

	public unMuteIssue(muteUid): Observable<any> {
		// eslint-disable-next-line spellcheck/spell-checker
		const url = '/users/' + localStorage.getItem('uid') + '/mutecontents/' + muteUid + '/';
		return this.apiService.delete(url);
	}

	public updateIssue(credential, issueUid): Observable<any> {
		const url = '/issues/' + issueUid + '/';
		return this.apiService.put(url, credential);
	}

	public filterByType(uid, slug, state, type, query): Observable<any> {
		if (state) {
			if (query) {
				return this.apiService.get('/products/' + uid + '/issues/' + slug + '/?archived=true&type=' + type + '&query=' + query);
			} else {
				return this.apiService.get('/products/' + uid + '/issues/' + slug + '/?archived=true&type=' + type);
			}
		} else {
			if (query) {
				return this.apiService.get('/products/' + uid + '/issues/' + slug + '/?type=' + type + '&query=' + query);
			} else {
				return this.apiService.get('/products/' + uid + '/issues/' + slug + '/?type=' + type);
			}
		}
	}

	public reorderIssues(product_uid, params): Observable<any> {
		const url = '/products/' + product_uid + '/issues/order/';
		return this.apiService.post(url, params);
	}
}
