import { filter } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService, PostService, NetworkService, ProfilesService, CollectionsService, LayoutService } from '../../shared';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Post } from '../../shared/models';
import { SearchService } from '../../shared/services/search.service';
import { TranslateService } from '@ngx-translate/core';
import { PostEditComponent } from '../../shared/layout/post-edit/post-edit.component';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { SegmentAnalyticsService } from '@core/services/third-party/segment-analytics.service';
import { MixPanelService } from '@core/services/third-party/mix-panel.service';

@Component({
	selector: 'app-buy-sell-detail',
	templateUrl: './buy-sell-detail.component.html',
	styleUrls: ['./buy-sell-detail.component.css'],
})
export class BuySellDetailComponent implements OnInit {
	@ViewChild('postEdit', { static: true }) public postEdit: PostEditComponent;
	public loadingGif = 'none';
	public currentUser: any;
	public postId: any;
	public post: any;
	public images: any[] = [];
	public showReadMore = false;
	public selectedTab = 'details';
	public message;
	public network;
	public showDeleteConfirmation = 'none';
	public editPost: any;
	public postType;
	public showCollectionModal = false;
	public collections: any;
	public collectionSearchString = '';

	public item = {
		category: '',
		commentable: true,
		shareable: true,
		state: '',
		type: 'offer',
	};

	// Reminders
	public reminder = { value: '' };
	public reminderOptions: any[] = [];
	public displayReminderModal = 'none';
	public display_toast = 'none';
	public reminderMessage;
	public reminderTime;
	public timeForReminder: number;
	public isEditReminder = false;
	public reminderObject = {
		username: '',
		contentUid: '',
		contentType: '',
		reminderOption: '',
		repeat: false,
	};
	// Reports
	public reportReasons: any[] = [];
	public reportModal = 'none';
	public flag = { value: '', text: '' };
	public reportPost;
	public reportText;
	public isReported = false;
	public appLogo = environment.logo;

	constructor(
		private userService: UserService,
		private router: Router,
		private searchService: SearchService,
		private profileService: ProfilesService,
		private translate: TranslateService,
		public collectionService: CollectionsService,
		private networkService: NetworkService,
		private route: ActivatedRoute,
		private _location: Location,
		private postService: PostService,
		private layoutService: LayoutService,
		private segmentService: SegmentAnalyticsService,
		private mixPanelService: MixPanelService
	) {}

	ngOnInit(): void {
		this.loadingGif = 'block';
		this.message = 'Is the item still available ?';
		this.route.queryParams.pipe(filter((params) => params.id)).subscribe((params) => {
			this.postService.get(params.id).subscribe((data) => {
				this.post = new Post(data);
				this.postType = this.post['type'];
				this.checkUserMentionAndHashtagAndAlterBody();
				if (this.post.attachmentDetails.gallery) {
					this.post.attachmentDetails.gallery.forEach((element) => {
						this.images.push(element.image.url);
					});
				}

				if (this.post.image && this.post.image.url) {
					this.images.push(this.post.image.url);
				}
				this.loadingGif = 'none';
			});
		});

		this.networkService.currentNetwork.subscribe((network) => {
			this.network = network;
		});

		this.userService.currentUser.subscribe((userData) => {
			this.currentUser = userData;
		});

		// Replace todo item with updated todo item from edit
		this.postEdit.updatedPostEmitter.subscribe((data) => {
			if (data) {
				this.post = new Post(data);
				this.images = [];

				this.checkUserMentionAndHashtagAndAlterBody();
				if (this.post.attachmentDetails.gallery) {
					this.post.attachmentDetails.gallery.forEach((element) => {
						this.images.push(element.image.url);
					});
				}

				if (this.post.image && this.post.image.url) {
					this.images.push(this.post.image.url);
				}
			}
		});
	}

	/**
	 * Function to check the user mention and hash tag in post list if exists change the post body
	 */
	private checkUserMentionAndHashtagAndAlterBody(): void {
		if (this.post['mentioned_users']) {
			this.post['mentioned_users'].forEach((mentionedUser) => {
				// const userMention = '@' + mentionedUser['name'];
				const html =
					'<b #userMention><a class="text-danger" href="/network/profile/' +
					mentionedUser?.memberId +
					'?username=' +
					mentionedUser?.username +
					'">' +
					mentionedUser['name'] +
					'</a></b>';
				this.post.body = this.post.body.replace('@' + mentionedUser['name'], html);
			});
		}
		if (this.post['hashtags']) {
			this.post['hashtags'].forEach((hashtag) => {
				if (hashtag['name']) {
					const hashtagName = hashtag['name'];
					const hashtagUid = hashtag['uid'];
					const html = '<b #hashTag><a class="text-danger" href="/network/#' + hashtagName + '/' + hashtagUid + '">#' + hashtagName + '</a></b>';
					this.post.body = this.post.body.replace('#' + hashtag['name'], html);
				}
			});
		}
		if (this.post.body && this.post.body.length > 300) {
			this.post['strippedBody'] = this.post.body.slice(0, 200) + '...';
		}
	}

	// getComments(post = this.post) {
	//   this.commentService.getAll(post.commentUrl).subscribe((data) => {
	//     let comments = data.objects.map((comment) => new Comment(comment));
	//     if (!post.commentFirstClick) {
	//       post.comments = post.comments.concat(comments);
	//     } else {
	//       post.comments = comments;
	//       post.commentFirstClick = false;
	//     }
	//     post.setCommentUrl(data.meta);
	//   });
	// }

	public onChangeTab(tab): void {
		this.selectedTab = tab;
		// if (tab === 'discussion') {
		//   this.getComments();
		// }
	}

	public markAsSold(): void {
		if (this.post.attachmentDetails.gallery && this.post.attachmentDetails.gallery.length > 0) {
			this.post.attachmentDetails.gallery.forEach((img) => {
				this.item['images'] = img.image.uid;
			});
		}
		if (this.post.contentPrivacy) {
			this.item['content_privacy'] = JSON.stringify(this.post['contentPrivacy']);
		} else if (this.post.channel && this.post.channel['uid']) {
			this.item['channel'] = this.post['channel']['uid'];
		} else if (this.post['customPrivacy'] && this.post['customPrivacy'].length > 0) {
			const customPrivacy: any[] = [];
			// let custom: any;
			this.post.customPrivacy.forEach((element) => {
				if (element.userId) {
					customPrivacy.push(element.userId);
				} else {
					customPrivacy.push(element.id);
				}
			});
			const custom = '[' + customPrivacy.join(',') + ']';
			this.item['custom_privacy'] = custom;
		} else {
		}

		if (this.post.type) {
			this.item.category = this.post['type']; // 1
			this.item['state'] = 'sold';
			this.loadingGif = 'block';
			this.postService.itemSold(this.post.uid, this.item).subscribe((data) => {
				// 2
				delete this.item['content_privacy'];
				delete this.item['channel'];
				delete this.item['custom_privacy'];
				this.post = data;
				this.loadingGif = 'none';
			});
		}
	}

	public onResellItem(): void {
		if (this.post['type'] === 'postTodo') {
			this.post['type'] = this.postType;
		}
		this.post['isResell'] = true;
		this.postService.editSelectedPost = this.post;
		this.editPost = this.post;
	}

	public onBackClick(): void {
		this._location.back();
	}

	// Function for confirming delete for an issue
	public onDeleteConfirm(): void {
		this.loadingGif = 'block';
		this.postService.deletePost(this.post['uid']).subscribe(() => {
			this.loadingGif = 'none';
			this._location.back();
			this.showDeleteConfirmation = 'none';
		});
	}

	public onEditPost(): void {
		if (this.post['type'] === 'postTodo') {
			this.post['type'] = this.postType;
		}
		delete this.post['isResell'];
		this.post['state'] = 'sold';
		this.postService.editSelectedPost = this.post;
		this.editPost = this.post;
	}

	public onAddTask(): void {
		this.post['type'] = 'postTodo';
		this.editPost = this.post;
	}

	/**
	 *Function to show add to collection model
	 */
	public onAddToCollectionClick(): void {
		this.showCollectionModal = true;
		this.collectionService.collection.subscribe(() => {
			this.collections = this.collectionService.sortedCollectionSubject.value;
			if (this.collections['myCollections'].length === 0 && this.collections['followingCollections'].length === 0 && this.collections['followingCollections'].length === 0) {
				this.collectionService.populateCollection();
			}
		});
	}

	public paginateCollection(): void {
		if (this.collectionService.nextUrl) {
			this.collectionService.paginateCollection();
		}
	}

	public onSearchEnter(event: any): void {
		if (event.keyCode === 13) {
			this.onSearchClick();
		}
	}
	public onSearchClick(): void {
		if (!this.collectionSearchString) {
			this.searchService.initiateSearch(this.collectionSearchString);
		}
		this.collectionService.populateCollection(this.collectionSearchString);
		this.collectionService.nextUrl = '';
	}

	public onChangeCollectionSearch(): void {
		if (!this.collectionSearchString) {
			this.collectionService.populateCollection(this.collectionSearchString);
		}
	}

	public onSelectCollection(collection): void {
		this.showCollectionModal = false;
		this.loadingGif = 'block';
		this.collectionService.addToCollection(collection.uid, this.post.uid).subscribe(() => {
			this.mixPanelService.track('Created Collections', {
				workspace: this.network.name || 'NA',
				appVersion: environment.version,
				language: this.translate.currentLang,
				username: this.currentUser?.username || 'NA',
				name: this.currentUser?.name || 'NA',
				date: moment().format('YYYY-MM-DD'),
			});

			this.segmentService.trackEvent('Created Collections', {
				workspace: this.network.name || 'NA',
				appVersion: environment.version,
				language: this.translate.currentLang,
				username: this.currentUser?.username || 'NA',
				name: this.currentUser?.name || 'NA',
				date: moment().format('YYYY-MM-DD'),
			});
			this.loadingGif = 'none';
			this.collectionService.populateCollection();
		});
	}

	public redirectToCreateCollection(): void {
		this.router.navigateByUrl(`/network/create-collection?postUid=${this.post.uid}`);
	}

	public displayReminders(): void {
		this.displayReminderModal = 'block';
		this.reminderOptions = [];
		this.networkService.getReminderOptions().subscribe((data) => {
			this.reminderOptions = data.reminderOptions;
		});
	}

	/**
	 * Function to create a reminder based on reminder option selected
	 */
	public createRemainder(): void {
		if (this.reminder['value']) {
			const option = this.reminder['value'];
			this.loadingGif = 'block';
			if (option === 'in_twenty_minutes') {
				this.reminderMessage = this.translate.instant('A reminder is set for 20 minutes');
				this.timeForReminder = 20;
			} else if (option === 'in_one_hour') {
				this.reminderMessage = this.translate.instant('A reminder is set for 1 hour');
				this.timeForReminder = 60;
			} else if (option === 'in_three_hours') {
				this.reminderMessage = this.translate.instant('A reminder is set for 3 hours');
				this.timeForReminder = 180;
			} else if (option === 'tomorrow') {
				this.reminderMessage = this.translate.instant('A reminder is set for tomorrow');
				this.timeForReminder = this.minutesUntilMidnight() + 540;
			} else {
				this.reminderMessage = this.translate.instant('A reminder is set for next week');
				this.timeForReminder = 6300;
			}

			const date = new Date();
			date.setMinutes(date.getMinutes() + this.timeForReminder);
			const month = date.getUTCMonth() + 1;
			const day = date.getUTCDate();
			const year = date.getUTCFullYear();
			const hours = date.getUTCHours();
			const minutes = date.getUTCMinutes();
			const seconds = date.getUTCSeconds();
			const utcDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
			this.reminderTime = utcDate;

			this.reminderObject.username = this.currentUser.username;
			this.reminderObject.contentUid = this.post.uid;
			this.reminderObject.contentType = 'post';
			this.reminderObject.reminderOption = option;
			this.reminderObject.repeat = true;
			this.reminderObject['reminder'] = this.reminderTime;
			this.networkService.createReminder(this.reminderObject).subscribe((data) => {
				this.timeForReminder = 0;
				this.reminder.value = '';
				this.displayReminderModal = 'none';
				this.loadingGif = 'none';
				this.display_toast = 'block';
				this.post['myRelations']['reminder'] = data.uid;
				this.closeReminderToastModal();
			});

			if (!this.isEditReminder) {
				this.networkService.createReminder(this.reminderObject).subscribe((data) => {
					this.post['myRelations']['reminder'] = data.uid;
					this.timeForReminder = 0;
					this.reminder.value = '';
					this.displayReminderModal = 'none';
					this.loadingGif = 'none';
					this.display_toast = 'block';
					this.closeReminderToastModal();
				});
			} else {
				this.networkService.editRemainder(this.reminderObject, this.post.myRelations.reminder).subscribe((data) => {
					this.post['myRelations']['reminder'] = data.uid;
					this.timeForReminder = 0;
					this.reminder.value = '';
					this.displayReminderModal = 'none';
					this.loadingGif = 'none';
					this.display_toast = 'block';
					this.closeReminderToastModal();
				});
			}
		}
	}

	private minutesUntilMidnight(): any {
		const midnight = new Date();
		midnight.setHours(24);
		midnight.setMinutes(0);
		midnight.setSeconds(0);
		midnight.setMilliseconds(0);
		return (midnight.getTime() - new Date().getTime()) / 1000 / 60;
	}

	public closeReminderToastModal(): void {
		setTimeout(() => {
			this.display_toast = 'none';
		}, 8000);
	}

	public getReminderDetails(): void {
		this.loadingGif = 'block';
		this.networkService.getRemainderDetails(this.post.myRelations.reminder).subscribe((data) => {
			this.loadingGif = 'none';
			if (data.reminderOption) {
				this.isEditReminder = true;
				this.reminder.value = data.reminderOption;
				this.displayReminders();
			}
		});
	}

	public handlePostReport(): void {
		this.isReported = false;
		if (this.post['myRelations'] && !this.post['myRelations']['flagged']) {
			this.reportModal = 'block';
			this.flag['value'] = '';
			this.flag['text'] = '';
			this.reportReasons = [];
			this.profileService.getReasons().subscribe((data) => {
				if (data.post) {
					this.reportReasons = Object.entries(data.post);
				}
			});
		} else {
			this.reportText = 'You have reported already';
			this.isReported = true;
			setTimeout(() => {
				this.isReported = false;
			}, 7000);
		}
	}

	public onPostReport(): void {
		if (this.flag['value']) {
			this.loadingGif = 'block';
			const obj = {
				contentType: 'post',
				contentUid: this.post['uid'],
				reason: this.flag['value'],
				text: this.flag['text'],
			};

			this.networkService.reportAnAction(obj).subscribe(
				(data) => {
					this.post.myRelations['flagged'] = data.uid;
					this.resetReports();
					this.reportText = 'Your feedback is reported successfully';
					this.isReported = true;
					this.loadingGif = 'none';
					setTimeout(() => {
						this.isReported = false;
					}, 7000);
				},
				() => {
					this.resetReports();
					this.loadingGif = 'none';
				}
			);
		}
	}

	public resetReports(): void {
		this.reportPost = '';
		this.flag['value'] = '';
		this.flag['text'] = '';
		this.reportReasons = [];
		this.reportModal = 'none';
	}

	/**
	 * Function to show the add task or add issue form
	 * @param $event
	 */
	public onClickMoreOptionsEmitterFn($event): void {
		this.postService.onClickMoreOptionsFromComments.emit($event);
		setTimeout(() => {
			this.layoutService.hideBd();
			this.layoutService.showBd('add-issue');
			window.scroll(0, 0);
		}, 200);
	}
}
