import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IConversation } from '../models/conversation';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class QuickChatService {
	private currentUrl: string;
	private toggleQuickChatVisibility = new BehaviorSubject<boolean>(false);
	private conversationToShowInQuickChat = new BehaviorSubject<IConversation>(null);
	constructor(private router: Router) {}

	public hideQuickChat(): void {
		this.toggleQuickChatVisibility.next(false);
	}
	public showQuickChat(): void {
		// if(this.router.url === '/chat/inbox'){
		// 	console.log('chat page');
		// 	return;
		// }
		this.toggleQuickChatVisibility.next(true);
	}

	public setConversationToShowInQuickChat(conversation: IConversation): void{
		this.conversationToShowInQuickChat.next(conversation);
	}

	public conversationToShowInQuickChatSubject(): Observable<IConversation>{
		return this.conversationToShowInQuickChat.asObservable();
	}

	public getConversationToShowInQuickChat(): IConversation{
		return this.conversationToShowInQuickChat.value;
	}

	public manageQuickChatState(show: boolean, conversation?: IConversation): void {
		if (show && conversation) {
			this.setConversationToShowInQuickChat(conversation);
		}
		this.toggleQuickChatVisibility.next(show);
	}

	public get isQuickChatOpened(): boolean{
		return this.toggleQuickChatVisibility.value;
	}

	public quickChatVisibilityStatus(): Observable<boolean>{
		return this.toggleQuickChatVisibility.asObservable();
	}

}
