import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { UserService, User, Post, NetworkService, SocketIoService, LayoutService, ApiService, CollectionsService, FileUploadComponent } from '../shared';
import { ActivatedRoute, Router } from '@angular/router';
import { InboxSocketService } from '../shared/services/inbox-socket.service';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { SidebarService } from 'projects/peer-core/src/lib/services/ui/sidebar.service';
import { WorkspaceCreationSessionManagementService } from '@shared/services/workspace-creation-session-management.service';

@Component({
	selector: 'app-network',
	templateUrl: './network.component.html',
	styleUrls: ['./network.component.css'],
})
export class NetworkComponent implements OnInit, OnDestroy {
	@ViewChild('bottomNav', { static: true }) public bottomNav: ElementRef;
	@ViewChild('fileUpload', { static: true }) public fileUpload: FileUploadComponent;
	public isExternalUser = false;
	public isAuthenticated: boolean;
	public currentUser: User;
	public displayError = 'none';
	public errorStatus: any = '';
	public imageModal = 'none';
	public model = new Post(
		{
			title: null,
			body: null,
			category: null,
			privacy: '',
			images: [],
			preview_images: [],
			shareable: true,
			commentable: true,
			channel: '',
		},
		true
	);
	public title;
	public network;
	public refreshData;
	public accessToken;
	private currentNetSub;
	private networkReady;
	public token_expires;
	public newExpiryDate;
	public value = false;
	public notificationCount: any;
	public postCategories;
	public posts;
	public isLocked = false;
	public currentDate;
	public currentDay;
	public currentMonth;
	public showSpin;
	public notifications;
	public displayConfig;
	public members;
	public userNotifications;
	public userNotificationSummary;
	public comments;
	public savedPosts;
	public classifieds;
	public networkLoaded;
	public showNetwork;
	public currentSubRoute;
	public networkType;
	public networkLogo;
	public client;
	public unreadCount;
	public activeNav;
	public nextUrl: any;
	public currentSubscription: any;
	public showMoreNav = false;
	public showReportedIssues = false;
	public showTodolistOption = false;
	public displayPointLadder = false;
	public showCustomers = false;
	public displayDeleteDialog = 'none';
	public modelText;
	public displayDialog = 'none';
	public isCustomerActive = false;
	public isTodoActive = false;
	public isCalActive = false;
	public isIssueActive = false;
	public isCollectionActive = false;
	public isDevMode = !environment.production;

	constructor(
		private userService: UserService,
		private socket: SocketIoService,
		private networkService: NetworkService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private socketIoService: SocketIoService,
		public layoutService: LayoutService,
		private inboxSocket: InboxSocketService,
		private collectionService: CollectionsService,
		private apiService: ApiService,
		public _location: Location,
		public sidebarService: SidebarService,
		private wsSessionManagementService: WorkspaceCreationSessionManagementService
	) {}

	ngOnInit(): void {
		this.wsSessionManagementService.clearJoinFlowSessionData();
		this.clearBranch();
		this.inboxSocket.getSocket().removeAllListeners();

		window.addEventListener('scroll', this.scroll, true); // third parameter

		this.activeNav = 'home';

		this.activatedRoute.queryParams.subscribe((params) => {
			if (this.currentSubRoute === 'view-channel') {
				// const channelId = params['id'];
				// this.networkService.getChannel(channelId, false).subscribe(data => {
				//   this.networkService.populateNetworkBasedOnId(data['networkUid']);
				// });
			} else if (this.currentSubRoute === 'collection') {
				const collectionId = params['id'];
				this.collectionService.getCollectionDetail(collectionId, false).subscribe(() => {
					this.networkService.populateNetworkBasedOnId(localStorage.getItem('network_id'));
				});
			}
		});

		this.networkService.populate();
		this.networkService.getDepartmentDetails();
		this.networkService.getNetworkSubscriptionDetails();

		this.userService.isAuthenticated.subscribe((authenticated) => {
			this.isAuthenticated = authenticated;
			if (authenticated) {
				this.title = 'Networks';
			} else {
				localStorage.setItem('pb-prev-url', this.router.url);
				this.router.navigateByUrl('/authentication/login');
			}
		});

		this.networkService.networkReady.subscribe((networkLoaded) => {
			this.networkLoaded = networkLoaded;
			if (networkLoaded) {
				this.networkService.currentNetwork.subscribe((network) => {
					if (network['uid']) {
						this.network = network;
						this.networkType = network.networkType;
						this.networkLogo = network.logo;
						this.showNetwork = true;

						localStorage.setItem('sub_networks', JSON.stringify(network.channels));
						localStorage.setItem('roles', JSON.stringify(network.roles));
						localStorage.setItem('membership', JSON.stringify(network.membership));
						this.socketIoService.joinChannel(this.network.uid);

						// const status = network?.subscription && network?.subscription?.status ? network?.subscription?.status : null;

						/**
						 * TODO: TO BE REPLACED WITH A GUARD
						 * Check if the current subscription for the network is past_due. If so, then redirect to expired page.
						 * ? UPDATE:- Added additional checks to prevent redirection to expired page when subscription is empty
						 * ! Handle redirections (Must tred carefully)
						 */
						// if (status) {
						// 	switch (status) {
						// 		case 'active':
						// 		case 'trialing':
						// 		case 'incomplete_expired':
						// 		case 'incomplete':
						// 		case 'past_due':
						// 			break;
						// 		default:
						// 			localStorage.setItem('networkExpired', 'true');
						// 			this.router.navigateByUrl('/expired');
						// 	}
						// }

						if (this.network && this.network.services) {
							this.checkIfTodo();
							this.checkIfCustomer();
							this.checkIfIssue();
						}

						if (this.network['privacySettings'] && this.network['membership']['memberType'] === 'normal') {
							this.checkForCustomerSettings(this.network);
						}

						if (this.network['pointsEarning']) {
							this.displayPointLadder = true;
						}

						if (network['membership'] && network['membership']['memberRole'] && network['membership']['memberRole']['role']) {
							const memberRole = network['membership']['memberRole']['role'];
							if (memberRole.slug === 'external') {
								this.isExternalUser = true;
							}
						}
					}
				});
			}
			this.userService.currentUser.subscribe((userData) => {
				if (this.networkLoaded) {
					this.currentUser = userData;
					const that = this;
					if (this.inboxSocket.getSocket().ioSocket.connected) {
						that.inboxSocket.getSocket().emit(
							'set-user-data',
							{
								username: this.currentUser.username,
								networkId: this.network.uid,
							},
							() => {}
						);
					}

					this.inboxSocket.getSocket().on('connect', () => {
						that.inboxSocket.getSocket().emit(
							'set-user-data',
							{
								username: this.currentUser.username,
								networkId: this.network.uid,
							},
							() => {}
						);
					});

					this.inboxSocket.getSocket().on('old-conversations', (oldConversations) => {
						const conversations = oldConversations.result;
						let totalMessageCount = 0;
						conversations.forEach((conv) => {
							if (conv.unreadMessageCount) {
								totalMessageCount += conv.unreadMessageCount;
							}
						});
						that.unreadCount = totalMessageCount;
					});
				}
			});

			// Check if network subscription expired
			/** For handling customer selection in issue create form.*/
			this.networkReady = this.networkService.subscriptionReady.subscribe((subscriptionLoaded) => {
				if (subscriptionLoaded) {
					this.currentNetSub = this.networkService.currentSubscription.subscribe((subscription) => {
						this.currentSubscription = subscription;
						// if (this.currentSubscription['status']) {
						// 	if (this.currentSubscription['status'] !== 'active' && this.currentSubscription['status'] !== 'trialing' && !localStorage.getItem('networkExpired')) {
						// 		localStorage.setItem('networkExpired', 'true');
						// 		this.router.navigateByUrl('/expired');
						// 	}
						// }
						if (
							this.currentSubscription &&
							this.currentSubscription['subscriptionPlan'] &&
							(this.currentSubscription?.subscriptionPlan?.name === 'Premium' || this.currentSubscription?.subscriptionPlan?.name === 'Basic')
						) {
							// 1
							if (subscription && !subscription['purchasedPlan']) {
								// 2
								this.isLocked = false; // 3
							} else {
								this.isLocked = true; // 3
							}
						} else {
							this.isLocked = false; // 4
						}
					});
				}
			});

			/** Handle Socket callback on member-delete event
			 * 1. Check if data on callback contains memberId and its same as current membership id.
			 * 2. Display dialog popup.
			 * 3. Set member-delete in Localstorage
			 * 4. If localstorage contains member-delete,display dialog popup
			 */
			this.socket.getSocket().on('member-delete', (data) => {
				const socketData = JSON.parse(data);
				if (localStorage.getItem('network_id')) {
					if (socketData['memberId'] && socketData['memberId'] === this.network['membershipUid']) {
						// 1
						this.displayDeleteDialog = 'block'; // 2
						localStorage.setItem('member-delete', 'deleted'); // 3
					} else {
						this.displayDeleteDialog = 'none';
					}
				}
			});

			if (localStorage.getItem('member-delete')) {
				// 4
				if (localStorage.getItem('member-delete') === 'deleted') {
					this.displayDeleteDialog = 'block';
				}
			}

			this.apiService.errorReady.subscribe((errorLoaded) => {
				if (errorLoaded) {
					this.apiService.currentError.subscribe(() => {
						this.modelText = 'Session expired,please try again';
						this.displayError = 'block';
					});
				}
			});

			// If notifications count exists,display it in sidebar notifications options
			if (localStorage.getItem('notificationCount')) {
				this.notificationCount = localStorage.getItem('notificationCount');
			} else {
				this.notificationCount = '';
			}
			// Remove zoom authentication from LS for single network login
			if (localStorage.getItem('zoom_authentication')) {
				localStorage.removeItem('zoom_authentication');
			}
		});

		// Close network image upload modal
		// eslint-disable-next-line spellcheck/spell-checker
		this.fileUpload.networkImageUploader.subscribe((data) => {
			if (data) {
				this.networkService.updateNetworkLogo(data, this.network);
				this.model['preview_images'] = [];
				this.imageModal = 'none';
				this.layoutService.hideBd();
			}
		});

		this.currentDate = new Date();
		this.currentDay = this.currentDate.getDate();
		this.currentMonth = this.currentDate.toLocaleString('default', {
			month: 'long',
		});
	}

	ngOnDestroy(): void {
		if (this.router.url !== '/settings' && this.router.url !== '/expired' && !this.router.url.includes('/posts/')) {
			window.removeEventListener('scroll', this.scroll, true);

			// NOTE: I removed for the prevent routing to another module, lazy module loading causing this issue.
			// this.networkService.destroyNetwork();
		}

		if (this.networkReady) {
			this.networkReady.unsubscribe();
		}
		if (this.currentNetSub) {
			this.currentNetSub.unsubscribe();
		}
	}

	public scroll = (): void => {};

	// handleError(error) {
	//   alert("f");
	// }

	public onShowMoreNavClick(): void {
		this.showMoreNav = !this.showMoreNav;
	}

	// controller for pages under 'More'
	private listView(obj): void {
		this.nextUrl = '';
		this.displayManager(obj.tab);
	}

	private displayManager(tab): void {
		this.displayConfig = { checkin: false };
		for (const key of Object.keys(this.displayConfig)) {
			if (key === tab) {
				this.displayConfig[key] = true;
			}
		}
	}

	/**
	 * Function to display network image upload modal
	 */
	private changeNetworkLogo(): void {
		if (this.network && this.network['membership']['memberType'] === 'admin') {
			this.layoutService.showBd('post');
			this.imageModal = 'block';
		}
	}

	private clearTab(): void {
		localStorage.removeItem('tab');
		localStorage.removeItem('detail-tab');
	}

	/**
	 * Function to close image modal
	 */
	public onCloseHandled(): void {
		this.layoutService.hideBd();
		this.imageModal = 'none';
	}

	/**
	 * 1. Close dialog popup.
	 * 2. Check if membership count of user is greater than one.
	 * 3. If true,naviagte to networks list
	 * 4. Else navigate to join flow page
	 */
	public handleMemberDelete(): void {
		this.displayDeleteDialog = 'none'; // 1
		if (localStorage.getItem('member-delete')) {
			localStorage.removeItem('member-delete');
		}
		if (this.currentUser['membershipCount'] > 1) {
			// 2
			this.router.navigateByUrl('/'); // 3
		} else {
			this.router.navigateByUrl('/workspace/welcome'); // 4
		}
	}

	private clearBranch(): void {
		localStorage.removeItem('branchData');
		localStorage.removeItem('branch_session_first');
	}

	private checkForCustomerSettings(network): void {
		if (network && network.privacySettings) {
			if (network.privacySettings['customer']['department']) {
				const fields = network['membership']['memberRole']['fields'];
				const arr = network.privacySettings['customer']['department'];
				if (arr.length > 0 && fields.length > 0) {
					arr.forEach(() => {
						fields.forEach((field) => {
							if (field['key'] === 'department') {
								const val = field['value'];
								const index = arr.findIndex((a) => a === val);
								if (index > -1) {
									this.showCustomers = true;
								} else {
									this.showCustomers = false;
								}
							}
						});
					});
				}
			}
		}
	}

	private checkIfTodo(): void {
		const index = this.network['services'].findIndex((service) => service['slug'] === 'add-task');
		if (index > -1) {
			this.showTodolistOption = true;
		} else {
			this.showTodolistOption = false;
		}
	}

	private checkIfIssue(): void {
		const index = this.network['services'].findIndex((service) => service['slug'] === 'add-issue');
		if (index > -1) {
			this.showReportedIssues = true;
		} else {
			this.showReportedIssues = false;
		}
	}

	private checkIfCustomer(): void {
		const index = this.network['services'].findIndex((service) => service['slug'] === 'add-customer');
		if (index > -1) {
			this.showCustomers = true;
		} else {
			this.showCustomers = false;
		}
	}
}
