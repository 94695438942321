export default class MathUtils {
	/**
	 * Calculate the x of y percent of a number
	 *
	 * @source https://stackoverflow.com/a/48841348/6940144
	 * @param partialValue number
	 * @param totalValue number
	 *
	 * @return number
	 */
	public static getPercentageOf(partialValue: number, totalValue: number): number {
		return Math.floor((100 * partialValue) / totalValue);
	}

	/**
	 * Round a number to a specific number of decimal places
	 *
	 * @source https://stackoverflow.com/a/11832950/6940144
	 * @param value number
	 * @param decimalPlaces number
	 *
	 * @returns number
	 */
	public static roundDecimalPlaces(value: number | string, decimalPlaces: number): number {
		if (typeof value === 'string') {
			value = parseFloat(value);
		}

		if (value > 0) {
			const multiplier = Math.pow(10, decimalPlaces);

			return Math.round(value * multiplier) / multiplier;
		}

		return value;
	}
}
