import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'bee-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
})
export class BeeButtonComponent implements OnInit {
	@Input() public type: 'button' | 'submit' | 'reset' = 'button';
	@Input() public size: 'sm' | 'md' | 'lg' = 'md';
	@Input() public buttonType: 'primary' | 'secondary' = 'primary';
	@Input() public width: 'auto' | 'full' = 'full';

	@Output() public click: EventEmitter<Event> = new EventEmitter<Event>();

	constructor() {}

	ngOnInit(): void {}

	public onClick(event: Event): void {
		this.click.emit(event);
	}
}
