import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { UserService } from './user.service';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private router: Router, private userService: UserService) {}

	canActivate(): Observable<boolean> {
		return this.userService.isAuthenticated.pipe(take(1));
	}
}
