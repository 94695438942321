import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BeeEventPreviewComponent } from './event-preview.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	declarations: [BeeEventPreviewComponent],
	imports: [CommonModule, TranslateModule],
	exports: [BeeEventPreviewComponent],
})
export class EventPreviewModule {}
