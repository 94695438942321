import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-oval-loader',
	templateUrl: './oval-loader.component.html',
	styleUrls: ['./oval-loader.component.css'],
})
export class OvalLoaderComponent implements OnInit {
	@Input() public isLoading = false;
	@Input() public size = '44px';

	constructor() {}

	ngOnInit(): void {}
}
