export enum networkTypes {
	public = 6,
	university = 1,
	enterprise = 5,
	neighborhood = 3,
	clubs = 4,
	school = 0,
	community = 2,
	friends_family = 7,
	work = 8,
	// eslint-disable-next-line spellcheck/spell-checker
	elelction = 9,
}

export class Network {
	public about: '';
	public activeBusinessCount: string;
	public activeDealCount: number;
	public activeMembersCount: number;
	public activeShopCount: number;
	public addMember: boolean;
	public address: '';
	public aggregates: boolean;
	public apiRequestState: boolean;
	public author: any;
	public networkType: any;
	public networkTypeOther: any;
	public cityName: any;
	public country: any;
	public created: string;
	public deniedAccountsCount: '';
	public deniedMembersCount: number;
	public email: string;
	public engagementMessageCount: number;
	public galleryCount: number;
	public latitude: any;
	public longitude: any;
	public logo: string;
	public listLocations: boolean;
	public listVotes: boolean;
	public locations: any;
	public membership: any;
	public missionStatement: '';
	public myRelations: any;
	public name: string;
	// eslint-disable-next-line spellcheck/spell-checker
	public referability: boolean;
	// eslint-disable-next-line spellcheck/spell-checker
	public shareability: boolean;
	public pointsEarning: boolean;
	public shortName: string;
	public state: number;
	public staticLocations: boolean;
	public channels: any;
	public title: '';
	public uid: string;
	public validationValue: any;
	public websiteUrl: '';
	public welcome: boolean;
	public services: any;
	public moreList: any;
	public myGroups: any;
	public otherGroups: any;
	public roles: any;
	public event_categories: any;
	public events: any;
	public channelMembershipList: any;
	public SubscriptionTemplates: any;
	public muted: any;
	public subscription?: any;

	constructor(network: any = false) {
		if (network) {
			for (const key of Object.keys(network)) {
				if (key in network) {
					this[key] = network[key];
				}
			}

			this.moreList = [{ name: 'Channels', url: '/networks/' + this.uid + '/channels/', icon: 'pb-channel', content: 'channel', tab: 'channel' }];

			if (this.services) {
				for (const s of this.services) {
					if (s['slug'] === 'buy-sell') {
						this.moreList.push({
							name: 'Buy & Sell',
							url: '/networks/' + this.uid + '/posts/?type=offer',
							icon: 'pb-tag',
							content: 'classifieds',
							tab: 'buy-sell',
						});
					}
					if (s['slug'] === 'event') {
						this.moreList.push({
							name: 'Events',
							url: '/networks/' + this.uid + '/posts/?tz=5.30&type=event',
							icon: 'pb-calendar',
							content: 'events',
							tab: 'event',
						});
					}
					if (s['slug'] === 'post') {
						this.moreList.push({ name: 'Collections', url: '', icon: 'pb-collection', content: 'Post', tab: 'collections' });
					}
				}

				this.moreList.push({ name: 'Reports', url: '', icon: 'pb-report', content: 'Reports', tab: 'reports' });
			}
			if (this.title) {
				this.moreList.push({ name: this.title, url: '', icon: 'pb-map-marker', content: 'Checkin', tab: 'checkin' });
			}
		}

		this.event_categories = [
			{ key: 'today_event', title: "Today's Events" },
			{ key: 'this_week', title: 'This Week' },
			{ key: 'this_month', title: 'This Month' },
			{ key: 'upcoming', title: 'Upcoming' },
			{ key: 'old_events', title: 'Old events' },
		];

		this.events = {
			today_event: [],
			this_week: [],
			this_month: [],
			upcoming: [],
			old_events: [],
		};
		this.channelMembershipList = [];
	}
}
