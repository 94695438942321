import { TranslateService } from '@ngx-translate/core';
import { ISidebarMenuItem, SidebarMenu } from './../../models/sidebar';
import { IAppResponse } from './../../../../../custom-flows/src/lib/core/models/app';
import { IWorkspace } from './../../../../../../src/app/shared/models/workspace/workspace';
import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NetworkService, PostService, UserService } from '@shared/services';
import { Subscription, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppService } from 'projects/custom-flows/src/lib/core/services/app.service';
import { SidebarService } from 'projects/peer-core/src/lib/services/ui/sidebar.service';
import { InviteTeammatesComponent } from '../invite-teammates/invite-teammates.component';

enum SidebarModules {
	APPLICATION = 'APPLICATION',
	INSIGHTS = 'INSIGHTS',
	BUY_SELL = 'BUY_SELL',
	COLLECTION = 'COLLECTION',
	DIRECTORY = 'DIRECTORY',
	TEAMS = 'TEAMS',
	MY_TASKS = 'MY_TASKS',
	CALENDAR = 'CALENDAR',
	CUSTOM_FLOW = 'CUSTOM_FLOW',
	PROJECT = 'PROJECT',
	AI = 'AI',
	HOME = 'HOME',
	EVENT = 'EVENT',
	PRODUCT = 'PRODUCT',
	CUSTOMER = 'CUSTOMER',
	INTEGRATIONS = 'INTEGRATIONS',
}

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'core-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
	public appName = environment.appName;
	@Input() public workspace: IWorkspace | any;
	@Input() public networkType: any;
	@Input() public isLegacy = false;

	@ViewChild('inviteModal', { static: false })
	public inviteModalComponent: InviteTeammatesComponent;

	// #region Window Sizes

	@HostListener('window:resize', ['$event']) public onWindowResize(): void {
		this.screenWidth = window.innerWidth;
		this.screenHeight = window.innerHeight;
	}

	public screenWidth: any;
	public screenHeight: any;

	// #endregion

	private routerSubscription: Subscription;
	private activatedRouteSubscription: Subscription;

	// #region Menu Variables
	private module: SidebarModules = SidebarModules.HOME;

	public showCustomers = false;
	public showIssues = false;
	public isExternalUser = false;
	public isAppsOpen = false;
	public isMyAppsOpen = false;
	public isMyAppsLoading = true;
	public isDevMode = !environment.production;

	// #endregion

	public currentUser: any;
	public myApps: any[] = [];
	private isWorkspaceLoaded = false;
	private currentPlan: string;
	private currentWorkspaceID = '';

	public isNeaty = environment.appName === 'Neaty';
	public appLogo = environment.logo;
	public appMiniLogo = environment.mini_logo;

	constructor(
		private networkService: NetworkService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private userService: UserService,
		private postService: PostService,
		private appService: AppService,
		private translateService: TranslateService,
		public sidebarService: SidebarService
	) {
		this.routerSubscription = this.router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				if (val.url.match(/\/project\/[0-9a-z-]+/g) !== null) {
					switch (val.url) {
						/**
						 * For checking whether My Tasks page is loaded
						 */
						case '/project/my-tasks':
							this.selectSidebarItem('my_tasks');
							this.module = SidebarModules.MY_TASKS;
							break;
						/**
						 * For checking whether projects page or any of it's children are loaded
						 */
						default:
							this.selectSidebarItem('projects');
							this.module = SidebarModules.PROJECT;
							break;
					}
				} else if (val.url.match(/\/custom-flows\/[0-9a-z-]+/g) !== null) {
					/**
					 * For checking whether Custom-Flows page is loaded
					 */
					this.selectSidebarItem('application');
					this.module = SidebarModules.APPLICATION;
					this.isMyAppsOpen = true;
				}
			}
		});
	}

	ngOnInit(): void {
		// #region Window Sizes
		this.screenWidth = window.innerWidth;
		this.screenHeight = window.innerHeight;
		// #endregion

		/**
		 * For checking whether which page is loaded and select the sidebar menu accordingly.
		 */
		this.activatedRouteSubscription = this.activatedRoute.url.subscribe(() => this.selectSidebarItemBasedOnRoute());

		this.networkService.populate();
		this.networkService.getDepartmentDetails();
		this.networkService.getNetworkSubscriptionDetails();

		this.networkService.networkReady.subscribe((isLoaded: any) => {
			this.isWorkspaceLoaded = isLoaded;

			if (isLoaded) {
				this.networkService.currentNetwork.subscribe((workspace: IWorkspace | any) => {
					if (workspace.uid !== this.currentWorkspaceID) {
						this.currentWorkspaceID = workspace.uid;

						this.workspace = workspace;
						this.currentPlan = workspace?.subscription?.network?.subscription?.subscriptionPlan?.name;

						this.createSidebarMenus();

						this.appService.init(workspace.uid);
						this.getApps();

						localStorage.setItem('sub_networks', JSON.stringify(workspace.channels));
						localStorage.setItem('roles', JSON.stringify(workspace.roles));
						localStorage.setItem('membership', JSON.stringify(workspace.membership));

						if (this.workspace && this.workspace.services) {
							this.showCustomers = this.workspace.services.findIndex((service: any) => service.slug === 'add-customer') > -1;

							this.showIssues = this.workspace.services.findIndex((service: any) => service.slug === 'add-issue') > -1;
						}

						if (this.workspace.privacySettings && this.workspace.membership?.memberType === 'normal') {
							if (workspace && workspace.privacySettings) {
								if (workspace.privacySettings.customer.department) {
									const fields = workspace.membership.memberRole.fields,
										arr = workspace.privacySettings.customer.department;

									if (arr.length > 0 && fields.length > 0) {
										arr.forEach(() => {
											fields.forEach((field) => {
												if (field['key'] === 'department') {
													const val = field['value'],
														index = arr.findIndex((a) => a === val);

													this.showCustomers = index > -1;
												}
											});
										});
									}
								}
							}
						}

						if (workspace.membership && workspace.membership.memberRole && workspace.membership.memberRole.role) {
							const memberRole = workspace.membership.memberRole.role;

							this.isExternalUser = memberRole.slug === 'external';
						}
					}
				});
			}

			this.userService.currentUser.subscribe((userData) => {
				if (this.isWorkspaceLoaded) {
					this.currentUser = userData;
				}
			});
		});

		/**
		 * Handle Custom Flow App Creation and Deletion
		 */
		this.appService.appCreated.subscribe((app: IAppResponse) => this.addCFApp(app));
		this.appService.appDeleted.subscribe((app: IAppResponse) => this.removeCFApp(app));
		this.appService.appEdited.subscribe((app: IAppResponse) => this.editCFApp(app));
	}

	ngOnDestroy(): void {
		this.routerSubscription?.unsubscribe();
		this.activatedRouteSubscription?.unsubscribe();
	}

	public createSidebarMenus(): void {
		if (this.sidebarService.getSidebar().items.length) {
			return;
		}

		this.sidebarService
			.newSidebar()
			// home
			.addMenu(
				new SidebarMenu({
					title: this.translateService.instant('Home'),
					key: 'home',
					icon: 'home',
					route: ['/network'],
					children: [],
					canAccess: true,
					isOpen: this.module === SidebarModules.HOME,

					onClick: (): void => {
						if (this.router.url === '/network') {
							// scroll to top
							window.scrollTo(0, 0);

							// reset posts
							this.postService.resetPosts();

							/**
							 * Refresh entire posts component route
							 */
							this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
								this.router.navigate(['/network']);
							});
						}

						this.selectSidebarItem('home');
					},
				})
			)
			// PeerBie AI
			.addMenu(
				new SidebarMenu({
					title: this.translateService.instant('PeerBie AI',{appName: this.appName}),
					key: 'ai',
					icon: 'ai',
					route: ['/ai'],
					children: [],
					isBeta: true,
					canAccess: this.isDevMode,
					isOpen: this.module === SidebarModules.AI,

					onClick: (): void => {
						this.selectSidebarItem('ai');
					},
				})
			)
			// Projects
			.addMenu(
				new SidebarMenu({
					title: this.translateService.instant('Projects'),
					key: 'projects',
					icon: 'projects',
					route: ['/project/projects'],
					children: [],
					canAccess: true,
					isOpen: this.module === SidebarModules.PROJECT,

					onClick: (): void => {
						this.selectSidebarItem('projects');
					},
				})
			)
			// ! apps [HIDDEN]
			.addMenu(
				new SidebarMenu({
					title: this.translateService.instant('Applications'),
					key: 'applications',
					icon: 'apps',
					route: [],
					children: [
						{
							icon: 'projects',
							title: this.translateService.instant('Projects'),
							route: ['/network/todolist'],
							isLegacy: true,
							canAccess: this.isDevMode,
							onClick: (): void => {
								const menuItem = this.sidebarService.sidebar.items.find((item) => item.title === this.translateService.instant('Applications'));
								menuItem.children[0].isOpen = true;

								this.toggleOtherMenus(menuItem.children, menuItem.children[0]);
							},
						},
						{
							icon: 'projects',
							title: this.translateService.instant('Projects'),
							route: ['/project/projects'],
							canAccess: true,
							onClick: (): void => {
								const menuItem = this.sidebarService.sidebar.items.find((item) => item.title === this.translateService.instant('Applications'));
								menuItem.children[1].isOpen = true;

								this.toggleOtherMenus(menuItem.children, menuItem.children[1]);
							},
						},
						{
							icon: 'products',
							title: this.translateService.instant('Products'),
							route: ['/network/products'],
							canAccess: true,
							onClick: (): void => {
								const menuItem = this.sidebarService.sidebar.items.find((item) => item.title === this.translateService.instant('Applications'));
								menuItem.children[2].isOpen = true;

								this.toggleOtherMenus(menuItem.children, menuItem.children[2]);
							},
						},
						{
							icon: 'customers',
							title: this.translateService.instant('Customers'),
							route: ['/network/customer-states'],
							canAccess: this.workspace?.subscription?.planQuotas?.numberOfCustomers >= 0 ? true : false,
							onClick: (): void => {
								const menuItem = this.sidebarService.sidebar.items.find((item) => item.title === this.translateService.instant('Applications'));
								menuItem.children[3].isOpen = true;

								this.toggleOtherMenus(menuItem.children, menuItem.children[3]);
							},
						},
						{
							icon: 'events',
							title: this.translateService.instant('Events'),
							route: ['/network/event'],
							canAccess: true,
							onClick: (): void => {
								const menuItem = this.sidebarService.sidebar.items.find((item) => item.title === this.translateService.instant('Applications'));
								menuItem.children[4].isOpen = true;

								this.toggleOtherMenus(menuItem.children, menuItem.children[4]);
							},
						},
					],
					canAccess: false,

					onClick: (): void => {
						const menuItem = this.getMenuItem('applications');
						menuItem.isOpen = !menuItem.isOpen;

						this.toggleOtherMenus(this.sidebarService.sidebar.items, menuItem);

						if (!menuItem.isOpen) {
							this.selectSidebarItemBasedOnRoute();
						}

						this.sidebarService.setSidebarCollapseStatus(false);
					},
				})
			)
			// ! custom flows [HIDDEN]
			.addMenu(
				new SidebarMenu({
					title: this.translateService.instant('Custom Flows'),
					key: 'custom_flows',
					icon: 'custom-flows',
					route: ['/custom-flows'],
					children: [],
					isBeta: true,
					canAccess: false,
					isOpen: this.module === SidebarModules.CUSTOM_FLOW,

					onClick: (): void => {
						this.selectSidebarItem('custom_flows');
					},
				})
			)
			// ! calendar [HIDDEN]
			.addMenu(
				new SidebarMenu({
					title: this.translateService.instant('My Calendar'),
					key: 'calendar',
					icon: 'calendar',
					route: ['/network/calendar'],
					children: [],
					canAccess: false,
					isOpen: this.module === SidebarModules.CALENDAR,

					onClick: (): void => {
						this.selectSidebarItem('calendar');
					},
				})
			)
			// my tasks
			.addMenu(
				new SidebarMenu({
					title: this.translateService.instant('sidebar_my_tasks'),
					key: 'my_tasks',
					icon: 'my-tasks',
					route: ['/project/my-tasks'],
					children: [],
					canAccess: true,
					isOpen: this.module === SidebarModules.MY_TASKS,

					onClick: (): void => {
						this.selectSidebarItem('my_tasks');
					},
				})
			)
			// teams
			.addMenu(
				new SidebarMenu({
					title: this.translateService.instant('Teams'),
					key: 'teams',
					icon: 'teams',
					route: ['/network/channel'],
					children: [],
					canAccess: true,
					isOpen: this.module === SidebarModules.TEAMS,

					onClick: (): void => {
						this.selectSidebarItem('teams');
					},
				})
			)
			// directory
			.addMenu(
				new SidebarMenu({
					title: this.translateService.instant('Directory'),
					key: 'directory',
					icon: 'directory',
					route: ['/member/members'],
					children: [],
					canAccess: true,
					isOpen: this.module === SidebarModules.DIRECTORY,

					onClick: (): void => {
						this.selectSidebarItem('directory');
					},
				})
			)
			// collections
			.addMenu(
				new SidebarMenu({
					title: this.translateService.instant('Collections'),
					key: 'collections',
					icon: 'collections',
					route: ['/network/collections'],
					children: [],
					canAccess: true,
					isOpen: this.module === SidebarModules.COLLECTION,

					onClick: (): void => {
						this.selectSidebarItem('collections');
					},
				})
			)
			// ? buy and sell
			.addMenu(
				new SidebarMenu({
					title: this.translateService.instant('Buy & Sell'),
					key: 'buy_sell',
					icon: 'insights',
					route: ['/network/buy-sell'],
					children: [],
					canAccess: this.workspace.moreList?.filter((item: any) => item.name === 'Buy & Sell').length,
					isOpen: this.module === SidebarModules.BUY_SELL,

					onClick: (): void => {
						this.selectSidebarItem('buy_sell');
					},
				})
			)
			// insights
			.addMenu(
				new SidebarMenu({
					title: this.translateService.instant('Insights'),
					key: 'insights',
					icon: 'insights',
					route: ['/network/reports'],
					children: [],
					canAccess: true,
					isOpen: this.module === SidebarModules.INSIGHTS,

					onClick: (): void => {
						this.selectSidebarItem('insights');
					},
				})
			)
			// integrations
			// .addMenu(
			// 	new SidebarMenu({
			// 		title: this.translateService.instant('Integrations'),
			// 		key: 'integrations',
			// 		icon: 'insights',
			// 		route: ['/integrations'],
			// 		children: [],
			// 		canAccess: true,
			// 		isOpen: this.module === SidebarModules.INTEGRATIONS,

			// 		onClick: (): void => {
			// 			this.selectSidebarItem('integrations');
			// 		},
			// 	})
			// )

			// my apps (children will be added later with getApps method)
			.addMenu(
				new SidebarMenu({
					title: this.translateService.instant('Applications'),
					key: 'application',
					icon: 'apps',
					route: null,
					children: [],
					canAccess: true,
					// isOpen: this.module === SidebarModules.APPLICATION,
					isOpen: true,

					onClick: (): void => {
						const menuItem = this.getMenuItem('application');
						menuItem.isOpen = !menuItem.isOpen;

						this.toggleOtherMenus(this.sidebarService.sidebar.items, menuItem);
						if (!menuItem.isOpen) {
							this.selectSidebarItemBasedOnRoute();
						}

						// this.sidebarService.setSidebarCollapseStatus(false);
					},
				})
			);
	}

	// #region CustomFlows

	private getApps(): void {
		this.isMyAppsLoading = true;

		this.appService
			.getAll()
			.subscribe((response: any) => {
				this.myApps = response.objects;
				const menuItem = this.getMenuItem('application');
				menuItem.children = [];
				this.myApps?.forEach((myApp: IAppResponse) => {
					if (!menuItem.children.find((child) => child.title === myApp.title)) {
						menuItem.children.push({
							icon: myApp?.icon?.thumbnails[120] ?? myApp?.icon?.url ?? '/assets/images/app-icons/icon-1.svg',
							color: myApp.colour,
							title: myApp.title,
							route: ['/custom-flows/apps/', myApp.uid],
							canAccess: true,
							key: myApp.uid,
						});
					}
				});

				if (response.count === 0) {
					menuItem.route = ['/custom-flows/start'];
				}
			})
			.add(() => {
				timer(500).subscribe(() => (this.isMyAppsLoading = false));
			});
	}

	/**
	 * Fix for redirecting to custom-flows creation page on + button click
	 * @param event Click Event
	 */
	public redirectToCustomFlows(event: MouseEvent): void {
		event.preventDefault();
		event.stopPropagation();
		this.router.navigate(['/custom-flows/start']);
	}

	// #endregion

	/**
	 * Select Currently loaded sidebar item according to route
	 */
	private selectSidebarItemBasedOnRoute(): void {
		switch (this.activatedRoute?.snapshot?.children?.[0]?.routeConfig?.path) {
			case '':
				this.selectSidebarItem('home');
				this.module = SidebarModules.HOME;
				break;
			case 'calendar':
				this.selectSidebarItem('calendar');
				this.module = SidebarModules.CALENDAR;
				break;
			case 'products':
			case 'product-detail':
			case 'issue-detail':
			case 'archive-products':
				this.selectSidebarItem('applications');
				this.module = SidebarModules.PRODUCT;
				break;
			case 'customers':
			case 'customer-states':
			case 'customer/:id':
				this.selectSidebarItem('applications');
				this.module = SidebarModules.CUSTOMER;
				break;
			case 'event':
				this.selectSidebarItem('applications');
				this.module = SidebarModules.EVENT;
				break;
			case 'channel':
				this.selectSidebarItem('teams');
				this.module = SidebarModules.TEAMS;
				break;
			case 'members':
				this.selectSidebarItem('directory');
				this.module = SidebarModules.DIRECTORY;
				break;
			case 'collections':
			case 'archive-collections':
				this.selectSidebarItem('collections');
				this.module = SidebarModules.COLLECTION;
				break;
			case 'buy-sell':
				this.selectSidebarItem('buy_sell');
				this.module = SidebarModules.BUY_SELL;
				break;
			case 'reports':
				this.selectSidebarItem('insights');
				this.module = SidebarModules.INSIGHTS;
				break;
			case 'threads':
				this.selectSidebarItem('ai');
				this.module = SidebarModules.AI;
				break;
		}
	}

	/**
	 * Handle sidebar menu item selection
	 * @param item Selected menu item
	 */
	private selectSidebarItem(item: string): void {
		const sidebarItem = this.getMenuItem(item);
		if (sidebarItem && !sidebarItem.isOpen) {
			sidebarItem.isOpen = true;
			this.toggleOtherMenus(this.sidebarService.sidebar.items, sidebarItem);
		}
	}

	/**
	 * Function to get a particular MenuItem
	 * @param title MenuItem key
	 * @returns MenuItem
	 */
	private getMenuItem(key: string): ISidebarMenuItem | null {
		const itemIndex = this.sidebarService.sidebar.items.findIndex((item: ISidebarMenuItem) => item.key === key);
		if (itemIndex > -1) {
			return this.sidebarService.sidebar.items[itemIndex];
		}
		return null;
	}

	/**
	 * Un-select all other menu items
	 * @param parentItems Array of SidebarMenuItems
	 * @param menuItem Selected SidebarMenuItem
	 */
	private toggleOtherMenus(parentItems: ISidebarMenuItem[], menuItem: ISidebarMenuItem): void {
		parentItems.forEach((item: ISidebarMenuItem) => {
			if (item.isOpen && menuItem.title !== item.title) {
				item.isOpen = false;
			}
		});
	}

	/**
	 * Invite member via link
	 *
	 * @returns void
	 */
	public inviteMemberViaLink(): void {
		this.inviteModalComponent?.showInviteModal();
	}

	public openHelpCenter(): void {
		window.open(environment.appURL + '/learn-center', '_blank');
	}

	/**
	 * Function to clear local storage on network leaving
	 *
	 * 1. This will redirect single network user inside network.
	 * 2. This will redirect user outside network
	 */
	public onLeaveNetwork(): void {
		if (!environment.single_workspace) {
			if (localStorage.getItem('membershipCount')) {
				const count = localStorage.getItem('membershipCount'),
					n = Number(count);

				if (n === 1) {
					// 1
					this.router.navigateByUrl('/network');
				}

				if (n > 1) {
					// 2
					this.router.navigateByUrl('/home');

					if (localStorage.getItem('network_id')) {
						window.localStorage.removeItem('network_id');
					}

					if (localStorage.getItem('notificationCount')) {
						window.localStorage.removeItem('notificationCount');
					}

					if (localStorage.getItem('MyTodolist')) {
						window.localStorage.removeItem('MyTodolist');
					}

					if (localStorage.getItem('networkExpired')) {
						localStorage.removeItem('networkExpired');
					}

					if (localStorage.getItem('purchasePlans')) {
						localStorage.removeItem('purchasePlans');
					}

					if (localStorage.getItem('welcome-tips')) {
						localStorage.removeItem('welcome-tips');
					}

					localStorage.removeItem('favoriteId');
					localStorage.removeItem('status');

					this.networkService.clearSubscriptions();
				}
			}

			if (this.workspace) {
				this.workspace.uid = null;
			}
		}
	}

	// #region Custom Flow App Changes

	/**
	 * To handle new CF app creation reflecting in sidebar
	 * @param app Created App
	 */
	private addCFApp(app: IAppResponse): void {
		const menuItem = this.getMenuItem('application'),
			index = menuItem?.children?.findIndex((child: ISidebarMenuItem) => child.key === app.uid);
		if (index === -1) {
			menuItem.children.unshift({
				icon: app?.icon?.thumbnails[120] ?? app?.icon?.url ?? '/assets/images/app-icons/icon-1.svg',
				color: app.colour,
				title: app.title,
				route: ['/custom-flows/apps/', app.uid],
				canAccess: true,
				key: app.uid,
			});

			menuItem.route = null;
		}
	}

	/**
	 * To handle new CF app deletion reflecting in sidebar
	 * @param app App to be removed
	 */
	private removeCFApp(app: IAppResponse): void {
		const menuItem = this.getMenuItem('application');
		menuItem.children = menuItem.children.filter((child: ISidebarMenuItem) => child.key !== app.uid);

		if (menuItem.children.length === 0) {
			menuItem.route = ['/custom-flows/start'];
		}
	}

	private editCFApp(app: IAppResponse): void {
		console.log(app);
		const menuItem = this.getMenuItem('application'),
			index = menuItem?.children?.findIndex((child: ISidebarMenuItem) => child.key === app.uid);
		if (index > -1) {
			menuItem.children[index].color = app.colour;
			menuItem.children[index].title = app.title;
			menuItem.children[index].icon = app?.icon?.thumbnails[120] ?? app?.icon?.url ?? '/assets/images/app-icons/icon-1.svg';
		}
	}

	// #endregion
}
