/* eslint-disable spellcheck/spell-checker */
/* eslint-disable max-len */
import { version } from './version';

export const environment = {
	version: version.number,
	appName: 'Neaty',
	primaryColor: '#927857',
	logo: '/assets/logos/Neaty/logo.png',
	mini_logo: '/assets/logos/Neaty/mini-logo.png',
	single_workspace: false,
	production: false,
	local: false,
	appSecret:
		'45m2c4ty7pbuq0g70i26qevvmy1bf3jlxzawsld0cagu4vuru1448pov37sxhp551c4t25w9fjs0xjvgclqx1etbqpembcbm4v2i4wn5dv1zrxgq1bgf94sg7aacvbbf7o5f7plrx6hu0sk69pmuac1mjpo90zm5ds7cf6f5en97j87fgc7liiome6dc3eiifpzgyi6m2g4i5mnaw6ews4ueaj0edzfrthf3d4xrj5upu37jreehl9bdz90812mggxyydrcjgtn2l5mpra5qutr4p7qgi45w1smvsb8tghj04idjrx5lit546zb96h9gd0g21sieon4s9kqpj2r6myzcujek7h5aqkoci66cfjslo9zcv4ng6yvnqhdthjvs9egbwryyjmuiltbg0edsatrcgq3wwsrgf7x26uxge4zipc2evi25ch8e068tkmpumi4g6kow9bitbhohmbp41c9jbdmgycph4bug7qc7ttnjscs7svr7',

	api_url: 'https://neaty-api.peerbie.com/alpha',
	baseApiUrl: 'https://neaty-api.peerbie.com',
	layer_url: 'layer:///apps/production/ff393218-af36-11e5-af04-8c32060070bc',
	google_api_key: 'AIzaSyClFWsjP1WEFmKVFR1HtL4WBCFphEsg7co',
	soketio_url: 'https://neaty-socketio.peerbie.com',
	reset_password_url: 'https://neaty-api.peerbie.com/alpha/accounts/password/reset/',
	socketio_chat_url: 'https://neaty-chat.peerbie.com?token=d6cb9900d2a22ae725786c029c5758d7ae818095a3402250857e6cbd61c9352d',
	chat_url: 'https://neaty-chat.peerbie.com/users',
	zoom_oauth_url: 'https://zoom.us/oauth/authorize?response_type=code&client_id=qo3tdCn3QOy15qk3gExBOQ&redirect_uri=https%3A%2F%2Fmy.peerbie.com%2Fzoom-auth',
	googleAuthUrl:
		'https://accounts.google.com/o/oauth2/auth?access_type=offline&client_id=799824477656-jqj5s16vqlcv91nd0c3sh3orohhbfpee.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fmy.peerbie.com%2Foauth&response_type=code&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fadmin.directory.user',
	azure_auth_url:
		'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=69ea8cde-afc9-44a6-8e2f-98822b061c37&response_type=code&redirect_uri=https%3A%2F%2Fmy.peerbie.com%2Foauth&scope=https%3A%2F%2Fgraph.microsoft.com%2FDirectory.Read.All+offline_access+openid+profile',
	saml_redirect_url: 'https://neaty-api.peerbie.com/complete/saml/',
	sso_login_url: 'https://neaty-api.peerbie.com/login/saml/',
	branch_key: 'key_live_oxlHUtVrLK3QfTbflv4XChopxtko7uJy',
	branch_key_dict: {},
	firebase: {
		apiKey: 'AIzaSyBbgEb4-gDJeAnPQhS4QMDdsjzSdcE6cuQ',
		authDomain: 'peerbie-89383.firebaseapp.com',
		databaseURL: 'https://peerbie-89383.firebaseio.com',
		projectId: 'peerbie-89383',
		storageBucket: 'peerbie-89383.appspot.com',
		messagingSenderId: '688684896083',
		appId: '1:688684896083:web:8b399282f446c458909ba6',
	},
	appURL: 'https://my.neaty.io',
};
