import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, ElementRef, OnDestroy, AfterViewInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { CommentsService, UserService, PostService, LayoutService, GeoLocationService, LocationService } from '../../../services';
import { Comment, LocationObj, Post } from '../../../models';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';

const CSS_CLASS_NAMES = {
	highLight: 'dd-highlight-item',
};

@Component({
	selector: 'app-post-comment',
	templateUrl: './post-comment.component.html',
	styleUrls: ['./post-comment.component.css'],
})
export class PostCommentComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
	@ViewChild('textFocus', { static: false }) public textFocus: ElementRef;
	@ViewChild('panel', { static: false }) public panel: ElementRef<any>;

	@Input() public post: any;
	@Input() public comment: any;
	@Input() public type: any;
	@Input() public members: any[] = [];
	@Input() public isLastComment: false;
	@Input() public isTodo = false;

	@Output() public newCommentCreatedEmitter: EventEmitter<any> = new EventEmitter();
	@Output() public replyCommentGenerated: EventEmitter<any> = new EventEmitter();

	public currentUser: any;
	public errors: any;
	public location_display = 'none';
	public locations: any[] = [];
	public currentLocation: any;
	public errorMessage = '';
	public commentText = this.translate.instant('Reply');
	public userSuggestionString = '';
	public hashSuggestionString = '';
	public fromTextArea = false;
	public hashTagSearch = '';
	public userSearch = '';
	public nextUserListUrl = '';
	public selectedLocation;
	public issuePage = false;
	public replyCommentSubscription;
	public parentComment;
	public newComment = new Comment({ text: '', isLink: false, url: '', images: [] }, true);
	public showHashTags = false;
	public showMembers = false;
	public newImage = false;
	public showComments = false;
	public isLoading = false;
	public searchLocation = '';
	public isOffline = false;
	public fileType = [];

	// New user mentions
	public shiftKeyUsed = false;
	public mentionIndex;

	private arrowKeyLocation = -1;
	private keyupSubscription: Subscription;
	public isMentions = false;
	public mentionCount = 0;
	public mentionType;
	public userMentions = [];
	public hashMentions = [];
	public nextMentionUrl;
	public mentionPrivacy: any;
	public CaretPosition: any;
	private subject: Subject<string> = new Subject();
	public mentionsFetched = 0;
	public userRegexFailed = false;
	public hashRegexFailed = false;
	public isCustomer = false;
	public commentType;
	public commentCreateType: 'masterComment' | 'childComment' = 'masterComment';
	public model = new Post(
		{
			title: '',
			body: '',
			category: null,
			privacy: '',
			images: [],
			preview_images: [],
			shareable: true,
			commentable: true,
			channel: '',
		},
		true
	);

	isTableItemComment: boolean = false;

	constructor(
		private commentService: CommentsService,
		private userService: UserService,
		private postService: PostService,
		private translate: TranslateService,
		private router: Router,
		public layoutService: LayoutService,
		private geoLocationService: GeoLocationService,
		private locationService: LocationService,
		private ref: ChangeDetectorRef,
		private pageLoadingUiService: PageLoadingUiService
	) {}

	ngOnInit(): void {
		const href = window.location.href;

		if (href.includes('issue-detail')) {
			this.issuePage = true;
		} else if (href.includes('customer')) {
			this.isCustomer = true;
		} else if (href.includes('custom-flows')) {
			this.isTableItemComment = true;
		}

		this.userService.currentUser.subscribe((user) => {
			this.currentUser = user;
		});

		if (this.type === 'reply-comment') {
			this.initializeReplyComment(this.comment);
		}

		window.addEventListener('online', () => {
			this.isOffline = false;
		});

		/**
		 *Function to detect clicks outside the drop down.
		 */
		this.layoutService.clickOutside.subscribe((data) => {
			if (
				data['srcElement'].className === 'dropdown pull-right user-suggestion' ||
				(data['srcElement'].attributes.id &&
					(data['srcElement'].attributes.id.nodeValue === 'hash-search' || data['srcElement'].attributes.id.nodeValue === 'hash-view-more'))
			) {
				if (this.post.showHashTags) {
					this.post.showHashTags = true;
				}

				if (this.post.showMembers) {
					this.post.showMembers = true;
				}
			} else {
				this.post.showHashTags = false;
				this.post.showMembers = false;
				this.fromTextArea = false;

				this.userMentions = [];
				this.hashMentions = [];
				this.mentionsFetched = 0;
			}
		});

		/** For handling @/# mentions searches
		 * 1. Call api for getting search results
		 * 2. If mention type is user,fill userMentions array
		 * 3. Else fill hashMentions array
		 * 4. If nextUrl exists,set nextUrl and call paginate function
		 */
		this.subject.pipe(debounceTime(400)).subscribe((searchTextValue) => {
			// 1
			const i = searchTextValue.indexOf('@');

			if (i > -1) {
				searchTextValue = searchTextValue.substring(i + 1);
			}

			this.mentionsFetched = 1;

			this.postService.getMentions(searchTextValue, this.mentionType, this.mentionPrivacy).subscribe((data) => {
				this.mentionsFetched = 0;
				this.arrowKeyLocation = -1;
				this.mentionCount = data.count;
				// =======[08-06-21,Mention Fixes]
				if ((this.newComment.text && !this.isMentions) || (!this.newComment.text && this.isMentions) || (this.newComment.text && this.isMentions)) {
					if (!this.userRegexFailed || !this.hashRegexFailed) {
						if (this.mentionType === 'user') {
							// 2
							this.hashMentions = [];
							this.userMentions = data.objects;
						} else if (this.mentionType === 'hash') {
							this.userMentions = []; // 3
							const filterData = [];
							data.objects.forEach((item: any) => {
								if (item.name !== '') {
									filterData.push(item);
								}
							});
							this.hashMentions = filterData;
						}
						if (data.next) {
							// 4
							this.nextMentionUrl = data.next.split('alpha')[1];
							this.paginateMentions();
						} else {
							this.nextMentionUrl = '';
						}
					}
				} else {
				}
				// ==============
			});
		});
	}

	ngOnChanges(): void {}

	ngAfterViewInit(): void {
		if (this.type === 'reply-comment') {
			this.textFocus.nativeElement.focus();
			this.showComments = true;
		} else if (this.type === 'edit-comment') {
			this.textFocus.nativeElement.focus();
			this.showComments = true;
			this.newComment.text = this.comment.text;
			this.newComment['mentioned_users'] = this.comment.mentioned_users;
			this.newComment['hashtags'] = this.comment.hashtags;
		}
	}

	ngOnDestroy(): void {
		if (this.replyCommentSubscription) {
			this.replyCommentSubscription.unsubscribe();
		}
	}

	/**
	 * Initialize reply comment.
	 */
	private initializeReplyComment(data): void {
		// this.onMemberSelect(data);
		this.parentComment = data;
	}

	/**
	 * Create new comment on post
	 *
	 * @param event any
	 * @param isClick boolean
	 */
	public createComment(): void {
		if (window.navigator.onLine) {
			this.isOffline = false;
			this.shiftKeyUsed = false;

			if (this.newComment.text.trim() || (this.model.preview_images && this.model.preview_images.length > 0) || this.newComment['url'] || this.newComment['location']) {
				if (this.newComment['mentioned_users'] && this.newComment['mentioned_users'].length > 0) {
					this.newComment['mentioned_users'] = this.newComment['mentioned_users'].map(function (user) {
						if (user && user.username) {
							return user.username;
						}
					});

					this.newComment['mentioned_users'] = this.newComment['mentioned_users'].join(',');
				}

				// Check for hash tag
				if (this.newComment['hashtags'] && this.newComment['hashtags'].length > 0) {
					this.newComment['hashtags'] = this.newComment['hashtags']
						.map((hash: string) => (hash && hash['name'] ? hash['name'] : null))
						.filter((hash: string) => hash && hash !== null);

					this.newComment['hashtags'] = Array.isArray(this.newComment['hashtags']) && this.newComment['hashtags'].length ? this.newComment['hashtags'].join(',') : null;
				}

				this.newComment.contentUid = this.post.uid;
				delete this.newComment['preview_images'];

				if (this.parentComment) {
					this.newComment['parent'] = this.parentComment.uid;
				}

				if (this.newComment['attachmentType'] === 'photo') {
					if (this.model.preview_images.length > 0) {
						this.model.preview_images.forEach((img) => {
							if (img['uid']) {
								this.newComment['images'] = img['uid'];
							}
						});
					}

					delete this.newComment['url'];
					delete this.newComment['location'];
				} else if (this.newComment['attachmentType'] === 'url') {
					delete this.newComment['location'];
					delete this.newComment['images'];
					if (!this.newComment['url']) {
						delete this.newComment['url'];
						delete this.newComment['attachmentType'];
					}
				} else if (this.newComment['attachmentType'] === 'doc') {
					delete this.newComment['location'];
					delete this.newComment['images'];
					delete this.newComment['url'];
					this.newComment['doc'] = this.model.images.join('|');
				} else if (this.newComment['attachmentType'] === 'location') {
					delete this.newComment['url'];
					delete this.newComment['images'];
					if (!this.newComment['location']) {
						delete this.newComment['location'];
						delete this.newComment['attachmentType'];
					}
				} else {
					delete this.newComment['attachmentType'];
					delete this.newComment['url'];
					delete this.newComment['images'];
					delete this.newComment['location'];
				}
				delete this.newComment['isLink'];

				if (this.issuePage) {
					this.newComment['contentType'] = 'issue';
				} else if (this.isCustomer) {
					this.newComment['contentType'] = 'customer';
				} else if (this.isTodo) {
					this.newComment['contentType'] = 'todo';
				} else if (this.isTableItemComment) {
					this.newComment['contentType'] = 'tableitem';
				} else {
					this.newComment['contentType'] = 'post';
				}

				this.handleHashtags(this.newComment.text);
				const newComment = this.newComment;
				this.resetNewComment();
				this.commentService.isCommentTyped = false;
				this.pageLoadingUiService.setPageLoading(true);

				if (newComment.text || newComment['attachmentType']) {
					if (this.type !== 'edit-comment') {
						this.commentService.create(newComment, this.currentUser.username).subscribe(
							(data) => {
								this.pageLoadingUiService.setPageLoading(false);

								if (this.comment) {
									this.comment['replyPost'] = false;
								}

								if (this.type === 'reply-comment') {
									this.replyCommentGenerated.emit();
								} else {
									if (this.post.comments) {
										this.post.comments.unshift(new Comment(data));
									} else {
										this.post.comments = [new Comment(data)];
									}
								}
								if (this.post['lastComments']) {
									this.post['lastComments'] = new Comment(data);
								} else {
									this.post['lastComments'] = {};
									this.post['lastComments'] = new Comment(data);
								}
								if (this.post['commentCount']) {
									this.post['commentCount'] = +this.post['commentCount'] + 1;
								} else {
									this.post['commentCount'] = 1;
								}

								if (this.type !== 'reply-comment') {
									if (this.post['commentCount']) {
										this.post['totalMasterCommentCount'] = this.post['totalMasterCommentCount'] + 1;
									} else {
										this.post['totalMasterCommentCount'] = 1;
									}
								}

								if (this.parentComment) {
									this.parentComment = null;
								}

								this.post['showComments'] = false;
								this.checkUserMentionAndHashtagAndAlterCommentText();
								this.alterComment(this.post['lastComments'], true);

								if (this.post['lastComments'].parent) {
									this.alterComment(this.post['lastComments'].parent, true);
								}

								this.textFocus.nativeElement.style.height = 'auto';
								delete this.newComment['doc'];
								this.model['preview_images'] = [];
								this.model.images = [];
								delete this.newComment['images'];

								this.ref.detectChanges();
							},
							(err) => {
								this.pageLoadingUiService.setPageLoading(false);
								this.newComment = newComment;
								this.errors = err;
							}
						);

						this.newCommentCreatedEmitter.emit();
					} else {
						this.commentService.updateComment(newComment, this.currentUser.username, this.comment.uid).subscribe(
							(data) => {
								this.pageLoadingUiService.setPageLoading(false);
								this.comment['replyPost'] = false;
								this.comment['editComment'] = false;
								const newData = this.alterComment(new Comment(data), this.isLastComment);
								this.comment['text'] = newData['text'];
								this.comment['alteredText'] = newData['alteredText'];
								this.comment['minAlteredText'] = newData['minAlteredText'];
								this.comment['showMinComment'] = false;
								this.comment['attachmentDetails'] = newData['attachmentDetails'];

								if (newData['hashtags']) {
									this.comment['hashtags'] = newData['hashtags'];
								}

								if (newData['mentioned_users']) {
									this.comment['mentioned_users'] = newData['mentioned_users'];
								}

								this.post['showComments'] = false;
								this.textFocus.nativeElement.style.height = 'auto';

								delete this.newComment['doc'];

								this.model['preview_images'] = [];
								this.model.images = [];
								delete this.newComment['images'];
							},
							(err) => {
								this.pageLoadingUiService.setPageLoading(false);
								this.newComment = newComment;
								this.errors = err;
							}
						);
					}
				}
			}
		} else {
			this.isOffline = true;
		}
	}

	public handleHashtags(text): void {
		if (text.indexOf('#') > -1) {
			const words = text.split(' ');
			const hashes = [];
			words.forEach((element) => {
				if (element.indexOf('#') > -1) {
					const index = element.indexOf('#');
					if (index === 0) {
						const string = element.replace(/(^#)/g, '');
						if (hashes.indexOf(string) === -1) {
							hashes.push(string);
						}
					}
				}
			});
			if (hashes.length > 0) {
				this.newComment['hashtags'] = hashes.join(',');
			}
		}
	}

	/*
	 *Function to reset new comment.
	 */
	public resetNewComment(): void {
		this.newComment = new Comment({ text: '', isLink: false, url: '', images: [] }, true);
	}

	/**
	 * Function to check the user mention and hash tag in post list if exists change the comment text
	 */
	private checkUserMentionAndHashtagAndAlterCommentText(): void {
		this.post.comments.forEach((comment) => {
			if (comment) {
				this.alterComment(comment);
			}
		});
	}

	/**
	 *
	 * @param comment
	 * @param isLastComment
	 *
	 * 1. Check if current page is customer/todo detail
	 * 2. Set comment type for hashtags
	 * 3. Set hash tag for customer/todo comments for redirections to customers/todos hashtags page
	 * 4. If string contains '<',then its cut off while displaying in innerHtml.Hence its replaced by '< '
	 */
	private alterComment(comment, isLastComment = false): void {
		if (this.router.url.indexOf('/network/customer/') > -1) {
			// 1

			this.commentType = 'customer'; // 2
		} else if (this.router.url.indexOf('/project/task-detail/') > -1) {
			this.commentType = 'todo'; // 2
		} else if (this.router.url.indexOf('/network/issue-detail?') > -1) {
			this.commentType = 'issue';
		}

		if (comment.text && comment.text.indexOf('<') > -1) {
			// 4
			comment.text = comment.text.replace(/</g, '< ');
		}

		comment.alteredText = comment.text;

		if (isLastComment) {
			comment.minAlteredText = comment.text;
		} else {
			comment.minAlteredText = '';
		}

		comment.showMinComment = false;

		if (comment.alteredText && comment.alteredText.length > 150) {
			comment.minAlteredText = comment.alteredText.slice(0, 150) + '...';
			comment.showMinComment = true;
		}

		if (comment['mentionedUsers']) {
			comment['mentionedUsers'].forEach((mentionedUser) => {
				// const userMention = '@' + mentionedUser['name'];
				const html =
					'<a #userMention class="text-green text-bold" href="/network/profile/' +
					mentionedUser?.memberId +
					'?username=' +
					mentionedUser?.username +
					'">@' +
					mentionedUser['name'] +
					'</a>';
				comment.alteredText = comment.alteredText.replace('@' + mentionedUser['name'], html);
				comment.minAlteredText = comment.minAlteredText.replace('@' + mentionedUser['name'], html);
			});
		}

		if (comment['hashtags']) {
			comment['hashtags'].forEach((hashtag) => {
				if (hashtag['name']) {
					if (this.commentType === 'customer') {
						// 3
						const hashtagName = hashtag['name'];
						// const hashtagUid = hashtag['uid'];
						const html = '<b #hashTag><a class="hash-customer">#' + hashtagName + '</a></b>';
						comment.alteredText = comment.alteredText.replace('#' + hashtag['name'], html);
						comment.minAlteredText = comment.minAlteredText.replace('#' + hashtag['name'], html);
					} else if (this.commentType === 'todo') {
						// 3
						const hashtagName = hashtag['name'];
						// const hashtagUid = hashtag['uid'];
						const html = '<b #hashTag><a class="hash-todo">#' + hashtagName + '</a></b>';
						comment.alteredText = comment.alteredText.replace('#' + hashtag['name'], html);
						comment.minAlteredText = comment.minAlteredText.replace('#' + hashtag['name'], html);
					} else if (this.commentType === 'issue') {
						// 3
						const hashtagName = hashtag['name'];
						// const hashtagUid = hashtag['uid'];
						const html = '<b #hashTag><a class="hash-issue">#' + hashtagName + '</a></b>';
						comment.alteredText = comment.alteredText.replace('#' + hashtag['name'], html);
						comment.minAlteredText = comment.minAlteredText.replace('#' + hashtag['name'], html);
					} else {
						const hashtagName = hashtag['name'];
						// const hashtagUid = hashtag['uid'];
						const html = '<b #hashTag><a class="hash-post">#' + hashtagName + '</a></b>';
						comment.alteredText = comment.alteredText.replace('#' + hashtag['name'], html);
						comment.minAlteredText = comment.minAlteredText.replace('#' + hashtag['name'], html);
					}
				}
			});
		}

		const urlRegex =
			/(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;

		if (comment.alteredText) {
			const strings = comment.text.match(urlRegex);

			if (strings && strings.length > 0) {
				strings.forEach((element) => {
					let url = element;
					if (!(element.indexOf('http://') === 0 || element.indexOf('https://') === 0 || element.indexOf('ftp://') === 0)) {
						url = 'http://' + element;
					}
					const html = '<a target="blank" class="pb-text-link" href="' + url + '">' + element + '</a>';
					if (comment.alteredText) {
						comment.alteredText = comment.alteredText.replace(element, html);
						comment.minAlteredText = comment.minAlteredText.replace(element, html);
					}
				});
			}
		}

		if (comment.parent) {
			// eslint-disable-next-line spellcheck/spell-checker
			comment.alteredText = `<span class="text-reply cursor-pointer">${this.commentText}&nbsp;@${comment.parent.user.name}</span>&nbsp;${comment.alteredText}`;
			// eslint-disable-next-line spellcheck/spell-checker
			comment.minAlteredText = `<span class="text-reply cursor-pointer">${this.commentText}&nbsp;@${comment.parent.user.name}</span>&nbsp;${comment.minAlteredText}`;
		}

		return comment;
	}

	/**
	 * 1.If image upload option is already selected,reset isCommentTyped and isLink
	 * 2.Set is link true and isCommentTyped true for handling 'new-post' in socket
	 */
	public addUrlBox(): void {
		this.newComment['attachmentType'] = '';
		if (this.newComment.isLink) {
			// 1
			this.commentService.isCommentTyped = false;
			this.newComment.isLink = false;
			this.newComment['attachmentType'] = '';
		} else {
			this.commentService.isCommentTyped = true; // 2
			this.newComment.isLink = true;
			this.newComment['attachmentType'] = 'url';
		}
	}

	/**
	 * 1.If location upload option is selected,set isCommentTyped true for handling 'new-post' in socket
	 */
	public onCommentLocationClick(): void {
		this.newComment['attachmentType'] = '';
		this.commentService.isCommentTyped = true; // 1
		this.location_display = 'block';
		this.layoutService.showBd('comment-location');

		if (this.locations.length === 0) {
			const self = this;
			let locations = [];
			const accuracy = { enableHighAccuracy: true };
			self.isLoading = true;

			// TODO: use loading spinner
			self.geoLocationService.getLocation(accuracy).subscribe(
				(position: any) => {
					self.currentLocation = position;

					self.locationService.getNearMeLocations(position.coords).subscribe((data) => {
						locations = data.response.groups[0].items.map((loc) => new LocationObj(loc, '4sq'));

						self.locations = self.sortData(locations);

						self.isLoading = false;
					});

					self.ref.detectChanges();
				},
				function (error) {
					self.errorMessage = error;
					self.ref.detectChanges();
					self.isLoading = false;
				}
			);
		}
	}

	private sortData(data: any): any {
		return data.sort(function (m1, m2) {
			if (m1.distance < m2.distance) {
				return -1;
			} else if (m1.distance > m2.distance) {
				return 1;
			} else {
				return 0;
			}
		});
	}

	/**
	 * Function to call on comment location  select.
	 */
	public onLocationSelect(loc): void {
		this.location_display = 'none';
		this.selectedLocation = loc;
		this.newComment['location'] = loc;

		this.locationService
			.create({
				venueId: this.newComment['location'].venueId,
				latitude: loc.latitude,
				longitude: loc.longitude,
				name: this.newComment['location'].name,
				category: this.newComment['location'].category,
				website: this.newComment['location'].website,
				phone: this.newComment['location'].phone,
			})
			.subscribe((data) => {
				this.newComment['location'] = data.uid;
				this.newComment['attachmentType'] = 'location';
			});

		this.layoutService.hideBd();
	}

	/**
	 * Function to hide location select modal.
	 */
	public onCloseLocationModal(): void {
		this.commentService.isCommentTyped = false;
		this.location_display = 'none';
		this.layoutService.hideBd();
	}

	/**
	 * Function to search locations.
	 */
	public onLocationSearch(): void {
		this.isLoading = true;

		this.locationService.getNearMeLocations(this.currentLocation.coords, this.searchLocation).subscribe((data) => {
			this.isLoading = false;

			const locations = data.response.groups[0].items.map((loc) => new LocationObj(loc, '4sq'));

			this.locations = this.sortData(locations);
		});
	}

	public onResetLocationSearch(): void {
		this.searchLocation = '';
		this.onLocationSearch();
	}

	public checkShiftKey(event): void {
		if (event.shiftKey) {
			this.shiftKeyUsed = true;
		}
	}

	public onUserSelect(user): void {
		this.isMentions = false;
		this.userMentions = [];
		this.hashMentions = [];

		const startPosition = this.textFocus.nativeElement.selectionStart,
			endPosition = this.textFocus.nativeElement.selectionEnd;

		if (startPosition === endPosition) {
			const text = this.textFocus.nativeElement.value.substring(0, startPosition);

			this.mentionIndex = text.lastIndexOf('@');

			if (this.mentionIndex > -1 && this.shiftKeyUsed) {
				const strippedText = this.textFocus.nativeElement.value.substring(this.mentionIndex, startPosition);

				if (strippedText !== '@') {
					const reg = new RegExp(strippedText, 'g');
					const replacedTitle = this.newComment.text.replace(reg, '@' + user.name + ' ');

					this.newComment.text = replacedTitle;
					// this.shiftKeyUsed = false;
				} else {
					// this.shiftKeyUsed = false;
					const firstPart = this.newComment.text.substr(0, startPosition);
					const lastPart = this.newComment.text.substr(startPosition + 1);

					const newString = firstPart + user.name + ' ' + lastPart;

					this.newComment.text = newString;
				}
			} else {
				const newTitle = <''>(
					`${this.textFocus.nativeElement.value.substring(0, startPosition)}@${user.name + ' '}${this.textFocus.nativeElement.value.substring(endPosition)}`.toString()
				);

				this.newComment.text = newTitle;
				this.textFocus.nativeElement.focus();
			}
		}
		if (this.newComment['mentioned_users']) {
			this.newComment['mentioned_users'].push(user);
		} else {
			this.newComment['mentioned_users'] = [user];
		}
	}

	public onHashTagSelect(hash): void {
		this.isMentions = false;
		this.userMentions = [];
		this.hashMentions = [];

		const startPosition = this.textFocus.nativeElement.selectionStart,
			endPosition = this.textFocus.nativeElement.selectionEnd;

		if (startPosition === endPosition) {
			const text = this.textFocus.nativeElement.value.substring(0, startPosition);

			this.mentionIndex = text.lastIndexOf('#');

			if (this.mentionIndex > -1 && this.shiftKeyUsed) {
				const strippedText = this.textFocus.nativeElement.value.substring(this.mentionIndex, startPosition);

				if (strippedText !== '#') {
					const reg = new RegExp(strippedText, 'g');
					const replacedTitle = this.newComment.text.replace(reg, '#' + hash['name'] + ' ');
					this.newComment.text = replacedTitle;
					// this.shiftKeyUsed = false;
				} else {
					// this.shiftKeyUsed = false;
					const firstPart = this.newComment.text.substr(0, startPosition);
					const lastPart = this.newComment.text.substr(startPosition + 1);
					const newString = firstPart + hash['name'] + ' ' + lastPart;
					this.newComment.text = newString;
				}
			} else {
				const newTitle = <''>(
					`${this.textFocus.nativeElement.value.substring(0, startPosition)}#${hash['name'] + ' '}${this.textFocus.nativeElement.value.substring(endPosition)}`.toString()
				);

				this.newComment.text = newTitle;
				this.textFocus.nativeElement.focus();
			}
		}
		if (this.newComment['hashtags']) {
			this.newComment['hashtags'].push(hash);
		} else {
			this.newComment['hashtags'] = [hash];
		}
	}

	/**
	 * For handling scroll pagination in mentions dropdown.
	 * 1. Check if nextMention url exists.
	 * 2. If true,call paginate api.
	 * 3. Set user mentions/hash mentions array accordingly.
	 * 4. Set nextMentionUrl if exists
	 */
	public paginateMentions(): void {
		if (this.nextMentionUrl) {
			// 1
			this.postService.paginate(this.nextMentionUrl).subscribe((data) => {
				// 2
				if (data) {
					if (this.mentionType === 'user') {
						// 3
						this.userMentions = this.userMentions.concat(data['objects']);
					} else if (this.mentionType === 'hash') {
						this.hashMentions = this.hashMentions.concat(data['objects']);
					}

					if (data.next) {
						// 4
						this.nextMentionUrl = data.next.split('alpha')[1];
					} else {
						this.nextMentionUrl = '';
					}
				}
			});
		}
	}

	/**
	 * For handling privacy for user mentions in comments.
	 * 1. Check if post contains content privacy,then set contentPrivacy for mentions.
	 * 2. Check if post contains custom privacy,then set customPrivacy for mentions.
	 * 3. Check if post contains channel privacy,then set channel as privacy for mentions.
	 * 4. If no privacy exists,then set mentionPrivacy as empty
	 */
	public handlePostPrivacy(): void {
		if (this.post['contentPrivacy']) {
			// 1
			this.mentionPrivacy = 'content_privacy=' + JSON.stringify(this.post['contentPrivacy']);
		} else if (this.post['customPrivacy']) {
			// 2
			const customPrivacy: any[] = [];
			// let array: any;
			this.post['customPrivacy'].forEach((element) => {
				if (element.id) {
					customPrivacy.push(element.id);
				}
			});

			const array = '[' + customPrivacy.join(',') + ']';

			this.mentionPrivacy = 'custom_privacy=' + array;
		} else if (this.post['channel']) {
			// 3
			this.mentionPrivacy = 'channel=' + this.post['channel']['uid'];
		} else {
			// 4
			this.mentionPrivacy = '';
		}
	}

	private getCaretPosition(e, ctrl): number {
		let caretPosition = 0; // IE Support
		ctrl = e.target;

		if (ctrl.selectionStart || ctrl.selectionStart === '0') {
			caretPosition = ctrl.selectionStart;
		}

		return caretPosition;
	}

	private returnWord(text, caretPosition): string {
		if (text) {
			const preText = text.substring(0, caretPosition);

			if (preText.indexOf(' ') > 0) {
				const words = preText.split(' ');

				return words[words.length - 1]; // return last word
			} else {
				return preText;
			}
		}
		return null;
	}

	// =================Mentions Functions=================================----------------------------------

	/**
	 * Function which executes on keyup in input textarea
	 * @param e
	 * 1. Handle post privacy for mentions filtering.
	 * 2. Manage textarea size according to content.
	 * 3. Check if value typed in input is @/#.
	 * 4. Pass value after @/# as subject for getting corresponding mentions.
	 * 5. If key pressed is not keyup/down then clear all mentions.
	 * 6. If no value exists in input,clear all mentions
	 */
	public onCommentChange(e): void {
		this.userRegexFailed = false;
		this.hashRegexFailed = false;
		this.handlePostPrivacy(); // 1
		// e.target.style.height = e.target.scrollHeight + 'px';  //2

		if (e.target.value) {
			// 2
			this.commentService.isCommentTyped = true;
		} else {
			this.commentService.isCommentTyped = false;
		}

		const userMentionRegex = /(^|\s)@(\w*(?:\s*\w*))$/;
		const hashTagRegex = /(^|\s)#(\w*(?:\s*\w*))$/;
		let text;
		const caretPosition = this.getCaretPosition(e, this.newComment.text);
		const word = this.returnWord(this.newComment.text, caretPosition); // 3

		if (word !== null) {
			if (userMentionRegex.test(word)) {
				// 3
				this.mentionType = 'user';

				if (e.keyCode !== 38 && e.keyCode !== 40) {
					text = word.replace(/(^@)/g, '');
					if (this.mentionType === 'user') {
						if (text.length >= 3) {
							this.mentionsFetched = 1;
							this.subject.next(text); // 4
						} else {
							return;
						}
					}
				}
			} else {
				this.userRegexFailed = true;
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					// 5
					this.hashMentions = [];
					this.userMentions = [];
				}
			}
			if (word !== null && hashTagRegex.test(word)) {
				// 3
				this.mentionType = 'hash';
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					this.mentionsFetched = 1;
					text = word.replace(/(^#)/g, '');
					this.subject.next(text); // 4
				}
			} else {
				this.hashRegexFailed = true;
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					// 5
					this.hashMentions = [];
					this.userMentions = [];
				}
			}
		} else {
			// 6

			if (!this.isMentions) {
				this.mentionsFetched = 0;
				this.userMentions = [];
				this.hashMentions = [];
			}
		}
	}

	public onCommentKeyDown(event: KeyboardEvent): void {
		if (this.userMentions.length > 0 || this.hashMentions.length > 0) {
			switch (event.keyCode) {
				case 38:
					event.preventDefault();
					this.arrowKeyLocation--;
					if (this.arrowKeyLocation < 0) {
						this.panel.nativeElement.scrollTop = 0;
						this.arrowKeyLocation = 0;
					}

					if (this.userMentions.length > 0) {
						this.userMentions.forEach((val, index) => {
							if (index === this.arrowKeyLocation) {
								this.addHighLightClass(val);
								this.scrollIntoView(val);
							} else {
								this.removeHighLightClass(val);
							}
						});
					} else {
						this.hashMentions.forEach((val, index) => {
							if (index === this.arrowKeyLocation) {
								this.addHighLightClass(val);
								this.scrollIntoView(val);
							} else {
								this.removeHighLightClass(val);
							}
						});
					}
					break;

				case 40:
					event.preventDefault();
					this.arrowKeyLocation++;
					if (this.arrowKeyLocation > this.mentionCount - 1) {
						this.panel.nativeElement.scrollTop = 0;
						this.arrowKeyLocation = 0;
					}

					if (this.userMentions.length > 0) {
						this.userMentions.forEach((val, index) => {
							if (index === this.arrowKeyLocation) {
								this.addHighLightClass(val);
								this.scrollIntoView(val);
							} else {
								this.removeHighLightClass(val);
							}
						});
					} else {
						this.hashMentions.forEach((val, index) => {
							if (index === this.arrowKeyLocation) {
								this.addHighLightClass(val);
								this.scrollIntoView(val);
							} else {
								this.removeHighLightClass(val);
							}
						});
					}
					break;

				case 13:
					event.preventDefault();
					if (this.mentionType === 'user') {
						this.userMentions.forEach((element, index) => {
							if (index === this.arrowKeyLocation) {
								this.onUserSelect(element);
							}
						});
					} else if (this.mentionType === 'hash') {
						this.hashMentions.forEach((element, index) => {
							if (index === this.arrowKeyLocation) {
								this.onHashTagSelect(element);
							}
						});
					}
			}
		}
	}

	/** For adding highlight class for mention elements
	 * 1. Clear all previous highlight class on elements.
	 * 2. Add mention highlight css class.
	 * @param val
	 */
	private addHighLightClass(val): void {
		this.clearHighLightClass(); // 1
		const element = document.getElementById(val.uid);

		element.classList.add(CSS_CLASS_NAMES.highLight); // 2
	}

	/** For removing highlight class for mention elements
	 * @param val
	 */
	private removeHighLightClass(val): void {
		const element = document.getElementById(val.uid);

		element?.classList?.remove(CSS_CLASS_NAMES.highLight);
	}

	/**
	 * For clearing highlight class from all mention elements
	 */
	private clearHighLightClass(): void {
		this.userMentions?.forEach((val) => {
			if (val.uid) {
				const element = document.getElementById(val.uid);

				element?.classList?.remove(CSS_CLASS_NAMES.highLight);
			}
		});

		this.hashMentions?.forEach((val) => {
			if (val.uid) {
				const element = document.getElementById(val.uid);

				element?.classList?.remove(CSS_CLASS_NAMES.highLight);
			}
		});
	}

	/**
	 * Scroll to current mention element
	 * 1. Get current element by its uid.
	 * 2. Get offset top of current element
	 * 3. Set topPosition as scroll top hence scroll to this position
	 * @param val
	 */
	private scrollIntoView(val): void {
		const elem = document.getElementById(val.uid),
			topPosition = elem.offsetTop;

		this.panel.nativeElement.scrollTop = topPosition;
	}

	/**
	 * On mouse hover,add or remove hightlight class
	 * 1. Clear previous remanats of hightlight class
	 * @param event
	 */
	public onHover(event: MouseEvent): void {
		this.clearHighLightClass(); // 1

		const target = event.target as HTMLElement;

		// eslint-disable-next-line spellcheck/spell-checker
		if (event.type === 'mouseover') {
			target.classList.add(CSS_CLASS_NAMES.highLight);
		} else {
			target.classList.remove(CSS_CLASS_NAMES.highLight);
		}
	}

	/**
	 * Function for displaying @/# mentions.
	 * 1. Check if selection mention type is @/#
	 * 2. Set mentionType and subject as empty
	 * @param type
	 */
	public displayMentions(type): void {
		this.textFocus.nativeElement.focus();
		// =======[08-06-21,Mention Fixes]
		this.isMentions = true;
		this.userRegexFailed = false;
		this.hashRegexFailed = false;
		// ====
		this.arrowKeyLocation = -1;
		this.shiftKeyUsed = false;

		if (type === 'user') {
			// 1
			this.mentionType = 'user';
			this.mentionsFetched = 1;
			this.subject.next(''); // 2
		} else {
			// 1
			this.mentionType = 'hash';
			this.subject.next(''); // 2
		}
	}

	public uploadDocument(): void {
		if (this.newComment['attachmentType'] === 'doc') {
			this.newComment['attachmentType'] = '';
		} else {
			this.newComment['attachmentType'] = 'doc';
		}

		if (this.model.preview_images && this.model.preview_images.length > 0) {
			this.model.preview_images = [];
			this.model.images = [];
		}
	}

	/**
	 * 1.If image upload option is already selected,reset isCommentTyped and newImage
	 * 2.Set isCommentTyped true for handling 'new-post' in socket and newImage true
	 */
	public showImageUpload(): void {
		// this.newComment['attachmentType'] = '';
		if (this.newImage) {
			// 1
			this.newComment['attachmentType'] = '';
			this.newImage = false;
			this.commentService.isCommentTyped = false;
		} else {
			this.newComment['attachmentType'] = 'photo'; // 2
			this.newImage = true;
			this.commentService.isCommentTyped = true;
		}

		if (this.model.preview_images && this.model.preview_images.length > 0) {
			this.model.preview_images = [];
			this.model.images = [];
		}
	}

	public autoSizeBox(e): void {
		e.target.style.cssText = 'height:auto';
		e.target.style.cssText = 'height:' + e.target.scrollHeight + 'px';
	}

	public onCut(): void {
		// this.textFocus.nativeElement.dispatchEvent(event);
		this.textFocus.nativeElement.click();
	}

	public onClick(event): void {
		this.autoSizeBox(event);
	}

	public onImgError(event): void {
		event.target.src = 'assets/images/default_avatar.jpg';
	}

	/** For reply comments
	 * Function to check the user mention and hash tag in post list if exists change the comment text
	 * 1. If string contains '<',then its cut off while displaying in innerHtml.Hence its replaced by '< '
	 */
	public checkUserMentionAndHashtagAndAlterReplyCommentText(comments): void {
		if (comments.replyComments) {
			comments.replyComments.forEach((comment) => {
				if (comment) {
					if (comment.text && comment.text.indexOf('<') > -1) {
						// 1
						comment.text = comment.text.replace(/</g, '< ');
					}

					comment.alteredText = comment.text;
					comment.minAlteredText = '';

					if (comment.alteredText && comment.alteredText.length > 150) {
						comment.minAlteredText = comment.alteredText.slice(0, 150) + '...';
						comment.showMinComment = true;
					}

					comment.alteredText = comment.alteredText.replace(/(?:\r\n|\r|\n)/g, '<br>');
					comment.minAlteredText = comment.minAlteredText.replace(/(?:\r\n|\r|\n)/g, '<br>');

					if (comment['mentionedUsers']) {
						comment['mentionedUsers'].forEach((mentionedUser) => {
							// const userMention = '@' + mentionedUser['name'];
							const html =
								'<b #userMention><a class="text-green" href="/network/profile/' +
								mentionedUser?.memberId +
								'?username=' +
								mentionedUser?.username +
								'">@' +
								mentionedUser['name'] +
								'</a></b>';

							comment.alteredText = comment.alteredText.replace('@' + mentionedUser['name'], html);
							comment.minAlteredText = comment.minAlteredText.replace('@' + mentionedUser['name'], html);
						});
					}

					if (comment['hashtags']) {
						comment['hashtags'].forEach((hashtag) => {
							if (hashtag['name']) {
								// 31-05-21 hashtags redirections for customers
								if (this.commentType === 'customer') {
									const hashtagName = hashtag['name'];
									// const hashtagUid = hashtag['uid'];
									const html = '<b #hashTag><a class="hash-customer">#' + hashtagName + '</a></b>';
									comment.alteredText = comment.alteredText.replace('#' + hashtag['name'], html);
									comment.minAlteredText = comment.minAlteredText.replace('#' + hashtag['name'], html);
								} else if (this.commentType === 'todo') {
									const hashtagName = hashtag['name'];
									// const hashtagUid = hashtag['uid'];
									const html = '<b #hashTag><a class="hash-todo">#' + hashtagName + '</a></b>';
									comment.alteredText = comment.alteredText.replace('#' + hashtag['name'], html);
									comment.minAlteredText = comment.minAlteredText.replace('#' + hashtag['name'], html);
								} else if (this.commentType === 'issue') {
									const hashtagName = hashtag['name'];
									// const hashtagUid = hashtag['uid'];
									const html = '<b #hashTag><a class="hash-issue">#' + hashtagName + '</a></b>';
									comment.alteredText = comment.alteredText.replace('#' + hashtag['name'], html);
									comment.minAlteredText = comment.minAlteredText.replace('#' + hashtag['name'], html);
								} else {
									const hashtagName = hashtag['name'];
									// const hashtagUid = hashtag['uid'];
									const html = '<b #hashTag><a class="hash-post">#' + hashtagName + '</a></b>';
									comment.alteredText = comment.alteredText.replace('#' + hashtag['name'], html);
									comment.minAlteredText = comment.minAlteredText.replace('#' + hashtag['name'], html);
								}
							}
						});
					}

					const urlRegex =
						/(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;

					if (comment.alteredText) {
						const strings = comment.text.match(urlRegex);

						if (strings && strings.length > 0) {
							strings.forEach((element) => {
								let url = element;

								if (!(element.indexOf('http://') === 0 || element.indexOf('https://') === 0 || element.indexOf('ftp://') === 0)) {
									url = 'http://' + element;
								}

								const html = '<a target="blank" class="pb-text-link" href="' + url + '">' + element + '</a>';

								if (comment.alteredText) {
									comment.alteredText = comment.alteredText.replace(element, html);
									comment.minAlteredText = comment.minAlteredText.replace(element, html);
								}
							});
						}
					}

					if (comment.parent) {
						// eslint-disable-next-line spellcheck/spell-checker
						comment.alteredText = `<span class="text-reply cursor-pointer">${this.commentText}&nbsp;@${comment.parent.user.name}</span>&nbsp;${comment.alteredText}`;
						// eslint-disable-next-line spellcheck/spell-checker
						comment.minAlteredText = `<span class="text-reply cursor-pointer">${this.commentText}&nbsp;@${comment.parent.user.name}</span>&nbsp;${comment.minAlteredText}`;
					}
				}
			});
		}
	}
}
