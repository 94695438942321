import { BeeError } from './../../../shared/models/custom-error';
import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TEXTBOX_VALUE_ACCESSOR } from '../../../shared/providers/textbox-accessor';
import { FormServiceService } from '../../../shared/services/form-service.service';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'bee-password-box',
	templateUrl: './password-box.component.html',
	styleUrls: ['./password-box.component.scss'],
	providers: [TEXTBOX_VALUE_ACCESSOR],
})
export class BeePasswordBoxComponent implements AfterViewInit {
	@Input() public id?: string;
	@Input() public placeholder = '';
	@Input() public formControlName?: string;
	@Input() public title?: string;
	@Input() public required = false;
	@Input() public minLength?: number;
	@Input() public maxLength?: number;
	@Input() public size: 'sm' | 'md' | 'lg' = 'md';
	@Input() public control: AbstractControl;
	@Input() public cleanValue = true;

	@Output() public blur: EventEmitter<Event> = new EventEmitter<Event>();
	@Output() public focus: EventEmitter<Event> = new EventEmitter<Event>();

	public errors: BeeError[] = [];
	public eyeToggle = false;

	constructor(private formServiceService: FormServiceService) {}

	public ngAfterViewInit(): void {
		this.control.statusChanges.subscribe((status: string) => {
			if (status === 'VALID') {
				this.errors = [];
			} else if (status === 'INVALID') {
				this.errors = this.formServiceService.handleFormErrors(this.control);
			}
		});
	}

	/**
	 * Input on blur event
	 */
	public onBlur(event: Event): void {
		if (this.cleanValue) {
			let cleanValue = this.control?.value?.trim();
			cleanValue = cleanValue?.replace(/\s+/g, ' ');

			if (cleanValue) {
				this.control.patchValue(cleanValue, { emitEvent: true });
			}
		}

		this.control.markAsTouched();
		this.control.markAsDirty();

		this.blur.emit(this.control.value);
	}

	/**
	 * Input on focus event
	 */
	public onFocus(event: Event): void {
		this.focus.emit(this.control.value);
	}

	public onEyeToggle(): void {
		this.eyeToggle = !this.eyeToggle;
	}
}
