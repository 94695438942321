import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-channel-edit',
	templateUrl: './channel-edit.component.html',
	styleUrls: ['./channel-edit.component.css'],
})
export class ChannelEditComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
