import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';
import { MarkdownService } from 'ngx-markdown';

@Injectable()
export class PostService {
	private getPostsObservable: Observable<any>;

	public refreshPostsEventEmitter: EventEmitter<boolean> = new EventEmitter();
	public showBackdrop: EventEmitter<any> = new EventEmitter();
	public deselectPost: EventEmitter<any> = new EventEmitter();
	private backdropStatus = false;
	public editSelectedPost: any;
	public lastPosts: any[] = [];
	public postCategories: any[] = [];
	public nextUrl = '';
	public filters;
	public productSlug;
	public selectedHash;
	public scrollPosition;
	public newPostEmitter = new EventEmitter<any>();

	public onClickMoreOptionsFromComments = new EventEmitter();

	constructor(private apiService: ApiService, private markdownService: MarkdownService) {}

	public get(uid: string): Observable<any> {
		return this.apiService.get(`/posts/${uid}/`);
	}

	public getCategories(uid): Observable<any> {
		// eslint-disable-next-line spellcheck/spell-checker
		return this.apiService.get('/networks/' + uid + '/postcategories/');
	}

	public getFiltersAndCategories(): Observable<any> {
		return this.apiService.get('/_support/categories/');
	}

	public getSavedPosts(url, slug, filterType = 'cat'): Observable<any> {
		if (slug !== 'all') {
			if (filterType === 'cat') {
				return this.apiService.get(url + '&category=' + slug);
			} else if (filterType === 'role') {
				return this.apiService.get(url + '&role=' + slug);
			}
		} else {
			return this.apiService.get(url);
		}
	}

	/**
	 * 1. If event post is searching,add '&' before query
	 * 2. Else add '?'
	 *
	 * @param url
	 * @param slug
	 * @param filterType
	 */
	public getPosts(url: any, slug: any, filterType = 'cat'): Observable<any> {
		if (filterType !== 'all') {
			if (filterType === 'cat') {
				return this.apiService.get(url + '?category=' + slug);
			} else if (filterType === 'role') {
				return this.apiService.get(url + '?role=' + slug);
			} else if (filterType === 'channel') {
				return this.apiService.get(url + '?channel=' + slug);
			} else if (filterType === 'query') {
				const substring = 'type=event',
					index = url.indexOf(substring);

				if (index > -1) {
					// 1
					return this.apiService.get(url + '&query=' + slug);
				} else {
					// 2
					return this.apiService.get(url + '?query=' + slug);
				}
			} else if (filterType === 'only_me') {
				return this.apiService.get(url + '?' + slug + '= true');
			} else if (filterType === 'department') {
				return this.apiService.get(url + '?department=' + slug);
			}
		} else {
			return this.apiService.get(url);
		}
	}

	public getPostsByHash(uid): Observable<any> {
		return this.apiService.get('/hashtags/' + uid + '/posts/?app_id=' + localStorage.getItem('network_id'));
	}

	public getIssuesByHash(uid): Observable<any> {
		return this.apiService.get('/hashtags/' + uid + '/issues/?app_id=' + localStorage.getItem('network_id'));
	}

	public personalFilterService(url, query = ''): Observable<any> {
		if (query) {
			return this.apiService.get(url + '?' + query);
		} else {
			return this.apiService.get(url);
		}
	}
	public getUserPosts(username): Observable<any> {
		return this.apiService.get('/users/' + username + '/posts/');
	}

	public create(data): Observable<any> {
		data = JSON.parse(JSON.stringify(data));
		return this.apiService.post('/posts/', data);
	}

	// -Issue management--------->
	public createIssue(data): Observable<any> {
		return this.apiService.post('/issues/', data);
	}

	public deleteIssue(issueUid): Observable<any> {
		const url = '/issues/' + issueUid + '/?username=' + localStorage.getItem('uid');
		return this.apiService.delete(url);
	}

	public updateIssue(credential, issueUid): Observable<any> {
		const url = '/issues/' + issueUid + '/';
		return this.apiService.put(url, credential);
	}

	public like(username, data): Observable<any> {
		// if (data.type === 'issue' || data.type === 'todo') {
		//   let params = { 'contentType': 'issue', 'contentUid': data.uid };
		//   return this.apiService.post('/users/' + username + '/likes/', params);
		// }
		if (data.type === 'issue') {
			const params = { contentType: 'issue', contentUid: data.uid };
			return this.apiService.post('/users/' + username + '/likes/', params);
		} else if (data.type === 'todo') {
			const params = { contentType: 'todo', contentUid: data.uid };
			return this.apiService.post('/users/' + username + '/likes/', params);
		} else if (data.type === 'customer') {
			const params = { contentType: 'customer', contentUid: data.uid };
			return this.apiService.post('/users/' + username + '/likes/', params);
		} else {
			const params = { contentType: 'post', contentUid: data.uid };
			return this.apiService.post('/users/' + username + '/likes/', params);
		}
	}

	public dislike(username, uid): Observable<any> {
		return this.apiService.delete('/users/' + username + '/likes/' + uid + '/');
	}

	public getMetaData(url): Observable<any> {
		return this.apiService.getMetaData(url);
	}

	public paginate(url, filters = null): Observable<any> {
		let slug = 'all';
		let filterType = 'cat';
		if (filters) {
			slug = filters.slug;
			filterType = filters.filterType;
		}

		if (slug !== 'all') {
			if (filterType === 'cat') {
				return this.apiService.get(url + '&category=' + slug);
			} else if (filterType === 'role') {
				return this.apiService.get(url + '&role=' + slug);
			} else if (filterType === 'channel') {
				return this.apiService.get(url + '&channel=' + slug);
			} else if (filterType === 'all') {
				/** -----filter Everything--- */
				return this.apiService.get(url);
			} else if (filterType === 'only_me') {
				return this.apiService.get(url + '&' + slug + '= true');
			} else {
				/** Paginate personal filter other than only me */
				return this.apiService.get(url + '&' + filterType + '=' + slug);
			}
		} else {
			return this.apiService.get(url);
		}
	}

	public getEventImages(): Observable<any> {
		const url = '/uploads/stock-images/';
		return this.apiService.get(url + '?type=event');
	}

	public getChannels(): Observable<any> {
		const url = '/networks/' + localStorage.getItem('network_id') + '/channels/';
		return this.apiService.get(url);
	}

	public postEventAttendType(username, type, contentUid): Observable<any> {
		const url = '/users/' + username + '/rsvps/';
		const params = {
			attending: type,
			contentType: 'post',
			contentUid: contentUid,
		};
		return this.apiService.post(url, params);
	}

	// eslint-disable-next-line spellcheck/spell-checker
	public updateEventAttendType(username, type, rsvpId): Observable<any> {
		// eslint-disable-next-line spellcheck/spell-checker
		const url = '/users/' + username + '/rsvps/' + rsvpId + '/';
		const params = { attending: type };
		return this.apiService.put(url, params);
	}

	public postVote(postUid, choiceUid): Observable<any> {
		const url = '/posts/' + postUid + '/votes/';
		const params = { choice: choiceUid, username: localStorage.getItem('uid') };
		return this.apiService.post(url, params);
	}

	public getClassifieds(url): Observable<any> {
		return this.apiService.get(url);
	}

	public savePost(postUid): Observable<any> {
		const url = '/users/' + postUid + '/save/';
		const params = {
			username: localStorage.getItem('uid'),
			contentType: 'network',
			contentUid: postUid,
		};
		return this.apiService.post(url, params);
	}

	public getComments(postUid): Observable<any> {
		const url = '/posts/' + postUid + '/comments/';
		return this.apiService.get(url);
	}

	public getTaskComments(postUid): Observable<any> {
		const url = '/todos/' + postUid + '/comments/';
		return this.apiService.get(url);
	}

	public getIssueComments(postUid): Observable<any> {
		const url = '/issues/' + postUid + '/comments/';
		return this.apiService.get(url);
	}

	public getContentComments(postUid, type): Observable<any> {
		let param;
		if (type === 'todo' || type == 'sub_task') {
			param = 'todos';
		} else if (type === 'issue' || type === 'feature' || type === 'enhancement') {
			param = 'issues';
		} else if (type === 'table_item') {
			param = `networks/${localStorage.getItem('network_id')}/apps/items`;
		}
		const url = '/' + param + '/' + postUid + '/comments/';
		return this.apiService.get(url);
	}

	public getCustomerComments(postUid): Observable<any> {
		const url = '/customers/' + postUid + '/comments/';
		return this.apiService.get(url);
	}

	public hideBackGround(): void {
		if (this.backdropStatus) {
			this.showBackdrop.emit('hide');
		} else {
			this.showBackdrop.emit('show');
		}

		this.backdropStatus = !this.backdropStatus;
	}

	public showBackDrop(): void {
		this.showBackdrop.emit('show');
		this.backdropStatus = true;
	}

	public hideBackDrop(): void {
		this.showBackdrop.emit('hide');
		this.backdropStatus = false;
	}

	public hideSelectedPost(): void {
		this.backdropStatus = false;
		this.deselectPost.emit();
		this.showBackdrop.emit('hide');
	}

	public deletePost(postUid): Observable<any> {
		const url = '/posts/' + postUid + '/?username=' + localStorage.getItem('uid');
		return this.apiService.delete(url);
	}

	public updatePost(credential, postUid): Observable<any> {
		const url = '/posts/' + postUid + '/';
		return this.apiService.put(url, credential);
	}

	public postWelcome(memberId): Observable<any> {
		const credential = { username: localStorage.getItem('uid') };
		const url = '/members/' + memberId + '/welcome/';
		return this.apiService.post(url, credential);
	}

	/**
	 * Function to alter the table body.
	 * 1.If string contains '<',then its cut off while displaying in innerHtml.Hence its replaced by '< '
	 */
	public alterPostBodyAccordingToUserMentionAndHashtag(post, searchValue = ''): void {
		if (post['title'] && post['title'].indexOf('<') > -1) {
			// 1
			post['title'] = post['title'].replace(/</g, '< ');
		}
		if (post['body'] && post['body'].indexOf('<') > -1) {
			// 1
			post['body'] = post['body'].replace(/</g, '< ');
		}
		if (post['description'] && post['description'].indexOf('<') > -1) {
			// 1
			post['description'] = post['description'].replace(/</g, '< ');
		}

		post['altered_body'] = post['body'];
		post['altered_title'] = post['title'];
		// For handling user mention and hashtags in issue management
		post['altered_description'] = post['description'];
		//

		if (searchValue) {
			const regex = new RegExp(searchValue, 'ig');
			const bodyKeys = [];
			const titleKeys = [];
			const authorKeys = [];
			let result;
			while ((result = regex.exec(post['altered_body']))) {
				bodyKeys.push(result[0]);
			}
			//  For search issue collections description
			while ((result = regex.exec(post['altered_description']))) {
				bodyKeys.push(result[0]);
			}
			//
			while ((result = regex.exec(post['title']))) {
				titleKeys.push(result[0]);
			}
			while ((result = regex.exec(post['author']['name']))) {
				authorKeys.push(result[0]);
			}
			authorKeys.forEach((key) => {
				const html = '<span class="search-string">' + key + '</span>';
				post['author']['name'] = post['author']['name'].replace(key, html);
			});
			titleKeys.forEach((key) => {
				const html = '<span class="search-string">' + key + '</span>';
				post['altered_title'] = post['title'].replace(key, html);
			});
			bodyKeys.forEach((key) => {
				const html = '<span class="search-string">' + key + '</span>';
				// For highlighting searched key word in posts body
				if (post.altered_body) {
					post.altered_body = post.altered_body.replace(key, html);
				}
				// For highlighting searched key word in issue description
				if (post.altered_description) {
					post.altered_description = post.altered_description.replace(key, html);
				}
			});
		}
		if (post['mentionedUsers']) {
			post['mentionedUsers'].forEach((mentionedUser) => {
				// const userMention = '@' + mentionedUser['name'];
				const html =
					'<b #userMention>' +
					'<a class="text-green text-bold" href="/network/profile/' +
					mentionedUser?.memberId +
					'?username=' +
					mentionedUser?.username +
					'">@' +
					mentionedUser['name'] +
					'</a></b>';
				if (post.altered_body) {
					post.altered_body = post.altered_body.replace('@' + mentionedUser['name'], html);
				}
				if (post.altered_title) {
					post.altered_title = post.altered_title.replace('@' + mentionedUser['name'], html);
				}
				if (post.altered_description) {
					post.altered_description = post.altered_description.replace('@' + mentionedUser['name'], html);
				}
			});
		}
		if (post['hashtags']) {
			post['hashtags'].forEach((hashtag) => {
				if (hashtag['name']) {
					const hashtagName = hashtag['name'];
					// const hashtagUid = hashtag['uid'];
					// const html = '<b #hashTag><a class="text-green"  href="/network/#' + hashtag_name + '/' + hashtag_uid + '">#' + hashtag_name + '</a></b>'; //09-06-21
					const html = '<b #hashTag><a class="hash-post">#' + hashtagName + 'userMention/b>';
					const issueHtml = '<b #hashTag><a class="hash-issue" >#' + hashtagName + ' </a></b>';
					if (post.altered_body) {
						post.altered_body = post.altered_body.replace('#' + hashtag['name'], html);
					}

					if (post.type === 'issue') {
						if (post.altered_title) {
							post.altered_title = post.altered_title.replace('#' + hashtag['name'], issueHtml);
						}
						if (post.altered_description) {
							post.altered_description = post.altered_description.replace('#' + hashtag['name'], issueHtml);
						}
					} else {
						if (post.altered_title) {
							post.altered_title = post.altered_title.replace('#' + hashtag['name'], html);
						}
						if (post.altered_description) {
							const newHtml = `<b #hashTag><a class="text-green">#${hashtagName}</a></b>`;
							post.altered_description = post.altered_description.replace('#' + hashtag['name'], newHtml);
						}
					}
				}
			});
		}
		const urlRegex =
			/(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;
		if (post.body) {
			const strings = post.body.match(urlRegex);
			if (strings && strings.length > 0) {
				strings.forEach((element) => {
					let url = element;
					if (!(element.indexOf('http://') === 0 || element.indexOf('https://') === 0 || element.indexOf('ftp://') === 0)) {
						url = 'http://' + element;
					}
					const html = '<a class="pb-text-link" target="blank" href="' + url + '">' + element + '</a>';
					if (post.altered_body) {
						post.altered_body = post.altered_body.replace(element, html);
					}
				});
			}
		}
		if (post.description) {
			const strings = post.description.match(urlRegex);
			if (strings && strings.length > 0) {
				strings.forEach((element) => {
					let url = element;
					if (!(element.indexOf('http://') === 0 || element.indexOf('https://') === 0 || element.indexOf('ftp://') === 0)) {
						url = 'http://' + element;
					}
					const html = '<a class="pb-text-link" target="blank" href="' + url + '">' + element + '</a>';
					if (post.altered_description) {
						post.altered_description = post.altered_description.replace(element, html);
					}
				});
			}
		}
		if (post.title) {
			const strings = post.title.match(urlRegex);
			if (strings && strings.length > 0) {
				strings.forEach((element) => {
					let url = element;
					if (!(element.indexOf('http://') === 0 || element.indexOf('https://') === 0 || element.indexOf('ftp://') === 0)) {
						url = 'http://' + element;
					}
					const html = '<a class="pb-text-link" target="blank" href="' + url + '">' + element + '</a>';

					if (post.altered_title) {
						post.altered_title = post.altered_title.replace(element, html);
					}
				});
			}
		}
		if (post.body && post.body.length > 350) {
			post['strippedBody'] = post.altered_body.slice(0, 300) + '...';
		}
		// -For handling more and hide feature in issue management
		if (post.description && post.description.length > 350) {
			post['stripped_Description'] = post.altered_description.slice(0, 300) + '...';
		}
		// -

		post['altered_body'] = this.markdownService.compile(post['body']);
	}

	public resetPosts(): void {
		this.lastPosts = [];
		this.nextUrl = '';
		this.filters = null;
		this.selectedHash = null;
		this.scrollPosition = 0;
		this.clearCachedPosts();
	}

	public clearCachedPosts(): void {
		const networkId = localStorage.getItem('network_id');
		Object.keys(localStorage).forEach((key) => {
			if (key.indexOf(`${networkId}-Posts`) > -1) {
				localStorage.removeItem(key);
			}
		});
	}

	public clearCachedLists(): void {
		localStorage.removeItem('tab');
		localStorage.removeItem('detail-tab');
		const networkId = localStorage.getItem('network_id');
		Object.keys(localStorage).forEach((key) => {
			if (key.indexOf(`${networkId}-lists`) > -1) {
				localStorage.removeItem(key);
			}
		});
	}

	public checkAccessibility(data): Observable<any> {
		const params = new HttpParams({
			fromObject: {
				format: 'json',
			},
		});

		const url = '/posts/' + data.postId + '/?username=' + localStorage.getItem('uid');

		return this.apiService.get(url, params);
	}

	/**
	 * Function for mark as sold in buy sell detail page
	 *
	 * @param uid
	 * @param data
	 */
	public itemSold(uid, data): Observable<any> {
		const url = '/posts/' + uid + '/';
		return this.apiService.put(url, data);
	}

	public getMentions(search: string, type: string, privacy): Observable<any> {
		if (type === 'user') {
			if (privacy) {
				return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/users/?query=' + search + '&' + privacy);
			} else {
				return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/users/?query=' + search);
			}
		} else if ((type = 'hash')) {
			return this.apiService.get('/hashtags/' + '?network=' + localStorage.getItem('network_id') + '&query=' + search);
		}
	}

	public getPostViews(postUid): Observable<any> {
		return this.apiService.get('/posts/' + postUid + '/views/');
	}

	public getCommentViews(comment_uid): Observable<any> {
		return this.apiService.get('/comments/' + comment_uid + '/views/');
	}

	/**
	 * Api function to get comment replies
	 */
	public getReplyComments(comment_uid): Observable<any> {
		const url = `/comments/${comment_uid}/children/`;

		return this.apiService.get(url);
	}

	/**
	 * Api function to duplicate project
	 */
	public duplicateProject(projectId): Observable<any> {
		const url = `/projects/${projectId}/duplicate/`;

		return this.apiService.put(url);
	}

	/**
	 * Api function to duplicate task
	 */
	public duplicateTask(todoUid): Observable<any> {
		const url = `/todos/${todoUid}/duplicate/`;

		return this.apiService.put(url);
	}
}
