import { Component, Input, OnInit } from '@angular/core';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'bee-private-public-label',
	templateUrl: './private-public-label.component.html',
	styleUrls: ['./private-public-label.component.scss'],
})
export class BeePrivatePublicLabelComponent implements OnInit {
	@Input() public isPrivate: boolean;

	constructor() {}

	ngOnInit(): void {}
}
