import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EventComponent } from './event.component';
import { SharedModule } from '../shared';
import { AgmCoreModule } from '@agm/core';
import { PostModule } from '../post/post.module';
import { CommonModule } from '@angular/common';

@NgModule({
	imports: [BrowserModule, AgmCoreModule, SharedModule, PostModule, CommonModule],
	declarations: [EventComponent],
	bootstrap: [EventComponent],
	providers: [],
})
export class EventModule {}
