import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';

@Injectable()
export class CommentsService {
	public replyCommentSubject = new BehaviorSubject<any>(null);
	public replyComment = this.replyCommentSubject.asObservable();

	public isCommentTyped = false;

	constructor(private apiService: ApiService) {}

	public getAll(url): Observable<any> {
		return this.apiService.get(url);
	}

	public create(data: any, username: string): Observable<any> {
		data = JSON.parse(JSON.stringify(data));

		return this.apiService.post(`/users/${username}/comments/`, data);
	}

	public updateComment(data, username, commentUid): Observable<any> {
		data = JSON.parse(JSON.stringify(data));
		return this.apiService.put(`/users/${username}/comments/` + commentUid + '/', data);
	}

	public likeComment(uid): Observable<any> {
		const data = { contentUid: uid, contentType: 'comment' },
			url = '/users/' + localStorage.getItem('uid') + '/likes/';

		return this.apiService.post(url, data);
	}

	public deleteLike(uid): Observable<any> {
		const url = '/users/' + localStorage.getItem('uid') + '/likes/' + uid + '/';

		return this.apiService.delete(url);
	}

	public deleteComment(uid): Observable<any> {
		const url = '/users/' + localStorage.getItem('uid') + '/comments/' + uid + '/';

		return this.apiService.delete(url);
	}

	public passReplySelectedComment(comment): void {
		this.replyCommentSubject.next(comment);
	}

	public report(body): Observable<any> {
		const url = '/users/' + localStorage.getItem('uid') + '/flags/';

		return this.apiService.post(url, body);
	}
}
