import { Component, OnInit, ViewChild, Input, SimpleChanges, EventEmitter, Output, OnChanges, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService, NetworkService, LayoutService, CommentsService, PaginationService, PostService } from '../../shared';
import { Location } from '@angular/common';
import { Comment, ITodo, ITodoData, Reminder, Subtask, Todo } from '../../shared/models';
import { TranslateService } from '@ngx-translate/core';
import { PostEditComponent } from '../../shared/layout/post-edit/post-edit.component';
import { PostComponent } from '../../post/post.component';
import { SearchService } from '../../shared/services/search.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClipboardService } from 'projects/peer-core/src/lib/services/ui/clipboard.service';
import { ProjectService } from '@modules/project/services/project.service';
import { AttachmentService } from 'projects/bee-attachment/src/lib/shared/services/attachment.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { SegmentAnalyticsService } from '@core/services/third-party/segment-analytics.service';
import { MixPanelService } from '@core/services/third-party/mix-panel.service';

@Component({
	selector: 'app-todolist-detail',
	templateUrl: './todolist-detail.component.html',
	styleUrls: ['./todolist-detail.component.css'],
})
export class TodolistDetailComponent implements OnInit, OnChanges, OnDestroy {
	constructor(
		private fb: FormBuilder,
		private postService: PostService,
		private paginationService: PaginationService,
		private commentService: CommentsService,
		private activatedRoute: ActivatedRoute,
		private translate: TranslateService,
		private userService: UserService,
		private router: Router,
		private _location: Location,
		private searchService: SearchService,
		public networkService: NetworkService,
		public layoutService: LayoutService,
		private clipboardService: ClipboardService,
		private projectService: ProjectService,
		private attachmentService: AttachmentService,
		private mixPanelService: MixPanelService,
		private segmentService: SegmentAnalyticsService
	) {
		this.paginationService.scrollDistance = 8;
		this.paginateSub$ = this.paginationService.initiateScroll.subscribe((data) => {
			if (data && !this.paginating) {
				this.paginateItems();
			}
		});

		this.estimateForm = this.fb.group({
			time: ['', Validators.required],
		});

		this.elapsedForm = this.fb.group({
			time: ['', Validators.required],
		});
	}
	@Input() filterParams;
	@Input() date;
	@Input() uid;
	@Input() timeNow;
	@Input() todoTime;
	@Input() currentPage = 'todoListDetail';
	@Input() username;
	@Input() archivedList = false;
	@Input() public myTeamTasks = false;
	@ViewChild('postEdit', { static: true }) public postEdit: PostEditComponent;
	@ViewChild('postComponent', { static: true }) public postComponent: PostComponent;
	@Output() public resetCounts: EventEmitter<any> = new EventEmitter();
	@Output() public refresh: EventEmitter<any> = new EventEmitter();
	@Output() public listTodosFetching: EventEmitter<any> = new EventEmitter();
	estimateForm: FormGroup;
	elapsedForm: FormGroup;
	toggle = {};
	public filter = { value: '' };
	public loadingGif = 'none';
	public reminderUid;
	public isEditReminder = false;
	public arr1 = [];
	public postUrl = null;
	public currentUserUid = localStorage.getItem('uid');
	public privacy = {};
	public privacyString = '';
	private currentDepartmentSubscriber;
	private departmentsReady;
	private countsSubscriber;
	public value;
	public network_id;
	public currentUser: any;
	public postPrivacy;
	public isFirstSearch = true;
	public showMenu = false;
	public showPostCreateForm = false;
	public showDefaultPost = true;
	public noAssignedItems = false;
	public setStartDayComment = false;
	public display_Completed = false;
	public displayMyTasks = false;
	public postType;
	public todosFetched: any = 0;
	public showDeleteConfirmation = 'none';
	public isRedirectFromEditPage = false;
	public showEditDelete = true;
	public muteId;
	public showMute = true;
	private networkSubscriber;
	private currentNetSub;
	private paramsSubscriber;
	private checkAccessibilitySub;
	public todoList;
	public network;
	private app_url;
	private store_url;
	private is_member = null;
	public todoListId;
	// todo item
	public subtask = new Subtask();
	public mainTask;
	public subIndex;
	public deleteItem_modal = 'none';
	public display_reminderOptions = 'none';
	public display_toast = 'none';
	public display_todoLists = 'none';
	public display_assignedTodos = 'none';
	public displayError = 'none';
	public displayEstimate = 'none';
	public displayElapsed = 'none';

	public personalFilters: any[] = [];
	public todoItems: any[] = [];
	public todoListsForItem: any[] = [];
	public SubscriptionTemplates = [];
	public departments = [];
	public assignedItems = [];
	public options: any[] = [];
	public selectedTodos: any = [];

	private paginating = false;
	public selectedToDoItem: any;
	public lists = { option: '' };
	public reminder = { value: '' };
	public assignedItem = { value: '' };
	public todoDetail = { todoList_uid: '', todolistName: '' };
	public selectedToDoList: any;
	public todoListPrivacy: any;
	public post: any;
	public next_url;
	public item_next_url;
	public start_next_url;
	public subNextUrl;

	public selectedRemainderOption;
	public reminderMessage;
	public timeForReminder: number;
	public todoItemIndex;
	public editSelectedPost: any;
	public myTodoList_uid;
	public paginateSub$: any;
	public searchSubscription: any;
	public collaboration: boolean;
	public defaultTasks = false;
	public isExternalUser = false;
	public sortByAssignee = false;
	public tab = '';
	public progressWidth;
	reminderObject = {
		username: '',
		contentUid: '',
		contentType: '',
		reminderOption: '',
		repeat: false,
	};
	public linkCopied = false;
	public isHashTags = false;
	public hashTag;
	public isArchived = false;
	public repeatModal = 'none';
	public squareSpinner = 'none';
	// reminders
	public reminderOptions: any[] = [];
	public displayReminderModal = 'none';
	public reminderTime;
	public timeFor;
	public remindersSelected = [];
	public model = new Reminder();
	public reminderToast = false;
	public todoForReminder;
	public numbers: any;

	public reaction: any;

	taskReactionDialog = 'none';

	public selectedItem: any;

	public selectedTodoIndex: any;

	public selectedTodoValue: any;

	public reactionToEdit: any;

	showEditReactionDialog = 'none';

	parentTask: any;

	comment = new Comment({ text: '', isLink: false, auto_generated: true }, true);

	public todoItem = {
		category: 'todo',
		network: localStorage.getItem('network_id'),
		collection_post: true,
		commentable: true,
		contentType: 'network',
		priority: '',
		repeatCycle: '',
		shareable: true,
		state: 'active',
		title: '',
		type: 'todo',
	};

	/**
	 * Function to show image slideshow
	 * @param todo
	 */
	public selectedImages: any[] = [];

	ngOnInit(): void {
		this.activatedRoute.queryParams.subscribe((params) => {
			if (params['archived']) {
				this.isArchived = true;
			} else {
				this.isArchived = false;
			}

			if (params['id']) {
				const id = params['id'];
				this.todoListId = id;
				this.userService.isAuthenticated.subscribe((authenticated) => {
					if (!authenticated) {
						localStorage.setItem('pb-prev-url', this.router.url);
						this.router.navigateByUrl('/authentication/login');
					} else {
						this.todosFetched = 0;
						this.tab = 'all';
						this.todoItems = [];
						this.isHashTags = false;
						this.networkService.getToDoListDetail(id, this.isArchived).subscribe(
							(detail) => {
								this.todoList = detail;
								this.calculateProgressBar();
								this.collaboration = this.todoList.collaboration;
								if (this.todoList.user && this.todoList.user.username === this.currentUser.username) {
									this.collaboration = true;
								}

								if (this.todoItems.length === 0 && this.currentPage === 'todoListDetail') {
									if (localStorage.getItem('detail-tab')) {
										this.todosFetched = 1;
										if (localStorage.getItem('detail-tab') === 'myTasks') {
											this._displayMyTasks();
										} else if (localStorage.getItem('detail-tab') === 'completed-tasks') {
											this.displayCompletedTasks();
										} else {
											this.getToDoItems();
										}
									} else {
										this.todosFetched = 1;
										this._displayMyTasks();
									}
								}

								if (this.todoList.repeatCycle === 'one_off') {
									const formattedDate = new Date(this.todoList.oneOff).toLocaleString();
									this.todoList['formattedDate'] = formattedDate.substring(0, formattedDate.indexOf(','));
								}

								// if mute id exists,show unmute option
								if (this.todoList.muted) {
									this.showMute = false;
									this.muteId = this.todoList.muted;
								}

								this.todoDetail = this.todoList;
								this.todoList['privacy'] = this.todoList['channel'];
								this.network_id = this.todoList['uid'];
								this.privacy['custom_privacy'] = this.todoList['customPrivacy'];
								this.privacy['content_privacy'] = this.todoList['contentPrivacy'];
								this.privacy['channel'] = this.todoList['channel'];

								if (localStorage.getItem('MyTodolist')) {
									if (this.todoListId === localStorage.getItem('MyTodolist')) {
										this.privacyString = this.currentUser.name;
										this.showEditDelete = false;
									} else {
										this.updatePrivacyString(this.todoList);
									}
								}
							},
							(err) => {
								this.todosFetched = 1;
							}
						);
					}
				});
			}

			if (params['hash_tag']) {
				this.isHashTags = true;
				this.hashTag = '#' + params['name'];
				this.getTodosByHashtags(params['hash_tag']);
			}

			this.networkSubscriber = this.networkService.networkReady.subscribe((networkLoaded) => {
				if (networkLoaded) {
					this.currentNetSub = this.networkService.currentNetwork.subscribe((network) => {
						this.network = network;
						this.currentUser = this.userService.getCurrentUser();
						network.membership.memberRole.fields.forEach((element) => {
							this.departmentsReady = this.networkService.departmentsReady.subscribe((departmentsLoaded) => {
								if (departmentsLoaded) {
									this.currentDepartmentSubscriber = this.networkService.currentDepartments.subscribe((departments) => {
										if (departments && departments['objects'].length > 0) {
											this.departments = departments.objects;
										}
									});
								}
							});

							if (element.CP === true) {
								this.personalFilters.push(element);
							}

							if (network['membership']['memberRole'] && network['membership']['memberRole']['role']) {
								const memberRole = network['membership']['memberRole']['role'];
								if (memberRole.slug === 'external') {
									this.isExternalUser = true;
								}
							}
						});
						if (this.network.SubscriptionTemplates) {
							this.SubscriptionTemplates = this.network.SubscriptionTemplates;
						}
					});
				}
			});
		});

		// Replace todo item with updated todo item from edit
		this.postEdit.updatedPostEmitter.subscribe((data) => {
			if (data) {
				if (this.tab) {
					if (this.tab === 'all') {
						this.displayAllTodos();
					} else if (this.tab === 'myTasks') {
						this._displayMyTasks();
					} else if (this.tab === 'completed') {
						this.displayCompletedTasks();
					}
				} else {
					if (this.currentPage === 'calendarView' && !this.myTeamTasks) {
						if (this.uid && this.date) {
							this.getTodosForCalendar(this.uid, this.date);
						} else if (this.date && this.username) {
							this.calfilterTodos(this.date, this.username);
						}
					} else if (this.currentPage === 'calendarView' && this.myTeamTasks && this.date) {
						this.getMyTeamTasks();
					}
				}
			}
		});

		this.postEdit.updatedReminderEmitter.subscribe((data) => {
			if (data) {
				let index = -1;
				index = this.findWithAttr(this.todoItems, 'uid', data['todoUid']);
				if (index > -1) {
					this.todoItems[index]['myRelations']['reminder'] = data.reminder;
				}
				this.showOneOffDate();
			}
		});

		// Append new todo item to todoitems from post component
		this.postComponent.newPostEmitter.subscribe((data) => {
			if (data) {
				this.getListDetail();
				this.todoItems = [];
				if (this.tab === 'myTasks') {
					this._displayMyTasks();
				} else {
					this.getToDoItems();
				}
			}
		});

		// For removing backdrop and clearing posttype on closing add task form modal
		this.layoutService.showBackdrop.subscribe((data) => {
			if (data === 'event-post-form' || data === 'hide') {
				this.showPostCreateForm = false;
				this.postType = '';
			}
		});
		// Remove previous route from LS
		if (localStorage.getItem('previous_route')) {
			localStorage.removeItem('previous_route');
		}

		/**
		 * Search for todos/task in todo list detail page
		 */
		if (window.location.pathname === '/network/todolist-detail') {
			this.searchSubscription = this.searchService.searchValue.subscribe((data) => {
				if (this.isFirstSearch && data.trim()) {
					this.isFirstSearch = false;
				}
				if (!this.isFirstSearch) {
					this.todoItems = [];
					this.todosFetched = 0;
					let filter;
					if (data) {
						filter = 'query=' + data;
					} else {
						filter = 'open=true';
					}
					this.networkService.getToDoItems(this.todoListId, filter, this.isArchived).subscribe(
						(res) => {
							if (res.next) {
								this.item_next_url = res.next.split('alpha')[1];
							} else {
								this.item_next_url = '';
							}
							this.displayMyTasks = false;
							this.todoItems = res.objects;
							this.todosFetched = 1;
							this.handleRelatedContent();
							this.showOneOffDate();
							this.getEstimatedTime();
							this.checkUserMentionAndHashtagAndAlterBody();
						},
						(err) => {
							this.todosFetched = 1;
						}
					);
				}
			});
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.hasOwnProperty('todoTime')) {
			this.todoItems = [];
			this.filterTodos(this.filterParams);
		}

		if (changes.hasOwnProperty('timeNow')) {
			this.todoItems = [];
			if (this.myTeamTasks) {
				this.getMyTeamTasks();
			} else {
				if (this.uid && this.date) {
					this.getTodosForCalendar(this.uid, this.date);
				} else {
					this.calfilterTodos(this.date, this.username);
				}
			}
		}

		if (changes.hasOwnProperty('myTeamTasks') && changes.myTeamTasks.currentValue) {
			this.getMyTeamTasks();
		}
	}

	findWithAttr(array, attr, value) {
		for (let i = 0; i < array.length; i += 1) {
			if (array[i][attr] === value) {
				return i;
			}
		}
		return -1;
	}

	calculateProgressBar() {
		if (this.todoList) {
			this.progressWidth = '0%';
			// let closedCount;
			const openCount = this.todoList['openTodos'];
			const totalCount = this.todoList['openTodos'] + this.todoList['closedTodos'];
			const closedCount = totalCount - openCount;
			const c = totalCount / closedCount;
			const width = Math.floor(100 / c);
			this.progressWidth = width + '%';
		}
	}

	/**
	 * Function to get all todo items
	 * 1. Set todo items array empty
	 * 2. Get all todo items
	 * 3. Set next url
	 * 4. Call paginate function
	 * 5. Show one off date for items
	 */
	getToDoItems() {
		localStorage.setItem('detail-tab', 'all');
		this.tab = 'all';
		this.display_Completed = false;
		this.defaultTasks = true;
		this.sortByAssignee = false;
		this.todoItems = []; // 1
		this.displayMyTasks = false;
		this.filter['value'] = '';
		this.todosFetched = 0;
		this.networkService.getToDoItems(this.todoListId, 'active_filter=true', this.isArchived).subscribe(
			(data) => {
				// 2
				if (data.next) {
					this.item_next_url = data.next.split('alpha')[1]; // 3
				} else {
					this.item_next_url = '';
				}
				this.todoItems = data.objects;
				this.todosFetched = 1;
				this.handleRelatedContent();
				this.showOneOffDate(); // 4
				this.getEstimatedTime();
				this.checkUserMentionAndHashtagAndAlterBody();
			},
			(err) => {
				this.todosFetched = 1;
			}
		);
	}

	getTodosByHashtags(uid) {
		this.display_Completed = false;
		this.defaultTasks = true;
		this.sortByAssignee = false;
		this.todoItems = []; // 1
		this.displayMyTasks = false;
		this.filter['value'] = '';
		this.todosFetched = 0;
		this.networkService.getTodosByHash(uid).subscribe(
			(data) => {
				if (data.next) {
					this.item_next_url = data.next.split('alpha')[1];
					this.todosFetched = 0;
				} else {
					this.item_next_url = '';
				}
				this.todoItems = data.objects;
				this.todosFetched = 1;
				this.handleRelatedContent();
				this.showOneOffDate(); // 4
				this.getEstimatedTime();
				this.checkUserMentionAndHashtagAndAlterBody();
			},
			(err) => {
				this.todosFetched = 1;
			}
		);
	}

	/**
	 * Function to check the user mention and hash tag in post list if exists change the post body
	 * 1.If string contains '<',then its cut off while displaying in innerHtml.Hence its replaced by '< '
	 */
	private checkUserMentionAndHashtagAndAlterBody(): void {
		this.todoItems.forEach((todo) => {
			if (todo['title'] && todo['title'].indexOf('<') > -1) {
				// 1
				todo['title'] = todo['title'].replace(/</g, '< ');
			}

			todo['altered_title'] = todo['title'];
			if (todo['mentionedUsers']) {
				todo['mentionedUsers'].forEach((mentionedUser) => {
					// const html = '<b #userMention><a class="text-green" href="/network/profile/' + mentionedUser['memberId'] + '">@' + mentionedUser['name'] + '</a><b>';
					const html = '<b #userMention><a class="text-green">@' + mentionedUser['name'] + '</a></b>';
					if (todo.altered_title) {
						todo['altered_title'] = todo.altered_title.replace('@' + mentionedUser['name'], html);
					}
				});
			}
			if (todo['hashtags']) {
				todo['hashtags'].forEach((hashtag) => {
					if (hashtag['name']) {
						// const html = '<b #hashTag><a class="hash-todo" href="/network/todolist-detail?hash_tag=' + hashtag['uid'] + '&name=' + hashtag['name'] + '" >#' + hashtag['name'] + '</a></b>';
						const html = '<b #hashTag><a class="hash-todo" >#' + hashtag['name'] + '</a></b>';
						if (todo.altered_title) {
							todo['altered_title'] = todo.altered_title.replace('#' + hashtag['name'], html);
						}
					}
				});
			}

			todo.baseAttachment = this.attachmentService.parseBaseAttachment(todo.attachmentDetails, todo);
		});
	}

	handleRelatedContent() {
		this.todoItems.forEach((todo) => {
			if (todo['relatedContent'] && todo['relatedContent']['attachmentDetails'] && todo['relatedContent']['type']) {
				todo['attachmentDetails'] = todo['relatedContent']['attachmentDetails'];
			}

			todo.baseAttachment = this.attachmentService.parseBaseAttachment(todo.attachmentDetails, todo);
		});
	}

	/**
	 * Function to get remaining todoitems
	 *
	 * 1. Get all todo items
	 * 2. Set next url
	 * 3. Append items to existing array
	 * 4. Show one off date for items
	 */
	private paginateItems(): void {
		if (this.item_next_url) {
			this.todosFetched = 0;
			this.paginating = true;
			this.networkService.paginateToDoLists(this.item_next_url).subscribe(
				(data) => {
					// 1
					this.paginating = false;
					if (data.next) {
						this.item_next_url = data.next.split('alpha')[1]; // 2
					} else {
						this.item_next_url = '';
					}
					this.todoItems = this.todoItems.concat(data.objects); // 3
					this.todosFetched = 1;
					this.handleRelatedContent();
					this.showOneOffDate(); // 4
					this.getEstimatedTime();
					this.checkUserMentionAndHashtagAndAlterBody();
				},
				(error) => {
					this.todosFetched = 1;
				}
			);
		}
	}

	updatePrivacyString(todoList) {
		this.privacyString = '';
		if (todoList['customPrivacy']) {
			todoList['customPrivacy'].forEach((cp, index) => {
				this.privacyString = `${this.privacyString}${index > 0 ? ', ' : ' '}${cp.username}`;
			});
		} else if (todoList['contentPrivacy']) {
			if (todoList['contentPrivacy'].role && todoList['contentPrivacy'].department) {
				const index = this.departments.findIndex((department) => department.uid === Object.values(todoList['contentPrivacy']));
				if (index > -1) {
					this.privacyString = this.departments[index]['name'] + ' , ' + todoList['contentPrivacy'].role;
				}
			} else if (todoList['contentPrivacy'].role) {
				this.privacyString = todoList['contentPrivacy'].role;
				//
			} else if (todoList['contentPrivacy'].department) {
				const index = this.departments.findIndex((department) => department.uid === Object.values(todoList['contentPrivacy']));
				if (index > -1) {
					this.privacyString = this.departments[index]['name'];
				}
			} else if (!todoList['contentPrivacy'].role || !todoList['contentPrivacy'].department) {
				this.value = Object.keys(todoList['contentPrivacy']);
				// Handling Personal filters
				this.personalFilters.forEach((element) => {
					if (element.key === this.value[0]) {
						this.privacyString = element.valueLabel;
					}
				});
				// Handling Membership CP
				if (todoList['contentPrivacy'].status) {
					this.privacyString = todoList['contentPrivacy'].status;
				} else if (todoList['contentPrivacy'].renewalStatus) {
					this.privacyString = todoList['contentPrivacy'].renewalStatus;
				} else if (todoList['contentPrivacy'].membership_level) {
					const index = this.SubscriptionTemplates.findIndex((val) => val.uid === todoList['contentPrivacy'].membership_level);
					if (index > -1) {
						this.privacyString = this.SubscriptionTemplates[index]['name'];
					}
				} else if (todoList['contentPrivacy'].trialPeriod) {
					this.privacyString = todoList['contentPrivacy'].trialPeriod;
				} else if (todoList['contentPrivacy'].manager) {
					this.privacyString = this.translate.instant('My Team');
				}
			}
		} else if (todoList['channel'] && Object.keys(todoList['channel']).length) {
			this.privacyString = todoList['channel'].name;
		} else {
			this.privacyString = 'Everyone';
		}
	}

	// Delete a todo list
	onDeleteToDoList() {
		this.showDeleteConfirmation = 'block';
	}
	public hideDeleteConfirmation(): void {
		this.showDeleteConfirmation = 'none';
	}
	public onDeleteConfirm(): void {
		this.showDeleteConfirmation = 'none';
		this.loadingGif = 'block';
		this.networkService.deleteToDoList(this.todoList.uid).subscribe((data) => {
			this._location.back();
			this.loadingGif = 'none';
		});
	}

	ngOnDestroy(): void {
		if (this.currentNetSub) {
			this.currentNetSub.unsubscribe();
		}
		if (this.networkSubscriber) {
			this.networkSubscriber.unsubscribe();
		}
		if (this.paramsSubscriber) {
			this.paramsSubscriber.unsubscribe();
		}
		if (this.checkAccessibilitySub) {
			this.checkAccessibilitySub.unsubscribe();
		}
		if (this.paginateSub$) {
			this.paginateSub$.unsubscribe();
		}
		if (this.searchSubscription) {
			this.searchSubscription.unsubscribe();
		}
		if (this.departmentsReady) {
			this.departmentsReady.unsubscribe();
		}
		if (this.currentDepartmentSubscriber) {
			this.currentDepartmentSubscriber.unsubscribe();
		}
		if (this.countsSubscriber) {
			this.countsSubscriber.unsubscribe();
		}
		this.searchService.resetSearchValue();
	}

	// Function for mute todo list
	public onMuteClick(): void {
		this.loadingGif = 'block';
		this.networkService.muteContents(this.todoListId).subscribe((data) => {
			this.showMute = false;
			this.loadingGif = 'none';
		});
	}

	// Function for unmute todo list
	public onUnMuteClick(): void {
		this.loadingGif = 'block';
		this.networkService.unMuteContents(this.muteId).subscribe(
			(data) => {
				this.showMute = true;
				this.loadingGif = 'none';
			},
			(error) => {
				this.showMute = true;
				this.loadingGif = 'none';
			}
		);
	}

	/**
    *Function to go to todo lists page
    1. If todolist detail is opened from inapp notifications,go back to previous route stored in LS
    2. Go back to todo lists page
    */
	public onClickBack(): void {
		if (localStorage.getItem('todo-prev-route')) {
			// 1
			const url = localStorage.getItem('todo-prev-route');
			this.router.navigateByUrl(url);
			localStorage.removeItem('todo-prev-route');
		} else {
			this._location.back(); // 2
		}
	}

	/**
	 * Display delete todo item modal
	 */
	showDeleteItemModal(item) {
		if (item['repeatFreequency']) {
			this.repeatModal = 'block';
			this.selectedToDoItem = item;
		} else {
			this.deleteItem_modal = 'block';
			this.selectedToDoItem = item;
		}
		// this.deleteItem_modal = 'block';
		// this.selectedToDoItem = item;
	}

	/**
	 * Function to hide all modal
	 */
	public closeModal(): void {
		this.repeatModal = 'none';
		this.display_assignedTodos = 'none';
		this.deleteItem_modal = 'none';
		this.display_todoLists = 'none';
		this.display_reminderOptions = 'none';
		this.display_toast = 'none';
	}

	/**
	 * Function for delete todo on confirm
	 * @param param
	 */
	deleteToDoItem(param) {
		this.loadingGif = 'block';
		this.networkService.deleteToDoItem(this.selectedToDoItem['uid'], param).subscribe((data) => {
			this.closeModal();
			this.todoItems.splice(this.todoItems.indexOf(this.selectedToDoItem), 1);
			this.loadingGif = 'none';
			if (this.currentPage === 'todoListDetail') {
				this.getListDetail();
			} else if (this.currentPage === 'todoList') {
				this.refresh.emit();
			} else if (this.currentPage === 'calendarView') {
				this.resetCounts.emit();
			}

			if (this.tab === 'all') {
				this.displayAllTodos();
			} else if (this.tab === 'myTasks') {
				this._displayMyTasks();
			} else if (this.tab === 'completed') {
				this.displayCompletedTasks();
			}
		});
	}

	/**
	 * Function to get todolists for todo item
	 */
	showToDoActions(item, index) {
		this.selectedToDoItem = item;
		this.todoItemIndex = index;
	}

	/**
	 * Function to set radio btn on/off in todolist
	 */
	onClickToDoList(list) {
		this.lists = { option: list.uid };
		this.selectedToDoList = list;
	}

	/**
	 * Function to move todoitem to another list
	 */
	moveToAnotherList() {
		this.updateAttachmentDetails(this.selectedToDoItem);
		if (this.selectedToDoItem.assignedUser && this.selectedToDoItem.assignedUser.username) {
			this.todoItem['assignedUser'] = this.selectedToDoItem.assignedUser.username;
		}
		if (this.selectedToDoItem.repeatFreequency) {
			this.todoItem['repeatFreequency'] = this.selectedToDoItem.repeatFreequency;
		}
		if (this.selectedToDoItem.numberOfOpens && this.selectedToDoItem.numberOfOpens > 0) {
			this.todoItem['numberOfOpens'] = this.selectedToDoItem.numberOfOpens;
		}
		if (this.selectedToDoItem['project'] && this.selectedToDoItem['project']['uid']) {
			this.todoItem['project'] = this.selectedToDoItem['project']['uid'];
		}
		if (this.selectedToDoItem['postUid']) {
			this.comment.contentType = 'post';
			this.comment.contentUid = this.selectedToDoItem.postUid;
			this.comment['text'] = this.translate.instant(`${this.currentUser.name} has moved task to ${this.selectedToDoList['name']} list`);
		} else {
			this.comment.contentType = '';
			this.comment.contentUid = '';
		}

		this.todoItem.priority = this.selectedToDoItem.priority;
		this.todoItem.repeatCycle = this.selectedToDoItem.repeatCycle;
		this.todoItem.title = this.selectedToDoItem.title;
		this.todoItem['todolistUid'] = this.selectedToDoList.uid;
		this.todoItem['status'] = this.selectedToDoItem['status'];
		this.loadingGif = 'block';
		this.networkService.updateToDoItem(this.todoItem, this.selectedToDoItem.uid).subscribe(
			(data) => {
				delete this.todoItem['project'];
				delete this.todoItem['repeatFreequency'];
				delete this.todoItem['oneOff'];
				delete this.todoItem['numberOfOpens'];
				this.todoItems.splice(this.todoItemIndex, 1);
				this.display_todoLists = 'none';
				this.selectedToDoItem = '';
				this.lists['option'] = '';
				if (this.currentPage === 'todoListDetail') {
					this.getListDetail();
				}

				if (this.comment['contentType'] && this.comment['contentUid']) {
					this.commentService.create(this.comment, this.currentUser.username).subscribe(
						(commentData) => {
							this.loadingGif = 'none';
						},
						(error) => {
							this.loadingGif = 'none';
						}
					);
				} else {
					this.loadingGif = 'none';
				}
			},
			(error) => {
				this.loadingGif = 'none';
			}
		);
	}

	/**
	 * Function to show todo item edit modal and edit component
	 */
	onEditToDoItem(item) {
		this.editSelectedPost = item;
		if (item['folderDetails']) {
			if (item['folderDetails']['contentPrivacy']) {
				this.todoListPrivacy = item['folderDetails']['contentPrivacy'];
			} else if (item['folderDetails']['customPrivacy']) {
				this.todoListPrivacy = item['folderDetails']['customPrivacy'];
			} else if (item['folderDetails']['channel'] && item['folderDetails']['channel']['uid']) {
				const uid = item['folderDetails']['channel']['uid'];
				if (item['folderDetails']['channel']['access'] === 4) {
					this.todoListPrivacy = { channel: uid, access: 'external' };
				} else {
					this.todoListPrivacy = { channel: uid };
				}
			} else {
				this.todoListPrivacy = 'Everyone';
			}
		}
	}

	/**
	 * Function for done/undone actions
	 */
	onDoneUndoneClicked(item, value, index) {
		if (item['status'] === 'open' && value === 'closed' && !item['estimate']) {
			this.displayElapsed = 'block';
			setTimeout(() => {
				document.getElementById('elapsedTime').focus();
			}, 150);
			this.selectedToDoItem = item;
			this.todoItemIndex = index;
		} else {
			this.displayElapsed = 'none';
			if (value === 'closed' && !this.reaction && item['estimate']) {
				this.selectedItem = item;
				this.selectedToDoItem = item;
				this.selectedTodoIndex = index;
				this.selectedTodoValue = value;
				this.reaction = '';
				this.taskReactionDialog = 'block';
			} else {
				this.loadingGif = 'block';
				this.updateAttachmentDetails(item);
				this.todoItem.title = item.title;
				this.todoItem['status'] = value;
				this.todoItem.priority = item.priority;
				this.todoItem.repeatCycle = item.repeatCycle;
				if (item.assignedUser && item.assignedUser.username) {
					this.todoItem['assignedUser'] = item.assignedUser.username;
				}
				if (item.repeatCycle === 'one_off') {
					const date = item.oneOff;
					const splicedDate = date.split(' ')[0];
					this.todoItem['oneOff'] = splicedDate;
				}
				if (item.repeatFreequency) {
					this.todoItem['repeatFreequency'] = item.repeatFreequency;
				}
				if (item.numberOfOpens && item.numberOfOpens > 0) {
					this.todoItem['numberOfOpens'] = item.numberOfOpens;
				}
				if (item['project'] && item['project']['uid']) {
					this.todoItem['project'] = item['project']['uid'];
				}
				if (value === 'closed') {
					this.todoItem['closedDate'] = this.networkService.setCurrentUtcDate();
				}

				if (item['postUid']) {
					this.comment.contentType = 'post';
					this.comment.contentUid = item['postUid'];
				} else {
					this.comment.contentType = '';
					this.comment.contentUid = '';
				}

				if (this.estimateForm.valid) {
					this.todoItem['estimate'] = this.estimateForm.value['time'];
				} else {
					delete this.todoItem['estimate'];
				}

				if (this.elapsedForm.valid) {
					this.todoItem['elapsed_time'] = this.elapsedForm.value['time'];
				} else {
					delete this.todoItem['elapsed_time'];
				}

				if (this.reaction) {
					this.todoItem['outcome'] = this.reaction;
				}

				this.networkService.updateToDoItem(this.todoItem, item.uid).subscribe(
					(data) => {
						if (data.status === 'closed') {
							this.mixPanelService.track('Completed Task', {
								workspace: this.network?.name || 'NA',
								appVersion: environment.version,
								language: this.translate?.currentLang,
								username: this.currentUser?.username || 'NA',
								name: this.currentUser?.name || 'NA',
								project: data?.project?.name || 'NA',
								date: moment().format('YYYY-MM-DD'),
							});

							this.segmentService.trackEvent('Completed Task', {
								workspace: this.network?.name || 'NA',
								appVersion: environment.version,
								language: this.translate?.currentLang,
								username: this.currentUser?.username || 'NA',
								name: this.currentUser?.name || 'NA',
								project: data?.project?.name || 'NA',
								date: moment().format('YYYY-MM-DD'),
							});
						}
						this.loadingGif = 'none';
						this.squareSpinner = 'block';
						setTimeout(() => {
							this.squareSpinner = 'none';
						}, 1300);
						delete this.todoItem['closedDate'];
						delete this.todoItem['project'];
						delete this.todoItem['repeatFreequency'];
						delete this.todoItem['oneOff'];
						delete this.todoItem['numberOfOpens'];
						delete this.todoItem['estimate'];
						delete this.todoItem['elapsed_time'];
						this.selectedToDoItem = '';
						this.todoItemIndex = '';
						this.estimateForm.reset();
						this.elapsedForm.reset();
						this.reaction = '';

						index = this.findWithAttr(this.todoItems, 'uid', data['uid']);
						if (this.currentPage === 'todoListDetail') {
							this.getListDetail();
						} else if (this.currentPage === 'todoList') {
							this.resetCounts.emit();
						}

						if (index > -1) {
							this.todoItems[index] = data;
							this.showOneOffDate();
						} else {
							this.todoItems.unshift(data);
							this.showOneOffDate();
						}

						this.getEstimatedTime();
						this.checkUserMentionAndHashtagAndAlterBody();
						this.createAutoComments(value, data);
						this.createAutoCommentsForTask(value, data);
					},
					(error) => {
						this.loadingGif = 'none';
						setTimeout(() => {
							this.squareSpinner = 'none';
						}, 1000);
					}
				);
			}
		}
	}

	/** \
	 * THis will create comments when a todo is open/closed/paused etc..
	 */
	createAutoComments(status, todo) {
		if (status === 'closed') {
			// this.comment['text'] = this.translate.instant('Task Completion') + `: ${todo.title}`;

			this.comment['text'] = this.translate.instant('task-completion', {
				title: todo['title'],
				time: todo['elapsedTime'],
			});
		} else if (status === 'open') {
			this.comment['text'] = this.translate.instant('Task Reopened') + `: ${todo.title}`;
		} else if (status === 'in_progress') {
			this.comment['text'] = this.translate.instant('task-start', {
				title: todo['title'],
				time: todo['estimate'],
			});
		} else {
			this.comment['text'] = this.translate.instant('task-paused', {
				title: todo['title'],
			});
		}
		if (this.comment['contentType'] && this.comment['contentUid']) {
			this.commentService.create(this.comment, this.currentUser.username).subscribe(
				() => {
					// this.comment.contentType = 'todo';
					// this.comment.contentUid = todo['uid'];
					// if (status === 'in_progress') {
					//   this.comment.text = this.translate.instant('started', {
					//     time: todo['estimate']
					//   });
					// } else if (status === 'paused') {
					//   this.comment.text = this.translate.instant('Task Pause');
					// }else if(status === 'closed'){
					//   this.comment['text'] = this.translate.instant('Task Completion', {
					//     time: todo['elapsedTime']
					//   });
					// }else if (status === 'open') {
					//   this.comment['text'] = this.translate.instant('Task Reopened')
					// }
					// this.commentService.create(this.comment, this.currentUser.username).subscribe(() => {
					// });
				},
				(error) => {}
			);
		}
	}

	/**
	 * Create auto comment for subtasks actions like start/pause/finish etc.
	 * @param status
	 * @param todo
	 */
	createAutoCommentsForTask(status, todo) {
		const comment = {};
		comment['contentType'] = 'todo';
		comment['contentUid'] = todo['uid'];
		if (status === 'in_progress') {
			comment['text'] = this.translate.instant('started', {
				time: todo['estimate'],
			});
		} else if (status === 'paused') {
			comment['text'] = this.translate.instant('Task Pause');
		} else if (status === 'closed') {
			comment['text'] = this.translate.instant('Task Completion', {
				time: todo['elapsedTime'],
			});
		} else if (status === 'open') {
			comment['text'] = this.translate.instant('Task Reopened');
		}
		this.commentService.create(comment, this.currentUser.username).subscribe(() => {});
	}

	/**
	 * Pass attachment details on todo for done/undone or move actions
	 * @param post
	 */
	updateAttachmentDetails(todo) {
		delete this.todoItem['doc'];
		delete this.todoItem['url'];
		delete this.todoItem['attachmentType'];
		delete this.todoItem['images'];

		if (todo.attachmentDetails.gallery && todo.attachmentDetails.gallery.length > 0) {
			const images: any[] = [];
			this.todoItem['images'] = [];
			todo.attachmentDetails.gallery.forEach((img) => {
				this.todoItem['attachmentType'] = 'photo';
				images.push(img.image.uid);
			});
			this.todoItem['images'] = images.join('|');
		}

		if (todo.attachmentDetails.location && todo.attachmentDetails.location.length > 0) {
			this.todoItem['attachmentType'] = 'location';
			this.todoItem['location'] = todo.attachmentDetails.location[0].uid;
		}
		if (todo.attachmentDetails.doc && todo.attachmentDetails.doc[0].uid) {
			this.todoItem['attachmentType'] = 'doc';
			this.todoItem['doc'] = todo.attachmentDetails.doc[0].uid;
		}
		if (todo.attachmentDetails.url && todo.attachmentDetails.url[0].url) {
			this.todoItem['attachmentType'] = 'url';
			this.todoItem['url'] = todo.attachmentDetails.url[0].url;
		}
	}

	public getDownloadLink(attachment): string {
		return attachment['url'] + '?cType=' + attachment['type'] + '&file=' + attachment['name'];
	}

	/**
	 * Function to show one off date of a todo item
	 */
	showOneOffDate() {
		this.todoItems.forEach((item) => {
			if (item.repeatCycle === 'one_off' && item.oneOff) {
				const newDate = new Date(item.oneOff).toLocaleString();
				const month = new Date(item.oneOff).toLocaleString('default', {
					month: 'short',
				});
				const date = newDate.substring(0, newDate.indexOf('/'));
				const taskDate = date + '/' + month;
				item['newDate'] = taskDate;

				const date2 = new Date(item['oneOff']);
				item['oneOffDay'] = date2.getDate();
				const month2 = date2.toLocaleString('default', { month: 'long' });
				item['oneOffMonth'] = month2.substring(0, 3);
			}
		});
	}

	getEstimatedTime() {
		this.todoItems.forEach((item) => {
			if (item.startTime) {
				const localTime = new Date(item.startTime).toLocaleString();
				const d: any = new Date(item.startTime);
				const currentDate: any = new Date().toUTCString();
				if (currentDate > d) {
				} else {
					const sliced = localTime.slice(localTime.lastIndexOf(',') + 1, localTime.lastIndexOf(':'));
					item['localEstimatedTime'] = sliced;
				}
			} else if (item.estimatedTime) {
				const localTime = new Date(item.estimatedTime).toLocaleString();
				const d: any = new Date(item.estimatedTime);
				const currentDate: any = new Date().toUTCString();
				if (currentDate > d) {
				} else {
					const sliced = localTime.slice(localTime.lastIndexOf(',') + 1, localTime.lastIndexOf(':'));
					item['localEstimatedTime'] = sliced;
				}
			}

			if (item.dueTime && item.dueTime !== '0') {
				item['dueTimeExists'] = true;
			} else if (!item.dueTime || item.dueTime === '0') {
				if (item.estimatedTime) {
					const d = new Date(item.estimatedTime);
					const currentDate = new Date();
					if (currentDate > d) {
						item['dueTimeExists'] = true;
					} else {
						item['dueTimeExists'] = false;
					}
				} else {
					item['dueTimeExists'] = false;
				}
			}
		});
	}

	showOneOffDateFor() {
		this.assignedItems.forEach((item) => {
			if (item.repeatCycle === 'one_off' && item.oneOff) {
				const newDate = new Date(item.oneOff).toLocaleString();
				item['newDate'] = newDate.substring(0, newDate.indexOf(','));
			}
		});
	}

	/**
	 * Function to display modal for start and end day action containing assigned items
	 */
	displayAssignedTodos(value) {
		this.display_assignedTodos = 'block';
		if (value === 'start') {
			this.assignedItems = [];
			const filterString = 'assigned=true&&open=true';
			this.networkService.getToDoItems(this.todoListId, filterString, this.isArchived).subscribe((data) => {
				if (data.next) {
					this.start_next_url = data.next.split('alpha')[1];
					this.paginateStartItems();
				} else {
					this.start_next_url = '';
				}
				this.assignedItems = data.objects;
				this.showOneOffDateFor();
				this.setStartDayComment = true;

				if (this.assignedItems.length === 0) {
					this.noAssignedItems = true;
				} else {
					this.noAssignedItems = false;
					this.assignedItems.forEach((item) => {
						item['checked'] = false;
					});
				}
			});
		} else {
			this.assignedItems = [];
			const filterString = `assigned=true&closedDate=${new Date().toISOString().slice(0, 10)}`;
			this.networkService.getToDoItems(this.todoListId, filterString, this.isArchived).subscribe((data) => {
				if (data.next) {
					this.start_next_url = data.next.split('alpha')[1];
					this.paginateStartItems();
				} else {
					this.start_next_url = '';
				}
				this.assignedItems = data.objects;
				this.showOneOffDateFor();
				this.setStartDayComment = false;

				if (this.assignedItems.length === 0) {
					this.noAssignedItems = true;
				} else {
					this.noAssignedItems = false;
					this.assignedItems.forEach((item) => {
						item['checked'] = false;
					});
				}
			});
		}
	}

	/**
	 * Function to get remaining todoitems for start day action
	 */
	paginateStartItems() {
		if (this.start_next_url) {
			this.paginating = true;
			this.networkService.paginateToDoLists(this.start_next_url).subscribe((data) => {
				this.paginating = false;
				if (data.next) {
					this.start_next_url = data.next.split('alpha')[1];
				} else {
					this.start_next_url = '';
				}
				this.assignedItems = this.assignedItems.concat(data.objects);
				this.showOneOffDateFor();
				if (this.start_next_url) {
					this.paginateStartItems();
				}
			});
		}
	}

	/**
	 * Function when selecting items from assigned tasks modal
	 */
	selectAssignedItems(item) {
		if (this.selectedTodos.includes(item.title) === false) {
			this.selectedTodos.push(item.title);
		} else {
			this.selectedTodos.splice(
				this.selectedTodos.findIndex((val) => val === item.title),
				1
			);
		}
	}

	/**
	 * Function to get all completed tasks and display it
	 */
	displayCompletedTasks() {
		if (this.todosFetched === 1) {
			localStorage.setItem('detail-tab', 'completed-tasks');
			this.tab = 'completed';
			this.filter.value = '';
			this.displayMyTasks = false;
			this.sortByAssignee = false;
			this.defaultTasks = false;
			this.todoItems = [];
			this.display_Completed = true;
			const filterString = 'completed=true';
			this.todosFetched = 0;
			this.networkService.getToDoItems(this.todoListId, filterString, this.isArchived).subscribe(
				(completedTasks) => {
					this.todoItems = completedTasks.objects;
					this.todosFetched = 1;
					this.handleRelatedContent();
					this.showOneOffDate();
					this.getEstimatedTime();
					this.checkUserMentionAndHashtagAndAlterBody();
					if (completedTasks.next) {
						this.item_next_url = completedTasks.next.split('alpha')[1];
					} else {
						this.item_next_url = '';
					}
				},
				(error) => {
					this.todosFetched = 1;
				}
			);
		}
	}

	/**
	 * Function to get all assigned tasks and display it
	 */
	_displayMyTasks() {
		if (this.todosFetched === 1) {
			localStorage.setItem('detail-tab', 'myTasks');
			this.tab = 'myTasks';
			this.filter.value = '';
			this.display_Completed = false;
			this.sortByAssignee = false;
			this.defaultTasks = false;
			this.displayMyTasks = true;
			this.todoItems = [];
			this.todosFetched = 0;
			const filterString = 'assigned=true&active_filter=true';
			this.networkService.getToDoItems(this.todoListId, filterString, this.isArchived).subscribe(
				(myTasks) => {
					this.todoItems = myTasks.objects;
					this.todosFetched = 1;
					this.handleRelatedContent();
					this.showOneOffDate();
					this.getEstimatedTime();
					this.checkUserMentionAndHashtagAndAlterBody();
					if (myTasks.next) {
						this.item_next_url = myTasks.next.split('alpha')[1];
					} else {
						this.item_next_url = '';
					}
				},
				(error) => {
					this.todosFetched = 1;
				}
			);
		}
	}

	displayAllTodos() {
		if (this.tab === 'all') {
			this.getToDoItems();
		} else {
			if (this.todosFetched === 1) {
				this.getToDoItems();
			}
		}
	}

	/**
	 * Function to get open todo tasks
	 */
	displayOpenTodos() {
		this.display_Completed = false;
		this.todoItems = [];
		this.getToDoItems();
	}

	/**
	 * Function which activates plus button and displays modal
	 */
	public activateButton(): void {
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
		this.layoutService.hideBd();
		this.showAddTaskForm();
	}

	/**
	 * Function which  displays add task form
	 */
	showAddTaskForm() {
		this.showPostCreateForm = !this.showPostCreateForm;
		if (this.showPostCreateForm) {
			this.layoutService.showBd('event-post-form');
			this.postType = 'add-task';
		}
	}

	/**
	 * Function to Redirect to post detail page
	 */
	checkIfRedirect(e, item) {
		if (e.target && e.target.className) {
			const type = e.target.className;

			if (type === 'hash-todo') {
				const text = e.target.innerHTML.split('#')[1],
					hashtags = item['hashtags'];

				hashtags.forEach((tag) => {
					if (tag['name'] === text) {
						this.router.navigateByUrl('/network/todolist-detail?hash_tag=' + tag['uid'] + '&name=' + tag['name']);
					}
				});
			} else if (type === 'text-green') {
				const text = e.target.innerHTML.split('@')[1];

				item['mentionedUsers'].forEach((mentionedUser) => {
					if (mentionedUser['name'] === text) {
						this.router.navigateByUrl('/network/profile/' + mentionedUser?.memberId + '?username=' + mentionedUser?.username);
					}
				});
			} else {
				if (
					type !== 'pb-icon pb-like' &&
					type !== 'pb-icon pb-liked' &&
					type !== 'pb-icon pb-like ng-star-inserted' &&
					type !== 'pb-icon pb-liked ng-star-inserted' &&
					type !== 'edit-img' &&
					type !== 'dropdown-item dropdown-border text-center' &&
					type !== 'todo-off' &&
					type !== 'todo-on' &&
					type !== 'todo-off ng-star-inserted' &&
					type !== 'todo-on ng-star-inserted' &&
					type !== 'dropdown-item dropdown-border text-center ng-star-inserted'
				) {
					this.handleRedirections(item);
				}
			}
		} else {
			this.handleRedirections(item);
		}
	}

	handleRedirections(item) {
		if (item.isManagerTask) {
			console.log(item);
			return;
		} else if (item.relatedContent && item.relatedContent.contentType) {
			localStorage.setItem('previous_route', this.router.url);
			if (item.relatedContent.type && item.relatedContent.type !== 'customer') {
				if (item.relatedContent.contentType === 'post') {
					this.router.navigateByUrl('/posts/' + item.relatedContent.contentUid);
				} else if (item.relatedContent.contentType === 'issue') {
					this.router.navigateByUrl(`/network/issue-detail?id=${item.relatedContent.contentUid}`);
				}
			} else {
				this.router.navigateByUrl(`/network/customer/${item.relatedContent.contentUid}`);
			}
		} else {
			if (item['folderDetails'] && item['folderDetails']['uid']) {
				let url;
				if (this.isArchived) {
					url = `/project/task-detail/${item.uid}&listUid=${item['folderDetails']['uid']}&archived=true`;
				} else {
					url = `/project/task-detail/${item.uid}`;
				}
				this.router.navigateByUrl(url);
			} else {
				let url;
				if (this.isArchived) {
					url = `/project/task-detail/${item.uid}&archived=true`;
				} else {
					url = `/project/task-detail/${item.uid}`;
				}
				this.router.navigateByUrl(url);
			}
		}
	}

	/**
	 * Function for sorting todo items
	 */
	sortTodoItems(key) {
		this.display_Completed = false;
		this.defaultTasks = false;
		this.todoItems = [];
		this.todosFetched = 0;
		this.filter['value'] = key;
		// let filterString;
		const filterString = key + '&active_filter=true';
		this.networkService.getToDoItems(this.todoListId, filterString, this.isArchived).subscribe(
			(data) => {
				if (data.next) {
					this.item_next_url = data.next.split('alpha')[1];
				} else {
					this.item_next_url = '';
				}
				this.displayMyTasks = false;
				this.todoItems = data.objects;
				this.todosFetched = 1;
				this.showOneOffDate();
				this.checkUserMentionAndHashtagAndAlterBody();
			},
			(err) => {
				this.todosFetched = 1;
			}
		);
	}

	filterTodos(params) {
		let filterString = '';
		if (params === 'assigned_to=true&open=true') {
			filterString = params;
		} else {
			filterString = params + '&assigned=true';
		}
		this.todosFetched = 0;
		this.networkService.newFilterTodos(filterString).subscribe(
			(data) => {
				if (data.next) {
					this.item_next_url = data.next.split('alpha')[1];
				} else {
					this.item_next_url = '';
				}
				this.todoItems = data.objects;
				this.todosFetched = 1;
				this.showOneOffDate();
				this.getEstimatedTime();
				this.checkUserMentionAndHashtagAndAlterBody();
				this.listTodosFetching.emit();
			},
			(err) => {
				this.todosFetched = 1;
			}
		);
	}

	calfilterTodos(params, username) {
		let filterString;
		if (localStorage.getItem('uid') === username) {
			filterString = params;
		} else {
			filterString = params;
		}
		this.todoItems = [];
		this.todosFetched = 0;

		this.networkService.calfilterTodos(filterString, username).subscribe(
			(data) => {
				if (data.next) {
					this.item_next_url = data.next.split('alpha')[1];
				} else {
					this.item_next_url = '';
				}
				this.todoItems = data.objects;
				this.todosFetched = 1;
				this.showOneOffDate();
				this.getEstimatedTime();
				this.checkUserMentionAndHashtagAndAlterBody();
				this.listTodosFetching.emit();
			},
			(err) => {
				this.todosFetched = 1;
			}
		);
	}

	getTodosForCalendar(uid, date) {
		this.todoItems = [];
		this.todosFetched = 0;
		this.networkService.getToDoItems(uid, date, this.archivedList).subscribe(
			(data) => {
				if (data.next) {
					this.item_next_url = data.next.split('alpha')[1];
					this.todosFetched = 0;
				} else {
					this.item_next_url = '';
				}
				this.todoItems = data.objects;
				this.todosFetched = 1;
				this.handleRelatedContent();
				this.showOneOffDate();
				this.getEstimatedTime();
				this.checkUserMentionAndHashtagAndAlterBody();
			},
			(err) => {
				this.todosFetched = 1;
			}
		);
	}

	getAllTodoLists() {
		this.display_todoLists = 'block';
		this.todoListsForItem = [];
		this.next_url = '';
		this.networkService.getToDoListForItem().subscribe((list) => {
			this.todoListsForItem = list.objects;
			if (list.next) {
				this.next_url = list.next.split('alpha')[1];
				this.paginateLists();
			} else {
				this.next_url = '';
			}

			if (this.selectedToDoItem['folderDetails'] && this.selectedToDoItem['folderDetails']['uid']) {
				this.lists['option'] = this.selectedToDoItem['folderDetails']['uid'];
			} else {
				this.lists['option'] = '';
			}
		});
	}

	/**
	 * Function to get remaining todolists
	 */
	paginateLists() {
		if (this.next_url) {
			this.networkService.paginateToDoLists(this.next_url).subscribe((data) => {
				this.next_url = data.next ? data.next.split('alpha')[1] : '';

				this.todoListsForItem = this.todoListsForItem.concat(data.objects);
				if (this.next_url) {
					this.paginateLists();
				}

				if (this.selectedToDoItem['folderDetails'] && this.selectedToDoItem['folderDetails']['uid']) {
					this.lists['option'] = this.selectedToDoItem['folderDetails']['uid'];
				} else {
					this.lists['option'] = '';
				}
			});
		}
	}

	/**
	 * Function to execute like on todo
	 * @param todo
	 * 1. Call like api
	 * 2. Set like uid in myRelations of todo detail object
	 * 3. Set liked text within todo object
	 */
	likeTodo(todo) {
		this.postService.like(this.currentUser.username, todo).subscribe((data) => {
			// 1
			todo.myRelations = { like: data.uid }; // 2
			if (todo['myRelations'] && todo['myRelations'].like && todo['likesCount']['count'] === 0) {
				// 3
				todo['likedText'] = this.translate.instant('You liked this');
			}
		});
	}

	/**
	 * Function to execute dislike on todo
	 * @param todo
	 * 1. Call dislike api
	 * 2. Remove like uid from myRelations of todo object
	 */
	dislikeTodo(todo) {
		this.postService.dislike(this.currentUser.username, todo.myRelations.like).subscribe((data) => {
			// 1
			todo.myRelations = { like: null };
			todo['likedText'] = ''; // 2
			todo['likesCount']['count'] = 0;
		});
	}

	public handleLinkShare(link: string): void {
		const text = this.translate.instant('listInvite', {
			username: this.currentUser.name,
			listName: this.todoDetail['name'],
			networkName: this.network['name'],
			inviteLink: link,
		});

		this.clipboardService.copy(link);
	}

	public handleTodoLinkShare(todo: any): void {
		this.clipboardService.copy(`${todo.title} ${todo.shareUrl}`);
	}

	getListDetail() {
		this.countsSubscriber = this.networkService.getToDoListDetail(this.todoListId, this.isArchived).subscribe((detail) => {
			this.todoList = detail;
			this.calculateProgressBar();
		});
	}

	handleArchive(state) {
		const archived = {};
		if (state === 'archive') {
			archived['archived'] = 'true';
		} else {
			archived['archived'] = 'false';
		}
		archived['state'] = 'active';
		archived['name'] = this.todoList['name'];
		if (this.todoList['network']) {
			archived['network'] = this.todoList['network'];
		}
		if (this.todoList['user']['username']) {
			archived['username'] = this.todoList['user']['username'];
		}
		if (this.todoList['repeatCycle']) {
			archived['repeatCycle'] = this.todoList['repeatCycle'];
		}
		if (this.todoList['oneOff']) {
			const date = new Date(this.todoList.oneOff);
			const startMonth = date.getUTCMonth() + 1;
			const startDay = date.getUTCDate();
			const startYear = date.getUTCFullYear();
			const startHours = date.getUTCHours();
			const startMinutes = date.getUTCMinutes();
			const startSeconds = date.getUTCSeconds();
			const utcDate = startYear + '-' + startMonth + '-' + startDay + ' ' + startHours + ':' + startMinutes + ':' + startSeconds;
			archived['oneOff'] = utcDate;
		}
		if (this.todoList['collarboration']) {
			archived['collaboration'] = this.todoList['collaboration'];
		}
		if (this.todoList['contentPrivacy']) {
			archived['content_privacy'] = JSON.stringify(this.todoList['contentPrivacy']);
		} else if (this.todoList['customPrivacy'] && this.todoList['customPrivacy'].length > 0) {
			const customPrivacy: any[] = [];
			// let custom: any;
			this.todoList.customPrivacy.forEach((element) => {
				if (element.userId) {
					customPrivacy.push(element.userId);
				} else {
					customPrivacy.push(element.id);
				}
			});
			const custom = '[' + customPrivacy.join(',') + ']';
			archived['custom_privacy'] = custom;
		} else if (this.todoList['channel'] && this.todoList['channel']['uid']) {
			archived['channel'] = this.todoList['channel']['uid'];
		}

		this.loadingGif = 'block';
		this.networkService.archivedList = this.todoList['uid'];
		this.networkService.updateToDoList(archived, this.todoList['uid']).subscribe(
			(data) => {
				this.loadingGif = 'none';
				this.todoList = data;
				this._location.back();
			},
			(error) => {
				console.error(error);
			}
		);
	}

	redirectToCalendar() {
		let url;
		if (this.isArchived) {
			url = `/network/calendar?id=${this.todoList.uid}&archived=true`;
		} else {
			url = `/network/calendar?id=${this.todoList.uid}`;
		}
		this.router.navigateByUrl(url);
		localStorage.removeItem('detail-tab');
	}

	/**
	 * On clicking reminder options
	 * @param reminder
	 */
	public onSelectReminder(reminder): void {
		const index = this.remindersSelected.findIndex((r) => r === reminder);
		if (index > -1) {
			this.remindersSelected.splice(index, 1);
		} else {
			this.remindersSelected.push(reminder);
		}
	}

	/**
	 * For displaying reminder options
	 * @param todo
	 */
	displayReminders(todo) {
		this.todoForReminder = todo;
		this.displayReminderModal = 'block';
		this.reminderOptions = [];
		this.networkService.getReminderOptions().subscribe((data) => {
			if (todo['estimatedTime']) {
				data.eventReminderOptions.forEach((obj) => {
					if (
						obj[0] !== 'ten_minutes_before' &&
						obj[0] !== 'fifteen_minutes_before' &&
						obj[0] !== 'one_day_before' &&
						obj[0] !== 'two_day_before' &&
						obj[0] !== 'one_week_before'
					) {
						this.reminderOptions.push(obj);
					}
				});
			} else {
				this.reminderOptions = data.reminderOptions;
			}
			if (this.remindersSelected.length > 0) {
				this.setPreviousReminders();
			}
		});
	}

	/**
	 * For creating a new reminders for content
	 */
	public setReminder(): void {
		if (this.remindersSelected.length > 0) {
			const object = {};
			this.remindersSelected.forEach((reminder) => {
				object[reminder] = this.setReminderDate(reminder);
			});
			const param = JSON.stringify(object);
			this.model.contentType = 'todo';
			this.model.contentUid = this.todoForReminder.uid;
			this.model.username = this.currentUser.username;
			this.model.repeat = true;

			if (this.todoForReminder['estimatedTime']) {
				delete this.model['reminderOption'];
				this.model['eventReminderOption'] = param;
			} else {
				this.model.reminderOption = param;
			}

			this.loadingGif = 'block';
			this.networkService.createReminder(this.model).subscribe((data) => {
				this.todoForReminder['myRelations']['reminder'] = data['uid'];
				this.loadingGif = 'none';
				this.resetReminders();
				this.reminderToast = true;
				setTimeout(() => {
					this.reminderToast = false;
				}, 8000);
			});
		} else {
			if (this.todoForReminder['myRelations'] && this.todoForReminder['myRelations']['reminder']) {
				this.deleteReminders();
			} else {
				this.resetReminders();
			}
		}
	}

	public deleteReminders(): void {
		this.model.contentUid = this.todoForReminder.uid;
		this.model.username = this.currentUser.username;
		this.model.repeat = true;
		this.model.contentType = 'todo';
		this.loadingGif = 'block';
		this.networkService.createReminder(this.model).subscribe((data) => {
			this.todoForReminder['myRelations']['reminder'] = '';
			this.loadingGif = 'none';
			this.resetReminders();
		});
	}

	/**
	 * For calculating corresponding to reminder selected
	 * @param option
	 */
	public setReminderDate(option): string {
		if (option === 'in_twenty_minutes') {
			this.timeFor = 20;
		} else if (option === 'in_one_hour') {
			this.timeFor = 60;
		} else if (option === 'in_three_hours') {
			this.timeFor = 180;
		} else if (option === 'tomorrow') {
			this.timeFor = this.minutesUntilMidnight() + 540;
		} else if (option === 'next_week') {
			this.timeFor = 6300;
		} else if (option === 'event_time') {
			this.timeFor = -1;
		} else if (option === 'five_minutes_before') {
			this.timeFor = 5;
		} else if (option === 'thirty_minutes_before') {
			this.timeFor = 30;
		} else if (option === 'one_hour_before') {
			this.timeFor = 60;
		} else if (option === 'two_hour_before') {
			this.timeFor = 120;
		}

		let date;
		if (this.todoDetail['estimatedTime']) {
			if (this.timeFor === -1) {
				date = new Date(this.todoDetail['estimatedTime']);
			} else {
				date = new Date(this.todoDetail['estimatedTime']);
				date.setMinutes(date.getMinutes() - this.timeFor);
			}
		} else {
			date = new Date();
			date.setMinutes(date.getMinutes() + this.timeFor);
		}

		const month = date.getUTCMonth() + 1;
		const day = date.getUTCDate();
		const year = date.getUTCFullYear();
		const hours = date.getUTCHours();
		const minutes = date.getUTCMinutes();
		const seconds = date.getUTCSeconds();
		const utcDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
		return utcDate;
	}

	/**
	 * Get previous reminders of content
	 */
	getReminderDetails(todo) {
		this.loadingGif = 'block';
		this.networkService.getContentReminders('todos', todo['uid']).subscribe((data) => {
			this.loadingGif = 'none';
			this.displayReminders(todo);

			if (data.objects && data.objects.length > 0) {
				data.objects.forEach((element) => {
					if (element['reminderOption']) {
						const ind1 = this.remindersSelected.findIndex((reminder) => reminder === element['reminderOption']);
						if (ind1 === -1) {
							this.remindersSelected.push(element['reminderOption']);
						}
					} else if (element['eventReminderOption']) {
						const ind1 = this.remindersSelected.findIndex((reminder) => reminder === element['eventReminderOption']);
						if (ind1 === -1) {
							this.remindersSelected.push(element['eventReminderOption']);
						}
					}
				});
			}
			this.setPreviousReminders();
		});
	}

	/**
	 * Set Previous reminders on reminder options
	 */
	private setPreviousReminders(): void {
		this.reminderOptions.forEach((reminder) => {
			this.remindersSelected.forEach((r) => {
				if (r === reminder[0]) {
					reminder['checked'] = true;
				}
			});
		});
	}

	/**
	 * For resetting reminders in reminder options modal
	 */
	public resetReminders(): void {
		this.displayReminderModal = 'none';
		this.timeFor = 0;
		this.todoForReminder = '';
		this.remindersSelected = [];
		delete this.model['eventReminderOption'];
		this.model = {
			contentType: '',
			contentUid: '',
			repeat: true,
			reminderOption: '',
			username: '',
		};
		this.reminderOptions.forEach((r) => {
			r['checked'] = false;
		});
	}

	private minutesUntilMidnight(): any {
		const midnight = new Date();
		midnight.setHours(24);
		midnight.setMinutes(0);
		midnight.setSeconds(0);
		midnight.setMilliseconds(0);
		return (midnight.getTime() - new Date().getTime()) / 1000 / 60;
	}

	/**
	 * For getting subtasks of a todo
	 * @param todo
	 */
	getSubtasks(todo, event) {
		if (event.target.className.indexOf('issue-up') > -1) {
			todo['subtasks'] = [];
			const total = todo['totalSubTasks'];
			this.numbers = Array(total)
				.fill(0)
				.map((x, i) => i);

			this.networkService.getSubtasks(todo['uid'], todo['totalSubTasks'], '', '').subscribe((data) => {
				todo['subtasks'] = data.objects;
				this.checkUserMentionAndHashtagForSubtasks(todo);
				this.numbers = [];
				if (data.next) {
					this.subNextUrl = data.next.split('alpha')[1];
				} else {
					this.subNextUrl = '';
				}
			});
		}
	}

	/**
	 * Check user/hash mentions for subtasks in a todo
	 * @param todo
	 */
	checkUserMentionAndHashtagForSubtasks(todo) {
		todo['subtasks'].forEach((task) => {
			task['altered_title'] = task['title'];
			if (task['mentionedUsers']) {
				task['mentionedUsers'].forEach((mentionedUser) => {
					const html = '<b #userMention><a class="text-green">@' + mentionedUser['name'] + '</a></b>';
					if (task.altered_title) {
						task['altered_title'] = task.altered_title.replace('@' + mentionedUser['name'], html);
					}
				});
			}
			if (task['hashtags']) {
				task['hashtags'].forEach((hashtag) => {
					if (hashtag['name']) {
						const html = '<b #hashTag><a class="hash-todo">#' + hashtag['name'] + '</a></b>';
						if (task.altered_title) {
							task['altered_title'] = task.altered_title.replace('#' + hashtag['name'], html);
						}
					}
				});
			}
		});
	}

	/**
	 * Manage start/pause/finish actions of subtasks under a task
	 * @param mainTask
	 * @param subtask
	 * @param state
	 * @param mainIndex
	 * @param subIndex
	 */
	manageSubtaskState(mainTask, subtask, state, mainIndex, subIndex) {
		if (subtask['status'] === 'open' && state === 'closed' && !subtask['estimate']) {
			this.displayElapsed = 'block';
			setTimeout(() => {
				document.getElementById('elapsedTime').focus();
			}, 150);
			this.mainTask = mainTask;
			this.selectedToDoItem = subtask;
			this.todoItemIndex = mainIndex;
			this.subIndex = subIndex;
		} else {
			this.displayElapsed = 'none';
			if (state === 'closed' && !this.reaction && subtask['estimate']) {
				this.taskReactionDialog = 'block';
				this.mainTask = mainTask;
				this.selectedToDoItem = subtask;
				this.todoItemIndex = mainIndex;
				this.subIndex = subIndex;
			} else {
				this.checkForSubtaskMentions(subtask);
				this.subtask.title = subtask.title;
				this.subtask['status'] = state;
				this.subtask.repeatCycle = subtask.repeatCycle;
				this.subtask['parent_content_type'] = 'todo';
				this.subtask['parent_content_uid'] = mainTask['uid'];

				if (subtask['mentioned_users']) {
					this.subtask['mentioned_users'] = subtask['mentioned_users'];
				} else if (subtask['hashtags']) {
					this.subtask['hashtags'] = subtask['hashtags'];
				}

				if (subtask.assignedUser && subtask.assignedUser.username) {
					this.subtask.assignedUser = subtask.assignedUser.username;
				} else {
					delete this.subtask['assignedUser'];
				}

				if (subtask.repeatCycle === 'one_off') {
					const date = subtask.oneOff;
					const splicedDate = date.split(' ')[0];
					this.subtask['oneOff'] = splicedDate;
				}

				if (subtask['project'] && subtask['project']['uid']) {
					this.subtask['project'] = subtask['project']['uid'];
				}

				if (state === 'closed') {
					this.subtask['closedDate'] = this.networkService.setCurrentUtcDate();
				}

				if (this.estimateForm.valid) {
					this.subtask['estimate'] = this.estimateForm.value['time'];
				} else {
					delete this.subtask['estimate'];
				}

				if (this.elapsedForm.valid) {
					this.subtask['elapsed_time'] = this.elapsedForm.value['time'];
				} else {
					delete this.subtask['elapsed_time'];
				}

				if (this.reaction) {
					this.subtask['outcome'] = this.reaction;
				}

				this.loadingGif = 'block';
				this.networkService.updateToDoItem(this.subtask, subtask.uid).subscribe(
					(data) => {
						delete this.subtask['estimate'];
						delete this.subtask['elapsed_time'];
						delete this.subtask['project'];
						delete this.subtask['oneOff'];
						delete this.subtask['closedDate'];
						this.reaction = '';
						this.getTodoDetails(mainTask, mainIndex);
						this.setMentionsAfterEdit(mainTask, data, subIndex);
						this.createSubtaskAutoComments(mainTask, state, data);
						this.createAutoCommentFromSubTaskToPost(state, data);
						this.reset();
						this.subtask = new Subtask();
						this.loadingGif = 'none';
						this.squareSpinner = 'block';
						setTimeout(() => {
							this.squareSpinner = 'none';
						}, 1300);
					},
					(error) => {
						this.loadingGif = 'none';
					}
				);
			}
		}
	}

	/**
	 * Function to close parent task if all subtasks are closed;
	 * @param todo
	 */
	closeParentTask(todo) {
		if (todo['status'] === 'closed') {
			return;
		}
		if (todo['subtasks'] && todo['subtasks'].length) {
			const unClosedTask = todo['subtasks'].find((li) => li.status !== 'closed');
			if (!unClosedTask) {
				const index = this.todoItems.findIndex((li) => li.uid === todo.uid);
				this.loadingGif = 'block';
				this.updateAttachmentDetails(todo);
				this.todoItem.title = todo.title;
				this.todoItem['status'] = 'closed';
				this.todoItem.priority = todo.priority;
				this.todoItem.repeatCycle = todo.repeatCycle;
				if (todo.assignedUser && todo.assignedUser.username) {
					this.todoItem['assignedUser'] = todo.assignedUser.username;
				}
				if (todo.repeatCycle === 'one_off') {
					const date = todo.oneOff;
					const splicedDate = date.split(' ')[0];
					this.todoItem['oneOff'] = splicedDate;
				}
				if (todo.repeatFreequency) {
					this.todoItem['repeatFreequency'] = todo.repeatFreequency;
				}
				if (todo.numberOfOpens && todo.numberOfOpens > 0) {
					this.todoItem['numberOfOpens'] = todo.numberOfOpens;
				}
				if (todo['project'] && todo['project']['uid']) {
					this.todoItem['project'] = todo['project']['uid'];
				}
				this.todoItem['closedDate'] = this.networkService.setCurrentUtcDate();

				if (todo['postUid']) {
					this.comment.contentType = 'post';
					this.comment.contentUid = todo['postUid'];
				} else {
					this.comment.contentType = '';
					this.comment.contentUid = '';
				}

				if (!todo['estimate']) {
					this.todoItem['elapsed_time'] = 0;
				}
				this.todoItem['outcome'] = 'happy';
				this.networkService.updateToDoItem(this.todoItem, todo.uid).subscribe(
					(data) => {
						if (data.status === 'closed') {
							this.mixPanelService.track('Completed Task', {
								workspace: this.network?.name || 'NA',
								appVersion: environment.version,
								language: this.translate?.currentLang,
								username: this.currentUser?.username || 'NA',
								name: this.currentUser?.name || 'NA',
								project: data?.project?.name || 'NA',
								date: moment().format('YYYY-MM-DD'),
							});

							this.segmentService.trackEvent('Completed Task', {
								workspace: this.network?.name || 'NA',
								appVersion: environment.version,
								language: this.translate?.currentLang,
								username: this.currentUser?.username || 'NA',
								name: this.currentUser?.name || 'NA',
								project: data?.project?.name || 'NA',
								date: moment().format('YYYY-MM-DD'),
							});
						}
						this.loadingGif = 'none';
						this.squareSpinner = 'block';
						setTimeout(() => {
							this.squareSpinner = 'none';
						}, 1300);
						delete this.todoItem['closedDate'];
						delete this.todoItem['project'];
						delete this.todoItem['repeatFreequency'];
						delete this.todoItem['oneOff'];
						delete this.todoItem['numberOfOpens'];
						delete this.todoItem['estimate'];
						delete this.todoItem['elapsed_time'];
						this.selectedToDoItem = '';
						this.todoItemIndex = '';
						this.estimateForm.reset();
						this.elapsedForm.reset();
						this.reaction = '';

						if (this.currentPage === 'todoListDetail') {
							this.getListDetail();
						} else if (this.currentPage === 'todoList') {
							this.resetCounts.emit();
						}

						if (index > -1) {
							this.todoItems[index] = data;
							this.showOneOffDate();
						} else {
							this.todoItems.unshift(data);
							this.showOneOffDate();
						}

						this.getEstimatedTime();
						this.checkUserMentionAndHashtagAndAlterBody();
						this.createAutoComments('closed', data);
						this.createAutoCommentsForTask('closed', data);
					},
					(error) => {
						this.loadingGif = 'none';
						setTimeout(() => {
							this.squareSpinner = 'none';
						}, 1000);
					}
				);
			}
		}
	}

	/**
	 * Create auto comment for subtasks actions like start/pause/finish etc.
	 * @param status
	 * @param todo
	 */
	createSubtaskAutoComments(mainTask, status, subtask) {
		const comment = {};
		comment['contentType'] = 'todo';
		comment['contentUid'] = mainTask['uid'];
		if (status === 'closed') {
			comment['text'] = this.translate.instant('task-completion', {
				title: subtask['title'],
				time: subtask['elapsedTime'],
			});
		} else if (status === 'open') {
			comment['text'] = this.translate.instant('Task Reopened') + `: ${subtask.title}`;
		} else if (status === 'in_progress') {
			comment['text'] = this.translate.instant('task-start', {
				title: subtask['title'],
				time: subtask['estimate'],
			});
		} else {
			comment['text'] = this.translate.instant('task-paused', {
				title: subtask['title'],
			});
		}

		if (comment['contentType'] && comment['contentUid']) {
			this.commentService.create(comment, this.currentUser.username).subscribe(
				(d) => {},
				(error) => {}
			);
		}
	}

	/**
	 * Function to create auto comments for sub task to post
	 * @param status
	 * @param todo
	 * @returns
	 */
	createAutoCommentFromSubTaskToPost(status, todo) {
		if (!todo.postUid) {
			return;
		}
		const comment = {
			text: '',
			isLink: false,
			auto_generated: true,
			contentType: 'post',
			contentUid: todo.postUid,
		};
		if (status === 'closed') {
			comment['text'] = this.translate.instant('task-completion', {
				title: todo['title'],
				time: todo['elapsedTime'],
			});
		} else if (status === 'open') {
			comment['text'] = this.translate.instant('Task Reopened') + `: ${todo.title}`;
		} else if (status === 'in_progress') {
			comment['text'] = this.translate.instant('task-start', {
				title: todo['title'],
				time: todo['estimate'],
			});
		} else {
			comment['text'] = this.translate.instant('task-paused', {
				title: todo['title'],
			});
		}

		// return
		this.commentService.create(comment, this.currentUser.username).subscribe(() => {});
	}

	getTodoDetails(todo, i) {
		this.networkService.getTask(todo['uid']).subscribe((data) => {
			if (this.todoItems[i]) {
				this.todoItems[i]['closedSubTasks'] = data['closedSubTasks'];
			}
		});
	}

	checkForSubtaskMentions(task) {
		if (task['mentioned_users'] && task['mentioned_users'].length > 0) {
			const userList = [];
			for (const u of task['mentioned_users']) {
				if (task['title'].indexOf('@' + u.name) > -1) {
					userList.push(u.username);
				}
			}
			task['mentioned_users'] = [];
			task['mentioned_users'] = userList.join(',');
		}
		if (task['hashtags'] && task['hashtags'].length > 0) {
			const tagList = [];
			for (const h of task['hashtags']) {
				if (task['title'].indexOf('#' + h['name']) > -1) {
					tagList.push(h['name']);
				}
			}
			task['hashtags'] = [];
			task['hashtags'] = tagList.join(',');
		}
	}

	setMentionsAfterEdit(todo, task, index) {
		task['altered_title'] = task['title'];
		if (task['mentionedUsers']) {
			task['mentionedUsers'].forEach((mentionedUser) => {
				const html = '<b #userMention><a class="text-green">@' + mentionedUser['name'] + '</a></b>';
				if (task.altered_title) {
					task['altered_title'] = task.altered_title.replace('@' + mentionedUser['name'], html);
				}
			});
		}

		if (task['hashtags']) {
			task['hashtags'].forEach((hashtag) => {
				if (hashtag['name']) {
					const html = '<b #hashTag><a class="hash-todo">#' + hashtag['name'] + '</a></b>';
					if (task.altered_title) {
						task['altered_title'] = task.altered_title.replace('#' + hashtag['name'], html);
					}
				}
			});
		}

		todo['subtasks'][index] = task;
		this.closeParentTask(todo);
	}

	/**
	 * Function on clicking start/pause action
	 */
	handleStartPause(todo, i) {
		this.selectedToDoItem = todo;
		this.todoItemIndex = i;
		if (todo['status'] === 'open' || todo['status'] === 'paused') {
			if (todo['estimate'] && todo['estimate'] > 0) {
				this.displayEstimate = 'none';
				this.onDoneUndoneClicked(this.selectedToDoItem, 'in_progress', i);
			} else {
				this.displayEstimate = 'block';
				setTimeout(() => {
					document.getElementById('estimateTime').focus();
				}, 150);
			}
		} else {
			this.displayEstimate = 'none';
			this.onDoneUndoneClicked(this.selectedToDoItem, 'paused', i);
		}
	}

	/**
	 * Handle subtask start/pause actions under a task
	 * @param task
	 * @param subtask
	 * @param mainIndex
	 * @param subIndex
	 */
	handleSubtasksStartPause(task, subtask, mainIndex, subIndex) {
		this.mainTask = task;
		this.selectedToDoItem = subtask;
		this.todoItemIndex = mainIndex;
		this.subIndex = subIndex;

		if (subtask['status'] === 'open' || subtask['status'] === 'paused') {
			if (subtask['estimate'] && subtask['estimate'] > 0) {
				this.displayEstimate = 'none';
				this.manageSubtaskState(this.mainTask, subtask, 'in_progress', mainIndex, subIndex);
			} else {
				this.displayEstimate = 'block';
				setTimeout(() => {
					document.getElementById('estimateTime').focus();
				}, 150);
			}
		} else {
			this.displayEstimate = 'none';
			this.manageSubtaskState(this.mainTask, subtask, 'paused', mainIndex, subIndex);
		}
	}

	/**
	 * Manage start action of task with estimated time
	 * @param i
	 */
	manageTaskStart(i) {
		if (this.estimateForm.valid) {
			this.displayEstimate = 'none';
			if (this.selectedToDoItem['type'] === 'sub_task') {
				this.manageSubtaskState(this.mainTask, this.selectedToDoItem, 'in_progress', this.todoItemIndex, this.subIndex);
			} else {
				this.onDoneUndoneClicked(this.selectedToDoItem, 'in_progress', i);
			}
		}
	}

	/**
	 * Manage finish/close action of task with elapased Tme
	 * @param i
	 */
	manageTaskClose(i) {
		if (this.elapsedForm.valid && this.reaction) {
			this.displayElapsed = 'none';
			this.selectedToDoItem['status'] = 'closed';
			if (this.selectedToDoItem['type'] === 'sub_task') {
				this.manageSubtaskState(this.mainTask, this.selectedToDoItem, 'closed', this.todoItemIndex, this.subIndex);
			} else {
				this.onDoneUndoneClicked(this.selectedToDoItem, 'closed', i);
			}
		}
	}

	/**
	 * THis will reset estimate and elapsed modals
	 */
	public reset(): void {
		this.displayElapsed = 'none';
		this.displayEstimate = 'none';
		this.elapsedForm.reset();
		this.estimateForm.reset();
		this.selectedToDoItem = '';
		this.todoItemIndex = '';
		this.mainTask = '';
		this.subIndex = '';
		this.taskReactionDialog = 'none';
		this.reaction = '';
	}

	public onImgError(event): void {
		event.target.src = 'assets/images/default_avatar.jpg';
	}

	/**
	 * Function to close the task with reaction;
	 */
	public onConfirmReaction(): void {
		this.taskReactionDialog = 'none';
		if (this.selectedToDoItem['type'] === 'sub_task') {
			this.manageSubtaskState(this.mainTask, this.selectedToDoItem, 'closed', this.todoItemIndex, this.subIndex);
		} else {
			this.onDoneUndoneClicked(this.selectedToDoItem, 'closed', this.selectedTodoIndex);
		}
	}

	/**
	 * Function to check the current reaction is editable or not
	 */
	checkReactionIsEditable(task) {
		if (this.currentUser) {
			if (
				(task['assignedUser'] &&
					(task['assignedUser'].username === this.currentUser.username || (task['author'] && task['author'].username === this.currentUser.username))) ||
				(task['author'] && task['author'].username === this.currentUser.username)
			) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	/**
	 * Function to edit project reaction
	 */
	public onEditReaction(task, parentTask): void {
		event.stopPropagation();
		if (this.checkReactionIsEditable(task)) {
			this.selectedItem = task;
			this.parentTask = parentTask ? parentTask : '';
			this.reaction = task['outcome'];
			this.showEditReactionDialog = 'block';
		}
	}

	/**
	 * Function to cancel update project reaction
	 */
	onCancelReactionEdit() {
		this.showEditReactionDialog = 'none';
		this.reaction = '';
	}

	/**
	 * Function to update project reaction
	 */
	onUpdateReaction() {
		this.updateAttachmentDetails(this.selectedItem);

		this.todoItem.title = this.selectedItem.title;
		this.todoItem['status'] = this.selectedItem.status;
		this.todoItem.priority = this.selectedItem.priority;
		this.todoItem.repeatCycle = this.selectedItem.repeatCycle;
		if (this.selectedItem.assignedUser && this.selectedItem.assignedUser.username) {
			this.todoItem['assignedUser'] = this.selectedItem.assignedUser.username;
		}
		if (this.selectedItem.repeatCycle === 'one_off') {
			const date = this.selectedItem.oneOff;
			const splicedDate = date.split(' ')[0];
			this.todoItem['oneOff'] = splicedDate;
		}
		if (this.selectedItem.repeatFreequency) {
			this.todoItem['repeatFreequency'] = this.selectedItem.repeatFreequency;
		}
		if (this.selectedItem['project'] && this.selectedItem['project']['uid']) {
			this.todoItem['project'] = this.selectedItem['project']['uid'];
		}
		if (this.selectedItem.numberOfOpens && this.selectedItem.numberOfOpens > 0) {
			this.todoItem['numberOfOpens'] = this.selectedItem.numberOfOpens;
		}

		this.todoItem['outcome'] = this.reaction;

		if (this.selectedItem.type === 'sub_task') {
			this.todoItem['parent_content_type'] = 'todo';
			this.todoItem['parent_content_uid'] = this.parentTask['uid'];
		}

		this.loadingGif = 'block';
		this.networkService.updateToDoItem(this.todoItem, this.selectedItem.uid).subscribe(
			(res) => {
				this.loadingGif = 'none';
				if (res) {
					if (res.status === 'closed') {
						this.mixPanelService.track('Completed Task', {
							workspace: this.network?.name || 'NA',
							appVersion: environment.version,
							language: this.translate?.currentLang,
							username: this.currentUser?.username || 'NA',
							name: this.currentUser?.name || 'NA',
							project: res?.project?.name || 'NA',
							date: moment().format('YYYY-MM-DD'),
						});

						this.segmentService.trackEvent('Completed Task', {
							workspace: this.network?.name || 'NA',
							appVersion: environment.version,
							language: this.translate?.currentLang,
							username: this.currentUser?.username || 'NA',
							name: this.currentUser?.name || 'NA',
							project: res?.project?.name || 'NA',
							date: moment().format('YYYY-MM-DD'),
						});
					}
					this.showEditReactionDialog = 'none';
					this.reaction = '';
					this.selectedItem['outcome'] = res['outcome'];
					delete this.todoItem['closedDate'];
					delete this.todoItem['project'];
					delete this.todoItem['repeatFreequency'];
					delete this.todoItem['oneOff'];
					delete this.todoItem['numberOfOpens'];
					delete this.todoItem['estimate'];
					delete this.todoItem['elapsed_time'];
					delete this.todoItem['outcome'];
					delete this.todoItem['parent_content_type'];
					delete this.todoItem['parent_content_uid'];
					this.parentTask = '';
				}
			},
			(err) => {
				this.loadingGif = 'none';
			}
		);
	}

	/**
	 * Function to duplicate task
	 * @param task
	 */
	onClickDuplicateTask(task, section, index) {
		// return
		this.loadingGif = 'block';
		this.postService.duplicateTask(task.uid).subscribe(
			(res) => {
				this.loadingGif = 'none';
				if (res) {
					const duplicateTask = res;
					this.todoItems.splice(index + 1, 0, duplicateTask);
					this.handleRelatedContent();
					this.showOneOffDate();
					this.getEstimatedTime();
					this.checkUserMentionAndHashtagAndAlterBody();
				}
			},
			(err) => {
				this.loadingGif = 'none';
			}
		);
	}

	/**
	 * Function to update the existing todo data with updated data
	 * @param $event
	 */
	taskUpdatedEmitter($event) {
		const element = this.todoItems.findIndex((li) => li.uid === $event.uid);
		if (element > -1) {
			this.todoItems[element] = $event;
		}
		this.handleRelatedContent();
		this.showOneOffDate();
		this.getEstimatedTime();
		this.checkUserMentionAndHashtagAndAlterBody();
	}

	showPostSlideShow(todo) {
		event.stopPropagation();
		this.selectedImages = todo.attachmentDetails.gallery;
	}

	/**
	 * Get my team tasks list
	 */
	private getMyTeamTasks(): void {
		console.log('getMyTeamTasks');
		const params = this.date;
		this.todoItems = [];
		this.todosFetched = 0;
		this.projectService.newFilterManagerTodos(params, this.network?.membership?.uid).subscribe(
			(data: ITodoData) => {
				if (data.next) {
					this.item_next_url = data.next.split('alpha')[1];
				} else {
					this.item_next_url = '';
				}
				this.todoItems = data.objects.map((task: ITodo) => ({ ...this.alterTaskTitle(task), isManagerTask: true }));
				console.log(this.todoItems);
				this.todosFetched = 1;
				this.showOneOffDate();
				this.getEstimatedTime();
				this.checkUserMentionAndHashtagAndAlterBody();
				this.listTodosFetching.emit();
			},
			(err) => {
				this.todosFetched = 1;
			}
		);
	}

	/**
	 * Altering the task title
	 * It converts the title user mention or hashtag to a link
	 *
	 * @param title
	 *
	 * @return Todo
	 */
	private alterTaskTitle(task: any): Todo | any {
		if (task.title && task.title.indexOf('<') > -1) {
			task.title = task.title.replace(/</g, '< ');
		}

		let alteredTitle = task.title;

		// altering the title with user mention
		task.mentionedUsers?.forEach((mentionedUser: any) => {
			if (mentionedUser.name) {
				alteredTitle = alteredTitle.replaceAll(`@${mentionedUser.name}`, '<span><a class="alter-title mention" #userMention>$&</a></span>');
			}
		});

		// altering the title with hashtag
		task.hashtags?.forEach((hashtag: any) => {
			if (hashtag.name) {
				alteredTitle = alteredTitle.replaceAll(`#${hashtag.name}`, '<span><a class="alter-title hashtag" #hashTag>$&</a></span>');
			}
		});

		const tagStartPosition = alteredTitle.indexOf('['),
			tagEndPosition = alteredTitle.indexOf(']');

		// altering the title with task tags
		if (tagStartPosition > -1 && tagEndPosition > -1) {
			const tagBody = alteredTitle.slice(tagStartPosition + 1, tagEndPosition);

			alteredTitle = alteredTitle.replaceAll(`[${tagBody}]`, `<span class="alter-title tag">${tagBody}</span>`);
		}

		task.altered_title = alteredTitle;

		return task;
	}
}
