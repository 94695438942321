import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProjectService } from '@modules/project/services/project.service';
import { TranslateService } from '@ngx-translate/core';
import MathUtils from '@shared/utils/math-utils';
import { Chart, registerables } from 'chart.js';
import doughnutChartPlugin from './../../../../../../projects/peer-core/src/lib/plugins/chartjs/doughnut-chart-plugin';
import { timer } from 'rxjs';

Chart.register(...registerables);
@Component({
	selector: 'app-new-project-update-post',
	templateUrl: './new-project-update-post.component.html',
	styleUrls: ['./new-project-update-post.component.scss'],
})
export class NewProjectUpdatePostComponent implements OnInit, OnDestroy {
	@ViewChild('doughnutGraphCanvas') public doughnutGraphCanvas: ElementRef;

	@Input() public post: any;
	public project: any;
	public sections = [];

	public detailExpanded = false;
	public sectionsExpanded = false;
	public displayPulseLoader = false;

	public chart: any;
	private chartConfigs: any;
	private chartInstance: Chart;
	private graphData: any;
	public userMoodCounts: any;

	constructor(private translateService: TranslateService, private projectService: ProjectService) {
		this.chartConfigs = {
			type: 'doughnut',
			data: {
				datasets: [],
			},
			plugins: [doughnutChartPlugin],
		};
	}

	ngOnInit(): void {
		this.project = this.setProjectProgressWidth(this.post?.project);
		this.userMoodCounts = this.project?.outcomeCounts;
	}

	/**
	 * Add progress bar width based on project counts
	 * @param project specified project
	 * @returns project object with progress percentage
	 */
	private setProjectProgressWidth(project: any): any {
		project.progressPercent = MathUtils.getPercentageOf(project?.totalCount - project?.openCount, project?.totalCount);
		project.section.progressPercent = MathUtils.getPercentageOf(project?.section?.closed, project?.section?.total);

		const elapseTime = project?.totalElapsedTime?.split('.'),
			estimateTime = project?.totalEstimateTime?.split('.');

		project['estimateTime'] = { hour: estimateTime[0], min: estimateTime[1] };
		project['actualTime'] = { hour: elapseTime[0], min: elapseTime[1] };

		return project;
	}

	/**
	 * Open project detail view
	 */
	public openProjectUpdatePostDetails(): void {
		this.detailExpanded = !this.detailExpanded;

		if (this.detailExpanded) {
			this.graphData = [];
		}

		timer(500).subscribe(() => {
			this.loadGraph();
		});
	}

	/**
	 * Open section list view
	 */
	public openSectionsList(): void {
		if (this.sections?.length === 0) {
			this.displayPulseLoader = true;
			this.projectService.getProjectSections(this.project?.uid, this.project?.section?.total + 1).subscribe((data: any) => {
				this.sections = data.objects;
				this.displayPulseLoader = false;
			});
		}

		this.sectionsExpanded = !this.sectionsExpanded;
	}

	/**
	 * Load the projects mood graph
	 */
	private loadGraph(): void {
		const drawGraph: any = this.doughnutGraphCanvas?.nativeElement;

		if (drawGraph) {
			const context = drawGraph.getContext('2d'),
				doughnutOptions: any = {
					...Chart.defaults.datasets.doughnut,
					...{
						cutout: '70%',
					},
				};

			// global configs
			Chart.defaults.elements.arc.borderWidth = 0;
			Chart.defaults.datasets.doughnut = doughnutOptions;
			Chart.defaults.plugins.tooltip.enabled = false;

			// #region Graph Data

			if (this.graphData && this.graphData?.length === 0) {
				this.graphData.push({
					label: this.translateService.instant('Happy'),
					value: this.userMoodCounts?.happy,
					color: '#10B981',
				});
				this.graphData.push({
					label: this.translateService.instant('Neutral'),
					value: this.userMoodCounts?.neutral,
					color: '#F59E0B',
				});
				this.graphData.push({
					label: this.translateService.instant('Sad'),
					value: this.userMoodCounts?.sad,
					color: '#EF4444',
				});

				this.chartConfigs.data.datasets = [
					{
						data: this.graphData.map((data) => data.value),
						backgroundColor: this.graphData.map((data) => data.color),
					},
				];
			}

			// #endregion

			this.destroyChart();
			this.chartInstance = new Chart(context, this.chartConfigs);
		}
	}

	/**
	 * Destroy the chart before re-rendering
	 */
	private destroyChart(): void {
		if (this.chartInstance) {
			this.chartInstance.destroy();
		}
	}

	ngOnDestroy(): void {
		this.destroyChart();
	}
}
