import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NetworkService, PrivacyComponent, LayoutService, PaginationService, UserService, PostEditComponent } from '../shared';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { SearchService } from '../shared/services/search.service';
import { TranslateService } from '@ngx-translate/core';
import { TimeZoneService } from '../shared/services/time-zone.service';
import { HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common';
import MathUtils from '../shared/utils/math-utils';

@Component({
	selector: 'app-todolist',
	templateUrl: './todolist.component.html',
	styleUrls: ['./todolist.component.css'],
})
export class TodolistComponent implements OnInit, OnDestroy {
	@ViewChild(PrivacyComponent, { static: true }) privacy;
	@ViewChild('postEdit', { static: true }) public postEdit: PostEditComponent;
	public model = { subcircle: '' };
	public closedItems = ['Today', 'Yesterday', 'Last Week', 'Last Month', 'All Time'];
	public type;
	public todoTime;
	private currentDepartmentSubscriber;
	private countsSubscriber;
	private archivedSubscriber;
	public loadingGif = 'none';
	private departmentsReady;
	private listsSubscriber;
	public networkLoaded;
	public network;
	public currentUser: any;
	public toDoLists = [];
	public isLoading = true;
	public showMenu = false;
	public showPostCreateForm = false;
	public showToDoLists = true;
	public isFirstSearch = true;
	public loadingItems = true;
	public noItems = false;
	public emptyLists = false;
	// -For start day end day new feature
	public displayStartEndModal = 'none';
	public filteredItems = [];
	public todoLists = [];
	public nextUrl;
	public isTodoTasksPaginating = false;
	public startDaySelected = true;

	public selectedTasks: any[] = [];
	public listIds: any[] = [];
	public selectedTodos: any = [];
	public arr1: any[] = [];
	public noTasks = false;
	public comments: any[] = [];
	public projectStartDate: any;
	public projectEndDate: any;
	public displayProjectError = 'none';
	public modelText;
	public showDeleteTodoListModal = 'none';
	public selectedTodoListUid;
	public selectedTodoListIndex;
	public pastDueOn = false;
	public priorityOn = false;
	public todayClosedOn = false;
	public openTasksOn = false;
	public showProjectFilter = false;

	public options: any[] = [];
	public todos: any[] = [];
	public postType;
	public paginateSub$: any;
	public searchSubscription: any;
	public nextTasksUrl;

	private paginating = false;
	public priorities = ['p1', 'p2', 'p3'];
	public filter = { value: '' };
	public filterString = { priority: '' };

	public array1: any[] = [];
	public array2: any[] = [];
	public array3: any[] = [];
	public filtered_todos: any[] = [];
	public personalFilters: any[] = [];
	public SubscriptionTemplates = [];
	public filterProjects = [];
	public departments = [];
	public projects = [];

	public deleteItem_modal = 'none';
	public displayError = 'none';
	public display_reminderOptions = 'none';
	public display_toast = 'none';
	public displayLists = 'none';
	public displayDeleteModal = 'none';
	public showProjectModal = 'none';

	public selectedProject;
	public selectedProjectIndex;
	public editSelectedProject;
	public editSelectedProjectIndex;
	public projectName;
	public project_next_url;
	public showEditBtn = false;
	public projectError = false;
	public forNames;
	public defaultCp;
	public membershipFields;
	public language;
	public todoItems: any[] = [];
	public todoListsForItem: any[] = [];
	public reminderOptions: any[] = [];
	public roles: any[] = [];
	public selectedToDoItem: any;
	public lists = { option: '' };
	public reminder = { value: '' };
	public selectedToDoList: any;
	public dayName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	public post: any;
	public selectedItemForReminder;
	public selectedRemainderOption;
	public reminderMessage;
	public reminderTime;
	public timeForReminder: number;
	public todoItemIndex;

	public privacyString;
	public value;
	public next_url;
	public project = { name: '' };
	public network_id;
	public networkSubscriber;
	public showTasksModal = false;
	public displayClosedTasks = false;
	public isExternalUser = false;
	public currentDate;
	public todoListCounts;
	public archivedCounts: any;
	public archivedProjects: any;
	public networkType;
	public tab = 'projects';
	public isFetching = 1;
	public filterParams;
	public currentDay;
	public isTodosFetched = true;

	reminderObject = {
		username: '',
		contentUid: '',
		contentType: '',
		reminderOption: '',
		repeat: false,
	};

	comment = { text: '' };

	public createProject = 'none';
	public projectNameErr = false;
	public projectDateErr = false;

	public todoItem = {
		assignedUser: '',
		category: 'todo',
		network: localStorage.getItem('network_id'),
		collection_post: true,
		commentable: true,
		contentType: 'network',
		priority: '',
		repeatCycle: '',
		shareable: true,
		state: 'active',
		title: '',
		type: 'todo',
	};

	constructor(
		private translate: TranslateService,
		private networkService: NetworkService,
		private searchService: SearchService,
		private layoutService: LayoutService,
		private _location: Location,
		private paginationService: PaginationService,
		private activatedRoute: ActivatedRoute,
		private userService: UserService,
		private router: Router,
		public timeZoneService: TimeZoneService
	) {
		this.paginationService.scrollDistance = 8;
		this.paginateSub$ = this.paginationService.initiateScroll.subscribe((data) => {
			if (data && !this.paginating) {
				this.paginate();
			}
		});
	}

	private setHeaders(): HttpHeaders {
		const headersConfig = {
			'Content-Type': 'application/json',
		};
		if (localStorage.getItem('token')) {
			headersConfig['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
		}
		return new HttpHeaders(headersConfig);
	}

	ngOnInit(): void {
		if (localStorage.getItem('networkExpired')) {
			this.router.navigateByUrl('/expired');
		}
		localStorage.removeItem('detail-tab');
		this.activatedRoute.queryParams.subscribe((params) => {
			if (params['statusUpdate']) {
				this.showTasksModal = true;
				this.showMenu = true;
				if (params['statusUpdate'] === 'startDay') {
					const option = { name: 'Start Day', slug: 'start' };
					this.onSelectAction(option);
				}
				if (params['statusUpdate'] === 'endDay') {
					const option = { name: 'End Day', slug: 'end' };
					this.onSelectAction(option);
				}
			} else if (params['tasksUpdate']) {
				if (params['tasksUpdate'] === 'endDay') {
					this.tab = 'closed';
					this.filterTodos('today', 'true');
				}
				if (params['tasksUpdate'] === 'pending') {
					this.tab = 'open';
					this.filterTodos('open', 'true');
				}
			}
		});

		this.activatedRoute.queryParams.subscribe((params) => {
			if (params['opentasks']) {
				this.tab = 'active_filter';
				this.filterTodos('active_filter', 'true');
				this.router.navigate([], {
					queryParams: { opentasks: false },
					queryParamsHandling: 'merge',
				});
			}
		});

		if (localStorage.getItem('network_id')) {
			this.network_id = localStorage.getItem('network_id');
		}
		this.networkSubscriber = this.networkService.networkReady.subscribe((networkLoaded) => {
			this.currentUser = this.userService.getCurrentUser();
			this.getCounts();
			this.getArchivedCounts();
			this.networkLoaded = networkLoaded;
			if (this.networkLoaded) {
				this.networkService.currentNetwork.subscribe((network) => {
					this.network = network;
					this.networkType = network.networkType;
					if (network.membership && network.membership.memberRole) {
						network.membership.memberRole.fields.forEach((element) => {
							// Set default cp in add new projects container--
							if (this.network.membership.memberRole && this.network.membership.memberRole.defaultContentPrivacy) {
								this.defaultCp = this.network['membership']['memberRole']['defaultContentPrivacy'];
								this.membershipFields = this.network['membership']['memberRole']['fields'];
								// this.membershipFields.forEach(field => {
								//   if (field.key === this.defaultCp) {
								//     if (!localStorage.getItem(`${this.network.uid}`)) {
								//       this.forNames = field.valueLabel;
								//       this.privacy["personalFilter"] = field;
								//       this.privacy.previousSelection = field.valueLabel;
								//       this.privacy.currentSelection = field.valueLabel;
								//       this.privacy.role = "";
								//     }
								//   }
								// });
								this.roles = this.network['roles'];
							}

							this.departmentsReady = this.networkService.departmentsReady.subscribe((departmentsLoaded) => {
								if (departmentsLoaded) {
									this.currentDepartmentSubscriber = this.networkService.currentDepartments.subscribe((departments) => {
										if (departments && departments['objects'].length > 0) {
											this.departments = departments.objects;
										}
									});
								}
							});
							if (element.CP === true) {
								this.personalFilters.push(element);
							}
						});
					}

					if (this.network && this.network.SubscriptionTemplates) {
						this.SubscriptionTemplates = this.network.SubscriptionTemplates;
					}

					if (network['membership'] && network['membership']['memberRole']['role']) {
						const memberRole = network['membership']['memberRole']['role'];
						if (memberRole.slug === 'external') {
							this.isExternalUser = true;
						}
					}

					this.currentDate = new Date();
					this.currentDay = this.dayName[new Date().getDay()];
				});
			}
		});

		// For removing backdrop and clearing posttype on closing the issue create form modal
		this.layoutService.showBackdrop.subscribe((data) => {
			if (data === 'event-post-form' || data === 'hide') {
				this.showPostCreateForm = false;
				this.showMenu = false;
				this.postType = '';
			}
		});
		/**




    /**
     * Search todolists
     * 1. Set search query
     * 2. Pass search query to todolists function
     */
		if (window.location.pathname === '/network/todolist') {
			this.searchSubscription = this.searchService.searchValue.subscribe((data) => {
				if (this.isFirstSearch && data.trim()) {
					this.isFirstSearch = false;
				}

				// TODO: integrate search service to new module

				if (!this.isFirstSearch) {
					const query = data; // 1
					if (this.tab === 'lists') {
						this.getToDoLists(query);
					} else if (this.tab === 'projects') {
						this.getProjects(query);
					} else if (this.tab === 'pastDue') {
						this.todoTime = new Date().getSeconds();
						this.filterParams = 'assigned_to=true&query=' + query;
					} else if (this.tab === 'closed') {
						this.todoTime = new Date().getSeconds();
						this.filterParams = this.filterParams + '&query=' + query;
					} else if (this.tab === 'open') {
						this.todoTime = new Date().getSeconds();
						this.filterParams = this.filterParams + '&query=' + query;
					}
				}
			});
		}

		if (localStorage.getItem('language')) {
			if (localStorage.getItem('language') === 'en') {
				this.language = 'en-IN';
			} else {
				this.language = 'tr-IN';
			}
		}

		// if (!localStorage.getItem('tab')) {
		//   this.handleDisplayLists();
		// } else if (localStorage.getItem('tab') === 'projects') {
		//   this.getProjects('');
		// } else if (localStorage.getItem('tab') === 'pastDue') {
		//   this.filterTodos('past', 'true');
		// } else if (localStorage.getItem('tab') === 'open') {
		//   this.filterTodos('open', 'true');
		// } else if (localStorage.getItem('tab') === 'closed') {
		//   this.filterTodos('today', 'true');
		// }

		if (!localStorage.getItem('tab')) {
			this.handleDisplayProjects();
		} else if (localStorage.getItem('tab') === 'lists') {
			this.tab = 'lists';
			this.handleDisplayLists();
		} else if (localStorage.getItem('tab') === 'pastDue') {
			this.filterTodos('assigned_to', 'true');
		} else if (localStorage.getItem('tab') === 'open') {
			this.tab = 'active_filter';
			this.filterTodos('active_filter', 'true');
		} else if (localStorage.getItem('tab') === 'closed') {
			this.filterTodos('today', 'true');
		}
	}

	ngOnDestroy(): void {
		this.searchService.resetSearchValue();
		if (this.searchSubscription) {
			this.searchSubscription.unsubscribe();
		}
		if (this.networkSubscriber) {
			this.networkSubscriber.unsubscribe();
		}
		if (this.departmentsReady) {
			this.departmentsReady.unsubscribe();
		}
		if (this.currentDepartmentSubscriber) {
			this.currentDepartmentSubscriber.unsubscribe();
		}
		if (this.listsSubscriber) {
			this.listsSubscriber.unsubscribe();
		}
		if (this.countsSubscriber) {
			this.countsSubscriber.unsubscribe();
		}
		if (this.archivedSubscriber) {
			this.archivedSubscriber.unsubscribe();
		}
		if (this.paginateSub$) {
			this.paginateSub$.unsubscribe();
		}
	}

	handleDisplayLists() {
		if (localStorage.getItem(`${this.network['uid']}-lists`)) {
			this.toDoLists = JSON.parse(localStorage.getItem(`${this.network['uid']}-lists`));

			if (this.networkService.archivedList) {
				const uid = this.networkService.archivedList;
				const index = this.toDoLists.findIndex((list) => list['uid'] === uid);

				if (index > -1) {
					this.toDoLists.splice(index, 1);
				}
			}

			this.updateTodoLists();
		} else {
			this.getToDoLists('');
		}
	}

	handleDisplayProjects() {
		if (localStorage.getItem(`${this.network['uid']}-projects`)) {
			this.projects = JSON.parse(localStorage.getItem(`${this.network['uid']}-projects`));
			this.updateProjects();
		} else {
			this.getProjects('');
		}
	}

	getProjects(query) {
		console.log(query);
		if (this.isFetching === 1 && this.isTodosFetched) {
			this.getArchivedProjectCounts();
			localStorage.removeItem('tab');
			localStorage.removeItem('detail-tab');
			this.tab = 'projects';
			this.toDoLists = [];
			this.filterParams = '';
			this.isFetching = 0;
			this.reset();
			this.networkService.getAllProjects(this.network.uid, query).subscribe(
				(data) => {
					if (data.next) {
						this.nextUrl = data.next.split('alpha')[1];
					} else {
						this.nextUrl = '';
					}
					this.projects = data.objects;
					this.isLoading = false;
					this.isFetching = 1;
					this.setProjectProgressWidth();
					this.displayCpForProjects();
					this.backUpProjects();
					if (this.projects.length === 0) {
						this.emptyLists = true;
					} else {
						this.emptyLists = false;
					}
				},
				(err) => {
					this.isFetching = 1;
				}
			);
		}
	}

	updateProjects() {
		this.getArchivedProjectCounts();
		this.networkService.getAllProjects(this.network.uid, '').subscribe((data) => {
			if (data.next) {
				this.nextUrl = data.next.split('alpha')[1];
			} else {
				this.nextUrl = '';
			}
			this.projects = data.objects;
			this.setProjectProgressWidth();
			this.displayCpForProjects();
			this.backUpProjects();
			if (this.projects.length === 0) {
				this.emptyLists = true;
			} else {
				this.emptyLists = false;
			}
		});
	}

	backUpProjects() {
		localStorage.setItem(`${this.network['uid']}-projects`, JSON.stringify(this.projects));
	}

	getToDoLists(query) {
		if (this.isFetching === 1 && this.isTodosFetched) {
			localStorage.setItem('tab', 'lists');
			this.tab = 'lists';
			this.reset();
			this.filterParams = '';
			this.isFetching = 0;
			this.listsSubscriber = this.networkService.getTodoLists(this.network.uid, query).subscribe(
				(data) => {
					if (data.next) {
						this.nextUrl = data.next.split('alpha')[1];
					} else {
						this.nextUrl = '';
					}
					this.toDoLists = data.objects;
					this.isFetching = 1;
					this.isLoading = false;
					this.updatePrivacyString();
					this.showOneOffDate();
					this.backUpTodoLists();
					if (this.toDoLists.length === 0) {
						this.emptyLists = true;
					} else {
						this.emptyLists = false;
					}
				},
				(err) => {
					this.isFetching = 1;
				}
			);
		}
	}

	backUpTodoLists() {
		localStorage.setItem(`${this.network['uid']}-lists`, JSON.stringify(this.toDoLists));
	}

	updateTodoLists() {
		this.listsSubscriber = this.networkService.getTodoLists(this.network.uid, '').subscribe((data) => {
			// 2
			if (data.next) {
				this.nextUrl = data.next.split('alpha')[1];
			} else {
				this.nextUrl = '';
			}
			this.toDoLists = data.objects;
			this.updatePrivacyString();
			this.showOneOffDate();
			this.backUpTodoLists();
		});
	}

	/**
	 * Function to paginate remaining todo lists or todo tasks
	 * 1. Get remaining tasks/Todolists from api
	 * 2. Set next url if exists
	 * 3. If current display is todolists, set objects from api to todolists else set it to filtered_todos
	 */
	public paginate(): void {
		if (this.nextUrl) {
			this.isFetching = 0;
			this.paginating = true;
			this.networkService.paginateToDoLists(this.nextUrl).subscribe(
				(data) => {
					this.paginating = false;
					this.isFetching = 1;
					if (data.next) {
						this.nextUrl = data.next.split('alpha')[1];
					} else {
						this.nextUrl = '';
					}
					if (this.tab === 'lists') {
						this.toDoLists = this.toDoLists.concat(data.objects);
						this.showOneOffDate();
						this.updatePrivacyString();
					} else if (this.tab === 'projects') {
						this.projects = this.projects.concat(data.objects);
						this.displayCpForProjects();
						this.setProjectProgressWidth();
					}
				},
				(err) => {
					this.isFetching = 1;
				}
			);
		}
	}

	setProjectProgressWidth() {
		this.projects.forEach((project) => {
			if (project['endDate']) {
				const endDate = new Date(project['endDate']);
				if (this.currentDate > endDate) {
					if (project['openCount'] > 0) {
						project['color'] = '#ff0000';
					} else {
						project['color'] = 'var(--success-color)';
					}
				} else if (this.currentDate <= endDate) {
					if (project['openCount'] > 0) {
						project['color'] = '#ffd306';
					} else if (project['openCount'] === 0) {
						project['color'] = 'var(--success-color)';
					}
				}
			}

			// let closedCount;
			const openCount = project.openCount;
			const totalCount = project.totalCount;
			const closedCount = totalCount - openCount;
			if (closedCount > 0 && totalCount > 0) {
				if (closedCount === totalCount) {
					project['progressWidth'] = '100%';
				} else {
					const halfTotal = totalCount / 2;
					if (closedCount === halfTotal) {
						project['progressWidth'] = '50%';
					} else if (closedCount > halfTotal) {
						project['progressWidth'] = '70%';
					} else {
						project['progressWidth'] = '30%';
					}
				}
			}
		});
	}

	projectSelected() {
		localStorage.setItem('tab', 'projects');
	}

	/**
	 * Function to reset variables to default values
	 */
	public reset(): void {
		this.nextUrl = '';
		this.emptyLists = false;
		this.isLoading = true;
		this.toDoLists = [];
		this.projects = [];
		this.networkService.onTodoDetail = false;
		this.filter.value = 'lists';
	}

	/**
	 * Function to show one off date of  todolists
	 */
	showOneOffDate() {
		this.toDoLists.forEach((list) => {
			if (list.repeatCycle === 'one_off' && list.oneOff) {
				const newDate = new Date(list.oneOff).toLocaleString();
				list['newDate'] = newDate.substring(0, newDate.indexOf(','));
			}
		});
	}

	// Update privacy values of a list
	private updatePrivacyString(): void {
		this.toDoLists.forEach((list) => {
			this.privacyString = '';
			if (localStorage.getItem('MyTodolist') && list.uid === localStorage.getItem('MyTodolist')) {
				list['privacy_name'] = this.currentUser.name;
			} else {
				if (list['customPrivacy']) {
					list['customPrivacy'].forEach((cp, index) => {
						list['privacy_name'] = this.translate.instant('Custom Selection');
					});
				} else if (list['contentPrivacy']) {
					if (list['contentPrivacy'].role && list['contentPrivacy'].department) {
						const index = this.departments.findIndex((department) => department.uid === list['contentPrivacy'].department);
						if (index > -1) {
							list['privacy_name'] = this.departments[index]['name'] + ' , ' + list['contentPrivacy'].role;
						}
					} else if (list['contentPrivacy'].role) {
						list['privacy_name'] = list['contentPrivacy'].role;
						//
					} else if (list['contentPrivacy'].department) {
						const index = this.departments.findIndex((department) => department.uid === list['contentPrivacy'].department);
						if (index > -1) {
							list['privacy_name'] = this.departments[index]['name'];
						}
					} else if (!list['contentPrivacy'].role || !list['contentPrivacy'].department) {
						this.value = Object.keys(list['contentPrivacy']);
						// Handling Personal filters
						this.personalFilters.forEach((element) => {
							if (element.key === this.value[0]) {
								list['privacy_name'] = element.valueLabel;
							}
						});
						// Handling Membership CP
						if (list['contentPrivacy'].status) {
							list['privacy_name'] = list['contentPrivacy'].status;
						} else if (list['contentPrivacy'].renewalStatus) {
							list['privacy_name'] = list['contentPrivacy'].renewalStatus;
						} else if (list['contentPrivacy'].membership_level) {
							const index = this.SubscriptionTemplates.findIndex((val) => val.uid === list['contentPrivacy'].membership_level);
							if (index > -1) {
								list['privacy_name'] = this.SubscriptionTemplates[index]['name'];
							}
						} else if (list['contentPrivacy'].trialPeriod) {
							list['privacy_name'] = list['contentPrivacy'].trialPeriod;
						} else if (list['contentPrivacy'].manager) {
							list['privacy_name'] = this.translate.instant('My Team');
						}
					}
				} else if (list['channel'] && Object.keys(list['channel']).length) {
					list['privacy_name'] = list['channel'].name;
				} else {
					list['privacy_name'] = this.translate.instant('Everyone');
				}
			}
		});
	}

	onClickTodoList(list) {
		this.router.navigateByUrl(`/project/team/${list.uid}`);
	}

	/**
	 * Function to display post create form for add task
	 */
	showPostForm() {
		this.showPostCreateForm = !this.showPostCreateForm;
		if (this.showPostCreateForm) {
			this.layoutService.showBd('event-post-form');
			this.postType = 'add-task';
		} else {
			this.postType = '';
			this.layoutService.hideBd('event-post-form');
		}
	}

	findWithAttr(array, attr, value) {
		for (let i = 0; i < array.length; i += 1) {
			if (array[i][attr] === value) {
				return i;
			}
		}
		return -1;
	}

	showDeleteConfirmModal(uid, index) {
		this.showDeleteTodoListModal = 'block';
		this.selectedTodoListUid = uid;
		this.selectedTodoListIndex = index;
	}

	closeDeleteModal() {
		this.showDeleteTodoListModal = 'none';
	}

	onConfirmDeleteToDoList() {
		this.showDeleteTodoListModal = 'none';
		// TODO: use loading spinner
		this.networkService.deleteToDoList(this.selectedTodoListUid).subscribe((data) => {
			this.toDoLists.splice(this.selectedTodoListIndex, 1);
		});
	}

	/**
	 * Function to get all projects
	 */
	// getAllProjects() {
	//   // TODO: use loading spinner
	//   this.networkService.getAllProjects(this.network.uid).subscribe(data => {
	//     if (data.next) {
	//       this.project_next_url = data.next.split("alpha")[1];
	//       this.paginateProjects();
	//     } else {
	//       this.project_next_url = "";
	//     }
	//     this.showProjectModal = "block";
	//     this.projectStartDate = "";
	//     this.projectEndDate = "";
	//     this.projectName = "";
	//     this.projects = data.objects;
	//     //--Display all cp of products
	//     this.displayCpForProjects();
	//     this.layoutService.hideBd();
	//
	//   });
	// }

	/**
	 * Function to display cp values of projects in add new project form
	 */
	displayCpForProjects() {
		this.projects.forEach((project) => {
			if (project['customPrivacy']) {
				project['projectCp'] = 'Custom Selection';
			} else if (project['contentPrivacy']) {
				if (project['contentPrivacy'].role && project['contentPrivacy'].department) {
					const index = this.departments.findIndex((department) => department.uid === project['contentPrivacy'].department);
					if (index > -1) {
						project['projectCp'] = this.departments[index]['name'] + ' , ' + project['contentPrivacy'].role;
					}
				} else if (project['contentPrivacy'].role) {
					project['projectCp'] = project['contentPrivacy'].role;
				} else if (project['contentPrivacy'].department) {
					const index = this.departments.findIndex((department) => department.uid === project['contentPrivacy'].department);
					if (index > -1) {
						project['projectCp'] = this.departments[index]['name'];
					}
				} else if (!project['contentPrivacy'].role || !project['contentPrivacy'].department) {
					const field = Object.keys(project['contentPrivacy']);
					this.personalFilters.forEach((element) => {
						if (element.key === field[0]) {
							project['projectCp'] = element.valueLabel;
						}
					});
					if (project['contentPrivacy'].status) {
						project['projectCp'] = project['contentPrivacy'].status;
					} else if (project['contentPrivacy'].renewalStatus) {
						project['projectCp'] = project['contentPrivacy'].renewalStatus;
					} else if (project['contentPrivacy'].membership_level) {
						const index = this.SubscriptionTemplates.findIndex((val) => val.uid === project['contentPrivacy'].membership_level);
						if (index > -1) {
							project['projectCp'] = this.SubscriptionTemplates[index]['name'];
						}
					} else if (project['contentPrivacy'].manager) {
						project['projectCp'] = this.translate.instant('My Team');
					}
				}
			} else if (project['channel'] && project['channel']['uid']) {
				project['projectCp'] = project.channel.name;
			} else {
				project['projectCp'] = 'Everyone';
			}
		});
	}

	/**
	 * Function to show privacy modal in add new products container
	 */
	// onPrivacyClick() {
	//   this.privacy.showPrivacy();
	//   this.privacy.privacy_settings = "block";
	// }

	/**
	 * Update cp value on creating a product according to the detail from the privacy modal.
	 */
	updatePrivacyValues() {
		if (this.privacy.custom_privacy && this.privacy.custom_privacy.length > 0) {
			delete this.project['content_privacy'];
			delete this.project['channel'];
			this.project['custom_privacy'] = [];
			const names = [];
			const newObject = this.privacy.custom_privacy;
			newObject.forEach((member) => {
				member.privacyType = 'customPrivacy';
			});
			localStorage.setItem(`${this.network_id}`, JSON.stringify(newObject));
			this.privacy.custom_privacy.forEach((user) => {
				if (user['user']) {
					this.project['custom_privacy'].push(user['user'].userId);
					names.push(user['user'].name);
				} else if (user.userId) {
					this.project['custom_privacy'].push(user.userId);
					names.push(user.username);
				} else {
					this.project['custom_privacy'].push(user.id);
					names.push(user.username);
				}
			});
		} else {
			let roleString = null;
			let departmentString = null;
			let personalFilterString = null;
			let selectByStatusString = null;
			let selectByRenewalString = null;
			let selectByLevelString = null;
			delete this.project['custom_privacy'];
			delete this.project['channel'];

			if (this.privacy.selectedRole && this.privacy.selectedDepartment) {
				roleString = '"role": "' + this.privacy.selectedRole.slug + '"';
				departmentString = '"department": "' + this.privacy.selectedDepartment.value + '"';
			} else if (this.privacy.selectedRole) {
				roleString = '"role": "' + this.privacy.selectedRole.slug + '"';
				// * ******************SPACE AFTER : ******************* */
				roleString = '"role": "' + this.privacy.selectedRole.slug + '"';
				const newObject = this.privacy.selectedRole;
				newObject.privacyType = 'role';
				localStorage.setItem(`${this.network_id}`, JSON.stringify(newObject));
			} else if (this.privacy.selectedDepartment) {
				if (this.privacy.selectedDepartment.value) {
					departmentString = '"department": "' + this.privacy.selectedDepartment.value + '"';
				}
				if (this.privacy.selectedDepartment.uid && this.privacy.selectedDepartment.name) {
					departmentString = '"department": "' + this.privacy.selectedDepartment.uid + '"';
				}
				// -
				const newObject = this.privacy.selectedDepartment;
				newObject.privacyType = 'department';
				localStorage.setItem(`${this.network_id}`, JSON.stringify(newObject));
			} else if (this.privacy.personalFilter) {
				let personalFilterName = '';
				personalFilterName = this.privacy.personalFilter.valueLabel;
				personalFilterString = '"' + this.privacy.personalFilter.key + '": "' + this.privacy.personalFilter.value + '"';
				// -
				const newObject = this.privacy.personalFilter;
				newObject.privacyType = 'personalFilter';
				localStorage.setItem(`${this.network_id}`, JSON.stringify(newObject));
			} else if (this.privacy.selectByStatusValue) {
				if (this.privacy.selectByStatusValue.status) {
					selectByStatusString = '"status": "' + this.privacy.selectByStatusValue.status + '"';
				} else {
					selectByStatusString = '"status": "' + this.privacy.selectByStatusValue[0] + '"';
					const newObject = this.privacy.selectByStatusValue;
					if (newObject.includes('status') === false) {
						newObject.push('status');
					}
					localStorage.setItem(`${this.network_id}`, JSON.stringify(newObject));
				}
			} else if (this.privacy.selectByRenewalValue) {
				if (this.privacy.selectByRenewalValue.renewalStatus) {
					selectByRenewalString = '"renewalStatus": "' + this.privacy.selectByRenewalValue.renewalStatus + '"';
				} else {
					selectByRenewalString = '"renewalStatus": "' + this.privacy.selectByRenewalValue[0] + '"';
					const newObject = this.privacy.selectByRenewalValue;
					if (newObject.includes('renewalFrequency') === false) {
						newObject.push('renewalFrequency');
					}
					localStorage.setItem(`${this.network_id}`, JSON.stringify(newObject));
				}
			} else if (this.privacy.selectByLevelValue) {
				if (this.privacy.selectByLevelValue.uid) {
					selectByLevelString = '"membership_level": "' + this.privacy.selectByLevelValue.uid + '"';
					const newObject = this.privacy.selectByLevelValue;
					newObject.privacyType = 'selectByLevel';
					localStorage.setItem(`${this.network_id}`, JSON.stringify(newObject));
				} else {
					selectByLevelString = '"membership_level": "' + this.privacy.selectByLevelValue.membership_level + '"';
				}
			} else if (this.privacy['channel']) {
				this.project['channel'] = this.privacy['channel']['uid'];
				const newObject = this.privacy.channel;
				newObject['privacyType'] = 'subCircle';
				localStorage.setItem(`${this.network_id}`, JSON.stringify(newObject));
			}
			if (
				this.privacy.custom_privacy.length === 0 &&
				!this.privacy.selectedRole &&
				!this.privacy.role &&
				!this.privacy.personalFilter &&
				!this.privacy.selectedDepartment &&
				!this.privacy.channel &&
				!this.model.subcircle &&
				!this.privacy.selectByStatusValue &&
				!this.privacy.selectByRenewalValue &&
				!this.privacy.selectByLevelValue
			) {
				localStorage.setItem(`${this.network_id}`, JSON.stringify('Everyone'));
			}

			if (roleString && departmentString) {
				this.project['content_privacy'] = '{' + roleString + ', ' + departmentString + '}';
			} else if (roleString && !departmentString) {
				this.project['content_privacy'] = '{' + roleString + '}';
			} else if (!roleString && departmentString) {
				this.project['content_privacy'] = '{' + departmentString + '}';
			} else if (personalFilterString) {
				this.project['content_privacy'] = '{' + personalFilterString + '}';
			} else if (selectByStatusString) {
				this.project['content_privacy'] = '{' + selectByStatusString + '}';
			} else if (selectByRenewalString) {
				this.project['content_privacy'] = '{' + selectByRenewalString + '}';
			} else if (selectByLevelString) {
				this.project['content_privacy'] = '{' + selectByLevelString + '}';
			} else {
				delete this.project['content_privacy'];
			}
		}
		if (this.project['custom_privacy'] && this.project['custom_privacy'].length > 0) {
			this.project['custom_privacy'] = '[' + this.project['custom_privacy'].join(',') + ']';
		}
	}

	/**
	 * Function to show privacy values selected from privacy modal on add new project form
	 */
	public updatePrivacyStringBasedOnPrivacyComponent(): void {
		this.forNames = '';
		if (this.privacy.custom_privacy && this.privacy.custom_privacy.length > 0) {
			const names = [];
			this.privacy.custom_privacy.forEach((user) => {
				if (user['user']) {
					names.push(user['user'].name);
				} else if (user['name']) {
					names.push(user['name']);
				} else {
					names.push(user.username);
				}
			});
			this.forNames = names.join(',');
		} else {
			if (this.privacy.selectedRole && this.privacy.selectedDepartment) {
				this.forNames = this.privacy.selectedRole.name + ', ' + this.privacy.selectedDepartment.valueLabel;
			} else if (this.privacy.selectedRole) {
				let roleName;
				if (this.privacy.selectedRole.name) {
					roleName = this.privacy.selectedRole.name;
				} else {
					roleName = this.privacy.selectedRole;
				}
				this.forNames = roleName;
			} else if (this.privacy.selectedDepartment) {
				if (this.privacy.selectedDepartment.valueLabel) {
					this.forNames = this.privacy.selectedDepartment.valueLabel;
				}
				if (this.privacy.selectedDepartment.name && this.privacy.selectedDepartment.uid) {
					this.forNames = this.privacy.selectedDepartment.name;
				}
			} else if (this.privacy.personalFilter) {
				let personalFilter = '';
				personalFilter = this.privacy.personalFilter.valueLabel;
				this.forNames = personalFilter;
			} else if (this.privacy.channel) {
				this.forNames = this.privacy.channel.name;
			} else if (this.privacy.selectByRenewalValue) {
				this.forNames = this.privacy.selectByRenewalValue[1];
			} else if (this.privacy.selectByLevelValue) {
				this.forNames = this.privacy.selectByLevelValue.name;
			} else if (this.privacy.selectByStatusValue) {
				this.forNames = this.privacy.selectByStatusValue[1];
			} else {
				this.forNames = 'Everyone';
			}
		}
	}

	/**
	 * Function to update cp field after creating/editing a project
	 */
	updateSelectionFields() {
		if (localStorage.getItem(`${this.network_id}`)) {
			const retrivedCp = JSON.parse(localStorage.getItem(`${this.network_id}`));
			if (retrivedCp.privacyType === 'role') {
				this.privacy['selectedRole'] = retrivedCp;
				this.forNames = retrivedCp.name;
				this.privacy.previousSelection = retrivedCp.name;
				this.privacy.currentSelection = retrivedCp.name;
			}
			if (retrivedCp.privacyType === 'department') {
				this.privacy['selectedDepartment'] = retrivedCp;
				if (retrivedCp.valueLabel) {
					this.privacy.previousSelection = retrivedCp.valueLabel;
					this.privacy.currentSelection = retrivedCp.valueLabel;
					this.forNames = retrivedCp.valueLabel;
				}
				if (retrivedCp.name && retrivedCp.uid) {
					this.privacy.previousSelection = retrivedCp.name;
					this.privacy.currentSelection = retrivedCp.name;
					this.forNames = retrivedCp.name;
				}
			}
			if (retrivedCp.privacyType === 'subCircle') {
				this.model['subcircle'] = retrivedCp.uid;
				this.privacy.channel = retrivedCp;
				this.forNames = retrivedCp.name;
				this.privacy.previousSelection = retrivedCp.name;
				this.privacy.currentSelection = retrivedCp.name;
			}
			if (retrivedCp.privacyType === 'personalFilter') {
				this.privacy['personalFilter'] = retrivedCp;
				this.forNames = retrivedCp.valueLabel;
				this.privacy.previousSelection = retrivedCp.valueLabel;
				this.privacy.currentSelection = retrivedCp.valueLabel;
			}
			if (Array.isArray(retrivedCp) && retrivedCp.includes('status') === true) {
				this.privacy.selectByStatusValue = retrivedCp;
				this.privacy.previousSelection = retrivedCp[1];
				this.privacy.currentSelection = retrivedCp[1];
				this.forNames = retrivedCp[1];
			}
			if (Array.isArray(retrivedCp) && retrivedCp.includes('renewalFrequency') === true) {
				this.privacy.selectByRenewalValue = retrivedCp;
				this.privacy.previousSelection = retrivedCp[1];
				this.privacy.currentSelection = retrivedCp[1];
				this.forNames = retrivedCp[1];
			}
			if (retrivedCp.privacyType === 'selectByLevel') {
				this.privacy['selectByLevelValue'] = retrivedCp;
				this.privacy.previousSelection = retrivedCp.name;
				this.privacy.currentSelection = retrivedCp.name;
				this.forNames = retrivedCp.name;
			}
			if (Array.isArray(retrivedCp) && !retrivedCp.includes('status') && !retrivedCp.includes('renewalFrequency')) {
				this.privacy['custom_privacy'] = retrivedCp;
				retrivedCp.forEach((member) => {
					if (member.user) {
						this.forNames = member.user.name;
						this.privacy.previousSelection = member.user.name;
						this.privacy.currentSelection = member.user.name;
					} else if (member.name) {
						this.forNames = member.name;
						this.privacy.previousSelection = member.name;
						this.privacy.currentSelection = member.name;
					} else {
						this.forNames = member.username;
						this.privacy.previousSelection = member.username;
						this.privacy.currentSelection = member.username;
					}
				});
			}
			if (retrivedCp === 'Everyone') {
				this.forNames = this.translate.instant('Everyone');
				this.privacy.previousSelection = this.translate.instant('Everyone');
				this.privacy.currentSelection = this.translate.instant('Everyone');
			}
		}
	}

	/**
	 * Function to create a new project and display created project
	 */
	createNewProject() {
		if (!this.projectName) {
			this.displayProjectError = 'block';
			this.modelText = this.translate.instant('Please enter a name for project');
		} else if (!this.projectStartDate) {
			this.displayProjectError = 'block';
			this.modelText = this.translate.instant('Select start date');
		} else if (!this.projectEndDate) {
			this.displayProjectError = 'block';
			this.modelText = this.translate.instant('Select end date');
		} else {
			this.displayProjectError = 'none';
			this.modelText = '';
			this.project['startDate'] = this.projectStartDate.toString();
			this.project['endDate'] = this.projectEndDate.toString();
			this.updatePrivacyValues();
			this.updateSelectionFields();
			this.project.name = this.projectName;
			// TODO: use loading spinner
			this.networkService.createNewProject(this.project, this.network.uid).subscribe((data) => {
				// Append new product
				this.projects.unshift(data);
				this.displayCpForProjects();
				this.projectName = '';
				this.projectError = false;
				delete this.project['startDate'];
				delete this.project['endDate'];
				this.projectStartDate = '';
				this.projectEndDate = '';
			});
		}
	}

	/**
	 * Function to display project delete confirmation dialog
	 * @param project
	 * @param index
	 */
	onDeleteProjectClicked(project, index) {
		this.selectedProjectIndex = index;
		this.displayDeleteModal = 'block';
		this.selectedProject = project;
	}

	/**
	 * Function to confirm a project deletion
	 */
	public confirmProjectDelete(): void {
		// TODO: use loading spinner
		this.networkService.deleteProject(this.network.uid, this.selectedProject.uid).subscribe((data) => {
			this.displayDeleteModal = 'none';

			// Remove deleted product
			this.projects.splice(this.selectedProjectIndex, 1);
		});
	}

	/**
	 * Function to display cp,name and set privacy value of selected project for edit
	 * @param project
	 * @param index
	 */
	onEditProjectClicked(project, i) {
		this.editSelectedProject = project;
		this.editSelectedProjectIndex = i;
		this.showEditBtn = true;
		this.projectName = project.name;
		this.forNames = '';
		this.privacy['personalFilter'] = null;
		if (project['startDate']) {
			this.projectStartDate = project['startDate'];
		}
		if (project['endDate']) {
			this.projectEndDate = project['endDate'];
		}

		// Handle custom privacy on product
		if (project['customPrivacy']) {
			this.privacy['custom_privacy'] = project['customPrivacy'];
			project['customPrivacy'].forEach((cp, index) => {
				if (cp.username) {
					this.forNames = `${this.forNames}${index > 0 ? ', ' : ' '}${cp.username}`;
				} else if (cp.name) {
					this.forNames = `${this.forNames}${index > 0 ? ', ' : ' '}${cp.name}`;
				}
			});
		} else if (project['contentPrivacy']) {
			this.privacy['content_privacy'] = project['contentPrivacy'];

			if (project['contentPrivacy'].role && project['contentPrivacy'].department) {
				const index = this.departments.findIndex((department) => department.uid === project['contentPrivacy'].department);
				if (index > -1) {
					this.forNames = this.departments[index]['name'] + ' , ' + project['contentPrivacy'].role;
				}
			} else if (project['contentPrivacy'].role) {
				const index = this.roles.findIndex((rol) => rol.slug === project['contentPrivacy'].role);
				this.privacy['selectedRole'] = this.roles[index];
				this.forNames = project['contentPrivacy'].role;
			} else if (project['contentPrivacy'].department) {
				const index = this.departments.findIndex((department) => department.uid === project['contentPrivacy'].department);
				if (index > -1) {
					this.forNames = this.departments[index]['name'];
					this.privacy['selectedDepartment'] = this.departments[index];
				}
			} else if (!project['contentPrivacy'].role || !project['contentPrivacy'].department) {
				const field = Object.keys(project['contentPrivacy']);
				this.personalFilters.forEach((element) => {
					if (element.key === field[0] && field[0] !== 'manager') {
						this.forNames = element.valueLabel;
						this.privacy['personalFilter'] = element;
					}
				});

				if (project['contentPrivacy'].status) {
					this.forNames = project['contentPrivacy'].status;
					this.privacy['selectByStatusValue'] = project['contentPrivacy'];
				} else if (project['contentPrivacy'].renewalStatus) {
					this.forNames = project['contentPrivacy'].renewalStatus;
					this.privacy['selectByRenewalValue'] = project['contentPrivacy'];
				} else if (project['contentPrivacy'].membership_level) {
					const index = this.SubscriptionTemplates.findIndex((val) => val.uid === project['contentPrivacy'].membership_level);
					if (index > -1) {
						this.forNames = this.SubscriptionTemplates[index]['name'];
						this.privacy['selectByLevelValue'] = project['contentPrivacy'];
					}
				} else if (project['contentPrivacy'].manager) {
					if (this.privacy.myTeam && this.privacy.myTeam.key) {
						this.forNames = this.translate.instant('My Team');
						this.privacy['personalFilter'] = this.privacy.myTeam;
					}
				}
			}
		} else if (project['channel'] && project['channel']['uid']) {
			this.privacy['channel'] = project['channel'];
			this.forNames = project.channel.name;
		} else {
			this.forNames = 'Everyone';
		}
	}

	/**
	 * Function to call edit project api and display edited project
	 */
	public onEditProject(): void {
		if (!this.projectName) {
			this.displayProjectError = 'block';
			this.modelText = this.translate.instant('Please enter a name for project');
		} else if (!this.projectStartDate) {
			this.displayProjectError = 'block';
			this.modelText = this.translate.instant('Select start date');
		} else if (!this.projectEndDate) {
			this.displayProjectError = 'block';
			this.modelText = this.translate.instant('Select end date');
		} else {
			this.displayProjectError = 'none';
			this.modelText = '';
			this.project['startDate'] = this.projectStartDate.toString();
			this.project['endDate'] = this.projectEndDate.toString();
			this.updatePrivacyValues();
			this.updateSelectionFields();
			this.project.name = this.projectName;
			// TODO: use loading spinner
			this.networkService.updateProject(this.project, this.network.uid, this.editSelectedProject.uid).subscribe((data) => {
				this.projects[this.editSelectedProjectIndex] = data;
				this.displayCpForProjects();
				this.projectName = '';
				delete this.project['startDate'];
				delete this.project['endDate'];
				this.projectStartDate = '';
				this.projectEndDate = '';
				this.projectError = false;
				this.showEditBtn = false;
			});
		}
	}

	/**
	 * Function to display task create form
	 * 1. Scroll to top of the page
	 * 2. Set task option and call function to display task create form
	 */
	public activateButton(): void {
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
		const option = { name: 'Add Task', slug: 'task' }; // 2
		this.onSelectAction(option);
	}

	/**
	 * Function which exeucutes selected action
	 * @param option
	 */
	onSelectAction(option) {
		// Gets redirected to create todo list page
		if (option.slug === 'list') {
			this.showMenu = !this.showMenu;
			this.layoutService.hideBd();
			this.router.navigateByUrl('/network/create-todolist');
			// Opens add task form
		} else if (option.slug === 'task') {
			this.showMenu = !this.showMenu;
			this.layoutService.hideBd();
			this.showPostForm();
		} else if (option.slug === 'start') {
			this.filteredItems = [];
			this.todoLists = [];
			this.startDaySelected = true;
			this.noTasks = false;
			// let filterString;
			const filterString = 'assigned=true&open=true';
			this.showMenu = !this.showMenu;
			this.getStartEndDayTodos(filterString);
			this.layoutService.hideBd();
		} else if (option.slug === 'end') {
			this.filteredItems = [];
			this.todoLists = [];
			this.noTasks = false;
			this.startDaySelected = false;
			const filterString = `assigned=true&closedDate=${new Date().toISOString().slice(0, 10)}`;
			this.displayStartEndModal = 'block';
			this.showMenu = !this.showMenu;
			this.getStartEndDayTodos(filterString);
			this.layoutService.hideBd();
		} else {
			this.showEditBtn = false;
			this.projectError = false;
			this.layoutService.hideBd();
		}
	}

	/**
	 * Function to get tasks on start/end day action
	 * @param filter
	 * 1. Display modal to show Tasks
	 * 2. Call api for getting tasks
	 * 3. Set next url
	 * 4. Push todolist name of tasks into an array for displaying in modal
	 * 5. Check items for end day action
	 */
	getStartEndDayTodos(filter) {
		this.displayStartEndModal = 'block'; // 1
		this.networkService.newFilterTodos(filter).subscribe((data) => {
			this.next_url = data.next ? data.next.split('alpha')[1] : '';

			if (data.count === 0) {
				this.noTasks = true;
			} else {
				this.noTasks = false;
			}
			this.filteredItems = data.objects;

			if (!this.startDaySelected) {
				// 5
				this.filteredItems.forEach((item) => {
					item['checked'] = true;
					this.selectedTasks.push(item);
				});
			}
			this.filteredItems.forEach((obj) => {
				// 4
				if (obj.folderDetails && obj.folderDetails.name) {
					if (this.todoLists.includes(obj.folderDetails.name) === false) {
						this.todoLists.push(obj.folderDetails.name);
					}
				}
			});
		});
	}

	/**
	 * Function to paginate tasks
	 * 1. Call paginate api
	 * 2. Set next url if it exists
	 * 3. Append tasks to current array
	 * 4. Push todolist name of tasks into an array for displaying in modal
	 */
	paginateTasks() {
		if (this.next_url !== '') {
			this.networkService.paginateTasks(this.next_url).subscribe((data) => {
				this.next_url = data.next ? data.next.split('alpha')[1] : '';

				this.filteredItems = this.filteredItems.concat(data.objects); // 3
				this.filteredItems.forEach((obj) => {
					if (obj.folderDetails && obj.folderDetails.name) {
						if (this.todoLists.includes(obj.folderDetails.name) === false) {
							this.todoLists.push(obj.folderDetails.name); // 4
						}
					}
				});
			});
		}
	}

	/**
	 * Function which executes on selecting task for start/end day action
	 * @param item
	 */
	selectTasksForActions(item) {
		if (this.selectedTasks.includes(item) === false) {
			this.selectedTasks.push(item);
		} else {
			this.selectedTasks.splice(
				this.selectedTasks.findIndex((task) => task === item),
				1
			);
		}
	}

	/**
	 * Function which creates multiple comments for start/end day action
	 * 1. Get selected tasks list uids in array
	 * 2. Push selected task uids corresponding to selected task list uid in array
	 * 3. Create an object with key as todolist uid,value as array with uids of selected task
	 * 4. Call api for start/end day action
	 */
	activateTaskComments() {
		this.selectedTasks.forEach((task) => {
			if (task.folderDetails && task.folderDetails.uid) {
				if (this.listIds.includes(task.folderDetails.uid) === false) {
					this.listIds.push(task.folderDetails.uid); // 1
				}
			}
		});
		let params;
		const obj = {};
		this.listIds.forEach((id) => {
			obj[id] = '';
			this.selectedTasks.forEach((task) => {
				if (task.folderDetails && task.folderDetails.uid) {
					if (id === task.folderDetails.uid) {
						this.selectedTodos.push(task.uid); // 2
					}
				}
			});
			obj[id] = this.selectedTodos; // 3
			this.selectedTodos = [];
		});

		const jsonObj = JSON.stringify(obj);
		if (this.startDaySelected) {
			params = {
				start: true,
				data: jsonObj,
				l: this.language,
			};
		} else {
			params = { end: true, data: jsonObj, l: this.language };
		}

		this.loadingGif = 'block';
		this.networkService.postTodoListActiveMembers(params).subscribe((data) => {
			// 4
			this.loadingGif = 'none';
			this.networkService.populate();
			this.displayStartEndModal = 'none';
			if (this.showTasksModal) {
				this.showTasksModal = false;
				this._location.back();
			}
		});
	}

	/**
	 * Function to navigate to todo/post detail page
	 * @param item
	 * 1. If task contains relatedContent, navigate to post/issue detail page
	 * 2. Navigate to todo detail page
	 */
	goToDetailPage(item) {
		if (item.relatedContent && item.relatedContent.contentType) {
			// 1
			if (item.relatedContent.contentType === 'post') {
				this.router.navigateByUrl('/posts/' + item.relatedContent.contentUid);
			} else if (item.relatedContent.contentType === 'issue') {
				// 1
				this.router.navigateByUrl(`/network/issue-detail?id=${item.relatedContent.contentUid}`);
			}
		} else {
			// 2
			const url = `/project/task-detail/${item.uid}`;

			this.router.navigateByUrl(url);
		}
	}

	/**
	 * Function to display todolists modal for move to another list action
	 * 1. Display todolists modal
	 * 2. Call api for getting all todolists
	 */
	displayTodoListsModal(item) {
		this.selectedToDoItem = item;
		this.displayLists = 'block'; // 1
		this.todoListsForItem = [];
		this.getListsForMoveAction(); // 2
	}

	/**
	 * Function to get all todolists for move action
	 * 1. Call api for getting todolists
	 * 2. Set next url
	 */
	getListsForMoveAction() {
		if (localStorage.getItem('network_id')) {
			this.networkService.getToDoListForItem().subscribe((list) => {
				// 1
				if (list.next) {
					this.next_url = list.next.split('alpha')[1]; // 2
					this.paginateLists();
				} else {
					this.next_url = '';
				}
				this.todoListsForItem = list.objects;
			});
		}
	}

	/**
	 * Function to paginate todolists for move action
	 * 1. Call paginate api for getting todolists
	 * 2. Set next url
	 * 3. Append todolists into array
	 */
	paginateLists() {
		if (this.next_url !== '') {
			this.networkService.paginateTasks(this.next_url).subscribe((data) => {
				this.next_url = data.next ? data.next.split('alpha')[1] : '';

				this.todoListsForItem = this.todoListsForItem.concat(data.objects); // 3
			});
		}
	}

	/**
	 * Function on selecting a todolist in move to anotherlist modal
	 * @param list
	 */
	onSelectTodoList(list) {
		this.lists = { option: list.name };
		this.selectedToDoList = list;
	}

	backToNoifications() {
		if (this.showTasksModal) {
			this.showTasksModal = false;
			this._location.back();
		}
	}

	checkIfFetched() {
		this.isTodosFetched = true;
	}

	/**
	 * Function to get todos for header filters
	 * @param key
	 * @param value
	 */
	filterTodos(key, value) {
		if (this.filter['value'] !== '' && this.filter['value'] === value) {
			this.filter['value'] = '';

			this.getToDoLists('');
		}

		if (this.isFetching === 1 && this.isTodosFetched) {
			if (key === 'assigned_to') {
				this.tab = 'pastDue';

				localStorage.setItem('tab', 'pastDue');
			} else if (key === 'active_filter' || key === 'reminder') {
				this.tab = 'active_filter';

				localStorage.setItem('tab', 'open');
			} else if (key === 'today' || key === 'closed') {
				this.tab = 'closed';

				localStorage.setItem('tab', 'closed');
			}

			this.todoTime = new Date().getSeconds();
			this.emptyLists = false;
			this.filter['value'] = value;
			this.projects = [];
			this.todoLists = [];
			this.nextUrl = '';

			if (key === 'assigned_to') {
				this.filterParams = key + '=' + value + '&open=true';
			} else {
				this.filterParams = key + '=' + value;
			}

			this.isTodosFetched = false;
			this.searchService.resetSearchValue();
		}
	}

	/**
	 * Function to get todos based on priority
	 * @param key
	 * @param value
	 */
	filterTodosOnPriority(key, value) {
		this.tab = 'open';
		this.emptyLists = false;
		this.filter['value'] = value;
		this.projects = [];
		this.todoLists = [];
		this.filterParams = key + '=' + value + '&open=true';
		this.todoTime = new Date().getSeconds();
		this.searchService.resetSearchValue();
	}

	getCounts() {
		// this.refresh();
		this.countsSubscriber = this.networkService.getTodoListCounts().subscribe((data) => {
			this.todoListCounts = data;
		});
	}

	private getArchivedCounts(): void {
		this.archivedSubscriber = this.networkService.getArchivedCounts().subscribe((data) => {
			this.archivedCounts = data['archivedCounts'];
		});
	}

	getArchivedProjectCounts() {
		this.archivedSubscriber = this.networkService.getArchivedProjectCounts().subscribe((data) => {
			this.archivedProjects = data['archivedCounts'];
		});
	}

	refresh() {
		if (this.tab === 'open') {
			this.filterTodos('open', 'true');
		} else if (this.tab === 'closed') {
			this.filterTodos('today', 'true');
		} else if (this.tab === 'pastDue') {
			this.filterTodos('past', 'true');
		}
	}

	public onImgError(event): void {
		event.target.src = 'assets/images/default_avatar.jpg';
	}

	public getPercentageOf(partialValue: number, total: number): number {
		return MathUtils.getPercentageOf(partialValue, total);
	}
}
