import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BeeError } from '../models/custom-error';

@Injectable({
	providedIn: 'root',
})
export class FormServiceService {
	constructor() {}

	public handleFormErrors(formControl: AbstractControl, customHints: any = {}): BeeError[] {
		if (formControl.errors) {
			const errors = Object.keys(formControl.errors);

			return <BeeError[]>errors.map((error: any) => {
				const newError = new BeeError({
					type: error,
				});

				if (error === 'pattern') {
					newError.message = 'Invalid input content type or format';
					newError.hiddenHint = `Hint: ${formControl.errors[error].requiredPattern}`;
				} else if (error === 'required') {
					newError.message = 'This field is required';
				} else if (error === 'minlength') {
					newError.message = 'This field is too short';
					newError.hint = `At least ${formControl.errors[error].requiredLength} characters are required`;
				} else if (error === 'maxlength') {
					newError.message = 'This field is too long';
					newError.hint = `At most ${formControl.errors[error].requiredLength} characters are allowed`;
				} else if (error === 'email') {
					newError.message = 'Invalid email address';
				} else if (error === 'validatePhoneNumber') {
					newError.message = 'Invalid phone number';
					newError.hint = `Phone number must be in the format: ${customHints.validatePhoneNumber}`;
				} else if (error === 'invalid') {
					newError.message = 'Invalid format';
				}

				return newError;
			});
		}
	}
}
