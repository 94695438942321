/* eslint-disable spellcheck/spell-checker */
import { Component, Input, OnInit } from '@angular/core';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';
import { ProjectService } from '@modules/project/services/project.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ICurrentUser } from '@shared/models';
import { PostService } from '@shared/services';
import { ToastService } from '@shared/services/common/toast.service';
import format from 'date-fns/format';

@Component({
	selector: 'app-no-meeting-day-modal',
	templateUrl: './no-meeting-day-modal.component.html',
	styleUrls: ['./no-meeting-day-modal.component.scss'],
})
export class NoMeetingDayModalComponent implements OnInit {
	@Input() public networkId: string;
	@Input() public currentUser: ICurrentUser;

	private dummyModel: any;
	private selectedDay: { day: string; selection: boolean; dayCode: number };
	private daySelected = false;
	private startDate: Date;
	private endDate: Date;
	public dates = [
		{ day: 'Monday', selection: false, dayCode: 1, disabled: false },
		{ day: 'Tuesday', selection: false, dayCode: 2, disabled: false },
		{ day: 'Wednesday', selection: false, dayCode: 3, disabled: false },
		{ day: 'Thursday', selection: false, dayCode: 4, disabled: false },
		{ day: 'Friday', selection: false, dayCode: 5, disabled: false },
		{ day: 'Saturday', selection: false, dayCode: 6, disabled: false },
		{ day: 'Sunday', selection: false, dayCode: 0, disabled: false },
	];

	constructor(
		public activeModal: NgbActiveModal,
		private translateService: TranslateService,
		private toastService: ToastService,
		private postService: PostService,
		private projectService: ProjectService,
		private pageLoadingUiService: PageLoadingUiService
	) {}

	ngOnInit(): void {
		const today = new Date().getDay();
		this.dates.forEach((day: any) => {
			if (day.dayCode < today) {
				day.disabled = true;
			}
		});
		console.log(this.dates);
	}

	public closeModal(): void {
		this.activeModal?.close();
	}

	public selectDay(selectedDate: any): void {
		if (!selectedDate?.disabled) {
			this.selectedDay = selectedDate;
			this.dates.forEach((date) => {
				if (date.day === selectedDate.day) {
					date.selection = true;
				} else {
					date.selection = false;
				}
			});
			this.daySelected = true;
		}
	}

	/**
	 * Converts local time to UTC
	 * @param date Local Time
	 * @returns UTC Conversion
	 */
	private convertLocalToUTCTime(date: Date): Date {
		const isoDate = date.toISOString();
		return new Date(`${isoDate.substring(0, 10)} ${isoDate.substring(11, 19)}`);
	}

	public createEvent(): void {
		if (this.daySelected) {
			const now = new Date();
			const today = new Date().getDay();
			const difference = this.selectedDay.dayCode - today;
			// if (difference >= 0) {
			const selectedDate = now.setDate(now.getDate() + difference);
			const starts = new Date(selectedDate);
			const ends = starts.setHours(starts.getHours() + 12);
			this.startDate = new Date(selectedDate);
			this.endDate = new Date(ends);
			// } else {
			// 	const newDifference = 7 + difference;
			// 	const selectedDate = new Date(now.setDate(now.getDate() + newDifference));
			// 	const starts = new Date(selectedDate);
			// 	const ends = starts.setHours(starts.getHours() + 12);
			// 	this.startDate = new Date(selectedDate);
			// 	this.endDate = new Date(ends);
			// }

			const startDateUTC = this.convertLocalToUTCTime(this.startDate);
			const endDateUTC = this.convertLocalToUTCTime(this.endDate);

			this.dummyModel = {
				network: this.networkId,
				username: this.currentUser?.username,
				shareable: 1,
				commentable: 1,
				attachmentType: 'photo',
				state: 'active',
				cost: 'free',
				link: 'www.andoneapp.com',
				type: 'event',
				starts: format(startDateUTC, 'yyyy-MM-dd HH:mm:ss'),
				ends: format(endDateUTC, 'yyyy-MM-dd HH:mm:ss'),
				category: 'no-meeting',
				title: 'No Meeting Day',
				images: 'e8d5c3b507debd5a5f3ed97cee2cc91944d99898_HyBKNxG',
				custom_privacy: '[' + [this.currentUser.userId] + ']',
			};

			this.pageLoadingUiService.setPageLoading(true);

			// API Call for creating No Meeting Day Post
			this.postService.create(this.dummyModel).subscribe((res: any) => {
				if (res) {
					const task = {
						assignedUser: this.currentUser?.username,
						category: 'todo',
						collection_post: true,
						commentable: true,
						description: '',
						endTime: format(startDateUTC, 'yyyy-MM-dd HH:mm:ss'),
						network: this.networkId,
						oneOff: format(startDateUTC, 'yyyy-MM-dd HH:mm:ss'),
						priority: 'p1',
						repeatCycle: 'one_off',
						selectedDueDate: 'one_off',
						startTime: format(startDateUTC, 'yyyy-MM-dd HH:mm:ss'),
						state: 'active',
						title: this.dummyModel.title,
						type: 'todo',
						uid: res.uid,
					};
					// Second API Call for creating No Meeting Day task to task list
					this.projectService
						.createToDo(task, this.currentUser?.username)
						.subscribe(() => {
							this.toastService.success(this.translateService.instant('New No Meeting Day Event Created'));
							this.closeModal();
						})
						.add(() => {
							this.pageLoadingUiService.setPageLoading(false);
						});
				}
			});
		} else {
			this.toastService.info('Please select valid day');
		}
	}
}
