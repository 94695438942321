import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { IWorkspace } from '@shared/models/workspace/workspace';
import { NetworkService } from '@shared/services';

@Injectable({
	providedIn: 'root',
})
export class CustomerGuard implements CanActivate {
	constructor(private networkService: NetworkService, private router: Router) {}

	public canActivate(): boolean {
		let isAuthenticated = false;
		this.networkService.networkReady.subscribe((isLoaded: any) => {
			if (isLoaded) {
				this.networkService.currentNetwork.subscribe((workspace: IWorkspace | any) => {
					if (
						workspace?.uid &&
						(workspace?.subscription?.planQuotas?.numberOfCustomers >= 0 || workspace?.subscription?.network?.subscription?.subscriptionPlan?.name === 'Starter') &&
						(workspace?.subscription?.network?.subscription.status === 'active' || workspace?.subscription?.network?.subscription.status === 'trailing')
					) {
						isAuthenticated = true;
					}
				});
			}
		});

		if (!isAuthenticated) {
			this.router.navigate(['/network']);
			return false;
		}
		return true;
	}
}
