import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { PostDetailComponent } from './post-detail.component';
import { SharedModule } from '../shared';
import { AgmCoreModule } from '@agm/core';
import { BeeAttachmentModule } from 'projects/bee-attachment/src/public-api';
import { PeerCoreModule } from '../../../projects/peer-core/src/lib/peer-core.module';
import { BeeAvatarModule } from 'projects/bee-ui/src/lib/modules/avatar/avatar.module';

const networkRouting: ModuleWithProviders<any> = RouterModule.forChild([
	{
		path: 'posts/:uid',
		component: PostDetailComponent,
	},
]);

@NgModule({
	declarations: [PostDetailComponent],
	bootstrap: [PostDetailComponent],
	providers: [],
	imports: [networkRouting, BrowserModule, AgmCoreModule, SharedModule, BeeAttachmentModule, BeeAvatarModule, PeerCoreModule],
})
export class PostDetailModule {}
