import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonPreviewComponent } from './person-preview.component';

@NgModule({
	declarations: [PersonPreviewComponent],
	imports: [CommonModule, RouterModule],
	exports: [PersonPreviewComponent],
})
export class PersonPreviewModule {}
