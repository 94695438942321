/* eslint-disable spellcheck/spell-checker */
import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { NetworkService } from '../services/network.service';
import { LayoutService, PbTranslateService } from '../services';
import { environment } from '../../../../src/environments/environment';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';
import CacheUtils from '@shared/utils/cache-utils';
import { IWorkPlace, workplaceData } from '@shared/models/workspace/workplace';
import cloneDeep from 'lodash/cloneDeep';

@Component({
	selector: 'app-network-settings',
	templateUrl: './network-settings.component.html',
	styleUrls: ['./network-settings.component.scss'],
})
export class NetworkSettingsComponent implements OnInit {
	@Input() public currentUser: any;

	public appName = environment.appName;
	public isNeaty = this.appName === 'Neaty';
	public displayDialog = 'none';
	public displayNetworks = 'none';
	public dailyDigestsNetworks = 'none';
	public displayStatusModal = 'none';
	public networkLoaded;
	public network;
	public displayPointLadder = false;
	public isExpired = false;
	public currentStatus;
	public defaultStatus;
	public currentWorkPlace: string;
	public selectedDepartment;
	public statusChoices = [];
	public memberships = [];
	public departments = [];
	public dummy = [];
	public nextUrl;
	public membershipUid;
	public isDailyDigestEnabled = false;
	public userEmail;
	public language = 'en';
	public selected: any = [];
	public settings = {};
	public zoom_oauth_url = environment.zoom_oauth_url;
	public department;
	public selectedOption;
	public pointsSettings = 'none';
	public ladderSettings = { pointsTablePrivacy: '' };
	public options = ['Everyone', 'My Team', 'My Department'];
	// eslint-disable-next-line spellcheck/spell-checker
	public unlinkzoom_modal = 'none';
	public linkZoom_modal = 'none';
	public data: any = { notification_type: null, mute_period: null };
	public showDoNotDisturbOptions = '';
	public currentSelectedMenu = '';
	public currentSelectedSubMenu = '';
	public selectedNetwork: any;
	public userDetails: any;
	public networkServices: any;
	public activeMemberships: any;
	public dailyDigestNetworks: any[];
	public showAvailabilityItem: any;
	public networks: any;
	public selectedMutedNetworkType: any;
	public selectedMuteNetworksId = '';
	public mutedNetworks: any[];
	public allNetworksMuted: any;
	public MuteNetworkString: any;
	public availability: any[] = [];
	public dropdownItem: any;
	public oldNetworkType: any;
	public oldMutedNetworks: any;
	public currentNetwork: any;
	public showMuteIcon = false;
	public zoom_linked = false;
	public muted;
	public user_mute_id;
	public muteNetworkId;
	public workplaceData: IWorkPlace[] = workplaceData;
	private selectedWorkPlace: IWorkPlace = workplaceData[0];

	public networkType = [
		{ name: 'Contacts', type: 'self', value: -1, show: true, muted: false },
		{ name: 'School', type: 'school', value: 0, show: false, muted: true },
		{
			name: 'University',
			type: 'university',
			value: 1,
			show: false,
			muted: true,
		},
		{
			name: 'Community',
			type: 'community',
			value: 2,
			show: false,
			muted: true,
		},
		{
			name: 'Neighborhood',
			type: 'neighborhood',
			value: 3,
			show: false,
			muted: true,
		},
		{ name: 'Memberships', type: 'clubs', value: 4, show: false, muted: true },
		{ name: 'Works', type: 'enterprise', value: 5, show: false, muted: true },
		{ name: 'Election', type: 'election', value: 6, show: false, muted: true },
	];

	constructor(
		private userService: UserService,
		private networkService: NetworkService,
		private layoutService: LayoutService,
		public translate: PbTranslateService,
		private pageLoadingUiService: PageLoadingUiService
	) {}

	public isEmpty(object): boolean {
		for (const key in object) {
			if (object.hasOwnProperty(key)) {
				return true;
			}
		}

		return false;
	}

	ngOnInit(): void {
		this.networkService.currentNetwork.subscribe((data) => {
			if (data.muted) {
				this.showMuteIcon = true;
				this.muteNetworkId = data.muted;
			} else {
				this.showMuteIcon = false;
			}
		});
		if (this.isNeaty) {
			this.language = 'ja';
			this.translate.changeLanguage(this.language);
			return;
		} else {
			if (localStorage.getItem('language') === 'en') {
				this.language = 'en';
			} else {
				if (this.isNeaty) {
					this.language = 'ja';
				} else {
					this.language = 'tr';
				}
			}
		}
		{
		}

		const self = this;
		this.userService.currentUser.subscribe((user) => {
			if (self.isEmpty(user)) {
				this.userDetails = user;
				this.user_mute_id = user.muted;
				this.zoom_linked = this.userDetails.zoomLinked;
				if (this.userDetails['country'] && this.userDetails['country']['networkService']) {
					this.networkServices = this.userDetails['country']['networkService'];
				}

				this.activeMemberships = this.userService.memberships;
				this.mutedNetworks = this.userDetails['muteSettings'];
				this.dailyDigestNetworks = this.userDetails['dailyDigestSettings'];
				if (user['email']) {
					this.userEmail = user['email'];
				}
				this.updateNetworkTypeStatus();
			}
		});

		this.networkService.networkReady.subscribe((networkLoaded) => {
			this.networkLoaded = networkLoaded;
			if (networkLoaded) {
				this.networkService.currentNetwork.subscribe((network) => {
					this.network = network;
					if (network['availabilityStatusChoices'] && network['availabilityStatusChoices'].length > 0) {
						this.statusChoices = network['availabilityStatusChoices'];
					} else {
						this.statusChoices = [];
					}

					if (this.network['pointsEarning']) {
						this.displayPointLadder = true;
					} else {
						this.displayPointLadder = false;
					}

					if (this.network['pointsTablePrivacy']) {
						if (this.network['pointsTablePrivacy'] === 1) {
							this.selectedOption = 'My Team';
						} else {
							this.selectedOption = 'My Department';
						}
					} else {
						this.selectedOption = 'Everyone';
					}

					this.checkIfDailyDigestEnabled();
				});
			}
		});

		if (this.network && this.network['membershipUid']) {
			this.membershipUid = this.network['membershipUid'];
		} else if (localStorage.getItem('membership')) {
			const membership = JSON.parse(localStorage.getItem('membership'));
			if (membership?.uid) {
				this.membershipUid = membership['uid'];
			}
		}

		if (localStorage.getItem('networkExpired')) {
			this.isExpired = true;
		} else {
			this.isExpired = false;
		}
	}

	private updateNetworkTypeStatus(): void {
		const self = this;
		this.activeMemberships.forEach(function (element) {
			const networkTypeIndex = self.networkType.findIndex((type) => type.value === element.network.networkType);
			if (networkTypeIndex > -1) {
				self.networkType[networkTypeIndex]['show'] = true;
				if (self.mutedNetworks.indexOf(element.network.uid) === -1) {
					self.networkType[networkTypeIndex]['muted'] = false;
					self.allNetworksMuted = false;
				}
			}
		});
		if (this.userDetails.contactsMute) {
			self.networkType[0].muted = true;
		}
	}

	public stopPropogation(event): void {
		event.stopPropagation();
	}

	public onEditClick(menu): void {
		event.stopPropagation();
		this.currentSelectedMenu = menu;
		this.currentSelectedSubMenu = '';
	}

	public onShowClick(menu): void {
		event.stopPropagation();
		if (this.currentSelectedSubMenu === menu) {
			this.currentSelectedSubMenu = '';
		} else {
			this.currentSelectedSubMenu = menu;
		}
	}

	public onShowAvailability(item): void {
		this.showAvailabilityItem = item;
	}

	public muteByNetworkType(type): void {
		const self = this;
		const i = this.networkType.indexOf(type);
		if (!this.networkType[i].muted) {
			if (type.value !== -1) {
				self.activeMemberships.forEach(function (element) {
					if (element.network.networkType === type.value) {
						const index = self.mutedNetworks.indexOf(element.network.uid);
						if (index === -1) {
							self.mutedNetworks.push(element.network.uid);
						}
					}
				});
				this.networkType[i].muted = true;
			} else {
				this.networkType[0].muted = true;
			}
		} else {
			this.activeMemberships.forEach(function (element) {
				if (element.network.networkType === type.value) {
					const index = self.mutedNetworks.indexOf(element.network.uid);
					self.mutedNetworks.splice(index, 1);
				}
			});
			this.networkType[i].muted = true;
		}
		this.updateNetworkTypeStatus();
	}

	public muteAllNetworks(): void {
		const self = this;
		this.allNetworksMuted = !this.allNetworksMuted;
		if (this.allNetworksMuted) {
			this.oldNetworkType = this.networkType;
			this.oldMutedNetworks = this.mutedNetworks;
			this.mutedNetworks = [];
			this.activeMemberships.forEach(function (element) {
				self.mutedNetworks.push(element.network.uid);
			});
			this.networkType.forEach(function (element, index) {
				self.networkType[index].muted = true;
			});
		} else {
			this.networkType = this.oldNetworkType;
			this.mutedNetworks = this.oldMutedNetworks;
			this.updateNetworkTypeStatus();
		}
	}

	public alterMutedNetwork(network): void {
		const index = this.mutedNetworks.indexOf(network.network.uid);
		if (index > -1) {
			this.mutedNetworks.splice(index, 1);
		} else {
			this.mutedNetworks.push(network.network.uid);
		}
		this.updateNetworkTypeStatus();
	}

	public pushToDailyDigestnetwork(networkId): void {
		event.stopPropagation();
		const index = this.dailyDigestNetworks.indexOf(networkId);
		if (index === -1) {
			this.dailyDigestNetworks.push(networkId);
		} else {
			this.dailyDigestNetworks.splice(index, 1);
		}
	}

	public onSubmitClick(menu): void {
		const self = this;
		let contactsMute = 0;

		switch (menu) {
			case 'mute':
				this.MuteNetworkString = '';
				this.mutedNetworks.forEach(function (element) {
					if (self.MuteNetworkString === '') {
						self.MuteNetworkString = element;
					} else {
						self.MuteNetworkString += '|' + element;
					}
				});
				if (this.networkType[0].muted) {
					contactsMute = 1;
				}
				this.pageLoadingUiService.setPageLoading(true);
				this.networkService.updateNetworkMuteStatus(contactsMute, self.MuteNetworkString).subscribe((data) => {
					this.activeMemberships = data['activeMemberships'];
					this.mutedNetworks = data['muteSettings'];
					this.updateNetworkTypeStatus();
					this.pageLoadingUiService.setPageLoading(false);
				});
				break;
			case 'email':
				let dailyDigestString = '';
				this.dailyDigestNetworks.forEach(function (element) {
					if (dailyDigestString === '') {
						dailyDigestString = element;
					} else {
						dailyDigestString += '|' + element;
					}
				});
				this.pageLoadingUiService.setPageLoading(true);
				this.networkService.updateDailyDigest(dailyDigestString).subscribe((data) => {
					this.dailyDigestNetworks = data['dailyDigestSettings'];
					this.pageLoadingUiService.setPageLoading(false);
				});
				break;
		}
	}

	public updateAvailability(availabiltyStatus, network): void {
		this.pageLoadingUiService.setPageLoading(true);
		this.networkService.updateAvailabiltyStatus(availabiltyStatus, network.uid).subscribe((data) => {
			this.pageLoadingUiService.setPageLoading(false);
			const index = this.activeMemberships.indexOf(network);
			this.activeMemberships[index].availabilityStatus = data['availabilityStatus'];
			this.selectedNetwork = this.activeMemberships[index];
			this.userService.updateUserDetails();
		});
	}

	public onNetworkSelect(network): void {
		event.stopPropagation();
		this.selectedNetwork = network;
	}

	public onShowSettingsClick(): void {
		this.currentSelectedMenu = '';
	}

	public hideLayout(): void {
		this.layoutService.hideBd();
	}

	/**
	 * 1. Set this item in LS for checking if user switched language manually
	 * @param lang
	 */
	public onChangeLanguage(lang): void {
		localStorage.setItem('user_language', 'enabled');
		this.translate.changeLanguage(lang);
		location.reload();
	}

	public selectDoNotDisturb(value): void {
		this.pageLoadingUiService.setPageLoading(true);
		// this.data['notification_type'] = 'only_me';
		this.data['notification_type'] = 'none';
		this.data['mute_period'] = value;
		// this.networkService.notificationControl(this.data).subscribe((data) => {
		//   // location.reload();
		//   this.onEditClick(null);
		//   this.networkService.populate();
		//   this.pageLoadingUiService.setPageLoading(false);
		// });
		this.networkService.onMuteNetwork(this.data).subscribe(
			() => {
				// location.reload();
				this.onEditClick(null);
				this.networkService.populate();
				this.pageLoadingUiService.setPageLoading(false);
			},
			() => {
				this.pageLoadingUiService.setPageLoading(false);
			}
		);
	}

	public unMute(): void {
		if (this.showMuteIcon) {
			this.pageLoadingUiService.setPageLoading(true);
			this.networkService.unMuteNetwork(this.muteNetworkId).subscribe(
				() => {
					this.networkService.populate();
					this.pageLoadingUiService.setPageLoading(false);
					this.showMuteIcon = false;
				},
				() => {
					this.pageLoadingUiService.setPageLoading(false);
				}
			);
		}
	}

	public preventDefaultAction(): void {
		event.stopPropagation();
		this.currentSelectedMenu = '';
		this.currentSelectedSubMenu = '';
	}

	public onZoomClick(): void {
		event.stopPropagation();
		this.currentSelectedMenu = '';
		this.currentSelectedSubMenu = '';
		if (this.zoom_linked) {
			this.unlinkzoom_modal = 'block';
		} else {
			this.linkZoom_modal = 'block';
		}
	}

	// Function to unlink zoom
	public unlinkZoom(): void {
		const data = { action: 'unlink' };
		this.pageLoadingUiService.setPageLoading(true);
		this.userService.zoomAuth(data).subscribe(() => {
			this.pageLoadingUiService.setPageLoading(false);
			this.layoutService.hideBd();
			this.unlinkzoom_modal = 'none';
			// call user details api and update current user data
			this.userService.updateUserDetails();
			// Remove user details from LS,hence on refresh updated user details gets on LS
			localStorage.removeItem('userDetails');
			CacheUtils.removeStoredData('userDetails');
		});
	}

	// Close zoom unlink modal
	public hideUnlinkModal(): void {
		this.unlinkzoom_modal = 'none';
		this.zoom_linked = true;
		this.layoutService.hideBd();
	}

	// Close zoom link modal
	public hideZoomLinkModal(): void {
		this.linkZoom_modal = 'none';
		this.zoom_linked = false;
		this.layoutService.hideBd();
	}

	// For closing zoom link modal on clicking link zoom account option
	public onLinkAccountClick(): void {
		this.linkZoom_modal = 'none';
		this.layoutService.hideBd();
		localStorage.setItem('zoom_authentication', 'true');
	}

	public handleNetworkStatus(): void {
		if (this.network) {
			this.currentStatus = '';
			this.defaultStatus = '';
			this.displayStatusModal = 'block';
			this.pageLoadingUiService.setPageLoading(true);

			this.networkService.getMembershipDetails(this.network['membershipUid']).subscribe((membership) => {
				this.pageLoadingUiService.setPageLoading(false);
				if (membership['availabilityStatus']) {
					this.defaultStatus = membership['availabilityStatus'];
					this.currentStatus = membership['availabilityStatus'];
				}
				if (membership['workplaceToday']) {
					this.currentWorkPlace = membership['workplaceToday'];
					const wp = workplaceData.find(w => w.value === membership['workplaceToday']);
					this.selectedWorkPlace = cloneDeep(wp);
					this.onWorkPlaceChange(this.selectedWorkPlace);
				}
			});
		} else {
			this.displayNetworks = 'block';
			this.getMemberships();
		}
	}

	public getMemberships(): void {
		this.memberships = [];
		this.userService.getMemberships(true).subscribe((data) => {
			if (data.next) {
				this.nextUrl = data.next.split('alpha')[1];
				this.paginate();
			} else {
				this.nextUrl = '';
			}
			this.memberships = data.objects;
			this.memberships.forEach((membership) => {
				const index = this.dailyDigestNetworks.findIndex((id) => id === membership['network']['uid']);
				if (index > -1) {
					membership['checked'] = true;
				} else {
					membership['checked'] = false;
				}
			});
		});
	}

	public paginate(): void {
		if (this.nextUrl) {
			this.userService.paginate(this.nextUrl).subscribe((data) => {
				if (data.next) {
					this.nextUrl = data.next.split('alpha')[1];
				} else {
					this.nextUrl = '';
				}
				this.memberships = this.memberships.concat(data.objects);
				this.memberships.forEach((membership) => {
					const index = this.dailyDigestNetworks.findIndex((id) => id === membership['network']['uid']);
					if (index > -1) {
						membership['checked'] = true;
					} else {
						membership['checked'] = false;
					}
				});
			});
		}
	}

	public displayStatusForNetwork(membership): void {
		this.pageLoadingUiService.setPageLoading(true);
		this.defaultStatus = membership['availabilityStatus'];
		this.currentStatus = membership['availabilityStatus'];
		this.networkService.get(membership['network']['uid']).subscribe((data) => {
			this.pageLoadingUiService.setPageLoading(false);
			this.membershipUid = data['membershipUid'];
			this.displayNetworks = 'none';
			this.displayStatusModal = 'block';
			if (data['availabilityStatusChoices'] && data['availabilityStatusChoices'].length > 0) {
				this.statusChoices = data['availabilityStatusChoices'];
			}
		});
	}

	public handleNetworksList(): void {
		if (!this.network) {
			this.displayNetworks = 'block';
			this.displayStatusModal = 'none';
			this.getMemberships();
		}
	}

	public updateNetworkStatus(status): void {
		this.currentStatus = status;
		if (this.currentStatus !== this.defaultStatus || this.currentWorkPlace !== this.selectedWorkPlace.value) {
			this.pageLoadingUiService.setPageLoading(true);
			this.networkService.updateAvailabiltyStatus(status, this.membershipUid, this.selectedWorkPlace.value ? this.selectedWorkPlace.value: '').subscribe((data) => {
				this.defaultStatus = data['availabilityStatus'];
				this.pageLoadingUiService.setPageLoading(false);
				this.handleNetworksList();
			});
		}
	}

	public handleDailyDigest(): void {
		if (this.network) {
			let param;
			this.onSelectNetwork(this.network['uid']);
			if (this.dailyDigestNetworks.length > 0) {
				param = this.dailyDigestNetworks.join('|');
			} else {
				param = 'none';
			}
			this.pageLoadingUiService.setPageLoading(true);
			this.networkService.updateDailyDigest(param).subscribe(() => {
				const user = JSON.parse(localStorage.getItem('userDetails'));
				user['dailyDigestSettings'] = this.dailyDigestNetworks;
				localStorage.setItem('userDetails', JSON.stringify(user));
				CacheUtils.storeData('userDetails', JSON.stringify(user));
				this.checkIfDailyDigestEnabled();
				this.pageLoadingUiService.setPageLoading(false);
			});
		} else {
			this.dailyDigestsNetworks = 'block';
			this.getMemberships();
		}
	}

	/**
	 * Check if dailydigest feature is enabled for network
	 * 1. Check if network uid is present in dailydigest array
	 * 2. If present,enable variable
	 * 3. Else disable variable
	 */
	private checkIfDailyDigestEnabled(): void {
		if (this.dailyDigestNetworks.length > 0) {
			const index = this.dailyDigestNetworks.findIndex((id) => id === this.network['uid']); // 1
			if (index > -1) {
				// 2
				this.isDailyDigestEnabled = true;
			} else {
				this.isDailyDigestEnabled = false; // 3
			}
		}
	}

	/**
	 * For adding/removing daily digest feature for networks
	 */
	public addOrRemoveDailyDigests(): void {
		let param;
		this.pageLoadingUiService.setPageLoading(true);

		if (this.dailyDigestNetworks.length > 0) {
			param = this.dailyDigestNetworks.join('|');
		} else {
			param = 'none';
		}

		this.networkService.updateDailyDigest(param).subscribe(() => {
			const user = JSON.parse(localStorage.getItem('userDetails'));
			user['dailyDigestSettings'] = this.dailyDigestNetworks;
			localStorage.setItem('userDetails', JSON.stringify(user));
			this.pageLoadingUiService.setPageLoading(false);
		});
	}

	/**
	 * Add/remove networks from daily digest array
	 * @param network
	 * 1. Check if network uid is not present in dailyDijest
	 * 2. If true,add that network uid
	 * 3. Else remove that network uid
	 */
	public onSelectNetwork(network): void {
		if (this.dailyDigestNetworks.includes(network) === false) {
			// 1
			this.dailyDigestNetworks.push(network); // 2
		} else {
			this.dailyDigestNetworks.splice(
				this.dailyDigestNetworks.findIndex((id) => id === network),
				1
			); // 3
		}
	}

	public onSelectDepartment(department): void {
		if (department['uid']) {
			const i = this.selected.findIndex((a) => a['name'] === 'All');
			if (i > -1) {
				this.selected.splice(i, 1);
			}

			this.departments[0]['checked'] = false;
			const index = this.selected.findIndex((_department) => _department === department['uid']);

			if (index > -1) {
				this.selected.splice(index, 1);
			} else {
				this.selected.push(department['uid']);
			}
		} else {
			this.selected = [];
			this.departments.forEach((_department) => {
				_department['checked'] = false;
			});

			this.departments[0]['checked'] = true;
			this.selected.push(department);
		}
	}

	public displayPointsSettings(): void {
		this.pointsSettings = 'block';
		if (this.network['pointsTablePrivacy']) {
			if (this.network['pointsTablePrivacy'] === 1) {
				this.selectedOption = 'My Team';
			} else {
				this.selectedOption = 'My Department';
			}
		} else {
			this.selectedOption = 'Everyone';
		}
	}

	public enablePointsSettings(): void {
		if (this.selectedOption) {
			if (this.selectedOption === 'Everyone') {
				this.ladderSettings['pointsTablePrivacy'] = '0';
			} else if (this.selectedOption === 'My Department') {
				this.ladderSettings['pointsTablePrivacy'] = '2';
			} else {
				this.ladderSettings['pointsTablePrivacy'] = '1';
			}

			this.pageLoadingUiService.setPageLoading(true);
			this.networkService.enablePointsSettings(this.ladderSettings).subscribe(() => {
				this.resetPointsSettings();
				this.pageLoadingUiService.setPageLoading(false);
				this.networkService.populate();
			});
		} else {
			this.resetPointsSettings();
		}
	}

	public resetPointsSettings(): void {
		this.pointsSettings = 'none';
		this.ladderSettings['pointsTablePrivacy'] = '';
	}

	private clearCache(): void {
		if (localStorage.getItem('networkExpired')) {
			localStorage.removeItem('networkExpired');
		}
	}

	public onWorkPlaceChange(workplace: IWorkPlace): void{
		this.selectedWorkPlace = {...workplace};
		this.workplaceData.forEach(wp => {
			if(wp.value === workplace.value){
				wp.selected = true;
			}else{
				wp.selected = false;
			}
		});
		this.workplaceData = cloneDeep(this.workplaceData);
	}
}
