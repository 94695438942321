import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-main-panel',
	templateUrl: './main-panel.component.html',
	styleUrls: ['./main-panel.component.css'],
})
export class MainPanelComponent implements OnInit {
	private websiteUrl = environment.appURL + '/learn-center';

	constructor(private translateService: TranslateService) {}

	ngOnInit(): void {}

	public redirectToResource(): void {
		const currentLanguage: string = this.translateService.currentLang;

		if (currentLanguage !== 'en') {
			// window.open(this.websiteUrl.replace('https://', `https://${currentLanguage}.`));
			window.open(this.websiteUrl, '_blank');

			return;
		}

		window.open(this.websiteUrl, '_blank');
	}
}
