import { Component, Input, OnInit } from '@angular/core';
import { ProjectService } from '@modules/project/services/project.service';
import { ITodo, Todo } from '@shared/models';
import MathUtils from '@shared/utils/math-utils';

@Component({
	selector: 'app-new-section-milestone-post',
	templateUrl: './new-section-milestone-post.component.html',
	styleUrls: ['./new-section-milestone-post.component.scss'],
})
export class NewSectionMilestonePostComponent implements OnInit {
	@Input() public post: any;
	public section: any;
	public tasks = [];

	public detailsExpanded = false;
	public displayPulseLoader = false;

	constructor(private projectService: ProjectService) {}

	ngOnInit(): void {
		this.section = this.setSectionProgressWidth(this.post?.section);
		this.section.ScrollDisabled = true;
	}

	/**
	 * Add progress bar width based on section counts
	 * @param section specified section
	 * @returns section object with progress percentage
	 */
	private setSectionProgressWidth(section: any): any {
		section.progressPercent = MathUtils.getPercentageOf(section?.closed, section?.total);
		return section;
	}

	public openSectionDetails(): void {
		if (this.tasks?.length === 0) {
			this.displayPulseLoader = true;
			this.projectService
				.getProjectSectionTodos(this.section?.uid, this.section?.total > 0 ? (this.section.offset ? this.section.offset : 0) : 0)
				.subscribe((res) => {
					this.section.nextPageUrl = res?.next;
					this.section.offset = res?.offset;
					this.tasks.push(...res.objects.map((task: ITodo) => this.alterTaskTitle(new Todo(task))));
				})
				.add(() => {
					this.displayPulseLoader = false;
				});
		}

		this.detailsExpanded = !this.detailsExpanded;
	}

	/**
	 * Altering the task title
	 * It converts the title user mention or hashtag to a link
	 *
	 * @param title
	 *
	 * @return Todo
	 */
	private alterTaskTitle(task: any): Todo | any {
		if (task.title && task.title.indexOf('<') > -1) {
			task.title = task.title.replace(/</g, '< ');
		}

		let alteredTitle = task.title;

		// altering the title with user mention
		task.mentionedUsers?.forEach((mentionedUser: any) => {
			if (mentionedUser.name) {
				alteredTitle = alteredTitle.replaceAll(`@${mentionedUser.name}`, '<span><a class="alter-title mention" #userMention>$&</a></span>');
			}
		});

		// altering the title with hashtag
		task.hashtags?.forEach((hashtag: any) => {
			if (hashtag.name) {
				alteredTitle = alteredTitle.replaceAll(`#${hashtag.name}`, '<span><a class="alter-title hashtag" #hashTag>$&</a></span>');
			}
		});

		const tagStartPosition = alteredTitle.indexOf('['),
			tagEndPosition = alteredTitle.indexOf(']');

		// altering the title with task tags
		if (tagStartPosition > -1 && tagEndPosition > -1) {
			const tagBody = alteredTitle.slice(tagStartPosition + 1, tagEndPosition);

			alteredTitle = alteredTitle.replaceAll(`[${tagBody}]`, `<span class="alter-title tag">${tagBody}</span>`);
		}

		task.altered_title = alteredTitle;

		return task;
	}

	/**
	 * Handle Pagination
	 */
	public loadMoreTasks(): void {
		if (this.section?.nextPageUrl) {
			if (this.section?.ScrollDisabled) {
				return;
			}

			this.section.ScrollDisabled = true;

			this.projectService.onTaskContainerScrollEvent.emit(true);

			this.projectService.getPaginationUrlData(this.section.nextPageUrl).subscribe((data) => {
				this.section.nextPageUrl = data.next ? data.next.split('alpha')[1] : null;

				this.tasks.push(...data.objects.map((todo: ITodo) => this.alterTaskTitle(todo)));
				this.section.ScrollDisabled = false;

				this.tasks.forEach((t: ITodo) => (t = this.alterTaskTitle(t)));
			});
		}
	}
}
