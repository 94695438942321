import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService, NetworkService, LayoutService } from '../../shared';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare let Stripe: any;

@Component({
	selector: 'app-purchase-page',
	templateUrl: './purchase-page.component.html',
	styleUrls: ['./purchase-page.component.css'],
})
export class PurchasePageComponent implements OnInit, AfterViewInit {
	public paymentForm: FormGroup;

	@ViewChild('cardNumber', {
		static: false,
	})
	public cardNumber: ElementRef;

	@ViewChild('cardCvc', {
		static: false,
	})
	// eslint-disable-next-line @typescript-eslint/indent
	public cardCvc: ElementRef;
	@ViewChild('cardExpiry', {
		static: false,
	})
	public cardExpiry: ElementRef;

	private stripe;
	public data = {
		name: '',
		email: '',
		phone: '',
		address: {
			city: '',
			state: '',
			country: '',
			postal_code: '',
			line1: '',
			line2: '',
		},
	};
	public seatsBefore: any;
	private display = false;
	public previousPaymentId;
	public isBillingPlanUpdated = false;
	public displayBillingDetails = true;
	public billingDetails: any;
	public card;
	public expiry;
	public cvc;
	public disableBillingPlan = false;
	public disablePlanType = false;
	public totalSeats: any;
	public seats: any;
	public subscriptionCancelDate: any = '';
	public processing = false;
	public displayEditBillingModal = 'none';
	public display_modal = 'none';
	public displayAlert = 'none';
	public displaySuccess = 'none';
	public displayStripeError = 'none';
	public message;
	public displayPaymentConfirmation = 'none';
	public displayUpdateAlert = 'none';
	public displayCancelMessage = 'none';
	public displayUpdatePaymentConfirmation = 'none';
	public stripeKey;
	public client_secret;
	public renewal_frequency: any;
	public currentPurchasePlan: any;
	public perSeatAmount: any;
	public network;
	public networkSubscriber;
	public currentNetSub;
	public subscription;
	public numberOfSeats: any;
	public updatedPlan = {};
	public previousPaymentDetails: any;
	public paymentDescription;
	public paymentDetails;
	public totalAmount: any;
	public additionalAmount: any;
	public additionalSeats: any = 0;
	public payableAmount: any;
	public previousCardDetails: any;
	public currentPlanObject = {
		network: '',
		currency: '',
		seats: '',
		renewal_frequency: '',
		subscription_template: '',
	};
	public showCountryModal = 'none';
	public field = {
		value: '',
	};
	public selectedField: any;
	public currentCountry;
	public code: any;
	public searchString;
	public isLoading = false;
	public displayError = false;
	public countries: any[] = [];
	public selectedCountry: any[] = [];
	public selectedCountryIndex = 0;
	private nextUrl;
	public nrSelect;
	public annualPlanSelected = false;
	public annualPlanMessage = 'Save 20% on annual plan';
	public purchasePlans = []; // For getting all purchase plans
	public selectedPlan: any;
	public billingPlans = ['Monthly', 'Yearly'];
	public currentBillingPlan: string;
	public annualSavings: any;
	public calculatedAmount: any;
	public amountPaid: any;
	public stripeCustomerId: any;
	public paymentMethodId: any;
	public planTitle = 'Your Current Plan';

	public nextBillingAmount: any;
	public updateSubscription = false;
	public isPlanUpdated = false;
	public toggleCard = false;
	public showCancelSubscription = false;
	public showBackBtn = false;
	public updatedBillingPlan: any;
	public isProcessing = false;
	public modalText;

	public showAdditionalAmount = false;
	public displayPurchaseError = 'none';
	public errorObj = {
		detail: this.translate.instant('Something went wrong'),
		status: '',
	};

	public default = this.translate.instant('Monthly');

	// coupon
	public coupon;
	public couponInvalid = false;
	public percent_off: any;
	public afterDiscount: any;
	public couponDialog = 'none';

	public showEnterCouponCode = false;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private http: HttpClient,
		private activatedRoute: ActivatedRoute,
		private translate: TranslateService,
		private userService: UserService,
		private router: Router,
		private _location: Location,
		public networkService: NetworkService,
		public layoutService: LayoutService,
		private fb: FormBuilder,
		private pageLoadingUiService: PageLoadingUiService
	) {
		// Form validation for name,email,address in payment form
		this.paymentForm = this.fb.group({
			name: ['', [Validators.required, Validators.minLength(2)]],
		});
	}

	/**
	 * 1. Get stripe key to load stripe elements
	 * 2. Check if route params contains update or not
	 * 3. Check if previous payment details exists
	 * 4. Else call api to get previous payment details and set it on form
	 * 5. If update doesnt exists within params, display cc fields
	 */
	ngOnInit(): void {
		this.activatedRoute.queryParams.subscribe((params) => {
			this.stripeKey = params.key; // 1
			this.stripeCustomerId = params.id;

			if (params.update) {
				// 2
				this.getBillingDetails();
				this.paymentMethodId = '';
				this.displayBillingDetails = false;
				this.updateSubscription = true;
				this.showCancelSubscription = localStorage.getItem('currentSubscriptionStatus') === 'trialing' ? false : true;

				if (this.networkService.previousPaymentDetails) {
					// 3
					this.previousPaymentDetails = this.networkService.previousPaymentDetails;
					this.previousCardDetails = this.previousPaymentDetails.paymentMethod.card;
					this.previousPaymentId = this.previousPaymentDetails.paymentMethod.id;
				} else if (!this.networkService.previousPaymentDetails) {
					// 4
					this.getUserActivePlanDetails();
				}

				this.display = true; // TODO: check if this is required
			} else {
				this.display = true; // 5
				this.showCancelSubscription = false;
			}
		});

		// Get current network details
		this.networkSubscriber = this.networkService.networkReady.subscribe((networkLoaded) => {
			if (networkLoaded) {
				this.currentNetSub = this.networkService.currentNetwork.subscribe((network) => {
					this.network = network;
				});
			}
		});

		if (localStorage.getItem('currentPurchasePlan')) {
			this.currentPurchasePlan = JSON.parse(localStorage.getItem('currentPurchasePlan'));
			console.log('currentPurchasePlan', JSON.parse(localStorage.getItem('currentPurchasePlan')));
			this.numberOfSeats = this.currentPurchasePlan.seats;
			this.renewal_frequency = this.currentPurchasePlan.renewal_frequency;

			if (this.currentPurchasePlan.renewal_frequency === 'yearly') {
				this.perSeatAmount = this.currentPurchasePlan.annualFee;
				const x = this.perSeatAmount * this.numberOfSeats;
				this.payableAmount = x * 12;
				this.calculatedAmount = this.payableAmount;
				this.default = this.translate.instant('Yearly');
				this.amountPaid = this.calculatedAmount;
			} else {
				this.perSeatAmount = this.currentPurchasePlan.fee;
				this.payableAmount = this.perSeatAmount * this.numberOfSeats;
				this.calculatedAmount = this.perSeatAmount * this.numberOfSeats;
				this.amountPaid = this.calculatedAmount;
			}

			this.changeDetectorRef.detectChanges();
		}

		// Get all purchase plans
		if (localStorage.getItem('purchasePlans')) {
			let arr = [];
			arr = JSON.parse(localStorage.getItem('purchasePlans'));

			arr.forEach((plan) => {
				if (plan.renewal_frequency !== 'null') {
					this.purchasePlans.push(plan);
				}
			});
		}

		this.setCurrentBillingPlan();
		this.calculateAnnualSavings();

		// create a stripe instance, we will use this to validate the form
		// continue with ngAfterViewInit
		this.stripe = Stripe(this.stripeKey, {
			locale: localStorage.getItem('language'),
		});

		// IF page is update payment plan
		// IF existing purchase plan is yearly, restrict user from downgrading it
		if (this.updateSubscription) {
			this.disableBillingPlan = this.currentPurchasePlan.renewal_frequency === 'yearly';
			// this.disablePlanType = this.currentPurchasePlan?.name === 'Pro';

			console.log(this.purchasePlans);
			const index = this.purchasePlans.findIndex((p) => p.name === this.currentPurchasePlan?.name);
			if (index >= 2) {
				this.disablePlanType = true;
				console.log('disable plan type:-', index, this.currentPurchasePlan.name);
			}

			this.disablePlanType = this.currentPurchasePlan?.name === 'Pro' || this.currentPurchasePlan?.name === 'Business';
		}
	}

	/**
	 * Load Stripe elements after view initialize and if display is true
	 *
	 * 1. Check if display is true
	 * 2. Set default font styles for stripe elements
	 * 3. Create card number element of stripe
	 * 4. Handle errors on card number field
	 * 5. Create card expiry element
	 * 6. Handle card expiry errors
	 * 7. Create card CVV
	 * 8. Handle card CVV errors
	 */
	ngAfterViewInit(): void {
		if (this.display) {
			// 1
			const elements = this.stripe.elements(),
				style = {
					// 2
					base: {
						color: '#495057',
						// eslint-disable-next-line spellcheck/spell-checker
						fontFamily: 'apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
					},
				};

			this.card = elements.create('cardNumber', {
				placeholder: this.translate.instant('Enter Card Number'),
				style: style,
				showIcon: true,
			});

			this.card.mount(this.cardNumber.nativeElement); // 3
			this.card.addEventListener('change', (event: any) => this.displayErrorMessage(event, 'card-errors'));

			const exp = elements.create('cardExpiry', {
				// 5
				placeholder: this.translate.instant('MM / YY'),
				style: style,
			});
			exp.mount(this.cardExpiry.nativeElement);
			exp.addEventListener('change', (event: any) => this.displayErrorMessage(event, 'expiry-errors'));

			const CVC = elements.create('cardCvc', {
				placeholder: 'CVV',
				style: style,
			});

			CVC.mount(this.cardCvc.nativeElement); // 7
			CVC.addEventListener('change', (event: any) => this.displayErrorMessage(event, 'cvc-errors'));
		}
	}

	/**
	 * Function to display cc fields on selecting 'add another card option'
	 *
	 * 1. Check if display is true
	 * 2. Set default font styles for stripe elements
	 * 3. Create card number element of stripe
	 * 4. Handle errors on card number field
	 * 5. Create card expiry element
	 * 6. Handle card expiry errors
	 * 7. Create card CVV
	 * 8. Handle card CVV errors
	 */
	private displayCreditCardFields(): void {
		this.display = !this.display;
		this.changeDetectorRef.detectChanges();

		if (this.display) {
			// 1
			const elements = this.stripe.elements();
			const style = {
				base: {
					color: '#495057',
					// eslint-disable-next-line spellcheck/spell-checker
					fontFamily: 'apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif', // 2
				},
			};

			this.card = elements.create('cardNumber', {
				placeholder: this.translate.instant('Enter Card Number'),
				style: style,
				showIcon: true,
			});

			this.card.mount(this.cardNumber.nativeElement); // 3
			this.card.addEventListener('change', (event: any) => this.displayErrorMessage(event, 'card-errors'));

			const exp = elements.create('cardExpiry', {
				placeholder: this.translate.instant('MM / YY'),
				style: style,
			});

			exp.mount(this.cardExpiry.nativeElement); // 5
			exp.addEventListener('change', (event: any) => this.displayErrorMessage(event, 'expiry-errors'));

			const CVC = elements.create('cardCvc', {
				placeholder: 'CVV',
				style: style,
			});

			CVC.mount(this.cardCvc.nativeElement); // 7
			CVC.addEventListener('change', (event: any) => this.displayErrorMessage(event, 'cvc-errors'));
		}
	}

	public createPaymentWithStripe(): void {
		console.log(this.paymentForm.value, this.paymentForm);
		this.paymentForm.markAllAsTouched();
		const form = this.paymentForm.value;
		this.pageLoadingUiService.setPageLoading(true);

		this.stripe
			.createPaymentMethod({
				type: 'card',
				card: this.card,
				billing_details: {
					name: form.name,
				},
			})
			.then((result) => {
				this.pageLoadingUiService.setPageLoading(false);

				if (result.error) {
					if (result['error']['code'] && result['error']['code'] === 'card_declined') {
						this.message = result['error']['message'];
						this.displayStripeError = 'block';
					}
				} else {
					this.paymentMethodId = result.paymentMethod.id;
					this.displayPaymentConfirmation = 'block';
				}
			});
	}

	public createSubscriptionPayment(): void {
		if (this.paymentMethodId) {
			this.pageLoadingUiService.setPageLoading(true);

			const object = {
				subscription_template: this.currentPurchasePlan.uid,
				network: this.network.uid,
				renewal_frequency: this.currentPurchasePlan.renewal_frequency,
				seats: this.currentPurchasePlan.seats,
				payment_id: this.paymentMethodId,
			};

			if (this.coupon && this.percent_off) {
				object['coupon'] = this.coupon;
			} else {
				delete object['coupon'];
			}

			// eslint-disable-next-line spellcheck/spell-checker
			if (!this.currentPurchasePlan['cancelledSubscription']) {
				this.networkService.cancelSubscriptionPlan(object).subscribe(
					(data) => {
						this.pageLoadingUiService.setPageLoading(false);
						this.networkService.getNetworkSubscriptionDetails();
						this.displayPaymentConfirmation = 'none';
						this.newRedirectToPaymentSuccess(data);
						this.redirect(data);
						this.redirectToPaymentSuccessFullPage(data);
					},
					() => {
						this.handleSubscriptionErr();
					}
				);
			} else {
				this.networkService.createSubscriptionPayment(object).subscribe(
					(data) => {
						this.pageLoadingUiService.setPageLoading(false);
						this.showEnterCouponCode = false;
						this.networkService.getNetworkSubscriptionDetails();
						this.displayPaymentConfirmation = 'none';
						if (localStorage.getItem('networkExpired')) {
							localStorage.removeItem('networkExpired');
							this.networkService.clearSubscriptions();
							this.networkService.getNetworkSubscriptionDetails();
						}
						this.newRedirectToPaymentSuccess(data);
						this.redirect(data);
						this.redirectToPaymentSuccessFullPage(data);
					},
					() => {
						this.handleSubscriptionErr();
					}
				);
			}
		}
	}

	private handleSubscriptionErr(): void {
		this.displayPurchaseError = 'block';

		if (localStorage.getItem('status')) {
			const x = JSON.parse(localStorage.getItem('status'));
			if (x['detail']) {
				this.errorObj['detail'] = x['detail'];
			} else {
				this.errorObj['detail'] = this.translate.instant('Something went wrong');
			}
			if (x['status']) {
				this.errorObj['status'] = x['status'];
			}
		}

		this.pageLoadingUiService.setPageLoading(false);
		this.displayPaymentConfirmation = 'none';
		this.displayUpdatePaymentConfirmation = 'none';
	}

	/**
	 * Function to display countries modal
	 * 1. Reset all variables
	 * 2. Call all countries
	 * 3. Set next url
	 * 4. Remove loader
	 */
	public displayCountryModal(): void {
		this.showCountryModal = 'block';
		this.field['value'] = ''; // 1
		this.isLoading = true;
		this.countries = [];
		this.searchString = '';
		this.displayError = false;

		this.userService.fetchCountries().subscribe((data) => {
			// 2
			this.countries = data['results'];
			this.selectedCountry = this.countries[0].uid;
			if (data.next) {
				// 2
				this.nextUrl = data.next.split('alpha')[1];
			} else {
				this.nextUrl = '';
			}
			this.isLoading = false; // 4
		});
	}

	/**
	 * Function to paginate remaining countries
	 * 1. Check if next url exists
	 * 2. Get countries
	 * 3. Set next url
	 * 4. Append data to array
	 */
	public paginateCountries(): void {
		if (this.nextUrl) {
			// 1
			this.userService.paginate(this.nextUrl).subscribe((data) => {
				// 2
				if (data.next) {
					this.nextUrl = data.next.split('alpha')[1]; // 3
				} else {
					this.nextUrl = '';
				}

				this.countries = this.countries.concat(data.results); // 4
			});
		}
	}

	/**
	 * Function to search countries in countries list
	 * 1. Set countries null and show loader
	 * 2. Call search api
	 * 3. Show search results
	 * 4. Display error message if results are empty
	 */
	public onSearchClick(): void {
		this.isLoading = true; // 1
		this.displayError = false;
		this.countries = [];

		this.userService.searchCountries(this.searchString).subscribe((data) => {
			// 2
			if (data.results.length > 0) {
				this.isLoading = false;
				this.countries = data.results; // 3
			} else {
				this.displayError = true; // 4
				this.isLoading = false;
			}
		});
	}

	public onSearchEnter(event: any): void {
		if (event.keyCode === 13) {
			this.onSearchClick();
		}
	}

	/**
	 * Function on selecting a country from modal
	 * @param country
	 * 1. Set field for radio button on/off
	 * 2. Set selected country in a variable
	 */
	public onSelectCountry(country): void {
		this.field.value = country.uid; // 1
		this.selectedField = country; // 2
	}

	/**
	 * Function which sets selected country and its code on sign in form
	 * 1. Close country modal
	 * 2. Set selected country name and code
	 */
	public executeChanges(): void {
		this.showCountryModal = 'none'; // 1

		if (this.selectedField) {
			this.currentCountry = this.selectedField.name; // 2
			this.paymentForm.controls.country.setValue(this.selectedField.name);
			this.code = this.selectedField['code']; // 2
		}
	}

	/** Date : 17-08-20
	 * Function which shows change plan modal
	 */
	public showChangePlanModal(): void {
		this.display_modal = 'block';

		if (this.seatsBefore) {
			this.seats = this.seatsBefore;
		} else {
			this.seats = this.currentPurchasePlan.seats;
		}

		const index = this.purchasePlans.findIndex((plan) => plan.name === this.currentPurchasePlan.name);

		if (index > -1) {
			this.selectedPlan = this.purchasePlans[index];
		}

		this.setCurrentBillingPlan();
	}

	/**
	 * Function to set current billing plan (monthly/annually)
	 * 1. Check if renewal frequency of current purchase plan is yearly
	 * 2. Set billing plan as yearly
	 * 3. Set annual plan message
	 * 4. Else set billing plan as monthly and show its message
	 */
	private setCurrentBillingPlan(): void {
		if (!this.isPlanUpdated) {
			if (this.currentPurchasePlan.renewal_frequency === 'yearly') {
				// 1
				this.annualPlanSelected = true;
				this.currentBillingPlan = 'yearly'; // 2
				this.annualPlanMessage = this.translate.instant("You're saving 20% with an annual plan"); // 3
			} else {
				this.annualPlanSelected = false; // 4
				this.currentBillingPlan = 'monthly';
				this.annualPlanMessage = this.translate.instant('Save 20% with annual plan');
			}
		} else {
		}
	}

	/**
	 * Function for calculating annual savings
	 * 1. Get monthly plan amount for an year
	 * 2. Calculate annual plan per seat amount
	 * 3. Get total annual plan amount for year
	 * 4. Calculate annual savings
	 */
	private calculateAnnualSavings(): void {
		const plan = this.purchasePlans.find((plans) => plans.name === this.currentPurchasePlan.name);
		const x = plan.fee * this.numberOfSeats;
		const monthlyPlanForYr = x * 12; // 1
		const monthlyFee = plan.fee;
		const discount = monthlyFee * 0.2;
		const finalAmount = monthlyFee - discount; // 2
		const y = finalAmount * this.numberOfSeats;
		const annualPlanForYr = y * 12; // 3

		this.annualSavings = monthlyPlanForYr - annualPlanForYr; // 4
	}

	/**
	 * Function for switching between monthly/annual plans
	 * @param event
	 * 1. Get per seat amount of current plan
	 * 2. Calculate 20% discount
	 * 3. Calculate final amount for per seat for annual plan
	 * 4. Get seats of current plan
	 * 5. Calculate annual plan amount per month
	 * 6. Calculate annual plan amount for year
	 * 7. Display message and set renewal frequency
	 */
	private setPlan(event): void {
		if (event.target.value === 'Yearly' && !this.annualPlanSelected) {
			this.annualPlanSelected = true;
			// const a = this.perSeatAmount; // 1
			// const d = a * 0.2; // 2
			const plan = this.purchasePlans.find((plans) => plans.name === this.currentPurchasePlan.name);
			this.perSeatAmount = plan?.annualFee; // 3

			this.seats = this.currentPurchasePlan.seats; // 4
			const annualPlanAmountPerMonth = this.seats * this.perSeatAmount; // 5
			this.calculatedAmount = annualPlanAmountPerMonth * 12; // 6
			this.annualPlanMessage = this.translate.instant("You're saving 20% with an annual plan"); // 7
			this.payableAmount = this.calculatedAmount;
			this.currentPurchasePlan.renewal_frequency = 'yearly';
			this.currentPurchasePlan.fee = this.perSeatAmount;
		}
		if (event.target.value === 'Monthly') {
			this.currentPurchasePlan.renewal_frequency = 'monthly';
			this.annualPlanSelected = false;
			this.seats = this.currentPurchasePlan.seats;
			const plan = this.purchasePlans.find((plans) => plans.name === this.currentPurchasePlan.name);
			this.perSeatAmount = plan.fee;
			this.currentPurchasePlan.fee = plan.fee;
			this.payableAmount = this.seats * this.perSeatAmount;
			this.calculatedAmount = this.perSeatAmount * this.seats;
			this.annualPlanMessage = this.translate.instant('Save 20% with annual plan');
		}
	}

	public onChangeBillingPlans(e): void {
		if ((e.target.value === 'Yearly' || e.target.value === 'Yıllık') && !this.annualPlanSelected) {
			this.annualPlanSelected = true;
			this.currentPurchasePlan.renewal_frequency = 'yearly';

			// For plan update
			if (this.updateSubscription) {
				this.isBillingPlanUpdated = true;
				this.showAdditionalAmount = true;

				const seatsBefore = this.seats;
				const seatsAdded = this.additionalSeats;
				const totalSeats = Number(seatsBefore) + Number(seatsAdded);

				// const a = this.selectedPlan.fee;
				// const d = a * 0.2;
				this.perSeatAmount = this.selectedPlan?.annualFee;
				// this.perSeatAmount = a - d;
				const annualPlanAmountPerMonth = totalSeats * this.perSeatAmount;
				this.calculatedAmount = annualPlanAmountPerMonth * 12;
				this.renewal_frequency = 'Yearly';
				this.annualPlanMessage = this.translate.instant("You're saving 20% with an annual plan");

				this.additionalAmount = this.calculatedAmount - this.amountPaid;
			} else {
				this.resetFields();
				// const a = this.selectedPlan.fee;
				// const d = a * 0.2;
				this.perSeatAmount = this.selectedPlan?.annualFee;
				// this.perSeatAmount = a - d;
				const annualPlanAmountPerMonth = this.seats * this.perSeatAmount;
				this.calculatedAmount = annualPlanAmountPerMonth * 12;
				this.renewal_frequency = 'Yearly';
				this.annualPlanMessage = this.translate.instant("You're saving 20% with an annual plan");
			}
		}

		// eslint-disable-next-line spellcheck/spell-checker
		if (e.target.value === 'Monthly' || e.target.value === 'Aylık') {
			this.annualPlanSelected = false;
			this.currentPurchasePlan.renewal_frequency = 'monthly';

			// For plan update
			if (this.updateSubscription) {
				this.isBillingPlanUpdated = true;
				this.showAdditionalAmount = true;
				const seatsBefore = this.seats;
				const seatsAdded = this.additionalSeats;
				const totalSeats = Number(seatsBefore) + Number(seatsAdded);
				this.perSeatAmount = this.selectedPlan.fee;
				this.calculatedAmount = this.perSeatAmount * totalSeats;
				this.renewal_frequency = 'Monthly';
				this.additionalAmount = this.calculatedAmount - this.amountPaid;
			} else {
				this.resetFields();
				this.perSeatAmount = this.selectedPlan.fee;
				this.calculatedAmount = this.perSeatAmount * this.seats;
				this.renewal_frequency = 'Monthly';
				this.annualPlanMessage = this.translate.instant('Save 20% with annual plan');
			}
		}
	}

	private resetFields(): void {
		this.isBillingPlanUpdated = false;
		this.showAdditionalAmount = false;
		this.additionalAmount = '';
	}

	/**
	 * Function to calculate amount with respect to number of seats
	 * @param e
	 * 1. Get number of seats selected
	 * 2. Set this number on current purchase Plan object
	 * 3. Check if annual plan is selected
	 * 4. Calculate annual payable amount with no.of seats
	 * 5. Calculate monthly payable amount for monthly plan
	 */
	public calculateAmountForSeats(e): void {
		const seats = e.target.value; // 1
		this.currentPurchasePlan.seats = seats; // 2

		if (this.annualPlanSelected) {
			// 3
			const monthlyAmount = seats * this.perSeatAmount;
			this.calculatedAmount = monthlyAmount * 12; // 4
		} else {
			this.calculatedAmount = this.perSeatAmount * seats; // 5
		}
	}

	public changePurchasePlan(): void {
		if (this.selectedPlan) {
			this.selectedPlan['seats'] = this.currentPurchasePlan.seats;

			if (this.default === 'Yearly' || this.default === 'Yıllık') {
				// const a = this.selectedPlan.fee;
				// const d = a * 0.2;
				// const perSeatAmount = a - d;
				const perSeatAmount = this.selectedPlan?.annualFee;
				const annualPlanAmountPerMonth = this.seats * perSeatAmount;
				this.perSeatAmount = perSeatAmount;
				this.calculatedAmount = annualPlanAmountPerMonth * 12;
			} else {
				this.perSeatAmount = this.selectedPlan.fee;
				this.calculatedAmount = this.seats * this.perSeatAmount;
			}

			this.currentPurchasePlan = this.selectedPlan;
		}
	}

	public updatePaymentPlan(): void {
		this.display_modal = 'none';
		this.payableAmount = this.calculatedAmount;
		this.numberOfSeats = this.seats;

		localStorage.setItem('currentPurchasePlan', JSON.stringify(this.currentPurchasePlan));

		this.calculateAnnualSavings();
	}

	private displayConfirm(): void {
		this.displayPaymentConfirmation = 'block';
	}

	public confirmSubscriptionCancel(): void {
		this.isProcessing = true;
		const data = {
			action: 'cancel',
			network: this.network.uid,
		};

		this.networkService.cancelSubscriptionPlan(data).subscribe(
			(res) => {
				this.networkService.getNetworkSubscriptionDetails();
				const date = res.nextBill;
				const d = new Date(date);
				let selectedDay: any;
				let selectedMonth: any;
				const month = d.getMonth() + 1;
				const day = d.getDate();
				const year = d.getFullYear();

				if (day < 10) {
					selectedDay = '0' + day;
				} else {
					selectedDay = day;
				}

				if (month < 10) {
					selectedMonth = '0' + month;
				} else {
					selectedMonth = month;
				}

				this.subscriptionCancelDate = selectedDay + '-' + selectedMonth + '-' + year;
				this.isProcessing = false;
				this.showBackBtn = true;
			},
			() => {
				this.isProcessing = false;
				this.displayCancelMessage = 'none';
				this.displayPurchaseError = 'block';

				if (localStorage.getItem('status')) {
					const x = JSON.parse(localStorage.getItem('status'));
					if (x['detail']) {
						this.errorObj['detail'] = x['detail'];
					} else {
						this.errorObj['detail'] = this.translate.instant('Something went wrong');
					}
					if (x['status']) {
						this.errorObj['status'] = x['status'];
					}
				}
			}
		);
	}

	/**
	 * Function to get current plan details for update
	 */
	private getUserActivePlanDetails(): void {
		this.networkService.getActivePurchasePlan().subscribe(
			(data) => {
				// 1
				if (data['paymentMethod'] && data['paymentMethod']['card']) {
					this.networkService.previousPaymentDetails = data;
					this.previousPaymentDetails = data;
					this.previousCardDetails = this.previousPaymentDetails.paymentMethod.card;
					this.previousPaymentId = this.previousPaymentDetails.paymentMethod['id'];
				} else {
					this.previousCardDetails = '';
					this.previousPaymentId = '';
					this.previousPaymentDetails = '';
				}
			},
			() => {}
		);
	}

	public calculateAmountForAdditionalSeats(e): void {
		this.showAdditionalAmount = true;
		const seats = e.target.value;

		if (this.annualPlanSelected) {
			const seatsBefore = this.seats,
				seatsAdded = seats,
				totalSeats = Number(seatsBefore) + Number(seatsAdded),
				monthlyAmount = totalSeats * this.perSeatAmount,
				annualTotalAmt = monthlyAmount * 12;

			this.calculatedAmount = annualTotalAmt;

			this.additionalAmount = this.calculatedAmount - this.amountPaid;
		} else {
			this.additionalAmount = this.perSeatAmount * seats;

			const seatsBefore = this.seats,
				seatsAdded = seats,
				totalSeats = Number(seatsBefore) + Number(seatsAdded);

			this.calculatedAmount = totalSeats * this.perSeatAmount;
		}

		this.calculateDiscount();
	}

	public changeExistingPlan(): void {
		if (this.additionalSeats || this.currentBillingPlan !== this.currentPurchasePlan.renewal_frequency) {
			this.planTitle = this.translate.instant('Your Updated Plan');
			this.isPlanUpdated = true;

			if (this.additionalSeats) {
				this.seatsBefore = this.seats;
				const totalSeats = this.seats + this.additionalSeats;
				this.currentPurchasePlan.seats = totalSeats;
				this.totalSeats = totalSeats;
				this.updatedBillingPlan = this.currentPurchasePlan.renewal_frequency;
			} else {
				this.totalSeats = this.currentPurchasePlan.seats;
			}

			if (this.currentBillingPlan !== this.currentPurchasePlan.renewal_frequency) {
				this.updatedBillingPlan = this.currentPurchasePlan.renewal_frequency;
			} else {
				this.updatedBillingPlan = this.currentPurchasePlan.renewal_frequency;
				this.isBillingPlanUpdated = false;
			}

			this.nextBillingAmount = this.payableAmount + this.additionalAmount;
		} else {
			this.planTitle = 'Your Current Plan';
			this.isPlanUpdated = false;
			this.updatedBillingPlan = '';
			this.nextBillingAmount = '';
		}

		this.display_modal = 'none';
	}

	/** Date : 17-08-20
	 * Function to close change plan details modal
	 */
	public closeModal(): void {
		this.display_modal = 'none';

		if (!this.updateSubscription) {
			this.default = this.translate.instant('Monthly');
			this.selectedPlan = '';
			this.currentPurchasePlan = JSON.parse(localStorage.getItem('currentPurchasePlan'));
			this.perSeatAmount = this.currentPurchasePlan.fee;
			this.calculatedAmount = this.payableAmount;
		}
	}

	private updateBillingDetails(): void {
		this.paymentForm.markAllAsTouched();
		const form = this.paymentForm.value;
		const data = {
			name: '',
			email: '',
			phone: '',
			address: {},
		};
		const address = {
			city: '',
			country: '',
			line1: '',
			line2: '',
			postal_code: '',
			state: '',
		};

		if (this.paymentForm.valid) {
			this.pageLoadingUiService.setPageLoading(true);
			data['name'] = form.name;
			data['email'] = form.email;
			data['phone'] = form.phone;

			address.city = form.city;
			address.state = form.state;
			address.postal_code = form.postal;
			address.country = this.code;
			// eslint-disable-next-line spellcheck/spell-checker
			address.line1 = form.addressline1;
			// eslint-disable-next-line spellcheck/spell-checker
			address.line2 = form.addressline2;

			data.address = address;

			const url = `${environment.api_url}/payments/customer/`;
			this.http
				.post(url, data, {
					headers: this.setHeaders(),
				})
				.subscribe((res) => {
					this.pageLoadingUiService.setPageLoading(false);
					if (this.updateSubscription) {
						this.billingDetails = res;
						this.populateUserBillingDetails();
						this.displaySuccess = 'block';
						this.displayEditBillingModal = 'none';
					}
				});
		}
	}

	private getBillingDetails(): void {
		this.networkService.getBillingDetails().subscribe((data) => {
			this.billingDetails = data;
			this.populateUserBillingDetails();
		});
	}

	private populateUserBillingDetails(): void {
		if (this.billingDetails) {
			if (this.billingDetails.name) {
				this.paymentForm.controls.name.setValue(this.billingDetails.name);
			}
		}
	}

	public updatePurchaseSubscription(): void {
		if (this.display) {
			this.pageLoadingUiService.setPageLoading(true);
			this.stripe
				.createPaymentMethod({
					type: 'card',
					card: this.card,
				})
				.then((result) => {
					if (result.error) {
						this.pageLoadingUiService.setPageLoading(false);
					} else {
						this.pageLoadingUiService.setPageLoading(false);
						this.paymentMethodId = result.paymentMethod.id;
						this.displayUpdatePaymentConfirmation = 'block';
					}
				});
		} else {
			this.pageLoadingUiService.setPageLoading(false);
			this.displayUpdatePaymentConfirmation = 'block';
		}
	}

	/**
	 * Function for  subscription update.
	 */
	public callUpdateSubscriptionApi(): void {
		this.pageLoadingUiService.setPageLoading(true);
		this.updatedPlan['network'] = this.network.uid;
		this.updatedPlan['subscription_template'] = this.currentPurchasePlan.uid;

		if (this.additionalSeats) {
			this.updatedPlan['seats'] = this.totalSeats;
		}

		if (this.updatedBillingPlan && this.isBillingPlanUpdated) {
			this.updatedPlan['renewal_frequency'] = this.updatedBillingPlan.toLowerCase();
		} else {
			this.updatedPlan['renewal_frequency'] = this.currentPurchasePlan['renewal_frequency'];
		}

		if (this.paymentMethodId) {
			this.updatedPlan['payment_id'] = this.paymentMethodId;
		}

		if (this.coupon && this.percent_off) {
			this.updatedPlan['coupon'] = this.coupon;
		}

		this.networkService.cancelSubscriptionPlan(this.updatedPlan).subscribe(
			(data) => {
				this.pageLoadingUiService.setPageLoading(false);
				this.networkService.getNetworkSubscriptionDetails();
				this.displayUpdatePaymentConfirmation = 'none';
				this.showEnterCouponCode = false;
				this.redirect(data);
				this.redirectToPaymentSuccessFullPage(data);
			},
			() => {
				this.handleSubscriptionErr();
			}
		);
	}

	/**
	 * This will check if status key is either active or trialing
	 * If true,then payment is successful,redirect users to success page
	 * Else show alert.
	 * @param data
	 */
	private redirect(data: any): void {
		if (data['subscriptionStripeData'] && data['subscriptionStripeData']['status']) {
			if (data['subscriptionStripeData']['status'] === 'active' || data['subscriptionStripeData']['status'] === 'trialing') {
				this.router.navigateByUrl('/network/purchase-status?id=' + data.transactionId);
			} else {
				this.message = 'Payment unsuccessful';
				this.displayStripeError = 'block';
			}
		}
	}

	private redirectToPaymentSuccessFullPage(data: any): void {
		if (data['subscription_stripe_data'] && data['subscription_stripe_data']['status']) {
			if (data['subscription_stripe_data']['status'] === 'active' || data['subscription_stripe_data']['status'] === 'trialing') {
				this.router.navigateByUrl('/network/purchase-status?id=' + data.transaction_id);
			} else {
				this.message = 'Payment unsuccessful';
				this.displayStripeError = 'block';
			}
		}
	}

	/**
	 * Handle redirection to success page after successful payment / error modal dialog on failure
	 * @param data Response Data from payment request
	 */
	private newRedirectToPaymentSuccess(data: any): void {
		console.log('Status:-', data?.status);
		if (data && data['status']) {
			switch (data?.status) {
				case 'active':
				case 'trailing':
					this.router.navigate([`/network/purchase-status`], { queryParams: { id: data.transactionId ?? data?.transaction_id } });
					break;
				default:
					this.message = 'Payment unsuccessful';
					this.displayStripeError = 'block';
					break;
			}
		}
	}

	public applyCoupon(): void {
		if (this.coupon) {
			this.pageLoadingUiService.setPageLoading(true);

			this.networkService.validateCoupon(this.coupon.trim()).subscribe(
				(data) => {
					this.percent_off = data['percent_off'];
					this.couponInvalid = false;
					this.couponDialog = 'none';
					this.calculateDiscount();
					this.pageLoadingUiService.setPageLoading(false);
				},
				() => {
					this.percent_off = '';
					this.couponInvalid = true;
					this.pageLoadingUiService.setPageLoading(false);
				}
			);
		} else {
			this.closeCoupon();
		}
	}

	private calculateDiscount(): void {
		if (!this.updateSubscription) {
			const a = this.percent_off / 100;
			const b = this.payableAmount * a;
			this.afterDiscount = this.payableAmount - b;
		} else {
			const a = this.percent_off / 100;
			const b = this.additionalAmount * a;
			this.afterDiscount = this.additionalAmount - b;
		}
	}

	public keyDown(): void {
		this.couponInvalid = false;
	}

	public closeCoupon(): void {
		this.couponDialog = 'none';
		this.couponInvalid = false;
		this.coupon = '';
		this.percent_off = '';
	}

	public closeDialog(): void {
		this.displayPaymentConfirmation = 'none';
		this.displayUpdatePaymentConfirmation = 'none';
		this.couponInvalid = false;
		this.coupon = '';
		this.percent_off = '';
	}

	public onClickBack(): void {
		this._location.back();
	}

	public close(): void {
		localStorage.removeItem('status');
		this.displayPurchaseError = 'none';
		this.errorObj = {
			detail: this.translate.instant('Something went wrong'),
			status: '',
		};
	}

	private setHeaders(): HttpHeaders {
		const headersConfig = {
			'Content-Type': 'application/json',
		};

		if (localStorage.getItem('token')) {
			headersConfig['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
		}

		return new HttpHeaders(headersConfig);
	}

	// #region Helpers

	private displayErrorMessage(event: any, elementId: string): void {
		const element: HTMLElement = document.getElementById(elementId);

		if (element) {
			element.textContent = event.error ? this.translate.instant(event.error.message) : '';
		}
	}

	// #endregion
}
