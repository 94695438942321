import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IWorkspacePaginatedResponse } from '@shared/models/workspace/workspace';
import { ApiService } from '@shared/services';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class WorkspaceCreationService {
	// #region BehaviorSubject
	private currentWorkspaceSubject = new BehaviorSubject<any>(null);
	private currentWorkspaceLogoSubject = new BehaviorSubject<FormData>(new FormData());
	private imageSrcSubject = new BehaviorSubject<string>('');
	private currentTeamSubject = new BehaviorSubject<any>({});
	// #endregion

	// #region Observable
	public currentWorkspace = this.currentWorkspaceSubject.asObservable().pipe(distinctUntilChanged());
	public currentWorkspaceLogo = this.currentWorkspaceLogoSubject.asObservable().pipe(distinctUntilChanged());
	public imageSrc = this.imageSrcSubject.asObservable().pipe(distinctUntilChanged());
	public currentTeam = this.currentTeamSubject.asObservable().pipe(distinctUntilChanged());
	// #endregion

	public createWorkspaceForm: FormGroup;

	constructor(private apiService: ApiService, private formBuilder: FormBuilder) {
		this.createWorkspaceForm = this.formBuilder.group({
			name: ['', Validators.required],
			subType: ['', Validators.required],
			type: ['enterprise'],
			verify: [true],
		});
	}


	// get workspace current value
	public getCurrentWorkspace(): any{
		return this.currentWorkspaceSubject.getValue();
	}

	// #region SETTERS ------------------------------------------------------------------------------------------------

	/**
	 *Set current Workspace subject value
	 * @param Workspace current Workspace
	 */
	public setCurrentWorkspace(workspace: any): void {
		this.currentWorkspaceSubject.next(workspace);
	}

	/**
	 * Set current Workspace Logo subject value
	 * @param Workspace logo images
	 */
	public setCurrentWorkspaceLogo(images: FormData): void {
		this.currentWorkspaceLogoSubject.next(images);
	}

	/**
	 * Set image-src subject value
	 * @param images src
	 */
	public setImageSrc(images: string): void {
		this.imageSrcSubject.next(images);
	}

	/**
	 * Set newly created team value
	 * @param team New Team
	 */
	public setTeam(team: any): void {
		this.currentTeamSubject.next(team);
	}

	/**
	 * Reset workspace creation subjects
	 */
	public resetWorkspaceCreation(): void {
		this.currentWorkspaceSubject.next(null);
		this.currentWorkspaceLogoSubject.next(new FormData());
		this.imageSrcSubject.next('');
		this.currentTeamSubject.next({});
	}

	// #endregion --------------------------------------------------------------------------------------------------------

	/**
	 * Get workspace details of a particular workspace
	 * @param uid workspace uid
	 * @returns workspace details
	 */
	public getWorkspace(uid: string): Observable<any> {
		return this.apiService.get(`/networks/${uid}/`);
	}

	/**
	 * Searches for workspace of the specified workspace name
	 * @param query Workspace name
	 * @param type Workspace type
	 * @returns list of workspaces as per search query
	 */
	public searchWorkspaces(query: string, type: string): Observable<any> {
		return this.apiService.get(`/networks/search/?query=${query}&type=${type}`);
	}

	/**
	 * To create a new workspace
	 * @param credentials Workspace credentials
	 * @returns Newly created workspace
	 */
	public createNewWorkspace(credentials: any): Observable<any> {
		return this.apiService.post(`/networks/`, credentials);
	}

	/**
	 * Handle upload of image for workspace logo
	 * @param files Image file in formData form
	 * @returns
	 */
	public uploadImage(file: FormData): Observable<any> {
		return this.apiService.upload('/uploads/', file);
	}

	/**
	 * Update the workspace logo
	 * @param value logo value
	 * @param workspace current workspace
	 * @returns
	 */
	public updateWorkspaceLogo(value: string, workspace: any): Observable<any> {
		const params = {};
		params['logo'] = value;

		if (workspace?.privacySettings?.customer) {
			params['privacy_settings'] = `{"customer":{"department":${JSON.stringify(workspace?.privacySettings?.customer?.department)}}}`;
		}

		if (workspace?.pointsTablePrivacy) {
			params['pointsTablePrivacy'] = workspace?.pointsTablePrivacy;
		}

		return this.apiService.put(`/networks/${workspace?.uid}/`, params);
	}

	/**
	 * Get user's membership list (workspaces)
	 * @param username Current user's username
	 * @returns Observable<IWorkspacePaginatedResponse>
	 */
	public getMemberships(username: string): Observable<IWorkspacePaginatedResponse> {
		return this.apiService.get(`/users/${username}/memberships/`);
	}

	/**
	 * Get role fields for update
	 * @param slug selected role's slug
	 * @param workspaceID Current workspace's uid
	 * @returns
	 */
	public getRoleFields(slug: string, workspaceID: string): Observable<any> {
		return this.apiService.get(`/roles/${slug}/?network=${workspaceID}`);
	}

	/**
	 * Save the role details
	 * @param currentUserUid Current user's uid
	 * @param data form data
	 * @returns
	 */
	public postRoleDetails(currentUserUid: string, data: any): Observable<any> {
		return this.apiService.post(`/users/${localStorage.getItem('uid') ?? currentUserUid}/memberships/`, data);
	}

	/**
	 * Update the role details
	 * @param currentUserUid Current user's uid
	 * @param membershipId user's membership id in the specified workspace
	 * @param body form data
	 * @returns
	 */
	public updateRoleDetails(currentUserUid: string, membershipId: string, body: any): Observable<any> {
		return this.apiService.put(`/users/${localStorage.getItem('uid') ?? currentUserUid}/memberships/${membershipId}/`, body);
	}

	/**
	 * Get the list of departments for the specified workspace
	 * @param workspaceID Current workspace uid
	 * @param offset pagination offset
	 * @param limit pagination limit
	 * @returns
	 */
	public getDepartments(workspaceID: string, offset: number = 0, limit: number = 50): Observable<any> {
		return this.apiService.get(`/networks/${workspaceID}/departments/?offset=${offset}&limit=${limit}`);
	}

	/**
	 * Get the list of cities for the specified coordinates
	 * @param coordinates current coordinates (latitude,longitude)
	 * @returns
	 */
	public getCities(searchParam: {searchKey?: string; location?: GeolocationCoordinates}): Observable<any> {
		const path = '/geo/cities/';
		console.log(searchParam);
		let params = new HttpParams();

		if(searchParam?.location?.latitude && searchParam?.location?.longitude){
			params = params.set('near',searchParam?.location?.latitude  + ',' + searchParam?.location?.longitude);
		}

		if(searchParam?.searchKey){
			params = params.set('query', searchParam.searchKey);
		}

		console.log(params);
		// return this.apiService.get(`/geo/cities/${coordinates ? `?near=${coordinates?.latitude},${coordinates?.longitude}` : ''}`);

		return this.apiService.get(path,params);
	}

	/**
	 * Get members of the current workspace
	 * @param workspaceID Current workspace uid
	 * @returns
	 */
	public getMembers(workspaceID: string): Observable<any> {
		return this.apiService.get(`/networks/${workspaceID}/members/?filter=active`);
	}

	/**
	 * Create Department from Join workspace Flow
	 * @param body Department Name Object
	 * @param workspaceID Current workspace uid
	 * @returns Created department
	 */
	public createDepartment(body: any, workspaceID: string): Observable<any> {
		return this.apiService.post(`/networks/${workspaceID}/departments/`, body);
	}

	/**
	 * Handle pagination
	 * @param url paginate url (next url)
	 * @returns
	 */
	public paginate(url: string): Observable<any> {
		return this.apiService.get(url);
	}

	/**
	 * Get membership details from a workspace
	 * @param membershipUid Member's membership ID
	 * @param workspaceID Current Workspace ID
	 * @returns
	 */
	public getMembershipDetails(membershipUid: string, workspaceID: string = ''): Observable<any> {
		let httpParams = new HttpParams();

		if (workspaceID) {
			localStorage.setItem('network_id', workspaceID);
			httpParams = httpParams.set('__includeAppId', 'true');
		} else if (localStorage.getItem('network_id')) {
			httpParams = httpParams.set('__includeAppId', 'true');
		}

		return this.apiService.get(`/users/${localStorage.getItem('uid')}/memberships/${membershipUid}/`, httpParams);
	}

	/**
	 * Get workspace's subscription details
	 * @param workspaceID Current Workspace ID
	 * @returns
	 */
	public getWorkspaceActiveSubscription(workspaceID: string): Observable<any> {
		return this.apiService.get(`/payments/transactions/active/?network=${workspaceID}`);
	}

	/**
	 * Sent invitation emails to team-mates
	 * @param workspaceID Current workspace ID
	 * @param body email addresses
	 * @returns
	 */
	public inviteTeammatesToWorkspace(workspaceID: string, body: any): Observable<any> {
		return this.apiService.post(`/networks/${workspaceID}/invite/`, body);
	}
}
