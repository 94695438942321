import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { GalleryModule, GALLERY_CONFIG } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import { LightboxService } from './services/ui/lightbox.service';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { WorkspaceListItemComponent } from './components/workspace-list-item/workspace-list-item.component';
import { WorkspaceSwitchBarComponent } from './components/workspace-switch-bar/workspace-switch-bar.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ThreeDotsLoaderComponent } from './components/loaders/three-dots-loader/three-dots-loader.component';
import { PulseLoaderComponent } from './components/loaders/pulse-loader/pulse-loader.component';
import { OvalLoaderComponent } from './components/loaders/oval-loader/oval-loader.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BeeLabelModule } from 'projects/bee-ui/src/lib/modules/label/label.module';
import { UserListModalComponent } from './components/modals/user-list-modal/user-list-modal.component';
import { MemberListItemComponent } from './components/modals/user-list-modal/member-list-item/member-list-item.component';
import { BeeAvatarModule } from 'projects/bee-ui/src/lib/modules/avatar/avatar.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InviteTeammatesComponent } from './components/invite-teammates/invite-teammates.component';

// ant design modules
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@NgModule({
	declarations: [
		SidebarComponent,
		WorkspaceListItemComponent,
		WorkspaceSwitchBarComponent,
		ThreeDotsLoaderComponent,
		PulseLoaderComponent,
		OvalLoaderComponent,
		UserListModalComponent,
		MemberListItemComponent,
		InviteTeammatesComponent,
	],
	imports: [
		CommonModule,
		GalleryModule,
		RouterModule,
		TranslateModule,
		OverlayModule,
		NgbModule,
		DragDropModule,
		BeeLabelModule,
		BeeAvatarModule,
		InfiniteScrollModule,
		NzModalModule,
		ReactiveFormsModule,
		FormsModule,
		OverlayModule,
		NzToolTipModule
	],
	exports: [
		SidebarComponent,
		WorkspaceListItemComponent,
		WorkspaceSwitchBarComponent,
		ThreeDotsLoaderComponent,
		PulseLoaderComponent,
		OvalLoaderComponent,
		UserListModalComponent,
		MemberListItemComponent,
		InviteTeammatesComponent,
	],
	providers: [
		{
			provide: GALLERY_CONFIG,
			useValue: {
				dots: false,
				imageSize: 'cover',
			},
		},
		Lightbox,
		LightboxService,
	],
})
export class PeerCoreModule {}
