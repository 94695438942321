import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ChannelService {
	constructor(private apiService: ApiService) {}
	public adminChannels: any[] = [];
	public myChannels: any[] = [];
	public otherChannels: any[] = [];
	public nextUrl = '';
	public scrollPosition: any;

	/**
	 * Function to delete sub network.
	 */
	public deleteChannel(uid): Observable<any> {
		const url = `/channels/${uid}/`;
		this.resetChannels();
		return this.apiService.delete(url);
	}

	/**
	 * Function to clear the stored channel data.
	 */
	public resetChannels(): void {
		this.adminChannels = [];
		this.myChannels = [];
		this.otherChannels = [];
		this.nextUrl = '';
		this.scrollPosition = 0;
	}

	/**
	 * Update channel
	 */
	public updateChannelDetails(channelId, data): Observable<any> {
		const url = `/channels/${channelId}/`;
		this.resetChannels();
		return this.apiService.put(url, data);
	}

	public checkAccessibility(data): Observable<any> {
		const params = new HttpParams({
				fromObject: {
					format: 'json',
				},
			}),
			url = '/channels/' + data.channelId + '/?username=' + localStorage.getItem('uid');

		return this.apiService.get(url, params);
	}

	public getPendingChannelRequests(channelId): Observable<any> {
		const url = '/channels/' + channelId + '/members/?state=requested';

		return this.apiService.get(url);
	}

	public handleChannelMembership(channelId, memberId): Observable<any> {
		const url = `/channels/${channelId}/members/${memberId}/`,
			data = { state: 'active' };

		return this.apiService.put(url, data);
	}

	public deleteChannelMembership(channelId, memberId): Observable<any> {
		const url = `/channels/${channelId}/members/${memberId}/`;

		return this.apiService.delete(url);
	}

	public getArchivedCounts(membershipId): Observable<any> {
		const url = '/users/' + localStorage.getItem('uid') + '/memberships/' + membershipId + '/channels_count/';

		return this.apiService.get(url);
	}
}
