export class Subscription {
	public cancelAt: any;
	public network: any;
	public numberOfSeats: any;
	public purchasedPlan: any;
	public renewableDate: any;
	public startDate: any;
	public subscriptionPlan: any;
	public trialEnd: any;
	public canceledAt: any;
	public uid: any;
	public subscriptionStripeData: any;
	public status: string;
}
