import { Subscription, timer } from 'rxjs';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserService, NetworkService, NotificationService, LayoutService } from '../../../services';
import { User } from '../../../models';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { SearchService } from '../../../services/search.service';
import { InboxSocketService } from '@shared/services/inbox-socket.service';
import { Location } from '@angular/common';
import { TabTitleUiService } from '@core/services/ui/common/tab-title-ui.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { HeaderService } from './services/header.service';
import CacheUtils from '@shared/utils/cache-utils';
import { distinctUntilChanged } from 'rxjs/operators';
import { MixPanelService } from '@core/services/third-party/mix-panel.service';
import { IConversation } from '@modules/chat/models/conversation';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { QuickChatService } from '@modules/chat/services/quick-chat.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
	@ViewChild('menuBody') public menuBody: ElementRef;

	public isExternalUser = false;
	public isStartDay = true;
	public isEndDay = true;
	public displayModal = 'none';
	public unreadCount = 0;
	public unreadInAppNotificationsCount: any;
	public modelText;
	public notificationCount = 0;
	public membershipUid;
	public isAuthenticated: boolean;
	public searchSubscription: any;
	public currentUser: User;
	public userNotificationSummary;
	public searchString: string;
	public showUpgradeOption = false;
	public nextUrl;
	public network;
	public showSearch = false;
	public showSearchBox = false;
	public searchPlaceholder = 'search';
	public displayDialog = 'none';
	public currentSubscription;
	public isExpired = false;
	public showNotification = false;
	public showUserDropdown = false;
	public notificationFetched = false;

	private lastNotificationCount = 0;
	private lastNotificationTime = 0;

	private lastConversationsData: IConversation[];

	public isDevMode = !environment.production;
	public currentUrl: string;

	private notificationSound: any = new Audio('assets/sounds/short-success-sound-glockenspiel-treasure-video-game-6346.mp3');
	private notificationSelectionSubscription: Subscription;
	private overlaySubscription: Subscription;
	public notificationToggle: boolean;
	public hideSearch = false;

	constructor(
		private userService: UserService,
		private networkService: NetworkService,
		private notificationService: NotificationService,
		public router: Router,
		public layoutService: LayoutService,
		public searchService: SearchService,
		private inboxSocket: InboxSocketService,
		public _location: Location,
		private changeDetectorRef: ChangeDetectorRef,
		private tabTitleUIService: TabTitleUiService,
		public headerService: HeaderService,
		public activatedRoute: ActivatedRoute,
		private mixPanelService: MixPanelService,
		private quickChatService: QuickChatService
	) {
		// init search box setup
		this.setupSearchBox();

		router.events.subscribe((val) => {
			if (val instanceof NavigationStart) {
				// clear search query
				this.searchString = '';
			} else if (val instanceof NavigationEnd) {
				this.currentUrl = val?.url;
				// reset search box setup
				this.setupSearchBox();
			}
		});
	}

	ngOnInit(): void {
		this.activatedRoute.url.subscribe((url) => {
			this.hideSearch = url[url?.length - 1]?.path === 'join-work' ? true : false;
		});
		this.inboxSocket.getSocket().removeAllListeners();

		const localNotificationCount = Number(localStorage.getItem('notificationCount')),
			cacheNotificationCount = Number(CacheUtils.getStoredData('notificationCount'));

		if (localNotificationCount >= 0) {
			this.notificationCount = localNotificationCount || 0;
		} else if (cacheNotificationCount >= 0) {
			this.notificationCount = cacheNotificationCount || 0;
		}

		this.userService.isAuthenticated.subscribe((authenticated) => {
			this.isAuthenticated = authenticated;

			if (!authenticated) {
				this.router.navigateByUrl('/');
			} else {
				this.userService.currentUser.subscribe((userData) => {
					this.currentUser = userData ? userData : this.userService.getCurrentUser();
				});
			}
		});

		this.networkService.networkReady.subscribe((networkLoaded) => {
			if (networkLoaded) {
				this.networkService.currentNetwork.subscribe((network) => {
					this.network = network;
					if (network.membership && network.membership.uid) {
						this.membershipUid = network.membership.uid;
					}

					if (network.uid) {
						this.showUpgradeOption = true;
					}

					if (network.membership && network.membership.memberRole && network.membership.memberRole.role) {
						const memberRole = network.membership.memberRole.role;

						if (memberRole.slug === 'external') {
							this.isExternalUser = true;
						}
					}
				});
				this.userService.currentUser.subscribe((userData) => {
					this.currentUser = userData ? userData : this.userService.getCurrentUser();

					const that = this;

					if (this.inboxSocket.getSocket().ioSocket.connected) {
						that.inboxSocket.getSocket().emit(
							'set-user-data',
							{
								username: this.currentUser.username,
								networkId: this.network.uid,
							},
							() => {}
						);
					}

					this.inboxSocket.getSocket().on('connect', () => {
						that.inboxSocket.getSocket().emit(
							'set-user-data',
							{
								username: this.currentUser.username,
								networkId: this.network.uid,
							},
							() => {}
						);
					});

					this.inboxSocket.getSocket().on('old-conversations', (oldConversations) => {
						const conversations = cloneDeep(oldConversations.result);
						let totalMessageCount = 0;

						conversations.forEach((conv) => {
							if (conv.unreadMessageCount) {
								totalMessageCount += conv.unreadMessageCount;
							}
						});

						that.unreadCount = totalMessageCount;
						if(this.lastConversationsData && oldConversations.result){
							const isDataChanged  = !isEqual(this.lastConversationsData,oldConversations.result);
							if(isDataChanged){
								this.lastConversationsData = cloneDeep(oldConversations.result);
								if(!this.quickChatService.isQuickChatOpened && this.unreadCount > 0){
									// this.quickChatService.manageQuickChatState(true, oldConversations.result[0]);
								}
							}else{
								// console.log('no change');
							}
						}else{
							// first load
							if(this.unreadCount > 0){
								// this.quickChatService.manageQuickChatState(true, conversations[0]);
								// this.quickChatService.setConversationToShowInQuickChat(conversations[0]);
								// this.quickChatService.showQuickChat();
							}
							this.lastConversationsData = cloneDeep(oldConversations.result);
						}
					});

					// this.inboxSocket.getSocket().on('new-message', (data) => {
					// 	console.log('new message inbox', data);
					// });

					this.unreadInAppNotificationsCount = this.currentUser.unreadInAppNotificationsCount;

					this.tabTitleUIService.updateTabNotificationCount(this.unreadInAppNotificationsCount);
				});

				this.changeDetectorRef.detectChanges();
			}
		});



		this.networkService.subscriptionReady.subscribe((subscriptionLoaded) => {
			if (subscriptionLoaded) {
				this.networkService.currentSubscription.subscribe((subscription) => {
					this.currentSubscription = subscription;
					localStorage.setItem('debugPlan', JSON.stringify(this.currentSubscription));
					// const renewableDate = new Date(this.currentSubscription.renewableDate),
					// 	currentDate = new Date();
					const renewableDate = new Date(this.network?.subscription?.renewableDate),
						currentDate = new Date();

					this.isExpired = currentDate > renewableDate;
				});
			}
		},
		error => {
			console.log(error);
		});

		this.searchSubscription = this.searchService.searchValue.subscribe((data) => {
			if (!data) {
				this.searchString = '';
			}
		});

		this.userService.logoutEvent.subscribe(() => {
			this.logout();
		});

		// unread message count
		this.inboxSocket.getSocket().on('old-conversations', (oldConversations: any) => {
			const conversations = oldConversations.result;

			const totalMessageCount = conversations?.map((conversation: any) => conversation.unreadMessageCount).reduce((a: any, b: any) => a + b, 0);

			if (this.unreadCount > 0 && totalMessageCount > 0 && this.unreadCount !== totalMessageCount) {
				this.playNotificationSound();
			} else {
				this.tabTitleUIService.updateTabNotificationCount(0);
			}

			this.unreadCount = totalMessageCount;

			this.changeDetectorRef.detectChanges();
		});

		timer(0, 10000).subscribe(() => {
			this.playNotificationSound();
		});

		// Notification.requestPermission().then(function (permission) {
		// 	console.log(permission);
		// 	const text = 'HEY! Your task 11 is now overdue.';
		// 	const notification = new Notification('To do list', { body: text });
		// 	console.log(notification);
		// });

		this.playNotificationSound();

		/**
		 * To update unread count based on chat
		 */
		this.headerService.unReadConversationCountUpdateEmitter.pipe(distinctUntilChanged()).subscribe((count: number) => {
			this.unreadCount = count;
		});
	}

	ngOnDestroy(): void {
		this.searchService.resetSearchValue();

		if (this.searchSubscription) {
			this.searchSubscription.unsubscribe();
		}

		this.currentUrl = '';
		this.notificationSelectionSubscription?.unsubscribe();
		this.overlaySubscription?.unsubscribe();
	}

	/**
	 * Setup search box visibility and placeholder
	 */
	public setupSearchBox(): void {
		const currentPage = this.router?.url?.split('/')?.pop();

		if (currentPage) {
			const targetPages: any = ['members', 'channel', 'collections', 'collection', 'event'],
				targetedPage = targetPages.find((page: string) => page === currentPage);

			let searchPlaceholderTarget = 'posts';
			this.showSearch = true;

			if (targetedPage) {
				searchPlaceholderTarget = targetedPage;
			} else if (currentPage === 'network' || currentPage === 'notifications') {
				searchPlaceholderTarget = 'posts';
			} else if (this.router?.url?.includes('/project/')) {
				// TODO: fix me
				searchPlaceholderTarget = 'tasks';
			} else if (currentPage === 'buy-sell') {
				searchPlaceholderTarget = 'offers';
			} else if (currentPage === 'inbox') {
				searchPlaceholderTarget = 'conversations';
			} else if (
				currentPage === 'customers' ||
				currentPage === 'customer-states' ||
				currentPage === 'checkin' ||
				currentPage === 'product-detail' ||
				currentPage === 'products'
			) {
				searchPlaceholderTarget = '';
			} else {
				this.showSearch = false;
			}

			if (this.showSearch) {
				this.searchPlaceholder = searchPlaceholderTarget !== '' ? `Search ${searchPlaceholderTarget}` : 'Search';
			}
		}
	}

	public getUserNotificationsSummary(filter): void {
		if (this.currentUser) {
			this.showNotification = true;

			if (!localStorage.getItem('network_id')) {
				this.notificationService.getUserNotifications(this.currentUser.username, filter).subscribe((notificationData) => {
					this.notificationFetched = true;

					// localStorage.removeItem('userDetails');
					// CacheUtils.removeStoredData('userDetails');

					// this.userService.updateUserDetails();
					this.allNotifications = notificationData;
					this.userNotificationSummary = notificationData.objects;

					if (notificationData.next) {
						this.nextUrl = notificationData.next.split('alpha')[1];
						this.paginate();
					} else {
						this.nextUrl = '';
					}
				});
			} else {
				this.notificationCount = 0;

				localStorage.removeItem('notificationCount');

				let networkId;

				this.networkService.currentNetwork.subscribe((network) => {
					networkId = network.uid;
				});

				this.notificationService.getNotifications(networkId).subscribe((notificationData) => {
					this.notificationFetched = true;
					this.allNotifications = notificationData;
					this.userNotificationSummary = notificationData.objects;

					// localStorage.removeItem('userDetails');
					// CacheUtils.removeStoredData('userDetails');

					// this.userService.updateUserDetails();

					this.updateNotificationStatus(notificationData.objects);

					if (notificationData.next) {
						this.nextUrl = notificationData.next.split('alpha')[1];
					} else {
						this.nextUrl = '';
					}
				});
			}
			this.changeDetectorRef.detectChanges();
		} else {
			this.userService.currentUser.subscribe((userData) => {
				this.currentUser = userData ? userData : this.userService.getCurrentUser();
			});
			this.getUserNotificationsSummary('type=account');
		}
	}

	public paginate(): void {
		if (this.nextUrl && this.router.url !== '/network') {
			this.networkService.paginateTasks(this.nextUrl).subscribe((data) => {
				if (data) {
					this.userNotificationSummary = this.userNotificationSummary.concat(data.objects);
					if (data.next) {
						this.nextUrl = data.next.split('alpha')[1];
					} else {
						this.nextUrl = '';
					}
				}
			});
		}
	}

	private updateNotificationStatus(notificationsArray: any[]): void {
		notificationsArray.forEach((notification) => {
			this.notificationService.changeNotificationReadStatus(notification);
		});
	}

	public logout(): void {
		// this.mixPanelService.reset();
		this.router.navigate(['/']);
		window.location.href = '/';

		this.check();

		this.userService.purgeAuth();
		this.userService.memberships = [];
		this.userService.nextUrl = [];
		this.networkService.destroyNetwork();

		localStorage.removeItem('user_language');
		localStorage.removeItem('membership');
		localStorage.removeItem('roles');
		localStorage.removeItem('sub_networks');
		localStorage.removeItem('userDetails');
		CacheUtils.removeStoredData('userDetails');
		localStorage.removeItem('network_settings');
		localStorage.removeItem('defaultNetworkType');
		localStorage.removeItem('defaultPurchasePlan');
		localStorage.removeItem('currentPurchasePlan');
		localStorage.removeItem('member-delete');
		localStorage.removeItem('membershipCount');
		localStorage.removeItem('isPartnerStack');
		localStorage.removeItem('currentSubscriptionStatus');
		this.mixPanelService.reset();
	}

	public onSearchClick(): void {
		if (this.showSearchBox) {
			if (this.searchString || this.searchString === '') {
				this.searchService.initiateSearch(this.searchString);
				if (this.searchString === '') {
					this.showSearchBox = false;
				}
			} else {
				this.showSearchBox = false;
			}
		} else {
			this.showSearchBox = true;
		}
	}

	public onSearchEnter(event: any): void {
		if (event.keyCode === 13) {
			this.onSearchClick();
		}
	}

	public hideBackdrop(): void {
		this.layoutService.hideBd();
		this.changeDetectorRef.detectChanges();
	}

	public onChangeSearch(): void {
		if (!this.searchString) {
			this.onSearchClick();
		}
	}

	private check(): void {
		Object.keys(localStorage).forEach((key) => {
			if (key.indexOf('-Todolist') > -1) {
				localStorage.removeItem(key);
			}

			if (key.indexOf('-Posts') > -1) {
				localStorage.removeItem(key);
			}

			if (key.indexOf('-lists') > -1) {
				localStorage.removeItem(key);
			}

			if (key.indexOf('-projects') > -1) {
				localStorage.removeItem(key);
			}
		});
	}

	public loadDetailPage(notification: any): void {
		this.hideBackdrop();

		if (!localStorage.getItem('network_id')) {
			localStorage.setItem('network_id', notification['networkId']);
			this.networkService.networkId = notification?.networkId;
		}

		this.notificationService.setLastNotificationReadTimeBasedOnNetwork(notification);

		if (notification.contentType === 'folder') {
			this.router.navigateByUrl('/network/collection?id=' + notification?.contentUid);
		} else if (
			// eslint-disable-next-line spellcheck/spell-checker
			(notification?.contentType === 'post' || notification?.contentType === 'comment' || notification?.contentType === 'foldercontents') &&
			notification?.parentType !== 'Issue'
		) {
			if (notification?.contentType === 'comment') {
				this.router.navigateByUrl('/posts/' + notification?.parentUid + '?comment_id=' + notification?.contentUid + '&parentComment=' + notification?.parentCommentUid);
			} else {
				this.router.navigateByUrl('/posts/' + notification?.postUid);
			}
		} else if (notification?.contentType === 'issue') {
			this.router.navigateByUrl('/network/issue-detail?id=' + notification?.contentUid);
		} else if (notification?.parentType && notification?.parentType === 'Issue') {
			this.router.navigateByUrl('/network/issue-detail?id=' + notification?.parentUid);
		} else if (notification?.contentType === 'todolist') {
			this.router.navigateByUrl(`/project/team/${notification?.contentUid}`);
		} else if (notification?.contentType === 'todo') {
			this.router.navigateByUrl('/project/task-detail/' + notification?.contentUid);
			// eslint-disable-next-line spellcheck/spell-checker
		} else if (notification?.contentType === 'channelmember' && !this.isExternalUser) {
			this.router.navigateByUrl('/network/view-channel?id=' + notification?.channel?.uid);
		} else if (notification?.contentType === 'StatusUpdate') {
			if (notification?.slug === 'forgot-start-day') {
				if (this.isStartDay) {
					this.router.navigateByUrl('/project/my-tasks');
				} else {
					this.modelText = 'You already have taken start day action';
					this.displayModal = 'block';
				}
			}

			if (notification?.slug === 'forgot-end-day') {
				if (this.isEndDay) {
					this.router.navigateByUrl('/project/my-tasks');
				} else {
					this.modelText = 'You have already taken end day action';
					this.displayModal = 'block';
				}
			}
		} else if (notification?.contentType === 'TaskUpdate') {
			if (notification?.slug === 'create-todo-tasks') {
				this.router.navigateByUrl('/network/todolist');
			}
			if (notification?.slug === 'congrats-end-day') {
				this.router.navigateByUrl('/project/my-tasks');
			}
			if (notification?.slug === 'pending-tasks-complete') {
				this.router.navigateByUrl('/project/my-tasks');
			}
		}
	}

	public onImgError(event): void {
		event.target.src = 'assets/images/default_avatar.jpg';
	}

	// #region Helpers

	public playNotificationSound(): void {
		return;
		this.tabTitleUIService.updateTabNotificationCount(this.unreadCount + this.notificationCount);

		if (this.unreadCount === 0 && this.notificationCount === 0) {
			return;
		}

		if (this.lastNotificationCount !== this.unreadCount && this.notificationCount && this.lastNotificationTime !== moment().unix() + moment().add(30, 'minute').unix()) {
			this.lastNotificationTime = moment().unix();
			this.lastNotificationCount = this.unreadCount && this.notificationCount;

			const promise = this.notificationSound.play();

			if (promise !== undefined) {
				promise
					.then(() => {
						// auto play started
					})
					.catch(() => {
						this.notificationSound.muted = true;

						this.notificationSound.play();
					});
			}
		}
	}

	// #endregion

	// #region Notification Overlay
	private allNotifications: any;
	public selectedFilter: 'All' | 'Unread' = 'All';

	/**
	 * Handle notification component functionalities
	 * 1. Get notification list and set behavior subject
	 * 2. Open notification overlay
	 */
	public showNotificationOverlay(): void {
		this.getUserNotificationsSummary('type=account');
		this.showNotification = true;
	}

	/**
	 * Close notification overlay
	 */
	public closeNotification(): void {
		this.nextUrl = '';
		this.showNotification = false;
	}

	/**
	 * Handle switching Of Notification Filter
	 * @param filter Selected Filter
	 * 1. Start loader
	 * 2.a. If "Unread", filter Notification List based on state
	 * 2.b. If "All", revert Notification List
	 * 3. Stop loader
	 */
	public changeNotificationFilter(filter: 'All' | 'Unread'): void {
		this.selectedFilter = filter;
		this.notificationFetched = false; // 1
		switch (filter) {
			case 'Unread':
				this.userNotificationSummary = this.userNotificationSummary.filter((notification: any) => notification?.state === 'unread'); // 2.a
				break;
			case 'All':
				this.userNotificationSummary = this.allNotifications.objects;
				this.nextUrl = this.allNotifications.next ? this.allNotifications.next.split('alpha')[1] : ''; // 2.b
				break;
		}
		this.menuBody?.nativeElement?.scrollTo({ top: 0, behavior: 'smooth' });
		this.notificationFetched = true; // 3
	}

	/**
	 * Handle Scroll pagination of notifications
	 */
	public paginateNotifications(): void {
		if (this.nextUrl) {
			this.headerService.paginateNotifications(this.nextUrl).subscribe((data) => {
				if (data) {
					if (this.selectedFilter === 'All') {
						this.userNotificationSummary = this.userNotificationSummary.concat(data.objects);
					} else if (this.selectedFilter === 'Unread') {
						const notificationList = data.objects.filter((notification) => notification?.state !== 'unread');
						this.userNotificationSummary = this.userNotificationSummary.concat(notificationList);
					}

					if (data.next) {
						this.nextUrl = data.next.split('alpha')[1];
					} else {
						this.nextUrl = '';
					}
				}
			});
		}
	}

	// #endregion
}
