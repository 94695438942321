/* eslint-disable spellcheck/spell-checker */
import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { CommentsService, UserService, PostService, NetworkService, LayoutService } from '../../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { InboxSocketService } from '../../../services/inbox-socket.service';
import { timer } from 'rxjs';
import { LightboxService } from 'projects/peer-core/src/lib/services/ui/lightbox.service';
import { ImageAttachment, IImageAttachment } from 'projects/bee-attachment/src/lib/shared/models/image-attachment';
import { debounceTime } from 'rxjs/operators';

@Component({
	selector: 'app-new-comment-list',
	templateUrl: './new-comment-list.component.html',
	styleUrls: ['./new-comment-list.component.css'],
})
export class NewCommentListComponent implements OnInit, OnChanges {
	@Input() public post: any;
	@Input() public isArchived = false;
	@Input() public isScrollEnabled: boolean;
	@Input() public maxHeight = 5;
	@Input() public isEndDayModalSource = false;
	@ViewChild('reportInput', { static: true }) public reportInput: any;
	// eslint-disable-next-line spellcheck/spell-checker
	@Output() public removeCommemtsHeading: EventEmitter<any> = new EventEmitter();
	@Output() public onClickMoreOptionsEmitter: EventEmitter<any> = new EventEmitter();
	@Output() public totalComments: EventEmitter<number> = new EventEmitter();

	public commentLoaded = false;
	public network;
	public scrollStatus = false;
	public parentUid;
	public commentType;
	public onlineStack = [];
	public viewDialog = 'none';
	public views: any = [];
	public viewNextUrl;
	public isFetching = 0;
	public masterComment: any;
	public selectedMainComment: any;
	public isCommentsLoading = false;

	public comment_id;
	public showCommentDeleteConfirmation = 'none';
	public showReplyCommentDeleteConfirmation = 'none';
	public deleteSelectedComment = 'none';
	public currentUser: any;
	public totalCommentsCount = 0;
	public selectedImages: any[] = [];
	public commentsFetched = -1; // - for not started,0 for fetching, 1 for fetched
	public reportSelectedComment;
	public reportSelectModalDisplay = 'none';
	public selectedReportOption = null;
	public reason;
	public showReportError = false;
	public reportOptionMissingError = true;
	public commentText = this.translate.instant('Reply');
	public parentCommentUid: any;
	public postType: 'add-issue' | 'add-task' = 'add-issue';
	public networkServices = [];

	private isFirstRun = true;

	constructor(
		public layoutService: LayoutService,
		private commentService: CommentsService,
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserService,
		private postService: PostService,
		private router: Router,
		private translate: TranslateService,
		private networkService: NetworkService,
		private socket: InboxSocketService,
		private activatedRoute: ActivatedRoute,
		private lightboxService: LightboxService
	) {}

	ngOnInit(): void {
		this.activatedRoute.queryParams.subscribe((params) => {
			this.comment_id = params['comment_id'];
			this.parentCommentUid = params['parentComment'];
		});
		this.commentsFetched = -1;

		// if comment length is greater than 1, show view more comments
		if (this.post.comments && this.post.comments.length > 1) {
			this.commentsFetched = 1;
		}

		this.currentUser = this.userService.getCurrentUser();

		this.networkService.currentNetwork.subscribe((network) => {
			this.network = network;
			this.networkServices = this.network.services ? this.network.services : [];
		});

		this.getComments();

		if (this.router.url.indexOf('/network/customer/') > -1) {
			this.commentType = 'customer';
		} else if (this.router.url.indexOf('/project/task-detail/') > -1) {
			this.commentType = 'todo';
		} else if (this.router.url.indexOf('/network/product-detail?') > -1 || this.router.url.indexOf('/network/issue-detail?') > -1) {
			this.commentType = 'issue';
		}

		/**
		 * For showing online/offline statues of post authors
		 * 1. Emit set user data
		 * 2. Get call back from onlineStack event and store online stack data
		 */
		if (this.socket.getSocket().ioSocket.connected) {
			// 1
			this.socket.getSocket().emit('set-user-data', { username: this.currentUser.username, networkId: this.network.uid }, () => {});
		}

		this.socket.getSocket().on('onlineStack', (members) => {
			// 2
			const onlineMembers = Object.keys(members).map(function (k) {
				return members[k];
			});
			this.onlineStack = onlineMembers;
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.hasOwnProperty('post') && JSON.stringify(changes?.post?.currentValue) !== JSON.stringify(changes?.post?.previousValue) && !changes?.post?.firstChange) {
			this.getComments();
		}
	}

	/**
	 * Function to create like or delete like of a comment
	 */
	public onLikeCommentClick(comment): void {
		if ((comment['myRelations'] || !comment['myRelations']) && !comment['myRelations'].like) {
			this.commentService.likeComment(comment.uid).subscribe((data) => {
				comment['myRelations'].like = data['uid'];

				if (comment['likeCount']) {
					comment['likeCount'] = comment['likeCount'] + 1;
				} else {
					comment['likeCount'] = 1;
				}
			});
		} else {
			this.commentService.deleteLike(comment['myRelations'].like).subscribe(() => {
				comment['myRelations'].like = null;

				if (comment['likesCount'] !== 0) {
					comment['likeCount'] = comment['likeCount'] - 1;
				}
			});
		}
	}

	/**
	 * Function to show the delete confirmation model when delete label in comment is clicked.
	 */
	public showDeleteCommentConfirmation(comment): void {
		this.showCommentDeleteConfirmation = 'block';
		this.deleteSelectedComment = comment;
	}

	public hideDeleteConfirmation(): void {
		this.showCommentDeleteConfirmation = 'none';
		this.deleteSelectedComment = null;
	}

	/**
	 * Function to show the delete confirmation model when delete label in comment is clicked.
	 */
	public showReplyDeleteCommentConfirmation(comment, masterComment): void {
		this.showReplyCommentDeleteConfirmation = 'block';
		this.deleteSelectedComment = comment;
		this.masterComment = masterComment;
	}

	public hideReplyDeleteConfirmation(): void {
		this.showReplyCommentDeleteConfirmation = 'none';
		this.deleteSelectedComment = null;
		this.masterComment = null;
	}

	/**
	 * Function to delete the comment on confirming comment delete.
	 */
	public onReplyCommentDeleteConfirmation(): void {
		if (this.deleteSelectedComment) {
			this.commentService.deleteComment(this.deleteSelectedComment['uid']).subscribe(() => {
				const commentIndex = this.masterComment['replyComments'].indexOf(this.deleteSelectedComment);

				this.masterComment['replyComments'].splice(commentIndex, 1);
				this.masterComment['replyCount'] = this.masterComment['replyCount'] - 1;
				this.post.commentCount = this.post.commentCount - 1;

				this.hideReplyDeleteConfirmation();
				this.removeCommemtsHeading.emit();
			});
		}
	}

	public getComments(): void {
		this.commentsFetched = 0;
		this.isCommentsLoading = true;
		if (this.post.type === 'enhancement' || this.post.type === 'feature' || this.post.type === 'issue' || this.post.type === 'todo' || this.post.type === 'sub_task') {
			this.postService
				.getTaskComments(this.post['uid'])
				.pipe(debounceTime(1500))
				.subscribe((data) => {
					console.log(data);
					this.totalCommentsCount = data.total_count;
					this.post.totalMasterCommentCount = data.count;
					this.totalComments.emit(data.count);
					this.post.comments = data.objects;
					this.commentsFetched = 1;

					this.post.nextCommentUrl = data.next ? data.next.split('alpha')[1] : null;

					this.checkUserMentionAndHashtagAndAlterCommentText();

					if (this.parentCommentUid !== this.comment_id) {
						const parentComment = this.post.comments.find((li) => li.uid === this.parentCommentUid);

						if (parentComment) {
							this.showReplyComments(parentComment);
						}
					} else if (this.comment_id) {
						setTimeout(() => {
							const elem = document.getElementById(this.comment_id);

							elem.scrollIntoView({ behavior: 'smooth', inline: 'center' });
							elem.classList.add('highlight-parent');
						}, 500);
					}

					timer(1000).subscribe(() => {
						this.changeDetectorRef.detectChanges();
						this.post.comments = data.objects;
					});
				})
				.add(() => {
					this.isCommentsLoading = false;
					this.changeDetectorRef.detectChanges();
				});
		} else if (this.post.type === 'customer') {
			this.postService
				.getCustomerComments(this.post['uid'])
				.subscribe((data) => {
					this.totalCommentsCount = data.total_count;
					this.post.totalMasterCommentCount = data.count;
					this.post.comments = data.objects;
					this.commentsFetched = 1;

					this.post.nextCommentUrl = data.next ? data.next.split('alpha')[1] : null;

					this.checkUserMentionAndHashtagAndAlterCommentText();

					if (this.parentCommentUid !== this.comment_id) {
						const parentComment = this.post.comments.find((li) => li.uid === this.parentCommentUid);

						if (parentComment) {
							this.showReplyComments(parentComment);
						}
					} else if (this.comment_id) {
						setTimeout(() => {
							const elem = document.getElementById(this.comment_id);

							elem.scrollIntoView({ behavior: 'smooth', inline: 'center' });
							elem.classList.add('highlight-parent');
						}, 500);
					}
				})
				.add(() => {
					this.isCommentsLoading = false;
					this.changeDetectorRef.detectChanges();
				});
		} else if (this.post.type === 'table_item') {
			this.postService
				.getContentComments(this.post['uid'], 'table_item')
				.subscribe((data) => {
					this.totalCommentsCount = data.total_count;
					this.post.totalMasterCommentCount = data.count;
					this.post.comments = data.objects;
					this.commentsFetched = 1;

					this.post.nextCommentUrl = data.next ? data.next.split('alpha')[1] : null;

					this.checkUserMentionAndHashtagAndAlterCommentText();

					if (this.parentCommentUid !== this.comment_id) {
						const parentComment = this.post.comments.find((li) => li.uid === this.parentCommentUid);

						if (parentComment) {
							this.showReplyComments(parentComment);
						}
					} else if (this.comment_id) {
						setTimeout(() => {
							const elem = document.getElementById(this.comment_id);

							elem.scrollIntoView({ behavior: 'smooth', inline: 'center' });
							elem.classList.add('highlight-parent');
						}, 500);
					}
				})
				.add(() => {
					this.isCommentsLoading = false;
					this.changeDetectorRef.detectChanges();
				});
		} else {
			this.postService
				.getComments(this.post['uid'])
				.subscribe((data) => {
					this.commentLoaded = true;
					this.totalCommentsCount = data.count;
					this.post.totalMasterCommentCount = data.count;
					this.post.comments = data.objects;
					this.commentsFetched = 1;

					this.post.nextCommentUrl = data.next ? data.next.split('alpha')[1] : null;

					this.checkUserMentionAndHashtagAndAlterCommentText();

					if (this.parentCommentUid !== this.comment_id) {
						const parentComment = this.post.comments.find((li) => li.uid === this.parentCommentUid);

						if (parentComment) {
							this.showReplyComments(parentComment);
						}
					} else if (this.comment_id) {
						setTimeout(() => {
							const elem = document.getElementById(this.comment_id);

							elem.scrollIntoView({ behavior: 'smooth', inline: 'center' });
							elem.classList.add('highlight-parent');
						}, 500);
					}
				})
				.add(() => {
					this.isCommentsLoading = false;
					this.changeDetectorRef.detectChanges();
				});
		}
	}

	public loadMoreComment(): void {
		this.commentsFetched = 0;

		if (this.post['nextCommentUrl']) {
			this.commentService.getAll(this.post['nextCommentUrl']).subscribe((data) => {
				data['objects'].forEach((datas) => {
					this.post.comments.push(datas);
				});

				this.checkUserMentionAndHashtagAndAlterCommentText();
				this.commentsFetched = 1;

				if (data.next) {
					this.post['nextCommentUrl'] = data.next.split('alpha')[1];
				} else {
					this.post['nextCommentUrl'] = null;
				}

				if (this.parentUid) {
					this.checkForParentComment();
				}
			});
		}
	}

	/**
	 * Function for handling autoscroll for reply commment
	 * @param e
	 * @param comment
	 * 1. Remove 'highlight-parent' on other elements.
	 * 2. Check if reply action is clicked.
	 * 3. Set selected comments parentUid .
	 * 4. Scroll to parent commments position from its uid.
	 * 5. Load more comments if parent comment is not found
	 */
	public onClickReplyComment(e, comment): void {
		if (this.parentUid) {
			const el = document.getElementById(this.parentUid);
			el.classList.remove('highlight-parent'); // 1
		}
		if (e.target.className === 'text-reply cursor-pointer') {
			// 2
			if (comment['parent'] && comment['parent']['uid']) {
				this.parentUid = comment['parent']['uid'];
				const element = document.getElementById(comment['parent']['uid']); // 3
				if (element) {
					element.scrollIntoView({ behavior: 'smooth', inline: 'center' }); // 4
					element.classList.add('highlight-parent');
				} else {
					this.loadMoreComment(); // 5
				}
			}
		}
	}

	/**
	 * For handling hashtags redirection to corresponding customerslist
	 * @param e
	 * @param comment
	 */
	public handleRedirections(e, comment): void {
		if (e.target && e.target.className) {
			const type = e.target.className;

			if (type === 'hash-customer') {
				const text = e.target.innerHTML.split('#')[1];
				const hashtags = comment['hashtags'];

				hashtags.forEach((tag) => {
					if (tag['name'] === text) {
						this.router.navigateByUrl('/network/customers?hash_tag=' + tag['uid'] + '&name=' + tag['name']);
					}
				});
			}

			if (type === 'hash-todo') {
				const text = e.target.innerHTML.split('#')[1],
					hashtags = comment['hashtags'];

				hashtags.forEach((tag: any) => {
					if (tag.name === text) {
						this.router.navigateByUrl(`/project/team/search?hash_tag=${tag.id}&name=${tag.name}`);
					}
				});
			}

			if (type === 'hash-issue') {
				const text = e.target.innerHTML.split('#')[1];
				const hashtags = comment['hashtags'];

				hashtags.forEach((tag) => {
					if (tag['name'] === text) {
						this.router.navigateByUrl('/network/reported-issues-detail?hash_tag=' + tag['uid'] + '&name=' + tag['name']);
					}
				});
			}

			if (type === 'hash-post') {
				const text = e.target.innerHTML.split('#')[1];
				const hashtags = comment['hashtags'];

				hashtags.forEach((tag) => {
					if (tag['name'] === text) {
						this.layoutService.hideBd();
						this.router.navigateByUrl('/network?hash_tag=' + tag['uid'] + '&name=' + tag['name']);
					}
				});
			}
		}
	}

	/**
	 * For checking and handling autoscoll on parent comment
	 * After comments are loaded
	 */
	public checkForParentComment(): void {
		const element = document.getElementById(this.parentUid);

		if (element) {
			element.scrollIntoView({ behavior: 'smooth', inline: 'center' });
			element.classList.add('highlight-parent');
		} else {
			this.loadMoreComment();
		}
	}

	/**
	 * Function to check the user mention and hash tag in post list if exists change the comment text
	 * 1. If string contains '<',then its cut off while displaying in innerHtml.Hence its replaced by '< '
	 */
	public checkUserMentionAndHashtagAndAlterCommentText(): void {
		if (this.post.comments) {
			this.post.comments.forEach((comment) => {
				if (comment) {
					if (comment.text && comment.text.indexOf('<') > -1) {
						// 1
						comment.text = comment.text.replace(/</g, '<');
					}

					comment.alteredText = comment.text;
					comment.minAlteredText = '';

					if (comment.alteredText && comment.alteredText.length > 150) {
						comment.minAlteredText = comment.alteredText.slice(0, 150) + '...';
						comment.showMinComment = true;
					}

					comment.alteredText = comment.alteredText.replace(/(?:\r\n|\r|\n)/g, '<br>');
					comment.minAlteredText = comment.minAlteredText.replace(/(?:\r\n|\r|\n)/g, '<br>');

					if (comment['mentionedUsers']) {
						comment['mentionedUsers'].forEach((mentionedUser) => {
							// const userMention = '@' + mentionedUser['name'];
							const html =
								'<b #userMention><a class="text-green" href="/network/profile/' +
								mentionedUser?.memberId +
								'?username=' +
								mentionedUser?.username +
								'">@' +
								mentionedUser['name'] +
								'</a></b>';
							comment.alteredText = comment.alteredText.replace('@' + mentionedUser['name'], html);
							comment.minAlteredText = comment.minAlteredText.replace('@' + mentionedUser['name'], html);
						});
					}

					if (comment['hashtags']) {
						comment['hashtags'].forEach((hashtag) => {
							if (hashtag['name']) {
								// 31-05-21 hashtags redirections for customers
								if (this.commentType === 'customer') {
									const hashtagName = hashtag['name'];
									// const hashtagUid = hashtag['uid'];
									const html = '<b #hashTag><a class="hash-customer">#' + hashtagName + '</a></b>';

									comment.alteredText = comment.alteredText.replace('#' + hashtag['name'], html);
									comment.minAlteredText = comment.minAlteredText.replace('#' + hashtag['name'], html);
								} else if (this.commentType === 'todo') {
									const hashtagName = hashtag['name'];
									// const hashtagUid = hashtag['uid'];
									const html = '<b #hashTag><a class="hash-todo">#' + hashtagName + '</a></b>';

									comment.alteredText = comment.alteredText.replace('#' + hashtag['name'], html);
									comment.minAlteredText = comment.minAlteredText.replace('#' + hashtag['name'], html);
								} else if (this.commentType === 'issue') {
									const hashtagName = hashtag['name'];
									// const hashtagUid = hashtag['uid'];
									const html = '<b #hashTag><a class="hash-issue">#' + hashtagName + '</a></b>';

									comment.alteredText = comment.alteredText.replace('#' + hashtag['name'], html);
									comment.minAlteredText = comment.minAlteredText.replace('#' + hashtag['name'], html);
								} else {
									const hashtagName = hashtag['name'];
									// const hashtagUid = hashtag['uid'];
									const html = '<b #hashTag><a class="hash-post">#' + hashtagName + '</a></b>';

									comment.alteredText = comment.alteredText.replace('#' + hashtag['name'], html);
									comment.minAlteredText = comment.minAlteredText.replace('#' + hashtag['name'], html);
								}
							}
						});
					}
					const urlRegex =
						/(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;
					if (comment.alteredText) {
						const strings = comment.text.match(urlRegex);
						if (strings && strings.length > 0) {
							strings.forEach((element) => {
								let url = element;

								if (!(element.indexOf('http://') === 0 || element.indexOf('https://') === 0 || element.indexOf('ftp://') === 0)) {
									url = 'http://' + element;
								}

								const html = '<a target="blank" class="pb-text-link" href="' + url + '">' + element + '</a>';

								if (comment.alteredText) {
									comment.alteredText = comment.alteredText.replace(element, html);
									comment.minAlteredText = comment.minAlteredText.replace(element, html);
								}
							});
						}
					}

					if (comment.parent) {
						comment.alteredText = `<span class="text-reply cursor-pointer">${this.commentText}&nbsp;@${comment.parent.user.name}</span>&nbsp;${comment.alteredText}`;
						comment.minAlteredText = `<span class="text-reply cursor-pointer">${this.commentText}&nbsp;@${comment.parent.user.name}</span>&nbsp;${comment.minAlteredText}`;
					}
				}
			});
		}
	}

	/**
	 * Function to  hide confirmation model and delete the delete selected post and comment.
	 */
	public hideCommentDeleteConfirmation(): void {
		this.showCommentDeleteConfirmation = 'none';
		this.deleteSelectedComment = null;
	}

	/**
	 * Function to delete the comment on confirming comment delete.
	 */
	public onCommentDeleteConfirmation(): void {
		if (this.deleteSelectedComment) {
			this.commentsFetched = 0;

			this.commentService.deleteComment(this.deleteSelectedComment['uid']).subscribe(() => {
				const commentIndex = this.post.comments.indexOf(this.deleteSelectedComment);

				this.post.comments.splice(commentIndex, 1);
				this.post.commentCount = this.post.commentCount - 1;
				this.post['totalMasterCommentCount'] = this.post['totalMasterCommentCount'] - 1;
				this.commentsFetched = 1;

				this.hideCommentDeleteConfirmation();
				this.removeCommemtsHeading.emit();
			});
		}
	}

	/**
	 * Function to reply the comment.
	 */
	public onReplyCommentClick(comment): void {
		comment['postId'] = this.post.uid;

		this.commentService.passReplySelectedComment(comment);
	}

	// #region UI Interactions

	public showInLightbox(comment: any): void {
		const images = comment.attachmentDetails?.gallery?.map((imageItem: any) => {
			const image = imageItem.image;

			return new ImageAttachment(<IImageAttachment>{
				id: image.uid,
				name: image.name,
				type: image.type,
				url: image.url,
				data: image,
			});
		});

		this.lightboxService.initLightbox(images);
	}

	// #endregion

	public onClickReport(comment): void {
		this.reportSelectedComment = comment;
		this.reportSelectModalDisplay = 'block';
		this.selectedReportOption = null;
		this.reason = '';
		this.showReportError = false;
		this.reportOptionMissingError = false;
	}

	public hideReportOptionSelectModal(): void {
		this.reportSelectedComment = null;
		this.reportSelectModalDisplay = 'none';
		this.reportOptionMissingError = false;
		this.reason = '';
	}

	public onSelectReportOption(option): void {
		this.selectedReportOption = option;
		this.showReportError = false;
		this.reportOptionMissingError = false;

		if (option === 'other') {
			if (this.reportInput) {
				this.reportInput.nativeElement.focus();
			}
		} else {
			this.reason = '';
		}
	}

	public onSubmitReport(): void {
		if (this.selectedReportOption) {
			if (this.selectedReportOption === 'other' && !this.reason) {
				this.showReportError = true;
			} else {
				const obj = {
					contentType: 'comment',
					text: 'message',
					contentUid: this.reportSelectedComment.uid,
					reason: this.selectedReportOption,
				};

				this.commentService.report(obj).subscribe((data) => {
					if (data) {
						this.reportSelectedComment['myRelations']['flag'] = data['uid'];
						this.hideReportOptionSelectModal();
					}
				});
			}
		} else {
			this.reportOptionMissingError = true;
		}
	}

	public getDownloadLink(attachment): string {
		return attachment['url'] + '?cType=' + attachment['type'] + '&file=' + attachment['name'];
	}

	/**
	 * If reply comment field is presnet other than current comment,then remove it from all comments in a post.
	 * @param comment
	 */
	public reset(comment): void {
		this.selectedMainComment = comment;

		this.post.comments.forEach((element) => {
			if (element['uid'] !== comment['uid'] && element['replyPost']) {
				element['replyPost'] = false;
			}
		});
	}

	public resetReply(comment, masterComment): void {
		this.selectedMainComment = masterComment;

		this.post.comments.forEach((element) => {
			if (element['uid'] !== comment['uid'] && element['replyPost']) {
				element['replyPost'] = false;
			}
		});
	}

	public isOnline(username: string): boolean {
		const index = this.onlineStack.findIndex((member) => member?.username === username);
		return this.onlineStack[index]?.isOnline;
	}

	public getCommentViews(comment): void {
		this.viewDialog = 'block';
		this.scrollStatus = false;
		this.views = [];
		this.viewNextUrl = '';
		this.isFetching = 0;

		this.postService.getCommentViews(comment['uid']).subscribe((data) => {
			this.views = data.objects;
			this.isFetching = 1;

			if (data.next) {
				this.viewNextUrl = data.next.split('alpha')[1];
				this.paginateViews();
			} else {
				this.viewNextUrl = '';
			}
			this.changeDetectorRef.detectChanges();
		});
	}

	public paginateViews(): void {
		if (this.viewNextUrl) {
			if (this.scrollStatus) {
				return;
			}

			this.scrollStatus = true;
			this.postService.paginate(this.viewNextUrl).subscribe((data) => {
				this.views = this.views.concat(data.objects);
				this.scrollStatus = false;

				if (data.next) {
					this.viewNextUrl = data.next.split('alpha')[1];
				} else {
					this.viewNextUrl = '';
				}
			});
		}
	}

	/**
	 * Function to get reply comments
	 */
	public showReplyComments(comment): void {
		comment['replyCountToggle'] = true;
		comment['loader'] = true;

		this.postService.getReplyComments(comment.uid).subscribe(
			(res) => {
				comment['loader'] = false;

				if (res) {
					comment['replyNextUrl'] = res.next ? res.next.split('alpha')[1] : '';
					comment['replyComments'] = res.objects;
					comment['unreadCount'] = 0;

					this.checkUserMentionAndHashtagAndAlterReplyCommentText(comment);

					if (this.comment_id) {
						setTimeout(() => {
							const elem = document.getElementById(this.comment_id);
							elem.scrollIntoView({ behavior: 'smooth', inline: 'center' });
							elem.classList.add('highlight-parent');

							this.comment_id = '';
							this.parentCommentUid = '';
						}, 500);
					}
				} else {
					comment['replyCountToggle'] = false;
				}
				this.changeDetectorRef.detectChanges();
			},
			() => {
				comment['loader'] = false;
				comment['replyCountToggle'] = false;
				this.changeDetectorRef.detectChanges();
			}
		);
	}

	/** For reply comments
	 * Function to check the user mention and hash tag in post list if exists change the comment text
	 * 1. If string contains '<',then its cut off while displaying in innerHtml.Hence its replaced by '< '
	 */
	public checkUserMentionAndHashtagAndAlterReplyCommentText(comments): void {
		if (comments.replyComments) {
			comments.replyComments.forEach((comment) => {
				if (comment) {
					if (comment.text && comment.text.indexOf('<') > -1) {
						// 1
						comment.text = comment.text.replace(/</g, '<');
					}

					comment.alteredText = comment.text;
					comment.minAlteredText = '';

					if (comment.alteredText && comment.alteredText.length > 150) {
						comment.minAlteredText = comment.alteredText.slice(0, 150) + '...';
						comment.showMinComment = true;
					}

					comment.alteredText = comment.alteredText.replace(/(?:\r\n|\r|\n)/g, '<br>');
					comment.minAlteredText = comment.minAlteredText.replace(/(?:\r\n|\r|\n)/g, '<br>');

					if (comment['mentionedUsers']) {
						comment['mentionedUsers'].forEach((mentionedUser) => {
							// const userMention = '@' + mentionedUser['name'];
							const html =
								'<b #userMention><a class="text-green" href="/network/profile/' +
								mentionedUser?.memberId +
								'?username=' +
								mentionedUser?.username +
								'">@' +
								mentionedUser['name'] +
								'</a></b>';
							comment.alteredText = comment.alteredText.replace('@' + mentionedUser['name'], html);
							comment.minAlteredText = comment.minAlteredText.replace('@' + mentionedUser['name'], html);
						});
					}

					if (comment['hashtags']) {
						comment['hashtags'].forEach((hashtag) => {
							if (hashtag['name']) {
								// 31-05-21 hashtags redirections for customers
								if (this.commentType === 'customer') {
									const hashtagName = hashtag['name'];
									// const hashtagUid = hashtag['uid'];
									const html = '<b #hashTag><a class="hash-customer">#' + hashtagName + '</a></b>';

									comment.alteredText = comment.alteredText.replace('#' + hashtag['name'], html);
									comment.minAlteredText = comment.minAlteredText.replace('#' + hashtag['name'], html);
								} else if (this.commentType === 'todo') {
									const hashtagName = hashtag['name'];
									// const hashtagUid = hashtag['uid'];
									const html = '<b #hashTag><a class="hash-todo">#' + hashtagName + '</a></b>';

									comment.alteredText = comment.alteredText.replace('#' + hashtag['name'], html);
									comment.minAlteredText = comment.minAlteredText.replace('#' + hashtag['name'], html);
								} else if (this.commentType === 'issue') {
									const hashtagName = hashtag['name'];
									// const hashtagUid = hashtag['uid'];
									const html = '<b #hashTag><a class="hash-issue">#' + hashtagName + '</a></b>';

									comment.alteredText = comment.alteredText.replace('#' + hashtag['name'], html);
									comment.minAlteredText = comment.minAlteredText.replace('#' + hashtag['name'], html);
								} else {
									const hashtagName = hashtag['name'];
									// const hashtagUid = hashtag['uid'];
									const html = '<b #hashTag><a class="hash-post">#' + hashtagName + '</a></b>';

									comment.alteredText = comment.alteredText.replace('#' + hashtag['name'], html);
									comment.minAlteredText = comment.minAlteredText.replace('#' + hashtag['name'], html);
								}
							}
						});
					}

					const urlRegex =
						/(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;
					if (comment.alteredText) {
						const strings = comment.text.match(urlRegex);
						if (strings && strings.length > 0) {
							strings.forEach((element) => {
								let url = element;

								if (!(element.indexOf('http://') === 0 || element.indexOf('https://') === 0 || element.indexOf('ftp://') === 0)) {
									url = 'http://' + element;
								}

								const html = '<a target="blank" class="pb-text-link" href="' + url + '">' + element + '</a>';

								if (comment.alteredText) {
									comment.alteredText = comment.alteredText.replace(element, html);
									comment.minAlteredText = comment.minAlteredText.replace(element, html);
								}
							});
						}
					}

					if (comment.parent) {
						comment.alteredText = `<span class="text-reply cursor-pointer">${this.commentText}&nbsp;@${comment.parent.user.name}</span>&nbsp;${comment.alteredText}`;
						comment.minAlteredText = `<span class="text-reply cursor-pointer">${this.commentText}&nbsp;@${comment.parent.user.name}</span>&nbsp;${comment.minAlteredText}`;
					}
				}
			});
		}
	}

	/**
	 * Function to paginate the reply comments
	 */
	public paginateReplyComments(comment): void {
		if (comment['replyNextUrl']) {
			comment['loader'] = true;

			this.commentService.getAll(comment['replyNextUrl']).subscribe(
				(res) => {
					comment['loader'] = false;

					if (res) {
						comment['replyNextUrl'] = res.next ? res.next.split('alpha')[1] : '';
						res['objects'].forEach((data) => {
							comment['replyComments'].push(data);
						});

						this.checkUserMentionAndHashtagAndAlterReplyCommentText(comment);
					}
					this.changeDetectorRef.detectChanges();
				},
				() => {
					comment['loader'] = false;
					this.changeDetectorRef.detectChanges();
				}
			);
		}
	}

	/**
	 * Function to refresh the comment replies
	 */
	public replyCommentGeneratedFn(): void {
		this.selectedMainComment.replyCount = this.selectedMainComment.replyCount + 1;
		this.showReplyComments(this.selectedMainComment);
	}

	public returnTotalCommentsText(commentsLength, TotalCommentsLength): void {
		return this.translate.instant('commentCount', {
			commentsLength: commentsLength,
			commentCount: TotalCommentsLength,
		});
	}

	public onClickMoreOptions(type, comment): void {
		const obj = {
			type: type,
			comment: comment,
		};

		this.onClickMoreOptionsEmitter.emit(obj);
	}

	/** Function to check the add task option is available in network services */
	public checkAddTaskOption(): boolean {
		if (this.networkServices && this.networkServices.length) {
			const slug = this.networkServices.find((li) => li.slug === 'add-task');

			return Boolean(slug);
		}

		return false;
	}

	/** Function to check the add issue option is available in network services */
	public checkAddIssueOption(): boolean {
		if (this.networkServices && this.networkServices.length) {
			const slug = this.networkServices.find((li) => li.slug === 'add-issue');

			return Boolean(slug);
		}

		return false;
	}
}
