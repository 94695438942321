import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

export enum EventAttendeeStatus {
	Attending = 1,
	NotAttending = 0,
	MaybeAttending = 2,
}

@Injectable()
export class EventService {
	private networkEventsObservable: Observable<any>;

	constructor(private apiService: ApiService) {}

	public getNetworkEvents(url?: string): Observable<any> {
		if (this.networkEventsObservable) {
			return this.networkEventsObservable;
		}

		this.networkEventsObservable = this.apiService.get(url).pipe(share());

		return this.networkEventsObservable;
	}

	public getEventAttendees(eventId: string, status: EventAttendeeStatus): Observable<any> {
		return this.apiService.get(`/posts/${eventId}/rsvps/?attending=${status}`).pipe(share());
	}
}
