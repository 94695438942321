import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable()
export class NotificationService {
	public notifications: any[] = [];
	public selectedFilter = '';
	public nextUrl = '';
	public scrollPosition = 0;

	constructor(private apiService: ApiService) {}

	public getNotifications(uid, filter = 'all', query?): Observable<any> {
		let url = `/networks/${uid}/messages/`;

		if ((filter !== 'all' || filter) && !query) {
			url = `${url}?${filter}`;
		} else if ((filter !== 'all' || filter) && query) {
			url = `${url}?${filter}&query=${query}`;
		} else if ((filter === 'all' || !filter) && query) {
			url = `${url}?query=${query}`;
		}

		return this.apiService.get(url);
	}

	public getUserNotifications(username, filter): Observable<any> {
		let url = '/users/' + username + '/messages/';

		if (filter) {
			url = url + '?' + filter;
		}

		return this.apiService.get(url);
	}

	public paginate(url): Observable<any> {
		return this.apiService.get(url);
	}

	public setLastNotificationReadTimeBasedOnNetwork(notification): void {
		let currentData = JSON.parse(localStorage.getItem('last-notification'));
		const currentTimeStamp = new Date(notification['created']).getTime();
		if (!currentData) {
			currentData = {};
		}
		if (currentData[localStorage.getItem('network_id')]) {
			if (currentData[localStorage.getItem('network_id')] < currentTimeStamp) {
				currentData[localStorage.getItem('network_id')] = currentTimeStamp;
			}
		} else {
			currentData[localStorage.getItem('network_id')] = currentTimeStamp;
		}
		currentData = JSON.stringify(currentData);
		localStorage.setItem('last-notification', currentData);
	}

	public getLastNotificationReadTimeBasedOnNetwork(): any {
		const lastReadTime = JSON.parse(localStorage.getItem('last-notification'));
		if (lastReadTime) {
			if (lastReadTime[localStorage.getItem('network_id')]) {
				return lastReadTime[localStorage.getItem('network_id')];
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	public changeNotificationReadStatus(notification): void {
		const notificationTime = new Date(notification['created']).getTime(),
			lastReadTime = this.getLastNotificationReadTimeBasedOnNetwork();

		if (lastReadTime) {
			if (lastReadTime >= notificationTime) {
				notification['state'] = 'Read';
			}
		}
	}

	public resetNotifications(): void {
		this.notifications = [];
		this.selectedFilter = '';
		this.nextUrl = '';
		this.scrollPosition = 0;
	}
}
