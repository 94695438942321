import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IConversation } from '@modules/chat/models/conversation';
import { ChatService } from '@modules/chat/services/chat.service';
import { QuickChatService } from '@modules/chat/services/quick-chat.service';
import { LayoutService, NetworkService, UserService } from '@shared/services';
import { format } from 'date-fns';
import { Subject, Subscription, timer } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { NavigationEnd, Router } from '@angular/router';
import { QuickChatSocketService } from '@modules/chat/services/quick-chat-socket.service';
import { Conversation } from '@shared/models';

@Component({
	selector: 'app-quick-chat',
	templateUrl: './quick-chat.component.html',
	styleUrls: ['./quick-chat.component.scss'],
})
export class QuickChatComponent implements OnInit, OnDestroy {
	@ViewChild('searchInput', { static: false }) public searchInput: ElementRef;

	constructor(
		// private chatSocketService: ChatSocketService,
		private chatSocketService: QuickChatSocketService,
		private userService: UserService,
		private networkService: NetworkService,
		private chatService: ChatService,
		public layoutService: LayoutService,
		private changeDetectorRef: ChangeDetectorRef,
		private quickChatService: QuickChatService,
		private router: Router
	) {}

	public minimizeChat = true;
	public conversationView = false;
	public openClose = false;
	private openClose$: Subscription;

	public imageRegex = /^[image]+\/[-\w.]+$/gm;

	public conversations = [];
	public isConversationsLoading = true;
	private conversationHash: string | null = null;
	public message: any;

	public isMessageListLoading = true;
	private groupMembers: any[] = [];
	private userMentionList: any[] = [];
	private offset = 0;
	public searchBarWindow = false;
	public addConversationWindow = false;
	public currentUser: any;
	public isExternalUser: any;
	public network: any;
	public onlineStack = [];
	public membershipList = [];
	public members;
	public externalChannels = [];
	public selectedConversation: any = null;
	public selectedConversation$: Subscription;
	public messages: any[] = [];
	public isMessagesLoading = false;

	public isMemberListFetched = false;
	private nextMemberUrl: any;
	private isFirstLoad = false;
	private randomColor = '';
	private senders = [];
	public sortedMemberList = [];
	private subject: Subject<string> = new Subject();

	public isAnotherUserTyping = false;
	public anotherTypingUser: any;

	public replyConversation = null;
	private selectedLocation = null;
	private selectedImageUrl = null;
	private selectedDocument = null;
	public selectedMessage: any;
	public selectionType: 'conversation' | 'message';
	public searchValue = '';
	public selectedImages: any;

	public deleteConfirmationModal = 'none';
	public attachmentBubble: any;
	public attachmentType = '';

	private lastConversationsData: IConversation[];

	private currentUrl: string;
	public socketEventsInitialized = false;

	private routerEventsSubscription: Subscription;

	public searchChatResults: Conversation[];
	ngOnInit(): void {
		this.userService.currentUser.subscribe((userData) => {
			this.currentUser = userData;
		});

		// this.networkService.currentNetwork.subscribe((network: any) => {
		// 	this.network = network;
		// });

		this.routerEventsSubscription = this.router.events.pipe(
			filter(event => event instanceof NavigationEnd)
		  ).subscribe(() => {
			this.currentUrl = this.router.url;
		});

		this.membershipList = this.members ? this.members : [];

		this.networkService.currentNetwork
			.subscribe((network) => {
				const isDataChanged = this.network?.uid !== network.uid;
				this.network = {...network};
				// - external channel scenario 03-06-21
				if (network['membership'] && network['membership']['memberRole'] && network['membership']['memberRole']['role']) {
					const memberRole = network['membership']['memberRole']['role'];

					if (memberRole.slug === 'external') {
						this.isExternalUser = true;

						for (const channel of network.channels) {
							if (channel.access === 4 && channel.uid) {
								this.externalChannels.push(channel.uid);
							}
						}
					}
				}

				if (network?.uid && isDataChanged) {
					this.quickChatService.manageQuickChatState(false);
					// if(!this.socketEventsInitialized){

					this.selectedConversation = null;
					this.lastConversationsData = null;
					this.conversationView = false;
					this.initChat();
					// }
				}
			});

		this.subject.pipe(debounceTime(700)).subscribe((searchTextValue) => {
			if(!searchTextValue){
				this.isMemberListFetched = true;
				return;
			}
			// let filterVal: any;
			this.isMemberListFetched = false;
			const key = searchTextValue.toLowerCase();
			this.searchChatResults = this.conversations.filter(c => {
				if (c.isGroup) {
					return c.conversationName.toLowerCase().includes(key);
				} else {
					return c.sender.name.toLowerCase().includes(key);
				}
			});
			this.isMemberListFetched = true;

			// if (this.isExternalUser) {
			// 	// Check for external user
			// 	filterVal = 'query=' + searchTextValue + '&channel=' + this.externalChannels.join(', ');
			// } else {
			// 	filterVal = 'query=' + searchTextValue;
			// }
			// Fetch members in the network
			// this.chatService.getMembers(this.network?.uid, '', filterVal).subscribe((data) => {
			// 	filterVal = '';
			// 	this.membershipList = data.objects;
			// 	if (data.next) {
			// 		this.nextMemberUrl = data.next.split('alpha')[1];
			// 		this.paginateParticipants();
			// 	} else {
			// 		this.nextMemberUrl = '';
			// 	}

			// 	this.sortNetworkMembersAlphabetically();
			// 	// Check whether member is online
			// 	this.updateMemberStatus();
			// },
			// error => {
			// 	console.log(error);
			// 	this.isMemberListFetched = true;
			// }
			// );

			// this.chatSocketService.getSocket().on('delete-message', (data) => {
			// 	this.messages = this.messages.filter((c) => c._id !== data.messageId);
			// });
		});


		this.openClose$ = this.quickChatService.quickChatVisibilityStatus().subscribe(
			isOpen => {
				this.openClose = isOpen;
				if(isOpen === false){
					this.minimizeChat = true;
					this.selectedConversation = null;
				}
			}
		);

		this.selectedConversation$ = this.quickChatService.conversationToShowInQuickChatSubject().subscribe(
			selectedConversation =>{
				if(selectedConversation){
					timer(100).subscribe(() => {
						this.openConversation(selectedConversation);
					});
				}
			}
		);
	}

	public switchChatViews(): void {
		timer(250).subscribe(() => {
			this.conversationView = !this.conversationView;
		});
	}

	private connectSocket(): void{
		// on socket connected
		this.chatSocketService.getSocket().on('connect', () => {
			this.quickChatService.showQuickChat();
			this.chatSocketService.getSocket().emit('set-user-data', { username: this.currentUser.username, networkId: this.network.uid });

			this.chatSocketService.getSocket().on('broadcast', () => {
				this.initChat();
				this.socketEventsInitialized = true;
			});
		});
	}

	/**
	 * Initializing Chat
	 */
	private initChat(): void {
		// if(this.socketEventsInitialized){
		// 	return;
		// }
		this.chatSocketService.getSocket().removeAllListeners();

		this.chatSocketService.getSocket().on('connect_error', (socket: any) => {
			console.error('connect_error', socket);
		});

		this.chatSocketService.getSocket().on('connect_timeout', (socket: any) => {
			console.error('connect_timeout', socket);
		});

		this.chatSocketService.getSocket().on('reconnect', (socket: any) => {
			console.error('reconnect', socket);
		});

		this.chatSocketService.getSocket().on('reconnect_attempt', (socket: any) => {
			console.error('reconnect_attempt', socket);
		});

		this.chatSocketService.getSocket().on('reconnecting', (socket: any) => {
			console.error('reconnecting', socket);
		});

		this.chatSocketService.getSocket().on('reconnect_error', (socket: any) => {
			console.error('reconnect_error', socket);
		});

		this.chatSocketService.getSocket().on('reconnect_failed', (socket: any) => {
			console.error('reconnect_failed', socket);
		});

		if (this.chatSocketService.getSocket().ioSocket.connected) {
			this.chatSocketService.getSocket().emit('set-user-data', { username: this.currentUser.username, networkId: this.network.uid }, () => {});
		}

		this.chatSocketService.getSocket().on('connect', () => {
			timer(250).subscribe(() => console.log('[[-- USER SEEDING... --]]'));

			timer(750).subscribe(() => {
				this.chatSocketService.getSocket().emit('set-user-data', { username: this.currentUser?.username, networkId: this.network?.uid }, () => {});

				this.chatSocketService.getSocket().on('broadcast', (_data: any) => {
					console.log('[[-- BROADCAST --]]:', _data);
				});
			});
		});
		this.chatSocketService.getSocket().emit('set-user-data', { username: this.currentUser.username, networkId: this.network.uid });

		this.chatSocketService.getSocket().on('onlineStack', (members) => {
			this.onlineStack = members;
			// this.updateMemberStatus();
		});

		this.isConversationsLoading = true;

		this.chatSocketService.getSocket().on('set-conversation', (conv: any) => {
			// console.debug('set-conversation', conv);
			// this.offset = 0;
			// const dataChange = isEqual(this.selectedConversation, conv);
			// let flag = false;
			// if(this.selectedConversation !== null){
			// 	flag = this.selectedConversation?._id !== conv._id;
			// }else{
			// 	if(conv.sender){
			// 		flag = true;
			// 	}
			// }
			// if(this.selectedConversation?._id !== conv._id ) {
			// 	console.log(this.selectedConversation,conv);

			// this.selectedConversation = conv;
			// this.isConversationsLoading = true;
			// this.chatSocketService.getSocket().emit('join-conversation', {
			// 	conversationId: conv._id,
			// 	user: this.currentUser.username,
			// 	networkId: this.network.uid,
			// });

			// if(conv?.sender){
			// 	this.quickChatService.manageQuickChatState(true, this.selectedConversation);
			// }
			// this.chatSocketService.getSocket().emit('old-messages-init', {
			// 	conversationId: conv._id,
			// 	username: this.currentUser.username,
			// });
			// }
			this.offset = 0;
			this.selectedConversation = conv;
			this.chatSocketService.getSocket().emit('old-messages-init', {
				conversationId: conv._id,
				username: this.currentUser.username,
			});

		});

		// list of conversations listener
		this.chatSocketService.getSocket().on('old-conversations', (oldConversations: any) => {
			// const conversationHash = JSON.stringify(oldConversations);
			// if (this.conversationHash !== conversationHash) {
			// 	this.conversationHash = conversationHash;
			// 	this.conversations = this.alterConversations(oldConversations.result);
			// 	console.log('Conversations', this.conversations);

			// 	// if (!this.isFirstLoad) {
			// 	// 	this.isFirstLoad = true;

			// 	// 	if (this.conversations && this.conversations.length > 0) {
			// 	// 		timer(100).subscribe(() => {
			// 	// 			this.openConversation(this.conversations[0]);
			// 	// 		});
			// 	// 	}
			// 	// }

			// 	const selectedConversation = this.quickChatService.getConversationToShowInQuickChat();
			// 	console.log('selected conv', selectedConversation, !this.quickChatService.isQuickChatOpened);

			// 	if(selectedConversation && !this.quickChatService.isQuickChatOpened){
			// 		timer(100).subscribe(() => {
			// 			this.openConversation(selectedConversation);
			// 		});
			// 	}


			// }

			const conversations = cloneDeep(oldConversations.result);
			this.conversations = this.alterConversations(conversations);
			let unreadMessageCount = 0;
			conversations.forEach((conv) => {
				if (conv.unreadMessageCount) {
					unreadMessageCount += conv.unreadMessageCount;
				}
			});
			const isChatPage = this.currentUrl === '/chat/inbox';
			if(this.lastConversationsData && oldConversations.result && !isChatPage){
				const isDataChanged  = !isEqual(this.lastConversationsData,oldConversations.result);
				if (isDataChanged) {
					this.isFirstLoad = false;
					this.lastConversationsData = cloneDeep(oldConversations.result);
					if (!this.quickChatService.isQuickChatOpened && unreadMessageCount > 0) {
						this.quickChatService.manageQuickChatState(true, conversations[0]);
					}else if(this.minimizeChat && this.conversationView === false){
						this.quickChatService.manageQuickChatState(true, conversations[0]);
					}
				}
			}else{

				if(!this.networkService.getCurrentNetwork() || isChatPage){
					return;
				}
				// first load
				this.isFirstLoad = true;
				if( oldConversations.result.length > 0 ){
					this.quickChatService.manageQuickChatState(true, conversations[0]);
				}

				this.lastConversationsData = cloneDeep(oldConversations.result);
			}


			timer(500).subscribe(() => {
				this.isConversationsLoading = false;
				this.changeDetectorRef.detectChanges();
			});
		});

		this.chatSocketService.getSocket().on('old-messages', (messages: any) => {
			this.isMessageListLoading = true;
			if (this.offset) {
				const newMessages = messages.result.reverse(),
					allMessages = newMessages.concat(this.messages);

				this.messages = allMessages;
			} else {
				this.messages = messages.result.reverse();
			}

			this.reorderMessages();

			timer(300).subscribe(() => {
				this.isMessageListLoading = false;
				this.offset = messages?.offset;
				this.changeDetectorRef.detectChanges();
			});
		});

		// on typing listener
		this.chatSocketService.getSocket().on('typing', (user) => {
			if (this.currentUser.username !== user.username) {
				this.anotherTypingUser = user;
				this.isAnotherUserTyping = true;

				timer(2000).subscribe(() => {
					this.isAnotherUserTyping = false;
				});
			}
		});

		this.chatSocketService.getSocket().on('new-message', (data) => {
			if (this.currentUser.username !== data.result.sender.username) {
				this.chatSocketService.getSocket().emit('delivered', {
					user: this.currentUser.username,
					messageId: data.result._id,
				});

				if (this.selectedConversation && this.conversationView) {
					this.chatSocketService.getSocket().emit('read', {
						user: this.currentUser.username,
						messageId: data.result._id,
					});
				}
			}
			if (this.messages) {
				this.messages.push(data.result);
				this.reorderMessages();
			}
			// this.chatSocketService.getSocket().emit({});
			// this.openConversation(data);
			// const conversation = this.getConversationById(data.conversationId);
			// this.changeConversation(conversation);
		});

		this.chatSocketService.getSocket().on('read', (data) => {
			if (this.messages) {
				const index = this.messages.findIndex((value) => value._id === data._id);

				if (index > -1) {
					if (data.isUnread) {
						this.messages[index].isUnread = false;
					}
				}
			}
		});

		this.chatSocketService.getSocket().on('update-conversation', (data) => {
			this.selectedConversation = data;
			this.groupMembers = data.participants ? data.participants : [];
			this.userMentionList = [];
		});

		this.chatSocketService.getSocket().on('delete-message', (data) => {
			this.messages = this.messages.filter((c) => c._id !== data.messageId);
		});
	}

	/**
	 * Function to update the online status of members;
	 */
	private updateMemberStatus(): void {
		this.membershipList.forEach((member) => {
			if (!member['username']) {
				if (this.onlineStack[member.user.username]) {
					member['isOnline'] = this.onlineStack[member.user.username].isOnline;
				} else {
					member['isOnline'] = false;
				}
			} else {
				if (this.onlineStack[member.username]) {
					member['isOnline'] = this.onlineStack[member.username].isOnline;
				} else {
					member['isOnline'] = false;
				}
			}
		});
	}

	private updateConversationStatus(): void {
		this.conversations.forEach((conv) => {
			if (conv.lastMessage && conv.lastMessage?.sender && conv.lastMessage?.sender?.username !== this.currentUser?.username) {
				if (conv.lastMessage.deliveredTo.indexOf(this.onlineStack[this.currentUser.username]._id) === -1) {
					this.chatSocketService.getSocket().emit('delivered', {
						user: this.currentUser.username,
						messageId: conv.lastMessage._id,
					});
				}
			}

			let totalMessageCount;

			if (conv.unreadMessageCount) {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				totalMessageCount += conv.unreadMessageCount;
			}
		});
	}

	/**
	 * Alter the conversation list
	 *
	 * @param conversation any[]
	 * @return any[]
	 */
	private alterConversations(conversations: any[]): any[] {
		let totalMessageCount = 0;

		conversations = conversations.map((conversation: any) => {
			if (conversation.lastMessage && conversation.lastMessage?.sender?.username !== this.currentUser.username) {
				if (conversation.lastMessage.deliveredTo.indexOf(this.onlineStack[this.currentUser.username]?._id) === -1) {
					this.chatSocketService.getSocket().emit('delivered', {
						user: this.currentUser.username,
						messageId: conversation.lastMessage._id,
					});
				}
			}

			if (conversation.unreadMessageCount) {
				totalMessageCount += conversation.unreadMessageCount;
			}

			return {
				...conversation,
				// isOnline: this.onlineStack[conversation.members[0].username]?.isOnline
			};
		});

		this.chatSocketService.updateUnreadMessageCount(totalMessageCount);

		return conversations;
	}

	/**
	 * Open target conversation
	 *
	 * @param conversation any
	 * @return void
	 */
	public openConversation(conversation: any): void {
		if(this.isFirstLoad){
			this.conversationView = false;
			this.isFirstLoad = false;
		}else{
			this.conversationView = true;
		}
		this.isMessageListLoading = true;
		this.offset = -1;
		this.selectedConversation = conversation;
		this.offset = 0;
		this.groupMembers = conversation.participants || [];
		this.userMentionList = [];

		this.chatSocketService.getSocket().emit('join-conversation', {
			conversationId: conversation._id,
			user: this.currentUser.username,
			networkId: this.network.uid,
		});

		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Reorder Messages with consecutive key
	 */
	private reorderMessages(): void {
		const messagesArray = [];
		this.messages.forEach((_message) => {
			// Filtering system messages
			if (_message?.parts[0]?.mimeType !== 'system/message') {
				messagesArray.push(_message);
			}
			if (_message?.parts?.[0]?.body?.indexOf('<') > -1) {
				_message['parts'][0]['body'] = _message['parts'][0]['body'].replace(/</g, '< ');
			}
			if (_message?.parts?.[0]?.body?.includes('@')) {
				const message = _message.parts[0].body;
				const startIndex = message.lastIndexOf('@');
				const midIndex = message.indexOf(' ', startIndex);
				let result = '';
				let highlightedMessage = '';
				if (midIndex === -1) {
					result = message.substring(startIndex, message.length);
					highlightedMessage = message.substring(0, startIndex) + ' ' + `<b>${result}</b>`;
				} else {
					const endIndex = message.indexOf(' ', midIndex + 1);
					if (endIndex === -1) {
						result = message.substring(startIndex, message.length);
						highlightedMessage = message.substring(0, startIndex) + ' ' + `<b>${result}</b>`;
					} else {
						result = message.substring(startIndex, endIndex);
						highlightedMessage = message.substring(0, startIndex) + ' ' + `<b>${result}</b>` + ' ' + message.substring(endIndex, message.length);
					}
				}
				_message.parts[0].body = highlightedMessage;
				// console.log('Mention', highlightedMessage);
			}
		});

		messagesArray?.forEach((message, index) => {
			if (index === 0) {
				// Avoiding the first message
				message['consecutiveMessage'] = false;
			} else {
				// Set states for each message depending on previous message
				const currentMessageUsername = message?.sender?.username;
				const currentMessageDate = new Date(message?.updatedOn);
				const previousMessageUsername = messagesArray[index - 1]?.sender?.username;
				const previousMessageDate = new Date(messagesArray[index - 1]?.updatedOn);

				if (currentMessageUsername === previousMessageUsername) {
					if (format(currentMessageDate, 'dd-M-yy') === format(previousMessageDate, 'dd-M-yy')) {
						message['consecutiveMessage'] = true;
					} else {
						message['consecutiveMessage'] = false;
					}
				} else {
					message['consecutiveMessage'] = false;
				}
			}

			// For identifying group messages
			if (this.selectedConversation?.isGroup) {
				if (message['consecutiveMessage'] === false) {
					if (message?.sender?.username !== this.currentUser?.username) {
						message['groupMessage'] = true;
						const sender = message?.sender?.username;
						this.randomColor = this.getRandomColor();
						const obj = { sender: sender, color: this.randomColor },
							i = this.senders.findIndex((object) => object['sender'] === sender);

						if (i === -1) {
							this.senders.push(obj);
						}
					}
				}
			}

			// For identifying links
			const urlRegex =
				/(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;
			const strings = message?.parts[0]?.body?.match(urlRegex);
			if (strings && strings.length > 0) {
				strings.forEach((element) => {
					let url = element;
					if (!(element.indexOf('http://') === 0 || element.indexOf('https://') === 0 || element.indexOf('ftp://') === 0)) {
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						url = 'http://' + element;
					}
					message.parts[0]['minBody'] = message?.parts[0]?.body;
				});
			}
		});

		// Replacing with edited messages
		this.messages.map((element, i) => {
			if (messagesArray[i]?._id === element?._id) {
				element = messagesArray[i];
			}
		});

		// Set Colors for group message
		this.senders.forEach((sender) => {
			this.messages.forEach((message) => {
				if (sender?.sender === message?.sender?.username && message?.sender?.username !== this.currentUser?.username) {
					message.sender.color = sender?.color;
				}
			});
		});
	}

	/**
	 * Handle pagination for members
	 */
	public paginateParticipants(): void {
		if (this.nextMemberUrl) {
			this.chatService.paginate(this.nextMemberUrl).subscribe((data) => {
				if (data) {
					this.membershipList = this.membershipList.concat(data['objects']);

					if (data.next) {
						this.nextMemberUrl = data.next.split('alpha')[1];
					} else {
						this.nextMemberUrl = '';
					}
				}
				this.sortNetworkMembersAlphabetically();
			});
		}
	}

	/**
	 * Sort Members alphabetically for showing in add-conversation-window
	 */
	private sortNetworkMembersAlphabetically(): void {
		const sortedList = [];
		this.membershipList.forEach((member: any) => {
			const memberObject = {
				username: member?.user?.username,
				name: member?.user?.name,
				bioline: member?.bioLine ? member?.bioLine : '',
				formattedRole: member?.user?.formattedRole,
				isOnline: member?.isOnline ? member?.isOnline : false,
				image: member?.user?.images[0] ? member?.user?.images[0] : [],
			};
			sortedList.push(memberObject);
		});
		const contacts = sortedList.sort((a, b) => (a.name.slice(0, 1).toUpperCase() > b.name.slice(0, 1).toUpperCase() ? 1 : -1));

		const grouped = contacts.reduce((groups, contact) => {
			const letter = contact.name.charAt(0);

			groups[letter] = groups[letter] || [];
			groups[letter].push(contact);

			return groups;
		}, {});

		this.sortedMemberList = Object.keys(grouped).map((key) => ({ alphabet: key, members: grouped[key] }));
		this.isMemberListFetched = true;
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Generate Random Dark Color
	 * @returns Color
	 */
	private getRandomColor(): any {
		let color = '#';

		for (let i = 0; i < 3; i++) {
			color += ('0' + Math.floor((Math.random() * Math.pow(16, 2)) / 2).toString(16)).slice(-2);
		}

		return color;
	}

	/**
	 * Check For Mentions in message Text
	 * @param word Message Text
	 */
	private checkForMentions(word: string): void {
		const userMentionRegex = /(^|\s)@(\w*(?:\s*\w*))$/;
		if (userMentionRegex.test(word)) {
			const text = word.replace(/(^@)/g, '');

			const mentionList = [];
			this.groupMembers.forEach((member: any) => {
				if (member?.name.toLowerCase().includes(text.toLowerCase())) {
					mentionList.push(member?.name);
				}
			});

			if (mentionList?.length) {
				this.userMentionList = mentionList;
			} else {
				this.userMentionList = [];
			}
		}
	}

	/**
	 * Handle message Send Click
	 * @param messagePackage Message Package
	 */
	public onMessageSend(messagePackage: any): void {
		if (messagePackage?.type === 'text') {
			// eslint-disable-next-line spellcheck/spell-checker
			this.checkForMentions(messagePackage?.msg);
			// eslint-disable-next-line spellcheck/spell-checker
			this.messagePost(messagePackage?.msg, 'text');
		} else {
			// eslint-disable-next-line spellcheck/spell-checker
			this.messagePost(messagePackage?.msg);
		}
	}

	/**
	 * Message Post Functionality
	 * @param message Message
	 * @param type Type of message
	 */
	private messagePost(message: any, type = null): void {
		let body = message;
		let mimeType = 'text/plain';
		let loc = null;
		let url = null;
		let name = null;

		switch (type) {
			case 'location':
				loc = {
					lat: this.selectedLocation.latitude,
					long: this.selectedLocation.longitude,
				};
				mimeType = 'location/coordinates';
				break;
			case 'image':
				url = this.selectedImageUrl['url'];
				mimeType = this.selectedImageUrl['type'];
				break;
			case 'document':
				url = this.selectedDocument['url'];
				if (this.selectedDocument['type'] === 'pdf' || this.selectedDocument['type'] === 'application/pdf') {
					mimeType = 'pdf';
				} else {
					mimeType = 'doc';
				}
				name = this.selectedDocument['name'];
				break;
			default:
				body = body;
				mimeType = 'text/plain';
		}

		const data = {
			conversationId: this.selectedConversation._id,
			user: this.currentUser.username,
			parts: [{ body: body, loc: loc, url: url, mimeType: mimeType, name: name }],
		};

		if (this.replyConversation) {
			data['parent'] = this.replyConversation._id;
		}
		this.replyConversation = null;

		const layerData = {
			username: this.currentUser.username,
			conversation: this.selectedConversation._id,
		};

		if (body && mimeType === 'text/plain') {
			layerData['message'] = body;
			layerData['type'] = 'text';
		} else if (mimeType === 'location/coordinates') {``
			layerData['type'] = 'location';
		} else {
			layerData['type'] = 'image';
		}

		if (this.userMentionList?.length) {
			data['mentions'] = this.userMentionList;
		}
		this.chatService.sendLayerNotification(this.currentUser.username, layerData).subscribe(() => {
			this.userMentionList = [];
		});
		this.chatSocketService.getSocket().emit('new-message', data);

		timer(150).subscribe(() => {
			this.conversations.map((conversation: any) => {
				if (conversation?._id === this.selectedConversation?._id) {
					conversation.lastMessage = data;
				}
			});
		});
		// -----------------------------------------------------------------------------------------------------------
		// this.scrollToBottom();
		// const cachedMessages: any[] = localStorage.getItem('draft-messages') ? JSON.parse(localStorage.getItem('draft-messages')) : [];
		// this.itemMessage = '';
		// this.cacheMessage(this.selectedConversation);
	}

	public imageViewerSelector(image: any): void {
		this.selectedImages = [];
		const data = { image: { url: image } };
		this.selectedImages.push(data);
	}

	public deleteMessage(message: any): void {
		timer(150).subscribe(() => {
			this.deleteConfirmationModal = 'block';
			this.layoutService.showBd('comment-location');
			this.changeDetectorRef.detectChanges();
		});
		this.selectedMessage = message;
	}

	public replyMessage(message: any): void {
		this.replyConversation = message;
	}

	/**
	 * Close Modals
	 */
	public closeModal(): void {
		this.layoutService.hideBd();
		delete this.selectionType;
		delete this.selectedMessage;
		this.deleteConfirmationModal = 'none';
	}

	/**
	 * Functionality to delete message/conversation
	 */
	public onConfirmDeleteConfirmation(): void {
		this.chatSocketService.getSocket().emit('delete-message', { messageId: this.selectedMessage?._id });
		timer(100).subscribe(() => {
			if (this.selectedConversation?.lastMessage?._id === this.selectedMessage?._id) {
				this.conversations.map((conversation) => {
					if (conversation?._id === this.selectedConversation?._id) {
						conversation.lastMessage = this.messages.slice(-1);
					}
				});
			}
			this.closeModal();
		});
	}
	//	#endregion

	/**
	 * Attachment Bubble open Functionality
	 * @param event state
	 */
	public attachmentBubbleOpen(event: boolean): void {
		this.attachmentBubble = event;
	}

	// #region Event Emitter Attachments

	/**
	 * Attachment Event emitter handler
	 * @param event Attachment details
	 */
	public handleAttachments(event: any): void {
		timer(200).subscribe(() => {
			if (this.attachmentType && event) {
				switch (this.attachmentType) {
					case 'image':
						this.selectedImageUrl = event;
						this.messagePost(null, 'image');
						break;

					case 'location':
						this.selectedLocation = event;
						this.messagePost(null, 'location');
						break;
					case 'document':
						this.selectedDocument = event;
						this.messagePost(null, 'document');
						break;
				}
			}
			this.attachmentBubble = false;
		});
	}

	/**
	 * Attachment Type handler
	 * @param event Type
	 */
	public handleAttachmentType(type: any): void {
		this.attachmentType = type;
	}

	/**
	 * Message list pagination
	 * @param event Boolean
	 */
	public handleMessagePagination(event: any): void {
		if (event) {
			this.chatSocketService.getSocket().emit('load-more-messages', {
				conversationId: this.selectedConversation?._id,
				username: this.currentUser?.username,
				offset: this.offset,
			});
		}
	}

	// #endregion

	public setSearchSubject(event: any): void {
		this.isMemberListFetched = false;
		this.changeDetectorRef.detectChanges();
		this.membershipList = [];

		if (event.target.value) {
			this.subject.next(this.searchValue);
		} else {
			this.subject.next('');
		}
	}

	/**
	 * Member selection from add conversation window
	 * @param member
	 * @param type
	 */
	public memberSelect(member: any, type: 'personal' | 'group'): void {
		this.isConversationsLoading = true;
		this.isMessageListLoading = true;
		this.selectedConversation = null;
		if (type === 'personal' ) {
			let isNewChat = false;
			const conversation = this.conversations.find(c => c?.isGroup === false && c?.user?.username === member?.username);
			if(conversation){
				this.selectedConversation = conversation;
				this.quickChatService.manageQuickChatState(true, this.selectedConversation);
			}else{
				isNewChat = true;
				this.chatSocketService.getSocket().emit('join-conversation', {
					participants: [this.currentUser.username, member?.username],
					user: this.currentUser.username,
					conversationName: '',
					networkId: this.network.uid,
				});
			}

			timer(150).subscribe(() => {
				// delete this.conversations;
				this.isFirstLoad = false;
				this.isMessageListLoading = true;
				this.searchValue = '';
				this.subject.next('');
				if(!isNewChat){
					this.isConversationsLoading = false;
				}
				// this.switchChatViews();
			});
		}
	}

	/**
	 * Check Online Status for members
	 * @param user Username
	 * @returns Online/offline
	 */
	public isOnline(user): boolean {
		if (this.onlineStack[user]) {
			return this.onlineStack[user]?.isOnline;
		} else {
			return false;
		}
	}

	public getConversationById(cId: string): any{
		return this.conversations.find(c => c._id === cId);
	}

	public changeConversation(conversation): any{
		if(conversation){
			this.openConversation(conversation);
			// this.conversationView = true;
		}
	}

	public closeQuickChat(): void {
		this.minimizeChat = true;
		this.quickChatService.hideQuickChat();
	}

	public expandToChat(): void{
		this.redirectToChat();
		this.closeQuickChat();
	}
	private redirectToChat(): void {
		this.router.navigate(['/chat', 'inbox']);
	}


	ngOnDestroy(): void {
		// Disconnect the socket and remove all event listeners
		const socket = this.chatSocketService.getSocket();
		socket.removeAllListeners(); // This removes all event listeners
		socket.disconnect();

		this.openClose$?.unsubscribe();
		this.selectedConversation$?.unsubscribe();
		this.routerEventsSubscription?.unsubscribe();

	}
}
