export interface IEventAttachmentLocation {
	address?: string;
	title?: string;
	link?: string;
}

export enum IEventStatus {
	NOT_STARTED = 'NOT_STARTED',
	IN_PROGRESS = 'IN_PROGRESS',
	COMPLETED = 'COMPLETED',
}

export enum IEventType {
	MEETING = 'MEETING',
	ONLINE_MEETING = 'ONLINE_MEETING',
	EVENT = 'EVENT',
	CUSTOM = 'CUSTOM',
}

export interface IEventAttachment {
	title?: string;
	subtitle?: string;
	date?: string;
	location?: IEventAttachmentLocation;
	backgroundImageUrl?: string;
	startDate?: string;
	endDate?: string;
	isExpired?: boolean;
	isOnlineMeeting?: boolean;
	onlineMeetingUrl?: string;
	isOnlineMeetingLinked?: boolean;
	meetingAppLogoUrl?: string;
	rsvpYesCount?: number;
	meetingPassword?: string;
	status?: IEventStatus;
	eventButtonText?: string;
	eventType?: IEventType;
	data?: any;
}

export class EventAttachment implements IEventAttachment {
	public title: string = null;
	public subtitle: string = null;
	public date: string = null;
	public location: IEventAttachmentLocation = null;
	public backgroundImageUrl: string = null;
	public startDate: string = null;
	public endDate: string = null;
	public isExpired = false;
	public isOnlineMeeting = false;
	public onlineMeetingUrl: string = null;
	public isOnlineMeetingLinked = false;
	public meetingAppLogoUrl: string = null;
	public eventType: IEventType = IEventType.CUSTOM;
	public status?: IEventStatus = IEventStatus.NOT_STARTED;

	constructor(values: IEventAttachment = {}) {
		Object.assign(this, values);
	}
}
