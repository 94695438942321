import { Component, OnInit } from '@angular/core';
import { UserService, Comment, Post, NetworkService, PostService, CommentsService, LayoutService, Reminder, ProductService } from '../../shared';
import { ActivatedRoute } from '@angular/router';
import { ProfilesService } from '../../shared/services/profiles.service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';
import { ClipboardService } from 'projects/peer-core/src/lib/services/ui/clipboard.service';
import { LightboxService } from 'projects/peer-core/src/lib/services/ui/lightbox.service';
import { IImageAttachment, ImageAttachment } from 'projects/bee-attachment/src/lib/shared/models/image-attachment';

@Component({
	selector: 'app-issue-detail',
	templateUrl: './issue-detail.component.html',
	styleUrls: ['./issue-detail.component.css'],
})
export class IssueDetailComponent implements OnInit {
	public toggle = false;
	public hideCommentsHeading = false;
	public issue;
	public currentUser;
	public showMore = false;
	public showHide = false;
	public status;
	public network;
	public editSelectedPost: any;
	private networkSubscriber;
	private currentNetSub;
	public isFetching = 0;
	public uid;
	public numberOfDays;

	// Modals
	public deleteModal = 'none';
	public displayVersionModal = 'none';
	public displayInprogressModal = 'none';

	// Reminders
	public reminderOptions: any[] = [];
	public displayReminderModal = 'none';
	public reminderTime;
	public timeFor;
	public remindersSelected = [];
	public reminderModel = new Reminder();
	public reminderToast = false;

	// Reports
	public reportReasons: any[] = [];
	public reportModal = 'none';
	public flag = { value: '', text: '' };
	public reportPost;
	public reportText;
	public isReported = false;

	// issue actions
	public estimatedTime;
	public version;
	public error = false;
	public model = new Post(
		{
			title: null,
			body: null,
			state: '',
			type: null,
			status: null,
			shareable: true,
			commentable: true,
			category: null,
		},
		true
	);
	public comment = new Comment({ text: '', isLink: false, auto_generated: true }, true);
	public showCommentList = true;
	public postType: 'add-issue' | 'add-task' | '' = '';

	constructor(
		private networkService: NetworkService,
		private postService: PostService,
		private userService: UserService,
		private profileService: ProfilesService,
		private productService: ProductService,
		private commentsService: CommentsService,
		public activatedRoute: ActivatedRoute,
		private _location: Location,
		private translate: TranslateService,
		private layoutService: LayoutService,
		private pageLoadingUiService: PageLoadingUiService,
		private clipboardService: ClipboardService,
		private lightboxService: LightboxService
	) {}

	ngOnInit(): void {
		this.activatedRoute.queryParams.subscribe((params) => {
			if (params['id']) {
				this.uid = params['id'];
				this.getIssueDetail();
			}
		});

		this.networkSubscriber = this.networkService.networkReady.subscribe((networkLoaded) => {
			if (networkLoaded) {
				this.currentNetSub = this.networkService.currentNetwork.subscribe((network) => {
					this.network = network;
					this.currentUser = this.userService.getCurrentUser();
				});
			}
		});
	}

	// Function for getting details of issue
	public getIssueDetail(): void {
		this.isFetching = 0;
		this.networkService.getIssueDetail(this.uid).subscribe((data) => {
			this.issue = data;
			this.isFetching = 1;
			const date1 = new Date(data.openDate);
			const date2 = new Date();
			this.numberOfDays = this.dayDifference(date2, date1);
			this.postService.alterPostBodyAccordingToUserMentionAndHashtag(this.issue);
		});
	}

	// Back button in issue Detail page
	public onBackClick(): void {
		this._location.back();
	}

	/**
	 * Function for displaying estimate time modal for moveToInprogress action
	 */
	public showInProgressModal(): void {
		this.error = false;
		this.estimatedTime = '';
		this.displayInprogressModal = 'block';
		setTimeout(() => {
			document.getElementById('estimatedTime').focus();
		}, 50);
	}

	/**
	 * Function on confirming move to inprogress action
	 */
	public onConfirmInProgress(): void {
		if (this.estimatedTime) {
			this.handleIssueStateChange('in_progress');
		} else {
			this.error = true;
		}
	}

	/**
	 * Function on confirming version number for move to released action
	 */
	public onConfirmVersion(): void {
		if (this.version) {
			this.handleIssueStateChange('released');
		} else {
			this.error = true;
		}
	}

	/**
	 * Function for displaying version no: modal for move to released action
	 */
	public showReleasedModal(): void {
		this.error = false;
		this.version = '';
		this.displayVersionModal = 'block';
	}

	/**
	 * Function for handling issue state changes on move actions
	 * @param state
	 */
	public handleIssueStateChange(state): void {
		this.updateAttachmentDetails();
		this.pageLoadingUiService.setPageLoading(true);
		this.model.title = this.issue.title;
		this.model.state = this.issue.state;
		this.model.type = 'issue';
		this.model.status = state;
		this.model.shareable = this.issue.shareable;
		this.model.commentable = this.issue.commentable;
		this.model['collection_post'] = '1';
		this.model['estimate'] = this.estimatedTime;
		this.model['assignedUser'] = this.currentUser.username;
		this.model.category = 'bugs';
		this.comment.contentType = 'issue';
		this.comment.contentUid = this.issue.uid;

		if (this.issue.priority) {
			this.model['priority'] = this.issue.priority;
		}
		if (this.issue.description) {
			this.model['description'] = this.issue.description;
		}
		if (this.issue.type) {
			this.model['type'] = this.issue.type;
		}

		this.productService.updateIssue(this.model, this.issue.uid).subscribe((data) => {
			this.issue = data;
			this.postService.alterPostBodyAccordingToUserMentionAndHashtag(this.issue);
			this.reset();

			if (state === 'open') {
				this.comment['text'] = this.translate.instant('moveToOpenText', {
					opens: data.numberOfOpens,
				});
			} else if (state === 'pool') {
				this.comment['text'] = this.translate.instant('Move to Pool Notes: Issue is on hold');
			} else if (state === 'fixed') {
				this.comment['text'] = this.translate.instant('moveToFixedText', {
					developmentTime: data.developmentTime,
				});
			} else if (state === 'closed') {
				this.comment['text'] = this.translate.instant('moveToClosedText', {
					time: data.elapsedTime,
				});
			} else if (state === 'released') {
				this.comment['text'] = this.translate.instant('moveToReleasedText', {
					version: this.version,
				});
			} else {
				this.comment['text'] = this.translate.instant('moveToInProgressText', {
					user: this.currentUser.name,
					estimate: this.estimatedTime,
				});
			}

			this.showCommentList = false;
			this.commentsService.create(this.comment, this.currentUser.username).subscribe(() => {
				this.pageLoadingUiService.setPageLoading(false);
				this.showCommentList = true;
			});
		});
	}

	/**
	 * Pass attachment details on a post on issue actions
	 * @param issue
	 */
	private updateAttachmentDetails(): void {
		if (this.issue.attachmentDetails.gallery && this.issue.attachmentDetails.gallery.length > 0) {
			this.model['images'] = [];
			const images: any[] = [];
			this.issue.attachmentDetails.gallery.forEach((img) => {
				this.model.attachmentType = 'photo';
				images.push(img.image.uid);
			});
			this.model['images'] = images.join('|');
		}
		if (this.issue.attachmentDetails.location && this.issue.attachmentDetails.location.length > 0) {
			this.model.attachmentType = 'location';
			this.model['location'] = this.issue.attachmentDetails.location[0].uid;
		}
		if (this.issue.attachmentDetails.doc && this.issue.attachmentDetails.doc[0].uid) {
			this.model.attachmentType = 'doc';
			this.model['doc'] = this.issue.attachmentDetails.doc[0].uid;
		}
		if (this.issue.attachmentDetails.url && this.issue.attachmentDetails.url[0].url) {
			this.model.attachmentType = 'url';
			this.model['url'] = this.issue.attachmentDetails.url[0].url;
		}
	}

	/**
	 * Function for resetting attachments in issue model
	 */
	public reset(): void {
		this.resetModals();
		delete this.model['images'];
		delete this.model['attachmentType'];
		delete this.model['doc'];
		delete this.model['url'];
		delete this.model['location'];
		delete this.model['description'];
	}

	/**
	 * Function for closing and resetting issue action variables
	 */
	public resetModals(): void {
		this.displayInprogressModal = 'none';
		this.displayVersionModal = 'none';
		this.error = false;
	}

	// Show more and  hide in issue description
	public showMoreHide(): void {
		if (this.issue['showMore']) {
			this.issue['showMore'] = false;
		} else {
			this.issue['showMore'] = true;
		}
	}

	// Function to calculate no of days between 2 dates
	private dayDifference(d1, d2): number {
		const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;
		const timeDiff = Math.abs(d2.getTime() - d1.getTime());
		const diffDays = Math.ceil(timeDiff / MILLISECONDS_PER_DAY);
		return diffDays;
	}

	// Function for removing comments heading if comment lists is empty
	public checkCommentList(): void {
		if (this.issue['comments'].length === 0) {
			this.hideCommentsHeading = true;
		}
	}

	public newCommentCreatedEmitterFn(): void {}

	public getDownloadLink(attachment): string {
		return attachment['url'] + '?cType=' + attachment['type'] + '&file=' + attachment['name'];
	}

	public handleIssueLinkShare(): void {
		let text;

		if (this.issue['title'] && this.issue['description']) {
			text = `${this.issue.title.substring(0, 80)}.
        ${this.issue.description.substring(0, 110)}....
        ${this.issue.shareUrl}`;
		} else if (this.issue['title'] && !this.issue['description']) {
			text = `${this.issue.title.substring(0, 80)}.
        ${this.issue.shareUrl}`;
		} else if (!this.issue['title'] && this.issue['description']) {
			text = `${this.issue.description.substring(0, 110)}....
        ${this.issue.shareUrl}`;
		} else {
			text = this.issue.shareUrl;
		}

		this.clipboardService.copy(text);
	}

	public showInLightbox(): void {
		const images = this.issue.attachmentDetails?.gallery?.map((imageItem: any) => {
			const image = imageItem.image;

			return new ImageAttachment(<IImageAttachment>{
				id: image.uid,
				name: image.name,
				type: image.type,
				url: image.url,
				data: image,
			});
		});

		this.lightboxService.initLightbox(images);
	}

	public handleRedirections(e): void {
		if (e.target && e.target.className) {
			const type = e.target.className;
			if (type === 'hash-issue') {
				const text = e.target.innerHTML.split('#')[1];
				const hashtags = this.issue['hashtags'];
				hashtags.forEach((tag) => {
					if ((tag['name'] = text)) {
						// this.router.navigateByUrl('/network/reported-issues-detail?hash_tag=' + tag['uid'] + '&name=' + tag['name']);
					}
				});
			}
		}
	}

	public displayTaskCreate(): void {
		this.issue['addTaskForIssue'] = true;
		this.editSelectedPost = this.issue;
	}

	public onIssueEdit(): void {
		delete this.issue['addTaskForIssue'];
		this.editSelectedPost = this.issue;
		this.postService.editSelectedPost = this.issue;
	}

	public displayReminders(): void {
		this.displayReminderModal = 'block';
		this.reminderOptions = [];
		this.networkService.getReminderOptions().subscribe((data) => {
			this.reminderOptions = data.reminderOptions;
			if (this.remindersSelected.length > 0) {
				this.setPreviousReminders();
			}
		});
	}

	/**
	 * On selecting reminder options
	 * @param reminder
	 */
	public onSelectReminder(reminder): void {
		const index = this.remindersSelected.findIndex((r) => r === reminder);
		if (index > -1) {
			this.remindersSelected.splice(index, 1);
		} else {
			this.remindersSelected.push(reminder);
		}
	}

	/**
	 * For resetting reminders in reminder options modal
	 */
	public resetReminders(): void {
		this.displayReminderModal = 'none';
		this.timeFor = 0;
		this.remindersSelected = [];
		this.reminderOptions.forEach((r) => {
			r['checked'] = false;
		});
	}

	/**
	 * For creating a new reminders for content
	 */
	public setReminder(): void {
		if (this.remindersSelected.length > 0) {
			const object = {};
			this.remindersSelected.forEach((reminder) => {
				object[reminder] = this.setReminderDate(reminder);
			});
			const param = JSON.stringify(object);
			this.reminderModel.contentType = 'issue';
			this.reminderModel.contentUid = this.issue.uid;
			this.reminderModel.username = this.currentUser.username;
			this.reminderModel.repeat = true;
			this.reminderModel.reminderOption = param;
			this.pageLoadingUiService.setPageLoading(true);
			this.networkService.createReminder(this.reminderModel).subscribe((data) => {
				this.issue['myRelations']['reminder'] = data['uid'];
				this.pageLoadingUiService.setPageLoading(false);
				this.resetReminders();
				this.reminderToast = true;
				setTimeout(() => {
					this.reminderToast = false;
				}, 8000);
			});
		} else {
			if (this.issue['myRelations'] && this.issue['myRelations']['reminder']) {
				this.deleteReminders();
			} else {
				this.resetReminders();
			}
		}
	}

	public deleteReminders(): void {
		this.reminderModel.contentUid = this.issue.uid;
		this.reminderModel.username = this.currentUser.username;
		this.reminderModel.repeat = true;
		this.reminderModel.contentType = 'issue';
		this.pageLoadingUiService.setPageLoading(true);
		this.networkService.createReminder(this.reminderModel).subscribe(() => {
			this.issue['myRelations']['reminder'] = '';
			this.pageLoadingUiService.setPageLoading(false);
			this.resetReminders();
		});
	}

	/**
	 * For calculating corresponding to reminder selected
	 * @param option
	 */
	public setReminderDate(option): string {
		if (option === 'in_twenty_minutes') {
			this.timeFor = 20;
		} else if (option === 'in_one_hour') {
			this.timeFor = 60;
		} else if (option === 'in_three_hours') {
			this.timeFor = 180;
		} else if (option === 'tomorrow') {
			this.timeFor = this.minutesUntilMidnight() + 540;
		} else {
			this.timeFor = 6300;
		}
		const date = new Date();
		date.setMinutes(date.getMinutes() + this.timeFor);
		const month = date.getUTCMonth() + 1;
		const day = date.getUTCDate();
		const year = date.getUTCFullYear();
		const hours = date.getUTCHours();
		const minutes = date.getUTCMinutes();
		const seconds = date.getUTCSeconds();
		const utcDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
		return utcDate;
	}

	/**
	 * Get previous reminders of content
	 */
	public getReminderDetails(): void {
		this.pageLoadingUiService.setPageLoading(true);
		this.networkService.getContentReminders('issues', this.issue['uid']).subscribe((data) => {
			this.pageLoadingUiService.setPageLoading(false);
			this.displayReminders();
			if (data.objects && data.objects.length > 0) {
				data.objects.forEach((element) => {
					if (element['reminderOption']) {
						this.remindersSelected.push(element['reminderOption']);
					}
				});
			}
			this.setPreviousReminders();
		});
	}

	/**
	 * Set Previous reminders on reminder options
	 */
	private setPreviousReminders(): void {
		this.reminderOptions.forEach((reminder) => {
			this.remindersSelected.forEach((r) => {
				if (r === reminder[0]) {
					reminder['checked'] = true;
				}
			});
		});
	}

	private minutesUntilMidnight(): any {
		const midnight = new Date();
		midnight.setHours(24);
		midnight.setMinutes(0);
		midnight.setSeconds(0);
		midnight.setMilliseconds(0);
		return (midnight.getTime() - new Date().getTime()) / 1000 / 60;
	}

	public likeIssue(): void {
		this.postService.like(this.currentUser.username, this.issue).subscribe((data) => {
			this.issue.myRelations = { like: data.uid };
			if (this.issue['myRelations'] && this.issue['myRelations'].like && this.issue['likesCount']['count'] === 0) {
				this.issue['likedText'] = this.translate.instant('You liked this');
			}
		});
	}

	public dislikeIssue(): void {
		this.postService.dislike(this.currentUser.username, this.issue.myRelations.like).subscribe(() => {
			this.issue.myRelations = { like: null };
			this.issue['likedText'] = '';
			this.issue['likesCount']['count'] = 0;
		});
	}

	/**
	 * Function to mute an issue
	 * @param issue
	 */
	public onMuteIssue(): void {
		this.pageLoadingUiService.setPageLoading(true);
		this.productService.muteIssue(this.issue['uid']).subscribe((data) => {
			this.pageLoadingUiService.setPageLoading(false);
			if (data.uid) {
				this.issue['myRelations']['muted'] = data.uid;
			}
		});
	}

	/**
	 * Function to unmute an issue
	 * @param issue
	 * @param muteId
	 */
	public onUnMuteIssue(muteId): void {
		this.pageLoadingUiService.setPageLoading(true);
		this.productService.unMuteIssue(muteId).subscribe(
			() => {
				this.issue['myRelations']['muted'] = '';
				this.pageLoadingUiService.setPageLoading(false);
			},
			() => {
				this.issue['myRelations']['muted'] = '';
				this.pageLoadingUiService.setPageLoading(false);
			}
		);
	}

	/**
	 * Function on confirming issue delete
	 */
	public onDeleteConfirm(): void {
		this.pageLoadingUiService.setPageLoading(true);
		this.productService.deleteIssue(this.issue['uid']).subscribe(() => {
			this.deleteModal = 'none';
			this.pageLoadingUiService.setPageLoading(false);
			this._location.back();
		});
	}

	/**
	 * Function for showing report options in modal
	 * @param post
	 */
	public handlePostReport(): void {
		this.isReported = false;
		if (this.issue['myRelations'] && !this.issue['myRelations']['flagged']) {
			this.reportModal = 'block';
			this.flag['value'] = '';
			this.flag['text'] = '';
			this.reportReasons = [];
			this.profileService.getReasons().subscribe((data) => {
				if (data.post) {
					this.reportReasons = Object.entries(data.post);
				}
			});
		} else {
			this.reportText = 'You have reported already';
			this.isReported = true;
			setTimeout(() => {
				this.isReported = false;
			}, 7000);
		}
	}

	/**
	 * Function for handling report actions
	 */
	public onPostReport(): void {
		if (this.flag['value']) {
			this.pageLoadingUiService.setPageLoading(true);
			const obj = {
				contentType: 'issue',
				contentUid: this.issue['uid'],
				reason: this.flag['value'],
				text: this.flag['text'],
			};

			this.networkService.reportAnAction(obj).subscribe(
				(data) => {
					this.issue.myRelations['flagged'] = data.uid;
					this.resetReports();
					this.reportText = 'Your feedback is reported successfully';
					this.isReported = true;
					this.pageLoadingUiService.setPageLoading(false);
					setTimeout(() => {
						this.isReported = false;
					}, 7000);
				},
				() => {
					this.resetReports();
					this.pageLoadingUiService.setPageLoading(false);
				}
			);
		}
	}

	/**
	 * Function for resetting reports modal
	 */
	public resetReports(): void {
		this.flag['value'] = '';
		this.flag['text'] = '';
		this.reportReasons = [];
		this.reportModal = 'none';
	}

	/**
	 * Function to show the add task or add issue form
	 * @param $event
	 */
	public onClickMoreOptionsEmitterFn($event): void {
		this.postType = $event.type;
		this.postService.onClickMoreOptionsFromComments.emit($event);
		setTimeout(() => {
			this.layoutService.hideBd();
			this.layoutService.showBd('add-issue');
			window.scroll(0, 0);
		}, 200);
	}
}
