import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CollectionsComponent } from './collections.component';
import { SharedModule } from '../shared';
import { AgmCoreModule } from '@agm/core';
import { CollectionDetailComponent } from './collection-detail/collection-detail.component';
import { CreateCollectionComponent } from './create-collection/create-collection.component';
import { PostModule } from '../post/post.module';
import { ArchivedCollectionsComponent } from './archived-collections/archived-collections.component';

@NgModule({
	imports: [BrowserModule, AgmCoreModule, SharedModule, PostModule],
	declarations: [CollectionsComponent, CollectionDetailComponent, CreateCollectionComponent, ArchivedCollectionsComponent],
	bootstrap: [CollectionsComponent],
	providers: [],
})
export class CollectionModule {}
