import { Injectable } from '@angular/core';

@Injectable()
export class TimeZoneService {
	constructor() {}

	public getTimeZone(): string {
		let offset = new Date().getTimezoneOffset();
		let isNegative = false;

		if (offset < 0) {
			offset = -1 * offset;
			isNegative = true;
		}

		let hoursString = Math.trunc(offset / 60).toString();
		let minutesString = (offset % 60).toString();

		switch (hoursString.length) {
			case 1:
				hoursString = '0' + hoursString;
				break;
			default:
				hoursString = hoursString;
		}

		switch (minutesString.length) {
			case 1:
				minutesString = '0' + minutesString;
				break;
			default:
				minutesString = minutesString;
		}

		if (isNegative) {
			return '+' + hoursString + minutesString;
		} else {
			return '-' + hoursString + minutesString;
		}
	}
}
