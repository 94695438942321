import { ToastService } from './../shared/services/common/toast.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NetworkService, User, UserService } from '../shared';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-notification-control',
	templateUrl: './notification-control.component.html',
	styleUrls: ['./notification-control.component.css'],
})
export class NotificationControlComponent implements OnInit, OnDestroy {
	public selectedNotificationText: string;
	public muteID: string;
	public data: any = { notification_method: [], notification_type: 'all' };
	public telephone = false;
	public email = false;
	private network: any;
	private currentUser: User;
	private networkSubscriber: Subscription;
	private currentNetSub: Subscription;

	constructor(
		private router: Router,
		private networkService: NetworkService,
		private translate: TranslateService,
		private toastService: ToastService,
		private userService: UserService,
		private pageLoadingUiService: PageLoadingUiService
	) {}

	ngOnInit(): void {
		this.networkSubscriber = this.networkService.networkReady.subscribe((networkLoaded) => {
			if (networkLoaded) {
				this.currentNetSub = this.networkService.currentNetwork.subscribe((network) => {
					this.network = network;
					this.muteID = network?.membership?.muted;
				});
			}
		});

		this.userService.currentUser.subscribe((user: User) => {
			this.currentUser = user;
		});

		if (this.muteID) {
			this.pageLoadingUiService.setPageLoading(true);

			this.networkService.getMuteSettings(this.muteID, this.network?.uid).subscribe((data: any) => {
				this.pageLoadingUiService.setPageLoading(false);

				this.data['notification_type'] = data?.notificationType;
				this.data['notification_method'] = data?.notificationMethod ? data?.notificationMethod : [];

				if (this.data.notification_type === 'all') {
					this.selectedNotificationText = this.translate.instant('You will be notified for every new content');
				} else if (this.data.notification_type === 'only_me') {
					this.selectedNotificationText = this.translate.instant('You will be notified only when the content is related to you');
				} else if (this.data.notification_type === 'none') {
					this.selectedNotificationText = this.translate.instant('You wont receive any notifications');
				} else {
					this.selectedNotificationText = this.translate.instant('You will be notified for every new content');
				}

				this.data?.notification_method?.forEach((_data: string) => {
					if (_data === 'email') {
						this.email = true;
					} else if (_data === 'phone') {
						this.telephone = true;
					}
				});
			});
		} else {
			this.selectedNotificationText = this.translate.instant('You will be notified for every new content');

			this.pageLoadingUiService.setPageLoading(false);
		}
	}

	public setNotificationType(value: string): void {
		this.data.notification_type = value;

		if (value === 'all') {
			this.selectedNotificationText = this.translate.instant('You will be notified for every new content');
		} else if (value === 'only_me') {
			this.selectedNotificationText = this.translate.instant('You will be notified only when the content is related to you');
		} else {
			this.selectedNotificationText = this.translate.instant('You wont receive any notifications');
		}
	}

	public setNotificationMethod(value: 'phone' | 'email'): void {
		if (value === 'phone' ? this.telephone : this.email) {
			const index = this.data.notification_method.findIndex((val: any) => val === value);
			this.data.notification_method?.splice(index, 1);
		} else if (!this.data.notification_method?.includes(value) || this.data?.notification_method?.length === 0) {
			this.data.notification_method?.push(value);
		}
	}

	public saveSettings(): void {
		this.pageLoadingUiService.setPageLoading(true);
		this.data['network_uid'] = this.network?.uid;

		this.networkService
			.updateNotificationSettings(this.data, this.currentUser?.username, this.muteID)
			.subscribe(() => {
				this.toastService.success(this.translate.instant('Notification settings saved successfully'), {
					duration: 5000,
				});

				this.router.navigateByUrl('/network');
			})
			.add(() => {
				this.pageLoadingUiService.setPageLoading(false);
			});
	}

	public cancelForm(): void {
		this.router.navigateByUrl('/network');
	}

	ngOnDestroy(): void {
		this.networkSubscriber?.unsubscribe();
		this.currentNetSub?.unsubscribe();
	}
}
