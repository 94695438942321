import { Component, OnDestroy, OnInit } from '@angular/core';
import { NetworkService, UserService } from '../../shared';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-points',
	templateUrl: './points.component.html',
	styleUrls: ['./points.component.css'],
})
export class PointsComponent implements OnInit, OnDestroy {
	public pointsForm: FormGroup;
	public points = [];
	private pointsSubscriber;
	public fetchingPoints = false;
	public query = '';
	public displayAddPoints = 'none';
	public currentUser;
	public user;
	public teamMembers = [];
	public networkLoaded;
	public network;
	public pointsExceeded = false;

	public appLogo = environment.logo;

	constructor(
		private fb: FormBuilder,
		private userService: UserService,
		private router: Router,
		private networkService: NetworkService,
		private pageLoadingUiService: PageLoadingUiService
	) {
		this.pointsForm = this.fb.group({
			points: ['', [Validators.required, Validators.min(1), Validators.max(200)]],
			description: ['', Validators.required],
			memberUid: [''],
		});
	}

	ngOnInit(): void {
		if (localStorage.getItem('networkExpired')) {
			this.router.navigateByUrl('/expired');
		}

		this.userService.currentUser.subscribe((userData) => {
			this.currentUser = userData;
		});

		// this.getPointRewards();
		this.filterPointRewards('Daily');
	}

	ngOnDestroy(): void {
		if (this.pointsSubscriber) {
			this.pointsSubscriber.unsubscribe();
		}
	}

	/**
	 * Function to get points rewards
	 */
	private getPointRewards(): void {
		this.fetchingPoints = false;
		this.points = [];
		this.query = '';
		const query = '';
		this.pointsSubscriber = this.networkService.getPointsRewards(query).subscribe(
			(data) => {
				this.getMembersOfManager();
				this.fetchingPoints = true;
				this.points = data.objects;
				this.calculatePoints();
			},
			() => {
				this.fetchingPoints = true;
			}
		);
	}

	/** *
	 * Function to filter points
	 * @param query
	 */
	public filterPointRewards(query): void {
		this.query = query;
		this.fetchingPoints = false;
		this.points = [];
		this.pointsSubscriber = this.networkService.getPointsRewards(query).subscribe(
			(data) => {
				this.fetchingPoints = true;
				this.points = data.objects;
				this.getMembersOfManager();
				this.calculatePoints();
			},
			() => {
				this.fetchingPoints = true;
			}
		);
	}

	/**
	 * Function to calculate ranks from each point objects
	 */
	private calculatePoints(): void {
		this.points.forEach((point) => {
			if (point['previousRank'] && point['currentRank']) {
				const rank = point['previousRank'] - point['currentRank'];
				point['rank'] = rank;
			}
		});
	}

	public handlePointsModal(point): void {
		event.stopPropagation();
		if (this.teamMembers.length > 0) {
			this.teamMembers.forEach((member) => {
				if (member['username'] === point['username']) {
					this.user = point;
					this.displayAddPoints = 'block';
				}
			});
		}
	}

	public addPointsToMember(): void {
		this.pointsForm.markAllAsTouched();
		if (this.pointsForm.valid) {
			this.pointsForm.controls.memberUid.setValue(this.user['uid']);
			this.pageLoadingUiService.setPageLoading(true);
			this.networkService.assignPoints(this.pointsForm.value).subscribe(
				() => {
					this.getPointRewards();
					this.reset();
					this.pageLoadingUiService.setPageLoading(false);
				},
				() => {
					this.pageLoadingUiService.setPageLoading(false);
				}
			);
		}
	}

	public reset(): void {
		this.displayAddPoints = 'none';
		this.pointsForm.reset();
	}

	private manageTextBox(e): void {
		e.target.style.height = e.target.scrollHeight + 'px';
	}

	public onKeyDown(e: any): boolean {
		if (!((e.keyCode > 95 && e.keyCode < 106) || (e.keyCode > 47 && e.keyCode < 58) || e.keyCode === 8)) {
			return false;
		}
	}

	private getMembersOfManager(): void {
		this.networkService.getTeamMembers().subscribe((data) => {
			if (data['teamMembers']) {
				this.teamMembers = data['teamMembers'];
				if (this.teamMembers.length > 0 && this.points.length > 0) {
					this.points.forEach((point) => {
						this.teamMembers.forEach((member) => {
							if (member['username'] === point['username']) {
								point['teamMember'] = true;
							}
						});
					});
				}
			}
		});
	}

	public onImgError(event): void {
		event.target.src = 'assets/images/default_avatar.jpg';
	}

	/**
	 * Function to show point detail
	 * @param point
	 */
	public showPointsDetail(point): void {
		if (point.teamMember || this.currentUser.username === point.username) {
			this.router.navigateByUrl('/network/point-detail/' + point.uid);
		}
	}
}
