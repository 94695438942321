export enum JoinWorkspaceSteps {
	SearchWorkspace = 1,
	DepartmentSelection = 2,
	TitleSelection = 3,
}

export enum CreateWorkspaceSteps {
	WorkspaceName = 1,
	IndustrySelection = 2,
	DepartmentSelection = 3,
	UserTitle = 4,
	AppSurvey = 5,
}
