import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AlphaService } from '../alpha.service';
import { HttpParams } from '@angular/common/http';
import { IWorkspaceDepartmentResponse } from '@shared/models/workspace/department';

@Injectable({
	providedIn: 'root',
})
export class WorkspaceService {
	constructor(private alphaService: AlphaService) {}

	/**
	 * Get selected workspace
	 *
	 * @param uuid selected workspace id
	 * @returns Observable<any>
	 */
	public getWorkspace(uuid: string): Observable<any> {
		return this.alphaService.get(`/networks/${uuid}/`);
	}

	/**
	 * Search in workspaces
	 *
	 * @param query search query
	 * @param type workspace type
	 * @returns Observable<any>
	 */
	public searchInWorkspaces(query: string, type: string): Observable<any> {
		return this.alphaService.get(`/networks/search/?query=${query}&type=${type}`);
	}

	/**
	 * Get all workspaces of the current user
	 *
	 * @param username username of the current user
	 * @returns Observable<any>
	 */
	public getAllWorkspacesOfUser(username: string, offset: number, limit: number = 10): Observable<any> {
		// we are removing the app id from the request to get the all workspaces
		const params = new HttpParams().set('__includeAppId', true);

		return this.alphaService.get(`/users/${username}/memberships/?offset=${offset}&limit=${limit}&x=e`, params);
	}

	/**
	 * Get depertments of selected workspace
	 *
	 * @param workspaceId selected workspace id
	 * @param offset offset
	 * @param limit limit
	 * @returns Observable<IWorkspaceDepartmentResponse>
	 */
	public getWorkspaceDepartments(workspaceId: string, offset: number, limit: number = 50): Observable<IWorkspaceDepartmentResponse> {
		return this.alphaService.get(`/networks/${workspaceId}/departments/?offset=${offset}&limit=${limit}`);
	}
}
