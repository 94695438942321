import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ListErrorsComponent } from './list-errors.component';
import { ShowAuthedDirective } from './show-authed.directive';
import { NgxIntlTelInputComponent } from './ngx-intl-tel-input.component';
import { NgxIntlTelInputService } from './services/ngx-intl-tel-input.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
export * from './ngx-intl-tel-input.component';
export * from './services/ngx-intl-tel-input.service';
import { TranslateModule } from '@ngx-translate/core';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TimeAgoPipe, ProfileImagePipe, RoundPipe, ReplaceUnderscorePipe, ReplaceTextlinePipe } from './shared.pipe';
import { EventFormComponent } from './event-form/event-form.component';
import { ClassifiedFormComponent } from './classified-form/classified-form.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { SidebarComponent } from './layout/sidebar.component';
import { PostListComponent } from './layout/post-list.component';
import { AgmCoreModule } from '@agm/core';
import { PrivacyComponent } from './privacy/privacy.component';
import { JoinNetworkFormsComponent } from './join-network-forms/join-network-forms.component';
import { NetworkSettingsComponent } from './network-settings/network-settings.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { PostCommentComponent } from './layout/comments/post-comment/post-comment.component';
import { PostEditComponent } from './layout';

import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from './services';
import { HttpClientModule } from '@angular/common/http';
import { PushNotificationComponent } from './push-notification/push-notification.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { DueDateComponent } from './due-date/due-date.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NewCommentListComponent } from './layout/comments/new-comment-list/new-comment-list.component';
import { CommentIssueAddTaskComponent } from './comment-issue-add-task/comment-issue-add-task.component';
import { UserActivityLogComponent } from './user-activity-log/user-activity-log.component';
import { AttendingUsersModalComponent } from './components/event/attending-users-modal/attending-users-modal.component';

import { MatTabsModule } from '@angular/material/tabs';
import { LoadingGifComponent } from './components/common/loading-gif/loading-gif.component';
import { SuccessAnimationComponent } from './components/common/success-animation/success-animation.component';
import { PlusButtonComponent } from './components/common/buttons/plus-button/plus-button.component';
import { StartEndDayService } from './services/home/start-end-day.service';
import { ToastService } from './services/common/toast.service';
import { DailyStatsComponent } from './layout/components/daily-stats/daily-stats.component';
import { FocusTimeModalComponent } from './components/network/daily-message-widget/focus-time-modal/focus-time-modal.component';
import { NoMeetingDayModalComponent } from './components/network/daily-message-widget/no-meeting-day-modal/no-meeting-day-modal.component';
import { ScheduleOToOMeetingModalComponent } from './components/network/daily-message-widget/schedule-o-to-o-meeting-modal/schedule-o-to-o-meeting-modal.component';
import { YoutubePlayerModalComponent } from './components/network/daily-message-widget/youtube-player-modal/youtube-player-modal.component';
import { LottieModule } from 'ngx-lottie';
import { FilterMenuComponent } from './components/filter-menu/filter-menu.component';
import { MatRadioModule } from '@angular/material/radio';
import { OverlayModule } from '@angular/cdk/overlay';
import { AssigneeLabelComponent } from './components/task/assignee-label/assignee-label.component';
import { UserAvatarComponent } from './components/common/user-avatar/user-avatar.component';
import { BeeUiModule } from 'projects/bee-ui/src/public-api';
import { PostCreateFocusCardComponent } from './components/post/post-create-focus-card/post-create-focus-card.component';
import { ContextMenuModule, ContextMenuService } from '@perfectmemory/ngx-contextmenu';
import { NewDailyMessagesWidgetComponent } from './components/post/new-daily-messages-widget/new-daily-messages-widget.component';
import { BeeAttachmentModule } from 'projects/bee-attachment/src/public-api';
import { NprogressModule } from 'projects/bee-progress/src/lib/modules/nprogress/nprogress.module';
import { BeeLabelModule } from 'projects/bee-ui/src/lib/modules/label/label.module';
import { OutcomeComponent } from './components/common/outcome/outcome.component';
import { PeerCoreModule } from 'projects/peer-core/src/public-api';
import { BeeAvatarModule } from 'projects/bee-ui/src/lib/modules/avatar/avatar.module';
import { NewProjectUpdatePostComponent } from './components/post/new-project-update-post/new-project-update-post.component';
import { HeaderComponent } from './layout/partials/header/header.component';
import { NewTeamDashboardComponent } from './components/post/new-team-dashboard/new-team-dashboard.component';
import { NewSectionMilestonePostComponent } from './components/post/new-section-milestone-post/new-section-milestone-post.component';
import { NewPostFilterMenuComponent } from './components/post/new-post-filter-menu/new-post-filter-menu.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { PlaceholderLottieComponent } from './components/common/placeholder-lottie/placeholder-lottie.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { CustomSelectInputModule } from './custom-select-input/custom-select-input.module';

@NgModule({
	imports: [CommonModule, FormsModule, BsDropdownModule.forRoot(), NgbModule],
	declarations: [NgxIntlTelInputComponent],
	exports: [NgxIntlTelInputComponent],
})
export class NgxIntlTelInputModule {
	public static forRoot(): ModuleWithProviders<any> {
		return {
			ngModule: NgxIntlTelInputModule,
			providers: [NgxIntlTelInputService],
		};
	}
}

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		RouterModule,
		AgmCoreModule,
		TranslateModule,
		NgbModule,
		InfiniteScrollModule,
		TimepickerModule,
		NgxMaterialTimepickerModule,
		CalendarModule.forRoot({
			provide: DateAdapter,
			useFactory: adapterFactory,
		}),
		NgbDropdownModule,
		MatTabsModule,
		LottieModule,
		MatRadioModule,
		OverlayModule,
		BeeUiModule,
		ContextMenuModule,
		BeeAttachmentModule,
		BeeLabelModule,
		BeeAttachmentModule,
		NprogressModule,
		PeerCoreModule,
		BeeAvatarModule,
		OverlayModule,
		NzToolTipModule,
		CustomSelectInputModule
	],
	declarations: [
		TimeAgoPipe,
		ProfileImagePipe,
		RoundPipe,
		SafeHtmlPipe,
		ReplaceUnderscorePipe,
		ReplaceTextlinePipe,
		ListErrorsComponent,
		HeaderComponent,
		FileUploadComponent,
		ShowAuthedDirective,
		SidebarComponent,
		PostListComponent,
		PrivacyComponent,
		DueDateComponent,
		JoinNetworkFormsComponent,
		NetworkSettingsComponent,
		EventFormComponent,
		ClassifiedFormComponent,
		UserProfileComponent,
		PostCommentComponent,
		PostEditComponent,
		NewCommentListComponent,
		PushNotificationComponent,
		CommentIssueAddTaskComponent,
		UserActivityLogComponent,
		AttendingUsersModalComponent,

		LoadingGifComponent,
		SuccessAnimationComponent,
		PlusButtonComponent,
		PlaceholderLottieComponent,
		// StartEndDayComponent, // currently not in use
		DailyStatsComponent,
		FocusTimeModalComponent,
		NoMeetingDayModalComponent,
		ScheduleOToOMeetingModalComponent,
		YoutubePlayerModalComponent,
		FilterMenuComponent,
		AssigneeLabelComponent,

		UserAvatarComponent,
		PostCreateFocusCardComponent,
		NewDailyMessagesWidgetComponent,
		OutcomeComponent,
		NewProjectUpdatePostComponent,
		NewTeamDashboardComponent,
		NewSectionMilestonePostComponent,
		NewPostFilterMenuComponent,
	],
	exports: [
		TimeAgoPipe,
		ReplaceUnderscorePipe,
		ReplaceTextlinePipe,
		SafeHtmlPipe,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		RouterModule,
		ListErrorsComponent,
		ShowAuthedDirective,
		ProfileImagePipe,
		RoundPipe,
		TranslateModule,
		HeaderComponent,
		FileUploadComponent,
		SidebarComponent,
		PostListComponent,
		AgmCoreModule,
		PrivacyComponent,
		DueDateComponent,
		JoinNetworkFormsComponent,
		UserProfileComponent,
		PostCommentComponent,
		NgbModule,
		TimepickerModule,
		OverlayModule,
		PostEditComponent,
		PushNotificationComponent,
		InfiniteScrollModule,
		NewCommentListComponent,
		CommentIssueAddTaskComponent,
		UserActivityLogComponent,
		AttendingUsersModalComponent,

		LoadingGifComponent,
		SuccessAnimationComponent,
		PlusButtonComponent,
		PlaceholderLottieComponent,
		FilterMenuComponent,
		AssigneeLabelComponent,
		UserAvatarComponent,
		PostCreateFocusCardComponent,
		NewDailyMessagesWidgetComponent,
		OutcomeComponent,
		BeeAvatarModule,
		NewProjectUpdatePostComponent,
		NewTeamDashboardComponent,
		NewSectionMilestonePostComponent,
	],
	providers: [EventService, StartEndDayService, ToastService, ContextMenuService],
})
export class SharedModule {}
