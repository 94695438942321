import { IPaginatedUI } from './base/paginated-data-ui';

export interface ISectionUI {
	nextPageUrl?: string | boolean | null;
	isNextPageLoading?: boolean;
	isLoading?: boolean;
	subTasksCount?: number;
	tasksScrollDisabled?: boolean;
	offset?: number;

	resetPagination?(): void;
}

export interface ISectionData extends IPaginatedUI {
	count?: number;
	next?: string;
	previous?: null;
	offset?: number;
	objects?: ISection[];
}

export interface ISection extends ISectionUI {
	uid?: string;
	network?: string;
	isEditLoading?: boolean;
	tasks?: any[];
	toggle?: boolean;
	isEditing?: boolean;
	totalCount?: number;
	name?: string;
	user?: any;
	created?: string;
	todo?: null;
	projectCount?: number;
	subTaskCount?: number;
	todolistCount?: number;
	order?: number;
	openCount?: number;
	closedCount?: number;
	userCount?: number;
	titleCount?: number;
	originalName?: string;
	isTodosLoading?: boolean;
}

export class Section implements ISection {
	public uid: string | null = null;
	public network: string = localStorage.getItem('network_id');
	public name: string;
	public todo: any;
	public project: any;
	public isEditLoading = false;
	public tasks: any[] = [];
	public toggle = false;
	public isEditing = false;
	public totalCount = 0;
	public offset = 0;

	constructor(values: Object = {}) {
		Object.assign(this, values);
	}

	public resetPagination(): void {
		this.tasks = [];
		this.offset = 0;
	}
}
