import { Component, EventEmitter, OnInit, Output } from '@angular/core';

export interface IReaction {
	id: number;
	name: string;
	selected: boolean;
	image: string;
}

@Component({
	selector: 'app-task-feedback',
	templateUrl: './task-feedback.component.html',
	styleUrls: ['./task-feedback.component.scss']
})
export class TaskFeedbackComponent implements OnInit {
	@Output() public closeButtonClicked = new EventEmitter<void>();
	@Output() public selectedReaction = new EventEmitter<IReaction>();

	public reactions = [
		{
			id:1,
			name: 'sad',
			selected: false,
			image: 'emoji.png'
		},
		{
			id:2,
			name: 'neutral',
			selected: false,
			image: 'emoji-1.png'
		},
		{
			id:3,
			name: 'happy',
			selected: true,
			image: 'emoji-2.png'
		}
	];
	constructor() { }

	ngOnInit(): void {
		// default reaction
		this.emitSelectedReaction(this.reactions[2]);
	}

	public onEmojiButtonClick(reaction: IReaction): void{
		this.reactions.forEach(r => {
			if (r.id === reaction.id) {
				r.selected = true;
				this.emitSelectedReaction(r);
			} else {
				r.selected = false;
			}
		});
	}

	private emitSelectedReaction(reaction: IReaction): void {
		this.selectedReaction.emit(reaction);
	}

	public onCloseButtonClick(): void {
		this.closeButtonClicked.emit();
	}
}
