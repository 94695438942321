import { IImageAttachment } from 'projects/bee-attachment/src/lib/shared/models/image-attachment';
import MimeTypeUtils from 'projects/peer-core/src/lib/utils/mime-type.utils';

interface IFileAttachmentUI {
	data?: any;
}

export interface IFileAttachment extends IFileAttachmentUI {
	url?: string;
	name?: string;
	mime?: string;
	ext?: string;
	thumbnails?: IImageAttachment[];
	size?: string;
	downloadLink?: string;
}

export class FileAttachment implements IFileAttachment {
	public url: string;
	public name: string;
	public mime: string;
	public ext: string = null;
	public thumbnails = [];
	public size: string = null;
	public downloadLink: string = null;

	constructor(values: IFileAttachment = {}) {
		Object.assign(this, values);

		if (this.mime) {
			const ext = MimeTypeUtils.findExtension(this.mime);

			if (ext) {
				this.ext = ext.toUpperCase();
			} else {
				const type = this.mime.substring(0, this.mime.indexOf('/'));

				switch (type) {
					case 'audio':
						this.ext = 'AUDIO';
						break;
				}
			}
		}

		if (this.mime) {
			this.downloadLink = `${this.url}?cType=${this.mime}&file=${this.name}`;
		}
	}
}
