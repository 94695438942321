import { Component, OnInit } from '@angular/core';
import { CollectionsService, PaginationService, NetworkService } from '../../shared';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
	selector: 'app-archived-collections',
	templateUrl: './archived-collections.component.html',
	styleUrls: ['./archived-collections.component.css'],
})
export class ArchivedCollectionsComponent implements OnInit {
	private paginating = false;
	public archivedCollections = [];
	public isFetching = 0;
	public nextUrl;
	public paginateSub$: any;

	constructor(
		private networkService: NetworkService,
		private collectionService: CollectionsService,
		private location: Location,
		private paginationService: PaginationService,
		private router: Router
	) {
		this.paginationService.scrollDistance = 8;
		this.paginateSub$ = this.paginationService.initiateScroll.subscribe((data) => {
			if (data && !this.paginating) {
				this.paginate();
			}
		});
	}

	ngOnInit(): void {
		this.getArchivedCollections();
	}

	private getArchivedCollections(): void {
		this.isFetching = 0;

		this.collectionService.getArchivedCollections().subscribe((data) => {
			this.isFetching = 1;
			this.archivedCollections = data.objects;

			this.nextUrl = data.next ? data.next.split('alpha')[1] : '';
		});
	}

	public paginate(): void {
		if (this.nextUrl) {
			this.isFetching = 0;
			this.paginating = true;
			this.networkService.paginateToDoLists(this.nextUrl).subscribe((data) => {
				this.paginating = false;
				this.isFetching = 1;

				this.nextUrl = data.next ? data.next.split('alpha')[1] : '';

				this.archivedCollections = this.archivedCollections.concat(data.objects);
			});
		}
	}

	public onClickBack(): void {
		this.location.back();
	}
}
