import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UserService } from '../shared';

@Injectable()
export class NoAuthGuard implements CanActivate {
	constructor(private userService: UserService) {}

	public canActivate(): boolean {
		this.userService.isAuthenticated.subscribe((isAuthenticated) => {
			if (isAuthenticated) {
				return false;
			}
		});

		return true;
	}
}
