import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { FocusTimeModalComponent } from '@shared/components/network/daily-message-widget/focus-time-modal/focus-time-modal.component';
import { NoMeetingDayModalComponent } from '@shared/components/network/daily-message-widget/no-meeting-day-modal/no-meeting-day-modal.component';
import { ScheduleOToOMeetingModalComponent } from '@shared/components/network/daily-message-widget/schedule-o-to-o-meeting-modal/schedule-o-to-o-meeting-modal.component';
import { DailyMessageService } from '@shared/components/network/daily-message-widget/services/daily-message.service';
import { YoutubePlayerModalComponent } from '@shared/components/network/daily-message-widget/youtube-player-modal/youtube-player-modal.component';
import { NetworkService } from '@shared/services';
import { StartEndDayService } from '@shared/services/home/start-end-day.service';
import ArrayUtils from '@shared/utils/array-utils';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-new-daily-messages-widget',
	templateUrl: './new-daily-messages-widget.component.html',
	styleUrls: ['./new-daily-messages-widget.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
export class NewDailyMessagesWidgetComponent implements OnInit, OnDestroy {
	@Input() public currentPage: any;
	@Input() public network: any;
	@Input() public currentUser: any;

	// #region Modals

	// Youtube Video Modal
	@ViewChild('youtubeVideoModal', { static: false }) public youtubeVideoModalRef: NgbModalRef;
	private youtubeVideoModal: NgbModalRef;

	// Focus Time Modal
	@ViewChild('focusTimeModal', { static: false }) public focusTimeModalRef: NgbModalRef;
	private focusTimeModal: NgbModalRef;

	// Schedule 1x1 Meeting Modal
	@ViewChild('schedule1x1MeetingModal', { static: false }) public schedule1x1MeetingModalRef: NgbModalRef;
	private schedule1x1MeetingModal: NgbModalRef;

	public modalConfigs: NgbModalOptions;

	// #endregion

	public isDailyMessageLoading = false;
	public dailyMessageLoadingSubject: Subject<any> = new Subject();
	public dailyMessage: any = null;
	public hasVideo = false;
	public actionButtonText = 'Details';
	private intervalId: any;
	constructor(
		private startEndDayService: StartEndDayService,
		private translateService: TranslateService,
		private networkService: NetworkService,
		private dailyMessageService: DailyMessageService,
		private modalService: NgbModal,
		private router: Router
	) {
		this.modalConfigs = {
			animation: true,
			// eslint-disable-next-line spellcheck/spell-checker
			ariaLabelledBy: 'modal-basic-title',
			ariaDescribedBy: 'modal-basic-description',
			backdrop: 'static',
			centered: true,
			container: 'body',
			keyboard: true,
			size: 'lg',
		};

		this.dailyMessageService.dailyMessageRefreshedEvent.subscribe(() => {
			this.loadDailyMessage();
		});

		this.dailyMessageLoadingSubject.subscribe((next: any) => {
			this.isDailyMessageLoading = next;
		});

		this.intervalId = setInterval(() => {
			// update message
			this.loadDailyMessage();
		}, 1000 * 60 * 10);
	}

	ngOnInit(): void {
		this.loadDailyMessage();
	}

	ngOnDestroy(): void {
		if(this.intervalId){
			clearInterval(this.intervalId);
		}
	}

	// #region Youtube Video Modal

	public showYoutubeVideoModal(): void {
		if (this.dailyMessage && this.dailyMessage.video_url && this.dailyMessage.youtubeEmbedUrl) {
			if (!this.modalService.hasOpenModals()) {
				this.youtubeVideoModal = this.modalService.open(YoutubePlayerModalComponent, this.modalConfigs);

				if (this.youtubeVideoModal) {
					this.youtubeVideoModal.componentInstance.youtubeEmbedUrl = this.dailyMessage.youtubeEmbedUrl;
					this.youtubeVideoModal.componentInstance.title = this.translateService.instant('daily_message_' + this.dailyMessage.slug);
				}
			}
		}
	}

	// #endregion

	// #region Focus Time Modal

	public showFocusTimeModal(): void {
		this.focusTimeModal = this.modalService.open(FocusTimeModalComponent, {
			...this.modalConfigs,
			size: 'md',
		});

		this.focusTimeModal.componentInstance.networkId = this.network.uid;
		this.focusTimeModal.componentInstance.currentUser = this.currentUser;
	}

	// #endregion

	// #region No Meeting Modal

	public showNoMeetingModal(): void {
		this.focusTimeModal = this.modalService.open(NoMeetingDayModalComponent, {
			...this.modalConfigs,
			size: 'md',
		});

		this.focusTimeModal.componentInstance.networkId = this.network.uid;
		this.focusTimeModal.componentInstance.currentUser = this.currentUser;
	}

	// #endregion

	// #region Schedule Your 1x1 Meeting

	public scheduleYour1x1MeetingModal(): void {
		this.schedule1x1MeetingModal = this.modalService.open(ScheduleOToOMeetingModalComponent, {
			...this.modalConfigs,
			size: 'md',
		});

		this.schedule1x1MeetingModal.componentInstance.networkId = this.network.uid;
		this.schedule1x1MeetingModal.componentInstance.currentUser = this.currentUser;
	}

	// #endregion

	/**
	 * Load daily message
	 * This data is caching, you need to check service method for more details
	 */
	public loadDailyMessage(): void {
		if (this.isDailyMessageLoading) {
			return;
		}

		const currentTime: number = this.getCurrentHourRange();

		this.isDailyMessageLoading = true;

		this.networkService
			.getDailyMessage(currentTime, this.network?.uid, this.currentUser?.username)
			.subscribe((data: any) => {
				if (data?.data && data?.data?.message) {
					this.dailyMessage = data.data;
					// use only for testing
					// this.dailyMessage.video_url = [
					// 	'https://www.youtube.com/watch?v=inpok4MKVLM',
					// 	'https://www.youtube.com/watch?v=tzIJfCMBBRs',
					// 	'https://www.youtube.com/watch?v=NMBuSMxrSaY',
					// 	'https://www.youtube.com/watch?v=xRH1To_xyr8',
					// 	'https://www.youtube.com/watch?v=drpNPMPqdtI',
					// 	'https://youtu.be/o7Ob4TDI2WE',
					// 	'https://www.youtube.com/watch?v=MTU4iCDntjs',
					// 	'https://youtu.be/6fnLKyRJsrs',
					// 	'https://www.youtube.com/watch?v=hLXQXbXsZdI',
					// 	'https://www.youtube.com/watch?v=nFIfv-jIgbI',
					// ];

					if (this.dailyMessage.video_url) {
						const videoUrl = Array.isArray(this.dailyMessage.video_url) ? ArrayUtils.shuffle(this.dailyMessage.video_url).pop() : this.dailyMessage.video_url;

						let videoId = null;

						if (videoUrl.indexOf('v=')) {
							videoId = videoUrl.split('v=')[1];
						} else {
							videoId = videoUrl.split('/').pop();
						}

						if (videoId) {
							this.dailyMessage.youtubeEmbedUrl = `https://www.youtube-nocookie.com/embed/${videoId}?controls=1`;

							this.hasVideo = true;
						}
					}

					this.dailyMessageService.dailyMessageUpdate(this.dailyMessage);
				} else {
					this.dailyMessage = {
						// eslint-disable-next-line spellcheck/spell-checker
						message: this.translateService.instant('static-msg', {
							name: this.currentUser.name,
						}),
					};
				}
			},
			error => {
				console.log(error);
				this.isDailyMessageLoading = false;
			}
			)
			.add(() => {
				this.isDailyMessageLoading = false;
			});
	}

	/**
	 * Function to redirect when click on daily message
	 */
	public messageAction(): void {
		const messageSlug = this.dailyMessage.slug;

		if (this.dailyMessage.video_url) {
			this.showYoutubeVideoModal();
		} else if (messageSlug === 'start-day-done') {
			this.startEndDayService.toggleStarDay(true);
		} else if (messageSlug === 'focus-time') {
			this.showFocusTimeModal();
		} else if (messageSlug === 'no-meeting') {
			this.showNoMeetingModal();
		} else if (messageSlug === 'schedule-meeting') {
			this.scheduleYour1x1MeetingModal();
		} else if (messageSlug === 'praise') {
			this.router.navigateByUrl('/network/points');
		} else if (messageSlug === 'single-upcoming-tasks' && this.dailyMessage?.task_uid) {
			this.router.navigate(['/project/task-detail/', this.dailyMessage.task_uid]);
		} else if (messageSlug === 'multiple-upcoming-tasks') {
			this.router.navigateByUrl('/network/calendar');
		} else if (messageSlug === 'pending-tasks-complete') {
			this.router.navigateByUrl('/project/my-tasks');
		} else {
			// handle component specific cases
			this.dailyMessageService.dailyActionClick(this.dailyMessage);
		}

		this.switchMessages();
	}

	/**
	 * For getting daily message on 'custom-message' event
	 */
	private getCurrentHourRange(): number {
		const today = new Date(),
			currentHour = today.getHours();

		let time: number;

		if (currentHour >= 8 && currentHour < 14) {
			time = 8;
		} else if (currentHour >= 14 && currentHour < 17) {
			time = 14;
		} else if (currentHour >= 17 && currentHour < 19) {
			time = 17;
		} else if (currentHour >= 19 && currentHour < 8) {
			time = 19;
		}

		// use only for testing
		// time = 8;

		return time;
	}

	/**
	 * Switch between message with message2
	 */
	private switchMessages(): void {
		if (this.dailyMessage) {
			if (this.dailyMessage.message2) {
				this.dailyMessage.message = this.dailyMessage.message2;
			} else {
				this.dailyMessage.message2 = this.dailyMessage.message;
			}
		}
	}
}
