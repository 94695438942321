import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Post } from '../../../models/post.model';
import { EventService, EventAttendeeStatus } from '../../../services/event.service';

@Component({
	selector: 'app-attending-users-modal',
	templateUrl: './attending-users-modal.component.html',
	styleUrls: ['./attending-users-modal.component.css'],
	encapsulation: ViewEncapsulation.None,
})
export class AttendingUsersModalComponent implements OnInit {
	@ViewChild('attendingUsersListModal', { static: false })
	public attendingUsersListModalRef: NgbModalRef;
	private attendingUsersListModal: NgbModalRef;

	public tabData: any = null;
	private post: Post;

	constructor(private modalService: NgbModal, private eventService: EventService, private translateService: TranslateService) {
		this.tabData = [
			{
				status: EventAttendeeStatus.Attending,
				title: this.translateService.instant('Attending'),
				count: 0,
				users: [],
			},
			{
				status: EventAttendeeStatus.MaybeAttending,
				title: this.translateService.instant('Maybe Attending'),
				count: 0,
				users: [],
			},
			{
				status: EventAttendeeStatus.NotAttending,
				title: this.translateService.instant('Not Attending'),
				count: 0,
				users: [],
			},
		];
	}

	ngOnInit(): void {}

	public selectedTabChange(event): void {
		this.loadAttendingUsers(this.tabData[event.index].status, event.index);
	}

	// #region AttendingUsers List Modal

	public showAttendingUsersListModal(post: Post): void {
		this.post = post;

		this.attendingUsersListModal = this.modalService.open(this.attendingUsersListModalRef, <NgbModalOptions>{
			backdrop: 'static',
			centered: true,
			container: 'body',
			keyboard: true,
			size: 'lg',
		});

		for (let i = 0; i < this.tabData.length; i++) {
			const tab = this.tabData[i];

			this.loadAttendingUsers(tab.status, i);
		}
	}

	public closeAttendingUsersListModal(): void {
		this.attendingUsersListModal.close();
	}

	// #endregion

	private loadAttendingUsers(status: EventAttendeeStatus, index: number): void {
		this.eventService.getEventAttendees(this.post.uid.toString(), status).subscribe((response: any) => {
			this.tabData[index].count = response.count;
			this.tabData[index].users = response.objects.map((x: any) => x.user) || [];
		});
	}
}
