import { Injectable } from '@angular/core';
import * as Toastify from 'toastify-js';

@Injectable({
	providedIn: 'root',
})
export class ToastService {
	constructor() {}

	public success(text: string, configs: any = {}): void {
		Toastify({
			...{
				text: text,
				duration: 2000,
				newWindow: true,
				close: true,
				gravity: 'top', // `top` or `bottom`
				position: 'right', // `left`, `center` or `right`
				stopOnFocus: true, // Prevents dismissing of toast on hover
				style: {
					background: 'linear-gradient(to right, #00b09b, #96c93d)',
					'border-radius': '5px',
					opacity: '90%',
				},
				offset: {
					x: 10,
					y: 70,
				},
				onClick: (): void => {}, // Callback after click
			},
			...configs,
		}).showToast();
	}

	public error(text: string, configs: any = {}): void {
		Toastify({
			...{
				text: text,
				duration: 2000,
				newWindow: true,
				close: true,
				gravity: 'top', // `top` or `bottom`
				position: 'right', // `left`, `center` or `right`
				stopOnFocus: true, // Prevents dismissing of toast on hover
				style: {
					// eslint-disable-next-line spellcheck/spell-checker
					background: 'linear-gradient(to right, #fd1d1d, #fcb045)',
					'border-radius': '5px',
					opacity: '90%',
				},
				offset: {
					x: 10,
					y: 70,
				},
				onClick: (): void => {}, // Callback after click
			},
			...configs,
		}).showToast();
	}

	public info(text: string, configs: any = {}): void {
		Toastify({
			...{
				text: text,
				duration: 3000,
				newWindow: true,
				close: true,
				gravity: 'top', // `top` or `bottom`
				position: 'right', // `left`, `center` or `right`
				stopOnFocus: true, // Prevents dismissing of toast on hover
				style: {
					background: 'linear-gradient(to right, #0ba5ec, #36bffa)',
					'border-radius': '5px',
					opacity: '90%',
				},
				offset: {
					x: 10,
					y: 70,
				},
				onClick: (): void => {}, // Callback after click
			},
			...configs,
		}).showToast();
	}
}
