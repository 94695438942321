import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { WrappedSocket } from 'ngx-socket-io/src/socket-io.service';

@Injectable()
export class SocketIoService {
	constructor(private socket: Socket) {}

	public joinChannel(network_id): void {
		this.socket.emit('room', network_id);
	}

	public leaveChannel(network_id): void {
		this.socket.emit('leave-room', network_id);
	}

	public getSocket(): WrappedSocket {
		return this.socket;
	}
}
