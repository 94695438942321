import { Component, OnInit, ViewChild, Input, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { NetworkService, LayoutService, PaginationService, PostService, UserService, PostEditComponent, Reminder } from '../../shared';
import { ActivatedRoute, Router } from '@angular/router';
import { PostComponent } from '../../post/post.component';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { SearchService } from '../../shared/services/search.service';
import { ToastService } from '../../shared/services/common/toast.service';
import { AttachmentService } from 'projects/bee-attachment/src/lib/shared/services/attachment.service';
import { ClipboardService } from 'projects/peer-core/src/lib/services/ui/clipboard.service';

@Component({
	selector: 'app-customers-list',
	templateUrl: './customers-list.component.html',
	styleUrls: ['./customers-list.component.css'],
})
export class CustomersListComponent implements OnInit, OnChanges, OnDestroy {
	@ViewChild('postComponent', { static: true }) public postComponent: PostComponent;
	@ViewChild('postEdit', { static: true }) public postEdit: PostEditComponent;
	@Input() public paidFilter;
	@Input() public currentPage = 'customersList';

	constructor(
		private translate: TranslateService,
		private networkService: NetworkService,
		private paginationService: PaginationService,
		private activatedRoute: ActivatedRoute,
		public layoutService: LayoutService,
		private router: Router,
		private userService: UserService,
		public _location: Location,
		private postService: PostService,
		private searchService: SearchService,
		private toastService: ToastService,
		private attachmentService: AttachmentService,
		private clipboardService: ClipboardService
	) {
		this.paginationService.scrollDistance = 8;
		this.paginateSub$ = this.paginationService.initiateScroll.subscribe((data) => {
			if (data && !this.paginating) {
				this.paginateCustomers();
			}
		});
	}

	public loadingGif = 'none';
	public postType;
	private customersSubscriber;
	public showPostCreateForm = false;
	public isEditReminder = false;
	public filterType;
	public reminderUid;
	public paginateSub$: any;
	public stateParam;
	public customerListState;
	public editCustomer;
	public selectedRemainderOption;
	private paginating = false;
	public currentUser;
	public customers = [];
	public reminder = { value: '' };
	public isFetching = false;
	public customersFetched = -1;
	public next_url;
	public selectedCustomer;
	public selectedCustomerStatus;
	public deleteCustomerModal = 'none';
	public display_toast = 'none';
	public reminderMessage;
	public customerStatus;
	public timeForReminder: number;
	public filterParams;
	public reminderObject = {
		username: '',
		contentUid: '',
		contentType: '',
		reminderOption: '',
		repeat: false,
	};
	public searchSubscription: any;
	public isFirstSearch = true;
	public network;
	public isHashTags = false;
	// reminders
	public reminderOptions: any[] = [];
	public displayReminderModal = 'none';
	public reminderTime;
	public timeFor;
	public remindersSelected = [];
	public model = new Reminder();
	public reminderToast = false;

	/**
	 * Function to show image slideshow
	 * @param todo
	 */
	public selectedImages: any[] = [];

	/**
	 *
	 */
	ngOnInit(): void {
		this.userService.currentUser.subscribe((userData) => {
			this.currentUser = userData;
		});

		if (!this.paidFilter) {
			this.activatedRoute.queryParams.subscribe((params) => {
				if (params) {
					if (params['state']) {
						this.stateParam = params['state'];
						this.customerListState = this.stateParam;
						this.filterParams = 'status=' + params['state'];
						this.filterCustomers(this.filterParams);
					} else if (params['priority']) {
						this.stateParam = params['priority'];
						this.filterParams = 'priority=' + params['priority'];
						this.filterCustomers(this.filterParams);
					} else if (params['product']) {
						this.stateParam = params['product'];
						this.filterParams = 'product=' + params['product'];
						this.filterCustomers(this.filterParams);
					} else if (params['dealType']) {
						this.stateParam = params['dealType'];
						this.filterParams = 'deal_type=' + params['dealType'];
						this.filterCustomers(this.filterParams);
					} else if (params['salesChannel']) {
						this.stateParam = params['salesChannel'];
						this.filterParams = 'sales_channel=' + params['salesChannel'];
						this.filterCustomers(this.filterParams);
					} else if (params['customerType']) {
						this.stateParam = params['customerType'];
						this.filterParams = 'customer_type=' + params['customerType'];
						this.filterCustomers(this.filterParams);
					} else if (params['assignee']) {
						this.stateParam = params['name'];
						this.filterParams = 'assigned_user=' + params['assignee'];
						this.filterCustomers(this.filterParams);
					} else if (params['hash_tag']) {
						this.stateParam = '#' + params['name'];
						this.isHashTags = true;
						this.getCustomersByHashtags(params['hash_tag']);
					} else {
						this.stateParam = 'None';
						this.filterCustomers('');
					}
				}
			});
		}

		this.networkService.currentNetwork.subscribe((network) => {
			this.network = network;
		});

		this.postEdit.updatedCustomerEmitter.subscribe((data) => {
			if (data) {
				let index = -1;
				index = this.findWithAttr(this.customers, 'uid', data['uid']);
				if (index > -1 && data['status'] === this.selectedCustomerStatus) {
					this.customers[index] = data;
				} else if (!this.paidFilter) {
					this.customers.splice(index, 1);
				}
				this.checkUserMentionAndHashtagAndAlterBody();
			}
		});

		this.postComponent.newPostEmitter.subscribe((data) => {
			if (data) {
				if (data['status'] === this.stateParam) {
					this.customers = [];
					this.filterCustomers(this.filterParams);
				}
			}
		});

		this.layoutService.showBackdrop.subscribe((data) => {
			if (data === 'event-post-form' || data === 'hide') {
				this.showPostCreateForm = false;
				this.postType = '';
			}
		});

		if (window.location.pathname === '/network/customers') {
			this.searchSubscription = this.searchService.searchValue.subscribe((data) => {
				if (this.isFirstSearch && data.trim()) {
					this.isFirstSearch = false;
				}
				if (!this.isFirstSearch) {
					let filter;
					if (data) {
						filter = 'query=' + data.trim();
					}
					this.isFetching = false;
					this.customers = [];

					this.networkService.filterCustomers(this.filterParams, filter).subscribe(
						(res) => {
							this.isFetching = true;
							this.customers = res.objects;

							this.customers.forEach((customer: any) => {
								customer.baseAttachment = this.attachmentService.parseBaseAttachment(customer.attachmentDetails);
							});

							this.checkUserMentionAndHashtagAndAlterBody();
							if (res.next) {
								this.next_url = res.next.split('alpha')[1];
								this.paginateCustomers();
							} else {
								this.next_url = '';
							}
						},
						() => {
							this.isFetching = true;
						}
					);
				}
			});
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.hasOwnProperty('paidFilter')) {
			this.customers = [];
			this.filterCustomers(this.paidFilter);
		}
	}

	ngOnDestroy(): void {
		if (this.customersSubscriber) {
			this.customersSubscriber.unsubscribe();
		}
	}

	private findWithAttr(array, attr, value): number {
		for (let i = 0; i < array.length; i += 1) {
			if (array[i][attr] === value) {
				return i;
			}
		}
		return -1;
	}

	public filterCustomers(filter): void {
		this.isFetching = false;
		this.isHashTags = false;
		this.filterType = '';
		this.customers = [];
		this.customersSubscriber = this.networkService.filterCustomers(filter, '').subscribe(
			(data) => {
				this.isFetching = true;
				data.objects.forEach((customer) => {
					customer['type'] = 'customer';
				});
				this.customers = data.objects;

				this.customers.forEach((customer: any) => {
					customer.baseAttachment = this.attachmentService.parseBaseAttachment(customer.attachmentDetails);
				});

				this.checkUserMentionAndHashtagAndAlterBody();

				if (data.next) {
					this.next_url = data.next ? data.next.split('alpha')[1] : '';

					this.paginateCustomers();
				} else {
					this.next_url = '';
				}
			},
			() => {
				this.isFetching = true;
			}
		);
	}

	private getCustomersByHashtags(uid): void {
		this.isFetching = false;
		this.filterType = '';
		this.customers = [];
		this.networkService.getCustomersByHash(uid).subscribe(
			(data) => {
				this.isFetching = true;
				this.customers = data.objects;

				this.customers.forEach((customer: any) => {
					customer.baseAttachment = this.attachmentService.parseBaseAttachment(customer.attachmentDetails);
				});

				this.checkUserMentionAndHashtagAndAlterBody();

				if (data.next) {
					this.next_url = data.next ? data.next.split('alpha')[1] : '';

					this.paginateCustomers();
				} else {
					this.next_url = '';
				}
			},
			() => {
				this.isFetching = true;
			}
		);
	}

	/**
	 * Function to check the user mention and hash tag in post list if exists change the post body
	 * 1.If string contains '<',then its cut off while displaying in innerHtml.Hence its replaced by '< '
	 */
	private checkUserMentionAndHashtagAndAlterBody(): void {
		this.customers.forEach((customer) => {
			if (customer['title'] && customer['title'].indexOf('<') > -1) {
				// 1
				customer['title'] = customer['title'].replace(/</g, '< ');
			}

			customer['altered_title'] = customer['title'];

			if (customer['mentionedUsers']) {
				customer['mentionedUsers'].forEach((mentionedUser) => {
					const html = '<b #userMention><a class="text-green">@' + mentionedUser['name'] + '</a></b>';
					if (customer.altered_title) {
						customer['altered_title'] = customer.altered_title.replace('@' + mentionedUser['name'], html);
					}
				});
			}
			if (customer['hashtags']) {
				customer['hashtags'].forEach((hashtag) => {
					if (hashtag['name']) {
						const html = '<b #hashTag><a class="hash-customer">#' + hashtag['name'] + '</a></b>';
						if (customer.altered_title) {
							customer['altered_title'] = customer.altered_title.replace('#' + hashtag['name'], html);
						}
					}
				});
			}
		});
	}

	public paginateCustomers(): void {
		if (this.next_url) {
			this.customersFetched = 0;
			this.paginating = true;
			this.networkService.paginateToDoLists(this.next_url).subscribe(
				(data) => {
					this.paginating = false;
					this.next_url = data.next ? data.next.split('alpha')[1] : '';

					this.customers = this.customers.concat(data.objects);

					this.customers.forEach((customer: any) => {
						customer.baseAttachment = this.attachmentService.parseBaseAttachment(customer.attachmentDetails);
					});

					this.customersFetched = 1;
					this.checkUserMentionAndHashtagAndAlterBody();
				},
				() => {
					this.customersFetched = 1;
				}
			);
		}
	}

	public sortCustomers(key): void {
		this.isHashTags = false;
		this.isFetching = false;
		this.customers = [];
		this.filterType = key;
		const filterString = key;
		this.networkService.filterCustomers(this.filterParams, filterString).subscribe(
			(data) => {
				this.isFetching = true;
				this.customers = data.objects;

				this.customers.forEach((customer: any) => {
					customer.baseAttachment = this.attachmentService.parseBaseAttachment(customer.attachmentDetails);
				});

				this.checkUserMentionAndHashtagAndAlterBody();
				if (data.next) {
					this.next_url = data.next ? data.next.split('alpha')[1] : '';

					this.paginateCustomers();
				} else {
					this.next_url = '';
				}
			},
			() => {
				this.isFetching = true;
			}
		);
	}

	public getDownloadLink(attachment): string {
		return attachment['url'] + '?cType=' + attachment['type'] + '&file=' + attachment['name'];
	}

	public deleteCustomer(): void {
		this.loadingGif = 'block';
		this.networkService.deleteCustomer(this.selectedCustomer['uid']).subscribe(() => {
			this.customers.splice(this.customers.indexOf(this.selectedCustomer), 1);
			this.deleteCustomerModal = 'none';
			this.loadingGif = 'none';
			this.selectedCustomer = '';
		});
	}

	public onEditCustomer(customerObj): void {
		const customer = customerObj;
		customer.type = 'customer';
		this.selectedCustomerStatus = customer.status;
		this.editCustomer = customer;
	}

	public onCreateCustomerTask(object): void {
		const customer = object;
		if (customer['type'] === 'customer') {
			customer['postType'] = 'customer';
			customer['type'] = 'postTodo';
		}
		this.editCustomer = customer;
	}

	/**
	 * Function which activates plus button and displays modal
	 */
	public activateButton(): void {
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
		this.layoutService.hideBd();
		this.showAddCustomer();
	}

	/**
	 * Function which  displays add task form
	 */
	private showAddCustomer(): void {
		this.showPostCreateForm = !this.showPostCreateForm;
		if (this.showPostCreateForm) {
			this.layoutService.showBd('event-post-form');
			this.postType = 'add-customer';
		}
	}

	public onBackClick(): void {
		this._location.back();
	}

	/**
	 * Function to execute like on todo
	 * @param customer
	 * 1. Call like api
	 * 2. Set like uid in myRelations of todo detail object
	 * 3. Set liked text within todo object
	 */
	public likeCustomer(customer): void {
		this.postService.like(this.currentUser.username, customer).subscribe((data) => {
			// 1
			customer.myRelations = { like: data.uid }; // 2
			if (customer['myRelations'] && customer['myRelations'].like && customer['likesCount']['count'] === 0) {
				// 3
				customer['likedText'] = this.translate.instant('You liked this');
			}
		});
	}

	/**
	 * Function to execute dislike on todo
	 * @param customer
	 * 1. Call dislike api
	 * 2. Remove like uid from myRelations of todo object
	 */
	public dislikeCustomer(customer): void {
		this.postService.dislike(this.currentUser.username, customer.myRelations.like).subscribe(() => {
			// 1
			customer.myRelations = { like: null };
			customer['likedText'] = ''; // 2
			customer['likesCount']['count'] = 0;
		});
	}

	public handleLinkShare(link: string): void {
		this.clipboardService.copy(link);
	}

	/** customer hashtags handling
	 * Manage redirection to customer detail page
	 * @param e
	 * @param uid
	 */
	public handleRedirection(e, customer): void {
		if (e.target && e.target.className) {
			const type = e.target.className;
			if (type === 'hash-customer') {
				const text = e.target.innerHTML.split('#')[1];
				const hashtags = customer['hashtags'];
				hashtags.forEach((tag) => {
					if (tag['name'] === text) {
						this.router.navigateByUrl('/network/customers?hash_tag=' + tag['uid'] + '&name=' + tag['name']);
					}
				});
			} else {
				if (
					type !== 'pb-icon pb-like' &&
					type !== 'pb-icon pb-liked' &&
					type !== 'pb-icon pb-like ng-star-inserted' &&
					type !== 'pb-icon pb-liked ng-star-inserted' &&
					type !== 'edit-img' &&
					type !== 'dropdown-item dropdown-border text-center' &&
					type !== 'pb-icon todo-radio-off' &&
					type !== 'pb-icon todo-radio-on' &&
					type !== 'pb-icon todo-radio-off ng-star-inserted' &&
					type !== 'pb-icon todo-radio-on ng-star-inserted' &&
					type !== 'dropdown-item dropdown-border text-center ng-star-inserted' &&
					type !== 'pb-icon pb-share'
				) {
					this.router.navigateByUrl('/network/customer/' + customer['uid']);
				}
			}
		} else {
			this.router.navigateByUrl('/network/customer/' + customer['uid']);
		}
	}

	public onSelectReminder(reminder): void {
		const index = this.remindersSelected.findIndex((r) => r === reminder);
		if (index > -1) {
			this.remindersSelected.splice(index, 1);
		} else {
			this.remindersSelected.push(reminder);
		}
	}

	public displayReminders(customer): void {
		this.selectedCustomer = customer;
		this.displayReminderModal = 'block';
		this.reminderOptions = [];
		this.networkService.getReminderOptions().subscribe((data) => {
			this.reminderOptions = data.reminderOptions;
			if (this.remindersSelected.length > 0) {
				this.setPreviousReminders();
			}
		});
	}

	/**
	 * For creating a new reminders for content
	 */
	public setReminder(): void {
		if (this.remindersSelected.length > 0) {
			const object = {};
			this.remindersSelected.forEach((reminder) => {
				object[reminder] = this.setReminderDate(reminder);
			});
			const param = JSON.stringify(object);
			this.model.contentType = 'customer';
			this.model.contentUid = this.selectedCustomer.uid;
			this.model.username = this.currentUser.username;
			this.model.repeat = true;
			this.model.reminderOption = param;
			this.loadingGif = 'block';
			this.networkService.createReminder(this.model).subscribe((data) => {
				this.selectedCustomer['myRelations']['reminder'] = data['uid'];
				this.loadingGif = 'none';
				this.resetReminders();
				this.reminderToast = true;
				setTimeout(() => {
					this.reminderToast = false;
				}, 8000);
			});
		} else {
			if (this.selectedCustomer['myRelations'] && this.selectedCustomer['myRelations']['reminder']) {
				this.deleteReminders();
			} else {
				this.resetReminders();
			}
		}
	}

	public deleteReminders(): void {
		this.model.contentUid = this.selectedCustomer.uid;
		this.model.username = this.currentUser.username;
		this.model.repeat = true;
		this.model.contentType = 'customer';
		this.loadingGif = 'block';
		this.networkService.createReminder(this.model).subscribe(() => {
			this.selectedCustomer['myRelations']['reminder'] = '';
			this.loadingGif = 'none';
			this.resetReminders();
		});
	}

	/**
	 * For calculating corresponding to reminder selected
	 * @param option
	 */
	public setReminderDate(option): string {
		if (option === 'in_twenty_minutes') {
			this.timeFor = 20;
		} else if (option === 'in_one_hour') {
			this.timeFor = 60;
		} else if (option === 'in_three_hours') {
			this.timeFor = 180;
		} else if (option === 'tomorrow') {
			this.timeFor = this.minutesUntilMidnight() + 540;
		} else {
			this.timeFor = 6300;
		}
		const date = new Date();
		date.setMinutes(date.getMinutes() + this.timeFor);
		const month = date.getUTCMonth() + 1;
		const day = date.getUTCDate();
		const year = date.getUTCFullYear();
		const hours = date.getUTCHours();
		const minutes = date.getUTCMinutes();
		const seconds = date.getUTCSeconds();
		const utcDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
		return utcDate;
	}

	/**
	 * Get previous reminders of content
	 */
	public getReminderDetails(customer): void {
		this.loadingGif = 'block';
		this.networkService.getContentReminders('customers', customer['uid']).subscribe((data) => {
			this.loadingGif = 'none';
			this.displayReminders(customer);
			if (data.objects && data.objects.length > 0) {
				data.objects.forEach((element) => {
					if (element['reminderOption']) {
						this.remindersSelected.push(element['reminderOption']);
					}
				});
			}
			this.setPreviousReminders();
		});
	}

	/**
	 * Set Previous reminders on reminder options
	 */
	private setPreviousReminders(): void {
		this.reminderOptions.forEach((reminder) => {
			this.remindersSelected.forEach((r) => {
				if (r === reminder[0]) {
					reminder['checked'] = true;
				}
			});
		});
	}

	/**
	 * For resetting reminders in reminder options modal
	 */
	public resetReminders(): void {
		this.displayReminderModal = 'none';
		this.timeFor = 0;
		this.remindersSelected = [];
		this.reminderOptions.forEach((r) => {
			r['checked'] = false;
		});
	}

	private minutesUntilMidnight(): any {
		const midnight = new Date();
		midnight.setHours(24);
		midnight.setMinutes(0);
		midnight.setSeconds(0);
		midnight.setMilliseconds(0);
		return (midnight.getTime() - new Date().getTime()) / 1000 / 60;
	}
}
