import { Component, Input, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
	selector: 'app-loading-gif',
	templateUrl: './loading-gif.component.html',
	styleUrls: ['./loading-gif.component.css'],
})
export class LoadingGifComponent implements OnInit {
	@Input() public isLoading = false;

	public options: AnimationOptions = {
		// eslint-disable-next-line spellcheck/spell-checker
		path: '/assets/lottie/page-loading.json',
		loop: true,
		// eslint-disable-next-line spellcheck/spell-checker
		autoplay: true,
		// eslint-disable-next-line spellcheck/spell-checker
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	constructor() {}

	ngOnInit(): void {}

	public animationCreated(animationItem: AnimationItem): void {
		animationItem.setSpeed(2);
	}
}
