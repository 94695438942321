export default class ArrayUtils {
	/**
	 * Shuffle the given array
	 * @source https://stackoverflow.com/a/12646864/6940144
	 *
	 * @param array any[]
	 * @returns any[]
	 */
	public static shuffle(array: any[]): any[] {
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));

			[array[i], array[j]] = [array[j], array[i]];
		}

		return array;
	}
}
