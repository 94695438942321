import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HomeAuthResolver } from '../home/home-auth-resolver.service';
import { NetworkComponent } from './network.component';
import { SharedModule } from '../shared';
import { AgmCoreModule } from '@agm/core';
import { PostComponent } from '../post/post.component';
import { BuySellComponent } from '../buy-sell/buy-sell.component';
import { LocationComponent } from '../location/location.component';
import { NetworkNotificationComponent } from '../network-notification/notification.component';
import { EventComponent } from '../event/event.component';
import { ChannelComponent } from '../channel/channel.component';
import { CollectionsComponent } from '../collections/collections.component';
import { UserProfileComponent } from '../shared/user-profile/user-profile.component';
import { CollectionDetailComponent } from '../collections/collection-detail/collection-detail.component';
import { CreateCollectionComponent } from '../collections/create-collection/create-collection.component';
import { ChannelDetailComponent } from '../channel/channel-detail/channel-detail.component';
import { CreateChannelComponent } from '../channel/create-channel/create-channel.component';
import { SearchService } from '../shared/services/search.service';
import { BuySellDetailComponent } from '../buy-sell/buy-sell-detail/buy-sell-detail.component';
import { PostModule } from '../post/post.module';
import { NgbModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ProductsComponent } from '../product/products/products.component';
import { AdminPanelComponent } from '../admin-settings/admin-panel/admin-panel.component';
import { NotificationControlComponent } from '../notification-control/notification-control.component';
import { TodolistComponent } from '../todolist/todolist.component';
import { ArchivedCollectionsComponent } from '../collections/archived-collections/archived-collections.component';
import { ArchivedChannelsComponent } from '../channel/archived-channels/archived-channels.component';
import { CreateTodolistComponent } from '../todolist/create-todolist/create-todolist.component';
import { ProjectDetailsComponent } from '../todolist/project-details/project-details.component';
import { PurchasePlansComponent } from '../purchase/purchase-plans/purchase-plans.component';
import { PurchasePageComponent } from '../purchase/purchase-page/purchase-page.component';
import { PurchaseStatusComponent } from '../purchase/purchase-status/purchase-status.component';
import { TermsComponent } from '../purchase/terms/terms.component';
import { CalendarViewComponent } from '../calendar/calendar-view.component';
import { PointsComponent } from '../pointLadder/points/points.component';
import { CustomerStatesComponent } from '../customers/customer-states/customer-states.component';
import { CustomersListComponent } from '../customers/customers-list/customers-list.component';
import { CustomerDetailComponent } from '../customers/customer-detail/customer-detail.component';
import { ReportsComponent } from '../reports/reports.component';
import { LocationDetailComponent } from '../location-detail/location-detail.component';
import { ProductDetailComponent } from '../product/product-detail/product-detail.component';
import { IssueDetailComponent } from '../product/issue-detail/issue-detail.component';
import { ArchiveProductsComponent } from '../product/archive-products/archive-products.component';
import { MainPanelComponent } from '../admin-settings/main-panel/main-panel.component';
import { AdminMembersComponent } from '../admin-settings/admin-members/admin-members.component';
import { AddMembersComponent } from '../admin-settings/add-members/add-members.component';
import { AdminAuthenticationComponent } from '../admin-settings/admin-authentication/admin-authentication.component';
import { CreateProductComponent } from '../product/create-product/create-product.component';
import { PointDetailComponent } from '../point-detail/point-detail.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { PeerCoreModule } from 'projects/peer-core/src/public-api';
import { CustomerGuard } from './customer-auth/customer.guard';
import { SubscriptionGuard } from '@core/services/guards/subscription.guard';

const networkRouting: ModuleWithProviders<any> = RouterModule.forChild([
	{
		path: 'network',
		component: NetworkComponent,
		resolve: {
			isAuthenticated: HomeAuthResolver,
		},
		children: [
			{ path: '', component: PostComponent, canActivate: [SubscriptionGuard] },
			{ path: 'channel', component: ChannelComponent, canActivate: [SubscriptionGuard] },
			{ path: 'view-channel', component: ChannelDetailComponent, canActivate: [SubscriptionGuard] },
			{ path: 'event', component: EventComponent, canActivate: [SubscriptionGuard] },
			{ path: 'notifications', component: NetworkNotificationComponent, canActivate: [SubscriptionGuard] },
			{ path: 'checkin', component: LocationComponent, canActivate: [SubscriptionGuard] },
			{ path: 'buy-sell', component: BuySellComponent, canActivate: [SubscriptionGuard] },
			{ path: 'buy-sell/detail', component: BuySellDetailComponent, canActivate: [SubscriptionGuard] },
			{ path: 'saved-post', component: PostComponent, canActivate: [SubscriptionGuard] },
			{ path: 'collections', component: CollectionsComponent, canActivate: [SubscriptionGuard] },
			{ path: 'collection', component: CollectionDetailComponent, canActivate: [SubscriptionGuard] },
			{ path: 'profile/:memberId', component: UserProfileComponent, canActivate: [SubscriptionGuard] },
			{ path: 'create-collection', component: CreateCollectionComponent, canActivate: [SubscriptionGuard] },
			{ path: 'edit-collection/:id', component: CreateCollectionComponent, canActivate: [SubscriptionGuard] },
			{ path: 'create-channel', component: CreateChannelComponent, canActivate: [SubscriptionGuard] },
			{ path: 'channel-edit', component: CreateChannelComponent, canActivate: [SubscriptionGuard] },
			{ path: 'products', component: ProductsComponent, canActivate: [SubscriptionGuard] },
			{ path: 'product-detail', component: ProductDetailComponent, canActivate: [SubscriptionGuard] },
			{ path: 'issue-detail', component: IssueDetailComponent, canActivate: [SubscriptionGuard] },
			{ path: 'archive-products', component: ArchiveProductsComponent, canActivate: [SubscriptionGuard] },
			{ path: 'notification-control', component: NotificationControlComponent, canActivate: [SubscriptionGuard] },
			{ path: 'issue-edit', component: CreateCollectionComponent, canActivate: [SubscriptionGuard] },
			{ path: 'todolist', component: TodolistComponent, canActivate: [SubscriptionGuard] },
			{ path: 'archive-collections', component: ArchivedCollectionsComponent, canActivate: [SubscriptionGuard] },
			{ path: 'archive-channel', component: ArchivedChannelsComponent, canActivate: [SubscriptionGuard] },
			{ path: 'create-todolist', component: CreateTodolistComponent, canActivate: [SubscriptionGuard] },
			{ path: 'create-product', component: CreateProductComponent, canActivate: [SubscriptionGuard] },
			{ path: 'edit-todolist/:id', component: CreateTodolistComponent, canActivate: [SubscriptionGuard] },
			{ path: 'generate-actions/:id', component: CreateTodolistComponent, canActivate: [SubscriptionGuard] },
			{ path: 'project-detail', component: ProjectDetailsComponent, canActivate: [SubscriptionGuard] },
			{ path: 'calendar', component: CalendarViewComponent, canActivate: [SubscriptionGuard] },
			{ path: 'plans', component: PurchasePlansComponent },
			{ path: 'purchase', component: PurchasePageComponent },
			{ path: 'purchase-status', component: PurchaseStatusComponent },
			{ path: 'terms', component: TermsComponent },
			{ path: 'reports', component: ReportsComponent, canActivate: [SubscriptionGuard] },
			{ path: 'points', component: PointsComponent, canActivate: [SubscriptionGuard] },
			{ path: 'customer-states', component: CustomerStatesComponent, canActivate: [CustomerGuard] },
			{ path: 'customers', component: CustomersListComponent, canActivate: [CustomerGuard] },
			{ path: 'customer/:id', component: CustomerDetailComponent, canActivate: [CustomerGuard] },
			{ path: 'loc-detail', component: LocationDetailComponent, canActivate: [SubscriptionGuard] },
			{ path: 'admin/members', component: AdminMembersComponent, canActivate: [SubscriptionGuard] },
			{ path: 'admin/members/add', component: AddMembersComponent, canActivate: [SubscriptionGuard] },
			{ path: 'admin/members/authentication', component: AdminAuthenticationComponent, canActivate: [SubscriptionGuard] },
			{ path: 'admin/settings', component: AdminPanelComponent, canActivate: [SubscriptionGuard] },
			{ path: 'admin', component: MainPanelComponent, canActivate: [SubscriptionGuard] },
			{ path: 'point-detail/:id', component: PointDetailComponent, canActivate: [SubscriptionGuard] },
		],
	},
]);

@NgModule({
	imports: [
		CommonModule,
		networkRouting,
		BrowserModule,
		FormsModule,
		AgmCoreModule,
		PostModule,
		SharedModule,
		NgbModule,
		InfiniteScrollModule,
		NgbTooltipModule,
		NgbPopoverModule,
		TranslateModule,
		PeerCoreModule,
	],
	declarations: [NetworkComponent],
	bootstrap: [NetworkComponent],
	providers: [SearchService],
})
export class NetworkModule {}
