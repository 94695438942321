import { Injectable } from '@angular/core';
import countries from '../data/countries';
import timezones from '../data/timezones';
import { ICountry } from '../models/country';
import { ITimezoneCountry } from '../models/timezone-country';

@Injectable({
	providedIn: 'root',
})
export class TimezoneCountryService {
	constructor() {}

	/**
	 * Get country from timezone
	 *
	 * @source: https://codepen.io/diego-fortes/pen/YzEPxYw
	 *
	 * @returns ITimezoneCountry|null
	 */
	public getTimezoneCountry(): ITimezoneCountry | null {
		const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

		if (timezone === '' || !timezone) {
			return null;
		}

		const timezoneCountry = <ITimezoneCountry>timezones[timezone];

		timezoneCountry.country = {
			alpha2: timezoneCountry.c[0].toUpperCase(),
			name: countries[timezoneCountry.c[0]],
		};

		return timezoneCountry;
	}

	public findCountry(alpha2: string): ICountry | null {
		if (!alpha2) {
			return null;
		}

		const _alpha2 = alpha2.toUpperCase();

		const countryName = countries[_alpha2.toUpperCase()];

		if (!countryName) {
			return null;
		}

		return {
			alpha2: _alpha2,
			name: countryName,
		};
	}
}
