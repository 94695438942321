import { distinctUntilChanged } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SearchService {
	public searchValueSubject = new BehaviorSubject('');
	public searchValue = this.searchValueSubject.asObservable().pipe(distinctUntilChanged());

	public placeHolder = '';
	constructor() {}

	public initiateSearch(value): void {
		this.searchValueSubject.next(value.trim());
	}

	public resetSearchValue(): void {
		this.searchValueSubject.next('');
	}
}
