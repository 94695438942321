import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'core-member-list-item',
	templateUrl: './member-list-item.component.html',
	styleUrls: ['./member-list-item.component.scss'],
})
export class MemberListItemComponent implements OnInit, OnChanges {
	@Output() public onAssigneeSelected: EventEmitter<any> = new EventEmitter<any>();

	@Input() public member: any = null;
	@Input() public index: number;
	@Input() public isUnassigned = false;
	@Input() public selectAble = false;
	@Input() public enableRedirections = false;

	constructor() {}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.hasOwnProperty('member')) {
			this.member.checked = changes?.member?.currentValue?.checked;
		}
	}

	public onAssigneeSelectClick(): void {
		this.onAssigneeSelected.emit(this.member);
	}
}
