import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../shared/services/common/toast.service';
import { LayoutService, NetworkService, PostService, Reminder, UserService } from '../../shared';
import { LightboxService } from 'projects/peer-core/src/lib/services/ui/lightbox.service';
import { IImageAttachment, ImageAttachment } from 'projects/bee-attachment/src/lib/shared/models/image-attachment';
import { ClipboardService } from 'projects/peer-core/src/lib/services/ui/clipboard.service';

@Component({
	selector: 'app-customer-board-view',
	templateUrl: './customer-board-view.component.html',
	styleUrls: ['./customer-board-view.component.css'],
})
export class CustomerBoardViewComponent implements OnInit {
	@Output() public onCloseModalEmitter: EventEmitter<any> = new EventEmitter();

	constructor(
		private networkService: NetworkService,
		private translate: TranslateService,
		public layoutService: LayoutService,
		private router: Router,
		private userService: UserService,
		private postService: PostService,
		private toastService: ToastService,
		private lightboxService: LightboxService,
		private clipboardService: ClipboardService
	) {}

	public showStatusBoardView = 'none';
	public newCustomerLoader = false;
	public activeCustomerLoader = false;
	public inactiveCustomerLoader = false;
	public inProgressCustomerLoader = false;
	public trialCustomerLoader = false;
	public newCount: any;
	public trialCount: any;
	public activeCount: any;
	public inActiveCount: any;
	public inProgressCount: any;
	public newNextUrl = '';
	public trialNextUrl = '';
	public activeNextUrl = '';
	public inactiveNextUrl = '';
	public inprogressNextUrl = '';
	public newCustomerList: any[] = [];
	public trailCustomerList: any[] = [];
	public activeCustomerList: any[] = [];
	public inactiveCustomerList: any[] = [];
	public inprogressCustomerList: any[] = [];
	public currentUser: any;
	public network: any;
	public editCustomer: any;
	public loadingGif = 'none';
	public remindersSelected: any[] = [];
	public selectedCustomer: any;
	public displayReminderModal = 'none';
	public reminderOptions: any[] = [];
	public selectedCustomerStatus: any;
	public model = new Reminder();
	public reminderToast = false;
	public reminderTime;
	public timeFor;
	public postType;
	public customerListState;
	public showPostCreateForm = false;
	public deleteCustomerModal = 'none';
	public selectedCustomerIndex: any;
	public filter = 'everything';
	public subFilter = '';
	public sortParams = '';
	public priorities: any[] = [];
	public products: any[] = [];
	public dealTypes: any[] = [];
	public salesChannels: any[] = [];
	public customerTypes: any[] = [];
	public subFilterName = '';
	public customerModel = {
		category: 'customer',
		type: 'customer',
		state: 'active',
		shareable: true,
		commentable: true,
		contentType: 'network',
	};
	public reminderObject = {
		username: '',
		contentUid: '',
		contentType: '',
		reminderOption: '',
		repeat: false,
	};

	// MORE OPTIONS FUNCTIONS END ///

	ngOnInit(): void {
		this.userService.currentUser.subscribe((userData) => {
			this.currentUser = userData;
		});

		this.networkService.currentNetwork.subscribe((network) => {
			this.network = network;
		});
	}

	/**
	 * Function to show board view
	 */
	public showBoardView(): void {
		this.showStatusBoardView = 'block';
		this.filter = 'everything';
		this.subFilter = '';
		this.sortParams = '';
		this.subFilterName = '';
		this.getAllCustomerList();
		this.showPostCreateForm = false;
		this.customerListState = '';
		this.postType = '';
		this.priorities = [];
		this.salesChannels = [];
		this.customerTypes = [];
		this.dealTypes = [];
		this.products = [];
	}

	/**
	 * Function to get all customer list
	 */
	public getAllCustomerList(): void {
		this.resetModal();
		this.getTrialCustomerList();
		this.getActiveCustomerList();
		this.getNewCustomerList();
		this.getInActiveCustomerList();
		this.getInProgressCustomerList();
	}

	/**
	 * Function to close board view
	 */
	public closeBoardView(): void {
		this.showStatusBoardView = 'none';
		this.onCloseModalEmitter.emit();
		this.resetModal();
	}

	/**
	 * Function to get trial customer list
	 * @param state
	 */
	public getNewCustomerList(): void {
		const state = 'status=open';
		this.newCustomerLoader = true;
		this.networkService.filterCustomers(state, this.sortParams).subscribe((res) => {
			this.newCustomerLoader = false;
			if (res) {
				this.newCount = res.count ? res.count : 0;
				this.newNextUrl = res.next ? res.next.split('alpha')[1] : '';
				this.newCustomerList = res.objects;
				this.checkUserMentionAndHashtagAndAlterBody(this.newCustomerList);
			}
		});
	}

	/**
	 * Function to paginate new customers
	 */
	public paginateNewCustomers(): void {
		if (this.newNextUrl) {
			this.newCustomerLoader = true;
			this.networkService.paginateToDoLists(this.newNextUrl).subscribe((res) => {
				this.newCustomerLoader = false;
				if (res) {
					Array.prototype.push.apply(this.newCustomerList, res.objects);
					this.newNextUrl = res.next ? res.next.split('alpha')[1] : '';
					this.checkUserMentionAndHashtagAndAlterBody(this.newCustomerList);
				}
			});
		}
	}

	/**
	 * Function to get new customer list
	 */
	public getTrialCustomerList(): void {
		const state = 'status=fixed';
		this.trialCustomerLoader = true;
		this.networkService.filterCustomers(state, this.sortParams).subscribe((res) => {
			this.trialCustomerLoader = false;
			if (res) {
				this.trialCount = res.count ? res.count : 0;
				this.trialNextUrl = res.next ? res.next.split('alpha')[1] : '';
				this.trailCustomerList = res.objects;
				this.checkUserMentionAndHashtagAndAlterBody(this.trailCustomerList);
			}
		});
	}

	/**
	 * Function to paginate trial customers
	 */
	public paginateTrialCustomers(): void {
		if (this.trialNextUrl) {
			this.trialCustomerLoader = true;
			this.networkService.paginateToDoLists(this.trialNextUrl).subscribe((res) => {
				this.trialCustomerLoader = false;
				if (res) {
					Array.prototype.push.apply(this.trailCustomerList, res.objects);
					this.trialNextUrl = res.next ? res.next.split('alpha')[1] : '';
					this.checkUserMentionAndHashtagAndAlterBody(this.trailCustomerList);
				}
			});
		}
	}

	/**
	 * Function to get active customer list
	 */
	public getActiveCustomerList(): void {
		const state = 'status=closed';
		this.activeCustomerLoader = true;
		this.networkService.filterCustomers(state, this.sortParams).subscribe((res) => {
			this.activeCustomerLoader = false;
			if (res) {
				this.activeCount = res.count ? res.count : 0;
				this.activeNextUrl = res.next ? res.next.split('alpha')[1] : '';
				this.activeCustomerList = res.objects;
				this.checkUserMentionAndHashtagAndAlterBody(this.activeCustomerList);
			}
		});
	}

	/**
	 * Function to paginate active customers
	 */
	public paginateActiveCustomers(): void {
		if (this.activeNextUrl) {
			this.activeCustomerLoader = true;
			this.networkService.paginateToDoLists(this.activeNextUrl).subscribe((res) => {
				this.activeCustomerLoader = false;
				if (res) {
					Array.prototype.push.apply(this.activeCustomerList, res.objects);
					this.activeNextUrl = res.next ? res.next.split('alpha')[1] : '';
					this.checkUserMentionAndHashtagAndAlterBody(this.activeCustomerList);
				}
			});
		}
	}

	/**
	 * Function to get In active customer list
	 */
	public getInActiveCustomerList(): void {
		const state = 'status=pool';
		this.inactiveCustomerLoader = true;
		this.networkService.filterCustomers(state, this.sortParams).subscribe((res) => {
			this.inactiveCustomerLoader = false;
			if (res) {
				this.inActiveCount = res.count ? res.count : 0;
				this.inactiveNextUrl = res.next ? res.next.split('alpha')[1] : '';
				this.inactiveCustomerList = res.objects;
				this.checkUserMentionAndHashtagAndAlterBody(this.inactiveCustomerList);
			}
		});
	}

	/**
	 * Function to paginate active customers
	 */
	public paginateInactiveCustomers(): void {
		if (this.inactiveNextUrl) {
			this.inactiveCustomerLoader = true;
			this.networkService.paginateToDoLists(this.inactiveNextUrl).subscribe((res) => {
				this.inactiveCustomerLoader = false;
				if (res) {
					Array.prototype.push.apply(this.inactiveCustomerList, res.objects);
					this.inactiveNextUrl = res.next ? res.next.split('alpha')[1] : '';
					this.checkUserMentionAndHashtagAndAlterBody(this.inactiveCustomerList);
				}
			});
		}
	}

	/**
	 * Function to get In active customer list
	 */
	private getInProgressCustomerList(): void {
		const state = 'status=released';
		this.inProgressCustomerLoader = true;
		this.networkService.filterCustomers(state, this.sortParams).subscribe((res) => {
			this.inProgressCustomerLoader = false;
			if (res) {
				this.inProgressCount = res.count ? res.count : 0;
				this.inprogressNextUrl = res.next ? res.next.split('alpha')[1] : '';
				this.inprogressCustomerList = res.objects;
				this.checkUserMentionAndHashtagAndAlterBody(this.inprogressCustomerList);
			}
		});
	}

	/**
	 * Function to paginate active customers
	 */
	public paginateInprogressCustomers(): void {
		if (this.inprogressNextUrl) {
			this.inProgressCustomerLoader = true;
			this.networkService.paginateToDoLists(this.inprogressNextUrl).subscribe((res) => {
				this.inProgressCustomerLoader = false;
				if (res) {
					Array.prototype.push.apply(this.inprogressCustomerList, res.objects);
					this.inprogressNextUrl = res.next ? res.next.split('alpha')[1] : '';
					this.checkUserMentionAndHashtagAndAlterBody(this.inprogressCustomerList);
				}
			});
		}
	}

	/**
	 * Function to reset modal on close
	 */
	private resetModal(): void {
		this.newCustomerList = [];
		this.trailCustomerList = [];
		this.activeCustomerList = [];
		this.inactiveCustomerList = [];
		this.inprogressCustomerList = [];
	}

	/**
	 *
	 * Function to show add customer modal
	 */
	public onClickAddCustomer(status): void {
		this.customerListState = status;
		this.showPostCreateForm = true;
		// this.layoutService.showBd('add-customer');
		this.postType = 'add-customer';
	}

	/**
	 * Function to close customer create modal
	 */
	public closeCreateCustomerModal(): void {
		this.postType = '';
		this.showPostCreateForm = false;
	}

	/**
	 * Function to drop in new customer list
	 * @param $event
	 */
	public onDropInNewCustomerList($event): void {
		if (this.checkTaskInLists($event.item.data, this.newCustomerList)) {
			// 1
			if ($event.previousIndex !== $event.currentIndex) {
				moveItemInArray(this.newCustomerList, $event.previousIndex, $event.currentIndex); // 2
				this.reorder(this.newCustomerList); // 3
			}
		} else {
			if ($event.item.data.status === 'fixed') {
				transferArrayItem(this.trailCustomerList, this.newCustomerList, $event.previousIndex, $event.currentIndex);
				this.trialCount = this.trialCount - 1;
				this.newCount = this.newCount + 1;
			} else if ($event.item.data.status === 'closed') {
				transferArrayItem(this.activeCustomerList, this.newCustomerList, $event.previousIndex, $event.currentIndex);
				this.activeCount = this.activeCount - 1;
				this.newCount = this.newCount + 1;
			} else if ($event.item.data.status === 'pool') {
				transferArrayItem(this.inactiveCustomerList, this.newCustomerList, $event.previousIndex, $event.currentIndex);
				this.inActiveCount = this.inActiveCount - 1;
				this.newCount = this.newCount + 1;
			} else if ($event.item.data.status === 'released') {
				transferArrayItem(this.inprogressCustomerList, this.newCustomerList, $event.previousIndex, $event.currentIndex);
				this.inProgressCount = this.inProgressCount - 1;
				this.newCount = this.newCount + 1;
			}
			const previousStatus = $event.item.data['status'];
			$event.item.data['status'] = 'open';
			this.reorder(this.newCustomerList);
			this.updateCustomerStatus($event.item.data, 'open', previousStatus);
		}
	}

	/**
	 * Function to drop in trial customer list
	 * @param $event
	 */
	public onDropInTrailCustomerList($event): void {
		if (this.checkTaskInLists($event.item.data, this.trailCustomerList)) {
			// 1
			if ($event.previousIndex !== $event.currentIndex) {
				moveItemInArray(this.trailCustomerList, $event.previousIndex, $event.currentIndex); // 2
				this.reorder(this.trailCustomerList); // 3
			}
		} else {
			if ($event.item.data.status === 'open') {
				transferArrayItem(this.newCustomerList, this.trailCustomerList, $event.previousIndex, $event.currentIndex);
				this.trialCount = this.trialCount + 1;
				this.newCount = this.newCount - 1;
			} else if ($event.item.data.status === 'closed') {
				transferArrayItem(this.activeCustomerList, this.trailCustomerList, $event.previousIndex, $event.currentIndex);
				this.activeCount = this.activeCount - 1;
				this.trialCount = this.trialCount + 1;
			} else if ($event.item.data.status === 'pool') {
				transferArrayItem(this.inactiveCustomerList, this.trailCustomerList, $event.previousIndex, $event.currentIndex);
				this.inActiveCount = this.inActiveCount - 1;
				this.trialCount = this.trialCount + 1;
			} else if ($event.item.data.status === 'released') {
				transferArrayItem(this.inprogressCustomerList, this.trailCustomerList, $event.previousIndex, $event.currentIndex);
				this.inProgressCount = this.inProgressCount - 1;
				this.trialCount = this.trialCount + 1;
			}
			const previousStatus = $event.item.data['status'];
			$event.item.data['status'] = 'fixed';
			this.reorder(this.trailCustomerList);
			this.updateCustomerStatus($event.item.data, 'fixed', previousStatus);
		}
	}

	/**
	 * Function to drop in active customer list
	 * @param $event
	 */
	public onDropInActiveCustomerList($event): void {
		if (this.checkTaskInLists($event.item.data, this.activeCustomerList)) {
			// 1
			if ($event.previousIndex !== $event.currentIndex) {
				moveItemInArray(this.activeCustomerList, $event.previousIndex, $event.currentIndex); // 2
				this.reorder(this.activeCustomerList); // 3
			}
		} else {
			if ($event.item.data.status === 'open') {
				transferArrayItem(this.newCustomerList, this.activeCustomerList, $event.previousIndex, $event.currentIndex);
				this.activeCount = this.activeCount + 1;
				this.newCount = this.newCount - 1;
			} else if ($event.item.data.status === 'fixed') {
				transferArrayItem(this.trailCustomerList, this.activeCustomerList, $event.previousIndex, $event.currentIndex);
				this.activeCount = this.activeCount + 1;
				this.trialCount = this.trialCount - 1;
			} else if ($event.item.data.status === 'pool') {
				transferArrayItem(this.inactiveCustomerList, this.activeCustomerList, $event.previousIndex, $event.currentIndex);
				this.inActiveCount = this.inActiveCount - 1;
				this.activeCount = this.activeCount + 1;
			} else if ($event.item.data.status === 'released') {
				transferArrayItem(this.inprogressCustomerList, this.activeCustomerList, $event.previousIndex, $event.currentIndex);
				this.inProgressCount = this.inProgressCount - 1;
				this.activeCount = this.activeCount + 1;
			}
			const previousStatus = $event.item.data['status'];
			$event.item.data['status'] = 'closed';
			this.reorder(this.activeCustomerList);
			this.updateCustomerStatus($event.item.data, 'closed', previousStatus);
		}
	}

	/**
	 * Function to drop in inactive customer list
	 * @param $event
	 */
	public onDropInInactiveCustomerList($event): void {
		if (this.checkTaskInLists($event.item.data, this.inactiveCustomerList)) {
			// 1
			if ($event.previousIndex !== $event.currentIndex) {
				moveItemInArray(this.inactiveCustomerList, $event.previousIndex, $event.currentIndex); // 2
				this.reorder(this.inactiveCustomerList); // 3
			}
		} else {
			if ($event.item.data.status === 'open') {
				transferArrayItem(this.newCustomerList, this.inactiveCustomerList, $event.previousIndex, $event.currentIndex);
				this.inActiveCount = this.inActiveCount + 1;
				this.newCount = this.newCount - 1;
			} else if ($event.item.data.status === 'fixed') {
				transferArrayItem(this.trailCustomerList, this.inactiveCustomerList, $event.previousIndex, $event.currentIndex);
				this.inActiveCount = this.inActiveCount + 1;
				this.trialCount = this.trialCount - 1;
			} else if ($event.item.data.status === 'closed') {
				transferArrayItem(this.activeCustomerList, this.inactiveCustomerList, $event.previousIndex, $event.currentIndex);
				this.inActiveCount = this.inActiveCount + 1;
				this.activeCount = this.activeCount - 1;
			} else if ($event.item.data.status === 'released') {
				transferArrayItem(this.inprogressCustomerList, this.inactiveCustomerList, $event.previousIndex, $event.currentIndex);
				this.inProgressCount = this.inProgressCount - 1;
				this.inActiveCount = this.inActiveCount + 1;
			}
			const previousStatus = $event.item.data['status'];
			$event.item.data['status'] = 'pool';
			this.reorder(this.inactiveCustomerList);
			this.updateCustomerStatus($event.item.data, 'pool', previousStatus);
		}
	}

	/**
	 * Function to drop in inprogress customer list
	 * @param $event
	 */
	public onDropInInprogressCustomerList($event): void {
		if (this.checkTaskInLists($event.item.data, this.inprogressCustomerList)) {
			// 1
			if ($event.previousIndex !== $event.currentIndex) {
				moveItemInArray(this.inprogressCustomerList, $event.previousIndex, $event.currentIndex); // 2
				this.reorder(this.inprogressCustomerList); // 3
			}
		} else {
			if ($event.item.data.status === 'open') {
				transferArrayItem(this.newCustomerList, this.inprogressCustomerList, $event.previousIndex, $event.currentIndex);
				this.inProgressCount = this.inProgressCount + 1;
				this.newCount = this.newCount - 1;
			} else if ($event.item.data.status === 'fixed') {
				transferArrayItem(this.trailCustomerList, this.inprogressCustomerList, $event.previousIndex, $event.currentIndex);
				this.inProgressCount = this.inProgressCount + 1;
				this.trialCount = this.trialCount - 1;
			} else if ($event.item.data.status === 'closed') {
				transferArrayItem(this.activeCustomerList, this.inprogressCustomerList, $event.previousIndex, $event.currentIndex);
				this.inProgressCount = this.inProgressCount + 1;
				this.activeCount = this.activeCount - 1;
			} else if ($event.item.data.status === 'pool') {
				transferArrayItem(this.inactiveCustomerList, this.inprogressCustomerList, $event.previousIndex, $event.currentIndex);
				this.inProgressCount = this.inProgressCount + 1;
				this.inActiveCount = this.inActiveCount - 1;
			}
			const previousStatus = $event.item.data['status'];
			$event.item.data['status'] = 'released';
			this.reorder(this.inprogressCustomerList);
			this.updateCustomerStatus($event.item.data, 'released', previousStatus);
		}
	}

	/**
	 * Function to load more customer data while drag and drop is done
	 */
	private loadMoreCustomerList(status): void {
		if (status === 'open') {
			if (this.newCustomerList.length < 7 && this.newNextUrl) {
				this.getNewCustomerList();
			}
		} else if (status === 'fixed') {
			if (this.trailCustomerList.length < 7 && this.trialNextUrl) {
				this.getTrialCustomerList();
			}
		} else if (status === 'closed') {
			if (this.activeCustomerList.length < 7 && this.activeNextUrl) {
				this.getActiveCustomerList();
			}
		} else if (status === 'pool') {
			if (this.inactiveCustomerList.length < 7 && this.inactiveNextUrl) {
				this.getInActiveCustomerList();
			}
		} else if (status === 'released') {
			if (this.inprogressCustomerList.length < 7 && this.inprogressNextUrl) {
				this.getInProgressCustomerList();
			}
		}
	}

	/**
	 * Function to update customer status
	 * @param $event
	 */
	private updateCustomerStatus(customer, status, previousStatus): void {
		if (customer.attachmentDetails) {
			if (customer.attachmentDetails['gallery'] && customer.attachmentDetails['gallery'].length) {
				this.customerModel['images'] = customer.attachmentDetails['gallery'][0].image.uid;
				this.customerModel['attachmentType'] = 'photo';
			} else if (customer.attachmentDetails['location'] && customer.attachmentDetails['location'].length) {
				this.customerModel['location'] = customer.attachmentDetails['location'][0].uid;
				this.customerModel['attachmentType'] = 'location';
			} else if (customer.attachmentDetails['doc'] && customer.attachmentDetails['doc'].length) {
				this.customerModel['doc'] = customer.attachmentDetails['doc'][0].uid;
				this.customerModel['attachmentType'] = 'doc';
			} else if (customer.attachmentDetails['url'] && customer.attachmentDetails['url'].length) {
				this.customerModel['url'] = customer.attachmentDetails['url'][0].url;
				this.customerModel['attachmentType'] = 'url';
			}
		}

		if (customer['mentioned_users'] && customer['mentioned_users'].length > 0) {
			const userList = [];
			for (const u of customer['mentioned_users']) {
				userList.push(u.username);
			}
			this.customerModel['mentioned_users'] = [];
			this.customerModel['mentioned_users'] = userList.join(',');
		}

		if (customer['hashtags'] && customer['hashtags'].length > 0) {
			const tagList = [];
			for (const h of customer['hashtags']) {
				tagList.push(h['name']);
			}
			this.customerModel['hashtags'] = [];
			this.customerModel['hashtags'] = tagList.join(',');
		}

		if (customer['buildingNumber']) {
			this.customerModel['buildingNumber'] = customer['buildingNumber'];
		}
		if (customer['street1']) {
			this.customerModel['street1'] = customer['street1'];
		}
		if (customer['street2']) {
			this.customerModel['street2'] = customer['street2'];
		}
		if (customer['zipCode']) {
			this.customerModel['zipCode'] = customer['zipCode'];
		}
		if (customer['contactNumber']) {
			this.customerModel['contactNumber'] = customer['contactNumber'];
			this.customerModel['countryCode'] = customer['countryCode'];
		}
		if (customer['email']) {
			this.customerModel['email'] = customer['email'];
		}
		if (customer['stateName']) {
			this.customerModel['stateName'] = customer['stateName'];
		}
		if (customer['contactName']) {
			this.customerModel['contactName'] = customer['contactName'];
		}
		if (customer['dealSize']) {
			this.customerModel['dealSize'] = customer['dealSize'];
		}
		if (customer['currency']) {
			this.customerModel['currency'] = customer['currency'];
		}

		if (customer.products && customer.products.length !== 0) {
			const productArray: any[] = [];
			customer.products.forEach((product) => {
				if (product) {
					productArray.push(product);
				}
			});
			this.customerModel['product'] = productArray.join(', ');
		}

		this.customerModel['title'] = customer.title;

		this.customerModel['priority'] = customer.priority;

		this.customerModel['dealType'] = customer.dealType;

		this.customerModel['salesChannel'] = customer.salesChannel;

		this.customerModel['status'] = status;

		// this.loadingGif = 'block';
		this.networkService.editCustomer(customer.uid, this.customerModel).subscribe(
			() => {
				// this.loadingGif = 'none';
				this.loadMoreCustomerList(previousStatus);
				this.customerModel = {
					category: 'customer',
					type: 'customer',
					state: 'active',
					shareable: true,
					commentable: true,
					contentType: 'network',
				};
			},
			() => {
				this.customerModel = {
					category: 'customer',
					type: 'customer',
					state: 'active',
					shareable: true,
					commentable: true,
					contentType: 'network',
				};
			}
		);
	}

	/**
	 * Function to control drag and drop
	 * @param e
	 */
	public enter(e): void {
		const elem = e['container']['element']['nativeElement'];
		elem.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
			inline: 'center',
		});
	}

	/**
	 * For checking if tasks is present in open/closed/inprogress lists
	 * @param task
	 * @param lists
	 */
	private checkTaskInLists(task, lists: any[]): boolean {
		if (lists.length > 0) {
			const index = lists.findIndex((x) => x['uid'] === task['uid']);
			if (index > -1) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	/**
	 * Function to reorder customer list
	 * @param customer
	 */
	public reorder(lists: any[]): void {
		const arr = [];
		const params = {};
		lists.forEach((task, index) => {
			if (task['uid']) {
				const object = { uid: task['uid'], order: index + 1 };
				arr.push(object);
			}
		});
		params['contents'] = JSON.stringify(arr);
		this.loadingGif = 'block';
		this.networkService.reorderCustomerList(params).subscribe(
			() => {
				this.loadingGif = 'none';
			},
			() => {
				this.loadingGif = 'none';
			}
		);
	}

	/**
	 * Function trigger when customer details is updated
	 * @param $event
	 */
	public updatedCustomerEmitterFn($event): void {
		if ($event) {
			if ($event.status === 'open') {
				this.newCustomerList = [];
				this.getNewCustomerList();
			} else if ($event.status === 'fixed') {
				this.trailCustomerList = [];
				this.getTrialCustomerList();
			} else if ($event.status === 'closed') {
				this.activeCustomerList = [];
				this.getActiveCustomerList();
			} else if ($event.status === 'pool') {
				this.inactiveCustomerList = [];
				this.getInActiveCustomerList();
			}
			if ($event.status === 'released') {
				this.inprogressCustomerList = [];
				this.getInProgressCustomerList();
			}
		}

		if (this.editCustomer) {
			if (this.editCustomer.status === 'open') {
				this.newCustomerList = [];
				this.getNewCustomerList();
			} else if (this.editCustomer.status === 'fixed') {
				this.trailCustomerList = [];
				this.getTrialCustomerList();
			} else if (this.editCustomer.status === 'closed') {
				this.activeCustomerList = [];
				this.getActiveCustomerList();
			} else if (this.editCustomer.status === 'pool') {
				this.inactiveCustomerList = [];
				this.getInActiveCustomerList();
			}
			if (this.editCustomer.status === 'released') {
				this.inprogressCustomerList = [];
				this.getInProgressCustomerList();
			}
		}
	}

	/**
	 * Function to show delete customer modal
	 * @param customer
	 */
	public onClickDeleteCustomer(customer, index): void {
		this.selectedCustomer = customer;
		this.selectedCustomerIndex = index;
		this.deleteCustomerModal = 'block';
	}

	/**
	 * Function to return the selected filter name
	 * @returns
	 */
	public getSelectedFilter(): string {
		if (this.filter === 'everything') {
			// Default
			return 'Based on status';
		} else if (this.filter === 'customer_type') {
			return 'By Customer Type';
		} else if (this.filter === 'deal_type') {
			return 'By Deal Type';
		} else if (this.filter === 'sales_channel') {
			return 'By Sales Channel';
		} else if (this.filter === 'product') {
			return 'By Product';
		} else if (this.filter === 'priority') {
			return 'By Priority';
		}
	}

	/**
	 * Function to set the main filter
	 * @param */
	public onChangeFilter(slug): void {
		if (this.filter !== slug) {
			this.filter = slug;
			if (this.filter === 'everything') {
				this.subFilter = '';
				this.sortParams = '';
				this.getAllCustomerList();
			} else {
				this.loadingGif = 'block';
				this.networkService.getGroupedByFilters(slug).subscribe(
					(data) => {
						this.loadingGif = 'none';
						if (slug === 'priority') {
							this.priorities = data['data'];
							this.subFilter = this.priorities[0].priority;
							this.sortParams = 'priority=' + this.priorities[0].slug;
						} else if (slug === 'product') {
							this.products = data['data'];
							this.subFilter = this.products[0].product ? this.products[0].product : 'None';
							if (this.products[0].product) {
								this.sortParams = 'product=' + this.products[0].product;
							} else {
								this.sortParams = '';
							}
						} else if (slug === 'deal_type') {
							this.dealTypes = data['data'];
							this.subFilter = this.dealTypes[0].deal_type ? this.dealTypes[0].deal_type : 'None';
							if (this.dealTypes[0].slug) {
								this.sortParams = 'deal_type=' + this.dealTypes[0].slug;
							} else {
								this.sortParams = '';
							}
						} else if (slug === 'sales_channel') {
							this.salesChannels = data['data'];
							this.subFilter = this.salesChannels[0].sales_channel ? this.salesChannels[0].sales_channel : 'None';
							if (this.salesChannels[0].slug) {
								this.sortParams = 'sales_channel=' + this.salesChannels[0].slug;
							} else {
								this.sortParams = '';
							}
						} else if (slug === 'customer_type') {
							this.customerTypes = data['data'];
							this.subFilter = this.customerTypes[0].customer_type ? this.customerTypes[0].customer_type : 'None';
							if (this.customerTypes[0].slug) {
								this.sortParams = 'customer_type=' + this.customerTypes[0].slug;
							} else {
								this.sortParams = '';
							}
						}
						this.getAllCustomerList();
					},
					() => {}
				);
			}
		}
	}

	/**
	 * Function to return the sub filter
	 * @returns
	 */
	public getSelectedSubFilter(): string {
		if (this.subFilter === 'p1') {
			return 'P1';
		} else if (this.subFilter === 'p2') {
			return 'P2';
		} else if (this.subFilter === 'p3') {
			return 'P3';
		} else if (this.subFilter === 'deal_none') {
			return 'None';
		} else if (this.subFilter === 'post_sales') {
			return 'Post sales';
		} else if (this.subFilter === 'services') {
			return 'Services';
		} else if (this.subFilter === 'sales') {
			return 'Sales';
		} else if (this.subFilter === 'salesChannel_none') {
			return 'None';
		} else if (this.subFilter === 'ads') {
			return 'Ads';
		} else if (this.subFilter === 'search') {
			return 'Search';
		} else if (this.subFilter === 'social_media') {
			return 'Social media';
		} else if (this.subFilter === 'partner') {
			return 'Partner';
		} else if (this.subFilter === 'reference') {
			return 'Reference';
		}
	}

	/**
	 * Function trigger when sub filters is changed
	 * @param subFilter
	 */
	public onChangeSubFilter(item): void {
		if (this.filter === 'priority') {
			this.subFilter = item.priority ? item.priority : 'None';
			this.sortParams = 'priority=' + item.slug;
		} else if (this.filter === 'deal_type') {
			this.subFilter = item.deal_type ? item.deal_type : 'None';
			if (item.slug) {
				this.sortParams = 'deal_type=' + item.slug;
			} else {
				this.sortParams = '';
			}
		} else if (this.filter === 'product') {
			this.subFilter = item.product ? item.product : 'None';
			if (item.product) {
				this.sortParams = 'product=' + item.product;
			} else {
				this.sortParams = '';
			}
		} else if (this.filter === 'sales_channel') {
			this.subFilter = item.sales_channel ? item.sales_channel : 'None';
			if (item.slug) {
				this.sortParams = 'sales_channel=' + item.slug;
			} else {
				this.sortParams = '';
			}
		} else if (this.filter === 'customer_type') {
			this.subFilter = item.customer_type ? item.customer_type : 'None';
			if (item.slug) {
				this.sortParams = 'customer_type=' + item.slug;
			} else {
				this.sortParams = '';
			}
		}

		this.getAllCustomerList();
	}

	// MORE OPTIONS FUNCTIONS //

	/** 31-05-21,customer hashtags handling
	 * Manage redirection to customer detail page
	 * @param e
	 * @param uid
	 */
	public handleRedirection(e, customer): void {
		if (e.target && e.target.className) {
			const type = e.target.className;
			if (type === 'hash-customer') {
				const text = e.target.innerHTML.split('#')[1];
				const hashtags = customer['hashtags'];
				hashtags.forEach((tag) => {
					if (tag['name'] === text) {
						this.router.navigateByUrl('/network/customers?hash_tag=' + tag['uid'] + '&name=' + tag['name']);
					}
				});
			} else {
				if (
					type !== 'pb-icon pb-like' &&
					type !== 'pb-icon pb-liked' &&
					type !== 'pb-icon pb-like ng-star-inserted' &&
					type !== 'pb-icon pb-liked ng-star-inserted' &&
					type !== 'edit-img' &&
					type !== 'dropdown-item dropdown-border text-center' &&
					type !== 'pb-icon todo-radio-off' &&
					type !== 'pb-icon todo-radio-on' &&
					type !== 'pb-icon todo-radio-off ng-star-inserted' &&
					type !== 'pb-icon todo-radio-on ng-star-inserted' &&
					type !== 'dropdown-item dropdown-border text-center ng-star-inserted' &&
					type !== 'pb-icon pb-share'
				) {
					this.router.navigateByUrl('/network/customer/' + customer['uid']);
				}
			}
		} else {
			this.router.navigateByUrl('/network/customer/' + customer['uid']);
		}
	}

	/**
	 * Function to get download link
	 * @param attachment
	 * @returns
	 */
	public getDownloadLink(attachment): string {
		return attachment['url'] + '?cType=' + attachment['type'] + '&file=' + attachment['name'];
	}

	/**
	 * Function to dislike user
	 * @param customer
	 */
	public dislikeCustomer(customer): void {
		this.postService.dislike(this.currentUser.username, customer.myRelations.like).subscribe(() => {
			// 1
			customer.myRelations = { like: null };
			customer['likedText'] = ''; // 2
			customer['likesCount']['count'] = 0;
		});
	}

	/**
	 * Function to like user
	 * @param customer
	 */
	public likeCustomer(customer): void {
		customer.type = 'customer';
		this.postService.like(this.currentUser.username, customer).subscribe((data) => {
			// 1
			customer.myRelations = { like: data.uid }; // 2
			if (customer['myRelations'] && customer['myRelations'].like && customer['likesCount']['count'] === 0) {
				// 3
				customer['likedText'] = this.translate.instant('You liked this');
			}
		});
	}

	/**
	 * Function to share the user link
	 *
	 * @param link string
	 */
	public handleLinkShare(link: string): void {
		this.clipboardService.copy(link);
	}

	/**
	 * Function to create task for customer
	 * @param object
	 */
	public onCreateCustomerTask(object): void {
		const customer = object;
		if (customer['type'] === 'customer') {
			customer['postType'] = 'customer';
			customer['type'] = 'postTodo';
		}
		this.editCustomer = customer;
	}

	/**
	 * Get previous reminders of content
	 */
	public getReminderDetails(customer): void {
		this.loadingGif = 'block';
		this.networkService.getContentReminders('customers', customer['uid']).subscribe((data) => {
			this.loadingGif = 'none';
			this.displayReminders(customer);
			if (data.objects && data.objects.length > 0) {
				data.objects.forEach((element) => {
					if (element['reminderOption']) {
						this.remindersSelected.push(element['reminderOption']);
					}
				});
			}
			this.setPreviousReminders();
		});
	}

	/**
	 * Function to display reminders
	 * @param customer
	 */
	public displayReminders(customer): void {
		this.selectedCustomer = customer;
		this.displayReminderModal = 'block';
		this.reminderOptions = [];
		this.networkService.getReminderOptions().subscribe((data) => {
			this.reminderOptions = data.reminderOptions;
			if (this.remindersSelected.length > 0) {
				this.setPreviousReminders();
			}
		});
	}

	/**
	 * Set Previous reminders on reminder options
	 */
	private setPreviousReminders(): void {
		this.reminderOptions.forEach((reminder) => {
			this.remindersSelected.forEach((r) => {
				if (r === reminder[0]) {
					reminder['checked'] = true;
				}
			});
		});
	}

	/**
	 * Function to edit customer
	 * @param customerObj
	 */
	public onEditCustomer(customerObj): void {
		const customer = customerObj;
		customer.type = 'customer';
		this.selectedCustomerStatus = customer.status;
		this.editCustomer = customer;
	}

	/**
	 * Function to check the user mention and hash tag in post list if exists change the post body
	 * 1.If string contains '<',then its cut off while displaying in innerHtml.Hence its replaced by '< '
	 */
	private checkUserMentionAndHashtagAndAlterBody(customers): void {
		if (customers && customers.length) {
			customers.forEach((customer) => {
				if (customer['title'] && customer['title'].indexOf('<') > -1) {
					// 1
					customer['title'] = customer['title'].replace(/</g, '< ');
				}

				customer['altered_title'] = customer['title'];

				if (customer['mentionedUsers']) {
					customer['mentionedUsers'].forEach((mentionedUser) => {
						const html = '<b #userMention><a class="text-green">@' + mentionedUser['name'] + '</a></b>';
						if (customer.altered_title) {
							customer['altered_title'] = customer.altered_title.replace('@' + mentionedUser['name'], html);
						}
					});
				}
				if (customer['hashtags']) {
					customer['hashtags'].forEach((hashtag) => {
						if (hashtag['name']) {
							const html = '<b #hashTag><a class="hash-customer">#' + hashtag['name'] + '</a></b>';
							if (customer.altered_title) {
								customer['altered_title'] = customer.altered_title.replace('#' + hashtag['name'], html);
							}
						}
					});
				}
			});
		}
	}

	/**
	 * Function to select reminder
	 * @param reminder
	 */
	public onSelectReminder(reminder): void {
		const index = this.remindersSelected.findIndex((r) => r === reminder);
		if (index > -1) {
			this.remindersSelected.splice(index, 1);
		} else {
			this.remindersSelected.push(reminder);
		}
	}

	/**
	 * For creating a new reminders for content
	 */
	public setReminder(): void {
		if (this.remindersSelected.length > 0) {
			const object = {};
			this.remindersSelected.forEach((reminder) => {
				object[reminder] = this.setReminderDate(reminder);
			});
			const param = JSON.stringify(object);
			this.model.contentType = 'customer';
			this.model.contentUid = this.selectedCustomer.uid;
			this.model.username = this.currentUser.username;
			this.model.repeat = true;
			this.model.reminderOption = param;
			this.loadingGif = 'block';
			this.networkService.createReminder(this.model).subscribe((data) => {
				this.selectedCustomer['myRelations']['reminder'] = data['uid'];
				this.loadingGif = 'none';
				this.resetReminders();
				this.reminderToast = true;
				setTimeout(() => {
					this.reminderToast = false;
				}, 8000);
			});
		} else {
			if (this.selectedCustomer['myRelations'] && this.selectedCustomer['myRelations']['reminder']) {
				this.deleteReminders();
			} else {
				this.resetReminders();
			}
		}
	}

	/**
	 * For calculating corresponding to reminder selected
	 * @param option
	 */
	public setReminderDate(option): string {
		if (option === 'in_twenty_minutes') {
			this.timeFor = 20;
		} else if (option === 'in_one_hour') {
			this.timeFor = 60;
		} else if (option === 'in_three_hours') {
			this.timeFor = 180;
		} else if (option === 'tomorrow') {
			this.timeFor = this.minutesUntilMidnight() + 540;
		} else {
			this.timeFor = 6300;
		}
		const date = new Date();
		date.setMinutes(date.getMinutes() + this.timeFor);
		const month = date.getUTCMonth() + 1;
		const day = date.getUTCDate();
		const year = date.getUTCFullYear();
		const hours = date.getUTCHours();
		const minutes = date.getUTCMinutes();
		const seconds = date.getUTCSeconds();
		const utcDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
		return utcDate;
	}

	/**
	 * For resetting reminders in reminder options modal
	 */
	public resetReminders(): void {
		this.displayReminderModal = 'none';
		this.timeFor = 0;
		this.remindersSelected = [];
		this.reminderOptions.forEach((r) => {
			r['checked'] = false;
		});
	}

	public deleteReminders(): void {
		this.model.contentUid = this.selectedCustomer.uid;
		this.model.username = this.currentUser.username;
		this.model.repeat = true;
		this.model.contentType = 'customer';
		this.loadingGif = 'block';
		this.networkService.createReminder(this.model).subscribe(() => {
			this.selectedCustomer['myRelations']['reminder'] = '';
			this.loadingGif = 'none';
			this.resetReminders();
		});
	}

	private minutesUntilMidnight(): any {
		const midnight = new Date();
		midnight.setHours(24);
		midnight.setMinutes(0);
		midnight.setSeconds(0);
		midnight.setMilliseconds(0);
		return (midnight.getTime() - new Date().getTime()) / 1000 / 60;
	}

	/**
	 * Function to delete customer
	 */
	public deleteCustomer(): void {
		this.loadingGif = 'block';
		this.networkService.deleteCustomer(this.selectedCustomer['uid']).subscribe(() => {
			this.deleteCustomerModal = 'none';
			this.loadingGif = 'none';
			if (this.selectedCustomer.status === 'open') {
				this.newCustomerList.splice(this.selectedCustomerIndex, 1);
				this.newCount = this.newCount - 1;
			} else if (this.selectedCustomer.status === 'fixed') {
				this.trailCustomerList.splice(this.selectedCustomerIndex, 1);
				this.trialCount = this.trialCount - 1;
			} else if (this.selectedCustomer.status === 'closed') {
				this.activeCustomerList.splice(this.selectedCustomerIndex, 1);
				this.activeCount = this.activeCount - 1;
			} else if (this.selectedCustomer.status === 'pool') {
				this.inactiveCustomerList.splice(this.selectedCustomerIndex, 1);
				this.inActiveCount = this.inActiveCount - 1;
			} else if (this.selectedCustomer.status === 'released') {
				this.inprogressCustomerList.splice(this.selectedCustomerIndex, 1);
				this.inProgressCount = this.inProgressCount - 1;
			}
			this.selectedCustomer = '';
			this.selectedCustomerIndex = '';
		});
	}

	// #region UI Interactions

	public showInLightbox(post: any): void {
		const images = post.attachmentDetails?.gallery?.map((imageItem: any) => {
			const image = imageItem.image;

			return new ImageAttachment(<IImageAttachment>{
				id: image.uid,
				name: image.name,
				type: image.type,
				url: image.url,
				data: image,
			});
		});

		this.lightboxService.initLightbox(images);
	}

	// #endregion
}
