import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class PageLoadingUiService {
	@Output() public pageLoadingChanged = new EventEmitter<boolean>();

	public isPageLoading = false;

	constructor() {}

	public setPageLoading(isLoading: boolean): void {
		this.isPageLoading = isLoading;

		this.pageLoadingChanged.emit(isLoading);
	}
}
