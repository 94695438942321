import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NetworkService, LayoutService } from '../../shared';
import { TranslateService } from '@ngx-translate/core';

// TODO: deprecated
// declare function salesTrack(obj): any;

@Component({
	selector: 'app-purchase-status',
	templateUrl: './purchase-status.component.html',
	styleUrls: ['./purchase-status.component.css'],
})
export class PurchaseStatusComponent implements OnInit {
	public paymentIntentId;
	public paymentStatusDetails;
	public billingDetails;
	public amount: number;
	public transactionId: any;
	public isLoading = false;
	public email: any = '';
	public renewalFrequency: any = '';
	public invoicePdf: any;
	public errorText: any;
	public numberOfSeats: any;

	constructor(
		private activatedRoute: ActivatedRoute,
		private translate: TranslateService,
		private router: Router,
		public networkService: NetworkService,
		public layoutService: LayoutService
	) {}

	ngOnInit(): void {
		// Get payment intent id
		this.activatedRoute.queryParams.subscribe((params) => {
			this.transactionId = params['id'];
		});
		this.getTransactionDetails();
	}

	private getTransactionDetails(): void {
		this.isLoading = true;
		this.networkService.getTransactionDetails(this.transactionId).subscribe(
			(data) => {
				this.paymentStatusDetails = data[0];

				// For Sales tracking function
				// const object = {
				// 	amount: this.paymentStatusDetails['amount'],
				// 	orderId: this.paymentStatusDetails['uid'],
				// 	productId: this.paymentStatusDetails['subscriptionPlan']['uid'],
				// 	customer: this.paymentStatusDetails['user']['userId'],
				// };
				// salesTrack(object);

				if (this.paymentStatusDetails.amount) {
					this.amount = this.paymentStatusDetails.amount / 100;
				}
				if (this.paymentStatusDetails.subscriptionStripeData && this.paymentStatusDetails.subscriptionStripeData.interval) {
					const interval = this.paymentStatusDetails.subscriptionStripeData.interval;
					if (interval === 'month') {
						this.renewalFrequency = 'Monthly';
					} else {
						this.renewalFrequency = 'Yearly';
					}
				}
				if (this.paymentStatusDetails.subscriptionStripeData && this.paymentStatusDetails.subscriptionStripeData.invoice) {
					const invoice = this.paymentStatusDetails.subscriptionStripeData.invoice;
					this.invoicePdf = invoice.invoicePdf;
					this.numberOfSeats = this.paymentStatusDetails.subscriptionStripeData['numberOfSeats'];
				}
				this.email = this.paymentStatusDetails?.user?.email ? this.paymentStatusDetails?.user?.email : null;
				// if (this.paymentStatusDetails['user'] && this.paymentStatusDetails['user']['username']) {
				// 	this.email = this.paymentStatusDetails['user']['username'];
				// }
				this.networkService.paymentDetails = '';
				this.networkService.purchasePlan = '';
				localStorage.removeItem('currentPurchasePlan');
				localStorage.removeItem('purchasePlans');
				localStorage.removeItem('chosenPlan');
				localStorage.removeItem('currentSubscriptionStatus');
				// localStorage.setItem('payment_status', 'success');
				this.isLoading = false;
			},
			() => {
				this.isLoading = false;
				this.errorText = this.translate.instant('Something went wrong)');
			}
		);
	}

	public downloadReceipt(): void {
		if (this.invoicePdf) {
			window.open(this.invoicePdf);
		}
	}
}
