import { Injectable, EventEmitter } from '@angular/core';
import { timer } from 'rxjs';

@Injectable()
export class LayoutService {
	public showBackdrop: EventEmitter<any> = new EventEmitter();
	public clickOutside: EventEmitter<any> = new EventEmitter();
	public postFilterList: EventEmitter<any> = new EventEmitter();

	public layouts: any[] = [];
	public displayBackdrop = false;
	public isMobile = false;
	public isAndroid = false;
	public isIos = false;

	/**
	 * Function  to show back drops and push the layout to layouts array
	 *
	 * @param layout
	 */
	public showBd(layout: any): void {
		if (!this.layouts.includes(layout)) {
			this.layouts.push(layout);
		}

		this.displayBackdrop = true;
		this.showBackdrop.emit('show');
	}

	/**
	 * Function to hide back drops and pop the layout from layouts array
	 *
	 * @param layout
	 */
	public hideBd(event = ''): void {
		if (!event || event['srcElement'].className === 'modal pb-modal') {
			this.showBackdrop.emit(this.layouts.pop());

			if (this.layouts.length === 0) {
				this.displayBackdrop = false;
				this.showBackdrop.emit('hide');
			}
		}
	}

	/**
	 * Function to listen to  click event all over the page
	 * @param event
	 */
	public onPageClick(event): void {
		this.clickOutside.emit(event);
	}

	/**
	 * Function to scroll page to the top
	 * @param event
	 */
	public scrollToTop(): void {
		timer(10).subscribe(() =>
			window.scroll({
				top: 0,
				left: 0,
				behavior: 'smooth',
			})
		);
	}

	/**
	 * Function to listen to  click event all over the page
	 * @param event
	 */
	public onFilterBtnClick(event): void {
		this.postFilterList.emit(event);
	}

	public unsetMobileDetect(): void {
		this.isMobile = false;
		this.isIos = false;
		this.isAndroid = false;
	}

	public detectMob(): any {
		this.isMobile = false;
		this.isAndroid = false;
		// this.isIos = false;

		if (
			navigator.userAgent.match(/Android/i) ||
			navigator.userAgent.match(/webOS/i) ||
			navigator.userAgent.match(/iPhone/i) ||
			navigator.userAgent.match(/iPad/i) ||
			navigator.userAgent.match(/iPod/i) ||
			navigator.userAgent.match(/BlackBerry/i) ||
			navigator.userAgent.match(/Windows Phone/i)
		) {
			this.isMobile = true;
		} else {
			this.isMobile = false;
		}

		if (navigator.userAgent.match(/Android/i)) {
			this.isAndroid = true;
		} else if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i)) {
			this.isIos = true;
		}

		return {
			isMobile: this.isMobile,
			isAndroid: this.isAndroid,
			isIos: this.isIos,
			isChrome: this.isChrome(),
		};
	}

	public isChrome(): boolean {
		return false;

		const isChromium = (window as any).chrome,
			winNav = window.navigator,
			vendorName = winNav.vendor,
			isOpera = typeof (window as any).opr !== 'undefined',
			isIEdge = winNav.userAgent.indexOf('Edge') > -1,
			// eslint-disable-next-line spellcheck/spell-checker
			isIOSChrome = winNav.userAgent.match('CriOS');

		if (isIOSChrome) {
			return true;
		} else if (isChromium !== null && typeof isChromium !== 'undefined' && vendorName === 'Google Inc.' && isOpera === false && isIEdge === false) {
			return true;
		} else {
			return false;
		}

		return false;
	}
}
