import { throwError as observableThrowError, Observable } from 'rxjs';
import { map, catchError, share } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import CacheUtils from '../utils/cache-utils';

@Injectable()
export class LocationService {
	private client_id = 'YQQ4GSTIHB0IO3M42U4VOMTJLHNKFUR1WZWWMK53RTY1NXIQ';
	private client_secret = 'KMPPG5M5FWKEJYNHGR1W2VFCMWWNQZEFS0K2YDROPLOFGU5E';

	public locations = [];
	public nextUrl = '';
	public scrollPosition = 0;

	private nearMeLocationsObservable: Observable<any>;

	constructor(private apiService: ApiService, private http: HttpClient) {}

	// eslint-disable-next-line spellcheck/spell-checker
	public getCheckins(url?, filterType?): Observable<any> {
		if (filterType) {
			return this.apiService.get(url + '?filter=' + filterType);
		} else {
			return this.apiService.get(url);
		}
	}

	private formatErrors(error: any): any {
		return observableThrowError(error.json());
	}

	/**
	 * FIXME: this method is not used anywhere, why?
	 */
	public getForsquare(lat, lang, q): Observable<any> {
		const url =
			'https://api.foursquare.com/v2/venues/explore?locale=en&client_id=' +
			this.client_id +
			'&ll=' +
			lat +
			',' +
			lang +
			'&v=20180205&client_secret=' +
			this.client_secret +
			'&query=' +
			q;

		return this.http.get(url).pipe(
			catchError(this.formatErrors),
			map((res: any) => res.json())
		);
	}

	/**
	 * Get near by locations with foursquare api
	 *
	 * @param coords any
	 * @param query string
	 */
	public getNearMeLocations(coordinates: any, query: string = ''): Observable<any> {
		if (this.nearMeLocationsObservable) {
			return this.nearMeLocationsObservable;
		}

		// #region Get data from cache

		const storedData = CacheUtils.getStoredData('nearMeLocations');

		if (storedData) {
			return new Observable((observer) => {
				observer.next(storedData);
				observer.complete();
			});
		}

		// #endregion

		const httpQuery = {
				locale: 'en',
				v: '20180205',
				query: query,
				client_id: this.client_id,
				client_secret: this.client_secret,
				ll: `${coordinates.latitude},${coordinates.longitude}`,
			},
			queryParams = new URLSearchParams(httpQuery).toString(),
			apiUrl = `https://api.foursquare.com/v2/venues/explore?${queryParams}`;

		this.nearMeLocationsObservable = this.http.get(apiUrl).pipe(
			catchError(this.formatErrors),
			map((res: any) => res),
			share()
		);

		// put data in the store
		this.nearMeLocationsObservable.subscribe((res: any) => {
			CacheUtils.storeData('nearMeLocations', res, 1000 * 60 * 60);
		});

		return this.nearMeLocationsObservable;
	}

	public paginate(url): Observable<any> {
		return this.apiService.get(url);
	}

	public create(data): Observable<any> {
		data = JSON.parse(JSON.stringify(data));
		return this.apiService.post('/locations/', data);
	}

	public resetLocation(): void {
		this.locations = [];
		this.nextUrl = '';
		this.scrollPosition = 0;
	}

	public getLocations(): Observable<any> {
		// eslint-disable-next-line spellcheck/spell-checker
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/locationcategories/');
	}
}
