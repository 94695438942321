import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HomeComponent } from './home.component';
import { HomeAuthResolver } from './home-auth-resolver.service';
import { SharedModule } from '../shared';
import { SafePipe } from '../shared';
import { JoinNetworkComponent } from './join-network/join-network.component';
import { UserHomeComponent } from './user-home/user-home.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { SingleNetworkGuard } from '../shared/services/single-network.guard';
import { UpdateRoleComponent } from './update-role/update-role.component';
import { JoinWorkComponent } from './join-work/join-work.component';
import { NetworkExpiredComponent } from './network-expired/network-expired.component';
import { ZapierComponent } from '../zapier/zapier.component';
import { HomeModule as NewHomeModule } from '../modules/home/home.module';
import { CommonModule } from '@angular/common';

const homeRouting: ModuleWithProviders<any> = RouterModule.forChild([
	{
		path: '',
		component: HomeComponent,
		resolve: {
			isAuthenticated: HomeAuthResolver,
		},
		children: [
			{
				path: '',
				canActivate: [SingleNetworkGuard],
				component: UserHomeComponent,
			},
			{ path: 'join', component: JoinNetworkComponent },
			{ path: 'settings', component: UserSettingsComponent },
			{ path: 'update-role', component: UpdateRoleComponent },
			{ path: 'join-work', component: JoinWorkComponent },
			{ path: 'expired', component: NetworkExpiredComponent },
			{ path: 'zapier', component: ZapierComponent },
		],
	},
]);
// ant design modules
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';

@NgModule({
	imports: [
		homeRouting,
		CommonModule,
		BrowserModule,
		SharedModule,
		NewHomeModule,

		// nz modules
		NzButtonModule,
		NzIconModule,
		NzModalModule,
	],
	declarations: [HomeComponent, SafePipe, JoinNetworkComponent, UserHomeComponent, UserSettingsComponent, UpdateRoleComponent, JoinWorkComponent, NetworkExpiredComponent],
	providers: [HomeAuthResolver],
})
export class HomeModule {}
