import { Component, Input, OnInit } from '@angular/core';
import { IPersonAttachment } from '../../shared/models/person-attachment';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'bee-person-preview',
	templateUrl: './person-preview.component.html',
	styleUrls: ['./person-preview.component.scss'],
})
export class PersonPreviewComponent implements OnInit {
	@Input() public person: IPersonAttachment;
	@Input() public defaultAvatar = 'assets/images/default_avatar.jpg';

	constructor() {}

	ngOnInit(): void {}
}
