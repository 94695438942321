/* eslint-disable spellcheck/spell-checker */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import mixpanel from 'mixpanel-browser';
import ja from '@angular/common/locales/ja';
import { registerLocaleData } from '@angular/common';


if (environment.production) {
	enableProdMode();

	// #region disable console debugging methods

	// if (window) {
	// 	if (!window.console) {
	// 		window.console = <any>{};
	// 	}

	// 	const methods: any[] = ['log', 'debug', 'warn', 'info'];

	// 	for (let i = 0; i < methods.length; i++) {
	// 		// eslint-disable-next-line no-console
	// 		console[methods[i]] = function (): void {};
	// 	}
	// }

	// #endregion

	/**
	 * Sentry Integration
	 */
	Sentry.init({
		dsn: 'https://091418aa468740e79b863b0b9efecc7d@o1406566.ingest.sentry.io/4503943345537024',

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		// replaysSessionSampleRate: 0.1,
		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		// replaysOnErrorSampleRate: 1.0,

		integrations: [
			new BrowserTracing({
				tracingOrigins: ['localhost', 'https://yourserver.io/api'],
				routingInstrumentation: Sentry.routingInstrumentation,
			}),
			// new Sentry.Replay({
			// 	maxEvents: 50,
			// 	maxSessions: 50,
			// 	maxErrors: 50,
			// 	maxTransactions: 50,
			// 	maxSpans: 50,
			// 	maxBreadcrumbs: 50,
			// }),
		],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	});

	const storageUserDetails: any = localStorage.getItem('userDetails') || null,
		storageNetworkId: any = localStorage.getItem('network_id') || null;

	if (storageUserDetails && storageNetworkId) {
		const _storageUserDetails = JSON.parse(storageUserDetails);

		Sentry.setUser({
			email: _storageUserDetails.email,
			fullName: `${_storageUserDetails.firstName} ${_storageUserDetails.lastName}`,
			username: _storageUserDetails.username,
			networkId: storageNetworkId,
			version: environment.version,
		});
	}

	/**
	 * MixPanel Integration
	 */
	mixpanel.init('4f45325cf51981ec9dc2a4edfe1977de', {
		loaded: function (mix) {
			console.log('distinct_id', mix.get_distinct_id());
			console.log('user_id', mixpanel.get_property('user_id'));
		},
	});

	platformBrowserDynamic()
		.bootstrapModule(AppModule)
		.catch((err: any) => console.error(err));
} else {
	platformBrowserDynamic()
		.bootstrapModule(AppModule)
		.catch((err) => console.log(err));
}

registerLocaleData(ja);
