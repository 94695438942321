import { Routes } from '@angular/router';
import { HomeAuthResolver } from './home/home-auth-resolver.service';
import { SubscriptionGuard } from '@core/services/guards/subscription.guard';
import { QuickChatGuardGuard } from '@modules/chat/components/quick-chat/gaurds/quick-chat-guard.guard';

// eslint-disable-next-line spellcheck/spell-checker
// other routes can be found in the src\app\network\network.module.ts

const appRoutes: Routes = [
	{
		path: '',
		resolve: {
			isAuthenticated: HomeAuthResolver,
		},

		children: [
			{
				path: 'auth',
				loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
			},
			{
				path: 'authentication',
				loadChildren: () => import('./modules/new-auth/new-auth.module').then((m) => m.NewAuthModule),
			},
			{
				path: 'project',
				canLoad: [SubscriptionGuard],
				canActivate: [SubscriptionGuard],
				loadChildren: () => import('./modules/project/project.module').then((m) => m.ProjectModule),
			},
			{
				path: 'chat',
				canLoad: [SubscriptionGuard],
				canActivate: [SubscriptionGuard],
				loadChildren: () => import('./modules/chat/chat.module').then((m) => m.ChatModule),
			},
			{
				path: 'home',
				loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
			},
			{
				path: 'payment',
				loadChildren: () => import('./modules/payment/payment.module').then((m) => m.PaymentModule),
			},
			{
				path: 'member',
				canLoad: [SubscriptionGuard],
				canActivate: [SubscriptionGuard],
				loadChildren: () => import('./modules/member/member.module').then((m) => m.MemberModule),
			},
			{
				path: 'custom-flows',
				canLoad: [SubscriptionGuard],
				canActivate: [SubscriptionGuard],
				loadChildren: () => import('./../../projects/custom-flows/src/lib/custom-flows.module').then((m) => m.CustomFlowsModule),
			},
			{
				path: 'ai',
				canLoad: [SubscriptionGuard],
				canActivate: [SubscriptionGuard],
				loadChildren: () => import('./modules/ai/ai.module').then((m) => m.AiModule),
			},
			{
				path: 'workspace',
				canActivate: [QuickChatGuardGuard],
				loadChildren: () => import('./modules/workspace/workspace.module').then((m) => m.WorkspaceModule),
			},
			{
				path: 'forms',
				loadChildren: () => import('./../../projects/custom-flows/src/lib/modules/sharable-forms/sharable-forms.module').then((m) => m.SharableFormsModule),
			},
			{
				path: 'integrations',
				loadChildren: () => import('./modules/integrations/integrations.module').then((m) => m.IntegrationsModule),
			},
		],
	},
];

// @NgModule({
// 	imports: [RouterModule.forRoot(routes, { useHash: false })],
// 	exports: [RouterModule],
// })
// export class AppRoutingModule {}

export default appRoutes;
