import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class StartEndDayService {
	@Output() public updateDayStatusEvent: EventEmitter<any> = new EventEmitter<boolean>();
	@Output() public showTodosEvent: EventEmitter<any> = new EventEmitter<boolean>();
	@Output() public toggleStarDayEvent: EventEmitter<any> = new EventEmitter<boolean>();

	constructor() {}

	public updateDayStatus(status: boolean): void {
		this.updateDayStatusEvent.emit(status);
	}

	public showTodos(type: string): void {
		this.showTodosEvent.emit(type);
	}

	public toggleStarDay(status: boolean): void {
		this.toggleStarDayEvent.emit(status);
	}
}
