import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService, NetworkService, ProductService, PaginationService } from '../../shared';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-archive-products',
	templateUrl: './archive-products.component.html',
	styleUrls: ['./archive-products.component.css'],
})
export class ArchiveProductsComponent implements OnInit, OnDestroy {
	public isFetching = 0;
	public products = [];
	public departments = [];
	public personalFilters = [];
	public SubscriptionTemplates = [];
	public selectByLevelValues: any[] = [];
	public value;
	public isPaginating = false;
	public next_url;
	public networkLoaded;
	public network;
	public paginating = false;
	public currentUser;
	public toggle = {};
	private currentDepartmentSubscriber;
	private departmentsReady;
	public paginateSub$: any;
	private networkSubscriber;
	private currentNetSub;

	public appLogo = environment.logo;

	constructor(
		private networkService: NetworkService,
		private router: Router,
		private productService: ProductService,
		private userService: UserService,
		private translate: TranslateService,
		private location: Location,
		private paginationService: PaginationService
	) {
		this.paginationService.scrollDistance = 8;
		this.paginateSub$ = this.paginationService.initiateScroll.subscribe((data) => {
			if (data && !this.paginating) {
				this.paginate();
			}
		});
	}

	ngOnInit(): void {
		this.networkSubscriber = this.networkService.networkReady.subscribe((networkLoaded) => {
			this.networkLoaded = networkLoaded;
			if (this.networkLoaded) {
				this.currentNetSub = this.networkService.currentNetwork.subscribe((network) => {
					this.network = network;
					this.currentUser = this.userService.getCurrentUser();

					this.departmentsReady = this.networkService.departmentsReady.subscribe((departmentsLoaded) => {
						if (departmentsLoaded) {
							this.currentDepartmentSubscriber = this.networkService.currentDepartments.subscribe((departments) => {
								if (departments && departments['objects'].length > 0) {
									this.departments = departments.objects;
								}
							});
						}
					});

					this.getArchivedProducts();
				});
			}
		});
	}

	ngOnDestroy(): void {
		if (this.departmentsReady) {
			this.departmentsReady.unsubscribe();
		}
		if (this.currentDepartmentSubscriber) {
			this.currentDepartmentSubscriber.unsubscribe();
		}
		if (this.networkSubscriber) {
			this.networkSubscriber.unsubscribe();
		}
		if (this.currentNetSub) {
			this.currentNetSub.unsubscribe();
		}
		if (this.paginateSub$) {
			this.paginateSub$.unsubscribe();
		}
	}

	private getArchivedProducts(): void {
		this.isFetching = 0;
		this.products = [];
		this.productService.getArchivedProducts().subscribe((data) => {
			this.isFetching = 1;
			this.products = data.objects;
			this.calculateBarIndicator();

			this.next_url = data.next ? data.next.split('alpha')[1] : '';
		});
	}

	/**
	 * Function for handling pagination of products
	 * 1. Check if next url exists
	 * 2. Call api to get remaining products
	 * 3. Append new products to products list
	 * 4. Store next url if exists
	 */
	public paginate(): void {
		if (this.next_url) {
			// 1
			this.paginating = true;
			this.isFetching = 0;
			this.networkService.paginateIssues(this.next_url).subscribe((data) => {
				// 2
				this.products = this.products.concat(data.objects); // 3
				this.calculateBarIndicator();
				this.paginating = false;
				this.isFetching = 1;

				this.next_url = data.next ? data.next.split('alpha')[1] : '';
			});
		}
	}

	/**
	 * Get product state counts
	 * @param prod
	 * 1. Check if product cell is not collapsed
	 * 2. Call api to get counts
	 * 3. Set counts of state within product
	 * 4. Set collapse false after a sec to show shimmer loader correctly
	 */
	public getProductCounts(prod): void {
		if (!prod['collapse']) {
			// 1
			this.productService.getProductCount(prod['uid']).subscribe((data) => {
				// 2
				this.products.forEach((product) => {
					if (product['uid'] === prod['uid']) {
						// 3
						product['collapse'] = true;
						product['closedCount'] = data['closedCount'];
						product['fixedCount'] = data['fixedCount'];
						product['inProgressCount'] = data['inProgressCount'];
						product['openCount'] = data['openCount'];
						product['poolCount'] = data['poolCount'];
						product['releasedCount'] = data['releasedCount'];
						product['poolCount'] = data['poolCount'];
					}
				});
			});
		} else {
			setTimeout(() => {
				prod['collapse'] = false; // 4
			}, 500);
		}
	}

	private calculateBarIndicator(): void {
		this.products.forEach((product) => {
			if (product['barIndicator']) {
				const fixed = product['barIndicator']['fixed'];
				const open = product['barIndicator']['open'];
				const net = product['barIndicator']['net'];

				const bar = fixed + open + net;
				product['bar'] = bar;
			}
		});
	}

	/**
	 * Function to get the department name based on department Id.
	 * Used in template
	 * @param value
	 */
	public getDepartment(value): void {
		const index = this.departments.findIndex((department) => department.uid === value);
		if (index !== -1) {
			return this.departments[index].name;
		}
	}

	/**
	 * Function to show post cp names of personal filters/memberships
	 * @param value
	 * 1. If content privacy(value) is status,show it.
	 * 2. If content privacy(value) is renewal status,show it.
	 * 3. Check if content privacy(value) is membershipLevel
	 * 4. Get membership level name from array
	 * 5. If value is manager,show cp as myTeam
	 * 6. If value is in personal filters,show it
	 */
	public getPostNames(value): string {
		if (value.status) {
			return value.status; // 1
		} else if (value.renewalStatus) {
			return value.renewalStatus; // 2
		} else if (value.membership_level) {
			// 3
			let name = '';
			this.selectByLevelValues.forEach((object) => {
				if (object.uid === value.membership_level) {
					name = object.name; // 4
				}
			});
			return name;
		} else if (value.manager) {
			return this.translate.instant('My Team'); // 5
		} else {
			this.value = Object.values(value);
			const index = this.personalFilters.findIndex((field) => field.value === this.value);
			if (index !== -1) {
				return this.personalFilters[index].valueLabel; // 6
			}
		}
	}

	public onClickBack(): void {
		this.location.back();
	}

	public navigateToDetail(product): void {
		const url = `/network/product-detail?id=${product.uid}&archived=true`;
		this.router.navigateByUrl(url);
	}
}
