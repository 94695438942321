import { IAppResponse } from './../models/app';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpMethod } from '@core/types/http-method.enum';
import { Observable } from 'rxjs';
import { BaseApiService } from 'projects/peer-core/src/lib/services/base/base-api.service';

@Injectable({
	providedIn: 'root',
})
export class AppService {
	private workspaceId = 'not_set_ws_id';
	private baseEndpoint = 'networks/';

	public tabSelectedIndex = 0;

	@Output() public appCreated: EventEmitter<IAppResponse> = new EventEmitter<IAppResponse>();
	@Output() public appDeleted: EventEmitter<IAppResponse> = new EventEmitter<IAppResponse>();
	@Output() public appEdited: EventEmitter<IAppResponse> = new EventEmitter<IAppResponse>();
	@Output() public appSwitched: EventEmitter<any> = new EventEmitter<any>();

	constructor(private baseApiService: BaseApiService) {}

	public init(workspaceId: string): void {
		this.workspaceId = workspaceId;

		this.baseEndpoint = `networks/${this.workspaceId}/apps`;
	}

	public get(appId: string): Observable<any> {
		return this.baseApiService.makeRequest(HttpMethod.GET, `${this.baseEndpoint}/${appId}/`);
	}

	public getAll(offset: number = 0, limit: number = 100): Observable<any> {
		return this.baseApiService.makeRequest(HttpMethod.GET, `${this.baseEndpoint}/?offset=${offset}&limit=${limit}`);
	}

	public create(data: any): Observable<any> {
		return this.baseApiService.makeRequest(HttpMethod.POST, `${this.baseEndpoint}/`, data);
	}

	public update(appId: string, data: any): Observable<any> {
		return this.baseApiService.makeRequest(HttpMethod.PUT, `${this.baseEndpoint}/${appId}/`, data);
	}

	public patch(appId: string, data: any): Observable<any> {
		return this.baseApiService.makeRequest(HttpMethod.PATCH, `${this.baseEndpoint}/${appId}/`, data);
	}

	public delete(appId: string): Observable<any> {
		return this.baseApiService.makeRequest(HttpMethod.DELETE, `${this.baseEndpoint}/${appId}/`);
	}

	public getSimplified(appId: string): Observable<any> {
		return this.baseApiService.makeRequest(HttpMethod.GET, `shared-forms/${appId}/`);
	}

	public createTemplate(data: any, appId: string): Observable<any> {
		return this.baseApiService.makeRequest(HttpMethod.POST, `${this.baseEndpoint}/${appId}/create-template/`, data);
	}

	public getTemplates(offset: number = 100, limit: number = 100): Observable<any> {
		return this.baseApiService.makeRequest(HttpMethod.GET, `templates/?offset=${offset}&limit=${limit}`);
	}

	public getDepartments(search = '', offset: number = 0, limit: number = 100): Observable<any> {
		return this.baseApiService.makeRequest(HttpMethod.GET, `templates/departments/?offset=${offset}&limit=${limit}&q=${search}`);
	}

	public getIndustries(search = '', offset: number = 0, limit: number = 100): Observable<any> {
		return this.baseApiService.makeRequest(HttpMethod.GET, `templates/industries/?offset=${offset}&limit=${limit}&q=${search}`);
	}

	public updateTemplate(templateId: string): Observable<any> {
		return this.baseApiService.makeRequest(HttpMethod.PUT, `templates/${templateId}/`, {});
	}

}
