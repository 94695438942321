import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { UserService, NetworkService, LocationService, GeoLocationService } from '../services';
import { LocationObj, Network } from '../models';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-join-network-forms',
	templateUrl: './join-network-forms.component.html',
	styleUrls: ['./join-network-forms.component.css'],
})
export class JoinNetworkFormsComponent implements OnInit {
	@Input() public networksTypes: any;
	@Input() public validationPendingNetwork: any;

	public toast_message = 'none';
	public userDetails;
	public searchDisplay = false;
	public roleSelectModal = 'none';
	public searchModalTitle = '';
	public searchInputLabel = '';
	public searchInputPlaceholder = '';
	public selectedNetworkType = '';
	public selectedNetwork: any = null;
	public networkDetails: any = null;
	public networks: any = null;
	public searchValue: string = null;
	public showSpin = false;
	public showErrorMessage = false;
	public searchClickStatus = false;
	public displayErrorModal = 'none';
	public selectedRole: any = null;
	public roleFields: any;
	public roleDetailsModel = 'none';
	public showAlreadyMemberErrorMessage = false;
	public roleDetails: any[] = [];
	public locationsList;
	public location;
	public locations;
	public currentLocation;
	public locationDisplay = 'none';
	public params: any;
	public email: string;
	public showEmailValidationModal = 'none';
	public validationRule: any;
	public departments: any;
	public selectedDepartment: any;
	public membershipId: any;
	public errorMessage: string = null;
	public showEmailSuccessModel = 'none';
	public verificationEmail: string = null;
	public requests: any;
	public showRequest: boolean;
	public showExtraNetworkTypes;
	public showNetworkTypes = true;
	public showPendingApproval = false;
	public fromMobile = false;

	constructor(
		private userService: UserService,
		public networkService: NetworkService,
		public locationService: LocationService,
		public geoLocationService: GeoLocationService,
		private ref: ChangeDetectorRef,
		private router: Router,
		private translateService: TranslateService
	) {
		this.userDetails = this.userService.getCurrentUser();
	}

	ngOnInit(): void {
		// For optimizing initial startup time
		// network settings are stored to localstorage on login and reused from LS afterwards
		if (localStorage.getItem('network_settings')) {
			const networkSettings = JSON.parse(localStorage.getItem('network_settings'));

			this.networkDetails = networkSettings;
			//
		} else {
			this.networkService.getNetworkTypes().subscribe((data) => {
				localStorage.setItem('network_settings', JSON.stringify(data));
				this.networkDetails = data;
				//
			});
		}
		//

		const self = this;
		let locations = [];
		const accuracy = { enableHighAccuracy: true };

		self.geoLocationService.getLocation(accuracy).subscribe((position: any) => {
			self.currentLocation = position;

			self.locationService.getNearMeLocations(position.coords).subscribe((data) => {
				locations = data.response.groups[0].items.map((loc) => new LocationObj(loc, '4sq'));

				self.locations = self.sortData(locations);
			});

			self.ref.detectChanges();
		});

		this.networkService.validationPendingNetwork.subscribe((data) => {
			if (data) {
				this.onNetworkSelect(data);
			}
		});
	}

	private onSelectNetworkType(type): void {
		this.departments = null;
		this.searchClickStatus = false;
		this.selectedNetwork = null;
		this.networks = null;
		this.searchValue = null;
		this.selectedNetworkType = type;
		switch (type) {
			case 'school':
				this.searchModalTitle = this.translateService.instant('Join') + ' ' + this.translateService.instant('School');
				this.searchInputLabel = this.translateService.instant('Find and join your school by Google Search');
				this.searchInputPlaceholder = 'Example: my high school';
				if (this.networkDetails.school.discovery === 'List') {
					this.showSpin = true;
					this.getNetworks(type);
				}
				break;
			case 'university':
				this.searchModalTitle = this.translateService.instant('Join') + ' ' + this.translateService.instant('University');
				this.searchInputLabel = this.translateService.instant('Find and join your university by Domain');
				this.searchInputPlaceholder = 'john@company.com';
				this.showNetworkSearchBox();
				if (this.networkDetails.university.discovery === 'List') {
					this.showSpin = true;
					this.getNetworks(type);
				}
				break;
			case 'community':
				this.searchModalTitle = this.translateService.instant('Join') + ' ' + this.translateService.instant('Community');
				this.searchInputLabel = this.translateService.instant('Find and join your Community by network');
				this.searchInputPlaceholder = 'Search item';
				if (this.networkDetails.community.discovery === 'List') {
					this.showSpin = true;
					this.getNetworks(type);
				}
				break;
			case 'neighborhood':
				this.searchModalTitle = this.translateService.instant('Join') + ' ' + this.translateService.instant('Neighborhood');
				this.searchInputLabel = this.translateService.instant('Find and join your Neighborhood by PostCode');
				this.searchInputPlaceholder = 'Example: 965508';
				if (this.networkDetails.neighborhood.discovery === 'List') {
					this.showSpin = true;
					this.getNetworks(type);
				}
				break;
			case 'clubs':
				this.searchModalTitle = this.translateService.instant('Join') + ' ' + this.translateService.instant('Memberships');
				this.searchInputLabel = this.translateService.instant('Find and join your Membership by network');
				this.searchInputPlaceholder = 'Example: Costco, AAA';
				if (this.networkDetails.clubs.discovery === 'List') {
					this.showSpin = true;
					this.getNetworks(type);
				}
				break;
			case 'enterprise':
				this.searchModalTitle = this.translateService.instant('Join') + ' ' + this.translateService.instant('Work');
				this.searchInputLabel = this.translateService.instant('Find and join your Work by Company Search');
				this.searchInputPlaceholder = 'Example: john@company.com';
				if (this.networkDetails.enterprise.discovery === 'List') {
					this.showSpin = true;
					this.getNetworks(type);
				}
				break;
			case 'elections':
				this.searchModalTitle = this.translateService.instant('Join') + ' ' + this.translateService.instant('Election');
				this.searchInputLabel = 'Find and join Election by network';
				this.searchInputPlaceholder = 'Example: network name';
				if (this.networkDetails.elections.discovery === 'List') {
					this.showSpin = true;
					this.getNetworks(type);
				}
				break;
			default:
				this.searchModalTitle = this.translateService.instant('Something Went Wrong');
				this.searchInputLabel = this.translateService.instant('Something Went Wrong');
				this.searchInputPlaceholder = this.translateService.instant('Something Went Wrong');
		}
		this.showNetworkSearchBox();
	}
	private getNetworks(type, searchValue = ''): void {
		this.showSpin = true;
		this.networkService.getNetworksBasedOnFilters(type, searchValue).subscribe((data) => {
			this.requests = data['requests'];
			this.networks = data['objects'];
			if (this.networks && this.networks.length === 0) {
				if (this.requests && this.requests !== null) {
					this.showRequest = true;
				} else {
					this.showErrorMessage = true;
				}
			}
			this.showSpin = false;
		});
	}

	public onSearchClick(): void {
		this.searchClickStatus = true;
		this.networks = null;
		this.getNetworks(this.selectedNetworkType, this.searchValue);
	}

	private showNetworkSearchBox(): void {
		this.showNetworkTypes = false;
		this.searchDisplay = true;
	}

	private hideNetworkSearchBox(): void {
		this.searchDisplay = false;
		this.showNetworkTypes = true;
	}

	public hideSearchAndNetworkTypes(): void {
		this.searchDisplay = false;
		this.showNetworkTypes = false;
	}

	public onCloseHandled(): void {
		this.searchDisplay = false;
		this.roleSelectModal = 'none';
		this.requests = null;
		this.showRequest = false;
		this.showAlreadyMemberErrorMessage = false;
	}

	private onNetworkTypeSelect(data): void {
		// remove return to make join network functional.
		// return;
		this.selectedNetwork = data;
		this.onSelectNetworkType(data.slug);
	}

	public onNetworkSelect(network): void {
		this.validationRule = null;
		this.departments = null;
		this.membershipId = null;
		this.networkService.setValidationPendingNetwork(null);
		this.userDetails = this.userService.getCurrentUser();
		this.selectedNetwork = network;
		this.location = null;
		const index = this.userService.memberships.findIndex((membership) => membership.network.uid === network.uid);
		if (index !== -1) {
			this.membershipId = this.userService.memberships[index].uid;
			if (
				(this.userService.memberships[index].validationMethod === 1 || this.userService.memberships[index].validationMethod === 2) &&
				this.selectedNetwork.membership['state'] !== 1
			) {
				this.networkService.get(this.userService.memberships[index].network.uid).subscribe((data) => {
					this.searchDisplay = false;
					if (this.userService.memberships[index].validationMethod === 2) {
						this.networkService.getMembershipDetails(this.membershipId).subscribe((membership) => {
							if (membership.verificationEmail) {
								this.showEmailValidation(membership.verificationEmail);
							} else {
								if (data['validationValue']) {
									this.validationRule = data['validationValue'].split('://')[1];
									this.showEmailValidation('@' + this.validationRule);
								} else {
									this.showEmailValidation();
								}
							}
						});
					} else {
						this.showEmailValidation();
					}
				});
				return;
			} else {
				this.showErrorModal(this.translateService.instant('You are already a member of this network.'));

				return;
			}
		}
		if (this.selectedNetwork.roles.length === 1) {
			this.selectedRole = this.selectedNetwork.roles[0].slug;
			// TODO: use loading spinner
			this.getRoleDetails();
		} else if (this.selectedNetwork.roles.length > 1) {
			this.selectedRole = this.selectedNetwork.roles[0].slug;
			this.showRoleForm();
		} else {
			return;
		}
	}

	private getRoleDetails(): void {
		this.networkService.getRoleDetails(this.selectedRole, this.selectedNetwork.uid).subscribe((data) => {
			this.roleFields = data.fields;
			this.roleFields.forEach(() => {
				// if (element.type === 'department') {
				//   this.networkService.getDepartmentDetails(this.selectedNetwork.uid).subscribe((data) => {
				//     this.departments = data['objects'];
				//   });
				//   this.selectedDepartment = element.value;
				// } else if (element.type === 'city') {
				//   if (element.value) {
				//     this.location = { 'formattedName': element.valueLabel, 'uid': element.value };
				//   }
				// }
			});
			this.showRoleDetailsModel();
		});
	}

	public onRoleSubmit(): void {
		if (this.selectedRole !== null) {
			this.location = null;
			this.getRoleDetails();
		} else {
		}
	}

	private showErrorModal(errorMessage = 'Something Went Wrong'): void {
		this.errorMessage = errorMessage;
		this.displayErrorModal = 'block';
	}

	private hideErrorModal(): void {
		this.displayErrorModal = 'none';
	}

	private hideRoleDetailsMode(): void {
		this.roleDetailsModel = 'none';
	}

	private showRoleDetailsModel(): void {
		this.searchDisplay = false;
		this.roleSelectModal = 'none';
		this.roleDetailsModel = 'block';
	}

	private sortData(data: any): any {
		return data.sort(function (m1, m2) {
			if (m1.distance < m2.distance) {
				return -1;
			} else if (m1.distance > m2.distance) {
				return 1;
			} else {
				return 0;
			}
		});
	}

	public onLocationClick(): void {
		// TODO: use loading spinner
		const nearMeLocation = this.currentLocation.coords.latitude + ',' + this.currentLocation.coords.longitude;
		this.networkService.getCities(nearMeLocation).subscribe((data) => {
			this.locationsList = data['objects'];
			this.locationDisplay = 'block';
		});
	}

	public closeLocationModal(): void {
		this.locationDisplay = 'none';
	}

	public onLocationSelect(loc): void {
		this.location = loc;
		this.closeLocationModal();
	}

	public onRemoveLocation(): void {
		this.location = null;
	}

	public submitRoleDetails(): void {
		this.verificationEmail = null;
		this.validationRule = null;
		let valid = true;
		const self = this;
		const postData = {};
		this.roleFields.forEach((element) => {
			if (element.mandatory && element.type !== 'email') {
				if (element.type === 'department') {
					if (self.selectedDepartment === null || self.selectedDepartment === '') {
						this.showErrorModal('Please enter the value for ' + element.name);
						valid = false;
						return;
					}
				} else if (element.type === 'city') {
					if (self.location === null || self.location === '') {
						this.showErrorModal('Please enter the value for ' + element.name);
						valid = false;
						return;
					}
				} else if ((element.value === null || element.value === '') && element.type !== 'city' && element.type !== 'department') {
					this.showErrorModal('Please enter the value for ' + element.name);
					valid = false;
					return;
				}
			}
			if (element.type === 'city') {
				postData[element.key] = self.location['uid'];
			} else if (element.type === 'department') {
				postData[element.key] = self.selectedDepartment;
			} else {
				postData[element.key] = element['value'];
			}
		});
		if (valid) {
			// postData['role'] = this.selectedRole['slug'];
			postData['network'] = this.selectedNetwork['uid'];
			postData['role'] = this.selectedRole;
			this.networkService.postRoleDetails(postData).subscribe((data) => {
				this.membershipId = data['uid'];
				this.hideRoleDetailsMode();
				if (data['state'] === 1) {
					this.userService.updateUserDetails();
					this.loadNetwork(data['network'].uid);
					this.roleDetailsModel = 'none';
					this.showNetworkTypes = true;
				} else {
					if (data['validationMethod']) {
						if (data['validationMethod'] === 1) {
							this.membershipId = data['uid'];
							this.showEmailValidation();
						} else if (data['validationMethod'] === 2) {
							if (data['verificationEmail']) {
								this.verificationEmail = data['verificationEmail'];
								this.showEmailValidation(data['verificationEmail']);
							} else {
								this.validationRule = data['network']['validationValue'].split('://')[1];
								this.showEmailValidation('@' + this.validationRule);
							}
						}
					} else {
						this.roleDetailsModel = 'none';
						this.showPendingApproval = true;
					}
				}
			});
		}
	}

	public onSubmitEmail(): void {
		if (this.verificationEmail === this.email) {
			this.showErrorModal('An email validation link is already send to that account');
		} else {
			const param = {
				email: this.email,
				username: localStorage.getItem('uid'),
				apiKey: 'fa151d3fd479281166781e953732216732786649',
			};
			this.networkService.postEmail(param, this.membershipId).subscribe((data) => {
				if (data['status'] === 'success') {
					this.showEmailSuccessModel = 'block';
					this.hideEmailModel();
				} else {
					this.hideEmailModel();
					this.showErrorModal('Something went wrong please try after sometime');
				}
			});
		}
	}

	public hideEmailModel(): void {
		this.showEmailValidationModal = 'none';
	}

	public hideEmailSuccessModel(): void {
		this.showEmailSuccessModel = 'none';
	}

	private loadNetwork(networkUid): void {
		this.networkService.get(networkUid).subscribe((networkData) => {
			this.networkService.setNetwork(new Network(networkData));
			if (localStorage.getItem('pb-prev-url')) {
				this.router.navigateByUrl(localStorage.getItem('pb-prev-url'));
				localStorage.removeItem('pb-prev-url');
			} else {
				this.router.navigateByUrl('/network');
			}
		});
	}

	private showEmailValidation(domain = ''): void {
		this.email = domain;
		this.showEmailValidationModal = 'block';
	}

	public onRequestClick(): void {
		if (this.requests.requests > 0) {
			this.showErrorModal(`You have already requested for ${this.searchValue} network. No need to request again`);
			this.onCloseHandled();
		} else {
			if (this.requests.contentUid) {
				this.networkService.requestNetwork(this.searchValue, this.requests.contentUid).subscribe(() => {
					this.onCloseHandled();
				});
			}
		}
	}

	// Used to show/hide the network types in the right side bar
	private toggleNetworkTypeList(): void {
		this.showExtraNetworkTypes = !this.showExtraNetworkTypes;
	}

	/**
	 * Function to show role form and hide search modal
	 */
	private showRoleForm(): void {
		this.searchDisplay = false;
		this.roleSelectModal = 'block';
	}

	/**
	 * Function to execute when back button clicked in role select modal
	 */
	public hideRoleSelectBox(): void {
		this.roleSelectModal = 'none';
		this.searchDisplay = true;
	}

	/**
	 * Function to execute when back button in role details modal is selected
	 */
	public hideRoleDetails(): void {
		this.roleDetailsModel = 'none';
		this.roleSelectModal = 'block';
	}

	public hidePendingApproval(): void {
		this.showPendingApproval = false;
		this.showNetworkTypes = true;
	}

	private showToastMessage(): void {
		this.toast_message = 'block';
		this.closeToastMessage();
	}

	public onClickRemoveToastMessage(): void {
		this.toast_message = 'none';
	}

	// Close toast message after 8 secs
	private closeToastMessage(): void {
		setTimeout(() => {
			this.toast_message = 'none';
		}, 8000);
	}
}
