import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { QuickChatService } from '@modules/chat/services/quick-chat.service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class QuickChatGuardGuard implements CanActivate {
	constructor(private quickChatService: QuickChatService) { }
	canActivate(
		route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		if (route.routeConfig.path === 'home' || route.routeConfig.path === 'workspace' || route.routeConfig.path === '') {
			this.quickChatService.hideQuickChat();
		}


		return true;
	}
}
