import { Component, Input } from '@angular/core';
import { Errors } from './models';

@Component({
	selector: 'app-list-errors',
	templateUrl: './list-errors.component.html',
})
export class ListErrorsComponent {
	public formattedErrors = [];

	@Input()
	set errors(errorList: Errors) {
		this.formattedErrors = [];
		if (errorList.errors && Object.keys(errorList.errors).length > 0) {
			this.formattedErrors.push(errorList.errors);
		}
	}

	get errorList(): any {
		return this.formattedErrors;
	}
}
