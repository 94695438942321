import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ITodo } from '@shared/models';
import { CommentsService, UserService } from '@shared/services';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-comment-input',
	templateUrl: './comment-input.component.html',
	styleUrls: ['./comment-input.component.scss']
})
export class CommentInputComponent implements OnInit, OnDestroy {
	@Input() public task: ITodo;
	public currentUser: any;
	public userSub: Subscription;
	public newComment= { text: '',contentUid: '',contentType: '' };
	public isLoading = false;

	constructor(public commentService: CommentsService, public userService: UserService) {
		this.newComment.text = '';
	}

	ngOnInit(): void {
		this.userSub = this.userService.currentUser.subscribe((user) => {
			this.currentUser = user;
			console.log(this.currentUser);
		});
	}


	/**
	 * Create new comment on post
	 *
	 * @param event any
	 * @param isClick boolean
	 */
	public createComment(): void {
		this.isLoading = true;
		if (this.newComment?.text.trim()) {
			this.newComment.contentUid = this.task.uid;
			this.newComment['contentType'] = 'todo';
			const newComment = this.newComment;

			if (newComment.text) {
				this.commentService.create(newComment, this.currentUser.username).subscribe(
					(data) => {
						this.isLoading = false;
						data.alteredText = data.text;
						if (this.task.comments?.length) {
							this.task.comments.unshift(data);
						} else {
							this.task.comments = [data];
						}
						this.task.commentCount += 1;
						this.newComment.text = '';
					},
					(err) => {
						console.log(err);
						this.isLoading = false;
					}
				);
			}
		}

	}

	ngOnDestroy(): void {
		this.userSub?.unsubscribe();
	}

}
