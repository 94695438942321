import { Component, Input, OnInit } from '@angular/core';
import { BeeError } from '../../models/custom-error';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'bee-error-list',
	templateUrl: './error-list.component.html',
	styleUrls: ['./error-list.component.scss'],
})
export class BeeErrorListComponent implements OnInit {
	@Input() public errors: BeeError[] = [];

	constructor() {}

	ngOnInit(): void {}
}
