import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { CollectionsService, PaginationService, UserService } from '../shared';
import { SearchService } from '../shared/services/search.service';
import { NetworkService } from '../shared';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';

@Component({
	selector: 'app-collections',
	templateUrl: './collections.component.html',
	styleUrls: ['./collections.component.css'],
})
export class CollectionsComponent implements OnInit, OnDestroy, AfterViewInit {
	public loadingGif = 'none';
	public myCollections: any[] = [];
	public otherCollections: any[] = [];
	public followingCollections: any[] = [];
	public emptyCollections = false;
	public showMyCollection = true;
	public showOtherCollection = false;
	public SubscriptionTemplates = [];
	public showFollowingCollection = false;
	public isFirstSearch = true;
	private paginateSub$: any;
	public archivedCounts: any;
	public departments = [];
	public personalFilters = [];
	public selectByLevelValues = [];
	public value;
	public collections = [];
	public nextUrl;
	public networkLoaded;
	public network;
	private currentDepartmentSubscriber;
	private networkSubscriber;
	private departmentsReady;
	public myFavoriteUid;
	public currentUser;

	constructor(
		public collectionService: CollectionsService,
		private userService: UserService,
		private translate: TranslateService,
		private searchService: SearchService,
		private router: Router,
		private networkService: NetworkService,
		private paginationService: PaginationService,
		private pageLoadingUiService: PageLoadingUiService
	) {
		this.paginateSub$ = this.paginationService.initiateScroll.subscribe((data) => {
			if (data && !this.collectionService.isCollectionPaginating) {
				this.collectionService.paginateCollection();
			}
		});
	}

	ngOnInit(): void {
		if (localStorage.getItem('networkExpired')) {
			this.router.navigateByUrl('/expired');
		}

		if (!this.collectionService.isCollectionLoaded) {
			this.pageLoadingUiService.setPageLoading(true);
			this.collectionService.populateCollection();
		}

		if (localStorage.getItem('favoriteId')) {
			this.myFavoriteUid = localStorage.getItem('favoriteId');
		}

		this.userService.currentUser.subscribe((userData) => {
			this.currentUser = userData;
		});

		this.networkSubscriber = this.networkService.networkReady.subscribe((networkLoaded) => {
			this.networkLoaded = networkLoaded;
			if (this.networkLoaded) {
				this.networkService.currentNetwork.subscribe((network) => {
					this.network = network;
					if (network.membership && network.membership.memberRole) {
						network.membership.memberRole.fields.forEach((element) => {
							this.departmentsReady = this.networkService.departmentsReady.subscribe((departmentsLoaded) => {
								if (departmentsLoaded) {
									this.currentDepartmentSubscriber = this.networkService.currentDepartments.subscribe((departments) => {
										if (departments && departments['objects'].length > 0) {
											this.departments = departments.objects;
										}
									});
								}
							});
							if (element.CP === true) {
								this.personalFilters.push(element);
							}
						});
					}
					if (this.network && this.network.SubscriptionTemplates) {
						this.SubscriptionTemplates = this.network.SubscriptionTemplates;
					}
				});
			}
		});

		this.collectionService.collection.subscribe(() => {
			const col = this.collectionService.sortedCollectionSubject.value;
			this.myCollections = col['myCollections'];
			this.followingCollections = col['followingCollections'];
			this.otherCollections = col['otherCollections'];

			if (this.followingCollections.length > 0) {
				this.showFollowingCollection = true;
			}

			if (this.otherCollections.length > 0) {
				this.showOtherCollection = true;
			}

			if (this.myCollections.length > 0) {
				this.showMyCollection = true;
			}

			if (this.collectionService.isCollectionLoaded) {
				this.pageLoadingUiService.setPageLoading(false);
			}
		});

		this.searchService.searchValue.subscribe((data) => {
			if (this.isFirstSearch && data.trim()) {
				this.isFirstSearch = false;
			}
			if (!this.isFirstSearch) {
				this.pageLoadingUiService.setPageLoading(true);
				this.myCollections = [];
				this.otherCollections = [];
				this.followingCollections = [];
				this.collectionService.populateCollection(data);
				this.showFollowingCollection = false;
				this.showOtherCollection = false;
				this.showMyCollection = false;
			}
		});

		this.getArchiveCollectionCounts();
	}

	ngAfterViewInit(): void {
		// Hack: Scrolls to top of Page after page view initialized
		let top = document.getElementById('top');
		if (top !== null) {
			top.scrollIntoView();
			top = null;
		}
	}

	ngOnDestroy(): void {
		this.myCollections = [];
		this.otherCollections = [];
		this.followingCollections = [];
		this.collectionService.resetCollections();
		this.collectionService.scrollPosition = document.documentElement.scrollHeight;
		if (this.paginateSub$) {
			this.paginateSub$.unsubscribe();
		}
	}

	public getArchiveCollectionCounts(): void {
		// this.pageLoadingUiService.setPageLoading(true);
		this.collectionService.getArchivedCounts().subscribe((data) => {
			this.archivedCounts = data['archivedCounts'];
			// this.pageLoadingUiService.setPageLoading(false);
		});
	}

	/**
	 * Function to make an un followed collection follow
	 * @param collection
	 */
	public followCollection(collection): void {
		this.pageLoadingUiService.setPageLoading(true);
		this.collectionService.followCollection(collection['uid']).subscribe((data) => {
			this.pageLoadingUiService.setPageLoading(false);
			if (data['follow']) {
				this.pageLoadingUiService.setPageLoading(false);
				this.otherCollections.splice(this.otherCollections.indexOf(collection), 1);
				this.followingCollections.push(collection);
				this.showFollowingCollection = true;
				if (this.otherCollections.length === 0) {
					this.showOtherCollection = false;
				}
			}
		});
		this.collectionService.populateCollection();
	}

	/**
	 * Function to make a followed collection unfollow
	 * @param collection
	 */
	public unfollowCollection(collection): void {
		this.pageLoadingUiService.setPageLoading(true);
		this.collectionService.unFollowCollection(collection['uid']).subscribe((data) => {
			this.pageLoadingUiService.setPageLoading(false);
			if (!data['follow']) {
				this.pageLoadingUiService.setPageLoading(false);
				this.followingCollections.splice(this.followingCollections.indexOf(collection), 1);
				this.otherCollections.push(collection);
				this.showOtherCollection = true;
				if (this.followingCollections.length === 0) {
					this.showFollowingCollection = false;
				}
			}
		});
		this.collectionService.populateCollection();
	}

	public getCollections(): void {
		this.collectionService.getCollections('').subscribe((data) => {
			this.collections = data.objects;
			this.sortCollections();
			if (data.next) {
				this.nextUrl = data.next.split('alpha')[1];
				// this.paginate();
			} else {
				this.nextUrl = '';
			}
		});
	}

	public sortCollections(): void {
		this.collections.forEach((collection) => {
			if (collection.user.username === localStorage.getItem('uid') || collection.collaboration === true) {
				this.myCollections.push(collection);
			} else {
				if (collection.follow) {
					this.followingCollections.push(collection);
				} else {
					this.otherCollections.push(collection);
				}
			}
		});
	}

	/**
	 * Function to get department name
	 * @param value
	 */
	public getDepartmentNameBasedOnId(value): string {
		const index = this.departments.findIndex((department) => department.uid === value);
		if (index !== -1) {
			return this.departments[index].name;
		}
	}

	/**
	 * Function to show post cp names of personal filters/memberships
	 * @param value
	 * 1. If content privacy(value) is status,show it.
	 * 2. If content privacy(value) is renewal status,show it.
	 * 3. Check if content privacy(value) is membershipLevel
	 * 4. Get membership level name from array
	 * 5. If value is manager,show cp as myTeam
	 * 6. If value is in personal filters,show it
	 */
	public getPostNames(value): string {
		if (value.status) {
			return value.status; // 1
		} else if (value.renewalStatus) {
			return value.renewalStatus; // 2
		} else if (value.membership_level) {
			// 3
			let name = '';
			this.selectByLevelValues.forEach((object) => {
				if (object.uid === value.membership_level) {
					name = object.name; // 4
				}
			});
			return name;
		} else if (value.manager) {
			return this.translate.instant('My Team'); // 5
		} else {
			this.value = Object.values(value);
			const index = this.personalFilters.findIndex((field) => field.value === this.value);
			if (index !== -1) {
				return this.personalFilters[index].valueLabel; // 6
			}
		}
	}
}
