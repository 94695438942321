import { timer } from 'rxjs';
import { EventEmitter, Injectable, Output } from '@angular/core';

export interface ScrollEvent {
	event: Event;
	scrollPercent: number;
}

@Injectable({
	providedIn: 'root',
})
export class ScrollService {
	@Output() public windowScrollChanged: EventEmitter<ScrollEvent> = new EventEmitter();

	constructor() {}

	/**
	 * Emits the scroll event and the percentage of the page scrolled.
	 *
	 * @param event Event
	 */
	public onWindowScrollChange(event: Event): void {
		this.windowScrollChanged.emit({
			event,
			scrollPercent: this.getScrollPercent(),
		});
	}

	/**
	 * Returns the percentage of the page scrolled.
	 *
	 * @returns {number} - The percentage of the page scrolled.
	 */
	public getScrollPercent(): number {
		const h = document.documentElement,
			b = document.body,
			st = 'scrollTop',
			sh = 'scrollHeight';

		return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
	}

	/**
	 * Scrolls to the top of the page.
	 *
	 * @returns {void}
	 */
	public scrollToTop(): void {
		timer(50).subscribe(() => {
			window.scrollTo(0, 0);
		});
	}
}
