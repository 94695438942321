export interface IWorkPlace {
	label: string;
	value: string;
	selected: boolean;
}


export const workplaceData: IWorkPlace[] = [
	{
		label: 'Office',
		value: 'Office',
		selected: true // default option
	},
	{
		label: 'On the Road',
		value: 'On the Road',
		selected: false
	},
	{
		label: 'Home office',
		value: 'Home office',
		selected: false
	}
];
