import { EventEmitter, Injectable, Output } from '@angular/core';
import { ApiService } from '@shared/services';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class HeaderService {
	@Output() public unReadConversationCountUpdateEmitter: EventEmitter<number> = new EventEmitter();

	constructor(private apiService: ApiService) {}

	/**
	 * Paginate notifications list on scroll
	 * @param url next url
	 * @returns new notifications list observable
	 */
	public paginateNotifications(url): Observable<any> {
		return this.apiService.get(url);
	}
}
