import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-post-create-focus-card',
	templateUrl: './post-create-focus-card.component.html',
	styleUrls: ['./post-create-focus-card.component.scss'],
})
export class PostCreateFocusCardComponent implements OnInit {
	@Input() public currentUser: any;
	@Output() public selectModalTypeEmitter: EventEmitter<string> = new EventEmitter();

	constructor() {}

	ngOnInit(): void {}

	/**
	 * Select Modal Type Emitter
	 * @param type Modal Type
	 */
	public selectPostOption(type: string): void {
		this.selectModalTypeEmitter.emit(type);
	}
}
