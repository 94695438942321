import { FormsModule } from '@angular/forms';
import { BeeInputModule } from './modules/input/input.module';
import { NgModule } from '@angular/core';

@NgModule({
	declarations: [],
	imports: [BeeInputModule, FormsModule],
	exports: [BeeInputModule],
})
export class BeeUiModule {}
