import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BeePrivatePublicLabelComponent } from './private-public-label/private-public-label.component';

@NgModule({
	declarations: [BeePrivatePublicLabelComponent],
	imports: [CommonModule],
	exports: [BeePrivatePublicLabelComponent],
})
export class BeeLabelModule {}
