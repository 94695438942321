import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { NetworkService } from './network.service';

@Injectable()
export class MessagingService {
	public currentMessage = new BehaviorSubject(null);

	constructor(
		private angularFireDB: AngularFireDatabase,
		private angularFireAuth: AngularFireAuth,
		private angularFireMessaging: AngularFireMessaging,
		private networkService: NetworkService
	) {
		this.angularFireMessaging.messages.subscribe((_messaging: AngularFireMessaging) => {
			_messaging.onMessage = _messaging.onMessage.bind(_messaging);
			_messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
		});
	}

	/**
	 * update token in firebase database
	 * @param userId userId as a key
	 * @param token token as a value
	 */
	public updateToken(userId, token): void {
		// we can change this function to request our backend service
		this.angularFireAuth.authState.pipe(take(1)).subscribe(() => {
			const data = {};
			data[userId] = token;
			this.angularFireDB.object('fcmTokens/').update(data);
		});
	}

	/**
	 * Request permission for notification from firebase cloud messaging
	 * @param userId userId
	 */
	public requestPermission(userId): void {
		this.angularFireMessaging.requestToken.subscribe(
			(token) => {
				this.updateToken(userId, token);
				localStorage.setItem('fcm_token', token);
			},
			() => {}
		);
	}

	/**
	 * hook method when new notification received in foreground
	 */
	public receiveMessage(): void {
		this.angularFireMessaging.messages.subscribe(
			(payload) => {
				this.playAudio();
				const notification = payload['notification'];
				if (notification.body.length > 230) {
					payload['strippedBody'] = notification.body.slice(0, 180) + '...';
				}
				if (payload['data'] && payload['data']['slug'] === 'new-member-request') {
					this.networkService.populate();
				}
				const today = new Date();
				const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
				const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
				const dateTime = date + ' ' + time;
				payload['created'] = dateTime;
				this.currentMessage.next(payload);
			},
			() => {}
		);
	}

	public playAudio(): void {
		const audio = new Audio();
		// eslint-disable-next-line spellcheck/spell-checker
		audio.src = 'assets/img/notiSound.mp3';
		audio.load();
		audio.play();
	}
}
