import { TaskStatus } from './../../shared/models/todo.model';
import { Component, OnInit, ViewChild, Input, SimpleChanges, EventEmitter, Output, ElementRef, QueryList, ViewChildren, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService, NetworkService, LayoutService, CommentsService, PaginationService, PostService, PrivacyComponent, MemberService, DueDateComponent } from '../../shared';
import { Location } from '@angular/common';
import { Comment, Reminder, Subtask, Section, Todo } from '../../shared/models';
import { TranslateService } from '@ngx-translate/core';
import { PostEditComponent } from '../../shared/layout/post-edit/post-edit.component';
import { PostComponent } from '../../post/post.component';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ToastService } from '../../shared/services/common/toast.service';
import { ClipboardService } from 'projects/peer-core/src/lib/services/ui/clipboard.service';
import { environment } from 'src/environments/environment';
import { SegmentAnalyticsService } from '@core/services/third-party/segment-analytics.service';
import { MixPanelService } from '@core/services/third-party/mix-panel.service';

const CSS_CLASS_NAMES = {
	highLight: 'dd-highlight-item',
};

@Component({
	selector: 'app-project-details',
	templateUrl: './project-details.component.html',
	styleUrls: ['./project-details.component.css'],
})
export class ProjectDetailsComponent implements OnInit, OnChanges {
	constructor(
		private fb: FormBuilder,
		private paginationService: PaginationService,
		private commentService: CommentsService,
		private activatedRoute: ActivatedRoute,
		private translate: TranslateService,
		private userService: UserService,
		private router: Router,
		private postService: PostService,
		private memberService: MemberService,
		private _location: Location,
		public networkService: NetworkService,
		public layoutService: LayoutService,
		private parserFormatter: NgbDateParserFormatter,
		private toastService: ToastService,
		private clipboardService: ClipboardService,
		private mixPanelService: MixPanelService,
		private segmentService: SegmentAnalyticsService
	) {
		this.paginationService.scrollDistance = 8;
		this.paginateSub$ = this.paginationService.initiateScroll.subscribe((data) => {
			if (data && !this.paginating) {
				this.paginateTodos();
			}
		});

		this.estimateForm = this.fb.group({
			time: ['', Validators.required],
		});

		this.elapsedForm = this.fb.group({
			time: ['', Validators.required],
		});
	}
	@Output() public resetCounts: EventEmitter<any> = new EventEmitter();
	@ViewChild(PrivacyComponent, { static: true }) privacy;
	@ViewChild('postEdit', { static: true }) public postEdit: PostEditComponent;
	@ViewChild('postComponent', { static: true }) public postComponent: PostComponent;
	@ViewChildren('textArea') textArea: QueryList<ElementRef>;
	@ViewChild('dueDateComponent', { static: true }) public dueDateComponent: DueDateComponent;
	@Input() currentPage = 'projectDetail';
	@Input() timeNow;
	@Input() date;
	@Input() archivedList = false;
	@Input() slug;
	@Input() projectUid;
	toggle = {};
	toggler = {};
	estimateForm: FormGroup;
	elapsedForm: FormGroup;
	public displayEstimate = 'none';
	public todoItem = new Todo();
	public newDate = new Date();
	public loadingGif = 'none';
	public model = { channel: '' };
	public filter = '';
	public isEditReminder = false;
	public arr1 = [];
	public postUrl = null;
	public currentUserUid = localStorage.getItem('uid');
	public forNames;
	public displayProjectError = false;
	public privacyString = '';
	private currentDepartmentSubscriber;
	private departmentsReady;
	public value;
	public network_id;
	public currentUser: any;
	public postPrivacy;
	public isFirstSearch = true;
	public showMenu = false;
	public showPostCreateForm = false;
	public showDefaultPost = true;
	public noAssignedItems = false;
	public setStartDayComment = false;
	public display_Completed = false;
	public displayMyTasks = false;
	public postType;
	public todosFetched: any = 0;
	public repeatModal = 'none';
	public showDeleteConfirmation = 'none';
	public isRedirectFromEditPage = false;
	public showEditDelete = true;
	public muteId;
	public showMute = true;
	private networkSubscriber;
	private currentNetSub;
	private paramsSubscriber;
	private checkAccessibilitySub;
	public todoList;
	public projectDetails;
	public network;
	private app_url;
	private store_url;
	private is_member = null;
	public todoListId;
	// todo item
	public deleteItem_modal = 'none';
	public display_reminderOptions = 'none';
	public display_toast = 'none';
	public display_todoLists = 'none';
	public display_assignedTodos = 'none';
	public displayError = 'none';
	public editProject = 'none';
	public isListsFetching = 0;
	public personalFilters: any[] = [];
	public todoItems: any[] = [];
	public todoListsForItem = [];
	public SubscriptionTemplates = [];
	public departments = [];
	public assignedItems = [];
	public options: any[] = [];
	public selectedTodos: any = [];
	public currentDate = new Date();
	private paginating = false;
	public reminderUid;
	public selectedToDoItem: any;
	public lists = { option: '' };
	public reminder = { value: '' };
	public assignedItem = { value: '' };
	public todoDetail = { todoList_uid: '', todolistName: '' };
	public selectedToDoList: any;
	public todoListPrivacy: any;
	public post: any;
	public next_url;
	public item_next_url;
	public start_next_url;
	public subNextUrl;
	public selectedItemForReminder;
	public selectedRemainderOption;
	public reminderMessage;
	public timeForReminder: number;
	public todoItemIndex;
	public editSelectedPost: any;
	public myTodoList_uid;
	public paginateSub$: any;
	public searchSubscription: any;
	public collaboration: boolean;
	public defaultTasks = false;
	public isExternalUser = false;
	public sortByAssignee = false;
	public tab = '';
	public projectId;
	public isArchived = false;
	public progressWidth;
	public squareSpinner = 'none';
	public projectName;
	public projectStart;
	public projectEnd;
	public modelText;
	public project = { name: '' };
	public roles = [];
	// reminders
	public reminderOptions: any[] = [];
	public displayReminderModal = 'none';
	public reminderTime;
	public timeFor;
	public remindersSelected = [];
	public reminderModel = new Reminder();
	public reminderToast = false;
	public todoForReminder;
	public numbers: any;
	public subtask = new Subtask();
	public selectedTask;
	public selectedSection;
	public currentIndex;
	public deleteDialog = 'none';

	public scrollStatus = false;
	public query;
	public kanbenView = 'none';
	public openTasks: any[] = [];
	public openFetching = 0;
	public openNextUrl;
	public closedTasks: any[] = [];
	public closedFetching = 0;
	public closedNextUrl;
	public inProgressTasks: any[] = [];
	public inProgressFetching = 0;
	public inProNextUrl;
	public newTask;
	public openCount;
	public closedCount;
	public inprogressCount;

	public sectionsBoard = 'none';
	public sectionRenameDialog = 'none';
	public isBoardFetched = 0;
	public stateFilter = 'All Tasks';
	public statusFilter = 'Filters';
	public boardTask;
	public newSection = false;
	public mainTask;
	public subIndex;

	comment = new Comment({ text: '', isLink: false, auto_generated: true }, true);
	public placement = 'right';
	public modelB = {
		year: this.newDate.getFullYear(),
		month: this.newDate.getMonth() + 1,
		day: this.newDate.getDate(),
	};
	Date = {
		year: this.newDate.getFullYear(),
		month: this.newDate.getMonth() + 1,
		day: this.newDate.getDate(),
	};

	// section
	@ViewChild('textSection', { static: false }) public textSection: ElementRef;
	@ViewChild('text', { static: false }) public text: ElementRef;
	@ViewChild('sec', { static: false }) public sec: ElementRef;
	public section = new Section();
	public element = new Section();
	public isCreate = false;
	public sections: any[] = [];
	public boardSections: any[] = [];
	public isAddSection = false;
	public setTaskForSection;
	public isLoading = 0;
	public editTask = { section: '', task: '', index: '' };
	public selectedSectionUid;
	public selectedIndex: any;
	public deleteSectionModal = 'none';
	public displayElapsed = 'none';
	public isReorder = false;
	public sectionsFetched: any = 0;

	public shiftKeyUsed = false;
	public currentSub;
	public sub = 'daily';
	public subRepeats = ['daily', 'tomorrow', 'weekly', 'monthly', 'one_off'];
	public subtaskDate = {
		year: new Date().getFullYear(),
		month: new Date().getMonth() + 1,
		day: new Date().getDate(),
	};
	public today = {
		year: new Date().getFullYear(),
		month: new Date().getMonth() + 1,
		day: new Date().getDate(),
	};
	public displaySubDuedate = 'none';
	public displayMembers = 'none';
	public subAssignee;
	public selectedSub;
	public next;
	public memberUid;
	public members: any[] = [];
	public counter: any = 1;
	public dummy = [];
	public params = { contents: '' };
	public contents: any[] = [];
	public showBtn = false;

	@ViewChild('panel', { static: false }) public panel: ElementRef<any>;
	private arrowKeyLocation = -1;
	private keyupSubscription: Subscription;
	public isMentions = false;
	public mentionCount = 0;
	public mentionType;
	public mentionIndex;
	public userMentions = [];
	public hashMentions = [];
	public nextMentionUrl;
	public mentionPrivacy: any;
	public CaretPosition: any;
	private subject: Subject<string> = new Subject();
	public mentionsFetched = 0;
	public userRegexFailed = false;
	public hashRegexFailed = false;
	public hashes = [];
	public native;
	public index;
	public todoListUid;

	public taskForReminder;

	taskCreationType: 'onBoard' | 'onSection' = 'onSection';

	selectedNewTask: any;

	taskAddType: 'multiple' | 'single' = 'single';

	public pausedTasks: any[] = [];

	pausedNextUrl: any;

	pausedFetching = 0;

	pausedCount: any;

	currentState: any;

	selectedTodoPreviousIndex: any;

	selectedSectionDetails: any;

	public reaction: any;

	taskReactionDialog = 'none';

	public selectedItem: any;

	public selectedTodoIndex: any;

	public selectedTodoValue: any;

	public reactionToEdit: any;

	showEditReactionDialog = 'none';

	parentTask: any;

	newTaskCreated = false;

	/**
	 * Function to show image slideshow
	 * @param todo
	 */
	public selectedImages: any[] = [];

	ngOnInit(): void {
		this.activatedRoute.queryParams.subscribe((params) => {
			if (params['archived']) {
				this.isArchived = true;
			} else {
				this.isArchived = false;
			}
			if (params['id']) {
				const id = params['id'];
				this.projectId = id;
				this.tab = 'all';
				this.networkService.getProjectDetail(id).subscribe((detail) => {
					this.projectDetails = detail;
					this.getSections();
					this.handlePreviousTodolist();
					this.updatePrivacyString(this.projectDetails);
					this.calculateProgressBar();

					if (this.todoItems.length === 0) {
						if (localStorage.getItem('detail-tab')) {
							this.todosFetched = 1;
							if (localStorage.getItem('detail-tab') === 'myTasks') {
								this.displayTodos('myTasks');
							} else if (localStorage.getItem('detail-tab') === 'completed-tasks') {
								this.displayTodos('completed');
							} else if (localStorage.getItem('detail-tab') === 'open') {
								this.displayTodos('open');
							} else {
								this.displayTodos('all');
							}
						} else {
							// this.getAllTodos('', 'all');
							this.getAllTodos('', 'myTasks');
						}
					}
				});
			}

			this.networkSubscriber = this.networkService.networkReady.subscribe((networkLoaded) => {
				if (networkLoaded) {
					this.currentNetSub = this.networkService.currentNetwork.subscribe((network) => {
						this.network = network;
						this.roles = network['roles'];
						this.currentUser = this.userService.getCurrentUser();
						if (network['membership'] && network['membership']['memberRole']) {
							network.membership.memberRole.fields.forEach((element) => {
								this.departmentsReady = this.networkService.departmentsReady.subscribe((departmentsLoaded) => {
									if (departmentsLoaded) {
										this.currentDepartmentSubscriber = this.networkService.currentDepartments.subscribe((departments) => {
											this.departments = departments.objects;
										});
									}
								});

								if (element.CP === true) {
									this.personalFilters.push(element);
								}

								if (network['membership']['memberRole'] && network['membership']['memberRole']['role']) {
									const memberRole = network['membership']['memberRole']['role'];
									if (memberRole.slug === 'external') {
										this.isExternalUser = true;
									}
								}
							});
						}
						if (this.network.SubscriptionTemplates) {
							this.SubscriptionTemplates = this.network.SubscriptionTemplates;
						}
					});
				}
			});
		});

		// Replace todo item with updated todo item from edit
		this.postEdit.updatedPostEmitter.subscribe((data) => {
			if (data) {
				if (this.currentPage === 'projectDetail') {
					if (this.tab === 'all') {
						this.displayTodos('all');
					} else if (this.tab === 'myTasks') {
						this.displayTodos('myTasks');
					} else if (this.tab === 'completed') {
						this.displayTodos('completed');
					} else {
						this.displayTodos('open');
					}

					if (this.kanbenView === 'block') {
						if (data['status'] === 'open') {
							this.getOpenTasks('');
						} else if (data['status'] === 'in_progress') {
							this.getInProgressTasks('');
						} else if (data['status'] === 'paused') {
							this.getPausedTasks('');
						} else {
							this.getClosedTasks('');
						}
					}
					if (this.sectionsBoard === 'block') {
						if (this.selectedSection) {
							this.selectedSection['tasks'] = [];
							this.getTodos(this.selectedSection, '');
						}
					}
				} else {
					this.getProjectToDosForCalendar(this.projectUid, this.date);
				}
			}
		});

		this.postEdit.updatedReminderEmitter.subscribe((data) => {
			if (data) {
				let index = -1;
				index = this.findWithAttr(this.todoItems, 'uid', data['todoUid']);
				if (index > -1) {
					this.todoItems[index]['myRelations']['reminder'] = data.reminder;
				}
				this.showOneOffDate();
			}
		});

		this.postComponent.newPostEmitter.subscribe((data) => {
			if (data) {
				this.getDetail();
				if (!this.setTaskForSection) {
					if (this.tab === 'all') {
						this.displayTodos('all');
					} else if (this.tab === 'open') {
						this.displayTodos('open');
					} else if (this.tab === 'myTasks') {
						this.displayTodos('myTasks');
					}
				} else {
					this.addTaskInSections(data);
				}
			}
		});

		this.layoutService.showBackdrop.subscribe((data) => {
			if (data === 'event-post-form' || data === 'hide') {
				this.showPostCreateForm = false;
				this.postType = '';
			}
		});

		this.privacy.privacyModalStatus.subscribe((data) => {
			if (data === 'closed') {
				this.layoutService.hideBd();
				this.updatePrivacyValues();
			}
		});

		/** For handling @/# mentions searches
		 * 1. Call api for getting search results
		 * 2. If mention type is user,fill userMentions array
		 * 3. Else fill hashMentions array
		 * 4. If nextUrl exists,set nextUrl and call paginate function
		 */
		this.subject.pipe(debounceTime(400)).subscribe((searchTextValue) => {
			// 1
			const i = searchTextValue.indexOf('@');
			if (i > -1) {
				searchTextValue = searchTextValue.substring(i + 1);
			}
			if (this.mentionType === 'user') {
				if (searchTextValue.length >= 3) {
					this.mentionsFetched = 1;
				} else {
					return;
				}
			}
			this.postService.getMentions(searchTextValue, this.mentionType, this.mentionPrivacy).subscribe((data) => {
				this.mentionsFetched = 0;
				this.arrowKeyLocation = -1;
				this.mentionCount = data.count;

				if (!this.userRegexFailed || !this.hashRegexFailed) {
					if (this.mentionType === 'user') {
						// 2
						this.hashMentions = [];
						this.userMentions = data.objects;
					} else if (this.mentionType === 'hash') {
						this.userMentions = []; // 3
						const filterData = [];
						data.objects.forEach((item: any) => {
							if (item.name !== '') {
								filterData.push(item);
							}
						});
						this.hashMentions = filterData;
					}
					if (data.next) {
						// 4
						this.nextMentionUrl = data.next.split('alpha')[1];
						this.paginateMentions();
					} else {
						this.nextMentionUrl = '';
					}
				}
			});
		});

		if (localStorage.getItem('previous_route')) {
			localStorage.removeItem('previous_route');
		}

		this.dueDateComponent.modalStatus.subscribe((data) => {
			if (data === 'closed') {
				if (this.taskAddType === 'single') {
					this.setTodoOptions();
				} else {
					this.setTodoOptionsForSectionTask();
				}
			}
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.hasOwnProperty('timeNow')) {
			this.todoItems = [];
			this.getProjectToDosForCalendar(this.projectUid, this.date);
		}
	}

	findWithAttr(array, attr, value) {
		for (let i = 0; i < array.length; i += 1) {
			if (array[i][attr] === value) {
				return i;
			}
		}
		return -1;
	}

	public onClickBack(): void {
		this._location.back();
	}

	calculateProgressBar() {
		if (this.projectDetails) {
			if (this.projectDetails['endDate']) {
				const endDate = new Date(this.projectDetails['endDate']);
				if (this.currentDate > endDate) {
					if (this.projectDetails['openCount'] > 0) {
						this.projectDetails['color'] = '#ff0000';
					} else {
						this.projectDetails['color'] = 'var(--success-color)';
					}
				} else if (this.currentDate <= endDate) {
					if (this.projectDetails['openCount'] > 0) {
						this.projectDetails['color'] = '#ffd306';
					} else if (this.projectDetails['openCount'] === 0) {
						this.projectDetails['color'] = 'var(--success-color)';
					}
				}
			}
			this.progressWidth = '0%';
			// let closedCount;
			const openCount = this.projectDetails['openCount'];
			const totalCount = this.projectDetails['totalCount'];
			const closedCount = totalCount - openCount;
			const c = totalCount / closedCount;
			const width = Math.floor(100 / c);
			this.progressWidth = width + '%';
		}
	}

	updatePrivacyString(project) {
		this.privacyString = '';
		this.forNames = '';
		if (project['customPrivacy']) {
			project['customPrivacy'].forEach((cp, index) => {
				this.privacyString = this.translate.instant('Custom selection');
				if (cp.username) {
					this.forNames = `${this.forNames}${index > 0 ? ', ' : ' '}${cp.username}`;
				} else if (cp.name) {
					this.forNames = `${this.forNames}${index > 0 ? ', ' : ' '}${cp.name}`;
				}
			});
		} else if (project['contentPrivacy']) {
			if (project['contentPrivacy'].role && project['contentPrivacy'].department) {
				const index = this.departments.findIndex((department) => department.uid === Object.values(project['contentPrivacy']));
				if (index > -1) {
					this.privacyString = this.departments[index]['name'] + ' , ' + project['contentPrivacy'].role;
				}
			} else if (project['contentPrivacy'].role) {
				this.privacyString = project['contentPrivacy'].role;
			} else if (project['contentPrivacy'].department) {
				const index = this.departments.findIndex((department) => department.uid === Object.values(project['contentPrivacy']));
				if (index > -1) {
					this.privacyString = this.departments[index]['name'];
				}
			} else if (!project['contentPrivacy'].role || !project['contentPrivacy'].department) {
				this.value = Object.keys(project['contentPrivacy']);
				this.personalFilters.forEach((element) => {
					if (element.key === this.value[0]) {
						this.privacyString = element.valueLabel;
					}
				});
				if (project['contentPrivacy'].status) {
					this.privacyString = project['contentPrivacy'].status;
				} else if (project['contentPrivacy'].renewalStatus) {
					this.privacyString = project['contentPrivacy'].renewalStatus;
				} else if (project['contentPrivacy'].membership_level) {
					const index = this.SubscriptionTemplates.findIndex((val) => val.uid === project['contentPrivacy'].membership_level);
					if (index > -1) {
						this.privacyString = this.SubscriptionTemplates[index]['name'];
					}
				} else if (project['contentPrivacy'].trialPeriod) {
					this.privacyString = project['contentPrivacy'].trialPeriod;
				} else if (project['contentPrivacy'].manager) {
					this.privacyString = this.translate.instant('My Team');
				}
			}
			this.forNames = this.privacyString;
		} else if (project['channel'] && Object.keys(project['channel']).length) {
			this.privacyString = project['channel'].name;
			this.forNames = this.privacyString;
		} else {
			this.privacyString = 'Everyone';
			this.forNames = this.privacyString;
		}
		this.privacy.previousSelection = this.forNames;
		this.privacy.currentSelection = this.forNames;
	}

	getProjectToDos(params) {
		this.sortByAssignee = false;
		this.todoItems = [];
		this.todosFetched = 0;
		this.networkService.getProjectTodos(this.projectDetails['slug'], params, this.isArchived).subscribe((data) => {
			if (data.next) {
				this.item_next_url = data.next.split('alpha')[1];
				this.todosFetched = 0;
				this.paginateTodos();
			} else {
				this.item_next_url = '';
			}

			this.todoItems = data.objects;
			this.todosFetched = 1;
			this.handleRelatedContent();
			this.showOneOffDate();
			this.getEstimatedTime();
		});
	}

	displayTodos(tab) {
		if (this.todosFetched === 1) {
			this.getProjectAndSections();
			if (tab === 'myTasks') {
				localStorage.setItem('detail-tab', 'myTasks');
				this.getAllTodos('open=true&assigned=true', 'myTasks');
			} else if (tab === 'completed') {
				localStorage.setItem('detail-tab', 'completed-tasks');
				this.getAllTodos('closed=All Time', 'completed');
			} else if (tab === 'open') {
				localStorage.setItem('detail-tab', 'open');
				this.getAllTodos('open=true', 'open');
			} else {
				localStorage.removeItem('detail-tab');
				this.getAllTodos('', 'all');
			}
		}
	}

	getProjectAndSections() {
		this.userMentions = [];
		this.hashMentions = [];
		this.sections = [];
		this.toggler = {};
		this.sectionsFetched = 0;
		this.networkService.getProjectDetail(this.projectId).subscribe(
			(detail) => {
				this.projectDetails = detail;
				this.getSections();
				this.calculateProgressBar();
			},
			(error) => {
				this.sectionsFetched = 1;
			}
		);
	}

	getSections() {
		const limit: number = this.projectDetails['sectionCount'] + 1;
		this.networkService.getProjectSections(this.projectDetails['uid'], limit).subscribe(
			(data) => {
				this.sections = data.objects;

				this.sectionsFetched = 1;
				this.sections.forEach((section) => {
					section['toggle'] = false;
				});
			},
			(error) => {
				this.sectionsFetched = 1;
			}
		);
	}

	getAllTodos(param, tab) {
		this.tab = tab;
		this.sortByAssignee = false;
		this.todoItems = [];
		this.todosFetched = 0;
		this.networkService.getTodosForProjects(this.projectDetails['uid'], param, this.isArchived).subscribe((data) => {
			if (data.next) {
				this.item_next_url = data.next.split('alpha')[1];
				this.todosFetched = 0;
			} else {
				this.item_next_url = '';
			}

			this.todoItems = data.objects;

			this.todosFetched = 1;
			this.handleRelatedContent();
			this.showOneOffDate();
			this.getEstimatedTime();
			this.checkUserMentionAndHashtagAndAlterBody();
		});
	}

	paginateTodos() {
		if (this.item_next_url) {
			if (this.scrollStatus) {
				return;
			}
			this.scrollStatus = true;
			this.todosFetched = 0;
			this.paginating = true;
			this.networkService.paginateToDoLists(this.item_next_url).subscribe((data) => {
				this.paginating = false;
				if (data.next) {
					this.item_next_url = data.next.split('alpha')[1];
				} else {
					this.item_next_url = '';
				}
				this.todoItems = this.todoItems.concat(data.objects);
				this.scrollStatus = false;
				this.todosFetched = 1;
				this.handleRelatedContent();
				this.showOneOffDate();
				this.getEstimatedTime();
				this.checkUserMentionAndHashtagAndAlterBody();
			});
		}
	}

	/**
	 * Function to check the user mention and hash tag in post list if exists change the post body
	 * 1.If string contains '<',then its cut off while displaying in innerHtml.Hence its replaced by '< '
	 */
	private checkUserMentionAndHashtagAndAlterBody(): void {
		this.todoItems.forEach((todo) => {
			if (todo['title'] && todo['title'].indexOf('<') > -1) {
				// 1
				todo['title'] = todo['title'].replace(/</g, '< ');
			}

			todo['altered_title'] = todo['title'];
			if (todo['mentionedUsers']) {
				todo['mentionedUsers'].forEach((mentionedUser) => {
					const html = '<b #userMention><a class="text-green">@' + mentionedUser['name'] + '</a></b>';
					if (todo.altered_title) {
						todo['altered_title'] = todo.altered_title.replace('@' + mentionedUser['name'], html);
					}
				});
			}
			if (todo['hashtags']) {
				todo['hashtags'].forEach((hashtag) => {
					if (hashtag['name']) {
						const html = '<b #hashTag><a class="hash-todo">#' + hashtag['name'] + '</a></b>';
						if (todo.altered_title) {
							todo['altered_title'] = todo.altered_title.replace('#' + hashtag['name'], html);
						}
					}
				});
			}
		});
	}

	handleRelatedContent() {
		this.todoItems.forEach((todo) => {
			if (todo['relatedContent'] && todo['relatedContent']['attachmentDetails'] && todo['relatedContent']['type']) {
				todo['attachmentDetails'] = todo['relatedContent']['attachmentDetails'];
			}
		});
	}

	showOneOffDate() {
		this.todoItems.forEach((item) => {
			if (item.repeatCycle === 'one_off' && item.oneOff) {
				const newDate = new Date(item.oneOff).toLocaleString();
				const month = new Date(item.oneOff).toLocaleString('default', {
					month: 'short',
				});
				const date = newDate.substring(0, newDate.indexOf('/'));
				const taskDate = date + '/' + month;
				item['newDate'] = taskDate;

				const date2 = new Date(item['oneOff']);
				item['oneOffDay'] = date2.getDate();
				const month2 = date2.toLocaleString('default', { month: 'long' });
				item['oneOffMonth'] = month2.substring(0, 3);
			}
		});
	}

	getEstimatedTime() {
		this.todoItems.forEach((item) => {
			if (item.startTime) {
				const localTime = new Date(item.startTime).toLocaleString();
				const d: any = new Date(item.startTime);
				const currentDate: any = new Date().toUTCString();
				if (currentDate > d) {
				} else {
					const sliced = localTime.slice(localTime.lastIndexOf(',') + 1, localTime.lastIndexOf(':'));
					item['localEstimatedTime'] = sliced;
				}
			} else if (item.estimatedTime) {
				const localTime = new Date(item.estimatedTime).toLocaleString();
				const d = new Date(item.estimatedTime);
				const currentDate = new Date();
				if (currentDate > d) {
				} else {
					const sliced = localTime.slice(localTime.lastIndexOf(',') + 1, localTime.lastIndexOf(':'));
					item['localEstimatedTime'] = sliced;
				}
			}

			if (item.startTime) {
				const localTime = new Date(item.startTime).toLocaleString();
				const d = new Date(item.startTime);
				const currentDate = new Date();
				if (currentDate > d) {
				} else {
					const sliced = localTime.slice(localTime.lastIndexOf(',') + 1, localTime.lastIndexOf(':'));
					item['localStartTime'] = sliced;
				}
			}

			if (item.dueTime && item.dueTime !== '0') {
				item['dueTimeExists'] = true;
			} else if (!item.dueTime || item.dueTime === '0') {
				if (item.estimatedTime) {
					const d = new Date(item.estimatedTime);
					const currentDate = new Date();
					if (currentDate > d) {
						item['dueTimeExists'] = true;
					} else {
						item['dueTimeExists'] = false;
					}
				} else {
					item['dueTimeExists'] = false;
				}
			}
		});
	}

	/**
	 * Display delete todo item modal
	 */
	showDeleteItemModal(item) {
		if (item['repeatFreequency']) {
			this.repeatModal = 'block';
			this.selectedToDoItem = item;
		} else {
			this.deleteItem_modal = 'block';
			this.selectedToDoItem = item;
		}
	}

	/**
	 * Function to hide all modal
	 */
	public closeModal(): void {
		this.display_assignedTodos = 'none';
		this.deleteItem_modal = 'none';
		this.display_todoLists = 'none';
		this.display_reminderOptions = 'none';
		this.display_toast = 'none';
	}

	/**
	 * Function to delete a todo item
	 */
	deleteToDoItem(param) {
		this.loadingGif = 'block';
		this.networkService.deleteToDoItem(this.selectedToDoItem['uid'], param).subscribe(
			(data) => {
				this.closeModal();
				if (this.currentPage === 'projectDetail') {
					this.getDetail();
				}
				this.resetAfterDelete();
				this.repeatModal = 'none';
				this.loadingGif = 'none';
				this.resetCounts.emit();
			},
			(error) => {
				this.loadingGif = 'none';
			}
		);
	}

	resetAfterDelete() {
		if (this.kanbenView === 'none' && this.sectionsBoard === 'none') {
			if (this.tab === 'all') {
				this.displayTodos('all');
			} else if (this.tab === 'myTasks') {
				this.displayTodos('myTasks');
			} else if (this.tab === 'completed') {
				this.displayTodos('completed');
			} else {
				this.displayTodos('open');
			}
		} else if (this.kanbenView === 'block') {
			if (this.selectedToDoItem['status'] === 'open') {
				this.getOpenTasks('');
			} else if (this.selectedToDoItem['status'] === 'in_progress') {
				this.getInProgressTasks('');
			} else if (this.selectedToDoItem['status'] === 'paused') {
				this.getPausedTasks('');
			} else {
				this.getClosedTasks('');
			}
		} else if (this.sectionsBoard === 'block') {
			if (this.selectedSection) {
				this.selectedSection['subTaskCount'] = this.selectedSection['subTaskCount'] - 1;
				this.selectedSection['tasks'] = [];
				this.getTodos(this.selectedSection, '');
			} else {
				this.getAllTodos('', 'all');
			}
		}
	}

	/**
	 * Function to get todolists for todo item
	 */
	showToDoActions(item, index) {
		this.selectedToDoItem = item;
		this.todoItemIndex = index;
	}

	/**
	 * Function to get remaining todolists
	 */
	paginateLists() {
		if (this.next_url) {
			this.networkService.paginateToDoLists(this.next_url).subscribe((data) => {
				this.next_url = data.next ? data.next.split('alpha')[1] : '';

				this.todoListsForItem = this.todoListsForItem.concat(data.objects);
				if (this.next_url) {
					this.paginateLists();
				}
			});
		}
	}

	/**
	 * Function to set radio btn on/off in todolist
	 */
	onClickToDoList(list) {
		this.lists = { option: list.uid };
		this.selectedToDoList = list;
	}

	/**
	 * Function to move todoitem to another list
	 */
	moveToAnotherList() {
		if (this.selectedToDoList) {
			this.updateAttachmentDetails(this.selectedToDoItem);
			if (this.selectedToDoItem.assignedUser && this.selectedToDoItem.assignedUser.username) {
				this.todoItem['assignedUser'] = this.selectedToDoItem.assignedUser.username;
			}
			if (this.selectedToDoItem.repeatFreequency) {
				this.todoItem['repeatFreequency'] = this.selectedToDoItem.repeatFreequency;
			}
			if (this.selectedToDoItem.numberOfOpens && this.selectedToDoItem.numberOfOpens > 0) {
				this.todoItem['numberOfOpens'] = this.selectedToDoItem.numberOfOpens;
			}
			if (this.selectedToDoItem['project'] && this.selectedToDoItem['project']['uid']) {
				this.todoItem['project'] = this.selectedToDoItem['project']['uid'];
			}
			if (this.selectedToDoItem['postUid']) {
				this.comment.contentType = 'post';
				this.comment.contentUid = this.selectedToDoItem.postUid;
				this.comment['text'] = this.translate.instant(`${this.currentUser.name} has moved task to ${this.selectedToDoList['name']} list`);
			} else {
				this.comment.contentType = '';
				this.comment.contentUid = '';
			}

			this.todoItem.priority = this.selectedToDoItem.priority;
			this.todoItem.repeatCycle = this.selectedToDoItem.repeatCycle;
			this.todoItem.title = this.selectedToDoItem.title;
			this.todoItem['todolistUid'] = this.selectedToDoList.uid;
			this.todoItem['status'] = this.selectedToDoItem['status'];

			this.loadingGif = 'block';
			this.networkService.updateToDoItem(this.todoItem, this.selectedToDoItem.uid).subscribe((data) => {
				delete this.todoItem['project'];
				delete this.todoItem['repeatFreequency'];
				delete this.todoItem['oneOff'];
				delete this.todoItem['numberOfOpens'];
				this.display_todoLists = 'none';
				this.selectedToDoList = '';
				this.selectedToDoItem = '';
				this.lists['option'] = '';
				this.todoItems[this.todoItemIndex] = data;
				this.getTaskDuetime(this.todoItems);
				this.handleContents(this.todoItems);
				this.handleMentions(this.todoItems);
				this.setTask(data);
				this.loadingGif = 'none';

				if (this.comment['contentType'] && this.comment['contentUid']) {
					this.commentService.create(this.comment, this.currentUser.username).subscribe((commentData) => {
						this.loadingGif = 'none';
					});
				}
			});
		} else {
			this.display_todoLists = 'none';
			this.selectedToDoList = '';
		}
	}

	setTask(task) {
		if (this.openTasks.length > 0) {
			const index = this.openTasks.findIndex((x) => x['uid'] === task['uid']);
			if (index > -1) {
				this.openTasks[index] = task;
				this.getTaskDuetime(this.openTasks);
				this.handleContents(this.openTasks);
				this.handleMentions(this.openTasks);
			}
		}
		if (this.inProgressTasks.length > 0) {
			const index = this.inProgressTasks.findIndex((x) => x['uid'] === task['uid']);
			if (index > -1) {
				this.inProgressTasks[index] = task;
				this.getTaskDuetime(this.inProgressTasks);
				this.handleContents(this.inProgressTasks);
				this.handleMentions(this.inProgressTasks);
			}
		}
		if (this.closedTasks.length > 0) {
			const index = this.closedTasks.findIndex((x) => x['uid'] === task['uid']);
			if (index > -1) {
				this.closedTasks[index] = task;
				this.getTaskDuetime(this.closedTasks);
				this.handleContents(this.closedTasks);
				this.handleMentions(this.closedTasks);
			}
		}
		if (this.sectionsBoard === 'block' && this.selectedSection) {
			const index = this.boardSections.findIndex((section) => section['uid'] === this.selectedSection['uid']);
			if (index > -1) {
				this.boardSections[index]['tasks'][this.todoItemIndex] = task;
				this.selectedSection = '';
				this.todoItemIndex = '';
			}
		}
	}

	/**
	 * Function to close toast modal after 8 secs
	 */
	public closeReminderToastModal(): void {
		setTimeout(() => {
			this.display_toast = 'none';
		}, 8000);
	}

	/**
	 * Function to show todo item edit modal and edit component
	 */
	onEditToDoItem(item) {
		this.setTaskForSection = '';
		this.editSelectedPost = item;
		// if (this.projectDetails['contentPrivacy']) {
		//   this.todoListPrivacy = this.projectDetails['contentPrivacy'];
		// } else if (this.projectDetails['customPrivacy']) {
		//   this.todoListPrivacy = this.projectDetails.customPrivacy;
		// }
		// else if (this.projectDetails['channel'] && this.projectDetails['channel'].uid) {
		//   let uid = this.projectDetails['channel'].uid;
		//   if (this.projectDetails.channel['access'] === 4) {
		//     this.todoListPrivacy = { 'channel': uid, 'access': 'external' };
		//   } else {
		//     this.todoListPrivacy = { 'channel': uid };
		//   }
		// } else {
		//   this.todoListPrivacy = 'Everyone';
		// }
		if (item['folderDetails']) {
			if (item['folderDetails']['contentPrivacy']) {
				this.todoListPrivacy = item['folderDetails']['contentPrivacy'];
			} else if (item['folderDetails']['customPrivacy']) {
				this.todoListPrivacy = item['folderDetails']['customPrivacy'];
			} else if (item['folderDetails']['channel'] && item['folderDetails']['channel']['uid']) {
				const uid = item['folderDetails']['channel']['uid'];
				if (item['folderDetails']['channel']['access'] === 4) {
					this.todoListPrivacy = { channel: uid, access: 'external' };
				} else {
					this.todoListPrivacy = { channel: uid };
				}
			} else {
				this.todoListPrivacy = 'Everyone';
			}
		}
	}

	/**
	 * Function for done/undone actions
	 */
	onDoneUndoneClicked(item, value, index) {
		if (item['status'] === 'open' && value === 'closed' && !item['estimate']) {
			this.displayElapsed = 'block';
			setTimeout(() => {
				document.getElementById('elapsedTime').focus();
			}, 150);
			this.selectedToDoItem = item;
			this.todoItemIndex = index;
		} else {
			this.displayElapsed = 'none';
			if (value === 'closed' && !this.reaction && item['estimate']) {
				this.selectedItem = item;
				this.selectedToDoItem = item;
				this.selectedTodoIndex = index;
				this.selectedTodoValue = value;
				this.reaction = '';
				this.taskReactionDialog = 'block';
			} else {
				this.taskReactionDialog = 'none';
				this.loadingGif = 'block';
				this.updateAttachmentDetails(item);
				this.todoItem.title = item.title;
				this.todoItem['status'] = value;
				this.todoItem.priority = item.priority;
				this.todoItem.repeatCycle = item.repeatCycle;
				if (item.assignedUser && item.assignedUser.username) {
					this.todoItem['assignedUser'] = item.assignedUser.username;
				}
				if (item.repeatCycle === 'one_off') {
					const date = item.oneOff;
					const splicedDate = date.split(' ')[0];
					this.todoItem['oneOff'] = splicedDate;
				}
				if (item.repeatFreequency) {
					this.todoItem['repeatFreequency'] = item.repeatFreequency;
				}
				if (item['project'] && item['project']['uid']) {
					this.todoItem['project'] = item['project']['uid'];
				}
				if (item.numberOfOpens && item.numberOfOpens > 0) {
					this.todoItem['numberOfOpens'] = item.numberOfOpens;
				}
				if (value === 'closed') {
					const d = new Date().toISOString().slice(0, 10);
					const date = new Date();
					const hours = date.getUTCHours();
					const minutes = date.getUTCMinutes();
					const seconds = date.getUTCSeconds();
					const utcDateTime = d + ' ' + hours + ':' + minutes + ':' + seconds;
					this.todoItem['closedDate'] = utcDateTime;
				}

				if (item['postUid']) {
					this.comment.contentType = 'post';
					this.comment.contentUid = item['postUid'];
				} else {
					this.comment.contentType = '';
					this.comment.contentUid = '';
				}

				if (this.estimateForm.valid) {
					this.todoItem['estimate'] = this.estimateForm.value['time'];
				} else {
					delete this.todoItem['estimate'];
				}

				if (this.elapsedForm.valid) {
					this.todoItem['elapsed_time'] = this.elapsedForm.value['time'];
				} else {
					delete this.todoItem['elapsed_time'];
				}

				if (this.reaction) {
					this.todoItem['outcome'] = this.reaction;
				}

				this.networkService.updateToDoItem(this.todoItem, item.uid).subscribe(
					(data) => {
						if (data.status === 'closed') {
							this.mixPanelService.track('Completed Task', {
								workspace: this.network?.name || 'NA',
								appVersion: environment.version,
								language: this.translate?.currentLang,
								username: this.currentUser?.username || 'NA',
								name: this.currentUser?.name || 'NA',
								project: data?.project?.name || 'NA',
								date: moment().format('YYYY-MM-DD'),
							});

							this.segmentService.trackEvent('Completed Task', {
								workspace: this.network?.name || 'NA',
								appVersion: environment.version,
								language: this.translate?.currentLang,
								username: this.currentUser?.username || 'NA',
								name: this.currentUser?.name || 'NA',
								project: data?.project?.name || 'NA',
								date: moment().format('YYYY-MM-DD'),
							});
						}
						this.loadingGif = 'none';
						this.squareSpinner = 'block';
						setTimeout(() => {
							this.squareSpinner = 'none';
						}, 1300);
						delete this.todoItem['closedDate'];
						delete this.todoItem['project'];
						delete this.todoItem['repeatFreequency'];
						delete this.todoItem['oneOff'];
						delete this.todoItem['numberOfOpens'];
						delete this.todoItem['estimate'];
						delete this.todoItem['elapsed_time'];
						delete this.todoItem['outcome'];
						this.selectedItem = '';
						this.selectedTodoIndex = '';
						this.selectedTodoValue = '';
						this.reaction = '';

						if (this.currentPage === 'projectDetail') {
							this.getDetail();
						}

						if (this.sectionsBoard === 'block' && this.selectedSection) {
							const i = this.boardSections.findIndex((section) => section['uid'] === this.selectedSection['uid']);
							if (i > -1) {
								this.boardSections[i]['tasks'][index] = data;
								this.getTaskDuetime(this.boardSections[i]['tasks']);
								this.handleContents(this.boardSections[i]['tasks']);
								this.handleMentions(this.boardSections[i]['tasks']);
								this.selectedSection = '';
							}
						} else {
							if (index > -1) {
								this.todoItems[index] = data;
								this.showOneOffDate();
							} else {
								this.todoItems.unshift(data);
								this.showOneOffDate();
							}
							this.getEstimatedTime();
							this.checkUserMentionAndHashtagAndAlterBody();
						}

						this.createAutoComments(value, data);
						this.createAutoCommentsForTask(value, data);
						this.reset();
					},
					(error) => {
						this.loadingGif = 'none';
						this.selectedSection = '';
					}
				);
			}
		}
	}

	createAutoCommentsForTask(status, todo) {
		const comment: any = {};
		comment.contentType = 'todo';
		comment.contentUid = todo['uid'];
		if (status === 'closed') {
			comment['text'] = this.translate.instant('Task Completion', {
				time: todo['elapsedTime'],
			});
		} else if (status === 'open') {
			comment['text'] = this.translate.instant('Task Reopened');
		} else if (status === 'in_progress') {
			comment['text'] = this.translate.instant('started', {
				time: todo['estimate'],
			});
		} else {
			comment['text'] = this.translate.instant('Task Pause');
		}
		this.commentService.create(comment, this.currentUser.username).subscribe(() => {});
	}

	/**
	 * Function to handle start and pause task in sections board view
	 * @param status
	 * @param todo
	 */
	handleStartAndPauseTaskInSectionBoard(item, value, index, section) {
		if (section) {
			this.selectedSection = section;
		} else {
			this.selectedSection = '';
		}

		// if(value === 'closed'){
		//   if(item['estimate'] && !this.reaction){

		//   }
		// }

		if (value === 'in_progress') {
			if (item['estimate'] && item['estimate'] > 0) {
				this.onDoneUndoneClicked(item, value, index);
			} else {
				this.displayEstimate = 'block';
				setTimeout(() => {
					document.getElementById('estimateTime').focus();
				}, 150);
				this.selectedToDoItem = item;
				this.todoItemIndex = index;
			}
		} else {
			this.onDoneUndoneClicked(item, value, index);
		}
	}

	/** \
	 * THis will create comments when a todo is open/closed/paused etc..
	 */
	createAutoComments(status, todo) {
		if (status === 'closed') {
			// this.comment['text'] = this.translate.instant('Task Completion') + `: ${todo.title}`;
			this.comment['text'] = this.translate.instant('task-completion', {
				title: todo['title'],
				time: todo['elapsedTime'],
			});
		} else if (status === 'open') {
			this.comment['text'] = this.translate.instant('Task Reopened') + `: ${todo.title}`;
		} else if (status === 'in_progress') {
			this.comment['text'] = this.translate.instant('task-start', {
				title: todo['title'],
				time: todo['estimate'],
			});
		} else {
			this.comment['text'] = this.translate.instant('task-paused', {
				title: todo['title'],
			});
		}
		if (this.comment['contentType'] && this.comment['contentUid']) {
			this.commentService.create(this.comment, this.currentUser.username).subscribe(
				() => {
					// this.comment.contentType = 'todo';
					// this.comment.contentUid = todo['uid'];
					// if (status === 'closed') {
					//   this.comment['text'] = this.translate.instant('Task Completion', {
					//     time: todo['elapsedTime']
					//   });
					// } else if (status === 'open') {
					//   this.comment['text'] = this.translate.instant('Task Reopened')
					// } else if (status === 'in_progress') {
					//   this.comment['text'] = this.translate.instant('started', {
					//     time: todo['estimate']
					//   });
					// } else {
					//   this.comment['text'] = this.translate.instant('Task Pause');
					// }
					// this.commentService.create(this.comment, this.currentUser.username).subscribe(() => {
					// });
				},
				(error) => {}
			);
		}
	}

	public getDownloadLink(attachment): string {
		return attachment['url'] + '?cType=' + attachment['type'] + '&file=' + attachment['name'];
	}

	/**
	 * Pass attachment details on todo for done/undone or move actions
	 * @param post
	 */
	updateAttachmentDetails(todo) {
		delete this.todoItem['doc'];
		delete this.todoItem['url'];
		delete this.todoItem['member'];
		delete this.todoItem['attachmentType'];
		delete this.todoItem['images'];

		if (todo.attachmentDetails.gallery && todo.attachmentDetails.gallery.length > 0) {
			this.todoItem['images'] = [];
			const images: any[] = [];
			todo.attachmentDetails.gallery.forEach((img) => {
				this.todoItem['attachmentType'] = 'photo';
				images.push(img.image.uid);
			});
			this.todoItem['images'] = images.join('|');
		}
		if (todo.attachmentDetails.location && todo.attachmentDetails.location.length > 0) {
			this.todoItem['attachmentType'] = 'location';
			this.todoItem['location'] = todo.attachmentDetails.location[0].uid;
		}
		if (todo.attachmentDetails.doc && todo.attachmentDetails.doc[0].uid) {
			this.todoItem['attachmentType'] = 'doc';
			this.todoItem['doc'] = todo.attachmentDetails.doc[0].uid;
		}
		if (todo.attachmentDetails.url && todo.attachmentDetails.url[0].url) {
			this.todoItem['attachmentType'] = 'url';
			this.todoItem['url'] = todo.attachmentDetails.url[0].url;
		}
		if (todo.attachmentDetails.member && todo.attachmentDetails.member['uid']) {
			this.todoItem['attachmentType'] = 'member';
			this.todoItem['member'] = todo.attachmentDetails.member['uid'];
		}
	}

	/**
	 * Function which activates plus button and displays modal
	 */
	public activateButton(): void {
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
		this.layoutService.hideBd();
		this.showAddTaskForm();
		this.setTaskForSection = '';
	}

	/**
	 * Function which  displays add task form
	 */
	showAddTaskForm() {
		this.showPostCreateForm = !this.showPostCreateForm;
		if (this.showPostCreateForm) {
			this.layoutService.showBd('event-post-form');
			this.postType = 'add-task';
		}
	}

	/**
	 * Function to Redirect to post detail page
	 */
	checkIfRedirect(e, item) {
		if (e.target && e.target.className) {
			const type = e.target.className;
			if (type === 'hash-todo') {
				const text = e.target.innerHTML.split('#')[1];
				const hashtags = item['hashtags'];
				hashtags.forEach((tag) => {
					if (tag['name'] === text) {
						this.router.navigateByUrl('/network/todolist-detail?hash_tag=' + tag['uid'] + '&name=' + tag['name']);
					}
				});
			} else if (type === 'text-green') {
				const text = e.target.innerHTML.split('@')[1];
				item['mentionedUsers'].forEach((mentionedUser) => {
					if (mentionedUser['name'] === text) {
						this.router.navigateByUrl('/network/profile/' + mentionedUser?.memberId + '?username=' + mentionedUser?.username);
					}
				});
			} else {
				if (
					type !== 'pb-icon pb-like' &&
					type !== 'pb-icon pb-liked' &&
					type !== 'pb-icon pb-like ng-star-inserted' &&
					type !== 'pb-icon pb-liked ng-star-inserted' &&
					type !== 'edit-img' &&
					type !== 'dropdown-item dropdown-border text-center' &&
					type !== 'todo-off' &&
					type !== 'todo-on' &&
					type !== 'todo-off ng-star-inserted' &&
					type !== 'todo-on ng-star-inserted' &&
					type !== 'dropdown-item dropdown-border text-center ng-star-inserted'
				) {
					this.handleRedirections(item);
				}
			}
		} else {
			this.handleRedirections(item);
		}
	}

	handleRedirections(item) {
		if (item.relatedContent && item.relatedContent.contentType) {
			localStorage.setItem('previous_route', this.router.url);
			if (item.relatedContent.type && item.relatedContent.type !== 'customer') {
				if (item.relatedContent.contentType === 'post') {
					this.router.navigateByUrl('/posts/' + item.relatedContent.contentUid);
				} else if (item.relatedContent.contentType === 'issue') {
					this.router.navigateByUrl(`/network/issue-detail?id=${item.relatedContent.contentUid}`);
				}
			} else {
				this.router.navigateByUrl(`/network/customer/${item.relatedContent.contentUid}`);
			}
		} else {
			if (item['folderDetails'] && item['folderDetails']['uid']) {
				let url;
				if (this.isArchived) {
					url = `/project/task-detail/${item.uid}&listUid=${item['folderDetails']['uid']}&archived=true`;
				} else {
					url = `/project/task-detail/${item.uid}&listUid=${item['folderDetails']['uid']}`;
				}
				this.router.navigateByUrl(url);
			} else {
				let url;
				if (this.isArchived) {
					url = `/project/task-detail/${item.uid}&archived=true`;
				} else {
					url = `/project/task-detail/${item.uid}`;
				}
				this.router.navigateByUrl(url);
			}
		}
	}

	sortTodoItems(key, value) {
		this.todoItems = [];
		this.todosFetched = 0;
		this.filter = key;
		const string = key + '=' + value;
		this.getAllTodos(string, 'all');
		this.getSections();
	}

	getTodoLists() {
		this.display_todoLists = 'block';
		this.todoListsForItem = [];
		this.networkService.getToDoListForItem().subscribe((list) => {
			this.todoListsForItem = list.objects;
			if (list.next) {
				this.next_url = list.next.split('alpha')[1];
				this.paginateLists();
			} else {
				this.next_url = '';
			}
		});
	}

	getAllTodoLists(section) {
		if (section) {
			this.selectedSection = section;
		} else {
			this.selectedSection = '';
		}
		this.isListsFetching = 0;
		this.display_todoLists = 'block';
		this.todoListsForItem = [];
		this.next_url = '';
		this.networkService.getToDoListForItem().subscribe((list) => {
			this.todoListsForItem = list.objects;
			this.isListsFetching = 1;
			if (list.next) {
				this.next_url = list.next.split('alpha')[1];
				this.paginateLists();
			} else {
				this.next_url = '';
			}

			if (this.selectedToDoItem['folderDetails'] && this.selectedToDoItem['folderDetails']['uid']) {
				this.lists['option'] = this.selectedToDoItem['folderDetails']['uid'];
			} else {
				this.lists['option'] = '';
			}
		});
	}

	getProjectToDosForCalendar(uid, params) {
		this.todoItems = [];
		this.sections = [];
		this.filter = '';
		this.sectionsFetched = 0;
		this.todosFetched = 0;
		params = params + '&open=true&network=' + localStorage.getItem('network_id');
		this.networkService.getTodosForProjectsInCal(uid, params, this.archivedList).subscribe((data) => {
			if (data.next) {
				this.item_next_url = data.next.split('alpha')[1];
				this.todosFetched = 0;
				this.paginateTodos();
			} else {
				this.item_next_url = '';
			}

			this.todoItems = data.objects;
			this.todosFetched = 1;
			this.sectionsFetched = 1;
			this.handleRelatedContent();
			this.showOneOffDate();
			this.getEstimatedTime();
			this.checkUserMentionAndHashtagAndAlterBody();
		});
	}

	getDetail() {
		this.networkService.getProjectDetail(this.projectId).subscribe((detail) => {
			this.projectDetails = detail;
			this.updatePrivacyString(detail);
			this.calculateProgressBar();
		});
	}

	/**
	 * Function to execute like on todo
	 * @param todo
	 * 1. Call like api
	 * 2. Set like uid in myRelations of todo detail object
	 * 3. Set liked text within todo object
	 */
	likeTodo(todo) {
		this.postService.like(this.currentUser.username, todo).subscribe((data) => {
			// 1
			todo.myRelations = { like: data.uid }; // 2
			if (todo['myRelations'] && todo['myRelations'].like && todo['likesCount']['count'] === 0) {
				// 3
				todo['likedText'] = this.translate.instant('You liked this');
			}
		});
	}

	/**
	 * Function to execute dislike on todo
	 * @param todo
	 * 1. Call dislike api
	 * 2. Remove like uid from myRelations of todo object
	 */
	dislikeTodo(todo) {
		this.postService.dislike(this.currentUser.username, todo.myRelations.like).subscribe((data) => {
			// 1
			todo.myRelations = { like: null };
			todo['likedText'] = ''; // 2
			todo['likesCount']['count'] = 0;
		});
	}

	public handleLinkShare(link: string): void {
		this.clipboardService.copy(link);
	}

	public handleTodoLinkShare(task: any): void {
		this.clipboardService.copy(`${task.title} ${task.shareUrl}`);
	}

	handleArchive(state: string) {
		const archived: any = {
			startDate: null,
		};

		archived['name'] = this.projectDetails['name'];

		if (state === 'archive') {
			archived['archived'] = 'true';
		} else {
			archived['archived'] = 'false';
		}

		if (this.projectDetails.startDate) {
			archived.startDate = this.setProjectDate(this.projectDetails.startDate);
		}

		if (this.projectDetails.endDate) {
			archived.endDate = this.setProjectDate(this.projectDetails.endDate);
		}

		if (this.projectDetails['contentPrivacy']) {
			archived['content_privacy'] = JSON.stringify(this.projectDetails['contentPrivacy']);
		} else if (this.projectDetails['customPrivacy'] && this.projectDetails['customPrivacy'].length > 0) {
			const customPrivacy: any[] = [];
			// let custom: any;
			this.projectDetails.customPrivacy.forEach((element) => {
				if (element.userId) {
					customPrivacy.push(element.userId);
				} else {
					customPrivacy.push(element.id);
				}
			});
			const custom = '[' + customPrivacy.join(',') + ']';
			archived['custom_privacy'] = custom;
		} else if (this.projectDetails['channel'] && this.projectDetails['channel']['uid']) {
			archived['channel'] = this.projectDetails['channel']['uid'];
		}

		this.loadingGif = 'block';
		this.networkService.updateProject(archived, this.network.uid, this.projectDetails.uid).subscribe(
			(data) => {
				this.loadingGif = 'none';
				this.projectDetails = data;
				this._location.back();
			},
			(error) => {}
		);
	}

	setProjectDate(date) {
		const index = date.indexOf(' ');
		if (index > -1) {
			return date.substring(0, index);
		} else {
			return date;
		}
	}

	redirectToCalendar() {
		let url;
		if (this.isArchived) {
			url = `/network/calendar?slug=${this.projectDetails['slug']}&uid=${this.projectDetails['uid']}&archived=true`;
		} else {
			url = `/network/calendar?slug=${this.projectDetails['slug']}&uid=${this.projectDetails['uid']}&name=${this.projectDetails['name']}`;
		}
		localStorage.removeItem('detail-tab');
		this.router.navigateByUrl(url);
	}

	public confirmProjectDelete(): void {
		this.loadingGif = 'block';
		this.networkService.deleteProject(this.network.uid, this.projectDetails['uid']).subscribe((data) => {
			this.showDeleteConfirmation = 'none';
			this.loadingGif = 'none';
			this._location.back();
		});
	}

	displayEditProject() {
		if (this.projectDetails) {
			this.editProject = 'block';
			this.handleProjectPrivacy(this.projectDetails);
			this.projectName = this.projectDetails['name'];
			if (this.projectDetails['startDate']) {
				const date = new Date(this.projectDetails['startDate']);
				const month = date.getUTCMonth() + 1;
				const day = date.getUTCDate();
				const year = date.getUTCFullYear();
				this.projectStart = {
					year: year,
					month: month,
					day: day,
				};
			}
			if (this.projectDetails['endDate']) {
				const date = new Date(this.projectDetails['endDate']);
				const month = date.getUTCMonth() + 1;
				const day = date.getUTCDate();
				const year = date.getUTCFullYear();
				this.projectEnd = {
					year: year,
					month: month,
					day: day,
				};
			}
		}
	}

	public onPrivacyClick(): void {
		this.privacy.showPrivacy();
		this.privacy.privacy_settings = 'block';
	}

	handleProjectPrivacy(data) {
		if (data) {
			if (data['contentPrivacy']) {
				this.privacy['content_privacy'] = data['contentPrivacy'];
				if (data['contentPrivacy'].department) {
					const index = this.departments.findIndex((department) => department.uid === data['contentPrivacy'].department);
					if (index > -1) {
						this.privacy['selectedDepartment'] = this.departments[index];
					}
				} else {
					this.privacy['selectedDepartment'] = null;
				}
				if (data['contentPrivacy'].role) {
					const index = this.roles.findIndex((rol) => rol.name === data['contentPrivacy'].role);
					this.privacy['role'] = this.roles[index];
				} else {
					this.privacy['role'] = null;
				}

				if (!data['contentPrivacy'].role && !data['contentPrivacy'].department) {
					const field = Object.keys(data['contentPrivacy']);
					this.personalFilters.forEach((element) => {
						if (element.key === field[0] && field[0] !== 'manager') {
							this.privacy['personalFilter'] = element;
						}
					});
					if (data['contentPrivacy'].status) {
						this.privacy['selectByStatusValue'] = data['contentPrivacy'];
					} else if (data['contentPrivacy'].renewalStatus) {
						this.privacy['selectByRenewalValue'] = data['contentPrivacy'];
					} else if (data['contentPrivacy'].membership_level) {
						this.privacy['selectByLevelValue'] = data['contentPrivacy'];
					} else if (data['contentPrivacy'].manager) {
						if (this.privacy.myTeam && this.privacy.myTeam.key) {
							this.privacy['personalFilter'] = this.privacy.myTeam;
						}
					}
				}
			} else {
				this.privacy['content_privacy'] = null;
				this.privacy['selectedDepartment'] = null;
				this.privacy['role'] = null;
			}
			if (data['customPrivacy']) {
				this.privacy['custom_privacy'] = data['customPrivacy'];
			} else {
				this.privacy['custom_privacy'] = [];
			}
			if (data['channel']) {
				this.privacy.channel = data['channel'];
			}
		}
	}

	public onEditProject(): void {
		if (!this.projectName) {
			this.displayProjectError = true;
		} else {
			this.project['startDate'] = this.parserFormatter.format(this.projectStart);
			this.project['endDate'] = this.parserFormatter.format(this.projectEnd);
			this.project.name = this.projectName;
			this.updatePrivacyValues();
			this.loadingGif = 'block';
			this.networkService.updateProject(this.project, this.network.uid, this.projectDetails.uid).subscribe((data) => {
				this.getDetail();
				this.displayProjectError = false;
				this.loadingGif = 'none';
				this.editProject = 'none';
			});
		}
	}

	public onKeyDown(e: any): void {
		if (!e.target.value) {
			this.displayProjectError = true;
		} else {
			this.displayProjectError = false;
		}
	}

	updatePrivacyValues() {
		if (this.privacy.custom_privacy && this.privacy.custom_privacy.length > 0) {
			delete this.project['content_privacy'];
			delete this.project['channel'];
			this.project['custom_privacy'] = [];
			const names = [];
			const newObject = this.privacy.custom_privacy;
			newObject.forEach((member) => {
				member.privacyType = 'customPrivacy';
			});
			localStorage.setItem(`${this.network['uid']}`, JSON.stringify(newObject));
			this.privacy.custom_privacy.forEach((user) => {
				if (user['user']) {
					this.project['custom_privacy'].push(user['user'].userId);
					names.push(user['user'].name);
				} else if (user.userId) {
					this.project['custom_privacy'].push(user.userId);
					names.push(user.username);
				} else {
					this.project['custom_privacy'].push(user.id);
					names.push(user.username);
				}
			});
		} else {
			let roleString = null;
			let departmentString = null;
			let personalFilterString = null;
			let selectByStatusString = null;
			let selectByRenewalString = null;
			let selectByLevelString = null;
			delete this.project['custom_privacy'];
			delete this.project['channel'];

			if (this.privacy.selectedRole && this.privacy.selectedDepartment) {
				roleString = '"role": "' + this.privacy.selectedRole.slug + '"';
				departmentString = '"department": "' + this.privacy.selectedDepartment['value'] + '"';
			} else if (this.privacy.selectedRole) {
				roleString = '"role": "' + this.privacy.selectedRole.slug + '"';
				roleString = '"role": "' + this.privacy.selectedRole.slug + '"';
				const newObject = this.privacy.selectedRole;
				newObject.privacyType = 'role';
				localStorage.setItem(`${this.network['uid']}`, JSON.stringify(newObject));
			} else if (this.privacy.selectedDepartment) {
				if (this.privacy.selectedDepartment['uid']) {
					departmentString = '"department":"' + this.privacy.selectedDepartment['uid'] + '"';
				}
				if (this.privacy.selectedDepartment['value']) {
					departmentString = '"department":"' + this.privacy.selectedDepartment['value'] + '"';
				}
				const newObject = this.privacy.selectedDepartment;
				newObject['privacyType'] = 'department';
				localStorage.setItem(`${this.network['uid']}`, JSON.stringify(newObject));
			} else if (this.privacy.personalFilter) {
				let personalFilterName = '';
				personalFilterName = this.privacy.personalFilter['valueLabel'];
				personalFilterString = '"' + this.privacy.personalFilter['key'] + '": "' + this.privacy.personalFilter['value'] + '"';
				const newObject = this.privacy.personalFilter;
				newObject['privacyType'] = 'personalFilter';
				localStorage.setItem(`${this.privacy.networkId}`, JSON.stringify(newObject));
			} else if (this.privacy.selectByStatusValue) {
				if (this.privacy.selectByStatusValue.status) {
					selectByStatusString = '"status": "' + this.privacy.selectByStatusValue.status + '"';
				} else {
					selectByStatusString = '"status": "' + this.privacy.selectByStatusValue[0] + '"';
					const newObject = this.privacy.selectByStatusValue;
					if (newObject.includes('status') === false) {
						newObject.push('status');
					}
					localStorage.setItem(`${this.network['uid']}`, JSON.stringify(newObject));
				}
			} else if (this.privacy.selectByRenewalValue) {
				if (this.privacy.selectByRenewalValue.renewalStatus) {
					selectByRenewalString = '"renewalStatus": "' + this.privacy.selectByRenewalValue.renewalStatus + '"';
				} else {
					selectByRenewalString = '"renewalStatus": "' + this.privacy.selectByRenewalValue[0] + '"';
					const newObject = this.privacy.selectByRenewalValue;
					if (newObject.includes('renewalFrequency') === false) {
						newObject.push('renewalFrequency');
					}
					localStorage.setItem(`${this.network['uid']}`, JSON.stringify(newObject));
				}
			} else if (this.privacy.selectByLevelValue) {
				if (this.privacy.selectByLevelValue.uid) {
					selectByLevelString = '"membership_level": "' + this.privacy.selectByLevelValue.uid + '"';
					const newObject = this.privacy.selectByLevelValue;
					newObject.privacyType = 'selectByLevel';
					localStorage.setItem(`${this.network['uid']}`, JSON.stringify(newObject));
				} else {
					selectByLevelString = '"membership_level": "' + this.privacy.selectByLevelValue.membership_level + '"';
				}
			} else if (this.privacy.channel) {
				this.project['channel'] = this.privacy.channel['uid'];
				const newObject = this.privacy.channel;
				newObject['privacyType'] = 'subCircle';
				localStorage.setItem(`${this.network['uid']}`, JSON.stringify(newObject));
			}
			if (
				this.privacy.custom_privacy.length === 0 &&
				!this.privacy.selectedRole &&
				!this.privacy.role &&
				!this.privacy.personalFilter &&
				!this.privacy.selectedDepartment &&
				!this.privacy.channel &&
				!this.privacy.selectByStatusValue &&
				!this.privacy.selectByRenewalValue &&
				!this.privacy.selectByLevelValue
			) {
				localStorage.setItem(`${this.network['uid']}`, JSON.stringify('Everyone'));
			}
			if (roleString && departmentString) {
				this.project['content_privacy'] = '{' + roleString + ', ' + departmentString + '}';
			} else if (roleString && !departmentString) {
				this.project['content_privacy'] = '{' + roleString + '}';
			} else if (!roleString && departmentString) {
				this.project['content_privacy'] = '{' + departmentString + '}';
			} else if (personalFilterString) {
				this.project['content_privacy'] = '{' + personalFilterString + '}';
			} else if (selectByStatusString) {
				this.project['content_privacy'] = '{' + selectByStatusString + '}';
			} else if (selectByRenewalString) {
				this.project['content_privacy'] = '{' + selectByRenewalString + '}';
			} else if (selectByLevelString) {
				this.project['content_privacy'] = '{' + selectByLevelString + '}';
			} else {
				delete this.project['content_privacy'];
			}
		}
		if (this.project['custom_privacy'] && this.project['custom_privacy'].length > 0) {
			this.project['custom_privacy'] = '[' + this.project['custom_privacy'].join(',') + ']';
		}
		this.updatePrivacyStringBasedOnPrivacyComponent();
	}

	public updatePrivacyStringBasedOnPrivacyComponent(): void {
		this.forNames = '';
		if (this.privacy.isPrivate) {
			this.forNames = this.currentUser.name;
		} else if (this.privacy.custom_privacy && this.privacy.custom_privacy.length > 0) {
			const names = [];
			this.privacy.custom_privacy.forEach((user) => {
				if (user['user']) {
					names.push(user['user'].name);
				} else if (user['name']) {
					names.push(user['name']);
				} else {
					names.push(user.username);
				}
			});
			this.forNames = names.join(',');
		} else {
			if (this.privacy.selectedRole && this.privacy.selectedDepartment) {
				this.forNames = this.privacy.selectedRole.name + ', ' + this.privacy.selectedDepartment.valueLabel;
			} else if (this.privacy.selectedRole) {
				let roleName;
				if (this.privacy.selectedRole.name) {
					roleName = this.privacy.selectedRole.name;
				} else {
					roleName = this.privacy.selectedRole;
				}
				this.forNames = roleName;
			} else if (this.privacy.selectedDepartment) {
				let departmentName;
				if (this.privacy.selectedDepartment.valueLabel) {
					departmentName = this.privacy.selectedDepartment.valueLabel;
					this.forNames = departmentName;
				}
				if (this.privacy.selectedDepartment.name && this.privacy.selectedDepartment.uid) {
					departmentName = this.privacy.selectedDepartment.name;
					this.forNames = departmentName;
				}
			} else if (this.privacy.personalFilter) {
				let personalFilter = '';
				personalFilter = this.privacy.personalFilter.valueLabel;
				this.forNames = personalFilter;
			} else if (this.privacy.channel) {
				this.forNames = this.privacy.channel.name;
			} else if (this.privacy.selectByRenewalValue) {
				this.forNames = this.privacy.selectByRenewalValue[1];
			} else if (this.privacy.selectByLevelValue) {
				this.forNames = this.privacy.selectByLevelValue.name;
			} else if (this.privacy.selectByStatusValue) {
				this.forNames = this.privacy.selectByStatusValue[1];
			} else {
				this.forNames = 'Everyone';
			}
		}
	}

	/**
	 * On clicking reminder options
	 * @param reminder
	 */
	public onSelectReminder(reminder): void {
		const index = this.remindersSelected.findIndex((r) => r === reminder);
		if (index > -1) {
			this.remindersSelected.splice(index, 1);
		} else {
			this.remindersSelected.push(reminder);
		}
	}

	displayReminders(todo) {
		this.todoForReminder = todo;
		this.displayReminderModal = 'block';
		this.reminderOptions = [];
		this.networkService.getReminderOptions().subscribe((data) => {
			if (todo['estimatedTime']) {
				data.eventReminderOptions.forEach((obj) => {
					if (
						obj[0] !== 'ten_minutes_before' &&
						obj[0] !== 'fifteen_minutes_before' &&
						obj[0] !== 'one_day_before' &&
						obj[0] !== 'two_day_before' &&
						obj[0] !== 'one_week_before'
					) {
						this.reminderOptions.push(obj);
					}
				});
			} else {
				this.reminderOptions = data.reminderOptions;
			}
			if (this.remindersSelected.length > 0) {
				this.setPreviousReminders();
			}
		});
	}

	/**
	 * For creating a new reminders for content
	 */
	public setReminder(): void {
		if (this.remindersSelected.length > 0) {
			const object = {};
			this.remindersSelected.forEach((reminder) => {
				object[reminder] = this.setReminderDate(reminder);
			});
			const param = JSON.stringify(object);
			this.reminderModel.contentType = 'todo';
			this.reminderModel.contentUid = this.todoForReminder.uid;
			this.reminderModel.username = this.currentUser.username;
			this.reminderModel.repeat = true;

			if (this.todoForReminder['estimatedTime']) {
				delete this.reminderModel['reminderOption'];
				this.reminderModel['eventReminderOption'] = param;
			} else {
				this.reminderModel.reminderOption = param;
			}

			this.loadingGif = 'block';
			this.networkService.createReminder(this.reminderModel).subscribe((data) => {
				this.todoForReminder['myRelations']['reminder'] = data['uid'];
				this.loadingGif = 'none';
				this.resetReminders();
				this.reminderToast = true;
				setTimeout(() => {
					this.reminderToast = false;
				}, 8000);
			});
		} else {
			if (this.todoForReminder['myRelations'] && this.todoForReminder['myRelations']['reminder']) {
				this.deleteReminders();
			} else {
				this.resetReminders();
			}
		}
	}

	public deleteReminders(): void {
		this.reminderModel.contentUid = this.todoForReminder.uid;
		this.reminderModel.username = this.currentUser.username;
		this.reminderModel.repeat = true;
		this.reminderModel.contentType = 'todo';
		this.loadingGif = 'block';
		this.networkService.createReminder(this.reminderModel).subscribe((data) => {
			this.todoForReminder['myRelations']['reminder'] = '';
			this.loadingGif = 'none';
			this.resetReminders();
		});
	}

	/**
	 * For calculating corresponding to reminder selected
	 * @param option
	 */
	setReminderDateForTask(option) {
		if (option === 'in_twenty_minutes') {
			this.timeFor = 20;
		} else if (option === 'in_one_hour') {
			this.timeFor = 60;
		} else if (option === 'in_three_hours') {
			this.timeFor = 180;
		} else if (option === 'tomorrow') {
			this.timeFor = this.minutesUntilMidnight() + 540;
		} else if (option === 'next_week') {
			this.timeFor = 6300;
		} else if (option === 'due_time' || option === 'event_time') {
			this.timeFor = -1;
		} else if (option === 'five_minutes_before') {
			this.timeFor = 5;
		} else if (option === 'thirty_minutes_before') {
			this.timeFor = 30;
		} else if (option === 'one_hour_before') {
			this.timeFor = 60;
		} else if (option === 'two_hour_before') {
			this.timeFor = 120;
		}

		let date;

		if (this.taskForReminder['estimatedTime']) {
			if (this.timeFor === -1) {
				date = new Date(this.taskForReminder['estimatedTime']);
			} else {
				date = new Date(this.taskForReminder['estimatedTime']);
				date.setMinutes(date.getMinutes() - this.timeFor);
			}
		} else if (this.taskForReminder['startTime']) {
			if (this.timeFor === -1) {
				date = new Date(this.taskForReminder['startTime']);
			} else {
				date = new Date(this.taskForReminder['startTime']);
				date.setMinutes(date.getMinutes() - this.timeFor);
			}
		} else if (this.taskForReminder['oneOff']) {
			// date = new Date();
			// date.setMinutes(date.getMinutes() + this.timeFor);

			date = new Date(this.taskForReminder['oneOff']);
			date.setMinutes(date.getMinutes() + this.timeFor);
		} else {
			date = new Date();
			date.setMinutes(date.getMinutes() + this.timeFor);
		}

		const month = date.getUTCMonth() + 1;
		const day = date.getUTCDate();
		const year = date.getUTCFullYear();
		const hours = date.getUTCHours();
		const minutes = date.getUTCMinutes();
		const seconds = date.getUTCSeconds();
		const utcDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;

		return utcDate;
	}

	/**
	 * For creating a new reminders for content
	 */
	setReminderForTask(todo) {
		if (this.remindersSelected.length > 0) {
			this.taskForReminder = todo;
			const object = {};
			this.remindersSelected.forEach((reminder) => {
				if (reminder === 'due_time') {
					object['event_time'] = this.setReminderDate(reminder);
				} else {
					object[reminder] = this.setReminderDate(reminder);
				}
			});

			const param = JSON.stringify(object);
			this.reminderModel.contentType = 'todo';
			this.reminderModel.contentUid = this.taskForReminder.uid;
			this.reminderModel.username = this.currentUser.username;
			this.reminderModel.repeat = true;

			if (this.taskForReminder['startTime']) {
				delete this.reminderModel['reminderOption'];
				this.reminderModel['eventReminderOption'] = param;
			} else {
				this.reminderModel.reminderOption = param;
			}

			this.loadingGif = 'block';
			this.networkService.createReminder(this.reminderModel).subscribe(
				(data) => {
					this.loadingGif = 'none';
					this.taskForReminder['myRelations']['reminder'] = data['uid'];
					this.resetReminders();
				},
				(error) => {}
			);
		} else {
			this.resetReminders();
		}
	}

	/**
	 * For creating a new reminders for content
	 */
	setReminderForTaskInSections(todo, reminders) {
		this.taskForReminder = todo;
		if (reminders.length > 0) {
			const object = {};
			reminders.forEach((reminder) => {
				if (reminder === 'due_time') {
					object['event_time'] = this.setReminderDate(reminder);
				} else {
					object[reminder] = this.setReminderDate(reminder);
				}
			});
			const param = JSON.stringify(object);
			if (this.taskForReminder['startTime']) {
				delete this.reminderModel['reminderOption'];
				this.reminderModel['eventReminderOption'] = param;
			} else {
				this.reminderModel.reminderOption = param;
			}
		} else {
			this.resetReminders();
		}
		this.reminderModel.contentType = 'todo';
		this.reminderModel.contentUid = this.taskForReminder.uid;
		this.reminderModel.username = this.currentUser.username;
		this.reminderModel.repeat = true;
		this.loadingGif = 'block';
		this.networkService.createReminder(this.reminderModel).subscribe(
			(data) => {
				this.loadingGif = 'none';
				this.taskForReminder['myRelations']['reminder'] = data['uid'];

				this.resetReminders();
			},
			(error) => {}
		);
	}

	/**
	 * For calculating corresponding to reminder selected
	 * @param option
	 */
	public setReminderDate(option): string {
		if (option === 'in_twenty_minutes') {
			this.timeFor = 20;
		} else if (option === 'in_one_hour') {
			this.timeFor = 60;
		} else if (option === 'in_three_hours') {
			this.timeFor = 180;
		} else if (option === 'tomorrow') {
			this.timeFor = this.minutesUntilMidnight() + 540;
		} else if (option === 'next_week') {
			this.timeFor = 6300;
		} else if (option === 'event_time') {
			this.timeFor = -1;
		} else if (option === 'five_minutes_before') {
			this.timeFor = 5;
		} else if (option === 'thirty_minutes_before') {
			this.timeFor = 30;
		} else if (option === 'one_hour_before') {
			this.timeFor = 60;
		} else if (option === 'two_hour_before') {
			this.timeFor = 120;
		}

		let date;
		if (this.todoDetail['estimatedTime']) {
			if (this.timeFor === -1) {
				date = new Date(this.todoDetail['estimatedTime']);
			} else {
				date = new Date(this.todoDetail['estimatedTime']);
				date.setMinutes(date.getMinutes() - this.timeFor);
			}
		} else {
			date = new Date();
			date.setMinutes(date.getMinutes() + this.timeFor);
		}

		const month = date.getUTCMonth() + 1;
		const day = date.getUTCDate();
		const year = date.getUTCFullYear();
		const hours = date.getUTCHours();
		const minutes = date.getUTCMinutes();
		const seconds = date.getUTCSeconds();
		const utcDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
		return utcDate;
	}

	/**
	 * Get previous reminders of content
	 */
	getReminderDetails(todo) {
		this.loadingGif = 'block';
		this.networkService.getContentReminders('todos', todo['uid']).subscribe((data) => {
			this.loadingGif = 'none';
			this.displayReminders(todo);

			if (data.objects && data.objects.length > 0) {
				data.objects.forEach((element) => {
					if (element['reminderOption']) {
						const ind1 = this.remindersSelected.findIndex((reminder) => reminder === element['reminderOption']);
						if (ind1 === -1) {
							this.remindersSelected.push(element['reminderOption']);
						}
					} else if (element['eventReminderOption']) {
						const ind1 = this.remindersSelected.findIndex((reminder) => reminder === element['eventReminderOption']);
						if (ind1 === -1) {
							this.remindersSelected.push(element['eventReminderOption']);
						}
					}
				});
			}
			this.setPreviousReminders();
		});
	}

	/**
	 * Set Previous reminders on reminder options
	 */
	private setPreviousReminders(): void {
		this.reminderOptions.forEach((reminder) => {
			this.remindersSelected.forEach((r) => {
				if (r === reminder[0]) {
					reminder['checked'] = true;
				}
			});
		});
	}

	/**
	 * For resetting reminders in reminder options modal
	 */
	public resetReminders(): void {
		this.displayReminderModal = 'none';
		this.timeFor = 0;
		this.todoForReminder = '';
		this.remindersSelected = [];
		delete this.model['eventReminderOption'];
		this.reminderModel = {
			contentType: '',
			contentUid: '',
			repeat: true,
			reminderOption: '',
			username: '',
		};
		this.reminderOptions.forEach((r) => {
			r['checked'] = false;
		});
	}

	private minutesUntilMidnight(): any {
		const midnight = new Date();
		midnight.setHours(24);
		midnight.setMinutes(0);
		midnight.setSeconds(0);
		midnight.setMilliseconds(0);
		return (midnight.getTime() - new Date().getTime()) / 1000 / 60;
	}

	/**
	 * For getting subtasks of a todo
	 * @param todo
	 */
	getSubtasks(todo, event) {
		if (event.target.className.indexOf('issue-up') > -1) {
			todo['subtasks'] = [];
			const total = todo['totalSubTasks'];
			this.numbers = Array(total)
				.fill(0)
				.map((x, i) => i);
			this.networkService.getSubtasks(todo['uid'], todo['totalSubTasks'], '', '').subscribe((data) => {
				todo['subtasks'] = data.objects;
				this.checkUserMentionAndHashtagForSubtasks(todo);
				this.numbers = [];
				if (data.next) {
					this.subNextUrl = data.next.split('alpha')[1];
				} else {
					this.subNextUrl = '';
				}
			});
		}
	}

	/**
	 * Check user/hash mentions for subtasks in a todo
	 * 1.If string contains '<',then its cut off while displaying in innerHtml.Hence its replaced by '< '
	 * @param todo
	 */
	checkUserMentionAndHashtagForSubtasks(todo) {
		todo['subtasks'].forEach((task) => {
			if (task['title'] && task['title'].indexOf('<') > -1) {
				// 1
				task['title'] = task['title'].replace(/</g, '< ');
			}

			task['altered_title'] = task['title'];
			if (task['mentionedUsers']) {
				task['mentionedUsers'].forEach((mentionedUser) => {
					const html = '<b #userMention><a class="text-green">@' + mentionedUser['name'] + '</a></b>';
					if (task.altered_title) {
						task['altered_title'] = task.altered_title.replace('@' + mentionedUser['name'], html);
					}
				});
			}
			if (task['hashtags']) {
				task['hashtags'].forEach((hashtag) => {
					if (hashtag['name']) {
						const html = '<b #hashTag><a class="hash-todo">#' + hashtag['name'] + '</a></b>';
						if (task.altered_title) {
							task['altered_title'] = task.altered_title.replace('#' + hashtag['name'], html);
						}
					}
				});
			}
		});
	}

	/**
	 * Manage start/pause/finish actions of subtasks under a task
	 * @param mainTask
	 * @param subtask
	 * @param state
	 * @param mainIndex
	 * @param subIndex
	 */
	manageSubtaskState(mainTask, subtask, state, mainIndex, subIndex) {
		if (subtask['status'] === 'open' && state === 'closed' && !subtask['estimate']) {
			this.displayElapsed = 'block';
			setTimeout(() => {
				document.getElementById('elapsedTime').focus();
			}, 150);
			this.mainTask = mainTask;
			this.selectedToDoItem = subtask;
			this.todoItemIndex = mainIndex;
			this.subIndex = subIndex;
		} else {
			if (state === 'closed' && !this.reaction && subtask['estimate']) {
				this.taskReactionDialog = 'block';
				this.mainTask = mainTask;
				this.selectedToDoItem = subtask;
				this.todoItemIndex = mainIndex;
				this.subIndex = subIndex;
			} else {
				this.displayElapsed = 'none';
				this.checkForSubtaskMentions(subtask);
				this.subtask.title = subtask.title;
				this.subtask['status'] = state;
				this.subtask.repeatCycle = subtask.repeatCycle;
				this.subtask['parent_content_type'] = 'todo';
				this.subtask['parent_content_uid'] = mainTask['uid'];

				if (subtask['mentioned_users']) {
					this.subtask['mentioned_users'] = subtask['mentioned_users'];
				} else if (subtask['hashtags']) {
					this.subtask['hashtags'] = subtask['hashtags'];
				}

				if (subtask.assignedUser && subtask.assignedUser.username) {
					this.subtask.assignedUser = subtask.assignedUser.username;
				} else {
					delete this.subtask['assignedUser'];
				}

				if (subtask.repeatCycle === 'one_off') {
					const date = subtask.oneOff;
					const splicedDate = date.split(' ')[0];
					this.subtask['oneOff'] = splicedDate;
				}

				if (subtask['project'] && subtask['project']['uid']) {
					this.subtask['project'] = subtask['project']['uid'];
				}

				if (state === 'closed') {
					this.subtask['closedDate'] = this.networkService.setCurrentUtcDate();
				}

				if (this.estimateForm.valid) {
					this.subtask['estimate'] = this.estimateForm.value['time'];
				} else {
					delete this.subtask['estimate'];
				}

				if (this.elapsedForm.valid) {
					this.subtask['elapsed_time'] = this.elapsedForm.value['time'];
				} else {
					delete this.subtask['elapsed_time'];
				}

				if (this.reaction) {
					this.subtask['outcome'] = this.reaction;
				}

				this.loadingGif = 'block';
				this.networkService.updateToDoItem(this.subtask, subtask.uid).subscribe(
					(data) => {
						this.reaction = '';
						delete this.subtask['estimate'];
						delete this.subtask['elapsed_time'];
						delete this.subtask['project'];
						delete this.subtask['oneOff'];
						delete this.subtask['closedDate'];
						this.reaction = '';
						this.getTodoDetails(mainTask, mainIndex);
						this.setMentionsAfterEdit(mainTask, data, subIndex);
						this.createSubtaskAutoComments(mainTask, state, data);
						this.reset();
						this.subtask = new Subtask();
						this.loadingGif = 'none';
						this.squareSpinner = 'block';
						setTimeout(() => {
							this.squareSpinner = 'none';
						}, 1300);
					},
					(error) => {
						this.loadingGif = 'none';
					}
				);
			}
		}
	}

	/**
	 * Function to close parent task if all subtasks are closed;
	 * @param todo
	 */
	closeParentTask(todo) {
		if (todo['status'] === 'closed') {
			return;
		}
		if (todo['subtasks'] && todo['subtasks'].length) {
			const unClosedTask = todo['subtasks'].find((li) => li.status !== 'closed');
			if (!unClosedTask) {
				const index = this.todoItems.findIndex((li) => li.uid === todo.uid);

				this.taskReactionDialog = 'none';
				this.loadingGif = 'block';
				this.updateAttachmentDetails(todo);
				this.todoItem.title = todo.title;
				this.todoItem.status = TaskStatus.closed;
				this.todoItem.priority = todo.priority;
				this.todoItem.repeatCycle = todo.repeatCycle;
				if (todo.assignedUser && todo.assignedUser.username) {
					this.todoItem['assignedUser'] = todo.assignedUser.username;
				}
				if (todo.repeatCycle === 'one_off') {
					const oneOffDate = todo.oneOff;
					const splicedDate = oneOffDate.split(' ')[0];
					this.todoItem['oneOff'] = splicedDate;
				}
				if (todo.repeatFreequency) {
					this.todoItem['repeatFreequency'] = todo.repeatFreequency;
				}
				if (todo['project'] && todo['project']['uid']) {
					this.todoItem['project'] = todo['project']['uid'];
				}
				if (todo.numberOfOpens && todo.numberOfOpens > 0) {
					this.todoItem['numberOfOpens'] = todo.numberOfOpens;
				}

				const d = new Date().toISOString().slice(0, 10);
				const date = new Date();
				const hours = date.getUTCHours();
				const minutes = date.getUTCMinutes();
				const seconds = date.getUTCSeconds();
				const utcDateTime = d + ' ' + hours + ':' + minutes + ':' + seconds;
				this.todoItem['closedDate'] = utcDateTime;

				if (todo['postUid']) {
					this.comment.contentType = 'post';
					this.comment.contentUid = todo['postUid'];
				} else {
					this.comment.contentType = '';
					this.comment.contentUid = '';
				}

				if (!todo['estimate']) {
					this.todoItem['elapsed_time'] = 0;
				}
				this.todoItem['outcome'] = 'happy';

				this.networkService.updateToDoItem(this.todoItem, todo.uid).subscribe(
					(data) => {
						if (data.status === 'closed') {
							this.mixPanelService.track('Completed Task', {
								workspace: this.network?.name || 'NA',
								appVersion: environment.version,
								language: this.translate?.currentLang,
								username: this.currentUser?.username || 'NA',
								name: this.currentUser?.name || 'NA',
								project: data?.project?.name || 'NA',
								date: moment().format('YYYY-MM-DD'),
							});

							this.segmentService.trackEvent('Completed Task', {
								workspace: this.network?.name || 'NA',
								appVersion: environment.version,
								language: this.translate?.currentLang,
								username: this.currentUser?.username || 'NA',
								name: this.currentUser?.name || 'NA',
								project: data?.project?.name || 'NA',
								date: moment().format('YYYY-MM-DD'),
							});
						}
						this.loadingGif = 'none';
						this.squareSpinner = 'block';
						setTimeout(() => {
							this.squareSpinner = 'none';
						}, 1300);
						delete this.todoItem['closedDate'];
						delete this.todoItem['project'];
						delete this.todoItem['repeatFreequency'];
						delete this.todoItem['oneOff'];
						delete this.todoItem['numberOfOpens'];
						delete this.todoItem['estimate'];
						delete this.todoItem['elapsed_time'];
						delete this.todoItem['outcome'];
						this.selectedItem = '';
						this.selectedTodoIndex = '';
						this.selectedTodoValue = '';
						this.reaction = '';

						if (this.currentPage === 'projectDetail') {
							this.getDetail();
						}

						if (this.sectionsBoard === 'block' && this.selectedSection) {
							const i = this.boardSections.findIndex((section) => section['uid'] === this.selectedSection['uid']);
							if (i > -1) {
								this.boardSections[i]['tasks'][index] = data;
								this.getTaskDuetime(this.boardSections[i]['tasks']);
								this.handleContents(this.boardSections[i]['tasks']);
								this.handleMentions(this.boardSections[i]['tasks']);
								this.selectedSection = '';
							}
						} else {
							if (index > -1) {
								this.todoItems[index] = data;
								this.showOneOffDate();
							} else {
								this.todoItems.unshift(data);
								this.showOneOffDate();
							}
							this.getEstimatedTime();
							this.checkUserMentionAndHashtagAndAlterBody();
						}

						this.createAutoComments('closed', data);
						this.createAutoCommentsForTask('closed', data);
						this.reset();
					},
					(error) => {
						this.loadingGif = 'none';
						this.selectedSection = '';
					}
				);
			}
		}
	}

	/**
	 * Create auto comment for subtasks actions like start/pause/finish etc.
	 * @param status
	 * @param todo
	 */
	createSubtaskAutoComments(mainTask, status, subtask) {
		const comment = {};
		comment['contentType'] = 'todo';
		comment['contentUid'] = mainTask['uid'];
		if (status === 'closed') {
			comment['text'] = this.translate.instant('task-completion', {
				title: subtask['title'],
				time: subtask['elapsedTime'],
			});
		} else if (status === 'open') {
			comment['text'] = this.translate.instant('Task Reopened') + `: ${subtask.title}`;
		} else if (status === 'in_progress') {
			comment['text'] = this.translate.instant('task-start', {
				title: subtask['title'],
				time: subtask['estimate'],
			});
		} else {
			comment['text'] = this.translate.instant('task-paused', {
				title: subtask['title'],
			});
		}

		if (comment['contentType'] && comment['contentUid']) {
			this.commentService.create(comment, this.currentUser.username).subscribe(
				(d) => {},
				(error) => {}
			);
		}
	}

	getTodoDetails(todo, i) {
		this.networkService.getTask(todo['uid']).subscribe((data) => {
			if (this.todoItems[i]) {
				this.todoItems[i]['closedSubTasks'] = data['closedSubTasks'];
			}
		});
	}

	checkForSubtaskMentions(task) {
		if (task['mentioned_users'] && task['mentioned_users'].length > 0) {
			const userList = [];
			for (const u of task['mentioned_users']) {
				if (task['title'].indexOf('@' + u.name) > -1) {
					userList.push(u.username);
				}
			}
			task['mentioned_users'] = [];
			task['mentioned_users'] = userList.join(',');
		}
		if (task['hashtags'] && task['hashtags'].length > 0) {
			const tagList = [];
			for (const h of task['hashtags']) {
				if (task['title'].indexOf('#' + h['name']) > -1) {
					tagList.push(h['name']);
				}
			}
			task['hashtags'] = [];
			task['hashtags'] = tagList.join(',');
		}
	}

	setMentionsAfterEdit(todo, task, index) {
		task['altered_title'] = task['title'];

		if (task['mentionedUsers']) {
			task['mentionedUsers'].forEach((mentionedUser) => {
				const html = '<b #userMention><a class="text-green">@' + mentionedUser['name'] + '</a></b>';
				if (task.altered_title) {
					task['altered_title'] = task.altered_title.replace('@' + mentionedUser['name'], html);
				}
			});
		}

		if (task['hashtags']) {
			task['hashtags'].forEach((hashtag) => {
				if (hashtag['name']) {
					const html = '<b #hashTag><a class="hash-todo">#' + hashtag['name'] + '</a></b>';
					if (task.altered_title) {
						task['altered_title'] = task.altered_title.replace('#' + hashtag['name'], html);
					}
				}
			});
		}

		todo['subtasks'][index] = task;
		this.closeParentTask(todo);
	}

	autoSizeSection(e) {
		if (e.target.value) {
			this.isCreate = true;
		} else {
			this.isCreate = false;
		}

		if (e.keyCode === 13) {
			e.preventDefault();
			this.onCreateSection();
		}

		e.target.style.cssText = 'padding: 5px; background: none; resize: none;height:auto;border:none';
		e.target.style.cssText = 'padding: 5px; background: none; resize: none;border:none;height:' + e.target.scrollHeight + 'px';
	}

	autoSizeBox(e, type) {
		if (type === 'board') {
			e.target.style.cssText = 'background: none; resize: none;height:auto;border:none;font-size:14px';
			e.target.style.cssText = 'background: none; resize: none;border:none;font-size:14px;height:' + e.target.scrollHeight + 'px';
		} else {
			e.target.style.cssText = 'background: none; resize: none;height:auto;border:none';
			e.target.style.cssText = 'background: none; resize: none;border:none;height:' + e.target.scrollHeight + 'px';
		}
	}

	addSection() {
		this.isAddSection = true;
		setTimeout(() => {
			this.textSection.nativeElement.focus();
		}, 0);
	}

	clearCreateSection() {
		this.isAddSection = false;
		this.isCreate = false;
		this.section['name'] = '';
	}

	displaySectionDelete(section, i) {
		this.deleteSectionModal = 'block';
		this.selectedSectionUid = section['uid'];
		this.selectedIndex = i;
	}

	closeSectionDelete() {
		this.deleteSectionModal = 'none';
		this.selectedSectionUid = '';
		this.selectedIndex = '';
	}

	deleteSectionOnly(param) {
		this.loadingGif = 'block';
		this.sections.splice(this.selectedIndex, 1);
		this.networkService.deleteSection(this.selectedSectionUid, param).subscribe((data) => {
			if (this.boardSections.length > 0) {
				this.boardSections.splice(this.selectedIndex, 1);
			}
			this.displayTodos(this.tab);
			this.closeSectionDelete();
			this.loadingGif = 'none';
		});
	}

	/**
	 * On selecting edit option in section
	 * Set prevoius section object for reset
	 * @param section
	 * @param index
	 */
	onClickSectionEdit(section, index) {
		delete section['tasks'];
		sessionStorage.setItem('section', JSON.stringify(section));
		this.sections.forEach((sec, ind) => {
			if (ind !== index) {
				delete sec['edit'];
				delete sec['showbg'];
			}
		});
	}

	/**
	 * On closing edit section cell
	 * Reset previous section object from Session storage
	 * @param index
	 */
	onCloseSection(index) {
		const previous = JSON.parse(sessionStorage.getItem('section'));
		delete previous['edit'];
		delete previous['showbg'];
		sessionStorage.removeItem('subtask');
		this.toggler = {};
		this.sections[index] = previous;
	}

	editSection(section, index) {
		this.loadingGif = 'block';
		this.section.name = section['name'];
		this.section['project'] = this.projectDetails['uid'];
		this.networkService.editSection(section['uid'], this.section).subscribe(
			(data) => {
				this.loadingGif = 'none';
				this.sections[index] = data;
				this.clearCreateSection();
				this.toggler = {};
			},
			(error) => {
				this.loadingGif = 'none';
				this.toggler = {};
			}
		);
	}

	deleteSectionTask(task, section, i) {
		this.loadingGif = 'block';
		this.networkService.deleteToDoItem(task['uid'], '').subscribe((data) => {
			this.loadingGif = 'none';
			this.getSectionDetail(section);
			this.resetCounts.emit();
			if (this.currentPage === 'projectDetail') {
				this.getDetail();
			}
			if (section && section['tasks']) {
				section['tasks'].splice(i, 1);
			}
		});
	}

	setSectionBetween(section, pos, index) {
		this.sections.forEach((sec) => {
			delete sec['below'];
			delete sec['above'];
		});
		this.clear(section);
		if (pos === 'above') {
			section['above'] = true;
		} else {
			section['below'] = true;
		}
		setTimeout(() => {
			this.textSection.nativeElement.focus();
		}, 0);
	}

	clear(section) {
		this.element['name'] = '';
		delete section['below'];
		delete section['above'];
		delete section['right'];
		delete section['left'];
	}

	onCreateSectionBetween(section, i) {
		if (this.element['name']) {
			this.element['project'] = this.projectDetails['uid'];
			this.loadingGif = 'block';
			this.networkService.createSection(this.element).subscribe(
				(data) => {
					data['toggle'] = true;
					this.sections.splice(i, 0, data);
					this.projectDetails['sectionCount'] = this.projectDetails['sectionCount'] + 1;
					this.clear(section);
					this.loadingGif = 'none';
					this.clearCreateSection();
					this.reorderSections();
				},
				(err) => {
					this.loadingGif = 'none';
				}
			);
		}
	}

	/**
	 * Reorder sections list after a new section is created in between
	 */
	reorderSections() {
		const arr = [];
		const params = {};
		this.sections.forEach((section, index) => {
			if (section['uid']) {
				const object = { uid: section['uid'], order: index + 1 };
				arr.push(object);
			}
		});
		params['contents'] = JSON.stringify(arr);
		this.loadingGif = 'block';
		this.networkService.reorderSections(params).subscribe(
			(data) => {
				this.loadingGif = 'none';
			},
			(error) => {
				this.loadingGif = 'none';
			}
		);
	}

	autoSizeBetween(e, section, i) {
		if (e.keyCode === 13) {
			e.preventDefault();
			this.onCreateSectionBetween(section, i);
		}

		e.target.style.cssText = 'padding: 5px; background: none; resize: none; font-size: 14px;height:auto;border:none';
		e.target.style.cssText = 'padding: 5px; background: none; resize: none; font-size: 14px;border:none;height:' + e.target.scrollHeight + 'px';
	}

	/**
	 * Function to create new section on top
	 * @param e
	 * @param section
	 * @param i
	 */
	addSectionOnTop(e, section, i) {
		if (e.keyCode === 13) {
			e.preventDefault();
			this.onCreateSectionOnTop(section, i);
		}

		e.target.style.cssText = 'padding: 5px; background: none; resize: none; font-size: 14px;height:auto;border:none';
		e.target.style.cssText = 'padding: 5px; background: none; resize: none; font-size: 14px;border:none;height:' + e.target.scrollHeight + 'px';
	}

	onCreateSectionOnTop(section, i) {
		if (this.section['name']) {
			this.section['project'] = this.projectDetails['uid'];
			this.loadingGif = 'block';
			this.networkService.createSection(this.section).subscribe(
				(data) => {
					data['toggle'] = true;
					this.onAddSectionTasks(data);
					this.sections.splice(i, 0, data);
					this.projectDetails['sectionCount'] = this.projectDetails['sectionCount'] + 1;
					this.clear(section);
					this.loadingGif = 'none';
					this.clearCreateSection();
					this.reorderSections();
				},
				(err) => {
					this.loadingGif = 'none';
				}
			);
		}
	}

	onCreateSection() {
		if (this.section['name']) {
			this.section['project'] = this.projectDetails['uid'];
			this.section['order'] = this.sections.length;
			this.loadingGif = 'block';
			this.networkService.createSection(this.section).subscribe(
				(data) => {
					this.loadingGif = 'none';
					this.projectDetails['sectionCount'] = this.projectDetails['sectionCount'] + 1;
					this.clearCreateSection();
					this.getSections();
					this.addSection();
				},
				(err) => {}
			);
		}
	}

	getSectionDetail(section) {
		this.networkService.getSectionDetail(section['uid']).subscribe((data) => {
			section['subTaskCount'] = data['subTaskCount'];
		});
	}

	setSectionTask(section) {
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
		this.layoutService.hideBd();
		this.showAddTaskForm();
		this.setTaskForSection = section;
	}

	addTaskInSections(task) {
		const arr = [];
		const params = {};
		const object = { content_type: 'todo', content_uid: task['uid'], order: 1 };
		arr.push(object);
		params['contents'] = JSON.stringify(arr);
		params['update'] = true;
		this.loadingGif = 'block';
		this.networkService.addTaskInSections(this.setTaskForSection['uid'], params).subscribe((data) => {
			this.loadingGif = 'none';
			this.setTaskForSection['subTaskCount'] = data['subTaskCount'];
			this.setTaskForSection['tasks'].push(task);
			this.reOrderSectionTasks(this.setTaskForSection);
		});
	}

	getTodosInSection(section) {
		if (section['subTaskCount'] > 0 && !section['tasks']) {
			this.loadingGif = 'block';
			let filter = '';
			if (this.tab === 'open') {
				filter = 'status=open';
			} else if (this.tab === 'myTasks') {
				filter = 'status=open&assigned=true';
			} else if (this.tab === 'completed') {
				filter = 'status=closed';
			} else {
				filter = '';
			}

			// Filter for the section tasks
			if (this.filter === 'sort_priority') {
				filter = filter ? filter + '&sort_priority=asc' : 'sort_priority=asc';
			} else if (this.filter === 'due_date') {
				filter = filter ? filter + '&due_date=desc' : 'due_date=desc';
			} else if (this.filter === 'assignee') {
				filter = filter ? filter + '&assignee=true' : 'assignee=true';
			} else if (this.filter === 'sort_title') {
				filter = filter ? filter + '&sort_title=asc' : 'sort_title=asc';
			} else if (this.filter === 'sort_status') {
				filter = filter ? filter + '&sort_status=true' : 'sort_status=true';
			}

			this.networkService.getTodosInSection(section.uid, section['subTaskCount'], filter).subscribe(
				(data) => {
					data.objects.forEach((task) => {
						task['section'] = section;
					});
					section['tasks'] = data.objects;
					this.checkUserMentionAndHashtagForSectionTasks(section);
					this.loadingGif = 'none';
				},
				(err) => {
					this.loadingGif = 'none';
				}
			);
		}
	}

	checkUserMentionAndHashtagForSectionTasks(section) {
		if (section['tasks'].length > 0) {
			section['tasks'].forEach((task) => {
				if (task['title'] && task['title'].indexOf('<') > -1) {
					task['title'] = task['title'].replace(/</g, '< ');
				}

				task['altered_title'] = task['title'];
				if (task['mentionedUsers']) {
					task['mentionedUsers'].forEach((mentionedUser) => {
						const html = '<b #userMention><a class="text-green">@' + mentionedUser['name'] + '</a></b>';
						if (task.altered_title) {
							task['altered_title'] = task.altered_title.replace('@' + mentionedUser['name'], html);
						}
					});
				}
				if (task['hashtags']) {
					task['hashtags'].forEach((hashtag) => {
						if (hashtag['name']) {
							const html = '<b #hashTag><a class="hash-todo">#' + hashtag['name'] + '</a></b>';
							if (task.altered_title) {
								task['altered_title'] = task.altered_title.replace('#' + hashtag['name'], html);
							}
						}
					});
				}
			});
		}
	}

	/**
	 * For managing task done/undone within a section
	 * @param section
	 * @param task
	 * @param state
	 * @param taskIndex
	 */
	manageSectionTaskState(section, task, state, taskIndex) {
		if (task['status'] === 'open' && state === 'closed' && !task['estimate']) {
			this.displayElapsed = 'block';
			setTimeout(() => {
				document.getElementById('elapsedTime').focus();
			}, 150);
			this.selectedSection = section;
			this.selectedToDoItem = task;
			this.todoItemIndex = taskIndex;
		} else {
			if (state === 'closed' && !this.reaction && task['estimate']) {
				this.selectedSection = section;
				this.selectedToDoItem = task;
				this.todoItemIndex = taskIndex;
				this.taskReactionDialog = 'block';
			} else {
				this.displayElapsed = 'none';
				this.loadingGif = 'block';
				this.updateAttachmentDetails(task);
				this.todoItem.title = task.title;
				this.todoItem['status'] = state;
				this.todoItem.priority = task.priority;
				this.todoItem.repeatCycle = task.repeatCycle;
				if (task.assignedUser && task.assignedUser.username) {
					this.todoItem['assignedUser'] = task.assignedUser.username;
				}
				if (task.repeatCycle === 'one_off') {
					const date = task.oneOff;
					const splicedDate = date.split(' ')[0];
					this.todoItem['oneOff'] = splicedDate;
				}
				if (task.repeatFreequency) {
					this.todoItem['repeatFreequency'] = task.repeatFreequency;
				}
				if (task['project'] && task['project']['uid']) {
					this.todoItem['project'] = task['project']['uid'];
				}
				if (task.numberOfOpens && task.numberOfOpens > 0) {
					this.todoItem['numberOfOpens'] = task.numberOfOpens;
				}
				if (state === 'closed') {
					this.todoItem['closedDate'] = this.networkService.setCurrentUtcDate();
				}

				if (this.estimateForm.valid) {
					this.todoItem['estimate'] = this.estimateForm.value['time'];
				} else {
					delete this.todoItem['estimate'];
				}

				if (this.elapsedForm.valid) {
					this.todoItem['elapsed_time'] = this.elapsedForm.value['time'];
				} else {
					delete this.todoItem['elapsed_time'];
				}

				if (task['postUid']) {
					this.comment.contentType = 'post';
					this.comment.contentUid = task['postUid'];
				} else {
					this.comment.contentType = '';
					this.comment.contentUid = '';
				}

				if (this.reaction) {
					this.todoItem['outcome'] = this.reaction;
				}

				this.networkService.updateToDoItem(this.todoItem, task.uid).subscribe(
					(data) => {
						if (data.status === 'closed') {
							this.mixPanelService.track('Completed Task', {
								workspace: this.network?.name || 'NA',
								appVersion: environment.version,
								language: this.translate?.currentLang,
								username: this.currentUser?.username || 'NA',
								name: this.currentUser?.name || 'NA',
								project: data?.project?.name || 'NA',
								date: moment().format('YYYY-MM-DD'),
							});

							this.segmentService.trackEvent('Completed Task', {
								workspace: this.network?.name || 'NA',
								appVersion: environment.version,
								language: this.translate?.currentLang,
								username: this.currentUser?.username || 'NA',
								name: this.currentUser?.name || 'NA',
								project: data?.project?.name || 'NA',
								date: moment().format('YYYY-MM-DD'),
							});
						}
						this.loadingGif = 'none';
						this.squareSpinner = 'block';
						setTimeout(() => {
							this.squareSpinner = 'none';
						}, 1300);
						delete this.todoItem['closedDate'];
						delete this.todoItem['project'];
						delete this.todoItem['repeatFreequency'];
						delete this.todoItem['oneOff'];
						delete this.todoItem['numberOfOpens'];
						delete this.todoItem['estimate'];
						delete this.todoItem['elapsed_time'];
						this.reset();

						if (this.currentPage === 'projectDetail') {
							this.getDetail();
						}

						section['tasks'][taskIndex] = data;
						this.checkUserMentionAndHashtagForSectionTasks(section);
						this.createAutoComments(state, data);
						this.createAutoCommentsForTask(state, data);
					},
					(error) => {
						this.loadingGif = 'none';
					}
				);
			}
		}
	}

	onClickEditTaskInSection(section, task) {
		this.setTaskForSection = section;
		this.editSelectedPost = task;
		if (this.projectDetails['contentPrivacy']) {
			this.todoListPrivacy = this.projectDetails['contentPrivacy'];
		} else if (this.projectDetails['customPrivacy']) {
			this.todoListPrivacy = this.projectDetails.customPrivacy;
		} else if (this.projectDetails['channel'] && this.projectDetails['channel'].uid) {
			const uid = this.projectDetails['channel'].uid;
			if (this.projectDetails.channel['access'] === 4) {
				this.todoListPrivacy = { channel: uid, access: 'external' };
			} else {
				this.todoListPrivacy = { channel: uid };
			}
		} else {
			this.todoListPrivacy = 'Everyone';
		}
	}

	/**
	 * 1. For ordering subtasks after a new subtask is dropped into a section
	 * @param section
	 * @param event
	 */
	onDropToSection(section, event) {
		if (this.checkIfTasksinMainlists(event.item.data)) {
			this.dropToSectionOutside(section, event);
		} else {
			this.dropBetweenSections(event);
		}
	}

	dropBetweenSections(event) {
		const destinationSection = event.container.data;
		destinationSection['subTaskCount'] = destinationSection['subTaskCount'] + 1;
		const sourceSection = event.item.data['section'];

		const index = this.sections.findIndex((sec) => sec['uid'] === sourceSection['uid']);
		this.sections[index]['tasks'].splice(event.previousIndex, 1);

		this.loadingGif = 'block';
		this.networkService.movetaskBetweenSections(sourceSection, event.item.data, destinationSection).subscribe(
			(data) => {
				this.loadingGif = 'none';
				this.getSectionDetail(sourceSection);
				this.getTodosInSection(destinationSection);
			},
			(error) => {
				this.loadingGif = 'none';
			}
		);
	}

	dropToSectionOutside(section, event) {
		this.todoItems.splice(event.previousIndex, 1);
		const task = event.item.data;
		const arr = [];
		const params = {};
		const object = { content_type: 'todo', content_uid: task['uid'], order: 1 };
		arr.push(object);
		params['contents'] = JSON.stringify(arr);
		params['update'] = true;

		this.loadingGif = 'block';
		this.networkService.addTaskInSections(section['uid'], params).subscribe(
			(data) => {
				this.loadingGif = 'none';
				section['subTaskCount'] = data['subTaskCount'];
				if (this.isReorder) {
					// 1
					section['tasks'].splice(event.currentIndex, 0, event.item.data); // 4
					this.reOrderSectionTasks(section);
				} else {
					this.getTodosInSection(section);
				}
			},
			(error) => {
				this.loadingGif = 'none';
			}
		);
	}

	/**
	 * Function on dropping task in section tasks list
	 * @param section
	 * @param event
	 * 1. Check if task is already present in section
	 * 2. If true, then reorder the section tasks accordinly
	 * 3. Call function to reorder tasks in section
	 * 4. Check if the incoming task is present in main task list
	 * 5. If true,then add this task to section
	 * 6. Else, the task is from another section
	 * 7. Get source section,get index of source section,remove the task from source section
	 * 8. Add the new task in that section
	 * 9. call api to move this task to new section
	 */
	onSectionDrop(section, event) {
		const sec = event.container.data;
		const task = event.item.data;
		this.isReorder = false;
		if (this.checkIftaskInSection(task, sec)) {
			// 1

			moveItemInArray(section['tasks'], event.previousIndex, event.currentIndex); // 2
			this.reOrderSectionTasks(section); // 3
		} else {
			if (this.checkIfTasksinMainlists(task)) {
				// 4

				this.isReorder = true;
				this.dropToSectionOutside(section, event); // 5
			} else {
				section['subTaskCount'] = section['subTaskCount'] + 1;
				const sourceSection = event.item.data['section'];
				const index = this.sections.findIndex((eachSec) => eachSec['uid'] === sourceSection['uid']);
				this.sections[index]['tasks'].splice(event.previousIndex, 1); // 7
				section['tasks'].splice(event.currentIndex, 0, event.item.data); // 8
				this.loadingGif = 'block';
				this.networkService.movetaskBetweenSections(sourceSection, task, sec).subscribe(
					(data) => {
						// 9
						this.reOrderSectionTasks(section);
						this.getSectionDetail(sourceSection);
					},
					(error) => {
						this.loadingGif = 'none';
					}
				);
			}
		}
	}

	/**
	 * Function on droppping a subtasks to main subtasks list
	 * 1. Check if subtask is already present in main subtask list
	 * 2. If true,then manage reordering subtask in main list.
	 * 3. Else manage adding subtask in main list
	 * 4. add the new subtask in index position in subtasks array
	 * 5. remove the subtask from section
	 * @param event
	 */
	onTasksDrop(event) {
		if (this.checkIfTasksinMainlists(event.item.data)) {
			// 1
			if (event.previousIndex !== event.currentIndex) {
				moveItemInArray(this.todoItems, event.previousIndex, event.currentIndex); // 2
				this.reorderTasks();
			}
		} else {
			// 3

			this.todoItems.splice(event.currentIndex, 0, event.item.data); // 4
			this.removetaskFromSection(event); // 5
		}
	}

	/**
	 * For removing a subtask from section on dropping to main subtasklists.
	 * 1. Call api to remove this subtask from section
	 * 2. remove this subtask from section subtasks list
	 * 4. Call function to reorder subtasks
	 * @param section
	 * @param task
	 */
	removetaskFromSection(event) {
		this.loadingGif = 'block';
		const task = event.item.data;
		const section = event.previousContainer.data;
		this.networkService.deleteTaskInSection(task['section'], task).subscribe(
			(data) => {
				// 1
				section['tasks'].splice(event.previousIndex, 1); // 2
				this.loadingGif = 'none';
				this.reorderTasks(); // 4
				this.getSectionDetail(section);
			},
			(error) => {
				this.loadingGif = 'none';
			}
		);
	}

	/**
	 * Function to reorder section tasks
	 * @param section
	 */
	reOrderSectionTasks(section) {
		const arr = [];
		const params = {};
		section['tasks'].forEach((task, index) => {
			if (task['uid']) {
				const object = {
					content_type: 'todo',
					content_uid: task['uid'],
					order: index + 1,
				};
				arr.push(object);
			}
		});
		params['contents'] = JSON.stringify(arr);
		this.loadingGif = 'block';
		this.networkService.editSection(section['uid'], params).subscribe(
			(data) => {
				this.loadingGif = 'none';
				this.refreshSection(section);
			},
			(error) => {
				this.loadingGif = 'none';
			}
		);
	}

	/**
	 * Refreshing tasks in section after tasks are ordered
	 * @param section
	 */
	refreshSection(section) {
		let filter = '';
		if (this.tab === 'open') {
			filter = 'status=open';
		} else if (this.tab === 'myTasks') {
			filter = 'status=open&assigned=true';
		} else if (this.tab === 'completed') {
			filter = 'status=closed';
		} else {
			filter = '';
		}
		this.loadingGif = 'block';
		this.networkService.getTodosInSection(section.uid, section['subTaskCount'], filter).subscribe(
			(data) => {
				data.objects.forEach((task) => {
					task['section'] = section;
				});
				section['tasks'] = data.objects;
				this.getTaskDuetime(section['tasks']);
				this.checkUserMentionAndHashtagForSectionTasks(section);

				if (this.newTaskCreated) {
					if (this.sectionsBoard === 'block') {
						this.onAddTaskInBoard(section);
					} else {
						this.onAddSectionTasks(section);
					}
					this.newTaskCreated = false;
				}

				this.loadingGif = 'none';
			},
			(err) => {
				this.loadingGif = 'none';
			}
		);
	}

	/**
	 * Function for reordering subtasks list
	 */
	reorderTasks() {
		const arr = [];
		const params = {};
		this.todoItems.forEach((task, index) => {
			if (task['uid']) {
				const object = { uid: task['uid'], order: index + 1 };
				arr.push(object);
			}
		});

		params['contents'] = JSON.stringify(arr);
		this.loadingGif = 'block';
		this.networkService.reorderTasks(this.projectDetails['uid'], params).subscribe(
			(data) => {
				this.loadingGif = 'none';
				this.handleRelatedContent();
				this.showOneOffDate();
				this.getEstimatedTime();
			},
			(err) => {
				this.loadingGif = 'none';
			}
		);
	}

	checkIftaskInSection(task, section) {
		if (section['tasks'] && section['tasks'].length > 0) {
			const index = section['tasks'].findIndex((x) => x['uid'] === task['uid']);
			if (index > -1) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	/**
	 * For checking if task is present/not in main tasklists
	 * @param task
	 */
	checkIfTasksinMainlists(task) {
		if (this.todoItems.length > 0) {
			const index = this.todoItems.findIndex((x) => x['uid'] === task['uid']);
			if (index > -1) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	public onImgError(event): void {
		event.target.src = 'assets/images/default_avatar.jpg';
	}

	/**
	 * Display task create cell in section
	 */
	onAddSectionTasks(section) {
		this.selectedSectionDetails = section;
		if (section['tasks'] && section['tasks'].length > 0) {
			const lastTask = section['tasks'][section['tasks'].length - 1];

			// FIXME: why we are checking for lastTask?
			if (lastTask['title']) {
				this.appendTask(section);
			} else {
				return;
			}
		} else {
			section['tasks'] = [];
			this.appendTask(section);
		}

		this.dueDateComponent.reset();
	}

	/**
	 * Add new task object to section
	 * @param section
	 */
	appendTask(section) {
		const x = new Subtask();
		x['repeatCycle'] = 'daily';
		x['assignedUser'] = this.currentUser;
		x['edit'] = true;
		section['tasks'].push(x);
		setTimeout(() => {
			document.getElementById('section-task-title').focus();
		}, 100);
	}

	/**
	 * Close task create cell in section
	 * @param section
	 * @param i
	 */
	onRemoveSectionTask(section, i) {
		section['tasks'].splice(i, 1);
		const lastTask = section['tasks'][section['tasks'].length - 1];
		if (lastTask && lastTask['status']) {
			section['isCreate'] = false;
		} else if (!lastTask) {
			section['isCreate'] = false;
		}
	}

	checkSectionTask(section, e) {
		if (section['tasks'] && section['tasks'].length > 0) {
			section['tasks'].forEach((task) => {
				if (!task['status']) {
					if (task['title']) {
						section['isCreate'] = true;
					} else {
						section['isCreate'] = false;
					}
				}
			});
		}
	}

	onKeyEnter(section, e) {
		if (e.keyCode === 13 && this.userMentions.length === 0 && this.hashMentions.length === 0) {
			e.preventDefault();
			this.onCreateSectionTasks(section);
		}
	}

	checkShiftKey(event) {
		// -Check if shift key is pressed for user mention / hashtags
		if (event.shiftKey) {
			this.shiftKeyUsed = true;
		}
	}

	/**
	 * For shoiwng duedate options for a task
	 * @param task
	 */
	displayTaskDueDate(task, type) {
		this.taskCreationType = type;
		this.selectedNewTask = task;
		// if (!task['status'] || task['edit']) {
		//   this.currentSub = task;
		//   this.displaySubDuedate = 'block';
		//   if (this.currentSub['repeatCycle'] === 'one_off') {
		//     if (this.currentSub['oneOff'] === this.setTomorrowDate()) {
		//       this.sub = 'tomorrow';
		//       this.subtaskDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
		//     } else {
		//       this.sub = 'one_off';
		//       var date = new Date(this.currentSub['oneOff']);
		//       var month = date.getMonth() + 1;
		//       var day = date.getDate();
		//       var year = date.getFullYear();
		//       this.subtaskDate = { year: year, month: month, day: day };
		//     }
		//   } else {
		//     this.sub = this.currentSub['repeatCycle'];
		//   }
		// }
		if (!task['status'] || task['edit']) {
			this.taskAddType = 'single';
			if (this.dueDateComponent) {
				this.dueDateComponent.display();
				if (task.repeatCycle === 'one_off') {
					this.dueDateComponent.showPreviouslySelectedDate(new Date(task.oneOff));
				}
			}
		}
	}

	/**
	 * Function to show due date component for tasks in sections
	 * @param task
	 * @param type
	 */
	displayTaskDueDateForSectionTask(task, type) {
		if (!task['status'] || task['edit']) {
			this.taskAddType = 'multiple';
			this.selectedNewTask = task;
			if (this.dueDateComponent) {
				this.dueDateComponent.display();
				this.dueDateComponent.selectedReminders = [];
				if (this.selectedNewTask.remindersSelected) {
					this.dueDateComponent.selectedReminders = this.selectedNewTask.remindersSelected;
				} else {
					if (task.myRelations && task.myRelations.reminder) {
						this.loadingGif = 'block';
						this.dueDateComponent.getContentReminders(task);
					}
				}
				if (task.repeatFreequency) {
					// Resetting repeat frequency
					this.dueDateComponent.selectedRepeat = task.repeatFreequency;
				} else {
					this.dueDateComponent.selectedRepeat = '';
				}

				if (task.startTime && task.fromTime) {
					this.dueDateComponent.startTime = task.fromTime;
				} else if (task.startTime && !task.fromTime) {
					const a = task.startTime;
					const b = new Date(a);
					this.dueDateComponent.startTime = b.getHours() + ':' + (b.getMinutes() > 9 ? b.getMinutes() : '0' + b.getMinutes());
				} else {
					this.dueDateComponent.startTime = null;
				}

				if (task.estimatedTime && task.toTime) {
					this.dueDateComponent.endTime = task.toTime;
				} else if (task.estimatedTime && !task.toTime) {
					const a = task.estimatedTime;
					const b = new Date(a);
					this.dueDateComponent.endTime = b.getHours() + ':' + (b.getMinutes() > 9 ? b.getMinutes() : '0' + b.getMinutes());
				} else {
					this.dueDateComponent.endTime = null;
				}

				if (task.repeatCycle) {
					this.dueDateComponent.selectedDueDate = task.repeatCycle;
				} else {
					this.dueDateComponent.selectedDueDate = 'daily';
				}

				if (task.repeatCycle === 'one_off') {
					this.dueDateComponent.showPreviouslySelectedDate(new Date(task.oneOff));
				}
			}
		}
	}

	/**
	 * Set starttime/endtime/repeat.. etc on todo.
	 */
	setTodoOptions() {
		if (this.dueDateComponent['selectedDueDate'] !== 'tomorrow') {
			this.selectedNewTask['repeatCycle'] = this.dueDateComponent['selectedDueDate'];
		} else {
			this.selectedNewTask['repeatCycle'] = 'one_off';
		}
		if (this.dueDateComponent['oneOff']) {
			this.selectedNewTask['oneOff'] = this.dueDateComponent['oneOff'];
		}

		if (this.dueDateComponent['startTime'] && this.dueDateComponent['startTime'] !== '00:00') {
			this.selectedNewTask['startTime'] = this.setUtcDate(this.dueDateComponent['startTime']);
		}
		if (this.dueDateComponent['endTime'] && this.dueDateComponent['endTime'] !== '00:00') {
			this.selectedNewTask['estimatedTime'] = this.setUtcDate(this.dueDateComponent['endTime']);
		}
		if (this.dueDateComponent['selectedRepeat']) {
			this.selectedNewTask['repeatFreequency'] = this.dueDateComponent['selectedRepeat'];
		}

		if (this.dueDateComponent['selectedReminders'] && this.dueDateComponent['selectedReminders'].length > 0) {
			this.remindersSelected = this.dueDateComponent['selectedReminders'];
		}
	}

	/**
	 * Function to add todo options for the task in sections
	 */
	setTodoOptionsForSectionTask() {
		if (this.dueDateComponent['selectedDueDate'] !== 'tomorrow') {
			this.selectedNewTask['repeatCycle'] = this.dueDateComponent['selectedDueDate'];
		} else {
			this.selectedNewTask['repeatCycle'] = 'one_off';
		}
		if (this.dueDateComponent['oneOff']) {
			this.selectedNewTask['oneOff'] = this.dueDateComponent['oneOff'];
		}

		this.selectedNewTask['startTime'] = this.dueDateComponent['startTime'] ? this.setUtcDate(this.dueDateComponent['startTime']) : null;
		this.selectedNewTask['fromTime'] = this.dueDateComponent['startTime'] ? this.dueDateComponent['startTime'] : null;

		this.selectedNewTask['estimatedTime'] = this.dueDateComponent['endTime'] ? this.setUtcDate(this.dueDateComponent['endTime']) : null;
		this.selectedNewTask['toTime'] = this.dueDateComponent['endTime'] ? this.dueDateComponent['endTime'] : null;

		this.selectedNewTask['repeatFreequency'] = this.dueDateComponent['selectedRepeat'] ? this.dueDateComponent['selectedRepeat'] : null;

		if (this.dueDateComponent['selectedReminders'] && this.dueDateComponent['selectedReminders'].length > 0) {
			// this.remindersSelected = this.dueDateComponent['selectedReminders'];
			this.selectedNewTask['remindersSelected'] = this.dueDateComponent['selectedReminders'];
		}
	}

	/**
	 * Function for setting utc date corresponding to oneOff date in todos
	 * @param time
	 */
	setUtcDate(time) {
		let d;
		if (this.dueDateComponent['selectedDueDate'] === 'daily') {
			const today = new Date().toISOString().slice(0, 10) + '';
			d = today + ' ' + time;
		} else {
			d = this.dueDateComponent['oneOff'] + ' ' + time;
		}
		const date = new Date(d);
		const startMonth = date.getUTCMonth() + 1;
		const startDay = date.getUTCDate();
		const startYear = date.getUTCFullYear();
		const startHours = date.getUTCHours();
		const startMinutes = date.getUTCMinutes();
		const startSeconds = date.getUTCSeconds();
		const utcDate = startYear + '-' + startMonth + '-' + startDay + ' ' + startHours + ':' + startMinutes + ':' + startSeconds;
		return utcDate;
	}

	/**
	 * For setting due date for subtask
	 */
	onSetDueDateForSubtask() {
		this.displaySubDuedate = 'none';
		if (this.sub === 'tomorrow') {
			this.currentSub['repeatCycle'] = 'one_off';
			this.currentSub['oneOff'] = this.setTomorrowDate();
		} else if (this.sub === 'one_off') {
			this.currentSub['repeatCycle'] = 'one_off';
			this.currentSub['oneOff'] = this.parserFormatter.format(this.subtaskDate);
		} else {
			this.currentSub['repeatCycle'] = this.sub;
		}
	}

	/**
	 * Set tomorrow date on subtask
	 */
	setTomorrowDate() {
		const today = new Date();
		const tomorrow = new Date(today);
		tomorrow.setDate(today.getDate() + 1);
		let dd: any;
		let mm: any;
		dd = tomorrow.getDate();
		mm = tomorrow.getMonth() + 1;
		const yyyy = tomorrow.getFullYear();
		if (dd < 10) {
			dd = '0' + dd;
		}
		if (mm < 10) {
			mm = '0' + mm;
		}
		return yyyy + '-' + mm + '-' + dd;
	}

	displaySubtaskAssignees(task) {
		if (!task['status'] || task['edit']) {
			this.currentSub = task;
			this.subAssignee = '';
			this.memberUid = '';
			this.displayMembers = 'block';

			if (task['assignedUser'] && task['assignedUser']['username']) {
				this.memberUid = task['assignedUser']['username'];
			}
			if (this.members.length === 0) {
				// this.getTodolistMembers('all', 'all');
				this.setProjectPrivacy();
			}
		}
	}

	setProjectPrivacy() {
		if (this.projectDetails['channel'] && this.projectDetails['channel']['uid']) {
			const uid = this.projectDetails['channel']['uid'];
			if (this.projectDetails['channel'] && this.projectDetails['channel']['access'] === 4) {
				this.getExternalChannelMembers(uid);
			} else {
				this.getTodolistMembers('channel', uid);
			}
		} else if (this.projectDetails.contentPrivacy) {
			const privacy = this.projectDetails.contentPrivacy;
			const key = Object.keys(privacy);
			const val = Object.values(privacy);
			const val2 = val[0].toString().toLowerCase();
			this.getTodolistMembers(key[0], val2);
		} else if (this.projectDetails.customPrivacy && this.projectDetails.customPrivacy.length > 0) {
			const customPrivacy = [];
			let filter;
			this.projectDetails.customPrivacy.forEach((user) => {
				if (user.id) {
					customPrivacy.push(user.id);
				}
				filter = '[' + customPrivacy.join(',') + ']';
			});
			this.getTodolistMembers('custom_privacy', filter);
		} else {
			this.getTodolistMembers('all', 'all');
		}
	}

	getExternalChannelMembers(value) {
		this.memberService.getMembersForExternalChannel(value).subscribe((data) => {
			this.members = data.objects;
			if (data.next) {
				this.next = data.next.split('alpha')[1];
				this.paginate();
			} else {
				this.next = '';
			}
		});
	}

	getTodolistMembers(key, value) {
		let param;
		if (key === 'all') {
			param = 'external_members=false';
		} else {
			param = key + '=' + value;
		}
		this.memberService.getMembers(this.network.uid, '', param).subscribe(
			(data) => {
				this.members = data.objects;
				if (data.next) {
					this.next = data.next.split('alpha')[1];
					this.paginate();
				} else {
					this.next = '';
				}
			},
			(error) => {}
		);
	}

	public paginate(): void {
		if (this.next) {
			this.networkService.paginateIssues(this.next).subscribe((data) => {
				this.members = this.members.concat(data.objects);
				if (data.next) {
					this.next = data.next.split('alpha')[1];
				} else {
					this.next = '';
				}
			});
		}
	}

	onSetAssigneeForSubtask() {
		this.displayMembers = 'none';
		if (this.subAssignee) {
			this.currentSub['assignedUser'] = this.subAssignee['user'];
		}
	}

	setUnassignedForSubtask() {
		this.displayMembers = 'none';
		delete this.currentSub['assignedUser'];
	}

	onCreateSectionTasks(section) {
		delete section['isCreate'];
		this.handlePreviousTodolist();
		this.loadingGif = 'block';

		if (section['subTaskCount'] && section['subTaskCount'] > 0) {
			this.counter = section['subTaskCount'] + 1;
		} else {
			this.counter = 1;
		}

		section['tasks'].forEach((task, index) => {
			if (!task['title']) {
				section['tasks'].splice(index, 1);
			}
		});

		// we do not need the multiple new task creation
		let array = [];
		array = section['tasks'].filter((task) => !task['status']);
		array.forEach((task, index) => {
			if (!task['status'] && task['title']) {
				task['project'] = this.projectDetails['uid'];
				this.checkForSubtaskMentions(task);
				delete task['edit'];
				delete task['showbg'];

				if (task['assignedUser'] && task['assignedUser']['username']) {
					task['assignedUser'] = task['assignedUser']['username'];
				} else {
					delete task['assignedUser'];
				}

				task['order'] = section['subTaskCount']++;

				this.networkService.createToDo(task, this.todoListUid).subscribe(
					(data) => {
						this.newTaskCreated = true;
						if (this.taskAddType === 'single') {
							this.setReminderForTask(data);
						} else {
							this.setReminderForTaskInSections(data, task.remindersSelected ? task.remindersSelected : []);
						}
						this.selectedNewTask = '';
						this.dummy.push(data);
						const object = {
							content_type: 'todo',
							content_uid: data['uid'],
							order: this.counter++,
						};

						// FIXME: why we are creating a new dummy context here?
						this.contents.push(object);

						if (this.contents.length === array.length) {
							this.params['contents'] = JSON.stringify(this.contents);
							this.params['update'] = true;
							this.addTasksIntoSection(section);
							this.showBtn = false;
							delete section['isCreate'];
						}
					},
					(err) => {
						this.loadingGif = 'none';
						delete section['isCreate'];
						section['subtasks'] = section['subtasks'].filter((tasks) => tasks['status']);
					}
				);
			}
		});
	}

	addTasksIntoSection(section) {
		this.networkService.addTaskInSections(section['uid'], this.params).subscribe(
			(data) => {
				this.params['contents'] = '';
				this.contents = [];
				this.loadingGif = 'none';
				section['subTaskCount'] = data['subTaskCount'];
				this.getDetail();
				this.sortSectionSubtasks(section);
			},
			(error) => {
				this.loadingGif = 'none';
			}
		);
	}

	sortSectionSubtasks(section) {
		if (section['tasks']) {
			section['tasks'] = section['tasks'].filter((task) => task['uid']);
		}

		this.dummy.sort((a, b) => a.order - b.order);

		if (this.sectionsBoard === 'block') {
			section['tasks'] = this.dummy.concat(section['tasks']);
		} else {
			section['tasks'] = section['tasks'].concat(this.dummy);
		}
		this.reorderSectionSubtasks(section);
	}

	reorderSectionSubtasks(section) {
		const arr = [];
		const params = {};
		section['tasks'].forEach((task, index) => {
			if (task['uid']) {
				const object = {
					content_type: 'todo',
					content_uid: task['uid'],
					order: index + 1,
				};
				arr.push(object);
			}
		});
		params['contents'] = JSON.stringify(arr);
		this.loadingGif = 'block';
		this.networkService.editSection(section['uid'], params).subscribe(
			(data) => {
				this.loadingGif = 'none';
				this.dummy = [];
				this.refreshSection(section);
			},
			(error) => {
				this.loadingGif = 'none';
			}
		);
	}

	getMentionsForSubtasks(e, index) {
		this.native = e['path'] ? e['path'][0] : '';
		this.index = index;
		this.userRegexFailed = false;
		this.hashRegexFailed = false;
		e.target.style.height = e.target.scrollHeight + 'px';
		const userMentionRegex = /(^|\s)@(\w*(?:\s*\w*))$/;
		const hashTagRegex = /(^|\s)#(\w*(?:\s*\w*))$/;
		let text;
		const caretPos = this.getCaretPositionForSubtask(e.target.value);
		const word = this.returnWord(e.target.value, caretPos);

		if (word !== null) {
			if (userMentionRegex.test(word)) {
				this.mentionType = 'user';
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					// this.mentionsFetched = 1;
					text = word.replace(/(^@)/g, '');
					this.subject.next(text);
				}
			} else {
				this.userRegexFailed = true;
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					this.hashMentions = [];
					this.userMentions = [];
				}
			}
			if (word !== null && hashTagRegex.test(word)) {
				this.mentionType = 'hash';
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					this.mentionsFetched = 1;
					text = word.replace(/(^#)/g, '');
					this.subject.next(text); // 4
				}
			} else {
				this.hashRegexFailed = true;
				if (e.keyCode !== 38 && e.keyCode !== 40) {
					this.hashMentions = [];
					this.userMentions = [];
				}
			}
		} else {
			if (!this.isMentions) {
				this.mentionsFetched = 0;
				this.userMentions = [];
				this.hashMentions = [];
			}
		}
	}

	onkeyDown(event: KeyboardEvent, section, task) {
		if (this.userMentions.length > 0 || this.hashMentions.length > 0) {
			switch (event.keyCode) {
				case 38:
					event.preventDefault();
					this.arrowKeyLocation--;
					if (this.arrowKeyLocation < 0) {
						this.panel.nativeElement.scrollTop = 0;
						this.arrowKeyLocation = 0;
					}

					if (this.userMentions.length > 0) {
						this.userMentions.forEach((val, index) => {
							if (index === this.arrowKeyLocation) {
								this.addHightLightClass(val);
								this.scrollIntoView(val);
							} else {
								this.removeHightLightClass(val);
							}
						});
					} else {
						this.hashMentions.forEach((val, index) => {
							if (index === this.arrowKeyLocation) {
								this.addHightLightClass(val);
								this.scrollIntoView(val);
							} else {
								this.removeHightLightClass(val);
							}
						});
					}
					break;

				case 40:
					event.preventDefault();
					this.arrowKeyLocation++;
					if (this.arrowKeyLocation > this.mentionCount - 1) {
						this.panel.nativeElement.scrollTop = 0;
						this.arrowKeyLocation = 0;
					}

					if (this.userMentions.length > 0) {
						this.userMentions.forEach((val, index) => {
							if (index === this.arrowKeyLocation) {
								this.addHightLightClass(val);
								this.scrollIntoView(val);
							} else {
								this.removeHightLightClass(val);
							}
						});
					} else {
						this.hashMentions.forEach((val, index) => {
							if (index === this.arrowKeyLocation) {
								this.addHightLightClass(val);
								this.scrollIntoView(val);
							} else {
								this.removeHightLightClass(val);
							}
						});
					}
					break;

				case 13:
					event.preventDefault();
					if (this.mentionType === 'user') {
						this.userMentions.forEach((element, index) => {
							if (index === this.arrowKeyLocation) {
								if (section) {
									this.onUserSelectForSubtask(section, element, '');
								}
								if (task) {
									this.onUserSelectForSubtask('', element, task);
								}
							}
						});
					} else if (this.mentionType === 'hash') {
						this.hashMentions.forEach((element, index) => {
							if (index === this.arrowKeyLocation) {
								if (section) {
									this.onHashTagForSubtask(section, element, '');
								}
								if (task) {
									this.onHashTagForSubtask('', element, task);
								}
							}
						});
					}
			}
		}
	}

	onUserSelectForSubtask(section, user, task) {
		this.isMentions = false;
		this.userMentions = [];
		this.hashMentions = [];
		const startPos = this.native.selectionStart;
		const endPos = this.native.selectionEnd;

		if (startPos === endPos) {
			const text = this.native.value.substring(0, startPos);
			this.mentionIndex = text.lastIndexOf('@');

			if (this.mentionIndex > -1 && this.shiftKeyUsed) {
				const strippedText = this.native.value.substring(this.mentionIndex, startPos);

				if (strippedText !== '@') {
					if (section) {
						const reg = new RegExp(strippedText, 'g');
						const replacedTitle = section['tasks'][this.index]['title'].replace(reg, '@' + user.name + ' ');
						section['tasks'][this.index]['title'] = replacedTitle;
					}
					if (task) {
						const reg = new RegExp(strippedText, 'g');
						const replacedTitle = task['title'].replace(reg, '@' + user.name + ' ');
						task['title'] = replacedTitle;
					}
				} else {
					if (section) {
						const firstPart = section['tasks'][this.index]['title'].substr(0, startPos);
						const lastPart = section['tasks'][this.index]['title'].substr(startPos + 1);
						const newString = firstPart + user.name + ' ' + lastPart;
						section['tasks'][this.index]['title'] = newString;
					}
					if (task) {
						const firstPart = task['title'].substr(0, startPos);
						const lastPart = task['title'].substr(startPos + 1);
						const newString = firstPart + user.name + ' ' + lastPart;
						task['title'] = newString;
					}
				}
			} else {
				const newTitle = <''>`${this.native.value.substring(0, startPos)}@${user.name + ' '}${this.native.value.substring(endPos)}`.toString();
				if (section) {
					section['tasks'][this.index]['title'] = newTitle;
				}
				if (task) {
					task['title'] = newTitle;
				}
				this.native.focus();
			}
		}

		if (section) {
			if (section['tasks'][this.index]['mentioned_users']) {
				section['tasks'][this.index]['mentioned_users'].push(user);
			} else {
				section['tasks'][this.index]['mentioned_users'] = [];
				section['tasks'][this.index]['mentioned_users'].push(user);
			}
		}

		if (task) {
			if (task['mentioned_users']) {
				task['mentioned_users'].push(user);
			} else {
				task['mentioned_users'] = [];
				task['mentioned_users'].push(user);
			}
		}
	}

	onHashTagForSubtask(section, tag, task) {
		this.isMentions = false;
		this.userMentions = [];
		this.hashMentions = [];

		const startPos = this.native.selectionStart;
		const endPos = this.native.selectionEnd;

		if (startPos === endPos) {
			const text = this.native.value.substring(0, startPos);
			this.mentionIndex = text.lastIndexOf('#');

			if (this.mentionIndex > -1 && this.shiftKeyUsed) {
				const strippedText = this.native.value.substring(this.mentionIndex, startPos);
				if (strippedText !== '#') {
					const reg = new RegExp(strippedText, 'g');
					if (section) {
						const replacedTitle = section['tasks'][this.index]['title'].replace(reg, '#' + tag['name'] + ' ');
						section['tasks'][this.index]['title'] = replacedTitle;
					}
					if (task) {
						const replacedTitle = task['title'].replace(reg, '#' + tag['name'] + ' ');
						task['title'] = replacedTitle;
					}
				} else {
					if (section) {
						const firstPart = section['tasks'][this.index]['title'].substr(0, startPos);
						const lastPart = section['tasks'][this.index]['title'].substr(startPos + 1);
						const newString = firstPart + tag['name'] + ' ' + lastPart;
						section['tasks'][this.index]['title'] = newString;
					}
					if (task) {
						const firstPart = task['title'].substr(0, startPos);
						const lastPart = task['title'].substr(startPos + 1);
						const newString = firstPart + tag['name'] + ' ' + lastPart;
						task['title'] = newString;
					}
				}
			} else {
				const newTitle = <''>`${this.native.value.substring(0, startPos)}#${tag['name'] + ' '}${this.native.value.substring(endPos)}`.toString();
				this.native.focus();
				if (section) {
					section['tasks'][this.index]['title'] = newTitle;
				}
				if (task) {
					task['title'] = newTitle;
				}
			}
		}

		if (section) {
			if (section['tasks'][this.index]['hashtags']) {
				section['tasks'][this.index]['hashtags'].push(tag);
			} else {
				section['tasks'][this.index]['hashtags'] = [];
				section['tasks'][this.index]['hashtags'].push(tag);
			}
		}
		if (task) {
			if (task['hashtags']) {
				task['hashtags'].push(tag);
			} else {
				task['hashtags'] = [];
				task['hashtags'].push(tag);
			}
		}
	}

	/** For adding highlight class for mention elements
	 * 1. Clear all previous highlight class on elements.
	 * 2. Add mention highlight css class.
	 * @param val
	 */
	addHightLightClass(val) {
		this.clearHighLightClass(); // 1
		const element = document.getElementById(val.uid);
		element.classList.add(CSS_CLASS_NAMES.highLight); // 2
	}

	/** For removing highlight class for mention elements
	 * @param val
	 */
	removeHightLightClass(val) {
		const element = document.getElementById(val.uid);
		element.classList.remove(CSS_CLASS_NAMES.highLight);
	}

	/**
	 * For clearing highlight class from all mention elements
	 */
	clearHighLightClass() {
		this.userMentions.forEach((val) => {
			if (val.uid) {
				const element = document.getElementById(val.uid);
				element.classList.remove(CSS_CLASS_NAMES.highLight);
			}
		});
		this.hashMentions.forEach((val) => {
			if (val.uid) {
				const element = document.getElementById(val.uid);
				element.classList.remove(CSS_CLASS_NAMES.highLight);
			}
		});
	}

	/**
	 * Scroll to current mention element
	 * 1. Get current elemennt by its uid.
	 * 2. Get offset top of current element
	 * 3. Set topPos as scrolltop hence scroll to this position
	 * @param val
	 */
	scrollIntoView(val) {
		const elem = document.getElementById(val.uid);
		const topPos = elem.offsetTop;
		this.panel.nativeElement.scrollTop = topPos;
	}

	/**
	 * On mouse hover,add or remove hightlight class
	 * 1. Clear previous remanats of hightlight class
	 * @param event
	 */
	onHover(event: MouseEvent) {
		this.clearHighLightClass(); // 1
		const target = event.target as HTMLElement;
		if (event.type === 'mouseover') {
			target.classList.add(CSS_CLASS_NAMES.highLight);
		} else {
			target.classList.remove(CSS_CLASS_NAMES.highLight);
		}
	}

	paginateMentions() {
		if (this.nextMentionUrl) {
			this.postService.paginate(this.nextMentionUrl).subscribe((data) => {
				if (data) {
					if (this.mentionType === 'user') {
						this.userMentions = this.userMentions.concat(data['objects']);
					} else if (this.mentionType === 'hash') {
						this.hashMentions = this.hashMentions.concat(data['objects']);
					}
					if (data.next) {
						this.nextMentionUrl = data.next.split('alpha')[1];
					} else {
						this.nextMentionUrl = '';
					}
				}
			});
		}
	}

	getCaretPositionForSubtask(ctrl) {
		let caretPos = 0;
		ctrl = this.native;
		if (ctrl.selectionStart || ctrl.selectionStart === '0') {
			caretPos = ctrl.selectionStart;
		}
		return caretPos;
	}

	returnWord(text, caretPos) {
		let words = [];
		let preText = '';
		if (text) {
			preText = text.substring(0, caretPos);
			if (preText.indexOf(' ') > 0) {
				words = preText.split(' ');
				return words[words.length - 1]; // return last word
			} else {
				return preText;
			}
		}
		return null;
	}

	/**
	 * This loads the cached todolist for creating task in sections
	 */
	handlePreviousTodolist() {
		if (localStorage.getItem(`${this.network.uid}-Todolist`)) {
			const val = localStorage.getItem(`${this.network.uid}-Todolist`);
			if (val === 'Team not selected' || val === 'No List Selected') {
				this.todoListUid = '';
			} else {
				const cacheList = JSON.parse(localStorage.getItem(`${this.network.uid}-Todolist`)); // 2
				if (cacheList['uid']) {
					this.todoListUid = cacheList['uid'];
				}
			}
		} else {
			this.todoListUid = '';
		}
	}

	displayTaskDelete(task, i, section) {
		this.currentIndex = i;
		this.selectedTask = task;
		this.deleteDialog = 'block';
		this.selectedSection = section;
	}

	clearModal() {
		this.section = new Section();
		this.currentIndex = '';
		this.selectedTask = '';
		this.selectedSection = '';
		this.deleteDialog = 'none';
		this.sectionRenameDialog = 'none';
	}

	confirmTaskDelete() {
		this.loadingGif = 'block';
		this.networkService.deleteToDoItem(this.selectedTask['uid'], '').subscribe(
			(data) => {
				if (this.selectedSection) {
					if (this.selectedSection && this.selectedSection['uid']) {
						this.getSectionDetail(this.selectedSection);
						if (this.selectedSection['tasks']) {
							this.selectedSection['tasks'].splice(this.currentIndex, 1);
						}
					}
				}
				this.loadingGif = 'none';
				this.clearModal();
			},
			(error) => {
				this.loadingGif = 'none';
				this.clearModal();
			}
		);
	}

	// ============Kanben view for tasks=============

	displayStatusView() {
		this.kanbenView = 'block';
		this.scrollStatus = false;
		this.query = '';
		this.getOpenTasks('');
		this.getInProgressTasks('');
		this.getClosedTasks('');
		this.getPausedTasks('');
		this.userMentions = [];
		this.hashMentions = [];
		this.currentState = '';
	}

	closeKanbenView() {
		this.kanbenView = 'none';
		this.scrollStatus = false;
		this.newTask = '';
		this.query = '';
		this.userMentions = [];
		this.hashMentions = [];
	}

	getOpenTasks(query) {
		let params;
		if (query) {
			params = 'open=true&query=' + query;
		} else {
			params = 'open=true';
		}
		this.scrollStatus = false;
		this.openFetching = 0;
		this.openNextUrl = '';
		this.openTasks = [];
		this.networkService.getTodosForKanben(this.projectDetails['uid'], params, this.isArchived).subscribe((data) => {
			if (data.next) {
				this.openNextUrl = data.next.split('alpha')[1];
				this.openFetching = 0;
			} else {
				this.openNextUrl = '';
			}
			this.openCount = data.count;
			this.openTasks = data.objects;
			this.openFetching = 1;
			this.getTaskDuetime(this.openTasks);
			this.handleContents(this.openTasks);
			this.handleMentions(this.openTasks);
		});
	}

	paginateOpen() {
		if (this.openNextUrl) {
			if (this.scrollStatus) {
				return;
			}
			this.scrollStatus = true;
			this.openFetching = 0;
			this.networkService.paginateToDoLists(this.openNextUrl).subscribe((data) => {
				if (data.next) {
					this.openNextUrl = data.next.split('alpha')[1];
				} else {
					this.openNextUrl = '';
				}
				this.openTasks = this.openTasks.concat(data.objects);
				this.openFetching = 1;
				this.scrollStatus = false;
				this.getTaskDuetime(this.openTasks);
				this.handleContents(this.openTasks);
				this.handleMentions(this.openTasks);
			});
		}
	}

	getInProgressTasks(query) {
		let params;
		if (query) {
			params = 'status=in_progress&query=' + query;
		} else {
			params = 'status=in_progress';
			// params = 'status=paused';
		}
		this.scrollStatus = false;
		this.inProgressFetching = 0;
		this.inProgressTasks = [];
		this.networkService.getTodosForKanben(this.projectDetails['uid'], params, this.isArchived).subscribe((data) => {
			if (data.next) {
				this.inProNextUrl = data.next.split('alpha')[1];
				this.inProgressFetching = 0;
			} else {
				this.inProNextUrl = '';
			}
			this.inprogressCount = data.count;
			this.inProgressTasks = data.objects;
			this.inProgressFetching = 1;
			this.getTaskDuetime(this.inProgressTasks);
			this.handleContents(this.inProgressTasks);
			this.handleMentions(this.inProgressTasks);
		});
	}

	paginateInProgress() {
		if (this.inProNextUrl) {
			if (this.scrollStatus) {
				return;
			}
			this.scrollStatus = true;
			this.closedFetching = 0;
			this.networkService.paginateToDoLists(this.inProNextUrl).subscribe((data) => {
				if (data.next) {
					this.inProNextUrl = data.next.split('alpha')[1];
				} else {
					this.inProNextUrl = '';
				}
				this.inProgressTasks = this.inProgressTasks.concat(data.objects);
				this.scrollStatus = false;
				this.inProgressFetching = 1;
				this.getTaskDuetime(this.inProgressTasks);
				this.handleContents(this.inProgressTasks);
				this.handleMentions(this.inProgressTasks);
			});
		}
	}

	/**
	 * Function to get paused tasks
	 * @param query
	 */
	getPausedTasks(query) {
		let params;
		if (query) {
			params = 'status=paused&query=' + query;
		} else {
			params = 'status=paused';
		}
		this.scrollStatus = false;
		this.pausedFetching = 0;
		this.pausedTasks = [];
		this.networkService.getTodosForKanben(this.projectDetails['uid'], params, this.isArchived).subscribe((data) => {
			if (data.next) {
				this.pausedNextUrl = data.next.split('alpha')[1];
				this.pausedFetching = 0;
			} else {
				this.pausedNextUrl = '';
			}
			this.pausedCount = data.count;
			this.pausedTasks = data.objects;
			this.pausedFetching = 1;
			this.getTaskDuetime(this.pausedTasks);
			this.handleContents(this.pausedTasks);
			this.handleMentions(this.pausedTasks);
		});
	}

	/**
	 * Function to paginate paused task
	 * @returns
	 */
	paginatePausedTask() {
		if (this.pausedNextUrl) {
			if (this.scrollStatus) {
				return;
			}
			this.scrollStatus = true;
			this.pausedFetching = 0;
			this.networkService.paginateToDoLists(this.pausedNextUrl).subscribe((data) => {
				if (data.next) {
					this.pausedNextUrl = data.next.split('alpha')[1];
				} else {
					this.pausedNextUrl = '';
				}
				this.pausedTasks = this.pausedTasks.concat(data.objects);
				this.scrollStatus = false;
				this.pausedFetching = 1;
				this.getTaskDuetime(this.pausedTasks);
				this.handleContents(this.pausedTasks);
				this.handleMentions(this.pausedTasks);
			});
		}
	}

	getClosedTasks(query) {
		let params;
		if (query) {
			params = 'closed=All Time&query=' + query;
		} else {
			params = 'closed=All Time';
		}
		this.scrollStatus = false;
		this.closedFetching = 0;
		this.closedTasks = [];
		this.networkService.getTodosForKanben(this.projectDetails['uid'], params, this.isArchived).subscribe((data) => {
			if (data.next) {
				this.closedNextUrl = data.next.split('alpha')[1];
				this.closedFetching = 0;
			} else {
				this.closedNextUrl = '';
			}
			this.closedCount = data.count;
			this.closedTasks = data.objects;
			this.closedFetching = 1;
			this.getTaskDuetime(this.closedTasks);
			this.handleContents(this.closedTasks);
			this.handleMentions(this.closedTasks);
		});
	}

	paginateClosed() {
		if (this.closedNextUrl) {
			if (this.scrollStatus) {
				return;
			}
			this.scrollStatus = true;
			this.closedFetching = 0;
			this.networkService.paginateToDoLists(this.closedNextUrl).subscribe((data) => {
				if (data.next) {
					this.closedNextUrl = data.next.split('alpha')[1];
				} else {
					this.closedNextUrl = '';
				}
				this.closedTasks = this.closedTasks.concat(data.objects);
				this.closedFetching = 1;
				this.scrollStatus = false;
				this.getTaskDuetime(this.closedTasks);
				this.handleContents(this.closedTasks);
				this.handleMentions(this.closedTasks);
			});
		}
	}

	getTaskDuetime(tasks: any[]) {
		return tasks.forEach((task) => {
			if (task.estimatedTime) {
				const localTime = new Date(task.estimatedTime).toLocaleString();
				const d = new Date(task.estimatedTime);
				const currentDate = new Date();
				if (currentDate > d) {
				} else {
					const sliced = localTime.slice(localTime.lastIndexOf(',') + 1, localTime.lastIndexOf(':'));
					task['localEstimatedTime'] = sliced;
				}
			}

			if (task.dueTime && task.dueTime !== '0') {
				task['dueTimeExists'] = true;
			} else if (!task.dueTime || task.dueTime === '0') {
				if (task.estimatedTime) {
					const d = new Date(task.estimatedTime);
					const currentDate = new Date();
					if (currentDate > d) {
						task['dueTimeExists'] = true;
					} else {
						task['dueTimeExists'] = false;
					}
				} else {
					task['dueTimeExists'] = false;
				}
			}
		});
	}

	handleContents(tasks: any[]) {
		return tasks.forEach((task) => {
			if (task['relatedContent'] && task['relatedContent']['attachmentDetails'] && task['relatedContent']['type']) {
				task['attachmentDetails'] = task['relatedContent']['attachmentDetails'];
			}
		});
	}

	handleMentions(tasks: any[]) {
		return tasks.forEach((task) => {
			if (task['title'] && task['title'].indexOf('<') > -1) {
				task['title'] = task['title'].replace(/</g, '< ');
			}
			task['altered_title'] = task['title'];
			if (task['mentionedUsers']) {
				task['mentionedUsers'].forEach((mentionedUser) => {
					const html = '<b #userMention><a class="text-green">@' + mentionedUser['name'] + '</a></b>';
					if (task.altered_title) {
						task['altered_title'] = task.altered_title.replace('@' + mentionedUser['name'], html);
					}
				});
			}
			if (task['hashtags']) {
				task['hashtags'].forEach((hashtag) => {
					if (hashtag['name']) {
						const html = '<b #hashTag><a class="hash-todo">#' + hashtag['name'] + '</a></b>';
						if (task.altered_title) {
							task['altered_title'] = task.altered_title.replace('#' + hashtag['name'], html);
						}
					}
				});
			}
		});
	}

	/**
	 * On Dropping tasks in Open lists in kanben view.
	 * 1. Check if task exists in open lists
	 * 2. If exists,then reorder the list
	 * 3. Call api to set new order of the lists
	 * 4. If task doesnt exists,then remove this task from  closed/inprogress lists
	 * 5. Add this task to open lists and call function to change the state of the task
	 * @param event
	 */
	onDropInOpenLists(event) {
		if (this.checkTaskInLists(event.item.data, this.openTasks)) {
			// 1
			if (event.previousIndex !== event.currentIndex) {
				moveItemInArray(this.openTasks, event.previousIndex, event.currentIndex); // 2
				this.reorder(this.openTasks); // 3
			}
		} else {
			if (event.item.data['status'] === 'closed') {
				this.closedCount = this.closedCount - 1;
				this.closedTasks.splice(event.previousIndex, 1); // 4
			} else if (event.item.data['status'] === 'paused') {
				this.pausedCount = this.pausedCount - 1;
				this.pausedTasks.splice(event.previousIndex, 1);
			} else {
				this.inprogressCount = this.inprogressCount - 1;
				this.inProgressTasks.splice(event.previousIndex, 1); // 4
			}
			this.openCount = this.openCount + 1;
			this.openTasks.splice(event.currentIndex, 0, event.item.data); // 5
			this.changeState(event.item.data, 'open', event.currentIndex, '');
		}
	}

	onDropInPausedList(event) {
		if (this.checkTaskInLists(event.item.data, this.pausedTasks)) {
			// 1
			if (event.previousIndex !== event.currentIndex) {
				moveItemInArray(this.pausedTasks, event.previousIndex, event.currentIndex); // 2
				this.reorder(this.pausedTasks); // 3
			}
		} else {
			if (event.item.data['status'] === 'closed') {
				this.closedCount = this.closedCount - 1;
				this.closedTasks.splice(event.previousIndex, 1); // 4
			} else if (event.item.data['status'] === 'open') {
				this.openCount = this.openCount - 1;
				this.openTasks.splice(event.previousIndex, 1); // 4
			} else {
				this.inprogressCount = this.inprogressCount - 1;
				this.inProgressTasks.splice(event.previousIndex, 1); // 4
			}
			this.pausedCount = this.pausedCount + 1;
			this.pausedTasks.splice(event.currentIndex, 0, event.item.data); // 5
			this.changeState(event.item.data, 'paused', event.currentIndex, '');
		}
	}

	/**
	 * On Dropping tasks in Closed lists in kanben view.
	 * 1. Check if task exists in closed lists
	 * 2. If exists,then reorder the list
	 * 3. Call api to set new order of the lists
	 * 4. If task doesnt exists,then remove this task from  open/inprogress lists
	 * 5. Add this task to open lists and call function to change the state of the task
	 * @param event
	 */
	onDropInClosedLists(event) {
		if (this.checkTaskInLists(event.item.data, this.closedTasks)) {
			// 1
			if (event.previousIndex !== event.currentIndex) {
				moveItemInArray(this.closedTasks, event.previousIndex, event.currentIndex); // 2
				this.reorder(this.closedTasks); // 3
			}
		} else {
			// return

			if (event.item.data['estimate'] && event.item.data['estimate'] > 0) {
				if (
					(event.item.data.status === 'in_progress' || event.item.data.status === 'paused' || event.item.data.status === 'open') &&
					event.item.data['estimate'] &&
					!this.reaction
				) {
					this.selectedTodoPreviousIndex = event.previousIndex;
					this.selectedToDoItem = event.item.data;
					this.todoItemIndex = event.currentIndex;
					this.taskReactionDialog = 'block';
					return;
				}
				if (event.item.data['status'] === 'open') {
					this.openCount = this.openCount - 1;
					this.openTasks.splice(event.previousIndex, 1); // 4
				} else if (event.item.data['status'] === 'paused') {
					this.pausedCount = this.pausedCount - 1;
					this.pausedTasks.splice(event.previousIndex, 1);
				} else {
					this.inprogressCount = this.inprogressCount - 1;
					this.inProgressTasks.splice(event.previousIndex, 1); // 4
				}
				this.displayElapsed = 'none';
				this.closedCount = this.closedCount + 1;
				this.closedTasks.splice(event.currentIndex, 0, event.item.data); // 5
				this.changeState(event.item.data, 'closed', event.currentIndex, '');
			} else {
				this.displayElapsed = 'block';
				setTimeout(() => {
					document.getElementById('elapsedTime').focus();
				}, 150);
				this.selectedTodoPreviousIndex = event.previousIndex;

				this.selectedToDoItem = event.item.data;
				this.todoItemIndex = event.currentIndex;
			}
		}
	}

	/**
	 * On Dropping tasks in Inprogress lists in kanben view.
	 * 1. Check if task exists in inprogress lists
	 * 2. If exists,then reorder the list
	 * 3. Call api to set new order of the lists
	 * 4. If task doesnt exists,then remove this task from  open/inprogress lists
	 * 5. Add this task to open lists and call function to change the state of the task
	 * @param event
	 */
	onDropInProgressLists(event) {
		if (this.checkTaskInLists(event.item.data, this.inProgressTasks)) {
			// 1
			if (event.previousIndex !== event.currentIndex) {
				moveItemInArray(this.inProgressTasks, event.previousIndex, event.currentIndex); // 2
				this.reorder(this.inProgressTasks); // 3
			}
		} else {
			if (event.item.data['estimate'] && event.item.data['estimate'] > 0) {
				if (event.item.data['status'] === 'open') {
					this.openCount = this.openCount - 1;
					this.openTasks.splice(event.previousIndex, 1); // 4
				} else if (event.item.data['status'] === 'paused') {
					this.pausedCount = this.pausedCount - 1;
					this.pausedTasks.splice(event.previousIndex, 1);
				} else {
					this.closedCount = this.closedCount - 1;
					this.closedTasks.splice(event.previousIndex, 1); // 4
				}
				this.displayEstimate = 'none';
				this.inprogressCount = this.inprogressCount + 1;
				this.inProgressTasks.splice(event.currentIndex, 0, event.item.data); // 5
				this.changeState(event.item.data, 'in_progress', event.currentIndex, '');
			} else {
				this.selectedTodoPreviousIndex = event.previousIndex;
				this.selectedToDoItem = event.item.data;
				this.todoItemIndex = event.currentIndex;
				this.displayEstimate = 'block';
				setTimeout(() => {
					document.getElementById('estimateTime').focus();
				}, 150);
			}
		}
	}

	/**
	 * Function to remove the todo from list after drag and drop
	 * @param status
	 * @param index
	 */
	removeTodoFromTheList(status, index, currentState) {
		if (status === 'open') {
			this.openCount = this.openCount - 1;
			this.openTasks.splice(index, 1); // 4
		} else if (status === 'paused') {
			this.pausedCount = this.pausedCount - 1;
			this.pausedTasks.splice(index, 1);
		} else if (status === 'in_progress') {
			this.inprogressCount = this.inprogressCount - 1;
			this.inProgressTasks.splice(index, 1);
		} else {
			this.closedCount = this.closedCount - 1;
			this.closedTasks.splice(index, 1); // 4
		}

		if (currentState === 'open') {
			this.openCount = this.openCount + 1;
		} else if (currentState === 'paused') {
			this.pausedCount = this.pausedCount + 1;
		} else if (currentState === 'in_progress') {
			this.inprogressCount = this.inprogressCount + 1;
		} else {
			this.closedCount = this.closedCount + 1;
		}
	}

	/**
	 * For checking if tasks is present in open/closed/inprogress lists
	 * @param task
	 * @param lists
	 */
	checkTaskInLists(task, lists: any[]) {
		if (lists.length > 0) {
			const index = lists.findIndex((x) => x['uid'] === task['uid']);
			if (index > -1) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	/**
	 * For reordering tasks in a particular lists
	 * @param lists
	 */
	reorder(lists: any[]) {
		const arr = [];
		const params = {};
		lists.forEach((task, index) => {
			if (task['uid']) {
				const object = { uid: task['uid'], order: index + 1 };
				arr.push(object);
			}
		});

		params['contents'] = JSON.stringify(arr);
		this.loadingGif = 'block';
		this.networkService.reorderTasks(this.projectDetails['uid'], params).subscribe(
			(data) => {
				this.loadingGif = 'none';
			},
			(err) => {
				this.loadingGif = 'none';
			}
		);
	}

	/**
	 * For changing task states
	 */
	changeState(task, state, index, currentState) {
		this.loadingGif = 'block';
		this.updateAttachmentDetails(task);
		this.todoItem.title = task.title;
		this.todoItem['status'] = state;
		this.todoItem.priority = task.priority;
		this.todoItem.repeatCycle = task.repeatCycle;
		if (task.assignedUser && task.assignedUser.username) {
			this.todoItem['assignedUser'] = task.assignedUser.username;
		}
		if (task.repeatCycle === 'one_off') {
			const date = task.oneOff;
			const splicedDate = date.split(' ')[0];
			this.todoItem['oneOff'] = splicedDate;
		}
		if (task.repeatFreequency) {
			this.todoItem['repeatFreequency'] = task.repeatFreequency;
		}
		if (task['project'] && task['project']['uid']) {
			this.todoItem['project'] = task['project']['uid'];
		}
		if (task.numberOfOpens && task.numberOfOpens > 0) {
			this.todoItem['numberOfOpens'] = task.numberOfOpens;
		}

		if (this.estimateForm.valid && state === 'in_progress') {
			this.todoItem['estimate'] = this.estimateForm.value['time'];
		}

		if (this.elapsedForm.valid) {
			this.todoItem['elapsed_time'] = this.elapsedForm.value['time'];
		}

		if (state === 'closed') {
			const d = new Date().toISOString().slice(0, 10);
			const date = new Date();
			const hours = date.getUTCHours();
			const minutes = date.getUTCMinutes();
			const seconds = date.getUTCSeconds();
			const utcDateTime = d + ' ' + hours + ':' + minutes + ':' + seconds;
			this.todoItem['closedDate'] = utcDateTime;
		}

		if (task['postUid']) {
			this.comment.contentType = 'post';
			this.comment.contentUid = task['postUid'];
		} else {
			this.comment.contentType = '';
			this.comment.contentUid = '';
		}

		if (this.reaction) {
			this.todoItem['outcome'] = this.reaction;
		}

		this.networkService.updateToDoItem(this.todoItem, task.uid).subscribe(
			(data) => {
				if (data.status === 'closed') {
					this.mixPanelService.track('Completed Task', {
						workspace: this.network?.name || 'NA',
						appVersion: environment.version,
						language: this.translate?.currentLang,
						username: this.currentUser?.username || 'NA',
						name: this.currentUser?.name || 'NA',
						project: data?.project?.name || 'NA',
						date: moment().format('YYYY-MM-DD'),
					});

					this.segmentService.trackEvent('Completed Task', {
						workspace: this.network?.name || 'NA',
						appVersion: environment.version,
						language: this.translate?.currentLang,
						username: this.currentUser?.username || 'NA',
						name: this.currentUser?.name || 'NA',
						project: data?.project?.name || 'NA',
						date: moment().format('YYYY-MM-DD'),
					});
				}
				this.reaction = '';
				this.createAutoComments(state, data);
				this.createAutoCommentsForTask(state, data);
				delete this.todoItem['closedDate'];
				delete this.todoItem['project'];
				delete this.todoItem['repeatFreequency'];
				delete this.todoItem['oneOff'];
				delete this.todoItem['numberOfOpens'];
				delete this.todoItem['estimate'];
				delete this.todoItem['elapsed_time'];

				this.loadingGif = 'none';

				if (this.currentPage === 'projectDetail') {
					this.getDetail();
				}

				if (currentState) {
					if (currentState === 'closed') {
						this.closedTasks[index] = data;
						// this.closedTasks.splice(index,0,data)
						this.getTaskDuetime(this.closedTasks);
						this.handleContents(this.closedTasks);
						this.handleMentions(this.closedTasks);
						this.reorder(this.closedTasks);
					} else if (currentState === 'open') {
						this.openTasks[index] = data;
						// this.openTasks.splice(index,0,data)
						this.getTaskDuetime(this.openTasks);
						this.handleContents(this.openTasks);
						this.handleMentions(this.openTasks);
						this.reorder(this.openTasks);
					} else if (currentState === 'paused') {
						this.pausedTasks[index] = data;
						// this.pausedTasks.splice(index,0,data)
						this.getTaskDuetime(this.pausedTasks);
						this.handleContents(this.pausedTasks);
						this.handleMentions(this.pausedTasks);
						this.reorder(this.pausedTasks);
					} else {
						this.inProgressTasks[index] = data;
						// this.inProgressTasks.splice(index,0,data)
						this.getTaskDuetime(this.inProgressTasks);
						this.handleContents(this.inProgressTasks);
						this.handleMentions(this.inProgressTasks);
						this.reorder(this.inProgressTasks);
					}
				} else {
					if (state === 'closed') {
						this.closedTasks[index] = data;
						// this.closedTasks.splice(index,0,data)
						this.getTaskDuetime(this.closedTasks);
						this.handleContents(this.closedTasks);
						this.handleMentions(this.closedTasks);
						this.reorder(this.closedTasks);
					} else if (state === 'open') {
						this.openTasks[index] = data;
						// this.openTasks.splice(index,0,data)
						this.getTaskDuetime(this.openTasks);
						this.handleContents(this.openTasks);
						this.handleMentions(this.openTasks);
						this.reorder(this.openTasks);
					} else if (state === 'paused') {
						this.pausedTasks[index] = data;
						// this.pausedTasks.splice(index,0,data)
						this.getTaskDuetime(this.pausedTasks);
						this.handleContents(this.pausedTasks);
						this.handleMentions(this.pausedTasks);
						this.reorder(this.pausedTasks);
					} else {
						this.inProgressTasks[index] = data;
						// this.inProgressTasks.splice(index,0,data)
						this.getTaskDuetime(this.inProgressTasks);
						this.handleContents(this.inProgressTasks);
						this.handleMentions(this.inProgressTasks);
						this.reorder(this.inProgressTasks);
					}
					this.estimateForm.reset();
					this.elapsedForm.reset();
					this.reset();
				}
			},
			(error) => {
				this.loadingGif = 'none';
			}
		);
	}

	/**
	 * Function to handle start and pause task
	 * @param todo
	 * @param i
	 * @param state
	 */
	handleStartPauseForBoard(todo, i, state, currentState) {
		this.selectedToDoItem = todo;
		this.selectedTodoPreviousIndex = i;
		// if (state) {
		//   this.currentState = state;
		// } else {
		//   this.currentState = '';
		// }
		if (state === 'open') {
			this.todoItemIndex = this.openTasks.length;
		} else if (state === 'in_progress') {
			this.todoItemIndex = this.inProgressTasks.length;
		} else if (state === 'paused') {
			this.todoItemIndex = this.pausedTasks.length;
		} else {
			this.todoItemIndex = this.closedTasks.length;
		}

		if (state === 'closed') {
			if ((currentState === 'in_progress' || currentState === 'paused' || currentState === 'open') && todo['estimate'] && !this.reaction) {
				this.taskReactionDialog = 'block';
				return;
			}
		}

		if (currentState === 'open' || currentState === 'paused') {
			if (todo['estimate'] && todo['estimate'] > 0) {
				this.displayEstimate = 'none';
				this.displayElapsed = 'none';
				this.changeState(todo, state, this.todoItemIndex, '');
				this.removeTodoFromTheList(todo['status'], i, state);
			} else {
				if (state === 'in_progress') {
					this.displayEstimate = 'block';
					setTimeout(() => {
						document.getElementById('estimateTime').focus();
					}, 150);
				} else {
					this.displayElapsed = 'block';
					setTimeout(() => {
						document.getElementById('elapsedTime').focus();
					}, 150);
				}
			}
		} else {
			this.displayEstimate = 'none';
			this.displayElapsed = 'none';
			this.changeState(todo, state, this.todoItemIndex, '');
			this.removeTodoFromTheList(todo['status'], i, state);
		}
	}

	autoSizeTask(e, state, task) {
		if (task['title'] && e.keyCode === 13 && this.userMentions.length === 0 && this.hashMentions.length === 0) {
			e.preventDefault();
			this.onCreateTaskInBoard(state, task);
		}
		e.target.style.cssText = 'padding: 5px; background: none; resize: none;height:auto;border:none;font-size:14px';
		e.target.style.cssText = 'padding: 5px; background: none; resize: none;border:none;font-size:14px;height:' + e.target.scrollHeight + 'px';
	}

	onAddTaskForState() {
		if (!this.newTask) {
			const x = new Subtask();
			x['repeatCycle'] = 'daily';
			x['assignedUser'] = this.currentUser;
			this.newTask = x;
			setTimeout(() => {
				this.textSection.nativeElement.focus();
			}, 0);
		} else {
			this.newTask = '';
		}
		this.dueDateComponent.reset();
	}

	onAddTaskFor() {
		if (!this.boardTask) {
			const x = new Subtask();
			x['repeatCycle'] = 'daily';
			x['assignedUser'] = this.currentUser;
			this.boardTask = x;
			setTimeout(() => {
				this.text.nativeElement.focus();
			}, 0);
		} else {
			this.boardTask = '';
		}
		this.dueDateComponent.reset();
	}

	onCreateTaskInBoard(state, task) {
		if (task['title'] && task['title'].trim()) {
			this.loadingGif = 'block';
			this.checkForSubtaskMentions(task);
			this.handlePreviousTodolist();
			task['status'] = state;
			task['project'] = this.projectDetails['uid'];

			if (task['assignedUser'] && task['assignedUser']['username']) {
				task['assignedUser'] = task['assignedUser']['username'];
			} else {
				delete task['assignedUser'];
			}

			// return
			this.networkService.createToDo(task, this.todoListUid).subscribe(
				(data) => {
					this.setReminderForTask(data);
					if (state) {
						this.openTasks.unshift(data);
						this.openCount = this.openCount + 1;
						this.reorder(this.openTasks);
						this.getTaskDuetime(this.openTasks);
						this.handleContents(this.openTasks);
						this.handleMentions(this.openTasks);
					} else {
						this.todoItems.unshift(data);
						this.reorder(this.todoItems);
						this.getTaskDuetime(this.todoItems);
						this.handleContents(this.todoItems);
						this.handleMentions(this.todoItems);
					}

					this.userMentions = [];
					this.hashMentions = [];
					this.newTask = '';
					this.boardTask = '';
					this.selectedNewTask = '';
					this.currentSub = '';
					this.sub = 'daily';
					this.subtaskDate = {
						year: new Date().getFullYear(),
						month: new Date().getMonth() + 1,
						day: new Date().getDate(),
					};
					this.loadingGif = 'none';
					if (this.kanbenView === 'block') {
						this.onAddTaskForState();
					}
					if (this.sectionsBoard === 'block') {
						this.onAddTaskFor();
					}
				},
				(error) => {
					this.loadingGif = 'none';
				}
			);
		}
	}

	getTasks() {
		this.sectionsBoard = 'block';
		this.isReorder = false;
		this.scrollStatus = false;
		this.isBoardFetched = 0;
		this.stateFilter = 'All Tasks';
		this.statusFilter = 'Filters';
		this.newTask = '';
		this.query = '';
		this.selectedSection = '';
		this.todoItemIndex = '';
		this.section['name'] = '';
		this.newSection = false;
		this.userMentions = [];
		this.hashMentions = [];
		this.tab = 'all';
		this.getAllTodos('', 'all');
		this.networkService.getProjectSections(this.projectDetails['uid'], this.projectDetails['sectionCount']).subscribe(
			(data) => {
				this.boardSections = data.objects;
				this.isBoardFetched = 1;

				if (this.boardSections.length > 0) {
					this.boardSections.forEach((section, i) => {
						this.getTodos(section, '');
					});
				}
			},
			(error) => {
				this.isBoardFetched = 1;
			}
		);
	}

	closeBoard() {
		this.sectionsBoard = 'none';
		this.isReorder = false;
		this.scrollStatus = false;
		this.newTask = '';
		this.query = '';
		this.userMentions = [];
		this.hashMentions = [];
		this.displayTodos(this.tab);
	}

	getTodos(section, filter) {
		section['fetching'] = false;
		this.networkService.getTodosInSection(section.uid, section['subTaskCount'], filter).subscribe(
			(data) => {
				data.objects.forEach((task) => {
					task['section'] = section;
				});
				section['tasks'] = data.objects;
				section['fetching'] = true;
				this.getTaskDuetime(section['tasks']);
				this.handleContents(section['tasks']);
				this.handleMentions(section['tasks']);
				this.selectedSection = '';
			},
			(err) => {}
		);
	}

	getTodos2(section, filter) {
		section['tasks'] = [];
		section['fetching'] = false;
		this.networkService.getTodosInSection(section.uid, section['subTaskCount'], filter).subscribe(
			(data) => {
				data.objects.forEach((task) => {
					task['section'] = section;
				});
				section['tasks'] = data.objects;
				section['fetching'] = true;
				this.getTaskDuetime(section['tasks']);
				this.handleContents(section['tasks']);
				this.handleMentions(section['tasks']);
			},
			(err) => {}
		);
	}

	onDropInSection(section, event) {
		const sec = event.container.data;
		const task = event.item.data;
		if (this.checkIftaskInSection(task, sec)) {
			if (event.previousIndex !== event.currentIndex) {
				moveItemInArray(section['tasks'], event.previousIndex, event.currentIndex);
				this.reOrderSectionTasks(section);
			}
		} else {
			if (this.checkIfTasksinMainlists(event.item.data)) {
				this.isReorder = true;
				this.dropToSectionOutside(section, event);
			} else {
				section['subTaskCount'] = section['subTaskCount'] + 1;
				const sourceSection = event.item.data['section'];
				// const index = this.sections.findIndex((sec) => sec['uid'] === sourceSection['uid']);
				const index = this.boardSections.findIndex((eachSec) => eachSec['uid'] === sourceSection['uid']);

				this.boardSections[index]['tasks'].splice(event.previousIndex, 1);
				section['tasks'].splice(event.currentIndex, 0, event.item.data);
				this.loadingGif = 'block';
				this.networkService.movetaskBetweenSections(sourceSection, task, sec).subscribe(
					(data) => {
						this.reOrderSectionTasks(section);
						this.getSectionDetail(sourceSection);
					},
					(error) => {
						this.loadingGif = 'none';
					}
				);
			}
		}
	}

	displaySectionRename(section, i) {
		this.sectionRenameDialog = 'block';
		this.selectedSection = section;
		this.selectedIndex = i;
	}

	editSectionInBoard() {
		this.loadingGif = 'block';
		this.section['project'] = this.projectDetails['uid'];
		this.networkService.editSection(this.selectedSection['uid'], this.section).subscribe(
			(data) => {
				this.loadingGif = 'none';
				this.boardSections[this.selectedIndex]['name'] = data['name'];
				this.sections[this.selectedIndex] = data;
				this.clearModal();
			},
			(error) => {
				this.loadingGif = 'none';
			}
		);
	}

	onAddTaskInBoard(section) {
		const x = new Subtask();
		x['repeatCycle'] = 'daily';
		x['assignedUser'] = this.currentUser;
		x['edit'] = true;
		section['tasks'].unshift(x);
		// this.selectedSection = section;
		setTimeout(() => {
			this.textSection.nativeElement.focus();
		}, 0);
		this.dueDateComponent.reset();
	}

	clearCell(section, i) {
		if (section['tasks'] && section['tasks'].length > 0) {
			section['tasks'].splice(i, 1);
			const index = section['tasks'].findIndex((task) => !task['status']);
			if (index === -1) {
				delete section['isCreate'];
			}
		}
	}

	onCreateTask(section) {
		this.handlePreviousTodolist();
		this.loadingGif = 'block';

		section['tasks'].forEach((task) => {
			if (!task['status'] && task['title']) {
				task['project'] = this.projectDetails['uid'];
				this.checkForSubtaskMentions(task);

				if (task['assignedUser'] && task['assignedUser']['username']) {
					task['assignedUser'] = task['assignedUser']['username'];
				} else {
					delete task['assignedUser'];
				}
				this.networkService.createToDo(task, this.todoListUid).subscribe(
					(data) => {
						section['tasks'] = section['tasks'].filter((tasks) => tasks['status']);
						section['tasks'].unshift(data);
						const object = {
							content_type: 'todo',
							content_uid: data['uid'],
							order: 1,
						};
						this.contents.push(object);
						this.params['contents'] = JSON.stringify(this.contents);
						this.params['update'] = true;
						this.addTask(section);
					},
					(err) => {
						delete section['isCreate'];
						section['subtasks'] = section['subtasks'].filter((tasks) => tasks['status']);
					}
				);
			}
		});
	}

	addTask(section) {
		this.networkService.addTaskInSections(section['uid'], this.params).subscribe(
			(data) => {
				this.params['contents'] = '';
				this.contents = [];
				this.loadingGif = 'none';
				section['subTaskCount'] = data['subTaskCount'];
				this.reorderSectionSubtasks(section);
			},
			(error) => {
				this.loadingGif = 'none';
			}
		);
	}

	enter(e) {
		const elem = e['container']['element']['nativeElement'];
		elem.scrollIntoView({ behavior: 'smooth', inline: 'center' });
	}

	autoSizeBoardTask(e) {
		e.target.style.cssText = 'padding: 5px; background: none; resize: none;height:auto;border:none;font-size:14px';
		e.target.style.cssText = 'padding: 5px; background: none; resize: none;border:none;font-size:14px;height:' + e.target.scrollHeight + 'px';
	}

	filterTasksByState(state) {
		this.stateFilter = state;
		if (state === 'complete') {
			this.getAllTodos('closed=All Time', this.tab);
			if (this.boardSections.length > 0) {
				this.boardSections.forEach((section, i) => {
					this.getTodos2(section, 'status=closed');
				});
			}
		} else if (state === 'incomplete') {
			this.getAllTodos('open=true', this.tab);
			if (this.boardSections.length > 0) {
				this.boardSections.forEach((section, i) => {
					this.getTodos2(section, 'status=open');
				});
			}
		} else {
			this.getAllTodos('', 'all');
			if (this.boardSections.length > 0) {
				this.boardSections.forEach((section, i) => {
					this.getTodos2(section, '');
				});
			}
		}
	}

	filterTasksByStatus(state) {
		this.statusFilter = state;
		if (state === 'My Tasks') {
			this.getAllTodos('open=true&assigned=true', this.tab);
			if (this.boardSections.length > 0) {
				this.boardSections.forEach((section, i) => {
					this.getTodos2(section, 'status=open&assigned=true');
				});
			}
		} else if (state === 'Past Due') {
			this.getAllTodos('past=true', this.tab);
			if (this.boardSections.length > 0) {
				this.boardSections.forEach((section, i) => {
					this.getTodos2(section, 'past=true');
				});
			}
		} else if (state === 'This Week') {
			this.getAllTodos('this_week=true', this.tab);
			if (this.boardSections.length > 0) {
				this.boardSections.forEach((section, i) => {
					this.getTodos2(section, 'this_week=true');
				});
			}
		} else {
			this.getAllTodos('next_week=true', this.tab);
			if (this.boardSections.length > 0) {
				this.boardSections.forEach((section, i) => {
					this.getTodos2(section, 'next_week=true');
				});
			}
		}
	}

	/** ----------------For adding new section in sections board view starts------------------------------- */

	onAddSection() {
		this.newSection = true;
		setTimeout(() => {
			this.sec.nativeElement.focus();
		}, 0);
	}

	onSectionEnter(e) {
		if (e.keyCode === 13) {
			e.preventDefault();
			this.onCreateSectionInBoard();
		}
	}

	onCreateSectionInBoard() {
		if (this.section['name']) {
			this.section['project'] = this.projectDetails['uid'];
			this.section['order'] = this.sections.length;
			this.loadingGif = 'block';
			this.networkService.createSection(this.section).subscribe(
				(data) => {
					data['fetching'] = true;
					data['tasks'] = [];
					this.boardSections.unshift(data);
					this.reorderSectionsBoard(this.boardSections);
					this.newSection = true;
					this.section['name'] = '';
					this.loadingGif = 'none';
					this.projectDetails['sectionCount'] = this.projectDetails['sectionCount'] + 1;
				},
				(err) => {
					this.loadingGif = 'none';
				}
			);
		}
	}

	reorderSectionsBoard(sections: any[]) {
		const arr = [];
		const params = {};
		sections.forEach((section, index) => {
			if (section['uid']) {
				const object = { uid: section['uid'], order: index + 1 };
				arr.push(object);
			}
		});
		params['contents'] = JSON.stringify(arr);
		this.loadingGif = 'block';
		this.networkService.reorderSections(params).subscribe(
			(data) => {
				this.loadingGif = 'none';
			},
			(error) => {
				this.loadingGif = 'none';
			}
		);
	}

	onTaskEdit(section, task) {
		this.onEditToDoItem(task);
		if (section) {
			this.selectedSection = section;
		} else {
			this.selectedSection = '';
		}
	}

	onTaskDelete(section, task) {
		this.selectedSection = section;
		this.showDeleteItemModal(task);
	}

	public onSearchEnter(event: any): void {
		if (event.keyCode === 13) {
			this.onSearchBoard();
		}
	}

	onSearchBoard() {
		if (this.kanbenView === 'block') {
			this.openTasks = [];
			this.inProgressTasks = [];
			this.closedTasks = [];
			if (this.query) {
				this.getOpenTasks(this.query);
				this.getInProgressTasks(this.query);
				this.getClosedTasks(this.query);
				this.getPausedTasks(this.query);
			} else {
				this.getOpenTasks('');
				this.getInProgressTasks('');
				this.getClosedTasks('');
				this.getPausedTasks('');
			}
		} else {
			if (this.query) {
				const query = 'query=' + this.query;
				this.getAllTodos(query, 'all');
				if (this.boardSections.length > 0) {
					this.boardSections.forEach((section, i) => {
						section['tasks'] = [];
						this.getTodos(section, query);
					});
				}
			} else {
				this.getAllTodos('', 'all');
				if (this.boardSections.length > 0) {
					this.boardSections.forEach((section, i) => {
						section['tasks'] = [];
						this.getTodos(section, '');
					});
				}
			}
		}
	}

	/**
	 * Function on clicking start/pause action
	 */
	handleStartPause(todo, i, section) {
		this.selectedToDoItem = todo;
		this.todoItemIndex = i;
		this.selectedSection = section;
		if (todo['status'] === 'open' || todo['status'] === 'paused') {
			if (todo['estimate'] && todo['estimate'] > 0) {
				this.displayEstimate = 'none';
				if (section) {
					this.manageSectionTaskState(section, todo, 'in_progress', i);
				} else {
					this.onDoneUndoneClicked(this.selectedToDoItem, 'in_progress', i);
				}
			} else {
				this.displayEstimate = 'block';
				setTimeout(() => {
					document.getElementById('estimateTime').focus();
				}, 150);
			}
		} else {
			this.displayEstimate = 'none';
			if (section) {
				this.selectedSection = section;
				this.manageSectionTaskState(section, todo, 'paused', i);
			} else {
				this.onDoneUndoneClicked(this.selectedToDoItem, 'paused', i);
			}
		}
	}

	/**
	 * Handle subtask start/pause actions under a task
	 * @param task
	 * @param subtask
	 * @param mainIndex
	 * @param subIndex
	 */
	handleSubtasksStartPause(task, subtask, mainIndex, subIndex) {
		this.mainTask = task;
		this.selectedToDoItem = subtask;
		this.todoItemIndex = mainIndex;
		this.subIndex = subIndex;

		if (subtask['status'] === 'open' || subtask['status'] === 'paused') {
			if (subtask['estimate'] && subtask['estimate'] > 0) {
				this.displayEstimate = 'none';
				this.manageSubtaskState(this.mainTask, subtask, 'in_progress', mainIndex, subIndex);
			} else {
				this.displayEstimate = 'block';
				setTimeout(() => {
					document.getElementById('estimateTime').focus();
				}, 150);
			}
		} else {
			this.displayEstimate = 'none';
			this.manageSubtaskState(this.mainTask, subtask, 'paused', mainIndex, subIndex);
		}
	}

	/**
	 * Function on calling start action from estimatedTime modal
	 */
	manageTaskStart(i) {
		this.estimateForm.markAllAsTouched();
		if (this.estimateForm.valid) {
			this.displayEstimate = 'none';
			if (this.kanbenView === 'block') {
				if (this.currentState) {
					this.changeState(this.selectedToDoItem, 'in_progress', this.todoItemIndex, this.currentState);
				} else {
					this.changeState(this.selectedToDoItem, 'in_progress', this.todoItemIndex, '');
					this.removeTodoFromTheList(this.selectedToDoItem.status, this.selectedTodoPreviousIndex, 'in_progress');
				}
			} else {
				if (this.selectedSection) {
					this.manageSectionTaskState(this.selectedSection, this.selectedToDoItem, 'in_progress', i);
				} else if (this.selectedToDoItem['type'] === 'sub_task') {
					this.manageSubtaskState(this.mainTask, this.selectedToDoItem, 'in_progress', this.todoItemIndex, this.subIndex);
				} else if (this.selectedToDoItem['type'] === 'todo') {
					this.onDoneUndoneClicked(this.selectedToDoItem, 'in_progress', i);
				}
			}
		}
	}

	/**
	 * Function on calling closed action from elapsed time modal
	 */
	manageTaskClose(i) {
		this.elapsedForm.markAllAsTouched();
		if (this.elapsedForm.valid && this.reaction) {
			this.displayElapsed = 'none';
			if (this.kanbenView === 'block') {
				if (this.currentState) {
					this.changeState(this.selectedToDoItem, 'closed', this.todoItemIndex, this.currentState);
				} else {
					this.changeState(this.selectedToDoItem, 'closed', this.todoItemIndex, '');
					this.removeTodoFromTheList(this.selectedToDoItem.status, this.selectedTodoPreviousIndex, 'closed');
				}
			} else {
				this.selectedToDoItem['status'] = 'closed';
				if (this.selectedSection) {
					this.manageSectionTaskState(this.selectedSection, this.selectedToDoItem, 'closed', i);
				} else if (this.selectedToDoItem['type'] === 'sub_task') {
					this.manageSubtaskState(this.mainTask, this.selectedToDoItem, 'closed', this.todoItemIndex, this.subIndex);
				} else if (this.selectedToDoItem['type'] === 'todo') {
					this.onDoneUndoneClicked(this.selectedToDoItem, 'closed', i);
				}
			}
		}
	}

	/**
	 * THis will reset estimate and elapsed modals
	 */
	public reset(): void {
		this.displayElapsed = 'none';
		this.displayEstimate = 'none';
		this.elapsedForm.reset();
		this.estimateForm.reset();
		this.selectedToDoItem = '';
		this.selectedSection = '';
		this.todoItemIndex = '';
		this.mainTask = '';
		this.subIndex = '';
		this.currentState = '';
		this.selectedTodoPreviousIndex = '';
		this.reaction = '';
		this.taskReactionDialog = 'none';
	}

	/**
	 * Function to show the due date
	 */
	showDueDateInDetail(task) {
		if (task['repeatCycle'] === 'daily') {
			return 'Today';
		} else if (task['repeatCycle'] === 'weekly') {
			return 'This week';
		} else if (task['repeatCycle'] === 'monthly') {
			return 'This month';
		} else if (task['oneOff']) {
			const tomorrow = moment().add(1, 'days').format('yyyy-MM-DD');
			if (tomorrow === moment(task['oneOff']).format('yyyy-MM-DD')) {
				return 'Tomorrow';
			} else {
				return moment(task['oneOff']).format('yyyy-MM-DD');
			}
		}
	}

	/**
	 * Function to round the decimal number
	 * @param number
	 * @returns
	 */
	public roundDecimalPlace(number): number {
		const value = parseFloat(number);
		return Math.round(value * 100) / 100;
	}

	onEnterSection(e, section) {
		//  const elem = e['container']['element']['nativeElement'];
		// elem.scrollIntoView({ behavior: 'smooth'});
		// window.scrollTo(0,0)
	}

	/**
	 * Date:18-04-2022
	 *
	 * Function to duplicate the project
	 * @param projectDetails
	 */
	onClickDuplicateProject(projectDetails) {
		this.loadingGif = 'block';
		this.postService.duplicateProject(projectDetails.uid).subscribe(
			(res) => {
				this.loadingGif = 'none';
				if (res) {
				}
			},
			(err) => {
				this.loadingGif = 'none';
			}
		);
	}

	/**
	 * Function to close the task with reaction;
	 */
	public onConfirmReaction(): void {
		this.taskReactionDialog = 'none';

		if (this.kanbenView === 'block') {
			if (this.currentState) {
				this.changeState(this.selectedToDoItem, 'closed', this.todoItemIndex, this.currentState);
			} else {
				this.closedCount = this.closedCount + 1;
				this.closedTasks.splice(this.todoItemIndex, 0, this.selectedToDoItem); // 5
				this.changeState(this.selectedToDoItem, 'closed', this.todoItemIndex, '');
				this.removeTodoFromTheList(this.selectedToDoItem.status, this.selectedTodoPreviousIndex, 'closed');
			}
		} else if (this.sectionsBoard === 'block') {
			this.onDoneUndoneClicked(this.selectedToDoItem, this.selectedTodoValue, this.selectedTodoIndex);
		} else {
			if (this.selectedSection) {
				this.manageSectionTaskState(this.selectedSection, this.selectedToDoItem, 'closed', this.todoItemIndex);
			} else if (this.selectedToDoItem['type'] === 'sub_task') {
				this.manageSubtaskState(this.mainTask, this.selectedToDoItem, 'closed', this.todoItemIndex, this.subIndex);
			} else if (this.selectedToDoItem['type'] === 'todo') {
				this.onDoneUndoneClicked(this.selectedToDoItem, this.selectedTodoValue, this.selectedTodoIndex);
			}
		}
	}

	/**
	 * Function to update project status;
	 */
	updateProjectStatus(status) {
		if (status !== this.projectDetails.status) {
			const obj = {
				channel: this.projectDetails.channel ? this.projectDetails.channel.uid : '',
				endDate: this.projectDetails.endDate,
				name: this.projectDetails.name,
				startDate: this.projectDetails.startDate,
				status: status,
			};

			this.loadingGif = 'block';
			this.networkService.updateProject(obj, this.network.uid, this.projectDetails.uid).subscribe(
				(data) => {
					this.projectDetails.status = status;
					this.onClickUpdateProjectTeam(this.projectDetails);
					this.loadingGif = 'none';
				},
				(err) => {
					this.loadingGif = 'none';
				}
			);
		}
	}

	/**
	 * Function to update project team status, to show the post create form
	 */
	onClickUpdateProjectTeam(projectDetails) {
		this.postType = 'post';
		this.layoutService.showBd('post');
		this.showPostCreateForm = true;
		setTimeout(() => {
			this.postComponent.model.title = this.projectDetails.name + ' ' + this.translate.instant('status update.');
			this.postComponent.projectUpdatePost = true;
			this.postComponent.selectedPostTypeLabel = 'Project Update';
			this.postComponent.privacy.onChannelChange(this.projectDetails.channel ? this.projectDetails.channel : '');
		}, 50);
	}

	/**
	 * Function to check the current reaction is editable or not
	 */
	checkReactionIsEditable(task) {
		if (this.currentUser) {
			if (
				(task['assignedUser'] &&
					(task['assignedUser'].username === this.currentUser.username || (task['author'] && task['author'].username === this.currentUser.username))) ||
				(task['author'] && task['author'].username === this.currentUser.username)
			) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	/**
	 * Function to edit project reaction
	 */
	public onEditReaction(task, parentTask): void {
		event.stopPropagation();
		if (this.checkReactionIsEditable(task)) {
			this.selectedItem = task;
			this.parentTask = parentTask ? parentTask : '';
			this.reaction = task['outcome'];
			this.showEditReactionDialog = 'block';
		}
	}

	/**
	 * Function to update project reaction
	 */
	onUpdateReaction() {
		this.updateAttachmentDetails(this.selectedItem);

		this.todoItem.title = this.selectedItem.title;
		this.todoItem['status'] = this.selectedItem.status;
		this.todoItem.priority = this.selectedItem.priority;
		this.todoItem.repeatCycle = this.selectedItem.repeatCycle;
		if (this.selectedItem.assignedUser && this.selectedItem.assignedUser.username) {
			this.todoItem['assignedUser'] = this.selectedItem.assignedUser.username;
		}
		if (this.selectedItem.repeatCycle === 'one_off') {
			const date = this.selectedItem.oneOff;
			const splicedDate = date.split(' ')[0];
			this.todoItem['oneOff'] = splicedDate;
		}
		if (this.selectedItem.repeatFreequency) {
			this.todoItem['repeatFreequency'] = this.selectedItem.repeatFreequency;
		}
		if (this.selectedItem['project'] && this.selectedItem['project']['uid']) {
			this.todoItem['project'] = this.selectedItem['project']['uid'];
		}
		if (this.selectedItem.numberOfOpens && this.selectedItem.numberOfOpens > 0) {
			this.todoItem['numberOfOpens'] = this.selectedItem.numberOfOpens;
		}

		this.todoItem['outcome'] = this.reaction;

		if (this.selectedItem.type === 'sub_task') {
			this.todoItem['parent_content_type'] = 'todo';
			this.todoItem['parent_content_uid'] = this.parentTask['uid'];
		}

		this.loadingGif = 'block';
		this.networkService.updateToDoItem(this.todoItem, this.selectedItem.uid).subscribe(
			(res) => {
				this.loadingGif = 'none';
				if (res) {
					if (res.status === 'closed') {
						this.mixPanelService.track('Completed Task', {
							workspace: this.network?.name || 'NA',
							appVersion: environment.version,
							language: this.translate?.currentLang,
							username: this.currentUser?.username || 'NA',
							name: this.currentUser?.name || 'NA',
							project: res?.project?.name || 'NA',
							date: moment().format('YYYY-MM-DD'),
						});

						this.segmentService.trackEvent('Completed Task', {
							workspace: this.network?.name || 'NA',
							appVersion: environment.version,
							language: this.translate?.currentLang,
							username: this.currentUser?.username || 'NA',
							name: this.currentUser?.name || 'NA',
							project: res?.project?.name || 'NA',
							date: moment().format('YYYY-MM-DD'),
						});
					}
					this.showEditReactionDialog = 'none';
					this.reaction = '';
					this.selectedItem['outcome'] = res['outcome'];
					delete this.todoItem['closedDate'];
					delete this.todoItem['project'];
					delete this.todoItem['repeatFreequency'];
					delete this.todoItem['oneOff'];
					delete this.todoItem['numberOfOpens'];
					delete this.todoItem['estimate'];
					delete this.todoItem['elapsed_time'];
					delete this.todoItem['outcome'];
					delete this.todoItem['parent_content_type'];
					delete this.todoItem['parent_content_uid'];
					this.parentTask = '';
				}
			},
			(err) => {
				this.loadingGif = 'none';
			}
		);
	}

	/**
	 * Function to cancel update project reaction
	 */
	onCancelReactionEdit() {
		this.showEditReactionDialog = 'none';
		this.reaction = '';
	}

	/**
	 * Function to duplicate task
	 * @param task
	 */
	onClickDuplicateTask(task, section, index) {
		this.loadingGif = 'block';
		this.postService.duplicateTask(task.uid).subscribe(
			(res) => {
				this.loadingGif = 'none';
				if (res) {
					const duplicateTask = res;
					if (section) {
						section['tasks'].splice(index + 1, 0, duplicateTask);
						this.checkUserMentionAndHashtagForSectionTasks(section);
					} else if (this.kanbenView === 'block') {
						this.openTasks.splice(0, 0, duplicateTask);
						this.openCount = this.openCount + 1;
						this.getTaskDuetime(this.openTasks);
						this.handleContents(this.openTasks);
						this.handleMentions(this.openTasks);
					} else {
						this.todoItems.splice(index + 1, 0, duplicateTask);
						this.checkUserMentionAndHashtagAndAlterBody();
					}
				}
			},
			(err) => {
				this.loadingGif = 'none';
			}
		);
	}

	showPostSlideShow(todo) {
		event.stopPropagation();
		this.selectedImages = todo.attachmentDetails.gallery;
	}
}
