import { timer } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { NprogressService } from './nprogress.service';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'bee-nprogress',
	templateUrl: './nprogress.component.html',
	styleUrls: ['./nprogress.component.scss'],
})
export class NprogressComponent implements OnInit {
	@Input() public progress = 0;

	constructor(private nprogressService: NprogressService) {}

	ngOnInit(): void {
		this.nprogressService.progressChanged.subscribe((value: number) => {
			this.progress = value;
		});
	}
}
