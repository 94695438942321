import { Component, Input, OnInit } from '@angular/core';
import { IImageAttachment } from 'projects/bee-attachment/src/lib/shared/models/image-attachment';
import { LightboxService } from 'projects/peer-core/src/lib/services/ui/lightbox.service';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'bee-image-preview',
	templateUrl: './image-preview.component.html',
	styleUrls: ['./image-preview.component.scss'],
})
export class ImagePreviewComponent implements OnInit {
	@Input() public images: IImageAttachment[] = [];
	@Input() public viewSize: 'sm' | 'md' | 'lg' = 'lg';

	constructor(private lightboxService: LightboxService) {}

	ngOnInit(): void {}

	public openGallery(index: number = 0): void {
		this.lightboxService.initLightbox(this.images, index);
	}
}
