import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ITodo, TaskStatus } from '@shared/models';
import { nonZeroAndValid } from '../task-timer-controls/task-timer-controls.component';
import { IReaction } from '../task-feedback/task-feedback.component';
import { Subscription } from 'rxjs';
import { ProjectService } from '@modules/project/services/project.service';
import { filter } from 'rxjs/operators';

@Component({
	selector: 'app-task-interaction-manager',
	templateUrl: './task-interaction-manager.component.html',
	styleUrls: ['./task-interaction-manager.component.scss']
})
export class TaskInteractionManagerComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
	@Output() public closeButtonClicked  = new EventEmitter<void>();
	@Output() public timeValuesCollected = new EventEmitter<{hour: number; minutes: number}>();
	@Output() public timeValuesWithReactionCollected = new EventEmitter<{hour: number; minutes: number; reaction: string}>();
	@Output() public reactionCollected = new EventEmitter<{reaction: string}>();
	@Output() public elapsedTimeCollected = new EventEmitter<{hour: number; minutes: number}>();
	@Output() public taskDetailsDrawerVisibility = new EventEmitter<ITodo>();

	@Input() public task: ITodo;
	@Input() public targetStatus: TaskStatus;


	public taskStatuses = TaskStatus;
	public isFormSubmitted = false;
	public timeForm: FormGroup;
	public label: string;

	public isTaskFeedbackView = false;
	public isTimeCollectionView = false;

	public showRequiredError = false;
	public requiredErrorMessage: string;

	public addNonZeroValidation = false;

	public selectedReaction: IReaction;

	private taskCloseSub: Subscription;

	public isLoading = false;

	public isCommentPopupView = false;

	public delay = (ms: number): Promise<void> => new Promise(resolve => setTimeout(resolve, ms));

	constructor(private fb: FormBuilder,  private projectService: ProjectService) {
		this.timeForm = this.fb.group({
			hourInputValue: ['00', [Validators.min(0), Validators.maxLength(3),Validators.pattern('^[0-9]*$')]],
			minuteInputValue: ['00',  [Validators.max(59), Validators.min(0), Validators.maxLength(2),Validators.pattern('^[0-9]*$')]]
		});
	}

	ngOnInit(): void {
		this.taskCloseSub = this.projectService.taskCompleted.pipe(filter(data => data.uid === this.task.uid)).subscribe(
			()=> {
				setTimeout(() => {
					this.isLoading = false;
					this.isTaskFeedbackView = true;
					this.isTimeCollectionView = false;
				}, 500);
			}
		);
	}

	ngOnChanges(changes: SimpleChanges): void {
		console.log(changes);
		if(changes.hasOwnProperty('task')){
			if(this.task?.uid){
				console.log(this.task.uid);
			}
		}
		if(changes.hasOwnProperty('targetStatus')){
			if(this.targetStatus === this.taskStatuses.in_progress){
				this.label = 'Estimate Time';
			}

			if(this.targetStatus === this.taskStatuses.closed){
				this.addNonZeroValidation = true;
				this.timeForm.setValidators(nonZeroAndValid('hourInputValue', 'minuteInputValue'));
				this.timeForm.updateValueAndValidity();

				// Update the validity of the form group when the value changes
				this.timeForm.get('hourInputValue').valueChanges.subscribe(() => {
					this.timeForm.updateValueAndValidity();
				  });

				  this.timeForm.get('minuteInputValue').valueChanges.subscribe(() => {
					this.timeForm.updateValueAndValidity();
				  });
				this.label = 'Time Spent';
			}
		}

	}

	ngAfterViewInit(): void {
		if(this.task && this.targetStatus){
			if(this.targetStatus === this.taskStatuses.in_progress){
				this.isTimeCollectionView = true;
				this.isTaskFeedbackView = false;
			}

			if(this.targetStatus === this.taskStatuses.closed){
				if(this.task.status === this.taskStatuses.paused || this.task.status === this.taskStatuses.in_progress){
					this.isTaskFeedbackView = true;
					this.isTimeCollectionView = false;
				}else if(this.task.status === this.taskStatuses.open){
					this.isTimeCollectionView = true;
					this.isTaskFeedbackView = false;
				}
			}
		}
	}

	public onCloseButtonClick(): void{
		this.closeButtonClicked.emit();
	}


	public onSubmit(): void {
		this.timeForm.updateValueAndValidity();
		this.isFormSubmitted = true;
		if(this.timeForm.valid){
			this.timeValuesCollected.emit(this.getCollectedTimeValues());
		}
	}

	public handleTaskCompletion(): void{
		if(this.isLoading){
			return;
		}
		this.timeForm.updateValueAndValidity();
		this.isFormSubmitted = true;

		if(this.timeForm.valid){
			const time = this.getCollectedTimeValues();
			if(Number(time.hour) === 0 && Number(time.minutes) === 0){
				this.showRequiredError = true;
				this.requiredErrorMessage = 'Please enter time spent';
			}else{
				this.showRequiredError = false;
				this.requiredErrorMessage = '';
				// this.isTimeCollectionView = true;
				this.elapsedTimeCollected.emit(({...time}));
				this.isLoading = true;
				setTimeout(() => {
					this.isLoading = false;
				}, 5000);
			}
		}
	}

	// public collectTimeAndShowFeedback(): void {
	// 	this.timeForm.updateValueAndValidity();
	// 	this.isFormSubmitted = true;
	// 	if(this.timeForm.valid){
	// 		const time = this.getCollectedTimeValues();
	// 		console.log(time.hour, time.minutes);
	// 		if(Number(time.hour) === 0 && Number(time.minutes) === 0){
	// 			this.showRequiredError = true;
	// 			this.requiredErrorMessage = 'Please enter time spent';
	// 		}else{
	// 			this.showRequiredError = false;
	// 			this.requiredErrorMessage = '';
	// 			setTimeout(() => {
	// 				this.isTaskFeedbackView = true;
	// 				this.isTimeCollectionView = false;
	// 			}, 500);
	// 		}
	// 	}
	// }

	public onCollectedFeedback(): void {
		// if(this.task.status === this.taskStatuses.open){
		// 	const timeValues = this.getCollectedTimeValues();
		// 	const selectedReaction = this.getSelectedFeedback();
		// 	this.timeValuesWithReactionCollected.emit(({...timeValues, reaction: selectedReaction}));
		// }else{
		// this.reactionCollected.emit({reaction: this.getSelectedFeedback()});
		// }
		this.reactionCollected.emit({reaction: this.getSelectedFeedback()});
	}

	public showTaskDetailsDrawer(): void{
		this.taskDetailsDrawerVisibility.emit(this.task);
	}

	private getCollectedTimeValues(): {hour: number; minutes: number}{
		const hour = Number(this.timeForm.get('hourInputValue').value);
		const minutes = Number(this.timeForm.get('minuteInputValue').value);
		return {hour, minutes};
	}

	public getSelectedFeedback(): string{
		return this.selectedReaction.name;
	}

	public setSelectedFeedback(reaction: IReaction): void {
		this.selectedReaction = reaction;
		this.reactionCollected.emit({reaction: this.getSelectedFeedback()});
	}

	// public handleTaskCloseFromOpen(targetStatus: TaskStatus): void{
	// 	this.targetStatus = targetStatus;
	// }

	// public handleTaskCloseFromPausedOrInProgress(targetStatus: TaskStatus): void{
	// 	this.targetStatus = targetStatus;
	// }

	ngOnDestroy(): void {
		this.taskCloseSub?.unsubscribe();
	}
}
