import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
	providedIn: 'root',
})
export class TabTitleUiService {
	constructor(private titleService: Title) {}

	public setTitle(newTitle: string): void {
		this.titleService.setTitle(newTitle);
	}

	/**
	 * Update tab notification count
	 *
	 * @param count message or notification count
	 * @returns void
	 */
	public updateTabNotificationCount(count: number): void {
		const currentTitle = this.titleService.getTitle();

		if (count === 0) {
			if (currentTitle.indexOf('(') !== -1 && currentTitle.indexOf(')') > 0) {
				this.titleService.setTitle(currentTitle.replace(/\(\d+\)/, '').trim());
			}

			return;
		}

		if (currentTitle.indexOf('(') !== -1 && currentTitle.indexOf(')') > 0) {
			this.titleService.setTitle(currentTitle.replace(/\(\d+\)/, `(${count})`).trim());
		} else {
			this.titleService.setTitle(`(${count}) ${currentTitle}`);
		}
	}
}
