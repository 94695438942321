import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NetworkService, LocationObj, LocationService, GeoLocationService } from '../shared';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-location',
	templateUrl: './location.component.html',
	styleUrls: ['./location.component.css'],
})
export class LocationComponent implements OnInit, OnDestroy {
	public currentUser;
	public network;
	public showSpin;
	public nextUrl;

	public locations: any;
	public lat: any;
	public lang: any;
	public errorMessage: string;
	public currentLocation: any = null;

	constructor(
		private locationService: LocationService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private networkService: NetworkService,
		private ref: ChangeDetectorRef,
		private geoLocationService: GeoLocationService
	) {
		window.onscroll = (): void => {
			const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
			const body = document.body,
				html = document.documentElement;
			const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
			const windowBottom = windowHeight + window.pageYOffset;
			if (windowBottom >= docHeight) {
				this.pagination();
			}
		};
		this.lang = null;
		this.lat = null;
	}

	ngOnInit(): void {
		this.networkService.currentNetwork.subscribe((network) => {
			this.network = network;
		});
	}

	ngOnDestroy(): void {
		this.locationService.locations = this.locations;
		this.locationService.nextUrl = this.nextUrl;
		this.locationService.scrollPosition = document.documentElement.scrollHeight;
	}

	public getLocations(): void {
		let locations = [];
		const self = this;
		const accuracy = { enableHighAccuracy: true };
		self.geoLocationService.getLocation(accuracy).subscribe(
			(position: any) => {
				self.currentLocation = position;
				self.networkService.getLocations(self.network.uid, position.coords).subscribe((data) => {
					locations = data.objects.map((loc) => new LocationObj(loc));
					self.locations = self.sortData(locations);
				});
				self.ref.detectChanges();
				// self.spinner.hide();
			},
			function (error) {
				self.errorMessage = error;
				self.ref.detectChanges();
				self.networkService.getLocations(self.network.uid, '').subscribe((data) => {
					locations = data.objects.map((loc) => new LocationObj(loc));
					self.locations = self.sortData(locations);
					// self.spinner.hide();
				});
			}
		);
	}

	private sortData(data: any): any {
		return data.sort(function (m1, m2) {
			if (m1.distance < m2.distance) {
				return -1;
			} else if (m1.distance > m2.distance) {
				return 1;
			} else {
				return 0;
			}
		});
	}
	public pagination(): void {
		if (this.nextUrl) {
			this.showSpin = true;
			this.locationService.paginate(this.nextUrl).subscribe((data) => {
				if (data.next) {
					this.nextUrl = data.next.split('alpha')[1];
				} else {
					this.nextUrl = '';
				}
				this.locations = this.locations.concat(this.sortData(data.objects).map((location) => new LocationObj(location)));
				this.showSpin = false;
			});
		}
	}
}
