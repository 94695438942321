import { Component, OnInit } from '@angular/core';
import { NetworkService } from '../../shared';
import { environment } from '../../../environments/environment';
import { Location } from '@angular/common';

@Component({
	selector: 'app-admin-authentication',
	templateUrl: './admin-authentication.component.html',
	styleUrls: ['./admin-authentication.component.css'],
})
export class AdminAuthenticationComponent implements OnInit {
	constructor(private networkService: NetworkService, private location: Location) {}
	public sso = {};
	// eslint-disable-next-line spellcheck/spell-checker
	public saml_redirect_url: string;
	public loadingGif = 'none';
	public sso_test_email;

	ngOnInit(): void {
		// eslint-disable-next-line spellcheck/spell-checker
		this.saml_redirect_url = environment.saml_redirect_url;
		this.loadingGif = 'block';

		this.networkService.getSSOConfigs().subscribe((res) => {
			if (res.count) {
				this.sso = res['results'][0];
			}
			this.loadingGif = 'none';
		});
	}

	public createSSO(): void {
		this.loadingGif = 'block';

		this.networkService.createSSOConfigs(this.sso).subscribe((res) => {
			// pass
			this.loadingGif = 'none';
			this.sso = res['results'][0];
		});
	}

	public updateSSO(): void {
		this.loadingGif = 'block';
		const data = this.sso;
		this.networkService.updateSSOConfigs(data, this.sso['id']).subscribe(() => {
			// pass
			this.loadingGif = 'none';
		});
	}

	public deleteSSO(): void {
		this.loadingGif = 'block';
		this.networkService.deleteSSOConfigs(this.sso['id']).subscribe(() => {
			// pass
			this.loadingGif = 'none';
			this.sso = {};
		});
	}

	public testSSO(): void {
		alert('Not implemented yet');
	}

	public onClickBack(): void {
		this.location.back();
	}
}
