import { DatePipe } from '@angular/common';

export class Event {
	public uid: number;
	public author: any;
	public title: '';
	public contentType: string;
	public contentUid: string;
	public channel: any;
	public myRelations: any;
	public state: string;
	public commentable: boolean;
	public commentCount: number;
	public roleDetails: any;
	public tag: any;
	public images: any;
	public phone: '';
	public resourceUri: string;
	public created: any;
	// eslint-disable-next-line spellcheck/spell-checker
	public longiude: any;
	public latitude: any;
	public privacy: any;
	public shareable: any;
	public likeCount: number;
	public show: boolean;
	public attendingUsers: any;
	public category: string;
	public body: '';
	public email: '';
	public starts: any;
	public ends: '';
	public venueName: '';
	public cost: '';
	public gallery: any;
	public galleryCount: number;
	public goingUsers: number;
	public maybeUsers: number;
	public notGoingUsers: number;
	public rsvpCount: number;
	public rsvpMaybeCount: number;
	public rsvpNoCount: number;
	public rsvpYesCount: number;
	public startDate: string;
	public startTime: string;

	constructor(event: any) {
		for (const key of Object.keys(event)) {
			if (key in event) {
				this[key] = event[key];
			}
		}
		this.show = true;
		const datePipe = new DatePipe('en-US');
		this.startDate = datePipe.transform(this.starts, 'MMM d');
		this.startTime = datePipe.transform(this.starts, 'EE h:mm a');
		this.starts = new Date(this.starts);
	}
}
