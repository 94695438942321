import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { UserService, NetworkService, NotificationService, SocketIoService, PaginationService } from '../shared';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchService } from '../shared/services/search.service';

@Component({
	selector: 'app-network-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss'],
})
export class NetworkNotificationComponent implements OnInit, AfterViewInit, OnDestroy {
	public isFetching = 0;
	public myTeamUid: any;
	public showMyTeam = false;
	public filter = { value: '' };
	public isStartDay = true;
	public isEndDay = true;
	public displayModal = 'none';
	public modelText;
	public nextUrl;
	public notifications: any[] = [];
	public network;
	public showSpin;
	public channelMembershipList;
	public categoryFilters;
	public selectedFilter = '';
	public memberType;
	public departments = [];
	public externalUserChannel: any;
	public isExternalUser = false;
	private isFirstSearch = true;
	public searchString = '';
	public showPaginationPreloader = false;
	public paginating = false;
	public paginateSub$: any;
	public channels;
	public hideSingleRole = false;

	constructor(
		private notificationService: NotificationService,
		private activatedRoute: ActivatedRoute,
		private networkService: NetworkService,
		private userService: UserService,
		private router: Router,
		private searchService: SearchService,
		private socketIoService: SocketIoService,
		private paginationService: PaginationService
	) {
		this.paginationService.scrollDistance = 8;
		this.paginateSub$ = this.paginationService.initiateScroll.subscribe((data) => {
			if (data && !this.paginating) {
				this.paginate();
			}
		});
		this.notifications = this.notificationService.notifications;
		this.selectedFilter = this.notificationService.selectedFilter;
		this.nextUrl = this.notificationService.nextUrl;
	}

	ngOnInit(): void {
		if (localStorage.getItem('networkExpired')) {
			this.router.navigateByUrl('/expired');
		}

		this.searchService.resetSearchValue();
		this.categoryFilters = ['Buy & Sell', 'events', 'recommendation', 'messages', 'Crime & Safety', 'general', 'roommate'];
		this.networkService.currentNetwork.subscribe((network) => {
			this.network = network;
			if (network['membership']) {
				this.checkForStartDayEndDay(network['membership']);
			}
			if (network.roles && network.roles.length > 0) {
				const roles = network.roles;
				const index = roles.findIndex((role) => role.slug === 'external');
				if (index > -1) {
					roles.splice(index, 1);
				}
				if (roles.length === 1) {
					this.hideSingleRole = true;
				}
			}

			this.checkIfManager();

			if (network['membership'] && network['membership']['memberRole'] && network['membership']['memberRole']['role']) {
				const memberRole = network['membership']['memberRole']['role'];
				if (memberRole.slug === 'external') {
					this.isExternalUser = true;
					if (network.channels.length > 0) {
						const index = network.channels.findIndex((channel) => channel.access === 4);
						this.externalUserChannel = network.channels[index];
					}
				}
			}

			if (this.network.channels && this.network.channels.length > 0) {
				this.channels = this.network.channels;
			}
			if (network.membership && network.membership.memberRole) {
				this.memberType = network['membership']['memberType'];
			}

			this.networkService.departmentsReady.subscribe((departmentsLoaded) => {
				if (departmentsLoaded) {
					this.networkService.currentDepartments.subscribe((departments) => {
						if (departments && departments['objects'].length > 0) {
							this.departments = departments.objects;
						}
					});
				}
			});
		});

		if (this.notifications.length === 0) {
			this.getNotifications();
		} else {
			this.updateNotificationStatus();
		}

		this.searchService.searchValue.subscribe((value) => {
			this.searchString = value;
			if (this.isFirstSearch && value.trim()) {
				this.isFirstSearch = false;
			}
			if (!this.isFirstSearch) {
				this.filterNotifications('', value);
			}
		});
	}

	ngOnDestroy(): void {
		this.paginationService.scrollDistance = 5;
		this.notificationService.scrollPosition = document.documentElement.scrollHeight;
		if (this.paginateSub$) {
			this.paginateSub$.unsubscribe();
		}
	}

	ngAfterViewInit(): void {
		if (this.notificationService.scrollPosition) {
			window.scroll(0, this.notificationService.scrollPosition);
		}
		const self = this;

		this.socketIoService.getSocket().on('notifications', (data) => {
			const d = JSON.parse(data);
			self.socketIoService.getSocket().emit('getnotification', {
				networkId: d.networkId,
				notificationId: d.notificationId,
				username: self.userService.getCurrentUser()['username'],
			});
		});

		this.socketIoService.getSocket().on('new-notification', (message) => {
			const d = JSON.parse(message);
			if (d.hasOwnProperty('uid')) {
				const i = self.notificationService.notifications.findIndex((notification) => notification['uid'] === d['uid']);
				if (i === -1) {
					self.notificationService.notifications.unshift(d);
				}
			}
		});

		// Hack: Scrolls to top of Page after page view initialized
		let top = document.getElementById('top');
		if (top !== null) {
			top.scrollIntoView();
			top = null;
		}
	}

	private getNotifications(): void {
		this.isFetching = 0;
		this.notifications = [];
		this.notificationService.getNotifications(this.network.uid).subscribe((notificationData) => {
			if (notificationData.next) {
				this.nextUrl = notificationData.next.split('alpha')[1];
				this.paginate();
			} else {
				this.nextUrl = '';
			}

			this.notifications = notificationData.objects;
			this.notificationService.notifications = notificationData.objects;
			this.updateNotificationStatus();
			this.isFetching = 1;
		});
	}

	public filterNotifications(filter = '', q = ''): void {
		if (this.selectedFilter === filter) {
			this.selectedFilter = '';
			this.notificationService.selectedFilter = '';
		} else {
			this.filter.value = '';
			this.selectedFilter = filter;
			this.notificationService.selectedFilter = filter;
		}
		this.notifications = [];
		this.isFetching = 0;
		this.notificationService.getNotifications(this.network.uid, filter, q).subscribe(
			(notificationData) => {
				if (notificationData.next) {
					this.nextUrl = notificationData.next.split('alpha')[1];
					this.notificationService.nextUrl = notificationData.next.split('alpha')[1];
				} else {
					this.nextUrl = '';
					this.notificationService.nextUrl = '';
				}
				this.notifications = notificationData.objects;
				this.notificationService.notifications = notificationData.objects;
				this.updateNotificationStatus();
				this.isFetching = 1;
			},
			() => {
				this.isFetching = 1;
				this.notifications = [];
			}
		);
	}

	public filterActions(value): void {
		this.filter = { value: value };
	}

	public paginate(): void {
		if (this.nextUrl) {
			this.paginating = true;
			this.showSpin = true;
			this.notificationService.paginate(this.nextUrl).subscribe((notificationData) => {
				this.showSpin = false;
				this.paginating = false;
				if (notificationData.next) {
					this.nextUrl = notificationData.next.split('alpha')[1];
					this.notificationService.nextUrl = notificationData.next.split('alpha')[1];
				} else {
					this.nextUrl = '';
					this.notificationService.nextUrl = '';
				}
				this.notifications = this.notifications.concat(notificationData.objects);

				this.updateNotificationStatus();
				this.notificationService.notifications = this.notifications;
				this.showSpin = false;
			});
		}
	}

	public loadDetailPage(notification): void {
		this.notificationService.setLastNotificationReadTimeBasedOnNetwork(notification);

		if (notification.contentType === null || notification.parentType === null) {
			return;
		}

		if (notification.contentType === 'folder') {
			this.router.navigateByUrl('/network/collection?id=' + notification.contentUid);
		} else if (
			// eslint-disable-next-line spellcheck/spell-checker
			(notification.contentType === 'post' || notification.contentType === 'comment' || notification.contentType === 'foldercontents') &&
			notification.parentType !== 'Issue'
		) {
			if (notification.contentType === 'comment') {
				this.router.navigateByUrl('/posts/' + notification.parentUid + '?comment_id=' + notification.contentUid + '&parentComment=' + notification.parentCommentUid);
			} else {
				this.router.navigateByUrl('/posts/' + notification.postUid);
			}
		} else if (notification.contentType === 'issue') {
			this.router.navigateByUrl('/network/issue-detail?id=' + notification.contentUid);
		} else if (notification.parentType && notification.parentType === 'Issue') {
			this.router.navigateByUrl('/network/issue-detail?id=' + notification.parentUid);
		} else if (notification.contentType === 'todolist') {
			this.router.navigateByUrl(`/project/team/${notification.contentUid}`);
		} else if (notification.contentType === 'todo') {
			this.router.navigateByUrl('/project/task-detail/', notification.contentUid);
			// eslint-disable-next-line spellcheck/spell-checker
		} else if (notification.contentType === 'channelmember' && !this.isExternalUser) {
			this.router.navigateByUrl('/network/view-channel?id=' + notification.channel.uid);
		} else if (notification.contentType === 'StatusUpdate') {
			if (notification.slug === 'forgot-start-day') {
				if (this.isStartDay) {
					this.networkService.todoAction = 'start';
					this.router.navigateByUrl('/network');
				} else {
					this.modelText = 'You already have taken start day action';
					this.displayModal = 'block';
				}
			}

			if (notification.slug === 'forgot-end-day') {
				if (this.isEndDay) {
					this.networkService.todoAction = 'end';
					this.router.navigateByUrl('/network');
				} else {
					this.modelText = 'You have already taken end day action';
					this.displayModal = 'block';
				}
			}
		} else if (notification.contentType === 'TaskUpdate') {
			if (notification.slug === 'create-todo-tasks') {
				this.router.navigateByUrl('/network/todolist');
			}
			if (notification.slug === 'congrats-end-day') {
				this.router.navigateByUrl('/project/my-tasks');
			}
			if (notification.slug === 'pending-tasks-complete') {
				this.router.navigateByUrl('/project/my-tasks');
			}
		}
	}

	private updateNotificationStatus(): void {
		this.notifications.forEach((notification) => {
			this.notificationService.changeNotificationReadStatus(notification);
			const html = `<span class="text-danger">${this.searchString}</span>`;
			notification.title.replace(this.searchString, html);
			notification.body.replace(this.searchString, html);
		});
	}

	/**
	 * Function  to check if user has manager cp
	 * 1. Check if local storage contains manager
	 * 2. Set manager uid and display my team filter
	 */
	private checkIfManager(): void {
		if (localStorage.getItem('manager')) {
			const obj = JSON.parse(localStorage.getItem('manager'));
			this.showMyTeam = true;
			this.myTeamUid = obj.uid;
		}
	}

	/**
	 * Function to check for start/end day actions in membership
	 * @param membership
	 */
	private checkForStartDayEndDay(membership): void {
		if (membership['startDay']) {
			this.isStartDay = false;
		}

		if (membership['endDay']) {
			this.isEndDay = false;
		}
	}
}
