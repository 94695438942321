import { Component, OnInit, ViewChild } from '@angular/core';
import { CollectionsService, PrivacyComponent, UserService, NetworkService, LayoutService } from '../../shared';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';
import { MixPanelService } from '@core/services/third-party/mix-panel.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { SegmentAnalyticsService } from '@core/services/third-party/segment-analytics.service';

@Component({
	selector: 'app-create-collection',
	templateUrl: './create-collection.component.html',
	styleUrls: ['./create-collection.component.css'],
})
export class CreateCollectionComponent implements OnInit {
	@ViewChild(PrivacyComponent, { static: true }) public privacy;
	public model = { channel: '' };
	public role;
	public roles;
	public network;
	public postUid;
	public defaultCp;
	public membershipFields;
	public networkId;
	public currentNetworkDefaultCp;
	public privacy_setting = 'none';
	public custom_privacy = 'none';
	public members = null;
	public user;
	public forNames;
	public totalMembersCount = null;
	public collectionId: any;
	public type = 'create-collection';
	public departments = [];
	public personalFilters = [];
	public SubscriptionTemplates = [];
	public collection = {
		name: '',
		description: '',
		collaboration: true,
		state: 'active',
	};
	public error: string;
	public externalUserChannel: any;
	public isExternalUser = false;
	public multipleExternalUser = false;

	private lastPrivacy = localStorage.getItem('lastPrivacy') ? JSON.parse(localStorage.getItem('lastPrivacy')) : null;

	constructor(
		private userService: UserService,
		private networkService: NetworkService,
		private collectionService: CollectionsService,
		private router: Router,
		private _location: Location,
		private activatedRoute: ActivatedRoute,
		private translate: TranslateService,
		private layoutService: LayoutService,
		private pageLoadingUiService: PageLoadingUiService,
		private mixPanelService: MixPanelService,
		private segmentService: SegmentAnalyticsService
	) {}

	ngOnInit(): void {
		this.activatedRoute.queryParams.subscribe((params) => {
			if (params['postUid']) {
				this.postUid = params['postUid'];
			}
			if (params['issueUid']) {
				this.collectionId = params['issueUid'];
			}
		});

		if (localStorage.getItem('network_id')) {
			this.networkId = localStorage.getItem('network_id');
		}

		this.user = this.userService.getCurrentUser();
		this.network = this.networkService.getCurrentNetwork();
		this.role = this.network.membership.memberRole.role;
		const regex = new RegExp(/^\/network\/edit-collection\/[a-zA-Z0-9_.-]*$/);
		const issueRegex = new RegExp(/^\/network\/issue-edit\?.*$/);
		this.networkService.currentNetwork.subscribe((data) => {
			if (data.membership && data.membership.memberRole) {
				data.membership.memberRole.fields.forEach(() => {
					this.networkService.departmentsReady.subscribe((departmentsLoaded) => {
						if (departmentsLoaded) {
							this.networkService.currentDepartments.subscribe((departments) => {
								this.departments = departments.objects;
							});
						}
					});
				});
				data.membership.memberRole.fields.forEach((value) => {
					if (value.CP === true) {
						this.personalFilters.push(value);
					}
				});
				this.roles = data['roles'];
			}
		});

		if (this.network.SubscriptionTemplates) {
			this.SubscriptionTemplates = this.network.SubscriptionTemplates;
		}

		if (this.network['membership']['memberRole'] && this.network['membership']['memberRole']['role']) {
			const memberRole = this.network['membership']['memberRole']['role'];
			if (memberRole.slug === 'external') {
				if (this.network.channels.length > 0) {
					const index = this.network.channels.findIndex((channel) => channel.access === 4);
					this.externalUserChannel = this.network.channels[index];
					this.forNames = this.externalUserChannel['name'];
				}
				if (this.network.channels && this.network.channels.length > 0) {
					const externalChannels = [];
					this.network.channels.forEach((channel) => {
						if (channel['access'] === 4) {
							externalChannels.push(channel);
						}
					});
					if (externalChannels.length > 1) {
						this.isExternalUser = true;
						this.multipleExternalUser = true;
					} else {
						this.isExternalUser = true;
					}
				}
			}
		}

		if ((!this.isExternalUser && !this.multipleExternalUser) || (this.isExternalUser && this.multipleExternalUser)) {
			this.updateSelectionFields();
		}

		if (!regex.test(this.router.url) && !issueRegex.test(this.router.url)) {
			if (this.network.membership.memberRole && this.network.membership.memberRole.defaultContentPrivacy) {
				this.defaultCp = this.network['membership']['memberRole']['defaultContentPrivacy'];
				this.membershipFields = this.network['membership']['memberRole']['fields'];
				// this.membershipFields.forEach((field) => {
				//   if (!this.lastPrivacy) {
				//     if (field.key === this.defaultCp) {
				//       if (!localStorage.getItem(`${this.networkId}`)) {
				//         this.forNames = field.valueLabel;
				//         this.privacy['personalFilter'] = field;
				//         this.privacy.role = '';
				//         this.privacy.previousSelection = field.valueLabel;
				//         this.privacy.currentSelection = field.valueLabel;
				//         this.privacy.personalFilterValue = field.valueLabel;
				//       }
				//     }
				//   }
				// });
			} else {
				if (!localStorage.getItem(`${this.networkId}`)) {
					this.forNames = this.role['name'];
				}
			}
			this.privacy.isPrivate = false;
		} else if (regex.test(this.router.url)) {
			this.type = 'edit-collection';
			this.activatedRoute.params.subscribe((params: Params) => {
				this.collectionId = params['id'];
				this.pageLoadingUiService.setPageLoading(true);
				this.collectionService.getCollectionDetail(this.collectionId, false).subscribe((data) => {
					this.pageLoadingUiService.setPageLoading(false);
					if (data) {
						this.collection['name'] = data['name'];
						this.collection['description'] = data['description'];
						this.collection['collaboration'] = data['collaboration'];
						this.role = '';
						if (data['contentPrivacy']) {
							this.privacy['content_privacy'] = data['contentPrivacy'];
							if (data['contentPrivacy'].department) {
								const index = this.departments.findIndex((department) => department.uid === data['contentPrivacy'].department);
								if (index > -1) {
									this.privacy['selectedDepartment'] = this.departments[index];
								}
							} else {
								this.privacy['selectedDepartment'] = null;
							}
							if (data['contentPrivacy'].role) {
								const index = this.roles.findIndex((role) => role.name === data['contentPrivacy'].role);
								this.privacy['role'] = this.roles[index];
							} else {
								this.privacy['role'] = null;
							}

							if (!data['contentPrivacy'].role && !data['contentPrivacy'].department) {
								const field = Object.keys(data['contentPrivacy']);
								this.personalFilters.forEach((element) => {
									if (element.key === field[0] && field[0] !== 'manager') {
										this.privacy['personalFilter'] = element;
									}
								});
								if (data['contentPrivacy'].status) {
									this.privacy['selectByStatusValue'] = data['contentPrivacy'];
								} else if (data['contentPrivacy'].renewalStatus) {
									this.privacy['selectByRenewalValue'] = data['contentPrivacy'];
								} else if (data['contentPrivacy'].membership_level) {
									this.privacy['selectByLevelValue'] = data['contentPrivacy'];
								} else if (data['contentPrivacy'].manager) {
									if (this.privacy.myTeam && this.privacy.myTeam.key) {
										this.privacy['personalFilter'] = this.privacy.myTeam;
									}
								}
							}
						} else {
							this.privacy['content_privacy'] = null;
							this.privacy['selectedDepartment'] = null;
							this.privacy['role'] = null;
						}
						if (data['customPrivacy']) {
							this.privacy['custom_privacy'] = data['customPrivacy'];
						} else {
							this.privacy['custom_privacy'] = [];
						}
						if (data['channel']) {
							this.model['channel'] = data['channel'].uid;
							this.privacy.selectedChannel = data['channel'].name;
						} else {
							this.model['channel'] = null;
							this.privacy.selectedChannel = 'All';
						}
						if ((!this.isExternalUser && !this.multipleExternalUser) || (this.isExternalUser && this.multipleExternalUser)) {
							this.updatePrivacyStringBasedOnCollection(data);
							this.updatePrivacyValues(true);
						}
					}
				});
			});
		} else if (issueRegex.test(this.router.url)) {
			this.type = 'issue-edit';
			this.pageLoadingUiService.setPageLoading(true);
			this.collectionService.getCollectionDetail(this.collectionId, false).subscribe((data) => {
				this.pageLoadingUiService.setPageLoading(false);
				if (data) {
					this.collection['name'] = data['name'];
					this.collection['description'] = data['description'];
					this.collection['collaboration'] = data['collaboration'];
					this.role = '';

					if (data['contentPrivacy']) {
						this.privacy['content_privacy'] = data['contentPrivacy'];
						if (data['contentPrivacy'].department) {
							const index = this.departments.findIndex((department) => department.value === data['contentPrivacy'].department);
							if (index > -1) {
								this.privacy['selectedDepartment'] = this.departments[index];
							}
						} else {
							this.privacy['selectedDepartment'] = null;
						}
						if (data['contentPrivacy'].role) {
							const index = this.roles.findIndex((role) => role.name === data['contentPrivacy'].role);
							this.privacy['role'] = this.roles[index];
						} else {
							this.privacy['role'] = null;
						}

						if (!data['contentPrivacy'].role && !data['contentPrivacy'].department) {
							const field = Object.keys(data['contentPrivacy']);
							this.personalFilters.forEach((element) => {
								if (element.key === field[0]) {
									this.privacy['personalFilter'] = element;
								}
							});
							if (data['contentPrivacy'].status) {
								this.privacy['selectByStatusValue'] = data['contentPrivacy'];
							} else if (data['contentPrivacy'].renewalStatus) {
								this.privacy['selectByRenewalValue'] = data['contentPrivacy'];
							} else if (data['contentPrivacy'].membership_level) {
								this.privacy['selectByLevelValue'] = data['contentPrivacy'];
							}
						}
					} else {
						this.privacy['content_privacy'] = null;
						this.privacy['selectedDepartment'] = null;
						this.privacy['role'] = null;
					}
					if (data['customPrivacy']) {
						this.privacy['custom_privacy'] = data['customPrivacy'];
					} else {
						this.privacy['custom_privacy'] = [];
					}
					if (data['channel']) {
						this.model['channel'] = data['channel'].uid;
						this.privacy.selectedSubCircle = data['channel'].name;
						this.privacy.channel = data['channel'];
					} else {
						this.model['channel'] = null;
						this.privacy.selectedSubCircle = 'All';
					}
					this.updatePrivacyStringBasedOnCollection(data);
					this.updatePrivacyValues(true);
				}
			});
		}

		this.privacy.privacyModalStatus.subscribe((data) => {
			if (data === 'closed') {
				this.layoutService.hideBd();
				this.updatePrivacyValues();
			}
		});
	}

	public onPrivacyClick(): void {
		if ((!this.isExternalUser && !this.multipleExternalUser) || (this.isExternalUser && this.multipleExternalUser)) {
			this.privacy.showPrivacy(this.type);
			this.privacy.privacy_settings = 'block';
		}
	}

	public onCreate(): void {
		if (!this.collection['name']) {
			this.error = this.translate.instant('Collection name is required');
		} else {
			this.pageLoadingUiService.setPageLoading(true);
			if ((!this.isExternalUser && !this.multipleExternalUser) || (this.isExternalUser && this.multipleExternalUser)) {
				this.updateSelectionFields();
				this.updatePrivacyValues();
			} else {
				this.collection['channel'] = this.externalUserChannel['uid'];
			}
			this.collectionService.createCollection(this.collection).subscribe((data) => {
				this.mixPanelService.track('Created Collections', {
					workspace: this.network.name || 'NA',
					appVersion: environment.version,
					language: this.translate.currentLang,
					collectionName: this.collection.name,
					username: this.user.name || 'NA',
					name: this.user.name || 'NA',
					date: moment().format('YYYY-MM-DD'),
				});

				this.segmentService.trackEvent('Created Collections', {
					workspace: this.network.name || 'NA',
					appVersion: environment.version,
					language: this.translate.currentLang,
					collectionName: this.collection.name,
					username: this.user.username || 'NA',
					name: this.user.name || 'NA',
					date: moment().format('YYYY-MM-DD'),
				});

				if (data && !this.postUid) {
					this.pageLoadingUiService.setPageLoading(false);
					this.router.navigate(['/network/collections']);
				} else {
					this.pageLoadingUiService.setPageLoading(true);
					this.collectionService.addToCollection(data.uid, this.postUid).subscribe((item) => {
						this.mixPanelService.track('Created Collections', {
							workspace: this.network.name || 'NA',
							appVersion: environment.version,
							language: this.translate.currentLang,
							username: this.user?.username || 'NA',
							name: this.user?.name || 'NA',
							date: moment().format('YYYY-MM-DD'),
						});

						this.segmentService.trackEvent('Created Collections', {
							workspace: this.network.name || 'NA',
							appVersion: environment.version,
							language: this.translate.currentLang,
							username: this.user?.username || 'NA',
							name: this.user?.name || 'NA',
							date: moment().format('YYYY-MM-DD'),
						});
						this.postUid = '';
						this.collectionService.newCollection.uid = item.uid;
						this.collectionService.newCollection.name = item.name;
						this.collectionService.postAddedToCollection = true;
						this.collectionService.populateCollection();
						this._location.back();
						this.pageLoadingUiService.setPageLoading(false);
					});
				}
			});
		}
	}

	public onUpdateCollection(): void {
		if (this.collectionId) {
			if (!this.collection['name']) {
				this.error = 'Please enter name of the collection';
			} else {
				if ((!this.isExternalUser && !this.multipleExternalUser) || (this.isExternalUser && this.multipleExternalUser)) {
					this.updatePrivacyValues();
				} else {
					this.collection['channel'] = this.externalUserChannel['uid'];
				}
				this.pageLoadingUiService.setPageLoading(true);
				this.collectionService.updateCollection(this.collectionId, this.collection).subscribe(() => {
					this._location.back();
					this.pageLoadingUiService.setPageLoading(false);
				});
			}
		}
	}

	/** ** function to update previos and current selection values in privacy */
	private updateSelectionFields(): void {
		if (localStorage.getItem(`${this.networkId}`)) {
			const retrievedCustomPrivacy = JSON.parse(localStorage.getItem(`${this.networkId}`));

			if (retrievedCustomPrivacy.privacyType === 'role') {
				this.privacy['selectedRole'] = retrievedCustomPrivacy;
				this.forNames = retrievedCustomPrivacy.name;
				this.privacy.previousSelection = retrievedCustomPrivacy.name;
				this.privacy.currentSelection = retrievedCustomPrivacy.name;
			}
			if (retrievedCustomPrivacy.privacyType === 'department') {
				this.privacy['selectedDepartment'] = retrievedCustomPrivacy;
				if (retrievedCustomPrivacy.valueLabel) {
					this.privacy.previousSelection = retrievedCustomPrivacy.valueLabel;
					this.privacy.currentSelection = retrievedCustomPrivacy.valueLabel;
					this.forNames = retrievedCustomPrivacy.valueLabel;
				}
				if (retrievedCustomPrivacy.name && retrievedCustomPrivacy.uid) {
					this.privacy.previousSelection = retrievedCustomPrivacy.name;
					this.privacy.currentSelection = retrievedCustomPrivacy.name;
					this.forNames = retrievedCustomPrivacy.name;
				}
			}
			if (retrievedCustomPrivacy.privacyType === 'channel' || retrievedCustomPrivacy.privacyType === 'subCircle') {
				this.model['channel'] = retrievedCustomPrivacy.uid;
				this.privacy.channel = retrievedCustomPrivacy;
				this.forNames = retrievedCustomPrivacy.name;
				this.privacy.previousSelection = retrievedCustomPrivacy.name;
				this.privacy.currentSelection = retrievedCustomPrivacy.name;
			}
			if (retrievedCustomPrivacy.privacyType === 'personalFilter') {
				this.privacy['personalFilter'] = retrievedCustomPrivacy;
				this.forNames = retrievedCustomPrivacy.valueLabel;
				this.privacy.previousSelection = retrievedCustomPrivacy.valueLabel;
				this.privacy.currentSelection = retrievedCustomPrivacy.valueLabel;
			}

			if (Array.isArray(retrievedCustomPrivacy)) {
				this.privacy['custom_privacy'] = retrievedCustomPrivacy;
				retrievedCustomPrivacy.forEach(() => {
					const membersList = [];
					for (const u of this.privacy['custom_privacy']) {
						if (u.user && u.user.firstName) {
							membersList.push(u.user.firstName);
						} else if (u.firstName) {
							membersList.push(u.firstName);
						} else if (u.name) {
							membersList.push(u.name);
						} else if (u.username) {
							membersList.push(u.username);
						}
					}
					this.privacy.previousSelection = membersList.join(', ');
					this.privacy.currentSelection = membersList.join(', ');
					this.forNames = membersList.join(', ');
				});
			}
			if (retrievedCustomPrivacy === 'Everyone') {
				this.forNames = this.translate.instant('Everyone');
				this.privacy.previousSelection = this.translate.instant('Everyone');
				this.privacy.currentSelection = this.translate.instant('Everyone');
			}
		} else {
			this.forNames = 'Everyone';
			this.privacy.previousSelection = 'Everyone';
			this.privacy.currentSelection = 'Everyone';
		}
	}

	private updatePrivacyStringBasedOnCollection(collection): void {
		this.forNames = '';
		if (collection['customPrivacy']) {
			collection['customPrivacy'].forEach((cp, index) => {
				if (cp.username) {
					this.forNames = `${this.forNames}${index > 0 ? ', ' : ' '}${cp.username}`;
				} else if (cp.name) {
					this.forNames = `${this.forNames}${index > 0 ? ', ' : ' '}${cp.name}`;
				}
			});
		} else if (collection['contentPrivacy']) {
			if (collection['contentPrivacy'].role && collection['contentPrivacy'].department) {
				const index = this.departments.findIndex((department) => department.uid === collection['contentPrivacy'].department);
				if (index > -1) {
					this.forNames = this.departments[index]['name'] + ' , ' + collection['contentPrivacy'].role;
				}
			} else if (collection['contentPrivacy'].role) {
				this.forNames = collection['contentPrivacy'].role;
			} else if (collection['contentPrivacy'].department) {
				const index = this.departments.findIndex((department) => department.uid === collection['contentPrivacy'].department);
				if (index > -1) {
					this.forNames = this.departments[index]['name'];
				}
			} else if (!collection['contentPrivacy'].role || !collection['contentPrivacy'].department) {
				const field = Object.keys(collection['contentPrivacy']);
				this.personalFilters.forEach((element) => {
					if (element.key === field[0] && field[0] !== 'manager') {
						this.forNames = element.valueLabel;
					}
				});
				if (collection['contentPrivacy'].status) {
					this.forNames = collection['contentPrivacy'].status;
				} else if (collection['contentPrivacy'].renewalStatus) {
					this.forNames = collection['contentPrivacy'].renewalStatus;
				} else if (collection['contentPrivacy'].membership_level) {
					const index = this.SubscriptionTemplates.findIndex((val) => val.uid === collection['contentPrivacy'].membership_level);
					if (index > -1) {
						this.forNames = this.SubscriptionTemplates[index]['name'];
					}
				} else if (collection['contentPrivacy'].manager) {
					this.forNames = this.translate.instant('My Team');
				}
			}
		} else if (collection['channel'] && collection['channel']['uid']) {
			this.forNames = collection['channel'].name;
		} else {
			this.forNames = 'Everyone';
		}
	}

	public updatePrivacyStringBasedOnPrivacyComponent(): void {
		this.forNames = '';
		if (this.privacy.isPrivate) {
			this.forNames = this.user.name;
		} else if (this.privacy.custom_privacy && this.privacy.custom_privacy.length > 0) {
			const names = [];
			this.privacy.custom_privacy.forEach((user) => {
				if (user['user']) {
					names.push(user['user'].name);
				} else if (user['name']) {
					names.push(user['name']);
				} else {
					names.push(user.username);
				}
			});
			this.forNames = names.join(',');
		} else {
			if (this.privacy.selectedRole && this.privacy.selectedDepartment) {
				this.forNames = this.privacy.selectedRole.name + ', ' + this.privacy.selectedDepartment.valueLabel;
			} else if (this.privacy.selectedRole) {
				let roleName;
				if (this.privacy.selectedRole.name) {
					roleName = this.privacy.selectedRole.name;
				} else {
					roleName = this.privacy.selectedRole;
				}
				this.forNames = roleName;
			} else if (this.privacy.selectedDepartment) {
				let departmentName = '';
				if (this.privacy.selectedDepartment.valueLabel) {
					departmentName = this.privacy.selectedDepartment.valueLabel;
					this.forNames = departmentName;
				}
				if (this.privacy.selectedDepartment.name && this.privacy.selectedDepartment.uid) {
					departmentName = this.privacy.selectedDepartment.name;
					this.forNames = departmentName;
				}
			} else if (this.privacy.personalFilter) {
				let personalFilter = '';
				personalFilter = this.privacy.personalFilter.valueLabel;
				this.forNames = personalFilter;
			} else if (this.model['channel']) {
				this.collection['channel'] = this.model['channel'];
				const index = this.network['channels'].findIndex((network) => network['uid'] === this.model['channel']);
				if (index !== -1) {
					this.forNames = this.network['channels'][index].name;
				}
			} else if (this.privacy.selectByRenewalValue) {
				this.forNames = this.privacy.selectByRenewalValue[1];
			} else if (this.privacy.selectByLevelValue) {
				this.forNames = this.privacy.selectByLevelValue.name;
			} else if (this.privacy.selectByStatusValue) {
				this.forNames = this.privacy.selectByStatusValue[1];
			} else {
				this.forNames = 'Everyone';
			}
		}
	}

	/**
	 * Update collection object according to the detail from the privacy modal.
	 */
	private updatePrivacyValues(isEdit = false): void {
		this.collection['channel'] = '';
		if (this.privacy.isPrivate) {
			delete this.collection['content_privacy'];
			this.collection['collaboration'] = false;
			this.collection['custom_privacy'] = [];
			this.collection['custom_privacy'].push(this.user.userId);
			delete this.collection['collaboration'];
		} else if (this.privacy.custom_privacy && this.privacy.custom_privacy.length > 0) {
			delete this.collection['content_privacy'];
			this.collection['custom_privacy'] = [];
			const names = [];
			// -
			const newObject = this.privacy.custom_privacy;
			newObject.forEach((member) => {
				member.privacyType = 'customPrivacy';
			});
			localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			//
			this.privacy.custom_privacy.forEach((user) => {
				if (user['user']) {
					this.collection['custom_privacy'].push(user['user'].userId);
					names.push(user['user'].name);
				} else {
					this.collection['custom_privacy'].push(user.userId);
					names.push(user.username);
				}
			});
			if (isEdit) {
				this.privacy.showCustomPrivacy();
			}
			delete this.collection['collaboration'];
		} else {
			let roleString = null;
			let departmentString = null;
			let personalFilterString = null;
			let selectByStatusString = null;
			let selectByRenewalString = null;
			let selectByLevelString = null;

			if (!this.lastPrivacy) {
				this.lastPrivacy = {};
			}
			delete this.collection['custom_privacy'];
			if (this.privacy.selectedRole && this.privacy.selectedDepartment) {
				roleString = '"role": "' + this.privacy.selectedRole.slug + '"';
				departmentString = '"department": "' + this.privacy.selectedDepartment.value + '"';
				// this.forNames = this.privacy.role.name + ', ' + this.privacy.selectedDepartment.valueLabel;
			} else if (this.privacy.selectedRole) {
				roleString = '"role": "' + this.privacy.selectedRole.slug + '"';
				let roleName;
				if (this.privacy.selectedRole.name) {
					roleName = this.privacy.selectedRole.name;
				} else {
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					roleName = this.privacy.selectedRole;
				}
				// * ******************SPACE AFTER : ******************* */
				roleString = '"role": "' + this.privacy.selectedRole.slug + '"';
				// * ************************************** */
				const newObject = this.privacy.selectedRole;
				newObject.privacyType = 'role';
				localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			} else if (this.privacy.selectedDepartment) {
				if (this.privacy.selectedDepartment.value) {
					departmentString = '"department":"' + this.privacy.selectedDepartment.value + '"';
					// let departmentName = '';
					// departmentName = this.privacy.selectedDepartment.valueLabel;
					departmentString = '"department": "' + this.privacy.selectedDepartment.value + '"';
				}
				if (this.privacy.selectedDepartment.uid && this.privacy.selectedDepartment.name) {
					departmentString = '"department":"' + this.privacy.selectedDepartment.uid + '"';
					// let departmentName = '';
					// departmentName = this.privacy.selectedDepartment.name;
					departmentString = '"department": "' + this.privacy.selectedDepartment.uid + '"';
				}
				const newObject = this.privacy.selectedDepartment;
				newObject.privacyType = 'department';
				localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			} else if (this.privacy.personalFilter) {
				// let personalFilterName = '';
				// personalFilterName = this.privacy.personalFilter.valueLabel;
				personalFilterString = '"' + this.privacy.personalFilter.key + '": "' + this.privacy.personalFilter.value + '"';
				// -
				const newObject = this.privacy.personalFilter;
				newObject.privacyType = 'personalFilter';
				localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			} else if (this.privacy.selectByStatusValue) {
				selectByStatusString = '"status": "' + this.privacy.selectByStatusValue[0] + '"';
				const newObject = this.privacy.selectByStatusValue;
				if (newObject.includes('status') === false) {
					newObject.push('status');
				}
				localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			} else if (this.privacy.selectByRenewalValue) {
				selectByRenewalString = '"renewalStatus": "' + this.privacy.selectByRenewalValue[0] + '"';
				// -
				const newObject = this.privacy.selectByRenewalValue;
				if (newObject.includes('renewalFrequency') === false) {
					newObject.push('renewalFrequency');
				}
				localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			} else if (this.privacy.selectByLevelValue) {
				selectByLevelString = '"membership_level": "' + this.privacy.selectByLevelValue.uid + '"';
				// -
				const newObject = this.privacy.selectByLevelValue;
				newObject.privacyType = 'selectByLevel';
				localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			} else if (this.model['channel']) {
				this.collection['channel'] = this.model['channel'];
				// -
				const newObject = this.privacy.channel;
				newObject.privacyType = 'channel';
				localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			}
			if (
				this.privacy.custom_privacy.length === 0 &&
				!this.privacy.selectedRole &&
				!this.privacy.role &&
				!this.privacy.personalFilter &&
				!this.privacy.selectedDepartment &&
				!this.privacy.channel &&
				!this.model.channel
			) {
				localStorage.setItem(`${this.networkId}`, JSON.stringify('Everyone'));
			}

			if (roleString && departmentString) {
				this.collection['content_privacy'] = '{' + roleString + ', ' + departmentString + '}';
			} else if (roleString && !departmentString) {
				this.collection['content_privacy'] = '{' + roleString + '}';
			} else if (!roleString && departmentString) {
				this.collection['content_privacy'] = '{' + departmentString + '}';
			} else if (personalFilterString) {
				this.collection['content_privacy'] = '{' + personalFilterString + '}';
			} else if (selectByStatusString) {
				this.collection['content_privacy'] = '{' + selectByStatusString + '}';
			} else if (selectByRenewalString) {
				this.collection['content_privacy'] = '{' + selectByRenewalString + '}';
			} else if (selectByLevelString) {
				this.collection['content_privacy'] = '{' + selectByLevelString + '}';
			} else {
				delete this.collection['content_privacy'];
			}
		}
		if ((!this.model.channel && !this.isExternalUser && !this.multipleExternalUser) || (this.isExternalUser && this.multipleExternalUser)) {
			delete this.collection['channel'];
		}
		if (this.collection['custom_privacy'] && this.collection['custom_privacy'].length > 0) {
			this.collection['custom_privacy'] = '[' + this.collection['custom_privacy'].join(',') + ']';
		}
		if (!isEdit) {
			this.updatePrivacyStringBasedOnPrivacyComponent();
		}
	}

	public onClickCancel(): void {
		this._location.back();
	}
}
