import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-classified-form',
	templateUrl: './classified-form.component.html',
	styleUrls: ['./classified-form.component.css'],
})
export class ClassifiedFormComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
