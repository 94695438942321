import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { Profile } from '../models';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class ProfilesService {
	constructor(private apiService: ApiService) {}

	public getMemberShip(username, memberId: string): Observable<any> {
		let httpParams: HttpParams;

		if (localStorage.getItem('network_id')) {
			httpParams = new HttpParams().set('__includeAppId', 'true');
		}

		return this.apiService.get(`/users/${username}/memberships/${memberId}/`, httpParams);
	}

	public follow(username: string): Observable<Profile> {
		return this.apiService.post('/profiles/' + username + '/follow');
	}

	public unfollow(username: string): Observable<Profile> {
		return this.apiService.delete('/profiles/' + username + '/follow');
	}

	/**
	 * Function to call user un follow api
	 * @param username
	 */
	public followUser(username): Observable<any> {
		const url = '/users/' + localStorage.getItem('uid') + '/relationships/followers/';
		const data = {
			user: username,
			network: localStorage.getItem('network_id'),
			state: 1,
			username: localStorage.getItem('uid'),
		};

		return this.apiService.post(url, data);
	}

	public unFollowUser(username): Observable<any> {
		const url = '/users/' + localStorage.getItem('uid') + '/relationships/followers/?user=' + username + '&network=' + localStorage.getItem('network_id');
		return this.apiService.delete(url);
	}

	/**
	 * Function to mute user.
	 * @param userId
	 */
	public muteUser(userId): Observable<any> {
		const url = '/users/' + localStorage.getItem('uid') + '/mute/';
		const data = {
			UserId: userId,
			networkUid: localStorage.getItem('network_id'),
		};
		return this.apiService.post(url, data);
	}

	public unMute(muteId): Observable<any> {
		const url = '/users/' + localStorage.getItem('uid') + '/mute/',
			data = {
				muteUid: muteId,
			};

		return this.apiService.post(url, data);
	}

	public mutePost(postUid, type): Observable<any> {
		// eslint-disable-next-line spellcheck/spell-checker
		const url = '/users/' + localStorage.getItem('uid') + '/mutecontents/',
			data = {
				contentType: type,
				contentUid: postUid,
				networkUid: localStorage.getItem('network_id'),
			};

		return this.apiService.post(url, data);
	}

	public muteIssue(uid): Observable<any> {
		// eslint-disable-next-line spellcheck/spell-checker
		const url = '/users/' + localStorage.getItem('uid') + '/mutecontents/';
		const data = {
			contentType: 'issue',
			contentUid: uid,
			networkUid: localStorage.getItem('network_id'),
		};
		return this.apiService.post(url, data);
	}

	public unMutePost(muteUid): Observable<any> {
		// eslint-disable-next-line spellcheck/spell-checker
		const url = '/users/' + localStorage.getItem('uid') + '/mutecontents/' + muteUid + '/';
		return this.apiService.delete(url);
	}

	public unMuteUser(userId, muteuid): Observable<any> {
		const url = '/users/' + localStorage.getItem('uid') + '/mute/';
		const data = {
			muteUid: muteuid,
			UserId: userId,
			networkUid: localStorage.getItem('network_id'),
		};
		return this.apiService.post(url, data);
	}

	/**
	 * To get the report flag reasons
	 */
	public getReasons(): Observable<any> {
		const url = '/_support/flagReasons/';
		return this.apiService.get(url);
	}

	/**
	 * Function to report user.
	 */
	public reportUser(userId, reason, text): Observable<any> {
		const url = '/users/' + localStorage.getItem('uid') + '/flags/';
		const data = {
			contentType: 'member',
			contentUid: userId,
			reason: reason,
			text: text,
		};
		return this.apiService.post(url, data);
	}

	public changeMembershipDetail(body, membershipId, userId = localStorage.getItem('uid')): Observable<any> {
		const url = '/users/' + userId + '/memberships/' + membershipId + '/';
		return this.apiService.put(url, body);
	}

	/**
	 * confirm/delete membership
	 */
	public updateMembership(memberUid, credential): Observable<any> {
		const url = '/networks/' + localStorage.getItem('network_id') + '/members/' + memberUid + '/';
		return this.apiService.put(url, credential);
	}

	/**
	 * Cancel pending membership of user
	 *
	 * @param networkUid string
	 * @param memberUid string
	 * @param credential string
	 *
	 * @returns Observable<any>
	 */
	public cancelPendingMembership(networkUid: string, memberUid: string, credential: object): Observable<any> {
		return this.apiService.put(`/networks/${networkUid}/members/${memberUid}/`, credential);
	}

	public makeAdmin(networkUid, credential): Observable<any> {
		const url = '/networks/' + networkUid + '/members/';
		return this.apiService.post(url, credential);
	}

	/**
	 * Function to get user activity log
	 * @param id
	 * @returns
	 */
	public getUserActivityLog(id): Observable<any> {
		const url = `/networks/members/${id}/activity_logs/`;
		return this.apiService.get(url);
	}

	/**
	 * Function to paginate user activity log
	 * @param url
	 * @returns
	 */
	public paginateUserActivities(url): Observable<any> {
		return this.apiService.get(url);
	}
}
