import { Component, EventEmitter, Input, OnInit, Output, } from '@angular/core';

@Component({
	selector: 'app-custom-select-input',
	templateUrl: './custom-select-input.component.html',
	styleUrls: ['./custom-select-input.component.scss']
})
export class CustomSelectInputComponent implements OnInit {
	@Input() public options: any;
	public showDropdown = false;
	@Input() public selectedOption: any;
	@Input() public width = '300px';

	@Output() public optionChanged = new EventEmitter();

	constructor() { }
	ngOnInit(): void {
	}

	public toggleDropdown(): void {
		this.showDropdown = !this.showDropdown;
	}

	public selectOption(option: { label: string; value: string; selected: boolean }): void {
		this.optionChanged.emit(option);
    	this.showDropdown = false;
	}

}
