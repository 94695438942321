import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { UserService, Network, NetworkService, MemberService, ChannelService, LayoutService } from '../../shared';
import { InboxSocketService } from '../../shared/services/inbox-socket.service';
import { PrivacyComponent } from '../../shared';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { MixPanelService } from '@core/services/third-party/mix-panel.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { ProjectService } from '@modules/project/services/project.service';
import { timer } from 'rxjs';
import { SegmentAnalyticsService } from '@core/services/third-party/segment-analytics.service';

@Component({
	selector: 'app-create-channel',
	templateUrl: './create-channel.component.html',
	styleUrls: ['./create-channel.component.css'],
})
export class CreateChannelComponent implements OnInit {
	@ViewChild(PrivacyComponent, { static: true }) public privacy;

	public loadingGif = 'none';
	public network: Network;
	public visibilities = [
		{ name: 'Public', value: 0 },
		{ name: 'Private', value: 1 },
		{ name: 'Hidden', value: 2 },
	];
	public isClicked = false;
	public selectedVisibility;
	public userDetails;
	public membershipDetails: any;
	public role = { role: null, roleSlug: null };
	public roles: any;
	public model = { preview_images: [], images: [] };
	public members: any[] = [];
	public displayPrivacyModal = 'none';
	public forNames;
	public selectedRole;
	public showCustomPrivacy = false;
	public customPrivacy: any[] = [];
	public customPrivacyString = null;
	public roleString: any = null;
	public totalMembersCount = 0;
	public nextMembersUrl = null;
	public networkLoaded;
	public disableInputButton = true;
	public memberList: any[] = [];
	public channel = {
		name: null,
		description: null,
		content_privacy: null,
		username: null,
		custom_privacy: null,
		logo: null,
		access: 0,
	};
	public memberModalDisplay = 'none';
	public channelMembersString;
	public memberString;
	public displayCreateChannel = true;
	// Changes in channel edit ---
	public showButtons = true;
	public memberCount;
	public memberUsernames: any[] = [];
	public channelMembers: any[] = [];
	public existingMember = false;
	public memberData;
	//
	public errorMessage = '';
	public displayMemberList = false;
	public displayReview = false;
	public searchRole = 'all';
	public personalFilterKey;
	public personalFilterValue;
	public personalFilterString: any = null;
	public memberSearchString;
	public selectByStatusString: any = null;
	public selectByRenewalString: any = null;
	public selectByLevelString: any = null;
	public departments = [];
	public personalFilters = [];
	public type = 'create';
	public memberAdmin = false;
	//
	public defaultCp;
	public membershipFields;
	public networkId;
	public hideRole = false;

	public showTodo = false;
	public isTodolist = true;
	public isGroup = true;
	// Repeat Cycle
	public repeatCycleOptions = 'none';
	public selectedRepeatOption = 'Daily';
	public repeatOptions = ['daily', 'weekly', 'monthly', 'one-off'];
	public repeatObj = { option: '' };
	public showSelectDateOption = false;
	public oneOff_date;
	public todoList = {
		name: '',
		collaboration: true,
		repeatCycle: 'daily',
		state: 'active',
		username: localStorage.getItem('uid'),
		network: '',
		channel: '',
		statusPost: true,
		uid: '',
	};
	public conversation = {
		conversationName: '',
		isGroup: true,
		networkId: '',
		participants: [],
		user: localStorage.getItem('uid'),
	};
	// For edit
	public channelId;
	// to store the logo of a channel which is going to be edited.
	public channelLogo;

	public errorDialog = 'none';

	public errorText = '';

	public isSearched = false;

	public memberListLoading = false;
	public channelReviewModal = false;

	public selectedChannel: any;
	public selectedTodolist: any;
	public selectedChannelEditMode = false;
	private previousImage: any;
	public currentPlan: string;

	constructor(
		private networkService: NetworkService,
		private activatedRoute: ActivatedRoute,
		private socket: InboxSocketService,
		public userServices: UserService,
		public memberService: MemberService,
		private router: Router,
		private channelService: ChannelService,
		private translate: TranslateService,
		private location: Location,
		private layoutService: LayoutService,
		private mixPanelService: MixPanelService,
		private projectService: ProjectService,
		private segmentService: SegmentAnalyticsService
	) {
		this.userDetails = this.userServices.getCurrentUser();
		this.networkService.currentNetwork.subscribe((network) => {
			this.network = network;

			this.roles = network['roles'];
			this.roles = this.roles.filter((li) => li.slug !== 'external');
			if (network.membership && network.membership.memberRole) {
				network.membership.memberRole.fields.forEach(() => {
					this.networkService.departmentsReady.subscribe((departmentsLoaded) => {
						if (departmentsLoaded) {
							this.networkService.currentDepartments.subscribe((departments) => {
								this.departments = departments.objects;
							});
						}
					});
				});
				network.membership.memberRole.fields.forEach((value) => {
					if (value.CP === true) {
						this.personalFilters.push(value);
					}
				});
			}
			if (network.membership && network.membership.memberType === 'admin') {
				this.memberAdmin = true;
			} else {
				this.memberAdmin = false;
			}

			if (network['services'] && network['services'].length > 0) {
				const i = network['services'].findIndex((s) => s['slug'] === 'add-task');
				if (i > -1) {
					this.showTodo = true;
				}
			}
		});
	}

	/**
	 * 1. Hidden for setting default channel cp.
	 * 2. Set default cp of channel as everyone.
	 */
	ngOnInit(): void {
		this.layoutService.hideBd();
		this.channel.username = this.userDetails.username;
		if (localStorage.getItem('network_id')) {
			this.networkId = localStorage.getItem('network_id');
		}

		this.networkService.networkReady.subscribe((networkLoaded) => {
			this.networkLoaded = networkLoaded;
			if (this.networkLoaded) {
				this.networkService.currentNetwork.subscribe((network) => {
					this.network = network;
				});
			}
		});

		this.channelMembersString = 'Everyone'; // 2
		this.privacy.previousSelection = 'Everyone';
		this.privacy.currentSelection = 'Everyone';

		const regex = new RegExp(/^\/network\/channel-edit\?.*$/);
		if (regex.test(this.router.url)) {
			this.type = 'edit';
			this.displayReview = true;
			this.showButtons = false;
			this.loadingGif = 'block';
			this.activatedRoute.queryParams.subscribe((params: Params) => {
				this.channelId = params['id'];
				this.networkService.getChannel(this.channelId, false).subscribe((data) => {
					if (data.userIds) {
						this.channelMembers = data.userIds;
					}

					if (data) {
						this.selectedChannel = data;
						// console.log('SelectedChannel', this.selectedChannel);

						if (data['contentPrivacy']) {
							this.privacy['content_privacy'] = data['contentPrivacy'];
							if (data['contentPrivacy'].department) {
								const index = this.departments.findIndex((department) => department.uid === data['contentPrivacy'].department);
								if (index > -1) {
									this.channelMembersString = this.departments[index].name;
									this.privacy['selectedDepartment'] = this.departments[index];
								}
							} else {
								this.privacy['selectedDepartment'] = null;
							}
							if (data['contentPrivacy'].role) {
								const index = this.roles.findIndex((role) => role.name === data['contentPrivacy'].role);
								this.privacy['role'] = this.roles[index];
								// this.channelMembersString = this.roles[index].name;
							} else {
								this.privacy['role'] = null;
							}

							if (!data['contentPrivacy'].role && !data['contentPrivacy'].department) {
								const field = Object.keys(data['contentPrivacy']);
								this.personalFilters.forEach((element) => {
									if (element.key === field[0] && field[0] !== 'manager') {
										this.channelMembersString = element.valueLabel;
										this.privacy['personalFilter'] = element;
									}
								});
								if (data['contentPrivacy'].status) {
									this.channelMembersString = data['contentPrivacy'].status;
									this.privacy['selectByStatusValue'] = data['contentPrivacy'];
								} else if (data['contentPrivacy'].renewalStatus) {
									this.channelMembersString = data['contentPrivacy'].renewalStatus;
									this.privacy['selectByRenewalValue'] = data['contentPrivacy'];
								} else if (data['contentPrivacy'].membership_level) {
									this.privacy['selectByLevelValue'] = data['contentPrivacy'];
								} else if (data['contentPrivacy'].manager) {
									if (this.privacy.myTeam && this.privacy.myTeam.key) {
										this.privacy['personalFilter'] = this.privacy.myTeam;
										this.channelMembersString = this.translate.instant('My Team');
									}
								}
							}
						} else {
							this.privacy['content_privacy'] = null;
							this.privacy['selectedDepartment'] = null;
							this.privacy['role'] = null;
						}
						if (data['customPrivacy']) {
							this.privacy['custom_privacy'] = data['customPrivacy'];
						} else {
							this.privacy['custom_privacy'] = [];
						}

						if (data.logo && data.logo.uid) {
							this.model.images.push(data.logo.uid);
						}
					}
					this.memberCount = data.memberCount;
					this.loadingGif = 'none';
					this.channel['name'] = data['name'];
					this.channel['description'] = data['description'];
					this.channel['access'] = data['access'];
					this.channelLogo = data['logo'];
				});
			});
		}

		this.privacy.privacyModalStatus.subscribe((data) => {
			if (data === 'closed') {
				this.layoutService.hideBd();
				this.updatePrivacyStringBasedOnPrivacyComponent();
			}
		});

		this.repeatObj.option = 'one-off';
		this.showSelectDateOption = true;
		this.todoList.collaboration = true;
		const today = new Date();
		const tomorrow = new Date(today);
		tomorrow.setDate(today.getDate() + 1);
		// this.oneOff_date = tomorrow;
		this.oneOff_date = {
			year: tomorrow.getFullYear(),
			month: tomorrow.getMonth() + 1,
			day: tomorrow.getDate(),
		};
	}

	/** ** function to update previos and current selection values in privacy */
	private updateSelectionFields(): void {
		if (localStorage.getItem(`${this.networkId}`)) {
			const retrievedCustomPrivacy = JSON.parse(localStorage.getItem(`${this.networkId}`));

			if (retrievedCustomPrivacy.privacyType === 'role') {
				this.privacy['selectedRole'] = retrievedCustomPrivacy;
				this.channelMembersString = retrievedCustomPrivacy.name;
				this.privacy.previousSelection = retrievedCustomPrivacy.name;
				this.privacy.currentSelection = retrievedCustomPrivacy.name;
			}

			if (retrievedCustomPrivacy.privacyType === 'department') {
				this.privacy['selectedDepartment'] = retrievedCustomPrivacy;

				if (retrievedCustomPrivacy.valueLabel) {
					this.privacy.previousSelection = retrievedCustomPrivacy.valueLabel;
					this.privacy.currentSelection = retrievedCustomPrivacy.valueLabel;
					this.channelMembersString = retrievedCustomPrivacy.valueLabel;
				}

				if (retrievedCustomPrivacy.name && retrievedCustomPrivacy.uid) {
					this.privacy.previousSelection = retrievedCustomPrivacy.name;
					this.privacy.currentSelection = retrievedCustomPrivacy.name;
					this.channelMembersString = retrievedCustomPrivacy.name;
				}
			}

			if (retrievedCustomPrivacy.privacyType === 'personalFilter') {
				this.privacy['personalFilter'] = retrievedCustomPrivacy;
				this.channelMembersString = retrievedCustomPrivacy.valueLabel;
				this.privacy.previousSelection = retrievedCustomPrivacy.valueLabel;
				this.privacy.currentSelection = retrievedCustomPrivacy.valueLabel;
			}

			if (Array.isArray(retrievedCustomPrivacy)) {
				this.privacy['custom_privacy'] = retrievedCustomPrivacy;

				retrievedCustomPrivacy.forEach((member) => {
					if (member.user) {
						this.channelMembersString = member.user.name;
						this.privacy.previousSelection = member.user.name;
						this.privacy.currentSelection = member.user.name;
					} else if (member.name) {
						this.channelMembersString = member.name;
						this.privacy.previousSelection = member.name;
						this.privacy.currentSelection = member.name;
					}
				});
			}

			if (Array.isArray(retrievedCustomPrivacy) && retrievedCustomPrivacy.includes('status') === true) {
				this.privacy.selectByStatusValue = retrievedCustomPrivacy;
				this.privacy.previousSelection = retrievedCustomPrivacy[1];
				this.privacy.currentSelection = retrievedCustomPrivacy[1];
				this.channelMembersString = retrievedCustomPrivacy[1];
			}

			if (Array.isArray(retrievedCustomPrivacy) && retrievedCustomPrivacy.includes('renewalFrequency') === true) {
				this.privacy.selectByRenewalValue = retrievedCustomPrivacy;
				this.privacy.previousSelection = retrievedCustomPrivacy[1];
				this.privacy.currentSelection = retrievedCustomPrivacy[1];
				this.channelMembersString = retrievedCustomPrivacy[1];
			}

			if (retrievedCustomPrivacy.privacyType === 'selectByLevel') {
				this.privacy['selectByLevelValue'] = retrievedCustomPrivacy;
				this.privacy.previousSelection = retrievedCustomPrivacy.name;
				this.privacy.currentSelection = retrievedCustomPrivacy.name;
				this.channelMembersString = retrievedCustomPrivacy.name;
			}

			if (retrievedCustomPrivacy === 'Everyone') {
				this.channelMembersString = this.translate.instant('Everyone');
				this.privacy.previousSelection = this.translate.instant('Everyone');
				this.privacy.currentSelection = this.translate.instant('Everyone');
			}
		}
	}

	private onCreateChannelClick(): void {
		// set default cp
		if (this.network.membership.memberRole && this.network.membership.memberRole.defaultContentPrivacy) {
			this.defaultCp = this.network['membership']['memberRole']['defaultContentPrivacy'];
			this.membershipFields = this.network['membership']['memberRole']['fields'];

			this.membershipFields.forEach((field) => {
				if (field.key === this.defaultCp) {
					if (!localStorage.getItem(`${this.networkId}`)) {
						this.channelMembersString = field.valueLabel;
						this.privacy.previousSelection = field.valueLabel;
						this.privacy.currentSelection = field.valueLabel;
						this.privacy.personalFilter = field;
					}
				}
			});
		} else {
			this.channelMembersString = this.role['role'];
		}

		this.selectedRole = this.role['roleSlug'];
	}

	public onClickRole(): void {
		this.selectedRole = this.role['roleSlug'];
		this.displayPrivacyModal = 'block';
		this.privacy.showPrivacy();
	}

	public onClickCustomPrivacy(): void {
		this.getMembers();
		this.showCustomPrivacy = !this.showCustomPrivacy;
	}

	public onCustomPrivacySelect(member): void {
		this.customPrivacy.push(member);
	}

	public getMembers(): void {
		this.displayMemberList = true;

		if (this.totalMembersCount === 0 || this.totalMembersCount > this.members.length) {
			if (this.network && this.network['uid']) {
				this.memberListLoading = true;

				this.memberService.getMembers(this.network.uid, this.nextMembersUrl).subscribe(
					(data) => {
						this.memberData = data;
						this.memberListLoading = false;
						data.objects.forEach((element) => {
							this.members.push(element);
						});
						this.totalMembersCount = data.count;
						if (data.next) {
							this.nextMembersUrl = data.next.split('alpha')[1];
						} else {
							this.nextMembersUrl = '';
							this.loadingGif = 'none';
						}
					},
					() => {
						this.memberListLoading = false;
					}
				);
			}
		}
	}

	public updatePrivacyStringBasedOnPrivacyComponent(): void {
		this.forNames = '';
		if (this.privacy.custom_privacy && this.privacy.custom_privacy.length > 0) {
			const names = [];
			this.privacy.custom_privacy.forEach((user) => {
				if (user['user']) {
					names.push(user['user'].name);
				} else if (user['name']) {
					names.push(user['name']);
				} else {
					names.push(user.username);
				}
			});
			this.channelMembersString = names.join(',');
		} else {
			if (this.privacy.selectedRole && this.privacy.selectedDepartment) {
				this.channelMembersString = this.privacy.selectedRole.name + ', ' + this.privacy.selectedDepartment.valueLabel;
			} else if (this.privacy.selectedRole) {
				let roleName;
				if (this.privacy.selectedRole.name) {
					roleName = this.privacy.selectedRole.name;
				} else {
					roleName = this.privacy.selectedRole;
				}
				this.channelMembersString = roleName;
			} else if (this.privacy.selectedDepartment) {
				let departmentName;
				if (this.privacy.selectedDepartment.valueLabel) {
					departmentName = this.privacy.selectedDepartment.valueLabel;
					this.channelMembersString = departmentName;
				}
				if (this.privacy.selectedDepartment.name && this.privacy.selectedDepartment.uid) {
					departmentName = this.privacy.selectedDepartment.name;
					this.channelMembersString = departmentName;
				}
			} else if (this.privacy.personalFilter) {
				let personalFilter = '';
				personalFilter = this.privacy.personalFilter.valueLabel;
				this.channelMembersString = personalFilter;
			} else if (this.privacy.selectByRenewalValue) {
				this.channelMembersString = this.privacy.selectByRenewalValue[1];
			} else if (this.privacy.selectByLevelValue) {
				this.channelMembersString = this.privacy.selectByLevelValue.name;
			} else if (this.privacy.selectByStatusValue) {
				this.channelMembersString = this.privacy.selectByStatusValue[1];
			} else {
				this.channelMembersString = 'Everyone';
			}
		}
	}

	public onSubmitPrivacy(): void {
		const privacyArray: any[] = [];
		this.customPrivacy = [];
		if (this.privacy.custom_privacy && this.privacy.custom_privacy.length > 0) {
			this.customPrivacy = this.privacy.custom_privacy;
		}

		// Role
		if (this.privacy.selectedRole) {
			this.selectedRole = this.privacy.selectedRole.slug;
			this.roleString = { role: this.privacy.selectedRole.slug };
			this.channel['content_privacy'] = JSON.stringify(this.roleString);

			const newObject = this.privacy.selectedRole;
			newObject.privacyType = 'role';
			localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
		}

		if (this.privacy.selectedDepartment) {
			let departmentString;
			if (this.privacy.selectedDepartment.value) {
				departmentString = '"department": "' + this.privacy.selectedDepartment.value + '"';
				this.channel['content_privacy'] = '{' + departmentString + '}';
			}

			if (this.privacy.selectedDepartment.uid && this.privacy.selectedDepartment.name) {
				departmentString = '"department": "' + this.privacy.selectedDepartment.uid + '"';
				this.channel['content_privacy'] = '{' + departmentString + '}';
			}

			// -
			const newObject = this.privacy.selectedDepartment;
			newObject.privacyType = 'department';
			localStorage.setItem(`${this.network.uid}`, JSON.stringify(newObject));
		}

		// Personal filter
		if (this.privacy.personalFilter) {
			this.personalFilterString = {};
			const key = this.privacy.personalFilter.key;
			this.personalFilterString[key] = this.privacy.personalFilter.value;
			this.channel['content_privacy'] = JSON.stringify(this.personalFilterString);
			// -
			const newObject = this.privacy.personalFilter;
			newObject.privacyType = 'personalFilter';
			localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
		}

		if (this.privacy.selectByStatusValue) {
			this.selectByStatusString = {
				status: this.privacy.selectByStatusValue[0],
			};

			this.channel['content_privacy'] = JSON.stringify(this.selectByStatusString);

			const newObject = this.privacy.selectByStatusValue;
			if (newObject.includes('status') === false) {
				newObject.push('status');
			}

			localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
		}

		if (this.privacy.selectByRenewalValue) {
			this.selectByRenewalString = {
				renewalStatus: this.privacy.selectByRenewalValue[0],
			};

			this.channel['content_privacy'] = JSON.stringify(this.selectByRenewalString);

			const newObject = this.privacy.selectByRenewalValue;
			if (newObject.includes('renewalFrequency') === false) {
				newObject.push('renewalFrequency');
			}

			localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
		}

		if (this.privacy.selectByLevelValue) {
			this.selectByLevelString = {
				membership_level: this.privacy.selectByLevelValue.uid,
			};
			this.channel['content_privacy'] = JSON.stringify(this.selectByLevelString);

			const newObject = this.privacy.selectByLevelValue;
			newObject.privacyType = 'selectByLevel';
			localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
		}

		// Custom privacy
		if (this.customPrivacy.length !== 0) {
			const newObject = this.customPrivacy;
			newObject.forEach((member) => {
				member.privacyType = 'customPrivacy';
			});
			localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			this.customPrivacy.forEach((element) => {
				if (element.userId) {
					privacyArray.push(element.userId);
					this.privacy.custom_privacy_display = false;
				} else if (element.user) {
					privacyArray.push(element.user.userId);
				} else {
					privacyArray.push(element.id);
				}
			});
			this.customPrivacyString = '[' + privacyArray.join(',') + ']';
			this.channel['custom_privacy'] = this.customPrivacyString;
			delete this.channel['content_privacy'];
		} else {
			if (
				this.selectedRole === null &&
				!this.privacy.personalFilter &&
				!this.privacy.selectByStatusValue &&
				!this.privacy.selectByRenewalValue &&
				!this.privacy.selectByLevelValue &&
				!this.privacy.selectedDepartment
			) {
				delete this.channel['content_privacy'];
				delete this.channel['custom_privacy'];
				localStorage.setItem(`${this.networkId}`, JSON.stringify('Everyone'));
			} else {
				const index = this.roles.findIndex((role) => role.slug === this.selectedRole);
				if (index > -1) {
					this.channelMembersString = '';
					this.customPrivacyString = null;
				}
				delete this.channel['custom_privacy'];
			}
		}
		if (!this.channel['content_privacy']) {
			delete this.channel['content_privacy'];
		}
		if (!this.channel['custom_privacy']) {
			delete this.channel['custom_privacy'];
		}
	}

	public onCloseHandle(): void {
		this.displayMemberList = false;
	}

	public onTitleChange(): void {
		if (this.channel.name && this.channel.description) {
			this.disableInputButton = false;
		}
	}

	public onSubmitNext(): void {
		if (this.channel.name) {
			this.displayCreateChannel = false;
			this.displayMemberModal();
		} else {
			this.errorMessage = 'Please enter the channel name';
		}
	}

	public displayMemberModal(): void {
		this.getMembers();

		// TODO: remove this
		this.memberModalDisplay = 'block';
	}

	private addMembers(): void {
		if (this.channel.name) {
			this.displayCreateChannel = false;
			this.displayMemberModal();
		} else {
			this.errorMessage = 'Please enter the channel name';
		}
	}

	public onSubmitReview(): void {
		if (this.type !== 'edit') {
			this.onCreateChannel();
		} else {
			this.selectedChannelEditMode = true;
			this.redirectToReviewPage();
			// this.onUpdateChannel();
		}
	}

	private redirectToReviewPage(): void {
		this.isGroup = false;
		const todoListID = this.selectedChannel?.membership?.myTodoListId;
		if (todoListID) {
			this.projectService.getTeams(this.network?.uid, this.selectedChannel?.name).subscribe((data) => {
				if (data) {
					this.selectedTodolist = data.objects[0];
					// console.log('SelectedTodolist', this.selectedTodolist, this.todoList.statusPost);
					this.showTodo = todoListID ? true : false;
					this.isTodolist = data.objects[0] ? true : false;
					this.todoList.collaboration = this.selectedTodolist?.collaboration ? true : false;
					this.todoList.statusPost = this.selectedTodolist?.statusPost ? true : false;
					this.selectedRepeatOption = this.selectedTodolist?.repeatCycle ? this.selectedTodolist?.repeatCycle : 'Daily';
					this.showSelectDateOption = this.selectedTodolist?.one_off ? true : false;
					this.oneOff_date = this.selectedTodolist?.one_off;
					this.todoList.uid = this.selectedTodolist?.uid;
				}
			});
		} else {
			this.selectedRepeatOption = 'Daily';
		}

		if (this.model.images[0]) {
			this.channelLogo = this.model.preview_images[0];
		}

		this.previousImage = this.model;
		if (this.model.images[0] === this.selectedChannel?.logo?.uid) {
			this.model.preview_images = [{}];
			this.model.preview_images[0].url = this.selectedChannel?.logo?.url;
		}
		this.channel['access'] = this.selectedChannel?.access;

		timer(100).subscribe(() => {
			this.displayCreateChannel = false;
			this.channelReviewModal = true;
			this.showButtons = true;
		});
	}

	public onClickBackFromEditReview(): void {
		this.displayCreateChannel = true;
		this.channelReviewModal = false;
	}

	public onCancelClicked(): void {
		this.selectedChannelEditMode = false;
		this.location.back();
	}

	public onUpdateChannel(): void {
		if (this.model.images && this.model.images[0]) {
			this.channel.logo = this.model.images[0];
		}
		this.isClicked = true;
		this.onSubmitPrivacy();
		this.updateSelectionFields();
		this.loadingGif = 'block';
		this.channelService.updateChannelDetails(this.channelId, this.channel).subscribe(
			(data) => {
				sessionStorage.setItem('refresh-channel-list', '1');
				this.loadingGif = 'none';
				this.displayCreateChannel = true;
				this.displayMemberList = false;
				this.networkService.populate();
				if (data['uid']) {
					if (this.isTodolist && this.todoList?.uid) {
						this.onCreateTodolist(data);
					}
					if (this.isGroup) {
						this.onCreateGroupChat(data);
					}
					const self = this;
					this.memberString = '';
					this.memberList.forEach(function (member) {
						if (self.memberString === '') {
							self.memberString = member.user.username;
						} else {
							self.memberString += '|' + member.user.username;
						}
					});
					const postData = { user: this.memberString };
					this.networkService.addMembers(postData, data['uid']).subscribe(() => {
						this.router.navigateByUrl('/network/channel');
						this.channel = {
							name: null,
							description: null,
							content_privacy: null,
							username: null,
							custom_privacy: null,
							logo: null,
							access: 0,
						};
					});
				}
			},
			(err) => {
				this.loadingGif = 'none';
				this.errorDialog = 'block';
				this.errorText = err.errors.error.detail ? err.errors.error.detail : 'Something went wrong';
			}
		);
	}

	public onCreateChannel(): void {
		this.isClicked = true;
		this.loadingGif = 'block';
		this.onSubmitPrivacy();
		this.displayCreateChannel = false;

		if (this.model.images && this.model.images[0]) {
			this.channel.logo = this.model.images[0];
		}

		this.updateSelectionFields();
		this.networkService.createChannel(this.channel).subscribe(
			(data) => {
				sessionStorage.setItem('refresh-channel-list', '1');
				this.isClicked = false;

				if (data.uid) {
					this.mixPanelService.track('Created Team', {
						workspace: this.network.name || 'NA',
						appVersion: environment.version,
						language: this.translate.currentLang,
						user: this.userDetails.name || 'NA',
						username: this.userDetails.username || 'NA',
						date: moment().format('YYYY-MM-DD'),
					});

					this.segmentService.trackEvent('Created Team', {
						workspace: this.network.name || 'NA',
						appVersion: environment.version,
						language: this.translate.currentLang,
						user: this.userDetails.name || 'NA',
						username: this.userDetails.username || 'NA',
						date: moment().format('YYYY-MM-DD'),
					});

					if (this.isTodolist) {
						this.onCreateTodolist(data);
					}
					if (this.isGroup) {
						this.onCreateGroupChat(data);
					}
					const self = this;
					this.memberString = '';
					this.memberList.forEach(function (member) {
						if (self.memberString === '') {
							self.memberString = member.user.username;
						} else {
							self.memberString += '|' + member.user.username;
						}
					});
					const postData = { user: this.memberString };
					this.networkService.addMembers(postData, data['uid']).subscribe(() => {
						this.loadingGif = 'none';
						this.channelService.resetChannels();
						this.networkService.populateNetworkBasedOnId(this.network.uid);
						this.router.navigateByUrl('/network/channel');
						this.channel = {
							name: null,
							description: null,
							content_privacy: null,
							username: null,
							custom_privacy: null,
							logo: null,
							access: 0,
						};
					});
				}
			},
			(err) => {
				this.loadingGif = 'none';
				this.errorDialog = 'block';
				this.isClicked = false;
				this.errorText = err.errors.error.detail ? err.errors.error.detail : 'Something went wrong';
			}
		);
	}

	public onCreateTodolist(data): void {
		this.todoList['name'] = data['name'];
		this.todoList['network'] = this.network['uid'];
		this.todoList['channel'] = data['uid'];

		if (this.todoList?.uid) {
			this.networkService.updateToDoList(this.todoList, this.selectedTodolist?.uid).subscribe(() => {});
		} else {
			this.networkService.createToDoList(this.todoList, this.network.uid).subscribe(() => {});
		}
	}

	public onCreateGroupChat(data): void {
		this.conversation['conversationName'] = data['name'];
		this.conversation['networkId'] = this.network['uid'];
		this.conversation.participants.push(localStorage.getItem('uid'));
		if (this.memberList.length > 0) {
			this.memberList.forEach((member) => {
				if (member['user'] && member['user']['username']) {
					this.conversation.participants.push(member['user']['username']);
				}
			});
		}
		this.socket.getSocket().emit('join-conversation', this.conversation);
	}

	public onSearchMembers(): void {
		this.purgeMemberList();
		this.memberSearchString = this.memberSearchString.trim();
		if (this.memberSearchString || this.memberSearchString === '') {
			this.memberListLoading = true;
			this.memberService.getMembers(this.network.uid, '', 'query=' + this.memberSearchString).subscribe(
				(data) => {
					this.memberListLoading = false;
					data.objects.forEach((element) => {
						if (!this.memberList.includes(element)) {
							this.members.push(element);
						}
					});
					if (this.memberSearchString) {
						this.isSearched = true;
					} else {
						this.isSearched = false;
					}
					this.totalMembersCount = data.count;
					if (data.next) {
						this.nextMembersUrl = data.next.split('alpha')[1];
					} else {
						this.nextMembersUrl = '';
						this.loadingGif = 'none';
					}
				},
				() => {
					this.memberListLoading = false;
				}
			);
		}
	}

	public onRoleChange(): void {
		if (this.searchRole === 'all') {
			this.purgeMemberList();
			this.memberService.getMembers(this.network.uid, '').subscribe((data) => {
				data.objects.forEach((element) => {
					if (!this.memberList.includes(element)) {
						this.members.push(element);
					}
				});
				this.totalMembersCount = data.count;
				if (data.next) {
					this.nextMembersUrl = data.next.split('alpha')[1];
				} else {
					this.nextMembersUrl = '';
					this.loadingGif = 'none';
				}
			});
		} else {
			this.purgeMemberList();
			this.memberService.getMembers(this.network.uid, '', 'role=' + this.searchRole).subscribe((data) => {
				data.objects.forEach((element) => {
					if (!this.memberList.includes(element)) {
						this.members.push(element);
					}
				});
				this.totalMembersCount = data.count;
				if (data.next) {
					this.nextMembersUrl = data.next.split('alpha')[1];
				} else {
					this.nextMembersUrl = '';
					this.loadingGif = 'none';
				}
			});
		}
	}

	private purgeMemberList(): void {
		const pointer = this,
			newArray = this.members.filter(function (member) {
				return pointer.memberList.includes(member);
			});

		this.members = newArray;
	}

	public onClickMembers(member): void {
		this.memberList.push(member);
	}

	public onSubmitMembers(): void {
		this.displayMemberList = false;
		this.channelReviewModal = true;
	}

	public onRemoveImage(): void {
		this.channelLogo = null;
		this.model.images = [];
	}

	public onBackClick(): void {
		this.isClicked = false;
		if (this.displayMemberList === true) {
			this.displayMemberList = false;
			this.displayCreateChannel = true;
			//  Changes in channel edit
			// this.displayReview = false;
			//
		} else if (this.displayReview === true) {
			if (this.type === 'edit' && this.channelReviewModal) {
				delete this.selectedTodolist;
				this.model = this.previousImage;
				this.showButtons = false;
				this.displayCreateChannel = true;
				this.channelReviewModal = false;
				this.displayMemberList = false;
				this.displayReview = true;
			} else {
				this.displayCreateChannel = false;
				this.displayMemberList = true;
				this.displayReview = false;
			}
		}
	}

	/**
	 * Function to clear member search
	 */
	public clearMemberSearch(): void {
		this.memberSearchString = '';
		this.onSearchMembers();
	}

	// Function to update repeat cycle options based on todo list
	private updateRepeatCycleBasedOnToDoList(data): void {
		if (data.repeatCycle !== 'one_off') {
			this.selectedRepeatOption = data.repeatCycle;
			this.todoList.repeatCycle = data.repeatCycle;
			this.repeatObj = { option: data.repeatCycle };
		} else {
			this.selectedRepeatOption = 'one-off';
			this.showSelectDateOption = true;
			this.todoList.repeatCycle = data.repeatCycle;
			this.repeatObj = { option: 'one-off' };

			const date = new Date(data.oneOff),
				startMonth = date.getUTCMonth() + 1,
				startDay = date.getUTCDate(),
				startYear = date.getUTCFullYear();

			// var utcDate = start_year + "-" + startMonth + "-" + startDay + " " + start_hours + ":" + start_minutes + ":" + start_seconds;
			const utcDate = startYear + '-' + startMonth + '-' + startDay;
			this.todoList['oneOff'] = utcDate;
			this.oneOff_date = {
				year: startYear,
				month: startMonth,
				day: startDay,
			};
		}
	}

	public onSelectRepeatOption(): void {
		this.selectedRepeatOption = this.repeatObj.option;
		this.todoList.repeatCycle = this.repeatObj.option;
		this.repeatCycleOptions = 'none';
		// If one-off is selected,show date option
		if (this.selectedRepeatOption === 'one-off') {
			this.showSelectDateOption = true;
		} else {
			this.showSelectDateOption = false;
			this.oneOff_date = '';
			delete this.todoList['oneOff'];
		}
	}

	// Repeat Cycle Modal----
	public showRepeatCycleOptions(): void {
		this.repeatCycleOptions = 'block';
		this.todoList.repeatCycle = this.repeatObj.option.toLowerCase();
		if (this.type !== 'edit-todolist' && this.type !== 'generate-actions') {
			this.repeatObj = { option: 'daily' };
		}
	}

	public closeModal(): void {
		this.repeatCycleOptions = 'none';
	}

	public onRepeatOptionClick(option): void {
		this.repeatObj = { option: option };
	}
}
