import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User, Network, networkTypes } from '../models';
import { UserService, NetworkService } from '../services';

@Component({
	selector: 'app-side-bar',
	templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
	public networkTypes: any;

	constructor(private userService: UserService, private router: Router, private networkService: NetworkService) {
		this.networkTypes = networkTypes;
		if (this.userService.memberships.length > 0) {
			this.isShimmering = 0;
			this.membershipList = this.userService.memberships;
			this.nextUrl = this.userService.nextUrl;
		}
	}
	public loadingGif = 'none';
	public membershipId;
	public isAuthenticated: boolean;
	public currentUser: User;
	public title;
	public membershipList = [];
	public userNotifications;
	public userNotificationSummary;
	public nextUrl;
	public paginating = false;
	public paginateSub$: any;
	public isShimmering = 1;

	ngOnInit(): void {
		this.userService.isAuthenticated.subscribe((authenticated) => {
			this.isAuthenticated = authenticated;
			if (authenticated) {
				this.title = 'Networks';
				if (this.membershipList) {
					this.membershipList.sort(function (m1, m2) {
						if (m1.network.networkType < m2.network.networkType) {
							return -1;
						} else if (m1.network.networkType > m2.network.networkType) {
							return 1;
						} else {
							return 0;
						}
					});
				}
			}
		});

		this.userService.currentUser.subscribe((userData) => {
			this.currentUser = userData;
		});
	}

	public loadNetwork(networkUid, membership): void {
		if (membership.manager && membership.manager === true) {
			if (!localStorage.getItem('manager')) {
				const userMembership = {
					manager: membership.manager,
					uid: membership.uid,
				};
				localStorage.setItem('manager', JSON.stringify(userMembership));
			}
		}
		if (membership.activityNotificationCount) {
			localStorage.setItem('notificationCount', membership.activityNotificationCount);
		}
		this.loadingGif = 'block';
		this.networkService.get(networkUid).subscribe((networkData) => {
			this.loadingGif = 'none';
			if (networkData['membership'].state === 1) {
				if (networkData['membership'] && networkData['membership']['memberRole']) {
					this.networkService.setNetwork(new Network(networkData));
					this.router.navigateByUrl('/network');
				} else {
					this.router.navigateByUrl(`/workspace/profile-information?admin=true&id=${networkUid}&membership=${networkData?.membership?.uid}`);
				}
			} else {
				this.checkForMembership(networkUid, membership);
			}
		});
	}

	private checkForMembership(networkUid, membership): void {
		let email = '';
		const membershipObject = membership;
		if (membershipObject.state === 0) {
			if (membershipObject['network']['adminUsername'] !== this.currentUser['username']) {
				this.membershipId = membershipObject.uid;
				if ((membershipObject.validationMethod === 1 || membershipObject.validationMethod === 2) && !membershipObject['network']['inviteOnly']) {
					if (membershipObject.network && membershipObject['network']['validationValue']) {
						const validationValue = membershipObject['network']['validationValue'];
						if (validationValue.indexOf('://') > -1) {
							const domain = validationValue.split('://')[1];
							email = '@' + domain;
						} else if (validationValue.indexOf('www.') > -1) {
							const domain = validationValue.split('www.')[1];
							email = '@' + domain;
						} else {
							email = '@' + validationValue;
						}
						this.router.navigateByUrl('/join-work?roleValidation=true&id=' + networkUid + '&membership=' + membershipObject.uid + '&email=' + email);
					} else {
						this.router.navigateByUrl('/join-work?roleValidation=true&id=' + networkUid + '&membership=' + membershipObject.uid);
					}
				}
				if (membershipObject['network'] && membershipObject['network']['inviteOnly']) {
					this.router.navigateByUrl('/join-work?inviteOnly=true&id=' + networkUid + '&membership=' + membershipObject.uid);
				}
			} else {
				let validationMethod: any;
				if (!membership['validationMethod']) {
					// =============================
					if (membership['network']['roles'] && membership['network']['roles'].length > 0) {
						const index = membership['network']['roles'].findIndex((role) => role.slug === 'external');
						if (index > -1) {
							membership['network']['roles'].splice(index, 1);
						}
						if (membership['network']['roles'].length > 1) {
							validationMethod = 'multiple-role';
							this.router.navigateByUrl(
								`/workspace/profile-information?admin=true&id=${networkUid}&membership=${membershipObject.uid}&validationMethod=${validationMethod}`
							);
						} else {
							validationMethod = 'role';
							this.router.navigateByUrl(
								`/workspace/profile-information?admin=true&id=${networkUid}&membership=${membershipObject.uid}&validationMethod=${validationMethod}`
							);
						}
					}
					// ====================================
				} else {
					validationMethod = 'email';
					if (membership.network && membership['network']['validationValue']) {
						const validationValue = membership['network']['validationValue'];
						if (validationValue.indexOf('://') > -1) {
							const domain = validationValue.split('://')[1];
							email = '@' + domain;
						} else if (validationValue.indexOf('www.') > -1) {
							const domain = validationValue.split('www.')[1];
							email = '@' + domain;
						} else {
							email = '@' + validationValue;
						}
					}

					if (email) {
						this.router.navigateByUrl(
							`/workspace/profile-information?admin=true&id=${networkUid}&membership=${membershipObject?.uid}&validationMethod=${validationMethod}&email=${email}`
						);
					} else {
						this.router.navigateByUrl(
							`/workspace/profile-information?admin=true&id=${networkUid}&membership=${membershipObject?.uid}&validationMethod=${validationMethod}`
						);
					}
				}
			}
		}
	}

	public paginate(): void {
		if (this.nextUrl) {
			this.isShimmering = 1;
			this.paginating = true;

			this.userService.paginate(this.nextUrl).subscribe((data) => {
				this.paginating = false;
				this.isShimmering = 0;

				if (data.next) {
					this.nextUrl = data.next.split('alpha')[1];
					this.userService.nextUrl = data.next.split('alpha')[1];
				} else {
					this.nextUrl = '';
					this.userService.nextUrl = '';
				}
				this.membershipList = this.membershipList.concat(data.objects);
				this.userService.memberships = this.membershipList;
			});
		}
	}

	public showMore(): void {
		if (this.nextUrl) {
			this.paginate();
		}
	}

	public navigateToJoinWork(): void {
		if (!this.router.url.includes('/join-work') || !this.router.url.includes('/workspace/welcome')) {
			this.router.navigateByUrl('/workspace/welcome');
		}
	}
}
