export interface ISidebarMenuItem {
	key?: string;
	title?: string;
	icon?: string;
	route?: string[];
	standaloneRoute?: boolean;
	canAccess?: boolean;
	isBeta?: boolean;
	isLegacy?: boolean;
	isOpen?: boolean;
	children?: ISidebarMenuItem[];
	color?: string;
	onClick?: (self: ISidebarMenu, parent: ISidebarMenu) => void;
}

export class SidebarMenuItem implements ISidebarMenuItem {
	public key?: string;
	public title?: string;
	public icon?: string;
	public route?: string[];
	public canAccess?: boolean;
	public isBeta?: boolean;
	public isLegacy?: boolean;
	public isOpen?: boolean;
	public children?: ISidebarMenuItem[];
	public onClick: (self: ISidebarMenu, parent: ISidebarMenu) => void;

	constructor(props?: Partial<ISidebarMenuItem>) {
		Object.assign(this, props);
	}
}

export interface ISidebarMenu {
	key?: string;
	title?: string;
	icon?: string;
	route?: string[];
	isDivider?: boolean;
	canAccess?: boolean;
	children?: ISidebarMenuItem[];
	isOpen?: boolean;
	isBeta?: boolean;
	isLegacy?: boolean;
	loadChildren: (self: ISidebarMenu) => void;
	onClick: (self: ISidebarMenu) => void;
}

export class SidebarMenu implements ISidebarMenu {
	public key?: string;
	public title?: string;
	public icon?: string;
	public route?: string[];
	public isDivider?: boolean;
	public canAccess?: boolean;
	public children?: ISidebarMenuItem[];
	public isOpen = false;
	public isBeta?: boolean;
	public isLegacy?: boolean;
	public loadChildren: (self: ISidebarMenu) => void;
	public onClick: (self: ISidebarMenu) => void;

	constructor(props?: Partial<ISidebarMenu>) {
		Object.assign(this, props);

		if (this.loadChildren) {
			this.loadChildren(this);
		}
	}
}

export interface ISidebar {
	items: ISidebarMenu[];

	version?: string | number;
}

export class Sidebar implements ISidebar {
	public items: ISidebarMenu[] = [];
	public version?: string | number;

	constructor(props?: Partial<ISidebar>) {
		Object.assign(this, props);
	}
}
