import { Profile } from './profile.model';

export class Comment {
	public uid: number;
	public text: any;
	public contentType: string;
	public contentUid: string;
	public created: string;
	public username: string;
	public user: Profile;
	public post: string;
	public myRelations: any;
	public content: any;
	public attachmentDetails: any;
	public resourceUri: string;
	public likeCount: number;
	public isLink: boolean;

	constructor(comment: any, form_obj: boolean = false) {
		if (comment) {
			for (const key of Object.keys(comment)) {
				if (key in comment) {
					this[key] = comment[key];
				}
			}

			if (!form_obj) {
				if (comment.attachmentDetails) {
					this.attachmentDetails = comment.attachmentDetails;
				} else {
					this.attachmentDetails = [];
				}
			}
		}
	}
}

export interface ICommentForm {
	auto_generated?: boolean;
	isLink?: boolean;
	text?: string;
	contentType?: string | 'todo';
	contentUid?: string;
}

export class CommentForm implements ICommentForm {
	// all fields are optional because we are using this class to update only the fields that are changed

	constructor(values: Object = {}) {
		Object.assign(this, values);
	}
}
