import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class BuySellService {
	public buySellList = [];
	public nextUrl = '';
	public filters;
	public scrollPosition;

	constructor(private apiService: ApiService) {}

	public getFilters(): Observable<any> {
		return this.apiService.get('/_support/categories/');
	}

	public getAll(url): Observable<any> {
		return this.apiService.get(url);
	}

	public getClassifieds(url): Observable<any> {
		return this.apiService.get(url);
	}

	public paginate(url): Observable<any> {
		return this.apiService.get(url);
	}

	public resetList(): void {
		this.buySellList = [];
		this.nextUrl = '';
		this.filters = null;
		this.scrollPosition = 0;
	}
}
