import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NetworkService } from '../../shared';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';


@Component({
	selector: 'app-network-expired',
	templateUrl: './network-expired.component.html',
	styleUrls: ['./network-expired.component.css'],
})
export class NetworkExpiredComponent implements OnInit, OnDestroy {
	public purchasePlans = [];
	private subscription: Subscription;
	public membershipUid: string;
	public networkUid: string;
	public displayDialog = 'none';
	public loadingGif = 'none';
	private currentNetSub: Subscription;
	public networkId: string;
	public currentSubscription: any;
	public currentPurchasePlan: any;
	public displayCancelMessage = 'none';
	public memberShipCount: any;
	private isAdmin = false;
	public appName = environment.appName;
	private networkSubscriber: Subscription;

	constructor(private networkService: NetworkService, private router: Router) {}

	ngOnInit(): void {
		if (localStorage.getItem('network_id')) {
			this.networkUid = localStorage.getItem('network_id');
			this.getPurchasePlans();
		}
		if (localStorage.getItem('membership')) {
			const membershipDetails = JSON.parse(localStorage.getItem('membership'));
			this.membershipUid = membershipDetails['uid'];
		}

		this.getSubscriptionDetails();
		this.memberShipCount = localStorage.getItem('membershipCount');

		// Get current network details
		this.networkSubscriber = this.networkService.networkReady.subscribe((networkLoaded) => {
			if (networkLoaded) {
				this.currentNetSub = this.networkService.currentNetwork.subscribe((network) => {
					if (network && network.membership && network.membership.memberType) {
						localStorage.setItem('networkAdmin', network.membership.memberType);
					}

					/**
					 * Check if the current subscription for the network is active/trailing. If so, then redirect back to network page.
					 */
					if (network?.subscription?.status === 'active' || network?.subscription?.status === 'trialing') {
						localStorage.removeItem('networkExpired');
						this.router.navigateByUrl('/network');
					}
				});
			}
		});

		const membership = JSON.parse(localStorage.getItem('membership'));
		this.isAdmin = membership.memberType === 'admin' ? true : false;
	}

	ngOnDestroy(): void {
		this.currentNetSub?.unsubscribe();
		this.subscription?.unsubscribe();
		this.networkSubscriber?.unsubscribe();
	}

	public getSubscription(): void {
		this.loadingGif = 'block';
		this.subscription = this.networkService.getActivePurchasePlan().subscribe((data) => {
			this.loadingGif = 'none';
			this.currentSubscription = data;
			this.currentPurchasePlan = data.subscriptionPlan;
			if (this.currentSubscription['network'] && this.currentSubscription['network']['uid']) {
				this.networkId = this.currentSubscription['network']['uid'];
			}
			if (this.currentSubscription && this.currentSubscription['renewableDate']) {
				const renewableDate = new Date(this.currentSubscription['renewableDate']);
				const currentDate = new Date();
				if (currentDate < renewableDate) {
					localStorage.removeItem('networkExpired');

					localStorage.setItem('network_id', this.networkId);
					this.networkService.networkId = this.networkId;

					this.router.navigateByUrl('/network');
				}
			}
		});
	}

	private getSubscriptionDetails(): void {
		this.loadingGif = 'block';
		this.networkService.getActivePurchasePlan().subscribe((data) => {
			this.loadingGif = 'none';
			this.currentSubscription = data;
			this.currentPurchasePlan = data.subscriptionPlan;
			if (this.currentSubscription['network'] && this.currentSubscription['network']['uid']) {
				this.networkId = this.currentSubscription['network']['uid'];
			}
		});
	}
	private getPurchasePlans(): void {
		this.loadingGif = 'block';
		this.subscription = this.networkService.getSubscriptions(this.networkUid).subscribe((subscriptions) => {
			if (subscriptions.subscription_templates) {
				/**
				 * Added additional filters for removing legacy plans [STAGING] !!!SERVER-SIDE ISSUE
				 */
				this.purchasePlans = subscriptions.subscription_templates.filter((plan: any) => {
					if (plan?.name === 'Premium' || plan?.name === 'Business') {
						return false;
					}
					return true;
				});

				this.purchasePlans.sort(
					(a, b) => a.fee - b.fee // 3
				);
				this.purchasePlans.forEach((plan) => {
					if (plan.renewal_frequency !== 'null') {
						switch (plan?.name) {
							case 'Starter':
								plan['annualFee'] = 0;

								break;
							case 'Basic':
								plan['annualFee'] = 7;

								break;
							case 'Pro':
								plan['annualFee'] = 9;

								break;
						}
						plan['seats'] = 5;
					}
				});

				// 2a
				this.loadingGif = 'none';
			}
		});
	}

	private clearCache(): void {
		localStorage.removeItem('networkExpired');
		localStorage.removeItem('purchasePlans');
		window.localStorage.removeItem('network_id');
		this.networkService.clearNetworkSubject();
		this.router.navigateByUrl('/');
	}

	public removeUserFromNetwork(): void {
		this.loadingGif = 'block';
		window.localStorage.removeItem('network_id');
		this.networkService.clearNetworkSubject();
		this.networkService.deleteMembership(this.membershipUid).subscribe(() => {
			this.loadingGif = 'none';
			this.handleMembershipCount();
		});
	}

	private handleMembershipCount(): void {
		if (localStorage.getItem('membershipCount')) {
			// let count: any;
			const count = localStorage.getItem('membershipCount');
			let n = Number(count);
			n = n - 1;
			localStorage.setItem('membershipCount', JSON.stringify(n));
			if (n >= 1) {
				this.router.navigateByUrl('/');
			} else {
				this.router.navigateByUrl('/workspace/welcome');
			}
		}
	}

	/**
	 * 23-03-2022
	 * Function to cancel the subscription
	 */
	public confirmSubscriptionCancel(): void {
		const data = {
			action: 'cancel',
			network: this.networkId,
		};
		this.displayCancelMessage = 'none';
		this.loadingGif = 'block';
		this.networkService.cancelSubscriptionPlan(data).subscribe(
			() => {
				this.loadingGif = 'none';
				if (this.memberShipCount > 1) {
					this.clearCache();
				}
			},
			() => {
				this.loadingGif = 'none';
			}
		);
	}

	/**
	 * 23-03-2022
	 * Function to show the subscription cancel modal
	 */
	public onShowCancelModal(): void {
		this.displayCancelMessage = 'block';
	}

	/**
	 * Function to show cancel subscription button
	 * @returns
	 */
	public showCancelSubscriptionButton(): boolean {
		if (this.currentSubscription && this.isAdmin) {
			if (
				(this.currentSubscription.status === 'incomplete' || this.currentSubscription.status === 'past_due' || this.currentSubscription.status === 'unpaid') &&
				// eslint-disable-next-line spellcheck/spell-checker
				!this.currentSubscription.cancelled
			) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}
}
