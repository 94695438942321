import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { UserService, LocationService, PaginationService, GeoLocationService, NetworkService, LayoutService } from '../services';
import { SearchService } from '../../shared/services/search.service';
import { Router } from '@angular/router';
import { LocationObj } from '../models';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-location-list',
	templateUrl: './location-list.html',
	// styleUrls: ['./footer.component.css']
})
export class LocationListComponent implements OnInit {
	public range = -1;
	private paginating = false;
	public appLogo = environment.logo;

	constructor(
		private locationService: LocationService,
		private searchService: SearchService,
		public layoutService: LayoutService,
		private router: Router,
		private networkService: NetworkService,
		private userService: UserService,
		private paginationService: PaginationService,
		public geoLocationService: GeoLocationService,
		private ref: ChangeDetectorRef,
		private pageLoadingUiService: PageLoadingUiService
	) {
		this.paginationService.scrollDistance = 8;
		this.paginateSub$ = this.paginationService.initiateScroll.subscribe((data) => {
			if (data && !this.paginating) {
				this.paginateLocations();
			}
		});
	}

	@Input() public locations;
	public searchLocation = '';
	public displayCheckIn = 'none';
	public isError = false;
	public network;
	public nextUrl;
	public locationsLists = [];
	public position;
	public paginateSub$: any;
	public isFetching = 0;
	public filter = { value: 'all' };
	public currentUser;
	public showMenu = false;
	public displayLocations = 'none';
	public locationErrorMessage = '';
	public selectedLocation;
	public modalLocations = [];
	public currentLocation;
	public reviews: any[] = [];
	public checkinCount;
	public showPostCreate = false;
	public postType = '';
	public review;
	public rating;
	public showPostCreateForm = true;
	public locObject;
	public yourRating;
	public checkLocationActionType: boolean;
	public reportActions: any[] = [];
	public searchSubscription: any;
	public isFirstSearch = true;
	public isSearch = false;
	public action;
	public error;

	ngOnInit(): void {
		this.networkService.currentNetwork.subscribe((network) => {
			this.network = network;
		});

		this.showPostCreateForm = false;
		this.userService.currentUser.subscribe((userData) => {
			this.currentUser = userData;
		});

		const accuracy = { enableHighAccuracy: true };
		this.geoLocationService.getLocation(accuracy).subscribe(
			(position) => {
				this.currentLocation = position;
			},
			function () {}
		);

		const self = this;
		let locations = [];

		// TODO: use loading spinner
		self.geoLocationService.getLocation(accuracy).subscribe(
			(position: any) => {
				self.locationService.getNearMeLocations(position.coords).subscribe((data) => {
					locations = data.response.groups[0].items.map((loc) => new LocationObj(loc, '4sq'));

					self.modalLocations = self.sortData(locations);
				});

				self.ref.detectChanges();
			},
			function (error) {
				self.locationErrorMessage = error;
				self.ref.detectChanges();

				self.error = 'You have rejected access to your location';
				self.isFetching = 1;
			}
		);

		this.layoutService.showBackdrop.subscribe((data) => {
			if (data === 'event-post-form' || data === 'hide') {
				this.showPostCreateForm = false;
				this.postType = '';
				this.showMenu = false;
			}
		});

		// Get locations list
		if (this.locationsLists.length === 0) {
			this.getLocations();
		}

		if (window.location.pathname === '/network/checkin') {
			this.searchSubscription = this.searchService.searchValue.subscribe((data) => {
				if (this.isFirstSearch && data.trim()) {
					this.isFirstSearch = false;
				}
				if (!this.isFirstSearch) {
					if (data) {
						this.isSearch = true;
						this.filterLocations(data.trim());
					} else {
						this.isSearch = false;
						this.getLocations();
					}
				}
			});
		}

		this.layoutService.showBackdrop.subscribe((data) => {
			if (data === 'event-post-form' || data === 'hide') {
				this.showPostCreateForm = false;
				this.postType = '';
				this.locObject = '';
			}
		});
	}

	// Get all locations for locations lists
	public getLocations(): void {
		this.isFetching = 0;
		this.locationsLists = [];
		const accuracy = { enableHighAccuracy: true };
		this.geoLocationService.getLocation(accuracy).subscribe(
			(position) => {
				this.position = position;
				this.networkService.getLocations(this.network.uid, this.position.coords).subscribe(
					(data) => {
						this.locationsLists = data.objects.map((loc) => new LocationObj(loc));
						this.locationsLists = this.sortData(this.locationsLists);
						this.isFetching = 1;
						if (data.next) {
							this.nextUrl = data.next.split('alpha')[1];
						} else {
							this.nextUrl = '';
						}
					},
					() => {}
				);
			},
			() => {}
		);
	}

	public paginateLocations(): void {
		if (this.nextUrl) {
			this.isFetching = 0;
			this.paginating = true;
			this.networkService.getRemainingLocations(this.nextUrl).subscribe((data) => {
				this.locationsLists = this.locationsLists.concat(data.objects);
				this.isFetching = 1;
				this.paginating = false;
				if (data.next) {
					this.nextUrl = data.next.split('alpha')[1];
				} else {
					this.nextUrl = '';
				}
			});
		}
	}

	public filterLocations(type): void {
		event.preventDefault();
		let param;
		if (this.isSearch) {
			param = 'query=' + type;
		} else {
			this.filter['value'] = type;
			param = 'filter=' + type;
		}
		this.locationsLists = [];
		this.isFetching = 0;
		const coords = this.currentLocation.coords.latitude + ',' + this.currentLocation.coords.longitude;
		this.networkService.filterLocations(coords, param).subscribe((data) => {
			this.locationsLists = data.objects.map((loc) => new LocationObj(loc));
			this.locationsLists = this.sortData(this.locationsLists);
			this.isFetching = 1;
			if (data.next) {
				this.nextUrl = data.next.split('alpha')[1];
			} else {
				this.nextUrl = '';
			}
		});
	}

	private sortData(data: any): any {
		return data.sort(function (m1, m2) {
			if (m1.distance < m2.distance) {
				return -1;
			} else if (m1.distance > m2.distance) {
				return 1;
			} else {
				return 0;
			}
		});
	}

	// Function on clicking plus button
	public activateActions(): void {
		this.layoutService.showBd('post_locations');
		this.showMenu = !this.showMenu;
		if (!this.showMenu) {
			this.layoutService.hideBd();
		}
	}

	public onLocationClick(loc): void {
		this.router.navigateByUrl(`/network/loc-detail?id=${loc['uid']}`);
	}

	public onLocationSearch(): void {
		this.locationService.getNearMeLocations(this.currentLocation.coords, this.searchLocation).subscribe((data) => {
			const locations = data.response.groups[0].items.map((loc) => new LocationObj(loc, '4sq'));

			this.modalLocations = this.sortData(locations);
		});
	}

	public onResetLocationSearch(): void {
		this.searchLocation = '';
		this.onLocationSearch();
	}

	public closeLocationModal(): void {
		this.displayLocations = 'none';
		this.layoutService.hideBd();
	}

	public onSelectActions(type): void {
		this.showMenu = !this.showMenu;
		this.displayLocations = 'block';
		this.layoutService.showBd('post_locations');
		this.action = type;
	}

	public onLocationSelect(loc): void {
		this.displayLocations = 'none';
		this.layoutService.hideBd();
		this.selectedLocation = loc;
		if (this.action === 'checkIn') {
			this.displayCheckIn = 'block';
		} else {
			this.showRecommendForm();
		}
	}

	public handleLocationCheckin(): void {
		if (this.review) {
			this.pageLoadingUiService.setPageLoading(true);
			this.locationService
				.create({
					venueId: this.selectedLocation.venueId,
					latitude: this.selectedLocation.latitude,
					longitude: this.selectedLocation.longitude,
					name: this.selectedLocation.name,
					category: this.selectedLocation.category,
					website: this.selectedLocation.website,
					phone: this.selectedLocation.phone,
				})
				.subscribe((data) => {
					this.selectedLocation['uid'] = data.uid;
					this.networkService.checkIn(this.selectedLocation.uid, this.review, this.range).subscribe(
						() => {
							this.closeCheckIn();
							this.pageLoadingUiService.setPageLoading(false);
						},
						() => {
							this.closeCheckIn();
							this.pageLoadingUiService.setPageLoading(false);
						}
					);
				});
		} else {
			this.pageLoadingUiService.setPageLoading(false);
			this.isError = true;
		}
	}

	public closeCheckIn(): void {
		this.displayCheckIn = 'none';
		this.review = '';
		this.range = -1;
		this.isError = false;
		this.layoutService.hideBd();
	}

	private showRecommendForm(): void {
		this.showPostCreateForm = !this.showPostCreateForm;
		if (this.showPostCreateForm) {
			this.layoutService.showBd('event-post-form');
			this.postType = 'recommend';
			this.locObject = this.selectedLocation;
		} else {
			this.postType = '';
			this.layoutService.hideBd('event-post-form');
		}
	}
}
