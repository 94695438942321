import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@shared/models';
import { ApiService } from '@shared/services';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class AuthenticationService {
	private currentAuthenticationStepSubject = new BehaviorSubject<string>('home');
	public currentAuthenticationStep = this.currentAuthenticationStepSubject.asObservable();
	public openTermsAndConditionsOrPrivacyModal = new Subject<'terms_conditions' | 'privacy_policy'>();
	constructor(private apiService: ApiService, private httpClient: HttpClient) {}

	/**
	 * Setup the authentication step
	 * @param step Authentication step
	 */
	public setAuthenticationStep(step: 'home' | 'form'): void {
		this.currentAuthenticationStepSubject.next(step);
	}

	/**
	 * Manual GET request
	 * @param url GET URL
	 * @returns Response object
	 */
	public customGetMethod(url: string): Observable<any> {
		return this.httpClient.get<any>(url, {
			responseType: 'text' as 'json',
		});
	}

	/**
	 * Handle Normal Sign In API request
	 * @param userData user credentials
	 * @param coordinates current location coordinates
	 * @returns
	 */
	public signIn(userData: any, coordinates: GeolocationCoordinates): Observable<User> {
		const lat = coordinates?.latitude,
			long = coordinates?.longitude,
			device = localStorage.getItem('device-token');
		/**
		 * Default:- /token/
		 * If coordinates:- ?near={latitude}-{longitude}
		 * If coordinates and device token:- ?near={latitude}-{longitude}&device-token={deviceToken}
		 */
		const url = `/token/${lat && long ? `?near=${lat}-${long}${device ? `&device-token=${device}` : ''}` : ''}`;
		return this.apiService.post(url, userData);
	}

	/**
	 * Handle SSO Sign In API request
	 * @param email user email address
	 * @returns
	 */
	public ssoSignIn(email: string): Observable<any> {
		return this.apiService.post(`/sso/network/`, { email: email });
	}

	/**
	 * Check if the user's email exists already
	 * @param email Email Address
	 * @returns
	 */
	public preSignUp(email: string): Observable<any> {
		return this.apiService.post(`/accounts/presignup/`, { email: email });
	}

	/**
	 * User registration
	 * @param credentials userDetails
	 * @returns
	 */
	public userSignUp(credentials: any): Observable<any> {
		return this.apiService.post(`/accounts/signup/`, credentials);
	}

	/**
	 * Get the terms of conditions and privacy policy text
	 * @param infoTextType Type of information
	 * @returns Observable<string>
	 */
	public getTermOfConditionsOrPrivacyText(infoTextType: 'terms_conditions' | 'privacy_policy'): Observable<string> {
		const apiURL = environment.baseApiUrl;
		return this.httpClient.get<string>(`${apiURL}/${infoTextType}/`, {
			responseType: 'text' as 'json',
		});
	}
}
