import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UrlPreviewComponent } from './url-preview.component';

@NgModule({
	declarations: [UrlPreviewComponent],
	imports: [CommonModule],
	exports: [UrlPreviewComponent],
})
export class UrlPreviewModule {}
