import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ChatSocketService } from '@modules/chat/services/chat-socket.service';
import { TranslateService } from '@ngx-translate/core';
import { ContextMenuComponent, ContextMenuService } from '@perfectmemory/ngx-contextmenu';
import { ToastService } from '@shared/services/common/toast.service';
import * as moment from 'moment';
import { ClipboardService } from 'ngx-clipboard';
import { pipe, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
	selector: 'app-quick-messages',
	templateUrl: './quick-messages.component.html',
	styleUrls: ['./quick-messages.component.scss'],
})
export class QuickMessagesComponent implements OnInit {
	@ViewChild('messageContextMenu') public messageContextMenu: ContextMenuComponent;
	@ViewChild('messageInput', { static: false }) public messageInput: ElementRef;
	@Input() public messages: any[];
	@Input() public isMessageListLoading: boolean;
	@Input() public currentUser: any;
	@Input() public selectedConversation: any;
	@Output() public sendMessageEmitter = new EventEmitter<any>();
	@Output() public replyEmitter = new EventEmitter<any>();
	@Output() public deleteEmitter = new EventEmitter<any>();
	@Output() public imageViewer = new EventEmitter<any>();
	@Output() public attachmentBubbleOpen = new EventEmitter<boolean>();
	@Output() public messagePaginationEmitter = new EventEmitter<any>();

	constructor(
		private chatSocketService: ChatSocketService,
		public contextMenuService: ContextMenuService,
		private clipboardService: ClipboardService,
		private toastService: ToastService,
		private translateService: TranslateService
	) {}

	public attachmentBubble: any;
	public attachmentsExpandedView = false;
	public message: string;
	public selectedMessage: any;
	public replyConversation: any;

	ngOnInit(): void {
		timer(200).subscribe(() => {
			this.messageInput?.nativeElement?.focus();
		});
	}

	// eslint-disable-next-line spellcheck/spell-checker
	public attachmentBubbleOpenEmiiter(): void {
		this.attachmentBubble = !this.attachmentBubble;
		this.attachmentBubbleOpen.emit(this.attachmentBubble);
	}

	/**
	 * Function to show date separation label
	 *
	 * @param message
	 */
	public showDateSeparation(message, index): any {
		const currentDay = moment().startOf('day'),
			date = moment(message.createdOn).startOf('day'),
			result = currentDay.diff(date);

		let day: any = '';

		if (this.checkPreviousDate(message, index)) {
			if (result === 0) {
				day = 'Today';
			} else if (result === 1) {
				day = 'Yesterday';
			} else {
				day = moment(message.createdOn).format('MMMM DD, YYYY');
			}
			return day;
		} else {
			return '';
		}
	}

	/**
	 * Function to check the previous date and message date is equal or not
	 *
	 * @param message
	 */
	public checkPreviousDate(message, index): boolean {
		let previousMsgObj: any;
		const currentMessageDate = moment(message.createdOn).startOf('day');

		if (index > 0) {
			previousMsgObj = this.messages[index - 1];
		}

		if (previousMsgObj) {
			const previousMsgDate = moment(previousMsgObj.createdOn).startOf('day');

			if (currentMessageDate.isSame(previousMsgDate)) {
				return false;
			} else {
				return true;
			}
		} else {
			return true;
		}
	}

	/**
	 * Detect EnterKey press
	 * @param event KeyPress Event
	 */
	public onMessageKeyUp(event: any): void {
		if (event.keyCode === 13) {
			event.preventDefault();

			if (typeof (this.message === 'string')) {
				const message = this.message.trim();
				// this.checkForMentions(message);
				timer(150).subscribe(() => {
					// this.messagePost(message, 'text');
					// eslint-disable-next-line spellcheck/spell-checker
					const messagePackage = { msg: message, type: 'text' };
					this.sendMessageEmitter.emit(messagePackage);
				});
			} else {
				// this.messagePost(this.message);
				// eslint-disable-next-line spellcheck/spell-checker
				const messagePackage = { msg: this.message, type: null };
				this.sendMessageEmitter.emit(messagePackage);
			}
			this.message = null;
			this.replyConversation = null;
		} else {
			// rxjs debounce
			this.chatSocketService.getSocket().emit('typing', {
				conversationId: this.selectedConversation?._id,
				username: this.currentUser?.username,
			});
			pipe(debounceTime(500), distinctUntilChanged());
		}
	}

	/**
	 * Typing Indicator
	 */
	public onMessageChange(): void {
		this.chatSocketService.getSocket().emit('typing', { conversationId: this.selectedConversation._id, user: this.currentUser.username });
	}

	/**
	 * On Click Send Message Button
	 */
	public onSendClick(): void {
		const _message = this.message.trim();
		if (_message) {
			// this.checkForMentions(_message);
			timer(150).subscribe(() => {
				// eslint-disable-next-line spellcheck/spell-checker
				const messagePackage = { msg: _message, type: 'text' };
				this.sendMessageEmitter.emit(messagePackage);
				// this.messagePost(_message, 'text');
			});
		} else {
			// eslint-disable-next-line spellcheck/spell-checker
			const messagePackage = { msg: _message, type: null };
			this.sendMessageEmitter.emit(messagePackage);
			// this.messagePost(this.message);
		}
		this.message = null;
		this.replyConversation = null;
	}

	/**
	 * Reply Button Click Emitter
	 * @param message
	 */
	public onClickReplyButton(message: any): void {
		this.replyConversation = message;
		this.messageInput?.nativeElement?.focus();
		if (this.replyConversation) {
			this.replyEmitter.emit(message);
		} else {
			this.replyConversation = null;
		}
	}

	/**
	 * Delete Button Click Emitter
	 */
	public onClickDeleteButton(): void {
		this.deleteEmitter.emit(this.selectedMessage);
	}

	/**
	 * Preview Image
	 * @param image
	 */
	public showImage(image: any): void {
		this.imageViewer.emit(image);
	}

	/**
	 * Get Download link for attachment
	 * @param attachment Selected attachment
	 * @returns download link
	 */
	public getDownloadLink(attachment: any): any {
		return attachment['url'] + '?cType=' + attachment['type'] + '&file=' + attachment['name'];
	}

	/**
	 * On Click Message Copy
	 */
	public messageCopyClick(): void {
		this.clipboardService.copyFromContent(this.selectedMessage?.parts[0]?.body);
		this.toastService.info(this.translateService.instant('Copied to clipboard'));
	}

	/**
	 * Function to open Context Menu
	 * @param event Mouse Event
	 * @param type Type of Confirmation
	 */
	public openContextMenu(event: MouseEvent, message: any): void {
		event.preventDefault();
		event.stopPropagation();

		this.selectedMessage = message;
		timer(150).subscribe(() => {
			this.contextMenuService.show.next({
				contextMenu: this.messageContextMenu,
				event,
				item: null,
			});
		});
	}

	/**
	 * Message pagination
	 */
	public onMessagePaginationTrigger(): void {
		this.messagePaginationEmitter.emit(true);
	}

	/**
	 * Reply parent scroll
	 *
	 * @param message Selected message
	 */
	public replyClicked(message: any): void {
		if (document.getElementById(message?.parent?._id)) {
			document.getElementById(message?.parent?._id).scrollIntoView();
		}
	}
}
