import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { QuickChatService } from '../../../services/quick-chat.service';

@Injectable({
	providedIn: 'root'
})
export class HideQuickChatResolver implements Resolve<boolean> {
	constructor(
		private quickChatService: QuickChatService
	){}
	public resolve(): Observable<boolean> {
		this.quickChatService.hideQuickChat();
		return of(true);
	}
}
