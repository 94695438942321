import { throwError as observableThrowError, Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { catchError, distinctUntilChanged } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams, HttpParameterCodec } from '@angular/common/http';
import { JwtService } from './jwt.service';

class CustomEncoder implements HttpParameterCodec {
	public encodeKey(key: string): string {
		return encodeURIComponent(key);
	}

	public encodeValue(value: string): string {
		return encodeURIComponent(value);
	}

	public decodeKey(key: string): string {
		return decodeURIComponent(key);
	}

	public decodeValue(value: string): string {
		return decodeURIComponent(value);
	}
}

@Injectable()
export class ApiService {
	public errorDetail: any;
	public status;
	public responseBody: any;
	public accessToken: any;
	public tokenExpires: any;

	// For Error dialog
	private errorSubject = new BehaviorSubject<any>(null);
	public currentError = this.errorSubject.asObservable().pipe(distinctUntilChanged());
	private isErrorSetSubject = new ReplaySubject<boolean>(1);
	public errorReady = this.isErrorSetSubject.asObservable();

	constructor(private http: HttpClient, private jwtService: JwtService) {}

	private setHeaders(): HttpHeaders {
		const headersConfig = {
			'Content-Type': 'application/x-www-form-urlencoded',
		};

		if (this.jwtService.getToken()) {
			headersConfig['Authorization'] = `Bearer ${this.jwtService.getToken()}`;
		}

		return new HttpHeaders(headersConfig);
	}

	private setChatHeaders(): HttpHeaders {
		const headersConfig = {
			'Content-Type': 'application/json',
		};
		if (this.jwtService.getToken()) {
			headersConfig['Authorization'] = `Bearer ${this.jwtService.getToken()}`;
		}
		return new HttpHeaders(headersConfig);
	}

	private setUploadHeaders(): HttpHeaders {
		// const headersConfig = {
		//   'X-access-level': 'user',
		// };
		const headersConfig = {};
		if (this.jwtService.getToken()) {
			headersConfig['Authorization'] = `Bearer ${this.jwtService.getToken()}`;
		}
		return new HttpHeaders(headersConfig);
	}

	private formatErrors(error: any): any {
		let message = error.error;

		if (message === undefined || message === null || !message) {
			message = error.message || error.detail || error;

			return [];
		}

		if (message.detail) {
			this.errorDetail = message.detail;

			const x = { detail: this.errorDetail, status: error.status };

			localStorage.setItem('status', JSON.stringify(x));
		} else if (message.password) {
			this.errorDetail = message.password[0];
		} else if (error.status === 0) {
			const x = { detail: 'Time out issue', status: 0 };

			localStorage.setItem('status', JSON.stringify(x));
		} else if (message.errors && message.errors.error && message.errors.error.message) {
			this.errorDetail = message.errors.error.message;

			const x = { detail: this.errorDetail, status: '400' };

			localStorage.setItem('status', JSON.stringify(x));
		} else if (message['url']) {
			this.errorDetail = message['url'][0];

			const x = { detail: this.errorDetail, status: error.status };

			localStorage.setItem('status', JSON.stringify(x));
		} else {
			const x = { detail: '', status: error.status };

			localStorage.setItem('status', JSON.stringify(x));
		}

		let response: any = {};

		try {
			response = error.json();
		} catch (ex) {
			try {
				response.errors = error;
			} catch (er) {
				response.errors = error.toString();
			}
		}

		switch (error.status) {
			case 404:
				response = {
					errors: 'Oops, it looks like that resource was not found',
				};
				break;
			case 500:
				response = {
					errors: 'Oops, Something went wrong please try again later.',
				};
				break;
		}

		return response;
	}

	public get(path: string, params: HttpParams = new HttpParams(), base = false): Observable<any> {
		const __includeAppId = params.get('__includeAppId') || false;

		// Remove custom __includeAppId from url
		if (__includeAppId) {
			params = params.delete('__includeAppId');
		}

		if (this.jwtService.getUdid()) {
			params.set('udid', `${this.jwtService.getUdid()}`);

			if (path.indexOf('?') > -1) {
				path = `${path}&udid=${this.jwtService.getUdid()}`;
			} else {
				path = `${path}?udid=${this.jwtService.getUdid()}`;
			}
		}

		if (localStorage.getItem('device-token')) {
			if (path.indexOf('?') > -1) {
				path = `${path}&udid=${localStorage.getItem('device-token')}`;
			} else {
				path = `${path}?udid=${localStorage.getItem('device-token')}`;
			}
		}

		if (localStorage.getItem('fcm_token') && path.includes('gcmid') === false) {
			params.set('gcmid', localStorage.getItem('fcm_token'));

			if (path.indexOf('?') > -1) {
				path = `${path}&gcmid=${encodeURIComponent(localStorage.getItem('fcm_token'))}`;
			} else {
				path = `${path}?gcmid=${encodeURIComponent(localStorage.getItem('fcm_token'))}`;
			}
		}

		if (localStorage.getItem('network_id') && __includeAppId && !path?.includes('&app_id=')) {
			if (path.indexOf('?') > -1) {
				path = `${path}&app_id=${localStorage.getItem('network_id')}`;
			} else {
				path = `${path}?app_id=${localStorage.getItem('network_id')}`;
			}
		}

		path = this.appendLanguage(path);

		if (base) {
			return this.http
				.get(`${environment.api_url.split('/alpha')[0]}${path}`, {
					headers: this.setHeaders(),
					params: params,
				})
				.pipe(catchError(this.formatErrors));
		} else {
			return this.http
				.get(`${environment.api_url}${path}`, {
					headers: this.setHeaders(),
					params: params,
				})
				.pipe(catchError(this.formatErrors));
		}
	}

	/**
	 * Custom get method
	 * @param url
	 * @param params
	 * @returns
	 */
	public customGetMethod(url, params: HttpParams = new HttpParams()): Observable<any> {
		return this.http.get(`${url}`, { headers: this.setHeaders(), params: params }).pipe(catchError(this.formatErrors));
	}

	public put(path: string, body: Object = {}): Observable<any> {
		let params = new HttpParams({ encoder: new CustomEncoder() });

		for (const key in body) {
			if (Object.prototype.hasOwnProperty.call(body, key)) {
				const value: string | number | boolean | Date = body[key];
				if (typeof value !== 'undefined' && value !== null) {
					params = params.append(key, value.toString());
				}
			}
		}

		if (localStorage.getItem('device-token')) {
			path = path.indexOf('?') > -1 ? `${path}&udid=${localStorage.getItem('device-token')}` : `${path}?udid=${localStorage.getItem('device-token')}`;
		}

		path = this.appendLanguage(path);

		return this.http
			.put(`${environment.api_url}${path}`, params, {
				headers: this.setHeaders(),
			})
			.pipe(catchError((error: any) => observableThrowError(this.formatErrors(error))));
		// .map((res: any) => res.json());
	}

	public calPut(path: string, body: Object = {}): Observable<any> {
		const params = body;
		if (localStorage.getItem('device-token')) {
			if (path.indexOf('?') > -1) {
				path = `${path}&udid=${localStorage.getItem('device-token')}`;
			} else {
				path = `${path}?udid=${localStorage.getItem('device-token')}`;
			}
		}
		path = this.appendLanguage(path);
		return this.http
			.put(`${environment.api_url}${path}`, params, {
				headers: this.setChatHeaders(),
			})
			.pipe(catchError((error: any) => observableThrowError(this.formatErrors(error))));
	}

	public post(path: string, body: Object = {}): Observable<any> {
		// let params = new URLSearchParams();

		// var data = {};
		let data = new HttpParams({ encoder: new CustomEncoder() });
		for (const key in body) {
			if (Object.prototype.hasOwnProperty.call(body, key)) {
				const value: string | number | boolean | Date = body[key];
				if (typeof value !== 'undefined' && value !== null) {
					data = data.append(key, value.toString());
				}
			}
		}

		if (this.jwtService.getUdid()) {
			data = data.append('udid', `${this.jwtService.getUdid()}`);
		}

		// let params = new HttpParams({
		//   encoder: new CustomQueryEncoderHelper(),
		//   fromObject: data
		// });
		// if (localStorage.getItem('fcm_token')) {
		//   params.append('gcmid', localStorage.getItem('fcm_token'));
		// }
		if (this.jwtService.getUdid()) {
			// params.set('udid', `${this.jwtService.getUdid()}`);
			if (path.indexOf('?') > -1) {
				path = `${path}&udid=${this.jwtService.getUdid()}`;
			} else {
				path = `${path}?udid=${this.jwtService.getUdid()}`;
			}
		}
		if (localStorage.getItem('device-token')) {
			if (path.indexOf('?') > -1) {
				path = `${path}&udid=${localStorage.getItem('device-token')}`;
			} else {
				path = `${path}?udid=${localStorage.getItem('device-token')}`;
			}
		}
		if (localStorage.getItem('fcm_token')) {
			// params.set('gcmid', localStorage.getItem('fcm_token'));
			if (path.indexOf('?') > -1) {
				path = `${path}&gcmid=${encodeURIComponent(localStorage.getItem('fcm_token'))}`;
			} else {
				path = `${path}?gcmid=${encodeURIComponent(localStorage.getItem('fcm_token'))}`;
			}
		}
		path = this.appendLanguage(path);
		return this.http
			.post(`${environment.api_url}${path}`, data, {
				headers: this.setHeaders(),
			})
			.pipe(catchError((error: any) => observableThrowError(this.formatErrors(error))));
		// .map((res: any) => res.json());
	}

	public patch(path: string, body: Object = {}): Observable<any> {
		let params = new HttpParams({ encoder: new CustomEncoder() });
		for (const key in body) {
			if (Object.prototype.hasOwnProperty.call(body, key)) {
				const value: string | number | boolean | Date = body[key];
				if (typeof value !== 'undefined' && value !== null) {
					params = params.append(key, value.toString());
				}
			}
		}

		if (localStorage.getItem('device-token')) {
			if (path.indexOf('?') > -1) {
				path = `${path}&udid=${localStorage.getItem('device-token')}`;
			} else {
				path = `${path}?udid=${localStorage.getItem('device-token')}`;
			}
		}
		path = this.appendLanguage(path);

		return this.http
			.patch(`${environment.api_url}${path}`, params, {
				headers: this.setHeaders(),
			})
			.pipe(catchError((error: any) => observableThrowError(this.formatErrors(error))));
		// .map((res: any) => res.json());
	}

	public upload(path = 'uploads', files): Observable<any> {
		return this.http
			.post(`${environment.api_url}${path}`, files, {
				headers: this.setUploadHeaders(),
			})
			.pipe(
				// .map(response => response.json())
				catchError((error) => observableThrowError(error))
			);
	}

	public getMetaData(url): Observable<any> {
		// eslint-disable-next-line spellcheck/spell-checker
		return this.http.jsonp(url + '?callback=__ng_jsonp__.__req0.finished', 'callback');
	}

	public delete(path): Observable<any> {
		return this.http.delete(`${environment.api_url}${path}`, { headers: this.setHeaders() }).pipe(catchError((error: any) => observableThrowError(this.formatErrors(error))));
		// .map((res: any) => res.json());
	}

	public appendLanguage(path): string {
		let languageString = 'en-IN';

		if (localStorage.getItem('language') === 'tr') {
			languageString = 'tr-IN';
		}

		if (path.indexOf('?') > -1) {
			path = `${path}&l=${languageString}`;
		} else {
			path = `${path}?l=${languageString}`;
		}

		return path;
	}

	public chatGet(path: string): Observable<any> {
		return this.http.get(`${environment.chat_url}${path}`, { headers: this.setChatHeaders() }).pipe(catchError(this.formatErrors));
	}

	/**
	 * Function for getting new accessToken
	 * 1. Set url and params.
	 * 2. Remove old access token and expiryDate.
	 * 3. Set new access token and expiry date in localStorage.
	 * 4. Clear all active subscriptions for session expire.
	 */
	public refreshToken(): void {
		const url = `${environment.api_url}/token/refresh/`; // 1

		const params = { refresh: localStorage.getItem('refreshToken') };

		this.http.post(url, params).subscribe(
			(data) => {
				this.responseBody = data;
				this.accessToken = JSON.parse(this.responseBody._body).access;

				localStorage.removeItem('token'); // 2
				localStorage.removeItem('tokenExpires');

				window.localStorage['token'] = this.accessToken; // 3

				this.tokenExpires = JSON.parse(this.responseBody._body).expires;

				window.localStorage['tokenExpires'] = this.tokenExpires;
			},
			() => {}
		);
	}

	public requestAccessToken(): Observable<any> {
		const url = `${environment.api_url}/token/refresh/`; // 1
		const params = { refresh: localStorage.getItem('refreshToken') };

		return this.http.post(url, params);
	}
}
