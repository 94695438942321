import { Injectable } from '@angular/core';

@Injectable()
export class JwtService {
	public getToken(): String {
		return window.localStorage['token'];
	}

	public saveToken(token: String): void {
		window.localStorage['token'] = token;
	}

	public destroyToken(): void {
		window.localStorage.removeItem('token');
		window.localStorage.removeItem('uid');
		window.localStorage.removeItem('udid');
	}

	public getUid(): String {
		return window.localStorage['uid'];
	}

	public saveUid(uid: String): void {
		window.localStorage['uid'] = uid;
	}

	public saveDeviceUid(udid: String): void {
		window.localStorage['udid'] = udid;
	}

	public getUdid(): String {
		return window.localStorage['udid'];
	}
}
