/* eslint-disable spellcheck/spell-checker */
interface IMimeFileType {
	ext: string;
	mime: string;
}

const mimeFileTypeList: IMimeFileType[] = [
	{ ext: '7z', mime: 'application/x-7z-compressed' },
	{ ext: 'aa', mime: 'audio/audible' },
	{ ext: 'AAC', mime: 'audio/aac' },
	{ ext: 'aaf', mime: 'application/octet-stream' },
	{ ext: 'aax', mime: 'audio/vnd.audible.aax' },
	{ ext: 'ac3', mime: 'audio/ac3' },
	{ ext: 'aca', mime: 'application/octet-stream' },
	{ ext: 'accda', mime: 'application/msaccess.addin' },
	{ ext: 'accdb', mime: 'application/msaccess' },
	{ ext: 'accdc', mime: 'application/msaccess.cab' },
	{ ext: 'accde', mime: 'application/msaccess' },
	{ ext: 'accdr', mime: 'application/msaccess.runtime' },
	{ ext: 'accdt', mime: 'application/msaccess' },
	{ ext: 'accdw', mime: 'application/msaccess.webapplication' },
	{ ext: 'accft', mime: 'application/msaccess.ftemplate' },
	{ ext: 'acx', mime: 'application/internet-property-stream' },
	{ ext: 'AddIn', mime: 'text/xml' },
	{ ext: 'ade', mime: 'application/msaccess' },
	{ ext: 'adobebridge', mime: 'application/x-bridge-url' },
	{ ext: 'adp', mime: 'application/msaccess' },
	{ ext: 'ADT', mime: 'audio/vnd.dlna.adts' },
	{ ext: 'ADTS', mime: 'audio/aac' },
	{ ext: 'afm', mime: 'application/octet-stream' },
	{ ext: 'ai', mime: 'application/postscript' },
	{ ext: 'aif', mime: 'audio/aiff' },
	{ ext: 'aifc', mime: 'audio/aiff' },
	{ ext: 'aiff', mime: 'audio/aiff' },
	{ ext: 'air', mime: 'application/vnd.adobe.air-application-installer-package+zip' },
	{ ext: 'amc', mime: 'application/mpeg' },
	{ ext: 'anx', mime: 'application/annodex' },
	{ ext: 'apk', mime: 'application/vnd.android.package-archive' },
	{ ext: 'apng', mime: 'image/apng' },
	{ ext: 'application', mime: 'application/x-ms-application' },
	{ ext: 'art', mime: 'image/x-jg' },
	{ ext: 'asa', mime: 'application/xml' },
	{ ext: 'asax', mime: 'application/xml' },
	{ ext: 'ascx', mime: 'application/xml' },
	{ ext: 'asd', mime: 'application/octet-stream' },
	{ ext: 'asf', mime: 'video/x-ms-asf' },
	{ ext: 'ashx', mime: 'application/xml' },
	{ ext: 'asi', mime: 'application/octet-stream' },
	{ ext: 'asm', mime: 'text/plain' },
	{ ext: 'asmx', mime: 'application/xml' },
	{ ext: 'aspx', mime: 'application/xml' },
	{ ext: 'asr', mime: 'video/x-ms-asf' },
	{ ext: 'asx', mime: 'video/x-ms-asf' },
	{ ext: 'atom', mime: 'application/atom+xml' },
	{ ext: 'au', mime: 'audio/basic' },
	{ ext: 'avci', mime: 'image/avci' },
	{ ext: 'avcs', mime: 'image/avcs' },
	{ ext: 'avi', mime: 'video/x-msvideo' },
	{ ext: 'avif', mime: 'image/avif' },
	{ ext: 'avifs', mime: 'image/avif-sequence' },
	{ ext: 'axa', mime: 'audio/annodex' },
	{ ext: 'axs', mime: 'application/olescript' },
	{ ext: 'axv', mime: 'video/annodex' },
	{ ext: 'bas', mime: 'text/plain' },
	{ ext: 'bcpio', mime: 'application/x-bcpio' },
	{ ext: 'bin', mime: 'application/octet-stream' },
	{ ext: 'bmp', mime: 'image/bmp' },
	{ ext: 'c', mime: 'text/plain' },
	{ ext: 'cab', mime: 'application/octet-stream' },
	{ ext: 'caf', mime: 'audio/x-caf' },
	{ ext: 'calx', mime: 'application/vnd.ms-office.calx' },
	{ ext: 'cat', mime: 'application/vnd.ms-pki.seccat' },
	{ ext: 'cc', mime: 'text/plain' },
	{ ext: 'cd', mime: 'text/plain' },
	{ ext: 'cdda', mime: 'audio/aiff' },
	{ ext: 'cdf', mime: 'application/x-cdf' },
	{ ext: 'cer', mime: 'application/x-x509-ca-cert' },
	{ ext: 'cfg', mime: 'text/plain' },
	{ ext: 'chm', mime: 'application/octet-stream' },
	{ ext: 'class', mime: 'application/x-java-applet' },
	{ ext: 'clp', mime: 'application/x-msclip' },
	{ ext: 'cmd', mime: 'text/plain' },
	{ ext: 'cmx', mime: 'image/x-cmx' },
	{ ext: 'cnf', mime: 'text/plain' },
	{ ext: 'cod', mime: 'image/cis-cod' },
	{ ext: 'config', mime: 'application/xml' },
	{ ext: 'contact', mime: 'text/x-ms-contact' },
	{ ext: 'coverage', mime: 'application/xml' },
	{ ext: 'cpio', mime: 'application/x-cpio' },
	{ ext: 'cpp', mime: 'text/plain' },
	{ ext: 'crd', mime: 'application/x-mscardfile' },
	{ ext: 'crl', mime: 'application/pkix-crl' },
	{ ext: 'crt', mime: 'application/x-x509-ca-cert' },
	{ ext: 'cs', mime: 'text/plain' },
	{ ext: 'csdproj', mime: 'text/plain' },
	{ ext: 'csh', mime: 'application/x-csh' },
	{ ext: 'csproj', mime: 'text/plain' },
	{ ext: 'css', mime: 'text/css' },
	{ ext: 'csv', mime: 'text/csv' },
	{ ext: 'cur', mime: 'application/octet-stream' },
	{ ext: 'czx', mime: 'application/x-czx' },
	{ ext: 'cxx', mime: 'text/plain' },
	{ ext: 'dat', mime: 'application/octet-stream' },
	{ ext: 'datasource', mime: 'application/xml' },
	{ ext: 'dbproj', mime: 'text/plain' },
	{ ext: 'dcr', mime: 'application/x-director' },
	{ ext: 'def', mime: 'text/plain' },
	{ ext: 'deploy', mime: 'application/octet-stream' },
	{ ext: 'der', mime: 'application/x-x509-ca-cert' },
	{ ext: 'dgml', mime: 'application/xml' },
	{ ext: 'dib', mime: 'image/bmp' },
	{ ext: 'dif', mime: 'video/x-dv' },
	{ ext: 'dir', mime: 'application/x-director' },
	{ ext: 'disco', mime: 'text/xml' },
	{ ext: 'divx', mime: 'video/divx' },
	{ ext: 'dll', mime: 'application/x-msdownload' },
	{ ext: 'dll.config', mime: 'text/xml' },
	{ ext: 'dlm', mime: 'text/dlm' },
	{ ext: 'doc', mime: 'application/msword' },
	{ ext: 'docm', mime: 'application/vnd.ms-word.document.macroEnabled.12' },
	{ ext: 'docx', mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
	{ ext: 'dot', mime: 'application/msword' },
	{ ext: 'dotm', mime: 'application/vnd.ms-word.template.macroEnabled.12' },
	{ ext: 'dotx', mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template' },
	{ ext: 'dsp', mime: 'application/octet-stream' },
	{ ext: 'dsw', mime: 'text/plain' },
	{ ext: 'dtd', mime: 'text/xml' },
	{ ext: 'dtsConfig', mime: 'text/xml' },
	{ ext: 'dv', mime: 'video/x-dv' },
	{ ext: 'dvi', mime: 'application/x-dvi' },
	{ ext: 'dwf', mime: 'drawing/x-dwf' },
	{ ext: 'dwg', mime: 'application/acad' },
	{ ext: 'dwp', mime: 'application/octet-stream' },
	{ ext: 'dxf', mime: 'application/x-dxf' },
	{ ext: 'dxr', mime: 'application/x-director' },
	{ ext: 'eml', mime: 'message/rfc822' },
	{ ext: 'emf', mime: 'image/emf' },
	{ ext: 'emz', mime: 'application/octet-stream' },
	{ ext: 'eot', mime: 'application/vnd.ms-fontobject' },
	{ ext: 'eps', mime: 'application/postscript' },
	{ ext: 'es', mime: 'application/ecmascript' },
	{ ext: 'etl', mime: 'application/etl' },
	{ ext: 'etx', mime: 'text/x-setext' },
	{ ext: 'evy', mime: 'application/envoy' },
	{ ext: 'exe', mime: 'application/vnd.microsoft.portable-executable' },
	{ ext: 'exe.config', mime: 'text/xml' },
	{ ext: 'f4v', mime: 'video/mp4' },
	{ ext: 'fdf', mime: 'application/vnd.fdf' },
	{ ext: 'fif', mime: 'application/fractals' },
	{ ext: 'filters', mime: 'application/xml' },
	{ ext: 'fla', mime: 'application/octet-stream' },
	{ ext: 'flac', mime: 'audio/flac' },
	{ ext: 'flr', mime: 'x-world/x-vrml' },
	{ ext: 'flv', mime: 'video/x-flv' },
	{ ext: 'fsscript', mime: 'application/fsharp-script' },
	{ ext: 'fsx', mime: 'application/fsharp-script' },
	{ ext: 'generictest', mime: 'application/xml' },
	{ ext: 'geojson', mime: 'application/geo+json' },
	{ ext: 'gif', mime: 'image/gif' },
	{ ext: 'gpx', mime: 'application/gpx+xml' },
	{ ext: 'group', mime: 'text/x-ms-group' },
	{ ext: 'gsm', mime: 'audio/x-gsm' },
	{ ext: 'gtar', mime: 'application/x-gtar' },
	{ ext: 'gz', mime: 'application/x-gzip' },
	{ ext: 'h', mime: 'text/plain' },
	{ ext: 'hdf', mime: 'application/x-hdf' },
	{ ext: 'hdml', mime: 'text/x-hdml' },
	{ ext: 'heic', mime: 'image/heic' },
	{ ext: 'heics', mime: 'image/heic-sequence' },
	{ ext: 'heif', mime: 'image/heif' },
	{ ext: 'heifs', mime: 'image/heif-sequence' },
	{ ext: 'hhc', mime: 'application/x-oleobject' },
	{ ext: 'hhk', mime: 'application/octet-stream' },
	{ ext: 'hhp', mime: 'application/octet-stream' },
	{ ext: 'hlp', mime: 'application/winhlp' },
	{ ext: 'hpp', mime: 'text/plain' },
	{ ext: 'hqx', mime: 'application/mac-binhex40' },
	{ ext: 'hta', mime: 'application/hta' },
	{ ext: 'htc', mime: 'text/x-component' },
	{ ext: 'htm', mime: 'text/html' },
	{ ext: 'html', mime: 'text/html' },
	{ ext: 'htt', mime: 'text/webviewhtml' },
	{ ext: 'hxa', mime: 'application/xml' },
	{ ext: 'hxc', mime: 'application/xml' },
	{ ext: 'hxd', mime: 'application/octet-stream' },
	{ ext: 'hxe', mime: 'application/xml' },
	{ ext: 'hxf', mime: 'application/xml' },
	{ ext: 'hxh', mime: 'application/octet-stream' },
	{ ext: 'hxi', mime: 'application/octet-stream' },
	{ ext: 'hxk', mime: 'application/xml' },
	{ ext: 'hxq', mime: 'application/octet-stream' },
	{ ext: 'hxr', mime: 'application/octet-stream' },
	{ ext: 'hxs', mime: 'application/octet-stream' },
	{ ext: 'hxt', mime: 'text/html' },
	{ ext: 'hxv', mime: 'application/xml' },
	{ ext: 'hxw', mime: 'application/octet-stream' },
	{ ext: 'hxx', mime: 'text/plain' },
	{ ext: 'i', mime: 'text/plain' },
	{ ext: 'ical', mime: 'text/calendar' },
	{ ext: 'icalendar', mime: 'text/calendar' },
	{ ext: 'ico', mime: 'image/x-icon' },
	{ ext: 'ics', mime: 'text/calendar' },
	{ ext: 'idl', mime: 'text/plain' },
	{ ext: 'ief', mime: 'image/ief' },
	{ ext: 'ifb', mime: 'text/calendar' },
	{ ext: 'iii', mime: 'application/x-iphone' },
	{ ext: 'inc', mime: 'text/plain' },
	{ ext: 'inf', mime: 'application/octet-stream' },
	{ ext: 'ini', mime: 'text/plain' },
	{ ext: 'inl', mime: 'text/plain' },
	{ ext: 'ins', mime: 'application/x-internet-signup' },
	{ ext: 'ipa', mime: 'application/x-itunes-ipa' },
	{ ext: 'ipg', mime: 'application/x-itunes-ipg' },
	{ ext: 'ipproj', mime: 'text/plain' },
	{ ext: 'ipsw', mime: 'application/x-itunes-ipsw' },
	{ ext: 'iqy', mime: 'text/x-ms-iqy' },
	{ ext: 'isp', mime: 'application/x-internet-signup' },
	{ ext: 'isma', mime: 'application/octet-stream' },
	{ ext: 'ismv', mime: 'application/octet-stream' },
	{ ext: 'ite', mime: 'application/x-itunes-ite' },
	{ ext: 'itlp', mime: 'application/x-itunes-itlp' },
	{ ext: 'itms', mime: 'application/x-itunes-itms' },
	{ ext: 'itpc', mime: 'application/x-itunes-itpc' },
	{ ext: 'IVF', mime: 'video/x-ivf' },
	{ ext: 'jar', mime: 'application/java-archive' },
	{ ext: 'java', mime: 'application/octet-stream' },
	{ ext: 'jck', mime: 'application/liquidmotion' },
	{ ext: 'jcz', mime: 'application/liquidmotion' },
	{ ext: 'jfif', mime: 'image/pjpeg' },
	{ ext: 'jnlp', mime: 'application/x-java-jnlp-file' },
	{ ext: 'jpb', mime: 'application/octet-stream' },
	{ ext: 'jpe', mime: 'image/jpeg' },
	{ ext: 'jpeg', mime: 'image/jpeg' },
	{ ext: 'jpg', mime: 'image/jpeg' },
	{ ext: 'js', mime: 'application/javascript' },
	{ ext: 'json', mime: 'application/json' },
	{ ext: 'jsx', mime: 'text/jscript' },
	{ ext: 'jsxbin', mime: 'text/plain' },
	{ ext: 'key', mime: 'application/vnd.apple.keynote' },
	{ ext: 'latex', mime: 'application/x-latex' },
	{ ext: 'library-ms', mime: 'application/windows-library+xml' },
	{ ext: 'lit', mime: 'application/x-ms-reader' },
	{ ext: 'loadtest', mime: 'application/xml' },
	{ ext: 'lpk', mime: 'application/octet-stream' },
	{ ext: 'lsf', mime: 'video/x-la-asf' },
	{ ext: 'lst', mime: 'text/plain' },
	{ ext: 'lsx', mime: 'video/x-la-asf' },
	{ ext: 'lzh', mime: 'application/octet-stream' },
	{ ext: 'm13', mime: 'application/x-msmediaview' },
	{ ext: 'm14', mime: 'application/x-msmediaview' },
	{ ext: 'm1v', mime: 'video/mpeg' },
	{ ext: 'm2t', mime: 'video/vnd.dlna.mpeg-tts' },
	{ ext: 'm2ts', mime: 'video/vnd.dlna.mpeg-tts' },
	{ ext: 'm2v', mime: 'video/mpeg' },
	{ ext: 'm3u', mime: 'audio/x-mpegurl' },
	{ ext: 'm3u8', mime: 'audio/x-mpegurl' },
	{ ext: 'm4a', mime: 'audio/m4a' },
	{ ext: 'm4b', mime: 'audio/m4b' },
	{ ext: 'm4p', mime: 'audio/m4p' },
	{ ext: 'm4r', mime: 'audio/x-m4r' },
	{ ext: 'm4v', mime: 'video/x-m4v' },
	{ ext: 'mac', mime: 'image/x-macpaint' },
	{ ext: 'mak', mime: 'text/plain' },
	{ ext: 'man', mime: 'application/x-troff-man' },
	{ ext: 'manifest', mime: 'application/x-ms-manifest' },
	{ ext: 'map', mime: 'text/plain' },
	{ ext: 'master', mime: 'application/xml' },
	{ ext: 'mbox', mime: 'application/mbox' },
	{ ext: 'mda', mime: 'application/msaccess' },
	{ ext: 'mdb', mime: 'application/x-msaccess' },
	{ ext: 'mde', mime: 'application/msaccess' },
	{ ext: 'mdp', mime: 'application/octet-stream' },
	{ ext: 'me', mime: 'application/x-troff-me' },
	{ ext: 'mfp', mime: 'application/x-shockwave-flash' },
	{ ext: 'mht', mime: 'message/rfc822' },
	{ ext: 'mhtml', mime: 'message/rfc822' },
	{ ext: 'mid', mime: 'audio/mid' },
	{ ext: 'midi', mime: 'audio/mid' },
	{ ext: 'mix', mime: 'application/octet-stream' },
	{ ext: 'mk', mime: 'text/plain' },
	{ ext: 'mk3d', mime: 'video/x-matroska-3d' },
	{ ext: 'mka', mime: 'audio/x-matroska' },
	{ ext: 'mkv', mime: 'video/x-matroska' },
	{ ext: 'mmf', mime: 'application/x-smaf' },
	{ ext: 'mno', mime: 'text/xml' },
	{ ext: 'mny', mime: 'application/x-msmoney' },
	{ ext: 'mod', mime: 'video/mpeg' },
	{ ext: 'mov', mime: 'video/quicktime' },
	{ ext: 'movie', mime: 'video/x-sgi-movie' },
	{ ext: 'mp2', mime: 'video/mpeg' },
	{ ext: 'mp2v', mime: 'video/mpeg' },
	{ ext: 'mp3', mime: 'audio/mpeg' },
	{ ext: 'mp4', mime: 'video/mp4' },
	{ ext: 'mp4v', mime: 'video/mp4' },
	{ ext: 'mpa', mime: 'video/mpeg' },
	{ ext: 'mpe', mime: 'video/mpeg' },
	{ ext: 'mpeg', mime: 'video/mpeg' },
	{ ext: 'mpf', mime: 'application/vnd.ms-mediapackage' },
	{ ext: 'mpg', mime: 'video/mpeg' },
	{ ext: 'mpp', mime: 'application/vnd.ms-project' },
	{ ext: 'mpv2', mime: 'video/mpeg' },
	{ ext: 'mqv', mime: 'video/quicktime' },
	{ ext: 'ms', mime: 'application/x-troff-ms' },
	{ ext: 'msg', mime: 'application/vnd.ms-outlook' },
	{ ext: 'msi', mime: 'application/octet-stream' },
	{ ext: 'mso', mime: 'application/octet-stream' },
	{ ext: 'mts', mime: 'video/vnd.dlna.mpeg-tts' },
	{ ext: 'mtx', mime: 'application/xml' },
	{ ext: 'mvb', mime: 'application/x-msmediaview' },
	{ ext: 'mvc', mime: 'application/x-miva-compiled' },
	{ ext: 'mxf', mime: 'application/mxf' },
	{ ext: 'mxp', mime: 'application/x-mmxp' },
	{ ext: 'nc', mime: 'application/x-netcdf' },
	{ ext: 'nsc', mime: 'video/x-ms-asf' },
	{ ext: 'numbers', mime: 'application/vnd.apple.numbers' },
	{ ext: 'nws', mime: 'message/rfc822' },
	{ ext: 'ocx', mime: 'application/octet-stream' },
	{ ext: 'oda', mime: 'application/oda' },
	{ ext: 'odb', mime: 'application/vnd.oasis.opendocument.database' },
	{ ext: 'odc', mime: 'application/vnd.oasis.opendocument.chart' },
	{ ext: 'odf', mime: 'application/vnd.oasis.opendocument.formula' },
	{ ext: 'odg', mime: 'application/vnd.oasis.opendocument.graphics' },
	{ ext: 'odh', mime: 'text/plain' },
	{ ext: 'odi', mime: 'application/vnd.oasis.opendocument.image' },
	{ ext: 'odl', mime: 'text/plain' },
	{ ext: 'odm', mime: 'application/vnd.oasis.opendocument.text-master' },
	{ ext: 'odp', mime: 'application/vnd.oasis.opendocument.presentation' },
	{ ext: 'ods', mime: 'application/vnd.oasis.opendocument.spreadsheet' },
	{ ext: 'odt', mime: 'application/vnd.oasis.opendocument.text' },
	{ ext: 'oga', mime: 'audio/ogg' },
	{ ext: 'ogg', mime: 'audio/ogg' },
	{ ext: 'ogv', mime: 'video/ogg' },
	{ ext: 'ogx', mime: 'application/ogg' },
	{ ext: 'one', mime: 'application/onenote' },
	{ ext: 'onea', mime: 'application/onenote' },
	{ ext: 'onepkg', mime: 'application/onenote' },
	{ ext: 'onetmp', mime: 'application/onenote' },
	{ ext: 'onetoc', mime: 'application/onenote' },
	{ ext: 'onetoc2', mime: 'application/onenote' },
	{ ext: 'opus', mime: 'audio/ogg' },
	{ ext: 'orderedtest', mime: 'application/xml' },
	{ ext: 'osdx', mime: 'application/opensearchdescription+xml' },
	{ ext: 'otf', mime: 'application/font-sfnt' },
	{ ext: 'otg', mime: 'application/vnd.oasis.opendocument.graphics-template' },
	{ ext: 'oth', mime: 'application/vnd.oasis.opendocument.text-web' },
	{ ext: 'otp', mime: 'application/vnd.oasis.opendocument.presentation-template' },
	{ ext: 'ots', mime: 'application/vnd.oasis.opendocument.spreadsheet-template' },
	{ ext: 'ott', mime: 'application/vnd.oasis.opendocument.text-template' },
	{ ext: 'oxps', mime: 'application/oxps' },
	{ ext: 'oxt', mime: 'application/vnd.openofficeorg.extension' },
	{ ext: 'p10', mime: 'application/pkcs10' },
	{ ext: 'p12', mime: 'application/x-pkcs12' },
	{ ext: 'p7b', mime: 'application/x-pkcs7-certificates' },
	{ ext: 'p7c', mime: 'application/pkcs7-mime' },
	{ ext: 'p7m', mime: 'application/pkcs7-mime' },
	{ ext: 'p7r', mime: 'application/x-pkcs7-certreqresp' },
	{ ext: 'p7s', mime: 'application/pkcs7-signature' },
	{ ext: 'pages', mime: 'application/vnd.apple.pages' },
	{ ext: 'pbm', mime: 'image/x-portable-bitmap' },
	{ ext: 'pcast', mime: 'application/x-podcast' },
	{ ext: 'pct', mime: 'image/pict' },
	{ ext: 'pcx', mime: 'application/octet-stream' },
	{ ext: 'pcz', mime: 'application/octet-stream' },
	{ ext: 'pdf', mime: 'application/pdf' },
	{ ext: 'pfb', mime: 'application/octet-stream' },
	{ ext: 'pfm', mime: 'application/octet-stream' },
	{ ext: 'pfx', mime: 'application/x-pkcs12' },
	{ ext: 'pgm', mime: 'image/x-portable-graymap' },
	{ ext: 'pic', mime: 'image/pict' },
	{ ext: 'pict', mime: 'image/pict' },
	{ ext: 'pkgdef', mime: 'text/plain' },
	{ ext: 'pkgundef', mime: 'text/plain' },
	{ ext: 'pko', mime: 'application/vnd.ms-pki.pko' },
	{ ext: 'pls', mime: 'audio/scpls' },
	{ ext: 'pma', mime: 'application/x-perfmon' },
	{ ext: 'pmc', mime: 'application/x-perfmon' },
	{ ext: 'pml', mime: 'application/x-perfmon' },
	{ ext: 'pmr', mime: 'application/x-perfmon' },
	{ ext: 'pmw', mime: 'application/x-perfmon' },
	{ ext: 'png', mime: 'image/png' },
	{ ext: 'pnm', mime: 'image/x-portable-anymap' },
	{ ext: 'pnt', mime: 'image/x-macpaint' },
	{ ext: 'pntg', mime: 'image/x-macpaint' },
	{ ext: 'pnz', mime: 'image/png' },
	{ ext: 'pot', mime: 'application/vnd.ms-powerpoint' },
	{ ext: 'potm', mime: 'application/vnd.ms-powerpoint.template.macroEnabled.12' },
	{ ext: 'potx', mime: 'application/vnd.openxmlformats-officedocument.presentationml.template' },
	{ ext: 'ppa', mime: 'application/vnd.ms-powerpoint' },
	{ ext: 'ppam', mime: 'application/vnd.ms-powerpoint.addin.macroEnabled.12' },
	{ ext: 'ppm', mime: 'image/x-portable-pixmap' },
	{ ext: 'pps', mime: 'application/vnd.ms-powerpoint' },
	{ ext: 'ppsm', mime: 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12' },
	{ ext: 'ppsx', mime: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow' },
	{ ext: 'ppt', mime: 'application/vnd.ms-powerpoint' },
	{ ext: 'pptm', mime: 'application/vnd.ms-powerpoint.presentation.macroEnabled.12' },
	{ ext: 'pptx', mime: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' },
	{ ext: 'prf', mime: 'application/pics-rules' },
	{ ext: 'prm', mime: 'application/octet-stream' },
	{ ext: 'prx', mime: 'application/octet-stream' },
	{ ext: 'ps', mime: 'application/postscript' },
	{ ext: 'psc1', mime: 'application/PowerShell' },
	{ ext: 'psd', mime: 'application/octet-stream' },
	{ ext: 'psess', mime: 'application/xml' },
	{ ext: 'psm', mime: 'application/octet-stream' },
	{ ext: 'psp', mime: 'application/octet-stream' },
	{ ext: 'pst', mime: 'application/vnd.ms-outlook' },
	{ ext: 'pub', mime: 'application/x-mspublisher' },
	{ ext: 'pwz', mime: 'application/vnd.ms-powerpoint' },
	{ ext: 'qht', mime: 'text/x-html-insertion' },
	{ ext: 'qhtm', mime: 'text/x-html-insertion' },
	{ ext: 'qt', mime: 'video/quicktime' },
	{ ext: 'qti', mime: 'image/x-quicktime' },
	{ ext: 'qtif', mime: 'image/x-quicktime' },
	{ ext: 'qtl', mime: 'application/x-quicktimeplayer' },
	{ ext: 'qxd', mime: 'application/octet-stream' },
	{ ext: 'ra', mime: 'audio/x-pn-realaudio' },
	{ ext: 'ram', mime: 'audio/x-pn-realaudio' },
	{ ext: 'rar', mime: 'application/x-rar-compressed' },
	{ ext: 'ras', mime: 'image/x-cmu-raster' },
	{ ext: 'rat', mime: 'application/rat-file' },
	{ ext: 'rc', mime: 'text/plain' },
	{ ext: 'rc2', mime: 'text/plain' },
	{ ext: 'rct', mime: 'text/plain' },
	{ ext: 'rdlc', mime: 'application/xml' },
	{ ext: 'reg', mime: 'text/plain' },
	{ ext: 'resx', mime: 'application/xml' },
	{ ext: 'rf', mime: 'image/vnd.rn-realflash' },
	{ ext: 'rgb', mime: 'image/x-rgb' },
	{ ext: 'rgs', mime: 'text/plain' },
	{ ext: 'rm', mime: 'application/vnd.rn-realmedia' },
	{ ext: 'rmi', mime: 'audio/mid' },
	{ ext: 'rmp', mime: 'application/vnd.rn-rn_music_package' },
	{ ext: 'rmvb', mime: 'application/vnd.rn-realmedia-vbr' },
	{ ext: 'roff', mime: 'application/x-troff' },
	{ ext: 'rpm', mime: 'audio/x-pn-realaudio-plugin' },
	{ ext: 'rqy', mime: 'text/x-ms-rqy' },
	{ ext: 'rtf', mime: 'application/rtf' },
	{ ext: 'rtx', mime: 'text/richtext' },
	{ ext: 'rvt', mime: 'application/octet-stream' },
	{ ext: 'ruleset', mime: 'application/xml' },
	{ ext: 's', mime: 'text/plain' },
	{ ext: 'safariextz', mime: 'application/x-safari-safariextz' },
	{ ext: 'scd', mime: 'application/x-msschedule' },
	{ ext: 'scr', mime: 'text/plain' },
	{ ext: 'sct', mime: 'text/scriptlet' },
	{ ext: 'sd2', mime: 'audio/x-sd2' },
	{ ext: 'sdp', mime: 'application/sdp' },
	{ ext: 'sea', mime: 'application/octet-stream' },
	{ ext: 'searchConnector-ms', mime: 'application/windows-search-connector+xml' },
	{ ext: 'setpay', mime: 'application/set-payment-initiation' },
	{ ext: 'setreg', mime: 'application/set-registration-initiation' },
	{ ext: 'settings', mime: 'application/xml' },
	{ ext: 'sgimb', mime: 'application/x-sgimb' },
	{ ext: 'sgml', mime: 'text/sgml' },
	{ ext: 'sh', mime: 'application/x-sh' },
	{ ext: 'shar', mime: 'application/x-shar' },
	{ ext: 'shtml', mime: 'text/html' },
	{ ext: 'sit', mime: 'application/x-stuffit' },
	{ ext: 'sitemap', mime: 'application/xml' },
	{ ext: 'skin', mime: 'application/xml' },
	{ ext: 'skp', mime: 'application/x-koan' },
	{ ext: 'sldm', mime: 'application/vnd.ms-powerpoint.slide.macroEnabled.12' },
	{ ext: 'sldx', mime: 'application/vnd.openxmlformats-officedocument.presentationml.slide' },
	{ ext: 'slk', mime: 'application/vnd.ms-excel' },
	{ ext: 'sln', mime: 'text/plain' },
	{ ext: 'slupkg-ms', mime: 'application/x-ms-license' },
	{ ext: 'smd', mime: 'audio/x-smd' },
	{ ext: 'smi', mime: 'application/octet-stream' },
	{ ext: 'smx', mime: 'audio/x-smd' },
	{ ext: 'smz', mime: 'audio/x-smd' },
	{ ext: 'snd', mime: 'audio/basic' },
	{ ext: 'snippet', mime: 'application/xml' },
	{ ext: 'snp', mime: 'application/octet-stream' },
	{ ext: 'sql', mime: 'application/sql' },
	{ ext: 'sol', mime: 'text/plain' },
	{ ext: 'sor', mime: 'text/plain' },
	{ ext: 'spc', mime: 'application/x-pkcs7-certificates' },
	{ ext: 'spl', mime: 'application/futuresplash' },
	{ ext: 'spx', mime: 'audio/ogg' },
	{ ext: 'src', mime: 'application/x-wais-source' },
	{ ext: 'srf', mime: 'text/plain' },
	{ ext: 'SSISDeploymentManifest', mime: 'text/xml' },
	{ ext: 'ssm', mime: 'application/streamingmedia' },
	{ ext: 'sst', mime: 'application/vnd.ms-pki.certstore' },
	{ ext: 'stl', mime: 'application/vnd.ms-pki.stl' },
	{ ext: 'sv4cpio', mime: 'application/x-sv4cpio' },
	{ ext: 'sv4crc', mime: 'application/x-sv4crc' },
	{ ext: 'svc', mime: 'application/xml' },
	{ ext: 'svg', mime: 'image/svg+xml' },
	{ ext: 'swf', mime: 'application/x-shockwave-flash' },
	{ ext: 'step', mime: 'application/step' },
	{ ext: 'stp', mime: 'application/step' },
	{ ext: 't', mime: 'application/x-troff' },
	{ ext: 'tar', mime: 'application/x-tar' },
	{ ext: 'tcl', mime: 'application/x-tcl' },
	{ ext: 'testrunconfig', mime: 'application/xml' },
	{ ext: 'testsettings', mime: 'application/xml' },
	{ ext: 'tex', mime: 'application/x-tex' },
	{ ext: 'texi', mime: 'application/x-texinfo' },
	{ ext: 'texinfo', mime: 'application/x-texinfo' },
	{ ext: 'tgz', mime: 'application/x-compressed' },
	{ ext: 'thmx', mime: 'application/vnd.ms-officetheme' },
	{ ext: 'thn', mime: 'application/octet-stream' },
	{ ext: 'tif', mime: 'image/tiff' },
	{ ext: 'tiff', mime: 'image/tiff' },
	{ ext: 'tlh', mime: 'text/plain' },
	{ ext: 'tli', mime: 'text/plain' },
	{ ext: 'toc', mime: 'application/octet-stream' },
	{ ext: 'tr', mime: 'application/x-troff' },
	{ ext: 'trm', mime: 'application/x-msterminal' },
	{ ext: 'trx', mime: 'application/xml' },
	{ ext: 'ts', mime: 'video/vnd.dlna.mpeg-tts' },
	{ ext: 'tsv', mime: 'text/tab-separated-values' },
	{ ext: 'ttf', mime: 'application/font-sfnt' },
	{ ext: 'tts', mime: 'video/vnd.dlna.mpeg-tts' },
	{ ext: 'txt', mime: 'text/plain' },
	{ ext: 'u32', mime: 'application/octet-stream' },
	{ ext: 'uls', mime: 'text/iuls' },
	{ ext: 'user', mime: 'text/plain' },
	{ ext: 'ustar', mime: 'application/x-ustar' },
	{ ext: 'vb', mime: 'text/plain' },
	{ ext: 'vbdproj', mime: 'text/plain' },
	{ ext: 'vbk', mime: 'video/mpeg' },
	{ ext: 'vbproj', mime: 'text/plain' },
	{ ext: 'vbs', mime: 'text/vbscript' },
	{ ext: 'vcf', mime: 'text/x-vcard' },
	{ ext: 'vcproj', mime: 'application/xml' },
	{ ext: 'vcs', mime: 'text/plain' },
	{ ext: 'vcxproj', mime: 'application/xml' },
	{ ext: 'vddproj', mime: 'text/plain' },
	{ ext: 'vdp', mime: 'text/plain' },
	{ ext: 'vdproj', mime: 'text/plain' },
	{ ext: 'vdx', mime: 'application/vnd.ms-visio.viewer' },
	{ ext: 'vml', mime: 'text/xml' },
	{ ext: 'vscontent', mime: 'application/xml' },
	{ ext: 'vsct', mime: 'text/xml' },
	{ ext: 'vsd', mime: 'application/vnd.visio' },
	{ ext: 'vsi', mime: 'application/ms-vsi' },
	{ ext: 'vsix', mime: 'application/vsix' },
	{ ext: 'vsixlangpack', mime: 'text/xml' },
	{ ext: 'vsixmanifest', mime: 'text/xml' },
	{ ext: 'vsmdi', mime: 'application/xml' },
	{ ext: 'vspscc', mime: 'text/plain' },
	{ ext: 'vss', mime: 'application/vnd.visio' },
	{ ext: 'vsscc', mime: 'text/plain' },
	{ ext: 'vssettings', mime: 'text/xml' },
	{ ext: 'vssscc', mime: 'text/plain' },
	{ ext: 'vst', mime: 'application/vnd.visio' },
	{ ext: 'vstemplate', mime: 'text/xml' },
	{ ext: 'vsto', mime: 'application/x-ms-vsto' },
	{ ext: 'vsw', mime: 'application/vnd.visio' },
	{ ext: 'vsx', mime: 'application/vnd.visio' },
	{ ext: 'vtt', mime: 'text/vtt' },
	{ ext: 'vtx', mime: 'application/vnd.visio' },
	{ ext: 'wasm', mime: 'application/wasm' },
	{ ext: 'wav', mime: 'audio/wav' },
	{ ext: 'wave', mime: 'audio/wav' },
	{ ext: 'wax', mime: 'audio/x-ms-wax' },
	{ ext: 'wbk', mime: 'application/msword' },
	{ ext: 'wbmp', mime: 'image/vnd.wap.wbmp' },
	{ ext: 'wcm', mime: 'application/vnd.ms-works' },
	{ ext: 'wdb', mime: 'application/vnd.ms-works' },
	{ ext: 'wdp', mime: 'image/vnd.ms-photo' },
	{ ext: 'webarchive', mime: 'application/x-safari-webarchive' },
	{ ext: 'webm', mime: 'video/webm' },
	{ ext: 'webp', mime: 'image/webp' } /* https://en.wikipedia.org/wiki/WebP */,
	{ ext: 'webtest', mime: 'application/xml' },
	{ ext: 'wiq', mime: 'application/xml' },
	{ ext: 'wiz', mime: 'application/msword' },
	{ ext: 'wks', mime: 'application/vnd.ms-works' },
	{ ext: 'WLMP', mime: 'application/wlmoviemaker' },
	{ ext: 'wlpginstall', mime: 'application/x-wlpg-detect' },
	{ ext: 'wlpginstall3', mime: 'application/x-wlpg3-detect' },
	{ ext: 'wm', mime: 'video/x-ms-wm' },
	{ ext: 'wma', mime: 'audio/x-ms-wma' },
	{ ext: 'wmd', mime: 'application/x-ms-wmd' },
	{ ext: 'wmf', mime: 'application/x-msmetafile' },
	{ ext: 'wml', mime: 'text/vnd.wap.wml' },
	{ ext: 'wmlc', mime: 'application/vnd.wap.wmlc' },
	{ ext: 'wmls', mime: 'text/vnd.wap.wmlscript' },
	{ ext: 'wmlsc', mime: 'application/vnd.wap.wmlscriptc' },
	{ ext: 'wmp', mime: 'video/x-ms-wmp' },
	{ ext: 'wmv', mime: 'video/x-ms-wmv' },
	{ ext: 'wmx', mime: 'video/x-ms-wmx' },
	{ ext: 'wmz', mime: 'application/x-ms-wmz' },
	{ ext: 'woff', mime: 'application/font-woff' },
	{ ext: 'woff2', mime: 'application/font-woff2' },
	{ ext: 'wpl', mime: 'application/vnd.ms-wpl' },
	{ ext: 'wps', mime: 'application/vnd.ms-works' },
	{ ext: 'wri', mime: 'application/x-mswrite' },
	{ ext: 'wrl', mime: 'x-world/x-vrml' },
	{ ext: 'wrz', mime: 'x-world/x-vrml' },
	{ ext: 'wsc', mime: 'text/scriptlet' },
	{ ext: 'wsdl', mime: 'text/xml' },
	{ ext: 'wvx', mime: 'video/x-ms-wvx' },
	{ ext: 'x', mime: 'application/directx' },
	{ ext: 'xaf', mime: 'x-world/x-vrml' },
	{ ext: 'xaml', mime: 'application/xaml+xml' },
	{ ext: 'xap', mime: 'application/x-silverlight-app' },
	{ ext: 'xbap', mime: 'application/x-ms-xbap' },
	{ ext: 'xbm', mime: 'image/x-xbitmap' },
	{ ext: 'xdr', mime: 'text/plain' },
	{ ext: 'xht', mime: 'application/xhtml+xml' },
	{ ext: 'xhtml', mime: 'application/xhtml+xml' },
	{ ext: 'xla', mime: 'application/vnd.ms-excel' },
	{ ext: 'xlam', mime: 'application/vnd.ms-excel.addin.macroEnabled.12' },
	{ ext: 'xlc', mime: 'application/vnd.ms-excel' },
	{ ext: 'xld', mime: 'application/vnd.ms-excel' },
	{ ext: 'xlk', mime: 'application/vnd.ms-excel' },
	{ ext: 'xll', mime: 'application/vnd.ms-excel' },
	{ ext: 'xlm', mime: 'application/vnd.ms-excel' },
	{ ext: 'xls', mime: 'application/vnd.ms-excel' },
	{ ext: 'xlsb', mime: 'application/vnd.ms-excel.sheet.binary.macroEnabled.12' },
	{ ext: 'xlsm', mime: 'application/vnd.ms-excel.sheet.macroEnabled.12' },
	{ ext: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
	{ ext: 'xlt', mime: 'application/vnd.ms-excel' },
	{ ext: 'xltm', mime: 'application/vnd.ms-excel.template.macroEnabled.12' },
	{ ext: 'xltx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template' },
	{ ext: 'xlw', mime: 'application/vnd.ms-excel' },
	{ ext: 'xml', mime: 'text/xml' },
	{ ext: 'xmp', mime: 'application/octet-stream' },
	{ ext: 'xmta', mime: 'application/xml' },
	{ ext: 'xof', mime: 'x-world/x-vrml' },
	{ ext: 'XOML', mime: 'text/plain' },
	{ ext: 'xpm', mime: 'image/x-xpixmap' },
	{ ext: 'xps', mime: 'application/vnd.ms-xpsdocument' },
	{ ext: 'xrm-ms', mime: 'text/xml' },
	{ ext: 'xsc', mime: 'application/xml' },
	{ ext: 'xsd', mime: 'text/xml' },
	{ ext: 'xsf', mime: 'text/xml' },
	{ ext: 'xsl', mime: 'text/xml' },
	{ ext: 'xslt', mime: 'text/xml' },
	{ ext: 'xsn', mime: 'application/octet-stream' },
	{ ext: 'xss', mime: 'application/xml' },
	{ ext: 'xspf', mime: 'application/xspf+xml' },
	{ ext: 'xtp', mime: 'application/octet-stream' },
	{ ext: 'xwd', mime: 'image/x-xwindowdump' },
	{ ext: 'z', mime: 'application/x-compress' },
	{ ext: 'zip', mime: 'application/zip' },
];

export default class MimeTypeUtils {
	public static findFromMimeType(mimeType: string): IMimeFileType | null {
		return mimeFileTypeList.find((mimeTypeObj: IMimeFileType) => mimeTypeObj.mime === mimeType) || null;
	}

	public static findFromExt(ext: string): IMimeFileType | null {
		return mimeFileTypeList.find((mimeTypeObj: IMimeFileType) => mimeTypeObj.ext === ext) || null;
	}

	public static findExtension(mimeType: string): string | null {
		return mimeFileTypeList.find((mimeTypeObj: IMimeFileType) => mimeTypeObj.mime === mimeType)?.ext || null;
	}
}
