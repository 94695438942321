import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.service';

@Injectable()
export class ReportService {
	constructor(private apiService: ApiService) {}

	public getTodoReport(network_uid, f1 = '7d', f2 = null, f3 = 'd'): Observable<any> {
		if (!f2) {
			return this.apiService.get(`/networks/${network_uid}/todo-reports/?filter=${f1}&p=${f3}`);
		} else {
			return this.apiService.get(`/networks/${network_uid}/todo-reports/?filter=${f1}&${f2}&p=${f3}`);
		}
	}

	public getTimeSheetReport(network_uid, f1 = '7d', f2 = null, f3 = 'd'): Observable<any> {
		if (!f2) {
			return this.apiService.get(`/networks/${network_uid}/timesheet-reports/?filter=${f1}&p=${f3}`);
		} else {
			return this.apiService.get(`/networks/${network_uid}/timesheet-reports/?filter=${f1}&${f2}&p=${f3}`);
		}
	}

	public getIssueReport(network_uid, f1 = '7d', f2 = null, f3 = 'd', per_prod = false): Observable<any> {
		if (per_prod) {
			if (!f2) {
				return this.apiService.get(`/networks/${network_uid}/issue-reports/?filter=${f1}&p=${f3}&per_product=1`);
			} else {
				return this.apiService.get(`/networks/${network_uid}/issue-reports/?filter=${f1}&${f2}&p=${f3}&per_product=1`);
			}
		} else {
			if (!f2) {
				return this.apiService.get(`/networks/${network_uid}/issue-reports/?filter=${f1}&p=${f3}`);
			} else {
				return this.apiService.get(`/networks/${network_uid}/issue-reports/?filter=${f1}&${f2}&p=${f3}`);
			}
		}
	}

	public getPointLadderReport(network_uid, f1 = '7d', f2 = null, f3 = 'd'): Observable<any> {
		if (!f2) {
			return this.apiService.get(`/networks/${network_uid}/point-reports/?filter=${f1}&p=${f3}`);
		} else {
			return this.apiService.get(`/networks/${network_uid}/point-reports/?filter=${f1}&${f2}&p=${f3}`);
		}
	}

	public getTeamMembers(network_uid, q = null): Observable<any> {
		if (q) {
			return this.apiService.get(`/networks/${network_uid}/manager/team-members/?query=${q}`);
		} else {
			return this.apiService.get(`/networks/${network_uid}/manager/team-members/`);
		}
	}
}
