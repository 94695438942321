interface IPersonAttachmentUI {
	data?: any;
}

export interface IPersonAttachment extends IPersonAttachmentUI {
	id?: string;
	name?: string;
	title?: string;
	avatar?: string;
	route?: string;
}

export class PersonAttachment implements IPersonAttachment {
	public id: string = null;
	public name: string = null;
	public title: string = null;
	public avatar: string = null;
	public route: string = null;

	constructor(values: IPersonAttachment = {}) {
		Object.assign(this, values);
	}
}
