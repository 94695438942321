import { AfterViewInit, Component, HostBinding, OnInit, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { UserService, User, Network, Event, NetworkService, EventService, LayoutService } from '../shared';
import { PostComponent } from '../post/post.component';

@Component({
	selector: 'app-event',
	templateUrl: './event.component.html',
	styleUrls: ['./event.component.css'],
	// host: { class: 'row g-0' },
})
export class EventComponent implements OnInit, AfterViewInit {
	@HostBinding('class') public row = 'row g-0';

	public myTeamUid: any;
	public showMyTeam = false;
	public fields;
	public events;
	public departments = [];
	public channelMembershipList;
	public currentUser: User;
	public memberType;
	public network: Network;
	public networkId;
	public channels;
	public showSpin;
	public nextUrl;
	public hasEvents;
	public event_categories;
	public filter = { value: '' };
	public selectedFilter: any = 'all';
	public postUrl = '';
	public showPostCreateForm = true;
	public postType = '';
	public isExternalUser = false;
	public noEvents = false;
	@ViewChildren('PostComponent') public PostComponent: PostComponent;

	constructor(
		private router: Router,
		private eventService: EventService,
		private userService: UserService,
		private networkService: NetworkService,
		private layoutService: LayoutService
	) {
		//   window.onscroll = () => {
		//     let windowHeight = "innerHeight" in window ? window.innerHeight
		//         : document.documentElement.offsetHeight;
		//     let body = document.body, html = document.documentElement;
		//     let docHeight = Math.max(body.scrollHeight,
		//         body.offsetHeight, html.clientHeight,'/posts/?type=event').subscribe((data)=>{
		//           let events = this.setEvents(data);
		//           this.events = data['objects'];
		//
		//         })
		//         html.scrollHeight, html.offsetHeight);
		//     let windowBottom = windowHeight + window.pageYOffset;
		//     if (windowBottom >= docHeight) {
		//         this.paginate();
		//     }
		//  }
	}

	ngOnInit(): void {
		if (localStorage.getItem('networkExpired')) {
			this.router.navigateByUrl('/expired');
		}

		this.currentUser = this.userService.getCurrentUser();
		this.showPostCreateForm = false;
		this.networkService.currentNetwork.subscribe((network) => {
			this.network = network;
			if (network.membership && network.membership.memberRole) {
				this.fields = network['membership']['memberRole']['fields'];
				this.checkIfManager();
				this.channels = network.channels;
				this.memberType = network['membership']['memberType'];
			}

			if (network['membership']['memberRole'] && network['membership']['memberRole']['role']) {
				const memberRole = network['membership']['memberRole']['role'];
				if (memberRole.slug === 'external') {
					this.isExternalUser = true;
				}
			}

			this.networkService.departmentsReady.subscribe((departmentsLoaded) => {
				if (departmentsLoaded) {
					this.networkService.currentDepartments.subscribe((departments) => {
						if (departments && departments['objects'].length > 0) {
							this.departments = departments.objects;
						}
					});
				}
			});
		});

		this.event_categories = [
			{ key: 'today_event', title: "Today's Events" },
			{ key: 'this_week', title: 'This Week' },
			{ key: 'this_month', title: 'This Month' },
			{ key: 'upcoming', title: 'Upcoming' },
			{ key: 'old_events', title: 'Old events' },
		];

		this.channelMembershipList = this.userService.memberships;
		this.postUrl = '/networks/' + this.network.uid + '/posts/?type=event';
		this.eventService.getNetworkEvents('/networks/' + this.network.uid + '/posts/?type=event').subscribe((data) => {
			this.events = data['objects'];
		});

		this.layoutService.showBackdrop.subscribe((data) => {
			if (data === 'event-post-form' || data === 'hide') {
				this.showPostCreateForm = false;
				this.postType = '';
			}
		});
	}

	ngAfterViewInit(): void {
		// Hack: Scrolls to top of Page after page view initialized
		let top = document.getElementById('top');
		if (top !== null) {
			top.scrollIntoView();
			top = null;
		}
	}

	public filterEvents(filter): void {
		event.preventDefault();
		this.filter = { value: '' };
		this.selectedFilter = filter;
		let url = '/networks/' + this.network.uid + '/posts/?type=event';
		if (filter !== 'all') {
			url = url + '&' + filter;
		}
		this.postUrl = url;
	}

	public filterActions(value): void {
		this.filter = { value: value };
	}

	private setEvents(data): any {
		const todayEvent = [];
		const thisWeek = [];
		const thisMonth = [];
		const upcoming = [];
		const pastEvents = [];

		this.showSpin = true;

		const events = data.objects.map((event) => new Event(event));
		const today = new Date();
		today.setHours(0, 0, 0, 0);
		const thisWeekRange = new Date();
		thisWeekRange.setHours(0, 0, 0, 0);
		thisWeekRange.setDate(thisWeekRange.getDate() + 7);

		const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
		this.hasEvents = false;

		for (const event of events) {
			this.hasEvents = true;
			const eventDate = new Date(event.starts.getFullYear() + ',' + (event.starts.getMonth() + 1) + ',' + event.starts.getDate());
			eventDate.setHours(0, 0, 0, 0);
			if (today.getTime() === eventDate.getTime()) {
				todayEvent.push(event);
			}
			if (today.getTime() < eventDate.getTime() && eventDate.getTime() < thisWeekRange.getTime()) {
				thisWeek.push(event);
			}

			if (thisWeekRange.getTime() < eventDate.getTime() && eventDate.getTime() <= lastDay.getTime()) {
				thisMonth.push(event);
			}

			if (lastDay.getTime() < eventDate.getTime()) {
				upcoming.push(event);
			}

			if (eventDate.getTime() < today.getTime()) {
				pastEvents.push(event);
			}
		}

		this.showSpin = false;

		if (data.next) {
			this.nextUrl = data.next.split('alpha')[1];
		} else {
			this.nextUrl = '';
		}

		return {
			today_event: todayEvent,
			this_week: thisWeek,
			this_month: thisMonth,
			upcoming: upcoming,
			old_events: pastEvents,
		};
	}

	private getList(key): any {
		if (key in this.events) {
			return this.events[key];
		} else {
			return [];
		}
	}

	public paginate(): void {
		if (this.nextUrl) {
			this.showSpin = true;
			this.eventService.getNetworkEvents(this.nextUrl).subscribe((data) => {
				const events = this.setEvents(data);
				this.network.events = {
					today_event: this.network.events['today_event'].concat(events['today_event']),
					this_week: this.network.events['this_week'].concat(events['this_week']),
					this_month: this.network.events['this_month'].concat(events['this_month']),
					upcoming: this.network.events['upcoming'].concat(events['upcoming']),
					old_events: this.network.events['old_events'].concat(events['old_events']),
				};
				this.showSpin = false;
			});
		}
	}

	// Pass the clicked post details to this function and store in readMorePost
	public showMore(post): void {
		if (post['showMore']) {
			post['showMore'] = false;
		} else {
			post['showMore'] = true;
		}
	}

	public activatePostCreate(): void {
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
		this.showPostCreateForm = !this.showPostCreateForm;
		if (this.showPostCreateForm) {
			this.layoutService.showBd('event-post-form');
			this.postType = 'event';
		} else {
			this.postType = '';
			this.layoutService.hideBd('event-post-form');
		}
	}

	/**
	 * Function  to check if user has manager cp
	 * 1. Check if local storage contains manager
	 * 2. Set manager uid and display my team filter
	 */
	private checkIfManager(): void {
		if (localStorage.getItem('manager')) {
			const obj = JSON.parse(localStorage.getItem('manager'));
			this.showMyTeam = true;
			this.myTeamUid = obj.uid;
		}
	}
}
