import { Injectable, EventEmitter } from '@angular/core';
import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class InboxSocketBaseService extends Socket {
	constructor() {
		super(<SocketIoConfig>{
			url: `${environment.socketio_chat_url}&l=${localStorage.getItem('language')}`,
			options: {
				reconnection: true,
				forceNew: true,
			},
		});
	}
}

// TODO: deprecated service
@Injectable({
	providedIn: 'root',
})
export class InboxSocketService {
	public socketController: EventEmitter<any> = new EventEmitter();

	constructor(private socket: InboxSocketBaseService) {}

	public getSocket(): InboxSocketBaseService {
		return this.socket;
	}

	public updateUnreadMessageCount(data): void {
		this.socketController.emit(data);
	}
}
