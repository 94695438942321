import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FilterMenu, IFilterMenu } from '@shared/components/filter-menu/filter-menu.component';

@Component({
	selector: 'app-new-post-filter-menu',
	templateUrl: './new-post-filter-menu.component.html',
	styleUrls: ['./new-post-filter-menu.component.scss'],
})
export class NewPostFilterMenuComponent implements OnInit {
	@Input() public teams: any[];
	@Input() public categories: any[];
	@Output() public filterEmitter = new EventEmitter<any>();

	public filterMenuItems: IFilterMenu[] = [];
	public isFilterMenuOpen = false;
	public selectedOption = 'All';

	constructor(private translateService: TranslateService) {}

	ngOnInit(): void {
		this.loadFilterMenu();
	}

	/**
	 * Handle Filter Menu close
	 * @param event Close Event
	 */
	public onFilterMenuClose(): void {
		this.isFilterMenuOpen = false;
	}

	/**
	 * Handle option selection
	 * @param event Filter Menu Event
	 */
	public onFilterOptionChange(event: any): void {
		const filter = { value: '', type: '' };

		if (event?.menuItem?.filterKey === 'category') {
			// Category
			filter.type = 'category';
			filter.value = event?.optionItem?.value;
		} else if (event?.menuItem?.filterKey === 'team') {
			// Team
			filter.type = 'team';
			filter.value = event?.optionItem?.value;
		} else if (event?.menuItem?.filterKey === 'all') {
			// All
			filter.type = 'all';
			filter.value = null;
		}

		this.selectedOption = event?.optionItem?.label ? event.optionItem.label : 'All';
		this.filterEmitter.emit(filter);
	}

	/**
	 * Open Filter Menu
	 */
	public openFilterMenu(): void {
		this.isFilterMenuOpen = !this.isFilterMenuOpen;
	}

	/**
	 * Load Filter Values
	 */
	private loadFilterMenu(): void {
		this.filterMenuItems = [
			// All
			new FilterMenu({
				isOpen: true,
				title: this.translateService.instant('All'),
				icon: '<svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="5" cy="5.5" r="4.5" fill="var(--primary-100)" stroke="var(--primary-color)"/> </svg>',
				filterKey: 'all',
			}),

			// Team
			new FilterMenu({
				isOpen: false,
				title: this.translateService.instant('Team'),

				// eslint-disable-next-line max-len
				icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M14.1693 17.5V15.8333C14.1693 14.9493 13.8181 14.1014 13.193 13.4763C12.5678 12.8512 11.72 12.5 10.8359 12.5H4.16927C3.28522 12.5 2.43737 12.8512 1.81225 13.4763C1.18713 14.1014 0.835938 14.9493 0.835938 15.8333V17.5" stroke="#9CA3AF" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/> <path d="M7.4974 9.16667C9.33835 9.16667 10.8307 7.67428 10.8307 5.83333C10.8307 3.99238 9.33835 2.5 7.4974 2.5C5.65645 2.5 4.16406 3.99238 4.16406 5.83333C4.16406 7.67428 5.65645 9.16667 7.4974 9.16667Z" stroke="#9CA3AF" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/> <path d="M19.1641 17.501V15.8344C19.1635 15.0958 18.9177 14.3784 18.4652 13.7946C18.0127 13.2109 17.3792 12.794 16.6641 12.6094" stroke="#9CA3AF" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/> <path d="M13.3359 2.60938C14.0529 2.79296 14.6885 3.20996 15.1423 3.79463C15.5961 4.37931 15.8425 5.0984 15.8425 5.83854C15.8425 6.57868 15.5961 7.29778 15.1423 7.88245C14.6885 8.46712 14.0529 8.88412 13.3359 9.06771" stroke="#9CA3AF" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/> </svg>',
				filterKey: 'team',
				options: this.teams
					?.map((x: any) => ({
						label: x.name,
						value: x.uid,
					}))
					.sort((a: any, b: any) => a.label.localeCompare(b.label)),
			}),

			// Categories
			new FilterMenu({
				isOpen: false,
				title: this.translateService.instant('Category'),
				// eslint-disable-next-line max-len
				icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M3.33594 17.5013V11.668" stroke="#9CA3AF" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/> <path d="M3.33594 8.33333V2.5" stroke="#9CA3AF" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/> <path d="M10 17.5V10" stroke="#9CA3AF" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/> <path d="M10 6.66667V2.5" stroke="#9CA3AF" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/> <path d="M16.6641 17.4987V13.332" stroke="#9CA3AF" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/> <path d="M16.6641 10V2.5" stroke="#9CA3AF" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/> <path d="M0.835938 11.668H5.83594" stroke="#9CA3AF" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/> <path d="M7.5 6.66797H12.5" stroke="#9CA3AF" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/> <path d="M14.1641 13.332H19.1641" stroke="#9CA3AF" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/> </svg>',
				filterKey: 'category',
				options: this.categories
					?.map((x: any) => ({
						label: x.name,
						value: x.slug,
					}))
					.sort((a: any, b: any) => a.label.localeCompare(b.label)),
			}),
		];
	}

	// #endregion
}
