import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PaginationService {
	public initiateScroll = new BehaviorSubject<boolean>(false);
	public scrollDistance = 4;

	constructor() {}

	public onScrollDown(): void {
		this.initiateScroll.next(true);
	}

	public resetScrollInitiation(): void {
		this.initiateScroll.next(false);
	}
}
