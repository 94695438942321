import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService, Post, LayoutService, NetworkService, FileUploadComponent } from '../../shared';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';
import { TranslateService } from '@ngx-translate/core';
import CacheUtils from '@shared/utils/cache-utils';
import { ToastService } from '@shared/services/common/toast.service';

@Component({
	selector: 'app-user-settings',
	templateUrl: './user-settings.component.html',
	styleUrls: ['./user-settings.component.css'],
})
export class UserSettingsComponent implements OnInit {
	@ViewChild('fileUpload', { static: true }) public fileUpload: FileUploadComponent;

	public appName = environment.appName;

	public isNeaty = environment.appName === 'Neaty';
	public loadingGif = 'none';
	public currentSelectedMenu;
	public firstName: string;
	public lastName: string;
	public name: string;
	public email: string;
	public gender: string;
	public phoneNumber: string;
	public profilePic: string;
	public currentPassword: string;
	public newPassword: string;
	public confirmPassword: string;
	public passwordErrorMessage: string = null;
	public user: any;
	public imageModal = 'none';
	public model = new Post(
		{
			title: null,
			body: null,
			category: null,
			privacy: '',
			images: [],
			preview_images: [],
			shareable: true,
			commentable: true,
			channel: '',
		},
		true
	);
	public displayErrorDialog = 'none';
	public modalErrorText = '';
	public isNetwork = false;
	public isExpired = false;
	public displayDialog = 'none';
	public membershipUid;
	public isDevMode = !environment.production;
	public currentUserUsername: any;

	public confirmModal?: NzModalRef;
	public showDeleteModal = false;

	constructor(
		private userService: UserService,
		private router: Router,
		public layoutService: LayoutService,
		private location: Location,
		private networkService: NetworkService,
		private pageLoadingUiService: PageLoadingUiService,
		private nzModalService: NzModalService,
		private translateService: TranslateService,
		private toastService: ToastService,
	) {
		this.loadingGif = 'block';
	}

	ngOnInit(): void {
		this.setUserData();

		this.fileUpload.profileImageUploaded.subscribe((data) => {
			if (data) {
				this.updateUserDetails();
				this.imageModal = 'none';
				this.layoutService.hideBd();
			}
		});

		if (localStorage.getItem('network_id')) {
			this.isNetwork = true;
		} else {
			this.isNetwork = false;
		}

		if (localStorage.getItem('networkExpired')) {
			this.isExpired = true;
		} else {
			this.isExpired = false;
		}

		if (localStorage.getItem('membership')) {
			const membership = JSON.parse(localStorage.getItem('membership'));
			this.membershipUid = membership['uid'];
		}

		this.userService.currentUser.subscribe((userData) => {
			this.currentUserUsername = userData?.username;
		});
	}

	public onEditClick(menu): void {
		this.currentSelectedMenu = menu;
		if (menu === 'password') {
			this.currentPassword = null;
			this.newPassword = null;
			this.confirmPassword = null;
			this.passwordErrorMessage = null;
		}
	}
	private setUserData(): void {
		this.userService.currentUser.subscribe((userData) => {
			if (userData) {
				this.user = userData;
			} else {
				this.user = this.userService.getCurrentUser();
			}
		});
		if (this.user.images && this.user.images.length > 0) {
			// this.profilePic = this.user['images'][0].url;
		}
		this.firstName = this.user['firstName'];
		this.lastName = this.user['lastName'];
		this.name = this.user['name'];
		this.email = this.user['email'];
		this.gender = this.user['gender'];
		this.phoneNumber = this.user['phoneNumber'];
		this.loadingGif = 'none';
	}
	public onSubmitClick(menu): void {
		this.loadingGif = 'block';
		let param: any;
		switch (menu) {
			case 'name':
				param = { firstName: this.firstName, lastName: this.lastName };
				break;
			case 'email':
				param = { email: this.email };
				break;
			case 'phone':
				param = { phoneNumber: this.phoneNumber };
				break;
			case 'gender':
				param = { gender: this.gender };
				break;
		}
		this.userService.update(param).subscribe(
			() => {
				this.setUserData();
				this.loadingGif = 'none';
				this.currentSelectedMenu = '';
			},
			(err) => {
				this.loadingGif = 'none';

				if (err && err.errors && err.errors.error && err.errors.error.email && err.errors.error.email[0]) {
					this.modalErrorText = err.errors.error.email[0];
				}
				if (this.modalErrorText) {
					this.displayErrorDialog = 'block';
				}
				if (this.currentSelectedMenu === 'email') {
					this.email = this.user['email'];
				}
			}
		);
	}

	private onImageUpload(): void {
		this.imageModal = 'block';
	}

	private onImageSubmit(): void {
		this.imageModal = 'none';
	}

	public onPasswordChange(): void {
		if (this.newPassword.trim() && this.confirmPassword.trim() && this.currentPassword.trim()) {
			if (this.newPassword !== this.confirmPassword) {
				this.passwordErrorMessage = 'New password and confirm password are not same';
			} else {
				this.pageLoadingUiService.setPageLoading(true);
				this.passwordErrorMessage = null;
				const param = {
					username: localStorage.getItem('uid'),
					old_password: this.currentPassword,
					new_password1: this.newPassword,
					new_password2: this.confirmPassword,
				};
				this.userService.updatePassword(param).subscribe(() => {
					this.currentSelectedMenu = '';
					this.pageLoadingUiService.setPageLoading(false);
					this.toastService.success('Password changed successfully',{position: 'center'});
				}, error => {
					this.pageLoadingUiService.setPageLoading(false);
					const errorsObj = error.errors.error;
					if(errorsObj.hasOwnProperty('oldPassword') && errorsObj.oldPassword.length > 0){
						this.passwordErrorMessage = errorsObj.oldPassword[0];
						return;
					}
					if(errorsObj.hasOwnProperty('newPassword2') && errorsObj.newPassword2.length > 0){
						this.passwordErrorMessage = errorsObj.newPassword2[0];
					}
				});
			}
		} else {
			this.passwordErrorMessage = 'Please enter all input fields';
		}
	}

	/**
	 * Function to display profile image upload modal
	 */
	public changeProfileImage(): void {
		this.layoutService.showBd('post');
		this.imageModal = 'block';
	}

	/**
	 * Function to close display profile image upload modal
	 */
	public onCloseHandled(): void {
		this.layoutService.hideBd();
		this.imageModal = 'none';
	}

	/**
	 *  Function to update user details after profile image is uploaded
	 * 1. Call update user detail api
	 * 2. Set new user details to current page
	 * 3. Remove old user details from localStorage
	 */
	private updateUserDetails(): void {
		this.userService.updateUserDetails();
		// this.user = this.userService.getCurrentUser();

		this.setUserData();
		localStorage.removeItem('userDetails');
		CacheUtils.removeStoredData('userDetails');
	}

	public removeUserFromNetwork(): void {
		window.localStorage.removeItem('network_id');
		this.networkService.clearNetworkSubject();
		this.loadingGif = 'block';
		this.networkService.deleteMembership(this.membershipUid).subscribe(() => {
			this.loadingGif = 'none';
			this.handleMembershipCount();
		});
	}

	private handleMembershipCount(): void {
		if (localStorage.getItem('membershipCount')) {
			// let count: any;
			const count = localStorage.getItem('membershipCount');
			let n = Number(count);
			n = n - 1;
			localStorage.setItem('membershipCount', JSON.stringify(n));
			if (n >= 1) {
				this.router.navigateByUrl('/');
			} else {
				this.router.navigateByUrl('/workspace/welcome');
			}
		}
	}

	private clearCache(): void {
		if (localStorage.getItem('networkExpired')) {
			localStorage.removeItem('networkExpired');
		}
	}

	public onBackHandle(): void {
		this.location.back();
	}

	// #region Delete User

	/**
	 * Click delete user action
	 * Open confirmation modal
	 * If true call delete user API and initiate logout procedures (along with clearing local storage values)
	 */
	public onDeleteUserClick(): void {
		this.showDeleteModal = true;
		return;
		this.confirmModal = this.nzModalService.confirm({
			nzTitle: this.translateService.instant('USER.USER_SETTINGS.DELETE_CONFIRM_MODAL.TITLE'),
			nzContent: this.translateService.instant('USER.USER_SETTINGS.DELETE_CONFIRM_MODAL.DESCRIPTION'),
			nzOkDanger: true,
			nzOnOk: () => {
				this.onDeleteConfirmation();
			},
		});
	}

	/**
	 * On confirmation of delete
	 * Call API for deleting user
	 */
	public onDeleteConfirmation(): void {
		this.pageLoadingUiService.setPageLoading(true);
		this.userService
			.deleteUser(this.currentUserUsername)
			.subscribe(
				() => {
					this.userService.logout();
				},
				(error: any) => {
					this.showDeleteModal = false;
					this.handleDeleteError(error);
				}
			)
			.add(() => {
				this.pageLoadingUiService.setPageLoading(false);
			});
	}

	public handleDeleteError(error: any): void {
		this.confirmModal = this.nzModalService.error({
			nzTitle: this.translateService.instant('Error'),
			// eslint-disable-next-line spellcheck/spell-checker
			nzContent: this.translateService.instant(error?.errors?.error?.detail ? error?.errors?.error?.detail : 'Aww Snap! Something went wrong'),
			nzOnOk: () => {},
		});
	}

	// #endregion
}
