import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PbTranslateService {
	constructor(private translate: TranslateService) {}

	public selectedLanguage;

	public initializeLanguage(): void {
		const prevLang = localStorage.getItem('language');
		this.setDefaultLanguage('en');

		if (prevLang) {
			this.selectedLanguage = prevLang;
			this.changeLanguage(prevLang);
		} else {
			this.selectedLanguage = 'en';
		}
	}

	public setDefaultLanguage(lang: string): void {
		this.translate.setDefaultLang(lang);
	}

	public changeLanguage(lang: string): void {
		this.selectedLanguage = lang;
		localStorage.setItem('language', lang);
		this.translate.use(lang);
	}
}
