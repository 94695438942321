import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService, NetworkService, Network, networkTypes, JoinNetworkFormsComponent, PaginationService, SidebarComponent, SocketIoService } from '../../shared';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ToastService } from './../../shared/services/common/toast.service';

@Component({
	selector: 'app-user-home',
	templateUrl: './user-home.component.html',
	styleUrls: ['../home.component.css'],
})
export class UserHomeComponent implements OnInit {
	public loadingGif = 'none';
	public nextUrl;
	public paginating = false;
	public paginateSub$: any;
	public title;
	public errorStatus: any = '';
	public membershipList;
	public userNotifications;
	public userNotificationSummary;
	public currentUser;
	public networkTypes;
	public showExtraNetworkTypes = false;
	public networksTypes: any;
	public searchModalDisplay = 'none';
	public selectedNetworkType: any = null;
	public showComponent = true;
	public refreshData;
	public accessToken;
	public token_expires;
	public newExpiryDate;
	public defaultNetworkType;
	public defaultPurchasePlan;
	public displayError = 'none';
	public membershipId;
	public branchData;
	public displayDeleteDialog = 'none';
	public isShimmering = 0;
	public displayDelete = 'none';
	public deletedNetwork;

	public a;

	@ViewChild(JoinNetworkFormsComponent, { static: true }) public joinNetwork;
	@ViewChild(SidebarComponent, { static: true }) public sidebar;

	constructor(
		private userService: UserService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private http: HttpClient,
		private socket: SocketIoService,
		private networkService: NetworkService,
		private translate: TranslateService,
		private toastService: ToastService,
		private paginationService: PaginationService
	) {
		this.networkTypes = networkTypes;
		// eslint-disable-next-line spellcheck/spell-checker
		// ---For getting membership details [Optimising initial startup time] [23-03-20]
		this.paginationService.scrollDistance = 8;
		this.paginateSub$ = this.paginationService.initiateScroll.subscribe((data) => {
			if (data && !this.paginating) {
				this.paginate();
			}
		});
		this.membershipList = this.userService.memberships;
		this.nextUrl = this.userService.nextUrl;
	}

	ngOnInit(): void {
		if (localStorage.getItem('networkExpired')) {
			localStorage.removeItem('networkExpired');
		}
		if (localStorage.getItem('network_id')) {
			localStorage.removeItem('network_id');
		}

		this.title = 'Networks';
		this.getMembershipsLists();

		this.networksTypes = this.networkService.pupulateNetworkTypesArray(this.userService.getCurrentUser()['country'].networkService);
		this.membershipList.sort(function (m1, m2) {
			if (m1.network.networkType < m2.network.networkType) {
				return -1;
			} else if (m1.network.networkType > m2.network.networkType) {
				return 1;
			} else {
				return 0;
			}
		});

		this.userService.currentUser.subscribe((userDataResponse) => {
			if (userDataResponse.username) {
				this.currentUser = userDataResponse;

				// this.refreshAccessToken();
				if (this.currentUser.userId) {
					this.networksTypes = this.networkService.pupulateNetworkTypesArray(this.currentUser.country.networkService);
				}
			}
		});

		this.socket.getSocket().on('member-delete', (data) => {
			const socketData = JSON.parse(data);
			if (socketData['memberId']) {
				this.handleDeleteMembership(socketData['memberId']);
			}
		});

		const userData = this.userService.getCurrentUser();
		const obj = {
			username: userData['username'], // REQUIRED, must be unique
			email: userData['email'],
			// eslint-disable-next-line spellcheck/spell-checker
			firstname: userData['firstName'],
			// eslint-disable-next-line spellcheck/spell-checker
			lastname: userData['lastName'] ? userData['lastName'] : '',
			signUpAt: userData['dateJoined'], // Sign up date (yyyy-mm-dd hh:mm:ss)
		};

		if (userData) {
			setTimeout(() => {
				if (window['productFruitsUser'].username !== obj.username) {
					window['productFruits']['identifyUser'](obj);
				}
			}, 200);
		}
	}

	public loadNetwork(networkUid, membership): void {
		if (membership.manager && membership.manager === true) {
			if (!localStorage.getItem('manager')) {
				const userMembership = {
					manager: membership.manager,
					uid: membership.uid,
				};
				localStorage.setItem('manager', JSON.stringify(userMembership));
			}
		}

		if (membership.activityNotificationCount) {
			localStorage.setItem('notificationCount', membership.activityNotificationCount);
		}

		this.loadingGif = 'block';
		this.networkService.get(networkUid).subscribe((networkData) => {
			this.loadingGif = 'none';

			if (networkData['membership'].state === 1) {
				if (networkData['membership'] && networkData['membership']['memberRole']) {
					this.networkService.setNetwork(new Network(networkData));
					// ---Branch redirections
					if (this.branchData && this.branchData['contentType'] !== 'Network') {
						this.manageBranchRedirections();
					} else {
						this.router.navigateByUrl('/network');
					}
				} else {
					this.router.navigateByUrl('/workspace/profile-information?admin=true&id=' + networkUid + '&membership=' + networkData['membership']['uid']);
				}
			} else {
				this.checkForMembership(networkUid, membership);
			}
		});
	}

	private checkForMembership(networkUid, membership): void {
		let email = '';
		const membershipObject = membership;
		if (membershipObject.state === 0) {
			if (membershipObject['network']['adminUsername'] !== this.currentUser['username']) {
				this.membershipId = membershipObject.uid;
				if ((membershipObject.validationMethod === 1 || membershipObject.validationMethod === 2) && !membershipObject['network']['inviteOnly']) {
					if (membershipObject.network && membershipObject['network']['validationValue']) {
						const validationValue = membershipObject['network']['validationValue'];
						if (validationValue.indexOf('://') > -1) {
							const domain = validationValue.split('://')[1];
							email = '@' + domain;
						} else if (validationValue.indexOf('www.') > -1) {
							const domain = validationValue.split('www.')[1];
							email = '@' + domain;
						} else {
							email = '@' + validationValue;
						}
						this.router.navigateByUrl('/join-work?roleValidation=true&id=' + networkUid + '&membership=' + membershipObject.uid + '&email=' + email);
					} else {
						this.router.navigateByUrl('/join-work?roleValidation=true&id=' + networkUid + '&membership=' + membershipObject.uid);
					}
				}
				if (membershipObject['network'] && membershipObject['network']['inviteOnly']) {
					this.router.navigateByUrl('/join-work?inviteOnly=true&id=' + networkUid + '&membership=' + membershipObject.uid);
				}
			} else {
				let validationMethod: any;
				if (!membership['validationMethod']) {
					// =============================
					if (membership['network']['roles'] && membership['network']['roles'].length > 0) {
						const index = membership['network']['roles'].findIndex((role) => role.slug === 'external');
						if (index > -1) {
							membership['network']['roles'].splice(index, 1);
						}
						if (membership['network']['roles'].length > 1) {
							validationMethod = 'multiple-role';
							this.router.navigateByUrl(
								`/workspace/profile-information?admin=true&id=${networkUid}&membership=${membershipObject?.uid}&validationMethod=${validationMethod}`
							);
						} else {
							validationMethod = 'role';
							this.router.navigateByUrl(
								`/workspace/profile-information?admin=true&id=${networkUid}&membership=${membershipObject?.uid}&validationMethod=${validationMethod}`
							);
						}
					}
					// ============================
				} else {
					validationMethod = 'email';
					if (membership.network && membership['network']['validationValue']) {
						const validationValue = membership['network']['validationValue'];
						if (validationValue.indexOf('://') > -1) {
							const domain = validationValue.split('://')[1];
							email = '@' + domain;
						} else if (validationValue.indexOf('www.') > -1) {
							const domain = validationValue.split('www.')[1];
							email = '@' + domain;
						} else {
							email = '@' + validationValue;
						}
					}

					if (email) {
						this.router.navigateByUrl(
							`/workspace/profile-information?admin=true&id=${networkUid}&membership=${membershipObject.uid}&validationMethod=${validationMethod}&email=${email}`
						);
					} else {
						this.router.navigateByUrl(
							`/workspace/profile-information?admin=true&id=${networkUid}&membership=${membershipObject?.uid}&validationMethod=${validationMethod}`
						);
					}
				}
			}
		}
	}

	private openModel(): void {
		this.searchModalDisplay = 'block';
	}

	private closeModel(): void {
		this.searchModalDisplay = 'none';
	}

	private onNetworkTypeSelect(networkType): void {
		this.joinNetwork.onSelectNetworkType(networkType.slug);
	}

	// eslint-disable-next-line spellcheck/spell-checker
	// ---For getting membership details [Optimising initial startup time] [23-03-20]
	private getMembershipsLists(): void {
		this.isShimmering = 1;

		try {
			this.userService
				.getMemberships()
				.subscribe((data) => {
					if (data.next) {
						this.nextUrl = data.next.split('alpha')[1];
						this.userService.membership_nextUrl = data.next.split('alpha')[1];
					} else {
						this.nextUrl = '';
						this.userService.membership_nextUrl = '';
					}

					this.isShimmering = 0;
					this.membershipList = data.objects;
					this.userService.memberships = data.objects;
					this.sidebar.getMembershipsLists();

					this.handleBranches();
				})
				.add(() => {
					this.isShimmering = 0;
				});
		} catch (error) {
			console.log(error);

			this.toastService.error('Something went wrong');

			this.isShimmering = 0;
		}
	}

	// eslint-disable-next-line spellcheck/spell-checker
	// ---For paginating membership details [Optimising initial startup time] [23-03-20]
	public paginate(): void {
		if (this.nextUrl) {
			this.paginating = true;
			this.userService.paginate(this.nextUrl).subscribe((data) => {
				this.paginating = false;
				if (data.next) {
					this.nextUrl = data.next.split('alpha')[1];
					this.userService.nextUrl = data.next.split('alpha')[1];
				} else {
					this.nextUrl = '';
					this.userService.nextUrl = '';
				}
				this.membershipList = this.membershipList.concat(data.objects);
				this.userService.memberships = this.membershipList;
			});
		}
	}

	/**
	 * Function for calling refresh token api
	 * 1. Set current date
	 * 2. Set Token expiry date
	 * 3. Set url and params for refresh api
	 * 4. Check if current date is equal/greater than token expiry date
	 * 5. If true,call refresh token api
	 * 6. Set new access token and expires date on local storage
	 * 7. Call memberships api again
	 */
	private refreshAccessToken(): void {
		const currentDate = new Date();
		currentDate.setHours(0, 0, 0, 0); // 1
		const expires = localStorage.getItem('tokenExpires');
		const newExpires = new Date(expires);
		newExpires.setHours(0, 0, 0, 0); // 2
		const url = `${environment.api_url}/token/refresh/`;
		const params = { refresh: localStorage.getItem('refreshToken') }; // 3

		if (localStorage.getItem('tokenExpires') && localStorage.getItem('refreshToken')) {
			if (currentDate >= newExpires) {
				// 4
				this.http.post(url, params).subscribe(
					(data) => {
						// 5
						this.refreshData = data;
						this.accessToken = JSON.parse(this.refreshData._body).access;
						localStorage.removeItem('token');
						localStorage.removeItem('tokenExpires');
						window.localStorage['token'] = this.accessToken; // 5
						this.token_expires = JSON.parse(this.refreshData._body).expires;
						window.localStorage['tokenExpires'] = this.token_expires;

						this.getMembershipsLists(); // 7
					},
					(error) => {
						this.errorStatus = error.status;
						this.displayError = 'block';
					}
				);
			}
		}
	}

	/**
	 * Redundant code
	 */
	private callMembershipsApi(): void {
		const url = `${environment.api_url}/token/refresh/`;
		const params = { refresh: localStorage.getItem('refreshToken') };
		this.http.post(url, params).subscribe(
			(data) => {
				this.refreshData = data;
				this.accessToken = JSON.parse(this.refreshData._body).access;
				localStorage.removeItem('token');
				window.localStorage['token'] = this.accessToken;
				// --Save token expires to local storage
				this.token_expires = JSON.parse(this.refreshData._body).expires;
				window.localStorage['tokenExpires'] = this.token_expires;
				// --call memberships api with new access token
				this.getMembershipsLists();
			},
			() => {
				this.displayError = 'block';
			}
		);
	}

	/**
	 * 1. Check if branchData exists in LS.
	 * 2. Check branchdata contains network id.
	 * 3. Check network id is present in membershipslist.
	 * 4. If true,redirect user inside that network.
	 * 5. Else navigate to join flow page passsing network id as query param
	 * 6. Clear branch details.
	 */
	private handleBranches(): void {
		setTimeout(() => {
			if (localStorage.getItem('branchData')) {
				// 1
				this.branchData = JSON.parse(localStorage.getItem('branchData')); // 2
				if (this.branchData['networkId']) {
					// 3
					const index = this.membershipList.findIndex((membership) => membership['network']['uid'] === this.branchData['networkId']);
					if (index > -1) {
						// 4
						const membership = this.membershipList[index];
						this.loadNetwork(this.branchData['networkId'], membership);
						this.clearBranch();
					} else {
						if (this.branchData['contentType'] === 'Network') {
							this.router.navigateByUrl('/workspace/profile-information?branch_networkId=' + this.branchData['networkId']); // 5
						} else {
							this.router.navigateByUrl(
								'/workspace/profile-information?branch_networkId=' +
									this.branchData['networkId'] +
									'&content_type=' +
									this.branchData['contentType'] +
									'&content_uid=' +
									this.branchData['contentUid']
							); // 5
						}
					}
				}
			}
		}, 1100);
	}

	private manageBranchRedirections(): void {
		if (this.branchData && this.branchData['contentType']) {
			if (this.branchData['contentType'] === 'ToDoList') {
				this.router.navigateByUrl(`/project/team/${this.branchData['contentUid']}`);
			} else if (this.branchData['contentType'] === 'ToDo') {
				this.router.navigateByUrl('/project/task-detail/', this.branchData.contentUid);
			} else if (this.branchData['contentType'] === 'Customer') {
				this.router.navigateByUrl('/network/customer/' + this.branchData['contentUid']);
			} else if (this.branchData['contentType'] === 'Channels') {
				this.router.navigateByUrl('/network/view-channel?id=' + this.branchData['contentUid']);
			} else if (this.branchData['contentType'] === 'Collections') {
				this.router.navigateByUrl('/network/collection?id=' + this.branchData['contentUid']);
			} else if (this.branchData['contentType'] === 'Issue') {
				this.router.navigateByUrl('/network/issue-detail?id=' + this.branchData['contentUid']);
			} else if (this.branchData['contentType'] === 'Post') {
				this.router.navigateByUrl('/posts/' + this.branchData['contentUid']);
			} else if (this.branchData['contentType'] === 'External-Channels') {
				this.router.navigateByUrl('/network/view-channel?id=' + this.branchData['contentUid'] + '&external=true');
			}
		}
	}

	private clearBranch(): void {
		localStorage.removeItem('branchData');
		localStorage.removeItem('branch_session_first');
	}

	/**
	 * Handle membership delete functionality if user gets removed from network
	 * @param uid
	 * 1. Remove network if membership gets deleted
	 */
	private handleDeleteMembership(uid): void {
		const index = this.membershipList.findIndex((membership) => membership['uid'] === uid);
		if (index > -1) {
			this.deletedNetwork = this.membershipList[index]['network']['name'];
			this.displayDelete = 'block';
			this.membershipList.splice(index, 1); // 1
		}
	}
}
