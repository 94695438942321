import { UrlService } from './../../shared/services/url.service';
import { Component, Input, OnInit } from '@angular/core';
import { IURLAttachment } from '../../shared/models/url-attachment';
import { environment } from 'src/environments/environment';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'bee-url-preview',
	templateUrl: './url-preview.component.html',
	styleUrls: ['./url-preview.component.scss'],
})
export class UrlPreviewComponent implements OnInit {
	@Input() public url: IURLAttachment = null;

	public fallbackOgPreview = null;

	constructor(private urlService: UrlService) { }

	ngOnInit(): void {
		this.url.urlFavicon = this.urlService.getFaviconUrl(this.url.url);
		this.url.fileType = this.urlService.getExtensionFromUrl(this.url.url);

		if (!this.url.image) {
			this.createPlaceholderImage();
		}
	}

	public openUrl(): void {
		if (this.url?.url) {
			window.open(this.url?.url, '_blank');
		}
	}

	// #region Helpers

	private createPlaceholderImage(): void {
		let description = this.url.description;

		if (description && description.toLocaleLowerCase().includes('please enable js')) {
			description = '';
		}

		if (description.length > 100) {
			description = description.substring(0, 100) + '...';
		}

		// eslint-disable-next-line max-len, spellcheck/spell-checker
		this.fallbackOgPreview = `https://banners-laravel-ready.vercel.app/${this.url.title
			}.png?theme=light&packageManager=&packageName=&pattern=architect&style=style_2&description=${description
			// eslint-disable-next-line spellcheck/spell-checker
			}&md=1&showWatermark=1&watermarkText=${environment.appURL}&fontSize=100px&images=bookmark&heights=150`;
	}

	// #endregion
}
