import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IWorkspace } from '@shared/models/workspace/workspace';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'core-workspace-list-item',
	templateUrl: './workspace-list-item.component.html',
	styleUrls: ['./workspace-list-item.component.scss'],
})
export class WorkspaceListItemComponent implements OnInit {
	@Input() public workspace!: IWorkspace;
	@Output() public onClick: EventEmitter<IWorkspace> = new EventEmitter<IWorkspace>();

	constructor() {}

	ngOnInit(): void {}

	public onWorkspaceClick(): void {
		this.onClick.emit(this.workspace);
	}
}
