import { StartEndDayService } from '@shared/services/home/start-end-day.service';
import { Component, ElementRef, Input, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NetworkService, UserTodoCounts } from '@shared/services/network.service';
import { Chart, registerables } from 'chart.js';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import { Subscription, timer } from 'rxjs';
import doughnutChartPlugin from './../../../../../../projects/peer-core/src/lib/plugins/chartjs/doughnut-chart-plugin';

Chart.register(...registerables);

@Component({
	selector: 'app-daily-stats',
	templateUrl: './daily-stats.component.html',
	styleUrls: ['./daily-stats.component.scss'],
})
export class DailyStatsComponent implements OnInit, OnDestroy {
	@ViewChild('statsCircleCanvas') public statsCircleCanvas: ElementRef;

	@ViewChild('statsCircleCanvas') set load(x: any) {
		this.loadGraph();
	}
	@Input() public currentUser: any;

	private chartConfigs: any;
	public isStatsLoading = true;
	public userTodoCounts: UserTodoCounts | null = null;
	public userGraphData: any[] = [];
	public pointTableData: any | null = null;
	public dayOfWeek: string;
	public monthOfYear: string;
	public today: string;
	public greetingMessage: string;
	public totalStatsCount = 0;

	private chartInstance: Chart;
	private updateDayStatusEventSubscription: Subscription;
	private currentWorkspaceSubscription: Subscription;

	constructor(
		private networkService: NetworkService,
		private translateService: TranslateService,
		private startEndDayService: StartEndDayService,
		private changeDetectorRef: ChangeDetectorRef
	) {
		this.isStatsLoading = true;

		const now: Date = new Date();

		this.dayOfWeek = format(now, 'EEEE');
		this.monthOfYear = format(now, 'MMMM');
		this.today = format(now, 'dd');

		this.calculateGreeting();

		setInterval(() => {
			this.calculateGreeting();
		}, 1000 * 60 * 60 * 2);

		this.updateDayStatusEventSubscription = this.startEndDayService.updateDayStatusEvent.subscribe(() => {
			if (!this.userGraphData) {
				this.loadGraph();

				this.loadUserPoints();
			}
		});

		this.chartConfigs = {
			type: 'doughnut',
			data: {
				// labels: ['Label 1', 'Label 2', 'Label 3', 'Label 4'],
				datasets: [],
			},

			plugins: [doughnutChartPlugin],
		};
	}

	ngOnInit(): void {
		this.currentWorkspaceSubscription = this.networkService.currentNetwork.subscribe(() => {
			timer(4000).subscribe(() => {
				this.loadStartDayUserCounts();
				this.loadUserPoints();
			});
		});

		this.loadStartDayUserCounts();
		this.loadUserPoints();
	}

	ngOnDestroy(): void {
		this.destroyChart();
		this.updateDayStatusEventSubscription.unsubscribe();
		this.currentWorkspaceSubscription.unsubscribe();
	}

	// #region Loaders

	/**
	 * Get user's task stats
	 */
	private loadStartDayUserCounts(): void {
		this.isStatsLoading = true;

		this.networkService
			.getStartDayUserCounts()
			.subscribe((response: UserTodoCounts) => {
				this.userTodoCounts = response;
			})
			.add(() => {
				this.isStatsLoading = false;
				this.loadGraph();
			});
	}

	/**
	 * Load the user's points
	 */
	public loadUserPoints(): void {
		this.networkService.getPointsRewards().subscribe((response: any) => {
			this.pointTableData = response.count && response.objects ? response.objects.find((x: any) => x.userId === this.currentUser?.userId) : null;
		});
	}

	// #endregion

	/**
	 * Load the daily stats graph
	 */
	private loadGraph(): void {
		const drawGraph: any = this.statsCircleCanvas ? this.statsCircleCanvas.nativeElement : null;

		if (drawGraph) {
			const context = drawGraph.getContext('2d');

			const doughnutOptions: any = {
				...Chart.defaults.datasets.doughnut,
				...{
					cutout: '70%',
				},
			};

			// global configs
			Chart.defaults.elements.arc.borderWidth = 0;
			Chart.defaults.datasets.doughnut = doughnutOptions;
			Chart.defaults.plugins.tooltip.enabled = false;

			// #region Graph Data

			if (this.userGraphData) {
				this.totalStatsCount = this.userTodoCounts.today + this.userTodoCounts.future + this.userTodoCounts.past;

				this.changeDetectorRef.detectChanges();

				if (this.userGraphData.find((x: any) => x.label === this.translateService.instant('Today'))) {
					this.userGraphData[this.userGraphData.findIndex((x: any) => x.label === this.translateService.instant('Today'))].value = this.userTodoCounts?.today;
				} else {
					this.userGraphData.push({
						label: this.translateService.instant('Today'),
						value: this.userTodoCounts?.today,
						color: '#3AC295',
					});
				}

				if (this.userGraphData.find((x: any) => x.label === this.translateService.instant('Upcoming'))) {
					this.userGraphData[this.userGraphData.findIndex((x: any) => x.label === this.translateService.instant('Upcoming'))].value = this.userTodoCounts?.future;
				} else {
					this.userGraphData.push({
						label: this.translateService.instant('Upcoming'),
						value: this.userTodoCounts?.future,
						color: '#5ED9F4',
					});
				}

				if (this.userGraphData.find((x: any) => x.label === this.translateService.instant('Over Due'))) {
					this.userGraphData[this.userGraphData.findIndex((x: any) => x.label === this.translateService.instant('Over Due'))].value = this.userTodoCounts?.past;
				} else {
					this.userGraphData.push({
						label: this.translateService.instant('Over Due'),
						value: this.userTodoCounts?.past,
						color: '#f79009',
					});
				}

				// if total tasks bigger than 0
				if (this.totalStatsCount > 0) {
					this.chartConfigs.data.datasets = [
						{
							data: this.userGraphData.filter((x) => x.value > 0).map((x) => x.value),
							backgroundColor: this.userGraphData.filter((x) => x.value > 0).map((x) => x.color),
						},
					];
				} else {
					// if total tasks less than 0, print empty circle
					this.chartConfigs.data.datasets = [
						{
							data: [1],
							backgroundColor: ['#dedede'],
						},
					];
				}
			}

			// #endregion

			this.destroyChart();
			this.chartInstance = new Chart(context, this.chartConfigs);
		}
	}

	/**
	 * Destroy the chart before re-rendering
	 */
	private destroyChart(): void {
		if (this.chartInstance) {
			this.chartInstance.destroy();
		}
	}

	/**
	 * Calculate greeting message
	 */
	private calculateGreeting(): void {
		const now: Date = new Date(),
			hours = now.getHours();

		if (hours < 12) {
			this.greetingMessage = 'Good Morning';
		} else if (hours >= 12 && hours <= 17) {
			this.greetingMessage = 'Good Afternoon';
		} else if (hours >= 17 && hours <= 24) {
			this.greetingMessage = 'Good Evening';
		}
	}
}
