import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NetworkService, LayoutService } from '../../shared';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { SearchService } from '../../shared/services/search.service';
import { PostComponent } from '../../post/post.component';
import { CustomerBoardViewComponent } from '../customer-board-view/customer-board-view.component';

@Component({
	selector: 'app-customer-states',
	templateUrl: './customer-states.component.html',
	styleUrls: ['./customer-states.component.css'],
	encapsulation: ViewEncapsulation.None,
})
export class CustomerStatesComponent implements OnInit, OnDestroy {
	@ViewChild('postComponent', { static: true }) public postComponent: PostComponent;
	public menu = { value: '' };
	private stateSubscriber;
	public groupedStates = [];
	public filter = '';
	public isFetching = false;
	public postType;
	public showPostCreateForm = false;
	public priorities = [];
	public products = [];
	public dealTypes = [];
	public salesChannels = [];
	public customerTypes = [];
	// eslint-disable-next-line spellcheck/spell-checker
	public assignees = [];
	public selectedFilter;
	public searchSubscription: any;
	public isFirstSearch = true;
	public paidOptions = ['Today', 'Yesterday', 'Last Week', 'Last Month', 'All Time'];
	public showStatusBoardView = false;
	public customerRestriction: boolean;

	@ViewChild('customerBoardViewComponent', { static: true })
	public customerBoardViewComponent: CustomerBoardViewComponent;

	constructor(
		private networkService: NetworkService,
		private router: Router,
		private layoutService: LayoutService,
		private searchService: SearchService,
		private _location: Location
	) {}

	ngOnInit(): void {
		if (localStorage.getItem('networkExpired')) {
			this.router.navigateByUrl('/expired');
		}

		if (localStorage.getItem('customer_prev')) {
			const slug = localStorage.getItem('customer_prev');
			this.getGroupedByFilters(slug);
		} else {
			this.getGroupedStates();
		}

		if (window.location.pathname === '/network/customer-states') {
			this.searchSubscription = this.searchService.searchValue.subscribe((data) => {
				if (this.isFirstSearch && data.trim()) {
					this.isFirstSearch = false;
				}
				if (!this.isFirstSearch) {
					if (data) {
						this.selectedFilter = 'paid';
						this.filter = 'query=' + data.trim();
					} else {
						if (this.selectedFilter === 'paid') {
							this.selectedFilter = '';
							this.filter = '';
							this.getGroupedStates();
						}
					}
				}
			});
		}

		this.layoutService.showBackdrop.subscribe((data) => {
			if (data === 'event-post-form' || data === 'hide') {
				this.showPostCreateForm = false;
				this.postType = '';
			}
		});

		this.postComponent.newPostEmitter.subscribe((data) => {
			if (data) {
				if (this.selectedFilter && this.selectedFilter !== 'paid') {
					this.getGroupedByFilters(this.selectedFilter);
				} else {
					this.getGroupedStates();
				}
			}
		});

		this.networkService.currentNetwork.subscribe((network) => {
			this.customerRestriction = network?.subscription?.planQuotas?.numberOfCustomers >= 0 ? false : true;
		});
	}

	ngOnDestroy(): void {
		if (this.stateSubscriber) {
			this.stateSubscriber.unsubscribe();
		}
		if (this.searchSubscription) {
			this.searchSubscription.unsubscribe();
		}

		this.searchService.resetSearchValue();
	}

	public filterCustomersByClosed(key, value): void {
		this.selectedFilter = 'paid';
		this.menu['value'] = value;
		this.filter = key + '=' + value;
	}

	public getGroupedStates(): void {
		this.reset();
		this.filter = '';
		this.selectedFilter = '';
		this.stateSubscriber = this.networkService.getGroupedStatus().subscribe(
			(data) => {
				this.isFetching = true;
				this.groupedStates = data.data;
				const index = this.groupedStates.findIndex((state) => state.slug === 'in_progress');
				if (index > -1) {
					this.groupedStates.splice(index, 1);
				}
			},
			() => {
				this.isFetching = true;
			}
		);
	}

	public getGroupedByFilters(slug): void {
		this.selectedFilter = slug;
		this.reset();
		this.networkService.getGroupedByFilters(slug).subscribe(
			(data) => {
				this.isFetching = true;
				if (slug === 'priority') {
					this.priorities = data['data'];
				} else if (slug === 'product') {
					this.products = data['data'];
				} else if (slug === 'deal_type') {
					this.dealTypes = data['data'];
				} else if (slug === 'assignee') {
					// eslint-disable-next-line spellcheck/spell-checker
					this.assignees = data['data'];
				} else if (slug === 'sales_channel') {
					this.salesChannels = data['data'];
				} else if (slug === 'customer_type') {
					this.customerTypes = data['data'];
				}
			},
			() => {
				this.isFetching = true;
			}
		);
	}

	public reset(): void {
		this.filter = '';
		this.isFetching = false;
		this.groupedStates = [];
		this.priorities = [];
		this.products = [];
		this.salesChannels = [];
		this.dealTypes = [];
		this.customerTypes = [];
		// eslint-disable-next-line spellcheck/spell-checker
		this.assignees = [];
		this.menu['value'] = '';
		localStorage.removeItem('customer_prev');
		this.searchService.resetSearchValue();
	}

	public setCurrentFilter(): void {
		if (this.selectedFilter) {
			localStorage.setItem('customer_prev', this.selectedFilter);
		}
	}

	/**
	 * Function which activates plus button and displays modal
	 */
	public activateButton(): void {
		const scrollToTop = window.setInterval(() => {
			const position = window.pageYOffset;

			if (position > 0) {
				window.scrollTo(0, position - 50);
			} else {
				window.clearInterval(scrollToTop);
			}
		}, 3);

		this.layoutService.hideBd();
		this.showAddCustomer();
	}

	/**
	 * Function which  displays add customer Form
	 */
	public showAddCustomer(): void {
		this.showPostCreateForm = !this.showPostCreateForm;
		if (this.showPostCreateForm) {
			this.layoutService.showBd('event-post-form');
			this.postType = 'add-customer';
		}
	}

	/**
	 * Function to show customer status board view
	 */
	public showCustomerStatusBoardView(): void {
		this.showStatusBoardView = true;
		if (this.customerBoardViewComponent) {
			this.customerBoardViewComponent.showBoardView();
		}
	}

	/**
	 * Function to close customer status board view
	 */
	public onCloseModalEmitter(): void {
		this.showStatusBoardView = false;
		this.getGroupedStates();
	}
}
