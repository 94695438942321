import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenService } from 'projects/peer-core/src/lib/services/base/token.service';
import { HttpMethod } from '@core/types/http-method.enum';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class BaseApiService {
	constructor(private http: HttpClient, private tokenService: TokenService) {}

	public makeRequest(method: HttpMethod, endpoint: string, body: any = {}, params: HttpParams = new HttpParams(), options: any = {}): Observable<any> {
		return this.http.request(method, `${environment.api_url}/${endpoint}`, {
			body,
			headers: this.setHeaders(),
			params,
			...options,
		});
	}

	public makeRequestDirectly(method: HttpMethod, endpoint: string, body: any = {}, params: HttpParams = new HttpParams(), options: any = {}): Observable<any> {
		return this.http.request(method, `${endpoint}`, {
			body,
			headers: this.setHeaders(),
			params,
			...options,
		});
	}

	// #region Helpers

	private setHeaders(): HttpHeaders {
		const headersConfig: any = {
			'Content-Type': 'application/json',
		};

		if (this.tokenService.getToken()) {
			headersConfig['Authorization'] = `Bearer ${this.tokenService.getToken()}`;
		}

		return new HttpHeaders(headersConfig);
	}

	// #endregion
}
