import { Component, Input, OnInit } from '@angular/core';
import { DialogRef } from '../../shared/models/dialog-ref';
import { IFileAttachment } from '../../shared/models/file-attachment';
import { DialogService } from '../../shared/services/dialog.service';
import { BeePdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { AttachmentService } from '../../shared/services/attachment.service';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'bee-file-preview',
	templateUrl: './file-preview.component.html',
	styleUrls: ['./file-preview.component.scss'],
})
export class BeeFilePreviewComponent implements OnInit {
	@Input() public files: IFileAttachment[];
	@Input() public maxTextLength = 45;

	private dialogRef: DialogRef | null = null;

	constructor(private dialogService: DialogService, private attachmentService: AttachmentService) {}

	ngOnInit(): void {
		this.maxTextLength = this.attachmentService?.options?.maxTextLength || this.maxTextLength;
	}

	public openViewModal(): void {
		return;

		let component: any = null;

		if (this.files[0]?.ext === 'PDF') {
			component = BeePdfViewerComponent;
		}

		if (component) {
			this.dialogRef = this.dialogService.open(component, { data: this.files[0] });

			this.dialogRef.afterClosed().subscribe(() => {
				// Subscription runs after the dialog closes
				console.log('Dialog closed!');
			});
		}
	}
}
