import { Injectable } from '@angular/core';
import { ApiService } from '@shared/services';
import CacheUtils from '@shared/utils/cache-utils';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ChatService {
	private networkId: string | null = null;
	private paginationObservable: Observable<any>;

	constructor(private apiService: ApiService) {
		this.networkId = CacheUtils.getStoredData('network_id');
	}

	/**
	 * Get all members in the network to be displayed as suggestions
	 * @param filter filter keyword (optional)
	 */
	public getAllUserSuggestions(query: string = '', filter: string = ''): Observable<any> {
		const currentNetworkID: string | null = localStorage.getItem('network_id');
		if (query) {
			return this.apiService.get(query);
		} else if (currentNetworkID) {
			if (filter) {
				return this.apiService.get('/networks/' + currentNetworkID + '/members/?filter=active&' + filter);
			} else {
				return this.apiService.get('/networks/' + currentNetworkID + '/members/?filter=active');
			}
		}
	}

	/**
	 * Get pagination data
	 */
	public getPaginationUrlData(url: string): Observable<any> {
		if (this.paginationObservable) {
			return this.paginationObservable;
		}

		this.paginationObservable = this.apiService.get(url);

		return this.paginationObservable;
	}

	/**
	 * Search conversations in the network
	 */
	public searchConversations(networkId: string, query: string, userId: string): Observable<any> {
		return this.apiService.chatGet(`/${userId}/conversations?network=${networkId}&query=${query}`);
	}

	/**
	 * Send Notification ?
	 */
	public sendLayerNotification(username, data): Observable<any> {
		// eslint-disable-next-line spellcheck/spell-checker
		return this.apiService.post('/users/' + username + '/layernotification/', data);
	}

	/**
	 * Get Members from network
	 */
	public getMembers(uid: any, q = '', filter = ''): Observable<any> {
		if (q) {
			return this.apiService.get(q);
		} else if (filter) {
			return this.apiService.get('/networks/' + uid + '/members/' + '?filter=active&' + filter);
		} else {
			return this.apiService.get('/networks/' + uid + '/members/?filter=active');
		}
	}

	/**
	 * Get members for chat
	 */
	public getMembersForChat(search: string): Observable<any> {
		return this.apiService.get('/networks/' + localStorage.getItem('network_id') + '/users/?query=' + search);
	}

	/**
	 * Paginate members list
	 * @param url
	 * @param filters
	 * @returns
	 */
	public paginate(url, filters = null): Observable<any> {
		let slug = 'all';
		let filterType = 'cat';
		if (filters) {
			slug = filters.slug;
			filterType = filters.filterType;
		}

		if (slug !== 'all') {
			if (filterType === 'cat') {
				return this.apiService.get(url + '&category=' + slug);
			} else if (filterType === 'role') {
				return this.apiService.get(url + '&role=' + slug);
			} else if (filterType === 'channel') {
				return this.apiService.get(url + '&channel=' + slug);
			} else if (filterType === 'all') {
				/** -----filter Everything--- */
				return this.apiService.get(url);
			} else if (filterType === 'only_me') {
				return this.apiService.get(url + '&' + slug + '= true');
			} else {
				/** Paginate personal filter other than only me */
				return this.apiService.get(url + '&' + filterType + '=' + slug);
			}
		} else {
			return this.apiService.get(url);
		}
	}
}
