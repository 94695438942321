import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { IEventAttachment, IEventStatus, IEventType } from '../../shared/models/event-attachment';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'bee-event-preview',
	templateUrl: './event-preview.component.html',
	styleUrls: ['./event-preview.component.scss'],
})
export class BeeEventPreviewComponent implements OnInit {
	@Input() public event!: IEventAttachment;
	@Output() public eventButtonClicked: EventEmitter<IEventAttachment> = new EventEmitter<IEventAttachment>();

	public EventType: typeof IEventType = IEventType;
	public EventStatus: typeof IEventStatus = IEventStatus;

	constructor() {}

	ngOnInit(): void {}

	public eventButtonClick(): void {
		this.eventButtonClicked.emit(this.event);

		this.event.startDate = moment('2023/03/01').format('d MMM HH:mm a');

		if (this.event.isOnlineMeeting && this.event.status !== this.EventStatus.COMPLETED) {
			if (moment(this.event.startDate).add('hour').unix() > moment().unix()) {
				alert('Event is Passed');

				this.event.status = this.EventStatus.COMPLETED;

				return;
			}

			window.open(this.event.onlineMeetingUrl, '_blank');
		}
	}
}
