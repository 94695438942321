import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
	selector: 'app-assignee-label',
	templateUrl: './assignee-label.component.html',
	styleUrls: ['./assignee-label.component.scss'],
})
export class AssigneeLabelComponent implements OnInit {
	@Input() public assignee: any | any[];
	@Input() public assigner: any;
	@Input() public size = 'md';
	@Input() public useOnlyFirstName: boolean;
	@Input() public assigneeDetails: any;

	constructor() {}

	ngOnInit(): void {}
}
