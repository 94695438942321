import { Comment } from './comment.model';

// eslint-disable-next-line spellcheck/spell-checker
export class ClassiFied {
	public uid: number;
	public author: any;
	public type: string;
	public contentType: string;
	public contentUid: string;
	public myRelations: any;
	public commentable: boolean;
	public comments: any;
	public bookmarkCount: number;
	public commentCount: number;
	public roleDetails: any;
	public flagCount: number;
	public resourceUri: string;
	public created: any;
	public privacy: any;
	public shareable: any;
	public likeCount: number;
	public starts: string;
	public ends: string;
	public finePrint: string;
	public postDetails: any;
	public public: boolean;
	public images: any;
	public body: any;
	public discountType: number;
	public condition: string;
	public category: any;
	public locationCity: any;
	public price: any;
	public dislikeCount: number;
	public dislikes: number;
	public contentPrivacy: any;
	public commentUrl: string;
	public show: boolean;
	public commentFirstClick: boolean;

	constructor(classified: any) {
		for (const key of Object.keys(classified)) {
			if (key in classified) {
				this[key] = classified[key];
			}
		}
		if (classified.lastComments) {
			this.comments = [new Comment(classified.lastComments[0])];
		} else {
			this.comments = [];
		}
		this.commentUrl = classified.commentUrl;
		this.show = true;
		this.commentFirstClick = true;
		this.commentUrl = '/posts/' + classified.uid + '/comments/';
		if (this.body) {
			this.body = this.body.length > 100 ? this.body.substr(1, 100) + '..' : this.body;
		}
	}

	public setCommentUrl(data): void {
		if (data.next) {
			this.commentUrl = data.next.split('alpha')[1];
		} else {
			this.commentUrl = '';
		}
	}
}
