import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { CalendarEvent, CalendarView, CalendarMonthViewBeforeRenderEvent } from 'angular-calendar';
import { Subject } from 'rxjs';
import { NetworkService } from '../services';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';

@Component({
	selector: 'app-due-date',
	templateUrl: './due-date.component.html',
	styleUrls: ['./due-date.component.css'],
})
export class DueDateComponent implements OnInit {
	public element: HTMLElement;
	public dialog = 'none';
	public errorDialog = 'none';
	public refresh: Subject<any> = new Subject();

	public view: CalendarView = CalendarView.Month;
	public CalendarView = CalendarView;
	public viewDate: Date = new Date();
	public events: CalendarEvent[];
	public renderEvents = [];
	public locale = localStorage.getItem('language');

	public oneOff;
	public day;
	public tomorrow;
	public text;
	public selectedDueDate = 'daily';
	public startTime;
	public endTime;
	public currentTime;
	public showRepeat = false;
	public selectedRepeat;
	public repeats = ['daily', 'weekly', 'monthly', 'yearly'];
	public reminders: any[] = [];
	public selectedReminders: any[] = [];
	public previousReminders: any[] = [];
	public showReminders = false;
	public disabled = false;
	public modalStatus: EventEmitter<any> = new EventEmitter();

	@Output() public successfullyFetchedRemainderDetailsEmitter: EventEmitter<any> = new EventEmitter();

	public beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
		this.renderEvents = renderEvent.body;
	}

	constructor(private networkService: NetworkService, private translate: TranslateService) {}

	ngOnInit(): void {
		this.day = this.viewDate.getDate();
		const today = new Date();
		this.tomorrow = new Date();
		this.tomorrow.setDate(today.getDate() + 1);

		// timer(500).subscribe(() => {
		// 	this.dayClicked({ date: this.viewDate });
		// 	this.setDueDate('one_off');
		// });
	}

	public display(): void {
		this.dialog = 'block';
		this.showReminders = false;
	}

	/**
	 * Function on setting due date in calendar popup
	 *
	 * @param value
	 */
	public setDueDate(value): void {
		if (this.selectedDueDate === value) {
			return;
		}

		this.selectedDueDate = value;
		this.startTime = null;
		this.endTime = null;
		this.oneOff = '';
		this.selectedRepeat = '';
		this.selectedReminders = [];
		this.reminders.forEach((reminder) => {
			reminder['checked'] = false;
		});

		this.renderEvents.forEach((d) => {
			if (this.day !== d.date.getDate()) {
				d['cssClass'] = 'bg-white';
			}
		});

		if (this.selectedDueDate === 'weekly' || this.selectedDueDate === 'monthly') {
			this.disabled = true;
		} else if (this.selectedDueDate === 'one_off') {
			this.disabled = false;
			this.currentTime = null;
			const date = new Date();

			this.setDate(date);
		} else if (this.selectedDueDate === 'tomorrow') {
			this.currentTime = null;
			this.disabled = false;

			this.renderEvents.forEach((d) => {
				if (this.tomorrow.getDate() === d.date.getDate()) {
					d['cssClass'] = 'bg-peach';
					const date = new Date(d.date);
					this.setDate(date);
				}
			});
		} else {
			this.disabled = false;
			this.currentTime = this.viewDate.getHours() + ':' + this.viewDate.getMinutes();
		}
	}

	public showPreviouslySelectedDate(selectedDate): void {
		this.renderEvents.forEach((d) => {
			if (selectedDate.getDate() === d.date.getDate() && selectedDate.getMonth() === d.date.getMonth()) {
				d['cssClass'] = 'bg-peach';
				const date = new Date(d.date);
				this.setDate(date);
			} else {
				delete d['cssClass'];
			}
		});
	}

	/**
	 * Function on clicking start/end time fields
	 * @param type
	 */
	public onClick(type): void {
		if (type === 'start') {
			this.showReminders = false;
			this.selectedReminders = [];

			if (this.disabled) {
				this.errorDialog = 'block';
				this.text = this.translate.instant('Select Today or Specify Date as due date to select start time');
			} else {
				this.errorDialog = 'none';
				this.text = '';
			}
		} else {
			if (this.disabled) {
				this.errorDialog = 'block';
				this.text = this.translate.instant('Select Today or Specify Date as due date to select end time');
			} else {
				this.errorDialog = 'none';
				this.text = '';
			}
		}
	}

	/**
	 * For getting reminder options in popup
	 */
	public getReminders(): void {
		this.reminders = [];

		this.networkService.getReminderOptions().subscribe((data) => {
			if (!this.startTime) {
				this.reminders = data.reminderOptions;
			} else {
				data.eventReminderOptions.forEach((element) => {
					if (
						element[0] === 'event_time' ||
						element[0] === 'five_minutes_before' ||
						element[0] === 'one_hour_before' ||
						element[0] === 'thirty_minutes_before' ||
						element[0] === 'two_hour_before'
					) {
						this.reminders.push(element);
					}
				});
			}

			this.setPreviousReminders();
		});
	}

	/**
	 * Function on selecting reminders
	 * @param reminder
	 */
	public onSelectReminder(reminder): void {
		const index = this.selectedReminders.findIndex((r) => r === reminder);

		if (index > -1) {
			this.selectedReminders.splice(index, 1);
		} else {
			this.selectedReminders.push(reminder);
		}
	}

	/**
	 * For setting reminders already set by user
	 */
	public setPreviousReminders(): void {
		this.reminders.forEach((reminder) => {
			this.selectedReminders.forEach((r) => {
				if (r === reminder[0]) {
					reminder['checked'] = true;
				}
			});
		});
	}

	/**
	 * Function on clicking a day in calendar
	 *
	 * @param day
	 */
	public dayClicked(day): void {
		this.selectedDueDate = 'one_off';
		const date = new Date(day.date);
		this.currentTime = null;
		this.setDate(date);

		this.renderEvents.forEach((d) => {
			if (this.day !== d.date.getDate()) {
				d['cssClass'] = 'bg-white';
			}
		});

		if (this.day !== day.date.getDate()) {
			day.cssClass = 'bg-peach';
		}
	}

	/**
	 * This will set 'oneOff' date
	 * @param date
	 */
	public setDate(date: Date): void {
		this.oneOff = format(date, 'yyyy-MM-dd');

		/**
		 * In case of UTC conversions
		 */
		// this.oneOff = date.toISOString().substring(0, 10);
	}

	// eslint-disable-next-line spellcheck/spell-checker, @typescript-eslint/no-unused-vars
	public setPreviousDateOnCalender(date): void {
		// this.renderEvents.forEach((d) => {
		//   if (date.getDate() === d.date.getDate()) {
		// eslint-disable-next-line spellcheck/spell-checker
		//     d['cssClass'] = 'bg-peach'
		//     //let date = new Date(d.date);
		//     //this.setDate(date);
		//   }
		// });
	}

	/**
	 * Get Previous reminders of a todo
	 * @param todo
	 */
	public getContentReminders(todo): void {
		this.networkService.getContentReminders('todos', todo['uid']).subscribe((data) => {
			this.successfullyFetchedRemainderDetailsEmitter.emit();

			if (data.objects && data.objects.length > 0) {
				data.objects.forEach((element) => {
					if (element['reminderOption']) {
						const i = this.selectedReminders.findIndex((reminder) => reminder === element['reminderOption']);

						if (i === -1) {
							this.selectedReminders.push(element['reminderOption']);
							this.previousReminders.push(element['reminderOption']);
						}
					} else if (element['eventReminderOption']) {
						const i = this.selectedReminders.findIndex((reminder) => reminder === element['eventReminderOption']);

						if (i === -1) {
							this.selectedReminders.push(element['eventReminderOption']);
							this.previousReminders.push(element['eventReminderOption']);
						}
					}
				});
			}
			this.setPreviousReminders();
		});
	}

	/**
	 * Close due date modal
	 */
	public closeModal(): void {
		this.dialog = 'none';
		this.modalStatus.emit('closed');

		this.renderEvents.forEach((d) => {
			if (this.day !== d.date.getDate()) {
				d['cssClass'] = 'bg-white';
			}
		});
	}

	/**
	 * Reset all variables after todo creation/close todo modal
	 */
	public reset(): void {
		this.selectedRepeat = '';
		this.selectedReminders = [];
		this.previousReminders = [];
		this.reminders = [];
		this.showReminders = false;
		this.selectedDueDate = 'daily';
		this.oneOff = '';
		this.startTime = null;
		this.endTime = null;
		this.currentTime = this.viewDate.getHours() + ':' + this.viewDate.getMinutes();
	}

	public displayStartTime(): void {
		// this.element = document.getElementById('start') as HTMLElement;
		// this.element.click();
	}

	public displayEndTime(): void {
		// this.element = document.getElementById('end') as HTMLElement;
		// this.element.click();
	}

	public hourSelected(event): void {
		const tempTime = event.split(':');
		this.startTime = tempTime[0] + ':' + tempTime[1];

		/**
		 * Add event time and 30min before reminders upon selecting a start-time (AS DEFAULT)
		 */
		this.networkService.getReminderOptions().subscribe((data) => {
			data?.eventReminderOptions?.forEach((element: any) => {
				if (element[0] === 'event_time' || element[0] === 'thirty_minutes_before') {
					// Check for event_time and thirty_minutes_before reminders
					const index = this.selectedReminders.findIndex((r) => r === element[0]);
					if (index === -1) {
						// Check whether the reminders already exist, if not, add them to list of selected reminders
						this.selectedReminders.push(element[0]);
					}
				}
			});
		});
	}

	/**
	 * Configure repeat frequency
	 * 1. If unchecked, then selected repeat frequency will be null
	 * 2. If checked, then selected repeat frequency will be the chosen repeat frequency
	 * @param repeat selected task repeat frequency
	 */
	public setTaskRepeat(repeat: string): void {
		if (repeat === this.selectedRepeat) {
			this.selectedRepeat = ''; // 1
		} else {
			this.selectedRepeat = repeat; // 2
		}
	}
}
