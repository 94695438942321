import { Injectable } from '@angular/core';
import CacheUtils from '@shared/utils/cache-utils';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class SegmentAnalyticsService {
	private userDetails: any = null;
	constructor() {}

	/**
	 * Saves user information from cache to be used by the service
	 */
	private setUserData(): void {
		if (environment.production) {
			if (typeof CacheUtils.getStoredData('userDetails') === 'string') {
				this.userDetails = JSON.parse(CacheUtils.getStoredData('userDetails'));
			} else {
				this.userDetails = CacheUtils.getStoredData('userDetails') ?? JSON.parse(localStorage.getItem('userDetails'));
			}
		}
	}

	/**
	 * Setup user details for identify calls
	 * @param data user details
	 */
	public setupUserData(data: any): void {
		if (!data) {
			this.setUserData();
		}

		this.identify(data);
		this.identifyForHeap(data);
	}

	// #region Segment Triggers

	/**
	 * Handles track event triggers
	 * @param event Title of the event to be tracked
	 * @param payload Event data
	 */
	public trackEvent(event: string, payload: any): void {
		if (environment.production) {
			this.setUserData();
			try {
				window?.analytics?.track(event, { ...payload });
			} catch (error) {
				console.error('Track Error', error);
			}
		}
	}

	/**
	 * Handles user identification requests
	 * @param data user data
	 */
	private identify(data: any): void {
		if (environment.production) {
			try {
				window?.analytics?.identify(data?.userId ?? this.userDetails?.userId, {
					name: data?.name ?? this.userDetails?.name,
					email: data.email ?? this.userDetails?.email,
				});
			} catch (error) {
				console.error('Identify Error', error);
			}
		}
	}

	/**
	 * Set Current Page
	 * @param page Current Page
	 */
	public setPage(page: string): void {
		if (environment.production) {
			try {
				window.analytics.page(page);
			} catch (error) {
				console.error('Page Error', error);
			}
		}
	}

	// #endregion

	// #region Heap Analytics

	/**
	 * Handles user identification requests for HEAP Analytics
	 * @param data user data
	 */
	private identifyForHeap(data: any): void {
		if (environment.production) {
			try {
				window?.heap?.identify(data?.userId ?? this.userDetails?.userId);
			} catch (error) {
				console.error('HEAP:- Identify Error', error);
			}
		}
	}

	/**
	 * Handles adding user properties requests for HEAP Analytics
	 * @param body user properties
	 */
	public addUserPropertiesForHeap(body: any): void {
		if (environment.production) {
			try {
				window?.heap?.addUserProperties(body);
			} catch (error) {
				console.error('HEAP:- Add User Properties', error);
			}
		}
	}

	// #endregion
}
