import { timer } from 'rxjs';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { ISidebar, Sidebar, ISidebarMenu, ISidebarMenuItem } from '../../models/sidebar';

@Injectable({
	providedIn: 'root',
})
export class SidebarService {
	@Output() public sidebarCollapsed: EventEmitter<boolean> = new EventEmitter<boolean>();

	public collapsedStatus = false;
	public collapsedStatusDelayed = false;
	public sidebar: ISidebar | null = null;

	constructor() {
		this.newSidebar(new Sidebar());
	}

	/**
	 * Create new sidebar
	 *
	 * @param sidebar ISidebar
	 * @return void
	 */
	public newSidebar(sidebar: ISidebar = new Sidebar()): SidebarService {
		if (!this.sidebar) {
			this.sidebar = sidebar;
		}

		return this;
	}

	/**
	 * Get sidebar
	 *
	 * @returns ISidebar
	 */
	public getSidebar(): ISidebar {
		return this.sidebar;
	}

	/**
	 * Add sidebar menu item
	 *
	 * @param menu ISidebarMenu
	 * @returns SidebarService
	 */
	public addMenu(menu: ISidebarMenu): SidebarService {
		this.sidebar.items.push(menu);

		return this;
	}

	/**
	 * Append child menu item to parent menu
	 *
	 * @param menu ISidebarMenu
	 * @param child ISidebarMenuItem
	 * @returns SidebarService
	 */
	public appendChild(menu: ISidebarMenu, child: ISidebarMenuItem): SidebarService {
		this.sidebar.items.find((item: ISidebarMenu) => item === menu)?.children?.push(child);

		return this;
	}

	public setSidebarCollapseStatus(status: boolean): void {
		this.collapsedStatus = status;

		timer(165).subscribe(() => {
			this.collapsedStatusDelayed = status;
		});

		this.sidebarCollapsed.emit(status);
	}
}
