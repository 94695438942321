import { Injectable } from '@angular/core';

export enum StorageType {
	Local,
	Session
}

@Injectable({
	providedIn: 'root'
})
export class StorageService {

	constructor() { }
	private getStorage(type: StorageType): Storage {
		return type === StorageType.Local ? localStorage : sessionStorage;
	}

	public setItem(key: string, value: any, type: StorageType = StorageType.Local): void {
		const storage = this.getStorage(type);
		storage.setItem(key, JSON.stringify(value));
	}

	public getItem<T>(key: string, type: StorageType = StorageType.Local): T | null {
		const storage = this.getStorage(type);
		const item = storage.getItem(key);
		return item ? JSON.parse(item) as T : null;
	}

	public removeItem(key: string, type: StorageType = StorageType.Local): void {
		const storage = this.getStorage(type);
		storage.removeItem(key);
	}

	public clear(type: StorageType = StorageType.Local): void {
		const storage = this.getStorage(type);
		storage.clear();
	}
}
