import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Errors, UserService, GeoLocationService, PbTranslateService } from '../shared';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
import { ApiService } from '../shared/services/api.service';
import { debounceTime } from 'rxjs/operators';
import * as googlePhoneLib from 'google-libphonenumber';
import { MixPanelService } from '@core/services/third-party/mix-panel.service';
import * as moment from 'moment';
import { SegmentAnalyticsService } from '@core/services/third-party/segment-analytics.service';
import { AuthenticationService } from '@modules/new-auth/services/authentication.service';

// declare function partnerStackSignUp(object): any;

declare let stripePayment;

@Component({
	selector: 'app-auth-page',
	templateUrl: './auth.component.html',
	// changeDetection: ChangeDetectionStrategy.Default,
})
export class AuthComponent implements OnInit {
	@ViewChild('digit', { static: false }) public digit: ElementRef;
	public loadingGif = 'none';
	public language = 'en';
	public authType: any = '';
	public title: String = '';
	public errors: Errors = new Errors();
	public isSubmitting = false;
	public isProcessing = false;
	public countries: any[] = [];
	public selectedCountry: any[] = [];
	public selectedCountryIndex = 0;
	public checked = true;
	public show = false;
	public showPassword = false;
	public showConfirmPassword = false;
	public nextUrl;
	public modalText;
	public displayErrorDialog = 'none';
	public countryUid: any;
	private subject: Subject<string> = new Subject();
	public clearSearch = false;
	public branchData;
	public registerFormType = 'phone';
	public currentLocation;
	public authForm: FormGroup;
	public ssoForm: FormGroup;
	public phoneForm: FormGroup;
	public emailForm: FormGroup;
	public validationForm: FormGroup;
	public userDetailForm: FormGroup;
	public passwordError = false;
	public countryCode = null;
	public errorMessage = '';
	public resetUrl = '';
	public verificationCode = '';
	public showSuccessMessage = false;
	public showCountryModal = 'none';
	public option = { value: '' };
	public field = { value: '' };
	public selectedField: any;
	public currentCountry;
	public code: any;
	public searchString;
	public isLoading = false;
	public displayError = false;
	public defaultNetworkType;
	public alphaCountryCode = 'TR';
	public latitude;
	public longitude;
	public country;
	public defaultCountry;
	public appSumoCode: any;
	public f6sCode: any;
	public f6sAccountDetails: any;
	public showPaymentDetails = 'none';
	public paymentIntent: any;
	public paymentForm: FormGroup;
	public appLogo = environment.logo;

	@ViewChild('cardNumber', { static: false }) public cardNumber: ElementRef;
	@ViewChild('cardCvc', { static: false }) public cardCvc: ElementRef;
	@ViewChild('cardExpiry', { static: false }) public cardExpiry: ElementRef;

	public stripe;
	public card;
	public expiry;
	public cvc;
	public stripeKey;

	public displayStripeError = 'none';
	/** End */

	public displayErrorCouponCode = 'none';
	public couponErrorText = '';
	public couponCodeType: 'appsumo' | 'f6s' | 'noCouponCode' = 'noCouponCode';
	public signupType: 'phone' | 'email' = 'email';
	public signInType: 'phone' | 'email' = 'phone';

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private userService: UserService,
		private http: HttpClient,
		private apiService: ApiService,
		private fb: FormBuilder,
		private geoLocationService: GeoLocationService,
		public translateService: TranslateService,
		public translate: PbTranslateService,
		private changeDetectorRef: ChangeDetectorRef,
		private mixPanelService: MixPanelService,
		private segmentService: SegmentAnalyticsService,
		private authenticationService: AuthenticationService
	) {
		this.authForm = this.fb.group({
			countryName: [''],
			phone: ['', Validators.required],
			password: ['', Validators.required],
			email: ['', [Validators.required]],
		});

		this.ssoForm = this.fb.group({
			email: ['', Validators.required],
		});

		this.phoneForm = this.fb.group({
			// countryName: ['', Validators.required],
			// countryCode: ['', Validators.required],
			// phone: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
			// email: [''],
			countryName: [''],
			countryCode: [''],
			phone: [''],
			email: ['', [Validators.required, Validators.email]],
		});

		this.emailForm = this.fb.group({
			email: ['', [Validators.required, Validators.email]],
			countryName: [''],
			appsumo: [''],
			f6s: [''],
		});

		this.validationForm = this.fb.group({
			digit1: ['', Validators.required],
			digit2: ['', Validators.required],
			digit3: ['', Validators.required],
			digit4: ['', Validators.required],
			digit5: ['', Validators.required],
			digit6: ['', Validators.required],
		});

		this.userDetailForm = this.fb.group(
			{
				name: ['', Validators.required],
				password: ['', [Validators.required, Validators.minLength(8)]],
				confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
				email: ['', [Validators.required, Validators.email]],
				cardHolderName: [''],
			},
			{ validator: this.passwordMatchValidator }
		);

		// Form validation for name,email,address in payment form
		this.paymentForm = this.fb.group({
			name: ['', [Validators.required, Validators.minLength(2)]],
		});

		this.onClickSignInWithEmail();
		// setTimeout(() => {
		// 	this.displayCountryModal();
		// }, 2000);
	}

	ngOnInit(): void {
		window['dataLayer'] = window['dataLayer'] || [];
		this.userService.isAuthenticated.subscribe((authenticated) => {
			if (authenticated) {
				this.router.navigateByUrl('/');
			}
		});

		const accuracy = { enableHighAccuracy: true };
		this.geoLocationService.getLocation(accuracy).subscribe(
			(position) => {
				this.currentLocation = position;
				if (this.currentLocation['coords']) {
					this.latitude = this.currentLocation['coords']['latitude'];
					this.longitude = this.currentLocation['coords']['longitude'];
					// this.callGeoNames();
				}
			},
			() => {
				// this.setDefaultCountry();
			}
		);

		// this.setDefaultCountry();

		this.resetUrl = environment.reset_password_url;

		this.route.url.subscribe((data) => {
			// Get the last piece of the URL (it's either 'login' or 'register')
			this.authType = data[data.length - 1].path;
			// this.authType = 'register';
			// this.registerFormType = 'name';

			// Set a title for the page accordingly
			this.title = this.authType === 'login' || this.authType === 'sso-login' ? 'Sign In' : 'Sign Up';
			// add form control for username if this is the register page

			if (this.title === 'Sign In') {
				window.dataLayer.push({
					event: 'gaEvent', // Event Name
					// eslint-disable-next-line spellcheck/spell-checker
					Category: 'Sign In', // Login ise Login
					Action: 'Initiate - Sign In',
					// eslint-disable-next-line spellcheck/spell-checker
					Label: 'tr', // en sayfaları için en olark gönderilmeli
					// eslint-disable-next-line spellcheck/spell-checker
					language_type: 'tr', // dil seçeneğine göre dinamik olaraak beslenecek
				});
			} else if (this.title === 'Sign Up') {
				window.dataLayer.push({
					event: 'gaEvent', // Event Name
					// eslint-disable-next-line spellcheck/spell-checker
					Category: 'Sign Up', // Login ise Login
					Action: 'Initiate - Sign Up',
					// eslint-disable-next-line spellcheck/spell-checker
					Label: 'tr', // en sayfaları için en olark gönderilmeli
					// eslint-disable-next-line spellcheck/spell-checker
					language_type: 'tr', // dil seçeneğine göre dinamik olaraak beslenecek
				});
			}
		});

		this.route.queryParams.subscribe((params: Params) => {
			this.showSuccessMessage = params['showSuccessMessage'];
		});

		if (localStorage.getItem('language') && localStorage.getItem('language') === 'tr') {
			this.language = 'tr';
		}

		this.subject.pipe(debounceTime(350)).subscribe((searchTextValue) => {
			this.isLoading = true;
			this.displayError = false;
			this.countries = [];

			this.userService.searchCountries(searchTextValue).subscribe((data) => {
				if (data.results.length > 0) {
					this.isLoading = false;
					this.countries = data.results;

					this.setDefaultCountry();
				} else {
					this.displayError = true;
					this.isLoading = false;
				}
			});
		});

		this.validationForm.valueChanges.subscribe((value) => {
			if (value && value['digit6']) {
				// Check for presence of last digit
				this.submitOtpForm(); // Submit otp validation form after all digits are entered
			}
		});

		/** Appsumo code extraction **/
		this.route.params.subscribe((params: Params) => {
			if (params && params['coupon']) {
				this.appSumoCode = params['coupon'];
				this.emailForm.patchValue({
					appsumo: this.appSumoCode,
					f6s: '',
				});
				this.f6sCode = '';
				this.authType = 'register';
				this.registerFormType = 'email';
				this.couponCodeType = 'appsumo';
				this.addRemoveCouponCodeValidation();
			}
		});

		/** F6s code extraction **/
		this.route.params.subscribe((params: Params) => {
			if (params && params['f6sCoupon']) {
				this.f6sCode = params['f6sCoupon'];
				this.appSumoCode = '';
				this.emailForm.patchValue({
					appsumo: '',
					f6s: this.f6sCode,
				});
				this.authType = 'register';
				this.registerFormType = 'email';
				this.couponCodeType = 'f6s';
				this.addRemoveCouponCodeValidation();
			}
		});

		if (this.router.url === '/appsumo' || this.router.url === '/f6s') {
			this.authenticationService.setAuthenticationStep('home');
			this.router.navigate(['/authentication/register']);

			this.authType = 'register';
			this.registerFormType = 'email';
			if (this.router.url === '/appsumo') {
				this.couponCodeType = 'appsumo';
			} else {
				this.couponCodeType = 'f6s';
			}
			this.addRemoveCouponCodeValidation();
		}

		this.addRemoveEmailPhoneValidation();
	}

	private passwordMatchValidator(formGroup: FormGroup): any {
		return formGroup.controls['password'].value === formGroup.controls['confirmPassword'].value ? null : { mismatch: true };
	}

	/**
	 * Function to add and remove coupon validation
	 */
	private addRemoveCouponCodeValidation(): void {
		if (this.couponCodeType === 'appsumo') {
			this.emailForm.controls['appsumo'].setValidators(Validators.required);
			this.emailForm.controls['appsumo'].updateValueAndValidity();
			this.emailForm.controls['f6s'].setValidators(null);
			this.emailForm.controls['f6s'].updateValueAndValidity();
		} else {
			this.emailForm.controls['appsumo'].setValidators(null);
			this.emailForm.controls['appsumo'].updateValueAndValidity();
			this.emailForm.controls['f6s'].setValidators(Validators.required);
			this.emailForm.controls['f6s'].updateValueAndValidity();
		}
	}

	/**
	 * Function to add and remove validation for email and phone based on sign up type
	 */
	private addRemoveEmailPhoneValidation(): void {
		if (this.signupType === 'email') {
			this.phoneForm.controls['email'].setValidators([Validators.required, Validators.email]);
			this.phoneForm.controls['email'].updateValueAndValidity();
			this.phoneForm.controls['phone'].setValidators(null);
			this.phoneForm.controls['phone'].updateValueAndValidity();
			this.phoneForm.controls['countryCode'].setValidators(null);
			this.phoneForm.controls['countryCode'].updateValueAndValidity();
		} else {
			this.phoneForm.controls['email'].setValidators(null);
			this.phoneForm.controls['email'].updateValueAndValidity();
			this.phoneForm.controls['phone'].setValidators([Validators.required, Validators.pattern('^[0-9]*$')]);
			this.phoneForm.controls['phone'].updateValueAndValidity();
			this.phoneForm.controls['countryCode'].setValidators(Validators.required);
			this.phoneForm.controls['countryCode'].updateValueAndValidity();
		}
	}

	/**
	 * Function to show sign up email form
	 */
	public onClickSignUpWithEmail(): void {
		this.signupType = 'email';
		this.addRemoveEmailPhoneValidation();
		this.phoneForm.patchValue({
			phone: '',
		});
	}

	/**
	 * Function to show sign up email form
	 */
	public onClickSignUpWithPhone(): void {
		this.signupType = 'phone';
		this.addRemoveEmailPhoneValidation();
		this.phoneForm.patchValue({
			email: '',
		});
	}

	/**
	 * Function to show sign in email form
	 */
	public onClickSignInWithEmail(): void {
		this.signInType = 'email';

		this.authForm.patchValue({
			phone: null,
			email: '',
		});
	}

	/**
	 * Function to show sign in phone form
	 */
	public onClickSignInWithPhone(): void {
		this.displayCountryModal();
		this.signInType = 'phone';
		this.authForm.patchValue({
			phone: '',
		});
	}

	public submitSSOLoginForm(): void {
		if (this.ssoForm.valid) {
			this.userService.getNetworkId(this.ssoForm.value.email).subscribe((data) => {
				if (data.network) {
					// eslint-disable-next-line spellcheck/spell-checker
					window.location.href = `${environment.sso_login_url}?idp=${data.network}`;
				}
			});
		}
	}

	/**
	 * 1. Check if localstorage contains pb-url for app-zoom authentication
	 * 2. If pb-url doesn't exists,continue inside app after login
	 * 3. If exists, then naviagte to that url after login
	 * 4. Check if country names and form is valid,then allow signin
	 */
	public submitLoginForm(): void {
		if ((this.authForm.value.email && this.authForm.value.password) || (this.authForm.value.phone && this.authForm.value.password && this.authForm.value.countryName)) {
			// 4
			this.showSuccessMessage = false;
			this.isSubmitting = true;
			this.errors = new Errors();

			if (this.authForm.value.phone && this.currentCountry === 'Turkey') {
				this.authForm.value.phone = this.authForm.value.phone.replace(/^0+/, '');
			}

			if (this.authForm.value.phone) {
				const clearPhoneNumber = this.authForm.value.phone.replace(/\s/g, '');

				this.authForm.value.phone = this.code + clearPhoneNumber;
			}

			const credentials = this.authForm.value;

			this.errorMessage = '';
			// return
			this.userService.attemptAuth(this.authType, credentials, this.currentLocation).subscribe(
				(data) => {
					if (!localStorage.getItem('pb-url')) {
						// 1
						if (data['membershipCount'] === '0') {
							// 2
							this.router.navigateByUrl('/workspace/welcome');
						} else {
							this.router.navigateByUrl('/');
						}
					} else {
						// 3
						this.router.navigateByUrl(localStorage.getItem('pb-url'));
						localStorage.removeItem('pb-url');
					}

					this.mixPanelService.track('Sign In', {
						// TODO: network name
						// workspace: this.network.name || 'NA'
						appVersion: environment.version,
						language: this.translateService.currentLang,
						user: data?.username || 'NA',
						username: data?.name || 'NA',
						date: moment().format('YYYY-MM-DD'),
						email: credentials?.email || 'NA',
					});

					this.segmentService.trackEvent('Sign In', {
						workspaceID: JSON.parse(localStorage.getItem('branchData'))?.networkId || 'NA',
						appVersion: environment.version,
						language: this.translateService.currentLang,
						user: data?.username || 'NA',
						username: data?.name || 'NA',
						date: moment().format('YYYY-MM-DD'),
						email: credentials?.email || 'NA',
					});

					window.dataLayer.push({
						event: 'gaEvent', // Event Name
						// eslint-disable-next-line spellcheck/spell-checker
						Category: 'Login', // Sign In ise Sign In
						Action: 'Success',
						// eslint-disable-next-line spellcheck/spell-checker
						Label: 'tr', // en sayfaları için en olark gönderilmeli
						// eslint-disable-next-line spellcheck/spell-checker
						language_type: 'tr', // dil seçeneğine göre dinamik olaraak beslenecek
					});
				},
				(err) => {
					window.dataLayer.push({
						event: 'gaEvent', // Event Name
						// eslint-disable-next-line spellcheck/spell-checker
						Category: 'Login', // Sign In ise Sign In
						Action: 'Fail',
						// eslint-disable-next-line spellcheck/spell-checker
						Label: 'Password Error', // hata nedeni gönderilmeli
						// eslint-disable-next-line spellcheck/spell-checker
						language_type: 'en', // dil seçeneğine göre dinamik olaraak beslenecek
					});

					this.errors = err;
					this.isSubmitting = false;

					// if (this.apiService.errorDetail) {
					// 	this.modalText = this.apiService.errorDetail;
					// } else {
					// 	this.modalText = 'No active account found with the given credentials';
					// }

					switch (err?.errors?.error?.detail) {
						case 'Invalid password.':
							this.modalText = 'Incorrect password for this email.';
							break;
						case 'Invalid username.':
							this.modalText = 'No account for this email.';
							break;
						default:
							this.modalText = err?.errors?.error?.detail ?? 'No active account found with the given credentials';
							break;
					}

					this.displayErrorDialog = 'block';
				}
			);
		}
	}

	/**
	 * Function to send the verification code again
	 */
	public onClickSendCodeAgain(): void {
		if (this.appSumoCode || this.f6sCode) {
			this.onSubmitEmailForm();
		} else {
			this.submitPhoneForm();
		}
	}

	public submitPhoneForm(): void {
		this.phoneForm.markAllAsTouched();

		if (this.phoneForm.valid) {
			if (this.checked) {
				if (this.signupType === 'email' || this.checkPhoneNumber() || this.signupType === 'phone') {
					this.isProcessing = true;
					const credentials = this.phoneForm.value;
					this.userService.submitCountryAndPhone(credentials).subscribe(
						(data) => {
							if (data['status'] === 'Success') {
								window.dataLayer.push({
									event: 'gaEvent', // Event Name
									// eslint-disable-next-line spellcheck/spell-checker
									Category: 'Sign Up', // Sign In ise Sign In
									Action: 'Success',
									// eslint-disable-next-line spellcheck/spell-checker
									Label: 'tr', // en sayfaları için en olark gönderilmeli
									// eslint-disable-next-line spellcheck/spell-checker
									language_type: 'tr', // dil seçeneğine göre dinamik olaraak beslenecek
								});

								this.isProcessing = false;

								if (this.signupType === 'email') {
									this.userDetailForm.patchValue({
										email: this.phoneForm.value.email,
									});

									this.registerFormType = 'name';
									if (this.f6sCode) {
										this.userDetailForm.controls['cardHolderName'].setValidators(Validators.required);
										this.userDetailForm.controls['cardHolderName'].updateValueAndValidity();
										setTimeout(() => {
											this.createStripeForm();
										}, 200);
										const amount = this.f6sAccountDetails.amount / 100;
										const savedAmount = 480 - amount;
										this.modalText = this.translateService.instant('PayableAmount', {
											amount: amount,
											totalAmount: savedAmount,
										});
										this.displayErrorDialog = 'block';
									} else {
										this.userDetailForm.controls['cardHolderName'].setValidators(null);
										this.userDetailForm.controls['cardHolderName'].updateValueAndValidity();
									}
								} else if (this.signupType === 'phone') {
									this.verificationCode = data['verificationCode'];
									this.registerFormType = 'otp';
									setTimeout(() => {
										this.digit.nativeElement.focus();
									}, 0);
								}
							} else {
								this.isProcessing = false;
								if (this.signupType === 'email') {
									this.modalText = this.translateService.instant('Email is invalid');
								} else {
									this.modalText = this.translateService.instant('Phone number is not valid');
								}
								this.displayErrorDialog = 'block';
								window.dataLayer.push({
									event: 'gaEvent', // Event Name
									// eslint-disable-next-line spellcheck/spell-checker
									Category: 'Sign Up', // Sign In ise Sign In
									Action: 'Fail',
									// eslint-disable-next-line spellcheck/spell-checker
									Label: 'Invalid phone number', // hata nedeni gönderilmeli
									// eslint-disable-next-line spellcheck/spell-checker
									language_type: 'en', // dil seçeneğine göre dinamik olaraak beslenecek
								});
							}
						},
						() => {
							this.isProcessing = false;
							if (this.apiService.errorDetail) {
								this.modalText = this.apiService.errorDetail;
								this.displayErrorDialog = 'block';
							} else {
								this.modalText = 'Something went wrong';
								this.displayErrorDialog = 'block';
							}

							window.dataLayer.push({
								event: 'gaEvent', // Event Name
								// eslint-disable-next-line spellcheck/spell-checker
								Category: 'Sign Up', // Sign In ise Sign In
								Action: 'Fail',
								// eslint-disable-next-line spellcheck/spell-checker
								Label: this.modalText, // hata nedeni gönderilmeli
								// eslint-disable-next-line spellcheck/spell-checker
								language_type: 'en', // dil seçeneğine göre dinamik olaraak beslenecek
							});
						}
					);
				} else {
					this.displayErrorDialog = 'block';
					this.modalText = 'Phone number is not valid';
				}
			} else {
				this.displayErrorDialog = 'block';
				this.modalText = 'You need to agree our Terms of Use and Privacy Policy';
			}
		}
	}

	/*
	 * Function to get otp code from email
	 */
	public onSubmitEmailForm(): void {
		this.emailForm.markAllAsTouched();

		if (this.emailForm.valid) {
			if (this.checked) {
				this.isProcessing = true;
				const credentials = {
					email: this.emailForm.value.email,
				};

				this.userService.submitCountryAndPhone(credentials).subscribe(
					(data) => {
						this.isProcessing = false;
						if (data['status'] === 'Success') {
							this.mixPanelService.track('Signup Completed', {
								step: 'Email Verification Success',
								email: credentials?.email,
								appVersion: environment.version,
								language: this.translateService.currentLang,
							});

							this.segmentService.trackEvent('Signup Completed', {
								step: 'Email Verification Success',
								email: credentials?.email,
								appVersion: environment.version,
								language: this.translateService.currentLang,
							});

							window.dataLayer.push({
								event: 'gaEvent', // Event Name
								// eslint-disable-next-line spellcheck/spell-checker
								Category: 'Sign Up', // Sign In ise Sign In
								Action: 'Success',
								// eslint-disable-next-line spellcheck/spell-checker
								Label: 'tr', // en sayfaları için en olark gönderilmeli
								// eslint-disable-next-line spellcheck/spell-checker
								language_type: 'tr', // dil seçeneğine göre dinamik olaraak beslenecek
							});

							this.isProcessing = false;
							this.registerFormType = 'name';
							// this.verificationCode = data['verificationCode'];
							// this.registerFormType = 'otp';

							/**
							 * eslint-disable-next-line spellcheck/spell-checker
							 * Add Reditus Tracking Script
							 */
							if (typeof window.gr === 'function') {
								window.gr('track', 'conversion', { email: credentials?.email });
							}

							setTimeout(() => {
								if (this.appSumoCode || this.f6sCode) {
									this.userDetailForm.patchValue({
										email: this.emailForm.value.email,
									});
								}
							}, 50);
						} else {
							this.isProcessing = false;
							this.modalText = this.translateService.instant('Email is invalid');
							this.displayErrorDialog = 'block';
							window.dataLayer.push({
								event: 'gaEvent', // Event Name
								// eslint-disable-next-line spellcheck/spell-checker
								Category: 'Sign Up', // Sign In ise Sign In
								Action: 'Fail',
								// eslint-disable-next-line spellcheck/spell-checker
								Label: 'Invalid email', // hata nedeni gönderilmeli
								// eslint-disable-next-line spellcheck/spell-checker
								language_type: 'en', // dil seçeneğine göre dinamik olaraak beslenecek
							});
						}
					},
					() => {
						this.mixPanelService.track('Signup Completed', {
							step: 'Form Verification Fail',
							email: credentials?.email,
							appVersion: environment.version,
							language: this.translateService.currentLang,
						});

						this.isProcessing = false;
						if (this.apiService.errorDetail) {
							this.modalText = this.apiService.errorDetail;
							this.displayErrorDialog = 'block';
						} else {
							this.modalText = 'Something went wrong';
							this.displayErrorDialog = 'block';
						}

						window.dataLayer.push({
							event: 'gaEvent', // Event Name
							// eslint-disable-next-line spellcheck/spell-checker
							Category: 'Sign Up', // Sign In ise Sign In
							Action: 'Fail',
							// eslint-disable-next-line spellcheck/spell-checker
							Label: this.modalText, // hata nedeni gönderilmeli
							// eslint-disable-next-line spellcheck/spell-checker
							language_type: 'en', // dil seçeneğine göre dinamik olaraak beslenecek
						});
					}
				);
			}
		}
	}

	/*
	 * Function to validate the appsumo code
	 */
	private validateAppSumoCode(): void {
		this.mixPanelService.track('Signup Started', {
			step: 'AppSumo Coupon Code Validation Started',
			code: this.appSumoCode,
			appVersion: environment.version,
			language: this.translateService.currentLang,
		});

		this.emailForm.markAllAsTouched();

		if (this.emailForm.valid && this.checked) {
			const email = this.emailForm?.value?.email;
			this.loadingGif = 'block';
			const url = `https://api.peerbie.com/coupon_verify/${this.appSumoCode}/?l=en-IN`;
			// const url = `https://staging.peerbie.com/coupon_verify/${this.appSumoCode}/?l=en-IN`;
			this.userService.validateCoupon(url).subscribe(
				(res) => {
					this.loadingGif = 'none';
					if (res['valid']) {
						this.mixPanelService.track('Signup Completed', {
							step: 'AppSumo Coupon Code Validation Success',
							email: email,
							appVersion: environment.version,
							language: this.translateService.currentLang,
						});

						this.displayErrorCouponCode = 'none';
						this.onSubmitEmailForm();
					} else {
						this.mixPanelService.track('Signup Completed', {
							step: 'AppSumo Coupon Code Validation Fail (Invalid Coupon)',
							email: email,
							code: this.appSumoCode,
							appVersion: environment.version,
							language: this.translateService.currentLang,
						});

						this.couponErrorText = this.translateService.instant('Coupon is invalid');
						this.displayErrorCouponCode = 'block';
					}
				},
				() => {
					this.mixPanelService.track('Signup Completed', {
						step: 'AppSumo Coupon Code Validation Fail (Request/Server Error)',
						email: email,
						code: this.appSumoCode,
						appVersion: environment.version,
						language: this.translateService.currentLang,
					});

					this.loadingGif = 'none';
				}
			);
		}
	}

	/*
	 * Function to validate the f6s code
	 */
	private validateF6sCode(): void {
		this.mixPanelService.track('Signup Started', {
			step: 'f6s Coupon Code Validation Started',
		});

		this.emailForm.markAllAsTouched();
		if (this.emailForm.valid && this.checked) {
			this.loadingGif = 'block';
			const email = this.emailForm?.value?.email;
			const url = `https://api.peerbie.com/coupon_verify/${this.f6sCode}/?l=en-IN`;
			// const url = `https://staging.peerbie.com/coupon_verify/${this.f6sCode}/?l=en-IN`;
			this.userService.validateCoupon(url).subscribe(
				(res) => {
					this.loadingGif = 'none';
					if (res['valid']) {
						this.mixPanelService.track('Signup Completed', {
							step: 'f6s Coupon Code Validation Success',
							email: email,
							appVersion: environment.version,
							language: this.translateService.currentLang,
						});

						this.displayErrorCouponCode = 'none';
						this.f6sAccountDetails = res;
						this.stripeKey = this.f6sAccountDetails.stripeKey;
						this.onSubmitEmailForm();
					} else {
						this.mixPanelService.track('Signup Completed', {
							step: 'f6s Coupon Code Validation Fail (Request/Server Error)',
							email: email,
							code: this.f6sCode,
							appVersion: environment.version,
							language: this.translateService.currentLang,
						});

						this.couponErrorText = this.translateService.instant('Coupon is invalid');
						this.displayErrorCouponCode = 'block';
					}
				},
				() => {
					this.mixPanelService.track('Signup Completed', {
						step: 'f6s Coupon Code Validation Fail (Request/Server Error)',
						email: email,
						code: this.f6sCode,
						appVersion: environment.version,
						language: this.translateService.currentLang,
					});

					this.loadingGif = 'none';
				}
			);
		}
	}

	/*
	 * Function to to navigate to home page if coupon code is invalid
	 */
	public navigateToHome(): void {
		if (this.appSumoCode || this.f6sCode) {
			// this.router.navigateByUrl('/');
			this.displayErrorCouponCode = 'none';
		}
	}

	/*
	 * Function to create stripe form
	 */
	private createStripeForm(): void {
		this.stripe = stripePayment(this.stripeKey, {
			locale: localStorage.getItem('language'),
		});
		const self = this;
		const elements = this.stripe.elements();
		const style = {
			// 2
			base: {
				color: '#495057',
				// eslint-disable-next-line spellcheck/spell-checker
				fontFamily: 'apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
			},
		};

		this.card = elements.create('cardNumber', {
			placeholder: this.translateService.instant('Enter Card Number'),
			style: style,
			showIcon: true,
		});

		this.card.mount(this.cardNumber.nativeElement); // 3
		this.card.addEventListener('change', function (event) {
			const displayError = document.getElementById('card-errors'); // 4
			if (event.error) {
				displayError.textContent = self.translateService.instant(event.error.message);
			} else {
				displayError.textContent = '';
			}
		});

		const exp = elements.create('cardExpiry', {
			// 5
			placeholder: this.translateService.instant('MM / YY'),
			style: style,
		});
		exp.mount(this.cardExpiry.nativeElement);
		exp.addEventListener('change', function (event) {
			const displayError = document.getElementById('expiry-errors'); // 6
			if (event.error) {
				displayError.textContent = self.translateService.instant(event.error.message);
			} else {
				displayError.textContent = '';
			}
		});

		const cvc = elements.create('cardCvc', {
			placeholder: 'CVV',
			style: style,
		});
		cvc.mount(this.cardCvc.nativeElement); // 7
		cvc.addEventListener('change', function (event) {
			const displayError = document.getElementById('cvc-errors'); // 8
			if (event.error) {
				displayError.textContent = self.translateService.instant(event.error.message);
			} else {
				displayError.textContent = '';
			}
		});
	}

	/*
	 * Function to create payment intent
	 */
	public createPaymentWithStripe(): void {
		this.paymentForm.markAllAsTouched();
		const form = this.paymentForm.value;

		this.stripe
			.createPaymentMethod({
				type: 'card',
				card: this.card,
				billing_details: {
					name: form.name,
				},
			})
			.then((result) => {
				if (result.error) {
					if (result['error']['code'] && result['error']['code'] === 'card_declined') {
						this.displayStripeError = 'block';
					}
				} else {
					this.paymentIntent = result.paymentMethod.id;
					this.submitUserDetails();
				}
			});
	}

	/*
	 * Function to validate the appsumo code or f6s code based on condition
	 */
	public validateCouponCode(): void {
		this.mixPanelService.track('Signup Started', {
			step: 'Validate Coupon Code Started',
			appVersion: environment.version,
			language: this.translateService.currentLang,
		});

		this.emailForm.markAllAsTouched();
		if (this.appSumoCode) {
			this.validateAppSumoCode();
		} else if (this.f6sCode) {
			this.validateF6sCode();
		}
	}

	/*
	 * Function to check the sign up is using f6s code
	 */
	private checkSignUpIsUsingF6s(): boolean {
		if (this.f6sCode) {
			if (this.paymentIntent) {
				return true;
			} else {
				this.createPaymentWithStripe();
				return false;
			}
		} else {
			return true;
		}
	}

	public submitOtpForm(): void {
		this.validationForm.markAllAsTouched();

		if (this.validationForm.valid) {
			const typedOtp =
				this.validationForm.value.digit1 +
				this.validationForm.value.digit2 +
				this.validationForm.value.digit3 +
				this.validationForm.value.digit4 +
				this.validationForm.value.digit5 +
				this.validationForm.value.digit6;
			if (typedOtp === this.verificationCode) {
				this.isProcessing = true;

				this.mixPanelService.track('Signup Completed', {
					step: 'OTP Validated',
					appVersion: environment.version,
					language: this.translateService.currentLang,
				});

				setTimeout(() => {
					this.isProcessing = false;
					this.registerFormType = 'name';
					if (this.f6sCode) {
						this.userDetailForm.controls['cardHolderName'].setValidators(Validators.required);
						this.userDetailForm.controls['cardHolderName'].updateValueAndValidity();
						setTimeout(() => {
							this.createStripeForm();
						}, 200);
						const amount = this.f6sAccountDetails.amount / 100;
						const savedAmount = 480 - amount;
						this.modalText = this.translateService.instant('PayableAmount', {
							amount: amount,
							totalAmount: savedAmount,
						});
						this.displayErrorDialog = 'block';
					} else {
						this.userDetailForm.controls['cardHolderName'].setValidators(null);
						this.userDetailForm.controls['cardHolderName'].updateValueAndValidity();
					}
				}, 1000);
			} else {
				this.displayErrorDialog = 'block';
				this.modalText = 'Incorrect validation code. Please enter the code again.';

				this.mixPanelService.track('Signup Completed', {
					step: 'OTP Validation Failed (Incorrect Validation Code)',
					appVersion: environment.version,
					language: this.translateService.currentLang,
				});
			}
		}
	}

	/**
	 * 1. Check if branchData exists in localstorage after submitting user details.
	 * 2. If true,manage redirection to Join flow and pass networkId as query params.
	 * 3. Else redirect to join flow.
	 * 4. Check if partnerstack token is present in cookies,if so call PS API.
	 */
	public submitUserDetails(): void {
		this.userDetailForm.markAllAsTouched();
		this.passwordError = false;
		const numbers = /^[0-9]+$/;
		const user = {};

		if (this.userDetailForm.controls.password.value.match(numbers)) {
			this.passwordError = true;
		} else {
			if (this.userDetailForm.valid && this.checkSignUpIsUsingF6s()) {
				let credentials: any;
				if (this.appSumoCode) {
					credentials = {
						email: this.userDetailForm.value.email,
						appsumo: this.appSumoCode,
						password: this.userDetailForm.value.password,
						country: this.selectedField ? this.selectedField.uid : this.countryUid,
					};
				} else if (this.f6sCode) {
					credentials = {
						email: this.userDetailForm.value.email,
						f6s_code: this.f6sCode,
						payment_method_id: this.paymentIntent,
						password: this.userDetailForm.value.password,
						country: this.selectedField ? this.selectedField.uid : this.countryUid,
					};
				} else {
					if (this.signupType === 'email') {
						credentials = {
							country: this.countryUid,
							email: this.userDetailForm.value.email,
							password: this.userDetailForm.value.password,
						};
					} else {
						credentials = {
							country: this.countryUid,
							phoneNumber: this.code + this.phoneForm.value.phone,
							password: this.userDetailForm.value.password,
						};
					}
				}

				user['email'] = this.userDetailForm.value.email;
				const name = this.userDetailForm.value.name;
				const index = name.lastIndexOf(' ');
				if (index > -1) {
					const firstName = name.substring(0, index).trim();
					if (firstName) {
						user['firstName'] = name.substring(0, index).trim();
						user['lastName'] = name.substring(index).trim();
					} else {
						user['firstName'] = name.substring(index).trim();
					}
				} else {
					user['firstName'] = name;
				}

				const cookies = document.cookie; // 4

				// if (cookies.indexOf('growSumoPartnerKey') > -1) {
				//   partnerStackSignUp(user);
				//   user['partnerstack'] = true;
				// }

				if (cookies.indexOf('_gr_id') > -1) {
					window.gr('track', 'conversion', {
						email: this.userDetailForm.value.email ? this.userDetailForm.value.email : '',
					});
				}

				// return

				this.loadingGif = 'block';
				this.userService.userSignUp(credentials).subscribe(
					(data) => {
						const _credentials = credentials;
						delete _credentials.password;

						this.userService.setAuth(data);
						this.userService.updateSignedUpUser(user).subscribe(
							(res) => {
								this.userService.updateUserSubject(res);
								localStorage.removeItem('isPartnerStack');
								localStorage.removeItem('userDetails');
								if (localStorage.getItem('branchData')) {
									// 1
									this.branchData = JSON.parse(localStorage.getItem('branchData'));
									this.handleBranches(this.branchData); // 2
								} else {
									this.router.navigateByUrl('/workspace/welcome'); // 3
								}
								this.loadingGif = 'none';
							},
							() => {
								this.loadingGif = 'none';
								this.modalText = this.translateService.instant('Something went wrong');
								this.displayErrorDialog = 'block';
							}
						);
					},
					() => {
						this.mixPanelService.track('Signup Completed', {
							step: 'OTP, User Detail Form (Request/Server Error)',
							appVersion: environment.version,
							language: this.translateService.currentLang,
						});

						this.loadingGif = 'none';
						this.modalText = this.apiService.errorDetail;
						this.displayErrorDialog = 'block';
					}
				);

				window.dataLayer.push({
					event: 'sign_up_success', // Event Name
					eventCategory: 'User Engagement',
					eventAction: 'SignUp Process Done', //
					eventLabel: 'tr', // which page. path referrer
				});
			}
		}
	}

	/**
	 * Manage redirection to join flow if branch url is used
	 * @param data
	 */
	private handleBranches(data): void {
		if (data['networkId']) {
			if (data['contentType'] === 'Network') {
				this.router.navigateByUrl('/workspace/profile-information?branch_networkId=' + data['networkId']);
				this.clearBranch();
			} else {
				this.router.navigateByUrl(
					'/workspace/profile-information?branch_networkId=' +
						this.branchData['networkId'] +
						'&content_type=' +
						this.branchData['contentType'] +
						'&content_uid=' +
						this.branchData['contentUid']
				); // 5
				this.clearBranch();
			}
		}
	}

	/**
	 * Function to clear branchData and branch session from Localstorage
	 */
	private clearBranch(): void {
		localStorage.removeItem('branchData');
		localStorage.removeItem('branch_session_first');
	}

	/**
	 * Function on selecting a country from modal
	 * @param country
	 * 1. Set field for radio button on/off
	 * 2. Set selected country in a variable
	 */
	public onSelectCountry(country): void {
		console.log(country);
		this.field.value = country.uid; // 1
		this.selectedField = country; // 2
	}

	/**
	 * Function which sets selected country and its code on sign-in form
	 *
	 * 1. Close country modal
	 * 2. Set selected country name and code
	 */
	public executeChanges(): void {
		this.showCountryModal = 'none'; // 1

		if (this.selectedField) {
			this.countryUid = this.selectedField['uid'];
			this.currentCountry = this.selectedField.name; // 2
			this.code = this.selectedField['countryCode']; // 2
			// this.alphaCountryCode = this.selectedField['code'];
			if (this.selectedField['code'] === 'UK') {
				this.alphaCountryCode = 'GB';
			} else {
				this.alphaCountryCode = this.selectedField['code'];
			}
			this.phoneForm.controls.countryName.setValue(this.currentCountry);
			this.phoneForm.controls.countryCode.setValue(this.code);
			this.authForm.controls.countryName.setValue(this.currentCountry);

			if (this.authType === 'register' && this.registerFormType === 'email') {
				this.emailForm.controls.countryName.setValue(this.currentCountry);
			}
		} else {
			// eslint-disable-next-line spellcheck/spell-checker
			this.countryUid = '1869af5c-ef96-11e0-8a8e-f23c91df419d';
			this.currentCountry = 'Turkey';
			this.code = 90;
			this.alphaCountryCode = 'TR';
			this.phoneForm.controls.countryCode.setValue(this.code);
		}
	}

	/**
	 * Function to display countries modal
	 * 1. Reset all variables
	 * 2. Call all countries
	 * 3. Set next url
	 * 4. Remove loader
	 */
	public displayCountryModal(): void {
		this.showCountryModal = 'block';
		this.field['value'] = ''; // 1
		this.isLoading = true;
		this.countries = [];
		this.searchString = '';
		this.displayError = false;
		this.userService.fetchCountries().subscribe((data) => {
			// 2
			this.countries = data['results'];

			if (data.next) {
				// 2
				this.nextUrl = data.next.split('alpha')[1];
			} else {
				this.nextUrl = '';
			}
			this.isLoading = false; // 4
		});
	}

	/**
	 * Function to paginate remaining countries
	 * 1. Check if next url exists
	 * 2. Get countries
	 * 3. Set next url
	 * 4. Append data to array
	 */
	public paginateCountries(): void {
		if (this.nextUrl) {
			// 1
			this.userService.paginate(this.nextUrl).subscribe((data) => {
				// 2
				if (data.next) {
					this.nextUrl = data.next.split('alpha')[1]; // 3
				} else {
					this.nextUrl = '';
				}

				this.countries = this.countries.concat(data.results); // 4
			});
		}
	}

	/**
	 * Function which sets radio btn on/off in network types modal
	 * @param type
	 */
	public onSelectNetwork(type): void {
		this.option['value'] = type;
	}

	public nextFocus(event): void {
		if (event.srcElement.value && event.keyCode !== 8) {
			const nextInput = event.srcElement.nextElementSibling;
			if (nextInput) {
				nextInput.focus();
			}
		}
		if (event.keyCode === 8) {
			const prevInput = event.srcElement.previousElementSibling;
			if (prevInput) {
				prevInput.focus();
			}
		}
	}

	public setSubject(e): void {
		if (e.target.value) {
			this.clearSearch = true;
			const text = e.target.value;
			this.subject.next(text);
		} else {
			this.clearSearch = false;
		}
	}

	public clearSearchLists(): void {
		this.clearSearch = false;
		this.subject.next('');
		this.searchString = '';
	}

	public handlePasswordReset(): void {
		window.open(this.resetUrl, '_blank');
	}

	private checkPhoneNumber(): any {
		const phone = this.code + this.phoneForm.value.phone,
			phoneUtil = googlePhoneLib.PhoneNumberUtil.getInstance(),
			number = phoneUtil.parse(phone, this.alphaCountryCode),
			isValid = phoneUtil.isValidNumber(number);

		return isValid;
	}

	/**
	 * Call GeoNames api(https://www.geonames.org/) for getting current country name from lat and long
	 * username : PeerBeeRocks!
	 * email: hello@peerbie.com
	 * password: peerbieMywork
	 */
	private callGeoNames(): void {
		const url = `https://secure.geonames.org/findNearbyJSON?lat=${this.latitude}&lng=${this.longitude}&username=peerbieMyWork`;
		this.http.get(url).subscribe(
			(data) => {
				const body = JSON.parse(data['_body']);

				if (body['geonames'].length > 0) {
					if (body['geonames'][0]['countryName']) {
						this.country = body['geonames'][0]['countryName'];
						this.getCountryInfo();

						const name = this.country.toLowerCase();

						this.setLanguage(name);
					}
				}
			},
			() => {
				this.setDefaultCountry();
			}
		);
	}

	/**
	 * Get country phone code and uid from country name
	 */
	private getCountryInfo(): void {
		this.userService.searchCountries(this.country).subscribe((data) => {
			if (data.results && data.results.length > 0) {
				data.results.forEach((country) => {
					if (country['name'] && country['name'] === this.country) {
						this.defaultCountry = country;

						this.code = this.defaultCountry['countryCode'];
						this.countryUid = country['uid'];

						if (country['code'] === 'UK') {
							this.alphaCountryCode = 'GB';
						} else {
							this.alphaCountryCode = country['code'];
						}

						this.authForm.controls.countryName.setValue(this.defaultCountry['name']);
						this.phoneForm.controls.countryCode.setValue(this.code);
						this.phoneForm.controls.countryName.setValue(this.defaultCountry['name']);

						this.selectedField = country;
					}
				});
			}
		});
	}

	private setLanguage(country): void {
		if (country === 'turkey') {
			this.translate.changeLanguage('tr');
		} else {
			this.translate.changeLanguage('en');
		}
	}

	/**
	 * If geo names fails, set Turkey as default country in sign-in and signup forms
	 */
	private setDefaultCountry(): void {
		this.alphaCountryCode = 'TR';
		this.code = 90;
		this.currentCountry = 'Turkey';
		// eslint-disable-next-line spellcheck/spell-checker
		this.countryUid = '1869af5c-ef96-11e0-8a8e-f23c91df419d';

		this.authForm.controls.countryName.setValue(this.currentCountry);
		this.phoneForm.controls.countryCode.setValue(this.code);
		this.phoneForm.controls.countryName.setValue(this.currentCountry);

		this.changeDetectorRef.detectChanges();

		this.showCountryModal = 'block';
	}

	public get email(): AbstractControl {
		return this.userDetailForm.get('email');
	}

	/**
	 * Function to disable or enable the sign in button
	 * @returns
	 */
	public checkAuthFormIsValidOrNot(): boolean {
		if (this.authForm.value.email && this.authForm.value.password) {
			return false;
		} else if (this.authForm.value.phone && this.authForm.value.password) {
			return false;
		} else {
			return true;
		}
	}
}
