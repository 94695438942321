import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../shared';

@Injectable()
export class HomeAuthResolver implements Resolve<boolean> {
	constructor(private userService: UserService) {}

	public resolve(): Observable<boolean> {
		return this.userService.isAuthenticated.pipe(take(1));
	}
}
