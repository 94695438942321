import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../src/environments/environment';

@Component({
	selector: 'app-zoom-app-auth',
	templateUrl: './zoom-app-auth.component.html',
	styleUrls: ['./zoom-app-auth.component.css'],
})
export class ZoomAppAuthComponent implements OnInit {
	public network_id;
	constructor(private route: ActivatedRoute, private router: Router, public translateService: TranslateService) {}

	ngOnInit(): void {
		/**
		 * Check if user is logged in or out
		 * 1. If user is logged in, go to zoom auth page
		 * 2. If user is not logged in,go to login page
		 */
		if (localStorage.getItem('userDetails')) {
			// 1
			this.route.queryParams.subscribe((params: Params) => {
				this.network_id = params['network_id'];
				localStorage.setItem('app_network_id', params.network_id);
				window.location.href = environment.zoom_oauth_url;
			});
		} else {
			localStorage.setItem('pb-url', this.router.url); // 2
			this.router.navigateByUrl('/authentication/login');
		}
	}
}
