import { ILocationAttachment } from './../../shared/models/location-attachment';
import { Component, Input, OnInit } from '@angular/core';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'bee-location-preview',
	templateUrl: './location-preview.component.html',
	styleUrls: ['./location-preview.component.scss'],
})
export class LocationPreviewComponent implements OnInit {
	@Input() public location: ILocationAttachment;

	constructor() {}

	ngOnInit(): void {}
}
