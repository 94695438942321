import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-event-form',
	templateUrl: './event-form.component.html',
	styleUrls: ['./event-form.component.css'],
})
export class EventFormComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
