export interface IPaginatedUI {
	isNextPageLoading?: boolean;
	isLoading?: boolean;
	limit?: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface IPaginatedResponse<T> extends IPaginatedUI {
	count: number;
	offset: number;
	next: string | null;
	previous: string | null;
	objects: any[];
}

export class PaginatedResponse implements IPaginatedResponse<any> {
	public count = 0;
	public offset = 0;
	public next: string | null = null;
	public previous: string | null = null;
	public objects: any[] = [];
	public isLoading = true;
	public isNextPageLoading = false;

	constructor(data: IPaginatedResponse<any> | {} = {}) {
		Object.assign(this, data);
	}
}
