import { Component, HostListener, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { UserService, LayoutService, PbTranslateService, MessagingService, PaginationService, GeoLocationService, NetworkService } from './shared';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { KeyboardUtilService } from '@core/services/util/keyboard-util.service';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';
import { ScrollService } from 'projects/peer-core/src/lib/services/ui/scroll.service';
import { filter } from 'rxjs/operators';
import { PreviousRouteService } from '@shared/services/previous-route.service';
import { Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { WorkspaceUiService } from '@core/services/ui/workspace/workspace-ui.service';
import CacheUtils from '@shared/utils/cache-utils';
import { environment } from 'src/environments/environment';

declare global {
	interface Window {
		dataLayer: any;
		gr: any;
		heap: any;
	}
}

interface IconDefinition {
	rel: string;
	sizes?: string;
	type?: string;
	href: string;
}

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
	@HostListener('window:scroll', ['$event']) public onScrollEvent($event: Event): void {
		this.scrollService.onWindowScrollChange($event);
	}

	public showBg = false;
	public isUserAuthenticated;
	public messages = [];
	public currentLocation: any;
	public country: any;
	public isPageLoading = false;

	private currentUrl: string;
	private previousUrl: string;

	private keyboardLongPressSubscription: Observable<Event>;
	private isMobile = false;
	private branchShareUrl = null;
	private nid = null;

	public appName = environment.appName;
	constructor(
		public layoutService: LayoutService,
		public translate: PbTranslateService,
		public paginationService: PaginationService,
		public activatedRoute: ActivatedRoute,
		public networkService: NetworkService,
		private http: HttpClient,
		private geoLocationService: GeoLocationService,
		private userService: UserService,
		private pbTranslateService: PbTranslateService,
		private messagingService: MessagingService,
		private keyboardUtilService: KeyboardUtilService,
		private pageLoadingUiService: PageLoadingUiService,
		private scrollService: ScrollService,
		private router: Router,
		private previousRouteService: PreviousRouteService,
		private titleService: Title,
		@Inject(PLATFORM_ID) private platformId: Object,
		private renderer: Renderer2,
		private translateService: TranslateService,
		private workspaceUIService: WorkspaceUiService
	) {
		// this language will be used as a fallback when a translation isn't found in the current language
		// translate.setDefaultLang('en');
		// the lang to use, if the lang isn't available, it will use the current loader to get them
		// translate.use('en');

		// Detect device
		this.detectmob();

		// Initialize translation.
		this.pbTranslateService.initializeLanguage();
		this.activatedRoute.queryParams.subscribe((params) => {
			//  To change network Id when network parameter is present in url.
			if (params && params.network) {
				localStorage.setItem('network_id', params?.network);
				this.networkService.networkId = params?.network;
			} else if (params && params.ssocode) {
				this.userService.setCurrentUserInfo(params?.ssocode);
			} else if (params && params.share_url) {
				if (!this.isMobile) {
					this.branchShareUrl = params.share_url;
					this.nid = params.nid;
					this.getBranchData();
				} else {
					window.location.replace(params.share_url);
				}
			}
		});
	}

	ngOnInit(): void {
		if (this.appName === 'Neaty') {
			this.translate.changeLanguage('ja');
		}
		if (isPlatformBrowser(this.platformId)) {
			this.updateFavicon();
		}

		const apTitle = environment.appName;
		this.translateService.get('app.appTitle', { appName: apTitle }).subscribe((translation: string) => {
			this.titleService.setTitle(translation);
		});
		// if(environment.appName === 'Neaty'){
		// 	const isBrowser = isPlatformBrowser(this.platformId);
		// 	if(isBrowser){
		// 		if(localStorage.getItem('language') === 'tr'){
		// 		}

		// 		if(localStorage.getItem('language') === 'en'){
		// 			this.titleService.setTitle('Neaty Solutions');
		// 		}
		// 	}
		// }
		/**
		 * Functionality to keep track of previous routes
		 */
		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
			this.previousUrl = this.currentUrl;
			this.currentUrl = event.url;
			this.previousRouteService.setPreviousURL(this.previousUrl);
		});

		this.userService.isAuthenticated.subscribe((data) => {
			this.isUserAuthenticated = data;
		});

		this.userService.populate();

		this.layoutService.showBackdrop.subscribe((data) => {
			if (data === 'hide') {
				this.showBg = false;
			} else {
				this.showBg = true;
			}
		});

		this.userService.currentUser.subscribe((user) => {
			if (user) {
				if (user.hasOwnProperty('userId')) {
					this.messagingService.requestPermission(user.userId);
					this.messagingService.receiveMessage();
					this.messagingService.currentMessage.subscribe((data) => {
						if (data) {
							const index = this.messages.findIndex((m) => m.data['contentUid'] === data['data']['contentUid']);
							if (index === -1) {
								this.messages.push(data);
							}
						}
					});
				}
			}
		});

		window.onstorage = (event): boolean => {
			const isProfileInfoPage = window.location.pathname.includes('profile-information');
			if (event.key === 'network_id' && !isProfileInfoPage) {
				console.log('event.newValue : ', event.newValue, 'event.newValue:', event.oldValue);

				if (event.newValue) {
					window.location.replace('/network');
				} else {
					window.location.replace('/');
				}
			} else if (event.key === 'branchData') {
				this.router.navigate(['/']);
			} else {
				return true;
			}
		};

		this.getPosition();

		// keyboard long press for show to current running version
		this.keyboardLongPressSubscription = this.keyboardUtilService.keyboardLongPress('KeyQ', 3000);
		this.keyboardLongPressSubscription.subscribe();

		// set page loading status
		this.pageLoadingUiService.pageLoadingChanged.subscribe((status: boolean) => (this.isPageLoading = status));

		// show quick chat
	}

	public onScroll(): void {
		this.paginationService.onScrollDown();
	}

	/**
	 *  1. Check if user manually switched language,else continue setting default lang
	 */
	private getPosition(): void {
		if (!localStorage.getItem('user_language')) {
			// 1
			const accuracy = { enableHighAccuracy: true };

			this.geoLocationService.getLocation(accuracy).subscribe(
				(position) => {
					this.geoLocationService.setCurrentLocation(position);
					if (position['coords']) {
						this.currentLocation = position['coords'];
						// this.callGeoNames();
					}
				},
				() => {}
			);
		}
	}

	/**
	 * Call GeoNames api(https://www.geonames.org/) for getting current country name from lat and long
	 * username : PeerBeeRocks!
	 * email: hello@peerbie.com
	 * password: peerbieMywork
	 */
	public callGeoNames(): void {
		this.pageLoadingUiService.setPageLoading(true);

		// eslint-disable-next-line spellcheck/spell-checker
		const url = `https://secure.geonames.org/findNearbyJSON?lat=${this.currentLocation['latitude']}&lng=${this.currentLocation['longitude']}&username=peerbieMyWork`;

		this.http
			.get(url)
			.subscribe((data) => {
				const body = JSON.parse(data['_body']);

				if (body['geonames'].length > 0) {
					if (body['geonames'][0]['countryName']) {
						this.country = body['geonames'][0]['countryName'];
						const country = this.country.toLowerCase();

						this.setLanguage(country);
					}
				}
			})
			.add(() => {
				this.pageLoadingUiService.setPageLoading(false);
			});
	}

	/**
	 * Set language based on country name
	 * @param country
	 *
	 * @return
	 */
	private setLanguage(country: string): void {
		this.translate.changeLanguage(country === 'turkey' ? 'tr' : 'en');
	}

	public updateFavicon(): void {
		if (this.appName === 'Neaty') {
			const icons: IconDefinition[] = [
				{
					rel: 'apple-touch-icon',
					sizes: '180x180',
					href: '/assets/favicon/appName/apple-touch-icon.png',
				},
				{
					rel: 'icon',
					type: 'image/png',
					sizes: '32x32',
					href: '/assets/favicon/appName/favicon-32x32.png',
				},
				{
					rel: 'icon',
					type: 'image/png',
					sizes: '16x16',
					href: '/assets/favicon/appName/favicon-16x16.png',
				},
			];
			icons.forEach((icon) => {
				icon.href = icon.href.replace('appName', this.appName);
				const link: HTMLLinkElement = this.getOrCreateLinkForRel(icon.rel, icon.sizes);
				this.renderer.setAttribute(link, 'href', icon.href);
				if (icon.sizes) {
					this.renderer.setAttribute(link, 'sizes', icon.sizes);
				}
				if (icon.type) {
					this.renderer.setAttribute(link, 'type', icon.type);
				}
			});
		}
	}

	private getOrCreateLinkForRel(rel: string, sizes?: string): HTMLLinkElement {
		const selector = `link[rel="${rel}"]` + (sizes ? `[sizes="${sizes}"]` : '');
		let link: HTMLLinkElement = document.querySelector(selector) as HTMLLinkElement;
		if (!link) {
			link = this.renderer.createElement('link');
			this.renderer.setAttribute(link, 'rel', rel);
			if (sizes) {
				this.renderer.setAttribute(link, 'sizes', sizes);
			}
			this.renderer.appendChild(document.head, link);
		}
		return link;
	}
		/**
	 * Get branch link data 
	 */
		private getBranchData(): void{
			var branch_key = environment.branch_key;
			if (this.nid in environment.branch_key_dict) {
				branch_key = environment.branch_key_dict[this.nid];
			}
			if(this.branchShareUrl){
				let url = `https://api2.branch.io/v1/url?url=${this.branchShareUrl}&branch_key=${branch_key}`;
				this.http
					.get(url)
					.subscribe((data) => {
						CacheUtils.storeData('branchData', JSON.stringify(data["data"]));
						this.router.navigate(['/']);
					});
			}
		}

	/**
	 * Detect device
	 * If the device is mobile/iPad and the current URL is
	 * a share url then we just need to redirect the use to share url
	 * else call getBranchData function to get the link data and redirect the user to join flow
	 */
	private detectmob(): void {
		// Todo: We may need to improve the detection logic using https://www.npmjs.com/package/ngx-device-detector
		if (
			navigator.userAgent.match(/Android/i) ||
			navigator.userAgent.match(/webOS/i) ||
			navigator.userAgent.match(/iPhone/i) ||
			navigator.userAgent.match(/iPad/i) ||
			navigator.userAgent.match(/iPod/i) ||
			navigator.userAgent.match(/BlackBerry/i) ||
			navigator.userAgent.match(/Windows Phone/i)
		) {
			this.isMobile = true;
		}
	}
}
