import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-outcome',
	templateUrl: './outcome.component.html',
	styleUrls: ['./outcome.component.scss'],
})
export class OutcomeComponent implements OnInit {
	@Input() public outcome: string = null;

	constructor() {}

	ngOnInit(): void {}
}
