import { IWorkspace } from './../../../../shared/models/workspace/workspace';
import { Injectable } from '@angular/core';
import { IBranchData } from '@shared/models/third-party/branch-data';
import { NetworkService } from '@shared/services';
import { Router } from '@angular/router';
import { timer } from 'rxjs';
import CacheUtils from '@shared/utils/cache-utils';
import { ScrollService } from 'projects/peer-core/src/lib/services/ui/scroll.service';

@Injectable({
	providedIn: 'root',
})
export class WorkspaceUiService {
	private branchData: IBranchData;
	private username: string;

	constructor(private networkService: NetworkService, private router: Router, private scrollService: ScrollService) {}

	/**
	 * Redirect to selected workspace
	 *
	 * @param workspace selected workspace
	 * @param username current user username
	 * @returns void
	 */
	public redirectToWorkspace(workspace: IWorkspace, username: string): void {
		this.username = username;

		if (workspace.network?.membership?.state === 1) {
			if (workspace.network?.membership?.memberRole) {
				this.networkService.setNetwork(workspace?.network);

				console.log('network', workspace);
				if (workspace.network.subscription.status) {
					switch (workspace.network.subscription.status) {
						case 'incomplete':
						case 'incomplete_expired':
						case 'past_due':
						case 'canceled':
						case 'unpaid':
							localStorage.setItem('networkExpired', 'true');
							this.router.navigate(['/expired']);
							return;

						default:
							if (localStorage.getItem('networkExpired')) {
								localStorage.removeItem('networkExpired');
							}
							break;
					}
				}

				if (this.branchData && this.branchData.contentType !== 'Network') {
					this.handleBranchRedirection();
				} else {
					window.localStorage['network_id'] = workspace?.network?.uid;
					this.router.navigateByUrl('/network');
				}
			} else {
				this.router.navigateByUrl(`/workspace/profile-information?admin=true&id=${workspace.network.uid}&membership=${workspace.network.membership.uid}`);
				this.scrollService.scrollToTop();
			}
		} else {
			this.checkForMembership(workspace);
		}
	}

	/**
	 * Check loaded branch data (index.html, onload)
	 *
	 * @source: branch.io implementation
	 * @param workspaces selected workspace
	 * @param callback callback function
	 */
	public handleBranches(workspaces: IWorkspace[], callback: (workspace: IWorkspace) => void): void {
		timer(1000).subscribe(() => {
			const branchDataVariable = CacheUtils.getStoredData('branchData');

			if (branchDataVariable) {
				if (typeof branchDataVariable === 'string') {
					this.branchData = <IBranchData>JSON.parse(branchDataVariable);
				} else {
					this.branchData = <IBranchData>branchDataVariable;
				}

				if (this.branchData.networkId) {
					const workspace = workspaces.find((ws: IWorkspace) => ws.network.uid === this.branchData.networkId);

					if (workspace) {
						callback(workspace);

						CacheUtils.removeStoredData(['branchData', 'branch_session_first']);
					} else {
						if (this.branchData.contentType === 'Network') {
							CacheUtils.removeStoredData(['branchData', 'branch_session_first']);
							this.router.navigateByUrl(`/workspace/profile-information?branch_networkId=${this.branchData.networkId}`);
						} else {
							this.router.navigateByUrl(
								`/workspace/profile-information?branch_networkId=${this.branchData.networkId}&content_type=${this.branchData.contentType}&content_uid=${this.branchData.contentUid}`
							);
						}

						this.scrollService.scrollToTop();
					}
				}
			}
		});
	}

	public reOrderWorkspaces(workspaces: IWorkspace[]): IWorkspace[] {
		return workspaces?.sort((a: IWorkspace, b: IWorkspace) => {
			if (a.network.networkType < b.network.networkType) {
				return -1;
			} else if (a.network.networkType > b.network.networkType) {
				return 1;
			} else {
				return 0;
			}
		});
	}

	public clearWorkspaceFromStorage(): void {
		CacheUtils.removeStoredData(['networkExpired', 'network_id']);
	}

	// #region Helpers

	/**
	 * Handle branch redirection
	 *
	 * @source: branch.io implementation
	 * @return void
	 */
	private handleBranchRedirection(): void {
		if (this.branchData && this.branchData.contentType) {
			CacheUtils.removeStoredData(['branchData', 'branch_session_first']);

			let route = '';

			if (this.branchData.contentType === 'ToDoList') {
				route = `/project/team/${this.branchData.contentUid}`;
			} else if (this.branchData.contentType === 'ToDo') {
				route = '/project/task-detail/' + this.branchData.contentUid;
			} else if (this.branchData.contentType === 'Customer') {
				route = '/network/customer/' + this.branchData.contentUid;
			} else if (this.branchData.contentType === 'Channels') {
				route = '/network/view-channel?id=' + this.branchData.contentUid;
			} else if (this.branchData.contentType === 'Collections') {
				route = '/network/collection?id=' + this.branchData.contentUid;
			} else if (this.branchData.contentType === 'Issue') {
				route = '/network/issue-detail?id=' + this.branchData.contentUid;
			} else if (this.branchData.contentType === 'Post') {
				route = '/posts/' + this.branchData.contentUid;
			} else if (this.branchData.contentType === 'External-Channels') {
				route = '/network/view-channel?id=' + this.branchData.contentUid + '&external=true';
			}

			this.router.navigateByUrl(route);
		}
	}

	/**
	 * Check current user membership
	 *
	 * @param workspace selected workspace
	 * @returns void
	 */
	private checkForMembership(workspace: IWorkspace): void {
		let email = '';

		if (workspace.network?.membership?.state === 0) {
			// if current user is not admin
			if (workspace?.network?.adminUsername !== this.username) {
				if ((workspace.validationMethod === 1 || workspace.validationMethod === 2) && !workspace.inviteOnly) {
					if (workspace.validationValue) {
						const validationValue = workspace.validationValue;

						if (validationValue.indexOf('://') > -1) {
							email = `@${validationValue.split('://')[1]}`;
						} else if (validationValue.indexOf('www.') > -1) {
							email = `@${validationValue.split('www.')[1]}`;
						} else {
							email = `@${validationValue}`;
						}
					}

					this.router.navigateByUrl(
						`/join-work?roleValidation=true&id=${workspace.network.uid}&membership=${workspace.network.membership.uid}${email ? '&email=' + email : ''}`
					);
				}

				if (workspace.network.inviteOnly) {
					this.router.navigateByUrl(`/join-work?inviteOnly=true&id=${workspace.network.uid}&membership=${workspace.network.membership.uid}`);
				}

				this.scrollService.scrollToTop();
			} else {
				// admin flow

				let validationMethod: any;

				if (!workspace.validationMethod) {
					if (workspace.network.roles && workspace.network.roles.length > 0) {
						const index = workspace.network.roles.findIndex((role: any) => role.slug === 'external');

						if (index > -1) {
							workspace.network.roles.splice(index, 1);
						}

						validationMethod = workspace?.network?.roles?.length > 1 ? 'multiple-role' : 'role';

						this.router.navigateByUrl(
							`/workspace/profile-information?admin=true&id=${workspace.network.uid}&membership=${workspace.network.membership.uid}&validationMethod=${validationMethod}`
						);

						this.scrollService.scrollToTop();
					}
				} else {
					validationMethod = 'email';

					if (workspace.validationValue) {
						const validationValue = workspace.validationValue;

						if (validationValue.indexOf('://') > -1) {
							email = `@${validationValue.split('://')[1]}`;
						} else if (validationValue.indexOf('www.') > -1) {
							email = `@${validationValue.split('www.')[1]}`;
						} else {
							email = `@${validationValue}`;
						}
					}

					this.router.navigateByUrl(
						`/workspace/profile-information?admin=true&id=${workspace.network.uid}&membership=${workspace.network.membership.uid}&validationMethod=${validationMethod}${
							email ? '&email=' + email : ''
						}`
					);

					this.scrollService.scrollToTop();
				}
			}
		}
	}

	// #endregion
}
