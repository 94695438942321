import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-three-dots-loader',
	templateUrl: './three-dots-loader.component.html',
	styleUrls: ['./three-dots-loader.component.scss'],
})
export class ThreeDotsLoaderComponent implements OnInit {
	@Input() public isLoading = false;

	constructor() {}

	ngOnInit(): void {}
}
