import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { WorkspaceUiService } from '@core/services/ui/workspace/workspace-ui.service';
import { WorkspaceService } from '@core/services/workspace/workspace.service';
import { TranslateService } from '@ngx-translate/core';
import { PaginatedResponse } from '@shared/models/base/paginated-data-ui';
import { IBranchData } from '@shared/models/third-party/branch-data';
import { IWorkspace, IWorkspacePaginatedResponse } from '@shared/models/workspace/workspace';
import { SocketIoService, UserService } from '@shared/services';
import { ToastService } from '@shared/services/common/toast.service';
import CacheUtils from '@shared/utils/cache-utils';
import { timer } from 'rxjs';
import { SidebarService } from '../../services/ui/sidebar.service';
import { DailyMessageService } from '@shared/components/network/daily-message-widget/services/daily-message.service';
import { environment } from 'src/environments/environment';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'core-workspace-switch-bar',
	templateUrl: './workspace-switch-bar.component.html',
	styleUrls: ['./workspace-switch-bar.component.scss'],
})
export class WorkspaceSwitchBarComponent implements OnInit {
	@ViewChild('overlayButton', { read: ElementRef }) private overlayButton: ElementRef;

	@Input() public network: any;
	@Input() public username!: any;

	// workspace delete modal
	public showWorkspaceDeletedModal = false;
	public deletedWorkspace: IWorkspace;
	public currentUser: any;
	public searchString = '';
	public showWorkspaceListPopup = false;
	public singleWorkspaceApp = environment.single_workspace;

	public branchData: IBranchData;
	public workspacesResponse: IWorkspacePaginatedResponse = new PaginatedResponse();

	constructor(
		private workspaceService: WorkspaceService,
		private workspaceUiService: WorkspaceUiService,
		private toastService: ToastService,
		private translateService: TranslateService,
		private socket: SocketIoService,
		private router: Router,
		private userService: UserService,
		public sidebarService: SidebarService,
		private dailyMessageService: DailyMessageService
	) {
		// handle member deletion
		this.socket.getSocket().on('member-delete', (data: any) => {
			const socketData = JSON.parse(data);

			if (socketData.memberId) {
				this.handleDeleteMembership(socketData.memberId);
			}
		});
	}

	ngOnInit(): void {
		this.userService.currentUser.subscribe((userData: any) => {
			this.currentUser = userData;

			if (!Object.keys(userData).length) {
				this.router.navigate(['/authentication/login']);

				return;
			}

			// #region Product Fruits Implementation

			const productFruitUserData = {
				// REQUIRED, must be unique
				username: userData.username,
				email: userData.email,
				// eslint-disable-next-line spellcheck/spell-checker
				firstname: userData.firstName,
				// eslint-disable-next-line spellcheck/spell-checker
				lastname: userData.lastName || '',
				// Sign up date (yyyy-mm-dd hh:mm:ss)
				signUpAt: userData.dateJoined,
			};

			timer(500).subscribe(() => {
				if (userData && window['productFruitsUser']?.username !== productFruitUserData?.username && typeof window?.productFruits?.identifyUser === 'function') {
					window?.productFruits?.identifyUser(productFruitUserData);
				}
			});

			// #endregion
		});
	}

	// #region Loaders

	/**
	 * Load user's networks
	 */
	public loadUsersNetworks(): void {
		this.workspacesResponse.isLoading = !this.workspacesResponse.objects.length && this.workspacesResponse.next === null;
		this.workspacesResponse.isNextPageLoading = this.workspacesResponse.objects.length && this.workspacesResponse.next !== null;

		if (!this.workspacesResponse.isLoading && !this.workspacesResponse.isNextPageLoading) {
			return;
		}

		// we are removing the app id from the request to get the all workspaces
		this.workspaceService
			.getAllWorkspacesOfUser(this.username, this.workspacesResponse.isNextPageLoading ? this.workspacesResponse.offset : 0, 10)
			.subscribe((data: IWorkspacePaginatedResponse) => {
				if (this.workspacesResponse.next) {
					this.workspacesResponse.objects.push(...data.objects);
				} else {
					this.workspacesResponse = new PaginatedResponse(data);
				}

				this.workspacesResponse.next = data.next;
				this.workspacesResponse.offset = data.offset + 10;

				this.syncIsCurrentWorkspace();
			})
			.add(() => {
				timer(500).subscribe(() => (this.workspacesResponse.isLoading = false));
			});

		// sort workspaces
		this.workspacesResponse.objects = this.workspaceUiService.reOrderWorkspaces(this.workspacesResponse.objects);
	}

	/**
	 * Switch to selected workspace
	 *
	 * @param workspace IWorkspace
	 */
	private loadNetwork(workspace: IWorkspace): void {
		if (workspace.manager === true) {
			if (!localStorage.getItem('manager')) {
				const userMembership = {
					manager: workspace.manager,
					uid: workspace.uid,
				};

				CacheUtils.storeData('manager', JSON.stringify(userMembership));
			}
		}

		if (workspace.activityNotificationCount) {
			CacheUtils.storeData('notificationCount', workspace.activityNotificationCount);
		}

		this.workspacesResponse.isLoading = true;

		this.workspaceService
			.getWorkspace(workspace.network.uid)
			.subscribe((network: any) => {
				// const _network = new Network(network);

				workspace.network = network;

				// redirect to selected workspace
				this.workspaceUiService.redirectToWorkspace(workspace, this.currentUser.username);

				// reset network
				this.network = workspace;

				// sync current workspace
				this.syncIsCurrentWorkspace();

				// scroll to top
				window.scrollTo(0, 0);

				this.showWorkspaceListPopup = false;

				this.dailyMessageService.dailyMessageRefresh();
			})
			.add(() => {
				this.workspacesResponse.isLoading = false;
			});
	}

	// #endregion

	// #region UI Interactions

	/**
	 * Open workspace switch popup and load user's networks
	 */
	public onWorkspaceSwitchOpenClick(): void {
		this.showWorkspaceListPopup = true;

		if (this.workspacesResponse.count === 0) {
			this.loadUsersNetworks();
		}
	}

	/**
	 * Switch selected workspace
	 *
	 * @param workspace IWorkspace
	 * @returns void
	 */
	public onWorkspaceItemClick(workspace: IWorkspace): void {
		if (workspace.isCurrentWorkspace) {
			this.toastService.info(this.translateService.instant('You are already in this workspace'));

			return;
		}

		this.workspaceUiService.clearWorkspaceFromStorage();

		this.loadNetwork(workspace);
	}

	public onSearchEnter(event: any): void {
		if (event?.keyCode === 13) {
			//
		}
	}

	// #endregion

	// #region Helpers

	private syncIsCurrentWorkspace(): void {
		this.workspacesResponse?.objects?.forEach((workspace) => {
			workspace.isCurrentWorkspace = workspace.network.uid === this.network?.uid;
		});
	}

	/**
	 * Handle delete workspace
	 *
	 * @param uid user membership uid
	 */
	private handleDeleteMembership(uid: string): void {
		this.workspaceService.getAllWorkspacesOfUser(this.username, 0, 1000).subscribe((data: IWorkspacePaginatedResponse) => {
			const workspace = data.objects?.find((ws: IWorkspace) => ws.uid === uid);

			if (workspace) {
				this.deletedWorkspace = workspace;
				this.showWorkspaceDeletedModal = true;

				this.workspacesResponse.objects = this.workspacesResponse.objects.filter((ws: IWorkspace) => ws.uid !== uid);

				if (this.network.uid === workspace.uid) {
					this.network = null;
					this.router.navigateByUrl('/home');
				}
			}
		});
	}

	// #endregion
}
