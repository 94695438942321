import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

@Component({
	selector: 'app-task-timer-controls',
	templateUrl: './task-timer-controls.component.html',
	styleUrls: ['./task-timer-controls.component.scss']
})
export class TaskTimerControlsComponent implements OnInit {
	@Input() public label: string;
	@Input() public timeForm: FormGroup;
	@Input() public isFormSubmitted: boolean;
	@Input() public showRequiredError: boolean;
	@Input() public requiredErrorMessage: string;


	@Output() public closeButtonClicked  = new EventEmitter<void>();

	// public hourInputValue = '00';
	// public minuteInputValue = '00';

	private hourStep = 1;

	private minuteStep = 10;

	constructor() {}

	ngOnInit(): void {
	}

	public onCloseButtonClick(): void{
		this.closeButtonClicked.emit();
	}

	public onHourInputFocus(): void {
		const hourInputValue = this.timeForm.get('hourInputValue').value;
		if(hourInputValue === '00'){
			this.timeForm.get('hourInputValue').setValue('');
		}
	}

	public hourInputFocusOut(): void{
		const hourInputValue = this.timeForm.get('hourInputValue').value;
		if(hourInputValue === ''){
			this.timeForm.get('hourInputValue').setValue('00');
		}
	}

	public onMinuteInputFocus(): void {
		const minuteInputValue = this.timeForm.get('minuteInputValue').value;
		if(minuteInputValue === '00'){
			this.timeForm.get('minuteInputValue').setValue('');
		}
	}

	public minuteInputFocusOut(): void{
		const minuteInputValue = this.timeForm.get('minuteInputValue').value;
		if(minuteInputValue === ''){
			this.timeForm.get('minuteInputValue').setValue('00');
		}
	}




	public increaseHour(): void {
		let hour: number | string = Number(this.timeForm.get('hourInputValue').value) || 0;
		// if (hour < 24) {
		hour+= this.hourStep;
		// }
		if(hour < 10){
			hour = '0'+ String(hour);
		}
		this.timeForm.get('hourInputValue').setValue(hour);
	}

	public decreaseHour(): void {
		let hour: number | string  = Number(this.timeForm.get('hourInputValue').value) || 0;
		if (hour > 0) {
			hour-= this.hourStep;
		}
		if(hour < 10 && hour >= 0){
			hour = '0'+ String(hour);
		}
		this.timeForm.get('hourInputValue').setValue(hour);
	}

	public increaseMinute(): void {
		let minute: number | string= Number(this.timeForm.get('minuteInputValue').value) || 0;

		if (minute < 50) {
			minute += this.minuteStep;
		}else{
			minute = 0;
		}
		if(minute < 10){
			minute = '0'+ String(minute);
		}
		console.log(minute);
		this.timeForm.get('minuteInputValue').setValue(minute);
	}

	public decreaseMinute(): void {
		let minute: number | string = Number(this.timeForm.get('minuteInputValue').value) || 0;

		if (minute > 9) {
			minute-= this.minuteStep;
		}else{
			minute = 0;
		}
		if(minute < 10 && minute >= 0){
			minute = '0'+ String(minute);
		}
		this.timeForm.get('minuteInputValue').setValue(minute);
	}

}


export function nonZeroAndValid(controlName1: string, controlName2: string): ValidatorFn {
	return (formGroup: FormGroup): ValidationErrors | null => {
	  const controlA = formGroup.get(controlName1);
	  const controlB = formGroup.get(controlName2);
	  if (Number(controlA?.value) !== 0 || Number(controlB?.value) !== 0) {
			return null; // Return null if validation passes
	  } else {
			return { nonZeroAndValid: true }; // Return an error object if validation fails
	  }
	};
}
