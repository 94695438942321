import { Component, Input, OnInit } from '@angular/core';
import { IBaseAttachment } from './../../shared/models/base-attachment';
import { IPersonAttachment } from './../../shared/models/person-attachment';
import { ImageAttachment } from '../../shared/models/image-attachment';
import { IFileAttachment } from '../../shared/models/file-attachment';
import { IURLAttachment } from '../../shared/models/url-attachment';
import { IEventAttachment } from '../../shared/models/event-attachment';
import { ILocationAttachment } from '../../shared/models/location-attachment';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'bee-attachment-body',
	templateUrl: './attachment-body.component.html',
	styleUrls: ['./attachment-body.component.scss'],
})
export class AttachmentBodyComponent implements OnInit {
	@Input() public baseAttachment: IBaseAttachment = null;
	@Input() public images: ImageAttachment[] = [];
	@Input() public url: IURLAttachment = null;
	@Input() public files: IFileAttachment[] = [];
	@Input() public person: IPersonAttachment = null;
	@Input() public event: IEventAttachment = null;
	@Input() public location: ILocationAttachment;
	@Input() public viewSize: 'sm' | 'md' | 'lg' = 'lg';

	constructor() {}

	ngOnInit(): void {
		if (this.baseAttachment) {
			this.images = this.baseAttachment.images;
			this.url = this.baseAttachment.url;
			this.files = this.baseAttachment.files;
			this.person = this.baseAttachment.person;
			this.location = this.baseAttachment.location;
			this.event = this.baseAttachment.event;
		}
	}
}
