import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouterModule } from '@angular/router';
import { AgmCoreModule } from '@agm/core';
import { AppComponent } from './app.component';
import { HomeModule } from './home/home.module';
import { AuthComponent } from './auth/auth.component';
import { AuthModule } from './auth/auth.module';
import { environment } from '../environments/environment';
import { NotificationModule } from './notification/notification.module';
import { HttpClientModule, HttpClient, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MockHttpCalIInterceptor } from './http.interceptor';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {
	ApiService,
	AuthGuard,
	CommentsService,
	FooterComponent,
	HeaderComponent,
	JwtService,
	ProfilesService,
	SharedModule,
	UserService,
	NetworkService,
	PostService,
	NotificationService,
	MemberService,
	EventService,
	BuySellService,
	LocationService,
	FileService,
	InboxService,
	GeoLocationService,
	SocketIoService,
	CollectionsService,
	ProductService,
	LayoutService,
	TimeZoneService,
	PbTranslateService,
	MessagingService,
	PaginationService,
	ReportService,
} from './shared';
import { NetworkModule } from './network/network.module';
import { UserModule } from './user/user.module';
import { PostDetailModule } from './post/post-detail.module';
import { PostModule } from './post/post.module';
import { CollectionModule } from './collections/collections.module';
import { LocationModule } from './location/location.module';
import { ChannelModule } from './channel/channel.module';
import { NetworkNotificationModule } from './network-notification/notification.module';
import { EventModule } from './event/event.module';
import { LocationListModule } from './location-list/location-list.module';
import { BuySellModule } from './buy-sell/buy-sell.module';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { NotificationControlComponent } from './notification-control/notification-control.component';
import { PopupComponent } from './popup/popup.component';
import { ZoomAppAuthComponent } from './zoom-app-auth/zoom-app-auth.component';
import { ZoomComponent } from './zoom/zoom.component';
import { CreateTodolistComponent } from './todolist/create-todolist/create-todolist.component';
import { TodolistDetailComponent } from './todolist/todolist-detail/todolist-detail.component';
import { PurchasePlansComponent } from './purchase/purchase-plans/purchase-plans.component';
import { PurchasePageComponent } from './purchase/purchase-page/purchase-page.component';
import { PurchaseStatusComponent } from './purchase/purchase-status/purchase-status.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarViewComponent } from './calendar/calendar-view.component';

import { registerLocaleData } from '@angular/common';
import localeTr from '@angular/common/locales/tr';
import { ReportsModule } from './reports/reports.module';
import { PointsComponent } from './pointLadder/points/points.component';
import { CustomerStatesComponent } from './customers/customer-states/customer-states.component';
import { CustomersListComponent } from './customers/customers-list/customers-list.component';
import { CustomerDetailComponent } from './customers/customer-detail/customer-detail.component';
import { ProjectDetailsComponent } from './todolist/project-details/project-details.component';
import { TermsComponent } from './purchase/terms/terms.component';
import { LocationDetailComponent } from './location-detail/location-detail.component';
import { OauthComponent } from './oauth/oauth.component';
import { ProductsComponent } from './product/products/products.component';
import { ProductDetailComponent } from './product/product-detail/product-detail.component';
import { IssueDetailComponent } from './product/issue-detail/issue-detail.component';
import { ArchiveProductsComponent } from './product/archive-products/archive-products.component';
import { AdminPanelComponent } from './admin-settings/admin-panel/admin-panel.component';
import { MainPanelComponent } from './admin-settings/main-panel/main-panel.component';
import { AdminMembersComponent } from './admin-settings/admin-members/admin-members.component';
import { AddMembersComponent } from './admin-settings/add-members/add-members.component';
import { AdminAuthenticationComponent } from './admin-settings/admin-authentication/admin-authentication.component';
import { CreateProductComponent } from './product/create-product/create-product.component';
import { CustomerBoardViewComponent } from './customers/customer-board-view/customer-board-view.component';
import { PointDetailComponent } from './point-detail/point-detail.component';
import { ZapierComponent } from './zapier/zapier.component';

import * as Sentry from '@sentry/angular';
import { ContextMenuModule, ContextMenuService } from '@perfectmemory/ngx-contextmenu';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';
import appRoutes from './app-routing.module';
import { HomeAuthResolver } from './home/home-auth-resolver.service';
import { PeerCoreModule } from 'projects/peer-core/src/public-api';
import { LightboxService } from 'projects/peer-core/src/lib/services/ui/lightbox.service';
import { Lightbox } from 'ng-gallery/lightbox';
import { BeeAvatarModule } from 'projects/bee-ui/src/lib/modules/avatar/avatar.module';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { FormsModule } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { BeeAttachmentModule } from 'projects/bee-attachment/src/public-api';
import { PreviousRouteService } from '@shared/services/previous-route.service';
import { MarkdownModule } from 'ngx-markdown';
import { QuickChatModule } from '@modules/chat/components/quick-chat/quick-chat.module';

registerLocaleData(localeTr);

const config: SocketIoConfig = <SocketIoConfig>{
	// eslint-disable-next-line spellcheck/spell-checker
	url: environment.soketio_url,
	options: {
		reconnection: true,
		reconnectionDelay: 1000,
		reconnectionDelayMax: 5000,
		reconnectionAttempts: 50,
		timeout: 10000,
		autoConnect: true,
		pingInterval: 10000,
		pingTimeout: 5000,
		forceNew: true,
	},
};

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http);
}

// eslint-disable-next-line spellcheck/spell-checker
// The lottie - web library can be loaded on demand using dynamic import.
// WebPack will load this library only when your animation gets rendered
// for the first time.Given the following code:
export function playerFactory(): any {
	return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

declare global {
	interface Window {
		analytics: any;
		productFruits: any;
	}
}

@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		FooterComponent,
		AuthComponent,
		NotificationControlComponent,
		PopupComponent,
		ZoomAppAuthComponent,
		ZoomComponent,
		CreateTodolistComponent,
		TodolistDetailComponent,
		PurchasePlansComponent,
		PurchasePageComponent,
		PurchaseStatusComponent,
		CalendarViewComponent,
		PointsComponent,
		CustomerStatesComponent,
		CustomersListComponent,
		CustomerDetailComponent,
		ProjectDetailsComponent,
		TermsComponent,
		LocationDetailComponent,
		ProductsComponent,
		ProductDetailComponent,
		IssueDetailComponent,
		ArchiveProductsComponent,
		AdminPanelComponent,
		MainPanelComponent,
		AdminMembersComponent,
		AddMembersComponent,
		OauthComponent,
		AdminAuthenticationComponent,
		CreateProductComponent,
		CustomerBoardViewComponent,
		PointDetailComponent,
		ZapierComponent,
	],
	imports: [
		BrowserModule,
		AgmCoreModule.forRoot({
			apiKey: environment.google_api_key,
		}),
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		SocketIoModule.forRoot(config),
		RouterModule.forRoot(appRoutes),
		SharedModule,
		AuthModule,
		HomeModule,
		NetworkModule,
		NotificationModule,
		UserModule,
		HttpClientJsonpModule,
		PostDetailModule,
		PostModule,
		NetworkNotificationModule,
		EventModule,
		LocationListModule,
		BuySellModule,
		LocationModule,
		ChannelModule,
		QuickChatModule,
		BrowserModule,
		CollectionModule,
		NgbModule,
		AngularFireDatabaseModule,
		AngularFireAuthModule,
		AngularFireMessagingModule,
		AngularFireModule.initializeApp(environment.firebase),
		InfiniteScrollModule,
		BrowserAnimationsModule,
		ReportsModule,
		CalendarModule.forRoot({
			provide: DateAdapter,
			useFactory: adapterFactory,
		}),
		ContextMenuModule,
		DragDropModule,
		ScrollingModule,
		NgxMaterialTimepickerModule,
		LottieModule.forRoot({ player: playerFactory }),
		LottieCacheModule.forRoot(),
		PeerCoreModule,
		BeeAvatarModule,
		FormsModule,
		NzIconModule,
		BeeAttachmentModule,
		MarkdownModule.forRoot(),
	],
	providers: [
		Lightbox,
		LightboxService,
		ApiService,
		AuthGuard,
		UserService,
		JwtService,
		NetworkService,
		PostService,
		NotificationService,
		MemberService,
		CommentsService,
		EventService,
		BuySellService,
		LocationService,
		ProfilesService,
		FileService,
		InboxService,
		GeoLocationService,
		SocketIoService,
		CollectionsService,
		ProductService,
		LayoutService,
		TimeZoneService,
		// LayerService,
		PbTranslateService,
		MessagingService,
		PaginationService,
		ReportService,
		ContextMenuService,
		HomeAuthResolver,
		{ provide: LOCALE_ID, useValue: localStorage.getItem('language') },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MockHttpCalIInterceptor,
			multi: true,
		},
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false,
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => (): void => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
		{ provide: NZ_I18N, useValue: en_US },
		PreviousRouteService,
	],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	exports: [TranslateModule],
})
export class AppModule {}
