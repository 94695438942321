// TODO: deprecated, remove this file
export class Subtask {
	public category = 'todo';
	public network: string = localStorage.getItem('network_id');
	public collection_post: any = true;
	public commentable: any = true;
	public priority = 'p2';
	public repeatCycle: any = 'daily';
	public shareable: any = true;
	public state: any = 'active';
	public title: any = null;
	public altered_title: any = null;
	public type: any = 'todo';
	public edit = false;
	public isEditing = false;
	public assignedUser: any = localStorage.getItem('uid');
	public parent_content_type: any;
	public parent_content_uid: any;
	public myRelations: any = {};

	constructor(init?: Partial<Subtask>) {
		Object.assign(this, init);
	}
}
