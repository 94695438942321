import { BaseApiService } from 'projects/peer-core/src/lib/services/base/base-api.service';
import { Injectable } from '@angular/core';
import { TokenService } from 'projects/peer-core/src/lib/services/base/token.service';
import * as store from 'store';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpMethod } from '@core/types/http-method.enum';

class CustomEncoder implements HttpParameterCodec {
	public encodeKey(key: string): string {
		return encodeURIComponent(key);
	}

	public encodeValue(value: string): string {
		return encodeURIComponent(value);
	}

	public decodeKey(key: string): string {
		return decodeURIComponent(key);
	}

	public decodeValue(value: string): string {
		return decodeURIComponent(value);
	}
}

@Injectable({
	providedIn: 'root',
})
export class AlphaService extends BaseApiService {
	public errorDetail: any;

	private udid: string;
	private deviceToken: string;
	private fcmToken: string;
	private networkId: string;

	constructor(private _http: HttpClient, private _tokenService: TokenService) {
		super(_http, _tokenService);

		this.init();
	}

	private init(): void {
		this.udid = this._tokenService.getUdid();
		this.deviceToken = this._tokenService.getDeviceToken();
		this.fcmToken = this._tokenService.getFcmToken();
		this.networkId = this._tokenService.getUid();
	}

	public get(path: string, params: HttpParams = new HttpParams(), useAlphaEndpoint: boolean = false): Observable<any> {
		console.log('get', path, params, useAlphaEndpoint);
		params.set('udid', this.udid || this.deviceToken);
		params.set('app_id', this.networkId);
		params.set('l', this.getLanguage());

		// eslint-disable-next-line @typescript-eslint/naming-convention
		const __includeAppId = params.get('__includeAppId') || false;

		if (__includeAppId) {
			params = params.delete('app_id');
			params = params.delete('__includeAppId');
		}

		if (this.fcmToken && !params.get('gcmid')) {
			params.set('gcmid', this.fcmToken);
		}

		let url = useAlphaEndpoint ? `${environment.api_url.split('/alpha')[0]}${path}` : `${environment.api_url}${path}`;

		url = url.replace(/([^:]\/)\/+/g, '$1');

		return this.makeRequestDirectly(HttpMethod.GET, url, params).pipe(catchError(this.formatErrors));
	}

	public post(path: string, body: Object = {}, params: HttpParams = new HttpParams(), useAlphaEndpoint: boolean = false): Observable<any> {
		console.log('get', path, params, useAlphaEndpoint);

		params.set('udid', this.udid || this.deviceToken);
		params.set('app_id', this.networkId);
		params.set('l', this.getLanguage());

		let data = new HttpParams({ encoder: new CustomEncoder() });

		for (const key in body) {
			if (Object.prototype.hasOwnProperty.call(body, key)) {
				const value: string | number | boolean | Date = body[key];

				if (typeof value !== 'undefined' && value !== null) {
					data = data.append(key, value.toString());
				}
			}
		}

		if (this.fcmToken && !params.get('gcmid')) {
			params.set('gcmid', this.fcmToken);
		}

		let url = useAlphaEndpoint ? `${environment.api_url.split('/alpha')[0]}${path}` : `${environment.api_url}${path}`;
		url = url.replace(/([^:]\/)\/+/g, '$1');

		return this.makeRequestDirectly(HttpMethod.POST, url, data, params).pipe(catchError(this.formatErrors));
	}

	// #region Helpers

	private getLanguage(): string {
		const lang = store.get('language');

		if (lang === 'tr') {
			return 'tr-TR';
		} else {
			return 'en-IN';
		}
	}

	private formatErrors(error: any): any {
		let message = error.error;

		if (message === undefined || message === null || !message) {
			message = error.message || error.detail || error;

			return [];
		}

		if (message.detail) {
			this.errorDetail = message.detail;

			const x = { detail: this.errorDetail, status: error.status };

			localStorage.setItem('status', JSON.stringify(x));
		} else if (message.password) {
			this.errorDetail = message.password[0];
		} else if (error.status === 0) {
			const x = { detail: 'Time out issue', status: 0 };

			localStorage.setItem('status', JSON.stringify(x));
		} else if (message.errors && message.errors.error && message.errors.error.message) {
			this.errorDetail = message.errors.error.message;

			const x = { detail: this.errorDetail, status: '400' };

			localStorage.setItem('status', JSON.stringify(x));
		} else if (message['url']) {
			this.errorDetail = message['url'][0];

			const x = { detail: this.errorDetail, status: error.status };

			localStorage.setItem('status', JSON.stringify(x));
		} else {
			const x = { detail: '', status: error.status };

			localStorage.setItem('status', JSON.stringify(x));
		}

		let response: any = {};

		try {
			response = error.json();
		} catch (ex) {
			try {
				response.errors = error;
			} catch (er) {
				response.errors = error.toString();
			}
		}

		switch (error.status) {
			case 404:
				response = {
					errors: 'Oops, it looks like that resource was not found',
				};
				break;
			case 500:
				response = {
					errors: 'Oops, Something went wrong please try again later.',
				};
				break;
		}

		return response;
	}

	// #endregion
}
