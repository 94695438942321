import { IPaginatedUI } from '../base/paginated-data-ui';

export interface IWorkspaceDepartmentResponse extends IPaginatedUI {
	count: number;
	next: null;
	previous: string;
	offset: number;
	objects: IWorkspaceDepartment[];
}

export interface IWorkspaceDepartment {
	uid: string;
	name: string;
	abbreviation: string;
}

export class WorkspaceDepartmentResponse implements IWorkspaceDepartmentResponse {
	public count: number;
	public next: null;
	public previous: string;
	public objects: IWorkspaceDepartment[];

	public isLoading = false;
	public offset = 0;
	public limit = 20;

	constructor(data: IWorkspaceDepartmentResponse = <IWorkspaceDepartmentResponse>{}) {
		Object.assign(this, data);
	}
}
