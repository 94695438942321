/* eslint-disable spellcheck/spell-checker */
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NetworkService, PostService } from '@shared/services';
import { ToastService } from '@shared/services/common/toast.service';
import { CalendarMonthViewBeforeRenderEvent, CalendarView } from 'angular-calendar';
import { Subject } from 'rxjs';
import format from 'date-fns/format';
import { ProjectService } from '@modules/project/services/project.service';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';
import { ICurrentUser } from '@shared/models';

@Component({
	selector: 'app-schedule-o-to-o-meeting-modal',
	templateUrl: './schedule-o-to-o-meeting-modal.component.html',
	styleUrls: ['./schedule-o-to-o-meeting-modal.component.scss'],
})
export class ScheduleOToOMeetingModalComponent implements OnInit {
	@Input() public networkId: any;
	@Input() public currentUser: ICurrentUser;

	private startDate: Date;
	private endDate: Date;
	private dummyModel: any;
	public meetingTime: string;
	private managerUID: any;

	// #region Calendar View
	public calendarViewDate: Date = new Date();
	public view: CalendarView = CalendarView.Month;
	public CalendarView = CalendarView;
	public calendarSelectedDay: any = null;
	public refreshCalendar: Subject<void> = new Subject();
	private beforeMonthViewRenderEvent: CalendarMonthViewBeforeRenderEvent = null;

	// #endregion

	constructor(
		public activeModal: NgbActiveModal,
		private translateService: TranslateService,
		private toastService: ToastService,
		private postService: PostService,
		private networkService: NetworkService,
		private projectService: ProjectService,
		private pageLoadingUiService: PageLoadingUiService
	) {}

	ngOnInit(): void {
		this.networkService.currentNetwork.subscribe((network) => {
			this.managerUID = network.membership.manager.userId;
		});
	}

	public closeModal(): void {
		this.activeModal?.close();
	}

	/**
	 * Function to run before calendar render
	 * @param renderEvent Render event
	 */
	public beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
		this.beforeMonthViewRenderEvent = renderEvent;

		this.beforeMonthViewRenderEvent.body.forEach((day: any) => {
			if (this.calendarSelectedDay) {
				if (format(day.date, 'yyyy-MM-dd') === format(this.calendarSelectedDay.date, 'yyyy-MM-dd')) {
					day.cssClass = 'bg-peach';
				}
			}
		});
	}

	/**
	 * Function on clicking a day in calendar
	 * @param day event on day selection
	 */
	public onCalendarDayClicked(event: { day: any; sourceEvent: PointerEvent }): void {
		if (!event.day?.isPast) {
			this.calendarSelectedDay = event.day;
			this.refreshCalendar.next();
		} else {
			this.toastService.info('Please select valid date');
		}
	}

	/**
	 * Converts local time to UTC
	 * @param date Local Time
	 * @returns UTC Conversion
	 */
	private convertLocalTimeToUTCTime(date: Date): Date {
		const isoDate = date.toISOString();
		return new Date(`${isoDate.substring(0, 10)} ${isoDate.substring(11, 19)}`);
	}

	/**
	 * Change Month
	 * @param event
	 */
	public onCalendarMonthChange(): void {
		this.calendarSelectedDay = null;
	}

	/**
	 * Create post and task for meeting
	 */
	public createEvent(): void {
		if (this.calendarSelectedDay && this.meetingTime) {
			const timeArray = this.meetingTime.split(':');
			const hour = Number(timeArray[0]);
			const minutes = Number(timeArray[1]);
			const selectedDay = this.calendarSelectedDay.date;

			selectedDay.setHours(hour);
			selectedDay.setMinutes(minutes);
			selectedDay.setSeconds(0);

			const starts = new Date(selectedDay);
			const ends = starts.setHours(starts.getHours() + 1);
			this.startDate = new Date(selectedDay);
			this.endDate = new Date(ends);

			const startDateUTC = this.convertLocalTimeToUTCTime(this.startDate);
			const endDateUTC = this.convertLocalTimeToUTCTime(this.endDate);

			this.dummyModel = {
				network: this.networkId,
				username: this.currentUser?.username,
				attachmentType: 'photo',
				category: 'meeting',
				commentable: 1,
				shareable: 0,
				cost: 'free',
				duration: 30,
				starts: format(startDateUTC, 'yyyy-MM-dd HH:mm:ss'),
				ends: format(endDateUTC, 'yyyy-MM-dd HH:mm:ss'),
				custom_privacy: '[' + [this.currentUser.userId] + ',' + [this.managerUID] + ']',
				images: '40199d8cb8b1920f8e4be84b8842e5b934b52e22_v0baRsJ',
				state: 'active',
				title: '1:1 Meeting',
				type: 'meeting',
			};

			this.pageLoadingUiService.setPageLoading(true);
			// API Call for creating No Meeting Day Post
			this.postService.create(this.dummyModel).subscribe((res: any) => {
				if (res) {
					const task = {
						assignedUser: this.currentUser?.username,
						category: 'todo',
						collection_post: true,
						commentable: true,
						description: '',
						endTime: format(endDateUTC, 'yyyy-MM-dd HH:mm:ss'),
						network: this.networkId,
						oneOff: format(startDateUTC, 'yyyy-MM-dd HH:mm:ss'),
						priority: 'p1',
						repeatCycle: 'one_off',
						selectedDueDate: 'one_off',
						startTime: format(startDateUTC, 'yyyy-MM-dd HH:mm:ss'),
						state: 'active',
						title: this.dummyModel.title,
						type: 'todo',
						uid: res.uid,
					};
					// Second API Call for creating No Meeting Day task to task list
					this.projectService
						.createToDo(task, this.currentUser?.username)
						.subscribe(() => {
							this.toastService.success(this.translateService.instant('New 1:1 Meeting Event Created'));
							this.closeModal();
						})
						.add(() => {
							this.pageLoadingUiService.setPageLoading(false);
						});
				}
			});
		} else {
			this.toastService.info('Please select valid time and date');
		}
	}
}
