import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class DailyMessageService {
	@Output() public dailyMessageUpdatedEvent: EventEmitter<any> = new EventEmitter<any>();
	@Output() public dailyMessageRefreshedEvent: EventEmitter<any> = new EventEmitter<any>();
	@Output() public dailyActionClickedEvent: EventEmitter<any> = new EventEmitter<any>();

	constructor() {}

	public dailyMessageUpdate(data: any): void {
		this.dailyMessageUpdatedEvent.emit(data);
	}

	public dailyMessageRefresh(): void {
		this.dailyMessageRefreshedEvent.emit();
	}

	public dailyActionClick(data: any): void {
		this.dailyActionClickedEvent.emit(data);
	}
}
