import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UserService, NetworkService, MemberService, ApiService, Network, GeoLocationService, SidebarComponent, SocketIoService, ProfilesService } from '../../shared';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { ToastService } from '../../shared/services/common/toast.service';
import { timer } from 'rxjs';
import { ClipboardService } from 'ngx-clipboard';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';
import { MixPanelService } from '@core/services/third-party/mix-panel.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { PreviousRouteService } from '@shared/services/previous-route.service';
import { SegmentAnalyticsService } from '@core/services/third-party/segment-analytics.service';

@Component({
	selector: 'app-join-work',
	templateUrl: './join-work.component.html',
	styleUrls: ['./join-work.component.scss'],
})
export class JoinWorkComponent implements OnInit, AfterViewInit {
	public appLogo = environment.logo;
	public appName = environment.appName;
	public loadingGif = 'none';
	public myFormGroup: FormGroup;
	public createNetworkForm: FormGroup;
	public stateParam = 0;
	public name = '';
	public validationValue;
	public currentYear: any;
	public networkUid: any;
	public domainName;
	public searchQuery;
	public currentLocation: any;
	public createdNetworkMembershipId;
	public nextUrl;
	public networkEmail;
	public networkName;
	public selectedRole: any;
	public role: any;
	public roles = [];
	public roleFieldsModel = {
		department: '',
		location: '',
		role: '',
		start: '',
		title: '',
		manager: '',
		state: 'active',
	};
	public email: any;
	public membershipId: any;
	public selectedNetworkId;
	public selectedNetwork;
	public displayInviteConfirmation = 'none';
	public displayError = 'none';
	public displayPendingCancel = 'none';
	public searchText: any;
	public modelText: any;
	public networks = [];
	public isSearching = false;
	public activeMemberships = [];
	public seatsExceeded = false;
	public inviteOnlyNetwork = false;
	public roleFields = [];
	public selectedDepartment: any;
	public selectedLocation: any;
	public selectedMember: any;
	public currentUser: any;
	public displayDepartmentsModal = 'none';
	public displayLocationsModal = 'none';
	public displayMembersModal = 'none';
	public departments = [];
	public locations = [];
	public members = [];
	public noDepartments = false;
	public noLocations = false;
	public noMembers = false;
	public paramsExists = false;
	public department = { uid: '' };
	public location = { uid: '' };
	public member = { id: '' };
	// -Variables for new UIs
	public branchContentType;
	public branchContentUid;
	public isMembershipCancel = false;
	public displayEmptyError = 'none';
	public displayDeleteDialog = 'none';
	public network;
	public view = 'initial';
	// public view = 'success';
	public shareLink;
	public isCountUpdated = false;
	public membershipNextUrl;
	public existingNetwork;
	public defaultDomain;
	public language = 'en';
	public solider;
	public loader = 0;
	public showCancel = true;
	public clearSearch = false;
	public settingRoles = 0;
	public newNetwork;
	public scrollStatus = false;
	public memberId = '';
	public socketMemberId = '';
	public sectors = [
		{ name: 'Business', slug: 'business' },
		{ name: 'Municipality', slug: 'municipality' },
		{ name: 'Retail', slug: 'retail' },
		{ name: 'Education', slug: 'education' },
		{ name: 'Non-profits', slug: 'non_profit' },
	];

	public queryField: FormControl = new FormControl();
	@ViewChild(SidebarComponent, { static: true }) public sidebar;

	public departmentForm: FormGroup;
	public newDepartmentAddView = false;
	private selectedNetworkUID = '';
	private previousURL: string;

	constructor(
		private userService: UserService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private networkService: NetworkService,
		private profileService: ProfilesService,
		private translate: TranslateService,
		private geoLocationService: GeoLocationService,
		private apiService: ApiService,
		private fb: FormBuilder,
		private socket: SocketIoService,
		private _location: Location,
		private memberService: MemberService,
		private toastService: ToastService,
		private clipboardService: ClipboardService,
		private pageLoadingUiService: PageLoadingUiService,
		private mixPanelService: MixPanelService,
		private previousRouteService: PreviousRouteService,
		private segmentService: SegmentAnalyticsService
	) {
		this.createNetworkForm = this.fb.group({
			name: ['', Validators.required],
			subType: [this.sectors[0]],
			type: ['enterprise'],
			verify: [true],
		});

		this.departmentForm = this.fb.group({
			name: ['', Validators.required],
		});
	}

	ngOnInit(): void {
		this.clearBranch();

		/**
		 * To get the previous route
		 */
		this.previousRouteService.previousURL.subscribe((url: string) => {
			console.log('previous', url);
			this.previousURL = url;
		});

		this.activatedRoute.queryParams.subscribe((params) => {
			if (params && params['id']) {
				this.selectedNetworkId = params['id'];
				this.membershipId = params['membership'];
				this.paramsExists = true;

				if (params['inviteOnly']) {
					this.view = 'adminApproval';
					this.getMemberShipDetails(this.selectedNetworkId);
					this.getNetworkSubscriptionDetails(this.selectedNetworkId);
				}
				if (params['roleValidation']) {
					this.view = 'emailValidation';
					this.getMemberShipDetails(this.selectedNetworkId);
					this.getNetworkSubscriptionDetails(this.selectedNetworkId);
					if (params['email']) {
						this.email = params['email'];
						this.defaultDomain = params['email'];
					} else {
						this.email = '';
					}
				}
				if (params['admin']) {
					if (params['validationMethod']) {
						if (params['validationMethod'] === 'email') {
							this.view = 'emailValidation';
							if (params['email']) {
								this.email = params['email'];
								this.defaultDomain = params['email'];
							}
						} else if (params['validationMethod'] === 'multiple-role') {
							this.view = 'roleSelection';
							this.loader = 1;
							this.networkService.get(params['id']).subscribe((networkData) => {
								this.setRoleForNetworkAdmin(networkData);
								if (networkData['membership'] && networkData['membership'].uid) {
									this.createdNetworkMembershipId = networkData['membership'].uid;
								}
								if (networkData['inviteOnly']) {
									this.stateParam = 1;
								} else {
									this.stateParam = 0;
								}
							});
						} else {
							this.view = 'roleFields';
							this.loader = 1;
							this.networkService.get(params['id']).subscribe((networkData) => {
								this.setRoleForNetworkAdmin(networkData);
								if (networkData['membership'] && networkData['membership'].uid) {
									this.createdNetworkMembershipId = networkData['membership'].uid;
								}
								if (networkData['inviteOnly']) {
									this.stateParam = 1;
								} else {
									this.stateParam = 0;
								}
							});
						}
					}
				}
			}

			if (params && params['branch_networkId']) {
				this.getBranchNetworkDetails(params['branch_networkId']);
				if (params['content_type'] && params['content_uid']) {
					this.branchContentType = params['content_type'];
					this.branchContentUid = params['content_uid'];
				}
			}
		});

		if (this.view === 'initial') {
			this.getMembershipsLists();
		}

		this.currentUser = this.userService.getCurrentUser();
		const accuracy = { enableHighAccuracy: true }; // 6
		this.geoLocationService.getLocation(accuracy).subscribe(
			(position) => {
				this.currentLocation = position;
			},
			function () { }
		);

		const date = new Date();
		this.currentYear = date.getFullYear();

		if (localStorage.getItem('language') && localStorage.getItem('language') === 'tr') {
			this.language = 'tr';
		}

		if (this.queryField) {
			this.domainName = '';
			this.queryField.valueChanges.pipe(debounceTime(1250)).subscribe((query) => {
				if (query) {
					this.isSearching = true;
					this.searchText = query;
					this.networkService.getNetworks(query, 'enterprise').subscribe(
						(data) => {
							this.isSearching = false;
							this.existingNetwork = '';
							if (data['objects'].length > 0) {
								this.networks = data.objects;
							} else {
								this.displayEmptyError = 'block';
								this.networks = [];
							}
						},
						() => {
							this.isSearching = false;
							this.networks = [];
						}
					);
				} else {
					this.isSearching = false;
					this.networks = [];
				}
			});
		}

		this.socket.getSocket().on('member-validation', (data) => {
			const d = JSON.parse(data);

			if (d['user'] && d['user']['membership']) {
				const membership = d['user']['membership'];
				if (membership['member_id']) {
					this.socketMemberId = membership['member_id'];
				}
			}

			if (this.memberId) {
				if (this.socketMemberId === this.memberId) {
					if (this.view === 'emailValidation' || this.view === 'adminApproval') {
						if (this.selectedNetworkId && !this.seatsExceeded && !this.createdNetworkMembershipId) {
							this.networkService.get(this.selectedNetworkId).subscribe((networkData) => {
								this.newNetwork = networkData;
								this.view = 'success';
								if (!this.isCountUpdated) {
									this.handleMembershipCount();
								}
							});
						}
					}
				} else {
				}
			}
		});

		/** Handle Socket callback on member-delete event
		 * 1. Check if data on callback contains memberId and its same as current membership id.
		 * 2. Display dialog popup.
		 */
		this.socket.getSocket().on('member-delete', (data) => {
			if (!this.isMembershipCancel && (this.view === 'adminApproval' || this.view === 'emailValidation' || this.view === 'success')) {
				const socketData = JSON.parse(data);
				if (socketData['memberId'] && socketData['memberId'] === this.membershipId) {
					// 1
					this.displayDeleteDialog = 'block'; // 2
				} else {
					this.displayDeleteDialog = 'none';
				}
			}
		});
	}

	ngAfterViewInit(): void {
		const scrollToTop = window.setInterval(() => {
			const position = window.pageYOffset;

			if (position > 0) {
				window.scrollTo(0, position - 50);
			} else {
				window.clearInterval(scrollToTop);
			}
		}, 3);
	}

	private getMembershipsLists(): void {
		this.userService.getMemberships().subscribe((data) => {
			if (data.next) {
				this.membershipNextUrl = data.next.split('alpha')[1];
				this.paginate();
			} else {
				this.membershipNextUrl = '';
			}
			this.activeMemberships = data.objects;
		});
	}

	public paginate(): void {
		if (this.membershipNextUrl) {
			this.userService.paginate(this.membershipNextUrl).subscribe((data) => {
				this.activeMemberships = this.activeMemberships.concat(data.objects);
				if (data.next) {
					this.membershipNextUrl = data.next.split('alpha')[1];
					this.paginate();
				} else {
					this.membershipNextUrl = '';
				}
			});
		}
	}

	/** Function to get back to networks list page */
	private backToNetworks(): void {
		this._location.back();
	}

	private selectNetwork(network): void {
		if (network['name']) {
			this.name = network['name'];
		}
		if (network?.uid) {
			this.selectedNetworkUID = network?.uid;
		}
		if (this.activeMemberships.length >= 0) {
			this.createdNetworkMembershipId = '';
			this.email = '';
			const index = this.activeMemberships.findIndex((membership) => membership['network'].uid === network.uid);

			if (index !== -1) {
				const membership = this.activeMemberships[index];

				if (membership.state === 1) {
					this.displayError = 'block';
					this.modelText = this.translate.instant('You have already joined in this network');
				}
				if (membership.state === 0) {
					if (membership['network']['adminUsername'] !== this.currentUser['username']) {
						this.membershipId = membership.uid;
						this.selectedNetworkId = membership['network'].uid;
						if ((membership.validationMethod === 1 || membership.validationMethod === 2) && !membership['network']['inviteOnly']) {
							this.view = 'emailValidation';
							this.getNetworkSubscriptionDetails(this.selectedNetworkId);
							if (membership.network && membership['network']['validationValue']) {
								const validationValue = membership['network']['validationValue'];
								if (validationValue.indexOf('://') > -1) {
									const domain = validationValue.split('://')[1];
									this.defaultDomain = '@' + domain;
									this.email = '@' + domain;
								} else if (validationValue.indexOf('www.') > -1) {
									const domain = validationValue.split('www.')[1];
									this.defaultDomain = '@' + domain;
									this.email = '@' + domain;
								} else {
									this.defaultDomain = '@' + validationValue;
									this.email = '@' + validationValue;
								}
							}
						}
						if (membership['network'] && membership['network']['inviteOnly']) {
							this.view = 'adminApproval';
							this.getNetworkSubscriptionDetails(this.selectedNetworkId);
						}
					} else {
						if (!membership['validationMethod']) {
							if (network['inviteOnly']) {
								this.stateParam = 1;
							} else {
								this.stateParam = 0;
							}
							this.setRoleForNetworkAdmin(network);
						} else {
							this.view = 'emailValidation';

							if (membership.network && membership['network']['validationValue']) {
								const validationValue = membership['network']['validationValue'];
								if (validationValue.indexOf('://') > -1) {
									const domain = validationValue.split('://')[1];
									this.defaultDomain = '@' + domain;
								} else if (validationValue.indexOf('www.') > -1) {
									const domain = validationValue.split('www.')[1];
									this.defaultDomain = '@' + domain;
								} else {
									this.defaultDomain = '@' + validationValue;
								}
							}

							if (this.checkEmail(this.queryField['value'])) {
								this.email = this.queryField['value'];
							} else {
								this.email = this.defaultDomain;
							}
						}
					}
				}
			} else {
				this.resetRoleFields();

				if (this.branchContentType !== 'External-Channels') {
					if (network.roles && network.roles.length > 0) {
						const indexOfExternal = network.roles.findIndex((role) => role.slug === 'external');
						if (indexOfExternal > -1) {
							network.roles.splice(indexOfExternal, 1);
						}
						if (network.roles.length === 1) {
							if (network.inviteOnly) {
								if (network['adminUsername'] && this.currentUser['username'] === network['adminUsername']) {
									this.inviteOnlyNetwork = false;
									this.stateParam = 1;
								} else {
									this.inviteOnlyNetwork = true;
									this.stateParam = 0;
								}
								this.networkEmail = network['email'];
							} else {
								this.inviteOnlyNetwork = false;
							}
							this.networkUid = network.uid;
							this.role = network.roles[0];

							if (network['membershipUid']) {
								this.setRoleForNetworkAdmin(network);
							} else {
								this.getRoleFields(network.uid);
							}
						} else {
							this.selectedNetwork = network;
							this.networkName = network['name'];
							this.roles = network['roles'];
							this.selectedRole = this.roles[0];
							this.view = 'roleSelection';
						}
					}
				} else {
					this.stateParam = 1;
					this.role = { slug: 'external', name: 'External' };

					this.getRoleFields(network.uid);
				}
			}
		}
	}

	public onSubmitRoleDetails(): void {
		if (!this.inviteOnlyNetwork) {
			this.myFormGroup.markAllAsTouched();
			if (this.myFormGroup.valid) {
				let roleDetails = {};
				roleDetails = this.myFormGroup.value;

				roleDetails['state'] = this.stateParam;

				if (this.role.slug) {
					roleDetails['role'] = this.role.slug;
					roleDetails['network'] = this.networkUid;
				}
				if (roleDetails['department']) {
					roleDetails['department'] = this.selectedDepartment.uid;
				}
				if (roleDetails['location']) {
					roleDetails['location'] = this.selectedLocation.uid;
				}
				if (roleDetails['manager']) {
					roleDetails['manager'] = this.selectedMember.uid;
				} else {
					delete roleDetails['manager'];
				}

				if (!this.createdNetworkMembershipId) {
					this.settingRoles = 1;
					this.networkService.postRoleDetails(roleDetails).subscribe(
						(data) => {
							this.network = data;
							this.newNetwork = data;
							this.settingRoles = 0;
							if (data['network'] && data['network']['shareUrl']) {
								this.shareLink = data['network']['shareUrl'];
							}
							if (data.state === 0) {
								this.membershipId = data.uid;
								this.selectedNetworkId = data['network'].uid;
								this.getNetworkSubscriptionDetails(this.selectedNetworkId);

								if ((data.validationMethod === 1 || data.validationMethod === 2) && !data['network']['inviteOnly']) {
									this.view = 'emailValidation';

									if (data.network && data['network']['validationValue']) {
										const validationValue = data['network']['validationValue'];

										if (validationValue.indexOf('://') > -1) {
											const domain = validationValue.split('://')[1];
											this.defaultDomain = '@' + domain;
										} else if (validationValue.indexOf('www.') > -1) {
											const domain = validationValue.split('www.')[1];
											this.defaultDomain = '@' + domain;
										} else {
											this.defaultDomain = '@' + validationValue;
										}

										if (this.checkEmail(this.queryField['value'])) {
											this.email = this.queryField['value'];
										} else {
											this.email = this.defaultDomain;
										}
									} else {
										const index = this.queryField['value'].lastIndexOf('@');
										if (index > -1) {
											this.defaultDomain = this.queryField['value'].substring(index);
										}
									}
								}

								if (data['network'] && data['network']['inviteOnly']) {
									this.view = 'adminApproval';
								}

								if (data.uid) {
									this.memberId = data.uid;
								}
							} else {
								// 1.For external users,user gets redirected inside network directly on submitting role.
								// 2.For normal users,network success page is shown.
								this.handleMembershipCount();
								if (this.role['slug'] !== 'external') {
									// 1
									this.getNetworkSubscriptionDetails(data['network'].uid);
									this.view = 'success';
								} else {
									// 2
									this.naviagteToNetwork();
								}
							}
						},
						(err: any) => {
							this.settingRoles = 0;
							this.displayError = 'block';
							// eslint-disable-next-line spellcheck/spell-checker
							this.modelText = err?.errors?.error?.detail ? err?.errors?.error?.detail : 'Aww Snap! Something went wrong';
						}
					);
				} else {
					roleDetails['state'] = this.stateParam;
					this.updateRoleFieldsForNetworkCreate(roleDetails, this.createdNetworkMembershipId);
				}
			}
		} else {
			this.myFormGroup.markAllAsTouched();
			if (this.myFormGroup.valid) {
				this.displayInviteConfirmation = 'block';
			}
		}
	}

	public onSubmitInviteOnlyNetwork(): void {
		this.displayInviteConfirmation = 'none';
		this.myFormGroup.markAllAsTouched();
		if (this.myFormGroup.valid) {
			let roleDetails = {};
			roleDetails = this.myFormGroup.value;

			roleDetails['state'] = this.stateParam;

			if (this.role.slug) {
				roleDetails['role'] = this.role.slug;
				roleDetails['network'] = this.networkUid;
			}

			if (roleDetails['department']) {
				roleDetails['department'] = this.selectedDepartment.uid;
			}
			if (roleDetails['location']) {
				roleDetails['location'] = this.selectedLocation.uid;
			}

			if (roleDetails['manager']) {
				roleDetails['manager'] = this.selectedMember.uid;
			} else {
				delete roleDetails['manager'];
			}

			this.settingRoles = 1;
			this.networkService.postRoleDetails(roleDetails).subscribe(
				(data) => {
					this.network = data;
					this.newNetwork = data;
					this.settingRoles = 0;

					if (data.state === 0) {
						this.membershipId = data.uid;
						this.selectedNetworkId = data['network'].uid;
						if (data['network'] && data['network']['inviteOnly']) {
							this.view = 'adminApproval';
							this.getNetworkSubscriptionDetails(this.selectedNetworkId);
						}
						if (data.uid) {
							this.memberId = data.uid;
						}
					}

					if (data.state === 1) {
						this.view = 'success';
						this.handleMembershipCount();
						this.getNetworkSubscriptionDetails(data['network'].uid);
					}

					if (data['network'] && data['network']['shareUrl']) {
						this.shareLink = data['network']['shareUrl'];
					}
				},
				(err: any) => {
					this.settingRoles = 0;
					this.displayError = 'block';
					// eslint-disable-next-line spellcheck/spell-checker
					this.modelText = err?.errors?.error?.detail ? err?.errors?.error?.detail : 'Aww Snap! Something went wrong';
				}
			);
		}
	}

	private checkEmail(email): boolean {
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	}

	public validateEmail(): void {
		if (this.email) {
			this.loader = 1;
			// let param;
			const param = { email: this.email };
			this.networkService.postEmail(param, this.membershipId).subscribe(
				() => {
					this.loader = 0;
					this.showCancel = true;
					this.displayError = 'block';
					this.modelText = this.translate.instant('A validation email has been sent to you,please validate via the link in the email.');
				},
				() => {
					this.loader = 0;
					this.displayError = 'block';
					this.modelText = this.translate.instant('Its not a valid email address');
				}
			);
		}
	}

	/**
	 * Function to cancel pending membership of user
	 */
	public cancelPendingMembership(): void {
		this.loadingGif = 'block';
		const credential = { state: 'inactive' };
		this.isMembershipCancel = true;
		this.profileService.cancelPendingMembership(this.selectedNetworkId, this.membershipId, credential).subscribe(() => {
			this.loadingGif = 'none';
			this.displayPendingCancel = 'none';
			this.router.navigateByUrl('/');
		});
	}

	private checkForPersonalEmail(email): boolean {
		if (
			// eslint-disable-next-line spellcheck/spell-checker
			email.indexOf('@gmail.com') > -1 ||
			email.indexOf('@yahoo.com') > -1 ||
			// eslint-disable-next-line spellcheck/spell-checker
			email.indexOf('@hotmail.com') > -1 ||
			// eslint-disable-next-line spellcheck/spell-checker
			email.indexOf('@icloud.com') > -1 ||
			email.indexOf('@outlook.com') > -1 ||
			// eslint-disable-next-line spellcheck/spell-checker
			email.indexOf('@mailhippo.com') > -1
		) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Function to create a new network
	 */
	public onCreateNetwork(): void {
		this.createNetworkForm.markAllAsTouched();
		if (this.createNetworkForm.valid) {
			this.existingNetwork = '';
			this.domainName = '';
			let networkName = this.createNetworkForm['value']['name'];
			networkName = networkName.toLowerCase();
			if (networkName.indexOf('@') > -1 && networkName.indexOf('.com') > -1) {
				if (!this.checkForPersonalEmail(networkName)) {
					this.loader = 1;
					this.email = networkName;
					this.createNetworkForm.value.subType = this.createNetworkForm.value.subType['slug'];
					this.searchDomainNetworks(networkName);
				} else {
					this.modelText =
						'Looks like a personal email address.If you want to be connected with your team, please use your work email address or enter a work-space name';
					this.displayError = 'block';
				}
			} else {
				this.loader = 1;
				this.createNetworkForm.value.subType = this.createNetworkForm.value.subType['slug'];
				const params = this.createNetworkForm.value;
				params['state'] = 'invite_only';
				this.stateParam = 1;
				this.userService.createNewNetwork(params).subscribe(
					(data) => {
						if (data['name']) {
							this.name = data['name'];
						}
						this.loader = 0;
						this.view = 'roleFields';
						this.setRoleForNetworkAdmin(data);
					},
					(err) => {
						this.loader = 0;
						this.displayError = 'block';
						if (this.apiService.errorDetail) {
							this.existingNetwork = networkName;
							this.modelText = this.apiService.errorDetail;
						} else {
							// eslint-disable-next-line spellcheck/spell-checker
							this.modelText = err?.errors?.error?.detail ? err?.errors?.error?.detail : 'Aww Snap! Something went wrong';
						}
					}
				);
			}
		}
	}

	private searchDomainNetworks(domain): void {
		this.networkService.getNetworks(domain, 'enterprise').subscribe(
			(data) => {
				this.loader = 0;
				if (data['objects'] && data['objects'].length > 0) {
					this.existingNetwork = domain;
					this.modelText = 'Domain you entered already exists as a workspace';
					this.displayError = 'block';
				} else if (data['domainDetails']) {
					const domainDetails = data['domainDetails'];
					if (domainDetails['domainName'] && domainDetails['domainName'].length > 0) {
						const domainName = domainDetails['domainName'];
						if (Array.isArray(domainName)) {
							this.createNetworkForm.value['validationValue'] = domainName[0].toLowerCase();
							this.validationValue = this.createNetworkForm.value['validationValue'];
						} else {
							this.createNetworkForm.value['validationValue'] = domainName.toLowerCase();
							this.validationValue = this.createNetworkForm.value['validationValue'];
						}
						this.domainName = data['searchQuery'];
						this.createNetworkForm.value.name = this.domainName;
						this.onCreateDomainNetwork(this.createNetworkForm.value);
					} else {
						this.domainName = data['searchQuery'];
						this.modelText = 'Domain you entered is invalid. Would you like to continue creating a workspace?';
						this.displayError = 'block';
					}
				}
			},
			(err: any) => {
				this.loader = 0;
				this.domainName = '';
				// eslint-disable-next-line spellcheck/spell-checker
				this.modelText = err?.errors?.error?.detail ? err?.errors?.error?.detail : 'Aww Snap! Something went wrong';
				this.displayError = 'block';
			}
		);
	}

	public onCloseHandled(): void {
		if (this.domainName) {
			this.displayError = 'none';
			const network = this.createNetworkForm.value;
			network['name'] = this.domainName;
			this.onCreateDomainNetwork(network);
		} else {
			if (this.existingNetwork) {
				this.view = 'search';
				this.queryField.setValue(this.existingNetwork);
			}
			this.displayError = 'none';
		}
	}

	private onCreateDomainNetwork(network): void {
		this.loader = 1;
		this.stateParam = 0;
		this.userService.createNewNetwork(network).subscribe(
			(data) => {
				if (data['name']) {
					this.name = data['name'];
				}
				this.loader = 0;
				this.domainName = '';
				this.view = 'roleFields';
				this.showCancel = false;
				this.setRoleForNetworkAdmin(data);
			},
			(err: any) => {
				this.loader = 0;
				this.domainName = '';
				this.displayError = 'block';
				if (this.apiService.errorDetail) {
					this.modelText = this.apiService.errorDetail;
				} else {
					// eslint-disable-next-line spellcheck/spell-checker
					this.modelText = err?.errors?.error?.detail ? err?.errors?.error?.detail : 'Aww Snap! Something went wrong';
				}
			}
		);
	}

	/**
	 * Function to get role fields and handle role fields form on network creation
	 * @param network
	 */
	private setRoleForNetworkAdmin(network): void {
		if (network['name']) {
			this.name = network['name'];
		}
		this.resetRoleFields();
		this.createdNetworkMembershipId = network['membershipUid'];
		if (network.roles && network.roles.length > 0) {
			const index = network.roles.findIndex((role) => role.slug === 'external');
			if (index > -1) {
				network.roles.splice(index, 1);
			}
			if (network.roles.length === 1) {
				this.networkUid = network.uid;
				this.role = network.roles[0];
				this.getRoleFields(network.uid);
			} else {
				this.selectedNetwork = network;
				this.networkName = network['name'];
				this.roles = network['roles'];
				this.selectedRole = this.roles[0];
				this.view = 'roleSelection';
				this.loader = 0;
			}
		}
	}

	/**
	 * Function to call role update api and redirect inside network on network creation
	 * @param roleFields
	 * @param membershipUid
	 */
	private updateRoleFieldsForNetworkCreate(roleFields, membershipUid): void {
		this.settingRoles = 1;
		this.networkService.updateRoleFields(roleFields, membershipUid).subscribe(
			(data) => {
				this.newNetwork = data;
				this.settingRoles = 0;
				if (data['network'] && data['network']['shareUrl']) {
					this.shareLink = data['network']['shareUrl'];
				}
				if (data.state === 0) {
					this.membershipId = data.uid;
					this.selectedNetworkId = data['network'].uid;
					this.getNetworkSubscriptionDetails(this.selectedNetworkId);
					if ((data.validationMethod === 1 || data.validationMethod === 2) && !data['network']['inviteOnly']) {
						this.view = 'emailValidation';
						if (data.network && data['network']['validationValue']) {
							const validationValue = data['network']['validationValue'];
							if (validationValue.indexOf('://') > -1) {
								const domain = validationValue.split('://')[1];
								this.defaultDomain = '@' + domain;
							} else if (validationValue.indexOf('www.') > -1) {
								const domain = validationValue.split('www.')[1];
								this.defaultDomain = '@' + domain;
							} else {
								this.defaultDomain = '@' + validationValue;
							}
						} else {
							this.defaultDomain = '@' + this.validationValue;
						}

						if (this.queryField['value']) {
							if (this.checkEmail(this.queryField['value'])) {
								this.email = this.queryField['value'];
							} else {
								this.email = this.defaultDomain;
							}
						}
					}
					if (data['network'] && data['network']['inviteOnly']) {
						this.view = 'adminApproval';
					}
				} else {
					this.view = 'success';
					this.handleMembershipCount();
					this.getNetworkSubscriptionDetails(data['network'].uid);
				}
			},
			(err: any) => {
				this.settingRoles = 0;
				this.displayError = 'block';
				// eslint-disable-next-line spellcheck/spell-checker
				this.modelText = err?.errors?.error?.detail ? err?.errors?.error?.detail : 'Aww Snap! Something went wrong';
			}
		);
	}

	// TODO: apply real logic here
	public naviagteToNetwork(): void {
		if (this.newNetwork) {
			let uid;

			this.loadingGif = 'block';

			if (this.newNetwork.network && this.newNetwork.network.uid) {
				uid = this.newNetwork.network.uid;
			} else {
				uid = this.newNetwork.uid;
			}

			this.networkService.get(uid).subscribe((networkData) => {
				this.loadingGif = 'none';

				this.networkService.setNetwork(new Network(networkData));

				if (!this.branchContentType && !this.branchContentUid) {
					this.networkService.displayWelcomeDialog = true;
					this.mixPanelService.track('Signup Completed', {
						workspace: new Network(networkData)?.name || 'NA',
						appVersion: environment.version,
						language: this.translate.currentLang,
						username: this.currentUser?.username || 'NA',
						name: this.currentUser?.name || 'NA',
						email: this.currentUser?.email || 'NA',
						date: moment().format('YYYY-MM-DD'),
						step: 'Joining workspace after SignUp/Get Started',
						inviteLinkCopied: this.newNetwork?.shareUrl ? this.newNetwork?.network?.shareUrl : 'NA',
						sector: this.createNetworkForm?.value?.subType || 'NA',
					});

					this.segmentService.trackEvent('Signup Completed', {
						workspace: new Network(networkData)?.name || 'NA',
						appVersion: environment.version,
						language: this.translate.currentLang,
						username: this.currentUser?.username || 'NA',
						name: this.currentUser?.name || 'NA',
						email: this.currentUser?.email || 'NA',
						date: moment().format('YYYY-MM-DD'),
						step: 'Joining workspace after SignUp/Get Started',
						inviteLinkCopied: this.newNetwork?.shareUrl ? this.newNetwork?.network?.shareUrl : 'NA',
						sector: this.createNetworkForm?.value?.subType || 'NA',
					});

					this.router.navigateByUrl('/');

					// FIXME: reload page to fix issue with network not being set (possibly a state bug)
					timer(2000).subscribe(() => {
						window.location.reload();
					});
				} else {
					this.networkService.displayWelcomeDialog = true;
					this.mixPanelService.track('Invitation Accepted', {
						workspace: new Network(networkData)?.name || 'NA',
						appVersion: environment.version,
						language: this.translate.currentLang,
						username: this.currentUser?.username || 'NA',
						name: this.currentUser?.name || 'NA',
						email: this.currentUser?.email || 'NA',
						date: moment().format('YYYY-MM-DD'),
						step: 'Joining workspace after SignUp using Branch.IO',
						inviteLinkCopied: this.newNetwork?.shareUrl ? this.newNetwork?.network?.shareUrl : 'NA',
						sector: this.createNetworkForm?.value?.subType || 'NA',
						// title: this.newNetwork?.membership?.memberRole?.fields?.length > 2 ? this.newNetwork?.membership?.memberRole?.fields?.[2]?.value : null || 'NA',
					});

					this.segmentService.trackEvent('Invitation Accepted', {
						workspace: new Network(networkData)?.name || 'NA',
						appVersion: environment.version,
						language: this.translate.currentLang,
						username: this.currentUser?.username || 'NA',
						name: this.currentUser?.name || 'NA',
						email: this.currentUser?.email || 'NA',
						date: moment().format('YYYY-MM-DD'),
						step: 'Joining workspace after SignUp using Branch.IO',
						inviteLinkCopied: this.newNetwork?.shareUrl ? this.newNetwork?.network?.shareUrl : 'NA',
						sector: this.createNetworkForm?.value?.subType || 'NA',
						// title: this.newNetwork?.membership?.memberRole?.fields?.length > 2 ? this.newNetwork?.membership?.memberRole?.fields?.[2]?.value : null || 'NA',
					});
					this.manageBranchRedirections();
				}
			});
		}
	}

	private manageBranchRedirections(): void {
		if (this.branchContentType) {
			if (this.branchContentType === 'ToDoList') {
				this.router.navigateByUrl(`/project/team/${this.branchContentUid}`);
			} else if (this.branchContentType === 'ToDo') {
				this.router.navigateByUrl('/project/task-detail/' + this.branchContentUid);
			} else if (this.branchContentType === 'Customer') {
				this.router.navigateByUrl('/network/customer/' + this.branchContentUid);
			} else if (this.branchContentType === 'Channels') {
				this.router.navigateByUrl('/network/view-channel?id=' + this.branchContentUid);
			} else if (this.branchContentType === 'Collections') {
				this.router.navigateByUrl('/network/collection?id=' + this.branchContentUid);
			} else if (this.branchContentType === 'Issue') {
				this.router.navigateByUrl('/network/issue-detail?id=' + this.branchContentUid);
			} else if (this.branchContentType === 'Post') {
				this.router.navigateByUrl('/posts/' + this.branchContentUid);
			} else if (this.branchContentType === 'External-Channels') {
				this.router.navigateByUrl('/network/view-channel?id=' + this.branchContentUid + '&external=true');
			}
		}
	}

	/**
	 * Function which set default role
	 * 1. Check if selected role exists in roles array
	 * 2. If exists, set that role object as selected role
	 */
	public onRoleChange(): void {
		const index = this.roles.findIndex((role) => role.slug === this.selectedRole); // 1
		if (index > 0) {
			this.selectedRole = this.roles[index]; // 2
		}
	}

	/**
	 * Function which set roles on clicking next in role update form
	 * 1. Set role
	 */
	public setRole(): void {
		if (this.selectedRole) {
			this.role = this.selectedRole;
			this.view = 'roleFields';
			this.getRoleFields(this.selectedNetwork.uid);
		}
	}

	private getRoleFields(uid): void {
		this.view = 'roleFields';
		this.loader = 1;
		this.networkUid = uid;
		this.selectedNetworkUID = uid;
		this.networkService.getRoleFieldsUpdate(this.role.slug, uid).subscribe((data) => {
			if (data.info && data.info.fields.length > 0) {
				this.roleFields = data.info.fields;
				const result = this.roleFields.filter((element) => element.key !== 'email');
				this.roleFields = result;
				const group = {};
				this.roleFields.forEach((input_template) => {
					if (input_template.mandatory) {
						group[input_template.key] = new FormControl('', [Validators.required]);
					} else {
						group[input_template.key] = new FormControl();
					}

					if (input_template.key === 'start') {
						group[input_template.key] = new FormControl(this.currentYear, [Validators.required]);
					}
				});
				this.loader = 0;
				this.myFormGroup = new FormGroup(group);
			}

			// As informed by the backend, when validationMethod is 0, we have to set thee state param as 1
			if (data?.validationMethod === 0) {
				this.stateParam = 1;
			}
		});
	}

	/**
	 *
	 * Function to reset all role fields
	 */
	private resetRoleFields(): void {
		this.roleFields = [];
		this.departments = [];
		this.locations = [];
		this.members = [];
		this.department.uid = '';
		this.location.uid = '';
		this.member.id = '';
	}

	private getBranchNetworkDetails(uid): void {
		this.loadingGif = 'block';
		this.networkService.get(uid).subscribe((networkData) => {
			this.loadingGif = 'none';
			this.selectNetwork(networkData);
		});
	}

	/**
	 * Get active subscription plan details of network
	 * 1.Check if trialEnd is 0
	 * 2.Check if renewable date is expired
	 * 3.Check if numberOfSeats in network is equal or greater than active members in network
	 * 4. Display seats exceeded popup
	 * @param networkUid
	 */
	private getNetworkSubscriptionDetails(networkUid): void {
		this.networkService.getNetworkActiveSubscription(networkUid).subscribe(
			(data) => {
				if (!data.trialEnd) {
					// 1
					if (data.numberOfSeats && data['network'] && data['network']['activeMembersCount'] >= 0) {
						const numberOfSeats = data.numberOfSeats;
						const activeMembers = data['network']['activeMembersCount'];
						if (data['status']) {
							if (data['status'] !== 'active' && data['status'] !== 'trialing') {
								this.seatsExceeded = true;
								this.displayError = 'block';
								this.modelText = this.translate.instant(
									'We could not log you in because there is a problem with your MyWork account. Your system admin will need to upgrade the account within 90 days.'
								);
							}
						} else if (activeMembers >= numberOfSeats) {
							this.seatsExceeded = true;
							this.displayError = 'block';
							this.modelText = this.translate.instant(
								// eslint-disable-next-line spellcheck/spell-checker
								'Number of paid seats exceeded. Your network admin is informed. Your co-workers look forward to seeing you at PeerBie.', { appName: this.appName }
							);
						} else {
							this.seatsExceeded = false;
							this.displayError = 'none';
							this.modelText = '';
						}
					}
				} else {
				}
			},
			() => { }
		);
	}

	/**
	 * Manage role fields api call according to selected field type
	 * @param field
	 */
	public onRoleFieldSelection(field): void {
		const selectedField = field.key;
		switch (selectedField) {
			case 'department':
				this.getDepartments();
				this.newDepartmentAddView = false;
				break;
			case 'location':
				this.getLocations();
				break;
			case 'manager':
				this.getMembers();
				break;
			default:
				break;
		}
	}

	/**
	 * Function to get all departments and display it
	 * 1. Display departments modal
	 * 2. Show spinner
	 * 3. Call api for getting departments
	 * 4. Hide spinner
	 * 5. Set departments array
	 */
	public getDepartments(): void {
		this.scrollStatus = false;
		this.displayDepartmentsModal = 'block'; // 1
		this.noDepartments = true; // 2
		this.departments = [];
		this.networkService.getDepartments(this.networkUid).subscribe((data) => {
			// 3
			this.noDepartments = false; // 4
			if (data.objects && data.objects.length > 0) {
				this.departments = data.objects; // 5
			}
			if (data.next) {
				this.nextUrl = data.next.split('alpha')[1];
				this.paginateDepartments();
			} else {
				this.nextUrl = '';
			}
		});
	}

	private paginateDepartments(): void {
		if (this.scrollStatus) {
			return;
		}
		this.scrollStatus = true;
		if (this.nextUrl !== '') {
			this.networkService.paginateTasks(this.nextUrl).subscribe((data) => {
				if (data.next) {
					this.nextUrl = data.next.split('alpha')[1];
				} else {
					this.nextUrl = '';
				}
				this.scrollStatus = false;

				data.objects.forEach((department) => {
					const index = this.departments.findIndex((i) => i?.uid === department?.uid);
					if (index === -1) {
						this.departments.push(department);
					}
				});
				// this.departments = this.departments.concat(data.objects);
			});
		}
	}

	/**
	 * Function to get all locations and display it
	 * 1. Display location modal
	 * 2. Set latitude and longitude of current location
	 * 3. Call api for getting locations
	 * 4. Set location data on locations array
	 */
	public getLocations(): void {
		this.displayLocationsModal = 'block'; // 1
		this.noLocations = true;
		this.locations = [];
		this.searchQuery = '';
		const nearMeLocation = this.currentLocation.coords.latitude + ',' + this.currentLocation.coords.longitude; // 2
		this.networkService.getCitiesForRoleUpdate(nearMeLocation).subscribe((data) => {
			// 3
			this.noLocations = false;
			if (data.results && data.results.length > 0) {
				this.locations = data.results; // 4
			}
		});
	}

	/**
	 * Function to get all members and display it
	 * 1. Display members modal
	 * 2. Show spinner
	 * 3. Call api for getting members
	 * 4. Check if next url exists,call pagination function
	 * 5. Hide spinner
	 * 6. Set members array
	 */
	public getMembers(): void {
		this.displayMembersModal = 'block'; // 1
		this.scrollStatus = false;
		this.noMembers = true; // 2
		this.members = [];
		this.memberService.getMembers(this.networkUid).subscribe((data) => {
			// 3
			this.noMembers = false; // 4
			if (data.next) {
				this.nextUrl = data.next.split('alpha')[1]; // 5
				this.paginateMembers();
			} else {
				this.nextUrl = '';
			}
			this.members = data.objects; // 6
		});
	}

	/**
	 * Function to get remaining members \
	 * 1. Check if next url exists
	 * 2. Call pagination api
	 * 3. Set next url if exists
	 * 4. Append new members objects to members array
	 */
	public paginateMembers(): void {
		if (this.scrollStatus) {
			return;
		}
		this.scrollStatus = true;
		if (this.nextUrl !== '') {
			// 1
			this.networkService.paginateTasks(this.nextUrl).subscribe((data) => {
				// 2
				if (data.next) {
					// 3
					this.nextUrl = data.next.split('alpha')[1];
				} else {
					this.nextUrl = '';
				}
				this.scrollStatus = false;
				this.members = this.members.concat(data.objects); // 4
			});
		}
	}

	/**
	 * Function on selecting department
	 * 1.Set selected department object
	 * 2.Set its uid for radio btn on
	 */
	public onSelectDepartment(department): void {
		this.selectedDepartment = department; // 1
		this.department.uid = department.uid; // 2
	}

	/**
	 * Function to set department selected
	 * 1. Close departments modal
	 * 2. Set selected department name on role object
	 */
	public onSetDepartment(): void {
		this.displayDepartmentsModal = 'none'; // 1
		if (this.selectedDepartment) {
			this.myFormGroup.controls.department.setValue(this.selectedDepartment.name); // 2
		}
	}

	/**
	 *  Function on selecting location
	 * 1.Set selected location object
	 * 2.Set its uid for radio btn on
	 */
	public onSelectLocation(loc): void {
		this.selectedLocation = loc; // 1
		this.location.uid = loc.uid; // 2
	}

	/**
	 * Function to set location selected
	 * 1. Close locations modal
	 * 2. Set selected location name on role object
	 */
	public onSetLocation(): void {
		this.displayLocationsModal = 'none'; // 1
		if (this.selectedLocation) {
			this.myFormGroup.controls.location.setValue(this.selectedLocation.name); // 2
		}
	}

	/**
	 * Function on selecting manager
	 * 1. Set selected manger object
	 * 2. Set its uid for radio btn on
	 */
	public onSelectManager(member): void {
		this.selectedMember = member; // 1
		this.member.id = member.uid; // 2
	}

	/**
	 * Function to set manager selected
	 * 1. Close managers modal
	 * 2. Set selected manager name on role object
	 */
	public onSetManager(): void {
		this.displayMembersModal = 'none'; // 1
		if (this.selectedMember) {
			this.myFormGroup.controls.manager.setValue(this.selectedMember['user'].name); // 2
		}
	}

	public onSearchClick(): void {
		this.noLocations = true;
		this.locations = [];
		const nearMeLocation = this.currentLocation.coords.latitude + ',' + this.currentLocation.coords.longitude;
		this.networkService.searchCitiesForRoleUpdate(nearMeLocation, this.searchQuery).subscribe((data) => {
			this.noLocations = false;
			if (data.results && data.results.length > 0) {
				this.locations = data.results;
			}
		});
	}

	public onSearchEnter(event: any): void {
		if (event.keyCode === 13) {
			this.onSearchClick();
		}
	}

	public backToInitial(): void {
		this.view = 'initial';
		this.loader = 0;
		this.queryField.reset();
		this.createNetworkForm.controls.name.reset();
		this.createNetworkForm.controls.subType.setValue(this.sectors[0]);
		this.domainName = '';
		this.networks = [];
		this.clearSearch = false;
		this.isSearching = false;
		this.existingNetwork = '';
		this.solider = '';
	}

	public backToPrevious(): void {
		if (!this.paramsExists) {
			if (this.createdNetworkMembershipId) {
				this.view = 'create';
			} else if (this.roles.length > 0 && this.view === 'roleFields') {
				this.view = 'roleSelection';
			} else {
				this.view = 'search';
			}
		} else if (this.previousURL === '/network') {
			this.router.navigate(['/']); // ? So as to prevent navigation back to /network immediately after join-workspace ? //
		} else {
			this._location.back();
		}
	}

	public handleBack(): void {
		this._location.back();
	}

	public onSelectNetwork(network): void {
		this.networks.forEach((element) => {
			if (network.uid !== element.uid) {
				element['selected'] = false;
			} else {
				network['selected'] = true;
				this.solider = network;
			}
		});
	}

	public displayRoleFields(): void {
		if (this.solider) {
			this.selectNetwork(this.solider);
		}
	}

	public searchEvent(event): void {
		if (event.target.value) {
			this.clearSearch = true;
		} else {
			this.clearSearch = false;
		}
	}

	public clearSearchLists(): void {
		this.clearSearch = false;
		this.isSearching = false;
		this.networks = [];
		this.queryField.reset();
	}

	public closeModal(): void {
		const name = this.queryField['value'];
		this.createNetworkForm.controls.name.setValue(name);
	}

	public domain(event): void {
		if (this.defaultDomain) {
			const val = event.target.value;
			if (val.indexOf(this.defaultDomain) === -1) {
				this.email = this.defaultDomain;
			}
			const i = val.lastIndexOf('m');
			const x = val.substring(i + 1);
			if (x) {
				this.email = this.defaultDomain;
			}
		}
	}

	/**
	 * 1. Close dialog popup.
	 * 2. Check if membership count of user is greater than one.
	 * 3. If true,naviagte to networks list
	 * 4. Else navigate to join flow page
	 */
	public handleMemberDelete(): void {
		this.displayDeleteDialog = 'none'; // 1
		this.router.navigateByUrl('/');
	}

	private handleMembershipCount(): void {
		if (localStorage.getItem('membershipCount')) {
			// let count: any;
			const count = localStorage.getItem('membershipCount');
			let n = Number(count);
			n = n + 1;
			localStorage.setItem('membershipCount', JSON.stringify(n));
			this.isCountUpdated = true;
		}
	}

	public copyInviteLink(): void {
		let name;
		let url;

		if (this.newNetwork) {
			if (this.newNetwork['name'] && this.newNetwork['shareUrl']) {
				name = this.newNetwork['name'];
				url = this.newNetwork?.shareUrl ? `${environment.appURL}?share_url=${this.newNetwork?.shareUrl}&nid=${this.newNetwork?.uid}` : '';
			}
			if (this.newNetwork.network) {
				if (this.newNetwork.network['name'] && this.newNetwork.network['shareUrl']) {
					name = this.newNetwork.network['name'];
					url = this.newNetwork?.shareUrl ? `${environment.appURL}?share_url=${this.newNetwork?.shareUrl}&nid=${this.newNetwork?.uid}` : '';
				}
			}
		}

		const text = this.translate.instant('networkInvite', {
			username: this.currentUser['name'],
			networkName: name,
			inviteLink: url,
		});

		this.clipboardService.copy(text);
		this.toastService.success(this.translate.instant('Copied to clipboard!'));
	}

	private clearBranch(): void {
		localStorage.removeItem('branchData');
		localStorage.removeItem('branch_session_first');
	}

	private getMemberShipDetails(network_id: string = ''): void {
		this.loadingGif = 'block';
		this.networkService.getMembershipDetails(this.membershipId, network_id ? network_id : null).subscribe((data) => {
			this.loadingGif = 'none';
			if (data.uid) {
				this.memberId = data.uid;
			}
			if (data['network'] && data['network']) {
				this.name = data['network']['name'];
			}
		});
	}

	/**
	 * Switch between department creation button view and input view
	 */
	public switchBetweenDepartmentCreationModes(): void {
		this.newDepartmentAddView = !this.newDepartmentAddView;
		this.departmentForm?.reset();
	}

	/**
	 * Handle add button click to add new department to department list
	 */
	public addNewDepartment(): void {
		if (this.departmentForm.valid && this.departmentForm?.value?.name.trim()) {
			this.pageLoadingUiService.setPageLoading(true);
			this.networkService.createDepartmentFromJoinWork(this.departmentForm.value, this.selectedNetworkUID).subscribe(
				(data) => {
					const index = this.departments.findIndex((i) => i?.uid === data?.uid);
					if (index === -1) {
						this.departments.unshift(data);
					} else {
						this.getDepartments();
					}
					this.onSelectDepartment(data);
					this.pageLoadingUiService.setPageLoading(false);
				},
				() => {
					this.pageLoadingUiService.setPageLoading(false);
				}
			);
		}
		this.departmentForm.reset();
		this.newDepartmentAddView = !this.newDepartmentAddView;
	}
}
