import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from '../../../environments/environment';
import { NetworkService } from '../../shared';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';

@Component({
	selector: 'app-add-members',
	templateUrl: './add-members.component.html',
	styleUrls: ['./add-members.component.css'],
})
export class AddMembersComponent implements OnInit {
	public loadingGif = 'none';
	public activeTab = 'connect';
	public provider_data;
	public selected_ip;
	public invite = { options: '0' };
	private tabs = {
		0: 'connect',
		1: 'profile',
		2: 'invite',
		3: 'create',
	};
	public connected: Boolean = false;
	public last_step = 0;
	public ip = {};
	public token;
	public edit = false;
	public scim_invite = true;
	private appName = environment.appName;

	public default_ip = {
		azure: {
			title: 'Configure azure Active directory as your Identity Provider',
			img: '../../../assets/img/logo-windows.png',
			connect: {
				title: 'How does it work?',
				description: `<ul>
							<li> Connecting to azure Active directory is a simple way of importing users from our organization to ${this.appName} Workplace </li>
							<li> When you add or remove users from the active directory, We'll automatically update it the ${this.appName} Workplace </li>
							<li> <b> You need to be Azure Active directory admin to connect Active directory with your account </b> </li>
						</ul>`,
				disabled: false,
			},
			profile: {
				title: 'Synchronize profile info',
				disabled: true,
			},
			invite: {
				title: 'Ready to send invitations',
				description: `Once accounts are created, you will need to send invitations so to users can access ${this.appName} Workplace.`,
				disabled: true,
			},
			create: {
				title: `Creating your ${this.appName} Workplace Accounts`,
				description: 'This may take a while, You can leave this page and continue with other tasks',
				disabled: true,
			},
		},
		google: {
			title: this.translate.instant('Configure Google Workplace as your Identity Provider'),
			img: '../../../assets/img/google-logo.png',
			connect: {
				title: this.translate.instant('How does it work?'),
				description: `<ul>
							<li> ${this.translate.instant(`Connecting to Google Workplace is a simple way of importing users from our organization to ${this.appName} Workplace`)} </li>
							<li> ${this.translate.instant(`When you add or remove users from the Google Workplace, We'll automatically update it the ${this.appName} Workplace`)} </li>
							<li> <b>${this.translate.instant(`You need to be Google Workplace admin to connect Google Workplace with your account`)} </b> </li>
						</ul>`,
				disabled: false,
			},
			profile: {
				title: this.translate.instant('Synchronize profile info'),
				description: this.translate.instant('Fields imported : primaryEmail and displayName'),
				disabled: true,
			},
			invite: {
				title: this.translate.instant('Ready to send invitations'),
				description: this.translate.instant(`Once accounts are created, you will need to send invitations so to users can access ${this.appName} Workplace.`),
				disabled: true,
			},
			create: {
				title: this.translate.instant(`Creating your ${this.appName} Workplace Accounts`),
				description: this.translate.instant('This may take a while, You can leave this page and continue with other tasks'),
				disabled: true,
			},
		},
	};

	constructor(private location: Location, private networkService: NetworkService, private translate: TranslateService, private pageLoadingUiService: PageLoadingUiService) {}

	ngOnInit(): void {
		this.ip = _.cloneDeep(this.default_ip);
		this.manageTabs();
	}

	private manageTabs(): void {
		this.networkService.getIdentityProvider().subscribe((data) => {
			this.pageLoadingUiService.setPageLoading(false);
			if (data.results.length) {
				this.selected_ip = data.results[0].provider;
				this.provider_data = data.results[0];
				this.last_step = 0;
				if (this.provider_data.config) {
					if ('invite' in this.provider_data['config']) {
						this.invite.options = this.provider_data['config']['invite'] ? '1' : '0';
					}

					for (const key in this.provider_data.config) {
						if (Object.prototype.hasOwnProperty.call(this.provider_data.config, key)) {
							this.ip[this.selected_ip][this.tabs[this.last_step]]['disabled'] = false;
							this.last_step += 1;
						}
					}
					if (this.last_step <= 3) {
						this.activeTab = this.tabs[this.last_step];
						this.ip[this.selected_ip][this.tabs[this.last_step]]['disabled'] = false;
					} else {
						this.connected = true;
					}
				}
			} else {
				this.ip = _.cloneDeep(this.default_ip);
				this.provider_data = null;
				this.selected_ip = null;
				this.activeTab = 'connect';
				this.connected = false;
			}
		});
	}

	public activateTab(step): void {
		this.last_step = step;
		this.activeTab = this.tabs[step];
	}

	public importProfile(): void {
		this.provider_data['config']['profile'] = ['Display Name', 'Primary Email'];
		const data = { config: JSON.stringify(this.provider_data['config']) };

		this.pageLoadingUiService.setPageLoading(true);

		this.networkService.updateIPConfig(this.provider_data['id'], data).subscribe(() => {
			this.manageTabs();
		});
	}

	public updateInvite(): void {
		const data = { invite: this.scim_invite ? 1 : 0 };
		this.networkService.updateInvite(data).subscribe(() => {});

		this.provider_data['config']['invite'] = this.invite.options === '1';
		const _data = { config: JSON.stringify(this.provider_data['config']) };

		this.pageLoadingUiService.setPageLoading(true);

		this.networkService.updateIPConfig(this.provider_data['id'], _data).subscribe(() => {
			this.manageTabs();
		});
	}

	public create(): void {
		this.provider_data['config']['create'] = true;
		const data = { config: JSON.stringify(this.provider_data['config']) };
		this.pageLoadingUiService.setPageLoading(true);

		this.networkService.updateIPConfig(this.provider_data['id'], data).subscribe(() => {
			this.manageTabs();
		});
	}

	public cancel(): void {
		if (this.edit) {
			this.connected = true;
		}
	}

	public editIPConfig(): void {
		this.connected = false;
		this.edit = true;
	}

	// public sanitizeData(data): SafeHtml {
	// 	return this.sanitizer.bypassSecurityTrustHtml(data);
	// }

	public selectProvider(ip): void {
		this.selected_ip = ip;
		localStorage.setItem('provider', ip);
		if (ip === 'azure') {
			this.getToken();
		}
	}

	public authenticate(): void {
		let url;

		if (this.selected_ip === 'azure') {
			url = environment.azure_auth_url;
		} else {
			url = environment.googleAuthUrl;
		}

		// eslint-disable-next-line spellcheck/spell-checker
		const win = window.open(url, 'windowname1', 'width=900, height=700');
		const self = this;
		const pollTimer = window.setInterval(function () {
			try {
				if (win.document.URL.indexOf(`${window.location.origin}/network/admin/members/add`) !== -1) {
					window.clearInterval(pollTimer);
					win.close();
					self.manageTabs();
				}
			} catch (e) {
				console.error(e);
			}
		}, 100);
	}

	public syncNowClick(): void {
		this.pageLoadingUiService.setPageLoading(true);

		this.networkService.importUsers().subscribe(() => {
			this.manageTabs();
		});
	}

	public deleteIPConfig(): void {
		this.pageLoadingUiService.setPageLoading(true);

		this.networkService.deleteIPConfig(this.provider_data['id']).subscribe(() => {
			this.manageTabs();
		});
	}

	public tabBack(): void {
		this.activeTab = this.tabs[this.last_step - 1];
		this.last_step = this.last_step - 1;
	}

	/* To copy Text from Textbox */
	public copyInputMessage(inputElement): void {
		inputElement.select();
		document.execCommand('copy');
		inputElement.setSelectionRange(0, 0);
	}

	private getToken(): void {
		this.networkService.getToken().subscribe((res) => {
			if (res && Object.keys(res).length > 0) {
				this.token = res;
				this.scim_invite = this.token.invite;
			}
		});
	}

	public generateToken(): void {
		this.pageLoadingUiService.setPageLoading(true);

		this.networkService.generateToken().subscribe((res) => {
			this.token = res;
			this.scim_invite = this.token.invite;

			this.pageLoadingUiService.setPageLoading(false);
		});
	}

	public refreshToken(): void {
		this.pageLoadingUiService.setPageLoading(true);

		this.networkService.refreshToken().subscribe((res) => {
			this.token = res;

			this.pageLoadingUiService.setPageLoading(false);
		});
	}

	public onClickBack(): void {
		if (this.selected_ip) {
			this.selected_ip = null;
		} else {
			this.location.back();
		}
	}
}
