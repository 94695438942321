import { Injectable } from '@angular/core';
import LocalStorage from '@core/utils/localstorage';

@Injectable({
	providedIn: 'root',
})
export class TokenService {
	constructor() {}

	public getToken(): string {
		return LocalStorage.get('token');
	}

	public saveToken(token: string) {
		return LocalStorage.set('token', token);
	}

	public getUid(): string {
		return LocalStorage.get('uid');
	}

	public saveUid(uid: string): void {
		return LocalStorage.set('uid', uid);
	}

	public saveDeviceUid(udid: string): void {
		return LocalStorage.set('udid', udid);
	}

	public getUdid(): string {
		return LocalStorage.get('udid');
	}

	public getDeviceToken(): string {
		return LocalStorage.get('device-token');
	}

	public saveDeviceToken(deviceToken: string): void {
		return LocalStorage.set('device-token', deviceToken);
	}

	public getFcmToken(): string {
		return LocalStorage.get('fcm_token');
	}

	public saveFcmToken(deviceToken: string): void {
		return LocalStorage.set('fcm_token', deviceToken);
	}

	public destroyToken(): void {
		LocalStorage.remove('token');
		LocalStorage.remove('uid');
		LocalStorage.remove('udid');
	}
}
