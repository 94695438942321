import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LocationComponent } from './location.component';
import { SharedModule } from '../shared';
import { LocationListModule } from '../location-list/location-list.module';
import { AgmCoreModule } from '@agm/core';

@NgModule({
	imports: [BrowserModule, AgmCoreModule, SharedModule, LocationListModule],
	declarations: [LocationComponent],
	bootstrap: [LocationComponent],
	providers: [],
})
export class LocationModule {}
