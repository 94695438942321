import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import {
	Component,
	OnInit,
	ViewChildren,
	QueryList,
	ElementRef,
	Input,
	Output,
	EventEmitter,
	ViewChild,
	ChangeDetectorRef,
	OnDestroy,
	OnChanges,
	SimpleChanges,
	AfterViewInit,
} from '@angular/core';
import {
	UserService,
	ProfilesService,
	NetworkService,
	PostService,
	CommentsService,
	SocketIoService,
	CollectionsService,
	GeoLocationService,
	LocationService,
	LayoutService,
} from '../services';
import { LocationObj } from '../../shared/models/locationobj.model';
import { User, Comment, Post, Reminder } from '../models';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchService } from '../services/search.service';
import { PostEditComponent } from './post-edit/post-edit.component';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../src/environments/environment';
import { AttendingUsersModalComponent } from '../components/event/attending-users-modal/attending-users-modal.component';
import { ToastService } from '../../shared/services/common/toast.service';
import { Location } from '@angular/common';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';
import { Subscription, timer } from 'rxjs';
import { ContextMenuComponent, ContextMenuService } from '@perfectmemory/ngx-contextmenu';
import { LightboxService } from 'projects/peer-core/src/lib/services/ui/lightbox.service';
import { IImageAttachment, ImageAttachment } from 'projects/bee-attachment/src/lib/shared/models/image-attachment';
import { IBaseAttachment } from 'projects/bee-attachment/src/lib/shared/models/base-attachment';
import { AttachmentService } from 'projects/bee-attachment/src/lib/shared/services/attachment.service';
import { ScrollEvent, ScrollService } from 'projects/peer-core/src/lib/services/ui/scroll.service';
import { NprogressService } from 'projects/bee-progress/src/lib/modules/nprogress/nprogress.service';
import { ClipboardService } from 'projects/peer-core/src/lib/services/ui/clipboard.service';
import { ChatSocketService } from '@modules/chat/services/chat-socket.service';
import CacheUtils from '@shared/utils/cache-utils';
import * as moment from 'moment';
import { MixPanelService } from '@core/services/third-party/mix-panel.service';
import { SegmentAnalyticsService } from '@core/services/third-party/segment-analytics.service';

// tui implementation
import Editor from '@toast-ui/editor';
import Viewer from '@toast-ui/editor/dist/toastui-editor-viewer';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight-all.js';
import Prism from 'prismjs';
import 'prismjs/components/prism-clojure.js';

@Component({
	selector: 'app-post-list',
	templateUrl: './post-list.component.html',
	styleUrls: ['./post-list.component.scss'],
})
export class PostListComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
	public appName = environment.appName;
	@ViewChildren('textArea') public textArea: QueryList<ElementRef>;
	@ViewChild('postEdit', { static: true }) public postEdit: PostEditComponent;
	// eslint-disable-next-line spellcheck/spell-checker
	@ViewChild('TogglerFilterList', { static: true }) public TogglerFilterList: ElementRef;
	@ViewChild('attendingUsersModal', { static: true }) public attendingUsersModal: AttendingUsersModalComponent;

	@Input() public postUrl;
	@Input() public paramType = '';
	@Input() public date = '';
	@Input() public timeNow = '';
	@Input() public currentPage = 'postList';
	@Input() public issueCollection: any;
	@Input() public network;
	@Input() public locations: any[] = [];
	@Output() public showBackdrop: EventEmitter<any> = new EventEmitter();
	@Input() public collection: any;
	@Input() public isArchived = false;
	@Output() public modalTypeEmitter: EventEmitter<string> = new EventEmitter();
	@Input() public isDayStarting = true;
	@Output() public noEvents: EventEmitter<boolean> = new EventEmitter();

	public confirmModal?: NzModalRef;
	public baseAttachment: IBaseAttachment = null;
	public currentUser: User;
	public externalUserChannel: any;
	public isExternalUser = false;
	public posts: any[] = [];
	private currentDepartmentSubscriber;
	private departmentsReady;
	public selectedPost: Post;
	public nextUrl;
	public filters;
	public showPostMute = true;
	public filter;
	public postMuteUid;
	public showBg = false;
	public postCategories;
	public dialog_display = 'none';
	public displayError = 'none';
	public scrollStatus = false;
	public post_type = {};
	public selected_post: Post;
	public isCollectionsFetched = 0;
	public errors;
	public networkLoaded;
	public saved_post;
	public saved_post_url;
	public showSpin;
	public showZoomLinkModal = 'none';
	public displayCollectionError = 'none';
	public searchSubscription: any;
	public myTeamUid: any;
	public showMyTeam = false;
	public issueHashTagPosts = false;
	public hashName;
	public item = {
		category: '',
		commentable: true,
		shareable: true,
		state: '',
		type: 'offer',
	};
	// Remainders----
	public reminder_action = 'none';
	public eventReminderOptions: any[] = [];

	public reminder = { value: '' };
	public eventReminder_option = 'none';
	public toast_message = 'none';
	public reminderMessage;
	public editRemainder = false;
	public editEventRemainder = false;
	public selectedRemainderOption;
	public timeForReminder: number;
	public reminderUid;
	public showSetReminderOption = false;

	public showCommentsContainer = false;
	public incomingPost;
	public currentPostIndex: any;

	public zoom_oauth_url = environment.zoom_oauth_url;

	public members;
	public eventModal = 'none';
	public currentEventAttend: any;
	public voteSelectedPost: any;
	public selectedChoice: any;
	private socket_post;
	public readMorePost: any = 0;
	public addToCollectionSelectedPost: any;
	public collections: any;
	public showCollectionModal = false;
	public showPostFilter = false;
	public deleteSelectedPost;
	public personalFilterValues = [];
	public value;
	public showUserSuggestion = false;
	public firstTimeNextUrl;
	public location_display = 'none';
	public singleRoleNetwork;
	public hideSingleRole = false;
	public status;
	public renewalFrequency: any[];
	public selectByLevelValues: any[] = [];
	public currentLocation;
	public errorMessage: string;
	// For issues---
	public estimatedTimeError: string;
	public versionNumberError: string;
	public estimatedTime;
	public versionNumber;
	public collectionName;
	public selectedPostForInProgress;
	public selectedPostForReleased;
	public selectedValueForInProgress;

	public selectedValueForReleased;
	public selectedIndexForInProgress;
	public selectedIndexForReleased;
	public displayToastForCollection = 'none';
	public displayToastCollection = false;
	public showEstimatedTimeModal = 'none';
	public showVersionNumberModal = 'none';
	public showIssueDeleteConfirmation = 'none';
	public deleteSelectedIssue;
	public showMoreOrHideForIssues = false;
	// -
	public showCommentDeleteConfirmation = 'none';
	public deleteSelectedComment;
	public deleteCommentSelectedPost;
	public hashTagList: any[] = [];
	public newCollection = { name: '', uid: '' };
	public channels: any[] = [];
	public fields;
	public isFirstSearch = true;
	public membership = JSON.parse(localStorage.getItem('membership'));
	public postFetched = false;
	public editSelectedPost: any;
	public editSelectedPostIndex: any;
	public isViewInitialized = false;
	public memberType;
	private memberSubscriber;
	public hash: any;
	public collectionRemoveSelectedPost: any;
	public showCollectionRemove = 'none';
	public showCollectionRemoveError = false;
	public searchString = '';
	public collectionSearchString = '';
	public departments = [];
	public socketIOSubscription: any;

	public reportSelectModalDisplay = 'none';
	public reportCommentSelectedPost = null;
	public reportSelectedComment = null;
	public selectedCommentReportOption = null;
	public reason = null;
	public showReportError = false;
	public reportOptionMissingError = false;
	public paginating = false;

	public isHashTags = false;
	public hashTag;
	public currentDate = new Date();
	public displayWelcomeTips = false;
	public isWelcomeTip = false;
	public isTaskTip = false;
	public isIssueTip = false;
	public isCustomerTip = false;
	public serviceIndex: any;
	public errorText = this.translate.instant('Something went wrong');
	public errorObj = {
		detail: this.translate.instant('Something went wrong'),
		status: '',
	};

	// Reports
	public reportReasons: any[] = [];
	public reportModal = 'none';
	public flag = { value: '', text: '' };
	public reportPost;
	public reportText;
	public isReported = false;

	public addCustomer = true;

	// Reminders
	public reminderOptions: any[] = [];
	public displayReminderModal = 'none';
	public reminderTime;
	public timeFor;
	public remindersSelected = [];
	public reminderModel = new Reminder();
	public reminderToast = false;
	public postForReminder;
	public selectedPostForReminder;

	public onlineStack = [];
	public viewDialog = 'none';
	public postViews: any = [];
	public viewNextUrl;
	public isFetching = 0;

	public postType: 'add-issue' | 'add-task' | '' = '';

	@ViewChild('reportInput', { static: true }) public reportInput: any;

	private model = new Post(
		{
			title: null,
			body: null,
			state: '',
			type: null,
			status: null,
			shareable: true,
			commentable: true,
			category: null,
		},
		true
	);
	private newComment = new Comment({ text: '', isLink: false }, true);
	private reminderObject = {
		username: '',
		contentUid: '',
		contentType: '',
		reminderOption: '',
		repeat: false,
	};

	private showPostCreateForm = false;

	private windowScrollChangeSubscription: Subscription;
	private refreshPostsSubscription: Subscription;

	public tuiViewer: Viewer = null;

	constructor(
		private postService: PostService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private commentService: CommentsService,
		private networkService: NetworkService,
		private userService: UserService,
		private chatSocketService: ChatSocketService,
		private socketIoService: SocketIoService,
		private profilesService: ProfilesService,
		private collectionService: CollectionsService,
		private geoLocationService: GeoLocationService,
		private locationService: LocationService,
		private ref: ChangeDetectorRef,
		private searchService: SearchService,
		public layoutService: LayoutService,
		private translate: TranslateService,
		private toastService: ToastService,
		public location: Location,
		private pageLoadingUiService: PageLoadingUiService,
		private contextMenuService: ContextMenuService,
		private lightboxService: LightboxService,
		private attachmentService: AttachmentService,
		private scrollService: ScrollService,
		private nprogressService: NprogressService,
		private clipboardService: ClipboardService,
		private translateService: TranslateService,
		private nzModalService: NzModalService,
		private mixPanelService: MixPanelService,
		private segmentService: SegmentAnalyticsService
	) {
		// page scroll subscription
		this.windowScrollChangeSubscription = this.scrollService.windowScrollChanged.subscribe((event: ScrollEvent) => {
			if (event.scrollPercent >= 80 && !this.paginating) {
				this.paginate();
			}
		});
	}

	ngOnInit(): void {
		if (localStorage.getItem('networkExpired')) {
			this.router.navigateByUrl('/expired');
		}

		if (this.collectionService.postAddedToCollection) {
			this.showCollectionToast();
		}

		this.isFirstSearch = true;
		this.searchService.resetSearchValue();

		if (this.router.url === '/network/saved-post') {
			this.saved_post = true;
		} else {
			this.saved_post = false;
		}

		if (localStorage.getItem('previous_route')) {
			localStorage.removeItem('previous_route');
		}

		/**
		 * Contingency to include app_id for post-list listing in profile page (post tab)
		 */
		if (this.currentPage === 'userProfile') {
			this.postUrl = `${this.postUrl}&app_id=${localStorage.getItem('network_id')}`;
		}

		/** Load cached Posts in Post list
		 *
		 * 1. Check if current page is post list,then check if LS contains cached posts of network
		 * 2. If exists,hide loader and then add those posts in posts array.
		 * 3. Else clear posts array and show loader
		 */
		if (this.currentPage === 'postList') {
			if (localStorage.getItem(`${this.network['uid']}-Posts`)) {
				this.postFetched = true;
				this.posts = JSON.parse(localStorage.getItem(`${this.network['uid']}-Posts`));
				this.posts.forEach((post: any) => {
					if (post?.project) {
						post.project['expanded'] = false;
					} else if (post?.todolist) {
						post.todolist['expanded'] = false;
					}
				});

				this.filters = this.postService.filters;
				this.nextUrl = this.postService.nextUrl;
			} else {
				this.postFetched = false;
				this.posts = [];
				this.filters = '';
				this.nextUrl = '';
			}
		}

		/** For displaying welcome tip when user first enters network
		 *
		 * 1. Check if welcome popup is shown
		 * 2. Display welcome tip if shown
		 * 3. Else reset welcome tip variables
		 */
		if (this.networkService.displayWelcomeDialog) {
			// 1
			this.displayWelcomeTips = true; // 2
			this.isWelcomeTip = true;
		} else {
			// 3
			this.displayWelcomeTips = false;
			this.isWelcomeTip = false;
		}

		this.userService.currentUser.subscribe((userData) => {
			this.currentUser = userData;

			if (this.network && this.network['membershipUid']) {
				if (this.currentUser && !localStorage.getItem('MyTodolist') && !localStorage.getItem('favoriteId')) {
					this.networkService.getMembershipDetails(this.network['membershipUid']).subscribe((membership) => {
						if (membership['myTodoListId']) {
							localStorage.setItem('MyTodolist', membership.myTodoListId);
						}

						if (membership['favoriteId']) {
							localStorage.setItem('favoriteId', membership.favoriteId);
						}
					});
				}
			}
		});

		/**
		 * Function to fetch posts.
		 */
		if (this.currentPage !== 'calendar') {
			this.activatedRoute.queryParams.subscribe((params) => {
				if (params['hash_tag'] && this.currentPage !== 'issues-list') {
					this.isHashTags = true;
					this.hashTag = '#' + params['name'];
					this.getPostsByHashtags(params['hash_tag']);
				} else if (params['hash_tag'] && this.currentPage === 'issues-list') {
					this.hashTag = '';
					this.posts = [];
					this.postFetched = false;
					this.getIssuesByHashtags(params['hash_tag']);
				} else {
					this.hashTag = '';
					this.getAllPosts();
				}
			});
		}

		this.networkService.networkReady.subscribe((networkLoaded) => {
			this.networkLoaded = networkLoaded;

			if (this.networkLoaded) {
				this.networkService.currentNetwork.subscribe((network) => {
					if (network.roles && network.roles.length > 0) {
						const roles = network.roles,
							index = roles.findIndex((role) => role.slug === 'external');

						if (index > -1) {
							roles.splice(index, 1);
						}

						if (roles.length === 1) {
							this.hideSingleRole = true;
						}
					}

					this.network = network;

					if (this.network.SubscriptionTemplates && this.network.SubscriptionTemplates.length > 0) {
						this.network.SubscriptionTemplates.forEach((value) => {
							if (value.cpEnabled === true) {
								this.selectByLevelValues.push(value);
							}
						});
					}

					if (network['membership'] && network['membership']['memberRole'] && network['membership']['memberRole']['role']) {
						const memberRole = network['membership']['memberRole']['role'];

						if (memberRole.slug === 'external') {
							this.isExternalUser = true;

							if (network.channels.length > 0) {
								const index = network.channels.findIndex((channel) => channel.access === 4);
								this.externalUserChannel = network.channels[index];
							}
						}

						// -Check if customer service exists in network and set its index
						if (network['services'].length > 0) {
							this.serviceIndex = network.services.findIndex((service: any) => service.slug === 'add-customer');
						}

						if (this.network['privacySettings'] && this.network['membership']['memberType'] === 'normal') {
							this.checkForCustomerSettings(this.network);
						}

						/** For handling task/issue/customer welcome tips
						 *
						 * 1. Check if user items exists in network membership and welcome tip is closed
						 * 2. Check if LS does not contain welcome-tips
						 * 3. If tasks are not created,display task welcome tip
						 * 4. If issues are not created,display issue welcome tip
						 * 5. If customers are not created and if customer service exists ,also if user is not external user,display customer welcome tip
						 */
						if (network['membership']['userItems'] && !this.isWelcomeTip) {
							// 1
							if (!localStorage.getItem('welcome-tips')) {
								// 2
								const userItems = network['membership']['userItems'];

								if (!userItems['tasksExists']) {
									// 3
									this.displayWelcomeTips = true;
									this.isTaskTip = true;
									this.isIssueTip = false;
									this.isCustomerTip = false;
								} else if (!userItems['issuesExists']) {
									// 4
									this.displayWelcomeTips = true;
									this.isTaskTip = false;
									this.isIssueTip = true;
									this.isCustomerTip = false;
								} else if (!userItems['customerExists'] && this.serviceIndex > -1 && !this.isExternalUser && this.addCustomer) {
									// 5
									this.displayWelcomeTips = true;
									this.isTaskTip = false;
									this.isIssueTip = false;
									this.isCustomerTip = true;
								} else if (userItems['tasksExists'] && userItems['issuesExists'] && userItems['customerExists']) {
									this.displayWelcomeTips = false;
									this.isTaskTip = false;
									this.isIssueTip = false;
									this.isCustomerTip = false;
								}
							}
						}
					}

					if (network.membership && network.membership.memberRole) {
						this.memberType = network.membership.memberType;
						this.fields = network['membership']['memberRole']['fields'];
						this.checkIfManager();
						this.channels = network.channels;

						this.network.membership.memberRole.fields.forEach((element) => {
							if (element.CP === true) {
								this.personalFilterValues.push(element);
							}
						});
					}
				});

				this.departmentsReady = this.networkService.departmentsReady.subscribe((departmentsLoaded) => {
					if (departmentsLoaded) {
						this.currentDepartmentSubscriber = this.networkService.currentDepartments.subscribe((departments) => {
							if (departments && departments['objects'].length > 0) {
								this.departments = departments.objects;
							}
						});
					}
				});
			}
		});

		// if (this.network['privacySettings'] && this.network['membership']['memberType'] === 'normal') {
		//   this.checkForCustomerSettings(this.network);
		// }

		if (this.network && this.network['uid'] && this.currentPage === 'postList') {
			if (this.postService.postCategories.length === 0) {
				this.postService.getCategories(this.network.uid).subscribe((data) => {
					this.postCategories = data.objects;
					this.postService.postCategories = this.postCategories;
				});
			} else {
				this.postCategories = this.postService.postCategories;
			}
		}

		const self = this;

		if (this.currentPage === 'postList' || this.currentPage === 'event') {
			// eslint-disable-next-line spellcheck/spell-checker
			this.socketIoService.getSocket().on('networkposts', function (message) {
				const d = JSON.parse(message);
				self.socket_post = d.postId;
				const memberId = self.membership.uid;
				console.log('post', d);
				// eslint-disable-next-line spellcheck/spell-checker
				self.socketIoService.getSocket().emit('getpost', {
					networkId: d.networkId,
					postId: d.postId,
					memberId: memberId,
				});
			});

			/**
			 * 1. Receive post on 'newpost' socket event and parse incoming post.
			 * 2. Check if received post has property 'uid' and received post is part of this network.
			 * 3. If true, check if current page is event,then dont do anything.
			 * 4. Check if received post uid exists within posts array in postlist.
			 * 5. If false, convert recived post to post object.
			 * 6. Check if pinned post exists in postlist and if received post is not a pinned post.
			 * 7. If true,then replace post from index position one with recived post,keeping pinnedpost at 0 position.
			 * 8. Else append post to first position.
			 * 9. Update cached posts.
			 *10. If commentlist is not open and comment is not typing,then append new post object,Else store new post and its index.
			 */
			// eslint-disable-next-line spellcheck/spell-checker
			this.socketIoService.getSocket().on('newpost', function (message) {
				const d = JSON.parse(message); // 1

				if (d.hasOwnProperty('uid') && self.network['uid'] === d['network']) {
					// 2

					if (self.currentPage === 'event' && d.postType !== 'event') {
						// 3
						return;
					} else {
						const isPresent = self.posts.some(function (el) {
							return el.uid === d.uid;
						}); // 4

						if (!isPresent) {
							const post = new Post(d); // 5

							if (self.posts.length > 0 && self.posts[0]?.pinnedPost && !post?.pinnedPost) {
								// 6
								self.posts.splice(1, 0, post); // 7
								self.checkUserMentionAndHashtagAndAlterBody();
							} else {
								self.posts.unshift(post); // 8
								self.checkUserMentionAndHashtagAndAlterBody();
							}

							if (self.posts.length > 0) {
								// 9
								const array = self.posts.slice(0, 10);
								localStorage.setItem(`${self.network['uid']}-Posts`, JSON.stringify(array));
							}
						} else {
							const index = self.findWithAttr(self.posts, 'uid', d.uid);

							if (index > -1) {
								if (self.showCommentsContainer) {
									// For showing comments container even after liking or adding a comment
									d['showComment'] = true;
									d['commentsLoaded'] = true;
								}

								const newPost = new Post(d);

								if (!self.selectedPost && !self.commentService.isCommentTyped) {
									// 10
									self.posts[index] = newPost;
									self.incomingPost = '';
									self.currentPostIndex = '';
								} else {
									self.currentPostIndex = index; // 10
									self.incomingPost = newPost;
								}

								self.checkUserMentionAndHashtagAndAlterBody();
							}

							if (self.posts.length > 0) {
								// 9
								const array = self.posts.slice(0, 10);
								localStorage.setItem(`${self.network['uid']}-Posts`, JSON.stringify(array));
							}
						}
					}
				} else {
					const index = self.findWithAttr(self.posts, 'uid', self.socket_post.uid);

					if (index > -1) {
						self.posts.splice(index, 1);
					}
				}
			});

			// eslint-disable-next-line spellcheck/spell-checker
			this.socketIoService.getSocket().on('networkposts-delete', function (message) {
				const d = JSON.parse(message),
					index = self.findWithAttr(self.posts, 'uid', d.postId);

				if (index > -1) {
					self.posts.splice(index, 1);
					const array = self.posts.slice(0, 10);

					localStorage.setItem(`${self.network['uid']}-Posts`, JSON.stringify(array));
				}
			});
		}

		this.postService.deselectPost.subscribe(() => {
			this.selectedPost = null;
		});

		this.searchSubscription = this.searchService.searchValue.subscribe((value) => {
			this.searchString = value;

			if (!this.issueCollection) {
				if (this.isFirstSearch && value.trim()) {
					this.isFirstSearch = false;
				}

				if (!this.isFirstSearch) {
					this.pageLoadingUiService.setPageLoading(true);
					this.postService.getPosts(this.postUrl, value, 'query').subscribe((postData) => {
						this.pageLoadingUiService.setPageLoading(false);

						if (postData.next) {
							this.nextUrl = postData.next.split('alpha')[1];
						} else {
							this.nextUrl = '';
						}

						this.posts = postData.objects.map((post: any) => {
							const _post = new Post(post);
							_post['author']['isOnline'] = this.isOnline(_post?.author?.username);

							if (_post?.project) {
								post.project['expanded'] = false;
							} else if (_post?.todolist) {
								post.todolist['expanded'] = false;
							}
							return _post;
						});

						this.checkUserMentionAndHashtagAndAlterBody(value);
					});
				}
			}
		});

		this.layoutService.showBackdrop.subscribe((data) => {
			if (data === 'post') {
				this.deselectPost();
			} else if (data === 'comment-location') {
				this.location_display = 'none';
			}
		});

		this.layoutService.postFilterList.subscribe(() => {
			this.showPostFilter = !this.showPostFilter;
		});

		this.postEdit.updatedPostEmitter.subscribe((newPost) => {
			if (newPost) {
				this.posts[this.editSelectedPostIndex] = newPost;
				this.checkUserMentionAndHashtagAndAlterBody();
				this.editSelectedPost = null;
				this.editSelectedPostIndex = -1;
			}
		});

		this.postService.newPostEmitter.subscribe((data) => {
			let index = -1;

			if (data) {
				data = new Post(data);
				index = this.findWithAttr(this.posts, 'uid', data['uid']);
			}

			if (data && this.currentPage === 'collectionDetail') {
				if (index > -1) {
					this.posts[index] = data;
				} else {
					this.posts.unshift(data);
					// this.getAllPosts();
				}

				this.checkUserMentionAndHashtagAndAlterBody();
			} else if (data && this.currentPage === 'events') {
				if (index > -1) {
					this.posts[index] = data;
				} else {
					this.posts.unshift(data);
				}
				this.noEvents.emit(false);

				this.checkUserMentionAndHashtagAndAlterBody();
			} else if (data && this.currentPage === 'channelDetail') {
				if (index > -1) {
					this.posts[index] = data;
				} else {
					this.posts.unshift(data);
				}

				this.checkUserMentionAndHashtagAndAlterBody();
			}
		});

		/**
		 * For showing online/offline statues of post authors
		 * 1. Emit set user data
		 * 2. Get call back from onlineStack event and store online stack data
		 */
		if (this.chatSocketService.getSocket().ioSocket.connected) {
			// 1
			this.chatSocketService.getSocket().emit('set-user-data', { username: this.currentUser?.username, networkId: this.network?.uid }, () => { });
		}

		this.chatSocketService.getSocket().on('onlineStack', (members) => {
			// 2
			const onlineMembers = Object.keys(members).map(function (k) {
				return members[k];
			});
			this.onlineStack = onlineMembers;
		});

		/**
		 * External trigger to refresh posts
		 */
		this.refreshPostsSubscription = this.postService.refreshPostsEventEmitter.subscribe((value: boolean) => {
			if (value) {
				this.posts = [];
				this.postFetched = false;
				this.getAllPosts();
			}
		});
	}

	ngOnDestroy(): void {
		this.posts = [];

		if (this.socketIOSubscription) {
			this.socketIOSubscription.unsubscribe();
		}

		this.searchService.resetSearchValue();

		if (this.searchSubscription) {
			this.searchSubscription.unsubscribe();
		}

		if (this.departmentsReady) {
			this.departmentsReady.unsubscribe();
		}

		if (this.currentDepartmentSubscriber) {
			this.currentDepartmentSubscriber.unsubscribe();
		}

		this.windowScrollChangeSubscription.unsubscribe();
		this.refreshPostsSubscription.unsubscribe();
	}

	ngAfterViewInit(): void {
		// this.loadFilterMenu();
		this.isViewInitialized = true;
		if (this.postService.scrollPosition && this.currentPage === 'postList') {
			window.scroll(0, this.postService.scrollPosition);
		}
		// Hack: Scrolls to top of Page after page view initialized
		let top = document.getElementById('top');
		if (top !== null) {
			top.scrollIntoView();
			top = null;
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.hasOwnProperty('postUrl') && this.isViewInitialized) {
			this.posts = [];
			this.postFetched = false;
			this.getPosts();
		}
		if (changes.hasOwnProperty('timeNow')) {
			this.posts = [];
			this.postFetched = false;
			this.getUserItemsForCalendar();
		}
	}

	/*
	 * Function to fetch posts.
	 */
	private getPosts(resetHash = false): void {
		this.showSpin = true;

		if (this.saved_post) {
			// eslint-disable-next-line spellcheck/spell-checker
			this.saved_post_url = '/users/' + this.currentUser.username + '/savedposts/?contentType=network&contentUid=' + this.network.uid;
			// TODO: use loading spinner
			this.postService.getSavedPosts(this.saved_post_url, 'all').subscribe((data) => {
				this.postFetched = true;
				this.posts = data.objects.map((item) => {
					const _post = new Post(item.post);
					_post['author']['isOnline'] = this.isOnline(_post?.author?.username);

					if (_post?.project) {
						_post.project['expanded'] = false;
					} else if (_post?.todolist) {
						_post.todolist['expanded'] = false;
					}

					return _post;
				});

				if (data.next) {
					this.nextUrl = data.next.split('alpha')[1];
				} else {
					this.nextUrl = '';
				}
				// this.checkUserMentionAndHashtagAndAlterBody();
			});
		} else {
			const hash = this.activatedRoute.snapshot.fragment;

			if (hash) {
				if (resetHash) {
					this.hash = '';
					this.getAllPosts();
				} else {
					this.hash = hash.split('/')[0];
					// TODO: use loading spinner
					this.postService.getPosts('/hashtags/' + hash.split('/')[1] + '/', '', 'all').subscribe(
						(postData) => {
							this.postFetched = true;
							if (postData.next) {
								this.nextUrl = postData.next.split('alpha')[1];
							} else {
								this.nextUrl = '';
							}

							this.posts = postData.objects.map((post) => {
								const _post = new Post(post);
								_post['author']['isOnline'] = this.isOnline(_post?.author?.username);

								if (_post?.project) {
									_post.project['expanded'] = false;
								} else if (_post?.todolist) {
									_post.todolist['expanded'] = false;
								}
								return _post;
							});

							this.checkUserMentionAndHashtagAndAlterBody();
						},
						() => this.handleError2
					);
				}
			} else {
				this.getAllPosts();
			}
		}
	}

	private getAllPosts(): void {
		// TODO: use loading spinner
		this.postService.getPosts(this.postUrl, '', 'all').subscribe(
			(postData) => {
				console.log(postData);

				this.postFetched = true;
				this.showSpin = false;

				if (postData?.objects?.length === 0 && this.currentPage === 'events') {
					this.noEvents.emit(true);
				}

				if (postData.next) {
					this.nextUrl = postData.next.split('alpha')[1];
					this.firstTimeNextUrl = JSON.parse(JSON.stringify(postData.next.split('alpha')[1]));

					this.paginate();
				} else {
					this.nextUrl = '';
				}

				this.posts = postData.objects.map((post: any) => {
					const _post = new Post(post);
					_post['author']['isOnline'] = this.isOnline(_post?.author?.username);

					if (_post?.project) {
						_post.project['expanded'] = false;
					} else if (_post?.todolist) {
						_post.todolist['expanded'] = false;
					}

					return _post;
				});

				this.checkUserMentionAndHashtagAndAlterBody();
				this.checkPastEvents();
			},
			() => {
				this.postFetched = true;
				this.displayError = 'block';

				if (localStorage.getItem('status')) {
					const x = JSON.parse(localStorage.getItem('status'));

					if (x['detail']) {
						this.errorObj['detail'] = x['detail'];
					} else {
						this.errorObj['detail'] = this.translate.instant('Something went wrong');
					}

					if (x['status']) {
						this.errorObj['status'] = x['status'];
					}
				}
			}
		);
	}

	public closeModal(): void {
		localStorage.removeItem('status');
		this.errorText = this.translate.instant('Something went wrong');
	}

	private checkPastEvents(): void {
		this.posts.forEach((post) => {
			if (post['type'] === 'events') {
				const starts = new Date(post.ends);
				const date = new Date();
				if (date > starts) {
					post['isPast'] = true;
				}
			}
		});
	}

	public checkEventIsExpiredOrNot(post): boolean {
		const starts = new Date(post.ends);
		const date = new Date();
		if (post.ends) {
			if (date > starts) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	/** Function to display events/meeting posts in calendar view
	 * 1. Call api to get posts passing paramType and date from calendar view
	 * 2. Set next url if exists
	 * 3. Set posts in array
	 *
	 */
	private getUserItemsForCalendar(): void {
		// TODO: use loading spinner
		this.networkService.getUserEvents(this.paramType, this.date).subscribe(
			(postData) => {
				// 1
				this.postFetched = true;
				this.showSpin = false;
				if (postData.next) {
					// 2
					this.nextUrl = postData.next.split('alpha')[1];
					this.firstTimeNextUrl = JSON.parse(JSON.stringify(postData.next.split('alpha')[1]));
					this.paginate();
				} else {
					this.nextUrl = '';
				}
				this.posts = postData.objects.map((post) => {
					const _post = new Post(post);
					_post['author']['isOnline'] = this.isOnline(_post?.author?.username);
					return _post;
				}); // 3
				this.checkUserMentionAndHashtagAndAlterBody();
			},
			() => {
				this.postFetched = true;
			}
		);
	}

	private onRemoveSelectedHashtag(): void {
		this.hash = '';
		this.posts = [];
		// this.router.navigateByUrl('/network');
		this.postFetched = false;
		this.getPosts(true);
	}

	private findWithAttr(array, attr, value): number {
		for (let i = 0; i < array.length; i += 1) {
			if (array[i][attr] === value) {
				return i;
			}
		}
		return -1;
	}

	private handleError2(): void { }

	private getComments(post): void {
		this.commentService.getAll(post.commentUrl).subscribe((data) => {
			const comments = data.objects.map((comment) => new Comment(comment));
			if (!post.commentFirstClick) {
				post.comments = post.comments.concat(comments);
			} else {
				post.comments = comments;
				post.commentFirstClick = false;
			}
			post.setCommentUrl(data.meta);
		});
	}

	private highlight(): void {
		this.showBg = true;
	}

	private disableIt(): void {
		this.showBg = false;
	}

	private addUrlBox(post): void {
		post.newComment.isLink = !post.newComment.isLink;
		post.newComment.attachmentType = 'url';
	}

	public likePost(post): void {
		this.postService.like(this.currentUser.username, post).subscribe((data) => {
			post['myRelations']['like'] = data.uid;
			post['likesCount']['count'] = post['likesCount']['count'] + 1;
			if (post['myRelations'] && post['myRelations'].like && post['likesCount']['count'] === 0) {
				post['likedText'] = this.translate.instant('You liked this');
			}
		});
	}

	public dislikePost(post): void {
		this.postService.dislike(this.currentUser.username, post.myRelations.like).subscribe(() => {
			post['myRelations']['like'] = null;
			post['likedText'] = '';
			post['likesCount']['count'] = post['likesCount']['count'] ? post['likesCount']['count'] - 1 : 0;
		});
	}

	private showMembers(post): void {
		post['showMembers'] = true;
		this.selected_post = post;
		this.showUserSuggestion = true;
		this.openModal();
	}

	private showImageUpload(post): void {
		post.newComment.attachmentType = 'photo';
		post['newImage'] = true;
	}

	private onMemberSelect(post, member): void {
		delete post['showMembers'];
		post['newComment'].text += '@' + member['user'].name + ' ';
		if (post.newComment['mentioned_users']) {
			post.newComment['mentioned_users'].push(member);
		} else {
			post.newComment['mentioned_users'] = [member];
		}
		this.onCloseHandled();
	}

	private removeSelectedMember(): void {
		delete this.selected_post.newComment.selected_member;
		delete this.selected_post.newComment.member;
		delete this.selected_post.newComment.attachmentType;
		this.selected_post = null;
	}

	private openModal(): void {
		this.dialog_display = 'block';
		//  this.selected_post = null;
	}
	private onCloseHandled(): void {
		this.dialog_display = 'none';
		this.eventModal = 'none';
		// this.selected_post = null;
	}

	private filterPost(slug, filterType): void {
		event.preventDefault();
		this.pageLoadingUiService.setPageLoading(true);

		if (this.filter && slug) {
			if (this.filter.value === slug) {
				slug = '';
				filterType = 'all';
			}
		}

		this.filters = { filterType: filterType, slug: slug };
		this.filter = { value: slug };
		if (this.saved_post) {
			this.postService.getSavedPosts(this.saved_post_url, slug, filterType).subscribe((data) => {
				this.posts = data.objects.map((post) => {
					const _post = new Post(post.post);
					_post['author']['isOnline'] = this.isOnline(_post?.author?.username);
					return _post;
				});
				this.checkUserMentionAndHashtagAndAlterBody();

				if (data.next) {
					this.nextUrl = data.next.split('alpha')[1];
				} else {
					this.nextUrl = '';
				}

				this.pageLoadingUiService.setPageLoading(false);
			});
		} else {
			this.postService.getPosts(this.postUrl, slug, filterType).subscribe((filteredPost) => {
				this.posts = filteredPost.objects.map((post) => {
					const _post = new Post(post);
					_post['author']['isOnline'] = this.isOnline(_post?.author?.username);
					return _post;
				});
				this.nextUrl = this.firstTimeNextUrl;
				this.checkUserMentionAndHashtagAndAlterBody();
				this.pageLoadingUiService.setPageLoading(false);
			});
		}
	}

	private personalFilter(filterType, slug): void {
		event.preventDefault();
		this.filter = { value: slug };
		this.filters = { filterType: filterType, slug: slug };
		this.getPersonalFilter(filterType + '=' + slug);
		this.pageLoadingUiService.setPageLoading(true);
	}

	private getPersonalFilter(q = ''): void {
		// TODO: use loading spinner
		this.postService.personalFilterService('/networks/' + localStorage.getItem('network_id') + '/posts/', q).subscribe((data) => {
			this.posts = data.objects.map((post) => {
				const _post = new Post(post);
				_post['author']['isOnline'] = this.isOnline(_post?.author?.username);

				return _post;
			});
			if (data.next) {
				this.nextUrl = data.next.split('alpha')[1];
				this.paginate();
			} else {
				this.nextUrl = '';
			}
			this.checkUserMentionAndHashtagAndAlterBody();
			this.pageLoadingUiService.setPageLoading(false);
		});
	}

	/**
	 * Function to append new post to the post list
	 * If first post in the array(post list) is pinned and new post is not pinned add new post to the second position
	 * If new post and first post in the array are pinned refresh the post list.
	 * If new post is pinned and first post in the array is not pinned add new post to top of the post list.
	 */
	public appendNewPost(data): void {
		// TODO: use loading spinner
		data = new Post(data);
		const index = this.findWithAttr(this.posts, 'uid', data.uid);
		if (this.posts[0]?.pinnedPost && !data?.pinnedPost) {
			if (index > -1) {
				this.posts.splice(index, 1);
			}
			this.posts.splice(1, 0, data);
			this.checkUserMentionAndHashtagAndAlterBody();
		} else if (this.posts[0]?.pinnedPost && data?.pinnedPost) {
			this.postFetched = false;
			this.posts = [];

			this.postService.getPosts(this.postUrl, '', 'all').subscribe(
				(postData) => {
					this.postFetched = true;
					if (postData.next) {
						this.nextUrl = postData.next.split('alpha')[1];
					} else {
						this.nextUrl = '';
					}
					this.posts = postData.objects.map((post) => {
						const _post = new Post(post);
						_post['author']['isOnline'] = this.isOnline(_post?.author?.username);
						return _post;
					});

					this.checkUserMentionAndHashtagAndAlterBody();
				},
				() => this.handleError2
			);
		} else {
			if (index > -1) {
				this.posts[index] = data;
			} else {
				this.posts.unshift(data);
			}
			this.checkUserMentionAndHashtagAndAlterBody();
		}
	}

	public paginate(): void {
		if (this.paginating) {
			return;
		}

		this.nprogressService.increaseProgressTo(90);

		if (this.nextUrl) {
			this.paginating = true;
			this.showSpin = true;

			if (this.saved_post) {
				this.postService
					.getSavedPosts(this.nextUrl, 'all')
					.subscribe((data) => {
						this.posts = data.objects.map((post) => {
							const _post = new Post(post.post);
							_post['author']['isOnline'] = this.isOnline(_post?.author?.username);
							return _post;
						});

						this.nextUrl = data.next ? data.next.split('alpha')[1] : '';
					})
					.add(() => {
						this.paginating = false;
						this.showSpin = false;
						this.nprogressService.setProgress(100);
					});
			} else {
				this.postService
					.paginate(this.nextUrl, this.filters)
					.subscribe((postData) => {
						this.nextUrl = postData.next ? postData.next.split('alpha')[1] : null;

						this.posts = this.posts.concat(
							postData.objects.map((post) => {
								const _post = new Post(post);
								_post['author']['isOnline'] = this.isOnline(_post?.author?.username);
								return _post;
							})
						);

						this.checkUserMentionAndHashtagAndAlterBody();
						this.checkPastEvents();
						this.showSpin = false;
					})
					.add(() => {
						// check over scroll
						const scrollPercent: number = this.scrollService.getScrollPercent();

						// then re-call paginate if we're at the bottom
						if (scrollPercent > 90) {
							this.paginate();
						}

						this.nprogressService.setProgress(100);

						this.paginating = false;
						this.showSpin = false;
					});
			}
		}
	}

	// Called when an event option is selected
	public onEventAttendSubmit(post, eventValue): void {
		const currentPost = post;
		const self = this;
		if (currentPost.myRelations.rsvp) {
			self.postService.updateEventAttendType(self.currentUser.username, eventValue, currentPost.myRelations.rsvp).subscribe((data) => {
				const index = self.posts.indexOf(currentPost);
				self.posts[index].myRelations.attending = data.attending;
				if (Number(eventValue) === 1) {
					// const index = self.posts.indexOf(currentPost);
					self.posts[index].rsvpYesCount = +self.posts[index].rsvpYesCount + 1;
				} else {
					// const index = self.posts.indexOf(currentPost);
					if (self.posts[index].rsvpYesCount > 0) {
						self.posts[index].rsvpYesCount = +self.posts[index].rsvpYesCount - 1;
					}
				}
				self.onCloseHandled();
			});
		} else {
			self.postService.postEventAttendType(self.currentUser.username, eventValue, currentPost.uid).subscribe((data) => {
				const index = self.posts.indexOf(currentPost);
				self.posts[index].myRelations.attending = data.attending;
				self.posts[index].myRelations.rsvp = data.uid;
				if (Number(eventValue) === 1) {
					// const index = self.posts.indexOf(currentPost);
					self.posts[index].rsvpYesCount = +self.posts[index].rsvpYesCount + 1;
				} else {
					// const index = self.posts.indexOf(currentPost);
					if (self.posts[index].rsvpYesCount > 0) {
						self.posts[index].rsvpYesCount = +self.posts[index].rsvpYesCount - 1;
					}
				}
				self.onCloseHandled();
			});
		}
		console.log(currentPost);
	}

	// Called when a choice is selected.
	public onChoiceSelect(post, choice): void {
		if (post.myRelations.vote) {
			this.voteSelectedPost = null;
			this.selectedChoice = null;
		} else {
			this.voteSelectedPost = post;
			this.selectedChoice = choice;
			this.onVoteSubmit();
		}
	}
	// Function to call the vote submit api.
	private onVoteSubmit(): void {
		if (this.voteSelectedPost !== null && this.selectedChoice !== null) {
			this.postService.postVote(this.voteSelectedPost.uid, this.selectedChoice.uid).subscribe((data) => {
				const index = this.posts.indexOf(this.voteSelectedPost);
				const choiceIndex = this.posts[index].choices.indexOf(this.selectedChoice);
				this.posts[index].myRelations.vote = data.uid;
				this.posts[index].myRelations.choice = data.choice.uid;
				this.posts[index].choices[choiceIndex] = data.choice;

				const count = this.posts[index].totalVoteCount;
				this.posts[index].votesFormatted =
					(count + 1).toString() + this.posts[index].votesFormatted.slice(/^\d$/.test(count) ? 1 : 2, this.posts[index].votesFormatted?.length);

				this.posts[index].totalVoteCount++;
			});
		}
	}
	// Pass the clicked post details to this function and store in readMorePost
	public showMore(post): void {
		if (post['showMore']) {
			post['showMore'] = false;
		} else {
			post['showMore'] = true;
		}
		// this.readMorePost = postUid;
	}

	// Function to save the post
	private onSaveClick(postUid): void {
		this.postService.savePost(postUid);
	}
	/**
	 * Function to show the post detail
	 **/
	public selectPost(post): void {
		this.selectedPost = post;
		this.showComments(post);
		this.layoutService.showBd('post');
	}

	/** Date: 28-09-20
	 * Function which executes on hiding comments list
	 * 1.Remove selected post
	 * 2.Check if there's a new post from socket
	 * 3.If new post exists, append it to index position
	 * 4.check mention/hashtags on post
	 */
	private deselectPost(): void {
		if (this.selectedPost && this.selectedPost['showComment']) {
			this.selectedPost['showComment'] = false;
		}
		this.selectedPost = null; // 1
		this.showCommentsContainer = false;
		if (this.incomingPost && this.currentPostIndex > -1) {
			// 2
			this.posts[this.currentPostIndex] = this.incomingPost; // 3
			this.checkUserMentionAndHashtagAndAlterBody(); // 4
		}
	}

	// Function to show the comments of a corresponding posts
	public showComments(post): void {
		post['showComment'] = true;
		// this.showCommentsContainer = true;
		// if (post['showComment']) {
		//   post['showComment'] = false;
		// } else {
		//   post['showComment'] = true;
		// }
	}

	/**
	 * Function to redirect to create collection page
	 */
	public redirectToCreateCollection(): void {
		this.router.navigateByUrl(`/network/create-collection?postUid=${this.addToCollectionSelectedPost.uid}`);
	}

	/**
	 * Function which displays popup after a new collection is created
	 */
	private showCollectionToast(): void {
		this.displayToastCollection = true;
		this.newCollection.uid = this.collectionService.newCollection.uid;
		this.newCollection.name = this.collectionService.newCollection.name;
		setTimeout(() => {
			this.displayToastCollection = false;
		}, 5000);
	}

	public paginateCollection(): void {
		if (this.collectionService.nextUrl) {
			this.collectionService.paginateCollection();
		}
	}

	/**
	 * Function to add post to collection
	 */
	public onSelectCollection(collection): void {
		this.showCollectionModal = false;
		this.pageLoadingUiService.setPageLoading(true);
		this.collectionService.addToCollection(collection.uid, this.addToCollectionSelectedPost.uid).subscribe(
			() => {
				this.mixPanelService.track('Created Collections', {
					workspace: this.network.name || 'NA',
					appVersion: environment.version,
					language: this.translate.currentLang,
					username: this.currentUser?.username || 'NA',
					name: this.currentUser?.name || 'NA',
					date: moment().format('YYYY-MM-DD'),
				});

				this.segmentService.trackEvent('Created Collections', {
					workspace: this.network.name || 'NA',
					appVersion: environment.version,
					language: this.translate.currentLang,
					username: this.currentUser?.username || 'NA',
					name: this.currentUser?.name || 'NA',
					date: moment().format('YYYY-MM-DD'),
				});
				this.pageLoadingUiService.setPageLoading(false);
				this.collectionService.populateCollection();
				this.addToCollectionSelectedPost = null;
			},
			() => {
				this.pageLoadingUiService.setPageLoading(false);
				this.displayCollectionError = 'block';
				// if (localStorage.getItem('status')) {
				//   let x = JSON.parse(localStorage.getItem('status'));
				//   if (x['detail']) {
				//     this.errorObj['detail'] = x['detail'];
				//   } else {
				//     this.errorObj['detail'] = this.translate.instant('Something went wrong');
				//   }
				//   if (x['status']) {
				//     this.errorObj['status'] = x['status'];
				//   }
				// }
			}
		);
	}

	/**
	 * Function to check the user mention and hash tag in post list if exists change the post body
	 */
	private checkUserMentionAndHashtagAndAlterBody(value = this.searchString): void {
		this.posts.forEach((post) => {
			this.postService.alterPostBodyAccordingToUserMentionAndHashtag(post, value);

			if (post['lastComments']) {
				this.checkUserMentionAndHashtagAndAlterCommentText(post['lastComments']);
				if (post['lastComments'].parent) {
					this.checkUserMentionAndHashtagAndAlterCommentText(post['lastComments'].parent);
				}
			}

			post.baseAttachment = this.attachmentService.parseBaseAttachment(post.attachmentDetails, post);
		});
		console.log(this.posts);

		this.posts.forEach((post: any) => {
			setTimeout(() => {
				const viewerElement: HTMLElement = document.querySelector(`#post-content-${post.uid}`);
				if (!viewerElement) {
					return;
				}

				// Function to replace plain text mentions with hyperlinks
				const replaceMentionWithHTML = (text: string, mentionedUsers: any[]): string => {
					if (!text) return text;

					mentionedUsers.forEach((mentionedUser) => {
						const mention = `@${mentionedUser['name']}`;
						const htmlLink = `<a class="text-green text-bold" href="/network/profile/${mentionedUser?.memberId}?username=${mentionedUser?.username}">${mention}</a>`;
						text = text.replace(new RegExp(mention.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g'), htmlLink); // Escape special characters in mention
					});

					return text;
				};

				let title = '';
				if (post['type'] !== 'issue') {
					title = post.title;
					if (post.mentionedUsers) {
						title = replaceMentionWithHTML(title, post.mentionedUsers);
					}
				}

				let body = '';
				if (title) {
					let bodyText = post?.body ? post?.body : '';
					if (post.mentionedUsers) {
						bodyText = replaceMentionWithHTML(bodyText, post.mentionedUsers);
					}
					body = `# ${title}\n${bodyText}`;
				} else {
					body = post?.body ? post?.body : '';
					if (post.mentionedUsers) {
						body = replaceMentionWithHTML(body, post.mentionedUsers);
					}
				}

				// Initialize the viewer
				const tuiViewer: Viewer = Editor.factory({
					el: viewerElement,
					viewer: true,
					height: '500px',
					initialValue: body,
					plugins: [[codeSyntaxHighlight, { highlighter: Prism }]],
					linkAttributes: { target: '_blank' },
					frontMatter: true,
				});

				post.tuiViewer = tuiViewer;
			}, 50);
		});
	}

	/**
	 * Function to check the user mention and hash tag in post list if exists change the comment text
	 * 1.If string contains '<',then its cut off while displaying in innerHtml.Hence its replaced by '< '
	 */
	private checkUserMentionAndHashtagAndAlterCommentText(comment): void {
		if (comment) {
			if (comment.text && comment.text.indexOf('<') > -1) {
				// 1
				comment.text = comment.text.replace(/</g, '< ');
			}

			comment.alteredText = comment.text;
			comment.minAlteredText = comment.text;
			if (comment.alteredText && comment.alteredText.length > 150) {
				comment.minAlteredText = comment.alteredText.slice(0, 150) + '...';
				comment.showMinComment = true;
			}

			if (comment['mentionedUsers']) {
				comment['mentionedUsers'].forEach((mentionedUser) => {
					// const userMention = '@' + mentionedUser['name'];
					const html =
						'<a #userMention class="text-green text-bold" href="/network/profile/' +
						mentionedUser?.memberId +
						'?username=' +
						mentionedUser?.username +
						'">@' +
						mentionedUser['name'] +
						'</a>';
					comment.alteredText = comment.alteredText.replace('@' + mentionedUser['name'], html);
					comment.minAlteredText = comment.minAlteredText.replace('@' + mentionedUser['name'], html);
				});
			}
			if (comment['hashtags']) {
				comment['hashtags'].forEach((hashtag) => {
					if (hashtag['name']) {
						if (comment['contentType'] === 'issue') {
							const hashtagName = hashtag['name'];
							// const hashtagUid = hashtag['uid'];
							const html = '<b #hashTag><a class="hash-issue">#' + hashtagName + '</a></b>';
							comment.alteredText = comment.alteredText.replace('#' + hashtag['name'], html);
							comment.minAlteredText = comment.minAlteredText.replace('#' + hashtag['name'], html);
						} else {
							const hashtagName = hashtag['name'];
							// const hashtagUid = hashtag['uid'];
							const html = '<b #hashTag><a class="hash-post">#' + hashtagName + '</a></b>';
							comment.alteredText = comment.alteredText.replace('#' + hashtag['name'], html);
							comment.minAlteredText = comment.minAlteredText.replace('#' + hashtag['name'], html);
						}
					}
				});
			}
			const urlRegex =
				// eslint-disable-next-line max-len
				/(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;
			if (comment.alteredText) {
				const strings = comment.text.match(urlRegex);
				if (strings && strings.length > 0) {
					strings.forEach((element) => {
						let url = element;
						if (!(element.indexOf('http://') === 0 || element.indexOf('https://') === 0 || element.indexOf('ftp://') === 0)) {
							url = 'http://' + element;
						}
						const html = '<a target="blank" class="pb-text-link" href="' + url + '">' + element + '</a>';
						if (comment.alteredText) {
							comment.alteredText = comment.alteredText.replace(element, html);
							comment.minAlteredText = comment.minAlteredText.replace(element, html);
						}
					});
				}
			}
		}
	}

	// For calling zoom refresh api for zoom meeting delete
	private callZoomRefresh(): void {
		if (this.currentUser && this.currentUser.zoomLinked) {
			// check if zoom in expired or not
			if (this.currentUser.zoomExpires) {
				const currentDate = new Date();
				const zoomExpiryDate = this.currentUser.zoomExpires;
				const splicedZoomDate = zoomExpiryDate.slice(0, zoomExpiryDate.lastIndexOf('.'));
				const u = '+0000';
				const utcZoomExpiryDate = splicedZoomDate.concat(u);
				const expiryDate = new Date(utcZoomExpiryDate);
				if (currentDate > expiryDate) {
					// Call zoom refresh api since zoom is expired

					const data = {};
					this.userService.zoomAuthRefresh(data).subscribe(() => {
						// call user details api and update current user data
						this.userService.updateUserDetails();
						this.currentUser = this.userService.getCurrentUser();
						// Remove user details from LS,hence on refresh updated user details gets on LS
						localStorage.removeItem('userDetails');
						CacheUtils.removeStoredData('userDetails');
					});
				} else {
				}
			}
		}
	}

	private onCommentLocationClick(post): void {
		this.selected_post = post;
		this.location_display = 'block';
		this.layoutService.showBd('comment-location');
		if (this.locations.length === 0) {
			const self = this;
			let locations = [];
			const accuracy = { enableHighAccuracy: true };

			// TODO: use loading spinner
			self.geoLocationService.getLocation(accuracy).subscribe(
				(position: any) => {
					self.currentLocation = position;

					self.locationService.getNearMeLocations(position.coords).subscribe((data) => {
						locations = data.response.groups[0].items.map((loc) => new LocationObj(loc, '4sq'));

						self.locations = self.sortData(locations);
					});

					self.ref.detectChanges();
				},
				function (error) {
					self.errorMessage = error;
					self.ref.detectChanges();
				}
			);
		}
	}

	private sortData(data: any): any {
		return data.sort(function (m1, m2) {
			if (m1.distance < m2.distance) {
				return -1;
			} else if (m1.distance > m2.distance) {
				return 1;
			} else {
				return 0;
			}
		});
	}

	/**
	 * Function to call on comment location  select.
	 */
	private onLocationSelect(loc): void {
		this.location_display = 'none';
		if (this.selected_post) {
			const index = this.posts.indexOf(this.selected_post);
			if (index !== -1) {
				this.posts[index].newComment.location = loc;
				this.posts[index].newComment.attachmentType = 'location';
			}
		}
	}

	/**
	 * Function to hide location select modal.
	 */
	private onCloseLocationModal(): void {
		this.location_display = 'none';
		this.layoutService.hideBd();
	}

	/**
	 * Function to create like or delete like of a comment
	 */
	public onLikeCommentClick(comment): void {
		if (!comment['myRelations'].like) {
			this.commentService.likeComment(comment.uid).subscribe((data) => {
				comment['myRelations'].like = data['uid'];
				if (comment['likeCount']) {
					comment['likeCount'] = comment['likeCount'] + 1;
				} else {
					comment['likeCount'] = 1;
				}
			});
		} else {
			this.commentService.deleteLike(comment['myRelations'].like).subscribe(() => {
				comment['myRelations'].like = null;
				comment['likeCount'] = comment['likeCount'] - 1;
			});
		}
	}

	private onClickCommentReport(comment, post): void {
		this.reportSelectModalDisplay = 'block';
		this.reportCommentSelectedPost = post;
		this.reportSelectedComment = comment;

		this.selectedCommentReportOption = null;
		this.reason = '';
		this.showReportError = false;
		this.reportOptionMissingError = false;
	}
	private hideCommentReportModal(): void {
		this.reportSelectModalDisplay = 'none';
		this.reportCommentSelectedPost = null;
		this.reportSelectedComment = null;
	}

	public onSubmitCommentReport(): void {
		if (this.selectedCommentReportOption) {
			if (this.selectedCommentReportOption === 'other' && !this.reason) {
				this.showReportError = true;
			} else {
				const obj = {
					contentType: 'comment',
					text: 'message',
					contentUid: this.reportSelectedComment.uid,
					reason: this.selectedCommentReportOption,
				};
				this.commentService.report(obj).subscribe(
					(data) => {
						if (data) {
							this.reportSelectedComment['myRelations'] = {};
							this.reportSelectedComment['myRelations']['flag'] = data['uid'];
							this.hideReportOptionSelectModal();
						}
					},
					() => {
						this.showReportError = true;
					}
				);
			}
		} else {
			this.reportOptionMissingError = true;
		}
	}

	public hideReportOptionSelectModal(): void {
		this.reportSelectedComment = null;
		this.reportSelectModalDisplay = 'none';
		this.reportCommentSelectedPost = null;
		this.reportOptionMissingError = false;
		this.reason = '';
	}

	public onSelectReportOption(option): void {
		this.selectedCommentReportOption = option;
		this.showReportError = false;
		this.reportOptionMissingError = false;
		if (option === 'other') {
			if (this.reportInput) {
				this.reportInput.nativeElement.focus();
			}
		} else {
			this.reason = '';
		}
	}
	/**
	 * Function to show the delete confirmation model when delete label in comment is clicked.
	 */
	public showDeleteCommentConfirmation(comment, post): void {
		this.showCommentDeleteConfirmation = 'block';
		this.deleteSelectedComment = comment;
		this.deleteCommentSelectedPost = post;
	}
	/**
	 * Function to show the delete confirmation model when delete label in comment is clicked.
	 */
	public hideCommentDeleteConfirmation(): void {
		this.showCommentDeleteConfirmation = 'none';
		this.deleteSelectedComment = null;
		this.deleteCommentSelectedPost = null;
	}

	/**
	 * Function to delete the comment on confirming comment delete.
	 */
	public onCommentDeleteConfirmation(): void {
		if (this.deleteSelectedComment) {
			this.commentService.deleteComment(this.deleteSelectedComment['uid']).subscribe(() => {
				const index = this.posts.indexOf(this.deleteCommentSelectedPost);
				this.posts[index].commentCount = this.posts[index].commentCount - 1;
				if (this.posts[index]['lastComments'].uid === this.deleteSelectedComment.uid) {
					delete this.posts[index]['lastComments'];
				} else {
					delete this.posts[index]['lastComments'].parent;
				}
				this.hideCommentDeleteConfirmation();
			});
		}
	}

	/**
	 * -------------------------------Functions for issue actions in issue starts------------------------------------------->
	 */

	// Function for executing issue actions
	private onIssueActionsClick(post, value, index): void {
		this.updateAttachmentDetails(post);
		this.pageLoadingUiService.setPageLoading(true);
		this.model.title = post.title;
		this.model['description'] = post.description;
		this.model.state = post.state;
		this.model.type = 'issue';
		this.model.status = value;
		this.model.shareable = post.shareable;
		this.model.commentable = post.commentable;
		this.model['collection_post'] = '1';
		this.model['estimate'] = this.estimatedTime;
		this.model['assignedUser'] = this.currentUser.username;
		this.model.category = 'bugs';
		this.newComment.contentType = 'issue';
		this.newComment.contentUid = post.uid;
		if (post.priority) {
			this.model['priority'] = post.priority;
		}
		this.posts.splice(index, 1);
		this.postService.updateIssue(this.model, post.uid).subscribe((data) => {
			delete this.model['images'];
			delete this.model['attachmentType'];
			delete this.model['doc'];
			delete this.model['url'];
			delete this.model['location'];
			if (value === 'open') {
				this.newComment['text'] = this.translate.instant('moveToOpenText', {
					opens: data.numberOfOpens,
				});
			} else if (value === 'pool') {
				this.newComment['text'] = this.translate.instant('Move to Pool Notes: Issue is on hold');
			} else if (value === 'fixed') {
				this.newComment['text'] = this.translate.instant('moveToFixedText', {
					developmentTime: data.developmentTime,
				});
			} else if (value === 'closed') {
				this.newComment['text'] = this.translate.instant('moveToClosedText', {
					time: data.elapsedTime,
				});
			} else if (value === 'released') {
				this.newComment['text'] = this.translate.instant('moveToReleasedText', {
					version: this.versionNumber,
				});
			} else {
				this.newComment['text'] = this.translate.instant('moveToInProgressText', {
					user: this.currentUser.name,
					estimate: this.estimatedTime,
				});
			}
			this.commentService.create(this.newComment, this.currentUser.username).subscribe((commentData) => {
				if (post.comments) {
					post.comments.unshift(new Comment(commentData));
				} else {
					post.comments = [new Comment(commentData)];
				}
				if (post['lastComments']) {
					post['lastComments'][0] = new Comment(commentData);
				} else {
					post['lastComments'] = [];
					post['lastComments'].push(new Comment(commentData));
				}
				if (post['commentCount']) {
					post['commentCount'] = +post['commentCount'] + 1;
				} else {
					post['commentCount'] = 1;
				}
				this.pageLoadingUiService.setPageLoading(false);
			});
		});
	}

	/**
	 * Pass attachment details on a post on issue actions
	 * @param post
	 */
	private updateAttachmentDetails(post): void {
		if (post.attachmentDetails.gallery && post.attachmentDetails.gallery.length > 0) {
			this.model['images'] = [];
			const images: any[] = [];
			post.attachmentDetails.gallery.forEach((img) => {
				this.model.attachmentType = 'photo';
				images.push(img.image.uid);
			});
			this.model['images'] = images.join('|');
		}
		if (post.attachmentDetails.location && post.attachmentDetails.location.length > 0) {
			this.model.attachmentType = 'location';
			this.model['location'] = post.attachmentDetails.location[0].uid;
		}
		if (post.attachmentDetails.doc && post.attachmentDetails.doc[0].uid) {
			this.model.attachmentType = 'doc';
			this.model['doc'] = post.attachmentDetails.doc[0].uid;
		}
		if (post.attachmentDetails.url && post.attachmentDetails.url[0].url) {
			this.model.attachmentType = 'url';
			this.model['url'] = post.attachmentDetails.url[0].url;
		}
	}

	// Shows Estimate time modal for move to in progress action
	public showInProgressModal(post, value, index): void {
		this.selectedPostForInProgress = post;
		this.selectedValueForInProgress = value;
		this.selectedIndexForInProgress = index;
		this.showEstimatedTimeModal = 'block';
		this.estimatedTimeError = '';
		this.estimatedTime = '';
	}

	// Shows Estimate time modal for move to in released action
	private showReleasedModal(post, value, index): void {
		this.selectedPostForReleased = post;
		this.selectedValueForReleased = value;
		this.selectedIndexForReleased = index;
		this.showVersionNumberModal = 'block';
		this.versionNumberError = '';
		this.versionNumber = '';
	}

	// Function for confirming version number in move to released action
	public onConfirmIssueReleased(): void {
		if (!this.versionNumber) {
			this.versionNumberError = 'Please enter a version number';
		} else {
			this.onIssueActionsClick(this.selectedPostForReleased, this.selectedValueForReleased, this.selectedIndexForReleased);
			this.showVersionNumberModal = 'none';
		}
	}

	// Function for canceling version number modal in move to released action
	public onCancelIssueReleased(): void {
		this.showVersionNumberModal = 'none';
	}

	// Function for confirming estimate time in move to in progress action
	public onConfirmIssueInProgress(): void {
		if (!this.estimatedTime) {
			this.estimatedTimeError = 'Please enter an estimate time';
		} else {
			this.onIssueActionsClick(this.selectedPostForInProgress, this.selectedValueForInProgress, this.selectedIndexForInProgress);
			this.showEstimatedTimeModal = 'none';
		}
	}

	// Function for canceling estimate time modal in move to in progress action
	public onCancelIssueInProgress(): void {
		this.showEstimatedTimeModal = 'none';
	}

	// Function for showing delete modal for an issue
	private onIssueDeleteClick(post): void {
		this.showIssueDeleteConfirmation = 'block';
		this.postService.showBackDrop();
		this.deleteSelectedIssue = post;
		this.showBackdrop.subscribe((data) => {
			if (data === 'hide') {
				this.hideIssueDeleteConfirmation();
			}
		});
	}

	// Function for confirming delete for an issue
	public onIssueDeleteConfirm(): void {
		this.postService.deleteIssue(this.deleteSelectedIssue['uid']).subscribe(() => {
			this.posts.splice(this.posts.indexOf(this.deleteSelectedIssue), 1);
			this.hideIssueDeleteConfirmation();
		});
	}

	// Function to cancel delete modal for an issue
	public hideIssueDeleteConfirmation(): void {
		this.deleteSelectedIssue = null;
		this.showIssueDeleteConfirmation = 'none';
		this.postService.hideBackDrop();
	}

	// Function for showing edit modal for issues
	private onIssueEditClick(post, postIndex): void {
		if (post['addTaskForIssue']) {
			post['addTaskForIssue'] = false;
		}
		post['edit'] = true;
		this.editSelectedPost = post;
		this.editSelectedPostIndex = postIndex;
		this.postService.editSelectedPost = post;
	}
	/**
	 * --------------------Functions for issue actions in issue ends----------------------------------------->
	 */

	// To welcome new user.
	public onWelcomePostClick(post): void {
		let memberId;
		if (post.author && post.author.memberId) {
			memberId = post.author.memberId;
		} else {
			memberId = post.attachmentDetails.member.uid;
		}
		this.postService.postWelcome(memberId).subscribe((data) => {
			post.myRelations.welcome = data.uid;
			if (post['myRelations'] && post['myRelations'].welcome && post['welcomeCount']['count'] === 0) {
				post['welcomeText'] = 'You welcomed';
			}
		});
	}

	public hideRemoveFromCollection(): void {
		this.showCollectionRemove = 'none';
		this.collectionRemoveSelectedPost = null;
		this.showCollectionRemoveError = false;
		this.postService.hideBackDrop();
	}

	public onRemoveConfirmation(): void {
		if (this.collectionRemoveSelectedPost && this.collection) {
			this.collectionService.removeFromCollection(this.collection.uid, this.collectionRemoveSelectedPost.uid).subscribe(
				() => {
					const index = this.posts.indexOf(this.collectionRemoveSelectedPost);
					this.posts.splice(index, 1);
					this.hideRemoveFromCollection();
					this.collectionRemoveSelectedPost = null;
				},
				() => {
					this.showCollectionRemoveError = true;
				}
			);
		}
	}

	public getDownloadLink(attachment): string {
		return attachment['url'] + '?cType=' + attachment['type'] + '&file=' + attachment['name'];
	}

	/**
	 * Function to get the department name based on department Id.
	 * Used in template
	 * @param value
	 */
	public getDepartmentNameBasedOnId(value): string {
		const index = this.departments.findIndex((department) => department.uid === value);

		if (index !== -1) {
			return this.departments[index].name;
		}
	}

	// Search in Add to collection modal----//
	public onSearchEnter(event: any): void {
		if (event.keyCode === 13) {
			this.onSearchClick();
		}
	}
	public onSearchClick(): void {
		if (!this.collectionSearchString) {
			this.searchService.initiateSearch(this.collectionSearchString);
		}
		this.collectionService.populateCollection(this.collectionSearchString);
	}

	public onChangeCollectionSearch(): void {
		if (!this.collectionSearchString) {
			this.collectionService.populateCollection(this.collectionSearchString);
		}
	}

	public handleIssueRedirections(post, e): void {
		if (e.target && e.target.className) {
			const type = e.target.className;
			if (type === 'hash-issue') {
				this.hashName = e.target.innerHTML.split('#')[1];
				const hashtags = post['hashtags'];
				hashtags.forEach((tag) => {
					if ((tag.name = this.hashName)) {
						this.router.navigateByUrl('/network/reported-issues-detail?hash_tag=' + tag['uid'] + '&name=' + tag['name']);
					}
				});
			} else {
				const url = `/network/issue-detail?id=${post.uid}`; // 3
				this.router.navigateByUrl(url);
			}
		} else {
			const url = `/network/issue-detail?id=${post.uid}`; // 3
			this.router.navigateByUrl(url);
		}
	}

	public handlePostRedirections(post, e): void {
		if (e.target && e.target.className) {
			const type = e.target.className;
			if (type === 'hash-post') {
				const text = e.target.innerHTML.split('#')[1];
				const hashtags = post['hashtags'];
				hashtags.forEach((tag) => {
					if ((tag['name'] = text)) {
						this.router.navigateByUrl('/network?hash_tag=' + tag['uid'] + '&name=' + tag['name']);
					}
				});
			}
		}
	}

	private getPostsByHashtags(uid): void {
		this.posts = [];
		this.postFetched = false;
		this.postService.getPostsByHash(uid).subscribe(
			(postData) => {
				this.postFetched = true;
				this.showSpin = false;
				if (postData.next) {
					this.nextUrl = postData.next.split('alpha')[1];
					this.firstTimeNextUrl = JSON.parse(JSON.stringify(postData.next.split('alpha')[1]));
					this.paginate();
				} else {
					this.nextUrl = '';
				}
				this.posts = postData.objects.map((post) => {
					const _post = new Post(post);
					_post['author']['isOnline'] = this.isOnline(_post?.author?.username);
					return _post;
				});
				this.checkUserMentionAndHashtagAndAlterBody();
			},
			() => {
				this.postFetched = true;
			}
		);
	}

	public getIssuesByHashtags(uid): void {
		// TODO: use loading spinner
		this.posts = [];
		this.postFetched = false;

		this.postService.getIssuesByHash(uid).subscribe(
			(postData) => {
				this.postFetched = true;
				this.showSpin = false;

				if (postData.next) {
					this.nextUrl = postData.next.split('alpha')[1];
					this.firstTimeNextUrl = JSON.parse(JSON.stringify(postData.next.split('alpha')[1]));
					this.paginate();
				} else {
					this.nextUrl = '';
				}

				this.posts = postData.objects.map((post) => {
					const _post = new Post(post);
					_post['author']['isOnline'] = this.isOnline(_post?.author?.username);
					return _post;
				});

				this.checkUserMentionAndHashtagAndAlterBody();
			},
			() => {
				this.postFetched = true;
			}
		);
	}

	/**
	 * Function for displaying previous filtered issue
	 * 1.Get all issue posts
	 */
	public onIssueBackClick(): void {
		this.posts = [];
		this.postFetched = false;
		this.issueHashTagPosts = false;
		this.getAllPosts(); // 1
	}

	/**
	 * Function to start a zoom meeting
	 * @param post
	 * 1.Check if zoom is linked for user
	 * 2.Open zoom window from start url
	 * 3.If zoom not linked,open zoom link modal
	 */
	public startZoomMeeting(post): void {
		if (this.currentUser.zoomLinked) {
			// 1
			const url = post.startUrl;
			this.onEventAttendSubmit(post, '1');
			window.open(url, '_blank'); // 2
		} else {
			this.showZoomLinkModal = 'block'; // 3
		}
	}

	/**
	 * Function to join a zoom meeting
	 *
	 * 1.Check if zoom is linked for user
	 * 2.Open zoom window from join url
	 * 3.If zoom not linked,open zoom link modal
	 */
	public joinZoomMeeting(post): void {
		if (this.currentUser.zoomLinked) {
			// 1
			const url = post.joinUrl;
			this.onEventAttendSubmit(post, '1');
			window.open(url, '_blank'); // 2
		} else {
			this.showZoomLinkModal = 'block'; // 3
		}
	}

	/**
	 * Function to open zoom link modal
	 */
	public linkZoomAccount(): void {
		this.showZoomLinkModal = 'none';
		localStorage.setItem('zoom_authentication', 'true');
	}

	public onAddTaskForIssuesClicked(post): void {
		post['addTaskForIssue'] = true;
		this.editSelectedPost = post;
	}

	/**
	 * Function  to check if user has manager cp
	 * 1. Check if local storage contains manager
	 * 2. Set manager uid and display my team filter
	 */
	private checkIfManager(): void {
		if (localStorage.getItem('manager')) {
			const obj = JSON.parse(localStorage.getItem('manager'));
			this.showMyTeam = true;
			this.myTeamUid = obj.uid;
		}
	}

	/**
	 * Function to show post cp names of personal filters/memberships
	 * @param value
	 * 1. If content privacy(value) is status,show it.
	 * 2. If content privacy(value) is renewal status,show it.
	 * 3. Check if content privacy(value) is membershipLevel
	 * 4. Get membership level name from array
	 * 5. If value is manager,show cp as myTeam
	 * 6. If value is in personal filters,show it
	 */
	public getPostNames(value): string {
		if (value.status) {
			return value.status; // 1
		} else if (value.renewalStatus) {
			return value.renewalStatus; // 2
		} else if (value.membership_level) {
			// 3
			let name = '';
			this.selectByLevelValues.forEach((object) => {
				if (object.uid === value.membership_level) {
					name = object.name; // 4
				}
			});
			return name;
		} else if (value.manager) {
			return this.translate.instant('My Team'); // 5
		} else {
			this.value = Object.values(value);
			const index = this.personalFilterValues.findIndex((field) => field.value === this.value);
			if (index !== -1) {
				return this.personalFilterValues[index].valueLabel; // 6
			}
		}
	}

	/**
	 * Function for redirection to  buy sell detail page
	 * @param event
	 * @param post
	 * 1. Set navigation url
	 * 2. Redirect to buy sell detail page
	 */
	public redirectBuySellDetail(event, post): void {
		event.preventDefault();
		if (event.target.className !== 'btn btn-event') {
			const url = `/network/buy-sell/detail?id=${post.uid}`; // 1
			this.router.navigateByUrl(url); // 2
		}
	}

	/**
	 * Function for handling mark as sold on buy sell post
	 * @param post
	 * 1. Set object for api
	 * 2. Call api for sold functionality
	 */
	public markAsSold(post, index): void {
		if (post.attachmentDetails.gallery && post.attachmentDetails.gallery.length > 0) {
			post.attachmentDetails.gallery.forEach((img) => {
				this.item['images'] = img.image.uid;
			});
		}

		if (post.contentPrivacy) {
			this.item['content_privacy'] = JSON.stringify(post['contentPrivacy']);
		} else if (post.channel && post.channel['uid']) {
			this.item['channel'] = post['channel']['uid'];
		} else if (post['customPrivacy'] && post['customPrivacy'].length > 0) {
			const customPrivacy: any[] = [];
			// let custom: any;
			post.customPrivacy.forEach((element) => {
				if (element.userId) {
					customPrivacy.push(element.userId);
				} else {
					customPrivacy.push(element.id);
				}
			});
			const custom = '[' + customPrivacy.join(',') + ']';
			this.item['custom_privacy'] = custom;
		} else {
		}

		if (post.type) {
			this.item.category = post['type']; // 1
			this.item['state'] = 'sold';
			this.pageLoadingUiService.setPageLoading(true);
			this.postService.itemSold(post.uid, this.item).subscribe((data) => {
				// 2
				delete this.item['content_privacy'];
				delete this.item['channel'];
				delete this.item['custom_privacy'];
				this.posts[index] = data;
				this.pageLoadingUiService.setPageLoading(false);
			});
		}
	}

	public onResellItem(post, index): void {
		post['isResell'] = true;
		if (post.type === 'postTodo') {
			post['type'] = '';
		}
		post['edit'] = true;
		this.editSelectedPost = post;
		this.editSelectedPostIndex = index;
		this.postService.editSelectedPost = post;
	}

	/**
	 * Function for setting subscriptions for displaying task/issues/customer forms
	 * @param slug
	 */
	public displayFormModals(slug): void {
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});

		if (slug === 'task') {
			this.networkService.setForm('task');
		} else if (slug === 'issue') {
			this.networkService.setForm('issue');
		} else {
			this.networkService.setForm('customer');
		}
	}

	public handleIssueLinkShare(post): void {
		let text;
		const shareUrl = post?.shareUrl ? `${environment.appURL}?share_url=${post?.shareUrl}&nid=${post?.uid}` : '';

		if (post['title'] && post['description']) {
			text = `${post.title.substring(0, 80)}.
      ${post.description.substring(0, 110)}....
      ${shareUrl}`;
		} else if (post['title'] && !post['description']) {
			text = `${post.title.substring(0, 80)}.
      ${shareUrl}`;
		} else if (!post['title'] && post['description']) {
			text = `${post.description.substring(0, 110)}....
      ${shareUrl}`;
		} else {
			text = shareUrl;
		}

		this.clipboardService.copy(text);
	}

	public onPostReport(): void {
		if (this.flag['value']) {
			this.pageLoadingUiService.setPageLoading(true);
			const obj = {
				contentType: 'post',
				contentUid: this.reportPost['uid'],
				reason: this.flag['value'],
				text: this.flag['text'],
			};

			this.networkService.reportAnAction(obj).subscribe(
				(data) => {
					this.posts.forEach((post) => {
						if (post.uid === this.reportPost.uid) {
							post.myRelations['flagged'] = data.uid;
						}
					});

					this.resetReports();
					this.reportText = 'Your feedback is reported successfully';
					this.isReported = true;
					this.pageLoadingUiService.setPageLoading(false);
					setTimeout(() => {
						this.isReported = false;
					}, 7000);
				},
				() => {
					this.resetReports();
					this.pageLoadingUiService.setPageLoading(false);
				}
			);
		}
	}

	public resetReports(): void {
		this.reportPost = '';
		this.flag['value'] = '';
		this.flag['text'] = '';
		this.reportReasons = [];
		this.reportModal = 'none';
	}

	private checkForCustomerSettings(network): void {
		if (network && network.privacySettings) {
			if (network.privacySettings['customer']['department']) {
				const fields = network['membership']['memberRole']['fields'];

				const arr = network.privacySettings['customer']['department'];
				if (arr.length > 0 && fields.length > 0) {
					arr.forEach(() => {
						fields.forEach((field) => {
							if (field['key'] === 'department') {
								const val = field['value'];
								const index = arr.findIndex((a) => a === val);
								if (index > -1) {
									this.addCustomer = true;
								} else {
									this.addCustomer = false;
									// this.displayWelcomeTips = false;
								}
							}
						});
					});
				}
			}
		}
	}

	public handleNetworkLinkShare(): void {
		const shareUrl = this.network['shareUrl'] ? `${environment.appURL}?share_url=${this.network['shareUrl']}&nid=${this.network['uid']}` : '';

		const text = this.translate.instant('networkInvite', {
			username: this.currentUser['name'],
			networkName: this.network['name'],
			inviteLink: shareUrl,
		});

		this.clipboardService.copy(text);
	}

	/**
	 * On selecting reminder options
	 * @param reminder
	 */
	public onSelectReminder(reminder): void {
		const index = this.remindersSelected.findIndex((r) => r === reminder);
		if (index > -1) {
			this.remindersSelected.splice(index, 1);
		} else {
			this.remindersSelected.push(reminder);
		}
	}

	/**
	 * For resetting reminders in reminder options modal
	 */
	public resetReminders(): void {
		this.postForReminder = '';
		this.displayReminderModal = 'none';
		this.timeFor = 0;
		this.remindersSelected = [];
		delete this.reminderModel['eventReminderOption'];
		this.reminderModel = {
			contentType: '',
			contentUid: '',
			repeat: true,
			reminderOption: '',
			username: '',
		};
		this.reminderOptions.forEach((r) => {
			r['checked'] = false;
		});
	}

	/**
	 * For creating a new reminders for content
	 */
	public setReminder(): void {
		if (this.remindersSelected.length > 0) {
			const object = {};
			this.remindersSelected.forEach((reminder) => {
				object[reminder] = this.setReminderDate(reminder);
			});

			const param = JSON.stringify(object);
			this.reminderModel.contentUid = this.postForReminder.uid;
			this.reminderModel.username = this.currentUser.username;
			this.reminderModel.repeat = true;

			if (this.postForReminder['type'] === 'issue') {
				this.reminderModel.contentType = 'issue';
			} else {
				this.reminderModel.contentType = 'post';
			}

			if (this.postForReminder['type'] !== 'events') {
				this.reminderModel.reminderOption = param;
			} else {
				delete this.reminderModel['reminderOption'];
				this.reminderModel['eventReminderOption'] = param;
			}

			this.pageLoadingUiService.setPageLoading(true);
			this.networkService.createReminder(this.reminderModel).subscribe((data) => {
				this.postForReminder['myRelations']['reminder'] = data['uid'];
				this.pageLoadingUiService.setPageLoading(false);
				this.resetReminders();
				this.reminderToast = true;
				setTimeout(() => {
					this.reminderToast = false;
				}, 8000);
			});
		} else {
			if (this.postForReminder['myRelations'] && this.postForReminder['myRelations']['reminder']) {
				this.deleteReminders();
			} else {
				this.resetReminders();
			}
		}
	}

	public deleteReminders(): void {
		this.reminderModel.contentUid = this.postForReminder.uid;
		this.reminderModel.username = this.currentUser.username;
		this.reminderModel.repeat = true;

		if (this.postForReminder['type'] === 'issue') {
			this.reminderModel.contentType = 'issue';
		} else {
			this.reminderModel.contentType = 'post';
		}

		this.pageLoadingUiService.setPageLoading(true);
		this.networkService.createReminder(this.reminderModel).subscribe(() => {
			this.postForReminder['myRelations']['reminder'] = '';
			this.pageLoadingUiService.setPageLoading(false);
			this.resetReminders();
		});
	}

	/**
	 * For calculating corresponding to reminder selected
	 * @param option
	 */
	public setReminderDate(option): string {
		if (option === 'in_twenty_minutes') {
			this.timeFor = 20;
		} else if (option === 'in_one_hour') {
			this.timeFor = 60;
		} else if (option === 'in_three_hours') {
			this.timeFor = 180;
		} else if (option === 'tomorrow') {
			this.timeFor = this.minutesUntilMidnight() + 540;
		} else if (option === 'next_week') {
			this.timeFor = 6300;
		} else if (option === 'event_time') {
			this.timeFor = -1;
		} else if (option === 'five_minutes_before') {
			this.timeFor = 5;
		} else if (option === 'ten_minutes_before') {
			this.timeFor = 10;
		} else if (option === 'fifteen_minutes_before') {
			this.timeFor = 15;
		} else if (option === 'thirty_minutes_before') {
			this.timeFor = 30;
		} else if (option === 'one_hour_before') {
			this.timeFor = 60;
		} else if (option === 'two_hour_before') {
			this.timeFor = 120;
		} else if (option === 'one_day_before') {
			this.timeFor = 1440;
		} else if (option === 'two_day_before') {
			this.timeFor = 2880;
		} else if (option === 'one_week_before') {
			this.timeFor = 10080;
		}

		let date;
		if (this.postForReminder['type'] === 'events' || this.postForReminder['type'] === 'meeting') {
			if (this.timeFor === -1) {
				date = new Date(this.postForReminder.starts);
			} else {
				date = new Date(this.postForReminder.starts);
				date.setMinutes(date.getMinutes() - this.timeFor);
			}
		} else {
			date = new Date();
			date.setMinutes(date.getMinutes() + this.timeFor);
		}

		const month = date.getUTCMonth() + 1;
		const day = date.getUTCDate();
		const year = date.getUTCFullYear();
		const hours = date.getUTCHours();
		const minutes = date.getUTCMinutes();
		const seconds = date.getUTCSeconds();
		const utcDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
		return utcDate;
	}

	/**
	 * Set Previous reminders on reminder options
	 */
	private setPreviousReminders(): void {
		this.reminderOptions.forEach((reminder) => {
			this.remindersSelected.forEach((r) => {
				if (r === reminder[0]) {
					reminder['checked'] = true;
				}
			});
		});
	}

	private minutesUntilMidnight(): any {
		const midnight = new Date();
		midnight.setHours(24);
		midnight.setMinutes(0);
		midnight.setSeconds(0);
		midnight.setMilliseconds(0);
		return (midnight.getTime() - new Date().getTime()) / 1000 / 60;
	}

	public isOnline(username: string): boolean {
		const userArray = this.onlineStack.filter((member: any) => member?.username === username);
		return userArray?.length > 0 ? userArray?.[0]?.isOnline : false;
	}

	public getPostViews(post): void {
		this.viewDialog = 'block';
		this.scrollStatus = false;
		this.postViews = [];
		this.viewNextUrl = '';
		this.isFetching = 0;
		this.postService.getPostViews(post['uid']).subscribe((data) => {
			this.postViews = data.objects;
			this.isFetching = 1;
			if (data.next) {
				this.viewNextUrl = data.next.split('alpha')[1];
				this.paginateViews();
			} else {
				this.viewNextUrl = '';
			}
		});
	}

	public paginateViews(): void {
		if (this.viewNextUrl) {
			if (this.scrollStatus) {
				return;
			}
			this.scrollStatus = true;
			this.postService.paginate(this.viewNextUrl).subscribe((data) => {
				this.postViews = this.postViews.concat(data.objects);
				this.scrollStatus = false;
				if (data.next) {
					this.viewNextUrl = data.next.split('alpha')[1];
				} else {
					this.viewNextUrl = '';
				}
			});
		}
	}

	/**
	 * Function to update the comment count
	 */
	private newCommentCreatedEmitterFn(): void {
		// if(this.selectedPost){
		//   const obj = this.posts.find(li=>li.uid === this.selectedPost.uid)
		//   obj['totalMasterCommentCount'] = obj['totalMasterCommentCount'] + 1;
		// }
	}

	/**
	 * Function to calculate the completed task count progress bar width
	 */
	private calculateCompletedTaskProgressBarWidth(post): string {
		let progressWidth = '0%';

		if (post && post.todolist) {
			const closed = post.todolist.closedTodos,
				totalTask = post.todolist.totalCount;

			if (totalTask > 0 && closed > 0) {
				const c = totalTask / closed,
					width = Math.floor(100 / c);

				progressWidth = width + '%';
			}

			return progressWidth;
		}
	}

	/**
	 * Function to round the decimal number
	 * @param number
	 * @returns
	 */
	public roundDecimalPlace(number): number {
		const value = parseFloat(number);
		return Math.round(value * 100) / 100;
	}

	/**
	 * Function to calculate the completed task count progress bar width for project status update post
	 */
	public calculateProgressBarFoProjectUpdatePost(post): string {
		let progressWidth = '0%';

		if (post && post.project) {
			const closed = post.project.totalCount - post.project.openCount,
				totalCount = post.project.totalCount;

			if (totalCount > 0 && closed > 0) {
				const c = totalCount / closed,
					width = Math.floor(100 / c);

				progressWidth = width + '%';
			}

			return progressWidth;
		}
	}

	/**
	 * Function to show the add task or add issue form
	 * @param $event
	 */
	public onClickMoreOptionsEmitterFn($event): void {
		this.postType = $event.type;
		this.postService.onClickMoreOptionsFromComments.emit($event);
		setTimeout(() => {
			this.layoutService.hideBd();
			this.layoutService.showBd('add-issue');
			window.scroll(0, 0);
		}, 200);
	}

	// #region UI Interactions

	public showInLightbox(post: any): void {
		const images = post.attachmentDetails?.gallery?.map((imageItem: any) => {
			const image = imageItem.image;

			return new ImageAttachment(<IImageAttachment>{
				id: image.uid,
				name: image.name,
				type: image.type,
				url: image.url,
				data: image,
			});
		});

		this.lightboxService.initLightbox(images);
	}

	/**
	 * Function to return the project status bar color
	 *
	 * @param projectDetails
	 * @returns string
	 */
	public projectStatusBarColor(projectDetails: any): string {
		if (projectDetails) {
			if (projectDetails['endDate']) {
				const endDate = new Date(projectDetails['endDate']);

				if (this.currentDate > endDate) {
					if (projectDetails['openCount'] > 0) {
						return '#ff0000';
					} else {
						return 'var(--success-color)';
					}
				} else if (this.currentDate <= endDate) {
					if (projectDetails['openCount'] > 0) {
						return '#ffd306';
					} else if (projectDetails['openCount'] === 0) {
						return 'var(--success-color)';
					}
				}
			}
		}
	}

	public showAttendingUsersModal(post: Post): void {
		this.attendingUsersModal.showAttendingUsersListModal(post);
	}

	/**
	 * Intermidatory between post-create => [post-list] => post
	 *
	 * @param type Modal Type
	 */
	public selectPostOption(type: string): void {
		this.modalTypeEmitter.emit(type);
	}

	/**
	 * Function for closing all welcome tips
	 *
	 * 1. Set welcome-tip in Localstorage
	 * 2. Reset all welcome tips variables
	 */
	public closeWelcomeTips(): void {
		localStorage.setItem('welcome-tips', 'closed'); // 1

		this.displayWelcomeTips = false; // 2
		this.isWelcomeTip = false;
		this.isTaskTip = false;
		this.isIssueTip = false;
		this.isCustomerTip = false;
	}

	public onImgError(event: any): void {
		event.target.src = 'assets/images/default_avatar.jpg';
	}

	// #endregion

	// #region Context Menu

	@ViewChild('postItemContextMenu')
	public postItemContextMenu: ContextMenuComponent;

	public contextMenuSelectedPost: Post = null;
	public contextMenuSelectedPostIndex: number;

	public onPostItemContextMenuOpen(event: MouseEvent, post: Post, index: number): void {
		event.preventDefault();
		event.stopPropagation();

		this.contextMenuSelectedPost = post;
		this.contextMenuSelectedPostIndex = index;

		if (post.postType === 'event') {
			if (post.starts) {
				const currentDate = new Date(),
					eventStartTime = new Date(post.starts);

				this.showSetReminderOption = currentDate > eventStartTime;
			}
		}

		timer(150).subscribe(() =>
			this.contextMenuService.show.next({
				contextMenu: this.postItemContextMenu,
				event,
				item: null,
			})
		);
	}

	public onAddTaskForPostsClicked(): void {
		const post: Post = this.contextMenuSelectedPost;
		post.type = 'postTodo';

		this.editSelectedPost = post;
	}

	public onGenerateActionsListClick(): void {
		this.router.navigate(['/network/generate-actions', this.contextMenuSelectedPost.uid]);
	}

	public onAddToCollectionClick(): void {
		this.showCollectionModal = true;
		this.addToCollectionSelectedPost = this.contextMenuSelectedPost;

		this.collectionService.collection.subscribe(() => {
			this.collections = this.collectionService.sortedCollectionSubject.value;

			if (this.collections['myCollections'].length === 0 && this.collections['followingCollections'].length === 0 && this.collections['followingCollections'].length === 0) {
				this.collectionService.populateCollection();
			}
		});
	}

	public onClickRemoveFromCollection(): void {
		this.collectionRemoveSelectedPost = this.contextMenuSelectedPost;
		this.showCollectionRemoveError = false;
		this.showCollectionRemove = 'block';
	}

	public displayReminders(post: Post): void {
		this.postForReminder = post || this.contextMenuSelectedPost;
		this.displayReminderModal = 'block';
		this.reminderOptions = [];

		this.networkService.getReminderOptions().subscribe((data) => {
			this.reminderOptions = this.contextMenuSelectedPost['type'] !== 'events' ? data.reminderOptions : data.eventReminderOptions;

			if (this.remindersSelected.length > 0) {
				this.setPreviousReminders();
			}
		});
	}

	public getReminderDetails(): void {
		const type = this.contextMenuSelectedPost.type === 'issue' ? 'issues' : 'posts';

		this.pageLoadingUiService.setPageLoading(true);
		this.networkService.getContentReminders(type, this.contextMenuSelectedPost['uid']).subscribe((data: any) => {
			this.pageLoadingUiService.setPageLoading(false);
			this.displayReminders(this.contextMenuSelectedPost);

			if (data.objects && data.objects.length > 0) {
				data.objects.forEach((element: any) => {
					if (element['reminderOption']) {
						this.remindersSelected.push(element['reminderOption']);
					} else if (element['eventReminderOption']) {
						this.remindersSelected.push(element['eventReminderOption']);
					}
				});
			}

			this.setPreviousReminders();
		});
	}

	/**
	 * Copy post title and description to clipboard with share url
	 *
	 * @return void
	 */
	public handleLinkShare(): void {
		const post: any = this.contextMenuSelectedPost;

		let text;
		const shareUrl = post?.shareUrl ? `${environment.appURL}?share_url=${post?.shareUrl}&nid=${post?.uid}` : '';

		if (post['title'] && post['body']) {
			text = `${post.title.substring(0, 80)}. \n${post.body.substring(0, 110)}.... \n${shareUrl}`;
		} else if (post['title'] && !post['body']) {
			text = `${post.title.substring(0, 80)}. \n${shareUrl}`;
		} else if (!post['title'] && post['body']) {
			text = `${post.body.substring(0, 110)}.... \n${shareUrl}`;
		} else {
			text = shareUrl;
		}

		this.clipboardService.copy(text);
	}

	public onMutePost(): void {
		this.pageLoadingUiService.setPageLoading(true);

		this.profilesService.mutePost(this.contextMenuSelectedPost.uid, 'post').subscribe((data: any) => {
			if (data.uid) {
				this.contextMenuSelectedPost['myRelations']['muted'] = data.uid;
			}

			this.pageLoadingUiService.setPageLoading(false);
		});
	}

	public onUnMutePost(): void {
		this.pageLoadingUiService.setPageLoading(true);

		this.profilesService.unMutePost(this.contextMenuSelectedPost.myRelations.muted).subscribe(
			() => {
				this.contextMenuSelectedPost['myRelations']['muted'] = '';
				this.pageLoadingUiService.setPageLoading(false);
			},
			() => {
				this.contextMenuSelectedPost['myRelations']['muted'] = '';
				this.pageLoadingUiService.setPageLoading(false);
			}
		);
	}

	public handlePostReport(): void {
		this.isReported = false;

		if (this.contextMenuSelectedPost['myRelations'] && !this.contextMenuSelectedPost['myRelations']['flagged']) {
			this.reportModal = 'block';
			this.flag['value'] = '';
			this.flag['text'] = '';
			this.reportReasons = [];
			this.reportPost = this.contextMenuSelectedPost;

			this.profilesService.getReasons().subscribe((data) => {
				if (data.post) {
					this.reportReasons = Object.entries(data.post);
				}
			});
		} else {
			this.reportText = 'You have reported already';
			this.isReported = true;

			timer(7000).subscribe(() => {
				this.isReported = false;
			});
		}
	}

	public onPostEditClick(): void {
		if (this.contextMenuSelectedPost.type === 'postTodo') {
			this.contextMenuSelectedPost['type'] = '';
		}

		this.contextMenuSelectedPost['edit'] = true;
		this.editSelectedPost = this.contextMenuSelectedPost;
		this.editSelectedPostIndex = this.contextMenuSelectedPostIndex;
		this.postService.editSelectedPost = this.contextMenuSelectedPost;
	}

	public onPostDeleteClick(): void {
		this.deleteSelectedPost = this.contextMenuSelectedPost;

		this.confirmModal = this.nzModalService.confirm({
			nzTitle: this.translate.instant('POST_LIST.POST_DETAILS.DELETE_CONFIRM_MODAL.TITLE'),
			nzContent: this.translate.instant('POST_LIST.POST_DETAILS.DELETE_CONFIRM_MODAL.DESCRIPTION'),
			nzOnOk: () => {
				this.pageLoadingUiService.setPageLoading(true);

				this.postService
					.deletePost(this.deleteSelectedPost.uid)
					.subscribe(() => {
						this.posts.splice(this.posts.indexOf(this.deleteSelectedPost), 1);
						const posts = this.posts.slice(0, 10);

						// TODO: remove local storage implementations
						localStorage.setItem(`${this.network.uid}-Posts`, JSON.stringify(posts)); // 2
					})
					.add(() => {
						if (this.posts?.length === 0 && this.currentPage === 'events') {
							this.noEvents.emit(true);
						}
						this.pageLoadingUiService.setPageLoading(false);
					});
			},
		});
	}

	// #endregion

	// #region Handle Filter Menu

	/**
	 * Handle filter posts according to filter type
	 * @param event Category Slug
	 */
	public handleFilterChange(event: any): void {
		switch (event?.type) {
			case 'category':
				this.filterPost(event?.value, 'cat');
				break;
			case 'team':
				this.filterPost(event?.value, 'channel');
				break;
			case 'all':
				this.filterPost('', 'all');
				break;
		}
	}

	// #endregion
}
