/* eslint-disable spellcheck/spell-checker */
import { Component, OnInit, Input, EventEmitter, OnChanges, SimpleChanges, Output, OnDestroy } from '@angular/core';
import { NetworkService, MemberService, PostService, UserService, LayoutService } from '../services';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { MixPanelService } from '@core/services/third-party/mix-panel.service';
import { SegmentAnalyticsService } from '@core/services/third-party/segment-analytics.service';

@Component({
	selector: 'app-privacy',
	templateUrl: './privacy.component.html',
	styleUrls: ['./privacy.component.css'],
})
export class PrivacyComponent implements OnInit, OnChanges, OnDestroy {
	@Input() public totalMembersCount = 0;
	@Input() public currentPage = '';
	@Input() public model;
	@Input() public isPostPrivacy = false;
	@Input() public privacy_settings = 'none';
	@Input() public members: any[] = [];
	@Input() public privacyType = 'post_creation';
	@Input() public custom_privacy: any[] = [];
	@Input() public role;
	@Output() public resetCurrentNetworkDefaultCp: EventEmitter<any> = new EventEmitter();
	@Output() public resetPrivacyComponent: EventEmitter<any> = new EventEmitter();
	@Output() public refresh: EventEmitter<any> = new EventEmitter();
	@Output() public productsEmitter: EventEmitter<any> = new EventEmitter();
	@Output() public emitCurrentSelectedProjectCp: EventEmitter<any> = new EventEmitter();
	@Output() public projectDeselectedEmitter: EventEmitter<any> = new EventEmitter();
	public nextUrl;
	public newDate = new Date();
	public channels;
	public scrollStatus = false;
	public myTeam: any;
	public showMyTeam = false;
	public roles;
	public selectedRole;
	public subscriptionPlan: any;
	private currentDepartmentSubscriber;
	private departmentsReady;
	public productObject;

	public archivedCounts: any;
	public isArchived = false;
	public archivedProducts = [];
	public isProcessing: any;

	public isRoleChanged = false;
	public isPersonalFilChanged = true;
	public isMembershipChanged = false;
	public isChannelChanged = false;
	public isDepartmentChanged = false;
	public isRolePriChanged = false;

	public membership;
	public departments: any[] = [];
	public selectedDepartment = '';
	public networkLoaded;
	public network;
	public nextMembersUrl;
	public custom_privacy_display = false;
	public showMembers = true;
	public showRoles = true;
	public showChannels = true;
	public showChannelMenu = true;
	public showPrivate = false;
	public selectedChannel = 'All';
	public showRoleMenu = false;
	public departmentDisplay = '';
	public showDepartmentMenu = false;
	public selectedDepartmentLabel = '';
	public isPrivate = false;
	public tempHideRole = false;
	/** ----Personal filter------- */
	public showSimilarToYouMenu = false;
	public hideChannnel = false;
	public fields;
	public memberType;
	public personalFilter = '';
	public hideField = true;
	public hideChannel;
	public channel = '';
	public showPersonalFilter = true;
	public currentSelection: any;
	public previousSelection: any;
	public personalFilterValue;
	public defaultCP;
	public currentUserId;
	public currentUser;
	public currentUserMemberData;
	public currentUserDepartment;
	public onlyMeSelected = false;
	public personalFilterAll = false;
	public roleSelected = false;
	public hideRole = false;
	public roleName;
	public networkId;
	public lastPrivacyObject;
	// -Memberships cp----
	public showMembership = false;
	public showSelectByStatus = false;
	public status: any[];
	public renewalFrequency: any[];
	public selectByStatusValue;
	public selectByRenewalValue;
	public showSelectByRenewal = false;
	public showSelectByLevel = false;
	public subscriptionTemplates;
	public selectByLevelValue;
	public membershipSelected = false;
	public showMemberships = true;
	public hideSelectByLevel = false;
	// For add an issue---
	public selectProduct_settings = 'none';
	public products: any[] = [];
	public networkUid;
	public selectedProduct: any = [];
	public productSlug;
	public productSelected = '';
	// Use this to store the custom privacy from the post edit component (Contains only User Ids).
	public customPrivacyUserIds: any[] = [];
	public selectedchannelUid: any;
	public displayNewProductsModal = 'none';
	public forNames;
	public isNewProductForm = false;
	public product = { product: '' };
	public productName;
	public productError;
	public isExternalUser = false;
	public multipleExternalUser = false;
	private currentSubscriber;
	private networkReady;

	public editSelectedProduct;
	public editSelectedProductIndex: any;
	public showEditBtn = false;
	public personalFilters = [];
	public showDeleteConfirmation = 'none';
	public selectedProductIndex: any;
	public productDepartments = [];

	// Projects variables
	public displayProjectsModal = 'none';
	public displayProjectError = 'none';
	public projectNameErr = false;
	public projectDateErr = false;
	public displayDeleteModal = 'none';
	public selectedProjectIndex;
	public selectedProject;
	public projectUid;
	public modelText;
	public projectStartDate: any;
	public projectEndDate: any;
	public projectName;
	public projects = [];
	public project_next_url;
	public projectSelected;
	public project = { name: '' };
	public isSpining = 0;
	public createProject = 'none';
	public isNewProjectForm = false;
	public projectError = false;
	public currentProject;
	public editSelectedProject;
	public editSelectedProjectIndex;
	public issueProducts = [];

	public placement = 'right';
	public modelB = {
		year: this.newDate.getFullYear(),
		month: this.newDate.getMonth() + 1,
		day: this.newDate.getDate(),
	};
	public currentDate = {
		year: this.newDate.getFullYear(),
		month: this.newDate.getMonth() + 1,
		day: this.newDate.getDate(),
	};

	private isPrivacyActiveSubject = new BehaviorSubject<any>(false);
	public isPrivacyActive = this.isPrivacyActiveSubject.asObservable();

	public privacyModalStatus: EventEmitter<any> = new EventEmitter();

	public errorModal?: NzModalRef;

	constructor(
		private networkService: NetworkService,
		private memberService: MemberService,
		private parserFormatter: NgbDateParserFormatter,
		private postService: PostService,
		private userService: UserService,
		private layoutService: LayoutService,
		private translate: TranslateService,
		private pageLoadingUiService: PageLoadingUiService,
		private nzModalService: NzModalService,
		private translateService: TranslateService,
		private mixPanelService: MixPanelService,
		private segmentService: SegmentAnalyticsService
	) {}

	ngOnInit(): void {
		this.hideRole = true;

		if (localStorage.getItem('network_id')) {
			this.networkId = localStorage.getItem('network_id');
		}

		this.networkReady = this.networkService.networkReady.subscribe((networkLoaded) => {
			this.networkLoaded = networkLoaded;
			if (networkLoaded) {
				this.currentSubscriber = this.networkService.currentNetwork.subscribe((data) => {
					this.networkUid = data.uid;
					this.subscriptionTemplates = data['SubscriptionTemplates'];
					if (this.subscriptionTemplates && this.subscriptionTemplates.length > 0) {
						this.hideSelectByLevel = false;
					} else {
						this.hideSelectByLevel = true;
					}

					if (data['membership'] && data['membership']['memberRole'] && data['membership']['memberRole']['role']) {
						const memberRole = data['membership']['memberRole']['role'];
						if (memberRole.slug === 'external') {
							this.isExternalUser = true;
							const externalChannels = [];
							data.channels.forEach((channel) => {
								if (channel['access'] === 4) {
									externalChannels.push(channel);
								}
							});
							if (externalChannels.length > 1) {
								this.updateSelectionFields();
								this.multipleExternalUser = true;
							}
						}
					}

					// Get personal filters-----
					if (data.membership && data.membership.memberRole) {
						this.fields = data['membership']['memberRole']['fields'];
						this.memberType = data['membership']['memberType'];
						this.defaultCP = data['membership']['memberRole']['defaultContentPrivacy'];
						this.checkNetworkSubscription();

						this.fields.forEach((field) => {
							if (this.currentPage !== 'collection') {
								if (!localStorage.getItem(`${this.networkId}`)) {
									if (this.defaultCP === field.key) {
										this.forNames = field.valueLabel;
									}
								}
							}
						});

						this.fields.forEach((value) => {
							if (value.CP === true) {
								this.personalFilters.push(value);
							}
						});
					}
					this.network = data;
					if (this.network && this.network.roles) {
						if (this.network.roles.length === 1) {
							this.hideRole = true;
						}
					}
					this.membership = data['membership'];
					this.roles = data['roles'];
					this.channels = data['channels'];

					this.userService.currentUser.subscribe((userData) => {
						this.currentUserId = userData.userId;
						this.currentUser = userData;
					});
					if (this.selectedchannelUid) {
						const index = this.channels.findIndex((channel) => channel.uid === this.selectedchannelUid);
						if (index > -1) {
							this.selectedChannel = this.channels[index].name;
						}
					}
					if (data['membership'] && data['membership']['memberType'] === 'normal') {
						this.departments = [];
						data['membership']['memberRole']['fields'].forEach((element) => {
							if (element.type === 'department') {
								this.showDepartmentMenu = true;
								this.departments.push(element);
							}
						});
					}

					if(this.membership?.memberRole?.fields){
						this.currentUserDepartment  = this.membership.memberRole.fields.find(f => f.type === "department");
					}

				});

				this.departmentsReady = this.networkService.departmentsReady.subscribe((departmentsLoaded) => {
					if (departmentsLoaded) {
						this.currentDepartmentSubscriber = this.networkService.currentDepartments.subscribe((departments) => {
							this.productDepartments = departments.objects;
							if (this.network && this.network['membership'] && this.network['membership']['memberType'] === 'admin') {
								this.departments = this.productDepartments;
							}
						});
					}
				});
			}
		});

		switch (this.privacyType) {
			case 'post_creation':
				this.showMembers = true;
				this.showRoles = true;
				this.showChannels = true;
				break;
			case 'channel-creation':
				this.showMembers = true;
				this.showRoles = true;
				this.showChannels = false;
				break;
			case 'collection_creation':
				this.showPrivate = true;
				this.showMembers = true;
				this.showRoles = true;
				this.showChannels = true;
				break;
			case 'post-edit':
				this.showMembers = true;
				this.showRoles = true;
				this.showChannels = true;
				break;
			default:
				this.showMembers = true;
				this.showRoles = true;
				this.showChannels = true;
				break;
		}
		this.updateDisplayValues();
		this.layoutService.showBackdrop.subscribe((data) => {
			if (data === 'privacy') {
				this.privacy_settings = 'none';
				this.isPrivacyActiveSubject.next(false);
				this.privacyModalStatus.emit('closed');
			}
		});
	}

	ngOnDestroy(): void {
		if (this.currentSubscriber) {
			this.currentSubscriber.unsubscribe();
		}
		if (this.networkReady) {
			this.networkReady.unsubscribe();
		}
		if (this.departmentsReady) {
			this.departmentsReady.unsubscribe();
		}
		if (this.currentDepartmentSubscriber) {
			this.currentDepartmentSubscriber.unsubscribe();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.hasOwnProperty('custom_privacy')) {
			if (this.custom_privacy.length > 0) {
				this.getMembers();
				this.showCustomPrivacy();
			}
		}
	}

	/**
	 * Function to load the members of current network
	 */
	public getMembers(): void {
		if (this.members.length === 0) {
			this.memberService.getMembers(localStorage.getItem('network_id'), this.nextMembersUrl).subscribe((data) => {
				data.objects.forEach((member) => {
					this.members.push(member);
					if(member?.user?.username === this.currentUser.username){
						this.currentUserMemberData = member;
					}
				});
				this.totalMembersCount = data.count;
				if (data.next) {
					this.nextMembersUrl = data.next.split('alpha')[1];
					this.paginateMembers();
				} else {
					this.nextMembersUrl = '';
				}
				if (this.customPrivacyUserIds.length > 0) {
					this.setPostMembers();
				}
			});
		}
	}

	public paginateMembers(): void {
		if (this.nextMembersUrl) {
			this.memberService.paginate(this.nextMembersUrl).subscribe((data) => {
				if (data.objects.length > 0) {
					this.members = this.members.concat(data.objects);
				}
			});
		}
	}

	private setPostMembers(): void {
		if (this.members.length > 0) {
			this.members.forEach((member) => {
				this.customPrivacyUserIds.forEach((m) => {
					if (member['user']['userId'] === m.id) {
						member['selected'] = true;
					}
				});
			});
		}
	}

	/**
	 *Function to hide the privacy modal
	 */
	public onCloseHandled(): void {
		// this.layoutService.hideBd();
		this.privacy_settings = 'none';
		this.isPrivacyActiveSubject.next(false);
		this.privacyModalStatus.emit('closed');
		this.showSimilarToYouMenu = false;
		this.showDepartmentMenu = false;
		this.showChannelMenu = false;
		this.showMembership = false;
		this.showSelectByStatus = false;
		this.showSelectByRenewal = false;
		this.showSelectByLevel = false;
		if (this.isNewProductForm) {
			this.displayNewProductsModal = 'block';
			this.selectProduct_settings = 'block';
			this.updatePrivacyStringBasedOnPrivacyComponent();
		}
		if (this.isNewProjectForm) {
			this.createProject = 'block';
			this.displayProjectsModal = 'block';
			this.updatePrivacyStringBasedOnPrivacyComponent();
		}
	}

	/**
	 *Function to show the privacy modal
	 */
	public showPrivacy(type = null): void {
		if (type) {
			this.privacyType = type;
			if(this.privacyType === 'edit-collection'){
				this.updatePrivacySelectionInfo();
			}
		}
		if (this.isNewProductForm) {
			this.selectProduct_settings = 'none';
			this.displayNewProductsModal = 'none';
		}
		if (this.isNewProjectForm) {
			this.createProject = 'none';
			this.displayProjectsModal = 'none';
		}
		this.layoutService.showBd('privacy');
		this.postService.showBackDrop();
		this.showChannelMenu = true;
		this.getAllDepartments();
		this.privacy_settings = 'block';
		this.privacyModalStatus.emit('show');
		this.isPrivacyActiveSubject.next(true);
		if (this.customPrivacyUserIds.length > 0) {
			this.showCustomPrivacy();
		}
	}

	/**
	 * Date : 09-10-20, Deparment Changes
	 * 1. Get all departments for admin user
	 */
	private getAllDepartments(): void {
		if (this.network && this.network['membership'] && this.network['membership']['memberType'] === 'admin') {
			this.showDepartmentMenu = true;
			this.departmentsReady = this.networkService.departmentsReady.subscribe((departmentsLoaded) => {
				if (departmentsLoaded) {
					this.currentDepartmentSubscriber = this.networkService.currentDepartments.subscribe((departments) => {
						this.departments = departments.objects;
					});
				}
			});
		}
	}

	/**
	 *Function to show custom privacy selection list.
	 */
	public showCustomPrivacy(): void {
		if (this.members.length === 0) {
			this.getMembers();
		}
		this.custom_privacy_display = !this.custom_privacy_display;
	}

	public onlyMeSelect(): void {
		this.isPersonalFilChanged = true;
		this.isChannelChanged = false;
		this.isDepartmentChanged = false;
		this.isMembershipChanged = false;

		this.showMemberships = false;
		this.custom_privacy = [];
		this.onSimiarShowClick();
		this.hideChannel = true;
		this.hideRole = true;
		this.selectedDepartment = '';
		this.role = '';
		this.selectedRole = '';
		this.personalFilter = '';
		this.resetCurrentNetworkDefaultCp.emit();
		this.onlyMeSelected = true;
		this.personalFilterAll = false;
		this.personalFilter = '';
		this.currentSelection = this.translate.instant('Only Me');
		this.personalFilterValue = this.translate.instant('Only Me');
		const index = this.custom_privacy.findIndex((m) => m.userId === this.currentUser.userId);
		if (index < 0) {
			this.custom_privacy.push(this.currentUser);
		}
	}

	/**
	 * Function executes when a member is selected or removed.
	 */
	public onCustomPrivacySelect(member): void {
		if (this.checkMemberInCustomPrivacy(member)) {
			const index = this.getCustomPrivacyIndex(member);
			if (index !== -1) {
				this.custom_privacy.splice(index, 1);
			}
		} else {
			this.custom_privacy.push(member);
		}
		this.resetCurrentNetworkDefaultCp.emit();
		this.updatePrivacySelectionInfo();
		this.selectedDepartment = '';
		this.role = '';
		this.selectedRole = '';
		this.channel = '';
		this.personalFilter = '';
		this.selectByStatusValue = '';
		this.selectByRenewalValue = '';
		this.selectByLevelValue = '';
		this.isPrivate = false;
	}

	/**
	 *Function executes when a sub network changes.
	 */
	public onChannelChange(selectedValue): void {
		this.isChannelChanged = true;
		this.isRolePriChanged = false;
		this.isDepartmentChanged = false;
		this.isMembershipChanged = false;
		this.onchannelShowClick();
		if (selectedValue === '') {
			this.showMemberships = true;
			this.currentSelection = this.translate.instant('All');
			this.selectedChannel = '';
			this.channel = '';
			this.tempHideRole = false;
			this.model.channel = '';
			this.showPersonalFilter = true;
		} else {
			this.showMemberships = false;
			this.channel = selectedValue;
			// this.showPersonalFilter = false;  //16-07-21 channel selection change
			this.currentSelection = selectedValue.name;
			// this.tempHideRole = true;         //16-07-21 channel selection change
			this.model.channel = selectedValue.uid;
			this.selectedChannel = selectedValue.name;
		}
		this.selectByStatusValue = '';
		this.selectByRenewalValue = '';
		this.selectByLevelValue = '';
		this.selectedDepartment = '';
		this.role = '';
		this.selectedRole = '';
		this.personalFilter = '';
		this.custom_privacy = [];
		this.model.content_privacy = undefined;
		this.model.custom_privacy = [];
		this.custom_privacy_display = false;
		this.isPrivate = false;
		this.updatePrivacySelectionInfo();
	}

	/**
	 * Function called when role changes
	 */
	public onRoleChange(selectedRole): void {
		this.isRolePriChanged = true;
		this.isChannelChanged = false;
		this.isDepartmentChanged = false;
		this.isMembershipChanged = false;
		this.onRoleShowClick();
		this.resetCurrentNetworkDefaultCp.emit();
		this.role = selectedRole;
		this.selectedRole = selectedRole;
		this.roleSelected = true;
		if (selectedRole) {
			this.currentSelection = this.role.name;
		} else {
			this.currentSelection = this.translate.instant('All');
		}
	}

	/**
	 * Function to update the display values
	 */
	private updateDisplayValues(): void {
		if (!this.isEmpty(this.model.channel)) {
			// TODO: ensure that the channel is not empty
			const index = this.channels?.findIndex((e) => e.uid === this.model.channel);

			if (index > -1) {
				this.isChannelChanged = true;
				this.selectedChannel = this.channels[index].name;
				this.tempHideRole = true;
			} else {
				this.selectedChannel = this.translate.instant('All');
				this.tempHideRole = false;
			}
			this.role = null;
			this.selectedRole = null;
		}
	}

	private isEmpty(obj): boolean {
		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				return false;
			}
		}
		return true;
	}

	/**
	 * Function to show/hide the channel details
	 */
	public onchannelShowClick(): void {
		this.showChannelMenu = !this.showChannelMenu;
	}

	/**
	 * -----Function which shows/hides personal filter values
	 */
	public onSimiarShowClick(): void {
		this.showSimilarToYouMenu = !this.showSimilarToYouMenu;
		this.showMembership = false;
	}
	/**
	 * -----Function which shows Personal filter menus
	 */
	public onPersonalFilterSelect(field): void {
		this.isPersonalFilChanged = true;
		this.isChannelChanged = false;
		this.isDepartmentChanged = false;
		this.isMembershipChanged = false;

		this.hideField = false;
		this.onlyMeSelected = false;
		this.onSimiarShowClick();
		if (field) {
			this.showMemberships = false;
			this.role = '';
			this.currentSelection = field.valueLabel;
			this.personalFilter = field;
			this.hideChannel = true;
			this.hideRole = true;
			this.personalFilterAll = false;
			this.resetCurrentNetworkDefaultCp.emit();
		} else {
			if (this.network.roles.length !== 1) {
				this.hideRole = false;
			}
			this.showMembership = false;
			this.showMemberships = true;
			this.onlyMeSelected = false;
			this.currentSelection = this.translate.instant('All');
			this.hideChannel = false;
			this.personalFilter = '';
			this.personalFilterAll = true;
		}
		this.custom_privacy = [];
		this.selectedDepartment = '';
		this.role = '';
		this.selectedRole = '';
		this.channel = '';
		// delete all membership values---//
		this.selectByStatusValue = '';
		this.selectByRenewalValue = '';
		this.selectByLevelValue = '';
		// -----------------------//
	}

	public onPersonalFilterAll(): void {
		if (
			this.role === '' &&
			this.selectedRole === '' &&
			this.selectedDepartment === '' &&
			this.personalFilter === '' &&
			this.custom_privacy.length === 0 &&
			!(this.selectedChannel && this.selectedChannel !== 'All' && this.selectedChannel !== '')
		) {
			this.resetCurrentNetworkDefaultCp.emit();
		}
	}

	public onRoleShowClick(): void {
		this.showRoleMenu = !this.showRoleMenu;
	}

	public onDepartmentShowClick(): void {
		this.showDepartmentMenu = !this.showDepartmentMenu;
	}

	/** Date:09-10-20
	 * Function on selecting a department
	 * @param department
	 */
	public onDepartmentSelect(department): void {
		this.isDepartmentChanged = true;
		this.isRolePriChanged = false;
		this.isChannelChanged = false;
		this.isMembershipChanged = false;
		this.onDepartmentShowClick();
		this.personalFilter = '';
		this.custom_privacy = [];
		this.selectByStatusValue = '';
		this.selectByLevelValue = '';
		this.selectByRenewalValue = '';
		this.selectedRole = '';
		this.selectedChannel = '';
		this.channel = '';

		this.resetCurrentNetworkDefaultCp.emit();
		if (department && department.valueLabel) {
			this.currentSelection = department.valueLabel;
			this.selectedDepartment = department;
		} else if (department.uid && department.name) {
			this.currentSelection = department.name;
			this.selectedDepartment = department;
		} else {
			this.currentSelection = this.translate.instant('All');
			this.selectedDepartment = '';
		}
	}

	public selectCurrentUsersDepartment(): void{
		this.resetPrivacy();
		this.showChannelMenu = false;
		this.currentSelection = this.currentUserDepartment.valueLabel;
		this.selectedDepartment = this.currentUserDepartment;
	}

	private updatePrivacySelectionInfo(): void {
		this.members.forEach((member) => {
			if (this.checkMemberInCustomPrivacy(member)) {
				member['selected'] = true;
			} else {
				member['selected'] = false;
			}
		});
	}

	private getCustomPrivacyIndex(member): number {
		let index = 0;
		let returnValue = -1;
		this.custom_privacy.forEach((selectedMember) => {
			if (selectedMember.user && selectedMember.user.userId === member.user.userId) {
				returnValue = index;
			} else if (selectedMember.id === member.user.userId) {
				returnValue = index;
			}
			index = index + 1;
		});
		return returnValue;
	}

	private checkMemberInCustomPrivacy(member): boolean {
		let returnValue = false;
		this.custom_privacy.forEach((selectedMember) => {
			if (selectedMember.user && selectedMember.user.userId === member.user.userId) {
				returnValue = true;
			} else if (selectedMember.id === member.user.userId) {
				returnValue = true;
			}
		});
		return returnValue;
	}

	/** --------------------Membership cp---------------------------------- */

	public onMembershipClick(): void {
		this.showMembership = !this.showMembership;
		this.networkService.getSelectByStatusRenewalFrequency().subscribe((data) => {
			this.status = data.status;
			this.renewalFrequency = data.renewalStatus;
		});
	}
	//
	public listSelectByStatus(): void {
		if (this.showSelectByRenewal) {
			this.showSelectByRenewal = !this.showSelectByRenewal;
		} else if (this.showSelectByLevel) {
			this.showSelectByLevel = !this.showSelectByLevel;
		}
		this.showSelectByStatus = !this.showSelectByStatus;
	}

	public onClickSelectByStatus(value): void {
		// hide personal filter and custom selection
		// this.hidePersonalFilter = false;
		// this.onlyMeSelected = true;
		// //---
		this.isMembershipChanged = true;
		this.isPersonalFilChanged = false;
		this.isRolePriChanged = false;
		this.isDepartmentChanged = false;
		this.isChannelChanged = false;

		this.personalFilter = '';
		this.membershipSelected = true;
		this.selectByStatusValue = value;
		this.showSelectByStatus = !this.showSelectByStatus;
		this.selectByRenewalValue = '';
		this.selectByLevelValue = '';
		this.currentSelection = value[1];
		//
		this.selectedDepartment = '';
		this.role = '';
		this.selectedRole = '';
		this.channel = '';
		this.selectedChannel = '';
		this.personalFilter = '';
		this.custom_privacy = [];
		// -
	}

	//
	public listSelectByRenewal(): void {
		if (this.showSelectByStatus) {
			this.showSelectByStatus = !this.showSelectByStatus;
		} else if (this.showSelectByLevel) {
			this.showSelectByLevel = !this.showSelectByLevel;
		}
		this.showSelectByRenewal = !this.showSelectByRenewal;
	}

	public onClickSelectByRenewal(value): void {
		this.isMembershipChanged = true;
		this.isPersonalFilChanged = false;
		this.isRolePriChanged = false;
		this.isDepartmentChanged = false;
		this.isChannelChanged = false;

		this.personalFilter = '';
		this.selectByRenewalValue = value;
		this.showSelectByRenewal = !this.showSelectByRenewal;
		//
		this.currentSelection = value[1];
		//
		this.selectByStatusValue = '';
		this.selectByLevelValue = '';
		this.selectedDepartment = '';
		this.role = '';
		this.selectedRole = '';
		this.selectedChannel = '';
		this.channel = '';
		this.personalFilter = '';
		this.custom_privacy = [];
		// -
	}

	//
	public listSelectByLevel(): void {
		this.showSelectByLevel = !this.showSelectByLevel;
		if (this.showSelectByStatus) {
			this.showSelectByStatus = !this.showSelectByStatus;
		} else if (this.showSelectByRenewal) {
			this.showSelectByRenewal = !this.showSelectByRenewal;
		}
	}

	public nClickSelectByLevel(value): void {
		this.isMembershipChanged = true;
		this.isPersonalFilChanged = false;
		this.isRolePriChanged = false;
		this.isDepartmentChanged = false;
		this.isChannelChanged = false;

		this.personalFilter = '';
		this.selectByLevelValue = value;
		this.showSelectByLevel = !this.showSelectByLevel;
		this.selectByStatusValue = '';
		this.selectByRenewalValue = '';
		//
		this.currentSelection = value.name;
		//
		this.selectedDepartment = '';
		this.role = '';
		this.selectedRole = '';
		this.channel = '';
		this.selectedChannel = '';
		this.personalFilter = '';
		this.custom_privacy = [];
		// -
	}

	/**
	 * Function to display products modal
	 */
	private showSelectProduct(): void {
		this.layoutService.showBd('privacy');
		this.postService.showBackDrop();
		this.selectProduct_settings = 'block';
		this.privacyModalStatus.emit('show');
		if (this.postService.productSlug) {
			this.productObject = this.postService.productSlug;
		} else {
			this.productObject = '';
		}
		if (this.products.length === 0) {
			this.getALlProducts();
		}
	}

	/**
	 * Function to get all products in issue management
	 */
	private getALlProducts(): void {
		this.isSpining = 0;
		this.scrollStatus = false;
		this.products = [];
		this.networkService.getAllProducts(this.networkUid).subscribe((data) => {
			this.isSpining = 1;
			this.products = data.objects;

			if (data.next) {
				this.nextUrl = data.next.split('alpha')[1];
				this.paginate();
			} else {
				this.nextUrl = '';
			}

			if (data.objects && data.objects.length > 0) {
				this.products.forEach((value) => {
					if (value.slug === this.postService.productSlug) {
						value['checked'] = true;
					} else {
						value['checked'] = false;
					}
				});
			}
		});
	}

	public paginate(): void {
		if (this.scrollStatus) {
			return;
		}
		this.scrollStatus = true;
		if (this.nextUrl) {
			this.networkService.paginateIssues(this.nextUrl).subscribe((data) => {
				this.products = this.products.concat(data.objects);
				this.scrollStatus = false;
				if (data.next) {
					this.nextUrl = data.next.split('alpha')[1];
				} else {
					this.nextUrl = '';
				}
			});
		}
	}

	/**
	 * Function to display cp values of products in add new product form
	 */
	private displayCpForProducts(): void {
		this.products.forEach((product) => {
			if (product['customPrivacy']) {
				product['productCp'] = 'Custom Selection';
			} else if (product['contentPrivacy']) {
				if (product['contentPrivacy'].role && product['contentPrivacy'].department) {
					const index = this.productDepartments.findIndex((department) => department.uid === product['contentPrivacy'].department);
					if (index > -1) {
						product['productCp'] = this.productDepartments[index]['name'] + ' , ' + product['contentPrivacy'].role;
					}
				} else if (product['contentPrivacy'].role) {
					product['productCp'] = product['contentPrivacy'].role;
				} else if (product['contentPrivacy'].department) {
					const index = this.productDepartments.findIndex((department) => department.uid === product['contentPrivacy'].department);
					if (index > -1) {
						product['productCp'] = this.productDepartments[index]['name'];
					}
				} else if (!product['contentPrivacy'].role || !product['contentPrivacy'].department) {
					const field = Object.keys(product['contentPrivacy']);
					this.personalFilters.forEach((element) => {
						if (element.key === field[0]) {
							product['productCp'] = element.valueLabel;
						}
					});
					if (product['contentPrivacy'].status) {
						product['productCp'] = product['contentPrivacy'].status;
					} else if (product['contentPrivacy'].renewalStatus) {
						product['productCp'] = product['contentPrivacy'].renewalStatus;
					} else if (product['contentPrivacy'].membership_level) {
						const index = this.subscriptionTemplates.findIndex((val) => val.uid === product['contentPrivacy'].membership_level);
						if (index > -1) {
							product['productCp'] = this.subscriptionTemplates[index]['name'];
						}
					} else if (product['contentPrivacy'].manager) {
						product['productCp'] = this.translate.instant('My Team');
					}
				}
			} else if (product['channel'] && product['channel']['uid']) {
				product['productCp'] = product.channel.name;
			} else {
				product['productCp'] = 'Everyone';
			}
		});
	}

	/**
	 * Function to display product delete confirmation dialog
	 * @param product
	 * @param index
	 */
	public onDeleteProductClicked(product, index): void {
		this.selectedProductIndex = index;
		this.showDeleteConfirmation = 'block';
		this.productSelected = product;
	}

	/**
	 * Function to confirm a product deletion
	 */
	public confirmProductDelete(): void {
		this.pageLoadingUiService.setPageLoading(true);
		this.networkService.deleteProduct(this.network.uid, this.productSelected['uid']).subscribe(() => {
			this.showDeleteConfirmation = 'none';
			this.pageLoadingUiService.setPageLoading(false);
			// Remove deleted product
			this.products.splice(this.selectedProductIndex, 1);
		});
	}

	public closeProductsModal(): void {
		this.selectProduct_settings = 'none';
		this.productsEmitter.emit();
	}

	public onSelectProduct(product): void {
		const index = this.selectedProduct.findIndex((p) => p === product['slug']);
		if (index > -1) {
			this.selectedProduct.splice(index, 1);
		} else {
			this.selectedProduct.push(product.slug);
		}
	}

	public selectProductForCustomer(product): void {
		this.selectedProduct = [];
		this.selectedProduct.push(product.slug);
		this.productObject = product.slug;
	}

	/**
	 * For closing projects modal.
	 * 1. Call reset event in post component for resetting privacy values
	 * 2. Display projects list modal
	 * 3. Set selected project for post component
	 */
	public onCloseProjects(): void {
		this.resetPrivacyComponent.emit(); // 1
		this.displayProjectsModal = 'none'; // 2
		this.projectSelected = this.currentProject; // 3
	}

	public onSelectProject(project): void {
		this.emitCurrentSelectedProjectCp.emit(project.channel);
	}

	/**
	 * Function to deselect the project
	 */
	public onDeselectProject(): void {
		this.projectUid = null;
		this.currentProject = null;
		this.projectDeselectedEmitter.emit();
	}

	/**
	 * Function to update previous privacy values on create new products modal
	 */
	private updateSelectionFields(): void {
		if (localStorage.getItem(`${this.networkId}`) && this.currentPage !== 'collection' && this.currentPage !== 'channel-detail') {
			const retrievedCustomPrivacy = JSON.parse(localStorage.getItem(`${this.networkId}`));

			if (retrievedCustomPrivacy.privacyType === 'role') {
				this.selectedRole = retrievedCustomPrivacy;
				this.forNames = retrievedCustomPrivacy.name;
				this.previousSelection = retrievedCustomPrivacy.name;
				this.currentSelection = retrievedCustomPrivacy.name;
				if (retrievedCustomPrivacy.role) {
					this.previousSelection = retrievedCustomPrivacy.role;
					this.currentSelection = retrievedCustomPrivacy.role;
				}
			}
			if (retrievedCustomPrivacy.privacyType === 'department') {
				this.selectedDepartment = retrievedCustomPrivacy;
				if (retrievedCustomPrivacy.valueLabel) {
					this.previousSelection = retrievedCustomPrivacy.valueLabel;
					this.currentSelection = retrievedCustomPrivacy.valueLabel;
					this.forNames = retrievedCustomPrivacy.valueLabel;
				}
				if (retrievedCustomPrivacy.name && retrievedCustomPrivacy.uid) {
					this.previousSelection = retrievedCustomPrivacy.name;
					this.currentSelection = retrievedCustomPrivacy.name;
					this.forNames = retrievedCustomPrivacy.name;
				}
			}
			if (retrievedCustomPrivacy.privacyType === 'subCircle' || retrievedCustomPrivacy.privacyType === 'channel') {
				this.model.channel = retrievedCustomPrivacy.uid;
				this.previousSelection = retrievedCustomPrivacy.name;
				this.currentSelection = retrievedCustomPrivacy.name;
				this.forNames = retrievedCustomPrivacy.name;
			}
			if (retrievedCustomPrivacy.privacyType === 'personalFilter') {
				this.personalFilter = retrievedCustomPrivacy;
				this.previousSelection = retrievedCustomPrivacy.valueLabel;
				this.currentSelection = retrievedCustomPrivacy.valueLabel;
				this.forNames = retrievedCustomPrivacy.valueLabel;
			}
			if (retrievedCustomPrivacy.privacyType === 'selectByLevel') {
				this.selectByLevelValue = retrievedCustomPrivacy;
				this.previousSelection = retrievedCustomPrivacy.name;
				this.currentSelection = retrievedCustomPrivacy.name;
				this.forNames = retrievedCustomPrivacy.name;
			}
			if (retrievedCustomPrivacy === 'Everyone') {
				this.previousSelection = 'Everyone';
				this.currentSelection = 'Everyone';
				this.forNames = 'Everyone';
			}
			if (Array.isArray(retrievedCustomPrivacy)) {
				retrievedCustomPrivacy.forEach((member) => {
					if (member.privacyType === 'customPrivacy') {
						this.custom_privacy = retrievedCustomPrivacy;
						if (member.name) {
							this.previousSelection = member.name;
							this.currentSelection = member.name;
							this.forNames = member.name;
						} else if (member.user) {
							this.previousSelection = member.user.name;
							this.currentSelection = member.user.name;
							this.forNames = member.user.name;
						} else if (member.username) {
							this.previousSelection = member.username;
							this.currentSelection = member.username;
							this.forNames = member.username;
						}
					}
				});
				if (retrievedCustomPrivacy.includes('status') === true) {
					this.selectByStatusValue = retrievedCustomPrivacy;
					this.previousSelection = retrievedCustomPrivacy[1];
					this.currentSelection = retrievedCustomPrivacy[1];
					this.forNames = retrievedCustomPrivacy[1];
				}
				if (retrievedCustomPrivacy.includes('renewalFrequency') === true) {
					this.selectByRenewalValue = retrievedCustomPrivacy;
					this.previousSelection = retrievedCustomPrivacy[1];
					this.currentSelection = retrievedCustomPrivacy[1];
					this.forNames = retrievedCustomPrivacy[1];
				}
			}
		}
	}

	/**
	 * Function to show privacy values selected from privacy modal on add new project form
	 */
	public updatePrivacyStringBasedOnPrivacyComponent(): void {
		this.forNames = '';
		if (this.custom_privacy && this.custom_privacy.length > 0) {
			const names = [];
			this.custom_privacy.forEach((user) => {
				if (user['user']) {
					names.push(user['user'].name);
				} else if (user['name']) {
					names.push(user['name']);
				} else {
					names.push(user.username);
				}
			});
			this.forNames = names.join(',');
		} else {
			if (this.selectedRole && this.selectedDepartment) {
				this.forNames = this.selectedRole.name + ', ' + this.selectedDepartment['valueLabel'];
			} else if (this.selectedRole) {
				let roleName;
				if (this.selectedRole.name) {
					roleName = this.selectedRole.name;
				} else {
					roleName = this.selectedRole;
				}
				this.forNames = roleName;
			} else if (this.selectedDepartment) {
				let departmentName = '';
				departmentName = this.selectedDepartment['valueLabel'];
				this.forNames = departmentName;
			} else if (this.personalFilter) {
				let personalFilter = '';
				personalFilter = this.personalFilter['valueLabel'];
				this.forNames = personalFilter;
			} else if (this.channel) {
				this.forNames = this.channel['name'];
			} else if (this.selectByRenewalValue) {
				this.forNames = this.selectByRenewalValue[1];
			} else if (this.selectByLevelValue) {
				this.forNames = this.selectByLevelValue.name;
			} else if (this.selectByStatusValue) {
				this.forNames = this.selectByStatusValue[1];
			} else {
				this.forNames = 'Everyone';
			}
		}
	}

	/**
	 * Update cp value on creating a product according to the detail from the privacy modal.
	 */
	private updatePrivacyValues(): void {
		if (this.custom_privacy && this.custom_privacy.length > 0) {
			delete this.product['content_privacy'];
			delete this.product['channel'];
			this.product['custom_privacy'] = [];
			const names = [];
			const newObject = this.custom_privacy;
			newObject.forEach((member) => {
				member.privacyType = 'customPrivacy';
			});
			localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			this.custom_privacy.forEach((user) => {
				if (user['user']) {
					this.product['custom_privacy'].push(user['user'].userId);
					names.push(user['user'].name);
				} else if (user.userId) {
					this.product['custom_privacy'].push(user.userId);
					names.push(user.username);
				} else {
					this.product['custom_privacy'].push(user.id);
					names.push(user.username);
				}
			});
		} else {
			let roleString = null;
			let departmentString = null;
			let personalFilterString = null;
			let selectByStatusString = null;
			let selectByRenewalString = null;
			let selectByLevelString = null;
			delete this.product['custom_privacy'];
			delete this.product['channel'];

			if (this.selectedRole && this.selectedDepartment) {
				roleString = '"role": "' + this.selectedRole.slug + '"';
				departmentString = '"department": "' + this.selectedDepartment['value'] + '"';
			} else if (this.selectedRole) {
				roleString = '"role": "' + this.selectedRole.slug + '"';
				// * ******************SPACE AFTER : ******************* */
				roleString = '"role": "' + this.selectedRole.slug + '"';
				const newObject = this.selectedRole;
				newObject.privacyType = 'role';
				localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			} else if (this.selectedDepartment) {
				if (this.selectedDepartment['uid']) {
					departmentString = '"department":"' + this.selectedDepartment['uid'] + '"';
				}
				if (this.selectedDepartment['value']) {
					departmentString = '"department":"' + this.selectedDepartment['value'] + '"';
				}
				const newObject = this.selectedDepartment;
				newObject['privacyType'] = 'department';
				localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			} else if (this.personalFilter) {
				// let personalFilterName = '';
				// personalFilterName = this.personalFilter['valueLabel'];
				personalFilterString = '"' + this.personalFilter['key'] + '": "' + this.personalFilter['value'] + '"';
				const newObject = this.personalFilter;
				newObject['privacyType'] = 'personalFilter';
				localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			} else if (this.selectByStatusValue) {
				if (this.selectByStatusValue.status) {
					selectByStatusString = '"status": "' + this.selectByStatusValue.status + '"';
				} else {
					selectByStatusString = '"status": "' + this.selectByStatusValue[0] + '"';
					const newObject = this.selectByStatusValue;
					if (newObject.includes('status') === false) {
						newObject.push('status');
					}
					localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
				}
			} else if (this.selectByRenewalValue) {
				if (this.selectByRenewalValue.renewalStatus) {
					selectByRenewalString = '"renewalStatus": "' + this.selectByRenewalValue.renewalStatus + '"';
				} else {
					selectByRenewalString = '"renewalStatus": "' + this.selectByRenewalValue[0] + '"';
					const newObject = this.selectByRenewalValue;
					if (newObject.includes('renewalFrequency') === false) {
						newObject.push('renewalFrequency');
					}
					localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
				}
			} else if (this.selectByLevelValue) {
				if (this.selectByLevelValue.uid) {
					selectByLevelString = '"membership_level": "' + this.selectByLevelValue.uid + '"';
					const newObject = this.selectByLevelValue;
					newObject.privacyType = 'selectByLevel';
					localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
				} else {
					selectByLevelString = '"membership_level": "' + this.selectByLevelValue.membership_level + '"';
				}
			} else if (this.channel) {
				this.product['channel'] = this.channel['uid'];
				const newObject = this.channel;
				newObject['privacyType'] = 'subCircle';
				localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			}
			if (
				this.custom_privacy.length === 0 &&
				!this.selectedRole &&
				!this.role &&
				!this.personalFilter &&
				!this.selectedDepartment &&
				!this.channel &&
				!this.model.subcircle &&
				!this.selectByStatusValue &&
				!this.selectByRenewalValue &&
				!this.selectByLevelValue
			) {
				localStorage.setItem(`${this.networkId}`, JSON.stringify('Everyone'));
			}
			if (roleString && departmentString) {
				this.product['content_privacy'] = '{' + roleString + ', ' + departmentString + '}';
			} else if (roleString && !departmentString) {
				this.product['content_privacy'] = '{' + roleString + '}';
			} else if (!roleString && departmentString) {
				this.product['content_privacy'] = '{' + departmentString + '}';
			} else if (personalFilterString) {
				this.product['content_privacy'] = '{' + personalFilterString + '}';
			} else if (selectByStatusString) {
				this.product['content_privacy'] = '{' + selectByStatusString + '}';
			} else if (selectByRenewalString) {
				this.product['content_privacy'] = '{' + selectByRenewalString + '}';
			} else if (selectByLevelString) {
				this.product['content_privacy'] = '{' + selectByLevelString + '}';
			} else {
				delete this.product['content_privacy'];
			}
		}
		if (this.product['custom_privacy'] && this.product['custom_privacy'].length > 0) {
			this.product['custom_privacy'] = '[' + this.product['custom_privacy'].join(',') + ']';
		}
	}

	/**
	 * Function to create a new product and display created product
	 */
	public createNewProduct(): void {
		if (!this.productName) {
			this.productError = true;
		} else {
			this.updatePrivacyValues();
			this.product.product = this.productName;
			this.pageLoadingUiService.setPageLoading(true);
			this.networkService.createNewProduct(this.product, this.network.uid).subscribe((data) => {
				this.mixPanelService.track('Created Product', {
					workspace: this.network.name || 'NA',
					appVersion: environment.version,
					language: this.translate.currentLang,
					username: this.currentUser.username || 'NA',
					name: this.currentUser.name || 'NA',
					date: moment().format('YYYY-MM-DD'),
				});

				this.segmentService.trackEvent('Created Product', {
					workspace: this.network.name || 'NA',
					appVersion: environment.version,
					language: this.translate.currentLang,
					username: this.currentUser.username || 'NA',
					name: this.currentUser.name || 'NA',
					date: moment().format('YYYY-MM-DD'),
				});
				this.selectedProduct = [];
				data['checked'] = true;
				this.products.unshift(data);
				this.onSelectProduct(data);
				this.displayNewProductsModal = 'none';
				this.isNewProductForm = false;
				this.displayCpForProducts();
				this.productName = '';
				this.productError = false;
				this.pageLoadingUiService.setPageLoading(false);
				this.refresh.emit();
			});
		}
	}

	/**
	 * Function to display cp,name and set privacy value of selected product for edit
	 * @param product
	 * @param index
	 */
	public onEditProductClicked(product, i): void {
		this.editSelectedProduct = product;
		this.editSelectedProductIndex = i;
		this.showEditBtn = true;
		this.productName = product.name;
		this.forNames = '';
		this.personalFilter = null;
		if (product['customPrivacy']) {
			this.custom_privacy = product['customPrivacy'];
			product['customPrivacy'].forEach((cp, index) => {
				if (cp.username) {
					this.forNames = `${this.forNames}${index > 0 ? ', ' : ' '}${cp.username}`;
				} else if (cp.name) {
					this.forNames = `${this.forNames}${index > 0 ? ', ' : ' '}${cp.name}`;
				}
			});
		} else if (product['contentPrivacy']) {
			if (product['contentPrivacy'].role && product['contentPrivacy'].department) {
				const index = this.productDepartments.findIndex((department) => department.uid === product['contentPrivacy'].department);
				if (index > -1) {
					this.forNames = this.productDepartments[index]['name'] + ' , ' + product['contentPrivacy'].role;
				}
			} else if (product['contentPrivacy'].role) {
				const index = this.roles.findIndex((role) => role.slug === product['contentPrivacy'].role);
				this.selectedRole = this.roles[index];
				this.forNames = product['contentPrivacy'].role;
			} else if (product['contentPrivacy'].department) {
				const index = this.productDepartments.findIndex((department) => department.uid === product['contentPrivacy'].department);
				if (index > -1) {
					this.forNames = this.productDepartments[index]['name'];
					this.selectedDepartment = this.productDepartments[index];
				}
			} else if (!product['contentPrivacy'].role || !product['contentPrivacy'].department) {
				const field = Object.keys(product['contentPrivacy']);
				this.personalFilters.forEach((element) => {
					if (element.key === field[0] && field[0] !== 'manager') {
						this.forNames = element.valueLabel;
						this.personalFilter = element;
					}
				});

				if (product['contentPrivacy'].status) {
					this.forNames = product['contentPrivacy'].status;
					this.selectByStatusValue = product['contentPrivacy'];
				} else if (product['contentPrivacy'].renewalStatus) {
					this.forNames = product['contentPrivacy'].renewalStatus;
					this.selectByRenewalValue = product['contentPrivacy'];
				} else if (product['contentPrivacy'].membership_level) {
					const index = this.subscriptionTemplates.findIndex((val) => val.uid === product['contentPrivacy'].membership_level);
					if (index > -1) {
						this.forNames = this.subscriptionTemplates[index]['name'];
						this.selectByLevelValue = product['contentPrivacy'];
					}
				} else if (product['contentPrivacy'].manager) {
					if (this.myTeam && this.myTeam.key) {
						this.forNames = this.translate.instant('My Team');
						this.personalFilter = this.myTeam;
					}
				}
			}
		} else if (product['channel'] && product['channel']['uid']) {
			this.channel = product['channel'];
			this.forNames = product.channel.name;
		} else {
			this.forNames = 'Everyone';
		}
	}

	/**
	 * Function to call edit product api and display edited product
	 */
	public onEditProduct(): void {
		if (!this.productName) {
			this.productError = true;
		} else {
			this.updatePrivacyValues();
			this.product.product = this.productName;
			this.pageLoadingUiService.setPageLoading(true);
			this.networkService.updateProduct(this.product, this.network.uid, this.editSelectedProduct.uid).subscribe((data) => {
				this.products[this.editSelectedProductIndex] = data;
				this.displayCpForProducts();
				this.displayNewProductsModal = 'none';
				this.isNewProductForm = false;
				this.productName = '';
				this.showEditBtn = false;
				this.productError = false;
				this.updateSelectionFields();
				this.pageLoadingUiService.setPageLoading(false);
			});
		}
	}

	/**
	 * Function  to check if user has manager cp
	 * 1. Check if local storage contains manager
	 * 2. Create an object with necessary params for cp
	 */
	private checkIfManager(): void {
		if (localStorage.getItem('manager')) {
			// 1
			const obj = JSON.parse(localStorage.getItem('manager'));
			this.showMyTeam = true;
			this.myTeam = {
				// 2
				key: 'manager',
				value: obj.uid,
				valueLabel: this.translate.instant('My Team'),
			};
		}
	}

	private checkNetworkSubscription(): void {
		this.networkService.currentSubscription.subscribe((subscription) => {
			if (subscription && subscription['subscriptionPlan']) {
				this.subscriptionPlan = subscription['subscriptionPlan'];
				if (this.subscriptionPlan['subscriptionType']) {
					const subscriptionType = this.subscriptionPlan['subscriptionType'].toLowerCase();
					if (subscriptionType === 'business' || subscriptionType === 'pro') {
						this.memberType = '';
					}
				}
			}
		});
	}

	// Projects
	private getProjects(): void {
		this.scrollStatus = false;
		this.projects = [];
		this.isSpining = 0;
		this.displayProjectsModal = 'block';
		this.networkService.getAllProjects(this.network.uid, '').subscribe((data) => {
			if (data.next) {
				this.project_next_url = data.next.split('alpha')[1];
				this.paginateProjects();
			} else {
				this.project_next_url = '';
			}
			this.isSpining = 1;
			this.projects = data.objects;
			this.displayCpForProjects();
		});
	}

	public paginateProjects(): void {
		if (this.scrollStatus) {
			return;
		}
		this.scrollStatus = true;
		if (this.project_next_url) {
			this.networkService.paginateIssues(this.project_next_url).subscribe((data) => {
				if (data.next) {
					this.project_next_url = data.next.split('alpha')[1];
				} else {
					this.project_next_url = '';
				}
				this.projects = this.projects.concat(data.objects);
				this.scrollStatus = false;
				this.displayCpForProjects();
			});
		}
	}

	public displayCpForProjects(): void {
		this.projects.forEach((product) => {
			if (product['customPrivacy']) {
				product['projectCp'] = 'Custom Selection';
			} else if (product['contentPrivacy']) {
				if (product['contentPrivacy'].role && product['contentPrivacy'].department) {
					const index = this.productDepartments.findIndex((department) => department.uid === product['contentPrivacy'].department);
					if (index > -1) {
						product['projectCp'] = this.productDepartments[index]['name'] + ' , ' + product['contentPrivacy'].role;
					}
				} else if (product['contentPrivacy'].role) {
					product['projectCp'] = product['contentPrivacy'].role;
				} else if (product['contentPrivacy'].department) {
					const index = this.productDepartments.findIndex((department) => department.uid === product['contentPrivacy'].department);
					if (index > -1) {
						product['projectCp'] = this.productDepartments[index]['name'];
					}
				} else if (!product['contentPrivacy'].role || !product['contentPrivacy'].department) {
					const field = Object.keys(product['contentPrivacy']);
					this.personalFilters.forEach((element) => {
						if (element.key === field[0]) {
							product['projectCp'] = element.valueLabel;
						}
					});
					if (product['contentPrivacy'].status) {
						product['projectCp'] = product['contentPrivacy'].status;
					} else if (product['contentPrivacy'].renewalStatus) {
						product['projectCp'] = product['contentPrivacy'].renewalStatus;
					} else if (product['contentPrivacy'].membership_level) {
						const index = this.subscriptionTemplates.findIndex((val) => val.uid === product['contentPrivacy'].membership_level);
						if (index > -1) {
							product['projectCp'] = this.subscriptionTemplates[index]['name'];
						}
					} else if (product['contentPrivacy'].manager) {
						product['projectCp'] = this.translate.instant('My Team');
					}
				}
			} else if (product['channel'] && product['channel']['uid']) {
				product['projectCp'] = product.channel.name;
			} else {
				product['projectCp'] = 'Everyone';
			}
		});
	}

	public displayCreateProject(): void {
		this.createProject = 'block';
		this.isNewProjectForm = true;
		this.forNames = 'Everyone';
		this.previousSelection = 'Everyone';
		this.currentSelection = 'Everyone';
		this.resetPrivacy();
		this.projectStartDate = {
			year: new Date().getFullYear(),
			month: new Date().getMonth() + 1,
			day: new Date().getDate(),
		};
		this.projectEndDate = '';
	}

	public displayCreateNewProductForm(): void {
		this.displayNewProductsModal = 'block';
		this.isNewProductForm = true;
		this.previousSelection = 'Everyone';
		this.currentSelection = 'Everyone';
		this.forNames = 'Everyone';
		this.resetPrivacy();
		this.getUnArchivedProducts();
		this.getArchivedProductCounts();
	}

	/**
	 * Function to create a new project and display created project
	 */
	public createNewProject(): void {
		if (!this.projectName) {
			this.projectNameErr = true;
		}
		if (!this.projectEndDate) {
			this.projectDateErr = true;
		}
		if (this.projectName && this.projectEndDate) {
			this.projectNameErr = false;
			this.projectDateErr = false;
			this.displayProjectError = 'none';
			this.modelText = '';
			this.project['startDate'] = this.parserFormatter.format(this.projectStartDate);
			this.project['endDate'] = this.parserFormatter.format(this.projectEndDate);
			this.updatePrivacyValues2();
			this.updateSelectionFields();
			this.project.name = this.projectName;
			this.pageLoadingUiService.setPageLoading(true);
			this.networkService
				.createNewProject(this.project, this.network.uid)
				.subscribe(
					(data) => {
						// ====

						this.currentProject = data;
						this.projectUid = data['uid'];
						this.emitCurrentSelectedProjectCp.emit(data.channel);
						// ====
						this.projects.unshift(data);
						this.displayCpForProjects();
						this.projectName = '';
						this.isNewProjectForm = false;
						delete this.project['startDate'];
						delete this.project['endDate'];
						this.projectStartDate = {
							year: new Date().getFullYear(),
							month: new Date().getMonth() + 1,
							day: new Date().getDate(),
						};
						this.projectEndDate = '';
						this.projectNameErr = false;
					},
					(error: any) => {
						this.errorModal = this.nzModalService.error({
							nzTitle: this.translateService.instant('Error'),
							nzContent: error?.errors?.error?.detail,
							nzOnOk: () => {},
						});
					}
				)
				.add(() => {
					this.createProject = 'none';
					this.pageLoadingUiService.setPageLoading(false);
				});
		}
	}

	public onKeyDown(e: any): void {
		if (e.target.value) {
			this.projectNameErr = false;
		}
	}

	private updatePrivacyValues2(): void {
		if (this.custom_privacy && this.custom_privacy.length > 0) {
			delete this.project['content_privacy'];
			delete this.project['channel'];
			this.project['custom_privacy'] = [];
			const names = [];
			const newObject = this.custom_privacy;
			newObject.forEach((member) => {
				member.privacyType = 'customPrivacy';
			});
			localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			this.custom_privacy.forEach((user) => {
				if (user['user']) {
					this.project['custom_privacy'].push(user['user'].userId);
					names.push(user['user'].name);
				} else if (user.userId) {
					this.project['custom_privacy'].push(user.userId);
					names.push(user.username);
				} else {
					this.project['custom_privacy'].push(user.id);
					names.push(user.username);
				}
			});
		} else {
			let roleString = null;
			let departmentString = null;
			let personalFilterString = null;
			let selectByStatusString = null;
			let selectByRenewalString = null;
			let selectByLevelString = null;
			delete this.project['custom_privacy'];
			delete this.project['channel'];

			if (this.selectedRole && this.selectedDepartment) {
				roleString = '"role": "' + this.selectedRole.slug + '"';
				departmentString = '"department": "' + this.selectedDepartment['value'] + '"';
			} else if (this.selectedRole) {
				roleString = '"role": "' + this.selectedRole.slug + '"';
				roleString = '"role": "' + this.selectedRole.slug + '"';
				const newObject = this.selectedRole;
				newObject.privacyType = 'role';
				localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			} else if (this.selectedDepartment) {
				if (this.selectedDepartment['uid']) {
					departmentString = '"department":"' + this.selectedDepartment['uid'] + '"';
				}
				if (this.selectedDepartment['value']) {
					departmentString = '"department":"' + this.selectedDepartment['value'] + '"';
				}
				const newObject = this.selectedDepartment;
				newObject['privacyType'] = 'department';
				localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			} else if (this.personalFilter) {
				// let personalFilterName = '';
				// personalFilterName = this.personalFilter['valueLabel'];
				personalFilterString = '"' + this.personalFilter['key'] + '": "' + this.personalFilter['value'] + '"';
				const newObject = this.personalFilter;
				newObject['privacyType'] = 'personalFilter';
				localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			} else if (this.selectByStatusValue) {
				if (this.selectByStatusValue.status) {
					selectByStatusString = '"status": "' + this.selectByStatusValue.status + '"';
				} else {
					selectByStatusString = '"status": "' + this.selectByStatusValue[0] + '"';
					const newObject = this.selectByStatusValue;
					if (newObject.includes('status') === false) {
						newObject.push('status');
					}
					localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
				}
			} else if (this.selectByRenewalValue) {
				if (this.selectByRenewalValue.renewalStatus) {
					selectByRenewalString = '"renewalStatus": "' + this.selectByRenewalValue.renewalStatus + '"';
				} else {
					selectByRenewalString = '"renewalStatus": "' + this.selectByRenewalValue[0] + '"';
					const newObject = this.selectByRenewalValue;
					if (newObject.includes('renewalFrequency') === false) {
						newObject.push('renewalFrequency');
					}
					localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
				}
			} else if (this.selectByLevelValue) {
				if (this.selectByLevelValue.uid) {
					selectByLevelString = '"membership_level": "' + this.selectByLevelValue.uid + '"';
					const newObject = this.selectByLevelValue;
					newObject.privacyType = 'selectByLevel';
					localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
				} else {
					selectByLevelString = '"membership_level": "' + this.selectByLevelValue.membership_level + '"';
				}
			} else if (this.channel) {
				this.project['channel'] = this.channel['uid'];
				const newObject = this.channel;
				newObject['privacyType'] = 'subCircle';
				localStorage.setItem(`${this.networkId}`, JSON.stringify(newObject));
			}
			if (
				this.custom_privacy.length === 0 &&
				!this.selectedRole &&
				!this.role &&
				!this.personalFilter &&
				!this.selectedDepartment &&
				!this.channel &&
				!this.model.subcircle &&
				!this.selectByStatusValue &&
				!this.selectByRenewalValue &&
				!this.selectByLevelValue
			) {
				localStorage.setItem(`${this.networkId}`, JSON.stringify('Everyone'));
			}
			if (roleString && departmentString) {
				this.project['content_privacy'] = '{' + roleString + ', ' + departmentString + '}';
			} else if (roleString && !departmentString) {
				this.project['content_privacy'] = '{' + roleString + '}';
			} else if (!roleString && departmentString) {
				this.project['content_privacy'] = '{' + departmentString + '}';
			} else if (personalFilterString) {
				this.project['content_privacy'] = '{' + personalFilterString + '}';
			} else if (selectByStatusString) {
				this.project['content_privacy'] = '{' + selectByStatusString + '}';
			} else if (selectByRenewalString) {
				this.project['content_privacy'] = '{' + selectByRenewalString + '}';
			} else if (selectByLevelString) {
				this.project['content_privacy'] = '{' + selectByLevelString + '}';
			} else {
				delete this.project['content_privacy'];
			}
		}
		if (this.project['custom_privacy'] && this.project['custom_privacy'].length > 0) {
			this.project['custom_privacy'] = '[' + this.project['custom_privacy'].join(',') + ']';
		}
	}

	public onDeleteProjectClicked(project, index): void {
		this.selectedProjectIndex = index;
		this.displayDeleteModal = 'block';
		this.selectedProject = project;
	}

	public confirmProjectDelete(): void {
		this.pageLoadingUiService.setPageLoading(true);
		this.networkService.deleteProject(this.network.uid, this.selectedProject.uid).subscribe(() => {
			this.displayDeleteModal = 'none';
			this.pageLoadingUiService.setPageLoading(false);
			this.projects.splice(this.selectedProjectIndex, 1);
		});
	}

	public onEditProjectClicked(product, i): void {
		this.editSelectedProject = product;
		this.editSelectedProjectIndex = i;
		this.showEditBtn = true;
		this.projectName = product.name;
		this.forNames = '';
		this.personalFilter = null;
		if (product['startDate']) {
			// this.projectStartDate = this.setProjectDate(product.startDate);
			const date = new Date(product['startDate']);
			const month = date.getUTCMonth() + 1;
			const day = date.getUTCDate();
			const year = date.getUTCFullYear();
			this.projectStartDate = {
				year: year,
				month: month,
				day: day,
			};
		}
		if (product['endDate']) {
			// this.projectEndDate = this.setProjectDate(product.endDate);
			const date = new Date(product['endDate']);
			const month = date.getUTCMonth() + 1;
			const day = date.getUTCDate();
			const year = date.getUTCFullYear();
			this.projectEndDate = {
				year: year,
				month: month,
				day: day,
			};
		}
		if (product['customPrivacy']) {
			this.reset();
			this.custom_privacy = product['customPrivacy'];
			product['customPrivacy'].forEach((cp, index) => {
				if (cp.username) {
					this.forNames = `${this.forNames}${index > 0 ? ', ' : ' '}${cp.username}`;
				} else if (cp.name) {
					this.forNames = `${this.forNames}${index > 0 ? ', ' : ' '}${cp.name}`;
				}
			});
		} else if (product['contentPrivacy']) {
			this.custom_privacy = [];
			this.channel = '';

			if (product['contentPrivacy'].role && product['contentPrivacy'].department) {
				const index = this.productDepartments.findIndex((department) => department.uid === product['contentPrivacy'].department);
				if (index > -1) {
					this.forNames = this.productDepartments[index]['name'] + ' , ' + product['contentPrivacy'].role;
				}
			} else if (product['contentPrivacy'].role) {
				const index = this.roles.findIndex((role) => role.slug === product['contentPrivacy'].role);
				this.selectedRole = this.roles[index];
				this.forNames = product['contentPrivacy'].role;
			} else if (product['contentPrivacy'].department) {
				const index = this.productDepartments.findIndex((department) => department.uid === product['contentPrivacy'].department);
				if (index > -1) {
					this.forNames = this.productDepartments[index]['name'];
					this.selectedDepartment = this.productDepartments[index];
				}
			} else if (!product['contentPrivacy'].role || !product['contentPrivacy'].department) {
				const field = Object.keys(product['contentPrivacy']);
				this.personalFilters.forEach((element) => {
					if (element.key === field[0] && field[0] !== 'manager') {
						this.forNames = element.valueLabel;
						this.personalFilter = element;
					}
				});

				if (product['contentPrivacy'].status) {
					this.forNames = product['contentPrivacy'].status;
					this.selectByStatusValue = product['contentPrivacy'];
				} else if (product['contentPrivacy'].renewalStatus) {
					this.forNames = product['contentPrivacy'].renewalStatus;
					this.selectByRenewalValue = product['contentPrivacy'];
				} else if (product['contentPrivacy'].membership_level) {
					const index = this.subscriptionTemplates.findIndex((val) => val.uid === product['contentPrivacy'].membership_level);
					if (index > -1) {
						this.forNames = this.subscriptionTemplates[index]['name'];
						this.selectByLevelValue = product['contentPrivacy'];
					}
				} else if (product['contentPrivacy'].manager) {
					if (this.myTeam && this.myTeam.key) {
						this.forNames = this.translate.instant('My Team');
						this.personalFilter = this.myTeam;
					}
				}
			}
		} else if (product['channel'] && product['channel']['uid']) {
			this.custom_privacy = [];
			this.selectedRole = '';
			this.selectedDepartment = '';
			this.personalFilter = '';
			this.selectByStatusValue = '';
			this.selectByRenewalValue = '';
			this.selectByLevelValue = '';
			this.channel = product['channel'];
			this.forNames = product.channel.name;
		} else {
			this.forNames = 'Everyone';
			this.selectedRole = '';
			this.selectedDepartment = '';
			this.personalFilter = '';
			this.selectByStatusValue = '';
			this.selectByRenewalValue = '';
			this.selectByLevelValue = '';
			this.channel = '';
			this.custom_privacy = [];
		}
	}

	/**
	 * Function to call edit project api and display edited project
	 */
	public onEditProject(): void {
		if (!this.projectName) {
			this.projectNameErr = true;
		}
		if (!this.projectEndDate) {
			this.projectDateErr = true;
		}
		if (this.projectName && this.projectEndDate) {
			this.displayProjectError = 'none';
			this.modelText = '';
			this.project['startDate'] = this.parserFormatter.format(this.projectStartDate);
			this.project['endDate'] = this.parserFormatter.format(this.projectEndDate);
			this.updatePrivacyValues2();
			this.updateSelectionFields();
			this.project.name = this.projectName;
			this.pageLoadingUiService.setPageLoading(true);
			this.networkService.updateProject(this.project, this.network.uid, this.editSelectedProject.uid).subscribe((data) => {
				this.projects[this.editSelectedProjectIndex] = data;
				this.createProject = 'none';
				this.isNewProjectForm = false;
				this.displayCpForProjects();
				this.pageLoadingUiService.setPageLoading(false);
				this.resetProjectForm();
			});
		}
	}

	private setProjectDate(date): void {
		const index = date.indexOf(' ');
		if (index > -1) {
			return date.substring(0, index);
		} else {
			return date;
		}
	}

	public reset(): void {
		this.selectedRole = '';
		this.selectedDepartment = '';
		this.personalFilter = '';
		this.selectByStatusValue = '';
		this.selectByRenewalValue = '';
		this.selectByLevelValue = '';
		this.channel = '';
	}

	public resetProjectForm(): void {
		this.showEditBtn = false;
		this.projectName = '';
		this.projectDateErr = false;
		this.projectNameErr = false;
		delete this.project['startDate'];
		delete this.project['endDate'];
		this.projectStartDate = {
			year: new Date().getFullYear(),
			month: new Date().getMonth() + 1,
			day: new Date().getDate(),
		};
		this.projectEndDate = '';
	}

	public resetPrivacy(): void {
		this.customPrivacyUserIds = [];
		this.custom_privacy = [];
		this.selectedRole = '';
		this.selectedDepartment = '';
		this.personalFilter = '';
		this.selectedChannel = '';
		this.selectByStatusValue = '';
		this.selectByRenewalValue = '';
		this.selectByLevelValue = '';
		this.channel = '';
	}

	private getArchivedProductCounts(): void {
		this.networkService.getArchivedProductCounts().subscribe((data) => {
			this.archivedCounts = data['archivedCounts'];
		});
	}

	public handleArchive(product, state): void {
		const archived = {};
		archived['name'] = product['name'];

		if (state === 'archive') {
			archived['archived'] = 'true';
		} else {
			archived['archived'] = 'false';
		}

		if (product['contentPrivacy']) {
			archived['content_privacy'] = JSON.stringify(product['contentPrivacy']);
		} else if (product['customPrivacy'] && product['customPrivacy'].length > 0) {
			const customPrivacy: any[] = [];
			// let custom: any;
			product.customPrivacy.forEach((element) => {
				if (element.userId) {
					customPrivacy.push(element.userId);
				} else {
					customPrivacy.push(element.id);
				}
			});
			const custom = '[' + customPrivacy.join(',') + ']';
			archived['custom_privacy'] = custom;
		} else if (product['channel'] && product['channel']['uid']) {
			archived['channel'] = product['channel']['uid'];
		}

		this.pageLoadingUiService.setPageLoading(true);
		this.networkService.updateProduct(archived, this.network.uid, product.uid).subscribe(
			() => {
				this.pageLoadingUiService.setPageLoading(false);
				if (state === 'archive') {
					this.getUnArchivedProducts();
				} else {
					this.getArchivedProducts();
				}
				this.getArchivedProductCounts();
				this.displayCpForProducts();
			},
			() => {}
		);
	}

	public getArchivedProducts(): void {
		this.isProcessing = 0;
		this.products = [];
		this.isArchived = true;

		this.networkService.getArchivedProducts().subscribe((data) => {
			this.isProcessing = 1;
			this.products = data.objects;
			this.displayCpForProducts();
		});
	}

	/**
	 * Function to get all products in issue management
	 */
	public getUnArchivedProducts(): void {
		this.isArchived = false;
		this.isProcessing = 0;
		this.products = [];

		this.networkService.getAllProducts(this.networkUid).subscribe((data) => {
			this.isProcessing = 1;
			this.products = data.objects;
			this.displayCpForProducts();
		});
	}

	public onPrivacyUpdatedToPrivate(): void{
		this.resetPrivacy();
		this.isPrivate = true;
		this.resetCurrentNetworkDefaultCp.emit();
		this.showChannelMenu = false;
		this.custom_privacy = [];
		this.custom_privacy.push(this.currentUser);
		this.selectedChannel = 'Myself';
		// this.isPrivate = false;
	}
}
