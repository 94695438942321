import { Component, OnInit, ViewChild } from '@angular/core';
import { PrivacyComponent, UserService, NetworkService, LayoutService } from '../../shared';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MixPanelService } from '@core/services/third-party/mix-panel.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { SegmentAnalyticsService } from '@core/services/third-party/segment-analytics.service';

@Component({
	selector: 'app-create-product',
	templateUrl: './create-product.component.html',
	styleUrls: ['./create-product.component.css'],
})
export class CreateProductComponent implements OnInit {
	@ViewChild(PrivacyComponent, { static: true }) public privacy;
	public model = { channel: '' };
	public loadingGif = 'none';
	public forNames;
	public currentUser;
	public network;
	public placement = 'bottom';
	public product: FormGroup;
	public confirmModal?: NzModalRef;

	constructor(
		private networkService: NetworkService,
		private userService: UserService,
		private fb: FormBuilder,
		private _location: Location,
		private layoutService: LayoutService,
		private translate: TranslateService,
		private mixPanelService: MixPanelService,
		private nzModalService: NzModalService,
		private translateService: TranslateService,
		private segmentService: SegmentAnalyticsService
	) {
		this.product = this.fb.group({
			product: ['', Validators.required],
		});
	}

	ngOnInit(): void {
		this.currentUser = this.userService.getCurrentUser();
		this.network = this.networkService.getCurrentNetwork();
		this.forNames = 'Everyone';
		this.privacy.previousSelection = 'Everyone';
		this.privacy.currentSelection = 'Everyone';

		this.privacy.privacyModalStatus.subscribe((data) => {
			if (data === 'closed') {
				this.layoutService.hideBd();
				this.updatePrivacyStringBasedOnPrivacyComponent();
			}
		});
	}

	public onCreate(): void {
		if (this.product['valid']) {
			this.updatePrivacyValues(this.product.value);
			this.loadingGif = 'block';
			this.networkService.createNewProduct(this.product.value, this.network.uid).subscribe(
				() => {
					this.mixPanelService.track('Created Product', {
						workspace: this.network.name || 'NA',
						appVersion: environment.version,
						language: this.translate.currentLang,
						username: this.currentUser.username || 'NA',
						name: this.currentUser.name || 'NA',
						date: moment().format('YYYY-MM-DD'),
					});

					this.segmentService.trackEvent('Created Product', {
						workspace: this.network.name || 'NA',
						appVersion: environment.version,
						language: this.translate.currentLang,
						username: this.currentUser.username || 'NA',
						name: this.currentUser.name || 'NA',
						date: moment().format('YYYY-MM-DD'),
					});

					this.loadingGif = 'none';
					this.product.reset();
					this._location.back();
				},
				(err: any) => {
					this.loadingGif = 'none';
					this.onProductCreationError(err);
				}
			);
		}
	}

	private updatePrivacyValues(product): void {
		product['channel'] = '';
		if (this.privacy.custom_privacy && this.privacy.custom_privacy.length > 0) {
			delete product['content_privacy'];
			product['custom_privacy'] = [];
			const names = [];

			const newObject = this.privacy.custom_privacy;
			newObject.forEach((member) => {
				member.privacyType = 'customPrivacy';
			});
			localStorage.setItem(`${this.network['uid']}`, JSON.stringify(newObject));
			//
			this.privacy.custom_privacy.forEach((user) => {
				if (user['user']) {
					product['custom_privacy'].push(user['user'].userId);
					names.push(user['user'].name);
				} else {
					product['custom_privacy'].push(user.userId);
					names.push(user.username);
				}
			});
		} else {
			let roleString = null;
			let departmentString = null;
			let personalFilterString = null;
			let selectByStatusString = null;
			let selectByRenewalString = null;
			let selectByLevelString = null;

			delete product['custom_privacy'];
			if (this.privacy.selectedRole && this.privacy.selectedDepartment) {
				roleString = '"role": "' + this.privacy.selectedRole.slug + '"';
				departmentString = '"department": "' + this.privacy.selectedDepartment.value + '"';
			} else if (this.privacy.selectedRole) {
				roleString = '"role": "' + this.privacy.selectedRole.slug + '"';
				let roleName;
				if (this.privacy.selectedRole.name) {
					roleName = this.privacy.selectedRole.name;
				} else {
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					roleName = this.privacy.selectedRole;
				}
				// * ******************SPACE AFTER : ******************* */
				roleString = '"role": "' + this.privacy.selectedRole.slug + '"';
				// * ************************************** */
				const newObject = this.privacy.selectedRole;
				newObject.privacyType = 'role';
				localStorage.setItem(`${this.network['uid']}`, JSON.stringify(newObject));
			} else if (this.privacy.selectedDepartment) {
				if (this.privacy.selectedDepartment.value) {
					departmentString = '"department":"' + this.privacy.selectedDepartment.value + '"';
					// let departmentName = '';
					// departmentName = this.privacy.selectedDepartment.valueLabel;
					departmentString = '"department": "' + this.privacy.selectedDepartment.value + '"';
				}
				if (this.privacy.selectedDepartment.uid && this.privacy.selectedDepartment.name) {
					departmentString = '"department":"' + this.privacy.selectedDepartment.uid + '"';
					// let departmentName = '';
					// departmentName = this.privacy.selectedDepartment.name;
					departmentString = '"department": "' + this.privacy.selectedDepartment.uid + '"';
				}
				const newObject = this.privacy.selectedDepartment;
				newObject.privacyType = 'department';
				localStorage.setItem(`${this.network['uid']}`, JSON.stringify(newObject));
			} else if (this.privacy.personalFilter) {
				// let personalFilterName = '';
				// personalFilterName = this.privacy.personalFilter.valueLabel;
				personalFilterString = '"' + this.privacy.personalFilter.key + '": "' + this.privacy.personalFilter.value + '"';
				// -
				const newObject = this.privacy.personalFilter;
				newObject.privacyType = 'personalFilter';
				localStorage.setItem(`${this.network['uid']}`, JSON.stringify(newObject));
			} else if (this.privacy.selectByStatusValue) {
				selectByStatusString = '"status": "' + this.privacy.selectByStatusValue[0] + '"';
				const newObject = this.privacy.selectByStatusValue;
				if (newObject.includes('status') === false) {
					newObject.push('status');
				}
				localStorage.setItem(`${this.network['uid']}`, JSON.stringify(newObject));
			} else if (this.privacy.selectByRenewalValue) {
				selectByRenewalString = '"renewalStatus": "' + this.privacy.selectByRenewalValue[0] + '"';
				// -
				const newObject = this.privacy.selectByRenewalValue;
				if (newObject.includes('renewalFrequency') === false) {
					newObject.push('renewalFrequency');
				}
				localStorage.setItem(`${this.network['uid']}`, JSON.stringify(newObject));
			} else if (this.privacy.selectByLevelValue) {
				selectByLevelString = '"membership_level": "' + this.privacy.selectByLevelValue.uid + '"';
				// -
				const newObject = this.privacy.selectByLevelValue;
				newObject.privacyType = 'selectByLevel';
				localStorage.setItem(`${this.network['uid']}`, JSON.stringify(newObject));
			} else if (this.model['channel']) {
				product['channel'] = this.model['channel'];
				// -
				const newObject = this.privacy.channel;
				newObject.privacyType = 'channel';
				localStorage.setItem(`${this.network['uid']}`, JSON.stringify(newObject));
			}
			if (
				this.privacy.custom_privacy.length === 0 &&
				!this.privacy.selectedRole &&
				!this.privacy.role &&
				!this.privacy.personalFilter &&
				!this.privacy.selectedDepartment &&
				!this.privacy.channel &&
				!this.model.channel
			) {
				localStorage.setItem(`${this.network['uid']}`, JSON.stringify('Everyone'));
			}

			if (roleString && departmentString) {
				product['content_privacy'] = '{' + roleString + ', ' + departmentString + '}';
			} else if (roleString && !departmentString) {
				product['content_privacy'] = '{' + roleString + '}';
			} else if (!roleString && departmentString) {
				product['content_privacy'] = '{' + departmentString + '}';
			} else if (personalFilterString) {
				product['content_privacy'] = '{' + personalFilterString + '}';
			} else if (selectByStatusString) {
				product['content_privacy'] = '{' + selectByStatusString + '}';
			} else if (selectByRenewalString) {
				product['content_privacy'] = '{' + selectByRenewalString + '}';
			} else if (selectByLevelString) {
				product['content_privacy'] = '{' + selectByLevelString + '}';
			} else {
				delete product['content_privacy'];
			}
		}
		if (!this.model.channel) {
			delete product['channel'];
		}
		if (product['custom_privacy'] && product['custom_privacy'].length > 0) {
			product['custom_privacy'] = '[' + product['custom_privacy'].join(',') + ']';
		}
	}

	public updatePrivacyStringBasedOnPrivacyComponent(): void {
		this.forNames = '';
		if (this.privacy.custom_privacy && this.privacy.custom_privacy.length > 0) {
			const names = [];
			this.privacy.custom_privacy.forEach((user) => {
				if (user['user']) {
					names.push(user['user'].name);
				} else if (user['name']) {
					names.push(user['name']);
				} else {
					names.push(user.username);
				}
			});
			this.forNames = names.join(',');
		} else {
			if (this.privacy.selectedRole && this.privacy.selectedDepartment) {
				this.forNames = this.privacy.selectedRole.name + ', ' + this.privacy.selectedDepartment.valueLabel;
			} else if (this.privacy.selectedRole) {
				let roleName;
				if (this.privacy.selectedRole.name) {
					roleName = this.privacy.selectedRole.name;
				} else {
					roleName = this.privacy.selectedRole;
				}
				this.forNames = roleName;
			} else if (this.privacy.selectedDepartment) {
				let departmentName = '';
				if (this.privacy.selectedDepartment.valueLabel) {
					departmentName = this.privacy.selectedDepartment.valueLabel;
					this.forNames = departmentName;
				}
				if (this.privacy.selectedDepartment.name && this.privacy.selectedDepartment.uid) {
					departmentName = this.privacy.selectedDepartment.name;
					this.forNames = departmentName;
				}
			} else if (this.privacy.personalFilter) {
				let personalFilter = '';
				personalFilter = this.privacy.personalFilter.valueLabel;
				this.forNames = personalFilter;
			} else if (this.model['channel']) {
				const index = this.network['channels'].findIndex((network) => network['uid'] === this.model['channel']);
				if (index !== -1) {
					this.forNames = this.network['channels'][index].name;
				}
			} else if (this.privacy.selectByRenewalValue) {
				this.forNames = this.privacy.selectByRenewalValue[1];
			} else if (this.privacy.selectByLevelValue) {
				this.forNames = this.privacy.selectByLevelValue.name;
			} else if (this.privacy.selectByStatusValue) {
				this.forNames = this.privacy.selectByStatusValue[1];
			} else {
				this.forNames = 'Everyone';
			}
		}
	}

	private updateSelectionFields(): void {
		if (localStorage.getItem(`${this.network['uid']}`)) {
			const retrievedCustomPrivacy = JSON.parse(localStorage.getItem(`${this.network['uid']}`));
			if (retrievedCustomPrivacy.privacyType === 'role') {
				this.privacy['selectedRole'] = retrievedCustomPrivacy;
				this.forNames = retrievedCustomPrivacy.name;
				this.privacy.previousSelection = retrievedCustomPrivacy.name;
				this.privacy.currentSelection = retrievedCustomPrivacy.name;
			}
			if (retrievedCustomPrivacy.privacyType === 'department') {
				this.privacy['selectedDepartment'] = retrievedCustomPrivacy;
				if (retrievedCustomPrivacy.valueLabel) {
					this.privacy.previousSelection = retrievedCustomPrivacy.valueLabel;
					this.privacy.currentSelection = retrievedCustomPrivacy.valueLabel;
					this.forNames = retrievedCustomPrivacy.valueLabel;
				}
				if (retrievedCustomPrivacy.name && retrievedCustomPrivacy.uid) {
					this.privacy.previousSelection = retrievedCustomPrivacy.name;
					this.privacy.currentSelection = retrievedCustomPrivacy.name;
					this.forNames = retrievedCustomPrivacy.name;
				}
			}
			if (retrievedCustomPrivacy.privacyType === 'channel' || retrievedCustomPrivacy.privacyType === 'subCircle') {
				this.model['channel'] = retrievedCustomPrivacy.uid;
				this.privacy.channel = retrievedCustomPrivacy;
				this.forNames = retrievedCustomPrivacy.name;
				this.privacy.previousSelection = retrievedCustomPrivacy.name;
				this.privacy.currentSelection = retrievedCustomPrivacy.name;
			}
			if (retrievedCustomPrivacy.privacyType === 'personalFilter') {
				this.privacy['personalFilter'] = retrievedCustomPrivacy;
				this.forNames = retrievedCustomPrivacy.valueLabel;
				this.privacy.previousSelection = retrievedCustomPrivacy.valueLabel;
				this.privacy.currentSelection = retrievedCustomPrivacy.valueLabel;
			}

			if (Array.isArray(retrievedCustomPrivacy)) {
				this.privacy['custom_privacy'] = retrievedCustomPrivacy;
				retrievedCustomPrivacy.forEach(() => {
					const membersList = [];
					for (const u of this.privacy['custom_privacy']) {
						if (u.user && u.user.firstName) {
							membersList.push(u.user.firstName);
						} else if (u.firstName) {
							membersList.push(u.firstName);
						} else if (u.name) {
							membersList.push(u.name);
						} else if (u.username) {
							membersList.push(u.username);
						}
					}
					this.privacy.previousSelection = membersList.join(', ');
					this.privacy.currentSelection = membersList.join(', ');
					this.forNames = membersList.join(', ');
				});
			}
			if (retrievedCustomPrivacy === 'Everyone') {
				this.forNames = this.translate.instant('Everyone');
				this.privacy.previousSelection = this.translate.instant('Everyone');
				this.privacy.currentSelection = this.translate.instant('Everyone');
			}
		} else {
			this.forNames = 'Everyone';
			this.privacy.previousSelection = 'Everyone';
			this.privacy.currentSelection = 'Everyone';
		}
	}

	public onPrivacyClick(): void {
		this.privacy.showPrivacy();
		this.privacy.privacy_settings = 'block';
	}

	public backToPrevious(): void {
		this._location.back();
	}

	/**
	 * Handle error messages from product creation to display error modal
	 * @param error Error Object
	 */
	public onProductCreationError(err: any): void {
		this.confirmModal = this.nzModalService.error({
			nzTitle: this.translateService.instant('Error'),
			nzContent: err?.errors?.error?.detail,
			nzOnOk: () => {
				this.backToPrevious();
			},
		});
	}
}
