export default class StrUtils {
	/**
	 * Extract extension name from URL
	 * This method always returns in lowercase format
	 *
	 * @source https://stackoverflow.com/a/64010944/6940144
	 * @param url string
	 *
	 * @returns string|null
	 */
	public static getExtensionFromUrl(url: string): string | null {
		try {
			return url.match(/^https?:\/\/.*[\\\/][^\?#]*\.([a-zA-Z0-9]+)\??#?/)[1]?.toLowerCase();
		} catch (ignored) {
			return null;
		}
	}
}
