import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MessagingService, NetworkService } from '../services';

@Component({
	selector: 'app-push-notification',
	templateUrl: './push-notification.component.html',
	styleUrls: ['./push-notification.component.css'],
})
export class PushNotificationComponent implements OnInit, OnChanges {
	@Input() public messages: any[] = [];

	public showOfflineNotification = 'none';
	public offlineText;
	public showRefresh = false;
	public date: any;
	public expiryDate: any;
	public currentDate: any;

	constructor(private router: Router, private messagingService: MessagingService, private networkService: NetworkService) {}

	ngOnInit(): void {
		localStorage.removeItem('status');

		window.addEventListener('online', () => {
			this.showOfflineNotification = 'block';
			this.offlineText = 'Your internet connection is restored';
			this.showRefresh = false;
			this.close();
		});
		window.addEventListener('offline', () => {
			this.showOfflineNotification = 'block';
			this.showRefresh = true;
			this.offlineText = 'Your are currently offline. ';
		});

		this.messagingService.currentMessage.subscribe((data) => {
			if (data && data.notification) {
				this.closeNotifications();
			}
		});

		// this.showOfflineNotification = 'block';
		// this.offlineText = 'Your internet connection is restored';
		// this.showRefresh = false;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.hasOwnProperty('messages')) {
		}
	}

	public onClickRemoveNotification(index): void {
		this.messages.splice(index, 1);
	}

	public onClickNotifications(message): void {
		const payLoad = message?.data;
		localStorage.setItem('network_id', payLoad?.network_id);
		this.networkService.networkId = payLoad?.network_id;

		switch (payLoad.contentType) {
			case 'Post':
				if (payLoad.category === 'comment' || payLoad?.category === 'like') {
					const url = '/posts/' + payLoad.contentUid + '?comment_id=' + payLoad?.comment_id;
					this.router.navigateByUrl(url);
					break;
				} else {
					window.location.replace('/posts/' + payLoad?.contentUid);
					// this.router.navigateByUrl('/posts/' + payLoad.contentUid);
					break;
				}
			case 'relationship':
				window.location.replace('/network/profile/' + payLoad.contentUid + '?username=' + payLoad?.username);
				break;
			case 'account':
				window.location.replace('/network/profile/' + payLoad.contentUid + '?username=' + payLoad?.username);
				break;
			case 'ChannelMember':
				this.router.navigateByUrl('/network/view-channel?id=' + payLoad?.channel_id);
				break;
			case 'comment':
				window.location.replace('/posts/' + payLoad?.contentUid);
				break;
			case 'Memberships':
				window.location.replace('/network/profile/' + payLoad?.contentUid + '?username=' + payLoad?.username);
				break;
			case 'member':
				window.location.replace('/network/profile/' + payLoad?.contentUid + '?username=' + payLoad?.username);
				break;
			// eslint-disable-next-line spellcheck/spell-checker
			case 'foldercontents':
				console.log('here 3');
				window.location.replace('/posts/' + payLoad?.contentUid);
				break;
			case 'Folder':
				window.location.replace('/network/collection?id=' + payLoad?.contentUid);
				break;
			case 'Issue':
				window.location.replace('/network/issue-detail?id=' + payLoad?.contentUid);
				break;
			case 'ToDo':
				localStorage.setItem('todo-prev-route', this.router.url);
				window.location.replace('/project/task-detail/' + payLoad?.contentUid);
				break;
			case 'ToDoList':
				localStorage.setItem('todo-prev-route', this.router.url);
				window.location.replace(`/project/team/${payLoad?.contentUid}`);
				break;

			case 'StatusUpdate':
				if (payLoad.slug === 'forgot-start-day') {
					window.location.replace('/project/my-tasks');
				}
				if (payLoad.slug === 'forgot-end-day') {
					window.location.replace('/project/my-tasks');
				}
				break;
			case 'TaskUpdate':
				if (payLoad.slug === 'create-todo-tasks') {
					window.location.replace('/network/todolist');
				}
				if (payLoad.slug === 'congrats-end-day') {
					window.location.replace('/project/my-tasks');
				}
				if (payLoad.slug === 'pending-tasks-complete') {
					window.location.replace('/project/my-tasks');
				}
				break;
			case 'Member':
				if (payLoad.slug === 'new-member-request') {
					window.location.replace('/network/members?pending=true');
				}
				break;

			default:
				location.replace('/network');
		}

		if (!payLoad['contentType'] && payLoad['conversation_id']) {
			// TODO: add open in conversation feature
			window.location.replace('/chat/inbox?conv_id=' + payLoad?.conversation_id);
		}
	}

	public closeNotifications(): void {
		if (this.messages.length > 0) {
			setTimeout(() => {
				this.messages.shift();
			}, 240000);
		}
	}

	/**
	 * Close offline status message after 6 secs
	 */
	public close(): void {
		setTimeout(() => {
			this.showOfflineNotification = 'none';
		}, 9000);
	}

	public locationReload(): void {
		location.reload();
	}
}
