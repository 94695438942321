import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UiAvatarsService } from '../../shared/services/third-party/ui-avatars.service';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'bee-avatar',
	templateUrl: './avatar.component.html',
	styleUrls: ['./avatar.component.scss'],
})
export class BeeAvatarComponent implements OnInit, OnChanges {
	@Input() public size: 'sm' | 'md' | 'lg' | 'xl' | 'custom' = 'md';
	@Input() public resource = 'assets/img/default_avatar.jpg';
	@Input() public height = 0;
	@Input() public width = 0;
	@Input() public borderRadius = 0;
	@Input() public isOnline = false;
	@Input() public hideIsOnline = false;
	@Input() public name: string;
	@Input() public colorRef: string;
	@Input() public colorRefType = 'date';
	@Input() public userId = null;
	@Input() public username = null;
	@Input() public memberId = null;

	constructor(private uiAvatarsService: UiAvatarsService) {}

	ngOnInit(): void {
		if (!this.resource) {
			this.generateResource();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.resource && !changes.resource.isFirstChange) {
			this.resource = changes.resource.currentValue;
			if (!this.resource) {
				this.generateResource();
			}
		}
	}

	/**
	 * Generate Avatar Image from name of the specified user
	 */
	public generateResource(): void {
		this.resource = this.uiAvatarsService.getAvatarUrl(this.name, 128, this.colorRef, this.colorRefType);
	}

	/**
	 * Function to calculate dimension of the image
	 * @returns height, width and border-radius (in pixels) object
	 */
	public getImageDimensions(): object {
		if (this.size === 'custom') {
			return { height: this.height.toString() + 'px', width: this.width.toString() + 'px', borderRadius: this.borderRadius.toString() + 'px' };
		}

		return {};
	}
}
