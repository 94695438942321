import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import 'chartjs-adapter-date-fns';
import { Chart } from 'chart.js';
import { TranslateService } from '@ngx-translate/core';
import { ReportService, NetworkService, UserService, PostService } from '../shared/services';
// eslint-disable-next-line spellcheck/spell-checker
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';

import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-reports',
	templateUrl: './reports.component.html',
	styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit, AfterViewInit {
	// eslint-disable-next-line spellcheck/spell-checker
	@ViewChild(DaterangepickerDirective, { static: false }) public pickerDirective: DaterangepickerDirective;
	@ViewChild('todoCanvas') public todoCanvas: ElementRef;
	@ViewChild('timesheetCanvas') public timesheetCanvas: ElementRef;
	@ViewChild('issueCanvasPerProduct') public issueCanvasPerProduct: ElementRef;
	@ViewChild('issueCanvas') public issueCanvas: ElementRef;
	@ViewChild('pointCanvas') public pointCanvas: ElementRef;

	public todoChart;
	public timesheetChart;
	public issueChart;
	public pointChart;
	public network;
	public channels;
	public showMyTeam;
	public myTeamUid;
	public todo_data;
	public timesheet_data;
	public issue_data;
	public point_data;
	public issue_per_prod_data;
	public showDatePicker = true;
	public teamMembers;
	public issueChartPerProduct;

	public filters = {
		todo: {
			activeFilter1: '7d',
			activeFilter2: {
				type: 'user',
				name: this.translate.instant('By me'),
				id: null,
			},
			activeFilter3: 'd',
		},
		timesheet: {
			activeFilter1: '7d',
			activeFilter2: {
				type: 'user',
				name: this.translate.instant('By me'),
				id: null,
			},
			activeFilter3: 'd',
		},
		point: {
			activeFilter1: '7d',
			activeFilter2: {
				type: 'user',
				name: this.translate.instant('By me'),
				id: null,
			},
			activeFilter3: 'd',
		},
		issue: {
			activeFilter1: '7d',
			activeFilter2: {
				type: 'assignee',
				name: this.translate.instant('Assigned to me'),
				id: null,
			},
			activeFilter3: 'd',
		},
	};

	public currentUser;
	public todoSelectedDateRange;
	public timesheetSelectedDateRange;
	public issueSelectedDateRange;
	public pointSelectedDateRange;
	public products;

	public todoChartToBeShown: any;
	public timesheetChartToBeShown: any;
	public issueChartToBeShown: any;
	public issueChartPerProductToBeShown: any;
	public pointChartToBeShown: any;
	private ctx: any;

	private chartPointChart: any;
	private chartTimesheetChart: any;
	private chartIssueChart: any;
	private chartIssuePerProductChart: any;
	private chartTodo: any;

	private ranges: any = {
		Today: [moment(), moment()],
		Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
		'Last 7 Days': [moment().subtract(6, 'days'), moment()],
		'Last 30 Days': [moment().subtract(29, 'days'), moment()],
		'This Month': [moment().startOf('month'), moment().endOf('month')],
		'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
	};

	constructor(
		private networkService: NetworkService,
		private reportService: ReportService,
		private userService: UserService,
		private postService: PostService,
		private translate: TranslateService,
		private pageLoadingUiService: PageLoadingUiService,
		router: Router
	) {
		if (localStorage.getItem('networkExpired')) {
			router.navigateByUrl('/expired');
		}
	}

	ngOnInit(): void {
		this.networkService.currentNetwork.subscribe((network) => {
			this.network = network;
			this.checkIfManager();
			if (this.network.channels && this.network.channels.length > 0) {
				this.channels = this.network.channels;
			}

			this.currentUser = this.userService.getCurrentUser();

			this.postService.getCategories(this.network.uid).subscribe((data) => {
				this.products = data.issueProducts;
				// this.productIssues = data.issueProducts;
			});

			this.reportService.getTeamMembers(this.network.uid).subscribe((data) => {
				this.teamMembers = data.teamMembers;
			});

			// setting default to 'By me'
			this.filters.todo.activeFilter2.id = this.currentUser['userId'];
			this.filters.timesheet.activeFilter2.id = this.currentUser['userId'];
			this.filters.issue.activeFilter2.id = this.currentUser['userId'];
			this.filters.point.activeFilter2.id = this.currentUser['userId'];
		});
	}

	ngAfterViewInit(): void {
		this.reportService.getTodoReport(this.network.uid, this.filters['todo'].activeFilter1, 'user=' + this.currentUser['userId']).subscribe((data) => {
			if (data.dataExists) {
				this.todo_data = data;
				this.refreshChart('todo');
			} else {
				this.todo_data = false;
			}
		});

		this.reportService.getTimeSheetReport(this.network.uid, this.filters['todo'].activeFilter1, 'user=' + this.currentUser['userId']).subscribe((data) => {
			if (data.dataExists) {
				this.timesheet_data = data;
				this.refreshChart('timesheet');
			} else {
				this.timesheet_data = false;
			}
		});

		this.reportService.getIssueReport(this.network.uid, this.filters['issue'].activeFilter1, 'assignee=' + this.currentUser['userId']).subscribe((data) => {
			if (data.dataExists) {
				this.issue_data = data;
				this.refreshChart('issue');
			} else {
				this.issue_data = false;
			}
		});

		this.reportService.getIssueReport(this.network.uid, this.filters['issue'].activeFilter1, 'assignee=' + this.currentUser['userId'], 'd', true).subscribe((data) => {
			if (data.dataExists) {
				this.issue_per_prod_data = data;
				this.refreshChart('issue');
			} else {
				this.issue_per_prod_data = false;
			}
		});

		this.reportService.getPointLadderReport(this.network.uid, this.filters['point'].activeFilter1, 'user=' + this.currentUser['userId']).subscribe((data) => {
			if (data.dataExists) {
				this.point_data = data;
				this.refreshChart('point');
			} else {
				this.point_data = false;
			}
		});
	}

	/**
	 * Handle export functionality of the charts
	 * @param type Selected chart type
	 */
	public handleExportChart(type: string): void {
		this.pageLoadingUiService.setPageLoading(true);
		const title = type.replace(/#|Canvas/gi, '');
		switch (type) {
			case 'issue':
				this.exportSpecificChart(this.issueCanvas, title, this.issueCanvasPerProduct);
				break;
			case 'todoCanvas':
				this.exportSpecificChart(this.todoCanvas, title);
				break;
			case 'timesheetCanvas':
				this.exportSpecificChart(this.timesheetCanvas, title);
				break;
			case 'pointCanvas':
				this.exportSpecificChart(this.pointCanvas, title);
				break;
		}
	}

	/**
	 * Handle individual chart to pdf file saving
	 * @param chart ElementRef
	 */
	private exportSpecificChart(chart: ElementRef, title: string, chart2: ElementRef = null): void {
		if (chart && !chart2) {
			html2canvas(chart?.nativeElement)
				.then((canvas) => {
					const pdfFile = new jsPDF('l', 'px', [898, 350]),
						imgData = canvas.toDataURL('image/jpeg', 1.0);
					pdfFile.addImage(imgData, 0, 0, 898, 350);
					pdfFile.save(`${title}.pdf`);
				})
				.then(() => {
					this.pageLoadingUiService.setPageLoading(false);
				});
		}
	}

	public filterReport(chart, type, name = '', id = null): void {
		if (!id && (type === 'user' || type === 'assignee')) {
			id = this.currentUser['userId'];
		}

		this.filters[chart].activeFilter2.id = id;
		const f1 = this.filters[chart].activeFilter1;

		this.filters[chart].activeFilter2.type = type;
		this.filters[chart].activeFilter2.name = name;

		this.updateChart(chart, f1, type + '=' + id);
	}

	private refreshChart(chart): void {
		setTimeout(() => {
			if (chart === 'point' && this.pointCanvas) {
				if (this.chartPointChart) {
					this.chartPointChart.destroy();
				}
				this.pointChartToBeShown = this.pointCanvas ? this.pointCanvas.nativeElement : null;

				this.ctx = this.pointChartToBeShown.getContext('2d');
				this.chartPointChart = new Chart(this.ctx, {
					type: 'bar',
					data: {
						labels: this.point_data.days,
						datasets: [
							{
								label: this.translate.instant('Points'),
								// eslint-disable-next-line spellcheck/spell-checker
								backgroundColor: 'limegreen',
								data: this.point_data.points,
							},
						],
					},
					options: {
						responsive: true,
						aspectRatio: 2.5,
						plugins: {
							title: {
								display: true,
								text: this.translate.instant('Point Reports'),
							},
							tooltip: {
								enabled: true,
							},
						},
						scales: {
							x: {
								type: 'time',
								grid: { drawBorder: false },
								time: { parser: 'yyyy-MM-dd', unit: 'day', stepSize: 1, displayFormats: { day: 'MMM dd' } },
							},
							y: {
								display: true,
								beginAtZero: true,
							},
						},
					},
				});
			} else if (chart === 'timesheet' && this.timesheetCanvas) {
				if (this.chartTimesheetChart) {
					this.chartTimesheetChart.destroy();
				}

				this.timesheetChartToBeShown = this.timesheetCanvas ? this.timesheetCanvas.nativeElement : null;
				this.ctx = this.timesheetChartToBeShown.getContext('2d');

				this.chartTimesheetChart = new Chart(this.ctx, {
					type: 'bar',
					data: {
						labels: this.timesheet_data.days,
						datasets: [
							{
								label: this.translate.instant('Duration'),
								// eslint-disable-next-line spellcheck/spell-checker
								backgroundColor: 'limegreen',
								data: this.timesheet_data.avgDuration,
							},
						],
					},
					options: {
						responsive: true,
						aspectRatio: 2.5,
						plugins: {
							title: {
								display: true,
								text: this.translate.instant('Time Sheet Reports'),
							},
							tooltip: {
								enabled: true,
							},
						},
						scales: {
							x: {
								type: 'time',
								grid: { drawBorder: false },
								time: { parser: 'yyyy-MM-dd', unit: 'day', stepSize: 1, displayFormats: { day: 'MMM dd' } },
							},
							y: {
								display: true,
								beginAtZero: true,
							},
						},
					},
				});
			} else if (chart === 'issue' && this.issueCanvas && this.issueCanvasPerProduct) {
				if (this.chartIssueChart) {
					this.chartIssueChart.destroy();
				}

				this.issueChartToBeShown = this.issueCanvas ? this.issueCanvas.nativeElement : null;
				this.ctx = this.issueChartToBeShown.getContext('2d');

				this.chartIssueChart = new Chart(this.ctx, {
					type: 'line',
					data: {
						labels: this.issue_data.days,
						datasets: [
							{
								label: this.translate.instant('AVG dev time'),
								backgroundColor: 'rgba(0, 143, 251, 0.85)',
								borderColor: 'rgba(0, 143, 251)',
								fill: false,
								data: this.issue_data.avgDevTime,
							},
							{
								label: this.translate.instant('AVG Estimate'),
								// eslint-disable-next-line spellcheck/spell-checker
								backgroundColor: 'limegreen',
								// eslint-disable-next-line spellcheck/spell-checker
								borderColor: 'limegreen',
								fill: false,
								data: this.issue_data.avgEstimate,
							},
							{
								label: this.translate.instant('Issue Count'),
								backgroundColor: 'rgba(255,204,0)',
								fill: false,
								data: this.issue_data.issueCount,
								borderColor: 'rgba(255,204,0)',
							},
						],
					},
					options: {
						responsive: true,
						aspectRatio: 2.5,
						transitions: {
							show: {
								animations: {
									x: { from: 0 },
									y: { from: 0 },
								},
							},
						},
						plugins: {
							title: {
								display: true,
								text: this.translate.instant('Issue Reports'),
							},
							tooltip: {
								enabled: true,
							},
						},
						scales: {
							x: {
								offset: true,
								grid: { drawBorder: false },
								type: 'time',
								time: { parser: 'yyyy-MM-dd', unit: 'day', stepSize: 1, displayFormats: { day: 'MMM dd' } },
							},
							y: {
								display: true,
								beginAtZero: true,
							},
						},
					},
				});

				if (this.chartIssuePerProductChart) {
					this.chartIssuePerProductChart.destroy();
				}

				this.issueChartPerProductToBeShown = this.issueCanvasPerProduct ? this.issueCanvasPerProduct.nativeElement : null;
				this.ctx = this.issueChartPerProductToBeShown.getContext('2d');

				const dataSet = [];
				this.issue_per_prod_data.issueCount.forEach((element: any) => {
					const randomColor: string = '#' + Math.floor(Math.random() * 16777215).toString(16),
						dataS = {
							label: element.name,
							backgroundColor: randomColor,
							fill: false,
							data: element.data,
						};

					dataSet.push(dataS);
				});

				this.chartIssuePerProductChart = new Chart(this.ctx, {
					type: 'bar',
					data: {
						labels: this.issue_per_prod_data.days,
						datasets: dataSet,
					},
					options: {
						responsive: true,
						aspectRatio: 2.5,
						plugins: {
							title: {
								display: true,
								text: this.translate.instant('Per Product'),
							},
							tooltip: {
								enabled: true,
							},
						},
						scales: {
							x: {
								offset: true,
								stacked: true,
								grid: { drawBorder: false },
								type: 'time',
								time: { parser: 'yyyy-MM-dd', unit: 'day', stepSize: 1, displayFormats: { day: 'MMM dd' } },
							},
							y: {
								display: true,
								stacked: true,
								beginAtZero: true,
							},
						},
					},
				});

				// this.issueChart = {
				//     series: [
				//         {
				//             name: this.translate.instant('AVG dev time'),
				//             data: this.issue_data.avgDevTime,
				//         },
				//         {
				//             name: this.translate.instant('AVG Estimate'),
				//             data: this.issue_data.avgEstimate,
				//         },
				//         {
				//             name: this.translate.instant('Issue Count'),
				//             data: this.issue_data.issueCount,
				//         },
				//     ],
				//     chart: {
				//         height: 350,
				//         type: 'line',
				//     },
				//     title: {
				//         text: this.translate.instant('Issue Reports'),
				//     },
				//     xAxis: {
				//         type: 'dateTime',
				//         categories: this.issue_data.days,
				//         dateTimeFormatter: {
				//             year: 'yyyy',
				//             month: 'MMM \'yy',
				//             day: 'dd MMM',
				//             hour: 'HH:mm',
				//         },
				//     },
				//     stroke: { width: 1 },
				// };

				// this.issueChartPerProduct = {
				//     series: this.issue_per_prod_data.issueCount,
				//     chart: {
				//         type: 'bar',
				//         height: 350,
				//         stacked: true,
				//         toolbar: {
				//             show: true,
				//         },
				//         zoom: {
				//             enabled: true,
				//         },
				//     },
				//     responsive: [
				//         {
				//             breakPoint: 480,
				//             options: {
				//                 legend: {
				//                     position: 'bottom',
				//                     offsetX: -10,
				//                     offsetY: 0,
				//                 },
				//             },
				//         },
				//     ],
				//     plotOptions: {
				//         bar: {
				//             horizontal: false,
				//             borderRadius: 10,
				//         },
				//     },
				//     title: {
				//         text: this.translate.instant('Per Product'),
				//     },
				//     xAxis: {
				//         type: 'dateTime',
				//         categories: this.issue_per_prod_data.days,
				//     },
				//     legend: {
				//         position: 'right',
				//         offsetY: 40,
				//     },
				//     fill: {
				//         opacity: 1,
				//     },
				// };
			} else if (this.todoCanvas) {
				if (this.chartTodo) {
					this.chartTodo.destroy();
				}

				this.todoChartToBeShown = this.todoCanvas ? this.todoCanvas.nativeElement : null;
				this.ctx = this.todoChartToBeShown.getContext('2d');

				this.chartTodo = new Chart(this.ctx, {
					type: 'line',
					data: {
						labels: this.todo_data.days,
						datasets: [
							{
								label: this.translate.instant('Completion Count'),
								backgroundColor: 'rgba(0, 143, 251, 0.85)',
								borderColor: 'rgba(0, 143, 251)',
								fill: false,
								data: this.todo_data.completionCount,
							},
							{
								label: this.translate.instant('Assigned Count'),
								// eslint-disable-next-line spellcheck/spell-checker
								backgroundColor: 'limegreen',
								// eslint-disable-next-line spellcheck/spell-checker
								borderColor: 'limegreen',
								fill: false,
								data: this.todo_data.assignmentCount,
							},
							{
								label: this.translate.instant('AVG Delay (in days)'),
								backgroundColor: 'rgba(255,204,0,0.4)',
								borderColor: 'rgba(255,204,0)',
								fill: false,
								data: this.todo_data.avgTimeDelay,
							},
							{
								label: this.translate.instant('AVG Duration (in days)'),
								backgroundColor: '#e91e63',
								borderColor: '#e91e63',
								fill: false,
								data: this.todo_data.avgDuration,
							},
						],
					},
					options: {
						responsive: true,
						aspectRatio: 2.5,
						transitions: {
							show: {
								animations: {
									x: { from: 0 },
									y: { from: 0 },
								},
							},
						},
						plugins: {
							title: {
								display: true,
								text: this.translate.instant('ToDo Reports'),
							},
							tooltip: {
								enabled: true,
							},
						},
						scales: {
							x: {
								grid: { drawBorder: false },
								type: 'time',
								time: { parser: 'yyyy-MM-dd', unit: 'day', stepSize: 1, displayFormats: { day: 'MMM dd' } },
							},
							y: {
								display: true,
								beginAtZero: true,
							},
						},
					},
				});
			}
		}, 1000);
	}

	public updateChart(chart, f1, f2?): void {
		if (this.filters[chart].activeFilter1 && this.filters[chart].activeFilter1 === 'range') {
			if (chart === 'todo') {
				this.datesUpdated(this.todoSelectedDateRange, 'todo');
			} else if (chart === 'timesheet') {
				this.datesUpdated(this.timesheetSelectedDateRange, 'timesheet');
			} else if (chart === 'issue') {
				this.datesUpdated(this.issueSelectedDateRange, 'issue');
			} else if (chart === 'point') {
				this.datesUpdated(this.pointSelectedDateRange, 'point');
			}
		}

		if (f1 === 'd' || f1 === 'w' || f1 === 'm') {
			if (f1 === 'w') {
				this.filters[chart].activeFilter1 = '1m';
			} else if (f1 === 'm') {
				this.filters[chart].activeFilter1 = '6m';
			} else {
				this.filters[chart].activeFilter1 = '7d';
			}
			this.filters[chart].activeFilter3 = f1;
		} else {
			this.filters[chart].activeFilter1 = f1;
		}

		if (f1 === 'range') {
			this.openDatePicker();
		} else {
			this.todoSelectedDateRange = null;
			this.timesheetSelectedDateRange = null;
			this.issueSelectedDateRange = null;
			this.pointSelectedDateRange = null;

			if (!f2 && this.filters[chart].activeFilter2) {
				f2 = this.filters[chart].activeFilter2.type + '=' + this.filters[chart].activeFilter2.id;
			}

			if (chart === 'todo') {
				this.reportService.getTodoReport(this.network.uid, this.filters[chart].activeFilter1, f2, this.filters[chart].activeFilter3).subscribe((data) => {
					if (data.dataExists) {
						this.todo_data = data;
						this.refreshChart(chart);
					} else {
						this.todo_data = false;
					}
				});
			} else if (chart === 'timesheet') {
				this.reportService.getTimeSheetReport(this.network.uid, this.filters[chart].activeFilter1, f2, this.filters[chart].activeFilter3).subscribe((data) => {
					if (data.dataExists) {
						this.timesheet_data = data;
						this.refreshChart(chart);
					} else {
						this.timesheet_data = false;
					}
				});
			} else if (chart === 'point') {
				this.reportService.getPointLadderReport(this.network.uid, this.filters[chart].activeFilter1, f2, this.filters[chart].activeFilter3).subscribe((data) => {
					if (data.dataExists) {
						this.point_data = data;
						this.refreshChart(chart);
					} else {
						this.point_data = false;
					}
				});
			} else if (chart === 'issue') {
				this.reportService.getIssueReport(this.network.uid, this.filters[chart].activeFilter1, f2, this.filters[chart].activeFilter3).subscribe((data) => {
					if (data.dataExists) {
						this.issue_data = data;
						this.refreshChart(chart);
					} else {
						this.issue_data = false;
					}
				});

				this.reportService.getIssueReport(this.network.uid, this.filters[chart].activeFilter1, f2, this.filters[chart].activeFilter3, true).subscribe((data) => {
					if (data.dataExists) {
						this.issue_per_prod_data = data;
						this.refreshChart(chart);
					} else {
						this.issue_per_prod_data = false;
					}
				});
			}
		}
	}

	/**
	 * Function  to check if user has manager cp
	 * 1. Check if local storage contains manager
	 * 2. Set manager uid and display my team filter
	 */
	private checkIfManager(): void {
		if (localStorage.getItem('manager')) {
			const obj = JSON.parse(localStorage.getItem('manager'));
			this.showMyTeam = true;
			this.myTeamUid = obj.uid;
		}
	}

	private openDatePicker(): void {
		// this.showDatePicker = true;
		this.pickerDirective.open();
	}

	public datesUpdated(e, chart): void {
		if (e.startDate && e.endDate) {
			this.filters[chart].activeFilter1 = 'range';
			const startDate = e.startDate.format('yyyy-MM-DD');
			const endDate = e.endDate.format('yyyy-MM-DD');

			const f1 = startDate + '|' + endDate;
			let f2 = null;
			if (this.filters[chart].activeFilter2) {
				f2 = this.filters[chart].activeFilter2.type + '=' + this.filters[chart].activeFilter2.id;
			}

			if (chart === 'todo') {
				this.reportService.getTodoReport(this.network.uid, f1, f2).subscribe((data) => {
					if (data.dataExists) {
						this.todo_data = data;
						this.refreshChart('todo');
					} else {
						this.todo_data = false;
					}
				});
			} else if (chart === 'timesheet') {
				this.reportService.getTimeSheetReport(this.network.uid, f1, f2).subscribe((data) => {
					if (data.dataExists) {
						this.timesheet_data = data;
						this.refreshChart('timesheet');
					} else {
						this.timesheet_data = false;
					}
				});
			} else if (chart === 'point') {
				this.reportService.getPointLadderReport(this.network.uid, f1, f2).subscribe((data) => {
					if (data.dataExists) {
						this.point_data = data;
						this.refreshChart('point');
					} else {
						this.point_data = false;
					}
				});
			} else if (chart === 'issue') {
				this.reportService.getIssueReport(this.network.uid, f1, f2).subscribe((data) => {
					if (data.dataExists) {
						this.issue_data = data;
						this.refreshChart('issue');
					} else {
						this.issue_data = false;
					}
				});
			}
		}
	}
}
