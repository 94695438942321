import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReportsComponent } from './reports.component';

import { BrowserModule } from '@angular/platform-browser';

import { SharedModule } from '../shared';

import { AgmCoreModule } from '@agm/core';

// eslint-disable-next-line spellcheck/spell-checker
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

const reportRouting: ModuleWithProviders<any> = RouterModule.forChild([
	{
		path: 'network/:uid/reports',
		component: ReportsComponent,
	},
]);

@NgModule({
	declarations: [ReportsComponent],
	imports: [
		reportRouting,
		CommonModule,
		BrowserModule,
		AgmCoreModule,
		SharedModule,
		// eslint-disable-next-line spellcheck/spell-checker
		NgxDaterangepickerMd.forRoot(),
	],
	bootstrap: [ReportsComponent],
})
export class ReportsModule {}
