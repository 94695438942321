/* eslint-disable spellcheck/spell-checker */
import { Component, Input, OnInit } from '@angular/core';
import { PageLoadingUiService } from '@core/services/ui/common/page-loading-ui.service';
import { ProjectService } from '@modules/project/services/project.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ICurrentUser } from '@shared/models';
import { PostService } from '@shared/services';
import { ToastService } from '@shared/services/common/toast.service';
import format from 'date-fns/format';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-focus-time-modal',
	templateUrl: './focus-time-modal.component.html',
	styleUrls: ['./focus-time-modal.component.css'],
})
export class FocusTimeModalComponent implements OnInit {
	@Input() public networkId: string;
	@Input() public currentUser: ICurrentUser;

	public starts: string;
	public ends: string;
	private dummyModel: any;
	public isSubmitted = false;

	constructor(
		public activeModal: NgbActiveModal,
		private translateService: TranslateService,
		private toastService: ToastService,
		private postService: PostService,
		private projectService: ProjectService,
		private pageLoadingUiService: PageLoadingUiService
	) {}

	ngOnInit(): void {
		const startDate = new Date(),
			endDate = new Date(startDate.getTime() + 120 * 60000);

		this.starts = startDate.getHours() + ':' + startDate.getMinutes();
		this.ends = endDate.getHours() + ':' + endDate.getMinutes();
	}

	public closeModal(): void {
		this.activeModal?.close();
	}

	/**
	 * Converts local time to UTC
	 * @param date Local Time
	 * @returns UTC Conversion
	 */
	private convertLocalToUTCTime(date: Date): Date {
		const isoDate = date.toISOString();
		return new Date(`${isoDate.substring(0, 10)} ${isoDate.substring(11, 19)}`);
	}

	public createEvent(): void {
		this.isSubmitted = true;
		const timeDiffInMinutes =
			Math.abs(
				new Date(null, null, null, parseInt(this.ends.split(':')[0], 10), parseInt(this.ends.split(':')[1], 10)).getTime() -
					new Date(null, null, null, parseInt(this.starts.split(':')[0], 10), parseInt(this.starts.split(':')[1], 10)).getTime()
			) / 60000;

		if (timeDiffInMinutes > 120) {
			this.translateService.get('Select Focuses Time Limit Error').subscribe((res: string) => {
				this.toastService.info(res);
			});
		} else if (timeDiffInMinutes > 0 || timeDiffInMinutes <= 120) {
			const currentDate: Date = new Date();

			const startDate: Date = this.convertLocalToUTCTime(
					new Date(
						currentDate.getFullYear(),
						currentDate.getMonth(),
						currentDate.getDate(),
						parseInt(this.starts.split(':')[0], 10),
						parseInt(this.starts.split(':')[1], 10)
					)
				),
				endDate: Date = this.convertLocalToUTCTime(
					new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), parseInt(this.ends.split(':')[0], 10), parseInt(this.ends.split(':')[1], 10))
				);

			this.dummyModel = {
				network: this.networkId,
				username: this.currentUser?.username,
				shareable: 1,
				commentable: 1,
				attachmentType: 'photo',
				state: 'active',
				cost: 'free',
				link: 'www.andoneapp.com',
				category: 'focus-time',
				type: 'event',
				images: environment.production ? '410a063f65d86d5b8875804919be63c17a2a5e83_zV3RI4O': 'b96af4aee282fb4f7b882f4c8852f88cd5d6af36',
				title: this.translateService.instant('Focus Time'),
				starts: format(startDate, 'yyyy-MM-dd HH:mm:ss'),
				ends: format(endDate, 'yyyy-MM-dd HH:mm:ss'),
				custom_privacy: '[' + [this.currentUser.userId] + ']',
			};

			this.pageLoadingUiService.setPageLoading(true);

			// API Call for creating Focus Time Post
			this.postService.create(this.dummyModel).subscribe((res: any) => {
				if (res) {
					const task = {
						assignedUser: this.currentUser?.username,
						category: 'todo',
						collection_post: true,
						commentable: true,
						description: '',
						endTime: format(endDate, 'yyyy-MM-dd HH:mm:ss'),
						// network: '49a50aae-4e09-4329-8116-0dcad0e9cea9',
						network: this.networkId ? this.networkId : '',
						oneOff: format(startDate, 'yyyy-MM-dd HH:mm:ss'),
						priority: 'p1',
						repeatCycle: 'one_off',
						selectedDueDate: 'one_off',
						startTime: format(startDate, 'yyyy-MM-dd HH:mm:ss'),
						state: 'active',
						title: this.dummyModel.title,
						type: 'todo',
						uid: res.uid,
					};
					// Second API Call for creating Focus Time task to task list
					this.projectService
						.createToDo(task, this.currentUser?.username)
						.subscribe(() => {
							this.isSubmitted = false;
							this.toastService.success(this.translateService.instant('New Focus Time Event Created'));
							this.closeModal();
						})
						.add(() => {
							this.pageLoadingUiService.setPageLoading(false);
						});
				}
			},
			error =>{
				console.log(error);
				this.isSubmitted = false;
			});
		} else {
			this.toastService.info('Please select valid time');
		}
	}
}
