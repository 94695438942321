import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContextMenuModule, ContextMenuService } from '@perfectmemory/ngx-contextmenu';
import { ChatSocketService } from '@modules/chat/services/chat-socket.service';
import { QuickChatComponent } from './quick-chat.component';
import { QuickMessagesComponent } from './quick-messages/quick-messages.component';
import { AttachmentBubbleQuickChatComponent } from './attachment-bubble-quick-chat/attachment-bubble-quick-chat.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { SharedModule } from '@shared/shared.module';
// import { NgxSpinnerModule } from 'ngx-spinner';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { ClipboardModule } from 'ngx-clipboard';
// import { PostModule } from 'src/app/post/post.module';
import { NetworkLayoutModule } from '@layouts/network-layout/network-layout.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { PeerCoreModule } from 'projects/peer-core/src/public-api';

@NgModule({
	declarations: [QuickChatComponent, QuickMessagesComponent, AttachmentBubbleQuickChatComponent],
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		AgmCoreModule,
		SharedModule,
		// NgxSpinnerModule,
		NgbModule,
		NgxMaterialTimepickerModule,
		DragDropModule,
		TranslateModule,
		MatTabsModule,
		InfiniteScrollModule,
		NetworkLayoutModule,
		// PostModule,
		ClipboardModule,
		ContextMenuModule,
		PeerCoreModule,
		CalendarModule.forRoot({
			provide: DateAdapter,
			useFactory: adapterFactory,
		}),
	],
	exports: [QuickChatComponent, QuickMessagesComponent, AttachmentBubbleQuickChatComponent],
	bootstrap: [QuickChatComponent],
	providers: [ChatSocketService, ContextMenuService],
})
export class QuickChatModule {}
